import React from 'react';
import { useMsal } from '@azure/msal-react';
import Button from 'react-bootstrap/Button';
import { Body1 } from '@fluentui/react-components';

/**
 * Renders a sign out button 
 */
export const Logout = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  };

  return (
    <Button style={{ backgroundColor: '#151b2b', border: 'none', marginRight: '20px' }} type='button' onClick={handleLogout}>
      <Body1>
        Sign Out
      </Body1>
    </Button>
  );
};