import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>Centar za pomoć TeamConnect & Često postavljana pitanja</Title3>
        <p><Body1>
          Dobrodošli u centar za pomoć TeamConnect, osmišljen kako bi podržao vaše iskustvo s našom opsežnom korporativnom direktorijom aplikacije. Ovdje ćete pronaći resurse za optimizaciju vaše upotrebe TeamConnect unutar Microsoft Teams, poboljšavajući komunikaciju diljem vaše mreže.
        </Body1></p>
        <Subtitle2>Kratki priručnik</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Poboljšane mogućnosti pretraživanja: </Body1Strong>
              Brzo pronađite prave kolege, odjele i bitne kontakte uz naše moćne alate za pretraživanje.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Vizualni organizacijski grafikoni: </Body1Strong>
              Navigirajte hijerarhijom vaše organizacije i jednostavno razumijte linije izvještavanja kroz intuitivne organizacijske grafikone.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Prilagodljivi alati za današnje potrebe: </Body1Strong>
              Koristite moderne funkcionalnosti prilagođene zahtjevima suvremenih radnih mjesta.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Optimiziranje vašeg iskustva</Subtitle2>
        <Body1>
          <p>
            TeamConnect revolucionira kako se povezujete unutar vaše organizacijske strukture, pretvarajući svakodnevne komunikacije u strateške angažmane.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Dobivanje pomoći i često postavljana pitanja</Subtitle1>
        <Body1>
          <p>
            Pronađite odgovore na česta pitanja o značajkama i postavkama TeamConnecta u ovom posvećenom odjeljku. Bilo da navigirate aplikacijom, prilagođavate postavke ili tražite rješenja za izazove, naš centar za pomoć ovdje je da vam učinkovito asistira.
          </p>
        </Body1>
        <Body1>
          <p>
            Za dublju podršku ili specifične upute, molimo konzultirajte <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>Priručnik za korisnike TeamConnecta</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        
        <Subtitle2>Korištenje TeamConnect često postavljanih pitanja</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Ne možete pronaći TeamConnect u bočnoj traci Microsoft Teamsa?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ako TeamConnect nije odmah vidljiv ili je nestao iz vaše bočne trake Microsoft Teamsa, možete ga lako pribiti za brzi pristup. Samo slijedite ove jednostavne korake:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Otvorite Microsoft Teams i kliknite na ikonu '...' (+ Aplikacije) na dnu bočne trake.</li>
                    <li className={styles.bulletBottomMargin}>Tražite 'TeamConnect' u traci za pretraživanje na vrhu iskočnog prozora.</li>
                    <li className={styles.bulletBottomMargin}>Desnim klikom na aplikaciju TeamConnect iz rezultata pretraživanja odaberite 'Pribiti'.</li>
                  </ol>
                  TeamConnect će sada biti pribijen na vašu bočnu traku, čineći ga dostupnim samo jednim klikom!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Susrećete se s tehničkim problemima s TeamConnectom? Evo kako dobiti pomoć.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ako naiđete na bilo kakve tehničke probleme s TeamConnectom unutar Microsoft Teamsa, tim za IT podršku vaše organizacije je tu da vam pomogne.
                  Obratite im se na <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> i oni će vam brzo pomoći.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Ne vidite svoje detalje o zaposleniku?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ako se vaš profil čini pomalo prazan s tek vašim imenom i e-mailom, vjerojatno vaš HR tim još nije dodao ostatak vaših detalja o zaposleniku u TeamConnect. 
                  Nema razloga za brigu! Brza poruka vašem timu za HR podršku pokrenut će stvari. Oni su spremni popuniti vaš profil svim važnim detaljima.
                  Obratite im se na <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> i gledajte kako vaš profil oživljava!
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Koje su jezične opcije dostupne u TeamConnectu?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect podržava globalnu raznolikost Microsoft Teamsa podržavajući sve njegove jezike. 
                  Bilo da preferirate arapski ili grčki, japanski ili švedski, na raspolaganju su vam brojne opcije, uključujući:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arapski, bugarski, kineski (pojednostavljeni i tradicionalni), hrvatski, češki, danski, nizozemski, engleski, estonski, finski, 
                      francuski, njemački, grčki, hebrejski, mađarski, indonezijski, talijanski, japanski, korejski, latvijski, litavski, norveški (bokmal), 
                      poljski, portugalski (Brazil i Portugal), rumunjski, ruski, srpski (latinica), slovački, slovenski, španjolski, švedski, tajlandski, 
                      turski, ukrajinski, vijetnamski.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Da prilagodite svoje TeamConnect iskustvo na željeni jezik, molimo vas prilagodite jezične postavke izravno u Microsoft Teamsima, 
                  jer se TeamConnect u potpunosti integrira s tim postavkama:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Idite na svoje postavke Microsoft Teamsa klikom na tri točke pored vaše profilne slike na vrhu, a zatim odaberite 'Izgled i pristupačnost'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Pomaknite se do odjeljka 'Jezik' i iz padajućeg izbornika odaberite željeni jezik.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Potvrdite svoje promjene klikom na gumb 'Spremi i ponovno pokreni' da primijenite nove jezične postavke.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Napomena: </Body1Strong>
                <Body1>
                  Promjene koje napravite na jezičnim postavkama u Microsoft Teamsima automatski će ažurirati vaše TeamConnect iskustvo. 
                  Imajte na umu da promjena jezika u Teamsima također može prilagoditi vaš format vremena i datuma. Trenutno, 
                  TeamConnect se razvija za pisanje s desna na lijevo. Uzbuđeni smo zbog ovih poboljšanja 
                  i obavijestit ćemo vas kada postanu dostupni.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Kako mogu promijeniti temu u TeamConnectu?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect sinkronizira s vašim postavkama teme za Microsoft Teams, uključujući opcije Svijetla, Tamna i Visoki kontrast. Da prilagodite svoju temu:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Pristupite postavkama Microsoft Teamsa klikom na tri točke pored vaše slike profila na vrhu.</li>
                    <li className={styles.bulletBottomMargin}>Odaberite 'Izgled i pristupačnost' kako biste istražili opcije teme.</li>
                    <li className={styles.bulletBottomMargin}>Odaberite željenu temu iz dostupnih opcija kako biste odmah ažurirali sučelje TeamConnecta kako bi se podudaralo.</li>
                  </ol>
                  Vaš izbor teme u Microsoft Teamsima automatski će se primijeniti na TeamConnect, osiguravajući dosljedno vizualno iskustvo u vašem radnom prostoru.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Istraživanje akcijskih tipki kontakata u TeamConnectu
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Kroz TeamConnect, gdje god pronađete detalje kontakta zaposlenika, također ćete otkriti naše praktične tipke za brzi pristup. Ovi korisni alati
                  su dizajnirani za optimizaciju vaše komunikacije unutar Microsoft ekosustava, osiguravajući da ste uvijek samo jedan klik udaljeni od povezivanja.
                  Evo vašeg sveobuhvatnog vodiča za učinkovito korištenje tih tipki:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona za razgovor:</Body1Strong> Kliknite za pokretanje trenutnog chata u Microsoft Teamsima. Ako trebate vratiti se u TeamConnect nakon pokretanja chata, jednostavno koristite strjelicu natrag u Desktop aplikaciji, gumb natrag u Mobilnoj aplikaciji ili gumb natrag u vašem pregledniku.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona za e-mail:</Body1Strong> Otvorite novu e-mail skicu u Outlooku s već unesenom adresom primatelja u polje "To:". Ako se Outlook ne pokrene, vrijedi provjeriti s vašim IT odjelom za bilo kakva ograničenja.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona za video poziv:</Body1Strong> Pokreće video poziv licem u lice putem Microsoft Teamsa.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona za poziv:</Body1Strong> Zapocinje glasovni poziv putem Microsoft Teamsa, olakšavajući brze rasprave.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona organizacijskog dijagrama:</Body1Strong> Otkriva položaj zaposlenika unutar hijerarhije organizacije, uključujući njihovog menadžera i izravne izvještaje.</li>
                    <li><Body1Strong>Ikona više (...):</Body1Strong> Otkriva dodatne opcije:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Susret odmah:</Body1Strong> Otvorite Microsoft Teams za početak trenutnog sastanka s zaposlenikom.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Novi sastanak:</Body1Strong> Usmjerava vas na Microsoft Teams za planiranje novog sastanka.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Dodaj kontakt:</Body1Strong> Dodaje kontaktne podatke zaposlenika iz TeamConnecta u vašu novu aplikaciju Microsoft Teams People, obogaćujući vašu funkciju poziva.</li>
                      </ul>
                    </li>
                  </ul>
                  Ove intuitivne tipke su izrađene kako bi osigurale da možete učinkovito povezivati ​​s kolegama, bilo za brzi razgovor, e-mail ili za planiranje budućih sastanaka.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Pozivi putem TeamConnecta
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Želite obaviti poziv? TeamConnect pojednostavljuje vašu komunikaciju integracijom s Microsoft Teamsima.
                  Jednostavno kliknite na željeni telefonski broj u TeamConnectu, i eto, sučelje za pozivanje Microsoft Teamsa se pojavljuje,
                  spremno za vas da bez problema obavite poziv.
                  <p>
                  <Body1Strong>Napomena: </Body1Strong> 
                  Imate problema s obavljanjem poziva? To može biti zbog IT politika vaše organizacije ili 
                  korištenja telefonskog sustava koji nije Microsoft Teams Phone System. Provjera s vašim IT odjelom na <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, može rasvijetliti stvar.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Postoje li troškovi povezani s obavljanjem poziva putem TeamConnecta?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect poboljšava vaše iskustvo pozivanja koristeći Microsoft Teams. Evo što trebate znati o troškovima poziva:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Za pozive na službene telefone zaposlenika, telefone odjela ili hitne linije unutar vaše organizacije (interna brojeva),
                      obično <Body1Strong>nema troškova</Body1Strong> kada koristite Microsoft Teams Phone System.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Za vanjske pozive, kao što su oni na mobilne telefone ili vanjske linije, primjenjuju se <Body1Strong>standardne tarife</Body1Strong> telefonskog sustava vaše organizacije.
                      Ove tarife variraju ovisno o vašem specifičnom servisnom planu s Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Napomena:</Body1Strong> Ako naiđete na bilo kakve probleme ili imate pitanja o naplati poziva, najbolje je konzultirati se 
                    s vašim IT odjelom na <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> za detaljne informacije prilagođene 
                    postavkama i politikama vaše organizacije.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Primjećujete neslaganje s vašim statusom prisutnosti 'Izvan ureda' u TeamConnectu?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Primijetili ste da vaš status prisutnosti 'Izvan ureda' iz Microsoft 365 nije pravilno prikazan u TeamConnectu?
                  Riješili smo to. Značajka sinkronizacije statusa prisutnosti 'Izvan ureda' već je implementirana u TeamConnectu.
                  Trenutačno čekamo da Microsoft implementira ovu značajku. Kada se to dogodi, vaš status prisutnosti 'Izvan ureda'
                  bit će točno odražen u TeamConnectu, usklađen s indikatorima prisutnosti Microsoft Teamsa za besprijekorno iskustvo.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Često postavljana pitanja o Imeniku zaposlenika</Subtitle2>
        <Body1>
          <p>
            <Body1Strong>Uvod u Imenik zaposlenika:</Body1Strong> Istražite profesionalni pejzaž vaše organizacije s Imenikom zaposlenika,
              vašim središnjim mjestom za detaljne uvide o zaposlenicima, dinamiku radnog mjesta, i izravnu povezanost s profilima i menadžerskim odnosima vaših kolega.
          </p>
        </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Trebate dodati, ažurirati ili ispraviti vaše podatke o zaposleniku?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Za dodavanje, ažuriranje ili ispravljanje vaših podataka o zaposleniku u TeamConnectu, molimo kontaktirajte tim za podršku zaposlenicima vaše organizacije.
                  Oni su spremni pomoći na <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Što se događa kada kliknem na ime zaposlenika u imeniku?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Klikom na ime zaposlenika u Imeniku zaposlenika TeamConnect otvara se detaljna stranica profila za tu osobu. Ovaj profil pruža sveobuhvatne
                  uvide o zaposleniku, uključujući, ali ne ograničavajući se na, njihov radni naslov, odjel, lokaciju ureda, kontakt informacije i više. Dizajniran je da vam
                  pruži potpuni pregled profesionalne uloge zaposlenika i kako se uklapaju u organizaciju, olakšavajući vam razumijevanje njihovih odgovornosti i 
                  kako biste mogli surađivati s njima.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Koje informacije su uključene u profil zaposlenika?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Svaki profil zaposlenika u TeamConnect je sveobuhvatan presjek, koji nudi bogatstvo uvida u profesionalni život pojedinca.
                  Evo što ćete pronaći:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Osnovni detalji:</Body1Strong> Ime, radni naslov, odjel i lokacija podružnice.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Struktura izvještavanja:</Body1Strong> Informacije o izravnim i funkcionalnim izvješćima, 
                      uključujući liniju i funkcionalne menadžere.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Status na radnom mjestu:</Body1Strong> Trenutni radni status kao što su Na-odmoru, na-Putu, 
                      Poslovno-putovanje itd.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Radno mjesto i lokalno vrijeme:</Body1Strong> Trenutna radna lokacija zaposlenika i 
                      lokalna vremenska zona.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontakt detalji:</Body1Strong> Radni telefon, mobilni broj i e-mail adresa.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Adresa ureda:</Body1Strong> Potpuna lokacija ureda uključujući naziv zgrade,
                      adresa i Google karta.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>O zaposleniku:</Body1Strong> Kratki osobni uvod ili profesionalni životopis.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Odnosi s zaposlenicima:</Body1Strong> Detalji o izvještajnoj strukturi zaposlenika i 
                      odnosima unutar tima.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Socijalne veze i jezici:</Body1Strong> Linkovi na profesionalne društvene profile
                      i jezične vještine.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vještine:</Body1Strong> Popis profesionalnih vještina i područja stručnosti.</li>
                  </ul>
                  Osim toga, profil nudi opcije za povezivanje putem chata, e-maila, video poziva ili zakazivanje sastanaka, zajedno s 
                  brzim pristupom organizacijskom dijagramu za širi kontekst tima.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Kako mogu ažurirati informacije svog profila?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Personalizacija vašeg profila je jednostavna u TeamConnectu. Evo kako to možete učiniti:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Idite na karticu <Body1Strong>Zaposlenik</Body1Strong> i kliknite na svoj <Body1Strong>Avatar</Body1Strong> kako biste otvorili svoj profil.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        U gornjem lijevom kutu, naći ćete poveznicu <Body1Strong>"Uredi svoj profil"</Body1Strong>. 
                        Klikom na to otvorit će se dijalog za uređivanje vašeg profila.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Ovdje možete poboljšati svoj profil dodavanjem ili ažuriranjem dijela <Body1Strong>O vama</Body1Strong>, poveznica društvenih mreža i telefonskog broja.
                        To olakšava vašim kolegama da se povežu s vama ili vas prate.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Ne zaboravite pritisnuti gumb <Body1Strong>Spremi</Body1Strong> kako biste primijenili svoje promjene.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Pomičite se prema dolje u dijalogu kako biste dodali svoje <Body1Strong>Jezične vještine</Body1Strong> i <Body1Strong>Profesionalnu sposobnost</Body1Strong>, 
                        kao i vaše <Body1Strong>Vještine</Body1Strong>. Dijeljenje ovih informacija pomaže vašim kolegama da razumiju 
                        vaša područja stručnosti i jezike na kojima možete komunicirati.
                      </li>
                    </ol>
                  Ažuriranje vašeg profila ne samo da održava vaše informacije ažurnima, već i potiče bolje veze unutar vaše organizacije.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Ažuriranje vašeg radnog mjesta, radnog prostora i vremenske zone u TeamConnectu
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Održavanje informacija o radnom mjestu, radnom prostoru i vremenskoj zoni ažurnim je ključno za besprijekornu suradnju u Microsoft Teamsima. 
                  Ova ažuriranja odmah se odražavaju u vašem profilu i popisima TeamConnecta, omogućavajući kolegama da brzo shvate vašu lokaciju rada 
                  i najbolje vrijeme za povezivanje s vama, poboljšavajući timski rad na različitim područjima i vremenskim zonama.
                  <p></p>
                  Spremni za ažuriranje svojih detalja? Lako je u TeamConnectu! Otiđite na 'Postavke' u glavnom izborniku i navigirajte do 'Opće postavke'. 
                  Ovdje možete glatko ažurirati svoje radno mjesto i vremensku zonu, i specificirati svoj radni prostor za dan. Ne zaboravite spremiti svoje promjene kako biste 
                  sve obavijestili i osigurali učinkovitu suradnju, bez obzira na vašu lokaciju!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Primijetili ste nešto čudno u profilu kolege?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ako naiđete na bilo kakve detalje u profilu kolege koji se ne čine sasvim točnimi, najbolji prvi korak je da im prijateljski skrenete pažnju. Brzi razgovor ili e-mail bi trebali biti dovoljni! Ako iz nekog razloga informacije ne budu ažurirane, ne brinite—samo se javite svojem timu za podršku u ljudskim resursima na <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, i oni će 
                  s radošću uskočiti i riješiti stvari.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Razumijevanje skupina oznaka: Svi, Favoriti, Prijavljeni & Novi zaposlenici
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Svi:</Body1Strong> Ova zadana oznaka prikazuje svakog zaposlenika u vašoj organizaciji, 
                      pružajući sveobuhvatan pregled tima.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoriti:</Body1Strong> Kliknite ovdje za filtriranje prikaza na kolege koje ste označili 
                      kao 'Favoriti', što olakšava brzo povezivanje s ključnim kontaktima.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Prijavljeni:</Body1Strong> Ova oznaka označava zaposlenike koji su aktivno na poslu, razlikujući 
                      od samo online prisutnosti na Microsoft Teams-u. Kada su prijavljeni, zaposlenici signaliziraju da su na poslu i dijele svoju trenutačnu radnu lokaciju 
                      tijekom dana. To može uključivati razne radne prostore kao što su Ured, Terenski ured, Kućni ured, Udaljeni radni prostor, Radno mjesto kod klijenta, 
                      Privatni ured kod klijenta, Javni prostor, Put, Poslovno putovanje ili čak Obuka. Stoga, status 'Prijavljeni' pruža jasan pokazatelj 
                      aktivnog sudjelovanja na poslu, nudeći kolegama precizno razumijevanje trenutačnog radnog konteksta i raspoloživosti svakog zaposlenika.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Novi zaposlenici:</Body1Strong> Prepoznajte nove lice u TeamConnectu s ovom oznakom, ističući 
                      zaposlenike koji su dodani u aplikaciju unutar posljednjih 30 dana. Obično, to se podudara s nedavnim dodacima u vašoj organizaciji, pomažući 
                      vam da pozdravite nove članove tima.
                    </li>
                  </ul>
                  Ove skupine oznaka su dizajnirane da pojednostave navigaciju i poboljšaju vaše iskustvo TeamConnecta, osiguravajući da uvijek možete
                  lako pronaći i povezati se s kolegama.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Dekodiranje ikona pokraj okvira za pretraživanje
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Imenik zaposlenika TeamConnecta je dizajniran za svestranost i jednostavnost, sa četiri ikone koje nude različite poglede 
                  i brz pristup ključnim detaljima. Evo što svaka ikona otkriva:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Prikaz popisa:</Body1Strong> Vaš uobičajeni pogled, predstavlja sve članove organizacije
                      na jednostavnom popisu.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Mali prikaz kartica:</Body1Strong> Kompaktna alternativa, ovaj prikaz pretvara
                      popis u male kartice zaposlenika.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Veliki prikaz kartica:</Body1Strong> Izaberite više detalja s većim karticama, koje uključuju 
                      dodatne opcije kontaktiranja poput telefona i mobilnih brojeva za jednostavno biranje.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organizacijski dijagram:</Body1Strong> Vizualizirajte strukturu tvrtke s prikazom organizacijskog dijagrama, 
                      savršeno za razumijevanje izvještajnih odnosa i navigaciju kroz organizacijski krajolik.</li>
                  </ol>
                    Prebacujte se između ovih ikona kako biste pronašli prikaz koji najbolje odgovara vašim trenutnim potrebama, bilo da tražite brze informacije ili dublje uvide.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Gdje brzo tražiti kolege?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tražite kolegu? Samo se uputite na okvir za pretraživanje TeamConnecta, smješten u gornjem desnom kutu pokraj ikone Organizacijskog dijagrama. 
                  Počnite upisivati ​​njihovo ime i gledajte kako se rezultati sužavaju sa svakim slovom. Zapamtite, za pretraživanja u TeamConnectu, preskočite 
                  traku za pretraživanje Microsoft Teams-a na vrhu - naš posebni okvir za pretraživanje je pravi put!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Kako funkcionira abecedno pretraživanje za pronalaženje zaposlenika?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Imenik zaposlenika TeamConnect uključuje korisnu značajku abecednog pretraživanja kako bi vam pomogla brzo locirati zaposlenike po njihovom imenu. 
                  Jednostavno kliknite na slovo abecede prikazano na vrhu imenika, i TeamConnect će odmah prikazati sve zaposlenike čija imena počinju s tim slovom. 
                  Da biste se vratili na cjeloviti popis zaposlenika, samo odaberite istaknuto slovo. Ova intuitivna značajka olakšava navigaciju kroz velike liste 
                  zaposlenika, osiguravajući da brzo i učinkovito pronađete kolege.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Kako mogu prilagoditi broj zaposlenika prikazanih po stranici?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect vam omogućuje da prilagodite koliko profila zaposlenika vidite po stranici, čineći navigaciju kroz Imenik zaposlenika lakšom. 
                  Na dnu svake stranice nalaze se opcije za paginaciju: 10, 50, 100, 500, 1000 i 5000. Zadana postavka prikazuje 10 profila po stranici. 
                  Da biste vidjeli više profila na jednoj stranici, jednostavno odaberite željeni broj iz ovih opcija. Ova značajka pruža fleksibilnost u načinu 
                  na koji pregledavate imenik, omogućujući vam prilagodbu prikaza prema vašim specifičnim potrebama ili preferencijama.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Kako mogu sortirati informacije o zaposlenicima u prikazu popisa?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  U prikazu popisa Imenika zaposlenika TeamConnect, imate fleksibilnost za sortiranje informacija o zaposlenicima prema svojim potrebama. Stupci—Ime zaposlenika, 
                  Radno mjesto, Odjel, Lokacija ureda i Radno mjesto su svi sortirani. Jednostavno kliknite na zaglavlje stupca po kojem želite sortirati. Klikom jedanput 
                  sortirat će se stupac u uzlaznom redoslijedu, a klikom ponovno sortirat će se u silaznom redoslijedu. Prema zadanim postavkama, direktorij je sortiran 
                  po imenu zaposlenika u uzlaznom redoslijedu, na temelju imena. Ova značajka sortiranja pomaže vam brzo organizirati i pregledavati detalje o zaposlenicima 
                  na način koji najbolje služi vašoj svrsi, bilo da tražite određenu osobu ili trebate grupirati zaposlenike prema određenom kriteriju kao što su odjel ili lokacija.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Pitanja i odgovori u vezi direktorija odjela</Subtitle2>
          <Body1>
          <p>
          <Body1Strong>Uvod u direktorij odjela:</Body1Strong> Direktorij odjela vaša je kapija za razumijevanje složene mreže strukture vaše
          organizacije, pružajući jasan prikaz hijerarhija unutar odjela, vođenja i mogućnosti za besprijekornu komunikaciju unutar odjela.
          </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                22. Kako funkcionišu oznake 'Sve', 'Favoriti' i 'Vaš odjel' u Direktoriju odjela?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  U Direktoriju odjela TeamConnecta, oznake vam pomažu brzo navigirati i filtrirati informacije o odjelima. Evo što svaka oznaka predstavlja:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sve:</Body1Strong> Ova oznaka prikazuje sve odjele unutar vaše organizacije, pružajući
                    sveobuhvatan pregled.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoriti:</Body1Strong> Upotrijebite ovu oznaku za pregled popisa odjela koje ste označili
                    kao 'Favoriti', olakšavajući pristup odjelima s kojima često surađujete.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vaš odjel:</Body1Strong> Ova oznaka filtrira prikaz tako da prikazuje samo vaš odjel,
                    omogućujući brz pristup informacijama i ažuriranjima vašeg neposrednog odjela.</li>
                  </ul>
                  Te oznake su dizajnirane da pojednostave vašu navigaciju unutar Direktorija odjela, poboljšavajući vašu sposobnost da efikasno pronađete i angažirate se s informacijama o odjelima.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Razumijevanje pregleda Direktorija odjela u TeamConnectu
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                Navigacija kroz Direktorij odjela u TeamConnectu je pojednostavljena s više opcija pregleda koje odgovaraju vašim preferencijama. Svaki pregled nudi
                jedinstven način istraživanja sveobuhvatnih informacija o odjelima, uključujući Ime odjela, Menadžera odjela, Nadređeni odjel, Telefon odjela,
                Hotline odjela, i Email odjela. Evo pregleda dostupnih pregleda:
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Prikaz liste:</Body1Strong> Zadana postavka koja uredno navodi sve odjele, pružajući
                  jasan, sažet pregled.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Prikaz velikih kartica:</Body1Strong> Prebacite se na ovaj pregled za detaljne informacijske kartice odjela, nudeći
                  prošireni sažetak ključnih informacija svakog odjela.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Prikaz organizacijskog dijagrama:</Body1Strong> Za vizualni prikaz strukture i
                  odnosa unutar odjela, prikaz organizacijskog dijagrama ilustrira kako se svaki odjel uklapa unutar šire hijerarhije organizacije.
                  </li>
                </ul>
                Za prebacivanje između ovih pregleda jednostavno koristite ikone pored okvira za pretraživanje na vrhu Direktorija odjela. Svaki pregled je dizajniran kako biste lako pristupili i razumjeli detalje odjela, osiguravajući da pronađete informacije koje trebate u formatu koji vam najbolje odgovara.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Kako koristiti okvir za pretraživanje u adresaru odjela
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Bez problema locirajte bilo koji odjel unutar TeamConnecta koristeći posvećeni okvir za pretraživanje smješten odmah pored ikona za pregled na vrhu 
                  Adresara odjela. Jednostavno počnite upisivati ime odjela koje tražite, i promatrajte kako se adresar prilagođava kako bi prikazao samo 
                  relevantne rezultate. Ova je funkcionalnost specifično dizajnirana kako biste izbjegli potrebu za ručnim pretraživanjem cijelog adresara, dovodeći 
                  odjel koji vam je potreban izravno na dohvat ruke. Zapamtite, ova funkcionalnost pretrage je jedinstvena za TeamConnectov Adresar odjela, stoga 
                  obavezno koristite ovaj okvir za pretraživanje umjesto glavne pretrage Microsoft Teamsa na vrhu sučelja Teamsa za upite specifične za odjel.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Kako funkcioniše abecedni filtar u adresaru odjela?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Učinkovito pretražite Adresar odjela koristeći abecedni filtar smješten na vrhu. Ova značajka omogućuje vam brzo sužavanje odjela odabirom slova. 
                  Kada odaberete slovo, TeamConnect filtrira i prikazuje samo one odjele čija imena počinju s vašim odabranim slovom, olakšavajući pronalazak onoga 
                  što tražite bez potrebe za pregledavanjem cijelog popisa. Da biste se vratili na cjelovit prikaz odjela, jednostavno deselektirajte istaknuto slovo. Ova intuitivna 
                  abecedna navigacija osigurava da učinkovito možete locirati bilo koji odjel po početnom slovu.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Kako mogu kontaktirati voditelja odjela?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Kontaktiranje voditelja odjela u TeamConnectu je jednostavno. Samo pronađite odjel koji vas zanima i kliknite na ime voditelja. Ova 
                  radnja će pokrenuti skočni prozor koji prikazuje opcije kontakta voditelja s poznatim ikonama za brzi pristup:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona chata:</Body1Strong> Za početak izravne poruke putem chata Microsoft Teamsa.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona e-pošte:</Body1Strong> Za otvaranje nove e-pošte adresirane njima u Microsoft Outlooku.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona video poziva:</Body1Strong> Za pokretanje video poziva putem Microsoft Teamsa.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona poziva:</Body1Strong> Za započinjanje glasovnog poziva koristeći Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikonica više (...):</Body1Strong> Za dodatne mogućnosti poput zakazivanja sastanka ili dodavanja 
                      njihovih kontaktnih informacija na vašu listu Teamsa.
                    </li>
                  </ul>
                  Ove ikone pružaju jednostavan način za direktno kontaktiranje s voditeljima odjela unutar TeamConnecta, osiguravajući učinkovitu komunikaciju unutar 
                  vaše organizacije.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Kako mogu pregledati ili kontaktirati zaposlenike unutar odjela?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Za istraživanje tko je tko unutar odjela u TeamConnectu, potražite tri točkice pokraj naziva odjela. Klikom na to otvorit će se opcije za daljnje akcije:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pregledajte zaposlenike u ovom odjelu:</Body1Strong> Odaberite ovo za prikaz popisa svih
                      zaposlenika povezanih s odjelom. Broj pored ove opcije pokazuje koliko zaposlenika ima u odjelu, pružajući brzi pregled.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organizacijski dijagram odjela:</Body1Strong> Ako želite razumjeti hijerarhiju odjela,
                      odaberite 'Organizacijski dijagram odjela' kako biste otkrili strukturalni raspored, prikazujući odnos između matičnih odjela, odjela o kojem je riječ,
                      i svih pododjela. Ova vizualna reprezentacija pomaže vam navigirati i razumjeti organizacijski okvir, olakšavajući identifikaciju i
                      kontaktiranje pojedinih članova odjela.
                    </li>
                    <li><Body1Strong>Odjel {'>'} Zaposlenik:</Body1Strong> Nakon pregleda zaposlenika unutar odjela, koristite ovu stazu za krušne mrvice da biste se lako
                      vratili na popis odjela, održavajući glatko iskustvo istraživanja unutar TeamConnecta.
                    </li>
                  </ul>
                  Ove značajke omogućuju vam ne samo da vidite popis zaposlenika u određenom odjelu, već i nude put do izravnog kontaktiranja s njima putem
                  komunikacijskih alata TeamConnecta.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Otkrivanje detaljnih informacija o odjelima u TeamConnectu
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Zaronite u detalje bilo kojeg odjela jednostavnim klikom na njegovo ime u TeamConnectu. Detaljan dijalog se otvara, pružajući bogatstvo
                  informacija koje slikaju potpunu sliku uloge i resursa odjela unutar vaše organizacije. Pronaći ćete sve, od vodstva i financijskih identifikatora do
                  komunikacijskih kanala i društvenih veza. Ova značajka je vaša prilika za:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Srce odjela – njegova svrha, ljudi i pokazatelji učinkovitosti.</li>
                    <li className={styles.bulletBottomMargin}>Kontaktne mogućnosti – izravne veze do ključnih figura odjela i komunikacijskih platformi.</li>
                    <li className={styles.bulletBottomMargin}>Organizacijska mreža – razumijevanje kako se odjel integrira s širom strukturom tvrtke.</li>
                  </ul>
                  Bilo da je riječ o brzom upitu ili temeljitom istraživanju podataka odjela, TeamConnect oprema vas alatima za povezivanje s i razumijevanje jedinstvenih
                  ekosustava unutar vaše organizacije.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Zanima vas zašto nije navedena adresa odjela u TeamConnectu?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Otkrivanje lokacije odjela ponekad se može činiti kao potraga za blagom bez karte. U TeamConnectu obično nećete naći odvojenu 
                  adresu za svaki odjel. Evo zašto ovaj kartografski detalj često izostaje:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Višestruke lokacije:</Body1Strong> Mnogi odjeli nisu ograničeni na jedan prostor; oni
                      prosperiraju u raznim uredima i geografijama.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Fluidna struktura:</Body1Strong> Organizacijski pejsaž je stalno u promjeni, s
                      odjelima koji evoluiraju, spajaju se ili čak raspuštaju u skladu sa strateškim promjenama.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Međuodjelna suradnja:</Body1Strong> Moderni radni prostori vrve suradničkim
                      duhom, često miješajući talente iz različitih odjela unutar zajedničkih prostora.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kultura rada na daljinu:</Body1Strong> S fleksibilnosti rada na daljinu, sidra odjela
                      često nisu postavljena u fizičkim uredskim prostorima.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pravne nijanse:</Body1Strong> Odjeli obično orbitiraju oko pravne strukture tvrtke
                      umjesto da imaju svoje vlastite samostalne adrese.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Operativno pojednostavljenje:</Body1Strong> Održavanje fokusa na dinamičnom timskom radu,
                      a ne na statičnim adresama, pomaže održati operativnu agilnost.
                    </li>
                  </ul>
                  Sve je u prihvaćanju pristupa bez granica kada je riječ o timskom radu i učinkovitosti. TeamConnect odražava tu filozofiju naglašavajući povezanost umjesto
                  lokacija.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Nedostaju li informacije o vašem odjelu ili su zastarjele?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Urednici odjela određeni su unutar vaše organizacije za dodavanje, ažuriranje ili ispravljanje specifičnih informacija o odjelu u TeamConnect.
                  Ako niste sigurni tko je urednik vašeg odjela ili ako određene informacije zahtijevaju intervenciju tima za podršku HR-a, 
                  molimo kontaktirajte <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> za upute.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Često postavljena pitanja o direktoriju ureda</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Uvod u direktorij ureda:</Body1Strong> Otkrijte okosnicu fizičke prisutnosti vaše organizacije putem direktorija ureda, 
                koji obuhvaća bitne informacije o uredima, operativne detalje i sveobuhvatan pregled odjela i osoblja koji definiraju svaku lokaciju.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Navigacija oznakama u direktoriju ureda
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Snalaženje u direktoriju ureda olakšano je našim sustavom oznaka dizajniranim za brzi pristup pravoj lokaciji. Evo kako svaka oznaka može pomoći u pojednostavljenju vašeg pretraživanja:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sve:</Body1Strong> Prikazuje potpuni popis ureda, pružajući pticu perspektivu 
                      lokacija vaše organizacije.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoriti:</Body1Strong> Pomaže vam usmjeriti se na one lokacije ureda koje često posjećujete ili na koje 
                      trebate pažljivo paziti, označavajući ih kao 'Favoriti'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vaš ured:</Body1Strong> Ova osobna oznaka filtrira direktorij tako da vam prikazuje 
                      lokaciju ureda na kojoj ste stacionirani, držeći vašu glavnu bazu na dohvat ruke.
                    </li>
                  </ul>
                  Bilo da rezervirate sobu za sastanke ili planirate posjet, ove oznake pružaju personalizirani i pojednostavljeni način interakcije s geografskim pejzažom vaše organizacije.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Usvajanje prikaza direktorija ureda u TeamConnectu
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Zaronite u organizacijski raspored različitih lokacija vaše tvrtke s pogledima na direktorij ureda TeamConnecta. Svaki je prikaz izrađen kako bi olakšao
                  jedinstveni aspekt navigacije i razumijevanja ureda:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Prikaz kao lista:</Body1Strong> Zadana postavka koja uredno organizira sve lokacije ureda u
                      lako čitljiv popis.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Prikaz velike kartice:</Body1Strong> Prikaz velikih kartica u direktoriju ureda obogaćuje vaš
                      pogled detaljnom prezentacijom svakog ureda. Kada se koristi kartica ureda, pronaći ćete obilje informacija i opcija za djelovanje
                      unutar izbornika "Više (...)":
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Prikaz odjela:</Body1Strong> Izravno navigirajte do popisa
                          svih odjela unutar ureda.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Prikaz zaposlenika:</Body1Strong> Pridobite potpuni popis zaposlenika dodijeljenih ureda,
                          olakšavajući izravan kontakt ili istraživanje osoblja ureda.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Org chart poslovne jedinice:</Body1Strong> Vizualizirajte mjesto ureda unutar šireg
                          organizacijskog konteksta za jasno razumijevanje hijerarhijskih struktura.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Bitni kontakti:</Body1Strong> Identificirajte ključne kontakte ureda za učinkovitu
                          komunikaciju.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Javni praznici ureda:</Body1Strong> Budite informirani o specifičnim javnim praznicima ureda
                          kako biste pravilno planirali svoje rasporede.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Radno vrijeme ureda:</Body1Strong> Pogledajte radno vrijeme ureda kako biste uskladili svoje
                          posjete ili pozive s njihovim aktivnim vremenima.
                        </li>
                      </ol>
                      Ovaj pogled pruža ne samo vizualni i informativni trenutak, već i brz pristup dubljim uvidima i izravnim radnjama vezanim za ured, sve uredno smješteno
                      pod opcijom "Više (...)" za vašu udobnost.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Prikaz organizacijskog dijagrama:</Body1Strong> Vizualizirajte hijerarhijsku strukturu matičnih i
                      podređenih ureda kako biste razumjeli međusobnu povezanost različitih lokacija ureda unutar vaše organizacije.
                    </li>
                  </ul>
                  Prebacujte se kroz ove prikaze koristeći ikone pokraj okvira za pretragu kako biste prilagodili svoje iskustvo pregledavanja vašim trenutnim potrebama.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Brzo pronalaženje ureda s funkcijom pretrage TeamConnecta
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Pronalaženje prave lokacije ureda unutar TeamConnecta olakšano je uz posvećenu funkciju pretrage. Smještena istaknuto pokraj ikona prikaza, okvir za pretragu
                  vaš je alat za precizno određivanje ureda po imenu. Dok tipkate, direktorij precizira prikaz kako bi prikazao samo urede koje odgovaraju
                  vašem unosu, olakšavajući vaš proces pretrage. Važno je koristiti ovaj okvir za pretragu unutar TeamConnecta za najtočnije i najrelevantnije rezultate,
                  za razliku od opće pretrage Microsoft Teamsa. S ovom ciljanom funkcionalnošću pretrage, možete brzo navigirati do bilo kojeg ureda u mreži
                  vaše organizacije.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Abecedno pretraživanje uredskog imenika
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ako preferirate pretraživanje po abecedi, naš abecedni filtar je brz način za pronalaženje ureda po imenu. Na vrhu Uredskog imenika vidjet ćete 
                  slova od A do Z. Klikom na slovo filtrirate prikaz tako da se prikažu samo oni uredi koji počinju s tim slovom, pojednostavljujući vaš proces 
                  pretraživanja. Da biste ponovno vidjeli sve urede, jednostavno odznačite aktivno slovo. Ova funkcija posebno je korisna kada poznajete 
                  ime ureda, ali vam je potreban brz način da ga locirate unutar TeamConnecta.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Otkrivanje sveobuhvatnih profila ureda u TeamConnectu
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Krenite na istraživačko putovanje kroz srce fizičke prisutnosti vaše organizacije s profilima ureda u TeamConnectu. 
                  Klikom na ime ureda otvara se živopisan dijalog profila, prepun kartica s detaljnim uvidima u ured:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pregled:</Body1Strong> Zaronite u srž identiteta ureda s detaljnim 
                      opisima, od poslovne strukture do radnog vremena i lokalnog vremena, hvatajući bit uredskog okruženja.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Bitni kontakti:</Body1Strong> Pristupite ključnim kontaktima za hitne službe i 
                      vanjsku podršku, svaki klikom dostupan kroz Microsoft Teams telefon sustav.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontakti ureda:</Body1Strong> Kontaktirajte interne usluge podrške s lakoćom, s 
                      detaljima za kontakt i mogućnostima izravnog angažiranja na dohvat ruke.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Javni praznici:</Body1Strong> Budite u toku s praznicima specifičnim za ured, osiguravajući 
                      da vaši planovi usklađuju s lokalnim običajima i radnim rasporedima.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Radno vrijeme:</Body1Strong> Planirajte svoje interakcije s preciznim znanjem kada 
                      ured vrvi aktivnošću ili se zatvara za dan.
                    </li>
                  </ul>
                  Bilo da je to živa energija u Austinu ili strateški čvorište u Singapuru, svaka kartica nudi prolaz za razumijevanje i povezivanje s različitim 
                  lokalitetima koji čine globalnu prisutnost vaše tvrtke.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Istraživanje dinamike odjela unutar ureda
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigacija mrežom profesionalaca odjela ureda odvija se s lakoćom u TeamConnectu. Evo kako zaroniti u detalje:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pregled odjela u ovom uredu:</Body1Strong> Otkrijte sve odjele koji čine
                      strukturu ureda odabirom ove opcije iz izbornika Više (...)
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organizacijski uvid:</Body1Strong> Kliknite na bilo koji odjel da otkrijete njegov organizacijski dijagram za
                      vizualni prikaz njegove strukture, s navigacijom preko staze mrvica kruha: Ured {'>'} Odjel {'>'} Dijagram.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Dubinsko istraživanje odjela:</Body1Strong> Dalje istražite odjel klikom na 
                      izbornik Više (...) pokraj imena odjela. Ovdje možete pristupiti popisu svih zaposlenika unutar tog odjela, izravno u kontekstu ureda.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Navigacija mrvicama kruha:</Body1Strong> Bez problema prelazite kroz razine. Od 
                      zaposlenika natrag do odjela ili s organizacijskog dijagrama, staza mrvica kruha (Ured {'>'} Odjel {'>'} Zaposlenik {'>'} Dijagram) čini vaše 
                      putovanje intuitivnim i na pravom putu.
                    </li>
                  </ul>
                  Složene staze TeamConnecta pozivaju vas da istražite, razumijete i angažirate se na svakoj razini odjela vašeg ureda, osnažujući vas
                  sveobuhvatnom navigacijskom kontrolom.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Povezivanje s osobljem ureda u TeamConnectu
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Zainteresirani za lica iza ureda? Odgovor je samo klik daleko u TeamConnectu. Angažirajte se s tri točkice pokraj izabranog
                  imena ureda kako biste otkrili niz interaktivnih opcija:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pregled zaposlenika u ovom uredu:</Body1Strong> Otkrijte popis svih stručnjaka 
                      koji ovaj ured smatraju svojim radnim domom. Broj prikazan nudi brzu procjenu zaposlenika, pozivajući vas da zaronite u zajednicu ureda.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Opcije kontakta:</Body1Strong> Svaki unos zaposlenika opremljen je ikonama za brzu akciju,
                      omogućujući vam da pokrenete razgovore, pozive ili čak video konferencije izravno iz TeamConnecta, potičući besprijekornu komunikaciju unutar ureda.
                    </li>
                    <li><Body1Strong>Navigacija:</Body1Strong> Da biste se vratili na širi popis ureda s popisa zaposlenika, jednostavno kliknite 'Ured' u tragu 
                      mrvica kruha (Ured {'>'} Zaposlenik), i bit ćete vraćeni na pregled ureda.
                    </li>
                  </ul>
                  S ovim značajkama, TeamConnect olakšava vizualizaciju raspodjele radne snage po uredima i omogućuje jednostavno povezivanje,
                  potičući povezanost radnog mjesta bez obzira na udaljenost.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Nedostaju li informacije o vašem uredu ili su zastarjele?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ažuriranje informacija o uredu u TeamConnect može se upravljati putem dodijeljenih urednika za urede. Ako niste sigurni tko su urednici ureda ili 
                  trebate pomoć s informacijama koje spadaju pod nadležnost HR-a, tim za podršku HR-u je spreman 
                  pomoći na <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Često postavljena pitanja o postavkama</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Važnost točnih općih postavki u TeamConnectu
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  U dinamičkom okruženju današnjeg radnog okruženja, ostati povezan i koordiniran je važnije nego ikad. Tu važnu ulogu igra ažuriranje vaših 
                  Općih postavki u TeamConnectu:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Odabir radnog mjesta:</Body1Strong> Uz savladačnosti vaše radne okoline ažuriranjem vašeg Radnog mjesta da odražava vašu trenutnu 
                        situaciju, bilo da je to promjena iz mira vašeg kućnog ureda u žamor glavnog ureda, ili čak preseljenje u javni prostor. Ta jasnoća o vašem 
                        radnom mjestu nudi kolegama uvid ne samo u najbolje načine za kontaktiranje s vama, već i u prirodu razgovora koje mogu pokretati s vama, 
                        štiteći povjerljivost osjetljivih razgovora posebno kada ste u otvorenom okruženju ureda klijenta ili sjedite u zračnoj luci.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Specifikacija radnog mjesta:</Body1Strong> Dijeljenje vašeg radnog grada čini više od povezivanja udaljenosti u postavi udaljenog tima—također 
                        otvara vrata spontanim profesionalnim okupljanjima ili povremenoj društvenoj interakciji. Bilo da je riječ o brzoj kavi, opuštenom ručku ili suradničkom 
                        sastanku, znanje da se nalazite u istom gradu može pretvoriti običan dan u priliku za značajne izravne kontakte. To ne samo da jača veze unutar tima, već 
                        također pruža bogatije razumijevanje lokalnih nijansi koje bi mogle utjecati na vaš rad.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Točnost vremenske zone:</Body1Strong> Kako timovi obuhvaćaju kontinente, osiguravanje da vaša vremenska zona odražava 
                      vaše trenutno mjesto je ključno za usklađivanje komunikacija. Lokalno vrijeme vašeg profila na TeamConnect izravno je utjecano ovom postavkom,
                      eliminirajući bilo kakvu nesigurnost za kolege koje pokušavaju stupiti u kontakt s vama.
                    </li>
                  </ul>
                  Zaronite u 'Postavke' {'>'} 'Opće postavke' unutar TeamConnecta kako biste fino podesili te detalje. Par trenutaka provedenih ažurirajući vaše postavke može 
                  znatno poboljšati koheziju i učinkovitost vaših suradničkih napora na svim razinama.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Dešifriranje odabira radnog mjesta u TeamConnectu
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Odabir pravog postavka u padajućem izborniku Radnog mjesta prilagođava TeamConnect kako bi točno odražavao vaš trenutni radni status. Pređite mišem preko ikone informacija za detaljno objašnjenje svake opcije:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Odjavljen/Pauza:</Body1Strong> Označava vrijeme izvan rada, bilo da je riječ o pauzi ili kraju dana.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ured/Teren-ured:</Body1Strong> Ukazuje na prisutnost u prostoru koji upravlja tvrtka.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> Rad iz posvećenog kućnog radnog prostora.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Javni prostor:</Body1Strong> Rad iz okruženja koje nije ured, što sugerira potrebu za fleksibilnom komunikacijom.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Radno mjesto klijenta/Privatni ured:</Body1Strong> Na lokaciji kod klijenta, s napomenom o razinama privatnosti za osjetljivost razgovora.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pendeliranje/Poslovno putovanje/Obuka:</Body1Strong> U pokretu ili angažiran u specifičnim radnim aktivnostima izvan ureda.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Javni praznik/Godišnji odmor/Razni dopusti:</Body1Strong> Službeno odsutan iz osobnih, zdravstvenih ili zbog praznika.</li>
                  </ul>
                  Odabir prikladnog radnog mjesta osigurava da vaš tim ima kontekst potreban za interakcije, poštujući dostupnost i privatnost.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Navigacija statusa 'Prijavljen' u TeamConnectu
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Oznaka 'Prijavljen' u Imeniku zaposlenika je dizajnirana da prikaže sve zaposlenike koji su trenutno na poslu. Ova vidljivost određuje se odabirom radnog mjesta:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Aktivna radna okruženja:</Body1Strong> Izbori Ured, Teren-ured, Home-Office, Remote-Workplace, Javni prostor, Radno mjesto klijenta, 
                    Privatni ured klijenta, Pendeliranje, Poslovno putovanje i Obuka signaliziraju da ste angažirani na poslu, čineći vas 'Prijavljenim' i vidljivo aktivnim kolegama.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Postavke koje nisu za rad:</Body1Strong> Odabir statusa Odjavljen, Pauza, Javni praznik, Godišnji odmor, Na dopustu, Porodiljni dopust, ili Bolovanje 
                      uklanja vas s popisa 'Prijavljenih', postavljajući jasna očekivanja za dostupnost i vrijeme odaziva.
                  </li>
                  </ul>
                  Ova funkcionalnost pomaže u razlikovanju radnog vremena od osobnog vremena, pridonoseći učinkovitoj suradnji i komunikaciji tima.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Upravljanje podacima i najčešća pitanja o privatnosti</Subtitle2>
          <Body1>
            <p>
              U današnjem digitalnom dobu, razumijevanje kako se vaši podaci upravljaju je ključno. TeamConnect je posvećen transparentnosti i robustnim praksama zaštite podataka.
              U nastavku ćete pronaći informacije o tome kako rukujemo, pohranjujemo i štitimo vaše podatke unutar TeamConnecta, osiguravajući da je vaš digitalni radni prostor siguran i usklađen.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Kako TeamConnect štiti moje osobne podatke?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect je posvećen zaštiti vaših osobnih informacija sveobuhvatnim pristupom sigurnosti podataka:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Jedinstvena Prijava (SSO):</Body1Strong> Da bi se pojačala sigurnost i pojednostavnilo korisničko iskustvo, TeamConnect integrira automatiziranu 
                      Jedinstvenu Prijava (SSO) s vašim računom Microsoft 365, omogućujući sigurno i jednostavno upravljanje pristupom.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Kriptiranje:</Body1Strong> Osiguravamo da su svi podaci unutar TeamConnecta kriptirani tijekom prijenosa i dok su u mirovanju,
                      čineći ih nedostupnima neovlaštenim stranama.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Minimizacija podataka:</Body1Strong> Naša prikupljanja podataka strogo je ograničena na ono što je bitno za funkcionalnost TeamConnecta.
                      Jedine korisničke informacije koje pohranjujemo su vaše Korisničko Ime Prijava (UPN) iz Azure Entra ID-a, što bi moglo nalikovati vašoj poslovnoj e-mail adresi.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Ograničen pristup:</Body1Strong> Pristup UPN-u ograničen je na administratora pretplate vaše organizacije unutar našeg portala za pretplate,
                      osiguravajući da osjetljive informacije budu vidljive samo ovlaštenim osobama.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Brzo brisanje podataka:</Body1Strong> TeamConnect je strukturiran tako da odmah briše pohranjeni UPN pod tri specifična uvjeta:
                      ako pretplatu otkaže administrator pretplate, ako korisnika ukloni ljudski resursi unutar TeamConnecta, ili ako pretplata TeamConnecta istekne. To osigurava da se vaši osobni podaci ne zadržavaju duže od njihovog korisnog života ili potrebe.
                    </li>                      
                  </ul>
                  <p>Kroz ove mjere, TeamConnect se obvezuje održavati najviše standarde zaštite podataka, osiguravajući da vaše osobne informacije ostanu sigurne i povjerljive.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Pitate se gdje se nalaze vaši podaci s TeamConnectom?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect prekida s uobičajenim modelom tradicionalnih SaaS usluga osiguravajući da vaši podaci nikada ne napuštaju vašu organizaciju. 
                  Za razliku od uobičajene prakse pohrane podataka na vanjskim oblak poslužiteljima, TeamConnect genijalno koristi vaš Microsoft 365 SharePoint Online 
                  za smještaj svih podataka aplikacije. Ovaj inovativni pristup ne samo da promiče suverenitet podataka već i poboljšava sigurnost, 
                  savršeno se usklađujući s našom čvrstom predanošću zaštiti vaše privatnosti i pridržavanju strogi standardi usklađenosti.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Hoće li moji podaci biti podijeljeni s bilo kojim trećim stranama?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ne, podaci se ne dijele s trećim stranama! Cubeet je nepokolebljiv u našoj predanosti privatnosti i sigurnosti vaših podataka. Ne dijelimo nikakve podatke TeamConnecta s trećim stranama bez dobivanja izričitog pristanka naših klijenata. 
                  U našem sigurnom okruženju pohranjujemo samo osnovne identifikatore, poput User Principal Name (UPN), naziva organizacije, naziva i ID-a stanara, unutar našeg portala za pretplate. 
                  Ovi su podaci ključni za pružanje usluge i štite se prema našim strogo politikama privatnosti.
                  Budite sigurni, naše prakse upravljanja podacima dizajnirane su kako bi osigurale da vaši podaci ostanu povjerljivi, s transparentnošću i kontrolom koja čvrsto ostaje u vašim rukama.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Kako se TeamConnect usklađuje s propisima o zaštiti podataka poput GDPR-a?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect usklađuje se s propisima o zaštiti podataka poput GDPR-a, HIPAA-e, CCPA-e i drugih koristeći se inherentnim funkcijama usklađenosti okruženja Microsoft 365, 
                  gdje su svi podaci klijenata sigurno pohranjeni. Za razliku od tradicionalnih SaaS modela, TeamConnect ne pohranjuje nikakve podatke klijenata na svojim poslužiteljima; umjesto toga, 
                  djeluje unutar Microsoft 365 okruženja vaše organizacije, pridržavajući se njegovih robustnih standarda sigurnosti i usklađenosti. Ovaj pristup osigurava da upravljanje vašim podacima ostane dosljedno 
                  s opsežnim okvirom usklađenosti koji pruža Microsoft 365, uključujući pridržavanje GDPR-a, HIPAA-e za informacije o zdravstvenoj zaštiti, CCPA-e za stanovnike Kalifornije, 
                  te drugih globalnih propisa o zaštiti podataka. Integrirajući se besprijekorno s vašim postojećim Microsoft 365 postavkama, TeamConnect omogućuje vašoj organizaciji da održava 
                  potpunu kontrolu nad svojim podacima, osiguravajući usklađenost s najstrožim zakonima i propisima o zaštiti podataka.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Kako TeamConnect rukuje podacima iz Microsoft Teams chata, poziva, video poziva, Outlook e-pošte i poziva putem telefonskog sustava?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect koristi Microsoft Graph API kako bi omogućio izravne interakcije poput chata, poziva, video poziva i Outlook e-pošte unutar Microsoft Teamsa,
                  pružajući besprijekorno korisničko iskustvo. Ključno je da, iako TeamConnect pokreće te komunikacije, to čini bez uplitanja u proces komunikacije
                  niti ima sposobnost snimanja ili pohrane bilo kojeg sadržaja interakcije. Svi razgovori, pozivi i e-pošte odvijaju se i upravljaju izravno unutar
                  sigurnih granica Microsoft Teamsa i Outlooka, strogo se pridržavajući Microsoftovih robustnih sigurnosnih i privatnih standarda 365. To osigurava da TeamConnect poboljšava
                  vaše komunikacijske sposobnosti dok u potpunosti čuva povjerljivost i integritet vaših podataka.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Za detaljne uvide u naše politike i prakse o podacima, možda biste također željeli konzultirati {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>Vodič za korisnike TeamConnecta</Body1Strong></Link> ili IT odjel vaše organizacije na <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> za pojedinosti vezane uz vašu konfiguraciju.
          </p>
        </Body1>        
        <p></p>  
        <Subtitle1 className={styles.fontColor}>Slušamo vas: Podijelite svoje povratne informacije i ideje</Subtitle1>
        <Body1>
          <p>
            Vaši uvidi pokreću evoluciju TeamConnecta. Bilo da prekipevate od sjajne ideje za značajku ili imate povratne informacije o trenutnom iskustvu, tu smo da vas saslušamo. Vaš je doprinos neprocjenjiv u našem kontinuiranom nastojanju da pružimo
            najbolju moguću uslugu.
          </p>
        </Body1>      
        <Subtitle2>Povratne informacije i prijedlozi za značajke</Subtitle2>
        <Body1>
          <p>
            Spremni za dijeljenje? Posjetite našu {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Stranicu za povratne informacije
            </Link>
            . Bilo da se radi o prijedlogu za novu značajku ili povratnim informacijama o postojećim funkcionalnostima, jednostavno odaberite 
            odgovarajuću opciju iz padajućeg izbornika u obrascu. Vaši doprinosi pomažu oblikovati budućnost TeamConnecta, 
            osiguravajući da ispunjava i nadmašuje vaše potrebe.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
