import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>Qendra e Ndihmës dhe FAQ-të TeamConnect</Title3>
        <p><Body1>
          Mirësevini në Qendrën e Ndihmës së TeamConnect, e projektuar për të mbështetur përvojën tuaj me aplikacionin tonë gjithëpërfshirës të drejtorisë korporative. Këtu do të gjeni burime për të optimizuar përdorimin tuaj të TeamConnect brenda Microsoft Teams, duke përmirësuar komunikimin në rrjetin tuaj.
        </Body1></p>
        <Subtitle2>Udhëzues i Shpejtë</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Kapacitete të Përmirësuara të Kërkimit: </Body1Strong>
              Gjeni kolegët, departamentet dhe kontaktet thelbësore shpejt me mjete të fuqishme kërkimi.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Grafikë Organizative Vizuale: </Body1Strong>
              Navigoni hierarkinë e organizatës suaj dhe kuptoni linjat e raportimit me lehtësi përmes grafikëve organizative intuitive.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Mjete Adaptuese për Nevojat e Sotme: </Body1Strong>
              Përdorni funksionalitete moderne të përshtatura për kërkesat e vendeve të punës bashkëkohore.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Optimizimi i Përvojës Tuaj</Subtitle2>
        <Body1>
          <p>
            TeamConnect revolucionarizon mënyrën se si ndërveproni brenda strukturës organizative tuaj, duke kthyer komunikimet e përditshme në angazhime strategjike.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Merrni Ndihmë & FAQ-të</Subtitle1>
        <Body1>
          <p>
            Gjeni përgjigje për pyetjet më të zakonshme rreth karakteristikave dhe cilësimeve të TeamConnect në këtë seksion të dedikuar. Pavarësisht nëse po navigoni në aplikacion, po personalizoni cilësimet, ose kërkoni zgjidhje për sfidat, Qendra jonë e Ndihmës është këtu për t'ju ndihmuar me efikasitet.
          </p>
        </Body1>
        <Body1>
          <p>
            Për mbështetje më të thellë ose udhëzime specifike, ju lutemi konsultoni <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>Udhëzuesin e Përdoruesit të TeamConnect</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>        
        <Subtitle2>Përdorimi i Pyetjeve të Shpeshta të TeamConnect</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Nuk e gjeni dot TeamConnect në shiritin anësor të Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nëse TeamConnect nuk është menjëherë i dukshëm ose ka zhdukur në shiritin anësor të Microsoft Teams, mund ta ngjisni lehtësisht për qasje të shpejtë. Vetëm ndiqni këto hapa të thjeshtë:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Hapni Microsoft Teams dhe klikoni mbi ikonën '...' (+ Aplikacionet) në fund të shiritit anësor.</li>
                    <li className={styles.bulletBottomMargin}>Kërkoni për 'TeamConnect' në shiritin e kërkimit në pjesën e sipërme të dritares që shfaqet.</li>
                    <li className={styles.bulletBottomMargin}>Klikoni me të djathtën mbi aplikacionin TeamConnect nga rezultatet e kërkimit dhe zgjidhni 'Ngjit'.</li>
                  </ol>
                  Tani TeamConnect do të jetë i ngjitur në shiritin tuaj anësor, duke bërë që të jetë i aksesueshëm me vetëm një klikim!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Po përballeni me probleme teknike me TeamConnect? Ja si të merrni ndihmë.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nëse hasni ndonjë problem teknik me TeamConnect brenda Microsoft Teams, ekipi i Mbështetjes IT të organizatës suaj është këtu për të ndihmuar.
                  Kontaktoni me ta në <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, dhe ata do t'ju ndihmojnë me shpejtësi.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Nuk i shihni detajet tuaja të punonjësit?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nëse profili juaj duket pak i zbrazët me vetëm emrin dhe emailin tuaj të dukshëm, është e mundshme që ekipi juaj i Burimeve Njerëzore ende nuk ka shtuar pjesën tjetër të detajeve tuaja të punonjësit në TeamConnect.
                  S'ka pse të shqetësoheni! Një mesazh i shpejtë për ekipin tuaj të mbështetjes së HR do të vërë gjërat në lëvizje. Ata janë në gatishmëri për të plotësuar profilin tuaj me të gjitha detajet e rëndësishme.
                  Kontaktoni në <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> dhe shikoni si profili juaj merr jetë!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Cilat opsione gjuhësore janë të disponueshme në TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect mirëpret diversitetin global të Microsoft Teams duke
                  mbështetur të gjitha gjuhët e tij. Qoftë që preferoni arabikën ose
                  greqishten, japonishten ose suedishten, keni një botë opsionesh në gishtat
                  tuaj, përfshirë:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arabisht, Bullgarisht, Kinezisht (i Thjeshtuar dhe Tradicional),
                      Kroatisht, Çekisht, Danisht, Holandisht, Anglisht, Estonisht,
                      Finlandisht, Frengjisht, Gjermanisht, Greqisht, Hebraisht, Hungarisht,
                      Indonesisht, Italisht, Japonisht, Koreanisht, Letonisht, Lituanisht,
                      Norvegjisht (Bokmal), Polonisht, Portugalisht (Brazil dhe Portugal),
                      Rumanisht, Rusisht, Serbisht (Latin), Sllovakisht, Sllovenisht,
                      Spanjisht, Suedisht, Tajlandisht, Turkisht, Ukrainisht, Vietnamisht.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Për të personalizuar përvojën tuaj në TeamConnect në gjuhën tuaj të
                  preferuar, ju lutemi rregulloni cilësimet e gjuhës direkt në Microsoft
                  Teams, pasi TeamConnect integrohet plotësisht me këto cilësime:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Shkoni te cilësimet e Microsoft Teams duke klikuar mbi tre pikat pranë
                      fotos suaj të profilit në krye, dhe pastaj zgjidhni 'Pamja dhe qasja
                      në përdorueshmëri'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Shkoni te sektori 'Gjuha' dhe zgjidhni gjuhën tuaj të preferuar nga
                      menuja zbritëse.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Konfirmoni ndryshimet tuaja duke klikuar butonin 'Ruaj dhe rinis' për
                      të aplikuar cilësimet e reja të gjuhës.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Shënim: </Body1Strong>
                <Body1>
                  Ndryshimet e bëra në cilësimet e gjuhës në Microsoft Teams do të
                  përditësojnë gjithashtu përvojën tuaj në TeamConnect pa probleme. Jeni të
                  vetëdijshëm se ndryshimi i gjuhës në Teams mund të ndryshojë gjithashtu
                  formatin tuaj të kohës dhe datës. Për momentin, TeamConnect është në
                  zhvillim për të mbështetur shkrimin nga e djathta në të majtë. Jemi të
                  entuziazmuar për këto përmirësime dhe do t'ju mbajmë të informuar ndërsa
                  bëhen të disponueshme.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Si mund të ndryshoj temën në TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect sinkronizohet me preferencat tuaja të temës së Microsoft Teams, duke përfshirë opsionet e Ndritshme, të Errëta dhe të Kontrast të Lartë. Për të personalizuar temën tuaj:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Qasuni te cilësimet tuaja të Microsoft Teams duke klikuar mbi tre pikat pranë fotos suaj të profilit në krye.</li>
                    <li className={styles.bulletBottomMargin}>Zgjidhni 'Pamja dhe aksesueshmëria' për të eksploruar opsionet e temës.</li>
                    <li className={styles.bulletBottomMargin}>Zgjidhni temën tuaj të preferuar nga opsionet e disponueshme për të përditësuar menjëherë ndërfaqen tuaj të TeamConnect për t'u përputhur.</li>
                  </ol>
                  Zgjedhja juaj e temës në Microsoft Teams do të aplikohet automatikisht në TeamConnect, duke siguruar një përvojë vizuale të konsistentë në hapësirën tuaj të punës.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Eksplorimi i Butonave të Veprimit të Kontaktit të TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Gjatë gjithë TeamConnect, ku gjenden detajet e kontaktit të një punonjësi, do të zbuloni gjithashtu butonat tanë të përshtatshëm për qasje të shpejtë. Këto mjete të përshtatshme janë projektuar për të përshpejtuar komunikimin tuaj brenda ekosistemit të Microsoft, duke siguruar që jeni gjithmonë vetëm një klikim larg nga lidhja. Ja udhëzuesi juaj i plotë për të përdorur këto butona efektivisht:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona e Bisedës:</Body1Strong> Klikoni për të filluar një bisedë të menjëhershme në Microsoft Teams. Nëse duhet të ktheheni në TeamConnect pas fillimit të një bisede, thjesht përdorni shigjetën e kthimit në Desktop App, butonin e kthimit në Mobile App, ose butonin e kthimit të shfletuesit tuaj.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona e Emailit:</Body1Strong> Hap një skicë të re emaili në Outlook me adresën e marrësit gati në fushën "To:". Nëse Outlook nuk hapet, vlen të kontrolloni me departamentin tuaj të IT për ndonjë kufizim.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona e Thirrjes Video:</Body1Strong> Nis një thirrje video ballë për ballë duke përdorur Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona e Thirrjes:</Body1Strong> Fillon një thirrje zanore në Microsoft Teams, duke bërë diskutimet e shpejta të pastra.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona e Hartës Organizative:</Body1Strong> Zbulon vendin e punonjësit në hierarkinë organizative, duke përfshirë menaxherin dhe raportet direkte.</li>
                    <li><Body1Strong>Më shumë (...) Ikona:</Body1Strong> Zbulon opsione të mëtejshme:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Takim Tani:</Body1Strong> Hap Microsoft Teams për të filluar menjëherë një takim me punonjësin.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Takim i Ri:</Body1Strong> Ju drejton në Microsoft Teams për të caktuar një takim të ri.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Shto Kontaktin:</Body1Strong> Shton detajet e kontaktit të punonjësit nga TeamConnect në aplikacionin e ri të Njerëzve të Microsoft Teams, duke pasuruar funksionin tuaj të thirrjes.</li>
                      </ul>
                    </li>
                  </ul>
                  Këto butona intuitive janë krijuar për të siguruar që ju të mund të lidheni në mënyrë efikase me kolegët, qoftë për një bisedë të shpejtë, një email, ose për të caktuar takime të ardhshme.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Bërja e thirrjeve përmes TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Dëshironi të bëni një thirrje? TeamConnect rrjedhëzon komunikimin tuaj duke u integruar me Microsoft Teams. 
                  Thjesht klikoni mbi numrin e telefonit të dëshiruar në TeamConnect, dhe ja, ndërfaqja e thirrjeve të Microsoft Teams shfaqet,
                  gati për ju që të bëni thirrjen pa probleme.
                  <p>
                  <Body1Strong>Shënim: </Body1Strong> 
                  Po hasni probleme me bërjen e thirrjeve? Kjo mund të jetë për shkak të politikave të IT të organizatës suaj ose 
                  përdorimit të një sistemi telefonik tjetër përveç Microsoft Teams Phone System. Kontrollimi me departamentin tuaj të IT në <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, mund të hedhë dritë mbi çështjen.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. A ka shpenzime që lidhen me bërjen e thirrjeve përmes TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect përmirëson përvojën tuaj të thirrjes duke përdorur Microsoft Teams. Ja çfarë duhet të dini për kostot e lidhura me thirrjet:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Për thirrjet drejt telefonave të punës së punonjësve, telefonave të departamenteve, ose linjave të nxehta brenda organizatës tuaj (numra të brendshëm),
                      zakonisht <Body1Strong>nuk ka shpenzime</Body1Strong> kur përdoret Sistemi i Telefonit të Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Për thirrjet e jashtme, si ato drejt telefonave celularë ose linjave të jashtme, <Body1Strong>tarifat standarde</Body1Strong> të sistemit telefonik të organizatës suaj zbatohen.
                      Këto tarifa ndryshojnë bazuar në planin tuaj specifik të shërbimit me Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Shënim:</Body1Strong> Nëse hasni ndonjë problem ose keni pyetje rreth tarifave të thirrjeve, është më mirë të konsultoheni me
                    departamentin tuaj të IT në <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> për informacion të detajuar 
                    i përshtatur për strukturën dhe politikat e organizatës suaj.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Vëreni një mos përputhje me statusin tuaj të pranisë 'Jashtë Zyre' në TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Vërejtët që statusi juaj i pranisë 'Jashtë Zyre' nga Microsoft 365 nuk po reflektohet siç duhet në TeamConnect?
                  Ne ju kemi mbuluar. Veçoria për sinkronizimin e statusit të pranisë 'Jashtë Zyre' tashmë është zbatuar në TeamConnect.
                  Aktualisht po presim që Microsoft të lëshojë këtë veçori. Sap të ndodhë kjo, statusi juaj i pranisë 'Jashtë Zyre'
                  do të pasqyrohet saktësisht në TeamConnect, duke u përputhur me treguesit e pranisë të Microsoft Teams për një përvojë të pandërprerë.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Pyetje të bëra shpesh të Drejtorisë së Punonjësve</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >Hyrje në Drejtorinë e Punonjësve:</Body1Strong> Navigoni peizazhin profesional të organizatës suaj me Drejtorinë e Punonjësve,
                qendra juaj për informacion të detajuar të punonjësve, dinamikat e vendit të punës, dhe lidhshmëria direkte me profilet dhe marrëdhëniet menaxheriale të kolegëve tuaj.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Keni nevojë për të shtuar, azhurnuar, ose korrigjuar të dhënat tuaja të punonjësve?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Për shtimin, azhurnimin, ose korrigjimin e të dhënave tuaja të punonjësve në TeamConnect, ju lutemi të kontaktoni me ekipin e mbështetjes së HR të organizatës suaj.
                  Ata janë të gatshëm të ndihmojnë në <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Çfarë ndodh kur klikoj në emrin e një punonjësi në drejtori?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Klikimi në emrin e një punonjësi në Drejtorinë e Punonjësve të TeamConnect hap një faqe të detajuar të profilit për atë individ. Ky profil ofron një pasqyrë të gjithanshme 
                  të të dhënave rreth punonjësit, duke përfshirë por jo kufizuar në, titullin e punës, departamentin, vendndodhjen e zyrës, informacionin e kontaktit dhe më shumë. Është projektuar për të ju dhënë 
                  një pasqyrë të plotë të rolit profesional të punonjësit dhe si ky i fundit përshtatet në organizatë, duke e bërë më të lehtë për ju të kuptoni përgjegjësitë e tyre dhe 
                  si mund të bashkëpunoni me ta.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Çfarë informacioni përfshihet në profilin e një punonjësi?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Çdo profil i punonjësit në TeamConnect është një pamje e gjithanshme, që ofron një sërë të dhënash të detajuara rreth jetës profesionale të individit. 
                  Ja çfarë do të gjeni:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Detajet bazë:</Body1Strong> Emri, titulli i punës, departamenti dhe vendndodhja e degës.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Struktura e Raportimit:</Body1Strong> Informacion mbi raportet direkte dhe funksionale, 
                      duke përfshirë menaxherët në linjë dhe funksional.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Statusi në Vendin e Punës:</Body1Strong> Statusi aktual i punës si në Pushim, në Udhëtim, 
                      Udhëtim Biznesi, etj.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vendi i Punës & Koha Lokale:</Body1Strong> Vendndodhja aktuale e punës dhe 
                      zona kohore lokale.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Detajet e Kontaktit:</Body1Strong> Telefoni i punës, numri i celularit dhe adresa e emailit.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Adresa e Zyrës:</Body1Strong> Vendndodhja e plotë e zyrës duke përfshirë emrin e ndërtesës,
                      adresën dhe hartën e Google.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Për:</Body1Strong> Një hyrje personale e shkurtër ose biografi profesionale.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Marrëdhëniet e Punonjësve:</Body1Strong> Detajet e strukturës së raportimit të punonjësit dhe marrëdhëniet e ekipit.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lidhjet Sociale & Gjuhët:</Body1Strong> Lidhjet me profilet profesionale sociale 
                      dhe aftësitë gjuhësore.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Aftësitë:</Body1Strong> Një listë e aftësive profesionale dhe zonave të ekspertizës.</li>
                  </ul>
                  Për më tepër, profili ofron opsione të veprueshme për të lidhur përmes bisedës, emailit, video thirrjes ose planifikimit të takimeve, së bashku me 
                  qasje të shpejtë në grafikun e organizatës për një kontekst më të gjerë të ekipit.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Si mund të përditësoj informacionin e profilimit tim?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Personalizimi i profilit tuaj është i thjeshtë në TeamConnect. Ja si mund ta bëni:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Shkoni te skeda <Body1Strong>Employee</Body1Strong> dhe klikoni mbi <Body1Strong>Avatar</Body1Strong> për të hapur profilin tuaj.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Në cepin e sipërm të majtë, do të gjeni lidhjen <Body1Strong>"Edit your profile"</Body1Strong>. 
                        Duke klikuar këtu do të hapet dialogu i redaktimit për profilin tuaj.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Këtu, mund të përmirësoni profilin tuaj duke shtuar ose përditësuar seksionin tuaj <Body1Strong>About You</Body1Strong>, lidhjet e rrjeteve sociale, dhe numrin e telefonit. 
                        Kjo e bën më të lehtë për kolegët tuaj të lidhen ose t'ju ndjekin.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Mos harroni të shtypni butonin <Body1Strong>Save</Body1Strong> për të aplikuar ndryshimet tuaja.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Zbrisni në dialog për të shtuar <Body1Strong>Languages</Body1Strong> dhe <Body1Strong>Proficiency</Body1Strong> tuajat, 
                        si dhe <Body1Strong>Skills</Body1Strong>. Ndajtja e kësaj informacioni ndihmon kolegët tuaj të kuptojnë
                        fushat tuaja të ekspertizës dhe gjuhët në të cilat mund të komunikoni.
                      </li>
                    </ol>
                  Përditësimi i profilit tuaj jo vetëm që mban informacionin tuaj aktual, por gjithashtu nxit lidhje më të mira brenda organizatës suaj.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Përditësimi i vendit tuaj të punës, vendndodhjes dhe zonës kohore në TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Mbajtja e informacionit tuaj të vendit të punës, vendndodhjes dhe zonës kohore të përditësuar është jetike për bashkëpunim të paanshëm në Microsoft Teams. 
                  Këto përditësime pasqyrohen menjëherë në profilin dhe listimet tuaja në TeamConnect, duke u mundësuar kolegëve të kuptojnë menjëherë vendndodhjen e punës tuaj 
                  dhe kohët më të mira për të lidhur me ju, duke përmirësuar bashkëpunimin ndërmjet rajoneve dhe zonave kohore të ndryshme.
                  <p></p>
                  Gati për të përditësuar detajet tuaja? Është e thjeshtë në TeamConnect! Shkoni te 'Settings' në Menynë e sipërme dhe navigoni te 'General Settings'. 
                  Këtu, mund të përditësoni lehtësisht Vendin e punës dhe Zonën kohore, dhe të specifikoni Vendndodhjen tuaj të punës për ditën. Mos harroni të ruani ndryshimet tuaja për 
                  të mbajtur të gjithë të informuar dhe për të siguruar bashkëpunim efektiv, pavarësisht nga vendndodhja juaj!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Vërejtët diçka të gabuar në profilin e një kolegu?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nëse vini re ndonjë detaj në profilin e një kolegu që nuk duket i saktë, hapi më i mirë i parë është të bëni një njoftim miqësor. Një bisedë e shpejtë ose një email duhet të mjaftojë! Nëse për ndonjë arsye informacioni nuk përditësohet, mos u shqetësoni—thjesht kontaktoni ekipin tuaj të mbështetjes së HR në <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, dhe ata do të jenë të gatshëm të intervenojnë dhe të rregullojnë gjërat.
                </Body1>
              </AccordionPanel>
            </AccordionItem>           
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Kuptimi i Grupeve të Etiketave: Të gjithë, Favoritët, Të Regjistruarit & Punonjës të Rinj
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Të gjithë:</Body1Strong> Ky etiketë e parazgjedhur shfaq çdo punonjës në organizatën tuaj, 
                      duke ofruar një pamje gjithëpërfshirëse të ekipit.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoritët:</Body1Strong> Kliko këtu për të filtruar pamjen tuaj te ata kolegë që i keni shënuar 
                      si 'Favoritë', duke e bërë më të lehtë të lidheni shpejt me kontaktet kryesore.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Të Regjistruarit:</Body1Strong> Ky etiketë tregon punonjësit që janë aktivisht në punë, duke i dalluar 
                      nga të qenit thjesht online në Microsoft Teams. Kur janë regjistruar, punonjësit sinjalizojnë se janë në punë dhe ndajnë vendndodhjen e tyre aktuale të punës 
                      gjatë ditës. Kjo mund të përfshijë një varietet vendesh pune si Zyrë, Zyra-Fushore, Zyra në Shtëpi, Vendi i Punës në Largësi, Vendi i Punës te Klienti, 
                      Zyra Private e Klientit, Zona Publike, Udhetim, Udhëtim Biznesi, ose edhe Trajnime. Prandaj, statusi 'Të Regjistruarit' ofron një tregues të qartë 
                      të qenies aktivisht në punë, duke ofruar kolegëve një kuptim të saktë të kontekstit aktual të punës dhe disponueshmërisë së njëri-tjetrit.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Punonjës të Rinj:</Body1Strong> Vështroni fytyrat e reja në TeamConnect me këtë etiketë, duke theksuar 
                      punonjësit që janë shtuar në aplikacion brenda 30 ditëve të fundit. Zakonisht, kjo përputhet me shtimet e fundit në organizatën tuaj, duke 
                      ju ndihmuar të mirëprisni anëtarët e rinj të ekipit.
                    </li>
                  </ul>
                  Këto grupe etiketash janë projektuar për të lehtësuar navigimin dhe për të përmirësuar përvojën tuaj në TeamConnect, duke siguruar që ju gjithmonë 
                  mund të gjeni dhe të lidheni me kolegët tuaj në mënyrë efikase.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Dekodimi i Ikonave Përballë Kutisë së Kërkimit
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Drejtoria e Punonjësve e TeamConnect është dizajnuar për lëvizshmëri dhe lehtësi, me katër ikona që ofrojnë pamje të ndryshme 
                  dhe qasje të shpejtë në detaje kyçe. Ja çfarë zbulon secila ikonë:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pamja e Listës:</Body1Strong> Pamja juaj kryesore, e cila prezanton të gjithë anëtarët e organizatës 
                      në një listë të thjeshtë.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pamja e Kartës së Vogël:</Body1Strong> Një alternativë kompakte, kjo pamje transformon 
                      listën në karta të vogla të punonjësve.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pamja e Kartës së Madhe:</Body1Strong> Zgjidhni për më shumë detaje me karta më të mëdha, që përfshijnë 
                      zgjedhje shtesë kontakti si numrat e telefonit dhe celular për thirrje të lehta.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Grafiku Organizativ:</Body1Strong> Vizualizoni strukturën e kompanisë me pamjen e Grafikut Organizativ, 
                      perfekte për të kuptuar marrëdhëniet e raportimit dhe për të naviguar në peizazhin organizativ.</li>
                  </ol>
                    Ndërroni midis këtyre ikonave për të gjetur pamjen që më së miri përshtatet me nevojat tuaja në atë moment, qoftë në kërkim të informacionit të shpejtë ose të thellë.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Për kërkime të shpejta të kolegëve, ku duhet të shikoj?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Po kërkoni një koleg? Thjesht shkoni te kutia e kërkimit të TeamConnect, e vendosur në anën e djathtë lart pranë ikonës së Grafikut Organizativ. 
                  Filloni të shkruani emrin e tyre, dhe shikoni si rezultatet përmirësohen me çdo shkronjë. Kujtoni, për kërkime në TeamConnect, anashkaloni shiritin e kërkimit të Microsoft Teams 
                  në pjesën e sipërme—kutia jonë speciale e kërkimit është rruga për të vazhduar!
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Si funksionon kërkimi alfabetik për gjetjen e punonjësve?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Drejtoria e Punonjësve e TeamConnect përfshin një opsion të përshtatshëm të kërkimit alfabetik për t'ju ndihmuar të gjeni shpejt punonjësit sipas emrit të tyre të parë.
                  Thjesht klikoni mbi një shkronjë nga alfabeti i shfaqur në krye të drejtorisë, dhe TeamConnect menjëherë do t'ju tregojë të gjithë punonjësit
                  që emrat e tyre të parë fillojnë me atë shkronjë. Për të kthyer në listën e plotë të punonjësve, thjesht hiqni zgjedhjen e shkronjës së theksuar. Kjo veçori intuitive
                  e bën të lehtë lundrimin nëpër listat e mëdha të punonjësve, duke u siguruar që të gjeni kolegët shpejt dhe efikasht.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Si mund të rregulloj numrin e punonjësve të shfaqur për faqe?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect ju lejon të personalizoni sa profile të punonjësve shihni për çdo faqe, duke e bërë më të lehtë për të lundruar nëpër Drejtorinë e Punonjësve.
                  Në fund të çdo faqe, do të gjeni opsione për numërimin e faqeve: 10, 50, 100, 500, 1000, dhe 5000. Konfigurimi i parazgjedhur tregon 10 profile për faqe.
                  Për të parë më shumë profile në një faqe të vetme, thjesht zgjidhni numrin tuaj të preferuar nga këto opsione. Kjo veçori ofron fleksibilitet në mënyrën se si shfletoni
                  drejtorinë, duke ju mundësuar të përshtatni shfaqjen sipas nevojave ose preferencave tuaja specifike.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Si të rendis informacionin e punonjësve në Pamjen Listë?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Në Pamjen e Listës së Drejtorisë së Punonjësve të TeamConnect, keni fleksibilitetin për të renditur informacionin e punonjësve sipas nevojave tuaja. Kolonat—Emri i Punonjësit, 
                  Titulli i Punës, Departamenti, Vendndodhja e Zyrës, dhe Vendi i Punës—janë të gjitha të renditshme. Thjesht klikoni mbi titullin e kolonës që dëshironi të renditni. Një klik
                  do ta rendit kolonën në rendin rritës, dhe një klik tjetër do ta rendit atë në rendin zbritës. Nga parazgjedhja, drejtoria është renditur sipas Emrit të Punonjësit në rendin rritës,
                  bazuar në emrin e parë. Ky veçori e renditjes ju ndihmon të organizoni shpejt dhe të shihni detajet e punonjësve në një mënyrë që ju shërben më së miri qëllimit tuaj, pavarësisht nëse
                  po kërkoni dikë specifik ose duhet të gruponi punonjësit sipas një kriteri të caktuar si departamenti ose vendndodhja.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Pyetjet e shpeshta të Drejtorisë së Departamenteve</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Hyrje në Drejtorinë e Departamenteve:</Body1Strong> Drejtoria e Departamenteve është porta juaj për të kuptuar rrjetën komplekse të 
              strukturës së organizatës suaj, duke ofruar një pamje të qartë të hierarkive departamentale, udhëheqjes menaxheriale dhe rrugëve për komunikim të paanshëm departamental.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Si funksionojnë etiketat 'Të Gjithë', 'Të Preferuarat' dhe 'Departamenti Juaj' në Drejtorinë e Departamenteve?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Në Drejtorinë e Departamenteve të TeamConnect, etiketat ndihmojnë për të naviguar dhe filtruar shpejt informacionin departamental. Ja çfarë përfaqëson secila etiketë:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Të Gjithë:</Body1Strong> Kjo etiketë shfaq të gjithë departamentet brenda organizatës suaj, duke ofruar një 
                      përmbledhje gjithëpërfshirëse.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Të Preferuarat:</Body1Strong> Përdorni këtë etiketë për të parë një listë të përzgjedhur të departamenteve që keni shënuar 
                      si 'Të Preferuarat', e cila e bën më të lehtë qasjen në departamentet me të cilat ndërveproni shpesh.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Departamenti Juaj:</Body1Strong> Kjo etiketë filtrohet për të shfaqur vetëm departamentin tuaj, 
                      duke lejuar qasje të shpejtë në informacionin dhe përditësimet e menjëhershme departamentale.</li>
                  </ul>
                  Këto etiketa janë dizajnuar për të lehtësuar navigimin tuaj brenda Drejtorisë së Departamenteve, duke rritur aftësinë tuaj për të gjetur dhe angazhuar me efikasitet informacionin departamental.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Kuptimi i Pamjeve të Drejtorisë së Departamenteve në TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigimi nëpër Drejtorinë e Departamenteve në TeamConnect është i lehtësuar me opsione të shumta të shikimit për të përshtatur preferencat tuaja. Çdo pamje ofron një 
                  mënyrë unike për të eksploruar informacionin gjithëpërfshirës departamental, duke përfshirë Emrin e Departamentit, Menaxherin e Departamentit, Departamentin Mëmë, Telefonin e Departamentit, 
                  Linjën Direkte të Departamentit dhe Emailin e Departamentit. Ja një paraqitje e pamjeve të disponueshme:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pamja me Listë:</Body1Strong> Konfigurimi fillestar që rendit në mënyrë të pastër të gjithë departamentet, duke ofruar një 
                      përmbledhje të qartë dhe të përcaktuar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pamja me Karta të Mëdha:</Body1Strong> Kaloni në këtë pamje për të parë kartat e detajuara të departamenteve, që ofrojnë 
                      një përmbledhje të zgjeruar të informacionit kryesor të secilit departament.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pamja e Organogramës:</Body1Strong> Për një përfaqësim vizual të strukturës dhe marrëdhënieve departamentale, pamja e Organogramës 
                      ilustron si çdo departament i përshtatet brenda hierarkisë më të gjerë organizative.
                    </li>
                  </ul>
                  Për të ndërruar ndërmjet këtyre pamjeve, thjesht përdorni ikonat pranë kutisë së kërkimit në krye të Drejtorisë së Departamenteve. Çdo pamje është dizajnuar për t'ju ndihmuar që të 
                  keni qasje dhe të kuptoni detajet departamentale me lehtësi, duke siguruar që gjeni informacionin që ju nevojitet në formatin që ju përshtatet më së miri.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Si të përdorni kutinë e kërkimit në Drejtorinë e Departamenteve
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Gjeni me lehtësi çdo departament brenda TeamConnect duke përdorur kutinë e kërkimit të dedikuar që ndodhet menjëherë pranë ikonave të shikimit në krye të
                  Drejtorisë së Departamenteve. Thjesht filloni të shkruani emrin e departamentit që po kërkoni, dhe vëzhgoni si drejtoria përshtatet vetë për të shfaqur vetëm
                  ndeshjet përkatëse. Kjo veçori është projektuar posaçërisht për të ju ndihmuar të shmangni nevojën për të kërkuar manualisht nëpër të gjithë drejtorinë, duke
                  sjellë departamentin që ju nevojitet drejtpërdrejt në gishtat tuaj. Mbani mend, kjo funksionalitet kërkimi është unike për Drejtorinë e Departamenteve të TeamConnect, 
                  kështu që sigurohuni të përdorni këtë kuti kërkimi në vend të kërkimit kryesor të Microsoft Teams në krye të ndërfaqes së Teams për pyetjet specifike të departamentit.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Si funksionon filtri alfabetik në Drejtorinë e Departamenteve?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Strukturoni kërkimin tuaj në Drejtorinë e Departamenteve me filtër alfabetik të vendosur në krye. Kjo veçori ju lejon të ngushtoni menjëherë departamentet duke zgjedhur një shkronjë. 
                  Sapë zgjidhni një shkronjë, TeamConnect filtrat dhe shfaq vetëm ato departamente që emrat e tyre fillojnë me shkronjën tuaj të zgjedhur, duke e bërë më të lehtë të gjeni atë që po kërkoni
                  pa u nevojitur të lundroni nëpër të gjithë listën. Për të rikthyer shikimin e plotë të departamentit, thjesht deselektoni shkronjën e dalluar. Ky udhëzim intuitiv alfabetik siguron që ju të
                  mund të gjeni me efikasitet çdo departament sipas shkronjës së parë të tij.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Si mund të kontaktoj menaxherin e departamentit?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Kontaktimi i një menaxheri departamenti në TeamConnect është i thjeshtë. Thjesht gjeni departamentin që ju intereson dhe klikoni mbi emrin e menaxherit. Ky
                  veprim do të çojë në një shfaqje të veçorive të kontaktit të menaxherit me ikonat e njohura për qasje të shpejtë:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona e Bisedës:</Body1Strong> Për të filluar një mesazh të menjëhershëm nëpërmjet bisedës së Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona e Email-it:</Body1Strong> Për të hapur një email të ri të drejtuar atyre në Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona e Thirrjes me Video:</Body1Strong> Për të nisur një thirrje video përmes Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona e Thirrjes:</Body1Strong> Për të filluar një thirrje zanore duke përdorur Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona e Më Shumë (...):</Body1Strong> Për opsione shtesë si planifikimi i një takimi ose shtimi i 
                      informacionit të tyre të kontaktit në listën tuaj të Teams.
                    </li>
                  </ul>
                  Këto ikona sigurojnë një metodë të paanshme për të arritur tek menaxherët e departamenteve direkt nga brenda TeamConnect, duke siguruar komunikim efikas në 
                  organizatën tuaj.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Si mund të shikoj ose të kontaktoj punonjësit brenda një departamenti?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Për të eksploruar se kush është kush brenda një departamenti në TeamConnect, mos kërko më tej se tre pikat pranë emrit të departamentit. Duke klikuar mbi këtë do të 
                  sjellë opsione për veprime të mëtejshme:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Shiko Punonjësit në Këtë Departament:</Body1Strong> Zgjidh këtë për të shfaqur një listë të të gjithë 
                      punonjësve të lidhur me departamentin. Numri pranë kësaj opsioni tregon sa punonjës janë në departament, duke ofruar një llogaritje të shpejtë të numrit të punonjësve.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Struktura Organizative e Departamentit:</Body1Strong> Nëse dëshiron të kuptosh hierarkinë e departamentit, 
                      zgjidh 'Struktura organizative e departamentit' për të zbuluar strukturën organizative, duke treguar marrëdhënien midis departamenteve prind, departamentit në fjalë, 
                      dhe ndonjë nëndepartamentesh. Kjo paraqitje vizuale të ndihmon të navigosh dhe të kuptosh kornizën organizative, duke e bërë më të lehtë të identifikosh dhe 
                      të kontakton anëtarë specifikë të departamentit.
                    </li>
                    <li><Body1Strong>Departamenti {'>'} Punonjësi:</Body1Strong> Pas shikimit të punonjësve brenda një departamenti, përdor këtë shteg bukuroshe për të naviguar lehtësisht 
                      mbrapsht në listën e departamenteve, duke ruajtur një përvojë të qetë eksplorimi brenda TeamConnect.
                    </li>
                  </ul>
                  Këto karakteristika të lejojnë jo vetëm të shohësh listën e punonjësve në një departament të caktuar, por gjithashtu ofrojnë një rrugë për të arritur direkt tek ata përmes 
                  mjeteve të komunikimit të TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Zbulimi i Detajeve të Thelluara të Departamentit në TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Zhytu në detajet e një departamenti me një klikim të thjeshtë mbi emrin e tij në TeamConnect. Një dialog i detajuar zhvillohet, duke ofruar një tapiseri të pasur të 
                  informacioneve që pikturon një imazh të plotë të rolit dhe burimeve të departamentit brenda organizatës suaj. Do të gjesh gjithçka nga liderët dhe identifikatorët financiarë 
                  deri tek kanale të komunikimit dhe lidhje sociale. Ky karakteristikë është porta juaj për:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Zemra e departamentit – qëllimi, njerëzit dhe matjet e performancës.</li>
                    <li className={styles.bulletBottomMargin}>Avenjutë e kontaktit – linjat direkte me figurat kyçe të departamentit dhe platforma komunikimi.</li>
                    <li className={styles.bulletBottomMargin}>Rrjeti organizativ – kuptimi se si departamenti integrohet me strukturën më të gjerë të kompanisë.</li>
                  </ul>
                  Qoftë një pyetje e shpejtë ose një zhytje e thellë në të dhënat e departamentit, TeamConnect ju pajis me mjetet për të lidhur dhe kuptuar ekosistemet unike 
                  brenda organizatës suaj.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Kurioz për Mungesën e Adresave të Departamenteve në TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Zbulimi i vendndodhjes së një departamenti ndonjëherë mund të ndihet si një kërkim thesari pa hartë. Në TeamConnect, zakonisht nuk do të gjesh një adresë të dedikuar 
                  për çdo departament. Ja pse ky detaj kartografik shpesh lihet jashtë:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vendndodhje të Shumëfishta:</Body1Strong> Shumë departamente nuk janë të kufizuara në një hapësirë të vetme; ato
                      lulëzojnë në zyra të ndryshme dhe gjeografi.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Strukturë Fluide:</Body1Strong> Peizazhi organizativ është gjithmonë në ndryshim, me 
                      departamente që evoluojnë, bashkohen, ose edhe shpërndahen në përputhje me ndryshimet strategjike.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Bashkëpunim Ndërdepartamental:</Body1Strong> Hapësirat moderne të punës janë mbushur me frymë bashkëpunimi, 
                      shpesh duke kombinuar talente nga departamente të ndryshme brenda hapësirave të përbashkëta.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kultura e Punës në Distanë:</Body1Strong> Me fleksibilitetin e punës në distancë, lidhjet departamentale 
                      zakonisht nuk janë të fiksuara në vendndodhje fizike të zyrave.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nuancat Ligjore:</Body1Strong> Departamentet zakonisht rrotullohen rreth strukturës ligjore të kompanisë 
                      në vend që të kenë adresat e tyre të pavarura.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Streamlining Operacional:</Body1Strong> Mbajtja e fokusit në bashkëpunim dinamik, jo në adresa statike, 
                      ndihmon në ruajtjen e shkathësisë operacionale.
                    </li>
                  </ul>
                  Bëhet fjalë për përqafimin e një qasjeje pa kufij ndaj punës në grup dhe efikasitetit. TeamConnect pasqyron këtë filozofi duke vënë theksin në lidhje në vend të vendndodhjeve.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. A i munguar ose jo e përditësuar informacioni i departamentit tuaj?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Redaktorët e departamentit janë të caktuar brenda organizatës suaj për të shtuar, përditësuar ose korrigjuar informacionin specifik të departamentit në TeamConnect.
                  Nëse nuk jeni të sigurt se kush është redaktori i departamentit tuaj ose nëse informacioni i caktuar kërkon ndërhyrje të ekipit të mbështetjes HR,
                  ju lutemi kontaktoni <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> për udhëzime.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Pyetjet e Bëra Më Shpesh për Drejtorinë e Zyrave</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Hyrje në Drejtorinë e Zyrave:</Body1Strong> Zbuloni bërthamën e pranisë fizike të organizatës suaj përmes Drejtorisë së Zyrave,
                që përfshin hollësi thelbësore të zyrave, specifika operative dhe një pamje të gjithanshme të departamenteve dhe personelit që përcaktojnë çdo vendndodhje.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Navigimi i Etiketave në Drejtorinë e Zyrave
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Gjeni rrugën tuaj rreth Drejtorisë së Zyrave me lehtësi duke përdorur sistemin tonë të etiketave të dizajnuara për qasje të shpejtë në vendin e duhur. Ja si çdo etiketë mund t'ju ndihmojë të strukturoni kërkimin tuaj:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Të Gjitha:</Body1Strong> Shfaq një listim të plotë të zyrave, duke ofruar një pamje panoramike të 
                      vendeve të organizimit tuaj.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Të Preferuarat:</Body1Strong> Ju ndihmon të përqendroheni në ato vende zyre që vizitoni më shpesh ose që keni 
                      nevojë të mbani nën vëzhgim, duke i shënuar si 'Të Preferuara'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zyra Juaj:</Body1Strong> Kjo etiketë personale filtrohet në drejtori për t'ju treguar vendin e zyrës
                      ku jeni të stacionuar, duke mbajtur bazën tuaj në këmbët tuaja.
                    </li>
                  </ul>
                  Pavarësisht nëse jeni duke rezervuar një sallë takimesh ose planifikoni një vizitë, këto etiketa ofrojnë një mënyrë të personalizuar dhe të thjeshtëzuar për të interaguar me peizazhin gjeografik të organizatës suaj.
                </Body1>
              </AccordionPanel>
            </AccordionItem>              
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Mjeshtërimi i parë se si të shihet Drejtoria e Zyrave në TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hyni në skemën organizative të lokalizimeve të ndryshme të kompanisë tuaj me pamjet e Direktorit të Zyrave në TeamConnect. Çdo pamje është krijuar për të lehtësuar 
                  një aspekt unik të navigimit dhe kuptimit të zyrës:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pamje e Listës:</Body1Strong> Konfigurimi fillestar që organizon me kujdes të gjitha lokalizimet e zyrave në 
                      një listë të lehtë për tu lexuar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pamje e Madhe e Kartelës:</Body1Strong> Pamja e Madhe e Kartelës në Drejtorinë e Zyrës pasuron 
                      shikimin tuaj me një paraqitje të detajuar të çdo zyre. Kur merreni me një kartelë zyre, do të gjeni një pasuri informacionesh dhe opsionesh vepruese 
                      brenda menysë "Më shumë (...)" :
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Shiko Departamentet:</Body1Strong> Drejtohuni direkt në listën
                          e të gjithë departamenteve që gjenden brenda zyrës.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Shiko Punonjësit:</Body1Strong> Qasuni në listën e plotë të punonjësve të caktuar për 
                          zyrën, duke lehtësuar kontaktin e drejtpërdrejtë ose eksplorimin e personelit të zyrës.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Grafiku organizativ i Entitetit Biznesor:</Body1Strong> Vizualizoni vendin e zyrës brenda kontekstit organizativ më të gjerë për një kuptim të qartë të strukturave hierarkike.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Kontaktet Kyçe:</Body1Strong> Identifikoni kontaktet kyçe të zyrës për komunikim të efikashtë.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Pushimet Publike të Zyrës:</Body1Strong> Qëndroni të informuar për pushimet publike specifike të zyrës për të planifikuar oraret tuaja përputhshmërisht.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Oraret e Punës të Zyrës:</Body1Strong> Shikoni orët e operimit të zyrës për të rregulluar 
                          vizitat ose thirrjet tuaja me kohët e tyre aktive.
                        </li>
                      </ol>
                      Kjo pamje ofron jo vetëm një fotografi vizuale dhe informative, por edhe qasje të shpejtë në përvojat e thelluara dhe veprimet e drejtpërdrejta që lidhen me zyrën, të gjitha të organizuara bukur nën opsionin "Më shumë (...)" për lehtësinë tuaj.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pamje e Grafikut Organizativ:</Body1Strong> Vizualizoni strukturën hierarkike të zyrave prind dhe nënzyra 
                      për të kuptuar ndërlidhshmërinë e ndryshme të lokalizimeve të zyrave brenda organizatës suaj.
                    </li>
                  </ul>
                  Kaloni nëpër këto pamje duke përdorur ikonat pranë kutisë së kërkimit për të përshtatur përvojën tuaj të shfletimit për të përmbushur nevojat tuaja të menjëhershme.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Gjetja e Zyrave Shpejt me Veçorinë e Kërkimit të TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Përqendrimi në lokalizimin e duhur të zyrës brenda TeamConnect është bërë i lehtë me veçorinë e dedikuar të kërkimit. Pozicionuar në mënyrë të spikatur pranë 
                  ikonave të pamjes, kutia e kërkimit është mjeti juaj për të përcaktuar zyrat me emër. Ndërsa shkruani, drejtoria përmirëson shfaqjen për të treguar vetëm zyrat 
                  që përputhen me hyrjen tuaj, duke e simplifikuar procesin tuaj të kërkimit. Është e rëndësishme të përdorni këtë kuti kërkimi brenda TeamConnect për rezultatet 
                  më të sakta dhe të përkatshme, në krahasim me kërkimin e përgjithshëm në Microsoft Teams. Me këtë funksionalitet të kërkimit të synuar, mund të navigoni shpejt 
                  në çdo zyrë në rrjetin e organizatës suaj.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Navigimi në Drejtorinë e Zyrave sipas Alfabetit
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nëse preferoni të kërkoni sipas alfabetit, filtri ynë alfabetik është një mënyrë e shpejtë për të gjetur zyrat sipas emrit. Në krye të Drejtorisë së Zyrave, do të 
                  shihni shkronjat nga A deri në Z. Duke klikuar mbi një shkronjë, pamja filtrohet për të treguar vetëm ato zyra që fillojnë me atë shkronjë, duke e thjeshtuar 
                  procesin tuaj të kërkimit. Për të rivendosur dhe parë sërish të gjitha zyrat, thjesht deselektoni shkronjën aktive. Kjo karakteristikë është veçanërisht e dobishme kur 
                  jeni të njohur me emrin e zyrës por keni nevojë për një mënyrë të shpejtë për ta lokalizuar atë brenda TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Zbulimi i Profileve të Gjithanshme të Zyrave në TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nisni një udhëtim eksplorues nëpër zemrën e pranisë fizike të organizatës suaj me profilet e zyrave të TeamConnect. 
                  Duke klikuar mbi emrin e një zyre shfaqet një dialog i gjallë i profilit, i mbushur me skeda të mbushura me informacione të detajuara për zyrën:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Përmbledhje:</Body1Strong> Hidhuni në thelbin e identitetit të zyrës me përshkrime të hollësishme,
                      nga struktura e biznesit deri te oraret e hapjes dhe koha lokale, duke kapur thelbin e ambientit të zyrës.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontakte Kyçe:</Body1Strong> Qasuni në kontakte të rëndësishme për shërbime emergjente dhe 
                      mbështetje të jashtme, secili vetëm një klikim larg përmes Sistemit Telefonik të Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontaktet e Zyrës:</Body1Strong> Lidhuni me shërbimet e mbështetjes brenda organizatës me lehtësi, me 
                      detajet e kontaktit dhe opsionet e angazhimit direkt në gishtat tuaj.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pushimet Publike:</Body1Strong> Ndjekni pushimet specifike të zyrës, duke siguruar që planet tuaja 
                      të përshtaten me vëzhgimet lokale dhe oraret e punës.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Oraret e Zyrës:</Body1Strong> Planifikoni ndërveprimet tuaja me dijeni të saktë të kohës kur zyra 
                      është me aktivitet ose përfundon ditën.
                    </li>
                  </ul>
                  Qoftë energia e vazhdueshme e Austin-it apo qendra strategjike e Singaporit, secila skedë ofron një portë për të kuptuar dhe lidhur me vendet e ndryshme që përbëjnë 
                  praninë globale të kompanisë tuaj.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Zhytja në Dinamikën e Departamenteve brenda një Zyra
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigimi në rrjetin profesional të departamenteve të një zyre zhvillohet me lehtësi në TeamConnect. Ja si të zhyteni në detajet:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Shikoni Departamentet në Këtë Zyre:</Body1Strong> Zbuloni të gjitha departamentet që formojnë 
                      strukturën e zyrës duke zgjedhur këtë opsion nga menuja Më shumë (...).
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Insight Organizativ:</Body1Strong> Klikoni në çdo departament për të zbuluar strukturën e tij organizative përmes një paraqitjeje vizuale, me udhëzuesin e breadcrumbs që ju orienton: Zyra {'>'} Departamenti {'>'} Skema.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zhytje e Thellë në Departament:</Body1Strong> Eksploroni më tej një departament duke klikuar në 
                      menuja e tij Më shumë (...) pranë emrit të departamentit. Këtu, mund të aksesoni një listë të të gjithë punonjësve brenda atij departamenti, drejtpërdrejt në kontekstin e zyrës.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Navigimi me Breadcrumbs:</Body1Strong> Kaloni nëpër nivelet pa probleme. Nga 
                      punonjësit mbrapsht në departamentet ose nga skema organizative, gjurma e breadcrumbs (Zyra {'>'} Departamenti {'>'} Punonjësi {'>'} Skema) mbajnë udhëtimin tuaj intuitiv dhe në rrugën e duhur.
                    </li>
                  </ul>
                  Rrugët komplekse të TeamConnect ju ftojnë të eksploroni, kuptoni dhe angazhoheni me çdo shtresë të departamenteve të zyrës suaj, duke ju fuqizuar me 
                  një komandë të gjithanshme navigimi.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Lidhja me Personelin e Zyrës në TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Kurioz për fytyrat pas një zyre? Përgjigja është vetëm një klikim larg në TeamConnect. Angazhohuni me pikat e trepikëshme (tri pika) pranë emrit të zyrës së zgjedhur për të zbuluar një suitë opsionesh interaktive:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Shikoni Punonjësit në Këtë Zyrë:</Body1Strong> Zbuloni listën e të gjithë profesionistëve 
                      që e quajnë këtë zyrë si shtëpinë e tyre të punës. Numri i shfaqur ofron një numërim të shpejtë të punonjësve, duke ju ftesë të zhyteni në komunitetin e zyrës.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Mundësitë e Kontaktit:</Body1Strong> Çdo regjistrim i punonjësit është pajisur me ikona veprimi të shpejta, 
                      duke ju lejuar të filloni biseda, thirrje ose madje konferenca video direkt nga TeamConnect, duke nxitur komunikimin e paanshëm brenda zyrës.
                    </li>
                    <li><Body1Strong>Navigimi:</Body1Strong> Për të kthyer në listën më të gjerë të zyrave nga lista e punonjësve, thjesht klikoni 'Zyra' në gjurmën e breadcrumbs (Zyra {'>'} Punonjësi), dhe do të ktheheni në përmbledhjen e zyrës.
                    </li>
                  </ul>
                  Me këto veçori, TeamConnect e bën të thjeshtë të visualizoni shpërndarjen e fuqisë punëtore në zyrat dhe të arrini pa mundim, 
                  duke nxitur një vend pune të lidhur pavarësisht nga distanca.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. A mungon ose është e vjetruar informacioni për zyrën tuaj?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Përditësimet e informacionit të zyrës në TeamConnect mund të menaxhohen nga redaktorët e caktuar të zyrës. Nëse jeni të pasigurt për redaktorët e zyrës ose 
                  keni nevojë për ndihmë me informacionin që bie nën përgjegjësinë e HR-it, ekipi i mbështetjes së HR-it është gati për të 
                  ndihmuar në <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Pyetjet më të shpeshta për Cilësimet</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Rëndësia e Cilësimeve të Përgjithshme të Sakta në TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Në peizazhin dinamik të ambientit të sotëm të punës, të qëndruarit të lidhur dhe të koordinuar është më jetike se kurrë. Ja ku luan një rol kyç përditësimi i
                  Cilësimeve të Përgjithshme në TeamConnect:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Zgjedhja e Vendit të Punës:</Body1Strong> Kapni thelbin e mjedisit tuaj të punës duke përditësuar Vendin tuaj të Punës për të pasqyruar situatën tuaj aktual,
                        qoftë një ndërrim nga qetësia e Zyrës-Homë në zhurmën e Zyrës kryesore, ose edhe vendosja në një Zonë Publike.
                        Kjo qartësi në vendndodhjen tuaj të punës ofron kolegëve të dhëna jo vetëm për mënyrat më të mira për t’ju kontaktuar, por edhe për natyrën e
                        bisedave në të cilat mund të angazhohen me ju, duke ruajtur konfidencialitetin e diskutimeve të ndjeshme sidomos kur jeni në mjedisin e hapur
                        të zyrës së një klienti ose ulur në një sallë pritjeje të aeroportit.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Specifikimi i Vendit të Punës:</Body1Strong> Ndarja e qytetit tuaj të punës bën më shumë se të krijojë ura midis largësive në një setup ekipi në distancë—ajo gjithashtu
                        hap dyert për grumbullime profesionale spontane ose socializime të rastësishme. Qoftë një kafe e shpejtë, një drekë e qetë, ose një takim bashkëpunues, të diturit që ndodheni në të njëjtin qytet mund të transformojë një ditë të zakonshme në një mundësi për lidhje me kuptim fytyrë më fytyrë. Kjo jo
                        vetëm forcon lidhjet e ekipit, por gjithashtu ofron një kuptim më të thellë të nuancave lokale që mund të jenë duke ndikuar në punën tuaj.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Saktësia e Orës Lokale:</Body1Strong> Ndërsa ekipet përhapen nëpër kontinente, është thelbësore që orari juaj të reflektojë
                      vendndodhjen tuaj aktuale për të harmonizuar komunikimet. Koha lokale në profilin tuaj të TeamConnect influencohet drejtpërdrejt nga kjo cilësim,
                      duke eliminuar çdo hamendje për kolegët që përpiqen të ju arrijnë.
                    </li>
                  </ul>
                  Hyni në 'Cilësimet' {'>'} 'Cilësimet e Përgjithshme' brenda TeamConnect për të përsosur këto detaje. Disa momente të shpenzuara për përditësimin e cilësimeve tuaja mund
                  të rrisë ndjeshëm kohezionin dhe efikasitetin e përpjekjeve tuaja bashkëpunuese në të gjithë bordet.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Zgjidhja e Vendit të Punës në TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Zgjedhja e vendosjes së duhur në menynë dropdown të Vendit të Punës përshtat TeamConnect për të reflektuar saktësisht statusin tuaj aktual të punës. Vendosni kursorin mbi ikonën e informacionit për një shpjegim të detajuar të çdo opsioni:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Break:</Body1Strong> Tregon kohën jashtë punës, qoftë për pushim ose në fund të ditës.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office/Field-Office:</Body1Strong> Tregon praninë në një hapësirë pune të menaxhuar nga kompania.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> Punë nga një hapësirë e dedikuar shtëpie.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Public-Area:</Body1Strong> Operon nga një mjedis jo-zyre, duke sugjeruar një nevojë për komunikim fleksibël.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Customer Workplace/Private Office:</Body1Strong> Në vend me një klient, me një shënim mbi nivelet e privatësisë për ndjeshmërinë e bisedës.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Commute/Business-Travel/Training:</Body1Strong> Në lëvizje ose i angazhuar në aktivitete të caktuara të lidhura me punën jashtë zyrës.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Public-Holiday/Vacation/Various Leaves:</Body1Strong> Zyrtarisht jashtë detyre për arsye personale, shëndetësore, ose festash.</li>
                  </ul>
                  Zgjedhja e një vendosjeje të përshtatshme të vendit të punës siguron që ekipi juaj të ketë kontekstin e nevojshëm për ndërveprime, duke respektuar disponueshmërinë dhe privatësinë.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Navigimi i Statusit 'Checked-In' në TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Etiketa 'Checked-In' në Drejtorinë e Punonjësve është projektuar për të shfaqur të gjithë punonjësit që aktualisht janë në punë. Kjo dukshmëri përcaktohet nga zgjedhja e Vendit të Punës që bëni:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ambientet Aktive të Punës:</Body1Strong> Zgjedhjet Office, Field-Office, Home-Office, Remote-Workplace, Public-Area, Customer-Workplace, 
                    Customer Private-Office, Commute, Business-Travel, dhe Training sinjalizojnë që jeni të angazhuar në punë, duke ju bërë 'Checked-In' dhe dukshëm aktiv për kolegët.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vendosjet Jo-Punë:</Body1Strong> Duke zgjedhur statuset Checked-Out, Break, Public-Holiday, Vacation, On-Leave, Maternity-Leave, ose Sick-Leave 
                      ju heq nga lista 'Checked-In', duke vendosur pritshmëri të qarta për disponueshmërinë dhe kohët e përgjigjes.
                  </li>
                  </ul>
                  Kjo funksionalitet ndihmon në dallimin e periudhave të punës nga koha personale, duke ndihmuar në bashkëpunimin dhe komunikimin efektiv të ekipit.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Pyetje të Shpeshta për Menaxhimin e të Dhënave dhe Privatësinë</Subtitle2>
          <Body1>
            <p>
              Në epokën digjitale të sotme, është thelbësore të kuptohet se si menaxhohen të dhënat tuaja. TeamConnect është i përkushtuar ndaj transparencës dhe praktikave të forta të mbrojtjes së të dhënave. 
              Më poshtë, do të gjeni informacion mbi mënyrën se si ne trajtojmë, ruajmë dhe mbrojmë të dhënat tuaja brenda TeamConnect, duke siguruar që hapësira juaj digjitale e punës është e sigurt dhe në përputhje.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Si mbrohet TeamConnect të dhënat e mia personale?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect është i përkushtuar për të mbrojtur informacionin tuaj personal me një qasje gjithëpërfshirëse ndaj sigurisë së të dhënave:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Single Sign-On (SSO):</Body1Strong> Për të forcuar sigurinë dhe për të përmirësuar përvojën e përdoruesit, TeamConnect integron automatikisht 
                      Single Sign-On (SSO) me llogarinë tuaj të Microsoft 365, duke lehtësuar menaxhimin e qasjes së sigurt dhe pa mundime.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Encryption:</Body1Strong> Ne sigurojmë që të gjitha të dhënat brenda TeamConnect të jenë të koduara gjatë transmetimit dhe kur janë në gjendje qëndrimi, 
                      duke i bërë ato të padisponueshme për palët e paautorizuara.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Minimizimi i të Dhënave:</Body1Strong> Mbledhja jonë e të dhënave është rigorozisht e kufizuar në atë që është thelbësore për funksionimin e TeamConnect. 
                      E vetmja informacion për përdoruesin që ne ruajmë është Emri juaj Kryesor i Përdoruesit (UPN) nga ID e Azure Entra, i cili mund të ngjajë me adresën tuaj të emailit të kompanisë.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Qasje e Kufizuar:</Body1Strong> Qasja në UPN është e kufizuar për Adminin e Abonimit të organizatës suaj brenda portalit tonë të Abonimit, 
                      duke siguruar që informacioni i ndjeshëm është i dukshëm vetëm për personelin e autorizuar.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Fshirje e Shpejtë e të Dhënave:</Body1Strong> TeamConnect është strukturuar për të fshirë menjëherë UPN e ruajtur nën tre kushte specifike: 
                      nëse abonimi është anuluar nga Admini i Abonimit, nëse një përdorues hiqet nga HR brenda TeamConnect, ose nëse abonimi i TeamConnect 
                      skadon. Kjo siguron që të dhënat tuaja personale të mos ruhen përtej jetës së tyre të dobishme ose nevojës.
                    </li>                      
                  </ul>
                  <p>Përmes këtyre masave, TeamConnect zotohet të ruajë standardet më të larta të mbrojtjes së të dhënave, duke siguruar që informacioni juaj personal të mbetet i sigurt dhe konfidencial.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Po mendoni që të dhënat tuaja ndodhen me TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect thyen kufijtë e ofertave tradicionale SaaS duke siguruar që të dhënat tuaja kurrë nuk largohen nga organizata juaj. 
                  Ndryshe nga praktika zakonshme e ruajtjes së të dhënave në serverë të jashtëm cloud, TeamConnect shfrytëzon në mënyrë të zgjuar 
                  Microsoft 365 SharePoint Online të organizatës suaj për të strehuar të gjitha të dhënat e aplikacionit. Ky qasje inovative jo vetëm 
                  që mbrojtje sovranitetin e të dhënave por gjithashtu forcon sigurinë, duke u përputhur në mënyrë të përsosur me përkushtimin tonë të rreptë 
                  për të mbrojtur privatësinë tuaj dhe për të përmbushur standardet e rrepta të përputhshmërisë.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. A do të ndahen të dhënat e mia me palë të treta?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jo, të dhënat nuk ndahen me palë të treta! Cubeet është e palëkundur në angazhimin tonë për privatësinë dhe sigurinë e të dhënave tuaja. Ne nuk ndajmë asnjë të dhënë të TeamConnect me palë të treta pa marrë miratimin e qartë nga klientët tanë.
                  Në mjedisin tonë të sigurt, ne ruajmë vetëm identifikuesit esencialë, siç janë Emri Kryesor i Përdoruesit (UPN), emri i organizatës, emri i tenantit dhe ID e tenantit, brenda portalit tonë të Abonimit.
                  Kjo informacion është thelbësore për ofrimin e shërbimeve dhe mbrohet nën politikat tona të rrepta të privatësisë.
                  Mund të jeni të sigurt, praktikat tona të menaxhimit të të dhënave janë të dizajnuara për të siguruar që të dhënat tuaja të mbeten konfidenciale, me transparencë dhe kontroll që të qëndrojnë fort në duart tuaja.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Si përputhet TeamConnect me rregulloret e mbrojtjes së të dhënave si GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect përputhet me rregulloret e mbrojtjes së të dhënave si GDPR, HIPAA, CCPA dhe të tjera duke shfrytëzuar funksionet e përmbushjes që janë të integruara në mjedisin e Microsoft 365,
                  ku të gjitha të dhënat e klientëve ruhen në mënyrë të sigurt. Ndryshe nga modelet tradicionale SaaS, TeamConnect nuk ruan asnjë të dhënë të klientit në serverët e tij; përkundrazi, operon brenda 
                  mjedisit tuaj të Microsoft 365, duke respektuar standardet e forta të sigurisë dhe përmbushjes. Ky qasje siguron që qeverisja e të dhënave tuaja të mbetet në përputhje me kuadrin gjithëpërfshirës të 
                  përmbushjes të ofruar nga Microsoft 365, duke përfshirë respektimin e GDPR, HIPAA për informacionin shëndetësor, CCPA për banorët e Kalifornisë,
                  dhe rregulloret e tjera globale të mbrojtjes së të dhënave. Duke u integruar pa probleme me konfigurimin tuaj ekzistues të Microsoft 365, TeamConnect fuqizon organizatën tuaj për të mbajtur 
                  kontroll të plotë mbi të dhënat tuaja, duke siguruar përputhshmëri me ligjet dhe rregulloret më të rrepta të mbrojtjes së të dhënave.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Si menaxhon TeamConnect të dhënat nga bisedat në Microsoft Teams, thirrjet, thirrjet me video, email-at në Outlook dhe thirrjet e sistemit telefonik?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect shfrytëzon Microsoft Graph API për të mundësuar ndërveprime të drejtpërdrejta si bisedat, thirrjet, thirrjet me video dhe email-at në Outlook brenda Microsoft Teams,
                  duke ofruar një përvojë të përshtatshme për përdoruesin. Kyç është fakti që, ndërsa TeamConnect iniciaton këto komunikime, e bën këtë pa ndërhyrë në procesin e komunikimit
                  ose duke pasur aftësinë për të regjistruar ose ruajtur ndonjë përmbajtje të ndërveprimit. Të gjitha bisedat, thirrjet dhe email-at ndodhin dhe menaxhohen direkt brenda
                  kufijve të sigurt të Microsoft Teams dhe Outlook, duke ndjekur me rigorozitet standardet e forta të sigurisë dhe privatësisë të Microsoft 365. Kjo siguron që TeamConnect të përmirësojë
                  aftësitë tuaja komunikuese duke ruajtur plotësisht konfidencialitetin dhe integritetin e të dhënave tuaja.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Për njohuri të hollësishme rreth politikave dhe praktikave tona të të dhënave, mund të dëshironi gjithashtu të konsultoheni me {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>Udhëzuesin e Përdoruesit të TeamConnect</Body1Strong></Link> ose departamentin tuaj të IT-së 
            në <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> për detaje specifike në lidhje me konfigurimin tuaj.
          </p>
        </Body1>        
        <p></p>  
      <Subtitle1 className={styles.fontColor}>Po Ju Dëgjojmë: Ndani Mendimet dhe Idetë Tuaja</Subtitle1>
        <Body1>
          <p>
            Mendimet tuaja drejtojnë evolucionin e TeamConnect. Qoftë se jeni plot me një ide brilante për një veçori të re ose keni disa përshtypje për përvojën tuaj të tanishme, ne jemi të gjithë veshë.
            Kontributi juaj është i çmuar në përpjekjen tonë të vazhdueshme për të ofruar shërbimin më të mirë të mundshëm.
          </p>
        </Body1>      
      <Subtitle2>Mendime dhe Sugjerime për Veçori</Subtitle2>
        <Body1>
          <p>
            Gati për të ndarë? Shkoni te faqja jonë {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Faqja e Mendimeve
            </Link>
            . Qoftë një sugjerim për një veçori të re ose mendime mbi funksionet ekzistuese, thjesht zgjidhni 
            opsionin përkatës nga menuja në formular. Kontributet tuaja ndihmojnë në formësimin e ardhmërisë së TeamConnect, 
            duke siguruar që ai të plotësojë dhe të tejkalojë nevojat tuaja.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
