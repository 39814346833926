import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();

  return (
    <>
      <Title3 className={styles.fontColor}>ศูนย์ช่วยเหลือและคำถามที่พบบ่อยของ TeamConnect</Title3>
        <p>
          <Body1>
            ยินดีต้อนรับสู่ศูนย์ช่วยเหลือ TeamConnect ที่ออกแบบมาเพื่อสนับสนุนประสบการณ์ของคุณกับแอปไดเรกทอรีองค์กรของเราอย่างครบวงจร ที่นี่คุณจะพบกับทรัพยากรที่จะช่วยปรับปรุงการใช้งาน TeamConnect ภายใน Microsoft Teams เพื่อเพิ่มประสิทธิภาพการสื่อสารข้ามเครือข่ายของคุณ
          </Body1>
        </p>
        <Subtitle2>คู่มือการใช้งานในเบื้องต้น</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>ความสามารถในการค้นหาที่เพิ่มขึ้น: </Body1Strong>
              ค้นหาเพื่อนร่วมงาน, แผนก, และผู้ติดต่อสำคัญได้อย่างรวดเร็วด้วยเครื่องมือค้นหาอันทรงพลังของเรา
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>แผนผังองค์กรเชิงภาพ: </Body1Strong>
              นำทางโครงสร้างขององค์กรและเข้าใจเส้นทางการรายงานได้อย่างง่ายดายผ่านแผนผังองค์กรที่ใช้งานง่าย
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>เครื่องมือที่ปรับตัวตามความต้องการในปัจจุบัน: </Body1Strong>
              ใช้ฟังก์ชันการทำงานร่วมสมัยที่ออกแบบมาสำหรับการทำงานในสถานที่ทำงานยุคใหม่
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>การเพิ่มประสิทธิภาพประสบการณ์การใช้งานของคุณ</Subtitle2>
        <Body1>
          <p>
            TeamConnect ปฏิวัติการทำงานร่วมกันภายในโครงสร้างองค์กรของคุณ โดยเปลี่ยนการสื่อสารประจำวันให้เป็นการมีส่วนร่วมทางยุทธศาสตร์
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>รับความช่วยเหลือและคำถามที่พบบ่อย</Subtitle1>
        <Body1>
          <p>
            ค้นหาคำตอบสำหรับคำถามที่พบบ่อยเกี่ยวกับคุณสมบัติและการตั้งค่าของ TeamConnect ในส่วนนี้ ไม่ว่าคุณจะกำลังนำทางแอป ปรับแต่งการตั้งค่า หรือมองหาวิธีแก้ไขปัญหา ศูนย์ช่วยเหลือของเราพร้อมให้บริการคุณอย่างมีประสิทธิภาพ
          </p>
        </Body1>
        <Body1>
          <p>
            สำหรับการสนับสนุนที่ลึกซึ้งยิ่งขึ้นหรือคำแนะนำที่เฉพาะเจาะจง โปรดปรึกษา <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>คู่มือผู้ใช้ TeamConnect</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>        
        <Subtitle2>การใช้งาน TeamConnect คำถามที่พบบ่อย</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. ไม่พบ TeamConnect ในแถบด้านข้างของ Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  หากไม่เห็น TeamConnect อย่างชัดเจนหรือหายไปในแถบข้างของ Microsoft Teams คุณสามารถตรึงได้ง่ายๆเพื่อการเข้าถึงอย่างรวดเร็ว โปรดปฏิบัติตามขั้นตอนง่ายๆ เหล่านี้:
                  <ol>
                    <li className={styles.bulletBottomMargin}>เปิด Microsoft Teams แล้วคลิกที่ไอคอน '...' (+ แอปพลิเคชัน) ที่ด้านล่างของแถบด้านข้าง</li>
                    <li className={styles.bulletBottomMargin}>ค้นหา 'TeamConnect' ในแถบค้นหาด้านบนของหน้าต่างที่ปรากฏขึ้น</li>
                    <li className={styles.bulletBottomMargin}>คลิกขวาที่แอป TeamConnect จากผลการค้นหาและเลือก 'ตรึง'</li>
                  </ol>
                  TeamConnect จะถูกตรึงไว้ที่แถบด้านข้างของคุณแล้ว ทำให้คุณเข้าถึงได้ด้วยการคลิกเพียงครั้งเดียว!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. มีปัญหาทางเทคนิคกับ TeamConnect หรือไม่? นี่คือวิธีขอความช่วยเหลือ
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  หากคุณพบปัญหาทางเทคนิคใดๆ กับ TeamConnect ภายใน Microsoft Teams ทีมสนับสนุน IT ขององค์กรของคุณพร้อมช่วยเหลือ <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> และพวกเขาจะช่วยคุณอย่างรวดเร็ว
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. ไม่สามารถพบรายละเอียดของพนักงานของคุณได้หรือ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  หากโปรไฟล์ของคุณดูว่างเปล่าโดยมีเพียงชื่อและอีเมลของคุณ อาจเป็นไปได้ว่าทีม HR ยังไม่ได้เพิ่มรายละเอียดพนักงานอื่นๆ ให้กับ TeamConnect
                  ไม่ต้องกังวล! ข้อความถึงทีมสนับสนุน HR ของคุณจะเริ่มกระบวนการนี้ พวกเขาพร้อมที่จะเติมเต็มโปรไฟล์ของคุณด้วยข้อมูลสำคัญ <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> และดูการเปลี่ยนแปลงในโปรไฟล์ของคุณ!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. มีตัวเลือกภาษาอะไรบ้างใน TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect รองรับความหลากหลายของภาษาใน Microsoft Teams เป็นอย่างดี
                  ไม่ว่าคุณจะชอบภาษาอาหรับหรือกรีก, ญี่ปุ่นหรือสวีเดน,
                  คุณมีตัวเลือกทั่วโลกที่คุณสามารถเลือกใช้ได้, รวมถึง:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      ภาษาอาหรับ, บัลแกเรีย, จีน (ตัวย่อและตัวเต็ม), โครเอเชีย, เช็ก,
                      เดนมาร์ก, ดัตช์, อังกฤษ, เอสโตเนีย, ฟินแลนด์, ฝรั่งเศส, เยอรมนี, กรีก,
                      ฮิบรู, ฮังการี, อินโดนีเซีย, อิตาลี, ญี่ปุ่น, เกาหลี, ลัตเวีย,
                      ลิธัวเนีย, นอร์เวย์ (บอกมาล), โปแลนด์, โปรตุเกส (บราซิลและโปรตุเกส),
                      โรมาเนีย, รัสเซีย, เซอร์เบีย (ละติน), สโลวัก, สโลวีเนีย, สเปน, สวีเดน,
                      ไทย, ตุรกี, ยูเครน, เวียดนาม.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  เพื่อปรับแต่งประสบการณ์การใช้งาน TeamConnect ให้เข้ากับภาษาที่คุณต้องการ,
                  กรุณาปรับการตั้งค่าภาษาโดยตรงใน Microsoft Teams, เนื่องจาก TeamConnect
                  ได้ผสานรวมกับการตั้งค่าเหล่านี้:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      ไปที่การตั้งค่า Microsoft Teams
                      ของคุณโดยการคลิกที่สามจุดข้างๆรูปโปรไฟล์ที่ด้านบน แล้วเลือก
                      'ปรากฏและการเข้าถึง'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      เลื่อนไปที่ส่วน 'ภาษา' และเลือกภาษาที่คุณต้องการจากเมนูแบบเลื่อนลง.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      ยืนยันการเปลี่ยนแปลงโดยการคลิกปุ่ม 'บันทึกและเริ่มใหม่'
                      เพื่อปรับใช้การตั้งค่าภาษาใหม่.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>หมายเหตุ:</Body1Strong>
                <Body1>
                  การเปลี่ยนแปลงที่คุณทำกับการตั้งค่าภาษาใน Microsoft Teams
                  จะอัปเดตประสบการณ์การใช้งาน TeamConnect ของคุณอย่างราบรื่นเช่นกัน.
                  โปรดทราบว่าการเปลี่ยนแปลงภาษาใน Teams
                  อาจปรับเปลี่ยนรูปแบบเวลาและวันที่ของคุณด้วย. ณ ตอนนี้, TeamConnect
                  กำลังพัฒนาเพื่อรองรับการเขียนจากขวาไปซ้าย.
                  เราตื่นเต้นกับการปรับปรุงเหล่านี้ และจะแจ้งให้ทราบเมื่อมีการอัปเดตใหม่.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. ฉันจะเปลี่ยนธีมใน TeamConnect ได้อย่างไร?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect จะซิงค์กับการตั้งค่าธีมของคุณใน Microsoft Teams ซึ่งรวมถึงตัวเลือก Light, Dark และ High Contrast สำหรับการปรับแต่งธีมของคุณ:
                  <ol>
                    <li className={styles.bulletBottomMargin}>เข้าถึงการตั้งค่าของ Microsoft Teams โดยคลิกที่สามจุดข้างๆรูปโปรไฟล์ของคุณที่ด้านบน</li>
                    <li className={styles.bulletBottomMargin}>เลือก 'Appearance and accessibility' เพื่อสำรวจตัวเลือกธีม</li>
                    <li className={styles.bulletBottomMargin}>เลือกธีมที่คุณต้องการจากตัวเลือกที่มีให้เพื่อทำการอัปเดตหน้าตาของ TeamConnect ให้ตรงกับทันที</li>
                  </ol>
                  การเลือกธีมของคุณใน Microsoft Teams จะถูกนำไปใช้กับ TeamConnect โดยอัตโนมัติ ซึ่งช่วยให้มั่นใจได้ว่ามีประสบการณ์ทางภาพที่สอดคล้องกันในพื้นที่ทำงานของคุณ
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. สำรวจปุ่มการดำเนินการติดต่อของ TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ทั่วทั้ง TeamConnect ทุกที่ที่คุณพบรายละเอียดการติดต่อของพนักงาน คุณยังจะค้นพบปุ่มเข้าถึงอย่างรวดเร็วที่สะดวก ปุ่มเหล่านี้ถูกออกแบบมาเพื่อให้การสื่อสารภายในระบบของ Microsoft ง่ายขึ้น ผ่านยูทิลิตี้แนะนำการใช้งานปุ่มเหล่านี้อย่างถี่ถ้วน:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chat Icon:</Body1Strong> คลิกเพื่อเริ่มการแชททันทีกับ Microsoft Teams หากต้องการกลับไปยัง TeamConnect หลังจากเริ่มแชท ให้ใช้ปุ่มลูกศรย้อนกลับในแอปพลิเคชันเดสก์ท็อป ปุ่มย้อนกลับในแอปพลิเคชันมือถือ หรือปุ่มย้อนกลับของเบราว์เซอร์</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Email Icon:</Body1Strong> เปิดแบบร่างอีเมลใหม่ใน Outlook พร้อมที่อยู่ของผู้รับในช่อง "To:". หาก Outlook ไม่เปิด ควรตรวจสอบกับแผนก IT ของคุณเกี่ยวกับข้อจำกัดใดๆ</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Video Call Icon:</Body1Strong> เริ่มต้นการโทรวิดีโอเพื่อพบหน้ากันผ่าน Microsoft Teams</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Call Icon:</Body1Strong> เริ่มการโทรเสียงผ่าน Microsoft Teams ทำให้การสนทนาสั้นๆ เป็นไปอย่างราบรื่น</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org Chart Icon:</Body1Strong> แสดงตำแหน่งของพนักงานในลำดับชั้นขององค์กร รวมถึงผู้จัดการและผู้ใต้บังคับบัญชา</li>
                    <li><Body1Strong>More (...) Icon:</Body1Strong> เปิดเผยตัวเลือกเพิ่มเติม:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Meet Now:</Body1Strong> เปิด Microsoft Teams เพื่อเริ่มการประชุมทันทีกับพนักงาน</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>New Meeting:</Body1Strong> ไปที่ Microsoft Teams เพื่อกำหนดการประชุมใหม่</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Add Contact:</Body1Strong> เพิ่มรายละเอียดการติดต่อของพนักงานจาก TeamConnect ไปยังแอป People ใหม่ของ Microsoft Teams ช่วยให้ความสามารถในการโทรของคุณร่ำรวยขึ้น</li>
                      </ul>
                    </li>
                  </ul>
                  ปุ่มเหล่านี้ออกแบบมาเพื่อช่วยให้คุณสามารถเชื่อมต่อกับเพื่อนร่วมงานได้อย่างมีประสิทธิภาพ ไม่ว่าจะเป็นการสนทนาเล็กน้อย การส่งอีเมล หรือการวางแผนประชุมในอนาคต
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. การโทรผ่าน TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ต้องการโทรหาใครหรือไม่? TeamConnect ช่วยให้การสื่อสารของคุณราบรื่นมากขึ้นโดยการทำงานร่วมกับ Microsoft Teams 
                  แค่คลิกที่หมายเลขโทรศัพท์ที่ต้องการใน TeamConnect แล้ว Microsoft Teams จะปรากฏขึ้นพร้อมให้คุณโทรออกได้ทันที
                  <p>
                  <Body1Strong>Note: </Body1Strong> 
                  พบปัญหาในการทำการโทรหรือไม่? อาจเป็นเพราะนโยบาย IT ขององค์กรหรือการใช้ระบบโทรศัพท์ที่ไม่ใช่ Microsoft Teams Phone System การตรวจสอบกับแผนก IT ของคุณที่ <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, สามารถช่วยให้คุณเข้าใจปัญหาได้ดีขึ้น
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. มีค่าใช้จ่ายในการโทรผ่าน TeamConnect หรือไม่?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect ช่วยเพิ่มประสบการณ์การโทรของคุณโดยใช้ประโยชน์จาก Microsoft Teams นี่คือสิ่งที่คุณต้องรู้เกี่ยวกับค่าใช้จ่ายในการโทร:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      สำหรับการโทรไปยังโทรศัพท์ที่ทำงานของพนักงาน, โทรศัพท์ฝ่ายหรือสายด่วนภายในองค์กรของคุณ (หมายเลขภายใน), 
                      โดยทั่วไปแล้วจะ <Body1Strong>ไม่มีค่าใช้จ่าย</Body1Strong> เมื่อใช้ระบบโทรศัพท์ของ Microsoft Teams
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      สำหรับการโทรภายนอก เช่น สำหรับมือถือหรือสายนอกออกไลน์ <Body1Strong>อัตรามาตรฐาน</Body1Strong> ของระบบโทรศัพท์องค์กรของคุณจะถูกนำมาใช้
                      อัตราเหล่านี้อาจแตกต่างกันขึ้นอยู่กับแผนบริการที่เฉพาะเจาะจงกับ Microsoft Teams ของคุณ
                    </li>
                  </ul>
                  <p><Body1Strong>หมายเหตุ:</Body1Strong> หากคุณพบปัญหาหรือมีคำถามเกี่ยวกับค่าใช้จ่ายในการโทร สิ่งที่ดีที่สุดคือไปปรึกษากับฝ่าย
                    IT ของคุณที่ <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> เพื่อข้อมูลที่ละเอียดอ่อน
                    ที่ปรับให้เข้ากับการตั้งค่าและนโยบายขององค์กรของคุณ</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. เห็นความไม่ตรงกันกับสถานะการแสดงว่าคุณไม่อยู่ที่ทำงาน ใน TeamConnect หรือไม่?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  สังเกตเห็นว่าสถานะการแสดงว่าคุณไม่อยู่ที่ทำงานจาก Microsoft 365 ไม่สะท้อนให้เห็นอย่างถูกต้องใน TeamConnect หรือไม่?
                  เรามีคำตอบให้คุณ ฟีเจอร์การซิงค์สถานะการแสดงว่าคุณไม่อยู่ที่ทำงานได้ถูกนำไปใช้งานใน TeamConnect แล้ว
                  เรากำลังรอการเปิดตัวฟีเจอร์นี้จาก Microsoft หลังจากนั้นสถานะการแสดงว่าคุณไม่อยู่ที่ทำงาน
                  ของคุณจะถูกสะท้อนอย่างถูกต้องใน TeamConnect, โดยการจับคู่กับตัวบ่งชี้สถานะใน Microsoft Teams สำหรับประสบการณ์ที่ราบรื่น
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>คำถามที่พบบ่อยของบัญชีพนักงาน</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>บทนำบัญชีพนักงาน:</Body1Strong> ท่องแนวทางทางวิชาชีพภายในองค์กรของคุณด้วยบัญชีพนักงาน,
                ศูนย์กลางของคุณสำหรับข้อมูลเชิงลึกเกี่ยวกับพนักงาน, พลวัตในที่ทำงาน, และการเชื่อมต่อตรงไปยังโปรไฟล์ของเพื่อนร่วมงานและความสัมพันธ์ทางผู้จัดการของคุณ
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. ต้องการเพิ่ม, ปรับปรุง, หรือแก้ไขข้อมูลพนักงานของคุณหรือไม่?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  สำหรับการเพิ่ม, ปรับปรุง, หรือแก้ไขข้อมูลพนักงานของคุณใน TeamConnect, โปรดติดต่อทีมสนับสนุนทรัพยากรมนุษย์ขององค์กรคุณ
                  พวกเขาพร้อมที่จะช่วยเหลือที่ <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. เกิดอะไรขึ้นเมื่อฉันคลิกที่ชื่อของพนักงานในไดเร็กทอรี?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  การคลิกที่ชื่อพนักงานใน TeamConnect Employee Directory จะเปิดหน้าโปรไฟล์โดยละเอียดของบุคคลนั้น โปรไฟล์นี้ให้ข้อมูลเชิงลึกอย่างกว้างขวางเกี่ยวกับพนักงาน รวมทั้งไม่จำกัดเพียงตำแหน่งงาน แผนก สถานที่ทำงาน ข้อมูลติดต่อ และอื่นๆ ออกแบบมาเพื่อให้คุณเข้าใจภาพรวมของบทบาทในการทำงานและวิธีการที่พวกเขาเข้ากับองค์กร เพื่อทำให้คุณเข้าใจหน้าที่และวิธีการที่คุณอาจร่วมมือกับพวกเขาได้ง่ายขึ้น
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. มีข้อมูลอะไรบ้างในโปรไฟล์ของพนักงาน?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  โปรไฟล์ของแต่ละพนักงานใน TeamConnect เป็นภาพรวมที่ครอบคลุม มอบข้อมูลเชิงลึกเกี่ยวกับชีวิตทางวิชาชีพของบุคคล นี่คือสิ่งที่คุณจะพบ:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>รายละเอียดพื้นฐาน:</Body1Strong> ชื่อ, ตำแหน่งงาน, แผนก, และสถานที่ตั้งของบริษัทลูก
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>โครงสร้างการรายงาน:</Body1Strong> ข้อมูลเกี่ยวกับการรายงานโดยตรงและการรายงานทางการทำงาน,
                      รวมถึงผู้จัดการสายงานและผู้จัดการทางฟังก์ชัน
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>สถานะในที่ทำงาน:</Body1Strong> สถานะการทำงานปัจจุบัน เช่น ลาออก, เดินทางไปทำงาน,
                      การเดินทางไปต่างประเทศ เป็นต้น
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ตำแหน่งที่ทำงานและเวลาท้องถิ่น:</Body1Strong> ตำแหน่งที่ทำงานปัจจุบันและ
                      เขตเวลาท้องถิ่น
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>รายละเอียดการติดต่อ:</Body1Strong> โทรศัพท์ที่ทำงาน, มือถือ, และที่อยู่อีเมล</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ที่อยู่สำนักงาน:</Body1Strong> ตำแหน่งที่ตั้งสำนักงานอย่างครบถ้วนรวมถึงชื่ออาคาร,
                      ที่อยู่, และ Google map
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>เกี่ยวกับ:</Body1Strong> บทนำส่วนตัวหรือประวัติวิชาชีพอย่างสั้น
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ความสัมพันธ์ในการทำงาน:</Body1Strong> รายละเอียดโครงสร้างการรายงานและความสัมพันธ์ในทีมของพนักงาน
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ลิงก์โซเชียลและภาษา:</Body1Strong> ลิงก์ไปยังโปรไฟล์โซเชียลมืออาชีพ
                      และความสามารถทางภาษา
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ทักษะ:</Body1Strong> รายการทักษะทางวิชาชีพและพื้นที่ความเชี่ยวชาญ</li>
                  </ul>
                  นอกจากนี้ โปรไฟล์ยังมีตัวเลือกที่สามารถดำเนินการเพื่อการเชื่อมต่อผ่านการแชท อีเมล วิดีโอคอล หรือการนัดหมาย พร้อมกับการเข้าถึงแผนผังองค์กรได้อย่างรวดเร็วเพื่อให้เข้าใจบริบทของทีมอย่างกว้างขวาง
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. ฉันจะอัปเดตข้อมูลโปรไฟล์ของตัวเองได้อย่างไร?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  การปรับแต่งโปรไฟล์ของคุณใน TeamConnect นั้นง่ายมาก นี่คือวิธีการทำ:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        ไปที่แท็บ <Body1Strong>Employee</Body1Strong> และคลิกที่ <Body1Strong>Avatar</Body1Strong> เพื่อเปิดโปรไฟล์ของคุณ
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        ในมุมซ้ายบน, คุณจะพบลิงก์ <Body1Strong>"Edit your profile"</Body1Strong> 
                        การคลิกที่นี่จะเปิดหน้าต่างแก้ไขโปรไฟล์ของคุณ
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        ที่นี่คุณสามารถปรับปรุงโปรไฟล์ของคุณได้โดยการเพิ่มหรืออัปเดตส่วน <Body1Strong>About You</Body1Strong>, ลิงก์โซเชียลมีเดีย, และหมายเลขโทรศัพท์
                        ซึ่งจะทำให้เพื่อนร่วมงานของคุณสามารถเชื่อมต่อหรือติดตามคุณได้ง่ายขึ้น
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        อย่าลืมกดปุ่ม <Body1Strong>Save</Body1Strong> เพื่อใช้การเปลี่ยนแปลงของคุณ
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        เลื่อนลงในหน้าต่างเพื่อเพิ่ม <Body1Strong>Languages</Body1Strong> และ <Body1Strong>Proficiency</Body1Strong> ของคุณ,
                        รวมทั้ง <Body1Strong>Skills</Body1Strong> การแชร์ข้อมูลนี้ช่วยให้เพื่อนร่วมงานของคุณเข้าใจถึง
                        ความเชี่ยวชาญของคุณและภาษาที่คุณสามารถสื่อสารได้
                      </li>
                    </ol>
                  การอัปเดตโปรไฟล์ของคุณไม่เพียงแต่ทำให้ข้อมูลของคุณทันสมัยเท่านั้น แต่ยังช่วยส่งเสริมการเชื่อมโยงที่ดีภายในองค์กรของคุณ
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. การอัปเดตสถานที่ทำงานของคุณ, ที่ทำงาน, และเขตเวลาใน TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  การรักษาข้อมูลสถานที่ทำงาน, ที่ทำงาน, และเขตเวลาให้เป็นปัจจุบันนั้นสำคัญมากสำหรับการทำงานร่วมกันอย่างราบรื่นใน Microsoft Teams.
                  ข้อมูลเหล่านี้จะแสดงในโปรไฟล์และรายการ TeamConnect ของคุณทันที ช่วยให้เพื่อนร่วมงานเข้าใจสถานที่ทำงาน 
                  และเวลาที่ดีที่สุดในการติดต่อกับคุณได้อย่างรวดเร็ว ส่งเสริมการทำงานเป็นทีมข้ามภูมิภาคและเขตเวลาต่างๆ
                  <p></p>
                  พร้อมที่จะอัปเดตข้อมูลของคุณหรือยัง? มันง่ายมากใน TeamConnect! ไปที่ 'Settings' ในเมนูด้านบนและเลือก 'General Settings'.
                  ที่นี่คุณสามารถอัปเดตสถานที่ทำงานและเขตเวลาของคุณได้อย่างราบรื่น และระบุที่ทำงานของคุณสำหรับวันนั้น อย่าลืมบันทึกการเปลี่ยนแปลงของคุณเพื่อ
                  รักษาการติดต่อที่ดีและให้ความร่วมมือเป็นไปอย่างมีประสิทธิภาพ ไม่ว่าคุณจะอยู่ที่ไหนก็ตาม!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. เห็นบางอย่างไม่เหมาะสมในโปรไฟล์ของเพื่อนร่วมงานหรือไม่?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  หากคุณพบรายละเอียดใดๆ ในโปรไฟล์ของเพื่อนร่วมงานที่ดูไม่เหมาะสม ขั้นตอนแรกที่ดีที่สุดคือการแจ้งให้เขาทราบอย่างเป็นมิตร
                  การสนทนาสั้นๆ หรืออีเมลก็เพียงพอแล้ว! หากด้วยเหตุผลใดก็ตามข้อมูลนั้นไม่ได้รับการอัปเดต ไม่ต้องกังวล—เพียงแค่
                  ส่งข้อความถึงทีมสนับสนุนทรัพยากรบุคคลของคุณที่ <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, และพวกเขายินดี
                  ที่จะเข้ามาช่วยแก้ไขปัญหา
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. การเข้าใจกลุ่มแท็ก: ทั้งหมด, รายการโปรด, เช็คอิน, และพนักงานใหม่
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ทั้งหมด:</Body1Strong> แท็กเริ่มต้นนี้แสดงพนักงานทุกคนในองค์กรของคุณ
                      ให้มุมมองอย่างครอบคลุมของทีม.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>รายการโปรด:</Body1Strong> คลิกที่นี่เพื่อกรองมุมมองของคุณไปยังเพื่อนร่วมงานที่คุณได้ทำเครื่องหมายว่า 'รายการโปรด'
                      ช่วยให้คุณเชื่อมต่อกับผู้ติดต่อหลักได้ง่ายขึ้น.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>เช็คอิน:</Body1Strong> แท็กนี้แสดงพนักงานที่กำลังทำงานอยู่ แยกจากการมีอยู่ออนไลน์เฉยๆ ใน Microsoft Teams เมื่อพนักงานเช็คอิน
                      พวกเขาสื่อสารว่ากำลังทำงานและแชร์ตำแหน่งการทำงานปัจจุบันของพวกเขาในระหว่างวัน นี่อาจรวมถึงสถานที่ทำงานหลากหลาย เช่น ออฟฟิศ, ฟิลด์ออฟฟิศ, โฮมออฟฟิศ, ที่ทำงานระยะไกล,
                      ที่ทำงานลูกค้า, สำนักงานส่วนตัวของลูกค้า, พื้นที่สาธารณะ, เดินทาง, ท่องเที่ยวธุรกิจ หรือแม้แต่การฝึกอบรม ดังนั้น สถานะ 'เช็คอิน' จึงให้ข้อบ่งชี้ที่ชัดเจน
                      ว่ากำลังทำงานอย่างกระตือรือร้น มอบความเข้าใจที่แม่นยำเกี่ยวกับบริบทและความพร้อมในการทำงานในปัจจุบันของเพื่อนร่วมงานกัน.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>พนักงานใหม่:</Body1Strong> สังเกตใบหน้าใหม่ใน TeamConnect ด้วยแท็กนี้
                      ที่เน้นพนักงานที่ถูกเพิ่มเข้ามาในแอปภายใน 30 วันที่ผ่านมา โดยทั่วไป นี่ตรงกับการเพิ่มใหม่ล่าสุดในองค์กรของคุณ ช่วยให้คุณต้อนรับสมาชิกทีมใหม่ 
                      ได้อย่างเหมาะสม.
                    </li>
                  </ul>
                  กลุ่มแท็กเหล่านี้ออกแบบมาเพื่อสร้างเส้นทางการใช้งานให้ราบรื่นและเพิ่มประสบการณ์ในการใช้งาน TeamConnect ของคุณ ทำให้คุณสามารถค้นหาและเชื่อมต่อกับเพื่อนร่วมงานได้อย่างมีประสิทธิภาพ.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. การถอดรหัสไอคอนข้างกล่องค้นหา
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ไดเรกทอรีพนักงานของ TeamConnect ออกแบบมาเพื่อความหลากหลายและความง่าย โดยมีไอคอนสี่ตัวที่นำเสนอมุมมองต่างๆ 
                  และเข้าถึงรายละเอียดสำคัญได้อย่างรวดเร็ว นี่คือสิ่งที่แต่ละไอคอนเปิดเผย:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>มุมมองรายการ:</Body1Strong> มุมมองหลักของคุณ นำเสนอสมาชิกขององค์กรทั้งหมด
                      ในรายการที่ชัดเจน.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>มุมมองการ์ดขนาดเล็ก:</Body1Strong> ทางเลือกที่กระชับยิ่งขึ้น มุมมองนี้เปลี่ยน
                      รายการเป็นการ์ดพนักงานขนาดย่อม.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>มุมมองการ์ดขนาดใหญ่:</Body1Strong> เลือกดูรายละเอียดเพิ่มเติมด้วยการ์ดขนาดใหญ่ ซึ่งรวมถึง
                      ตัวเลือกการติดต่อเพิ่มเติมเช่นหมายเลขโทรศัพท์และมือถือสำหรับการโทรอย่างง่ายดาย.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>แผนผังองค์กร:</Body1Strong> มองเห็นโครงสร้างของบริษัทด้วยมุมมองแผนผัง 
                      องค์กร ซึ่งเหมาะสำหรับการเข้าใจความสัมพันธ์ในการรา Reporting และการเดินทางภายในทัศนะการองค์กร.</li>
                  </ol>
                    สลับระหว่างไอคอนเหล่านี้เพื่อหามุมมองที่เหมาะสมที่สุดสำหรับความต้องการของคุณในขณะนั้น ไม่ว่าคุณจะกำลังหาข้อมูลอย่างรวดเร็วหรือมุมมองที่ลึกซึ้งกว่า.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. สำหรับการค้นหาเพื่อนร่วมงานอย่างรวดเร็ว ควรดูที่ไหน?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  กำลังมองหาเพื่อนร่วมงาน? แค่ไปที่กล่องค้นหา TeamConnect ตั้งอยู่ทางด้านขวาบนข้างไอคอนแผนผังองค์กร. 
                  เริ่มพิมพ์ชื่อของพวกเขา และดูการค้นหาที่จะกลายเป็นละเอียดขึ้นตามที่รหัสผ่าน. จำไว้ว่า สำหรับการค้นหา TeamConnect ข้ามแถบค้นหาของ Microsoft Teams 
                  ที่ด้านบน—กล่องค้นหาพิเศษของเราเป็นทางที่ถูกต้อง!
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. การค้นหาตามตัวอักษรเรียงลำดับทำงานอย่างไรเพื่อหาพนักงาน?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ไดเร็กทอรีพนักงานของ TeamConnect มีคุณสมบัติการค้นหาตามตัวอักษรเพื่อช่วยให้คุณหาพนักงานด้วยชื่อแรกได้ง่ายขึ้นอย่างรวดเร็ว
                  เพียงแค่คลิกที่ตัวอักษรใดตัวหนึ่งจากอัลฟาเบทที่แสดงที่ด้านบนของไดเร็กทอรี และ TeamConnect จะแสดงรายชื่อพนักงานทั้งหมด
                  ที่มีชื่อแรกเริ่มต้นด้วยตัวอักษรนั้นทันที หากต้องการกลับไปยังรายการพนักงานทั้งหมด เพียงแค่ยกเลิกการเลือกตัวอักษรที่เน้นไว้ เครื่องมือนี้ทำให้
                  การนำทางผ่านรายการพนักงานจำนวนมากเป็นเรื่องง่าย โดยช่วยให้คุณสามารถหาเพื่อนร่วมงานได้อย่างรวดเร็วและมีประสิทธิภาพ
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. ฉันจะปรับจำนวนพนักงานที่แสดงต่อหน้าได้อย่างไร?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect ช่วยให้คุณสามารถปรับแต่งจำนวนโปรไฟล์พนักงานที่คุณดูต่อหน้าได้ เพื่อให้ง่ายต่อการนำทางผ่านไดเร็กทอรีพนักงาน
                  ที่ด้านล่างของแต่ละหน้า คุณจะพบตัวเลือกการแบ่งหน้า: 10, 50, 100, 500, 1000, และ 5000 การตั้งค่าเริ่มต้นแสดง 10 โปรไฟล์ต่อหน้า
                  หากต้องการดูโปรไฟล์เพิ่มเติมในหน้าเดียว เพียงเลือกหมายเลขที่คุณต้องการจากตัวเลือกเหล่านี้ คุณสมบัตินี้ให้ความยืดหยุ่นในการเรียกดู
                  ไดเร็กทอรี่ ช่วยให้คุณสามารถปรับแต่งการแสดงผลให้ตรงกับความต้องการหรือความชอบของคุณ
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. ฉันจะจัดเรียงข้อมูลพนักงานในมุมมองรายการได้อย่างไร?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ในมุมมองรายการของไดเร็กทอรีพนักงาน TeamConnect คุณมีความยืดหยุ่นในการจัดเรียงข้อมูลพนักงานเพื่อให้เหมาะกับความต้องการของคุณ คอลัมน์—ชื่อพนักงาน
                  ตำแหน่งงาน แผนก สถานที่ทำงาน และสถานที่ทำงาน—ล้วนสามารถจัดเรียงได้ เพียงคลิกที่หัวข้อคอลัมน์ที่คุณต้องการจัดเรียง การคลิกครั้งแรกจะจัดเรียงคอลัมน์ในลำดับ
                  เพิ่มขึ้น และการคลิกอีกครั้งจะจัดเรียงลำดับจากมากไปน้อย โดยค่าเริ่มต้น ไดเร็กทอรีจะจัดเรียงตามชื่อพนักงานในลำดับเพิ่มขึ้น ตามชื่อแรก คุณสมบัติการจัดเรียงนี้ช่วยให้คุณจัดระเบียบและดูรายละเอียดของพนักงานได้อย่างรวดเร็วในลักษณะที่ตอบสนองวัตถุประสงค์ของคุณได้ดีที่สุด ไม่ว่าคุณจะกำลังมองหาบุคคลใดๆ หรือต้องการจัดกลุ่มพนักงานตามหลักเกณฑ์ใดๆ เช่น แผนกหรือสถานที่
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>คำถามที่พบบ่อยของแผนก</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>บทนำถึงแผนก:</Body1Strong> ดิเรกทอรีแผนกเป็นประตูสู่ความเข้าใจในโครงสร้างองค์กรของคุณ โดยนำเสนอมุมมองที่ชัดเจนของลำดับชั้นในแผนก ผู้นำทางการจัดการ และช่องทางสื่อสารภายในแผนกอย่างราบรื่น
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. แท็ก 'ทั้งหมด', 'รายการโปรด' และ 'แผนกของคุณ' ในดิเรกทอรีแผนกทำงานอย่างไร?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ในดิเรกทอรีแผนกของ TeamConnect, แท็กช่วยให้คุณสามารถนำทางและกรองข้อมูลแผนกได้อย่างรวดเร็ว นี่คือสิ่งที่แต่ละแท็กหมายถึง:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ทั้งหมด:</Body1Strong> แท็กนี้จะแสดงแผนกทั้งหมดในองค์กรของคุณ ให้ภาพรวมอย่างครอบคลุม
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>รายการโปรด:</Body1Strong> ใช้แท็กนี้เพื่อดูรายการแผนกที่คุณได้ทำเครื่องหมายเป็น 'รายการโปรด' ทำให้ง่ายต่อการเข้าถึงแผนกที่คุณติดต่อบ่อย
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>แผนกของคุณ:</Body1Strong> แท็กนี้กรองมุมมองเพื่อแสดงเฉพาะแผนกของคุณ เพื่อการเข้าถึงข้อมูลและอัปเดตของแผนกทันที</li>
                  </ul>
                  แท็กเหล่านี้ออกแบบมาเพื่อทำให้การนำทางของคุณในดิเรกทอรีแผนกเป็นไปอย่างราบรื่น และเพิ่มประสิทธิภาพในการค้นหา และมีส่วนร่วมกับข้อมูลแผนกได้ดียิ่งขึ้น
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. การเข้าใจมุมมองดิเรกทอรีแผนกใน TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  การนำทางผ่านดิเรกทอรีแผนกใน TeamConnect เป็นไปอย่างราบรื่นด้วยตัวเลือกการมองเห็นหลายรูปแบบที่ตรงกับความชอบของคุณ แต่ละมุมมองมอบวิธีที่ไม่เหมือนใครในการสำรวจข้อมูลแผนกอย่างครบถ้วน รวมถึงชื่อแผนก ผู้จัดการแผนก แผนกแม่ หมายเลขโทรศัพท์แผนก สายด่วนแผนก และอีเมลแผนก นี่คือภาพรวมของมุมมองที่มีอยู่:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>มุมมองรายการ:</Body1Strong> การตั้งค่าเริ่มต้นที่จัดรายการแผนกทั้งหมดอย่างเรียบร้อย ให้ภาพรวมที่ชัดเจนและกระชับ
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>มุมมองการ์ดขนาดใหญ่:</Body1Strong> เปลี่ยนไปใช้มุมมองนี้เพื่อดูการ์ดแผนกโดยละเอียด นำเสนอสรุปข้อมูลสำคัญของแต่ละแผนก
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>มุมมองแผนภูมิองค์กร:</Body1Strong> สำหรับการแสดงความเชื่อมโยงและโครงสร้างแผนกแบบภาพ มุมมองแผนภูมิองค์กรแสดงวิธีการที่แต่ละแผนกเข้ากันได้กับลำดับชั้นในองค์กรที่ใหญ่กว่า
                    </li>
                  </ul>
                  เพื่อสลับระหว่างมุมมองเหล่านี้ ให้ใช้ไอคอนข้างๆกล่องค้นหาที่ด้านบนของดิเรกทอรีแผนก แต่ละมุมมองถูกออกแบบมาเพื่อช่วยให้คุณเข้าถึงและเข้าใจรายละเอียดแผนกได้ง่ายขึ้น และรับรองว่าคุณจะพบข้อมูลที่คุณต้องการในรูปแบบที่เหมาะสมที่สุดสำหรับคุณ
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. วิธีการใช้กล่องค้นหาในสมุดที่อยู่แผนก
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ค้นหาแผนกใด ๆ ภายใน TeamConnect อย่างง่ายดายโดยใช้กล่องค้นหาที่จัดเตรียมไว้อยู่ข้างๆ ไอคอนมุมมองที่ด้านบนของ
                  สมุดที่อยู่แผนก เพียงเริ่มพิมพ์ชื่อแผนกที่คุณต้องการค้นหา แล้วจะเห็นว่าสมุดที่อยู่ปรับเปลี่ยนเองเพื่อแสดงเฉพาะ
                  ผลการจับคู่ที่เกี่ยวข้อง คุณลักษณะนี้ออกแบบมาเพื่อช่วยให้คุณข้ามขั้นตอนการค้นหาด้วยตนเองในทั้งหมดของสมุดที่อยู่ได้ นำ
                  แผนกที่คุณต้องการมาไว้ที่ปลายนิ้วของคุณเลย โปรดจำไว้ ฟังก์ชันการค้นหานี้มีเฉพาะในสมุดที่อยู่แผนกของ TeamConnect ดังนั้น
                  ต้องใช้กล่องค้นหานี้แทนการค้นหาหลักของ Microsoft Teams ที่ด้านบนสุดของอินเทอร์เฟส Teams สำหรับการสอบถามเฉพาะแผนก
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. ตัวกรองตามตัวอักษรทำงานอย่างไรในสมุดที่อยู่แผนก?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ทำให้การค้นหาในสมุดที่อยู่แผนกเป็นไปอย่างรวดเร็วด้วยตัวกรองตามตัวอักษรที่ตั้งอยู่ด้านบน คุณลักษณะนี้ช่วยให้คุณสามารถควบคุมการแสดงผล
                  ของแผนกโดยเลือกตัวอักษร หากคุณเลือกตัวอักษรใดตัวหนึ่ง TeamConnect จะกรองและแสดงเฉพาะแผนกที่มีชื่อเริ่มต้นด้วยตัวอักษรที่คุณเลือก
                  ทำให้ค้นหาสิ่งที่คุณต้องการได้ง่ายขึ้นโดยไม่ต้องเลื่อนดูรายการทั้งหมด เพื่อกลับไปยังมุมมองแผนกทั้งหมด เพียงแค่ยกเลิกการเลือกตัวอักษรที่ได้
                  ทำเครื่องหมายไว้ การนำทางตามตัวอักษรอย่างนี้ช่วยให้คุณสามารถค้นหาแผนกใด ๆ ตามตัวอักษรเริ่มต้นได้อย่างมีประสิทธิภาพ
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. ฉันจะติดต่อผู้จัดการแผนกได้อย่างไร?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  การติดต่อผู้จัดการแผนกใน TeamConnect ทำได้ง่ายโดยการหาแผนกที่สนใจและคลิกที่ชื่อผู้จัดการ การกระทำนี้จะทำให้เกิดกล่องผุดขึ้นที่แสดงตัวเลือกการติดต่อของผู้จัดการพร้อมกับไอคอนที่ใช้ง่าย:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ไอคอนแชท:</Body1Strong> เพื่อเริ่มข้อความโต้ตอบทันทีผ่านการแชทของ Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ไอคอนอีเมล:</Body1Strong> เพื่อเปิดอีเมลใหม่ที่อยู่ถึงพวกเขาใน Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ไอคอนการโทรวิดีโอ:</Body1Strong> เพื่อเริ่มการโทรวิดีโอผ่าน Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ไอคอนการโทร:</Body1Strong> เพื่อเริ่มการโทรด้วยเสียงผ่าน Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ไอคอนเพิ่มเติม (...):</Body1Strong> สำหรับตัวเลือกเพิ่มเติมเช่นการนัดหมายหรือเพิ่มข้อมูลติดต่อของพวกเขาในรายชื่อ Teams ของคุณ.
                    </li>
                  </ul>
                  ไอคอนเหล่านี้ให้วิธีการสื่อสารที่ราบรื่นกับผู้จัดการแผนกโดยตรงจากภายใน TeamConnect ทำให้การสื่อสารในองค์กรของคุณเป็นไปอย่างมีประสิทธิผล
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. ฉันสามารถดูหรือติดต่อพนักงานภายในแผนกได้อย่างไร?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  เพื่อสำรวจตัวตนของแต่ละคนภายในแผนกใน TeamConnect ให้ดูที่สัญลักษณ์จุดสามจุดข้างชื่อแผนก คลิกที่นี้จะแสดงตัวเลือกสำหรับการดำเนินการเพิ่มเติม:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ดูพนักงานในแผนกนี้:</Body1Strong> เลือกนี้เพื่อแสดงรายชื่อพนักงานทั้งหมดที่เกี่ยวข้องกับแผนก ตัวเลขข้างตัวเลือกนี้บอกจำนวนพนักงานในแผนกปัจจุบัน
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>โครงสร้างองค์กรของแผนก:</Body1Strong> หากคุณต้องการเข้าใจลำดับชั้นของแผนก ให้เลือก 'โครงสร้างองค์กรของแผนก' เพื่อเปิดเผยโครงสร้างที่แสดงความสัมพันธ์ระหว่างแผนกแม่ แผนกนี้ และแผนกย่อย ภาพแสดงนี้ช่วยให้คุณเข้าใจโครงสร้างระบบงานและง่ายต่อการระบุและติดต่อสมาชิกแผนกเฉพาะ
                    </li>
                    <li><Body1Strong>แผนก {'>'} พนักงาน:</Body1Strong> หลังจากดูพนักงานภายในแผนกแล้ว ใช้ลำดับนี้เพื่อนำทางกลับไปยังรายการแผนกได้อย่างง่ายดาย รักษาประสบการณ์การสำรวจที่ราบรื่นใน TeamConnect
                    </li>
                  </ul>
                  คุณสมบัตินี้ช่วยให้คุณสามารถดูรายการพนักงานในแผนกที่กำหนดได้ แต่ยังเป็นทางเลือกในการติดต่อกับพวกเขาโดยตรงผ่านเครื่องมือการสื่อสารของ TeamConnect
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. ค้นพบข้อมูลรายละเอียดแผนกใน TeamConnect อย่างลึกซึ้ง
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ดำดิ่งสู่รายละเอียดของแผนกใด ๆ ด้วยการคลิกเพียงครั้งเดียวที่ชื่อแผนกใน TeamConnect การสนทนาที่ละเอียดอ่อนจะกางออกมา นำเสนอข้อมูลที่หลากหลายที่รวบรวมภาพรวมของบทบาทและทรัพยากรของแผนกภายในองค์กรของคุณ คุณจะพบทุกอย่างตั้งแต่ผู้นำ ตัวบ่งชี้ทางการเงิน ไปจนถึงช่องทางการสื่อสารและลิงก์สังคม คุณสมบัตินี้เป็นประตูเข้าถึง:
                  <ul>
                    <li className={styles.bulletBottomMargin}>หัวใจสำคัญของแผนก – วัตถุประสงค์ คน และตัวชี้วัดประสิทธิภาพของพวกเขา</li>
                    <li className={styles.bulletBottomMargin}>ช่องทางการติดต่อ – สายตรงไปยังบุคคลสำคัญของแผนกและแพลตฟอร์มการสื่อสาร</li>
                    <li className={styles.bulletBottomMargin}>เว็บองค์กร – การเข้าใจวิธีการที่แผนกบูรณาการเข้ากับโครงสร้างของบริษัทอย่างกว้างขวาง</li>
                  </ul>
                  ไม่ว่าจะเป็นคำถามเร่งด่วนหรือการดำดิ่งลึกลงไปในข้อมูลแผนก TeamConnect มีเครื่องมือที่จะช่วยให้คุณเชื่อมต่อและเข้าใจระบบนิเวศที่ไม่เหมือนใครในองค์กรของคุณ
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. สงสัยเกี่ยวกับการไม่มีที่อยู่แผนกใน TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  การค้นหาสถานที่ของแผนกบางครั้งอาจรู้สึกเหมือนการตามหาสมบัติโดยไม่มีแผนที่ ใน TeamConnect คุณจะไม่พบที่อยู่ที่กำหนดไว้อย่างชัดเจนสำหรับแต่ละแผนก นี่คือเหตุผลที่รายละเอียดทางภูมิศาสตร์นี้มักถูกละเว้น:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>หลายสถานที่:</Body1Strong> หลายแผนกไม่ได้ถูกจำกัดอยู่เพียงที่เดียว; พวกเขาเติบโตอยู่ในหลายสำนักงานและภูมิภาค
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>โครงสร้างที่เปลี่ยนแปลงได้:</Body1Strong> ทัศน์สังเกตองค์กรมักเปลี่ยนแปลงอยู่เสมอ ด้วยแผนกที่เติบโต ผสานรวม หรือแม้แต่ยุบตัวเพื่อสอดคล้องกับการเปลี่ยนแปลงทางกลยุทธ์
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>การทำงานร่วมกันข้ามแผนก:</Body1Strong> พื้นที่ทำงานสมัยใหม่เต็มไปด้วยความร่วมมือ โดยมักผสมผสานความสามารถจากแผนกต่างๆ ภายในพื้นที่ร่วมกัน
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>วัฒนธรรมการทำงานทางไกล:</Body1Strong> ด้วยความยืดหยุ่นของการทำงานทางไกล แผนกต่างๆ จุดยึดไม่ได้ถูกทิ้งในที่ตั้งสำนักงานเสมอไป
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>เหตุผลทางกฎหมาย:</Body1Strong> แผนกโดยทั่วไปจะหมุนรอบโครงสร้างทางกฎหมายของบริษัท แทนที่จะมีที่อยู่ของตัวเอง
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>การปรับปรุงกระบวนการดำเนินงาน:</Body1Strong> การรักษาความคล่องตัวในการดำเนินงานโดยไม่มุ่งเน้นที่ที่อยู่ที่คงที่ ช่วยให้กระบวนการดำเนินงานทำได้ดีขึ้น
                    </li>
                  </ul>
                  ทั้งหมดนี้เกี่ยวกับการยอมรับแนวทางที่ไม่จำกัดขอบเขตในการทำงานร่วมกันและประสิทธิภาพ TeamConnect สะท้อนปรัชญานี้โดยเน้นการเชื่อมต่อมากกว่าสถานที่ทำงาน
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. ข้อมูลของแผนกของคุณหายไปหรือล้าสมัยหรือไม่?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  บรรณาธิการแผนกถูกกำหนดให้ภายในองค์กรของคุณเพื่อเพิ่ม อัปเดต หรือแก้ไขข้อมูลเฉพาะแผนกใน TeamConnect 
                  หากคุณไม่แน่ใจว่าบรรณาธิการแผนกของคุณคือใครหรือหากข้อมูลที่กำหนดต้องการการแทรกแซงจากทีมสนับสนุนทรัพยากรบุคคล
                  กรุณาติดต่อ <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> เพื่อรับคำแนะนำ
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>คำถามที่พบบ่อยของไดเรกทอรีสำนักงาน</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>บทนำไดเรกทอรีสำนักงาน:</Body1Strong> ค้นพบโครงสร้างหลักของการมีอยู่ทางกายภาพขององค์กรของคุณผ่านไดเรกทอรีสำนักงาน
                ซึ่งรวมถึงรายละเอียดเกี่ยวกับสำนักงานที่สำคัญ ข้อมูลการดำเนินงาน และภาพรวมที่ครอบคลุมเกี่ยวกับแผนกและบุคลากรที่กำหนดแต่ละสถานที่
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. การนำทางแท็กในไดเรกทอรีสำนักงาน
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ค้นหาตำแหน่งที่ตั้งสำนักงานของคุณอย่างง่ายดายด้วยระบบแท็กของเราที่ออกแบบมาสำหรับการเข้าถึงที่รวดเร็ว นี่คือวิธีที่แต่ละแท็กสามารถช่วยให้การค้นหาของคุณเป็นไปอย่างราบรื่น:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ทั้งหมด:</Body1Strong> แสดงรายชื่อสำนักงานทั้งหมด ให้ภาพรวมของที่ตั้งองค์กรของคุณ
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>รายการโปรด:</Body1Strong> ช่วยให้คุณสามารถเน้นไปที่สถานที่สำนักงานที่คุณไปบ่อยหรือต้องการติดตามอย่างใกล้ชิด โดยการทำเครื่องหมายว่า 'รายการโปรด'
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>สำนักงานของคุณ:</Body1Strong> แท็กส่วนตัวนี้กรองไดเรกทอรีเพื่อแสดงตำแหน่งที่ตั้งของสำนักงานที่คุณประจำอยู่ ทำให้คุณสามารถเข้าถึงฐานบ้านของคุณได้ง่าย
                    </li>
                  </ul>
                  ไม่ว่าคุณจะจองห้องประชุมหรือวางแผนการเยี่ยมชม แท็กเหล่านี้เสนอวิธีการที่ส่วนตัวและง่ายดายในการโต้ตอบกับภูมิทัศน์ทางภูมิศาสตร์ขององค์กรของคุณ
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. การทำความเข้าใจการดูไดเรกทอรีสำนักงานใน TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ดำดิ่งสู่โครงสร้างองค์กรของสถานที่ต่างๆ ในบริษัทของคุณผ่านมุมมองไดเรกทอรีสำนักงานของ TeamConnect แต่ละมุมมองถูกออกแบบมาเพื่อช่วยในการนำทางและเข้าใจสำนักงานที่เฉพาะเจาะจง:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>มุมมองรายการ:</Body1Strong> การตั้งค่าเริ่มต้นที่จัดเรียงสถานที่สำนักงานทั้งหมดเป็นรายการที่อ่านง่าย
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>มุมมองการ์ดขนาดใหญ่:</Body1Strong> มุมมองการ์ดขนาดใหญ่ในไดเรกทอรีสำนักงานเพิ่มรายละเอียดในการนำเสนอแต่ละสำนักงาน เมื่อใช้งานการ์ดสำนักงาน คุณจะพบข้อมูลจำนวนมากและตัวเลือกการดำเนินการ
                      ในเมนู "เพิ่มเติม (...)" ดังนี้:
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>ดูแผนก:</Body1Strong> นำทางโดยตรงไปยังรายการของแผนกทั้งหมดที่ตั้งอยู่ภายในสำนักงาน
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>ดูพนักงาน:</Body1Strong> เข้าถึงรายการครบถ้วนของพนักงานที่ได้รับมอบหมายให้กับสำนักงาน เพื่อการติดต่อหรือสำรวจพนักงานในสำนักงานได้โดยตรง
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>แผนภูมิองค์กรธุรกิจ:</Body1Strong> แสดงภาพตำแหน่งของสำนักงานภายในบริบทองค์กรใหญ่เพื่อความเข้าใจเกี่ยวกับโครงสร้างที่เป็นชั้น
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>ติดต่อสำคัญ:</Body1Strong> ระบุตัวติดต่อหลักของสำนักงานเพื่อการสื่อสารที่ง่ายขึ้น
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>วันหยุดสาธารณะของสำนักงาน:</Body1Strong> อัปเดตข้อมูลเกี่ยวกับวันหยุดสาธารณะเฉพาะสำนักงานเพื่อการวางแผนตารางเวลาของคุณ
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>เวลาทำงานของสำนักงาน:</Body1Strong> ดูเวลาทำงานของสำนักงานเพื่อประสานการเข้าพบหรือโทรตามเวลาทำงานของพวกเขา
                        </li>
                      </ol>
                      มุมมองนี้ไม่เพียงแต่ให้ภาพและข้อมูลสำคัญแต่ยังให้การเข้าถึงข้อมูลลึกและการดำเนินการโดยตรงเกี่ยวกับสำนักงาน ทั้งหมดนี้จัดเรียงอย่างเป็นระเบียบภายใต้ตัวเลือก "เพิ่มเติม (...)" สำหรับความสะดวกของคุณ
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>มุมมองแผนภูมิองค์กร:</Body1Strong> แสดงภาพโครงสร้างชั้นของสำนักงานแม่และสำนักงานย่อยเพื่อเข้าใจการเชื่อมต่อระหว่างสถานที่สำนักงานต่างๆ ในองค์กรของคุณ
                    </li>
                  </ul>
                  สลับมุมมองเหล่านี้โดยใช้ไอคอนข้างๆ กล่องค้นหาเพื่อปรับปรุงประสบการณ์การเรียกดูของคุณให้ตรงกับความต้องการทันที
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. ค้นหาสำนักงานอย่างรวดเร็วด้วยฟีเจอร์ค้นหาของ TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  การค้นหาสำนักงานที่ตรงกับความต้องการภายใน TeamConnect ทำได้ง่ายดาข้อมูลและการดำเนินการโดยตรงเกี่ยวกับสำนักงาน ทั้งหมดนี้จัดเรียงอย่างเป็นระเบียบภายใต้ตัวเลือก "เพิ่มเติม (...)" สำหรับความสะดวกของคุณ
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. การนำทางไดเรกทอรีสำนักงานตามตัวอักษร
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  หากคุณต้องการค้นหาตามตัวอักษร เครื่องมือกรองแบบตัวอักษรของเราช่วยให้คุณค้นหาสำนักงานตามชื่อได้อย่างรวดเร็ว ที่ด้านบนของไดเรกทอรีสำนักงาน คุณจะเห็นตัวอักษรจาก A ถึง Z การคลิกที่ตัวอักษรจะกรองมุมมองเพื่อแสดงเฉพาะสำนักงานที่เริ่มต้นด้วยตัวอักษรนั้น ทำให้กระบวนการค้นหาของคุณง่ายขึ้น เพื่อรีเซ็ตและดูสำนักงานทั้งหมดอีกครั้ง เพียงแค่ยกเลิกการเลือกตัวอักษรที่ใช้งานอยู่ ฟีเจอร์นี้มีประโยชน์พิเศษเมื่อคุณคุ้นเคยกับชื่อสำนักงาน แต่ต้องการวิธีรวดเร็วในการหาตำแหน่งภายใน TeamConnect
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. เปิดเผยโปรไฟล์สำนักงานอย่างครบถ้วนใน TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  เริ่มการเดินทางสำรวจใจกลางของการปรากฏตัวทางกายภาพขององค์กรคุณด้วยโปรไฟล์สำนักงานของ TeamConnect การคลิกที่ชื่อสำนักงานจะแสดงไดอะล็อกโปรไฟล์ที่สดใส ซึ่งเต็มไปด้วยแท็บที่มีข้อมูลละเอียดเกี่ยวกับสำนักงาน:
                  <ul>
                      <li className={styles.bulletBottomMargin}><Body1Strong>ภาพรวม:</Body1Strong> สำรวจใจกลางของตัวตนของสำนักงานด้วยตัวชี้วัดรายละเอียดตั้งแต่โครงสร้างธุรกิจจนถึงเวลาทำการและเวลาท้องถิ่น จับอรรถรสของสภาพแวดล้อมในสำนักงาน
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ผู้ติดต่อที่สำคัญ:</Body1Strong> เข้าถึงผู้ติดต่อสำคัญสำหรับบริการฉุกเฉินและการสนับสนุนภายนอก แต่ละคลิกผ่านระบบโทรศัพท์ของ Microsoft Teams
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ผู้ติดต่อสำนักงาน:</Body1Strong> ติดต่อบริการสนับสนุนภายในได้ง่าย พร้อมรายละเอียดการติดต่อและตัวเลือกการมีส่วนร่วมโดยตรง
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>วันหยุดสาธารณะ:</Body1Strong> ติดตามวันหยุดเฉพาะของสำนักงาน เพื่อให้แผนการของคุณสอดคล้องกับการสังเกตการณ์ท้องถิ่นและตารางการทำงาน
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>เวลาทำการ:</Body1Strong>วางแผนการโต้ตอบของคุณด้วยความรู้ที่แน่นอนเกี่ยวกับเวลาที่สำนักงานคึกคักหรือเงียบสงบ
                    </li>
                  </ul>
                  ไม่ว่าจะเป็นพลังที่แข็งแกร่งของ Austin หรือศูนย์กลางกลยุทธ์ของสิงคโปร์ แต่ละแท็บนำเสนอทางเข้าสู่ความเข้าใจและการเชื่อมต่อกับที่ตั้งที่หลากหลายซึ่งสร้างขึ้นสำหรับการปรากฏตัวทั่วโลกของบริษัทคุณ
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. การศึกษาดำเนินการภายในแผนกต่างๆ ในออฟฟิศ
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  การนำทางเครือข่ายวิชาชีพของแผนกต่างๆ ในออฟฟิศสามารถทำได้ง่ายดายผ่าน TeamConnect นี่คือวิธีการศึกษาข้อมูลเจาะลึก:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ดูแผนกในออฟฟิศนี้:</Body1Strong> ค้นพบทุกแผนกที่เป็นส่วนหนึ่งของออฟฟิศโดยเลือกตัวเลือกนี้จากเมนูเพิ่มเติม (...)
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>ข้อมูลการจัดการองค์กร:</Body1Strong> คลิกที่แผนกใดๆ เพื่อดูแผนผังองค์กรของพวกเขาฟืกฎ โดยมีเส้นทางนำทางช่วยคุณ: ออฟฟิศ {'>'} แผนก {'>'} แผนผัง
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>การเจาะลึกแผนก:</Body1Strong> สำรวจเพิ่มเติมภายในแผนกโดยคลิกที่เมนูเพิ่มเติม (...) ข้างชื่อแผนก ที่นี่คุณสามารถเข้าถึงรายชื่อพนักงานทั้งหมดภายในแผนกนั้นได้ทันที
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>การนำทางด้วยเส้นทาง:</Body1Strong> สามารถเดินทางระหว่างระดับได้อย่างราบรื่น จากพนักงานกลับไปยังแผนกหรือจากแผนผังองค์กร เส้นทางนำทาง (ออฟฟิศ {'>'} แผนก {'>'} พนักงาน {'>'} แผนผัง) ช่วยให้การเดินทางของคุณเป็นไปอย่างมีสติและอยู่ในเส้นทาง
                    </li>
                  </ul>
                  เส้นทางที่ซับซ้อนของ TeamConnect ชวนให้คุณสำรวจ ทำความเข้าใจ และมีส่วนร่วมกับทุกระดับของแผนกในออฟฟิศของคุณ มอบอำนาจในการนำทางอย่างครบถ้วน
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. การเชื่อมต่อกับบุคลากรในออฟฟิศผ่าน TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  อยากรู้ใครอยู่เบื้องหลังออฟฟิศหรือไม่? คำตอบอยู่เพียงการคลิกเดียวใน TeamConnect ใช้ตัวเลือกจุดไข่ปลา (สามจุด) ข้างชื่อออฟฟิศที่คุณเลือกเพื่อเผยแพร่ชุดของตัวเลือกการโต้ตอบ:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ดูพนักงานในออฟฟิศนี้:</Body1Strong> เปิดเผยรายชื่อทุกคนที่ทำงานอยู่ในออฟฟิศนี้ โดยตัวเลขที่แสดงให้เห็นถึงผู้คนทำให้คุณได้สำรวจชุมชนในออฟฟิศ
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ตัวเลือกในการติดต่อ:</Body1Strong> ทุกการเข้าสู่ระเบียนพนักงานมีไอคอนการกระทำแบบรวดเร็ว ช่วยให้คุณสามารถเริ่มการสนทนา โทร หรือแม้แต่การประชุมทางวิดีโอได้ทันทีจาก TeamConnect ช่วยให้การสื่อสารภายในออฟฟิศเป็นไปอย่างราบรื่น
                    </li>
                    <li><Body1Strong>นำทาง:</Body1Strong> เพื่อกลับไปยังรายการออฟฟิศทั่วไปจากรายการพนักงาน ให้คลิก 'Office' ในเส้นทางนำทาง (Office {'>'} Employee) และคุณจะกลับมาที่ภาพรวมออฟฟิศ
                    </li>
                  </ul>
                  ด้วยคุณสมบัตินี้ TeamConnect ทำให้การมองเห็นการกระจายพนักงานข้ามออฟฟิศและการติดต่อสื่อสารได้อย่างง่ายดาย ส่งเสริมให้สถานที่ทำงานมีความเชื่อมต่อไม่ว่าจะอยู่ห่างไกลเพียงใด
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. ข้อมูลสำนักงานของคุณหายไปหรือล้าสมัยหรือไม่?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  การอัปเดตข้อมูลสำนักงานใน TeamConnect สามารถจัดการได้โดยผู้แก้ไขสำนักงานที่ได้รับมอบหมาย หากคุณไม่แน่ใจเกี่ยวกับผู้แก้ไขสำนักงานหรือต้องการความช่วยเหลือเกี่ยวกับข้อมูลที่อยู่ภายใต้การดูแลของ HR ทีมสนับสนุน HR พร้อมที่จะช่วยเหลือที่ <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>คำถามที่พบบ่อยเกี่ยวกับการตั้งค่า</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. ความสำคัญของการตั้งค่าทั่วไปที่ถูกต้องใน TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ในสภาพแวดล้อมการทำงานที่เปลี่ยนแปลงไปอย่างมากในปัจจุบัน การเชื่อมต่อและประสานงานกับผู้อื่นเป็นสิ่งสำคัญยิ่งกว่าเดิม นั่นคือที่มาของการอัปเดตการตั้งค่าทั่วไปของคุณใน TeamConnect:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>การเลือกที่ทำงาน:</Body1Strong> จับภาพรวมของสภาพแวดล้อมการทำงานของคุณโดยการอัปเดตที่ทำงานของคุณให้สะท้อนสถานการณ์ปัจจุบัน ไม่ว่าจะเป็นการเปลี่ยนจากความสงบของ Home-Office ไปยังความวุ่นวายของ Office หลัก หรือการตั้งรกรากในพื้นที่สาธารณะ ความชัดเจนนี้ในที่ทำงานของคุณนำเสนอข้อมูลเกี่ยวกับวิธีการติดต่อคุณที่ดีที่สุดสำหรับเพื่อนร่วมงาน พร้อมทั้งธรรมชาติของการสนทนาที่พวกเขาสามารถมีส่วนร่วมได้ ซึ่งปกป้องความลับของการสนทนาที่ละเอียดอ่อนโดยเฉพาะเมื่อคุณอยู่ในสภาพแวดล้อมเปิดของสำนักงานลูกค้าหรือนั่งอยู่ในห้องรอสนามบิน
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>รายละเอียดจุดทำงาน:</Body1Strong> การแชร์เมืองที่คุณทำงานไม่เพียงแต่ช่วยเชื่อมต่อระยะทางในการตั้งค่าทีมระยะไกลเท่านั้น แต่ยังเปิดประตูสู่การพบปะทางวิชาชีพกะทันหันหรือการพบปะสังสรรค์อย่างไม่เป็นทางการ ไม่ว่าจะเป็นการดื่มกาแฟเร็วๆ การรับประทานอาหารกลางวันอย่างเพลิดเพลิน หรือการประชุมแบบร่วมมือ การรู้ว่าคุณอยู่ในเมืองเดียวกันสามารถเปลี่ยนแปลงวันธรรมดาให้กลายเป็นโอกาสสำหรับการเชื่อมต่อหน้าต่อหน้าที่มีความหมาย นอกจากนี้ยังเสริมสร้างความสัมพันธ์ในทีมและยังให้ความเข้าใจที่ลึกซึ้งยิ่งขึ้นเกี่ยวกับลักษณะท้องถิ่นที่อาจมีอิทธิพลต่องานของคุณ
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ความถูกต้องของเขตเวลา:</Body1Strong> เนื่องจากทีมมีสมาชิกตั้งอยู่ทั่วทวีป การให้เขตเวลาของคุณสะท้อนถึงสถานที่ตั้งปัจจุบันของคุณจึงเป็นสิ่งจำเป็นสำหรับการประสานงานการสื่อสาร การตั้งเวลาท้องถิ่นในโปรไฟล์ TeamConnect ของคุณได้รับอิทธิพลโดยตรงจากการตั้งค่านี้ ทำให้ไม่ต้องเดาว่าเพื่อนร่วมงานกำลังพยายามติดต่อคุณ
                    </li>
                  </ul>
                  ดำดิ่งไปที่ 'การตั้งค่า' {'>'} 'การตั้งค่าทั่วไป' ภายใน TeamConnect เพื่อปรับแต่งรายละเอียดเหล่านี้ เพียงไม่กี่นาทีที่ใช้ในการอัปเดตการตั้งค่าของคุณสามารถเพิ่มพูนความเข้าใจและประสิทธิภาพของความพยายามในการทำงานร่วมกันของคุณได้อย่างมาก
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. การระบุตัวเลือกการทำงานภายใน TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  การเลือกตัวเลือกที่ใช่จากเมนู Workplace จะช่วยปรับการใช้งาน TeamConnect ให้สะท้อนสถานะการทำงานของคุณได้แม่นยำยิ่งขึ้น โปรดเอื้อมไปที่ไอคอนข้อมูลเพื่อดูรายละเอียดของแต่ละตัวเลือก:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Break:</Body1Strong> แสดงถึงการพักจากงาน ไม่ว่าจะเป็นพักเบรกหรือจบการทำงานในวันนั้น</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office/Field-Office:</Body1Strong> บ่งชี้ถึงการอยู่ในพื้นที่ทำงานที่บริหารจัดการโดยบริษัท</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> การทำงานจากพื้นที่ทำงานภายในบ้าน</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Public-Area:</Body1Strong> การทำงานจากสถานที่นอกสำนักงาน ซึ่งบ่งบอกถึงความต้องการในการสื่อสารอย่างยืดหยุ่น</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Customer Workplace/Private Office:</Body1Strong> การทำงานที่สถานที่ของลูกค้า พร้อมกับหมายเหตุเกี่ยวกับระดับความเป็นส่วนตัวสำหรับความละเอียดในการสนทนา</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Commute/Business-Travel/Training:</Body1Strong> การเดินทางหรือมีกิจกรรมที่เกี่ยวข้องกับงานนอกสถานที่</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Public-Holiday/Vacation/Various Leaves:</Body1Strong> การหยุดงานอย่างเป็นทางการเพื่อเหตุผลส่วนตัว สุขภาพหรือวันหยุด</li>
                  </ul>
                  การเลือกสถานที่ทำงานที่เหมาะสมจะช่วยให้ทีมของคุณมีบริบทที่จำเป็นสำหรับการติดต่อสื่อสาร โดยคำนึงถึงความพร้อมและความเป็นส่วนตัว
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. การใช้งานสถานะ 'Checked-In' ใน TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  แท็ก 'Checked-In' ใน Employee Directory ได้รับการออกแบบมาเพื่อแสดงรายชื่อพนักงานที่กำลังทำงานอยู่ การมองเห็นนี้ขึ้นอยู่กับการเลือก Workplace ที่คุณทำ:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Active Work Environments:</Body1Strong> ตัวเลือกเช่น Office, Field-Office, Home-Office, Remote-Workplace, Public-Area, Customer-Workplace,
                    Customer Private-Office, Commute, Business-Travel, และ Training แสดงว่าคุณกำลังทำงาน ทำให้คุณ 'Checked-In' และมองเห็นได้ชัดเจนต่อเพื่อนร่วมงาน
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Non-Work Settings:</Body1Strong> การเลือกสถานะเช่น Checked-Out, Break, Public-Holiday, Vacation, On-Leave, Maternity-Leave, หรือ Sick-Leave
                      จะทำให้คุณไม่อยู่ในรายการ 'Checked-In' ตั้งค่าความคาดหวังในเรื่องความพร้อมและเวลาในการตอบสนองชัดเจน
                  </li>
                  </ul>
                  ฟังก์ชันนี้ช่วยในการแยกเวลาทำงานออกจากเวลาส่วนตัว ซึ่งช่วยในการทำงานร่วมกันและการสื่อสารในทีมได้เป็นอย่างดี
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>คำถามที่พบบ่อยเกี่ยวกับการจัดการข้อมูลและความเป็นส่วนตัว</Subtitle2>
          <Body1>
            <p>
              ในยุคดิจิทัลปัจจุบัน การเข้าใจวิธีการจัดการข้อมูลของคุณเป็นเรื่องสำคัญ TeamConnect มุ่งมั่นเรื่องความโปร่งใสและแนวทางการปกป้องข้อมูลอย่างมั่นคง
              ด้านล่างนี้คุณจะพบข้อมูลเกี่ยวกับวิธีการที่เราจัดการ จัดเก็บ และปกป้องข้อมูลของคุณภายใน TeamConnect รับประกันว่าพื้นที่ทำงานดิจิทัลของคุณปลอดภัยและเป็นไปตามเกณฑ์
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. TeamConnect ปกป้องข้อมูลส่วนบุคคลของฉันอย่างไร?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect มุ่งมั่นที่จะปกป้องข้อมูลส่วนบุคคลของคุณด้วยแนวทางที่ครอบคลุมต่อความปลอดภัยของข้อมูล:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Single Sign-On (SSO):</Body1Strong> เพื่อเพิ่มความปลอดภัยและปรับปรุงประสบการณ์ผู้ใช้ TeamConnect รวม Single Sign-On (SSO) อัตโนมัติกับบัญชี Microsoft 365 ของคุณ ซึ่งช่วยให้การจัดการการเข้าถึงเป็นไปอย่างปลอดภัยและไม่ยุ่งยาก
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>การเข้ารหัส:</Body1Strong> เราให้ความสำคัญกับการเข้ารหัสข้อมูลภายใน TeamConnect ทั้งในระหว่างการส่งและในขณะที่ไม่มีการใช้งาน ทำให้ไม่สามารถเข้าถึงได้โดยบุคคลที่ไม่ได้รับอนุญาต
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>จำกัดข้อมูล:</Body1Strong> การเก็บข้อมูลของเราจำกัดเฉพาะสิ่งที่จำเป็นสำหรับการทำงานของ TeamConnect เท่านั้น ข้อมูลผู้ใช้ที่เราเก็บคือชื่อหลักผู้ใช้ (UPN) จาก Azure Entra ID ซึ่งอาจคล้ายกับที่อยู่อีเมลของบริษัทของคุณ
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>การเข้าถึงที่จำกัด:</Body1Strong> การเข้าถึง UPN ถูกจำกัดไว้กับผู้ดูแลสมาชิกในองค์กรของคุณภายในพอร์ทัลการสมัครใช้ของเรา ทำให้ข้อมูลที่ละเอียดอ่อนสามารถมองเห็นได้เฉพาะโดยบุคคลที่มีสิทธิ์เท่านั้น
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>การลบข้อมูลทันที:</Body1Strong> TeamConnect ถูกออกแบบให้ลบ UPN ที่เก็บไว้ทันทีที่มีสามสถานการณ์เฉพาะ: หากการสมัครใช้งานถูกยกเลิกโดยผู้ดูแลสมาชิก หากผู้ใช้ถูกลบโดย HR ภายใน TeamConnect หรือหากการสมัครใช้งาน TeamConnect หมดอายุ สิ่งนี้รับประกันว่าข้อมูลส่วนบุคคลของคุณจะไม่ถูกเก็บไว้เกินกว่าที่จำเป็นหรือมีประโยชน์
                    </li>                      
                  </ul>
                  <p>ด้วยมาตรการเหล่านี้ TeamConnect มุ่งมั่นที่จะรักษามาตรฐานสูงสุดของการปกป้องข้อมูล ทำให้ข้อมูลส่วนบุคคลของคุณปลอดภัยและเป็นความลับต่อไป</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. สงสัยว่าข้อมูลของคุณอยู่ที่ไหนเมื่อใช้งาน TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect ทำลายกรอบแบบแผนของผลิตภัณฑ์ SaaS ด้วยการรับประกันว่าข้อมูลของคุณไม่มีวันออกจากองค์กรของคุณ 
                  ไม่เหมือนกับการปฏิบัติทั่วไปที่จัดเก็บข้อมูลบนเซิร์ฟเวอร์คลาวด์ภายนอก, TeamConnect ใช้ประโยชน์จาก Microsoft 365 SharePoint 
                  Online ขององค์กรคุณเพื่อเป็นที่เก็บข้อมูลทั้งหมดของแอปพลิเคชัน วิธีดังกล่าวนี้ไม่เพียงแต่ส่งเสริมเอกราชของข้อมูลเท่านั้น แต่ยังเสริมความปลอดภัย, 
                  สอดคล้องอย่างสมบูรณ์กับความมุ่งมั่นอย่างหนักแน่นของเราในการปกป้องความเป็นส่วนตัวของคุณและปฏิบัติตามมาตรฐานการปฏิบัติตามกฎระเบียบอย่างเคร่งครัด
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. ข้อมูลของฉันจะถูกแชร์กับบุคคลที่สามหรือไม่?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ไม่, ข้อมูลไม่ถูกแชร์กับบุคคลที่สาม! Cubeet มุ่งมั่นในความมุ่งมั่นของเราต่อความเป็นส่วนตัวและความปลอดภัยของข้อมูลของคุณ เราไม่แชร์ข้อมูล TeamConnect ใด ๆ กับบุคคลที่สามโดยไม่ได้รับความยินยอมอย่างชัดเจนจากลูกค้าของเรา 
                  ในสภาพแวดล้อมที่ปลอดภัยของเรา, เราเก็บเพียงตัวระบุที่จำเป็นเท่านั้น เช่น User Principal Name (UPN), ชื่อองค์กร, ชื่อผู้ให้เช่า, และ ID ผู้ให้เช่า, ภายในพอร์ทัลการสมัครใช้งานของเรา 
                  ข้อมูลนี้มีความสำคัญสำหรับการให้บริการและได้รับการคุ้มครองภายใต้นโยบายความเป็นส่วนตัวที่เข้มงวดของเรา 
                  หายห่วง, ปฏิบัติการจัดการข้อมูลของเราถูกออกแบบมาเพื่อให้แน่ใจว่าข้อมูลของคุณจะยังคงเป็นความลับ, โดยมีความโปร่งใสและการควบคุมยังคงอยู่ในมือของคุณอย่างแน่นอน
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. TeamConnect ปฏิบัติตามกฎข้อบังคับในการปกป้องข้อมูลเช่น GDPR ได้อย่างไร?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect สอดคล้องกับกฎระเบียบการปกป้องข้อมูลเช่น GDPR, HIPAA, CCPA และอื่น ๆ โดยการใช้ประโยชน์จากคุณลักษณะการปฏิบัติตามกฎหมายที่มีอยู่แล้วในสภาพแวดล้อม Microsoft 365,
                  ซึ่งจัดเก็บข้อมูลของลูกค้าอย่างปลอดภัย ไม่เหมือนกับแบบจำลอง SaaS แบบดั้งเดิม, TeamConnect ไม่จัดเก็บข้อมูลของลูกค้าใด ๆ บนเซิร์ฟเวอร์ของมัน; แต่ว่าดำเนินการภายใน
                  สภาพแวดล้อม Microsoft 365 ขององค์กรคุณ, ยึดมั่นในมาตรฐานความปลอดภัยและการปฏิบัติตามกฎหมายที่แข็งแกร่ง วิธีการนี้รับประกันว่าการกำกับดูแลข้อมูลของคุณจะสอดคล้องกับ
                  กรอบการปฏิบัติตามกฎระเบียบที่ครอบคลุมซึ่ง Microsoft 365 ให้ไว้, รวมถึงการปฏิบัติตาม GDPR, HIPAA สำหรับข้อมูลด้านสุขภาพ, CCPA สำหรับผู้อยู่อาศัยในแคลิฟอร์เนีย,
                  และกฎหมายการปกป้องข้อมูลระดับโลกอื่น ๆ โดยการผสานรวมอย่างไร้รอยต่อกับการตั้งค่า Microsoft 365 ที่มีอยู่ของคุณ, TeamConnect สนับสนุนให้องค์กรของคุณคงไว้ซึ่งการควบคุมข้อมูลอย่างเต็มที่,
                  ทำให้เป็นไปตามกฎหมายและระเบียบการคุ้มครองข้อมูลที่เข้มงวดที่สุด
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. TeamConnect จัดการข้อมูลจากการสนทนา, การโทร, การโทรวิดีโอ, อีเมล Outlook และการโทรจากระบบโทรศัพท์ใน Microsoft Teams อย่างไร?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect ใช้ประโยชน์จาก Microsoft Graph API เพื่อเปิดใช้งานการโต้ตอบโดยตรงเช่นการสนทนา, การโทร, การโทรวิดีโอ และอีเมล Outlook ภายใน Microsoft Teams,
                  โดยให้ประสบการณ์ผู้ใช้ที่ราบรื่น สำคัญที่สุดคือในขณะที่ TeamConnect เป็นผู้เริ่มการสื่อสารเหล่านี้ แต่เป็นการดำเนินการโดยไม่แทรกแซงกระบวนการสื่อสาร
                  หรือมีความสามารถในการบันทึกหรือเก็บข้อมูลใดๆ ของเนื้อหารายงาน เนื้อหาทั้งหมดของการสนทนา, การโทร และอีเมลจะเกิดขึ้นและได้รับการจัดการโดยตรงใน
                  Microsoft Teams และ Outlook ที่ปลอดภัย โดยปฏิบัติตามมาตรฐานความปลอดภัยและความเป็นส่วนตัวของ Microsoft 365 อย่างเคร่งครัด ซึ่งมั่นใจว่า TeamConnect เสริมสร้าง
                  ความสามารถในการสื่อสารของคุณขณะรักษาความลับและความสมบูรณ์ของข้อมูลของคุณอย่างเต็มที่
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
        <p>
          สำหรับข้อมูลเชิงลึกโดยละเอียดเกี่ยวกับนโยบายและแนวปฏิบัติด้านข้อมูลของเรา คุณอาจต้องการปรึกษา {' '}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
          <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>คู่มือผู้ใช้ TeamConnect</Body1Strong></Link> หรือระบบไอทีขององค์กรของคุณ
            แผนกที่ <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> สำหรับข้อมูลเฉพาะที่เกี่ยวข้องกับการตั้งค่าของคุณ
        </p>
        </Body1>  
        <p></p>  
        <Subtitle1 className={styles.fontColor}>เรากำลังฟัง: แบ่งปันความคิดเห็นและแนวคิดของคุณ</Subtitle1>
        <Body1>
          <p>
            ความเห็นของคุณช่วยพัฒนา TeamConnect ไม่ว่าคุณจะมีไอเดียเจ๋งๆ สำหรับฟีเจอร์หรือมีความเห็นเกี่ยวกับประสบการณ์ปัจจุบันของคุณ, เราพร้อมฟัง ข้อมูลนำเข้าของคุณมีค่าอย่างยิ่งในการตอบสนองความพยายามของเราที่จะให้บริการที่ดีที่สุด
          </p>
        </Body1>      
        <Subtitle2>ความคิดเห็นและข้อเสนอแนะสำหรับฟีเจอร์</Subtitle2>
        <Body1>
          <p>
            พร้อมที่จะแบ่งปัน? ไปที่หน้า {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              หน้าข้อเสนอแนะ
            </Link>
            ไม่ว่าจะเป็นข้อเสนอแนะสำหรับฟีเจอร์ใหม่หรือความเห็นเกี่ยวกับฟังก์ชันที่มีอยู่, แค่เลือกตัวเลือกที่เหมาะสมจากเมนูแบบเลื่อนลงในแบบฟอร์มของเราซึ่งช่วยทำให้
            TeamConnect ตอบสนองและเกินความคาดหมายของคุณ
            </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;