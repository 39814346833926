import sampleSubscription from '../sample/subscription_resolve.json';
import sampleList from '../sample/subscriptionList.json';

/* start resolve purchased subscription */
export const resolveSaasSubscription = async (accessToken, dToken) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      body: JSON.stringify({
        purchasedToken: dToken,
      }),
    };

    if (process.env.NODE_ENV !== 'production') {
      return {
        data: sampleSubscription,
        message: 'this feature is only run in production',
      };
    };

    const url = '/v1/api/saas/subscription/resolve';
    const res = await (await fetch(url, options)).json();

    if (res.data && res.data.code === 'InvalidValue') {
      throw new Error(`${res.data.code}: ${res.data.message}`);
    };

    return res;
  } catch (error) {
    throw error;
  };
};

/* start activate purchased subscription */
export const activeSaasSubscription = async (accessToken, subscriptionId) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      body: JSON.stringify({
        subscriptionId: subscriptionId,
      }),
    };

    if (process.env.NODE_ENV !== 'production') {
      return {
        status: 200,
        statusText: 'this feature is only run in production',
      };
    };

    const url = '/v1/api/saas/subscription/activate';
    const res = await fetch(url, options);
    //console.log('res:', res)

    if (res && res.ok) return res;
    else return res.json();
  } catch (error) {
    throw error;
  };
};

/* get saas subscription list */
export const fetchSaasSubscriptionList = async (accessToken) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
    };

    if (process.env.NODE_ENV !== 'production') {
      return {
        data: {
          subscriptions: sampleList.subscriptions
        },
        message: 'this feature is only run in production',
      };
    };

    const apiKey = process.env.REACT_APP_EXCHANGE_SAAS_FULFILLMENT_TOKEN;
    const url = `/v1/api/saas/subscription/list?code=${apiKey}`;
    const res = await (await fetch(url, options)).json();

    return res;
  } catch (error) {
    throw error;
  };
};

export const fetchSaasSubscriptionListByPurchaser = async (accessToken) => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
    };

    if (process.env.NODE_ENV !== 'production') {
      return {
        data: {
          subscriptions: sampleList.subscriptions
        },
        message: 'this feature is only run in production',
      };
    };

    const apiKey = process.env.REACT_APP_EXCHANGE_SAAS_FULFILLMENT_TOKEN;
    const url = `/v1/api/saas/subscription/purchaser?code=${apiKey}`;
    const res = await (await fetch(url, options)).json();

    return res;
  } catch (error) {
    throw error;
  };
};