import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});
function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>Центр поддержки и ЧаВО TeamConnect</Title3>
        <p><Body1>
          Добро пожаловать в Центр поддержки TeamConnect, созданный для поддержки вашего опыта использования нашего комплексного корпоративного каталога приложений. Здесь вы найдете ресурсы для оптимизации использования TeamConnect в Microsoft Teams, улучшая коммуникацию в вашей сети.
        </Body1></p>
        <Subtitle2>Краткое руководство</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Расширенные возможности поиска: </Body1Strong>
              Быстро находите нужных коллег, отделы и важные контакты с помощью наших мощных инструментов поиска.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Визуальные организационные схемы: </Body1Strong>
              Понимайте иерархию вашей организации и линии отчетности с легкостью благодаря интуитивно понятным организационным схемам.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Адаптивные инструменты для современных нужд: </Body1Strong>
              Используйте современные функции, адаптированные к требованиям современных рабочих мест.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Оптимизация вашего опыта</Subtitle2>
        <Body1>
          <p>
            TeamConnect революционизирует способ взаимодействия в рамках вашей организационной структуры, превращая повседневные коммуникации в стратегические взаимодействия.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Получите помощь и ЧаВО</Subtitle1>
        <Body1>
          <p>
            Найдите ответы на частые вопросы о функциях и настройках TeamConnect в этом специальном разделе. Будь то навигация по приложению, настройка параметров или поиск решений для проблем, наш Центр поддержки готов помочь вам эффективно.
          </p>
        </Body1>
        <Body1>
          <p>
            Для более глубокой поддержки или конкретных рекомендаций, пожалуйста, обратитесь к <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>Руководству пользователя TeamConnect</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>       
        <Subtitle2>Использование TeamConnect: Часто задаваемые вопросы</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Не можете найти TeamConnect в боковой панели Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Если TeamConnect не отображается или исчез в боковой панели Microsoft Teams, вы можете легко закрепить его для быстрого доступа. Просто следуйте этим простым шагам:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Откройте Microsoft Teams и нажмите на значок '...' (+ Приложения) в нижней части боковой панели.</li>
                    <li className={styles.bulletBottomMargin}>Поищите 'TeamConnect' в строке поиска в верхней части всплывающего окна.</li>
                    <li className={styles.bulletBottomMargin}>Щелкните правой кнопкой мыши по приложению TeamConnect в результатах поиска и выберите 'Закрепить'.</li>
                  </ol>
                  Теперь TeamConnect будет закреплен в вашей боковой панели, что обеспечит доступ к нему всего в один клик!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Столкнулись с техническими проблемами в TeamConnect? Вот как получить помощь.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Если у вас возникли технические проблемы с TeamConnect в Microsoft Teams, команда IT-поддержки вашей организации к вашим услугам.
                  Обратитесь к ним по адресу <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, и они помогут вам оперативно.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Не видите свои сведения о сотруднике?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Если в вашем профиле отображаются только ваше имя и электронная почта, вероятно, ваша команда по работе с персоналом еще не добавила остальные сведения о сотруднике в TeamConnect.
                  Не стоит переживать! Быстрое сообщение вашей команде поддержки HR приведет все в движение. Они готовы заполнить ваш профиль всеми важными деталями.
                  Обратитесь по адресу <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> и увидите, как ваш профиль оживет!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Какие языковые опции доступны в TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect охватывает глобальное разнообразие Microsoft Teams,
                  поддерживая все его языки. Будь то арабский или греческий, японский или
                  шведский, у вас есть мир возможностей под рукой, включая:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Арабский, болгарский, китайский (упрощенный и традиционный),
                      хорватский, чешский, датский, голландский, английский, эстонский,
                      финский, французский, немецкий, греческий, иврит, венгерский,
                      индонезийский, итальянский, японский, корейский, латышский, литовский,
                      норвежский (букмол), польский, португальский (Бразилия и Португалия),
                      румынский, русский, сербский (латиница), словацкий, словенский,
                      испанский, шведский, тайский, турецкий, украинский, вьетнамский.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Чтобы настроить ваш опыт работы с TeamConnect на предпочитаемом вами
                  языке, пожалуйста, настройте языковые параметры напрямую в Microsoft
                  Teams, так как TeamConnect полностью интегрируется с этими настройками:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Перейдите в настройки Microsoft Teams, кликнув по трем точкам рядом с
                      вашим профильным фото в верхней части, затем выберите 'Внешний вид и
                      доступность'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Прокрутите до раздела 'Язык' и выберите предпочитаемый вами язык из
                      выпадающего меню.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Подтвердите ваши изменения, нажав кнопку 'Сохранить и перезапустить',
                      чтобы применить новые настройки языка.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Примечание: </Body1Strong>
                <Body1>
                  Изменения, сделанные в настройках языка в Microsoft Teams, также бесшовно
                  обновят ваш опыт работы с TeamConnect. Обратите внимание, что изменение
                  языка в Teams может также скорректировать формат вашего времени и даты. В
                  настоящее время, TeamConnect развивается для поддержки письма справа
                  налево. Мы рады этим улучшениям и будем держать вас в курсе, когда они
                  станут доступны.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Как изменить тему в TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect синхронизируется с вашими предпочтениями темы в Microsoft Teams, включая варианты Светлая, Темная и Высокий контраст. Чтобы настроить свою тему:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Перейдите в настройки Microsoft Teams, нажав на три точки рядом с изображением вашего профиля вверху.</li>
                    <li className={styles.bulletBottomMargin}>Выберите "Внешний вид и доступность", чтобы изучить варианты тем.</li>
                    <li className={styles.bulletBottomMargin}>Выберите предпочтительную тему из доступных вариантов, чтобы мгновенно обновить интерфейс TeamConnect, чтобы он соответствовал выбору.</li>
                  </ol>
                  Ваш выбор темы в Microsoft Teams будет автоматически применен к TeamConnect, обеспечивая единообразный визуальный опыт по всему вашему рабочему пространству.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Изучение кнопок действий контактов в TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  В TeamConnect, где бы вы ни нашли контактные данные сотрудника, вы также обнаружите наши удобные кнопки быстрого доступа. Эти полезные инструменты
                  предназначены для оптимизации вашего общения в рамках экосистемы Microsoft, гарантируя, что вы всегда в один клик от возможности связаться.
                  Вот ваше полное руководство по эффективному использованию этих кнопок:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Иконка чата:</Body1Strong> Нажмите, чтобы начать мгновенный чат в Microsoft Teams. Если вам нужно вернуться в TeamConnect после начала чата, просто используйте стрелку назад в настольном приложении, кнопку назад в мобильном приложении или кнопку назад в браузере.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Иконка электронной почты:</Body1Strong> Открывает новый черновик электронного письма в Outlook с готовым адресом получателя в поле "Кому:". Если Outlook не запускается, стоит проверить с вашим IT-отделом наличие ограничений.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Иконка видеозвонка:</Body1Strong> Инициирует видеозвонок лицом к лицу с использованием Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Иконка звонка:</Body1Strong> Начинает голосовой звонок в Microsoft Teams, делая короткие обсуждения беспрепятственными.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Иконка организационной схемы:</Body1Strong> Показывает место сотрудника в организационной иерархии, включая его менеджера и непосредственных подчиненных.</li>
                    <li><Body1Strong>Иконка "Еще (...)":</Body1Strong> Показывает дополнительные опции:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Встреча сейчас:</Body1Strong> Открывает Microsoft Teams для немедленного начала встречи с сотрудником.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Новая встреча:</Body1Strong> Направляет вас в Microsoft Teams для планирования новой встречи.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Добавить контакт:</Body1Strong> Добавляет контактные данные сотрудника из TeamConnect в новое приложение People в Microsoft Teams, обогащая вашу функцию вызова.</li>
                      </ul>
                    </li>
                  </ul>
                  Эти интуитивно понятные кнопки созданы для того, чтобы вы могли эффективно связаться с коллегами, будь то для быстрого разговора, отправки электронной почты или планирования будущих встреч.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Совершение звонков через TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Хотите совершить звонок? TeamConnect оптимизирует ваше общение, интегрируясь с Microsoft Teams. 
                  Просто нажмите на желаемый номер телефона в TeamConnect, и вот, интерфейс вызова Microsoft Teams появляется, 
                  готовый для того, чтобы вы могли без проблем набрать номер.
                  <p>
                  <Body1Strong>Примечание:</Body1Strong> 
                  Имеете проблемы с звонками? Это может быть связано с политиками IT вашей организации или использованием телефонной системы, отличной от системы Microsoft Teams Phone System. Обращение в ваш IT-отдел по адресу <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, может прояснить ситуацию.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Существуют ли расходы, связанные с совершением звонков через TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect улучшает ваш опыт совершения звонков, используя Microsoft Teams. Вот что вам нужно знать о стоимости звонков:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      За звонки на рабочие телефоны сотрудников, телефоны отделов или горячие линии внутри вашей организации (внутренние номера),
                      обычно <Body1Strong>нет расходов</Body1Strong> при использовании телефонной системы Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Для внешних звонков, таких как звонки на мобильные телефоны или на внешние линии, применяются <Body1Strong>стандартные тарифы</Body1Strong> телефонной системы вашей организации.
                      Эти тарифы варьируются в зависимости от вашего конкретного тарифного плана с Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Примечание:</Body1Strong> Если у вас возникли проблемы или есть вопросы о расчетах за звонки, лучше всего проконсультироваться с 
                    вашим IT-отделом по адресу <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> для получения подробной информации,
                    адаптированной под настройки и политики вашей организации.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Обнаружили несоответствие с вашим статусом "Вне офиса" в TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Заметили, что ваш статус "Вне офиса" из Microsoft 365 не отражается корректно в TeamConnect? 
                  Мы уже позаботились о этом. Функция синхронизации статуса "Вне офиса" уже внедрена в TeamConnect.
                  Мы в настоящее время ожидаем внедрения этой функции от Microsoft. Как только это произойдет, ваш статус "Вне офиса"
                  будет точно отображаться в TeamConnect, что будет соответствовать индикаторам присутствия в Microsoft Teams для бесперебойной работы.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>Часто задаваемые вопросы каталога сотрудников</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >Введение в каталог сотрудников:</Body1Strong> Осваивайте профессиональную среду вашей организации с помощью каталога сотрудников,
                вашего центрального ресурса для получения подробных сведений о сотрудниках, динамики рабочего места и прямого доступа к профилям ваших коллег и управленческим связям.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Необходимо добавить, обновить или исправить ваши данные сотрудника?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Для добавления, обновления или исправления ваших данных сотрудника в TeamConnect, пожалуйста, свяжитесь с командой поддержки HR вашей организации.
                  Они готовы помочь по адресу <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Что происходит, когда я нажимаю на имя сотрудника в каталоге?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Нажатие на имя сотрудника в Справочнике сотрудников TeamConnect открывает подробную страницу профиля данного лица. Этот профиль предоставляет исчерпывающую информацию о сотруднике, включая, но не ограничиваясь, его должность, отдел, местоположение офиса, контактные данные и многое другое. Он создан для того, чтобы дать вам полное представление о профессиональной роли сотрудника и его месте в организации, что облегчает понимание его обязанностей и возможностей сотрудничества с ним.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Какая информация включена в профиль сотрудника?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Каждый профиль сотрудника в TeamConnect представляет собой всесторонний снимок, предлагающий богатый объем информации о профессиональной жизни лица. Вот что вы найдете:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Основные данные:</Body1Strong> Имя, должность, отдел и местоположение филиала.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Схема отчетности:</Body1Strong> Информация о прямых и функциональных подчинениях, включая линейных и функциональных менеджеров.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Статус на работе:</Body1Strong> Текущий статус работы, такой как отпуск, путь на работу, командировка и т.д.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Рабочее место и местное время:</Body1Strong> Текущее местоположение работы сотрудника и местный часовой пояс.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Контактные данные:</Body1Strong> Рабочий телефон, мобильный номер и адрес электронной почты.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Адрес офиса:</Body1Strong> Полное местоположение офиса, включая название здания, адрес и карту Google.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>О себе:</Body1Strong> Краткое личное представление или профессиональная биография.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Отношения с сотрудниками:</Body1Strong> Детали структуры подчинения и взаимоотношений в команде.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Социальные связи и языки:</Body1Strong> Ссылки на профессиональные социальные профили и владение языками.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Навыки:</Body1Strong> Список профессиональных навыков и областей экспертизы.</li>
                  </ul>
                  Кроме того, профиль предлагает практичные возможности связаться через чат, электронную почту, видеозвонок или запланировать встречи, а также быстрый доступ к организационной схеме для более широкого контекста команды.
                </Body1>
              </AccordionPanel>
            </AccordionItem>           
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Как я могу обновить информацию в своем профиле?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Персонализировать ваш профиль в TeamConnect очень просто. Вот как это можно сделать:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Перейдите на вкладку <Body1Strong>Employee</Body1Strong> и нажмите на ваш <Body1Strong>Avatar</Body1Strong>, чтобы открыть ваш профиль.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        В верхнем левом углу вы найдете ссылку <Body1Strong>"Edit your profile"</Body1Strong>. 
                        Нажатие на неё откроет диалог редактирования вашего профиля.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Здесь вы можете улучшить ваш профиль, добавив или обновив ваш раздел <Body1Strong>About You</Body1Strong>, ссылки на социальные сети и номер телефона. 
                        Это упростит вашим коллегам возможность связываться с вами или подписываться на вас.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Не забудьте нажать кнопку <Body1Strong>Save</Body1Strong>, чтобы применить изменения.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Прокрутите диалог вниз, чтобы добавить ваш <Body1Strong>Languages</Body1Strong> и <Body1Strong>Proficiency</Body1Strong>, 
                        а также ваш <Body1Strong>Skills</Body1Strong>. Поделиться этой информацией помогает вашим коллегам понять 
                        ваши области экспертизы и языки, на которых вы можете общаться.
                      </li>
                    </ol>
                  Обновление вашего профиля не только поддерживает актуальность вашей информации, но и способствует лучшему взаимодействию внутри вашей организации.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Обновление вашего рабочего места, рабочего участка и часового пояса в TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Поддержание актуальной информации о вашем рабочем месте, рабочем участке и часовом поясе крайне важно для бесперебойного сотрудничества в Microsoft Teams. 
                  Эти обновления мгновенно отображаются в вашем профиле и списках TeamConnect, что позволяет коллегам быстро понять ваше местоположение 
                  и лучшее время для связи с вами, повышает командную работу в разных регионах и часовых поясах.
                  <p></p>
                  Готовы обновить свои данные? Это легко в TeamConnect! Перейдите в 'Settings' в верхнем меню и выберите 'General Settings'. 
                  Здесь вы можете легко обновить ваше рабочее место и часовой пояс, а также указать ваш рабочий участок на день. Не забудьте сохранить изменения, чтобы 
                  все были в курсе и для обеспечения эффективного сотрудничества, независимо от вашего местоположения!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Заметили что-то странное в профиле коллеги?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Если вы заметили какие-либо детали в профиле коллеги, которые кажутся вам неправильными, лучший первый шаг - дать им дружеское предупреждение. Быстрый разговор или электронное письмо должны сработать! Если по какой-то причине информация не будет обновлена, не беспокойтесь - просто свяжитесь с вашей командой поддержки HR по адресу <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, и они будут рады вмешаться и уладить вопрос.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Понимание групп тегов: Все, Избранные, Кто на месте и Новые сотрудники
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Все:</Body1Strong> Этот тег по умолчанию отображает всех сотрудников вашей организации,
                      предоставляя полное представление о команде.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Избранные:</Body1Strong> Нажмите здесь, чтобы фильтровать ваш просмотр на тех коллег,
                      которых вы отметили как 'Избранные', что упрощает быструю связь с ключевыми контактами.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Кто на месте:</Body1Strong> Этот тег указывает на сотрудников, которые активно находятся на работе, отличая
                      их от простого онлайн-присутствия в Microsoft Teams. Когда отмечены как на месте, сотрудники сообщают, что они на работе и делятся своим текущим рабочим местоположением
                      в течение дня. Это может включать разнообразные рабочие места, такие как Офис, Полевой офис, Домашний офис, Удалённое рабочее место, Клиентское рабочее место,
                      Частный офис клиента, Публичная зона, Поездки, Командировки или даже Обучение. Таким образом, статус 'Кто на месте' ясно указывает
                      на то, что человек активно находится на работе, предоставляя коллегам точное представление о текущем контексте работы и доступности каждого.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Новые сотрудники:</Body1Strong> Найдите новые лица в TeamConnect с этим тегом, выделяя
                      сотрудников, которых добавили в приложение в последние 30 дней. Обычно это соответствует недавним прибавлениям к вашей организации, помогая
                      приветствовать новых членов команды.
                    </li>
                  </ul>
                  Эти группы тегов предназначены для упрощения навигации и повышения удобства использования TeamConnect, обеспечивая всегда возможность 
                  эффективно находить и связываться с вашими коллегами.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Расшифровка иконок рядом с поисковой строкой
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Справочник сотрудников TeamConnect создан для универсальности и удобства, включая четыре иконки, которые предлагают различные виды
                  и быстрый доступ к ключевым деталям. Вот что каждая иконка раскрывает:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Вид списком:</Body1Strong> Ваш основной вид, представляющий всех членов организации
                      в простом списке.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Маленькие карточки:</Body1Strong> Компактная альтернатива, преобразующая список
                      в маленькие карточки сотрудников.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Большие карточки:</Body1Strong> Выберите более детальное изображение с большими карточками, которые включают
                      дополнительные варианты связи, такие как телефон и мобильные номера для удобного набора.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Организационная схема:</Body1Strong> Визуализируйте структуру компании с помощью представления организационной схемы,
                      идеально подходящего для понимания отчётных отношений и навигации по организационному ландшафту.</li>
                  </ol>
                  Переключайтесь между этими иконками, чтобы найти вид, который лучше всего подходит вам в данный момент, будь то поиск быстрой информации или более глубоких пониманий.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Где я могу быстро найти коллегу?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ищете коллегу? Просто перейдите к поисковой строке TeamConnect, расположенной в верхней правой части рядом с иконкой организационной схемы.
                  Начните вводить их имя, и смотрите, как результаты становятся более точными с каждой введённой буквой. Помните, для поиска в TeamConnect пропустите
                  поисковую строку Microsoft Teams в верхней части — наша специальная поисковая строка — ваш выбор!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Как работает алфавитный поиск для нахождения сотрудников?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Справочник сотрудников TeamConnect включает удобную функцию алфавитного поиска, которая помогает быстро находить сотрудников по их имени.
                  Просто нажмите на букву в алфавите, отображаемую в верхней части каталога, и TeamConnect мгновенно покажет вам всех сотрудников,
                  чьи имена начинаются с этой буквы. Чтобы вернуться к полному списку сотрудников, просто снимите выделение с выделенной буквы.
                  Эта интуитивно понятная функция облегчает навигацию по большим спискам сотрудников, обеспечивая быстрый и эффективный поиск коллег.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Как я могу настроить количество сотрудников, отображаемых на странице?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect позволяет настраивать количество профилей сотрудников, которые вы видите на странице, делая навигацию по справочнику сотрудников более удобной.
                  В нижней части каждой страницы вы найдете опции пагинации: 10, 50, 100, 500, 1000 и 5000. По умолчанию отображается 10 профилей на страницу.
                  Чтобы просмотреть больше профилей на одной странице, просто выберите нужное вам количество из этих опций. Эта функция предоставляет гибкость в том,
                  как вы просматриваете каталог, позволяя настроить отображение в соответствии с вашими конкретными потребностями или предпочтениями.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Как я могу сортировать информацию о сотрудниках в режиме списка?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  В режиме списка справочника сотрудников TeamConnect у вас есть возможность сортировать информацию о сотрудниках в соответствии с вашими потребностями.
                  Колонки — Имя сотрудника, Должность, Отдел, Местоположение офиса и Рабочее место — все они могут быть отсортированы. Просто нажмите на заголовок колонки,
                  по которому вы хотите сортировать. Один клик сортирует колонку по возрастанию, повторный клик — по убыванию. По умолчанию каталог сортируется по Имени сотрудника
                  в алфавитном порядке на основе имени. Эта функция сортировки помогает вам быстро организовать и просматривать информацию о сотрудниках таким образом,
                  который наилучшим образом соответствует вашим целям, будь то поиск конкретного человека или группировка сотрудников по определенному критерию, такому как отдел
                  или местоположение.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Часто задаваемые вопросы о директории отделов</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Введение в директорию отделов:</Body1Strong> Директория отделов — это ваш путь к пониманию сложной структуры вашей 
              организации, предоставляющий четкое представление о иерархии отделов, руководстве и путях для бесперебойного департаментального общения.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Как работают теги 'Все', 'Избранное' и 'Ваш отдел' в директории отделов?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  В директории отделов TeamConnect теги помогают вам быстро навигировать и фильтровать информацию отделов. Вот что представляет каждый тег:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Все:</Body1Strong> Этот тег отображает все отделы вашей организации, предоставляя 
                      комплексный обзор.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Избранное:</Body1Strong> Используйте этот тег для просмотра отобранного списка отделов, которые вы 
                      отметили как 'Избранное', облегчая доступ к отделам, с которыми вы часто взаимодействуете.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ваш отдел:</Body1Strong> Этот тег фильтрует представление, чтобы показывать только ваш отдел, 
                      позволяя быстро получить доступ к информации и обновлениям вашего непосредственного отдела.</li>
                  </ul>
                  Эти теги разработаны для упрощения вашей навигации в директории отделов, повышая вашу способность быстро находить и взаимодействовать с информацией отделов.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Понимание представлений директории отделов в TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Навигация по директории отделов в TeamConnect упрощена благодаря множеству вариантов представления, которые соответствуют вашим предпочтениям. Каждое представление предлагает уникальный способ исследовать комплексную информацию отдела, включая Название отдела, Руководителя отдела, Вышестоящий отдел, Телефон отдела, Горячая линия отдела и Электронную почту отдела. Вот краткий обзор доступных представлений:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Представление списком:</Body1Strong> Начальная настройка, которая аккуратно перечисляет все отделы, обеспечивает ясный, сжатый обзор.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Представление большими карточками:</Body1Strong> Переключитесь на это представление, чтобы видеть детализированные карточки отделов, предлагающие расширенное резюме ключевой информации каждого отдела.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Представление организационной схемой:</Body1Strong> Для визуального представления структуры отделов и 
                      взаимоотношений представление организационной схемы иллюстрирует, как каждый отдел вписывается в более широкую иерархию организации.
                    </li>
                  </ul>
                  Для переключения между этими представлениями просто используйте иконки рядом с поисковой строкой в верхней части директории отделов. Каждое представление разработано для того, чтобы вы могли легко получить доступ и понимание подробностей отделов, обеспечивая нахождение нужной информации в формате, который лучше всего подходит для вас.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Как использовать поисковое окно в справочнике отделов
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Быстро находите любой отдел в TeamConnect, используя специальное поисковое окно, расположенное рядом с иконками просмотра в верхней части 
                  Справочника отделов. Просто начните вводить название отдела, который вас интересует, и наблюдайте, как справочник сам отфильтрует и покажет 
                  только релевантные результаты. Эта функция специально разработана, чтобы помочь вам обойти необходимость вручную просматривать весь справочник, 
                  предоставляя необходимый отдел прямо на ваших кончиках пальцев. Помните, что эта функция поиска уникальна для Справочника отделов TeamConnect, 
                  поэтому используйте именно это поисковое окно, а не основной поиск Microsoft Teams вверху интерфейса Teams для запросов, касающихся конкретных отделов.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Как работает алфавитный фильтр в справочнике отделов?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Упростите свой поиск в Справочнике отделов с помощью алфавитного фильтра, расположенного вверху. Эта функция позволяет вам быстро сузить 
                  выбор отделов, выбрав букву. Как только вы выберете букву, TeamConnect отфильтрует и покажет только те отделы, названия которых начинаются 
                  с выбранной вами буквы, что облегчает поиск нужной информации без необходимости пролистывать весь список. Чтобы вернуться к полному просмотру 
                  отделов, просто снимите выделение с выбранной буквы. Это интуитивно понятное алфавитное навигация гарантирует, что вы можете эффективно найти 
                  любой отдел по его начальной букве.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Как я могу связаться с менеджером отдела?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Связаться с менеджером отдела в TeamConnect просто. Просто найдите интересующий отдел и нажмите на имя менеджера. Это действие вызовет всплывающее 
                  окно, отображающее варианты связи с менеджером с знакомыми иконками быстрого доступа:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Иконка чата:</Body1Strong> Для начала мгновенного сообщения через чат Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Иконка почты:</Body1Strong> Чтобы открыть новое письмо, адресованное им в Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Иконка видеозвонка:</Body1Strong> Для начала видеозвонка через Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Иконка звонка:</Body1Strong> Для начала голосового вызова с использованием Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Иконка «Ещё (...)»:</Body1Strong> Для дополнительных опций, таких как запланировать встречу или добавить 
                      их контактную информацию в ваш список Teams.
                    </li>
                  </ul>
                  Эти иконки предоставляют простой способ напрямую связаться с менеджерами отделов внутри TeamConnect, обеспечивая эффективную коммуникацию 
                  в вашей организации.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Как мне просмотреть или связаться с сотрудниками внутри отдела?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Чтобы узнать, кто есть кто в отделе в TeamConnect, обратите внимание на три точки рядом с названием отдела. Нажав на них, вы увидите опции для дальнейших действий:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Просмотреть сотрудников этого отдела:</Body1Strong> Выберите этот пункт, чтобы отобразить список всех 
                      сотрудников, связанных с отделом. Число рядом с этим вариантом показывает, сколько сотрудников находится в отделе, что позволяет быстро подсчитать их количество.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Организационная схема отдела:</Body1Strong> Если вы хотите понять иерархию отдела, 
                      выберите "Организационная схема отдела", чтобы увидеть структурную раскладку, демонстрирующую взаимосвязь между головным отделом, 
                      данным отделом и любыми подотделами. Это наглядное представление помогает вам навигировать и понимать организационную структуру, 
                      облегчая идентификацию и связь с конкретными членами отдела.
                    </li>
                    <li><Body1Strong>Отдел {'>'} Сотрудник:</Body1Strong> После просмотра сотрудников внутри отдела используйте этот путь, чтобы легко 
                      вернуться к списку отделов, сохраняя плавный опыт исследования в TeamConnect.
                    </li>
                  </ul>
                  Эти функции позволяют вам не только видеть список сотрудников данного отдела, но также предлагают возможность напрямую связаться с ними через 
                  коммуникационные инструменты TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Открываем подробные сведения об отделах в TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Погрузитесь в детали любого отдела, просто кликнув на его имя в TeamConnect. Появится подробный диалог, предлагающий богатую палитру 
                  информации, которая создает полное представление о роли и ресурсах отдела в вашей организации. Вы найдете все, от лидерства и 
                  финансовых идентификаторов до коммуникационных каналов и социальных ссылок. Эта функция является вашим доступом к:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Сердцебиение отдела – его цели, люди и показатели эффективности.</li>
                    <li className={styles.bulletBottomMargin}>Пути связи – прямые линии к ключевым фигурам отдела и платформы коммуникаций.</li>
                    <li className={styles.bulletBottomMargin}>Организационная сеть – понимание того, как отдел вписывается в более широкую структуру компании.</li>
                  </ul>
                  Независимо от того, нужен ли вам быстрый запрос или глубокое погружение в данные отдела, TeamConnect оснащает вас инструментами для связи и понимания 
                  уникальных экосистем внутри вашей организации.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Интересует отсутствие адресов отделов в TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Поиск местоположения отдела иногда может показаться поиском сокровищ без карты. В TeamConnect обычно не указан постоянный адрес каждого отдела. Вот почему эта картографическая деталь часто опускается:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Несколько местоположений:</Body1Strong> Многие отделы не ограничиваются одним пространством; они
                      процветают в различных офисах и географиях.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Подвижная структура:</Body1Strong> Организационный ландшафт постоянно меняется, с 
                      отделами, которые эволюционируют, сливаются или даже распадаются в соответствии со стратегическими сдвигами.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Межотделочное сотрудничество:</Body1Strong> Современные рабочие пространства живут 
                      духом сотрудничества, часто объединяя таланты из разных отделов в общих пространствах.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Культура удаленной работы:</Body1Strong> Благодаря гибкости удаленной работы, 
                      якоря отделов не всегда закрепляются в физических офисных помещениях.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Юридические нюансы:</Body1Strong> Отделы обычно ориентированы на юридическую 
                      структуру компании, а не имеют собственных отдельных адресов.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Оптимизация работы:</Body1Strong> Сосредоточение внимания на динамичной командной 
                      работе, а не на статичных адресах, помогает поддерживать операционную гибкость.
                    </li>
                  </ul>
                  Все дело в принятии подхода без границ к командной работе и эффективности. TeamConnect отражает эту философию, акцентируя внимание на связях, а не на местоположениях.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Отсутствует или устарела информация о вашем департаменте?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Редакторы департаментов в вашей организации назначены для добавления, обновления или исправления специфической информации в TeamConnect.
                  Если вы не уверены, кто ваш редактор департамента, или если некоторая информация требует вмешательства команды поддержки HR,
                  пожалуйста, свяжитесь с <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> за руководством.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Часто задаваемые вопросы о справочнике офисов</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Введение в справочник офисов:</Body1Strong> Узнайте о важнейшей части физического присутствия вашей организации через справочник офисов,
                который включает в себя основные сведения об офисе, операционные детали и подробный обзор департаментов и персонала каждого местоположения.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Навигация по тегам в справочнике офисов
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Легко ориентируйтесь в справочнике офисов с помощью нашей системы тегов, разработанной для быстрого доступа к нужному местоположению. Вот как каждый тег может помочь оптимизировать ваш поиск:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Все:</Body1Strong> Показывает полный список офисов, предоставляя обзор 
                      местоположений вашей организации.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Избранное:</Body1Strong> Помогает сосредоточиться на тех местах офисов, которые вы часто посещаете или за которыми вам нужно пристально следить, отмечая их как 'Избранное'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ваш офис:</Body1Strong> Этот личный тег фильтрует директорию, чтобы показать вам офис,
                      где вы находитесь, делая вашу базу всегда под рукой.
                    </li>
                  </ul>
                  Будь то бронирование переговорной или планирование визита, эти теги предлагают персонализированный и упрощенный способ взаимодействия с географическим ландшафтом вашей организации.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
           <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Овладение просмотром каталога офисов в TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Погрузитесь в организационную структуру различных местоположений вашей компании с помощью функции просмотра каталога офисов в TeamConnect. Каждый вид оформлен так, чтобы облегчить 
                  уникальный аспект навигации и понимания офиса:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Вид списка:</Body1Strong> Стандартная настройка, аккуратно организующая все местоположения офисов в 
                      легко читаемый список.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Вид больших карточек:</Body1Strong> Вид больших карточек в каталоге офисов обогащает ваш 
                      просмотр подробной презентацией каждого офиса. При взаимодействии с карточкой офиса вы найдете множество информации и действий 
                      доступных в меню "Ещё (...)" :
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Просмотр отделов:</Body1Strong> Непосредственно переходите к списку
                          всех отделов, расположенных в офисе.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Просмотр сотрудников:</Body1Strong> Получите доступ к полному списку сотрудников, назначенных на 
                          офис, облегчая прямую связь или исследование офисного персонала.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Организационная диаграмма юридического лица:</Body1Strong> Визуализируйте место офиса в более широком
                          организационном контексте для чёткого понимания иерархических структур.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Основные контакты:</Body1Strong> Идентифицируйте ключевые контакты офиса для упрощения 
                          коммуникации.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Праздники офиса:</Body1Strong> Оставайтесь в курсе специфических для офиса публичных 
                          праздников, чтобы соответствующим образом планировать свои графики.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Рабочие часы офиса:</Body1Strong> Просмотрите рабочие часы офиса, чтобы согласовать свои 
                          визиты или звонки с их активными периодами.
                        </li>
                      </ol>
                      Этот вид не только предоставляет визуальный и информационный снимок, но и быстрый доступ к более глубоким аналитическим данным и прямым действиям, связанным с офисом, все аккуратно расположенные под опцией "Ещё (...)" для вашего удобства.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Вид организационной диаграммы:</Body1Strong> Визуализируйте иерархическую структуру материнских и дочерних офисов 
                      для понимания взаимосвязанности различных местонахождений офисов в вашей организации.
                    </li>
                  </ul>
                  Переключайтесь между этими видами с помощью иконок рядом с полем для поиска, чтобы настроить ваш опыт просмотра в соответствии с вашими непосредственными потребностями.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Быстрый поиск офисов с помощью функции поиска TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Нахождение нужного местоположения офиса в TeamConnect упрощается с помощью специальной функции поиска. Расположенное на видном месте рядом с иконками видов, поле поиска является вашим инструментом для точного указания офисов по названию. По мере ввода каталог фильтрует отображение, показывая только офисы, соответствующие вашему запросу, оптимизируя ваш процесс поиска. Важно использовать это поле поиска в TeamConnect для получения наиболее точных и актуальных результатов, в отличие от общего поиска Microsoft Teams. С этой целевой функцией поиска вы можете быстро перейти в любой офис в сети вашей организации.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Навигация по офисному каталогу по алфавиту
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Если вы предпочитаете поиск по алфавиту, наш алфавитный фильтр позволит вам быстро найти офисы по названию. В верхней части каталога офисов вы увидите буквы с A по Z. Клик по букве приводит к тому, что отображаются только те офисы, которые начинаются с этой буквы, упрощая ваш поиск. Чтобы сбросить фильтр и снова увидеть все офисы, просто снимите выбор с активной буквы. Эта функция особенно полезна, когда вы знаете название офиса, но вам нужен быстрый способ его найти в TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Раскрытие подробных профилей офисов в TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Отправляйтесь в исследовательское путешествие в самое сердце физического присутствия вашей организации с профилями офисов в TeamConnect. 
                  Клик по названию офиса открывает яркий диалог профиля, наполненный вкладками с подробной информацией о офисе:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Обзор:</Body1Strong> Погрузитесь в суть идентичности офиса с подробными описаниями, включая бизнес-структуру, часы работы и местное время, охватывая суть офисной среды.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Контакты для связи:</Body1Strong> Получите доступ к ключевым контактным данным для экстренных служб и внешней поддержки, каждая доступна одним кликом через Microsoft Teams Phone System.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Контакты офиса:</Body1Strong> Легко свяжитесь с внутренними службами поддержки, имея под рукой контактную информацию и прямые опции общения.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Государственные праздники:</Body1Strong> Будьте в курсе праздников, специфичных для офиса, чтобы ваши планы соответствовали местным традициям и рабочему графику.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Часы работы:</Body1Strong> Планируйте свои взаимодействия с точным знанием о том, когда в офисе наиболее активная деятельность или наступает конец дня.
                    </li>
                  </ul>
                  Будь то энергичный Остин или стратегический центр Сингапура, каждая вкладка предоставляет возможность познакомиться и связаться с разнообразными местами, формирующими глобальное присутствие вашей компании.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Изучение динамики отделов в офисе
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Навигация по сети отделов офиса становится простой в TeamConnect. Вот как можно углубиться в детали:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Просмотр отделов в этом офисе:</Body1Strong> Откройте для себя все отделы, которые составляют 
                      структуру офиса, выбрав этот вариант в меню "Еще (...)".
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Организационные подробности:</Body1Strong> Нажмите на любой отдел, чтобы показать его организационную 
                      схему для визуального представления его структуры, с навигационной цепочкой, которая ведет вас: Офис {'>'} Отдел {'>'} Схема.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Погружение в отдел:</Body1Strong> Более подробно изучите отдел, нажав на меню "Еще (...)" рядом 
                      с названием отдела. Здесь вы можете получить доступ к списку всех сотрудников в этом отделе, прямо в контексте офиса.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Навигация по точкам:</Body1Strong> Плавно перемещайтесь по уровням. От сотрудников обратно к 
                      отделам или из организационной схемы, навигационная цепочка (Офис {'>'} Отдел {'>'} Сотрудник {'>'} Схема) держит ваше путешествие интуитивно понятным 
                      и на правильном пути.
                    </li>
                  </ul>
                  Сложные пути в TeamConnect приглашают вас изучать, понимать и взаимодействовать с каждым уровнем отделов вашего офиса, давая вам всесторонние навигационные возможности.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Взаимодействие с персоналом офиса в TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Интересуетесь, кто работает в офисе? Ответ всего в одном клике в TeamConnect. Взаимодействуйте с тремя точками рядом с выбранным названием офиса, чтобы 
                  открыть набор интерактивных опций:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Просмотр сотрудников в этом офисе:</Body1Strong> Узнайте список всех профессионалов,
                      которые считают этот офис своим рабочим домом. Отображаемое число дает быстрый подсчет сотрудников, приглашая вас погрузиться в сообщество офиса.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Опции связи:</Body1Strong> Каждая запись сотрудника оснащена иконками быстрых действий,
                      позволяющими начать чаты, звонки или даже видеоконференции прямо из TeamConnect, способствуя беспрепятственному внутриофисному общению.
                    </li>
                    <li><Body1Strong>Навигация:</Body1Strong> Чтобы вернуться к более широкому списку офисов из списка сотрудников, просто нажмите 'Офис' в навигационной
                      цепочке (Офис {'>'} Сотрудник), и вы вернетесь к обзору офиса.
                    </li>
                  </ul>
                  С этими функциями TeamConnect делает простым визуализацию распределения рабочей силы по офисам и облегчает связь, способствуя созданию связанного рабочего пространства, 
                  независимо от расстояния.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Отсутствует или устарела информация о вашем офисе?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Обновление информации об офисе в TeamConnect можно осуществлять через назначенных редакторов офиса. Если вы не уверены, кто является редакторами офиса или 
                  нуждаетесь в помощи по информации, которая относится к компетенции отдела кадров, команда поддержки HR готова 
                  помочь по адресу <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Часто задаваемые вопросы по настройкам</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Важность точности общих настроек в TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  В динамичной рабочей среде сегодняшнего дня поддержание связи и координации становится все более важным. Именно здесь обновление ваших 
                  Общих настроек в TeamConnect играет ключевую роль:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Выбор рабочего места:</Body1Strong> Отразите суть вашей рабочей среды, обновив ваше Рабочее место в соответствии с текущей 
                        ситуацией, будь то переход от тишины Домашнего офиса к суете главного Офиса или устройство в Общественном месте.
                        Эта ясность в определении вашего рабочего места предоставляет коллегам не только способы связи с вами, но и понимание того, 
                        какие беседы могут быть проведены с вами, обеспечивая конфиденциальность важных обсуждений, особенно когда вы находитесь в открытом 
                        пространстве офиса клиента или сидите в зале ожидания аэропорта.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Уточнение рабочего места:</Body1Strong> Указание вашего рабочего города делает больше, чем просто сближает на расстоянии в удаленной команде – это 
                        также открывает двери для спонтанных профессиональных встреч или неформального общения. Будь то быстрый кофе, неспешный обед или совместная 
                        встреча, знание того, что вы находитесь в одном городе, может превратить обычный день в возможность для значимых личных связей. Это не 
                        только укрепляет командные узы, но и дает глубокое понимание местных особенностей, которые могут влиять на вашу работу.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Точность часового пояса:</Body1Strong> Поскольку команды работают на разных континентах, важно, чтобы ваш часовой пояс 
                      отражал ваше текущее местоположение для гармонизации коммуникаций. Местное время в вашем профиле TeamConnect напрямую зависит от этой настройки, 
                      исключая любые догадки для коллег, пытающихся связаться с вами.
                    </li>
                  </ul>
                  Перейдите в 'Настройки' {'>'} 'Общие настройки' в TeamConnect, чтобы тонко настроить эти детали. Несколько минут, потраченных на обновление ваших настроек, могут 
                  значительно улучшить согласованность и эффективность вашей совместной работы в целом.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Разбор выбора рабочего места в TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Выбор подходящего параметра в выпадающем списке рабочих мест позволяет точно настроить TeamConnect в соответствии с вашим текущим рабочим статусом. Наведите курсор на значок информации для получения подробного объяснения каждого варианта:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Отсутствие/Перерыв:</Body1Strong> Означает время, проведенное вне работы, будь то перерыв или окончание рабочего дня.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Офис/Полевой офис:</Body1Strong> Указывает на присутствие в рабочем пространстве, управляемом компанией.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Домашний офис:</Body1Strong> Работа из домашнего офиса.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Удаленное рабочее место/Общественное место:</Body1Strong> Работа из неофисной среды, что требует гибкости в общении.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Рабочее место клиента/Частный офис:</Body1Strong> Работа на территории клиента с указанием уровня конфиденциальности для ведения переговоров.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Перемещение/Командировка/Обучение:</Body1Strong> Находясь в движении или занятость в специфической рабочей активности вне офиса.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Государственные праздники/Отпуск/Различные виды отпусков:</Body1Strong> Официально не на работе по личным, здоровьесберегающим или праздничным причинам.</li>
                  </ul>
                  Выбор соответствующего рабочего места обеспечивает вашей команде контекст, необходимый для взаимодействия, с уважением к доступности и конфиденциальности.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Навигация по статусу 'На месте' в TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Тег 'На месте' в Каталоге сотрудников предназначен для отображения всех сотрудников, которые в данный момент на работе. Это видимость определяется выбором рабочего места:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Активные рабочие среды:</Body1Strong> Выбор Офис, Полевой офис, Домашний офис, Удаленное рабочее место, Общественное место, Рабочее место клиента, 
                    Частный офис клиента, Перемещение, Командировка и Обучение указывает на вашу рабочую занятость, делая вас 'На месте' и видимым для коллег.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Не рабочие настройки:</Body1Strong> Выбор статусов Отсутствие, Перерыв, Государственный праздник, Отпуск, В отпуске, Декретный отпуск или Больничный 
                      убирает вас из списка 'На месте', устанавливая четкие ожидания по доступности и времени ответа.
                  </li>
                  </ul>
                  Эта функциональность помогает разграничивать рабочее время и личное время, способствуя эффективному сотрудничеству и общению в команде.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Вопросы и ответы по управлению данными и конфиденциальности</Subtitle2>
          <Body1>
            <p>
              В современную эпоху цифровых технологий понимание того, как управляются ваши данные, имеет решающее значение. TeamConnect стремится к прозрачности и надежным практикам защиты данных. 
              Ниже вы найдете информацию о том, как мы обращаемся, храним и защищаем ваши данные в TeamConnect, обеспечивая безопасность и соответствие вашего цифрового рабочего пространства.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Как TeamConnect защищает мои личные данные?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect посвящен защите вашей личной информации с помощью комплексного подхода к безопасности данных:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Единый вход (SSO):</Body1Strong> Для усиления безопасности и упрощения пользовательского опыта TeamConnect интегрируется с автоматизированным 
                      Единым входом (SSO) в вашу учетную запись Microsoft 365, обеспечивая безопасное и легкое управление доступом.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Шифрование:</Body1Strong> Мы гарантируем, что все данные в TeamConnect зашифрованы как во время передачи, так и в режиме ожидания, 
                      делая их недоступными для неавторизованных лиц.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Минимизация данных:</Body1Strong> Наш сбор данных строго ограничен тем, что необходимо для функционирования TeamConnect. 
                      Единственная информация о пользователях, которую мы храним, – это ваше имя управляющего принципала (UPN) из Azure Entra ID, которое может напоминать адрес вашей корпоративной электронной почты.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Ограниченный доступ:</Body1Strong> Доступ к UPN ограничен администратором подписки вашей организации в нашем портале подписки, 
                      что гарантирует, что конфиденциальная информация доступна только уполномоченным лицам.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Быстрое удаление данных:</Body1Strong> TeamConnect структурирован так, чтобы мгновенно удалять хранимый UPN при трех конкретных условиях: 
                      если подписка отменяется администратором подписки, если пользователь удаляется HR в TeamConnect или если подписка TeamConnect истекает. Это гарантирует, что ваша личная информация не сохраняется дольше, чем это необходимо.
                    </li>                      
                  </ul>
                  <p>Благодаря этим мерам TeamConnect обязуется поддерживать высочайшие стандарты защиты данных, обеспечивая безопасность и конфиденциальность вашей личной информации.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Интересуетесь, где находятся ваши данные с TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect выходит за рамки традиционных предложений SaaS, гарантируя, что ваши данные никогда не покидают вашу организацию. 
                  В отличие от обычной практики хранения данных на внешних облачных серверах, TeamConnect изобретательно использует вашу 
                  организационную среду Microsoft 365 SharePoint Online для хранения всех данных приложений. Этот новаторский подход не только поддерживает суверенитет данных, но и укрепляет безопасность, 
                  идеально соответствуя нашей твердой приверженности защите вашей конфиденциальности и соблюдению строгих стандартов соответствия.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Будут ли мои данные переданы третьим сторонам?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Нет, данные не передаются третьим сторонам! Cubeet твердо придерживается приверженности конфиденциальности и безопасности ваших данных. Мы не передаем данные TeamConnect третьим сторонам без получения явного согласия от наших клиентов. 
                  В нашей безопасной среде мы храним только необходимые идентификаторы, такие как имя главного пользователя (UPN), название организации, название арендатора и ID арендатора, в нашем портале подписки. 
                  Эта информация критически важна для предоставления услуг и защищена в соответствии с нашими строгими политиками конфиденциальности.
                  Будьте уверены, наши практики управления данными разработаны так, чтобы обеспечить конфиденциальность ваших данных, с прозрачностью и контролем, остающимися твердо в ваших руках.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Как TeamConnect соответствует нормам защиты данных, таким как GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect соответствует нормам защиты данных, таким как GDPR, HIPAA, CCPA и другие, используя встроенные функции соблюдения требований среды Microsoft 365, 
                  где безопасно хранятся все данные клиентов. В отличие от традиционных моделей SaaS, TeamConnect не хранит данные клиентов на своих серверах; вместо этого, он работает в рамках 
                  среды Microsoft 365 вашей организации, соблюдая ее надежные стандарты безопасности и соответствия. Этот подход гарантирует, что управление вашими данными остается в соответствии с 
                  комплексной системой соблюдения требований, предоставляемой Microsoft 365, включая соблюдение GDPR, HIPAA для информации о здоровье, CCPA для жителей Калифорнии, 
                  и других глобальных норм защиты данных. Интегрируясь бесшовно с вашей существующей установкой Microsoft 365, TeamConnect дает вашей организации возможность поддерживать 
                  полный контроль над вашими данными, обеспечивая соблюдение строгих законов и нормативов по защите данных.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Как TeamConnect работает с данными из чатов, звонков, видеозвонков в Microsoft Teams, электронных писем Outlook и звонков через телефонную систему?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect использует Microsoft Graph API для обеспечения прямого взаимодействия, такого как чаты, звонки, видеозвонки и электронные письма Outlook в рамках Microsoft Teams, 
                  обеспечивая бесперебойный пользовательский опыт. Важно, что, хотя TeamConnect инициирует эти коммуникации, он делает это, не вмешиваясь в процесс коммуникации 
                  и не имея возможности записывать или хранить какой-либо контент взаимодействия. Все беседы, звонки и письма происходят и управляются непосредственно в 
                  защищенной среде Microsoft Teams и Outlook в строгом соответствии со стандартами безопасности и конфиденциальности Microsoft 365. Это гарантирует, что TeamConnect улучшает 
                  ваши коммуникативные возможности, полностью сохраняя конфиденциальность и целостность ваших данных.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Для подробного изучения наших политик и практик в области данных вы также можете ознакомиться с {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>Руководством пользователя TeamConnect</Body1Strong></Link> или обратиться в IT-отдел вашей организации по адресу <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> для получения информации, специфичной для вашей конфигурации.
          </p>
        </Body1>        
        <p></p>  
      <Subtitle1 className={styles.fontColor}>Мы вас слушаем: делитесь своими отзывами и идеями</Subtitle1>
        <Body1>
          <p>
            Ваши идеи способствуют развитию TeamConnect. Независимо от того, если у вас есть блестящая идея функции или обратная связь о вашем текущем опыте, мы внимательно вас слушаем. Ваше мнение бесценно в нашем непрерывном стремлении предоставить наилучший сервис.
          </p>
        </Body1>      
      <Subtitle2>Отзывы и предложения функций</Subtitle2>
        <Body1>
          <p>
            Готовы поделиться? Перейдите на нашу {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              страницу обратной связи
            </Link>
            . Будь то предложение по новой функции или отзыв о существующих функциональностях, просто выберите 
            соответствующий вариант из выпадающего списка в форме. Ваши вклады помогают формировать будущее TeamConnect, 
            обеспечивая, чтобы он соответствовал и превосходил ваши ожидания.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;