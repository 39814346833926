import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>Ionad Cabhrach & CCanna TeamConnect</Title3>
        <p><Body1>
          Fáilte go dtí an Ionad Cabhrach TeamConnect, atá deartha chun tacú le d’eispéireas leis an aip eolaire cuideachta cuimsitheach seo. Anseo, gheobhaidh tú acmhainní chun do úsáid as TeamConnect laistigh de Microsoft Teams a bharrfheabhsú, ag cur leis an gcaoi a gcuireann tú cumarsáid trasna do líonra.
        </Body1></p>
        <Subtitle2>Treoir Tagartha Tapa</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Cumais Cuardaigh Feabhsaithe: </Body1Strong>
              Aimsigh na comhghleacaithe, na rannóga, agus na teagmhálacha riachtanacha go tapa le huirlisí cuardaigh cumhachtacha.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Cairteacha Eagraíochtúla Amhairc: </Body1Strong>
              Navigáid hierarcha na heagraíochta agus tuig línte tuairiscithe gan stró trí chairteacha eagraíochtúla iomasacha.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Uirlisí Oiriúnacha do Riachtanais an Lae Inniu: </Body1Strong>
              Úsáid feidhmeanna nua-aimseartha atá saindeartha de réir éilimh na n-ionad oibre comhaimseartha.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Eispéireas a Bharrfheabhsú</Subtitle2>
        <Body1>
          <p>
            Déanann TeamConnect réabhlóid ar an dóigh a n-idirghníomhaíonn tú laistigh de do struchtúr eagarsíoch, ag iompú cumarsáid laethúil ina rannpháirtíochtaí straitéiseacha.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Faigh Cabhair & CCanna</Subtitle1>
        <Body1>
          <p>
            Faigh freagraí ar cheisteanna coitianta faoi ghnéithe agus socruithe TeamConnect sa rannóg thiomnaithe seo. Cibé an bhfuil tú ag nascleanúint tríd an aip, socruithe a shaincheapadh, nó réitigh ar dúshláin á lorg, tá ár nIonad Cabhrach anseo chun cuidiú leat go héifeachtach.
          </p>
        </Body1>
        <Body1>
          <p>
            Chun tacaíocht níos doimhne nó treoir shonrach a fháil, cuir glaoch le do thoil ar an <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>Treoir Úsáideora TeamConnect</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        
        <Subtitle2>Ag baint úsáide as CCanna Uirlisí Foireann</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Ní féidir Uirlisí Foireann a fháil ar thaobhchlár Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Má tá Uirlisí Foireann dofheicthe nó imithe as do thaobhchlár Microsoft Teams, is féidir leat é a ghreamú go héasca chun rochtain tapa a fháil. Lean na céimeanna simplí seo:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Oscail Microsoft Teams agus cliceáil ar an deilbhín '...' (+ Aipeanna) ag bun an thaobhchláir.</li>
                    <li className={styles.bulletBottomMargin}>Cuardaigh do 'Uirlisí Foireann' sa bharra cuardaigh ag barr an fhuinneog aníos.</li>
                    <li className={styles.bulletBottomMargin}>Cliceáil deas ar an aip Uirlisí Foireann ó na torthaí cuardaigh agus roghnaigh 'Greamú'.</li>
                  </ol>
                  Beidh Uirlisí Foireann anois ghreamaithe ar do thaobhchlár, rud a chiallaíonn go mbeidh sé inrochtana le cliceáil amháin!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Ag dul i ngleic le fadhbanna teicniúla le Uirlisí Foireann? Seo conas cabhair a fháil.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Má bhíonn aon fhadhbanna teicniúla agat le Uirlisí Foireann laistigh de Microsoft Teams, tá foireann Tacaíochta IT d'eagraíochta anseo chun cabhrú. 
                  Déan teagmháil leo ag <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, agus cabhróidh siad leat go pras.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Ní féidir na sonraí fostaí a fheiceáil?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Má mhothaíonn do phróifíl beagán folamh le d'ainm agus r-phost le feiceáil, is dócha nach bhfuil d'fhoireann AD tar éis na sonraí fostaí eile a chur leis i Uirlisí Foireann fós. 
                  Ná bíodh imní ort! Cuirfidh teachtaireacht thapa chuig d'fhoireann tacaíochta AD rudaí ar siúl. Tá siad réidh chun do phróifíl a líonadh leis na sonraí tábhachtacha go léir.
                  Déan teagmháil ag <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> agus féach do phróifíl ag teacht chun beatha!
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Cad iad na roghanna teanga atá ar fáil in TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tugann TeamConnect aghaidh ar éagsúlacht dhomhanda Microsoft Teams trí thacaíocht a thabhairt do gach ceann dá theangacha. 
                  Cibé an fearr leat Araibis nó Gréigis, Seapáinis nó Sualainnis, tá domhan roghanna ar fáil agat, lena n-áirítear:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Araibis, Bulgáiris, Sínis (Simplithe agus Traidisiúnta), Cróitis, Seicis, Danmhairgis, Ollainnis, Béarla, Eastóinis, Fionlainnis, 
                      Fraincis, Gearmáinis, Gréigis, Eabhrais, Ungáiris, Indinéisis, Iodáilis, Seapáinis, Cóiréis, Laitvis, Liotuáinis, Ioruais (Bokmal), 
                      Polainnis, Portaingéilis (An Bhrasaíl agus An Phortaingéil), Rómáinis, Rúisis, Seirbis (Laidin), Slóvaicis, Slóivéinis, Spáinnis, Sualainnis, Téalainnis, 
                      Tuircis, Úcráinis, Vítneaimis.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Chun do thaithí TeamConnect a shaincheapadh do do theanga roghnaithe, coigeartaigh na socruithe teanga go díreach i Microsoft Teams, 
                  mar go n-inteagraíonn TeamConnect go hiomlán leis na socruithe sin:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Téigh chuig socruithe do Microsoft Teams trí chliceáil ar na trí phointe in aice le d'íomhá próifíle ag an mbarr, agus ansin roghnaigh 'Cumaisc agus inrochtaineacht'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Scrollaigh go dtí an rannóg 'Teanga' agus roghnaigh do theanga is fearr leat ón roghchlár anuas.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Deimhnigh do chuid athruithe trí chliceáil ar an gcnaipe 'Sábháil agus atosaigh' chun na socruithe teanga nua a chur i bhfeidhm.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Nóta: </Body1Strong>
                <Body1>
                  Déanfaidh athruithe a dhéanfar ar do socruithe teanga i Microsoft Teams nuashonrú gan uaim ar do thaithí TeamConnect chomh maith. 
                  Bí ar an eolas go bhféadfadh athrú ar an teanga i Teams an formáid ama agus dáta a choigeartú freisin. Faoi láthair, 
                  tá TeamConnect ag éabhlóidiú chun scríbhneoireacht ó dheas go clé a fhormáidiú. Táimid spreagtha faoi na feabhsuithe seo 
                  agus coinneoimid ar an eolas tú de réir mar a thiocfaidh siad ar fáil.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Conas is féidir liom an téama a athrú i TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Déanann TeamConnect sioncrónú le do chuid sainroghanna téama Microsoft Teams, lena n-áirítear na roghanna Éadrom, Dorcha, agus Codarsnacht Ard. Chun do théama a shaincheapadh:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Roche a dhéanamh ar do chuid socruithe Microsoft Teams trí chliceáil ar an trí ponc in aice le d'íomhá próifíle ag an mbarr.</li>
                    <li className={styles.bulletBottomMargin}>Roghnaigh 'Cumarsáid agus inrochtaineacht' chun na roghanna téama a iniúchadh.</li>
                    <li className={styles.bulletBottomMargin}>Roghnaigh do théama is fearr leat ó na roghanna atá ar fáil chun comhéadan do TeamConnect a nuashonrú láithreach chun freastal air.</li>
                  </ol>
                  Cuirfear d'éagsúlacht téama i Microsoft Teams i bhfeidhm go huathoibríoch ar TeamConnect, ag cinntiú taithí amhairc chomhsheasmhach ar fud do cheardlainne.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Ag iniúchadh na gCnaipí Gnímh Teagmhála ar TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ar fud TeamConnect, áit ar bith a fhaighidh tú sonraí teagmhála fostaí, gheobhaidh tú ár gcnaipe rochtana tapa áisiúil freisin. Tá na huirlisí seo deartha chun do chumarsáid laistigh den éiceachóras Microsoft a shruthlíniú, ag cinntiú nach bhfuil tú ach cliceáil amháin ar shiúl ó nascadh.
                  Seo duit treoir chuimsitheach le húsáid éifeachtach na gcnaipe seo:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Deilbhín Comhrá:</Body1Strong> Cliceáil chun comhrá toirtéiseach Microsoft Teams a thosú. Má theastaíonn uait filleadh ar TeamConnect tar éis comhrá a thosú, úsáid an saighead siar sa App Deisce, an cnaoi siar sa App Soghluaiste, nó cnaoi siar do bhrabhsálaí.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Deilbhín Ríomhphoist:</Body1Strong> Oscail dréacht nua ríomhphoist Outlook le seoladh an fhaighteora réidh sa réimse "To:". Má sheoltar Outlook, fiú amháin, tá sé fiú d'aiseolas a fháil ó d'roinn TF.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Deilbhín Glao Físe:</Body1Strong> Tosaíonn sé glao físe duine le duine ag baint úsáide as Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Deilbhín Glao:</Body1Strong> Tosaíonn sé glao gutha Microsoft Teams, ag déanamh pléite tapa gan uaim.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Deilbhín Chairt Eagraíochtúil:</Body1Strong> Nochtann sé áit an fhostaí i heirceacht na heagraíochta, lena n-áirítear a mbainisteoir agus tuairiscí dírí.</li>
                    <li><Body1Strong>Deilbhín Níos Mó (...):</Body1Strong> Nochtann sé roghanna breise:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Buaile Léi Anois:</Body1Strong> Oscail Microsoft Teams chun cruinniú láithreach a thosú leis an bhfostaí.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Cruinniú Nua:</Body1Strong> Díríonn tú chuig Microsoft Teams chun cruinniú nua a sceidealú.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Cuir Teagmháil Leis:</Body1Strong> Cuir sonraí teagmhála an fhostaí ó TeamConnect leis chun d'fheidhm glao a shaibhriú i d'App Daoine nua Microsoft Teams.</li>
                      </ul>
                    </li>
                  </ul>
                  Tá na cnaipí éirimiúla seo ceaptha chun a chinntiú gur féidir leat ceangal go héifeachtúil le comhghleacaithe, cibé acu le haghaidh comhrá gasta, ríomhphost, nó chun cruinnithe sa todhchaí a sceidealú.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Glaoite a dhéanamh trí TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ag iarraidh glao a dhéanamh? Déanann TeamConnect do chumarsáid a shruthlíniú trí chomhtháthú le Microsoft Teams.
                  Cliceáil go simplí ar an uimhir theileafóin atá ag teastáil i TeamConnect, agus voilà, pops suas comhéadan glaonna Microsoft Teams,
                  réidh leat glao a dhéanamh gan uaim.
                  <p>
                  <Body1Strong>Tabhair faoi deara: </Body1Strong> 
                  Má tá deacrachtaí agat glaonna a dhéanamh? D'fhéadfadh sé seo a bheith de bharr polasaithe TF d'eagraíocht nó úsáid córas teileafóin seachas Córas Fón Microsoft Teams. Má dhéanann tú seiceáil le d'roinn TF ag <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, féadfaidh sé roinnt soiléire a thabhairt ar an scéal.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. An bhfuil costais a bhaineann le glaonna a dhéanamh trí TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Feabhsaíonn TeamConnect do thaithí glaoch trí bheith ag brath ar Microsoft Teams. Seo an méid atá uait a fhios faoi chostais a bhaineann le glaonna:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Maidir le glaonna chuig fóin oibre fostaithe, fóin ranna, nó línte te níos laistigh de d’eagraíocht (uimhreacha inmheánacha),
                      de ghnáth <Body1Strong>níl aon chostas</Body1Strong> nuair a úsáidtear Córas Fón Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Maidir le glaonna seachtracha, amhail iad siúd chuig fóin phóca nó línte lasmuigh, <Body1Strong>rátaí caighdeánacha</Body1Strong> de chóras fón d’eagraíochta bheirtear i bhfeidhm.
                      Athraíonn na rátaí seo bunaithe ar do phlean seirbhíse sonrach le Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Tabhair faoi deara:</Body1Strong> Má thagann tú ar fhadhbanna nó má tá ceisteanna agat faoi mhuirir glaonna, is fearr dul i gcomhairle le do 
                    roinn TF ag <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> le haghaidh eolais mhionsonraithe
                    atá oiriúnach do shocrú agus do pholasaithe d’eagraíochta.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Ag tabhairt faoi deara mí-oiriúnacht le do stádas láithreachta ‘As Oifig’ in TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  An bhfaca tú nach bhfuil do stádas láithreacht ‘As Oifig’ ó Microsoft 365 ag léiriú i gceart in TeamConnect?
                  Táimid anseo chun cabhrú. Tá an ghné chun stádas láithreachta ‘As Oifig’ a shioncronú curtha i bhfeidhm cheana féin in TeamConnect.
                  Táimid ag fanacht faoi láthair go rollfaidh Microsoft amach an ghné seo. A luaithe a tharlóidh sé sin, beidh do stádas láithreachta ‘As Oifig’ 
                  á léiriú go cruinn in TeamConnect, ag teacht le táscairí láithreachta Microsoft Teams le haghaidh taithí gan uaim.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>CCanna Ceisteanna um Eolaire Fostaithe</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >Réamhrá um Eolaire na bhFostaithe:</Body1Strong> Gabh tríd an tírdhreach gairmiúil de d’eagraíocht leis an Eolaire Fostaithe, 
                do lárionad lárnach le haghaidh léargas mionsonraithe ar fostaithe, dinimic an ionaid oibre, agus nascacht dhíreach le próifílí do chomhghleacaithe agus caidrimh bhainistíochta.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. An gá duit sonraí fostaithe a chur leis, a nuashonrú, nó a cheartú?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Le sonraí fostaithe a chur leis, a nuashonrú, nó a cheartú in TeamConnect, téigh i dteagmháil le foireann tacaíochta AD d’eagraíochta. 
                  Tá siad réidh chun cabhrú ag <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Céard a tharlaíonn nuair a chliceálaim ar ainm fostaí sa stiúrthóir?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Osclaíonn cliceáil ar ainm fostaí i Stiúrthóir Foirne TeamConnect leathanach próifíle mionsonraithe don duine sin. Cuireann an phróifíl seo léargas cuimsitheach
                  ar fáil faoin bhfostaí, lena n-áirítear, ach gan a bheith teoranta do, a theideal post, an roinn, suíomh an oifig, eolas teagmhála, agus tuilleadh. Tá sé deartha chun forbhreathnú iomlán
                  a thabhairt duit ar ról gairmiúil an fhostaí agus conas a oireann siad isteach san eagraíocht, ag déanamh sé níos éasca duit tuiscint a fháil ar a gcuid freagrachtaí agus
                  conas d'fhéadfá comhoibriú leo.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Cén fhaisnéis atá san áireamh i bpróifíl fostaí?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Is íomhá chuimsitheach de phróifíl gach fostaí i TeamConnect, a thairgeann léargas saibhir ar shaol gairmiúil an duine.
                  Seo a bhfaighidh tú:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sonraí Bunúsacha:</Body1Strong> Ainm, teideal an phoist, roinn, agus suíomh fochuideachta.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Struchtúr Tuarascála:</Body1Strong> Eolas ar thuairiscí dírí agus feidhmiúla,
                      lena n-áirítear bainisteoirí líne agus feidhme.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Stádas Ceardlainne:</Body1Strong> Stádas oibre reatha mar ar saoire, comaitéireacht,
                      Gnó-Taisteal, srl.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Áit Oibre & Am Áitiúil:</Body1Strong> Suíomh oibre reatha an fhostaí agus
                      crios ama áitiúil.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sonraí Teagmhála:</Body1Strong> Fón oibre, uimhir shoghluaiste, agus seoladh ríomhphoist.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Seoladh Oifige:</Body1Strong> Suíomh iomlán na hoifige lena n-áirítear ainm an fhoirgnimh,
                      seoladh, agus Google map.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Faoi:</Body1Strong> Réamhrá pearsanta gairid nó beathaisnéis ghairmiúil.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Caidreamh Fostaí:</Body1Strong> Sonraí ar struchtúr tuairiscithe an fhostaí 
                      agus caidrimh foirne.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Naisc Shóisialta & Teangacha:</Body1Strong> Naisc le próifílí sóisialta gairmiúla
                      agus inniúlachtaí teanga.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Scileanna:</Body1Strong> Liosta de scileanna gairmiúla agus réimsí saineolais.</li>
                  </ul>
                  Ina theannta sin, cuireann an phróifíl roghanna gníomhacha ar fáil le nascadh trí chomhrá, ríomhphost, glao físe, nó cruinnithe a sceidealú, mar aon le
                  rochtain thapa ar chairt na heagraíochta le haghaidh comhthéacs foirne níos leithne.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Cén chaoi a bhféadfainn mo chuid faisnéise próifíle a nuashonrú?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tá sé éasca do phróifíl a phearsanú i TeamConnect. Seo conas is féidir leat é a dhéanamh:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Téigh go dtí an cluaisín <Body1Strong>Fostaí</Body1Strong> agus cliceáil ar do <Body1Strong>Avatar</Body1Strong> chun do phróifíl a oscailt.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Sa chúinne uachtarach ar chlé, gheobhaidh tú an nasc <Body1Strong>"Cuir d'eolas próifíle in eagar"</Body1Strong>. 
                        Trí chliceáil air seo osclófar an dialóg in eagar do do phróifíl.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Anseo, is féidir leat do phróifíl a fheabhsú trí d'eolas <Body1Strong>Faoin Tú</Body1Strong> a chur leis nó a nuashonrú, naisc na meán sóisialta, agus d'uimhir teileafóin. 
                        Déanann sé seo níos éasca do do chomhghleacaithe ceangal a dhéanamh leat nó leanúint ort.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Ná déan dearmad an cnaipe <Body1Strong>Sábháil</Body1Strong> a bhrú chun d'athruithe a chur i bhfeidhm.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Scrollaigh síos sa dialóg chun do <Body1Strong>Teangacha</Body1Strong> agus do <Body1Strong>Inniúlacht</Body1Strong> a chur leis,
                        chomh maith le do <Body1Strong>Scileanna</Body1Strong>. Cabhraíonn an fhaisnéis seo le do chomhghleacaithe tuiscint a fháil ar do réimsí saineolais 
                        agus na teangacha inar féidir leat cumarsáid a dhéanamh.
                      </li>
                    </ol>
                  Ní hamháin go gcoinníonn nuashonrú do phróifíle d'fhaisnéis reatha, ach cuireann sé le naisc níos fearr laistigh de d'eagraíocht freisin.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Ag Nuashonrú Do Ionad Oibre, Do Spota Oibre, agus Do Chrios Ama in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tá sé riachtanach d'fhaisnéis ionad oibre, spota oibre, agus crios ama a choinneáil cothrom le dáta le haghaidh comhoibriú gan uaim i Microsoft Teams.
                  Tugtar léiriú láithreach ar na nuashonruithe seo i do phróifíl TeamConnect agus liostaí, ag ligean do chomhghleacaithe tuiscint a fháil go tapa ar do shuíomh oibre
                  agus na hamanna is fearr chun ceangal leat, ag méadú comhoibrithe ar fud réigiúin agus criosanna ama éagsúla.
                  <p></p>
                  Réidh le do shonraí a nuashonrú? Tá sé éasca in TeamConnect! Téigh go 'Socruithe' sa Roghchlár barr agus nascleanúint chuig 'Socruithe Ginearálta'.
                  Anseo, is féidir leat do Ionad Oibre agus Crios Ama a nuashonrú go réidh, agus do Spota Oibre don lá a shonrú. Cuimhnigh do athruithe a shábháil chun gach duine a choinneáil ar an eolas agus
                  comhoibriú éifeachtach a áirithiú, is cuma cá bhfuil tú!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. An bhfuil rud éigin as ord i bpróifíl comhghleacaí?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Má thagann tú trasna ar aon mhionsonraí i bpróifíl comhghleacaí nach bhfuil ceart go leor, is é an chéad chéim is fearr ná rabhadh cairdiúil a thabhairt dóibh.
                  Ba chóir go ndéanfadh comhrá gairid nó ríomhphost an cleas! Mura ndéantar an fhaisnéis a nuashonrú ar chúis éigin, ná bí buartha—díreach
                  seol ping chuig do fhoireann tacaíochta AD ag <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, agus beidh siad sásta
                  dul i mbun oibre agus rudaí a réiteach.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Ag Tuiscint Grúpaí Clibeanna: Gach, Roghanna, Seiceáilte-Isteach, & Fostaithe Nua
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Gach:</Body1Strong> Taispeánann an clib réamhshocraithe seo gach fostaí i do eagraíocht,
                      ag soláthar léargas cuimsitheach ar an bhfoireann.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Roghanna:</Body1Strong> Cliceáil anseo chun do radharc a scagadh chuig na comhghleacaithe 
                      atá marcáilte agat mar 'Roghanna,' rud a éascaíonn teagmháil gasta le teagmhálaithe ríthábhachtacha.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Seiceáilte-Isteach:</Body1Strong> Léiríonn an clib seo fostaithe atá i mbun oibre go gníomhach, 
                      ag idirdhealú ó láithreacht ar líne amháin i Microsoft Teams. Nuair atá siad seiceáilte isteach, léiríonn fostaithe go bhfuil siad ag obair agus roinneann 
                      siad a suíomh oibre reatha i rith an lae. Féadfaidh seo éagsúlacht ionaid oibre a áireamh mar Oifig, Oifig-Réimse, Oifig-Bhaile, Láthair-Oibre Iargúlta, 
                      Láthair-Oibre Custaiméirí, Oifig Phríobháideach Custaiméirí, Limistéar Poiblí, Commute, Taisteal Gnó, nó fiú Oiliúint. Dá bhrí sin, cuireann an stádas 
                      'Seiceáilte-Isteach' léiriú soiléir ar bheith ag obair go gníomhach, ag tairiscint comhghleacaithe tuiscint chruinn ar chomhthéacs oibre reatha 
                      agus infhaighteacht a chéile.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Fostaithe Nua:</Body1Strong> Aithin aghaidheanna úra i TeamConnect leis an gclib seo, ag aibhsiú 
                      fostaithe a cuireadh leis an aip laistigh de na 30 lá deireanacha. De ghnáth, bíonn sé seo ag teacht le cur leis na le déanaí d'eagraíocht, ag cabhrú leat 
                      fáilte a chur roimh bhaill nua foirne.
                    </li>
                  </ul>
                  Tá na grúpaí clibeanna seo deartha chun nascleanúint a shruthlíniú agus do thaithí TeamConnect a fheabhsú, ag cinntiú go bhfuil tú i gcónaí
                  in ann teagmháil a dhéanamh go héifeachtach le do chomhghleacaithe.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Ag Réiteach na nDeilbhíní in aice leis an mBosca Cuardaigh
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tá Eolaire Fostaithe TeamConnect deartha le haghaidh ilúsáid agus éascaíocht, ag taispeáint ceithre dheilbhín a thairgeann radhairc éagsúla 
                  agus rochtain tapa ar sonraí tábhachtacha. Seo cad a nochtann gach deilbhín:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Radharc Liosta:</Body1Strong> Do radharc réamhshocraithe, ag cur i láthair gach comhalta eagraíochta
                      i liosta dhíreach.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Radharc Cárta Beag:</Body1Strong> Malairt dlúth, athraíonn an radharc seo an liosta i gcártaí beaga bídeacha
                      d'fhostaithe.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Radharc Cárta Mór:</Body1Strong> Roghnaigh mionsonraí níos mó le cártaí níos mó, a chuimsíonn 
                      roghanna teagmhála breise mar uimhreacha teileafóin agus guthán póca le haghaidh glaonna éasca.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Cairt Eagraíochta:</Body1Strong> Léirigh struchtúr na cuideachta le radharc Cairt Eagraíochta, 
                      foirfe chun caidreamh tuairiscithe a thuiscint agus tírdhreach eagraíochtúil a nascleanúint.</li>
                  </ol>
                    Athraigh idir na deilbhíní seo chun an radharc is fearr a oireann do do riachtanais faoi láthair a fháil, cibé an bhfuil tú ag lorg eolais tapa nó léargais níos doimhne.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Do chuardach tapa comhghleacaithe, cá bhfaighidh mé é?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ag lorg comhghleacaí? Níl ort ach dul chuig bosca cuardaigh TeamConnect, atá suite ar thaobh uachtarach ar dheis in aice le deilbhín an Chairt Eagraíochta. 
                  Tosaigh ag clóscríobh a n-ainm, agus féach mar a ghéaraíonn na torthaí le gach litir. Cuimhnigh, le haghaidh cuardaigh TeamConnect, scipeáil barra cuardaigh 
                  Microsoft Teams thuas—ár mbosca cuardaigh speisialta is é an bealach le dul!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Conas a oibríonn an cuardach aibítre chun fostaithe a aimsiú?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tá gné cuardaigh aibítre áisiúil san eolaire Fostaithe TeamConnect chun cabhrú leat fostaithe a aimsiú go tapa de réir a gcéadainm. 
                  Cliceáil go simplí ar litir ón aibítir atá taispeánta ag barr an eolaire, agus taispeánfaidh TeamConnect duit láithreach na fostaithe 
                  ar tosaíonn a gcéadainmneacha leis an litir sin. Chun filleadh ar an liosta iomlán fostaithe, díroghnaigh an litir atá aibhsithe. Déanann 
                  an ghné iomasach seo nascleanúint trí liostaí móra fostaithe go héasca, ag cinntiú gur féidir leat comhghleacaithe a aimsiú go tapa agus go héifeachtach.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Conas is féidir liom líon na bhfostaithe atá taispeánta in aghaidh an leathanaigh a choigeartú?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ceadaíonn TeamConnect duit saincheapadh cé mhéad próifílí fostaí a fheiceann tú in aghaidh an leathanaigh, ag éascú duit brabhsáil tríd an Eolaire Fostaithe.
                  Ag bun gach leathanaigh, gheobhaidh tú roghanna uimhrithe: 10, 50, 100, 500, 1000, agus 5000. Taispeánann an socruithe réamhshocraithe 10 próifíl in aghaidh an leathanaigh.
                  Chun níos mó próifílí a fheiceáil ar aon leathanach amháin, roghnaigh díreach d'uimhir roghnaithe ó na roghanna seo. Soláthraíonn an ghné seo solúbthacht i do bhrabhsáil 
                  tríd an eolaire, agus ligeann sé duit an taispeántas a shaincheapadh de réir do riachtanas nó do roghanna sonracha.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Conas is féidir liom eolas fostaí a shórtáil sa Radharc Liosta?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Sa Radharc Liosta d'Eolaire Fostaithe TeamConnect, tá an tsolúbthacht agat eolas fostaí a shórtáil chun freastal ar do riachtanais. Tá na colúin—Ainm Fostaí, 
                  Teideal Poist, Rannóg, Suíomh Oifige, agus Áit Oibre—inshórtáilte. Cliceáil go simplí ar cheanntásc an cholúin ar mhaith leat a shórtáil de réir. Déanfaidh cliceáil 
                  amháin an colún a shórtáil i dtreo atá ag ardú, agus déanfaidh cliceáil arís é a shórtáil i dtreo atá ag ísliú. De réir réamhshocraithe, tá an t-eolaire sórtáilte de 
                  réir Ainm an Fhostaí i dtreo atá ag ardú, bunaithe ar an gcéadainm. Cabhraíonn an ghné sórtála seo leat sonraí fostaithe a eagrú agus a fheiceáil go tapa ar an mbealach 
                  is fearr a fhreastalaíonn ar do chuspóir, cibé acu ag lorg duine áirithe atá tú nó gá duit fostaithe a ghrúpáil de réir critéir áirithe cosúil le rannóg nó suíomh.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Áis Tacaíochta Ranna Ceisteanna Coitianta</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Réamhrá Áis Tacaíochta Ranna:</Body1Strong> Is é Áis Tacaíochta Ranna do gheata chun tuiscint a fháil ar an ngréasán casta de struchtúr do 
              eagraíochta, ag tairiscint radharc soiléir ar ardcheannais rannacha, ceannaireacht bhainistíochta, agus bealaí cumarsáide rannacha gan uaim.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Conas a oibríonn na clibeanna 'Gach', 'Ceanáin', agus 'Do Roinn' in Áis Tacaíochta Ranna?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  In Áis Tacaíochta Ranna TeamConnect, cabhraíonn clibeanna leat nascleanúint tapa a dhéanamh agus faisnéis rannach a scagadh. Seo a leanas cad a léiríonn gach clib:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Gach:</Body1Strong> Taispeánann an clib seo gach roinn laistigh de d'eagraíocht, ag soláthar forbhreathnú 
                      cuimsitheach.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ceanáin:</Body1Strong> Úsáid an clib seo chun liosta curtha in eagar de ranna a chuir tú i d'eadan 'Ceanáin' a 
                      fheiceáil, rud a éascaíonn rochtain ar na ranna a mbíonn tú i dteagmháil leo go minic.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Do Roinn:</Body1Strong> Scagann an clib seo an radharc chun do roinn féin a thaispeáint amháin, 
                      ag ceadú rochtain tapa ar d'fhaisnéis rannach láithreach agus nuashonruithe.</li>
                  </ul>
                  Tá na clibeanna seo deartha chun do nascleanúint laistigh den Áis Tacaíochta Ranna a shruthlíniú, ag méadú do chumas faisnéis rannach a aimsiú agus a 
                  chomhghníomhú go héifeachtach.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Tuiscint ar Radhairc Áis Tacaíochta Ranna i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tá nascleanúint trí Áis Tacaíochta Ranna i TeamConnect sruthlínithe le roghanna iliomad radharcanna a oireann do do roghanna. Tairgeann gach radharc bealach uathúil chun faisnéis chuimsitheach rannach a iniúchadh, lena n-áirítear Ainm na Roinne, Bainisteoir na Roinne, Roinn Tuismitheora, Fón na Roinne,
                  Líne Theasa na Roinne, agus Ríomhphost na Roinne. Seo thíos léargas ar na radhairc atá ar fáil:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Radharc Liosta:</Body1Strong> An socrú réamhshocraithe a liostaíonn na ranna go néata, ag soláthar 
                      forbhreathnú soiléir, gonta.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Radharc Cárta Mór:</Body1Strong> Aistrigh chuig an radharc seo chun cártaí mionsonraithe ranna a fheiceáil, ag tairiscint 
                      achoimre leathnaithe ar eolas príomhúil gach roinne.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Radharc Chairt Eagraí:</Body1Strong> Chun léiriú amhairc ar an struchtúr rannach agus caidrimh, léiríonn an radharc Chairt Eagraí 
                      conas a oireann gach roinn laistigh den eagraíocht níos mó.
                    </li>
                  </ul>
                  Chun idirghníomhú idir na radhairc seo, úsáid na deilbhíní in aice leis an mbosca cuardaigh ag barr an Áis Tacaíochta Ranna. Tá gach radharc deartha chun cabhrú leat rochtain a fháil ar 
                  agus tuiscint a fháil ar mhionsonraí rannacha go héasca, ag cinntiú go bhfaighidh tú an fhaisnéis atá uait sa bhformáid is fearr duit.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Conas an Bosca Cuardaigh a Úsáid i nEolaire na Roinne
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Faigh aon roinn go réidh laistigh de TeamConnect ag baint úsáide as an mbosca cuardaigh tiomnaithe atá suite díreach in aice leis na deilbhíní amharc ag barr an
                  Eolaire Roinne. Tosaigh ag clóscríobh ainm na roinne atá á lorg agat, agus féach mar a mhionathraíonn an t-eolaire é féin chun cluichí ábhartha amháin a thaispeáint.
                  Dearadh an ghné seo go sonrach chun cabhrú leat an gá atá le sift de láimh trí eolaire iomlán a sheachbhóthar, ag tabhairt na roinne a theastaíonn uait díreach chuig do mhéara.
                  Cuimhnigh, tá an fheidhmiúlacht cuardaigh seo uathúil do Eolaire Roinn TeamConnect, mar sin bí cinnte an bosca cuardaigh seo a úsáid seachas príomhchuardach Microsoft Teams
                  ag barr iomlán an chomhéadain Teams le haghaidh ceisteanna sonracha don roinn.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Conas a oibríonn an scagaire aibítre i nEolaire na Roinne?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Déan do chuardach a shruthlíniú in Eolaire na Roinne leis an scagaire aibítre atá suite ag barr. Ceadaíonn an ghné seo duit roinneacha a chaolú go tapa trí litir a roghnú.
                  Nuair a roghnaíonn tú litir, scagann TeamConnect agus taispeánann sé na roinneacha sin amháin a bhfuil tús a n-ainmneacha leis an litir roghnaithe agat, rud a fhágann
                  go bhfuil sé níos éasca an méid atá á lorg agat a fháil gan scrollú tríd an liosta iomlán. Chun filleadh ar an radharc roinne iomlán, díroghnaigh an litir aibhsithe.
                  Cinntíonn an nascleanúint intuigthe aibítre seo go bhféadfá aon roinn a aimsiú go héifeachtach de réir a céad litir.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Conas is féidir liom teagmháil a dhéanamh le bainisteoir na roinne?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tá teagmháil a dhéanamh le bainisteoir roinne i TeamConnect díreach. Faigh amach an roinn atá uait agus cliceáil ar ainm an bhainisteora. Spreagfaidh an gníomh seo popover a thaispeánann na roghanna teagmhála atá ag an mbainisteoir leis na deilbhíní rochtana gasta aitheanta:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Deilbhín Comhrá:</Body1Strong> Chun teachtaireacht láithreach a thosú trí chomhrá Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Deilbhín Ríomhphoist:</Body1Strong> Chun ríomhphost nua a oscailt atá seolta chuig iad i Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Deilbhín Glao Físe:</Body1Strong> Chun glao físe a thionscnamh trí Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Deilbhín Glao:</Body1Strong> Chun glao gutha a thosú ag baint úsáide as Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Deilbhín Níos Mó (...):</Body1Strong> Le haghaidh roghanna breise cosúil le cruinniú a sceidealú nó a gcuid faisnéise teagmhála a chur le do liosta Teams.
                    </li>
                  </ul>
                  Soláthraíonn na deilbhíní seo modh réidh chun teacht go díreach ar bhainisteoirí na roinne laistigh de TeamConnect, ag cinntiú cumarsáid éifeachtach laistigh de do eagraíocht.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Cén chaoi ar féidir liom féachaint ar fhostaithe nó teagmháil a dhéanamh leo laistigh de roinn?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Chun aithne a chur ar cé atá i roinn i TeamConnect, féach níos faide ná na poncanna trí cinn in aice le hainm na roinne. Trí chliceáil air seo tabharfar suas roghanna le haghaidh gníomhú breise:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Féach ar Fhostaithe sa Roinn Seo:</Body1Strong> Roghnaigh seo chun liosta de gach fostaí atá nasctha leis an roinn a thaispeáint. Léiríonn an uimhir in aice leis an rogha seo cé mhéad fostaithe atá sa roinn, ag soláthar comhaireamh tapa ceann.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Léaráid Eagraíochta na Roinne:</Body1Strong> Más mian leat tuiscint a fháil ar heirceacht na roinne,
                      roghnaigh 'léaráid eagraíochta na roinne' chun an leagan amach struchtúrach a nochtadh, ag taispeáint an chaidrimh idir ranna tuismitheoirí, an roinn i gceist,
                      agus aon fhoirinn. Cuidíonn an léiriú amhairc seo leat nascleanúint a dhéanamh agus tuiscint a fháil ar an gcóras eagrúcháin, rud a éascaíonn aitheantas agus 
                      teagmháil a dhéanamh le baill sonracha na roinne.
                    </li>
                    <li><Body1Strong>Roinn {'>'} Fostaí:</Body1Strong> Tar éis do na fostaithe laistigh de roinn a fheiceáil, úsáid an cosán breadcrumb seo chun nascleanúint a dhéanamh ar ais go héasca chuig liosta na roinne, ag coimeád taithí iniúchta réidh laistigh de TeamConnect.
                    </li>
                  </ul>
                  Ligeann na gnéithe seo duit ní amháin liosta na bhfostaithe i roinn ar leith a fheiceáil ach cuireann siad bealach ar fáil freisin chun teagmháil a dhéanamh go díreach leo trí uirlisí cumarsáide TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Ag Fionnachtain Mionsonraí Domhain Roinne in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Fiosraigh sonraí ar bith roinne le cliceáil simplí ar a hainm i TeamConnect. Nochtann dialóg mionsonraithe, ag tairiscint téip de fhaisnéis a phéinteálann pictiúr iomlán de ról agus acmhainní na roinne laistigh d'eagraíocht. Gheobhaidh tú gach rud ó cheannaireacht agus aitheantóirí airgeadais go bealaí cumarsáide agus naisc shóisialta. Is é an ghné seo do gheata chuig:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Croitheadh na roinne – a cuspóir, a daoine, agus a táscairí feidhmíochta.</li>
                    <li className={styles.bulletBottomMargin}>Bealaí teagmhála – línte díreacha chuig figiúirí ríthábhachtacha na roinne agus ardáin cumarsáide.</li>
                    <li className={styles.bulletBottomMargin}>An gréasán eagraíochtúil – tuiscint a fháil ar conas a chomhtháthaíonn an roinn le struchtúr níos leithne na cuideachta.</li>
                  </ul>
                  Cibé an bhfuil sé ina fhiosrúchán tapa nó tumadh domhain isteach i sonraí na roinne, úsáideann TeamConnect na huirlisí chun nascadh leis agus tuiscint a fháil ar na heiceachórais uathúla laistigh de d'eagraíocht.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Ag fiafraí faoi Easpa Seoltaí Roinne in TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Uaireanta, d’fhéadfadh sé mothú mar thóraíocht taisce gan léarscáil a bheith ag lorg suíomh roinne. In TeamConnect, ní gheobhaidh tú de ghnáth seoladh tiomnaithe do gach roinn. Seo cúis go minic a fhágtar an mionsonra cartagrafach seo ar lár:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Suíomhanna Iolracha:</Body1Strong> Níl go leor roinne teoranta do spás aonair; táid ag rath ar fud oifigí éagsúla agus tíreolaíochtaí.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Struchtúr Sreabhach:</Body1Strong> Tá tírdhreach eagrúcháin ag athrú i gcónaí, le roinne ag forbairt, ag comhcheangal, nó fiú ag díscaoileadh i gcomhréir le hathruithe straitéiseacha.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Comhoibriú Idir-Roinne:</Body1Strong> Bíonn spásanna oibre nua-aimseartha beo le spiorad comhoibríoch, ag meascadh buanna ó roinne éagsúla laistigh d’áiteanna comhroinnte.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Cultúr Oibre iargúlta:</Body1Strong> Le solúbthacht na hoibre iargúlta, ní i gcónaí a leagtar ancairí roinne i suíomhanna oifige fisiciúla.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Miondealuithe Dlíthiúla:</Body1Strong> Déanann roinne tipiciúil fithis timpeall struchtúr dlíthiúil na cuideachta seachas seoltaí neamhspleácha a bheith acu.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Réamhshimpliú Oibríochta:</Body1Strong> Cuidíonn an béim ar chomhoibriú dinimiciúil, seachas seoltaí statacha, le solúbthacht oibríochtúil a chothabháil.
                    </li>
                  </ul>
                  Tá sé ar fad faoi ghlacadh le cur chuige gan teorainneacha i leith obair foirne agus éifeachtúlacht. Léiríonn TeamConnect an fealsúnacht seo trí naisc thar suíomhanna a bhéim.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. An bhfuil eolas do roinn in easnamh nó as dáta?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tá eagarthóirí na roinne ainmnithe laistigh de do eagraíocht chun eolas sonrach don roinn a chur leis, a nuashonrú, nó a cheartú in TeamConnect.
                  Má tá tú neamhchinnte cé hé/hí d'eagarthóir do roinne nó má tá tacaíocht ó fhoireann tacaíochta AD de dhíth ar fhaisnéis áirithe,
                  déan teagmháil le <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> chun treoir a fháil.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>CCanna Treoracha Oifige</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Réamhrá do Threoracha na nOifige:</Body1Strong> Faigh amach croílár láithreacht fhisiciúil d'eagraíochta trí Threoracha na nOifige,
                a chuimsíonn sonraí bunúsacha oifige, sonrachtaí oibríochtúla, agus léargas cuimsitheach ar na ranna agus an lucht foirne a shainmhíníonn gach suíomh.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Ag Nascleanúint Clibeanna i dTreoracha na nOifige
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Faigh do bhealach timpeall Treoracha na n-Oifige go héasca ag baint úsáide as ár gcóras clibeanna atá deartha le haghaidh rochtain thapa ar an suíomh ceart. Seo mar is féidir le gach clib cabhrú leat do chuardach a shruthlíniú:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Gach Ceann:</Body1Strong> Taispeánann sé liosta iomlán na n-oifigí, ag tairiscint léargas for-leathan ar
                      suíomhanna d'eagraíochta.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favorites:</Body1Strong> Cuidíonn sé leat díriú isteach ar na suíomhanna oifige a mbíonn tú ag freastal orthu go minic nó ar gá
                      duit súil ghéar a choinneáil orthu, trí iad a mharcáil mar 'Favorites'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Do Oifig:</Body1Strong> Scagann an clib pearsanta seo an t-eolaire chun an suíomh oifige ina bhfuil tú
                      lonnaithe a thaispeáint, ag coinneáil do bhunáit bhaile ag barr do mhéar.
                    </li>
                  </ul>
                  Cibé an bhfuil tú ag áirithint seomra cruinnithe nó ag pleanáil cuairte, cuireann na clibeanna seo bealach pearsantaithe agus simplí ar fáil chun idirghníomhú le tírdhreach geografach d'eagraíochta.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Foinsiú Amhairc ar Eolaire an Oifig in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tum isteach i leagan amach eagrúcháin láithreacha éagsúla do chuideachta le hamhairc Eolaire Oifige TeamConnect. Tá gach amharc déanta chun gné ar leith de nascleanúint agus tuiscint oifige a éascú:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Amharc Liosta:</Body1Strong> An suíomh réamhshocraithe a eagraíonn gach suíomh oifige go néata i liosta atá furasta le léamh.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Amharc Cárta Mór:</Body1Strong> Saibhríonn Amharc Cárta Mór san Eolaire Oifige d’amharc le cur i láthair mionsonraithe de gach oifig. Agus tú i mbun cárta oifige, gheobhaidh tú flúirse eolais agus roghanna inrátaithe laistigh den roghchlár "Níos Mó (...)":
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Amharc Ranna:</Body1Strong> Nascleanúint dhíreach chuig liosta de na ranna uile laistigh den oifig.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Amharc Fostaithe:</Body1Strong> Rochtain ar phainéal iomlán na bhfostaithe atá sannaithe don oifig, ag éascú teagmhála dírigh nó taiscéalaíocht ar phearsanra an oifige.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Léaráid Eagrúcháin an Ghníomhaireachta Gnó:</Body1Strong> Léirigh áit na hoifige laistigh de chomhthéacs na heagraíochta níos mó do thuiscint shoiléir ar struchtúirí heirgeacha.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Teagmhálacha Riachtanacha:</Body1Strong> Aithin teagmhálaithe lárnacha na hoifige le haghaidh cumarsáide réidh.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Saoire Phoiblí na hOifige:</Body1Strong> Fan ar an eolas faoi laethanta saoire poiblí sonracha na hoifige chun do sceidil a phleanáil dá réir.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Uaireanta Oibre na hOifige:</Body1Strong> Féach uaireanta oibriúcháin na hoifige chun do chuairteanna nó do ghlaonna a ailíniú le hamanna gníomhacha.
                        </li>
                      </ol>
                      Soláthraíonn an t-amharc seo ní amháin léargas amhairc agus faisnéiseach ach freisin rochtain thapa ar léargais níos doimhne agus ar ghníomhartha dírigh a bhaineann leis an oifig, iad uile curtha faoi rogha "Níos Mó (...)" ar do shon go néata.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Amharc Léaráid Eagrúcháin:</Body1Strong> Léirigh struchtúr heirgeach na n-oifigí tuismitheoirí agus fo-oifigí chun tuiscint a fháil ar an gceangal idir láithreacha oifige éagsúla laistigh de d'eagraíocht.
                    </li>
                  </ul>
                  Scaip trí na hamharceanna seo ag úsáid na n-deilbhíní in aice leis an mbosca cuardaigh chun d’eispéireas brabhsála a shaincheapadh chun freastal ar do riachtanais láithreacha.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Oifigí a Fháil go Tapa le Gné Cuardaigh TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Déantar é a dhéanamh éasca teacht ar an suíomh oifige ceart laistigh de TeamConnect leis an ngné cuardaigh dírithe. Suite go feiceálach in aice na n-deilbhíní amharc, is é an bosca cuardaigh d’uirlis chun oifigí a aimsiú de réir ainm. De réir mar a chlóscríobhann tú, míníonn an t-eolaire an taispeántas chun na hoifigí amháin a thaispeáint a mheaitseálann d’ionchur, ag réimíniú do phróiseas cuardaigh. Tá sé tábhachtach an bosca cuardaigh seo a úsáid laistigh de TeamConnect le haghaidh na dtorthaí is cruinne agus is ábhartha, seachas cuardach ginearálta Microsoft Teams. Leis an bhfeidhmiúlacht cuardaigh dírithe seo, is féidir leat nascleanúint a dhéanamh go tapa chuig aon oifig i líonra d'eagraíochta.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Agluuraa Fọla-kaa Biako Biako ni Sɔhweanim Nkyerɛw ne Yahweanim Biako Biako
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Sɛ wo pɛ sɛ wuhwehwɛ tumi biako nkyerɛw a, yɛn biako biako weanim yɛ ɛkwan pa ma wuhu sɔhweanim foforo bi wɔ din. Wɔ Sɔhweanim Nkyerɛw no so koro mu no, 
                  wobehu litaraa A kosii Z. Sɛ wokleki litarabii bi a, ɛmaa ɛpopo a ɛde akɔka sɔhweanim a ɛhyɛ no din kobiiar no adi kyerɛ, na saa nti ɛsɛ sɛ wosie w’adwuma no. 
                  Sɛ wopɛ sɛ wohu sɔhweanim nyinaa bio a, tantrɛw litarabii a edi ho adaw so. Sɛnea ɛbɛyɛ a, wodi nkitaho sɛ woyɛ pataku a ɛwɔ sɔhweanim din mu nuduru ho, 
                  nanso wopɛ kwan pa sɛ wuhu no kuro mu wɔ TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Asansakasa Ntɔkwa Sɔhweanim Nkɔkyɛre Kronkron wɔ TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hyehyɛ doodo kyerɛ mu abrabɔ a wɔka ho ban yi wɔ w’atitire mu nneɛma saa nferɛw ho adwumayɛde wɔ TeamConnect no ho tumi nkɔkyɛre nkyerɛase: 
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nkɔkyɛre Kronkron:</Body1Strong> Kɔ anim nhwehwɛ sohwεanim no ho asɛde kura mu, 
                      afei di dwuma ho fi titira so kosii dua-nna-koro biara, kyerɛ sɔhweanim no ho amanehunu.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontakt sɛsen:</Body1Strong> Wu ne boafo a ɛhia akyɛde hyehye ahyɛ Microsoft Teams Phone System kwan so.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sɔhweanim Kontakt:</Body1Strong> Kyekyere kasa nneɛma atitire a w'akwan so adi, na wope kyerɛ ho foforo.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ɔhɔho De mu:</Body1Strong> Betwɛn sɔhweanim de mu biakra, sɛnea ɛbɛyɛ a wo atutu so ne de a w៛de dibeae hyehyɛ mu.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nna-koro:</Body1Strong> Tutu wo bibiafi nna-koro bi kunu pɛ sɛ wohu sɛ ɔkwan bɔn no adoso afei aso mpuei.
                    </li>
                  </ul>
                  Sɛ yɛka ho a, Austin anaa Singapore, nkɔkyɛre biara de ɛkwan ma wuhu nea ɛma w’atitire no atena mu bi tumi nni biakoyɛ.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Rianú Taobh Istigh de Ranna Oifige
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ag rianú líonra gairmiúil ranna oifige ar fud TeamConnect ar bhealach éasca. Seo conas dul i ngleic leis na sonraí:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Amharc ar Ranna sa Oifig Seo:</Body1Strong> Aimsigh na ranna go léir a chruthaíonn an 
                      fíochán de chuid na hoifige trí an rogha seo a roghnú ón roghchlár Níos Mó (...).
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sealladh Eagraíochtúil:</Body1Strong> Cliceáil ar aon roinn chun a scairdeagraim a nochtadh don 
                      léiriú amhairc ar a struchtúr, leis an mbóthar grúnlainne ag treorú tú: Oifig {'>'} Roinn {'>'} Chairt.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tumadh Domhain Rannach:</Body1Strong> Iniúchadh breise a dhéanamh ar roinn tríd an 
                      roghchlár Níos Mó (...) a bhualadh in aice leis an ainm rann. Anseo, is féidir leat rochtain a fháil ar liosta de na fostaithe uile laistigh den roinn sin, ceart i gcomhthéacs na hoifige.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nascleanúint Grúnlainne:</Body1Strong> Trasnú trí na leibhéil gan stró. Ón 
                      fhostaithe ar ais chuig na ranna nó ón scairdeagraim, coimeádann an bóthar grúnlainne (Oifig {'>'} Roinn {'>'} Fostaí {'>'} Chairt) do 
                      thuras instintiúil agus ar an mbóthar.
                    </li>
                  </ul>
                  Cuireann conairí casta TeamConnect cuireadh duit iniúchadh a dhéanamh, tuiscint a fháil, agus páirt a ghlacadh i ngach sraith de ranna do oifige, ag cumhachtú leat le smacht nascleanúna cuimsitheach.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Ceangal le Foireann Oifige i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Fiosrach faoi na haghaidheanna taobh thiar de oifig? Tá an freagra ach cliceáil amháin ar shiúl i TeamConnect. Gnóthaigh leis na trí phointe (ellipsis) in aice le ainm na hoifige roghnaithe chun roghanna idirghníomhacha a nochtadh:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Amharc ar Fhostaithe sa Oifig Seo:</Body1Strong> Nocht liosta de na gairmithe go léir 
                      a thugann a dtithe oibre ar an oifig seo. Tá uimhir atá taispeánta ag tairiscint áireamh gasta de na fostaithe, ag cuireadh duit dul isteach sa phobal oifige.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Roghanna Teagmhála:</Body1Strong> Tá gach iontráil fostaí feistithe le deilbhíní gníomhaíochta tapa, 
                      ag ligean duit comhráite, glaonna, nó fiú comhdhálacha físe a thosú díreach ó TeamConnect, ag cothú cumarsáide láithreach intra-oifige.
                    </li>
                    <li><Body1Strong>Nascleanúint:</Body1Strong> Chun filleadh ar an liosta oifige níos leithne ó liosta na bhfostaithe, cliceáil ach 'Oifig' sa bóthar grúnlainne 
                      (Oifig {'>'} Fostaí), agus beidh tú tugtha ar ais chuig forbhreathnú na hoifige.
                    </li>
                  </ul>
                  Leis na gnéithe seo, déanann TeamConnect éasca chun dáileadh an lucht oibre trasna oifigí a shamhlú agus teagmháil a dhéanamh gan stró, 
                  ag cothú ionad oibre ceangailte is cuma cén fad.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. An bhfuil eolas faoi d'oifig in easnamh nó as dáta?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Is féidir le heagarthóirí oifige ainmnithe nuashonruithe ar eolas oifige a láimhseáil i TeamConnect. Má tá tú éiginnte faoi na heagarthóirí oifige nó
                  má theastaíonn cúnamh uait maidir le faisnéis a bhaineann le raon feidhme AD, tá foireann tacaíochta AD réidh le
                  cabhrú ag <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>CCanna Socruithe</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Tábhacht na Socruithe Ginearálta Cruinne i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Sa tírdhreach dinimiciúil atá ann inniu don timpeallacht oibre, tá sé níos tábhachtaí ná riamh fanacht ceangailte agus comhordaithe. Seo áit a bhfuil ról ríthábhachtach ag nuashonrú do
                  Socruithe Ginearálta i TeamConnect:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Rogha na hIonaid Oibre:</Body1Strong> Gabh croílár do shuíomh oibre trí d'ionad oibre a nuashonrú chun scáthán a dhéanamh ar do staid reatha
                        bíodh sé ag aistriú ó chiúnas d’Oifig Baile go dtí pléasc na hOifige mhóir, nó ag socrú isteach i Limistéar Poiblí.
                        Tugann an soiléireacht seo i do shuíomh oibre léargas do chomhghleacaithe ní hamháin ar na bealaí is fearr chun teagmháil a dhéanamh leat, ach freisin ar
                        an ábhar comhrá is féidir leo bheith páirteach iontu leat, ag cosaint rúndacht pléite íogaire go háirithe nuair atá tú i measc timpeallacht oscailte
                        oifig cliant nó i suíochán i seomra feithimh aerfoirt.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Sonrú Workspot:</Body1Strong> Déanann roinnt do chathair oibre níos mó ná bearnaí a dhroichead i bhfoireann iargúlta—a osclaíonn sé
                        doras freisin do chruinnithe gairmiúla spontáineacha nó sóisialta ócáideacha. Cibé acu is caife tapa é, lón fada nó cruinniú comhoibríoch,
                        is féidir le heolas go bhfuil tú sa chathair chéanna lá gnáth a thiontú ina dheis do nasc pearsanta brí. Ní hamháin go neartaíonn sé seo ceangail foirne ach
                        cuireann sé freisin tuiscint níos saibhre ar na núansachtaí áitiúla a d'fhéadfadh a bheith ag dul i bhfeidhm ar do chuid oibre.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Cruinneas Crios Ama:</Body1Strong> Mar a spreadann foirne ilchríocha, tá sé ríthábhachtach go
                      léiríonn do chrios ama do shuíomh reatha chun cumarsáid a chomhchuibhiú. Tá Am Áitiúil do phróifíl TeamConnect go díreach faoi thionchar an tsocraithe seo,
                      ag baint gach buille faoi thuairim as do chomhghleacaithe ag iarraidh teagmháil a dhéanamh leat.
                    </li>
                  </ul>
                  Tum isteach i 'Socruithe' {'>'} 'Socruithe Ginearálta' laistigh de TeamConnect chun na sonraí seo a mhionchoigeartú. Is féidir le cúpla nóiméad caite ag nuashonrú do socruithe
                  an chomhtháthú agus éifeachtúlacht do iarrachtaí comhoibríocha a fheabhsú go mór.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Tuiscint an Rogha Ceardlainn in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Roghnú an suíomh ceart sa roghchlár Ceardlainn cuireann sé TeamConnect in oiriúint le léiriú cruinn ar do stádas oibre reatha. Bog thar an deilbhín eolais le haghaidh míniú mion ar gach rogha:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Amach/Break:</Body1Strong> Léiríonn sé am ar shiúl ón obair, bíodh sé ar bhriseadh nó ag deireadh an lae.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Oifig/Oifig Réimse:</Body1Strong> Léiríonn sé láithreacht i spás oibre bainistithe ag an gcuideachta.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> Ag obair ó spás oibre baile tiomnaithe.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ionad Oibre Cianda/Limistéar Poiblí:</Body1Strong> Ag obair as timpeallacht neamhoifige, rud a léiríonn gá le cumarsáid solúbtha.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ceardlann Custaiméirí/Oifig Phríobháideach:</Body1Strong> Ar an láthair le cliant, ag tabhairt faoi deara leibhéil phríobháideachais le haghaidh íogaireacht comhrá.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Aistear Gnó/Taisteal Gnó/Oiliúint:</Body1Strong> Ar an mbóthar nó i mbun gníomhaíochtaí sonracha a bhaineann le hobair lasmuigh den oifig.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lá Saor Poiblí/Saoire/Éagsúil Saoire:</Body1Strong> Go hoifigiúil as oifig ar chúiseanna pearsanta, sláinte, nó saoire.</li>
                  </ul>
                  Ag roghnú suíomh ceardlainn cuí chinntíonn sé go bhfuil an comhthéacs ag do fhoireann atá riachtanach do idirghníomhaíochtaí, ag urramú infhaighteacht agus príobháideachas.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Ag Láimhseáil an Stádas 'Seiceáilte Isteach' in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tá an clib 'Seiceáilte Isteach' sa Stiúrthóireacht Fostaithe deartha chun gach fostaithe atá faoi láthair ag obair a thaispeáint. Sroichtear an infheictheacht seo trí roghnú Ceardlainn a dhéanann tú:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Comhshaolta Oibre Gníomhacha:</Body1Strong> Léiríonn na roghanna Oifig, Oifig Réimse, Home-Office, Ionad Oibre Cianda, Limistéar Poiblí, Oifig Chustaiméirí, 
                    Oifig Phríobháideach Chustaiméirí, Aistear Gnó, Taisteal Gnó, agus Oiliúint go bhfuil tú gníomhach san obair, ag marcáil tú 'Seiceáilte Isteach' agus go bhfuil tú gníomhach go feiceálach do chomhghleacaithe.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Suíomhanna Neamh-Oibre:</Body1Strong> Ag roghnú Amach, Break, Lá Saor Poiblí, Saoire, Ar Saoire, Saoire Mháithreachais, nó Saoire Tinn 
                      bainníonn stádais duit ón liosta 'Seiceáilte Isteach', ag leagan síos ionchais shoiléire maidir le hinfhaighteacht agus amanna freagartha.
                    </li>
                  </ul>
                  Cabhraíonn an fheidhmiúlacht seo le tréimhsí oibre a idirdhealú ó am pearsanta, ag cabhrú le comhoibriú agus cumarsáid éifeachtach foirne.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Bainistíocht Sonraí agus CCanna Príobháideachta</Subtitle2>
          <Body1>
            <p>
            Sa ré dhigiteach inniu, tá sé ríthábhachtach a thuiscint conas a bhainistítear do chuid sonraí. Tá TeamConnect tiomanta do thrédhearcacht agus do chleachtais láidre cosanta sonraí.
            Thíos, gheobhaidh tú eolas ar conas a láimhseálaimid, a stórálaimid, agus a chosnaímid do chuid sonraí laistigh de TeamConnect, ag cinntiú go bhfuil do spás oibre digiteach sábháilte agus cloíonn sé leis na rialacháin.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                42. Conas a chosnaíonn TeamConnect mo shonraí pearsanta?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>Tá TeamConnect tiomanta do chosaint d’fhaisnéis phearsanta le cur chuige cuimsitheach i leith slándála sonraí:</p>
                  <ul>
                  <li className={styles.bulletBottomMargin}>
                <Body1Strong>Aonair Sínigh-Isteach (SSO):</Body1Strong> Chun slándáil a fheabhsú agus chun eispéireas an úsáideora a shruthlíniú, comhtháthaíonn TeamConnect uathoibriú 
                Aonair Sínigh-Isteach (SSO) le do chuntas Microsoft 365, ag éascú bainistíocht rochtana slán agus gan dua.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                <Body1Strong>Criptiú:</Body1Strong> Cinntímid go mbíonn gach sonra taobh istigh de TeamConnect criptithe i rith an aistrithe agus agus iad ina luí, 
                ag déanamh dóibh bheith do-ruigsithe do pháirtithe neamhúdaraithe.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                <Body1Strong>Laghdaigh Sonraí:</Body1Strong> Tá bailiú ár sonraí teoranta go docht leis na méideanna is riachtanach le haghaidh feidhmiúlacht TeamConnect. 
                An t-aon fhaisnéis úsáideora a stórálaimid ná d’ainm Prionsabail Úsáideora (UPN) ó Azure Entra ID, a d’fhéadfadh cosúil le do sheoladh ríomhphoist cuideachta.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                <Body1Strong>Rochtain Teoranta:</Body1Strong> Tá rochtain ar an UPN teoranta do Riarthóir Síntiúis d’eagraíocht taobh istigh dár bportach Síntiús, 
                ag cinntiú nach bhfeictear an fhaisnéis íogair ach ag pearsanra údaraithe.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                <Body1Strong>Scriosadh Sonraí Pras:</Body1Strong> Tá TeamConnect struchtúrtha chun an UPN stóráilte a scriosadh láithreach faoi thrí choinníoll ar leith: 
                má chuirtear deireadh leis an síntiús ag Riarthóir an Síntiúis, má bhaintear úsáideoir ag an AD laistigh de TeamConnect, nó má éiríonn síntiús TeamConnect as feidhm. Cinntíonn sé seo nach gcoinnítear do chuid sonraí pearsanta níos faide ná a shaol úsáideach nó an gá atá leo.
                  </li>
                  </ul>
                 <p>Trí na bearta seo, geallann TeamConnect go gcoinníonn sé na caighdeáin is airde de chosaint sonraí, ag cinntiú go fanann d’fhaisnéis phearsanta slán agus rúnda.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. An bhfuil tú ag smaoineamh cá bhfuil do chuid sonraí lonnaithe le TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Déanann TeamConnect briseadh as múnla na dtairiscintí SaaS traidisiúnta trí a chinntiú nach bhfágann do chuid sonraí d’eagraíocht riamh. 
                  Murab ionann agus an cleachtas coitianta sonraí a stóráil ar fhreastalaithe scamall seachtracha, úsáideann TeamConnect go hinghlactha Microsoft 365 SharePoint Online d’eagraíochta 
                  chun gach sonraí iarratais a thabhairt faoi dhíon. Ní hamháin go gcuireann an cur chuige nuálach seo ceannas ar fhlaithiúlacht sonraí ach neartaíonn sé slándáil freisin, 
                  ag teacht go foirfe leis an dtiomantas diongbháilte atá againn chun do phríobháideacht a chosaint agus cloí le caighdeáin um chomhlíonadh dian.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. An roinnfear mo chuid sonraí le haon tríú páirtithe?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ní roinntear sonraí le tríú páirtithe! Tá Cubeet tiomanta go daingean dár dtiomantas do phríobháideacht agus do shlándáil do chuid sonraí. Ní roinnimid aon sonraí TeamConnect le tríú páirtithe gan toiliú sainráite a fháil ónár gcustaiméirí. 
                  Inár dtimpeallacht shlán, stóráilimid ach na táscairí riachtanacha, amhail Ainm Príomhúil an Úsáideora (UPN), ainm na heagraíochta, ainm an tionónta, agus ID an tionónta, laistigh dár bPortal Síntiús. 
                  Tá an fhaisnéis seo ríthábhachtach maidir le soláthar seirbhíse agus tá sí cosanta faoi ár mbeartas príobháideachais dian.
                  Bí cinnte, tá ár gcleachtais bhainistíochta sonraí deartha chun a chinntiú go fanann do chuid sonraí faoi rún, le trédhearcacht agus smacht daingean i do lámha.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Conas a chomhlíonann TeamConnect rialacháin cosanta sonraí cosúil le GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Cloíonn TeamConnect le rialacháin cosanta sonraí ar nós GDPR, HIPAA, CCPA, agus eile trí úsáid a bhaint as gnéithe comhlíonta dúchasacha timpeallachta Microsoft 365, 
                  áit a stóráiltear gach sonraí custaiméara go slán. Murab ionann agus samhlacha SaaS traidisiúnta, ní stóráil TeamConnect aon sonraí custaiméara ar a fhreastalaithe féin; ina ionad sin, oibríonn sé laistigh de 
                  timpeallacht Microsoft 365 d’eagraíochta, ag cloí leis na caighdeáin slándála agus comhlíonta láidre atá ann. Cinntíonn an cur chuige seo go fanann do bhainistíocht sonraí ag teacht leis 
                  an gcreat comhlíonta cuimsitheach arna sholáthar ag Microsoft 365, lena n-áirítear cloí le GDPR, HIPAA maidir le faisnéis sláinte, CCPA do chónaitheoirí California, 
                  agus rialacháin eile um chosaint sonraí domhanda. Trí chomhtháthú gan uaim le do shocrú Microsoft 365 atá ann cheana féin, cuireann TeamConnect ar chumas d’eagraíochta smacht iomlán a choimeád ar do chuid sonraí, agus comhlíonadh leis na dlíthe agus na rialacháin is déine um chosaint sonraí.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. TeamConnect ɛtumi ɛnndi data a efi Microsoft Teams chats, calls, video calls, Outlook emails, ne phone system calls?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect da Microsoft Graph API so ma ɛbɔ soa nkabom a ɛyɛ dɛdeɛ sɛ chat, calls, video calls, ne Outlook email wɔ Microsoft Teams mu,
                  na ɛnkyerɛ nnwuma pɛ. Papaapa, TeamConnect betwerɛ nkabom yi hyɛ, ɛyɛ saa na wontumi nhintaw nkyerɛwade afoforo wɔ nkabom kwan no so
                  anaa wɔnnye nnwuma biara a ɛka ho. Nkaakaa no, calls, ne mails no nyinaa di na ɛyɛ n'adwuma Microsoft Teams ne Outlook mu, fa Microsoft's 365 dɔteɛ ne hintabea siam yi so. 
                  Eyi bɛboa sɛ TeamConnect beto wo nkabom dɔteɛ ahorow na afei de, da wo data ho hintabea ne tumi so bɔ mu.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Sɛ wompɛ sɛ wohu wo data nkabom ne ahintasɛm a wɔatumi abue, woma wo hwɛ {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>TeamConnect User Guide</Body1Strong></Link> anaaso wɔdan no IT 
              department fa <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> ama amanedo akakaka.
          </p>
        </Body1>        
        <p></p>  
      <Subtitle1 className={styles.fontColor}>Yɛnte Ase: Kyerɛ Yɛn Wo Nimdeɛ Ne Nhyehyɛe Anom</Subtitle1>
        <Body1>
          <p>
            Wo nimdeɛ na ɛmma TeamConnect n'asɔrɔkwa. Sɛ woaduru so reko akyerɛ sɛ wo wɔ nsɛnkyerɛnne pa biadze a, anaaso wɔ wo dɔ mfaso so a, yɛtee wo. Wo nimdeɛ yɛ adwuma kɛsekɛse wɔ yɛn nimde nhoahoa mu.
          </p>
        </Body1>      
      <Subtitle2>Nhyehyɛe Ne Nsɛnkyerɛnne A Ateaseɛ</Subtitle2>
        <Body1>
          <p>
            Sɛ wopɛ sɛ wobetumi abue a, sane kɔ yɛn {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Feedback Page
            </Link>
            . Sɛ yɛyɛ ne dɔ bi a woasiesie a, anaaso sɛ wobetumi aba dɔteɛ bi ka, fapɛnfo bɛhwɛ wo nimdeɛ. Saa nsɛnkyerɛnne yi bɛboa sɛ TeamConnect tɔ so na ɛtumi pɛ wo nhyira.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
