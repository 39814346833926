import PropTypes from 'prop-types';
import { Body1 } from '@fluentui/react-components';

function ErrorMessage({ text, color }) {
  return (
    <div style={{ textAlign: 'center' }}>
      <Body1 style={{ color: color || '#cc0000' }}>
        { 
          (text) ? text :`Unknown Exception: ${text}`
        }
      </Body1>
    </div>
  );
};

ErrorMessage.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  weight: PropTypes.string,
};

export default ErrorMessage;