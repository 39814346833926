import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>Centrul de Ajutor și Întrebări Frecvente TeamConnect</Title3>
        <p><Body1>
          Bine ați venit la Centrul de Ajutor TeamConnect, conceput pentru a vă susține experiența cu aplicația noastră complexă de director corporativ. Aici veți găsi resurse pentru a optimiza utilizarea TeamConnect în cadrul Microsoft Teams, îmbunătățind comunicarea în cadrul rețelei dumneavoastră.
        </Body1></p>
        <Subtitle2>Ghid de Referință Rapidă</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Capabilități de Căutare Avansate: </Body1Strong>
              Găsiți rapid colegii, departamentele și contactele esențiale cu ajutorul instrumentelor noastre de căutare puternice.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Grafice Organizaționale Vizuale: </Body1Strong>
              Navigați cu ușurință ierarhia organizației dvs. și înțelegeți liniile de raportare prin intermediul graficelor organizaționale intuitive.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Instrumente Adaptative pentru Nevoile de Astăzi: </Body1Strong>
              Utilizați funcționalități moderne, adaptate cerințelor locurilor de muncă contemporane.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Optimizarea Experienței Dumneavoastră</Subtitle2>
        <Body1>
          <p>
            TeamConnect revoluționează modul în care interacționați în cadrul structurii organizaționale, transformând comunicațiile de zi cu zi în angajamente strategice.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Obțineți Ajutor și Întrebări Frecvente</Subtitle1>
        <Body1>
          <p>
            Găsiți răspunsuri la întrebări comune despre caracteristicile și setările TeamConnect în această secțiune dedicată. Fie că navigați în aplicație, personalizați setările sau căutați soluții la provocări, Centrul nostru de Ajutor este aici pentru a vă asista eficient.
          </p>
        </Body1>
        <Body1>
          <p>
            Pentru suport mai profund sau orientare specifică, vă rugăm să consultați <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>Ghidul Utilizatorului TeamConnect</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>        
        <Subtitle2>Utilizarea Întrebărilor frecvente TeamConnect</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Nu găsiți TeamConnect în bara laterală a Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Dacă TeamConnect nu este vizibil sau a dispărut din bara laterală Microsoft Teams, îl puteți fixa cu ușurință pentru acces rapid. Urmați acești pași simpli:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Deschideți Microsoft Teams și faceți clic pe iconița '...' (+ Aplicații) din partea inferioară a barei laterale.</li>
                    <li className={styles.bulletBottomMargin}>Căutați 'TeamConnect' în bara de căutare din partea de sus a ferestrei pop-up.</li>
                    <li className={styles.bulletBottomMargin}>Faceți clic dreapta pe aplicația TeamConnect din rezultatele căutării și selectați 'Fixează'.</li>
                  </ol>
                  TeamConnect va fi acum fixat în bara laterală, fiind accesibil cu doar un clic!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Întâmpinați probleme tehnice cu TeamConnect? Iată cum puteți obține ajutor.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Dacă întâmpinați probleme tehnice cu TeamConnect în Microsoft Teams, echipa de suport IT a organizației voastre este aici pentru a ajuta. 
                  Contactați-i la <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, și vă vor asista prompt.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Nu vedeți detaliile angajatului dumneavoastră?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Dacă profilul dumneavoastră pare un pic gol, afișând doar numele și emailul, este probabil că echipa HR nu a adăugat încă restul detaliilor angajatului în TeamConnect. 
                  Nu trebuie să vă faceți griji! Un mesaj rapid către echipa de suport HR va pune lucrurile în mișcare. Aceștia sunt pregătiți să completeze profilul dumneavoastră cu toate detaliile importante.
                  Contactați-i la <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> și urmăriți cum profilul dumneavoastră prinde viață!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Ce opțiuni de limbă sunt disponibile în TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect îmbrățișează diversitatea globală a Microsoft Teams prin suportarea tuturor limbilor acestuia.
                  Fie că preferi araba sau greaca, japoneza sau suedeza, ai o lume de opțiuni la îndemână, incluzând:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arabă, Bulgară, Chineză (Simplificată și Tradițională), Croată, Cehă, Daneză, Olandeză, Engleză, Estonă, Finlandeză, 
                      Franceză, Germană, Greacă, Ebraică, Maghiară, Indoneziană, Italiană, Japoneză, Coreeană, Letonă, Lituaniană, Norvegiană (Bokmal), 
                      Poloneză, Portugheză (Brazilia și Portugalia), Română, Rusă, Sârbă (Latin), Slovacă, Slovenă, Spaniolă, Suedeză, Tailandeză, 
                      Turcă, Ucraineană, Vietnamneză.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Pentru a personaliza experiența ta TeamConnect în limba preferată, te rugăm să ajustezi setările de limbă direct în Microsoft Teams,
                  deoarece TeamConnect se integrează complet cu aceste setări:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Mergi la setările tale Microsoft Teams făcând clic pe cele trei puncte de lângă poza ta de profil din partea de sus, și apoi selectează 'Apariță și accesibilitate'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Derulează până la secțiunea 'Limbă' și selectează limba preferată din meniul derulant.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Confirmă schimbările făcând clic pe butonul 'Salvează și repornește' pentru a aplica noile setări de limbă.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Notă: </Body1Strong>
                <Body1>
                  Schimbările făcute în setările de limbă din Microsoft Teams vor actualiza de asemenea experiența ta TeamConnect în mod automat.
                  Fii conștient că modificarea limbii în Teams poate ajusta de asemenea formatul tău de dată și oră. În acest moment,
                  TeamConnect evoluează pentru a susține scrierea de la dreapta la stânga. Suntem entuziasmați de aceste îmbunătățiri
                  și te vom ține la curent pe măsură ce devin disponibile.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Cum pot schimba tema în TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect se sincronizează cu preferințele tale de temă din Microsoft Teams, incluzând opțiunile Luminoasă, Întunecată și Contrast Ridicat. Pentru a personaliza tema:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Accesează setările Microsoft Teams făcând clic pe cele trei puncte lângă poza ta de profil, de sus.</li>
                    <li className={styles.bulletBottomMargin}>Selectează 'Aspect și accesibilitate' pentru a explora opțiunile de teme.</li>
                    <li className={styles.bulletBottomMargin}>Alege tema preferată din opțiunile disponibile pentru a actualiza instant interfața ta TeamConnect pentru a se potrivi.</li>
                  </ol>
                  Alegerea temei în Microsoft Teams va fi aplicată automat în TeamConnect, asigurând o experiență vizuală consistentă în spațiul tău de lucru.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Explorarea butoanelor de acțiune pentru contacte în TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  În întregul TeamConnect, oriunde găsești detaliile de contact ale unui angajat, vei descoperi de asemenea butoanele noastre convenabile de acces rapid. Aceste unelte practice 
                  sunt proiectate pentru a eficientiza comunicarea în cadrul ecosistemului Microsoft, asigurându-te că ești mereu la un clic distanță de a te conecta. 
                  Iată ghidul tău complet pentru utilizarea eficientă a acestor butoane:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Iconița de chat:</Body1Strong> Fă clic pentru a începe un chat instant pe Microsoft Teams. Dacă trebuie să te întorci la TeamConnect după începerea unui chat, folosește pur și simplu săgeata înapoi în Aplicația Desktop, butonul înapoi în Aplicația Mobilă, sau butonul înapoi al browserului tău.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Iconița de email:</Body1Strong> Deschide un nou draft de email în Outlook cu adresa destinatarului pregătită în câmpul "Către:". Dacă Outlook nu se lansează, merită să verifici cu departamentul tău IT pentru eventuale restricții.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Iconița de apel video:</Body1Strong> Inițiază un apel video față în față folosind Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Iconița de apel:</Body1Strong> Începe un apel vocal Microsoft Teams, simplificând discuțiile rapide.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Iconița de organigramă:</Body1Strong> Arată locul angajatului în ierarhia organizațională, inclusiv managerul și rapoartele directe.</li>
                    <li><Body1Strong>Mai multe (...) Iconița:</Body1Strong> Dezvăluie opțiuni suplimentare:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Întâlnire acum:</Body1Strong> Deschide Microsoft Teams pentru a începe imediat o întâlnire cu angajatul.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Întâlnire nouă:</Body1Strong> Te direcționează către Microsoft Teams pentru a programa o nouă întâlnire.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Adaugă contact:</Body1Strong> Adaugă detaliile de contact ale angajatului din TeamConnect în noua aplicație Microsoft Teams People, îmbogățind funcția ta de apel.</li>
                      </ul>
                    </li>
                  </ul>
                  Aceste butoane intuitive sunt create pentru a asigura că te poți conecta eficient cu colegii, fie pentru un chat rapid, un email, sau pentru a programa întâlniri viitoare.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Efectuarea apelurilor prin TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Îți dorești să faci un apel? TeamConnect eficientizează comunicația ta integrându-se cu Microsoft Teams. 
                  Pur și simplu fă clic pe numărul de telefon dorit în TeamConnect, și voilà, interfața de apelare Microsoft Teams apare, 
                  gata să inițiezi apelul fără probleme.
                  <p>
                  <Body1Strong>Notă: </Body1Strong> 
                  Întâmpini probleme la efectuarea apelurilor? Aceasta ar putea fi din cauza politicilor IT ale organizației tale sau 
                  a utilizării unui sistem telefonic diferit de Microsoft Teams Phone System. Verificarea cu departamentul tău IT la <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, poate clarifica situația.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Există costuri asociate cu efectuarea apelurilor prin TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect îmbunătățește experiența apelurilor tale utilizând Microsoft Teams. Iată ce trebuie să știi despre costurile legate de apeluri:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Pentru apeluri către telefoanele de serviciu ale angajaților, telefoanele departamentelor sau linii directe în cadrul organizației tale (numere interne), 
                      de obicei <Body1Strong>nu există costuri</Body1Strong> când se folosește Sistemul Telefonic Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Pentru apeluri externe, cum ar fi cele către telefoane mobile sau linii externe, se aplică <Body1Strong>tarifele standard</Body1Strong> ale sistemului telefonic al organizației tale. 
                      Aceste tarife variază în funcție de planul tău de servicii specifice cu Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Notă:</Body1Strong> Dacă întâmpini probleme sau ai întrebări legate de costurile apelurilor, este cel mai bine să consulți 
                    departamentul IT la <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> pentru informații detaliate adaptate 
                    la configurarea și politicile organizației tale.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Ai observat o neconcordanță la statusul tău de prezență 'Out of Office' în TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ai observat că statusul tău de prezență 'Out of Office' din Microsoft 365 nu se reflectă corect în TeamConnect? 
                  Te avem acoperit. Funcția de sincronizare a statusului de prezență 'Out of Office' a fost deja implementată în TeamConnect.
                  Așteptăm în prezent implementarea acestei funcții de către Microsoft. Odată ce se va întâmpla, statusul tău de prezență 'Out of Office' 
                  va fi reflectat corect în TeamConnect, aliniindu-se cu indicatorii de prezență ai Microsoft Teams pentru o experiență fără cusur.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>FAQ-uri Director Angajați</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >Introducere Director Angajați:</Body1Strong> Navighează peisajul profesional al organizației tale cu Directorul de Angajați, 
                centrul tău principal pentru detalii despre angajați, dinamica la locul de muncă și conectivitate directă la profilurile colegilor tăi și relațiile lor manageriale.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Ai nevoie să adaugi, actualizezi sau corectezi datele tale de angajat?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Pentru a adăuga, actualiza sau corecta datele tale de angajat în TeamConnect, te rog să iei legătura cu echipa de suport HR a organizației tale. 
                  Ei sunt pregătiți să ajute la <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Ce se întâmplă când dau clic pe numele unui angajat din director?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Dând clic pe numele unui angajat în Directorul de Angajați TeamConnect, se deschide o pagină detaliată de profil pentru acea persoană. Acest profil oferă informații complete
                  despre angajat, inclusiv, dar fără a se limita la, titlul funcției, departamentul, locația biroului, informațiile de contact și multe altele. Este conceput pentru a-ți oferi
                  o vedere de ansamblu completă asupra rolului profesional al angajatului și cum se integrează în organizație, facilitându-ți înțelegerea responsabilităților acestuia și
                  modul în care ai putea colabora cu el.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Ce informații sunt incluse în profilul unui angajat?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Fiecare profil de angajat în TeamConnect este un rezumat cuprinzător, oferind o multitudine de informații despre viața profesională a persoanei.
                  Iată ce vei găsi:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Detalii de bază:</Body1Strong> Nume, titlul funcției, departamentul și locația sucursalei.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Structura de raportare:</Body1Strong> Informații despre rapoarte directe și funcționale,
                      inclusiv managerii de linie și funcționali.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Statusul la locul de muncă:</Body1Strong> Statusul actual de muncă, cum ar fi Concediu, Deplasare,
                      Călătorie de afaceri, etc.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Locul de muncă & Ora locală:</Body1Strong> Locația actuală de muncă a angajatului și
                      fusul orar local.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Detalii de contact:</Body1Strong> Telefon de serviciu, număr de mobil și adresă de email.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Adresa biroului:</Body1Strong> Locația completă a biroului inclusiv numele clădirii,
                      adresa și harta Google.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Despre:</Body1Strong> O scurtă introducere personală sau bio profesională.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Relații angajați:</Body1Strong> Detalii ale structurii de raportare a angajatului și 
                      relațiile în echipă.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Linkuri sociale & Limbi:</Body1Strong> Linkuri către profiluri sociale profesionale 
                      și competențe lingvistice.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Competențe:</Body1Strong> O listă de competențe profesionale și domenii de expertiză.</li>
                  </ul>
                  În plus, profilul oferă opțiuni de acțiune pentru a conecta prin chat, email, apel video, sau pentru programarea întâlnirilor, împreună cu
                  acces rapid la organigrama organizației pentru un context mai larg al echipei.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Cum îmi pot actualiza informațiile din profil?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Personalizarea profilului tău este ușoară în TeamConnect. Iată cum poți face asta:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Navighează în tab-ul <Body1Strong>Employee</Body1Strong> și clic pe <Body1Strong>Avatar</Body1Strong> pentru a-ți deschide profilul.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        În colțul stânga sus, vei găsi linkul <Body1Strong>"Editează-ți profilul"</Body1Strong>. 
                        Dând clic pe acesta se va deschide dialogul de editare a profilului tău.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Aici, îți poți îmbunătăți profilul adăugând sau actualizând secțiunea <Body1Strong>Despre tine</Body1Strong>, linkurile către rețele de socializare și numărul de telefon. 
                        Acest lucru facilitează colegilor tăi să se conecteze sau să te urmărească.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Nu uita să apeși pe butonul <Body1Strong>Save</Body1Strong> pentru a aplica modificările.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Derulează în jos în dialog pentru a adăuga limbile tale <Body1Strong>Languages</Body1Strong> și <Body1Strong>Proficiency</Body1Strong>, 
                        precum și competențele tale <Body1Strong>Skills</Body1Strong>. Partajarea acestei informații ajută colegii să înțeleagă 
                        domeniile tale de expertiză și limbile în care poți comunica.
                      </li>
                    </ol>
                  Actualizându-ți profilul nu doar că menții informațiile curente, dar și facilitează conexiuni mai bune în cadrul organizației tale.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Actualizarea locului de muncă, locației de lucru și fusul orar în TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Menținerea informațiilor despre locul de muncă, locația de lucru și fusul orar actualizate este crucială pentru colaborarea fără probleme în Microsoft Teams.
                  Aceste actualizări sunt reflectate instantaneu în profilul și listările tale TeamConnect, permițând colegilor să înțeleagă rapid locația ta de lucru
                  și cele mai bune momente pentru a te contacta, îmbunătățind astfel colaborarea între diferite regiuni și fusuri orare.
                  <p></p>
                  Gata să îți actualizezi detaliile? Este ușor în TeamConnect! Îndreaptă-te către 'Settings' în meniul de sus și navighează la 'General Settings'.
                  Aici, poți actualiza cu ușurință locul de muncă și fusul orar, și să specifici locația ta de lucru pentru ziua respectivă. Nu uita să salvezi modificările pentru
                  a ține pe toți la curent și pentru a asigura o colaborare eficientă, indiferent de locația ta!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Ai observat ceva incorect în profilul unui coleg?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Dacă întâlnești detalii în profilul unui coleg care nu par corecte, cel mai bun prim pas este să-i dai un sfat prietenos. O conversație rapidă sau un email ar trebui să fie de ajuns! Dacă dintr-un motiv sau altul informațiile nu sunt actualizate, nu-ți face griji—doar contactează echipa ta de suport HR la <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, și ei vor fi mai mult decât fericiți să intervină și să rezolve problema.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Înțelegerea grupurilor de etichete: Toate, Favorite, Prezenți la serviciu, & Angajați noi
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Toate:</Body1Strong> Această etichetă implicită afișează fiecare angajat din organizația dumneavoastră, 
                      oferind o vedere cuprinzătoare asupra echipei.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favorite:</Body1Strong> Apăsați aici pentru a filtra vizualizarea către colegii pe care i-ați marcat 
                      ca 'Favorite', facilitând astfel conectarea rapidă cu contactele cheie.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Prezenți la serviciu:</Body1Strong> Această etichetă indică angajații care sunt activi la serviciu, deosebind 
                      de simpla prezență online în Microsoft Teams. Când sunt marcați ca prezenți, angajații semnalează că sunt la serviciu și împărtășesc locația lor de lucru curentă 
                      pe durata zilei. Aceasta poate include o varietate de locuri de muncă precum Birou, Birou și Teren, Birou de Acasă, Locul de muncă la Distanță, Locul de muncă al Clientului, 
                      Biroul Privat al Clientului, Zonă Publică, în Drum, Călătorie de Afaceri, sau chiar Instruire. Prin urmare, statutul de 'Prezenți la serviciu' oferă o indicație clară 
                      că angajatul este activ la serviciu, oferind colegilor o înțelegere precisă a contextului și disponibilității actuale de muncă ale fiecăruia.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Angajați noi:</Body1Strong> Identificați fețe noi în TeamConnect cu această etichetă, evidențiind 
                      angajații care au fost adăugați în aplicație în ultimele 30 de zile. În mod tipic, acest lucru coincide cu adăugările recente în organizația dumneavoastră, ajutând 
                      la întâmpinarea noilor membri ai echipei.
                    </li>
                  </ul>
                  Aceste grupuri de etichete sunt concepute pentru a simplifica navigarea și a îmbunătăți experiența dumneavoastră cu TeamConnect, asigurând că puteți găsi și 
                  vă puteți conecta întotdeauna eficient cu colegii dumneavoastră.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Descifrarea pictogramelor de lângă caseta de căutare
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Directorul de angajați al TeamConnect este conceput pentru versatilitate și ușurință, prezentând patru pictograme care oferă diferite vizualizări 
                  și acces rapid la detalii cheie. Iată ce dezvăluie fiecare pictogramă:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vizualizare Listă:</Body1Strong> Vizualizarea dvs. principală, prezentând toți membrii organizației 
                      într-o listă simplă.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vizualizare Carton Mic:</Body1Strong> O alternativă compactă, această vizualizare transformă 
                      lista în carduri de angajați de dimensiuni reduse.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vizualizare Carton Mare:</Body1Strong> Alegeți mai multe detalii cu carduri mai mari, care includ 
                      opțiuni suplimentare de contact precum numere de telefon și mobile pentru apeluri facile.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Diagrama Organizațională:</Body1Strong> Vizualizați structura companiei cu ajutorul vizualizării Diagrama Organizațională, 
                      perfectă pentru înțelegerea relațiilor de raportare și navigarea în peisajul organizațional.</li>
                  </ol>
                    Comutați între aceste pictograme pentru a găsi vizualizarea care se potrivește cel mai bine nevoilor dumneavoastră în momentul respectiv, fie că căutați informații rapide sau insighturi mai profunde.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Pentru căutări rapide ale colegilor, unde ar trebui să mă uit?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Cauți un coleg? Doar mergi la caseta de căutare TeamConnect, situată în partea dreaptă sus lângă pictograma Diagrama Organizațională. 
                  Începe să tastezi numele lor, și urmărește cum rezultatele se rafinează cu fiecare literă. Amintește-ți, pentru căutările în TeamConnect, sări peste bara de căutare Microsoft Teams 
                  din partea de sus - caseta noastră specială de căutare este calea de urmat!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Cum funcționează căutarea alfabetică pentru găsirea angajaților?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Directorul de angajați TeamConnect include o funcție practică de căutare alfabetică pentru a te ajuta să localizezi rapid angajații după prenume. 
                  Pur și simplu fă clic pe o literă din alfabetul afișat în partea de sus a directorului, iar TeamConnect îți va arăta instantaneu toți angajații 
                  ale căror prenume încep cu acea literă. Pentru a reveni la lista completă de angajați, doar deselectează litera evidențiată. Această funcție intuitivă 
                  facilitează navigarea prin liste mari de angajați, asigurându-te că poți găsi colegii rapid și eficient.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Cum pot ajusta numărul de angajați afișați pe pagină?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect îți permite să personalizezi câte profile de angajați vezi pe pagină, facilitând navigarea prin Directorul de angajați. 
                  La partea de jos a fiecărei pagini, vei găsi opțiuni de paginare: 10, 50, 100, 500, 1000 și 5000. Setarea implicită afișează 10 profile pe pagină. 
                  Pentru a vedea mai multe profile pe o singură pagină, selectează pur și simplu numărul preferat dintre aceste opțiuni. Această funcție oferă flexibilitate 
                  în modul în care răsfoiești directorul, permițându-ți să adaptezi afișarea la nevoile sau preferințele tale specifice.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Cum pot sorta informațiile angajaților în Vizualizarea Listă?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  În Vizualizarea Listă a Directorului de angajați TeamConnect, ai flexibilitatea de a sorta informațiile angajaților pentru a se potrivi nevoilor tale. Coloanele—Numele Angajatului, 
                  Titlul Profesional, Departamentul, Locația Biroului, și Locul de Muncă—sunt toate sortabile. Fă clic pur și simplu pe antetul coloanei pe care dorești să o sortezi. Un clic va sorta 
                  coloana în ordine ascendentă, iar un alt clic o va sorta în ordine descendentă. Implicit, directorul este sortat după Numele Angajatului în ordine ascendentă, 
                  bazându-se pe prenume. Această caracteristică de sortare te ajută să organizezi rapid și să vizualizezi detaliile angajaților într-un mod care îți servește cel mai bine scopul, 
                  fie că ești în căutarea unei persoane specifice sau ai nevoie să grupezi angajații după un criteriu particular, cum ar fi departamentul sau locația.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Întrebări frecvente despre Directorul Departamentelor</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Introducere în Directorul Departamentelor:</Body1Strong> Directorul Departamentelor este poarta ta către înțelegerea structurii complexe a 
              organizației tale, oferind o vedere clară asupra ierarhiilor departamentale, leadership-ului managerial și cailor pentru o comunicare departamentală fără probleme.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Cum funcționează etichetele 'Toate', 'Favorite' și 'Departamentul tău' în Directorul Departamentelor?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  În Directorul Departamentelor din TeamConnect, etichetele te ajută să navighezi rapid și să filtrezi informațiile departamentale. Iată ce reprezintă fiecare etichetă:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Toate:</Body1Strong> Această etichetă afișează toate departamentele din organizația ta, oferind o 
                      vedere comprehensivă.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favorite:</Body1Strong> Folosește această etichetă pentru a vedea o listă selectă a departamentelor pe care le-ai 
                      marcat ca 'Favorite', facilitând accesul la departamentele cu care interacționezi frecvent.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Departamentul tău:</Body1Strong> Această etichetă filtrează vizualizarea pentru a arăta doar departamentul tău, 
                      permițând acces rapid la informațiile și actualizările departamentale imediate.</li>
                  </ul>
                  Aceste etichete sunt proiectate pentru a îmbunătăți navigarea ta în cadrul Directorului Departamentelor, sporind capacitatea ta de a găsi și interacționa cu informațiile departamentale eficient.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Înțelegerea Vizualizărilor în Directorul Departamentelor din TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigarea prin Directorul Departamentelor în TeamConnect este optimizată cu multiple opțiuni de vizualizare pentru a se potrivi preferințelor tale. Fiecare vizualizare oferă o 
                  modalitate unică de a explora informațiile departamentale în detaliu, incluzând Numele Departamentului, Managerul Departamentului, Departamentul Părinte, Telefonul Departamentului, 
                  Linia Directă a Departamentului și Emailul Departamentului. Iată o imagine de ansamblu a vizualizărilor disponibile:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vizualizare Listă:</Body1Strong> Configurația implicită care listează ordonat toate departamentele, oferind o 
                      vedere clară și concisă.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vizualizare Card Mare:</Body1Strong> Schimbă la această vizualizare pentru a vedea carduri detaliate ale departamentelor, oferind 
                      un rezumat extins al informațiilor cheie ale fiecărui departament.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vizualizare Diagramă Organizațională:</Body1Strong> Pentru o reprezentare vizuală a structurii și relațiilor departamentale, 
                      vizualizarea Diagramă Organizațională ilustrează cum fiecare departament se încadrează în ierarhia organizațională mai largă.
                    </li>
                  </ul>
                  Pentru a comuta între aceste vizualizări, folosește pur și simplu icoanele de lângă caseta de căutare din partea de sus a Directorului Departamentelor. Fiecare vizualizare este proiectată pentru a te ajuta 
                  să accesezi și să înțelegi detaliile departamentale cu ușurință, asigurând că găsești informațiile de care ai nevoie în formatul care ți se potrivește cel mai bine.
                </Body1>
              </AccordionPanel>
            </AccordionItem>    
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Cum să folosești căsuța de căutare în Directorul Departamentelor
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Localizează fără întreruperi orice departament din TeamConnect folosind căsuța de căutare dedicată situată chiar lângă pictogramele de vizualizare în partea de sus a 
                  Directorului de Departamente. Trebuie doar să începi să scrii numele departamentului pe care îl cauți și urmărește cum directorul se refinează pentru a afișa doar 
                  meciurile relevante. Această funcționalitate este concepută special pentru a te ajuta să eviți necesitatea de a căuta manual prin întregul director, aducând 
                  departamentul de care ai nevoie direct la îndemâna ta. Reține că această funcționalitate de căutare este unică pentru Directorul de Departamente TeamConnect, așa că asigură-te 
                  că folosești această căsuță de căutare în locul căutării principale Microsoft Teams situată chiar în partea superioară a interfeței Teams pentru întrebări specifice departamentului.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Cum funcționează filtrul alfabetic în Directorul Departamentelor?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Simplifică-ți căutarea în Directorul Departamentelor cu filtrul alfabetic situat în partea de sus. Această caracteristică îți permite să restrângi rapid departamentele 
                  selectând o literă. Odată ce alegi o literă, TeamConnect filtrează și afișează doar acele departamente ale căror nume începe cu litera selectată, făcându-ți 
                  mult mai ușor să găsești ceea ce cauți fără a derula prin întreaga listă. Pentru a reveni la vizualizarea completă a departamentelor, deselectează pur și simplu litera 
                  evidențiată. Această navigație alfabetică intuitivă asigură că poți localiza eficient orice departament după litera inițială.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Cum pot să contactez managerul departamentului?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Contactarea unui manager de departament în TeamConnect este directă. Trebuie doar să localizezi departamentul de interes și să faci clic pe numele managerului. Aceasta 
                  acțiune va declanșa un popover care afișează opțiunile de contact ale managerului cu pictogramele rapide familiare:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icoana Chat:</Body1Strong> Pentru a începe un mesaj instantaneu prin chat-ul Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icoana Email:</Body1Strong> Pentru a deschide un nou e-mail adresat lor în Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icoana Apel Video:</Body1Strong> Pentru a iniția un apel video prin Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icoana Apel:</Body1Strong> Pentru a începe un apel vocal utilizând Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icoana Mai multe (...) :</Body1Strong> Pentru opțiuni suplimentare, cum ar fi programarea unei întâlniri sau adăugarea 
                      informațiilor lor de contact în lista ta Teams.
                    </li>
                  </ul>
                  Aceste icoane oferă o metodă fără probleme pentru a contacta direct managerii departamentelor din cadrul TeamConnect, asigurând o comunicare eficientă în cadrul 
                  organizației tale.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Cum pot vedea sau contacta angajații dintr-un departament?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Pentru a explora cine este cine în cadrul unui departament în TeamConnect, nu căutați mai departe decât cele trei puncte de lângă numele departamentului. Dând clic pe acesta, 
                  vor apărea opțiuni pentru acțiuni ulterioare:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vizualizați angajații acestui departament:</Body1Strong> Selectați această opțiune pentru a afișa o listă a tuturor 
                      angajaților asociați cu departamentul. Numărul de lângă această opțiune indică câți angajați sunt în departament, oferind un recensământ rapid.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organigrama departamentului:</Body1Strong> Dacă doriți să înțelegeți ierarhia departamentului, 
                      alegeți 'Organigrama departamentului' pentru a dezvălui structura organizațională, prezentând relația între departamentele părinte, departamentul în cauză, 
                      și eventualele subdepartamente. Această reprezentare vizuală vă ajută să navigați și să înțelegeți cadrul organizațional, facilitând identificarea și 
                      contactarea membrilor specifici ai departamentului.
                    </li>
                    <li><Body1Strong>Departament {'>'} Angajat:</Body1Strong> După vizualizarea angajaților dintr-un departament, folosiți această cale de navigare pentru a reveni 
                      ușor la lista departamentelor, menținând o experiență de explorare fluidă în cadrul TeamConnect.
                    </li>
                  </ul>
                  Aceste caracteristici vă permit nu doar să vedeți lista angajaților dintr-un anumit departament, dar oferă și o cale pentru a-i contacta direct prin 
                  instrumentele de comunicare ale TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Descoperirea detaliilor amănunțite ale departamentelor în TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Aprofundați detaliile oricărui departament cu un simplu clic pe numele său în TeamConnect. Se desfășoară un dialog detaliat, oferind o bogată tapiserie de 
                  informații care creează o imagine completă a rolului și resurselor departamentului în cadrul organizației dumneavoastră. Veți găsi totul, de la conducerea și 
                  identificatori financiari până la canale de comunicare și legături sociale. Această caracteristică este poarta dvs. către:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Inima departamentului – scopul, oamenii și metricele de performanță ale acestuia.</li>
                    <li className={styles.bulletBottomMargin}>Căi de contact – linii directe către figuri cheie ale departamentului și platforme de comunicare.</li>
                    <li className={styles.bulletBottomMargin}>Rețeaua organizațională – înțelegerea modului în care departamentul se integrează în structura mai largă a companiei.</li>
                  </ul>
                  Fie că este vorba de o întrebare rapidă sau de o analiză profundă a datelor departamentale, TeamConnect vă oferă instrumentele necesare pentru a vă conecta cu și a înțelege ecosistemele unice din cadrul organizației dumneavoastră.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Curiozitate despre absența adreselor departamentelor în TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Descoperirea locației unui departament poate uneori să pară o vânătoare de comori fără hartă. În TeamConnect, nu veți găsi de obicei o adresă dedicată pentru fiecare departament. Iată de ce acest detaliu cartografic este adesea omis:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Mai multe locații:</Body1Strong> Multe departamente nu sunt limitate la un singur spațiu; ele
                      prosperă în diverse birouri și geografii.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Structură fluidă:</Body1Strong> Peisajul organizațional este în continuă schimbare, cu 
                      departamente care evoluează, se contopesc sau chiar se desființează în conformitate cu schimbările strategice.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Colaborare interdepartamentală:</Body1Strong> Spațiile de lucru moderne vibrează cu spiritul colaborativ, adesea combinând talente din diverse departamente în spații comune.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Munca la distanță:</Body1Strong> Cu flexibilitatea muncii la distanță, ancorarea departamentală nu este întotdeauna fixată în locațiile fizice ale birourilor.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nuanțe legale:</Body1Strong> Departamentele orbită în jurul structurii legale a companiei mai degrabă decât să aibă propriile adrese autonome.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Streamlining operațional:</Body1Strong> Menținerea focusului pe munca de echipă dinamică, nu pe adrese statice, ajută la păstrarea agilității operaționale.
                    </li>
                  </ul>
                  Este vorba despre a îmbrățișa o abordare fără limite la munca în echipă și eficiență. TeamConnect reflectă această filozofie prin accentuarea conexiunilor în loc de locații.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Lipsesc sau sunt învechite informațiile departamentului dumneavoastră?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Editorii departamentelor sunt desemnați în cadrul organizației dumneavoastră pentru a adăuga, actualiza sau corecta informațiile specifice departamentului în TeamConnect.
                  Dacă nu sunteți sigur cine este editorul departamentului dumneavoastră sau dacă anumite informații necesită intervenția echipei de suport HR,
                  vă rugăm să contactați <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> pentru îndrumare.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Întrebări frecvente despre Directorul de Birouri</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Introducere în Directorul de Birouri:</Body1Strong> Descoperiți baza fizică a prezenței organizației dumneavoastră prin intermediul Directorului de Birouri,
                care include detalii esențiale despre birouri, specificații operaționale și o privire cuprinzătoare asupra departamentelor și personalului care definește fiecare locație.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Navigând Etichetele în Directorul de Birouri
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigați cu ușurință prin Directorul de Birouri folosind sistemul nostru de etichete conceput pentru acces rapid la locația potrivită. Iată cum vă poate ajuta fiecare etichetă să vă simplificați căutarea:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Toate:</Body1Strong> Afișează o listă completă a birourilor, oferind o perspectivă de ansamblu asupra
                      locațiilor organizației dumneavoastră.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favorit:</Body1Strong> Vă ajută să vă concentrați asupra locațiilor de birou pe care le frecventați sau pe care trebuie 
                      să le monitorizați atent, marcându-le ca 'Favorit'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Biroul Dumneavoastră:</Body1Strong> Această etichetă personală filtrează directorul pentru a vă arăta 
                      locația biroului unde sunteți staționat, menținându-vă baza de acasă la îndemână.
                    </li>
                  </ul>
                  Fie că rezervați o sală de întâlniri sau planificați o vizită, aceste etichete oferă un mod personalizat și simplificat de a interacționa cu peisajul geografic al organizației dumneavoastră.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Stăpânirea vizualizărilor Directorului de Birou în TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Explorează configurația organizațională a diferitelor locații ale companiei tale cu vizualizările Directorului de Birou din TeamConnect. Fiecare vizualizare este creată pentru a facilita 
                  un aspect unic al navigării și înțelegerii biroului:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vizualizare Listă:</Body1Strong> Configurația implicită care organizează toate locațiile birourilor în 
                      o listă ușor de citit.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vizualizare Card Mare:</Body1Strong> Vizualizarea Card Mare în Directorul de Birouri îmbogățește 
                      vizualizarea cu o prezentare detaliată a fiecărui birou. Când interacționezi cu un card de birou, vei găsi o multitudine de informații și opțiuni acționabile 
                      în meniul "Mai multe (...)":
                      <ol>
                        <li style={{ marginTop: '8px' }} className={styles.bulletBottomMargin}><Body1Strong>Vizualizare Departamente:</Body1Strong> Navighează direct la o listă
                          a tuturor departamentelor situate în cadrul biroului.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Vizualizare Angajați:</Body1Strong> Accesează lista completă a angajaților repartizați la 
                          birou, facilitând contactul direct sau explorarea personalului biroului.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Organigrama Entității de Afaceri:</Body1Strong> Vizualizează locul biroului în contextul organizațional mai larg pentru o înțelegere clară a structurilor ierarhice.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Contacte Esențiale:</Body1Strong> Identifică contactele cheie ale biroului pentru o 
                          comunicare eficientizată.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Sărbători Publice Ale Biroului:</Body1Strong> Informează-te despre sărbătorile publice specifice biroului pentru a-ți planifica programul corespunzător.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Orele de Lucru Ale Biroului:</Body1Strong> Vezi orele de funcționare ale biroului pentru a-ți alinia 
                          vizitele sau apelurile la timpurile lor active.
                        </li>
                      </ol>
                      Această vizualizare oferă nu doar o instantaneu vizual și informațional, dar și acces rapid la perspective mai profunde și acțiuni directe legate de birou, toate aranjate ordonat sub opțiunea "Mai multe (...)" pentru confortul tău.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vizualizare Organigramă:</Body1Strong> Vizualizează structura ierarhică a birourilor părinte și filiale 
                      pentru a înțelege interconectarea diferitelor locații ale birourilor din cadrul organizației tale.
                    </li>
                  </ul>
                  Comută între aceste vizualizări folosind icoanele de lângă caseta de căutare pentru a-ți personaliza experiența de navigare în funcție de nevoile tale imediate.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Găsirea rapidă a birourilor cu funcția de căutare TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Localizarea rapidă a locației biroului corect în TeamConnect este facilitată de funcția dedicată de căutare. Poziționată proeminent lângă icoanele de vizualizare, caseta de căutare este instrumentul tău pentru a identifica birourile după nume. Pe măsură ce tastezi, directorul rafinează afișarea pentru a arăta doar birourile care se potrivesc 
                  cu intrarea ta, simplificând procesul de căutare. Este important să folosești această casetă de căutare în TeamConnect pentru cele mai precise și relevante rezultate, 
                  spre deosebire de căutarea generală din Microsoft Teams. Cu această funcționalitate de căutare țintită, poți naviga rapid către orice birou din rețeaua organizației tale.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Navigarea alfabetică a directorului de birouri
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Dacă preferați să căutați alfabetic, filtrul nostru alfabetic este o modalitate rapidă de a găsi birouri după nume. În partea de sus a directorului de birouri, veți
                  vedea literele de la A la Z. Făcând clic pe o literă, vizualizarea este filtrată pentru a arăta doar acele birouri care încep cu acea literă, simplificând procesul de căutare.
                  Pentru a reseta și a vedea din nou toate birourile, deselectați pur și simplu litera activă. Această funcție este deosebit de utilă atunci când sunteți familiarizat cu numele
                  biroului, dar aveți nevoie de o modalitate rapidă de a-l localiza în TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Dezvăluirea profilurilor complexe ale birourilor în TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Porniți într-o călătorie exploratorie prin inima prezenței fizice a organizației dumneavoastră cu profilele de birou din TeamConnect.
                  Făcând clic pe numele unui birou se deschide un dialog de profil vibrant, plin de file cu informații detaliate despre birou:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Prezentare generală:</Body1Strong> Explorați nucleul identității biroului cu descrieri detaliate,
                      de la structura afacerii la orele de deschidere și ora locală, captând esența mediului de birou.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Contacte esențiale:</Body1Strong> Accesați contacte cruciale pentru servicii de urgență și
                      suport extern, fiecare la un clic distanță prin sistemul telefonic Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Contacte de birou:</Body1Strong> Contactați cu ușurință serviciile de asistență interne, cu
                      detaliile de contact și opțiuni de angajare directă la îndemână.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zile libere publice:</Body1Strong> Fiți la curent cu sărbătorile specifice biroului, asigurându-vă că
                      planurile dumneavoastră se aliniază cu observanțele locale și programele de lucru.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Orele de birou:</Body1Strong> Planificați interacțiunile dumneavoastră cu cunoștințe precise despre când
                      biroul este plin de activitate sau se închide pentru ziua respectivă.
                    </li>
                  </ul>
                  Fie că este vorba de energia plină de viață a Austinului sau de hub-ul strategic din Singapore, fiecare filă oferă un portal pentru a înțelege și a vă conecta cu locațiile diverse
                  care compun prezența globală a companiei dumneavoastră.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Analiza dinamicii departamentelor dintr-un birou
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigarea în rețeaua profesională a departamentelor unui birou se desfășoară cu ușurință în TeamConnect. Iată cum să te aprofundezi în detalii:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vizualizează departamentele din acest birou:</Body1Strong> Descoperă toate departamentele care 
                      formează structura biroului selectând această opțiune din meniul More (...).
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Detalii organizaționale:</Body1Strong> Clic pe orice departament pentru a dezvălui schema lui organizațională pentru
                      o reprezentare vizuală a structurii sale, cu traseul breadcrumb care te ghidează: Office {'>'} Department {'>'} Chart.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Analiză amănunțită a departamentului:</Body1Strong> Explorează mai mult un departament clicând pe 
                      meniul său More (...) lângă numele departamentului. Aici, poți accesa o listă cu toți angajații din acel departament, direct în contextul biroului.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Navigare cu breadcrumb:</Body1Strong> Traversează nivelurile în mod fluid. De la 
                      angajați înapoi la departamente sau de la organigrama, traseul breadcrumb (Office {'>'} Department {'>'} Employee {'>'} Chart) îți menține 
                      călătoria intuitivă și pe drumul cel bun.
                    </li>
                  </ul>
                  Cărările complexe ale TeamConnect te invită să explorezi, să înțelegi și să interacționezi cu fiecare strat al departamentelor biroului tău, împuternicindu-te cu 
                  o comandă navigațională comprehensivă.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Conectare cu personalul biroului în TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Curios despre fețele din spatele unui birou? Răspunsul este la doar un clic distanță în TeamConnect. Angajează-te cu elipsa (trei puncte) lângă numele biroului ales pentru a dezvălui o suită de opțiuni interactive:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vizualizează angajații din acest birou:</Body1Strong> Descoperă o listă cu toți profesioniștii 
                      care consideră acest birou casa lor de lucru. Numărul afișat oferă un calcul rapid al angajaților, invitându-te să te integrezi în comunitatea biroului.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Opțiuni de contact:</Body1Strong> Fiecare intrare a angajatului este echipată cu pictograme de acțiune rapidă, 
                      permițându-ți să inițiezi chaturi, apeluri sau chiar conferințe video direct din TeamConnect, favorizând comunicația internă fără probleme.
                    </li>
                    <li><Body1Strong>Navigare:</Body1Strong> Pentru a reveni la lista mai largă a birourilor dintr-o listă de angajați, clic simplu pe 'Office' în traseul de breadcrumb 
                      (Office {'>'} Employee), și vei fi adus înapoi la prezentarea generală a biroului.
                    </li>
                  </ul>
                  Cu aceste caracteristici, TeamConnect simplifică vizualizarea distribuției forței de muncă în birouri și permite să ajungi cu ușurință, 
                  favorizând un loc de muncă conectat, indiferent de distanță.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Lipsește sau este învechită informația despre birou?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Actualizările informațiilor despre birouri în TeamConnect pot fi gestionate de editorii de birou desemnați. Dacă nu ești sigur cine sunt editorii 
                  de birou sau ai nevoie de asistență pentru informațiile care țin de domeniul HR, echipa de suport HR este pregătită să ajute la <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Întrebări frecvente despre setări</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Importanța setărilor generale exacte în TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  În peisajul dinamic al mediului de lucru de astăzi, menținerea conexiunii și coordonării este mai crucială ca niciodată. Actualizarea Setărilor 
                  Generale în TeamConnect joacă un rol esențial:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Selectarea locului de muncă:</Body1Strong> Captează esența mediului tău de lucru actualizând Locul de Muncă pentru a reflecta situația 
                        actuală, fie că este o schimbare de la liniștea Biroului de Acasă la agitația Biroului principal, sau stabilirea într-un Spațiu Public. 
                        Această claritate în locul tău de muncă oferă colegilor noțiuni, nu doar despre cele mai bune metode de a te contacta, dar și despre 
                        natura conversațiilor în care pot să se angajeze cu tine, protejând confidențialitatea discuțiilor sensibile mai ales când te afli 
                        în mediul deschis al unui birou client sau într-un salon de aeroport.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Specificația locului de muncă:</Body1Strong> Partajarea orașului tău de lucru face mai mult decât să reducă distanțele într-o echipă 
                        la distanță—de asemenea deschide porțile către întâlniri profesionale spontane sau socializare casual. Fie că este vorba de o cafea rapidă, 
                        un prânz liniștit sau o întâlnire colaborativă, știind că te afli în același oraș poate transforma o zi obișnuită într-o oportunitate pentru 
                        conexiuni semnificative față în față. Acest lucru nu doar consolidează legăturile echipei, ci oferă și o înțelegere mai bogată a nuanțelor 
                        locale care ar putea influența munca ta.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Exactitatea fusului orar:</Body1Strong> Pe măsură ce echipele se extind pe continente, 
                      asigurarea că fusul tău orar reflectă localitatea actuală este esențială pentru armonizarea comunicărilor. Ora Locală din profilul tău 
                      TeamConnect este direct influențată de această setare, eliminând orice incertitudine pentru colegii care încearcă să te contacteze.
                    </li>
                  </ul>
                  Explorează 'Setări' {'>'} 'Setări Generale' în TeamConnect pentru a regla aceste detalii. Câteva momente petrecute actualizând setările tale pot 
                  îmbunătăți semnificativ coeziunea și eficiența eforturilor tale colaborative pe tot parcursul.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Descifrarea selecției locului de muncă în TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Alegerea setării potrivite în meniul derulant Workplace personalizează TeamConnect pentru a reflecta corect starea actuală a muncii tale. Treceți cu mouse-ul peste pictograma de informații pentru o explicație detaliată a fiecărei opțiuni:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Break:</Body1Strong> Indică timpul petrecut departe de muncă, fie că este vorba de o pauză sau de sfârșitul zilei de lucru.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office/Field-Office:</Body1Strong> Indică prezența într-un spațiu de lucru administrat de companie.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> Lucru dintr-un spațiu de acasă dedicat.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Public-Area:</Body1Strong> Operare dintr-un mediu neoficial, sugerând necesitatea unei comunicări flexibile.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Customer Workplace/Private Office:</Body1Strong> La fața locului cu un client, cu o notă privind nivelurile de confidențialitate pentru sensibilitatea conversației.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Commute/Business-Travel/Training:</Body1Strong> În deplasare sau angajat în activități specifice de lucru în afara biroului.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Public-Holiday/Vacation/Various Leaves:</Body1Strong> Oficial în afara serviciului pentru motive personale, de sănătate sau vacanță.</li>
                  </ul>
                  Selectarea unei setări adecvate ale locului de muncă asigură că echipa ta are contextul necesar pentru interacțiuni, respectând disponibilitatea și confidențialitatea.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Navigarea în starea 'Checked-In' în TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Eticheta 'Checked-In' din Directorul Angajaților este concepută pentru a afișa toți angajații care sunt în prezent la lucru. Această vizibilitate este determinată de selecția locului de muncă pe care o faceți:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Medii active de lucru:</Body1Strong> Alegerile Office, Field-Office, Home-Office, Remote-Workplace, Public-Area, Customer-Workplace, 
                    Customer Private-Office, Commute, Business-Travel și Training semnalizează că ești angajat în muncă, fiind 'Checked-In' și vizibil activ colegilor.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Setări non-lucrative:</Body1Strong> Optând pentru Checked-Out, Break, Public-Holiday, Vacation, On-Leave, Maternity-Leave sau Sick-Leave 
                      te scoate din lista 'Checked-In', stabilind așteptări clare pentru disponibilitate și timpurile de răspuns.
                  </li>
                  </ul>
                  Această funcționalitate ajută la delimitarea perioadelor de muncă de timpul personal, contribuind la o colaborare și comunicare eficientă în echipă.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Întrebări frecvente despre managementul datelor și confidențialitate</Subtitle2>
          <Body1>
            <p>
              În era digitală de astăzi, este crucial să înțelegi cum sunt gestionate datele tale. TeamConnect este dedicată transparenței și practicilor solide de protecție a datelor.
              Mai jos, vei găsi informații despre modul în care manipulăm, stocăm și protejăm datele tale în cadrul TeamConnect, asigurându-te că spațiul tău de lucru digital este sigur și conform.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Cum protejează TeamConnect datele mele personale?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect este dedicată protejării informațiilor tale personale printr-o abordare cuprinzătoare a securității datelor:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Single Sign-On (SSO):</Body1Strong> Pentru a consolida securitatea și pentru a simplifica experiența utilizatorului, TeamConnect integrează 
                      Single Sign-On (SSO) automatizat cu contul tău Microsoft 365, facilitând gestionarea accesului sigur și fără efort.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Criptarea:</Body1Strong> Ne asigurăm că toate datele din TeamConnect sunt criptate atât în timpul transmiterii, cât și atunci când sunt inerte,
                      făcându-le inaccesibile pentru părțile neautorizate.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Minimizarea datelor:</Body1Strong> Colectarea noastră de date este strict limitată la ce este esențial pentru funcționalitatea TeamConnect.
                      Singurele informații despre utilizator pe care le stocăm sunt Numele Principal al Utilizatorului (UPN) de la Azure Entra ID, care ar putea să semene cu adresa ta de email a companiei.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Acces restricționat:</Body1Strong> Accesul la UPN este limitat la Administratorul de Abonament al organizației tale în cadrul portalului nostru de abonamente,
                      asigurând că informațiile sensibile sunt vizibile doar pentru personalul autorizat.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Ștergerea rapidă a datelor:</Body1Strong> TeamConnect este structurată pentru a șterge instantaneu UPN-ul stocat sub trei condiții specifice:
                      dacă abonamentul este anulat de Administratorul de Abonament, dacă un utilizator este eliminat de HR în cadrul TeamConnect, sau dacă abonamentul TeamConnect expiră. Acest lucru asigură că datele tale personale nu sunt păstrate peste durata de viață utilă sau necesitatea lor.
                    </li>                      
                  </ul>
                  <p>Prin aceste măsuri, TeamConnect se angajează să mențină cele mai înalte standarde de protecție a datelor, asigurând că informațiile tale personale rămân securizate și confidențiale.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Vă întrebați unde sunt stocate datele dvs. cu TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect sparge tiparele ofertelor SaaS tradiționale prin asigurarea faptului că datele dvs. nu părăsesc niciodată organizația. 
                  Spre deosebire de practica obișnuită de a stoca datele pe servere cloud externe, TeamConnect folosește ingenios Microsoft 365 SharePoint Online al organizației dvs.
                  pentru a găzdui toate datele aplicației. Această abordare inovatoare nu numai că promovează suveranitatea datelor, dar și întărește securitatea,
                  aliniindu-se perfect cu devotamentul nostru ferm de a proteja confidențialitatea dvs. și de a respecta standardele stricte de conformitate.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Datele mele vor fi împărtășite cu terți?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nu, datele nu sunt împărtășite cu terții! Cubeet este ferm angajat în protejarea confidențialității și securității datelor dvs. Nu împărtășim nicio dată TeamConnect cu terții fără a obține consimțământul explicit al clienților noștri. 
                  În mediul nostru securizat, stocăm doar identificatori esențiali, cum ar fi Numele Principal al Utilizatorului (UPN), numele organizației, numele tenantului și ID-ul tenantului, în cadrul portalului nostru de abonamente. 
                  Aceste informații sunt cruciale pentru furnizarea serviciilor și sunt protejate sub politici riguroase de confidențialitate.
                  Fiți siguri, practicile noastre de gestionare a datelor sunt concepute pentru a asigura că datele dvs. rămân confidențiale, cu transparență și control ferm în mâinile dvs.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Cum se conformează TeamConnect cu reglementările de protecție a datelor precum GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect se aliniază la reglementările de protecție a datelor precum GDPR, HIPAA, CCPA și altele, folosind caracteristicile de conformitate inerente ale mediului Microsoft 365, 
                  unde toate datele clienților sunt stocate în siguranță. Spre deosebire de modelele tradiționale SaaS, TeamConnect nu stochează datele clienților pe propriile servere; în schimb, operează în cadrul 
                  mediului Microsoft 365 al organizației dvs., respectând standardele sale robuste de securitate și conformitate. Această abordare asigură că guvernanța datelor dvs. rămâne consecventă cu 
                  cadrul cuprinzător de conformitate oferit de Microsoft 365, inclusiv aderența la GDPR, HIPAA pentru informațiile de sănătate, CCPA pentru rezidenții Californiei, 
                  și alte reglementări globale de protecție a datelor. Prin integrarea fără probleme cu configurația dvs. existentă Microsoft 365, TeamConnect împuternicește organizația dvs. să mențină 
                  controlul complet asupra datelor, asigurând conformitatea cu cele mai stricte legi și reglementări de protecție a datelor.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Cum gestionează TeamConnect datele din chaturile Microsoft Teams, apelurile, apelurile video, emailurile din Outlook și apelurile telefonice?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect profită de Microsoft Graph API pentru a permite interacțiuni directe precum chatul, apelurile, apelurile video și emailurile din Outlook în cadrul Microsoft Teams, 
                  oferind o experiență de utilizator fără întreruperi. Esențial, deși TeamConnect inițiază aceste comunicații, o face fără a interveni în procesul de comunicare 
                  sau având capacitatea de a înregistra sau stoca conținutul interacțiunilor. Toate conversațiile, apelurile și emailurile au loc și sunt gestionate direct în 
                  cadrul securizat al Microsoft Teams și Outlook, respectând cu strictețe standardele robuste de securitate și confidențialitate ale Microsoft 365. Acest lucru asigură că TeamConnect îmbunătățește 
                  capacitățile dvs. de comunicare, păstrând în același timp confidențialitatea și integritatea datelor dvs.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Pentru detalii aprofundate despre politicile și practicile noastre de date, poate doriți să consultați {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>Ghidul Utilizatorului TeamConnect</Body1Strong></Link> sau departamentul IT al organizației dvs. la <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> pentru detalii specifice configurației dvs.
          </p>
        </Body1>        
        <p></p>  
      <Subtitle1 className={styles.fontColor}>Vă ascultăm: Partajați feedback-ul și ideile dvs.</Subtitle1>
        <Body1>
          <p>
            Perspectivele dvs. conduc evoluția TeamConnect. Fie că aveți o idee strălucitoare pentru o funcționalitate nouă sau aveți un feedback despre experiența curentă, suntem tot urechi. Contribuția dvs. este neprețuită în căutarea noastră continuă de a oferi cel 
            mai bun serviciu posibil.
          </p>
        </Body1>      
      <Subtitle2>Feedback și sugestii de funcționalități</Subtitle2>
        <Body1>
          <p>
            Gata să partajați? Accesați pagina noastră de {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Feedback
            </Link>
            . Fie că este o sugestie pentru o funcționalitate nouă sau feedback despre funcționalitățile existente, selectați doar opțiunea potrivită din meniul derulant al formularului. Contribuțiile dvs. ajută la modelarea viitorului TeamConnect, 
            asigurându-se că îndeplinește și depășește nevoile dvs.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
