import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Button } from '@fluentui/react-components';
import {
  bundleIcon,
  ChevronUpRegular as IconChevronUpRegular,
  ChevronUpFilled as IconChevronUpFilled, 
} from '@fluentui/react-icons';
const IconChevronUp = bundleIcon(IconChevronUpFilled, IconChevronUpRegular);

const useStyles = makeStyles({
  scrollToTopButton: {
    position: 'fixed',
    right: '20px',
    bottom: '50px',
  },
});

const ScrollToTopButton = ({ closeAllAccordions }) => {
  const [isVisible, setIsVisible] = useState(false);
  const styles = useStyles();
  
  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    };
  };
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    if (closeAllAccordions) {
      closeAllAccordions();
    };
  };
  
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);
  
  return (
    isVisible && 
    <Button
      className={styles.scrollToTopButton}
      size='medium'
      appearance='primary'
      onClick={scrollToTop}
      icon={<IconChevronUp />}>
    </Button>
  );
};

ScrollToTopButton.propTypes = {
  closeAllAccordions: PropTypes.func,
};

export default ScrollToTopButton;