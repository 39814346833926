import PropTypes from "prop-types";
import {
  makeStyles,
  tokens,
  Link,
  Body1,
  Body1Strong,
  Subtitle2,
  Subtitle1,
  Title3,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,
  },
  bulletBottomMargin: {
    marginBottom: "8px",
  },
  scrollToTopButton: {
    position: "fixed",
    right: "5%",
    transform: "translateX(95%)",
    bottom: "8px",
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>
        TeamConnect pagalbos centras ir DUK
      </Title3>
      <p>
        <Body1>
          Sveiki atvykę į TeamConnect pagalbos centrą, kuris sukurtas jūsų
          patirčiai su mūsų išsamiu įmonių direktoriumi remti. Čia rasite
          išteklius, kaip optimizuoti TeamConnect naudojimą Microsoft Teams,
          gerinant bendravimą jūsų tinkle.
        </Body1>
      </p>
      <Subtitle2>Greitoji nuorodų vadovėlis</Subtitle2>
      <ul>
        <Body1>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Išplėstos paieškos galimybės: </Body1Strong>
            Greitai suraskite reikiamus kolegas, departamentus ir būtinus
            kontaktus naudodamiesi mūsų galingais paieškos įrankiais.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Vaizdiniai organizacijos diagramos: </Body1Strong>
            Lengvai orientuokitės įmonės hierarchijoje ir supraskite
            atskaitomybės linijas naudodami intuityvias organizacijos diagramas.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>
              Pritaikomos šiandienos poreikiams priemonės:{" "}
            </Body1Strong>
            Naudokite šiuolaikines funkcijas, pritaikytas šiuolaikinės darbo
            vietos reikalavimams.
          </li>
        </Body1>
      </ul>
      <p></p>
      <Subtitle2>Optimizuokite savo patirtį</Subtitle2>
      <Body1>
        <p>
          TeamConnect revoliucionizuoja bendravimą jūsų organizacinėje
          struktūroje, paverčiant kasdienius bendravimus strateginiais
          įsitraukimais.
        </p>
      </Body1>
      <p></p>
      <Subtitle1 className={styles.fontColor}>Gaukite pagalbą ir DUK</Subtitle1>
      <Body1>
        <p>
          Šiame skyriuje rasite dažniausiai užduodamus klausimus apie
          TeamConnect funkcijas ir nustatymus. Naudodamiesi programa,
          pritaikydami nustatymus ar ieškodami sprendimų problemoms, mūsų
          pagalbos centras čia, kad jums padėtų efektyviai.
        </p>
      </Body1>
      <Body1>
        <p>
          Norėdami gauti išsamesnę pagalbą ar konkrečias rekomendacijas, prašome
          kreiptis į mūsų{" "}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>
              TeamConnect naudotojo vadovą
            </Body1Strong>
          </Link>
          .
        </p>
      </Body1>
      <p></p>
      <Subtitle2>Naudotis „TeamConnect“ DUK</Subtitle2>
      <p></p>
      <div>
        <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
          <AccordionItem value="1">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                1. Nerandate „TeamConnect“ „Microsoft Teams“ šoninėje juostoje?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Jei „TeamConnect“ nėra akivaizdžiai matomas arba dingo iš jūsų „Microsoft Teams“ šoninės juostos, galite jį lengvai prisegti greitam pasiekimui. Tereikia sekti šiais paprastais žingsniais:
                <ol>
                  <li className={styles.bulletBottomMargin}>Atidarykite „Microsoft Teams“ ir spustelėkite '...' (+ Apps) piktogramą apačioje, šoninėje juostoje.</li>
                  <li className={styles.bulletBottomMargin}>Paieškokite „TeamConnect“ paieškos lauke ant pop-up lango viršaus.</li>
                  <li className={styles.bulletBottomMargin}>Dešiniuoju pelės klavišu spustelėkite ant „TeamConnect“ programos paieškos rezultatuose ir pasirinkite 'Pin'.</li>
                </ol>
                Dabar „TeamConnect“ bus prisegtas prie jūsų šoninės juostos, jo pasiekimas bus vos vieno spustelėjimo atstumu!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="2">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                2. Susiduriate su techninėmis problemomis naudodamiesi „TeamConnect“? Štai kaip gauti pagalbą.
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Jei susiduriate su bet kokiais techniniais nesklandumais naudodamiesi „TeamConnect“ „Microsoft Teams“, jūsų organizacijos IT palaikymo komanda yra čia, kad padėtų. 
                Kreipkitės į juos el. paštu <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, ir jie jums nedelsiant padės.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="3">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                3. Nerandate savo darbuotojo duomenų?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Jei jūsų profilis atrodo šiek tiek tuščias, matydamas tik jūsų vardą ir el. paštą, greičiausiai jūsų žmogiškųjų išteklių komanda dar neįtraukė likusių jūsų darbuotojo duomenų į „TeamConnect“. 
                Nereikia jaudintis! Greita žinutė jūsų žmogiškųjų išteklių palaikymo komandai pajudins reikalus. Jie pasiruošę pildyti jūsų profilį visomis svarbiomis detalėmis. 
                Kreipkitės el. paštu <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> ir stebėkite, kaip jūsų profilis tampa gyvas!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="4">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                4. Kokios kalbų parinktys yra prieinamos TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect palaiko visą Microsoft Teams pasaulinę įvairovę, palaikydama
                visas jo kalbas. Ar pageidaujate arabų ar graikų, japonų ar švedų kalbos,
                turite pasaulio galimybių jūsų pirštų galiukuose, įskaitant:
              </Body1>
              <ul>
                <li>
                  <Body1>
                    Arabų, bulgarų, kinų (supaprastinta ir tradicinė), kroatų, čekų, danų,
                    olandų, anglų, estų, suomių, prancūzų, vokiečių, graikų, hebrajų,
                    vengrų, indoneziečių, italų, japonų, korėjiečių, latvių, lietuvių,
                    norvegų (Bokmal), lenkų, portugalų (Brazilijos ir Portugalijos),
                    rumunų, rusų, serbų (lotyniška), slovakų, slovėnų, ispanų, švedų,
                    tajų, turkų, ukrainiečių, vietnamiečių.
                  </Body1>
                </li>
              </ul>
              <Body1>
                Norėdami pritaikyti savo TeamConnect patirtį prie norimos kalbos, prašome
                tiesiogiai Microsoft Teams nustatymuose pakeisti kalbos nustatymus,
                kadangi TeamConnect pilnai integruojasi su šiais nustatymais:
              </Body1>
              <ol>
                <Body1>
                  <li className={styles.bulletBottomMargin}>
                    Eikite į savo Microsoft Teams nustatymus spustelėję tris taškus šalia
                    savo profilio nuotraukos viršuje ir pasirinkite 'Išvaizda ir
                    prieinamumas'.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Slinkite iki 'Kalbos' skilčio ir iš išskleidžiamojo meniu pasirinkite
                    pageidaujamą kalbą.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Patvirtinkite savo pasirinkimus spustelėdami mygtuką 'Išsaugoti ir iš
                    naujo paleisti', kad taikytumėte naujus kalbos nustatymus.
                  </li>
                </Body1>
              </ol>
              <Body1Strong>Pastaba: </Body1Strong>
              <Body1>
                Pakeitimai, atlikti jūsų kalbos nustatymuose Microsoft Teams, taip pat
                sklandžiai atnaujins jūsų TeamConnect patirtį. Turėkite omenyje, kad
                keičiant kalbą Teams gali taip pat keistis laiko ir datos formatas. Šiuo
                metu, TeamConnect tobulėja dešinės į kairę rašymo link. Mes esame sužavėti
                šiais patobulinimais ir informuosime jus, kai jie taps prieinami.
              </Body1>
            </AccordionPanel>
          </AccordionItem>;
          <AccordionItem value="5">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                5. Kaip pakeisti temą „TeamConnect“?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                „TeamConnect“ sinchronizuojasi su jūsų „Microsoft Teams“ temos nustatymais, įskaitant šviesią, tamsią ir didelio kontrasto parinktis. Norėdami pritaikyti savo temą:
                <ol>
                  <li className={styles.bulletBottomMargin}>Prieigą prie savo „Microsoft Teams“ nustatymų gaukite spustelėdami tris taškus šalia savo profilio nuotraukos viršuje.</li>
                  <li className={styles.bulletBottomMargin}>Pasirinkite „Appearance and accessibility“ norėdami naršyti temų parinktyse.</li>
                  <li className={styles.bulletBottomMargin}>Pasirinkite pageidaujamą temą iš galimų parinkčių, kad iš karto atnaujintumėte savo „TeamConnect“ sąsają.</li>
                </ol>
                Jūsų temos pasirinkimas „Microsoft Teams“ bus automatiškai pritaikytas „TeamConnect“, užtikrinant nuoseklią vizualinę patirtį visoje jūsų darbo erdvėje.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="6">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                6. Susipažinimas su „TeamConnect“ kontaktų veiksmų mygtukais
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Visame „TeamConnect“, kur tik rasite darbuotojo kontaktinę informaciją, taip pat atrasi mūsų patogius greitai pasiekiamus mygtukus. Šie įrankiai 
                yra sukurti palengvinti jūsų bendravimą „Microsoft“ ekosistemoje, užtikrinant, kad visada būsite tik vieno paspaudimo atstumu nuo ryšio. 
                Štai jūsų išsamus vadovas šių mygtukų naudojimui:
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Chat Icon:</Body1Strong> Spustelėkite, kad pradėtumėte aktyvų „Microsoft Teams“ pokalbį. Jei reikia grįžti į „TeamConnect“ po pokalbio pradžios, paprasčiausiai naudokite atgal rodyklę darbalaukio programoje, atgal mygtuką mobiliųjų įrenginių programoje arba jūsų naršyklės atgal mygtuką.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Email Icon:</Body1Strong> Atidaro naują „Outlook“ el. laiško juodraštį su gavėjo adresu paruoštu "To:" lauke. Jei „Outlook“ nepradeda veikti, verta pasitikrinti su IT skyriumi dėl galimų apribojimų.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Video Call Icon:</Body1Strong> Pradeda akis į akį vaizdo skambutį naudojant „Microsoft Teams“.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Call Icon:</Body1Strong> Pradeda „Microsoft Teams“ balso skambutį, palengvinantį greitus pokalbius.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Org Chart Icon:</Body1Strong> Atskleidžia darbuotojo vietą organizacinėje hierarchijoje, įskaitant jų vadovą ir tiesioginius pavaldinius.</li>
                  <li><Body1Strong>More (...) Icon:</Body1Strong> Atskleidžia papildomas parinktis:
                    <ul>
                      <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Meet Now:</Body1Strong> Atidaro „Microsoft Teams“ nedelsiant pradėti susitikimą su darbuotoju.</li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>New Meeting:</Body1Strong> Nukreipia jus į „Microsoft Teams“ suplanuoti naują susitikimą.</li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>Add Contact:</Body1Strong> Prideda darbuotojo kontaktinę informaciją iš „TeamConnect“ į jūsų naująjį „Microsoft Teams People“ programėlę, praturtinantį jūsų skambučių funkciją.</li>
                    </ul>
                  </li>
                </ul>
                Šie intuityvūs mygtukai yra sukurti užtikrinti, kad galėtumėte efektyviai bendrauti su kolegomis, tiek greitam pokalbiui, el. laiškui, tiek suplanuoti būsimus susitikimus.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="7">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                7. Skambučiai per „TeamConnect“
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Norite paskambinti? „TeamConnect“ racionalizuoja jūsų bendravimą integruodamas su „Microsoft Teams“. 
                Tiesiog spustelėkite norimą telefono numerį „TeamConnect“, ir štai, „Microsoft Teams“ skambučio sąsaja pasirodo,
                paruošta jums sklandžiai skambinti.
                <p>
                <Body1Strong>Pastaba: </Body1Strong> 
                Jei susiduriate su problemomis skambinant? Tai gali būti dėl jūsų organizacijos IT politikos ar naudojamo telefono sistemos, kita nei „Microsoft Teams Phone System“. Pasitikrinimas su jūsų IT skyriumi <Link href={`mailto:${itSupport}`}>
                <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, gali paaiškinti šią situaciją.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="8">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                8. Ar susiję kaštai su skambučiais per TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect pagerina jūsų skambučių patirtį, naudodamas Microsoft Teams. Štai ką turite žinoti apie skambučių susijusius kaštus:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    Skambinant į darbuotojų darbo telefonus, skyriaus telefonus ar pagalbos linijas jūsų organizacijoje (vidiniai numeriai), 
                    naudojant Microsoft Teams Phone System, <Body1Strong>paprastai kaštų nėra</Body1Strong>.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Skambinant į išorinius numerius, pavyzdžiui, į mobiliojo ryšio telefonus ar išorines linijas, <Body1Strong>taikomos standartinės tarifų</Body1Strong> jūsų organizacijos telefono sistemos. 
                    Šie tarifai skiriasi priklausomai nuo jūsų konkretaus paslaugos plano su Microsoft Teams.
                  </li>
                </ul>
                <p><Body1Strong>Pastaba:</Body1Strong> Jei susiduriate su problemomis ar turite klausimų dėl skambučių apmokestinimo, geriausia pasitarti su 
                  IT skyriumi <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, kur gausite išsamią informaciją, 
                  pritaikytą jūsų organizacijos sąrankai ir politikai.</p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="9">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                9. Pastebėjote nesutapimą su jūsų „Nebuvimo biure“ būsenos rodikliu TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Pastebėjote, kad jūsų „Nebuvimo biure“ būsenos rodiklis iš Microsoft 365 neteisingai atsispindi TeamConnect? 
                Mes jau įdiegėme funkciją „Nebuvimo biure“ būsenos sinchronizavimui TeamConnect.
                Laukiame, kol Microsoft išleis šią funkciją. Kai tai įvyks, jūsų „Nebuvimo biure“ būsenos rodiklis 
                bus tiksliai atspindėtas TeamConnect, sutapdamas su Microsoft Teams būsenos indikatoriais, užtikrinant nepriekaištingą patirtį.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>        
      <Subtitle2>Darbuotojų katalogo DUK</Subtitle2>
        <Body1>
          <p>
            <Body1Strong >Darbuotojų katalogo pristatymas:</Body1Strong> Naršykite profesinį jūsų organizacijos peizažą naudodamiesi Darbuotojų katalogu, 
              jūsų centrine vieta, kurioje gausite išsamią informaciją apie darbuotojus, darbo aplinką ir tiesioginį ryšį su jūsų kolegų profiliais bei valdymo ryšiais.
          </p>
        </Body1>
      <div>
        <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
          <AccordionItem value="10">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                10. Reikia pridėti, atnaujinti ar ištaisyti jūsų darbuotojo duomenis?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Pridėdami, atnaujindami ar ištaisydami savo darbuotojo duomenis TeamConnect, kreipkitės į jūsų organizacijos personalo palaikymo komandą. 
                Jie pasiruošę padėti <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
              </Body1>
            </AccordionPanel>
          </AccordionItem>           
          <AccordionItem value="11">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                11. Kas atsitinka, paspaudus ant darbuotojo vardo kataloge?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Paspaudus ant darbuotojo vardo „TeamConnect“ darbuotojų kataloge atidaromas išsamus to asmens profilio puslapis. Šis profilis pateikia išsamias 
                įžvalgas apie darbuotoją, įskaitant, bet neapsiribojant, jo pareigybėmis, skyriumi, biuro vieta, kontaktine informacija ir daugiau. Jis yra sukurtas suteikti jums 
                visapusišką darbuotojo profesinio vaidmens apžvalgą ir kaip jis įsilieja į organizaciją, padedant jums suprasti jo atsakomybes ir 
                kaip galėtumėte su juo bendradarbiauti.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="12">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                12. Kokia informacija įtraukiama į darbuotojo profilį?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Kiekvienas darbuotojo profilis „TeamConnect“ yra išsamus momentinės būklės vaizdas, siūlantis gausybę įžvalgų apie asmenį profesionaliame gyvenime. 
                Štai ką rasite:
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Pagrindinės detalės:</Body1Strong> Vardas, pareigybes, skyrius ir filialo vieta.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Ataskaitų struktūra:</Body1Strong> Informacija apie tiesioginius ir funkcinės pranešimus, 
                    įskaitant linijos ir funkciniai vadovai.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Darbo būklė:</Body1Strong> Esamas darbo statusas, tokius kaip Atostogauja, Kelionė į darbą, 
                    Verslo-kelionė ir t.t.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Darbo vieta ir vietinis laikas:</Body1Strong> Darbuotojo dabartinė darbo vieta ir 
                    vietos laiko juosta.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Kontaktinė informacija:</Body1Strong> Darbo telefonas, mobilusis numeris ir elektroninio pašto adresas.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Biuro adresas:</Body1Strong> Visas biuro vieta įskaitant pastato pavadinimą,
                    adresą ir „Google“ žemėlapį.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Apie:</Body1Strong> Trumpas asmeninis pristatymas arba profesinis biografija.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Darbuotojų santykiai:</Body1Strong> Informacija apie darbuotojo ataskaitų 
                    struktūrą ir komandos santykius.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Socialiniai ryšiai & Kalbos:</Body1Strong> Nuorodos į profesionalius socialinius profilius 
                    ir kalbų mokėjimo lygius.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Įgūdžiai:</Body1Strong> Sąrašas profesinių įgūdžių ir ekspertizės sričių.</li>
                </ul>
                Be to, profilis siūlo praktiškas galimybes susisiekti per pokalbį, elektroninį laišką, vaizdo skambutį arba suplanuoti susitikimus, taip pat 
                greitą prieigą prie organizacijos diagramos platesniam komandos kontekstui.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="13">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                13. Kaip atnaujinti savo profilio informaciją?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Asmeninti savo profilį „TeamConnect“ yra lengva. Štai kaip tai galite padaryti:
                  <ol>
                    <li className={styles.bulletBottomMargin}>
                      Eikite į skirtuką <Body1Strong>Darbuotojas</Body1Strong> ir spustelėkite savo <Body1Strong>Avatarą</Body1Strong>, kad atidarytumėte savo profilį.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Viršutiniame kairiajame kampe rasite nuorodą <Body1Strong>"Redaguoti savo profilį"</Body1Strong>. 
                      Spustelėję šią nuorodą, atsidarys profilio redagavimo dialogas.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Čia galite pagerinti savo profilį pridėdami ar atnaujindami jūsų <Body1Strong>Apie jus</Body1Strong> skyrių, socialinių tinklų nuorodas ir telefono numerį. 
                      Tai palengvina jūsų kolegoms ryšį su jumis arba sekimą.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Nepamirškite paspausti mygtuką <Body1Strong>Išsaugoti</Body1Strong>, kad pritaikytumėte pakeitimus.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Slinkite žemyn dialogo lange, kad pridėtumėte savo <Body1Strong>Kalbas</Body1Strong> ir <Body1Strong>Gebėjimų lygį</Body1Strong>, 
                      taip pat savo <Body1Strong>Įgūdžius</Body1Strong>. Šios informacijos dalinimasis padeda jūsų kolegoms suprasti 
                      jūsų kompetencijos sritis ir kalbas, kuriose galite bendrauti.
                    </li>
                  </ol>
                Atnaujindami savo profilį ne tik išlaikote savo informaciją naujausią, bet ir skatiname geresnius ryšius su jūsų organizacijoje.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="14">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                14. Darbo vietos, darbo vietos ir laiko zonos atnaujinimas „TeamConnect“
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Darbo vietos, darbo vietos ir laiko zonos informacijos laikymas naujausiomis yra būtinas sklandžiam bendradarbiavimui „Microsoft Teams“. 
                Šie atnaujinimai tuoj pat atsispindi jūsų „TeamConnect“ profilyje ir sąrašuose, leidžiant kolegoms greitai suprasti jūsų darbo vietą 
                ir geriausius laikus su jumis susisiekti, skatinant komandinį darbą įvairiuose regionuose ir laiko juostose.
                <p></p>
                Pasiruošę atnaujinti savo duomenis? Tai lengva „TeamConnect“! Eikite į „Nustatymai“ viršutiniame meniu ir naviguokite į „Bendrieji nustatymai“. 
                Čia galite sklandžiai atnaujinti savo Darbo vietą ir Laiko zoną, ir nurodyti savo Darbo vietą dienai. Nepamirškite išsaugoti savo pakeitimus 
                norint informuoti visus ir užtikrinti veiksmingą bendradarbiavimą nepriklausomai nuo jūsų buvimo vietos!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="15">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                15. Pastebėjote kažką netinkamo kolegos profilyje?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Jei pastebėsite bet kokią informaciją kolegos profilyje, kuri atrodo neteisinga, geriausias pirmas žingsnis yra draugiškas perspėjimas. 
                Trumpas pokalbis ar el. laiškas turėtų padėti! Jei dėl tam tikrų priežasčių informacija nebus atnaujinta, nesijaudinkite—tiesiog 
                kreipkitės į savo personalo palaikymo komandą adresu <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, ir jie mielai padės sutvarkyti viską.
              </Body1>
            </AccordionPanel>
          </AccordionItem>            
          <AccordionItem value="16">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                16. Supratimas apie žymių grupes: Visi, Mėgstamiausi, Prisijungę ir Nauji darbuotojai
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Visi:</Body1Strong> Ši numatytoji žymė rodo visus jūsų organizacijos darbuotojus,
                    suteikdama išsamų komandos vaizdą.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Mėgstamiausi:</Body1Strong> Spustelėkite čia, kad filtruotumėte savo vaizdą tiems kolegoms, kuriuos pažymėjote
                    kaip „Mėgstamiausi“, palengvinant greitą ryšį su pagrindiniais kontaktais.
                  </li>                  
                  <li className={styles.bulletBottomMargin}><Body1Strong>Prisijungę:</Body1Strong> Ši žymė nurodo darbe esančius darbuotojus, išskiriant
                    nuo paprasto buvimo prisijungus Microsoft Teams. Kai prisijungę, darbuotojai signalizuoja, kad jie yra darbe ir dalijasi savo dabartine darbo vieta
                    per dieną. Tai gali apimti įvairias darbo vietas, tokią kaip Biuras, Šakos biuras, Namų biuras, Nuotolinė darbo vieta, Kliento darbo vieta,
                    Kliento privatus biuras, Vieša erdvė, Keliavimas, Verslo kelionė ar net Mokymai. Todėl „Prisijungę“ būsenos suteikia aiškų signalą
                    apie aktyvų darbą, suteikiant kolegoms tikslią supratimą apie kiekvieno dabartinį darbo kontekstą ir prieinamumą.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Nauji darbuotojai:</Body1Strong> Atraskite naujus veidus TeamConnect su šia žyma, išryškinant
                    darbuotojus, kurie pridėti prie programos per paskutines 30 dienų. Paprastai tai sutampa su neseniai įvykusiais įsidarbinimais jūsų organizacijoje, padedant
                    pasveikinti naujus komandos narius.
                  </li>
                </ul>
                Šios žymių grupės sukurtos siekiant supaprastinti naršymą ir pagerinti jūsų TeamConnect patirtį, užtikrinančią, kad visada galėtumėte
                rasti ir susisiekti su savo kolegomis veiksmingai.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="17">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                17. Ikonų šalia paieškos lauko iššifravimas
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                „TeamConnect“ darbuotojų katalogas sukurtas naudoti lanksčiai ir paprastai, kuriame yra keturios ikonos, siūlančios skirtingus vaizdus
                ir greitą prieigą prie pagrindinių duomenų. Štai ką kiekviena ikona atskleidžia:
                <ol>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Sąrašo vaizdas:</Body1Strong> Jūsų pagrindinis vaizdas, pateikiantis visus organizacijos narius
                    aiškiame sąraše.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Mažų kortelių vaizdas:</Body1Strong> Kompaktiškas alternatyva, šis vaizdas paverčia sąrašą
                    į mažus darbuotojų kortelius.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Didelių kortelių vaizdas:</Body1Strong> Pasirinkite detalesnį su didesnėmis kortelėmis, kuriose yra
                    papildomi kontaktų variantai, pavyzdžiui, telefono ir mobiliojo telefono numeriai, lengvai skambinant.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Organizacijos schema:</Body1Strong> Vizualizuokite įmonės struktūrą su organizacijos schemos vaizdu,
                    puikiai tinka suprasti ataskaitų santykius ir naršyti organizaciniame kraštovaizdyje.</li>
                </ol>
                Perjunkite šias ikonas, kad rastumėte vaizdą, kuris geriausiai atitinka jūsų poreikius tuo metu, ar ieškote greitos informacijos ar gilesnių įžvalgų.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="18">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                18. Greitai ieškant kolegos, kur turėčiau žiūrėti?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ieškote kolegos? Tiesiog eikite į „TeamConnect“ paieškos langelį, esantį viršutiniame dešiniajame kampe šalia organizacinės schemos piktogramos.
                Pradėkite rašyti jų vardą ir stebėkite, kaip rezultatai tampa tikslesni su kiekviena raide. Atminkite, „TeamConnect“ paieškose nepasitelkite „Microsoft Teams“
                paieškos juostos viršuje – mūsų specialus paieškos langelis yra geriausias pasirinkimas!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="19">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                19. Kaip veikia abėcėlinė paieška darbuotojų radimui?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                „TeamConnect“ darbuotojų katalogas apima patogią abėcėlinės paieškos funkciją, kuri padeda greitai rasti darbuotojus pagal vardą. 
                Tiesiog spustelėkite ant raidės, rodomos katalogo viršuje, ir „TeamConnect“ tuoj pat parodys visus darbuotojus, 
                kurių vardai prasideda šia raide. Norėdami grįžti prie visų darbuotojų sąrašo, tiesiog atžymėkite paryškintą raidę. Ši intuityvi 
                funkcija palengvina didelių darbuotojų sąrašų naršymą ir užtikrina, kad kolegas galėsite rasti greitai ir veiksmingai.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="20">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                20. Kaip galėčiau reguliuoti rodžioma darbuotojų skaičių puslapyje?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                „TeamConnect“ leidžia jums pasirinkti, kiek darbuotojų profilių norite matyti puslapyje, tai palengvina naršymą darbuotojų kataloge. 
                Kiekvieno puslapio apačioje rasite puslapiavimo parinktis: 10, 50, 100, 500, 1000 ir 5000. Numatytasis nustatymas rodo 10 profilių puslapyje. 
                Norėdami peržiūrėti daugiau profilių viename puslapyje, tiesiog pasirinkite pageidaujamą skaičių iš šių parinkčių. Ši funkcija suteikia lankstumo 
                naršant katalogą, leidžiant jums pritaikyti rodymą pagal savo poreikius ar pageidavimus.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="21">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                21. Kaip rūšiuoti darbuotojų informaciją sąrašo vaizde?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                „TeamConnect“ darbuotojų katalogo sąrašo vaizde jūs turite lankstumą rūšiuoti darbuotojų informaciją pagal savo poreikius. Stulpeliai—Darbuotojo vardas, 
                Pareigos, Departamentas, Biuro vieta, ir Darbovietė—visi yra rūšiuojami. Tiesiog spustelėkite ant stulpelio antraštės, pagal kurią norite rūšiuoti. 
                Spustelėjus kartą, stulpelis bus rūšiuojamas didėjančia tvarka, o spustelėjus dar kartą—mažėjančia tvarka. Pagal nutylėjimą, katalogas rūšiuojamas pagal 
                Darbuotojo vardą didėjančia tvarka, remiantis pirmuoju vardu. Ši rūšiavimo funkcija padeda greitai organizuoti ir peržiūrėti darbuotojų duomenis būdu, kuris 
                geriausiai atitinka jūsų tikslus, ar ieškote konkrečio asmens, ar reikia grupuoti darbuotojus pagal tam tikrą kriterijų, pavyzdžiui, departamentą ar vietą.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        </div>
        <p></p>
        <Subtitle2>Skyrių direktorijos DUK</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Skyrių direktorijos įvadas:</Body1Strong> Skyrių direktorija yra jūsų vartai suprasti sudėtingą jūsų organizacijos struktūrą, suteikiant aiškų vaizdą apie skyrių hierarchijas, vadovų vadovavimą ir galimybes užtikrinti sklandų skyrių bendravimą.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Kaip veikia žymės 'Visi', 'Mėgstamiausi' ir 'Jūsų skyrius' skyrių direktorijoje?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect skyrių direktorijoje žymės padeda greitai naršyti ir filtruoti skyrių informaciją. Štai ką kiekviena žymė reiškia:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Visi:</Body1Strong> Ši žymė rodo visus jūsų organizacijos skyrius, suteikdama 
                      išsamų apžvalgą.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Mėgstamiausi:</Body1Strong> Naudokite šią žymę, kad matytumėte iš anksto atrinktą skyrių sąrašą, kuriuos pažymėjote kaip „Mėgstamiausi“, palengvindami dažniau bendraujamų skyrių pasiekimą.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Jūsų skyrius:</Body1Strong> Ši žymė filtruoja rodinį, kad rodytų tik jūsų skyrių, 
                      leidžiant greitai pasiekti jūsų tiesioginę skyriaus informaciją ir naujienas.</li>
                  </ul>
                  Šios žymės sukurtos tam, kad supaprastintų jūsų naršymą skyrių direktorijoje, padidindamos jūsų galimybę efektyviai rasti ir bendrauti su skyrių informacija.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Suprantant skyrių direktorijos rodinius TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Naršymas per skyrių direktoriją TeamConnect yra supaprastintas su keliais rodymo būdais pagal jūsų pageidavimus. Kiekvienas vaizdas siūlo unikalų būdą tyrinėti išsamius skyrių duomenis, įskaitant skyriaus pavadinimą, skyriaus vadovą, vyresnįjį skyrių, skyriaus telefoną, pagalbos telefono numerį ir skyriaus el. paštą. Štai vaizdai, kurie yra prieinami:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sąrašo rodinys:</Body1Strong> Numatytasis išdėstymas, kuris tvarkingai išdėsto visus skyrius, suteikdamas aiškų, glaustą apžvalgą.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Stambiųjų kortelių rodinys:</Body1Strong> Perjunkite į šį rodinį, kad matytumėte detalias skyrių korteles, siūlančias išplėstą kiekvieno skyriaus pagrindinės informacijos suvestinę.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organizacijos diagramos rodinys:</Body1Strong> Norėdami vizualiai pavaizduoti skyrių struktūrą ir ryšius, organizacijos diagramos rodinys iliustruoja, kaip kiekvienas skyrius įsikuria didesnėje organizacinėje hierarchijoje.
                    </li>
                  </ul>
                  Perjungiant tarp šių rodymų, tiesiog naudokite piktogramas šalia paieškos lauko viršuje skyrių direktorijoje. Kiekvienas vaizdas sukurtas padėti jums lengvai pasiekti ir suprasti skyriaus detales, užtikrinant, kad rasite reikiamą informaciją pageidaujamu formatu.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Kaip naudotis paieškos laukeliu skyrių kataloge
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Naudodamiesi TeamConnect skyrių katalogo viršuje šalia vaizdo piktogramų esančiu skirtu paieškos laukeliu, lengvai raskite bet kurį skyrių. Tiesiog pradėkite
                  rašyti ieškomo skyriaus pavadinimą ir stebėkite, kaip katalogas pats atsirinkinėja ir rodo tik atitinkamus atitikmenis. Ši funkcija yra specialiai suprojektuota
                  tam, kad jums nereikėtų rankiniu būdu naršyti visame kataloge, o ieškomą skyrių atvestų tiesiai prie jūsų pirštų galiukų. Nepamirškite, kad ši paieškos 
                  funkcionalumas yra unikalus TeamConnect skyrių katalogui, todėl naudokite šį paieškos laukelį vietoj pagrindinio „Microsoft Teams“ paieškos viršuje 
                  „Teams“ sąsajoje skyriams ieškoti.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Kaip veikia abėcėlinis filtras skyrių kataloge?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Skyrių kataloge greitai ieškokite naudodamiesi abėcėliniu filtru, esančiu viršuje. Ši funkcija leidžia jums greitai sumažinti skyrių, pasirinkus raidę. 
                  Pasirinkus raidę, „TeamConnect“ filtruoja ir rodo tik tuos skyrius, kurių pavadinimai prasideda jūsų pasirinkta raide, taip palengvindamas tai, ko 
                  ieškote, suradimą neslidinėjant per visą sąrašą. Norėdami grįžti prie viso skyrių vaizdo, tiesiog atžymėkite pasirinktą raidę. Ši intuityvi abėcėlinė
                  navigacija užtikrina, kad galėsite veiksmingai rasti bet kurį skyrių pagal pradinę raidę.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Kaip galiu susisiekti su skyriaus vadovu?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Susisiekti su skyriaus vadovu „TeamConnect“ yra paprasta. Tiesiog raskite norimą skyrių ir spustelėkite ant vadovo vardo. Šis veiksmas sukels iškylančią 
                  juostą, kurioje bus rodomos vadovo kontaktinės parinktys su pažįstamais greitojo prieigos piktogramomis:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pokalbio piktograma:</Body1Strong> Pradėti akimirksniu žinutę per „Microsoft Teams“ pokalbį.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>El. pašto piktograma:</Body1Strong> Atidaryti naują laišką, adresuotą jiems „Microsoft Outlook“.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vaizdo skambučio piktograma:</Body1Strong> Pradėti vaizdo skambutį per „Microsoft Teams“.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Skambučio piktograma:</Body1Strong> Pradėti balso skambutį naudojant „Microsoft Teams“.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Daugiau (...) piktograma:</Body1Strong> Papildomoms parinktims, pvz., susitikimo suplanavimui ar 
                      jų kontaktinės informacijos įtraukimui į jūsų „Teams“ sąrašą.
                    </li>
                  </ul>
                  Šios piktogramos suteikia sklandų būdą tiesiogiai susisiekti su skyriaus vadovais per „TeamConnect“, užtikrindamos veiksmingą bendravimą jūsų organizacijoje.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Kaip galiu peržiūrėti ar susisiekti su skyriaus darbuotojais?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Norėdami sužinoti, kas yra skyriuje TeamConnect programoje, ieškokite trijų taškų šalia skyriaus pavadinimo. Paspaudus juos atsivers kitos veiksmų parinktys:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Peržiūrėti šio skyriaus darbuotojus:</Body1Strong> Pasirinkite tai, kad būtų rodomas sąrašas visų su skyriumi susijusių darbuotojų. Šalia šios parinkties esantis skaičius nurodo darbuotojų skyriuje skaičių, suteikiant greitą apžvalgą.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Skyriaus organizacinė struktūra:</Body1Strong> Jei norite suprasti skyriaus hierarchiją, 
                      pasirinkite 'Skyriaus organizacinė struktūra' kad atskleisti struktūrinį išdėstymą, pateikiant ryšius tarp pagrindinių skyrių, aptariamo skyriaus ir bet kokių poskyrių. Šis vaizdinis atvaizdavimas padeda lengviau naviguoti ir suprasti organizacinę struktūrą, palengvindamas tam tikrų skyriaus narių identifikavimą ir susisiekimą su jais.
                    </li>
                    <li><Body1Strong>Skyrius {'>'} Darbuotojas:</Body1Strong> Peržiūrėjus skyriaus darbuotojus, naudokite šį maršrutą, kad lengvai grįžtumėte į skyrių sąrašą, išlaikydami sklandų naršymo patirtį TeamConnect programoje.
                    </li>
                  </ul>
                  Šios funkcijos leidžia ne tik matyti darbuotojų sąrašą tam tikrame skyriuje, bet ir suteikia galimybę tiesiogiai susisiekti su jais per TeamConnect komunikacijos įrankius.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Išsamių skyrių informacijos atradimas TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Gilyn įvesti bet koks skyrius su paprastu paspaudimu ant jo pavadinimo TeamConnect. Detali dialogo langas atsiskleidžia, pateikdamas turtingą informacijos mozaiką, 
                  kuri pateikia visas skyriaus funkcijas ir išteklius jūsų organizacijoje. Rasite viską nuo vadovybės ir finansinių identifikatorių iki komunikacijos kanalų ir socialinių nuorodų. Ši funkcija yra jūsų vartai į:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Skyriaus širdį – jo paskirtį, žmones ir veiklos rodiklius.</li>
                    <li className={styles.bulletBottomMargin}>Kontakto būdai – tiesioginiai ryšiai su pagrindiniais skyriaus asmenimis ir komunikacijos platformomis.</li>
                    <li className={styles.bulletBottomMargin}>Organizacinis tinklas – supratimas, kaip skyrius integravosi su platesne įmonės struktūra.</li>
                  </ul>
                  Ar tai greitas užklausimas ar gilus nardymas į skyriaus duomenis, TeamConnect suteikia jums įrankius susisiekti su ir suprasti unikalias jūsų organizacijos ekosistemas.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Kodėl TeamConnect nėra skyriaus adresų?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Kartais ieškant skyriaus vietos gali jaustis kaip lobio medžioklė be žemėlapio. TeamConnect programoje paprastai neišvysite atskiro adreso kiekvienam skyriui. Štai kodėl ši geografinė detalė dažnai praleidžiama:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kelių vietų:</Body1Strong> Daug skyrių neapsiriboja viena vieta; jie veikia įvairiose biuruose ir geografijose.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lanksčios struktūros:</Body1Strong> Organizacinis peizažas nuolat keičiasi, skyriai vystosi, sujungiami ar net išnyksta atsižvelgiant į strateginius pokyčius.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tarpusavio bendradarbiavimas:</Body1Strong> Šiuolaikinės darbo vietos alsuoja bendradarbiavimo dvasia, dažnai susilieja įgūdžiai iš įvairių skyrių bendroje erdvėje.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nuotolinio darbo kultūra:</Body1Strong> Dėl nuotolinio darbo lankstumo skyrių būstinės ne visada įsikuria fizinėse biuro vietose.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Teisiniai niuansai:</Body1Strong> Skyriai paprastai sukasi aplink įmonės teisinę struktūrą, o ne turi savo atskirus adresus.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Operacinis supaprastinimas:</Body1Strong> Dėmesys dinamiškam komandų darbui, o ne statiniams adresams, padeda išlaikyti operacinį lankstumą.
                    </li>
                  </ul>
                  Visas dėmesys skiriamas sienų neturinčiam komandų darbui ir efektyvumui. TeamConnect atspindi šią filosofiją, akcentuodamas ryšius vietoj vietų.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Ar jūsų skyriaus informacija trūksta arba pasenusi?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Skyriaus redaktoriai yra paskirti jūsų organizacijoje, kad pridėtų, atnaujintų arba ištaisytų skyriaus specifinę informaciją „TeamConnect“.
                  Jei nežinote, kas yra jūsų skyriaus redaktorius, arba jei tam tikra informacija reikalauja žmogiškųjų išteklių palaikymo komandos įsikišimo, 
                  prašome susisiekti <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> dėl patarimo.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Būsto katalogo DUK</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Būsto katalogo pristatymas:</Body1Strong> Sužinokite apie jūsų organizacijos fizinę buveinę per Būsto katalogą, 
                kuris apima būtinus biuro duomenis, operatyvias specifikas, ir išsamiai apibrėžia kiekvienos vietos skyrius bei personalą.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Naudotojimo žymės Būsto kataloge
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Lengvai orientuokitės Būsto kataloge naudodamiesi mūsų žymėjimo sistema, skirta greitam tinkamos vietos pasiekimui. Štai kaip kiekviena žymė gali padėti supaprastinti jūsų paiešką:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Visi:</Body1Strong> Pateikia visų biurų sąrašą, suteikiant visą vaizdą 
                      apie jūsų organizacijos vietas.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Mėgstamiausi:</Body1Strong> Padeda sutelkti dėmesį į tuos biuro vietas, kurias dažnai lankote arba 
                      reikia stebėti, pažymint juos kaip „Mėgstamiausi“.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Jūsų biuras:</Body1Strong> Ši asmeninė žymė filtruoja katalogą, kad rodytų jūsų 
                      dislokacijos vietą, laikydama jūsų būstinę po ranka.
                    </li>
                  </ul>
                  Ar rezervuojate susitikimo kambarį ar planuojate apsilankymą, šios žymės siūlo asmeniškai pritaikytą ir supaprastintą būdą, kaip susisiekti su jūsų organizacijos 
                  geografiniu kraštovaizdžiu.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Biurų katalogo rodinių valdymas naudojant TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Susipažinkite su įmonės įvairių vietų organizacine struktūra naudojant TeamConnect biurų katalogo vaizdus. Kiekvienas rodinys yra sukurtas palengvinant
                  unikalus biuro navigacijos ir supratimo aspektas:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sąrašo rodinys:</Body1Strong> Numatytasis nustatymas, kuris tvarkingai organizuoja visų biurų vietas į
                      lengvai skaitomą sąrašą.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Didelio kortelės rodinys:</Body1Strong> Didelio kortelės rodinys biurų kataloge praturtina jūsų
                      vaizdą detalizuota kiekvieno biuro pristatymu. Bendraujant su biuro kortele, rasite daug informacijos ir veiksmų variantų
                      per „Daugiau (...)“ meniu:
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Peržiūrėti skyrius:</Body1Strong> Tiesiogiai pereikite prie sąrašo
                          visų biure esančių skyrių.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Peržiūrėti darbuotojus:</Body1Strong> Prieiga prie pilno biure priskirtų darbuotojų sąrašo, palengvinant tiesioginį kontaktą arba biuro personalo tyrinėjimą.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Verslo subjekto organizacinė schema:</Body1Strong> Vizualizuokite biuro vietą didesniame
                          organizaciniame kontekste aiškiai suprantant hierarchines struktūras.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Būtini kontaktai:</Body1Strong> Nustatykite pagrindinius biuro kontaktus supaprastintai
                          komunikacijai.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Biuro viešosios atostogos:</Body1Strong> Likite informuoti apie biuro specifines viešąsias 
                          atostogas, kad galėtumėte planuoti savo grafikus atitinkamai.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Biuro darbo valandos:</Body1Strong> Peržiūrėkite biuro darbo valandas, kad suderintumėte savo 
                          vizitus ar skambučius su jų aktyviais laikais.
                        </li>
                      </ol>
                      Šis rodinys teikia ne tik vizualinę ir informacinę santrauką, bet ir greitą prieigą prie gilesnių įžvalgų ir tiesioginių veiksmų, susijusių su biuru, viskas tvarkingai sudėta po „Daugiau (...)“ parinktimi jūsų patogumui.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organizacinės schemos rodinys:</Body1Strong> Vizualizuokite pagrindinių ir šakinių biurų hierarchinę struktūrą
                      siekiant suprasti įvairių biuro vietų sąveiką jūsų organizacijoje.
                    </li>
                  </ul>
                  Perjunkite šiuos rodinius naudodami piktogramas šalia paieškos laukelio, kad pritaikytumėte naršymo patirtį pagal savo akimirkinius poreikius.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Greitas biurų radimas naudojant TeamConnect paieškos funkciją
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tinkamos biuro vietos TeamConnect sistemoje radimas palengvintas dėl dedikuotos paieškos funkcijos. Iškiliai prie rodinio piktogramų uždėtas paieškos langelis yra jūsų įrankis biurams rasti pagal pavadinimą. Rašant, katalogas patobulina rodymą rodydamas tik biurus, atitinkančius jūsų įvestį, optimizuodamas jūsų paieškos procesą. Svarbu naudoti šį paieškos langelį TeamConnect sistemoje, kad gautumėte tikslius ir aktualius rezultatus, o ne bendro Microsoft Teams paiešką. Su šia tikslinės paieškos funkcionalumu galite greitai patekti į bet kurį biurą savo organizacijos tinkle.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Biuro adresų katalogo naršymas pagal abėcėlę
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jei norite ieškoti pagal abėcėlę, mūsų abėcėlinis filtras yra greitas būdas rasti biurus pagal pavadinimą. Biuro adresų katalogo viršuje matysite 
                  raides nuo A iki Z. Paspaudus ant raidės, vaizdas filtruojamas, kad būtų rodomi tik tie biurai, kurių pavadinimai prasideda ta raide, taip 
                  palengvinant paiešką. Norėdami atšaukti ir vėl pamatyti visus biurus, tiesiog atžymėkite aktyvią raidę. Ši funkcija ypač naudinga, kai žinote 
                  biuro pavadinimą, bet reikia greitai jį surasti „TeamConnect“ sistemoje.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Visapusiški biuro profiliai „TeamConnect“ sistemoje
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Leiskitės į tyrinėjimo kelionę po savo organizacijos fizinio buvimo vietų širdį su „TeamConnect“ biuro profiliais. 
                  Spustelėjus ant biuro pavadinimo, atsiveria ryškus profilio dialogas, kuris gausus skirtukų su išsamiomis biuro įžvalgomis:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Apžvalga:</Body1Strong> Naršykite iki biuro tapatybės šerdies su išsamiais
                      aprašymais, pradedant nuo verslo struktūros iki darbo valandų ir vietos laiko, pagavimas biuro aplinkos esmę.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Būtini kontaktai:</Body1Strong> Prieiga prie būtinų kontaktų avarinėms paslaugoms ir
                      išorinei pagalbai, kiekvienas pasiekiamas vienu paspaudimu per „Microsoft Teams Phone System“.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Biuro kontaktai:</Body1Strong> Susisiekite su vidinėmis aptarnavimo paslaugomis lengvai, su
                      kontaktiniais duomenimis ir tiesioginėmis sąveikos galimybėmis tiesiai po ranka.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Viešos šventės:</Body1Strong> Būkite informuoti apie biurui būdingas šventes, užtikrindami, kad 
                      jūsų planai atitiktų vietos stebėjimus ir darbo grafikus.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Darbo valandos:</Body1Strong> Planuokite savo sąveikas su tiksliomis žiniomis, kada
                      biure verda veikla ar baigiasi diena.
                    </li>
                  </ul>
                  Ar tai veržli Austin'o energija ar strateginis Singapūro centras, kiekvienas skirtukas siūlo vartus suprasti ir susijungti su įvairiomis
                  vietovėmis, kurios sudaro jūsų įmonės pasaulinę buvimą.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Išnagrinėjimas skyrių dinamikos biure
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigacija biuro skyrių profesionaliame tinkle vyksta sklandžiai naudojant „TeamConnect“. Štai kaip įsigilinti į detales:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Peržiūrėkite skyrius šiame biure:</Body1Strong> Atraskite visus skyrius, kurie sudaro 
                      biuro struktūrą, pasirinkdami šią parinktį iš daugiau (...) meniu.
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organizacinis įžvalgumas:</Body1Strong> Spustelėkite ant bet kurio skyriaus, kad atskleistumėte jo organizacinę schemą, 
                      vizualiai pavaizduojantį jo struktūrą, o navigacijos kelias jus ves: Biuras {'>'} Skyrius {'>'} Schema.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Skyriaus giluminis tyrinėjimas:</Body1Strong> Tolimesniam skyriaus tyrinėjimui spustelėkite 
                      ant daugiau meniu (...) šalia skyriaus pavadinimo. Čia galėsite peržiūrėti visų to skyriaus darbuotojų sąrašą, tiesiai biuro kontekste.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Navigacija per nuorodas:</Body1Strong> Sklandžiai keliaukite per lygius. Nuo 
                      darbuotojų atgal į skyrius arba iš organizacinės schemos, navigacijos kelias (Biuras {'>'} Skyrius {'>'} Darbuotojas {'>'} Schema) padeda jūsų 
                      kelionei būti intuityvi ir teisinga.
                    </li>
                  </ul>
                  „TeamConnect“ sudėtingi keliai kviečia jus tyrinėti, suprasti ir įsitraukti į kiekvieną jūsų biuro skyrių sluoksnį, suteikdami jums išsamią navigacijos kontrolę.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Ryšių su biuro personalu užmezgimas naudojant „TeamConnect“
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ar esate smalsūs, kas slepiasi už biuro veidų? Atsakymas yra tik vieno paspaudimo atstumu „TeamConnect“. Užmegzkite ryšį su trijų taškų piktograma (trijų taškų) šalia pasirinkto 
                  biuro pavadinimo, kad atskleistumėte interaktyvių parinkčių rinkinį:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Peržiūrėkite darbuotojus šiame biure:</Body1Strong> Atraskite visų profesionalų, 
                      kurie vadina šį biurą savo darbo namais, sąrašą. Pateikiamas skaičius greitai parodo darbuotojų skaičių, kviesdamas jus įsigilinti į biuro bendruomenę.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontaktų parinktys:</Body1Strong> Kiekvieno darbuotojo įrašas aprūpintas greito veiksmo piktogramomis, 
                      leidžiančiomis pradėti pokalbius, skambučius ar net vaizdo konferencijas tiesiai iš „TeamConnect“, skatinantys nesudėtingą tarpusavio ryšį biure.
                    </li>
                    <li><Body1Strong>Navigacija:</Body1Strong> Norėdami grįžti prie platesnio biurų sąrašo iš darbuotojų sąrašo, tiesiog spustelėkite 'Biuras' navigacijos kelyje 
                      (Biuras {'>'} Darbuotojas), ir būsite grąžinti atgal į biuro apžvalgą.
                    </li>
                  </ul>
                  Su šiomis savybėmis „TeamConnect“ palengvina darbuotojų pasiskirstymo tarp biurų vizualizavimą ir leidžia lengvai susisiekti, 
                  skatindamas sujungtą darbo vietą nepaisant atstumo.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Ar jūsų biuro informacija trūksta arba pasenusi?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Biuro informacijos atnaujinimai TeamConnect gali būti tvarkomi priskirtų biuro redaktorių. Jei nesate tikri dėl biuro redaktorių arba
                  reikia pagalbos su informacija, kuri priklauso HR kompetencijai, HR palaikymo komanda yra pasirengusi
                  padėti adresu <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Nustatymų DUK</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Tikslaus bendrųjų nustatymų svarba TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Dinamiškoje šiandienos darbo aplinkoje būtinai būti sujungtam ir koordinuotam yra svarbiau nei bet kada. Čia aktualu atnaujinti savo
                  Bendruosius nustatymus TeamConnect, kurie atlieka lemiamą vaidmenį:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Darbo vietos Pasirinkimas:</Body1Strong> Atspindėkite savo darbo aplinkos esmę, atnaujindami darbo vietą, kad ji atitiktų jūsų dabartinę
                        padėtį, pavyzdžiui, persikėlimą iš namų biuro ramybės į pagrindinio biuro šurmulį arba įsitvirtinimą viešojoje erdvėje.
                        Ši aiškumo dėl jūsų darbo vietos suteikia kolegoms supratimą ne tik apie geriausius būdus, kaip su jumis susisiekti, bet ir
                        apie pokalbių pobūdį, kurį jie gali su jumis užmegzti, užtikrinant konfidencialių diskusijų saugumą, ypač kai esate kliento biure arba sėdite oro uosto laukiamajame.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Darbo vietos Specifikacija:</Body1Strong> Bendrindami savo darbo miestą, jūs ne tik mažinate atstumus tarp nuotolinių komandos narių, bet ir
                        sudarote galimybes netikėtiems profesionaliems susitikimams ar neformaliems susibūrimams. Ar tai būtų greita kava, ilgas pietūs ar bendradarbiavimo susitikimas,
                        žinojimas, kad esate tame pačiame mieste, gali paversti įprastą dieną prasmingų asmeninių ryšių galimybe. Tai ne tik sustiprina komandos ryšius,
                        bet ir suteikia gilesnį supratimą apie vietos niuansus, kurie gali turėti įtakos jūsų darbui.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Laiko juostos Tikslumas:</Body1Strong> Komandoms išplitus per žemynus, būtina, kad jūsų laiko juosta atspindėtų
                      jūsų dabartinę vietą, siekiant suderinti komunikaciją. Jūsų TeamConnect profilio Vietos laikas tiesiogiai priklauso nuo šio nustatymo,
                      pašalinant bet kokį spėliojimą kolegoms, bandantys su jumis susisiekti.
                    </li>
                  </ul>
                  Naršykite 'Nustatymai' {'>'} 'Bendrieji Nustatymai' TeamConnect, kad patikslintumėte šiuos duomenis. Keletas momentų, skirtų atnaujinti jūsų nustatymus, gali
                   žymiai padidinti jūsų bendradarbiavimo pastangų sanglaudą ir efektyvumą visapusiškai.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Darbo vietos pasirinkimo aiškinimas TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tinkamo pasirinkimo parinkimas Darbo vietų išskleidžiamajame meniu pritaiko TeamConnect, kad tiksliai atspindėtų jūsų dabartinę darbo būseną. Užveskite pelę virš informacijos piktogramos, kad gautumėte išsamią kiekvienos parinkties paaiškinimą:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Break:</Body1Strong> Reikšmė laiko praleidimui ne darbe, ar tai būtų pertrauka ar darbo dienos pabaiga.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office/Field-Office:</Body1Strong> Nurodo buvimą įmonės valdomoje darbo erdvėje.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> Darbas iš namų, skirtos darbo erdvės.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Public-Area:</Body1Strong> Darbas iš nebiuro aplinkos, reikalaujantis lankstaus bendravimo.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Customer Workplace/Private Office:</Body1Strong> Darbas kliento vietoje, su pastaba apie privatumo lygius, susijusius su pokalbių jautrumu.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Commute/Business-Travel/Training:</Body1Strong> Judėjimas ar įsitraukimas į konkretesnes, su darbu susijusias veiklas už biuro ribų.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Public-Holiday/Vacation/Various Leaves:</Body1Strong> Oficialiai nedirbant dėl asmeninių, sveikatos ar švenčių priežasčių.</li>
                  </ul>
                  Tinkamo darbo vietos nustatymo pasirinkimas užtikrina, kad jūsų komanda turėtų reikalingą kontekstą sąveikai, gerbiant prieinamumą ir privatumą.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. 'Checked-In' būsenos navigacija TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  'Checked-In' žyma Darbuotojų kataloge skirta rodyti visus šiuo metu darbe esančius darbuotojus. Ši matomumas nustatomas pagal jūsų pasirinktą darbo vietą:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Active Work Environments:</Body1Strong> Pasirinkimai Office, Field-Office, Home-Office, Remote-Workplace, Public-Area, Customer-Workplace, 
                    Customer Private-Office, Commute, Business-Travel ir Training signalizuoja, jog esate įsitraukęs į darbą, todėl esate 'Checked-In' ir matomas kolegoms.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Non-Work Settings:</Body1Strong> Pasirinkus Checked-Out, Break, Public-Holiday, Vacation, On-Leave, Maternity-Leave ar Sick-Leave 
                      būsenas jus pašalina iš 'Checked-In' sąrašo, nustatant aiškius lūkesčius dėl prieinamumo ir atsakymo laiko.
                  </li>
                  </ul>
                  Ši funkcionalumas padeda atskirti darbo laikotarpius nuo asmeninio laiko, prisidedant prie efektyvios komandos bendradarbiavimo ir bendravimo.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Duomenų valdymo ir privatumo DUK</Subtitle2>
          <Body1>
            <p>
              Šiandienos skaitmeniniame amžiuje suprasti, kaip yra valdomi jūsų duomenys, yra būtina. „TeamConnect“ įsipareigoja skaidrumui ir tvirtoms duomenų apsaugos praktikoms. 
              Žemiau rasite informaciją, kaip mes tvarkome, saugome ir apsaugome jūsų duomenis „TeamConnect“, užtikrindami, kad jūsų skaitmeninė darbo erdvė būtų saugi ir atitiktų reikalavimus.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Kaip „TeamConnect“ apsaugo mano asmeninius duomenis?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>„TeamConnect“ yra įsipareigojęs saugoti jūsų asmeninę informaciją, naudodamas išsamų požiūrį į duomenų saugumą:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Vieno prisijungimo funkcija (SSO):</Body1Strong> Siekiant sustiprinti saugumą ir supaprastinti vartotojo patirtį, „TeamConnect“ automatizuoja 
                      vieno prisijungimo (SSO) įtraukimą su jūsų „Microsoft 365“ paskyra, palengvinantį saugų ir pastangų nereikalaujantį prieigos valdymą.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Šifravimas:</Body1Strong> Mes užtikriname, kad visi „TeamConnect“ viduje esantys duomenys būtų šifruojami tiek perduodant, tiek saugant, 
                      todėl jie nepasiekiami neautorizuotiems asmenims.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Duomenų minimizavimas:</Body1Strong> Mūsų duomenų rinkimas yra griežtai ribojamas iki to, kas būtina „TeamConnect“ funkcionalumui. 
                      Vienintelė vartotojo informacija, kurią mes saugome, yra jūsų vartotojo pagrindinis vardas (UPN) iš „Azure Entra ID“, kuris gali atrodyti kaip jūsų įmonės el. pašto adresas.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Prieigos apribojimas:</Body1Strong> Prieiga prie UPN yra apribota jūsų organizacijos prenumeratos administratoriui mūsų prenumeratos portale, 
                      užtikrinant, kad jautri informacija būtų matoma tik įgaliotiems asmenims.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Greitas duomenų šalinimas:</Body1Strong> „TeamConnect“ yra sukonstruotas taip, kad nedelsiant ištrintų saugomą UPN pagal tris konkrečias sąlygas: 
                      jei prenumeratą atšaukia prenumeratos administratorius, jei vartotoją pašalina personalo skyrius „TeamConnect“ viduje, arba jei „TeamConnect“ 
                      prenumerata pasibaigs. Tai užtikrina, kad jūsų asmeniniai duomenys nebūtų saugomi ilgiau nei jų naudinga trukmė ar būtinybė.
                    </li>                      
                  </ul>
                  <p>Dėl šių priemonių „TeamConnect“ įsipareigoja palaikyti aukščiausius duomenų apsaugos standartus, užtikrindami, kad jūsų asmeninė informacija išliktų saugi ir konfidenciali.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Nesate tikri, kur yra jūsų duomenys su TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect laužo tradicinių SaaS pasiūlymų rėmus, užtikrindamas, kad jūsų duomenys niekada nepalieka jūsų organizacijos.
                  Priešingai nei įprasta praktika, kai duomenys saugomi išoriniuose debesijos serveriuose, TeamConnect įgudusiai pasinaudoja jūsų organizacijos
                  Microsoft 365 SharePoint Online, kad laikytų visus programos duomenis. Šis novatoriškas požiūris ne tik skatina duomenų suverenitetą, bet ir stiprina saugumą,
                  puikiai derindamas su mūsų tvirtu įsipareigojimu saugoti jūsų privatumą ir laikytis griežtų atitikties standartų.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Ar mano duomenys bus dalijamasi su trečiosiomis šalimis?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ne, duomenys nėra dalijami su trečiosiomis šalimis! Cubeet yra tvirtai įsipareigojęs jūsų duomenų privatumui ir saugumui. Mes neatskleidžiame jokių TeamConnect duomenų trečiosioms šalims be aiškaus mūsų klientų sutikimo.
                  Mūsų saugioje aplinkoje saugome tik būtinus identifikatorius, tokie kaip User Principal Name (UPN), organizacijos pavadinimas, nuomininko vardas ir nuomininko ID, mūsų prenumeratos portale.
                  Ši informacija yra būtina teikiant paslaugas ir yra saugoma pagal mūsų griežtas privatumo politikas.
                  Būkite tikri, kad mūsų duomenų valdymo praktikos yra sukurtos taip, kad jūsų duomenys išliktų konfidencialūs, o skaidrumas ir kontrolė tvirtai išlieka jūsų rankose.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Kaip TeamConnect laikosi duomenų apsaugos reglamentų, pavyzdžiui, GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect atitinka duomenų apsaugos reglamentus, tokie kaip GDPR, HIPAA, CCPA ir kiti, pasinaudodama Microsoft 365 aplinkos įgimtomis atitikties funkcijomis,
                  kur saugomi visi klientų duomenys. Priešingai nei tradiciniai SaaS modeliai, TeamConnect nesaugo jokių klientų duomenų savo serveriuose; vietoj to, ji veikia per jūsų
                  organizacijos Microsoft 365 aplinką, laikydamasi jos griežtų saugumo ir atitikties standartų. Šis metodas užtikrina, kad jūsų duomenų valdymas išliktų nuoseklus su
                  išsamiu Microsoft 365 teikiamu atitikties reglamentų rėmu, įskaitant laikymąsi GDPR, HIPAA sveikatos informacijai, CCPA Kalifornijos gyventojams,
                  ir kitais pasauliniais duomenų apsaugos reglamentais. Integruodamasi sklandžiai su jūsų esama Microsoft 365 sąranga, TeamConnect įgalina jūsų organizaciją 
                  palaikyti visišką kontrolę prieš jūsų duomenis, užtikrindama atitikimą su griežčiausiais duomenų apsaugos įstatymais ir reglamentais.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Kaip „TeamConnect“ tvarko duomenis iš „Microsoft Teams“ pokalbių, skambučių, vaizdo skambučių, „Outlook“ el. laiškų ir telefonų sistemos skambučių?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  „TeamConnect“ naudoja „Microsoft Graph API“, kad galėtų tiesiogiai bendrauti, pvz., vykdyti pokalbius, skambučius, vaizdo skambučius ir „Outlook“ el. laiškus „Microsoft Teams“ viduje,
                  užtikrinant sklandų vartotojo patyrimą. Svarbiausia, kad nors „TeamConnect“ inicijuoja šiuos ryšius, tai daroma nekišantis į bendravimo procesą
                  ir neturint galimybės įrašyti ar saugoti jokio sąveikos turinio. Visi pokalbiai, skambučiai ir el. laiškai vyksta ir yra valdomi tiesiogiai
                  saugioje „Microsoft Teams“ ir „Outlook“ aplinkoje, griežtai laikantis „Microsoft“ 365 stiprių saugumo ir privatumo standartų. Tai užtikrina, kad „TeamConnect“ pagerintų
                  jūsų bendravimo galimybes, visiškai išsaugant jūsų duomenų konfidencialumą ir vientisumą.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Norėdami gauti išsamesnių įžvalgų apie mūsų duomenų politikas ir praktikas, taip pat galite pasikonsultuoti su {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>„TeamConnect“ naudotojo vadovu</Body1Strong></Link> arba jūsų organizacijos IT 
              departamentu <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> dėl jūsų sistemos ypatumų.
          </p>
        </Body1>        
        <p></p>  
        <Subtitle1 className={styles.fontColor}>Mes klausomės: Pasidalykite savo atsiliepimais ir idėjomis</Subtitle1>
        <Body1>
          <p>
            Jūsų įžvalgos veda „TeamConnect“ evoliuciją. Nesvarbu, ar jūsų galvoje kirba nuostabi funkcijos idėja, ar turite atsiliepimų apie dabartinį patyrimą,
            mes atvirai klausome. Jūsų indėlis yra neįkainojamas mūsų nuolatinėje siektyje teikti geriausią įmanomą paslaugą.
          </p>
        </Body1>      
        <Subtitle2>Atsiliepimai ir pasiūlymai dėl funkcijų</Subtitle2>
        <Body1>
          <p>
            Pasiruošę pasidalinti? Eikite į mūsų {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Atsiliepimų puslapį
            </Link>
            . Naudodamiesi formos išplečiamuoju sąrašu tiesiog pasirinkite tinkamą parinktį, ar tai būtų naujos funkcijos pasiūlymas, ar atsiliepimas apie esamas funkcijas. Jūsų indėlis padeda formuoti „TeamConnect“ ateitį,
            užtikrinant, kad ji atitiktų ir viršytų jūsų lūkesčius.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
