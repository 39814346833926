import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>מרכז העזרה ושאלות נפוצות של TeamConnect </Title3>
        <p><Body1>
          ברוכים הבאים למרכז העזרה של TeamConnect, שנועד לתמוך בחווייתכם עם אפליקציית המדריך החברתי המקיפה שלנו. כאן תמצאו משאבים לשימוש מיטבי ב-TeamConnect בתוך Microsoft Teams, מה שישפר את התקשורת ברשת שלכם.
        </Body1></p>
        <Subtitle2>מדריך לשימוש מהיר</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>יכולות חיפוש מתקדמות: </Body1Strong>
              מצאו את העמיתים, המחלקות, ואנשי הקשר החיוניים במהירות עם כלי החיפוש החזקים שלנו.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>תרשימי ארגון חזותיים: </Body1Strong>
              נווטו בהיררכיה של הארגון והבינו את קווי הדיווח בקלות דרך תרשימי ארגון אינטואיטיביים.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>כלים מותאמים לצרכים העכשוויים: </Body1Strong>
              השתמשו בפונקציונליות מודרנית המותאמת לדרישות של מקומות עבודה עכשוויים.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>שיפור חוויית השימוש שלכם</Subtitle2>
        <Body1>
          <p>
            TeamConnect מהפכת את אופן האינטראקציה שלכם במבנה הארגוני שלכם, הופכת תקשורת יומיומית להתערבויות אסטרטגיות.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>קבלו עזרה ושאלות נפוצות</Subtitle1>
        <Body1>
          <p>
            חפשו תשובות לשאלות נפוצות על תכונות והגדרות של TeamConnect בסעיף זה המיועד לכך. אם אתם מנווטים באפליקציה, מעצבים הגדרות או מחפשים פתרונות לאתגרים, מרכז העזרה שלנו כאן כדי לעזור לכם ביעילות.
          </p>
        </Body1>
        <Body1>
          <p>
            לתמיכה מעמיקה יותר או הדרכה ספציפית, אנא התייעצו עם <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>מדריך המשתמש של TeamConnect</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        
        <Subtitle2>שאלות נפוצות בשימוש ב-TeamConnect</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. לא מוצא את TeamConnect בסרגל הצד של Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  אם TeamConnect אינו נראה במיידי או נעלם מסרגל הצד שלך ב-Microsoft Teams, תוכל לנעוץ אותו בקלות לגישה מהירה. פשוט עקוב אחר השלבים הפשוטים האלה:
                  <ol>
                    <li className={styles.bulletBottomMargin}>פתח את Microsoft Teams ולחץ על הסמל '...' (+ Apps) בתחתית הסרגל הצדדי.</li>
                    <li className={styles.bulletBottomMargin}>חפש את 'TeamConnect' בסרגל החיפוש בראש החלון המוקפץ.</li>
                    <li className={styles.bulletBottomMargin}>לחץ עם הכפתור הימני על יישום TeamConnect מתוך תוצאות החיפוש ובחר 'נעץ'.</li>
                  </ol>
                  עכשיו TeamConnect יהיה מונעץ לסרגל הצד שלך, ותוכל לגשת אליו רק בלחיצת כפתור!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. נתקל בבעיות טכניות עם TeamConnect? הנה איך תוכל לקבל עזרה.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  אם אתה נתקל בבעיות טכניות עם TeamConnect ב-Microsoft Teams, צוות התמיכה הטכנית של הארגון שלך נמצא כאן כדי לעזור. 
                  פנה אליהם ב-<Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, והם יסייעו לך במהירות.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. לא מוצא את פרטי העובד שלך?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  אם הפרופיל שלך נראה קצת ריק עם שמך ודוא"ל בלבד, ייתכן שצוות המשאבי אנוש שלך עדיין לא הוסיף את שאר פרטי העובד שלך ל-TeamConnect.
                  אין צורך לדאוג! הודעה מהירה לצוות התמיכה במשאבי אנוש תזרז את הדברים. הם מוכנים למלא את הפרופיל שלך עם כל הפרטים החשובים.
                  פנה אליהם ב-<Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> ותראה איך הפרופיל שלך מתמלא בחיים!
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="4">
            <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. אילו אפשרויות שפה זמינות ב-TeamConnect?
                </Body1Strong>
            </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect מחבקת את המגוון העולמי של Microsoft Teams על ידי תמיכה בכל השפות שלו.
                  אם אתה מעדיף ערבית או יוונית, יפנית או שוודית, יש לך עולם של אפשרויות בקצה ידייך, כולל:
                </Body1>
                  <ul>
                    <li>
                      <Body1>
                        ערבית, בולגרית, סינית (מפושטת ומסורתית), קרואטית, צ'כית, דנית, הולנדית, אנגלית, אסטונית, פינית,
                        צרפתית, גרמנית, יוונית, עברית, הונגרית, אינדונזית, איטלקית, יפנית, קוריאנית, לטבית, ליטאית, נורווגית (בוקמל),
                        פולנית, פורטוגזית (ברזיל ופורטוגל), רומנית, רוסית, סרבית (לטינית), סלובקית, סלובנית, ספרדית, שוודית, תאית,
                        טורקית, אוקראינית, וייטנאמית.
                      </Body1>
                    </li>
                  </ul>
                <Body1>
                  כדי להתאים אישית את חווית ה-TeamConnect שלך לשפה המועדפת עליך, בבקשה התאם את הגדרות השפה ישירות ב-Microsoft Teams,
                  כפי ש-TeamConnect משתלבת לגמרי עם ההגדרות האלה:
                </Body1>
                <ol>
                <Body1>
                  <li className={styles.bulletBottomMargin}>
                  לך להגדרות של Microsoft Teams על ידי לחיצה על שלוש הנקודות ליד תמונת הפרופיל שלך בראש העמוד, ואז בחר 'מראה ונגישות'.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                  גלול לסעיף 'שפה' ובחר את השפה המועדפת עליך מתוך תפריט הנפתח.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    אשר את השינויים על ידי לחיצה על כפתור 'שמור והפעל מחדש' כדי ליישם את הגדרות השפה החדשות.
                  </li>
                </Body1>
                </ol>
                <Body1Strong>שים לב: </Body1Strong>
                <Body1>
                  שינויים שנעשו להגדרות השפה ב-Microsoft Teams יעדכנו גם את חווית ה-TeamConnect שלך בצורה חלקה.
                  דע כי שינוי השפה ב-Teams עלול גם לשנות את פורמט השעה והתאריך שלך. כרגע,
                  TeamConnect מתפתחת כדי לתמוך בכתיבה מימין לשמאל. אנו נרגשים מהשיפורים האלו
                  ונמשיך לעדכן אותך ככל שיהיו זמינים.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. איך אני יכול לשנות את הערכת הנושא ב-TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect מסנכרן עם העדפות הנושא שלך ב-Microsoft Teams, כולל האפשרויות אור, כהה וניגודיות גבוהה. כדי להתאים אישית את הנושא שלך:
                  <ol>
                    <li className={styles.bulletBottomMargin}>גש להגדרות של Microsoft Teams על ידי לחיצה על שלוש הנקודות ליד תמונת הפרופיל שלך בחלק העליון.</li>
                    <li className={styles.bulletBottomMargin}>בחר 'מראה ונגישות' כדי לחקור את אפשרויות הנושא.</li>
                    <li className={styles.bulletBottomMargin}>בחר את הנושא המועדף עליך מתוך האפשרויות הזמינות כדי לעדכן מיידית את ממשק ה-TeamConnect שלך להתאמה.</li>
                  </ol>
                  בחירת הנושא שלך ב-Microsoft Teams תיושם אוטומטית על TeamConnect, מה שמבטיח חווית צפייה עקבית במרחב העבודה שלך.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. חקירת כפתורי פעולה ליצירת קשר של TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ברחבי TeamConnect, בכל מקום שבו תמצא פרטי קשר של עובד, תגלה גם את הכפתורים המהירים שלנו. הכלים הנוחים האלה עוצבו כדי להקל על התקשורת שלך בתוך אקוסיסטם ה-Microsoft, ומבטיחים שתמיד תהיה רק לחיצה אחת מלהתחבר.
                  הנה המדריך המקיף שלך לשימוש יעיל בכפתורים אלו:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>אייקון שיחה:</Body1Strong> לחץ כדי להתחיל שיחת Microsoft Teams מיידית. אם אתה צריך לחזור ל-TeamConnect לאחר התחלת שיחה, פשוט השתמש בחץ החזור באפליקציית שולחן העבודה, בכפתור החזור באפליקציית הנייד או בכפתור החזור של הדפדפן שלך.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>אייקון אימייל:</Body1Strong> פותח טיוטת אימייל חדשה ב-Outlook עם כתובת הנמען מוכנה בשדה "אל:". אם Outlook לא נפתח, כדאי לבדוק עם מחלקת ה-IT שלך לגבי אילוצים.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>אייקון שיחת וידאו:</Body1Strong> מתחיל שיחת וידאו פנים אל פנים באמצעות Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>אייקון שיחה:</Body1Strong> מתחיל שיחת קול ב-Microsoft Teams, ומאפשר שיחות מהירות וחלקות.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>אייקון תרשים ארגוני:</Body1Strong> חושף את מקומו של העובד בהיררכיה הארגונית, כולל מנהלו ודיווחים ישירים.</li>
                    <li><Body1Strong>אייקון נוסף (...):</Body1Strong> חושף אפשרויות נוספות:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>פגישה מיידית:</Body1Strong> פותח את Microsoft Teams להתחלת פגישה מיידית עם העובד.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>פגישה חדשה:</Body1Strong> מנתב אותך ל-Microsoft Teams כדי לתזמן פגישה חדשה.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>הוסף איש קשר:</Body1Strong> מוסיף את פרטי הקשר של העובד מ-TeamConnect לאפליקציית האנשים החדשה של Microsoft Teams, מעשיר את פונקציית השיחה שלך.</li>
                      </ul>
                    </li>
                  </ul>
                  הכפתורים האינטואיטיביים האלו עוצבו כדי להבטיח שתוכל בקלות להתחבר עם עמיתים לעבודה, בין אם זה לשיחה מהירה, דואר אלקטרוני או לתזמון פגישות עתידיות.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. ביצוע שיחות דרך TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  מעוניין לבצע שיחה? TeamConnect מפשט את תקשורתך על ידי אינטגרציה עם Microsoft Teams.
                  פשוט לחץ על מספר הטלפון הרצוי ב-TeamConnect, והנה, ממשק השיחות של Microsoft Teams מופיע,
                  מוכן שתתקשר בצורה חלקה.
                  <p>
                    <Body1Strong>הערה: </Body1Strong> 
                    נתקל בבעיות בביצוע שיחות? זה יכול להיות בשל מדיניות ה-IT של ארגון שלך או השימוש במערכת טלפוניה שאינה Microsoft Teams Phone System. בדיקה עם מחלקת ה-IT שלך ב-<Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, יכולה להאיר את הנושא.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                8. ישנם עלויות הקשורות לביצוע שיחות דרך TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                  <Body1>
                  TeamConnect משפר את חווית השיחות שלך על ידי שימוש ב-Microsoft Teams. זה מה שצריך לדעת על עלויות קשורות לשיחות:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                    לשיחות לטלפונים של עובדים, מחלקות או קווי חירום בארגון שלך (מספרים פנימיים),
                    בדרך כלל <Body1Strong>אין עלויות</Body1Strong> בשימוש במערכת הטלפונים של Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                    לשיחות חיצוניות, כגון לניידים או קווים חיצוניים, <Body1Strong>התעריפים הרגילים</Body1Strong> של מערכת הטלפונים של הארגון שלך חלים.
                    התעריפים אלו משתנים בהתאם לתוכנית השירות הספציפית עם Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>שימו לב:</Body1Strong> אם נתקלתם בבעיות או שיש לכם שאלות לגבי חיובי שיחות, מומלץ להתייעץ עם מחלקת 
                    ה-IT בארגון שלכם ב-<Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> לקבלת מידע מפורט 
                    המותאם להגדרות ולמדיניות של הארגון שלכם.</p>
                  </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                  <Body1Strong className={styles.fontColor}>
                  9. שמתם לב לחוסר התאמה בסטטוס הנוכחות 'מחוץ למשרד' ב-TeamConnect?
                  </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                שמתם לב שסטטוס הנוכחות 'מחוץ למשרד' מ-Microsoft 365 לא משתקף כראוי ב-TeamConnect?
                אנחנו כבר טיפלנו בזה. התכונה לסנכרון סטטוס הנוכחות 'מחוץ למשרד' כבר מיושמת ב-TeamConnect.
                אנו מחכים כעת להפצה של תכונה זו על ידי מיקרוסופט. ברגע שזה יקרה, סטטוס הנוכחות 'מחוץ למשרד' שלכם ישקף באופן מדויק ב-TeamConnect,
                בהתאמה לאינדיקטורי נוכחות של Microsoft Teams לחוויה חלקה ומתואמת.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>שאלות ותשובות על מדריך העובדים</Subtitle2>
          <Body1>
            <p>
                <Body1Strong >הקדמה למדריך העובדים:</Body1Strong> נווט בנוף המקצועי של הארגון שלך עם מדריך העובדים, 
                המרכז שלך לתובנות מפורטות על עובדים, דינמיקות במקום העבודה וחיבור ישיר לפרופילים וליחסי הניהול של עמיתיך.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. צריך להוסיף, לעדכן או לתקן את נתוני העובד שלך?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  להוספת, עדכון או תיקון של נתוני העובד שלך ב-TeamConnect, אנא פנה לצוות תמיכת משאבי האנוש של הארגון שלך.
                  הם מוכנים לעזור ב-<Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. מה קורה כשאני לוחץ על שם עובד במדריך?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  לחיצה על שם עובד במדריך עובדי TeamConnect פותחת דף פרופיל מפורט עבור אותו הפרט. פרופיל זה מספק תובנות מקיפות אודות העובד, כולל (אך לא מוגבל לכך) תיאור משרתו, מחלקה, מיקום המשרד, פרטי קשר ועוד. הפרופיל עוצב כדי לתת לך סקירה מלאה על תפקידו המקצועי של העובד ואיך הוא משתלב בארגון, מה שיקל עליך להבין את אחריותיו ואיך תוכל לשתף פעולה איתו.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. אילו מידע כלול בפרופיל של עובד?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  כל פרופיל עובד ב-TeamConnect הוא תמונת מצב מקיפה, המציעה מודעות רבה לחייו המקצועיים של הפרט. הנה מה תמצא:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>פרטים בסיסיים:</Body1Strong> שם, תיאור משרה, מחלקה, ומיקום החברה-הכלילי.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>מבנה הדיווח:</Body1Strong> מידע על דיווחים ישירים ותפקודיים, 
                      כולל מנהלים הישירים והתפקודיים.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>מצב במקום העבודה:</Body1Strong> מצב העבודה הנוכחי כגון בחופשה, בדרך לעבודה, 
                      בנסיעות עסקיות וכדומה.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>מקום עבודה & שעה מקומית:</Body1Strong> מיקום העבודה הנוכחי ואזור הזמן המקומי.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>פרטי קשר:</Body1Strong> טלפון עבודה, מספר נייד, וכתובת דוא"ל.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>כתובת המשרד:</Body1Strong> מיקום המשרד המלא כולל שם הבניין,
                      הכתובת, ומפת Google.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>אודות:</Body1Strong> הקדמה אישית קצרה או תיאור מקצועי.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>יחסי עובדים:</Body1Strong> פרטי מבנה הדיווח ויחסי צוות.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>קישורים חברתיים & שפות:</Body1Strong> קישורים לפרופילים מקצועיים ברשתות החברתיות 
                      ושליטה בשפות.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>מיומנויות:</Body1Strong> רשימת מיומנויות מקצועיות ותחומי מומחיות.</li>
                  </ul>
                  כמו כן, הפרופיל מציע אפשרויות פעולה נוספות ליצירת קשר באמצעות צ'אט, דוא"ל, שיחת וידאו או לתזמן פגישות, יחד עם גישה מהירה לתרשים הארגוני להבנת הצוות הרחבה יותר.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. איך אני יכול לעדכן את פרטי הפרופיל שלי?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  להתאים אישית את הפרופיל שלך קל ב-TeamConnect. כך תוכל לעשות זאת:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        נווט ללשונית <Body1Strong>Employee</Body1Strong> ולחץ על ה<Body1Strong>Avatar</Body1Strong> כדי לפתוח את הפרופיל שלך.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        בפינה השמאלית העליונה, תמצא את הקישור <Body1Strong>"ערוך את הפרופיל שלך"</Body1Strong>. כשתלחץ עליו ייפתח דיאלוג עריכה לפרופיל שלך.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        כאן, תוכל לשפר את הפרופיל שלך על ידי הוספה או עדכון של פרטי הסעיף <Body1Strong>על עצמך</Body1Strong>, קישורים לרשתות חברתיות, ומספר טלפון.
                        זה יקל על עמיתיך ליצור קשר או לעקוב אחריך.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        אל תשכח ללחוץ על כפתור ה<Body1Strong>Save</Body1Strong> כדי להחיל את השינויים שלך.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        גלול למטה בדיאלוג כדי להוסיף את ה<Body1Strong>Languages</Body1Strong> ואת ה<Body1Strong>Proficiency</Body1Strong>, וכן את ה<Body1Strong>Skills</Body1Strong> שלך.
                        שיתוף פעולה זה עוזר לעמיתיך להבין את תחומי המומחיות שלך ואת השפות בהן אתה יכול לתקשר.
                      </li>
                    </ol>
                  עדכון הפרופיל שלך לא רק שומר על המידע שלך עדכני, אלא גם תורם ליצירת קשרים טובים יותר בארגון שלך.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. עדכון מקום העבודה, נקודת העבודה ואזור הזמן ב-TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  שמירה על מידע עדכני אודות מקום העבודה, נקודת העבודה, ואזור הזמן היא קרושיאלית לשיתוף פעולה חלק ב-Microsoft Teams.
                  עדכונים אלו משתקפים מיד בפרופיל וברישומים שלך ב-TeamConnect, ומאפשרים לעמיתיך להבין במהירות את מיקום העבודה שלך
                  ואת הזמנים הטובים ביותר ליצירת קשר עימך, מה שמחזק את העבודה הצוותית בין אזורים ואזורי זמן שונים.
                  <p></p>
                  מוכן לעדכן את הפרטים שלך? זה קל ב-TeamConnect! עבור ל-'הגדרות' בתפריט העליון ונווט ל-'הגדרות כלליות'.
                  כאן תוכל לעדכן בצורה חלקה את מקום העבודה ואזור הזמן שלך, ולציין את נקודת העבודה שלך ליום.
                  זכור לשמור את השינויים שלך כדי לשמור על כולם מעודכנים ולהבטיח שיתוף פעולה יעיל, ללא תלות במיקומך!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. ראית משהו לא בסדר בפרופיל של עמית?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  אם נתקלת בפרטים בפרופיל של עמית שנראים לך לא בסדר, הצעד הראשון הטוב ביותר הוא לתת לו התראה ידידותית.
                  שיחה קצרה או אימייל צריכים לעשות את העבודה! אם מסיבה כלשהי המידע לא מתעדכן, אל דאגה—פשוט שלח הודעה לצוות תמיכת המשאבים האנושיים שלך ב<Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>,
                  והם ישמחו להתערב ולסדר את העניינים.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. הבנת קבוצות תגים: כולם, מועדפים, נכנסו למערכת, ועובדים חדשים
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>כולם:</Body1Strong> תג זה מציג באופן דיפולטיבי כל עובד בארגון שלך,
                      ונותן מבט כולל על הצוות.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>מועדפים:</Body1Strong> לחץ כאן כדי לסנן את התצוגה לאנשי הקשר שסימנת
                      כ'מועדפים', מה שיקל עליך להתחבר במהירות לאנשי המפתח.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>נכנסו למערכת:</Body1Strong> תג זה מציין עובדים שנמצאים כעת בעבודה, מבדיל
                      בין נוכחות פשוטה ב-Microsoft Teams. כאשר עובדים נכנסים למערכת, הם מראים שהם נמצאים בעבודה ומשתפים את מיקום העבודה הנוכחי שלהם 
                      במהלך היום. זה יכול לכלול מגוון מקומות עבודה כמו משרד, משרד שטח, משרד ביתי, מקום עבודה מרחוק, מקום עבודה של לקוח, 
                      משרד פרטי של לקוח, אזור ציבורי, נסיעות, נסיעות עסקיות או אפילו הכשרה. לכן, מצב 'נכנס למערכת' מספק אינדיקציה ברורה 
                      של נוכחות פעילה בעבודה, ומציעה לעמיתים הבנה מדויקת של הקונטקסט והזמינות העבודתיים הנוכחיים של כל אחד.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>עובדים חדשים:</Body1Strong> זהה פנים חדשות ב-TeamConnect עם תג זה, המדגיש
                      עובדים שהתווספו לאפליקציה ב-30 הימים האחרונים. בדרך כלל, זה תואם להוספות אחרונות לארגון שלך, ועוזר 
                      לך לקבל פני חברי צוות חדשים.
                    </li>
                  </ul>
                  קבוצות תגים אלו עוצבו כדי לזרז את הניווט ולשפר את חוויית ה-TeamConnect שלך, ומבטיחות שתמיד תוכל 
                  למצוא ולהתחבר עם עמיתיך ביעילות.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. פענוח האייקונים ליד תיבת החיפוש
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  מדריך העובדים של TeamConnect מעוצב לגמישות ונוחות, וכולל ארבעה אייקונים שמציעים תצוגות שונות 
                  וגישה מהירה לפרטים חשובים. הנה מה שכל אייקון חושף:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>תצוגת רשימה:</Body1Strong> תצוגת הבסיס שלך, מציגה את כל חברי הארגון 
                      ברשימה פשוטה.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>תצוגת כרטיס קטן:</Body1Strong> חלופה קומפקטית, הופכת את 
                      הרשימה לכרטיסי עובדים בגודל ביס.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>תצוגת כרטיס גדול:</Body1Strong> בחר בפרטים רבים יותר עם כרטיסים גדולים יותר, שכוללים 
                      אפשרויות קשר נוספות כמו מספרי טלפון ונייד לחיוג נוח.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>תרשים ארגוני:</Body1Strong> המחש את מבנה החברה עם תצוגת התרשים הארגוני, 
                      מושלמת להבנת יחסי הדיווח וניווט בנוף הארגוני.</li>
                  </ol>
                    החלף בין אייקונים אלה כדי למצוא את התצוגה שמתאימה ביותר לצרכיך ברגע זה, בין אם אתה מחפש מידע מהיר או תובנות עמוקות יותר.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. לחיפוש מהיר אחר עמיתים, איפה כדאי לחפש?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  מחפש עמית? פשוט עבור אל תיבת החיפוש של TeamConnect, הנמצאת בצד הימני העליון ליד אייקון התרשים הארגוני.
                  התחל להקליד את שמם, וצפה כיצד התוצאות מתמצקות עם כל אות. זכור, עבור חיפושים ב-TeamConnect, המנע משימוש בשורת החיפוש של Microsoft Teams 
                  בחלק העליון—תיבת החיפוש המיוחדת שלנו היא הדרך ללכת!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. איך עובדת חיפוש אלפבתית למצוא עובדים?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  המדריך לעובדים של TeamConnect כולל תכונה נוחה של חיפוש אלפבתי שעוזרת לך לאתר מהר עובדים לפי שם פרטי.
                  פשוט לחץ על אות מהאלפבית המוצג בראש המדריך, ו-TeamConnect יציג מיד את כל העובדים
                  ששמם הפרטי מתחיל באות זו. כדי לחזור לרשימה המלאה של העובדים, פשוט בטל את הבחירה באות המודגשת. תכונה זו, האינטואיטיבית,
                  מקלה על ניווט ברשימות גדולות של עובדים, ומבטיחה שתוכל למצוא עמיתים במהירות וביעילות.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. איך אני יכול לשנות את מספר העובדים המוצגים בכל דף?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect מאפשר לך להתאים אישית את מספר פרופילי העובדים שאתה רואה בכל דף, כדי להקל על הניווט במדריך לעובדים.
                  בתחתית כל דף, תמצא אפשרויות לעימוד: 10, 50, 100, 500, 1000, ו-5000. ההגדרה המוגדרת כברירת מחדל מציגה 10 פרופילים לדף.
                  כדי לראות יותר פרופילים בדף אחד, פשוט בחר את המספר המועדף עליך מבין האפשרויות הללו. תכונה זו מספקת גמישות באופן שבו אתה גולש
                  במדריך, ומאפשרת לך להתאים את התצוגה לצרכים או להעדפות הספציפיות שלך.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. איך אני ממיין מידע על עובדים בתצוגת רשימה?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  בתצוגת הרשימה של מדריך העובדים של TeamConnect, יש לך את הגמישות למיין מידע על עובדים לפי הצורך שלך. העמודות—שם העובד, 
                  תפקיד, מחלקה, מיקום המשרד ומקום העבודה—כולן ניתנות למיון. פשוט לחץ על כותרת העמודה שברצונך למיין לפיה. לחיצה אחת תמיין 
                  את העמודה לפי סדר עולה, ולחיצה נוספת תמיין אותה לפי סדר יורד. כברירת מחדל, המדריך ממוין לפי שם העובד בסדר עולה, בהתבסס על השם הפרטי.
                  תכונת המיון זו עוזרת לך לארגן ולהציג פרטי עובדים בצורה המשרתת את מטרתך בצורה הטובה ביותר, בין אם אתה מחפש אדם מסוים או צריך לקבץ עובדים לפי קריטריון מסוים כמו מחלקה או מיקום.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
          <Subtitle2>שאלות נפוצות במדריך המחלקות</Subtitle2>
            <Body1>
              <p>
              <Body1Strong>הקדמה למדריך המחלקות:</Body1Strong> מדריך המחלקות הוא שערך להבנת הרשת המורכבת של מבנה הארגון שלך, ומציע תצוגה ברורה של היררכיות המחלקתיות, הנהגת 
                הניהול ודרכים לתקשורת מחלקתית חלקה.
              </p>
            </Body1>        
          <div>
            <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
              <AccordionItem value="22">
                <AccordionHeader>
                  <Body1Strong className={styles.fontColor}>
                    22. כיצד פועלים התגים 'הכל', 'מועדפים', ו'המחלקה שלך' במדריך המחלקות?
                  </Body1Strong>
                </AccordionHeader>
                <AccordionPanel>
                  <Body1>
                    במדריך המחלקות של טים קונקט, התגים עוזרים לך לנווט ולסנן מידע מחלקתי במהירות. הנה מה שכל תג מייצג:
                    <ul>
                      <li className={styles.bulletBottomMargin}><Body1Strong>הכל:</Body1Strong> תג זה מציג את כל המחלקות בארגון שלך, ומספק סקירה כוללת.
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>מועדפים:</Body1Strong> השתמש בתג זה כדי לראות רשימה מעודכנת של מחלקות שסימנת 
                        כ'מועדפות', מה שמקל על הגישה למחלקות עם התקשורת התכופה ביותר שלך.
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>המחלקה שלך:</Body1Strong> תג זה מסנן את התצוגה כדי להציג רק את מחלקתך, 
                        מאפשר גישה מהירה למידע המחלקתי ועדכונים הקשורים אליך באופן ישיר.</li>
                    </ul>
                    תגים אלה מיועדים לייעל את הניווט שלך בתוך מדריך המחלקות, ולשפר את יכולתך למצוא ולהתעסק במידע מחלקתי ביעילות.
                  </Body1>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem value="23">
                <AccordionHeader>
                  <Body1Strong className={styles.fontColor}>
                    23. הבנת תצוגות מדריך המחלקות בטים קונקט
                  </Body1Strong>
                </AccordionHeader>
                <AccordionPanel>
                  <Body1>
                    ניווט במדריך המחלקות בטים קונקט מועיל עם אפשרויות תצוגה מרובות שמתאימות להעדפותיך. כל תצוגה מציעה דרך ייחודית לחקור מידע מחלקתי כולל, כולל שם המחלקה, מנהל המחלקה, המחלקה האם, טלפון המחלקה, קו החם של המחלקה, ודוא"ל המחלקה. הנה סקירה של התצוגות הזמינות:
                    <ul>
                      <li className={styles.bulletBottomMargin}><Body1Strong>תצוגת רשימה:</Body1Strong> ההגדרה המוגדרת כברירת מחדל, שמסדירה את כל המחלקות ברשימה 
                        נקייה ומקוצרת.
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>תצוגת כרטיס גדול:</Body1Strong> החלף לתצוגה זו כדי לראות כרטיסי מחלקה מפורטים, המציעים 
                        סיכום מורחב של מידע מרכזי בכל מחלקה.
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>תצוגת תרשים ארגוני:</Body1Strong> לייצוג ויזואלי של המבנה המחלקתי והיחסים, תצוגת 
                        התרשים הארגוני ממחישה כיצד כל מחלקה מתמזגת בהיררכיה הארגונית הרחבה יותר.
                      </li>
                    </ul>
                    כדי לעבור בין התצוגות הללו, פשוט השתמש באייקונים ליד תיבת החיפוש בראש מדריך המחלקות. כל תצוגה מיועדת לעזור לך לגשת ולהבין פרטים מחלקתיים בקלות, ומבטיחה שתמצא את המידע שאתה זקוק לו בפורמט המתאים ביותר עבורך.
                  </Body1>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem value="24">
                <AccordionHeader>
                  <Body1Strong className={styles.fontColor}>
                    24. איך להשתמש בתיבת החיפוש במדריך המחלקות
                  </Body1Strong>
                </AccordionHeader>
                <AccordionPanel>
                  <Body1>
                    מצא בצורה חלקה את כל מחלקה ב-TeamConnect באמצעות תיבת החיפוש המוקדשת הממוקמת ממש ליד סמלי התצוגה בחלק העליון של 
                    מדריך המחלקות. פשוט התחל להקליד את שם המחלקה שאתה מחפש, וצפה כיצד המדריך משפר את עצמו להציג רק 
                    התאמות רלוונטיות. תכונה זו תוכננה במיוחד כדי לעזור לך לעקוף את הצורך לעבור באופן ידני דרך כל המדריך, ולהביא את 
                    המחלקה שאתה צריך ישירות לקצות אצבעותיך. זכור, פונקציית החיפוש הזו ייחודית למדריך המחלקות של TeamConnect, אז הקפד 
                    להשתמש בתיבת חיפוש זו במקום בחיפוש הראשי של Microsoft Teams בראש ממשק ה-Teams עבור שאילתות ספציפיות למחלקה.
                  </Body1>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem value="25">
                <AccordionHeader>
                  <Body1Strong className={styles.fontColor}>
                    25. איך פועל המסנן האלפביתי במדריך המחלקות?
                  </Body1Strong>
                </AccordionHeader>
                <AccordionPanel>
                  <Body1>
                    עשה את חיפושך במדריך המחלקות יעיל יותר עם המסנן האלפביתי הממוקם בחלק העליון. התכונה הזו מאפשרת לך לצמצם במהירות 
                    מחלקות על ידי בחירת אות. כאשר אתה בוחר אות, TeamConnect מסנן ומציג רק את המחלקות ששמם מתחיל 
                    באות שבחרת, וכך מקל עליך למצוא את מה שאתה מחפש בלי לגלול דרך כל הרשימה. כדי לחזור לתצוגת המחלקה המלאה, פשוט בטל 
                    את בחירת האות המודגשת. ניווט אלפביתי אינטואיטיבי זה מבטיח שתוכל לאתר ביעילות כל מחלקה לפי אות התחלתה.
                  </Body1>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem value="26">
                <AccordionHeader>
                  <Body1Strong className={styles.fontColor}>
                    26. איך אני יכול ליצור קשר עם מנהל המחלקה?
                  </Body1Strong>
                </AccordionHeader>
                <AccordionPanel>
                  <Body1>
                    יצירת קשר עם מנהל מחלקה ב-TeamConnect היא פשוטה. פשוט מצא את המחלקה שמעניינת אותך ולחץ על שם המנהל. פעולה זו תפעיל 
                    חלונית צץ המציגה אפשרויות התקשרות עם המנהל עם סמלים מוכרים לגישה מהירה:
                    <ul>
                      <li className={styles.bulletBottomMargin}><Body1Strong>סמל שיחה:</Body1Strong> להתחיל הודעה מיידית באמצעות צ'אט של Microsoft Teams.</li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>סמל דואר אלקטרוני:</Body1Strong> לפתוח דואר אלקטרוני חדש מופנה אליהם ב-Microsoft Outlook.</li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>סמל שיחת וידאו:</Body1Strong> ליזום שיחת וידאו באמצעות Microsoft Teams.</li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>סמל שיחה:</Body1Strong> להתחיל שיחת קול באמצעות Microsoft Teams.</li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>סמל נוסף (...):</Body1Strong> לאפשרויות נוספות כמו לתזמן פגישה או להוסיף 
                        את פרטי הקשר שלהם לרשימת ה-Teams שלך.
                      </li>
                    </ul>
                    סמלים אלה מספקים שיטה חלקה ליצירת קשר ישירה עם מנהלי מחלקות מתוך TeamConnect, מבטיחים תקשורת יעילה בארגון שלך.
                  </Body1>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem value="27">
                <AccordionHeader>
                  <Body1Strong className={styles.fontColor}>
                    27. איך אפשר לצפות או ליצור קשר עם עובדים במחלקה?
                  </Body1Strong>
                </AccordionHeader>
                <AccordionPanel>
                  <Body1>
                    לגלות מי מי במחלקה ב-TeamConnect, פשוט חפשו את שלוש הנקודות ליד שם המחלקה. לחיצה על כך תפתח אפשרויות לפעולה נוספת:
                    <ul>
                      <li className={styles.bulletBottomMargin}><Body1Strong>צפייה בעובדים במחלקה זו:</Body1Strong> בחרו באפשרות זו כדי להציג רשימה של כל העובדים הקשורים למחלקה. המספר לצד אפשרות זו מציין כמה עובדים יש במחלקה, ומספק ספירה מהירה.
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>תרשים ארגוני של המחלקה:</Body1Strong> אם ברצונכם להבין את היררכיה של המחלקה,
                        בחרו 'תרשים ארגוני של המחלקה' כדי לחשוף את המבנה המבני, המציג את הקשר בין המחלקות האב, המחלקה בשאלה,
                        וכל תת-המחלקות. הייצוג הוויזואלי הזה עוזר לכם לנווט ולהבין את המסגרת הארגונית, והופך את זה לקל יותר לזהות וליצור קשר עם חברי מחלקה מסוימים.
                      </li>
                      <li><Body1Strong>מחלקה {'>'} עובד:</Body1Strong> לאחר צפייה בעובדים במחלקה, השתמשו בנתיב זה כדי לנווט בקלות חזרה לרשימת המחלקות, תוך שמירה על חווית חקירה חלקה ב-TeamConnect.
                      </li>
                    </ul>
                    התכונות אלו מאפשרות לכם לא רק לראות את רשימת העובדים במחלקה מסוימת אלא גם מציעות דרך ליצור קשר ישיר עם הם דרך כלי התקשורת של TeamConnect.
                  </Body1>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem value="28">
                <AccordionHeader>
                  <Body1Strong className={styles.fontColor}>
                    28. גילוי פרטים מעמיקים על מחלקות ב-TeamConnect
                  </Body1Strong>
                </AccordionHeader>
                <AccordionPanel>
                  <Body1>
                    חקרו את פרטי מחלקה כלשהי באמצעות לחיצה פשוטה על שמה ב-TeamConnect. דיאלוג מפורט נפתח, המציע רקמה עשירה של
                    מידע המצייר תמונה מלאה של תפקיד המחלקה ומשאביה בארגון שלכם. תמצאו הכל, החל מהנהגה ועד מזהים פיננסיים, ערוצי תקשורת וקישורים חברתיים. תכונה זו היא שערכם ל:
                    <ul>
                      <li className={styles.bulletBottomMargin}>לפעימת הלב של המחלקה – מטרתה, אנשיה ומדדי ביצועים.</li>
                      <li className={styles.bulletBottomMargin}>אמצעי יצירת קשר – קווים ישירים לדמויות מפתח במחלקה ולפלטפורמות תקשורת.</li>
                      <li className={styles.bulletBottomMargin}>האריג הארגוני – הבנה כיצד המחלקה משתלבת עם מבנה החברה הרחב יותר.</li>
                    </ul>
                    בין אם זו שאילתה מהירה או חקירה עמוקה של נתוני המחלקה, TeamConnect מצויד בכלים שיאפשרו לכם ליצור קשר עם ולהבין את האקוסיסטמות הייחודיות בארגון שלכם.
                  </Body1>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem value="29">
                <AccordionHeader>
                  <Body1Strong className={styles.fontColor}>
                    29. תהים לגבי היעדר כתובות מחלקות ב-TeamConnect?
                  </Body1Strong>
                </AccordionHeader>
                <AccordionPanel>
                  <Body1>
                    חיפוש אחר מיקום מחלקה יכול להרגיש לפעמים כמו מציאת אוצר בלי מפה. ב-TeamConnect, לרוב לא תמצאו כתובת מוקדשת לכל מחלקה. הנה מדוע פרט זה הגיאוגרפי לעיתים נשמט:
                    <ul>
                      <li className={styles.bulletBottomMargin}><Body1Strong>מיקומים מרובים:</Body1Strong> רבות מהמחלקות אינן מוגבלות למרחב אחד; הן פועלות במגוון משרדים וגאוגרפיות.
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>מבנה גמיש:</Body1Strong> נוף הארגוני משתנה תמיד, עם מחלקות שמתפתחות, מתמזגות או אף מתפרקות בהתאם לשינויים אסטרטגיים.
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>שיתוף פעולה בין מחלקתי:</Body1Strong> מרחבי העבודה המודרניים רועשים ברוח שיתופית, לעיתים מערבבים כישרונות ממחלקות שונות במרחבים משותפים.
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>תרבות עבודה מרחוק:</Body1Strong> עם הגמישות של עבודה מרחוק, עוגני המחלקות לא תמיד נופלים במיקומים פיזיים של משרדים.
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>ניואנסים משפטיים:</Body1Strong> מחלקות בדרך כלל מקיפות את מבנה החברה המשפטי ולא כוללות כתובות עצמאיות משלהן.
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>ניהול זריז:</Body1Strong> שמירה על מיקוד בעבודת צוות דינאמית, לא בכתובות סטטיות, עוזרת לשמור על זריזות הפעילות.
                      </li>
                    </ul>
                    זה כלל על אימוץ גישה ללא גבולות לעבודה ויעילות בצוות. TeamConnect משקף פילוסופיה זו על ידי הדגשת קשרים על פני מיקומים.
                  </Body1>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem value="30">
                <AccordionHeader>
                  <Body1Strong className={styles.fontColor}>
                    30. האם פרטי המחלקה שלך חסרים או מעודכנים?
                  </Body1Strong>
                </AccordionHeader>
                <AccordionPanel>
                  <Body1>
                    עורכי המחלקה מונהים בתוך הארגון שלך להוסיף, לעדכן או לתקן מידע ספציפי למחלקה ב-TeamConnect.
                    אם אינך בטוח מי הוא עורך המחלקה שלך או אם מידע מסוים דורש התערבות צוות התמיכה של משאבי אנוש,
                    אנא פנה אל <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> לקבלת הדרכה.
                  </Body1>
                </AccordionPanel>
              </AccordionItem>          
            </Accordion>
          </div>
          <p></p>
            <Subtitle2>שאלות נפוצות אודות מדריך המשרדים</Subtitle2>
              <Body1>
                <p>
                  <Body1Strong>הקדמה למדריך המשרדים:</Body1Strong> גלה את שלד הנוכחות הפיזית של הארגון שלך דרך מדריך המשרדים,
                    הכולל פרטים חיוניים על המשרדים, פרטים על הפעילות ומבט מקיף על המחלקות והאנשים שמגדירים כל מיקום.
                </p>
              </Body1>
            <div>
              <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
                <AccordionItem value="31">
                  <AccordionHeader>
                    <Body1Strong className={styles.fontColor}>
                      31. ניווט לפי תגיות במדריך המשרדים
                    </Body1Strong>
                  </AccordionHeader>
                  <AccordionPanel>
                    <Body1>
                      מצא את דרכך במדריך המשרדים בקלות באמצעות מערכת התגיות שלנו שתוכננה לגישה מהירה למיקום הנכון. הנה איך כל תג יכול לעזור לך להפשיט את החיפוש שלך:
                      <ul>
                        <li className={styles.bulletBottomMargin}><Body1Strong>הכל:</Body1Strong> מציג רישום מלא של המשרדים, ומספק מבט רחב על מיקומי הארגון שלך.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>מועדפים:</Body1Strong> עוזר לך להתמקד במיקומי המשרדים שאליהם אתה נוהג לבקר או צריך 
                          לשמור עליהם מרחוק, על ידי סימון שלהם כ'מועדפים'.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>המשרד שלך:</Body1Strong> תג זה מסנן את המדריך כדי להציג לך את מיקום המשרד 
                          בו אתה מוצב, ושומר את בסיס הבית שלך תמיד נגיש.
                        </li>
                      </ul>
                      בין אם אתה מזמין חדר ישיבות או מתכנן ביקור, תגיות אלה מציעות דרך אישית ומפושטת להתקשר עם הנוף הגאוגרפי של הארגון שלך.
                    </Body1>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="32">
                  <AccordionHeader>
                    <Body1Strong className={styles.fontColor}>
                      32. שליטה בתצוגות ספריית המשרדים ב-TeamConnect
                    </Body1Strong>
                  </AccordionHeader>
                  <AccordionPanel>
                    <Body1>
                      צלול אל תצורת הארגון של המיקומים השונים של החברה שלך באמצעות תצוגות ספריית המשרדים של TeamConnect. כל תצוגה מעוצבת כדי לקדם
                      היבט ייחודי של ניווט והבנת המשרד:
                      <ul>
                        <li className={styles.bulletBottomMargin}><Body1Strong>תצוגת רשימה:</Body1Strong> ההגדרה המוגדרת כברירת מחדל אשר מארגנת את כל מיקומי המשרד ברשימה
                          ברורה וקריאה.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>תצוגת כרטיס גדול:</Body1Strong> תצוגת כרטיס הגדולה בספריית המשרדים מעשירה את 
                          תצוגתך עם מצג של כל משרד בפירוט. כאשר אתה מתעסק עם כרטיס משרד, תמצא שפע של מידע ואפשרויות לפעולה
                          בתוך התפריט "עוד (...)" :
                          <ol>
                            <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>צפה במחלקות:</Body1Strong> נווט באופן ישיר לרשימה
                              של כל המחלקות הממוקמות בתוך המשרד.
                            </li>
                            <li className={styles.bulletBottomMargin}><Body1Strong>צפה בעובדים:</Body1Strong> גש לרשימה המלאה של העובדים המוקצים ל
                              משרד, תוך כדי קידום קשר ישיר או חקירה של צוות המשרד.
                            </li>
                            <li className={styles.bulletBottomMargin}><Body1Strong>תרשים ארגוני של ישות העסק:</Body1Strong> המחשת מקום המשרד בתוך ההקשר הארגוני הרחב יותר עבור הבנה ברורה של מבני היררכיה.
                            </li>
                            <li className={styles.bulletBottomMargin}><Body1Strong>אנשי קשר חיוניים:</Body1Strong> זיהוי אנשי הקשר המרכזיים במשרד לתקשורת
                              יעילה.
                            </li>
                            <li className={styles.bulletBottomMargin}><Body1Strong>חגים ציבוריים של המשרד:</Body1Strong> הישאר מודע לחגים הציבוריים הספציפיים למשרד כדי לתכנן את לוחות הזמנים שלך בהתאמה.
                            </li>
                            <li className={styles.bulletBottomMargin}><Body1Strong>שעות פעילות של המשרד:</Body1Strong> צפה בשעות הפעילות של המשרד כדי להתאים את ביקוריך או שיחותיך לזמנים הפעילים שלהם.
                            </li>
                          </ol>
                          תצוגה זו מספקת לא רק תמונה חזותית ומידע אלא גם גישה מהירה לתובנות עמוקות יותר ופעולות ישירות הקשורות למשרד, הכל מסודר תחת אפשרות ה"עוד (...)" לנוחותך.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>תצוגת תרשים ארגוני:</Body1Strong> המחשת מבנה ההיררכיה של המשרדים הראשיים והמשניים
                          כדי להבין את הקשרים בין מיקומי המשרדים השונים בארגון שלך.
                        </li>
                      </ul>
                      החלף בין תצוגות אלה באמצעות הסמלים ליד תיבת החיפוש כדי להתאים את חוויית הגלישה שלך לצרכיך המיידיים.
                    </Body1>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="33">
                  <AccordionHeader>
                    <Body1Strong className={styles.fontColor}>
                      33. מציאת משרדים במהירות עם תכונת החיפוש של TeamConnect
                    </Body1Strong>
                  </AccordionHeader>
                  <AccordionPanel>
                    <Body1>
                      מיקוד במיקום המשרד הנכון בתוך TeamConnect הופך לקל יותר באמצעות תכונת החיפוש המוקדשת. הממוקמת במקום בולט ליד 
                      סמלי התצוגה, תיבת החיפוש היא הכלי שלך למציאת משרדים לפי שם. ככל שאתה מקליד, המדריך משפר את התצוגה להציג רק משרדים התואמים 
                      לקלט שלך, מה שמקל על תהליך החיפוש שלך. חשוב להשתמש בתיבת החיפוש הזו ב-TeamConnect לתוצאות המדויקות והרלוונטיות ביותר,
                      בניגוד לחיפוש הכללי ב-Microsoft Teams. עם יכולת החיפוש הממוקדת הזו, אתה יכול לנווט במהירות לכל משרד ברשת הארגון שלך.
                    </Body1>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="34">
                  <AccordionHeader>
                    <Body1Strong className={styles.fontColor}>
                      34. ניווט אלפבתי במדריך המשרדים
                    </Body1Strong>
                  </AccordionHeader>
                  <AccordionPanel>
                    <Body1>
                      אם אתם מעדיפים לחפש לפי אלף בית, המסנן האלפבתי שלנו הוא דרך מהירה למצוא משרדים לפי שם. בחלק העליון של מדריך המשרדים, תוכלו 
                      לראות את האותיות א' עד ת'. לחיצה על אות מסננת את התצוגה כך שיוצגו רק המשרדים המתחילים באות זו, כך מפשטת את תהליך החיפוש שלכם.
                      כדי לאפס ולראות את כל המשרדים שוב, פשוט בטלו את בחירת האות הפעילה. תכונה זו שימושית במיוחד כאשר אתם מכירים את שם המשרד אך זקוקים 
                      לדרך מהירה לאתרו בתוך TeamConnect.
                    </Body1>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="35">
                  <AccordionHeader>
                    <Body1Strong className={styles.fontColor}>
                      35. חשיפת פרופילים מקיפים של משרדים ב-TeamConnect
                    </Body1Strong>
                  </AccordionHeader>
                  <AccordionPanel>
                    <Body1>
                      התחילו מסע חקירה דרך ליבה של נוכחות הארגון הפיזית עם פרופילי המשרדים של TeamConnect.
                      לחיצה על שם המשרד מציגה דיאלוג פרופיל תוסס, מלא בלשוניות עם תובנות מפורטות אודות המשרד:
                      <ul>
                        <li className={styles.bulletBottomMargin}><Body1Strong>סקירה כללית:</Body1Strong> צללו אל עומק הזהות של המשרד עם תיאורים מפורטים, 
                          ממבנה העסק ועד שעות פתיחה ושעה מקומית, תוך תיעוד מהות הסביבה המשרדית.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>אנשי קשר חיוניים:</Body1Strong> גישה לאנשי קשר חיוניים לשירותי חירום ותמיכה 
                          חיצונית, כל אחד רק לחיצה אחת דרך מערכת הטלפונים של Microsoft Teams.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>אנשי קשר במשרד:</Body1Strong> קשרו עם שירותי תמיכה פנימיים בקלות, עם פרטי קשר
                          ואפשרויות התערבות ישירות זמינות תמיד לכם.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>חגים ציבוריים:</Body1Strong> שמרו על עדכניות בנוגע לחגים הספציפיים למשרד, ווודאו 
                          שהתוכניות שלכם מתואמות עם האירועים המקומיים ולוחות הזמנים.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>שעות פעילות המשרד:</Body1Strong> תכננו את אינטראקציות שלכם עם ידע מדויק על מתי 
                          המשרד מתמלא בפעילות או מתרוקן לקראת סוף היום.
                        </li>
                      </ul>
                      בין אם זו אנרגיה תוססת של אוסטין או המרכז האסטרטגי של סינגפור, כל לשונית מציעה שער להבנה וליצירת קשר עם המקומות השונים 
                      שמרכיבים את הנוכחות הגלובלית של חברתכם.
                    </Body1>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="36">
                  <AccordionHeader>
                    <Body1Strong className={styles.fontColor}>
                      36. חקירת דינמיקות מחלקתיות בתוך משרד
                    </Body1Strong>
                  </AccordionHeader>
                  <AccordionPanel>
                    <Body1>
                      ניווט ברשת המקצועית של מחלקות במשרד מתבצע בקלות ב-TeamConnect. הנה איך לחקור את הפרטים:
                      <ul>
                        <li className={styles.bulletBottomMargin}><Body1Strong>צפה במחלקות במשרד זה:</Body1Strong> גלה את כל המחלקות המרכיבות את 
                          המבנה של המשרד על ידי בחירת אפשרות זו מתוך התפריט הנוסף (...).
                        </li>                      
                        <li className={styles.bulletBottomMargin}><Body1Strong>תובנה ארגונית:</Body1Strong> לחץ על כל מחלקה כדי לחשוף את תרשים הארגון שלה להצגה 
                          חזותית של מבנה המחלקה, עם מסלול הניווט המוביל אותך: משרד {'>'} מחלקה {'>'} תרשים.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>חקירה עמוקה של מחלקה:</Body1Strong> חקור יותר את המחלקה על ידי לחיצה על 
                          תפריט הנוסף (...) ליד שם המחלקה. כאן, אתה יכול לגשת לרשימה של כל העובדים במחלקה זו, ישירות בהקשר המשרד.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>ניווט במסלול:</Body1Strong> עבור בין השכבות בתור חלק מהמסע. מעובדים חזרה למחלקות או מתרשים הארגון, 
                          מסלול הניווט (משרד {'>'} מחלקה {'>'} עובד {'>'} תרשים) משמר את מסעך אינטואיטיבי ועל המסלול.
                        </li>
                      </ul>
                      הנתיבים המורכבים של TeamConnect מזמינים אותך לחקור, להבין ולהתערב בכל שכבה של מחלקות המשרד שלך, ונותנים לך שליטה ניווטית מקיפה.
                    </Body1>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="37">
                  <AccordionHeader>
                    <Body1Strong className={styles.fontColor}>
                      37. יצירת קשר עם עובדי המשרד ב-TeamConnect
                    </Body1Strong>
                  </AccordionHeader>
                  <AccordionPanel>
                    <Body1>
                      סקרן לדעת מי הפנים מאחורי המשרד? התשובה רק בלחיצת כפתור ב-TeamConnect. התערב עם האליפסה (שלוש נקודות) ליד שם המשרד שבחרת כדי לחשוף מגוון אפשרויות אינטראקטיביות:
                      <ul>
                        <li className={styles.bulletBottomMargin}><Body1Strong>צפה בעובדים במשרד זה:</Body1Strong> חשוף רשימה של כל המקצוענים 
                          שמכנים את המשרד הזה ביתם העבודתי. המספר המוצג מציע ספירה מהירה של העובדים, מזמין אותך לחקור את הקהילה במשרד.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>אפשרויות קשר:</Body1Strong> כל ערך של עובד כולל אייקונים לפעולה מהירה, 
                          מאפשר לך להתחיל שיחות, שיחות טלפון, או אפילו ועידות וידאו ישירות מ-TeamConnect, תומך בתקשורת בין-משרדית חלקה.
                        </li>
                        <li><Body1Strong>ניווט:</Body1Strong> כדי לחזור לרשימת המשרדים הרחבה יותר מרשימת העובדים, פשוט לחץ על 'משרד' במסלול הניווט 
                          (משרד {'>'} עובד), ותוחזר לסקירת המשרד.
                        </li>
                      </ul>
                      עם התכונות האלה, TeamConnect מקל על חיזוי התפלגות הכוח העובד במשרדים ומאפשר לך ליצור קשר בקלות,
                      תומך במקום עבודה מחובר ללא קשר למרחק.
                    </Body1>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem value="38">
                  <AccordionHeader>
                    <Body1Strong className={styles.fontColor}>
                      38. האם חסרים או עדכניים פרטי המשרד שלך?
                    </Body1Strong>
                  </AccordionHeader>
                  <AccordionPanel>
                    <Body1>
                      עדכון פרטי משרד ב-TeamConnect ניתן לטיפול על ידי המערכים המוקצים למשרד. אם אתה לא בטוח לגבי מערכי המשרד או 
                      זקוק לעזרה בנושאים הנמצאים תחת אחריות משאבי האנוש, צוות התמיכה של משאבי האנוש מוכן ל 
                      סייע ב- <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                    </Body1>
                  </AccordionPanel>
                </AccordionItem>          
              </Accordion>
            </div>
            <p></p>
              <Subtitle2>שאלות נפוצות אודות הגדרות</Subtitle2>
              <p></p>
              <div>
                <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
                  <AccordionItem value="39">
                    <AccordionHeader>
                      <Body1Strong className={styles.fontColor}>
                        39. חשיבות הגדרות כלליות מדויקות ב-TeamConnect
                      </Body1Strong>
                    </AccordionHeader>
                    <AccordionPanel>
                      <Body1>
                        בנוף הדינמי של סביבת העבודה היום, להישאר מחוברים ומתואמים הוא חשוב מתמיד. זו הסיבה שבה עדכון הגדרותיך הכלליות ב-TeamConnect משחק תפקיד מרכזי:
                        <ul>
                          <li className={styles.bulletBottomMargin}>
                            <Body1Strong>בחירת מקום עבודה:</Body1Strong> תעדכן את המהות של הגדרת מקום העבודה שלך כדי שתשקף את מצבך הנוכחי, אם זה מעבר משקט של המשרד הביתי אל ההמולה של 
                              המשרד הראשי, או אפילו התמקמות באזור ציבורי. בהירות זו בלוקיישן העבודה שלך מספקת לעמיתים לא רק הבנה איך הכי טוב ליצור איתך קשר, אלא גם את מהות 
                              השיחות שניתן להעלות איתך, תוך שמירה על סודיות של שיחות רגישות במיוחד כאשר אתה נמצא בסביבה הפתוחה של משרד לקוח או יושב בטרמינל בנמל התעופה.
                          </li>
                          <li className={styles.bulletBottomMargin}>
                            <Body1Strong>מפרט מקום העבודה:</Body1Strong> שיתוף עיר העבודה שלך עושה יותר מלגשר על פערים בצוות מרוחק—הוא גם 
                              פותח דלתות למפגשים מקצועיים ספונטניים או חברתיים קז'ואליים. אם זה קפה מהיר, ארוחת צהריים נינוחה, או פגישה שיתופית,
                              הידיעה שאתה נמצא באותה עיר יכולה להפוך יום רגיל להזדמנות לקשרים פנים אל פנים משמעותיים. זה לא רק מחזק קשרי צוות אלא גם מעניק הבנה עמוקה יותר של הניואנסים 
                              המקומיים שעשויים להשפיע על עבודתך.
                          </li>
                          <li className={styles.bulletBottomMargin}><Body1Strong>דיוק אזור הזמן:</Body1Strong> כאשר צוותים כוללים יבשות, חשוב לוודא שאזור הזמן שלך משקף 
                            את מיקומך הנוכחי כדי להרמונז תקשורות. זמן המקומי בפרופיל שלך ב-TeamConnect מושפע ישירות מההגדרה הזו, 
                            מבטל כל אי בהירות עבור עמיתים המנסים ליצור איתך קשר.
                          </li>
                        </ul>
                        צלול אל 'הגדרות' {'>'} 'הגדרות כלליות' בתוך TeamConnect לפינוי פרטים אלה. כמה רגעים של עדכון בהגדרות שלך יכולים 
                        לשפר משמעותית את התיאום והיעילות של מאמצי השיתוף פעולה שלך בכל הקשור.
                      </Body1>
                    </AccordionPanel>
                  </AccordionItem>            
                  <AccordionItem value="40">
                    <AccordionHeader>
                      <Body1Strong className={styles.fontColor}>
                        40. פענוח בחירת מקום עבודה ב-TeamConnect
                      </Body1Strong>
                    </AccordionHeader>
                    <AccordionPanel>
                      <Body1>
                        בחירת ההגדרה הנכונה בתפריט הנפתח של מקום העבודה מתאימה את TeamConnect לשקף את מצב העבודה הנוכחי שלך במדויק. רחף מעל סמל המידע להסבר מפורט של כל אופציה:
                        <ul>
                          <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Break:</Body1Strong> מציין זמן היעדרות מהעבודה, אם להפסקה או לסיום היום.</li>
                          <li className={styles.bulletBottomMargin}><Body1Strong>Office/Field-Office:</Body1Strong> מציין נוכחות במשרד שמנוהל על ידי החברה.</li>
                          <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> עבודה ממשרד ביתי מוקדש.</li>
                          <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Public-Area:</Body1Strong> עבודה מסביבה שאינה משרדית, מה שמצביע על צורך בתקשורת גמישה.</li>
                          <li className={styles.bulletBottomMargin}><Body1Strong>Customer Workplace/Private Office:</Body1Strong> נמצא באתר הלקוח, עם הערה על רמות הפרטיות לרגישות השיחה.</li>
                          <li className={styles.bulletBottomMargin}><Body1Strong>Commute/Business-Travel/Training:</Body1Strong> בתנועה או מעורב בפעילויות עבודה מסוימות מחוץ למשרד.</li>
                          <li className={styles.bulletBottomMargin}><Body1Strong>Public-Holiday/Vacation/Various Leaves:</Body1Strong> מחופש רשמית לסיבות אישיות, בריאותיות או חגים.</li>
                        </ul>
                        בחירה של הגדרת מקום עבודה מתאימה מבטיחה שהצוות שלך יוכל לקבל את ההקשר הנדרש לאינטראקציות, תוך כבוד לזמינות ולפרטיות.
                      </Body1>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem value="41">
                    <AccordionHeader>
                      <Body1Strong className={styles.fontColor}>
                        41. ניווט בסטטוס 'Checked-In' ב-TeamConnect
                      </Body1Strong>
                    </AccordionHeader>
                    <AccordionPanel>
                      <Body1>
                        התג 'Checked-In' במדריך העובדים מיועד להציג את כל העובדים שנמצאים כעת בעבודה. הנראות הזו נקבעת על פי בחירת מקום העבודה שלך:
                        <ul>
                          <li className={styles.bulletBottomMargin}><Body1Strong>Active Work Environments:</Body1Strong> הבחירות Office, Field-Office, Home-Office, Remote-Workplace, Public-Area, Customer-Workplace, 
                          Customer Private-Office, Commute, Business-Travel, ו-Training מציינות שאתה מעורב בעבודה, מה שמסמן אותך כ-'Checked-In' ופעיל לעיני עמיתיך.
                        </li>
                          <li className={styles.bulletBottomMargin}><Body1Strong>Non-Work Settings:</Body1Strong> בחירה ב-Checked-Out, Break, Public-Holiday, Vacation, On-Leave, Maternity-Leave, או Sick-Leave 
                            מסירה אותך מרשימת ה-'Checked-In', וקובעת ציפיות ברורות לזמינות וזמני תגובה.
                        </li>
                        </ul>
                        פונקציונליות זו עוזרת להבדיל בין תקופות עבודה לזמן אישי, תוך תרומה לשיתוף פעולה ותקשורת יעילה בצוות.
                      </Body1>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </div>
              <p></p>
              <Subtitle2>שאלות ותשובות בנושא ניהול נתונים ופרטיות</Subtitle2>
                <Body1>
                  <p>
                    בעידן הדיגיטלי של היום, להבין כיצד הנתונים שלך מנוהלים הוא קריטי. TeamConnect מחויבת לשקיפות ולשיטות הגנה מתקדמות על נתונים. 
                    להלן, תמצא מידע על כיצד אנו מטפלים, מאחסנים ומגנים על הנתונים שלך בתוך TeamConnect, ומבטיחים שהמרחב הדיגיטלי שלך מאובטח ועומד בתקנות.
                  </p>
                </Body1>        
              <div>
                <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
                  <AccordionItem value="42">
                    <AccordionHeader>
                      <Body1Strong className={styles.fontColor}>
                        42. כיצד TeamConnect מגנה על הנתונים האישיים שלי?
                      </Body1Strong>
                    </AccordionHeader>
                    <AccordionPanel>
                      <Body1>
                        <p>TeamConnect מחויבת להגנה על המידע האישי שלך באמצעות גישה מקיפה לאבטחת מידע:</p>
                        <ul>
                          <li className={styles.bulletBottomMargin}>
                            <Body1Strong>הזדהות יחידה (SSO):</Body1Strong> לשיפור האבטחה ולהקלה על חווית המשתמש, TeamConnect משלבת אימות 
                            אוטומטי של הזדהות יחידה עם חשבון Microsoft 365 שלך, מה שמקל על ניהול גישה בטוחה ונטולת מאמץ.
                          </li>
                          <li className={styles.bulletBottomMargin}>
                            <Body1Strong>הצפנה:</Body1Strong> אנו מבטיחים כי כל הנתונים בתוך TeamConnect מוצפנים גם במהלך העברתם וגם כאשר הם במצב שלא בשימוש, 
                            דבר שהופך אותם לבלתי נגישים לגורמים בלתי מורשים.
                          </li>
                          <li className={styles.bulletBottomMargin}>
                            <Body1Strong>מזעור נתונים:</Body1Strong> איסוף הנתונים שלנו מוגבל במחמירות למה שנחוץ לתפקוד של TeamConnect.
                            המידע היחיד על המשתמש שאנו שומרים הוא שמך המרכזי של משתמש (UPN) מ-Azure Entra ID, אשר עשוי להיראות כמו כתובת הדואר האלקטרוני של החברה שלך.
                          </li>
                          <li className={styles.bulletBottomMargin}>
                            <Body1Strong>גישה מוגבלת:</Body1Strong> הגישה ל-UPN מוגבלת למנהל המנוי של הארגון שלך בתוך פורטל המנויים שלנו, 
                            מה שמבטיח שמידע רגיש ניתן לצפייה רק על ידי אנשים מורשים.
                          </li>
                          <li className={styles.bulletBottomMargin}>
                            <Body1Strong>מחיקת נתונים מיידית:</Body1Strong> TeamConnect מובנית למחוק באופן מיידי את ה-UPN השמור בשלוש תנאים ספציפיים: 
                            אם המנוי בוטל על ידי מנהל המנוי, אם משתמש הוסר על ידי מחלקת המשאבים האנושיים בתוך TeamConnect, או אם המנוי ל-TeamConnect 
                            פג תוקף. זה מבטיח שהנתונים האישיים שלך לא יישמרו מעבר לתועלתם או הכרחם.
                          </li>                      
                        </ul>
                        <p>באמצעות מדידות אלו, TeamConnect מחויבת לשמירה על הסטנדרטים הגבוהים ביותר של הגנה על נתונים, ומבטיחה כי המידע האישי שלך יישאר בטוח וחסוי.</p>
                      </Body1>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem value="43">
                    <AccordionHeader>
                      <Body1Strong className={styles.fontColor}>
                        43. תוהה היכן נמצאים הנתונים שלך עם TeamConnect?
                      </Body1Strong>
                    </AccordionHeader>
                    <AccordionPanel>
                      <Body1>
                        TeamConnect שובר את התבנית של הצעות SaaS מסורתיות בכך שהוא מבטיח שהנתונים שלך לא יעזבו את הארגון שלך.
                        בניגוד לנוהג הרגיל של אחסון נתונים בשרתי ענן חיצוניים, TeamConnect מנצל בחוכמה את Microsoft 365 SharePoint Online של הארגון שלך כדי לאחסן את כל נתוני היישום.
                        גישה חדשנית זו לא רק מחזקת ריבונות נתונים אלא גם ממגנת את האבטחה,
                        ומתיישבת באופן מושלם עם המחויבות הנחרצת שלנו לשמירה על הפרטיות שלך ולציות לתקנות נוקשות.
                      </Body1>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem value="44">
                    <AccordionHeader>
                      <Body1Strong className={styles.fontColor}>
                        44. האם הנתונים שלי ישותפו עם גורמים שלישיים?
                      </Body1Strong>
                    </AccordionHeader>
                    <AccordionPanel>
                      <Body1>
                        לא, הנתונים לא משותפים עם גורמים שלישיים! Cubeet נחושה במחויבות שלנו לפרטיות ולאבטחה של הנתונים שלך.
                        אנו לא משתפים נתונים של TeamConnect עם גורמים שלישיים ללא קבלת הסכמה מפורשת מלקוחותינו.
                        בסביבתנו המאובטחת, אנו שומרים רק מזהים חיוניים, כמו שם משתמש ראשי (UPN), שם הארגון, שם הדייר, ומזהה הדייר,
                        בתוך פורטל המנוי שלנו. מידע זה חיוני להספקת השירות ומוגן תחת מדיניות הפרטיות הקפדנית שלנו.
                        הנהלת הנתונים שלנו מעוצבת כך שהנתונים שלך יישארו חסויים, עם שקיפות ובקרה שנמצאות בידיים שלך במלוא העוצמה.
                      </Body1>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem value="45">
                    <AccordionHeader>
                      <Body1Strong className={styles.fontColor}>
                        45. כיצד TeamConnect מסתדר עם תקנות הגנת נתונים כמו GDPR?
                      </Body1Strong>
                    </AccordionHeader>
                    <AccordionPanel>
                      <Body1>
                        TeamConnect מתיישר עם תקנות הגנת נתונים כמו ה-GDPR, HIPAA, CCPA ואחרות על ידי שימוש בתכונות התאימות הטבעיות של סביבת Microsoft 365,
                        שבה נשמרים כל נתוני הלקוחות באופן מאובטח. בניגוד לדגמים מסורתיים של SaaS, TeamConnect לא שומר נתוני לקוחות על השרתים שלו;
                        במקום זאת, הוא פועל בתוך סביבת Microsoft 365 של הארגון שלך, תוך ציות לתקנות האבטחה והתאימות החזקות שלה.
                        גישה זו מבטיחה ששליטת הנתונים שלך תישאר עקבית עם מסגרת התאימות המקיפה שמספקת Microsoft 365,
                        כולל ציות ל-GDPR, HIPAA למידע בריאותי, CCPA לתושבי קליפורניה,
                        ותקנות הגנת נתונים גלובליות אחרות. על ידי שילוב מושלם עם הקונפיגורציה הקיימת של Microsoft 365 שלך, TeamConnect מעניק לארגון שלך את היכולת לשמור על שליטה מלאה
                        בנתונים שלך, תוך הבטחת ציות לחוקי הגנת הנתונים המחמירים ביותר.
                      </Body1>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem value="46">
                      <AccordionHeader>
                        <Body1Strong className={styles.fontColor}>
                          46. איך TeamConnect מטפל בנתונים משיחות, שיחות וידאו, שיחות טלפון ומיילים של Outlook ב-Microsoft Teams?
                        </Body1Strong>
                      </AccordionHeader>
                      <AccordionPanel>
                        <Body1>
                          TeamConnect מנצל את Microsoft Graph API כדי לאפשר אינטראקציות ישירות כמו שיחת צ'אט, שיחות, שיחות וידאו ואימייל של Outlook בתוך Microsoft Teams,
                          כאשר מספק חוויה חלקה למשתמש. בעיקר, כאשר TeamConnect מתחיל את התקשורות הללו, הוא עושה זאת מבלי להתערב בתהליך התקשורת
                          או להיות בעל היכולת להקליט או לאחסן תוכן של אינטראקציה. כל השיחות, שיחות ומיילים מתקיימים ומנוהלים ישירות בתוך
                          המסגרות המאובטחות של Microsoft Teams ו-Outlook, בהתאם המחמיר לתקני האבטחה והפרטיות של Microsoft 365. זה מבטיח ש-TeamConnect מעצים
                          את יכולות התקשורת שלך תוך שמירה מלאה על חשאיות ושלמות הנתונים שלך.
                        </Body1>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
                <p></p>
                <Body1>
                  <p>
                    לקבלת תובנות מפורטות על מדיניות הנתונים והנהלים שלנו, תוכלו גם להתייעץ עם {' '}
                    <Link href={userGuideLink} target="_blank" rel="noreferrer">
                    <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>מדריך המשתמש של TeamConnect</Body1Strong></Link> או עם מחלקת ה-IT של ארגון 
                      שלכם ב- <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> לפרטים הקשורים להגדרות שלך.
                  </p>
                </Body1>        
                <p></p>  
              <Subtitle1 className={styles.fontColor}>אנחנו מקשיבים: שתפו אותנו במשוב וברעיונות שלכם</Subtitle1>
                <Body1>
                  <p>
                    התובנות שלכם מניעות את התפתחות TeamConnect. אם יש לכם רעיון מעולה לתכונה חדשה או משוב על החוויה הנוכחית שלכם, אנחנו כאן לשמיעה. התרומה שלכם חשובה לנו במשימה המתמשכת שלנו להציע את 
                    השירות הטוב ביותר.
                  </p>
                </Body1>      
              <Subtitle2>משוב והצעות לתכונות</Subtitle2>
                <Body1>
                  <p>
                    מוכנים לשתף? מעבר לדף {' '}
                    <Link 
                      style={{ fontWeight: 600 }} 
                      href={feebackLink}
                      target='_blank'>
                      דף המשוב
                    </Link>
                    . בין אם זו הצעה לתכונה חדשה או משוב על פונקציונליות קיימות, פשוט בחרו 
                    את האפשרות המתאימה מהתפריט הנפתח בטופס. התרומות שלכם עוזרות לעצב את עתיד TeamConnect, 
                    כדי שימשיך לעמוד בצרכים שלכם ולעלות עליהם.
                  </p>
                </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
