import { useState, useEffect } from 'react';
import { fetchSaasSubscriptionList } from '../../../services/saasService';

const RowItem = ({index, item}) => {
  return (
    <tr key={item?.id}>
      <td>{(index+1)}</td>
      <td>{`${item?.id.substring(0,13)}-XXXX-YYYY`}</td>
      <td>{item?.name}</td>
      <td>{item?.offerId}</td>
      <td>{item?.saasSubscriptionStatus}</td>
      <td>{item?.planId}</td>
      <td>{item?.quantity}</td>
      <td>{item?.purchaser?.emailId}</td>
      <td>{item?.term?.termUnit}</td>
      <td>{item?.created?.slice(0,19)}</td>
    </tr>
  )
};

const Table = ({ token }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionList, setSubscriptionList] = useState([]);

  const requestData = async (t1) => {
    try {
      setIsLoading(true);

      const res = await fetchSaasSubscriptionList(t1);
      if (res && res.data) {
        const { subscriptions } = res.data;
        setSubscriptionList(subscriptions);
      };

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    };
  };

  useEffect(() => {
    if (token) requestData(token);
  }, [token]);

  return (
    <div style={{ marginTop: '20px', marginBottom: '60px', marginLeft: '20px', marginRight: '20px' }}>
      {
        (isLoading) && <div>requesting...</div>
      }

      {
        (!isLoading && Array.isArray(subscriptionList) && subscriptionList.length > 0) &&
        <table>
          <tr>
            <th>No.</th>
            <th>ID</th>
            <th>Name</th>
            <th>Offer ID</th>
            <th>Status</th>
            <th>Plan ID</th>
            <th>Quantity</th>
            <th>Purchaser</th>
            <th>TermUnit</th>
            <th>Created</th>
          </tr>
          {
            subscriptionList.map((item, index) => {
              return <RowItem index={index} item={item} />
            })
          }
        </table>
      }

      {
        (!isLoading && Array.isArray(subscriptionList) && subscriptionList.length === 0) &&
        <div>No data</div>
      }
    </div>
  );
};

export default Table;