import React, {  } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { PageLayout } from '../../layouts/PageLayout';
import { Body1, Body1Strong } from "@fluentui/react-components";
import './home.css';
import Container from './container';
import Footer from '../../components/footer';

/**
* If a user is authenticated the Container component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
*/
const MainContent = () => {
  return (
    <div className="home">
      <AuthenticatedTemplate>
        <Container />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Body1>
          <center>
            Please sign in with your <Body1Strong>Microsoft Admin Account</Body1Strong> to register your subscription.
          </center>
        </Body1>
      </UnauthenticatedTemplate>
      <Footer />
    </div>
  );
};

export default function App() {
  return (
    <PageLayout>
      <center>
        <MainContent />
      </center>
    </PageLayout>
  );
};
