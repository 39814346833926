import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../config/authConfig';
import { getMeInfo } from '../../services/authService';
import { addLogin } from '../../services/loginService';
import { Loading, ScrollToTopButton } from '../../components';
import { Subtitle1, Label, Dropdown, Option } from '@fluentui/react-components';
import { LANGUAGES_LOCALIZATION } from '../../utils/common';
import { find } from 'lodash';
import info from '../../../package.json';
import Content from './content';
import AR_SA from './languages/ar_sa';
import AZ_AZ from './languages/az_az';
import CA_ES from './languages/ca_es';
import CY_GB from './languages/cy_gb';
import DA_DK from './languages/da_dk';
import DE_DE from './languages/de_de';
import EL_GR from './languages/el_gr';
import EN_GB from './languages/en_gb';
import EN_US from './languages/en_us';
import ES_ES from './languages/es_es';
import ES_US from './languages/es_us';
import FA_IR from './languages/fa_ir';
import FI_FI from './languages/fi_fi';
import FR_FR from './languages/fr_fr';
import GA_IE from './languages/ga_ie';
import HE_IL from './languages/he_il';
import HI_IN from './languages/hi_in';
import HR_HR from './languages/hr_hr';
import HU_HU from './languages/hu_hu';
import HY_AM from './languages/hy_am';
import ID_ID from './languages/id_id';
import IS_IS from './languages/is_is';
import IT_IT from './languages/it_it';
import JA_JP from './languages/ja_jp';
import KA_GE from './languages/ka_ge';
import KO_KR from './languages/ko_kr';
import LT_LT from './languages/lt_lt';
import LV_LV from './languages/lv_lv';
import MK_MK from './languages/mk_mk';
import MS_MY from './languages/ms_my';
import MT_MT from './languages/mt_mt';
import NL_NL from './languages/nl_nl';
import NO_NO from './languages/no_no';
import PL_PL from './languages/pl_pl';
import PT_PT from './languages/pt_pt';
import RO_RO from './languages/ro_ro';
import RU_RU from './languages/ru_ru';
import SK_SK from './languages/sk_sk';
import SL_SI from './languages/sl_si';
import SQ_AL from './languages/sq_al';
import SR_RS from './languages/sr_rs';
import SV_SE from './languages/sv_se';
import TH_TH from './languages/th_th';
import TR_TR from './languages/tr_tr';
import UK_UA from './languages/uk_ua';
import VI_VN from './languages/vi_vn';
import ZH_CN from './languages/zh_cn';
import ZH_TW from './languages/zh_tw';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const options = LANGUAGES_LOCALIZATION.map(item => {
  return {
    name: item.NAME,
    value: item.VALUE.toLocaleLowerCase(),
  };
});

const defaultLocale = LANGUAGES_LOCALIZATION[0];

const Container = () => {
  const query = useQuery();

  const lan = query.get('lan');
  const [localeValue, setLocaleValue] = useState('');
  const [localeName, setLocaleName] = useState('');

  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(true);
  const [meProfile, setMeProfile] = useState(null);

  const itSupport = info.email;
  const hrSupport = info.email;
  const feebackLink = 'https://cubeet.ai/feedback';
  const userGuideLink = 'https://cubeetapps.blob.core.windows.net/guides/cubeet-teamconnect-guide.pdf';

  const [openItems, setOpenItems] = useState(['0']);
  const handleToggle = (event, data) => {
    setOpenItems(data.openItems);
  };

  const closeAllAccordions = () => {
    setOpenItems([]);
  };

  const onDropDownChange = (event, value) => {
    const { optionValue, optionText } = value;
    setLocaleValue(optionValue);
    setLocaleName(optionText);
  };
  
  const requestProfileData = async (inst, acct) => {
    try 
    {
      setIsLoading(true);

      const resAccess = await inst.acquireTokenSilent({
        ...loginRequest,
        account: acct,
      });

      const { accessToken } = resAccess;
      const resMe = await (getMeInfo(accessToken));
      setMeProfile(resMe);

      await addLogin(resMe);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    };
  };

  useEffect(() => {
    const runApp = async () => {
      if (instance && Array.isArray(accounts) && accounts.length) {
        const account = accounts[0];
        return requestProfileData(instance, account);
      };
    };
    
    if (instance && accounts) runApp();
  }, [instance, accounts]);

  useEffect(() => {
    if (lan) {
      const oLan = find(LANGUAGES_LOCALIZATION, (item) => {
        if (item.VALUE.toLocaleLowerCase() === lan) return item;
      });
      if (oLan) {
        setLocaleValue(oLan.VALUE.toLocaleLowerCase());
        setLocaleName(oLan.NAME);
      } else {
        setLocaleValue(defaultLocale.VALUE.toLocaleLowerCase());
        setLocaleName(defaultLocale.NAME);
      };
    } else {
      setLocaleValue(defaultLocale.VALUE.toLocaleLowerCase());
      setLocaleName(defaultLocale.NAME);
    };
  }, [lan]);

  return (
    <>
      <Subtitle1 className='card-title'>
        Welcome {accounts[0].name}
      </Subtitle1>
      <br/>
      <p>{meProfile?.userPrincipalName}</p>

      <br/>
      
      {
        (isLoading || !meProfile) 
        ? <Loading text={'Initializing...'} />
        : null
      }

      {
        (!isLoading && meProfile)
        ? <div>
            <Label>{'Select language: '}</Label>
            <Dropdown
              name='dropdownLanguage'
              defaultValue={localeName}
              defaultSelectedOptions={[localeValue]}
              onOptionSelect={onDropDownChange}
              size='medium'
              listbox={{ style: { height: '35vh' }}}
            >
              {
                options.map((opt, index) => (
                  <Option key={index} text={opt.name} value={opt.value} disabled={opt.disabled}>
                    {opt.name}
                  </Option>
                ))
              }
            </Dropdown>
          </div>
        : null
      }

      <br/>
      {
          (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'ar-sa') 
          ? <AR_SA itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'az-az') 
          ? <AZ_AZ itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'ca-es') 
          ? <CA_ES itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'cy-gb') 
          ? <CY_GB itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'da-dk') 
          ? <DA_DK itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'de-de') 
          ? <DE_DE itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'el-gr') 
          ? <EL_GR itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'en-gb') 
          ? <EN_GB itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'en-gb') 
          ? <EN_GB itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'en-us') 
          ? <EN_US itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'es-es') 
          ? <ES_ES itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'es-us') 
          ? <ES_US itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'fa-ir') 
          ? <FA_IR itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'fi-fi') 
          ? <FI_FI itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'fr-fr') 
          ? <FR_FR itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'ga-ie') 
          ? <GA_IE itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'he-il') 
          ? <HE_IL itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'hi-in') 
          ? <HI_IN itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'hr-hr') 
          ? <HR_HR itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'hu-hu') 
          ? <HU_HU itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'hy-am') 
          ? <HY_AM itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'id-id') 
          ? <ID_ID itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'is-is') 
          ? <IS_IS itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'it-it') 
          ? <IT_IT itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'ja-jp') 
          ? <JA_JP itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'ka-ge') 
          ? <KA_GE itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'ko-kr') 
          ? <KO_KR itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'lt-lt') 
          ? <LT_LT itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'lv-lv') 
          ? <LV_LV itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'mk-mk') 
          ? <MK_MK itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'ms-my') 
          ? <MS_MY itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'mt-mt') 
          ? <MT_MT itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'nl-nl') 
          ? <NL_NL itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'no-no') 
          ? <NO_NO itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'pl-pl') 
          ? <PL_PL itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'pt-pt') 
          ? <PT_PT itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'ro-ro') 
          ? <RO_RO itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'ru-ru') 
          ? <RU_RU itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'sk-sk') 
          ? <SK_SK itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'sl-si') 
          ? <SL_SI itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'sq-al') 
          ? <SQ_AL itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'sr-rs') 
          ? <SR_RS itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'sv-se') 
          ? <SV_SE itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'th-th') 
          ? <TH_TH itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'tr-tr') 
          ? <TR_TR itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'uk-ua') 
          ? <UK_UA itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'vi-vn') 
          ? <VI_VN itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'zh-cn') 
          ? <ZH_CN itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile && localeValue.toLocaleLowerCase() === 'zh-tw') 
          ? <ZH_TW itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : (!isLoading && meProfile) 
          ? <Content itSupport={itSupport} hrSupport={hrSupport} feebackLink={feebackLink} userGuideLink={userGuideLink} openItems={openItems} handleToggle={handleToggle} />
        : null
      }
      
      <ScrollToTopButton closeAllAccordions={closeAllAccordions} />
    </>
  );
};

export default Container;