import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>Trung Tâm Hỗ Trợ & Câu Hỏi Thường Gặp TeamConnect</Title3>
        <p><Body1>
          Chào mừng đến với Trung Tâm Hỗ Trợ TeamConnect, được thiết kế để hỗ trợ trải nghiệm của bạn với ứng dụng danh bạ công ty toàn diện của chúng tôi. Tại đây, bạn sẽ tìm thấy các nguồn lực để tối ưu hóa việc sử dụng TeamConnect trong Microsoft Teams, tăng cường giao tiếp trên mạng lưới của bạn.
        </Body1></p>
        <Subtitle2>Hướng Dẫn Tham Khảo Nhanh</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Khả năng Tìm kiếm Nâng cao: </Body1Strong>
              Tìm đúng đồng nghiệp, bộ phận và liên hệ thiết yếu một cách nhanh chóng với công cụ tìm kiếm mạnh mẽ của chúng tôi.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Sơ đồ Tổ chức Trực quan: </Body1Strong>
              Dễ dàng điều hướng cấu trúc tổ chức và hiểu rõ các dòng báo cáo thông qua sơ đồ tổ chức trực quan.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Công cụ Thích ứng với Nhu cầu Hiện đại: </Body1Strong>
              Sử dụng các chức năng hiện đại được điều chỉnh theo yêu cầu của môi trường làm việc hiện đại.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Tối ưu Hóa Trải Nghiệm Của Bạn</Subtitle2>
        <Body1>
          <p>
            TeamConnect cách mạng hóa cách bạn tương tác trong cấu trúc tổ chức của mình, biến giao tiếp hàng ngày thành các cuộc hội ngộ chiến lược.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Nhận Hỗ Trợ & Câu Hỏi Thường Gặp</Subtitle1>
        <Body1>
          <p>
            Tìm câu trả lời cho các câu hỏi thường gặp về các tính năng và cài đặt của TeamConnect trong phần này. Dù bạn đang điều hướng ứng dụng, tùy chỉnh cài đặt hay tìm giải pháp cho các thách thức, Trung Tâm Hỗ Trợ của chúng tôi sẵn sàng hỗ trợ bạn một cách hiệu quả.
          </p>
        </Body1>
        <Body1>
          <p>
            Để được hỗ trợ sâu hơn hoặc hướng dẫn cụ thể, vui lòng tham khảo <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>Hướng Dẫn Sử Dụng TeamConnect</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>        
        <Subtitle2>Sử dụng Câu Hỏi Thường Gặp về TeamConnect</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Không tìm thấy TeamConnect trong thanh bên của Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nếu không thấy TeamConnect ngay lập tức hoặc nó biến mất trong thanh bên của Microsoft Teams, bạn có thể ghim nó lại để truy cập nhanh chóng. Chỉ cần làm theo các bước đơn giản sau:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Mở Microsoft Teams và nhấp vào biểu tượng '...' (+ Ứng dụng) ở dưới cùng của thanh bên.</li>
                    <li className={styles.bulletBottomMargin}>Tìm kiếm 'TeamConnect' trong thanh tìm kiếm ở đầu cửa sổ bật lên.</li>
                    <li className={styles.bulletBottomMargin}>Nhấp chuột phải vào ứng dụng TeamConnect từ kết quả tìm kiếm và chọn 'Ghim'.</li>
                  </ol>
                  TeamConnect sẽ được ghim vào thanh bên của bạn, giúp bạn có thể truy cập chỉ với một cái nhấp chuột!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Gặp sự cố kỹ thuật với TeamConnect? Đây là cách để nhận trợ giúp.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nếu bạn gặp bất kỳ vấn đề kỹ thuật nào với TeamConnect trong Microsoft Teams, đội hỗ trợ CNTT của tổ chức bạn sẵn sàng để giúp đỡ.
                  Liên hệ với họ tại <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, và họ sẽ hỗ trợ bạn ngay lập tức.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Không thấy thông tin nhân viên của bạn?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nếu hồ sơ của bạn cảm thấy hơi trống trải chỉ với tên và email của bạn, có thể là đội nhân sự của bạn chưa thêm các chi tiết nhân viên khác vào TeamConnect.
                  Không cần lo lắng! Một tin nhắn nhanh chóng đến đội hỗ trợ nhân sự của bạn sẽ khởi động mọi thứ. Họ sẵn sàng điền đầy đủ các thông tin quan trọng vào hồ sơ của bạn.
                  Hãy liên hệ tại <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> và xem hồ sơ của bạn trở nên sống động!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Các tùy chọn ngôn ngữ có sẵn trong TeamConnect là gì?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect ghi nhận sự đa dạng toàn cầu của Microsoft Teams bằng cách hỗ
                  trợ tất cả các ngôn ngữ của nó. Dù bạn ưa thích tiếng Ả Rập hay tiếng Hy
                  Lạp, tiếng Nhật hay tiếng Thụy Điển, bạn có thế giới tùy chọn ngay tại
                  ngón tay của mình, bao gồm:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Tiếng Ả Rập, Tiếng Bulgari, Tiếng Trung (Giản thể và Phồn thể), Tiếng
                      Croatia, Tiếng Séc, Tiếng Đan Mạch, Tiếng Hà Lan, Tiếng Anh, Tiếng
                      Estonia, Tiếng Phần Lan, Tiếng Pháp, Tiếng Đức, Tiếng Hy Lạp, Tiếng
                      Hebrew, Tiếng Hungary, Tiếng Indonesia, Tiếng Ý, Tiếng Nhật, Tiếng
                      Hàn, Tiếng Latvia, Tiếng Litva, Tiếng Na Uy (Bokmal), Tiếng Ba Lan,
                      Tiếng Bồ Đào Nha (Brazil và Bồ Đào Nha), Tiếng Romania, Tiếng Nga,
                      Tiếng Serbia (Latin), Tiếng Slovak, Tiếng Slovene, Tiếng Tây Ban Nha,
                      Tiếng Thụy Điển, Tiếng Thái, Tiếng Thổ Nhĩ Kỳ, Tiếng Ukraina, Tiếng
                      Việt.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Để tùy chỉnh trải nghiệm TeamConnect theo ngôn ngữ ưa thích của bạn, vui
                  lòng điều chỉnh cài đặt ngôn ngữ trực tiếp trong Microsoft Teams, vì
                  TeamConnect tích hợp đầy đủ với các cài đặt này:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Chuyển đến cài đặt Microsoft Teams của bạn bằng cách nhấp vào ba dấu
                      chấm cạnh ảnh đại diện của bạn ở đầu trang, rồi chọn 'Hình thức và khả
                      năng truy cập'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Cuộn đến mục 'Ngôn ngữ' và chọn ngôn ngữ ưa thích của bạn từ menu thả
                      xuống.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Xác nhận các thay đổi của bạn bằng cách nhấp vào nút 'Lưu và khởi động
                      lại' để áp dụng cài đặt ngôn ngữ mới.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Chú ý: </Body1Strong>
                <Body1>
                  Các thay đổi được thực hiện với cài đặt ngôn ngữ trong Microsoft Teams
                  cũng sẽ cập nhật trải nghiệm TeamConnect của bạn một cách liền mạch. Lưu ý
                  rằng việc thay đổi ngôn ngữ trong Teams cũng có thể điều chỉnh định dạng
                  thời gian và ngày của bạn. Hiện tại, TeamConnect đang phát triển để hỗ trợ
                  viết từ phải sang trái. Chúng tôi rất phấn khích về những cải tiến này và
                  sẽ tiếp tục thông báo cho bạn khi chúng trở nên khả dụng.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Làm thế nào để tôi thay đổi chủ đề trong TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect đồng bộ với các tùy chọn chủ đề của Microsoft Teams của bạn, bao gồm các lựa chọn Light, Dark và High Contrast. Để tùy chỉnh chủ đề của bạn:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Truy cập cài đặt Microsoft Teams của bạn bằng cách nhấp vào ba chấm cạnh ảnh đại diện của bạn ở trên cùng.</li>
                    <li className={styles.bulletBottomMargin}>Chọn 'Appearance and accessibility' để khám phá các tùy chọn chủ đề.</li>
                    <li className={styles.bulletBottomMargin}>Chọn chủ đề bạn ưa thích từ các tùy chọn có sẵn để cập nhật ngay lập tức giao diện TeamConnect của bạn cho phù hợp.</li>
                  </ol>
                  Lựa chọn chủ đề của bạn trong Microsoft Teams sẽ được tự động áp dụng cho TeamConnect, đảm bảo trải nghiệm thị giác nhất quán trên không gian làm việc của bạn.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Khám phá các nút hành động liên hệ của TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Trên khắp TeamConnect, ở bất cứ đâu bạn tìm thấy chi tiết liên lạc của nhân viên, bạn sẽ khám phá các nút truy cập nhanh tiện lợi của chúng tôi. Những công cụ hữu ích này
                  được thiết kế để tối ưu hóa giao tiếp của bạn trong hệ sinh thái Microsoft, đảm bảo bạn luôn chỉ cách một cú nhấp chuột để kết nối.
                  Dưới đây là hướng dẫn toàn diện của bạn để sử dụng hiệu quả các nút này:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Biểu tượng Chat:</Body1Strong> Nhấp để bắt đầu cuộc trò chuyện ngay lập tức với Microsoft Teams. Nếu bạn cần quay lại TeamConnect sau khi bắt đầu trò chuyện, chỉ cần sử dụng mũi tên quay lại trong Ứng dụng Desktop, nút quay lại trong Ứng dụng Di động, hoặc nút quay lại của trình duyệt của bạn.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Biểu tượng Email:</Body1Strong> Mở bản nháp email mới trong Outlook với địa chỉ người nhận sẵn sàng trong trường "To:" Nếu Outlook không khởi chạy, đáng để kiểm tra với bộ phận IT của bạn về bất kỳ hạn chế nào.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Biểu tượng Cuộc gọi video:</Body1Strong> Khởi động cuộc gọi video trực tiếp sử dụng Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Biểu tượng Cuộc gọi:</Body1Strong> Bắt đầu cuộc gọi thoại Microsoft Teams, làm cho cuộc thảo luận nhanh chóng trở nên liền mạch.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Biểu tượng Sơ đồ tổ chức:</Body1Strong> Hiển thị vị trí của nhân viên trong hệ thống phân cấp tổ chức, bao gồm người quản lý và các báo cáo trực tiếp của họ.</li>
                    <li><Body1Strong>Biểu tượng Thêm (...):</Body1Strong> Tiết lộ thêm các tùy chọn:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Gặp ngay:</Body1Strong> Mở Microsoft Teams để bắt đầu cuộc họp ngay lập tức với nhân viên.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Cuộc họp Mới:</Body1Strong> Đưa bạn đến Microsoft Teams để lên lịch cuộc họp mới.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Thêm liên hệ:</Body1Strong> Thêm chi tiết liên lạc của nhân viên từ TeamConnect vào ứng dụng People mới của Microsoft Teams, làm phong phú thêm chức năng gọi của bạn.</li>
                      </ul>
                    </li>
                  </ul>
                  Những nút trực quan này được tạo ra để đảm bảo bạn có thể kết nối hiệu quả với đồng nghiệp, cho dù là để trò chuyện nhanh, gửi email, hay để lên lịch các cuộc họp trong tương lai.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Thực hiện cuộc gọi thông qua TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Muốn thực hiện cuộc gọi? TeamConnect tối ưu hóa giao tiếp của bạn bằng cách tích hợp với Microsoft Teams.
                  Chỉ cần nhấp vào số điện thoại mong muốn trong TeamConnect, và voilà, giao diện gọi của Microsoft Teams hiện ra,
                  sẵn sàng cho bạn thực hiện cuộc gọi một cách liền mạch.
                  <p>
                  <Body1Strong>Lưu ý: </Body1Strong> 
                  Gặp sự cố khi thực hiện cuộc gọi? Điều này có thể do các chính sách IT của tổ chức bạn hoặc sử dụng hệ thống điện thoại khác ngoài Hệ thống Điện thoại Microsoft Teams. Liên hệ với bộ phận IT tại <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, có thể làm sáng tỏ vấn đề.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Có chi phí nào liên quan đến việc thực hiện cuộc gọi qua TeamConnect không?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect cải thiện trải nghiệm gọi của bạn bằng cách tận dụng Microsoft Teams. Đây là những gì bạn cần biết về chi phí liên quan đến cuộc gọi:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Đối với các cuộc gọi đến điện thoại công việc, điện thoại bộ phận, hoặc đường dây nóng trong tổ chức của bạn (số nội bộ),
                      thường thì <Body1Strong>không có chi phí</Body1Strong> khi sử dụng Hệ thống Điện thoại Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Đối với các cuộc gọi bên ngoài, như cuộc gọi đến điện thoại di động hoặc đường dây ngoài, <Body1Strong>giá cước chuẩn</Body1Strong> của hệ thống điện thoại tổ chức của bạn sẽ được áp dụng.
                      Mức phí này thay đổi tùy theo kế hoạch dịch vụ cụ thể của bạn với Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Lưu ý:</Body1Strong> Nếu bạn gặp bất kỳ vấn đề nào hoặc có thắc mắc về phí cuộc gọi, tốt nhất bạn nên tham khảo ý kiến từ bộ phận IT của bạn tại <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> để có thông tin chi tiết
                    phù hợp với cài đặt và chính sách của tổ chức bạn.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Phát hiện sự không khớp với trạng thái hiện diện 'Ngoài văn phòng' của bạn trong TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Bạn đã nhận thấy trạng thái hiện diện 'Ngoài văn phòng' từ Microsoft 365 của bạn không phản ánh chính xác trong TeamConnect?
                  Chúng tôi đã hỗ trợ bạn. Tính năng đồng bộ hóa trạng thái hiện diện 'Ngoài văn phòng' đã được triển khai trong TeamConnect.
                  Chúng tôi đang chờ Microsoft triển khai tính năng này. Khi điều đó xảy ra, trạng thái hiện diện 'Ngoài văn phòng' của bạn
                  sẽ được phản ánh chính xác trong TeamConnect, đồng bộ với các chỉ báo hiện diện của Microsoft Teams để mang lại trải nghiệm liền mạch.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>Câu Hỏi Thường Gặp về Danh Bạ Nhân Sự</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Giới thiệu Danh Bạ Nhân Sự:</Body1Strong> Khám phá bối cảnh chuyên nghiệp của tổ chức bạn với Danh Bạ Nhân Sự,
                trung tâm của bạn cho cái nhìn chi tiết về nhân viên, động lực nơi làm việc, và kết nối trực tiếp tới hồ sơ và mối quan hệ quản lý của đồng nghiệp.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Cần thêm, cập nhật hoặc sửa đổi dữ liệu nhân viên của bạn?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Để thêm, cập nhật hoặc sửa đổi dữ liệu nhân viên của bạn trong TeamConnect, vui lòng liên hệ với đội ngũ hỗ trợ nhân sự của tổ chức bạn.
                  Họ sẵn sàng hỗ trợ tại <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="11">
                <AccordionHeader>
                    <Body1Strong className={styles.fontColor}>
                        11. Điều gì xảy ra khi tôi nhấp vào tên của một nhân viên trong danh bạ?
                    </Body1Strong>
                </AccordionHeader>
                <AccordionPanel>
                    <Body1>
                        Nhấp vào tên của một nhân viên trong Danh bạ Nhân viên TeamConnect sẽ mở ra trang hồ sơ chi tiết của cá nhân đó. Hồ sơ này cung cấp cái nhìn toàn diện
                        về nhân viên, bao gồm nhưng không giới hạn, chức danh công việc, bộ phận, vị trí văn phòng, thông tin liên lạc, và nhiều hơn nữa. Nó được thiết kế để giúp bạn
                        hiểu rõ về vai trò chuyên môn của nhân viên và cách họ phù hợp với tổ chức, giúp bạn dễ dàng hiểu các trách nhiệm của họ và
                        cách bạn có thể hợp tác với họ.
                    </Body1>
                </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
                <AccordionHeader>
                    <Body1Strong className={styles.fontColor}>
                        12. Hồ sơ của một nhân viên bao gồm những thông tin gì?
                    </Body1Strong>
                </AccordionHeader>
                <AccordionPanel>
                    <Body1>
                        Mỗi hồ sơ nhân viên trong TeamConnect là bức tranh tổng thể chi tiết, cung cấp nhiều thông tin về đời sống chuyên nghiệp của cá nhân đó.
                        Dưới đây là những gì bạn sẽ tìm thấy:
                        <ul>
                            <li className={styles.bulletBottomMargin}><Body1Strong>Chi tiết cơ bản:</Body1Strong> Tên, chức danh công việc, bộ phận và vị trí công ty con.
                            </li>
                            <li className={styles.bulletBottomMargin}><Body1Strong>Cơ cấu báo cáo:</Body1Strong> Thông tin về báo cáo trực tiếp và chức năng,
                                bao gồm quản lý hàng dọc và chức năng.
                            </li>
                            <li className={styles.bulletBottomMargin}><Body1Strong>Tình trạng làm việc:</Body1Strong> Tình trạng làm việc hiện tại như Nghỉ phép, Đi lại,
                                Công tác, v.v.
                            </li>
                            <li className={styles.bulletBottomMargin}><Body1Strong>Địa điểm làm việc & Giờ địa phương:</Body1Strong> Địa điểm làm việc hiện tại và
                                múi giờ địa phương.
                            </li>
                            <li className={styles.bulletBottomMargin}><Body1Strong>Chi tiết liên lạc:</Body1Strong> Điện thoại công ty, số di động và địa chỉ email.</li>
                            <li className={styles.bulletBottomMargin}><Body1Strong>Địa chỉ văn phòng:</Body1Strong> Địa điểm văn phòng đầy đủ bao gồm tên tòa nhà,
                                địa chỉ và Google map.
                            </li>
                            <li className={styles.bulletBottomMargin}><Body1Strong>Giới thiệu:</Body1Strong> Giới thiệu cá nhân ngắn gọn hoặc tiểu sử chuyên nghiệp.</li>
                            <li className={styles.bulletBottomMargin}><Body1Strong>Quan hệ nhân viên:</Body1Strong> Chi tiết về cơ cấu báo cáo và quan hệ nhóm của nhân viên.
                            </li>
                            <li className={styles.bulletBottomMargin}><Body1Strong>Mạng xã hội & Ngôn ngữ:</Body1Strong> Liên kết đến hồ sơ xã hội chuyên nghiệp
                                và trình độ ngoại ngữ.
                            </li>
                            <li className={styles.bulletBottomMargin}><Body1Strong>Kỹ năng:</Body1Strong> Danh sách các kỹ năng chuyên môn và lĩnh vực chuyên môn.</li>
                        </ul>
                        Ngoài ra, hồ sơ cung cấp các tùy chọn có thể thực hiện để kết nối qua chat, email, cuộc gọi video, hoặc lên lịch cuộc họp, cùng với
                        quyền truy cập nhanh vào sơ đồ tổ chức để hiểu rõ hơn về bối cảnh nhóm rộng lớn hơn.
                    </Body1>
                </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Làm cách nào để cập nhật thông tin hồ sơ của tôi?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Việc cá nhân hóa hồ sơ của bạn trong TeamConnect rất dễ dàng. Dưới đây là cách bạn có thể làm:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Chuyển đến tab <Body1Strong>Employee</Body1Strong> và nhấp vào <Body1Strong>Avatar</Body1Strong> của bạn để mở hồ sơ cá nhân.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Ở góc trên bên trái, bạn sẽ thấy liên kết <Body1Strong>"Chỉnh sửa hồ sơ của bạn"</Body1Strong>. 
                        Nhấp vào đây sẽ mở hộp thoại chỉnh sửa hồ sơ của bạn.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Tại đây, bạn có thể nâng cao hồ sơ của mình bằng cách thêm hoặc cập nhật phần <Body1Strong>About You</Body1Strong>, các liên kết mạng xã hội, và số điện thoại.
                        Điều này giúp cho đồng nghiệp của bạn dễ dàng kết nối hoặc theo dõi bạn hơn.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Đừng quên nhấn nút <Body1Strong>Save</Body1Strong> để áp dụng các thay đổi của bạn.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Cuộn xuống trong hộp thoại để thêm <Body1Strong>Languages</Body1Strong> và <Body1Strong>Proficiency</Body1Strong> của bạn,
                        cũng như <Body1Strong>Skills</Body1Strong> của bạn. Việc chia sẻ thông tin này giúp đồng nghiệp của bạn hiểu rõ hơn về 
                        khu vực chuyên môn và ngôn ngữ mà bạn có thể giao tiếp.
                      </li>
                    </ol>
                  Cập nhật hồ sơ của bạn không chỉ giữ cho thông tin của bạn được cập nhật mà còn thúc đẩy mối quan hệ tốt hơn trong tổ chức của bạn.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Cập nhật Nơi Làm Việc, Workspot, và Múi Giờ trong TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Việc giữ thông tin nơi làm việc, workspot và múi giờ được cập nhật thường xuyên là rất quan trọng cho sự hợp tác liền mạch trong Microsoft Teams.
                  Những cập nhật này được phản ánh ngay lập tức trong hồ sơ TeamConnect và danh sách của bạn, cho phép đồng nghiệp hiểu nhanh chóng về vị trí làm việc của bạn
                  và thời điểm tốt nhất để kết nối với bạn, tăng cường tinh thần làm việc nhóm giữa các khu vực và múi giờ khác nhau.
                  <p></p>
                  Sẵn sàng cập nhật thông tin của bạn? Rất đơn giản trong TeamConnect! Hãy đi đến 'Settings' trong Menu trên cùng và điều hướng đến 'General Settings'.
                  Tại đây, bạn có thể dễ dàng cập nhật Nơi Làm Việc và Múi Giờ, và chỉ định Workspot cho ngày hôm đó. Nhớ lưu các thay đổi của bạn để 
                  mọi người được thông báo và đảm bảo sự hợp tác hiệu quả, không kể vị trí của bạn ở đâu!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Phát hiện điều gì đó không ổn trong hồ sơ của đồng nghiệp?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nếu bạn phát hiện bất kỳ thông tin nào trong hồ sơ của đồng nghiệp mà có vẻ không chính xác, bước đầu tiên tốt nhất là nên nhắc nhở họ một cách thân thiện.
                  Một cuộc trò chuyện nhanh hoặc email là đủ! Nếu vì lý do nào đó thông tin không được cập nhật, đừng lo lắng—chỉ cần liên hệ với đội ngũ hỗ trợ nhân sự của bạn tại <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, và họ sẽ sẵn lòng
                  can thiệp và giải quyết vấn đề.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Hiểu về Nhóm Thẻ: Tất cả, Ưa thích, Đã Check-In, & Nhân viên Mới
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tất cả:</Body1Strong> Thẻ mặc định này hiển thị tất cả nhân viên trong tổ chức của bạn,
                      cung cấp cái nhìn toàn diện về đội ngũ.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ưa thích:</Body1Strong> Nhấp vào đây để lọc chế độ xem của bạn cho những đồng nghiệp mà bạn đã đánh dấu
                      là 'Ưa thích,' giúp dễ dàng kết nối nhanh chóng với các đầu mối quan trọng.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Đã Check-In:</Body1Strong> Thẻ này chỉ ra nhân viên đang hoạt động tại nơi làm việc, phân biệt
                      với chỉ online trên Microsoft Teams. Khi đã check in, nhân viên báo hiệu rằng họ đang tại công và chia sẻ vị trí làm việc hiện tại
                      trong ngày. Điều này có thể bao gồm nhiều loại nơi làm việc như Văn phòng, Văn phòng Chi nhánh, Văn phòng Tại nhà, Nơi làm việc Từ xa, Nơi làm việc của Khách hàng,
                      Văn phòng Riêng của Khách hàng, Khu vực Công cộng, Di chuyển, Đi công tác, hoặc thậm chí Đào tạo. Do đó, tình trạng 'Đã Check-In' cung cấp một chỉ dấu rõ ràng
                      về việc đang tích cực làm việc, giúp đồng nghiệp hiểu chính xác về bối cảnh công việc và khả năng sẵn có của nhau.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nhân viên Mới:</Body1Strong> Nhận diện gương mặt mới trong TeamConnect với thẻ này, nổi bật
                      nhân viên đã được thêm vào ứng dụng trong vòng 30 ngày qua. Thông thường, điều này phù hợp với những bổ sung gần đây cho tổ chức của bạn, giúp
                      bạn chào đón các thành viên mới trong đội.
                    </li>
                  </ul>
                  Các nhóm thẻ này được thiết kế để tinh giản việc điều hướng và nâng cao trải nghiệm TeamConnect của bạn, đảm bảo bạn luôn có thể
                  tìm và kết nối với đồng nghiệp một cách hiệu quả.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Giải mã các biểu tượng bên cạnh hộp tìm kiếm
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Danh bạ Nhân viên của TeamConnect được thiết kế để linh hoạt và dễ sử dụng, bao gồm bốn biểu tượng cung cấp các chế độ xem khác nhau
                  và truy cập nhanh chóng vào các chi tiết quan trọng. Sau đây là những gì từng biểu tượng tiết lộ:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chế độ Xem Danh sách:</Body1Strong> Chế độ xem mặc định của bạn, trình bày tất cả các thành viên tổ chức
                      trong một danh sách đơn giản.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chế độ Xem Thẻ Nhỏ:</Body1Strong> Một lựa chọn gọn gàng, chế độ xem này biến danh sách
                      thành các thẻ nhân viên cỡ nhỏ.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chế độ Xem Thẻ Lớn:</Body1Strong> Lựa chọn cho nhiều chi tiết hơn với các thẻ lớn hơn, bao gồm
                      các tùy chọn liên hệ bổ sung như số điện thoại và số di động để dễ dàng gọi điện.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sơ đồ Tổ chức:</Body1Strong> Hình dung cấu trúc công ty với chế độ xem Sơ đồ Tổ chức,
                      hoàn hảo để hiểu các mối quan hệ báo cáo và điều hướng cảnh quan tổ chức.</li>
                  </ol>
                    Chuyển đổi giữa các biểu tượng này để tìm chế độ xem phù hợp nhất với nhu cầu của bạn tại thời điểm đó, cho dù bạn đang tìm kiếm thông tin nhanh chóng hay các thông tin sâu rộng hơn.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Để tìm kiếm đồng nghiệp nhanh chóng, tôi nên nhìn ở đâu?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Bạn đang tìm kiếm một đồng nghiệp? Chỉ cần đến hộp tìm kiếm TeamConnect, nằm ở phía bên phải phía trên cạnh biểu tượng Sơ đồ tổ chức.
                  Bắt đầu nhập tên họ, và xem kết quả được tinh chỉnh với mỗi chữ cái. Nhớ rằng, cho các tìm kiếm TeamConnect, hãy bỏ qua thanh tìm kiếm Microsoft Teams 
                  ở trên cùng—hộp tìm kiếm đặc biệt của chúng tôi là cách để đi!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Tính năng tìm kiếm theo bảng chữ cái hoạt động như thế nào để tìm nhân viên?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Danh bạ nhân viên của TeamConnect bao gồm một tính năng tìm kiếm theo bảng chữ cái tiện lợi để giúp bạn nhanh chóng tìm kiếm nhân viên theo tên đầu tiên.
                  Chỉ cần nhấp vào một chữ cái từ bảng chữ cái được hiển thị ở đầu danh bạ, và TeamConnect sẽ lập tức hiển thị tất cả nhân viên
                  có tên đầu tiên bắt đầu bằng chữ cái đó. Để quay trở lại danh sách đầy đủ các nhân viên, chỉ cần bỏ chọn chữ cái đã được làm nổi bật. Tính năng trực quan này
                  giúp điều hướng qua các danh sách nhân viên lớn dễ dàng, đảm bảo bạn có thể tìm kiếm đồng nghiệp một cách nhanh chóng và hiệu quả.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Làm thế nào để điều chỉnh số lượng nhân viên hiển thị trên mỗi trang?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect cho phép bạn tùy chỉnh số lượng hồ sơ nhân viên bạn xem trên mỗi trang, làm cho việc điều hướng qua Danh bạ Nhân viên dễ dàng hơn.
                  Ở cuối mỗi trang, bạn sẽ tìm thấy các tùy chọn phân trang: 10, 50, 100, 500, 1000 và 5000. Cài đặt mặc định hiển thị 10 hồ sơ mỗi trang.
                  Để xem nhiều hồ sơ hơn trên một trang, chỉ cần chọn số lượng ưa thích của bạn từ các tùy chọn này. Tính năng này cung cấp sự linh hoạt trong cách bạn duyệt
                  danh bạ, cho phép bạn tùy chỉnh hiển thị theo nhu cầu hoặc sở thích cụ thể của bạn.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Làm thế nào để sắp xếp thông tin nhân viên trong Chế độ Xem Danh sách?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Trong Chế độ Xem Danh sách Danh bạ Nhân viên của TeamConnect, bạn có sự linh hoạt để sắp xếp thông tin nhân viên theo nhu cầu của bạn. Các cột—Tên Nhân viên,
                  Chức danh, Phòng ban, Địa điểm Văn phòng và Nơi làm việc—đều có thể sắp xếp được. Chỉ cần nhấp vào tiêu đề cột bạn muốn sắp xếp. Nhấp một lần sẽ sắp xếp
                  cột theo thứ tự tăng dần và nhấp lại sẽ sắp xếp theo thứ tự giảm dần. Theo mặc định, danh bạ được sắp xếp theo Tên Nhân viên theo thứ tự tăng dần,
                  dựa trên tên đầu tiên. Tính năng sắp xếp này giúp bạn nhanh chóng tổ chức và xem chi tiết nhân viên một cách phù hợp nhất với mục đích của bạn, 
                  cho dù bạn đang tìm kiếm một người cụ thể hoặc cần nhóm các nhân viên theo tiêu chí nhất định như phòng ban hoặc địa điểm.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Câu hỏi thường gặp về Danh bạ Phòng ban</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Giới thiệu Danh bạ Phòng ban:</Body1Strong> Danh bạ Phòng ban là cánh cửa của bạn để hiểu rõ cấu trúc phức tạp của tổ chức, cung cấp cái nhìn rõ ràng về cấp bậc phòng ban, lãnh đạo quản lý, và các kênh giao tiếp phòng ban liền mạch.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Các thẻ 'Tất cả', 'Yêu thích', và 'Phòng ban của Bạn' trong Danh bạ Phòng ban hoạt động như thế nào?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Trong Danh bạ Phòng ban của TeamConnect, các thẻ giúp bạn dễ dàng điều hướng và lọc thông tin phòng ban. Dưới đây là ý nghĩa của từng thẻ:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tất cả:</Body1Strong> Thẻ này hiển thị tất cả các phòng ban trong tổ chức của bạn, cung cấp cái nhìn toàn diện.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Yêu thích:</Body1Strong> Sử dụng thẻ này để xem danh sách các phòng ban bạn đã đánh dấu là 'Yêu thích', giúp dễ dàng truy cập các phòng ban bạn thường xuyên tương tác.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Phòng ban của Bạn:</Body1Strong> Thẻ này lọc để chỉ hiển thị phòng ban của bạn, cho phép truy cập nhanh đến thông tin và cập nhật phòng ban trực tiếp.</li>
                  </ul>
                  Các thẻ này được thiết kế để tối ưu hóa việc điều hướng trong Danh bạ Phòng ban, nâng cao khả năng tìm kiếm và tương tác với thông tin phòng ban một cách hiệu quả.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Hiểu về các Chế độ Xem Danh bạ Phòng ban trong TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Việc điều hướng qua Danh bạ Phòng ban trong TeamConnect được tối ưu hóa với nhiều chế độ xem để phù hợp với sở thích của bạn. Mỗi chế độ xem mang lại một cách khác nhau để khám phá thông tin phòng ban toàn diện, bao gồm Tên phòng ban, Trưởng phòng, Phòng ban Cấp trên, Điện thoại phòng ban, Đường dây nóng phòng ban, và Email phòng ban. Dưới đây là cái nhìn tổng quan về các chế độ xem khả dụng:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chế độ Xem Danh sách:</Body1Strong> Ứng dụng mặc định này liệt kê gọn gàng tất cả các phòng ban, cung cấp cái nhìn rõ ràng, ngắn gọn.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chế độ Xem Thẻ Lớn:</Body1Strong> Chuyển sang chế độ này để xem các thẻ phòng ban chi tiết, cung cấp tổng quan mở rộng về thông tin chính của từng phòng ban.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chế độ Xem Sơ đồ Tổ chức:</Body1Strong> Để có cái nhìn trực quan về cấu trúc và mối quan hệ giữa các phòng ban, chế độ xem Sơ đồ Tổ chức minh họa cách mỗi phòng ban phù hợp với cấu trúc tổ chức rộng lớn hơn.
                    </li>
                  </ul>
                  Để chuyển đổi giữa các chế độ xem này, chỉ cần sử dụng các biểu tượng bên cạnh hộp tìm kiếm ở đầu Danh bạ Phòng ban. Mỗi chế độ xem được thiết kế để giúp bạn tiếp cận và hiểu rõ các chi tiết phòng ban một cách dễ dàng, đảm bảo bạn tìm thấy thông tin bạn cần trong định dạng phù hợp nhất với bạn.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Cách sử dụng hộp tìm kiếm trong Danh bạ Phòng ban
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Dễ dàng tìm kiếm bất kỳ phòng ban nào trong TeamConnect bằng hộp tìm kiếm chuyên dụng, được đặt ngay cạnh các biểu tượng xem ở đầu Danh bạ Phòng ban. Chỉ cần bắt đầu nhập tên phòng ban bạn muốn tìm, và quan sát danh bạ tự động lọc ra chỉ các kết quả phù hợp. Tính năng này được thiết kế riêng để giúp bạn bỏ qua việc phải lọc thủ công toàn bộ danh bạ, đưa phòng ban bạn cần ngay đến tầm tay. Nhớ rằng, chức năng tìm kiếm này đặc biệt dành cho Danh bạ Phòng ban của TeamConnect, vì vậy hãy sử dụng hộp tìm kiếm này thay vì tìm kiếm chính của Microsoft Teams ở đầu giao diện Teams cho các truy vấn liên quan đến phòng ban.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Bộ lọc theo thứ tự chữ cái trong Danh bạ Phòng ban hoạt động như thế nào?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tối ưu hóa việc tìm kiếm của bạn trong Danh bạ Phòng ban với bộ lọc theo thứ tự chữ cái được đặt ở đầu. Tính năng này cho phép bạn nhanh chóng thu hẹp các phòng ban bằng cách chọn một chữ cái. Khi bạn chọn một chữ cái, TeamConnect lọc và chỉ hiển thị những phòng ban có tên bắt đầu với chữ cái bạn đã chọn, giúp bạn dễ dàng tìm thấy những gì mình đang tìm kiếm mà không cần cuộn qua toàn bộ danh sách. Để quay lại xem toàn bộ phòng ban, chỉ cần bỏ chọn chữ cái đã được đánh dấu. Trình điều hướng theo chữ cái này đảm bảo bạn có thể tìm kiếm hiệu quả bất kỳ phòng ban nào theo chữ cái đầu tiên của nó.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Làm thế nào để liên hệ với người quản lý phòng ban?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Việc liên hệ với người quản lý phòng ban trong TeamConnect là rất đơn giản. Chỉ cần tìm đến phòng ban bạn quan tâm và nhấp vào tên người quản lý. Hành động này sẽ kích hoạt một hộp thoại hiển thị các tùy chọn liên lạc của người quản lý với các biểu tượng truy cập nhanh quen thuộc:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Biểu tượng Chat:</Body1Strong> Để bắt đầu một tin nhắn tức thời qua chat Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Biểu tượng Email:</Body1Strong> Để mở một email mới gửi đến họ trong Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Biểu tượng Cuộc gọi Video:</Body1Strong> Để khởi động một cuộc gọi video qua Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Biểu tượng Cuộc gọi:</Body1Strong> Để bắt đầu một cuộc gọi thoại sử dụng Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Biểu tượng Thêm (...):</Body1Strong> Để xem thêm các tùy chọn như lên lịch họp hay thêm thông tin liên lạc của họ vào danh sách Teams của bạn.
                    </li>
                  </ul>
                  Các biểu tượng này cung cấp một phương thức liền mạch để liên hệ trực tiếp với các quản lý phòng ban từ trong TeamConnect, đảm bảo giao tiếp hiệu quả trong tổ chức của bạn.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Làm cách nào để xem hoặc liên hệ với nhân viên trong một bộ phận?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Để khám phá ai là ai trong một bộ phận trong TeamConnect, hãy nhìn vào ba chấm cạnh tên bộ phận. Nhấp vào đây sẽ hiện ra các tùy chọn cho các hành động tiếp theo:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Xem Nhân viên trong Bộ phận này:</Body1Strong> Chọn này để hiển thị danh sách tất cả nhân viên liên quan đến bộ phận. Số bên cạnh tùy chọn này cho biết có bao nhiêu nhân viên trong bộ phận, cung cấp số lượng nhanh chóng.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sơ đồ Tổ chức Bộ phận:</Body1Strong> Nếu bạn muốn hiểu rõ hệ thống phân cấp của bộ phận,
                      chọn 'Sơ đồ tổ chức bộ phận' để tiết lộ cấu trúc tổ chức, trưng bày mối quan hệ giữa các bộ phận mẹ, bộ phận đang xét và các bộ phận phụ. Biểu đồ này giúp bạn dễ dàng điều hướng và hiểu khung tổ chức, từ đó dễ dàng xác định và liên hệ với các thành viên cụ thể trong bộ phận.
                    </li>
                    <li><Body1Strong>Bộ phận {'>'} Nhân viên:</Body1Strong> Sau khi xem các nhân viên trong một bộ phận, sử dụng dấu vết này để dễ dàng quay lại danh sách bộ phận, duy trì trải nghiệm khám phá mượt mà trong TeamConnect.
                    </li>
                  </ul>
                  Những tính năng này cho phép bạn không chỉ xem danh sách nhân viên trong một bộ phận nhất định mà còn cung cấp con đường để liên hệ trực tiếp với họ thông qua các công cụ giao tiếp của TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Khám phá Chi tiết Sâu rộng về Bộ phận trong TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Đi sâu vào chi tiết của bất kỳ bộ phận nào với một cú nhấp chuột vào tên của nó trong TeamConnect. Một cuộc đối thoại chi tiết bắt đầu, mang đến một bức tranh phong phú về thông tin mà nói lên toàn bộ vai trò và nguồn lực của bộ phận trong tổ chức của bạn. Bạn sẽ tìm thấy mọi thứ từ lãnh đạo và nhận dạng tài chính cho đến kênh giao tiếp và liên kết xã hội. Tính năng này là cánh cửa của bạn để:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Nhịp đập của bộ phận - mục đích, con người, và các chỉ số hiệu suất.</li>
                    <li className={styles.bulletBottomMargin}>Các đường dây liên lạc - đường dây trực tiếp đến các nhân vật quan trọng trong bộ phận và các nền tảng giao tiếp.</li>
                    <li className={styles.bulletBottomMargin}>Mạng lưới tổ chức - hiểu cách bộ phận tích hợp với cấu trúc công ty rộng lớn hơn.</li>
                  </ul>
                  Cho dù đó là một câu hỏi nhanh chóng hay một cuộc khảo sát sâu rộng về dữ liệu bộ phận, TeamConnect trang bị cho bạn các công cụ để kết nối với và hiểu các hệ sinh thái độc đáo trong tổ chức của bạn.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Tò mò về Việc Thiếu Địa chỉ Bộ phận trong TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Việc tìm kiếm địa điểm của một bộ phận đôi khi có thể cảm thấy giống như một cuộc săn tìm kho báu không có bản đồ. Trong TeamConnect, bạn thường không tìm thấy địa chỉ cụ thể cho từng bộ phận. Dưới đây là lý do tại sao chi tiết bản đồ này thường được bỏ qua:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nhiều Địa điểm:</Body1Strong> Nhiều bộ phận không bị giới hạn ở một không gian duy nhất; chúng phát triển qua nhiều văn phòng và địa lý.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Cấu trúc Linh hoạt:</Body1Strong> Cảnh quan tổ chức luôn thay đổi, với các bộ phận phát triển, sáp nhập hoặc thậm chí là tan rã phù hợp với các thay đổi chiến lược.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Hợp tác Liên bộ phận:</Body1Strong> Các không gian làm việc hiện đại đang sống động với tinh thần hợp tác, thường xuyên kết hợp tài năng từ các bộ phận khác nhau trong các không gian chung.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Văn hóa Làm việc Từ xa:</Body1Strong> Với sự linh hoạt của làm việc từ xa, các neo đậu bộ phận không luôn luôn được thả tại các vị trí văn phòng vật lý.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nuôi dưỡng Pháp lý:</Body1Strong> Các bộ phận thường quay quanh cấu trúc pháp lý của công ty chứ không có các địa chỉ độc lập của riêng mình.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Cải tiến Hoạt động:</Body1Strong> Giữ tập trung vào làm việc nhóm linh hoạt, không phải địa chỉ cố định, giúp duy trì sự nhanh nhẹn trong hoạt động.
                    </li>
                  </ul>
                  Đó là tất cả về việc chấp nhận một cách tiếp cận không giới hạn về làm việc nhóm và hiệu quả. TeamConnect phản ánh triết lý này bằng cách nhấn mạnh kết nối hơn là các địa điểm.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Thông tin của bộ phận của bạn có thiếu hoặc cũ không?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Các biên tập viên bộ phận được chỉ định trong tổ chức của bạn để thêm, cập nhật hoặc sửa đổi thông tin cụ thể của bộ phận trong TeamConnect.
                  Nếu bạn không chắc ai là biên tập viên bộ phận của mình hoặc nếu thông tin cụ thể cần can thiệp của đội hỗ trợ nhân sự,
                  vui lòng liên hệ <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> để được hướng dẫn.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Câu hỏi thường gặp về Danh bạ Văn phòng</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Giới thiệu về Danh bạ Văn phòng:</Body1Strong> Khám phá xương sống của sự hiện diện vật lý của tổ chức bạn thông qua Danh bạ Văn phòng,
                bao gồm những chi tiết cần thiết về văn phòng, đặc điểm hoạt động và cái nhìn toàn diện về các bộ phận và nhân viên định hình từng địa điểm.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Điều hướng Thẻ trong Danh bạ Văn phòng
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tìm đường đi quanh Danh bạ Văn phòng một cách dễ dàng sử dụng hệ thống thẻ của chúng tôi được thiết kế để truy cập nhanh đến địa điểm phù hợp. Đây là cách mỗi thẻ có thể giúp đơn giản hóa việc tìm kiếm của bạn:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tất cả:</Body1Strong> Hiển thị danh sách đầy đủ các văn phòng, cung cấp cái nhìn toàn cảnh về các địa điểm của tổ chức bạn.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Yêu thích:</Body1Strong> Giúp bạn tập trung vào những địa điểm văn phòng bạn thường xuyên ghé thăm hoặc cần theo dõi sát sao, bằng cách đánh dấu chúng là 'Yêu thích'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Văn phòng của bạn:</Body1Strong> Thẻ cá nhân này lọc danh bạ để hiển thị địa điểm văn phòng nơi bạn đang làm việc, giữ cho căn cứ của bạn luôn trong tầm tay.
                    </li>
                  </ul>
                  Cho dù bạn đang đặt phòng họp hay lên kế hoạch cho chuyến thăm, những thẻ này cung cấp một cách tương tác cá nhân hóa và đơn giản với bối cảnh địa lý của tổ chức bạn.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Nắm vững Cách Xem Thư Mục Văn Phòng trong TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Khám phá bố cục tổ chức của các địa điểm khác nhau của công ty bạn với các chế độ xem Thư Mục Văn Phòng của TeamConnect. Mỗi chế độ được thiết kế để 
                  đơn giản hóa khía cạnh độc đáo của việc điều hướng và hiểu văn phòng:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Xem Danh Sách:</Body1Strong> Cài đặt mặc định sắp xếp tất cả các địa điểm văn phòng vào
                      một danh sách dễ đọc.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Xem Thẻ Lớn:</Body1Strong> Chế độ Xem Thẻ Lớn trong Thư Mục Văn Phòng làm phong phú thêm 
                      cái nhìn của bạn với một bản trình bày chi tiết về mỗi văn phòng. Khi tương tác với một thẻ văn phòng, bạn sẽ tìm thấy rất nhiều thông tin và tùy chọn
                      có thể thực hiện trong menu "Thêm (...)":
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Xem Các Phòng Ban:</Body1Strong> Truy cập trực tiếp đến danh sách
                          tất cả các phòng ban được đặt trong văn phòng.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Xem Nhân Viên:</Body1Strong> Truy cập vào danh sách đầy đủ các nhân viên được phân công cho
                          văn phòng, thuận tiện cho việc liên lạc trực tiếp hoặc khám phá nhân sự văn phòng.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Sơ Đồ Tổ Chức Thực Thể Kinh Doanh:</Body1Strong> Hình dung vị trí của văn phòng trong bối cảnh
                          tổ chức lớn hơn để hiểu rõ các cấu trúc phân cấp.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Liên Hệ Cần Thiết:</Body1Strong> Xác định các liên hệ chính của văn phòng để giao tiếp một
                          cách thuận lợi.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Ngày Lễ Công Cộng Của Văn Phòng:</Body1Strong> Cập nhật thông tin về các ngày lễ công cộng
                          riêng của văn phòng để lên kế hoạch cho lịch trình của bạn phù hợp.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Giờ Làm Việc Của Văn Phòng:</Body1Strong> Xem giờ hoạt động của văn phòng để điều chỉnh
                          thời gian thăm viếng hoặc gọi điện cho họ.
                        </li>
                      </ol>
                      Chế độ này không chỉ cung cấp bức tranh trực quan và thông tin mà còn cho phép truy cập nhanh đến các cái nhìn sâu sắc hơn và hành động trực tiếp liên quan đến văn phòng, tất cả được gói gọn trong tùy chọn "Thêm (...)" cho sự tiện lợi của bạn.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Xem Sơ Đồ Tổ Chức:</Body1Strong> Hình dung cấu trúc phân cấp của các văn phòng chính và phụ 
                      để hiểu sự liên kết của các vị trí văn phòng khác nhau trong tổ chức của bạn.
                    </li>
                  </ul>
                  Chuyển đổi qua lại giữa các chế độ này bằng các biểu tượng cạnh hộp tìm kiếm để tùy chỉnh trải nghiệm duyệt của bạn cho phù hợp với nhu cầu ngay lập tức của bạn.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Tìm Kiếm Văn Phòng Nhanh Chóng với Tính Năng Tìm Kiếm của TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Việc xác định vị trí văn phòng phù hợp trong TeamConnect trở nên dễ dàng với tính năng tìm kiếm được thiết kế riêng. Được đặt nổi bật cạnh các biểu tượng 
                  chế độ xem, hộp tìm kiếm là công cụ của bạn để xác định văn phòng theo tên. Khi bạn nhập, thư mục sẽ lọc hiển thị chỉ các văn phòng phù hợp với dữ liệu nhập vào,
                  đơn giản hóa quá trình tìm kiếm của bạn. Việc sử dụng hộp tìm kiếm này trong TeamConnect là rất quan trọng để đạt được kết quả chính xác và liên quan nhất, 
                  trái ngược với việc tìm kiếm chung trong Microsoft Teams. Với chức năng tìm kiếm chuyên biệt này, bạn có thể di chuyển nhanh chóng đến bất kỳ văn phòng nào trong
                  mạng lưới tổ chức của bạn.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Duyệt Danh Bạ Văn Phòng Theo Chữ Cái
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nếu bạn muốn tìm kiếm theo bảng chữ cái, bộ lọc theo thứ tự chữ cái của chúng tôi là một cách nhanh chóng để tìm kiếm văn phòng theo tên. Ở đầu Danh Bạ Văn Phòng, bạn sẽ thấy các chữ từ A đến Z. Nhấp vào một chữ sẽ lọc danh sách để chỉ hiển thị những văn phòng bắt đầu bằng chữ đó, làm đơn giản hóa quá trình tìm kiếm của bạn. Để đặt lại và xem tất cả các văn phòng trở lại, chỉ cần bỏ chọn chữ đang kích hoạt. Tính năng này đặc biệt hữu ích khi bạn biết tên văn phòng nhưng cần một cách nhanh chóng để định vị nó trong TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Tiết Lộ Hồ Sơ Văn Phòng Toàn Diện trong TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Bắt đầu một hành trình khám phá trái tim của sự hiện diện vật lý của tổ chức bạn với các hồ sơ văn phòng của TeamConnect.
                  Nhấp vào tên một văn phòng sẽ mở ra một hộp thoại hồ sơ đầy màu sắc, tràn ngập các tab chứa đầy thông tin chi tiết về văn phòng:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tổng Quan:</Body1Strong> Khám phá bản sắc cốt lõi của văn phòng với các mô tả chi tiết, từ cấu trúc doanh nghiệp đến giờ mở cửa và giờ địa phương, nắm bắt bản chất của môi trường văn phòng.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Liên Hệ Thiết Yếu:</Body1Strong> Truy cập các liên hệ quan trọng cho dịch vụ khẩn cấp và hỗ trợ bên ngoài, mỗi liên lạc chỉ cách một cú nhấp chuột qua Hệ Thống Điện Thoại Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Liên Hệ Văn Phòng:</Body1Strong> Liên hệ với các dịch vụ hỗ trợ nội bộ dễ dàng, với thông tin liên lạc và các tùy chọn tương tác trực tiếp ngay tại đầu ngón tay bạn.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ngày Lễ Công Cộng:</Body1Strong> Cập nhật các ngày lễ đặc trưng của văn phòng, đảm bảo kế hoạch của bạn phù hợp với các quan sát và lịch làm việc địa phương.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Giờ Làm Việc Văn Phòng:</Body1Strong> Lên kế hoạch cho các tương tác của bạn với kiến thức chính xác về khi nào văn phòng nhộn nhịp với hoạt động hoặc bình tĩnh dần vào cuối ngày.
                    </li>
                  </ul>
                  Dù là sự náo nhiệt của Austin hay trung tâm chiến lược của Singapore, mỗi tab đều cung cấp một cánh cửa để hiểu và kết nối với các địa điểm đa dạng tạo nên sự hiện diện toàn cầu của công ty bạn.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Khám phá Động lực Bộ phận trong Văn phòng
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Việc duyệt qua mạng lưới chuyên nghiệp các bộ phận của một văn phòng được triển khai dễ dàng trong TeamConnect. Dưới đây là cách để khám phá chi tiết:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Xem các Bộ phận trong Văn phòng này:</Body1Strong> Khám phá tất cả các bộ phận tạo nên 
                      cấu trúc của văn phòng bằng cách chọn tùy chọn này từ menu Thêm (...).
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Hiểu biết về Tổ chức:</Body1Strong> Nhấp vào bất kỳ bộ phận nào để hiển thị sơ đồ tổ chức của nó cho 
                      biểu đồ thể hiện cấu trúc, với dấu vết đường đi dẫn bạn: Văn phòng {'>'} Bộ phận {'>'} Biểu đồ.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Khám phá sâu hơn về Bộ phận:</Body1Strong> Khám phá thêm về một bộ phận bằng cách nhấp vào 
                      menu Thêm (...) bên cạnh tên bộ phận. Tại đây, bạn có thể truy cập danh sách tất cả các nhân viên trong bộ phận đó, ngay trong bối cảnh văn phòng.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Duyệt Bằng Breadcrumb:</Body1Strong> Di chuyển mượt mà qua các cấp. Từ 
                      nhân viên trở lại các bộ phận hoặc từ biểu đồ tổ chức, dấu vết Breadcrumb (Văn phòng {'>'} Bộ phận {'>'} Nhân viên {'>'} Biểu đồ) giúp 
                      hành trình của bạn trực quan và theo dõi.
                    </li>
                  </ul>
                  Những con đường phức tạp của TeamConnect mời bạn khám phá, hiểu biết, và tương tác với từng lớp của các bộ phận văn phòng, trao cho bạn 
                  quyền kiểm soát di chuyển toàn diện.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Kết nối với Nhân viên Văn phòng trong TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tò mò về những gương mặt đằng sau một văn phòng? Câu trả lời chỉ cách một cú nhấp chuột trong TeamConnect. Tham gia với dấu ba chấm bên cạnh tên văn phòng bạn chọn để tiết lộ một loạt các tùy chọn tương tác:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Xem Nhân viên trong Văn phòng này:</Body1Strong> Tìm hiểu danh sách tất cả các chuyên gia 
                      gọi đây là nơi làm việc của họ. Số lượng hiển thị cung cấp một bản đếm nhanh của các nhân viên, mời bạn khám phá cộng đồng văn phòng.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tùy chọn Liên hệ:</Body1Strong> Mỗi mục nhập nhân viên được trang bị các biểu tượng hành động nhanh, 
                      cho phép bạn bắt đầu trò chuyện, cuộc gọi hoặc thậm chí hội nghị truyền hình ngay từ TeamConnect, thúc đẩy giao tiếp nội bộ mượt mà.
                    </li>
                    <li><Body1Strong>Điều hướng:</Body1Strong> Để quay lại danh sách văn phòng rộng lớn hơn từ danh sách nhân viên, chỉ cần nhấp vào 'Văn phòng' trong dấu vết 
                      Breadcrumb (Văn phòng {'>'} Nhân viên), và bạn sẽ được đưa trở lại tổng quan về văn phòng.
                    </li>
                  </ul>
                  Với những tính năng này, TeamConnect làm cho việc hiển thị phân bổ lực lượng lao động qua các văn phòng và tiếp cận dễ dàng, 
                  thúc đẩy một nơi làm việc kết nối bất kể khoảng cách.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Thông tin văn phòng của bạn có thiếu hoặc cũ không?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Các cập nhật thông tin văn phòng trong TeamConnect có thể được xử lý bởi những người chỉnh sửa văn phòng được chỉ định. Nếu bạn không chắc về các biên tập viên văn phòng hoặc 
                  cần hỗ trợ với thông tin thuộc về bộ phận Nhân sự, đội hỗ trợ Nhân sự sẵn sàng 
                  hỗ trợ tại <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Các câu hỏi thường gặp về cài đặt</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Tầm quan trọng của việc cài đặt chung chính xác trong TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Trong bối cảnh làm việc đầy biến động ngày nay, việc giữ liên lạc và phối hợp là quan trọng hơn bao giờ hết. Đó là lý do tại sao việc cập nhật 
                  Cài đặt Chung của bạn trong TeamConnect đóng một vai trò then chốt:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Lựa chọn Nơi làm việc:</Body1Strong> Nắm bắt bản chất của môi trường làm việc của bạn bằng cách cập nhật Nơi làm việc của bạn để phản ánh tình hình hiện tại của bạn,
                        cho dù là chuyển từ sự yên tĩnh của Văn phòng Tại nhà đến sự nhộn nhịp của Văn phòng chính, hoặc thậm chí là định cư tại Khu vực Công cộng.
                        Sự rõ ràng về địa điểm làm việc của bạn cung cấp cho đồng nghiệp cái nhìn không chỉ về cách tốt nhất để liên lạc với bạn, mà còn về bản chất của
                        các cuộc trò chuyện mà họ có thể tham gia với bạn, bảo vệ tính bảo mật của các cuộc thảo luận nhạy cảm đặc biệt là khi bạn ở giữa môi trường mở
                        của văn phòng khách hàng hoặc ngồi trong phòng chờ sân bay.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Chỉ định Địa điểm làm việc:</Body1Strong> Việc chia sẻ thành phố làm việc của bạn không chỉ giúp kết nối khoảng cách trong một thiết lập nhóm từ xa—nó còn
                        mở ra cơ hội cho các cuộc tụ họp chuyên nghiệp ngẫu hứng hoặc giao lưu xã hội. Cho dù đó là một cốc cà phê nhanh, một bữa trưa thoải mái, hoặc một cuộc họp hợp tác,
                        việc biết bạn đang ở trong cùng một thành phố có thể biến một ngày bình thường thành cơ hội để kết nối trực tiếp ý nghĩa. Điều này không chỉ củng cố mối quan hệ nhóm
                        mà còn cung cấp sự hiểu biết sâu sắc hơn về những tinh tế địa phương có thể đang ảnh hưởng đến công việc của bạn.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Độ chính xác của Múi giờ:</Body1Strong> Khi các nhóm trải rộng qua các lục địa, việc đảm bảo múi giờ của bạn phản ánh
                      địa điểm hiện tại của bạn là cần thiết để hài hòa các giao tiếp. Giờ Địa phương trong hồ sơ TeamConnect của bạn trực tiếp được ảnh hưởng bởi cài đặt này,
                      loại bỏ mọi sự đoán mò cho đồng nghiệp khi cố gắng liên hệ với bạn.
                    </li>
                  </ul>
                  Đi vào 'Cài đặt' {'>'} 'Cài đặt Chung' trong TeamConnect để điều chỉnh tinh tế những chi tiết này. Vài phút dành thời gian cập nhật cài đặt của bạn có thể 
                  cải thiện đáng kể sự gắn kết và hiệu quả của các nỗ lực hợp tác trên toàn bộ.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Giải mã việc lựa chọn Nơi làm việc trong TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Chọn đúng cài đặt trong trình đơn Nơi làm việc giúp TeamConnect phản ánh chính xác trạng thái làm việc hiện tại của bạn. Di chuột qua biểu tượng thông tin để xem giải thích chi tiết về từng tuỳ chọn:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Break:</Body1Strong> Đánh dấu thời gian rời khỏi công việc, dù là nghỉ ngơi hay kết thúc ngày làm.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office/Field-Office:</Body1Strong> Chỉ thị sự hiện diện trong không gian văn phòng do công ty quản lý.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> Làm việc tại không gian văn phòng tại nhà được dành riêng.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Public-Area:</Body1Strong> Vận hành từ một môi trường không phải văn phòng, cho thấy nhu cầu giao tiếp linh hoạt.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Customer Workplace/Private Office:</Body1Strong> Tại chỗ cùng với khách hàng, với ghi chú về mức độ riêng tư cho độ nhạy của cuộc trò chuyện.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Commute/Business-Travel/Training:</Body1Strong> Di chuyển hoặc tham gia các hoạt động liên quan đến công việc bên ngoài văn phòng.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Public-Holiday/Vacation/Various Leaves:</Body1Strong> Nghỉ phép chính thức vì lý do cá nhân, sức khỏe, hoặc kỳ nghỉ.</li>
                  </ul>
                  Việc lựa chọn thiết lập nơi làm việc phù hợp đảm bảo đội ngũ của bạn có được ngữ cảnh cần thiết cho tương tác, tôn trọng sự sẵn sàng và riêng tư.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Điều hướng trạng thái 'Checked-In' trong TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Thẻ 'Checked-In' trong Thư mục Nhân viên được thiết kế để hiển thị tất cả nhân viên hiện đang có mặt tại công việc. Tính khả dụng này được xác định bởi lựa chọn Nơi làm việc mà bạn thực hiện:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Môi trường làm việc tích cực:</Body1Strong> Các lựa chọn Văn phòng, Văn phòng tại vùng, Home-Office, Nơi làm việc từ xa, Khu vực công cộng, Khách hàng-Văn phòng,
                    Văn phòng Riêng của Khách hàng, Di chuyển, Du lịch công tác, và Đào tạo cho thấy bạn đang tham gia công việc, khiến bạn 'Checked-In' và hoạt động rõ ràng đối với đồng nghiệp.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Các cài đặt không làm việc:</Body1Strong> Lựa chọn Checked-Out, Break, Nghỉ lễ công cộng, Kỳ nghỉ, Nghỉ phép, Nghỉ thai sản, hoặc Nghỉ ốm
                      loại bỏ bạn khỏi danh sách 'Checked-In', đặt ra các kỳ vọng rõ ràng cho tính sẵn sàng và thời gian phản hồi.
                    </li>
                  </ul>
                  Tính năng này giúp phân biệt thời gian làm việc với thời gian cá nhân, hỗ trợ hợp tác và giao tiếp đội nhóm hiệu quả.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Quản lý Dữ liệu và Câu Hỏi Thường Gặp về Quyền Riêng Tư</Subtitle2>
          <Body1>
            <p>
              Trong thời đại số ngày nay, việc hiểu cách dữ liệu của bạn được quản lý là rất quan trọng. TeamConnect cam kết minh bạch và thực hiện các phương pháp bảo vệ dữ liệu mạnh mẽ. 
              Dưới đây, bạn sẽ tìm thấy thông tin về cách chúng tôi xử lý, lưu trữ và bảo vệ dữ liệu của bạn trong TeamConnect, đảm bảo không gian làm việc số của bạn an toàn và tuân thủ.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. TeamConnect bảo vệ dữ liệu cá nhân của tôi như thế nào?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect cam kết bảo vệ thông tin cá nhân của bạn với một cách tiếp cận toàn diện về bảo mật dữ liệu:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Đăng nhập Đơn (SSO):</Body1Strong> Để tăng cường bảo mật và tối ưu hóa trải nghiệm người dùng, TeamConnect tích hợp tự động 
                      Đăng nhập Đơn (SSO) với tài khoản Microsoft 365 của bạn, giúp quản lý truy cập an toàn và dễ dàng.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Mã hóa:</Body1Strong> Chúng tôi đảm bảo rằng tất cả dữ liệu trong TeamConnect được mã hóa cả trong quá trình truyền và trong khi lưu trữ, 
                      làm cho chúng không thể truy cập bởi những người không được phép.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Giảm thiểu Dữ liệu:</Body1Strong> Việc thu thập dữ liệu của chúng tôi được hạn chế chặt chẽ chỉ những gì cần thiết cho chức năng của TeamConnect.
                      Duy nhất thông tin người dùng mà chúng tôi lưu trữ là Tên Người Dùng Chính (UPN) từ Azure Entra ID, có thể giống với địa chỉ email công ty của bạn.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Truy cập Hạn chế:</Body1Strong> Truy cập vào UPN được giới hạn cho Quản trị Viên Đăng ký của tổ chức bạn trong cổng Đăng ký của chúng tôi,
                      đảm bảo rằng thông tin nhạy cảm chỉ hiển thị cho nhân viên được ủy quyền.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Xóa Dữ liệu Nhanh chóng:</Body1Strong> TeamConnect được cấu trúc để ngay lập tức xóa UPN được lưu trữ dưới ba điều kiện cụ thể:
                      nếu đăng ký bị hủy bởi Quản trị Viên Đăng ký, nếu một người dùng bị loại bỏ bởi nhân sự trong TeamConnect, hoặc nếu đăng ký TeamConnect hết hạn.
                      Điều này đảm bảo rằng dữ liệu cá nhân của bạn không được giữ lại lâu hơn thời gian hữu ích hoặc cần thiết của nó.
                    </li>                      
                  </ul>
                  <p>Thông qua các biện pháp này, TeamConnect cam kết duy trì tiêu chuẩn cao nhất của bảo vệ dữ liệu, đảm bảo rằng thông tin cá nhân của bạn luôn được an toàn và bảo mật.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Bạn có tự hỏi liệu dữ liệu của mình được lưu trữ ở đâu khi sử dụng TeamConnect không?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect phá vỡ khuôn mẫu của những dịch vụ SaaS truyền thống bằng cách đảm bảo rằng dữ liệu của bạn không bao giờ rời khỏi tổ chức của bạn.
                  Không giống như thực tiễn thường thấy là lưu trữ dữ liệu trên các máy chủ đám mây bên ngoài, TeamConnect tinh tế sử dụng Microsoft 365 SharePoint Online của tổ chức bạn 
                  để chứa tất cả dữ liệu ứng dụng. Cách tiếp cận sáng tạo này không chỉ làm nổi bật chủ quyền dữ liệu mà còn tăng cường bảo mật,
                  phù hợp hoàn hảo với cam kết kiên định của chúng tôi trong việc bảo vệ quyền riêng tư của bạn và tuân thủ các tiêu chuẩn tuân thủ nghiêm ngặt.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Liệu dữ liệu của tôi có được chia sẻ với bất kỳ bên thứ ba nào không?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Không, dữ liệu không được chia sẻ với bên thứ ba! Cubeet kiên định trong cam kết về quyền riêng tư và an ninh dữ liệu của bạn. Chúng tôi không chia sẻ bất kỳ dữ liệu nào của TeamConnect với bên thứ ba mà không có sự đồng ý rõ ràng từ khách hàng của mình.
                  Trong môi trường an toàn của chúng tôi, chúng tôi chỉ lưu trữ các thông tin nhận dạng cần thiết, chẳng hạn như Tên Người Dùng Chính (UPN), tên tổ chức, tên tenant, và ID tenant, trong Cổng đăng ký của chúng tôi.
                  Thông tin này rất quan trọng cho việc cung cấp dịch vụ và được bảo vệ theo chính sách riêng tư nghiêm ngặt của chúng tôi.
                  Hãy yên tâm, các thực tiễn quản lý dữ liệu của chúng tôi được thiết kế để đảm bảo dữ liệu của bạn được bảo mật, với sự minh bạch và quyền kiểm soát vẫn nằm chắc trong tay bạn.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. TeamConnect tuân thủ các quy định bảo vệ dữ liệu như GDPR như thế nào?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect phù hợp với các quy định bảo vệ dữ liệu như GDPR, HIPAA, CCPA, và các quy định khác bằng cách tận dụng các tính năng tuân thủ sẵn có của môi trường Microsoft 365,
                  nơi tất cả dữ liệu của khách hàng được lưu trữ an toàn. Không giống như các mô hình SaaS truyền thống, TeamConnect không lưu trữ bất kỳ dữ liệu khách hàng nào trên máy chủ của mình; thay vào đó, nó hoạt động trong
                  môi trường Microsoft 365 của tổ chức bạn, tuân thủ các tiêu chuẩn bảo mật và tuân thủ mạnh mẽ của nó. Cách tiếp cận này đảm bảo rằng quản lý dữ liệu của bạn phù hợp với
                  khuôn khổ tuân thủ toàn diện do Microsoft 365 cung cấp, bao gồm tuân thủ GDPR, HIPAA cho thông tin y tế, CCPA cho cư dân California,
                  và các quy định bảo vệ dữ liệu toàn cầu khác. Bằng cách tích hợp liền mạch với cấu hình Microsoft 365 hiện có của bạn, TeamConnect trao quyền cho tổ chức của bạn duy trì
                  quyền kiểm soát đầy đủ đối với dữ liệu của mình, đảm bảo tuân thủ các luật và quy định bảo vệ dữ liệu nghiêm ngặt nhất.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. TeamConnect xử lý dữ liệu từ các cuộc trò chuyện, cuộc gọi, cuộc gọi video, email Outlook và cuộc gọi qua hệ thống điện thoại của Microsoft Teams như thế nào?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect tận dụng Microsoft Graph API để kích hoạt các tương tác trực tiếp như trò chuyện, cuộc gọi, cuộc gọi video và email Outlook trong Microsoft Teams, 
                  cung cấp trải nghiệm người dùng liền mạch. Đặc biệt, trong khi TeamConnect khởi xướng các giao tiếp này, nó làm như vậy mà không can thiệp vào quá trình giao tiếp 
                  hoặc có khả năng ghi hoặc lưu trữ bất kỳ nội dung tương tác nào. Tất cả các cuộc trò chuyện, cuộc gọi và email diễn ra và được quản lý trực tiếp trong 
                  khuôn khổ an toàn của Microsoft Teams và Outlook, tuân thủ nghiêm ngặt các tiêu chuẩn bảo mật và quyền riêng tư mạnh mẽ của Microsoft 365. Điều này đảm bảo rằng TeamConnect cải thiện 
                  khả năng giao tiếp của bạn trong khi vẫn bảo toàn tính bảo mật và toàn vẹn của dữ liệu của bạn.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Để hiểu rõ hơn về chính sách và thực tiễn dữ liệu của chúng tôi, bạn cũng có thể muốn tham khảo {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>Hướng dẫn Sử dụng TeamConnect</Body1Strong></Link> hoặc phòng CNTT của tổ chức bạn tại <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> để biết chi tiết liên quan đến cài đặt của bạn.
          </p>
        </Body1>        
        <p></p>  
        <Subtitle1 className={styles.fontColor}>Chúng Tôi Đang Lắng Nghe: Chia Sẻ Ý Kiến và Ý Tưởng của Bạn</Subtitle1>
        <Body1>
          <p>
            Những hiểu biết của bạn thúc đẩy sự phát triển của TeamConnect. Cho dù bạn đang tràn đầy ý tưởng tính năng tuyệt vời hoặc có một số phản hồi về trải nghiệm hiện tại, chúng tôi luôn lắng nghe. Ý kiến của bạn vô cùng quý giá trong nỗ lực liên tục của chúng tôi để cung cấp dịch vụ tốt nhất có thể.
          </p>
        </Body1>
        <Subtitle2>Phản Hồi và Đề Xuất Tính Năng</Subtitle2>
        <Body1>
          <p>
            Sẵn sàng chia sẻ? Đến trang {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Trang Phản Hồi
            </Link>.
            Cho dù đó là đề xuất cho một tính năng mới hay phản hồi về các chức năng hiện có, chỉ cần chọn 
            tùy chọn phù hợp từ dropdown trong biểu mẫu. Những đóng góp của bạn giúp định hình tương lai của TeamConnect, 
            đảm bảo rằng nó đáp ứng và vượt trội hơn nhu cầu của bạn.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
