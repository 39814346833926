import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>TeamConnect središče za pomoč in pogosta vprašanja</Title3>
        <p><Body1>
          Dobrodošli v središču za pomoč TeamConnect, zasnovanem za podporo vaših izkušenj z našo celovito korporativno aplikacijo za imenike. Tukaj boste našli vire za optimizacijo uporabe TeamConnect znotraj Microsoft Teams, kar izboljšuje komunikacijo po vašem omrežju.
        </Body1></p>
        <Subtitle2>Hiter referenčni vodič</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Izboljšane sposobnosti iskanja: </Body1Strong>
              Hitro poiščite prave sodelavce, oddelke in nujne stike s pomočjo naših zmogljivih iskalnih orodij.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Vizualni organizacijski diagrami: </Body1Strong>
              S pomočjo intuitivnih organizacijskih shem enostavno razumite hierarhijo in poročilne linije vaše organizacije.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Prilagodljiva orodja za današnje potrebe: </Body1Strong>
              Uporabite sodobne funkcionalnosti, prilagojene zahtevam sodobnih delovnih mest.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Optimizacija vaše izkušnje</Subtitle2>
        <Body1>
          <p>
            TeamConnect revolucionira način vaše interakcije znotraj organizacijske strukture, vsakodnevno komunikacijo spremeni v strateška angažiranja.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Pomoč in pogosta vprašanja</Subtitle1>
        <Body1>
          <p>
            Poiščite odgovore na pogosta vprašanja o funkcijah in nastavitvah TeamConnecta v tem posvečenem oddelku. Če se soočate z navigacijo po aplikaciji, prilagajanjem nastavitev ali iskanjem rešitev za izzive, je naše središče za pomoč tukaj, da vam učinkovito pomaga.
          </p>
        </Body1>
        <Body1>
          <p>
            Za bolj poglobljeno podporo ali posebna navodila se posvetujte z <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnect priročnikom za uporabnike</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>        
        <Subtitle2>Uporaba pogostih vprašanj TeamConnect</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Ne najdete TeamConnect v stranski vrstici Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Če TeamConnect ni takoj vidno ali je izginilo v vaši stranski vrstici Microsoft Teams, ga lahko enostavno pripnete za hiter dostop. Samo sledite tem preprostim korakom:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Odprite Microsoft Teams in kliknite na ikono '...' (+ Aplikacije) na dnu stranske vrstice.</li>
                    <li className={styles.bulletBottomMargin}>V iskalno vrstico na vrhu pojavnega okna vpišite 'TeamConnect'.</li>
                    <li className={styles.bulletBottomMargin}>Z desno tipko miške kliknite na aplikacijo TeamConnect iz rezultatov iskanja in izberite 'Pripni'.</li>
                  </ol>
                  TeamConnect bo zdaj pripet na vaši stranski vrstici, dostopen z enim samim klikom!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Naleteli na tehnične težave s TeamConnect? Tukaj je pomoč.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Če naletite na kakršne koli tehnične težave s TeamConnect v Microsoft Teams, je tukaj ekipa IT podpore vaše organizacije, ki vam bo pomagala. 
                  Obrnite se nanje na <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, in vam bodo hitro pomagali.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Ne vidite svojih zaposlitvenih podatkov?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Če se vam zdi, da je vaš profil precej prazen, saj prikazuje samo vaše ime in elektronski naslov, je verjetno, da vaš HR tim še ni dodal ostalih vaših zaposlitvenih podatkov v TeamConnect.
                  Ni razloga za skrb! Hitro sporočilo vaši ekipi HR podpore bo stvari spravilo v tek. Čakajo, da vaš profil dopolnijo z vsemi pomembnimi podatki.
                  Obrnite se na njih na <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> in opazujte, kako vaš profil oživi!
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Katere možnosti jezikov so na voljo v TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect podpira globalno raznolikost Microsoft Teams z podporo vseh
                  njegovih jezikov. Ne glede na to, ali imate raje arabščino ali grščino,
                  japonščino ali švedščino, imate na voljo svet možnosti, vključno z:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arabščina, Bolgarščina, Kitajščina (Poenostavljena in Tradicionalna),
                      Hrvaščina, Češčina, Danska, Nizozemska, Angleščina, Estonščina,
                      Finščina, Francoščina, Nemščina, Grščina, Hebrejščina, Madžarščina,
                      Indonezijska, Italijanščina, Japonščina, Korejščina, Latvijščina,
                      Litvanščina, Norveščina (Bokmal), Poljščina, Portugalska (Brazilija in
                      Portugalska), Romunščina, Ruščina, Srbska (Latinica), Slovaščina,
                      Slovenski, Španščina, Švedščina, Tajščina, Turščina, Ukrajinščina,
                      Vietnamska.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Za prilagoditev izkušnje TeamConnect svojemu želenemu jeziku, prosimo
                  prilagodite jezikovne nastavitve neposredno v Microsoft Teams, saj se
                  TeamConnect v celoti integrira s temi nastavitvami:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Pojdite v nastavitve Microsoft Teams s klikom na tri pike poleg vaše
                      profilne slike na vrhu in nato izberite 'Videz in dostopnost'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Pomaknite se do razdelka 'Jezik' in iz spustnega menija izberite svoj
                      želeni jezik.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Potrdite svoje spremembe s klikom na gumb 'Shrani in ponovno zaženi',
                      da uporabite nove jezikovne nastavitve.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Opomba: </Body1Strong>
                <Body1>
                  Spremembe, ki jih naredite v jezikovnih nastavitvah v Microsoft Teams, se
                  bodo nemoteno posodobile tudi v vaši izkušnji TeamConnect. Zavedajte se,
                  da lahko sprememba jezika v Teams prav tako prilagodi vašo obliko časa in
                  datuma. Trenutno se TeamConnect razvija za podporo pisanja od desne proti
                  levi. Navdušeni smo nad temi izboljšavami in vas bomo obveščali, ko bodo
                  na voljo.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Kako lahko spremenim temo v TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect se sinhronizira s tvojimi nastavitvami teme v Microsoft Teams, vključno z možnostmi Svetla, Temna in Visok kontrast. Za prilagoditev tvoje teme:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Dostop do nastavitev Microsoft Teams s klikom na tri pike ob tvoji profilni slikici na vrhu.</li>
                    <li className={styles.bulletBottomMargin}>Izberi 'Videz in dostopnost', da raziščeš možnosti teme.</li>
                    <li className={styles.bulletBottomMargin}>Izberi želeno temo iz ponujenih možnosti, da takoj posodobiš vmesnik TeamConnect, da se ujema.</li>
                  </ol>
                  Tvoja izbira teme v Microsoft Teams bo samodejno uporabljena v TeamConnect, zagotavljajoč dosledno vizualno izkušnjo v tvojem delovnem prostoru.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Raziskovanje kontaktnih akcijskih gumbov v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Po vsem TeamConnect, kjer koli najdeš podrobnosti o kontaktnem osebi, boš odkril tudi naše priročne gumbe za hitri dostop. Ta priročna orodja 
                  so zasnovana za poenostavitev tvoje komunikacije v okviru Microsoft ekosistema, zagotavljajoč, da si vedno le klik stran od vzpostavitve povezave.
                  Tukaj je tvoj celovit vodnik za učinkovito uporabo teh gumbov:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona za klepet:</Body1Strong> Klikni za začetek takojšnjega klepeta v Microsoft Teams. Če se moraš vrniti v TeamConnect po začetku klepeta, preprosto uporabi puščico nazaj v namizni aplikaciji, gumb nazaj v mobilni aplikaciji ali gumb nazaj v tvojem brskalniku.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona e-pošte:</Body1Strong> Odpri nov osnutek e-pošte v Outlooku z že pripravljenim naslovnikom v polju "Za:". Če Outlook ne odpre, je vredno preveriti pri tvojem IT oddelku za morebitne omejitve.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona video klica:</Body1Strong> Začni obrazec-obraz video klic uporabljajoč Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona klica:</Body1Strong> Začne glasovni klic v Microsoft Teams, omogoča hitre pogovore.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona organizacijske sheme:</Body1Strong> Razkrije mesto zaposlenega v organizacijski hierarhiji, vključno z njegovim nadrejenim in neposrednimi poročevalci.</li>
                    <li><Body1Strong>Dodatno (...) Ikona:</Body1Strong> Razkrije dodatne možnosti:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Meet Now:</Body1Strong> Odpri Microsoft Teams za začetek takojšnjega sestanka z zaposlenim.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>New Meeting:</Body1Strong> Usmeri te v Microsoft Teams za načrtovanje novega sestanka.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Add Contact:</Body1Strong> Doda podrobnosti kontakta zaposlenega iz TeamConnect v tvojo novo aplikacijo Microsoft Teams People, bogatijoč tvojo funkcijo klica.</li>
                      </ul>
                    </li>
                  </ul>
                  Te intuitivne gumbe so oblikovane tako, da ti omogočajo učinkovito povezovanje s sodelavci, bodisi za hiter klepet, e-pošto ali za načrtovanje prihodnjih srečanj.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Opravljanje klicev preko TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Želiš opraviti klic? TeamConnect poenostavlja tvojo komunikacijo z integracijo v Microsoft Teams. 
                  Preprosto klikni na želeno telefonsko številko v TeamConnect, in glej, vmesnik za klicanje Microsoft Teams se pojavi, 
                  pripravljen, da nemoteno opraviš klic.
                  <p>
                  <Body1Strong>Opomba: </Body1Strong> 
                  Če naletiš na težave z opravljanjem klicev? To je lahko posledica IT politik tvoje organizacije ali 
                  uporabe telefonskega sistema, ki ni Microsoft Teams Phone System. Posvetovanje z IT oddelkom na <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, lahko osvetli zadevo.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Ali so povezani stroški z opravljanjem klicev prek TeamConnecta?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect izboljša vašo izkušnjo klicev z uporabo Microsoft Teams. Tukaj je, kar morate vedeti o stroških povezanih s klici:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Za klice na službene telefone zaposlenih, telefone oddelkov ali vroče linije znotraj vaše organizacije (notranje številke),
                      običajno <Body1Strong>ni stroškov</Body1Strong> pri uporabi telefonskega sistema Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Za zunanje klice, kot so tisti na mobilne telefone ali zunanje linije, veljajo <Body1Strong>standardne tarife</Body1Strong> telefonskega sistema vaše organizacije.
                      Te tarife se razlikujejo glede na vaš specifični servisni paket z Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Opomba:</Body1Strong> Če naletite na težave ali imate vprašanja o stroških klicev, je najbolje, da se posvetujete z 
                    vašim IT oddelkom na <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> za podrobne informacije 
                    prilagojene vaši organizacijski strukturi in politiki.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Ste opazili neskladje s statusom vaše prisotnosti 'Ni na delovnem mestu' v TeamConnectu?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ste opazili, da vaš status prisotnosti 'Ni na delovnem mestu' iz Microsoft 365 ni pravilno prikazan v TeamConnectu? 
                  Imamo rešitev. Značilnost za sinhronizacijo statusa prisotnosti 'Ni na delovnem mestu' je že implementirana v TeamConnectu.
                  Trenutno čakamo na izvedbo te funkcije s strani Microsofta. Ko se to zgodi, bo vaš status prisotnosti 'Ni na delovnem mestu'
                  natančno odražen v TeamConnectu, kar bo skladno z indikatorji prisotnosti Microsoft Teams za nemoteno izkušnjo.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>Pogosta vprašanja imenika zaposlenih</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Uvod v imenik zaposlenih:</Body1Strong> Navigirajte skozi profesionalno pokrajino vaše organizacije z Imenikom zaposlenih,
                vašim centralnim vozliščem za podrobne vpoglede v zaposlene, delovne dinamike in neposredno povezljivost do profilov vaših kolegov in managerskih razmerij.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Potrebujete dodati, posodobiti ali popraviti svoje zaposlitvene podatke?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Za dodajanje, posodabljanje ali popravljanje vaših zaposlitvenih podatkov v TeamConnectu, se prosimo obrnite na ekipo za kadrovsko podporo vaše organizacije.
                  Pripravljeni so vam pomagati na <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Kaj se zgodi, ko kliknem na ime zaposlenega v imeniku?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  S klikom na ime zaposlenega v Imeniku zaposlenih TeamConnect se odpre podrobna profilna stran tega posameznika. Ta profil nudi celovite 
                  vpoglede o zaposlenem, vključno z, a ne omejeno na, njegov delovni naziv, oddelek, lokacijo pisarne, kontaktnimi informacijami in več. Oblikovan je tako, da vam 
                  nudi popoln pregled nad profesionalno vlogo zaposlenega in kako se vklaplja v organizacijo, kar vam olajša razumevanje njegovih odgovornosti in 
                  kako bi lahko sodelovali z njim.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Katere informacije so vključene v profil zaposlenega?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Vsak profil zaposlenega v TeamConnect je celovita slika, ki nudi bogate vpoglede v poklicno življenje posameznika.
                  Evo, kaj boste našli:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Osnovni podatki:</Body1Strong> Ime, delovni naziv, oddelek in lokacija podružnice.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Poročevalska struktura:</Body1Strong> Informacije o neposrednih in funkcionalnih poročilih, 
                      vključno z linijskimi in funkcionalnimi managerji.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Delovni status:</Body1Strong> Trenutni delovni status, kot so Na dopustu, Pendelj, 
                      Poslovno potovanje itd.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Delovno mesto & Lokalni čas:</Body1Strong> Trenutna delovna lokacija zaposlenega in 
                      lokalni časovni pas.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontaktni podatki:</Body1Strong> Službeni telefon, mobilna številka in e-poštni naslov.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pisarniški naslov:</Body1Strong> Popolna lokacija pisarne vključno z imenom stavbe,
                      naslovom in Google zemljevidom.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>O meni:</Body1Strong> Kratek osebni uvod ali profesionalna biografija.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Medsebojni odnosi zaposlenih:</Body1Strong> Podrobnosti o poročevalski 
                      strukturi zaposlenega in odnosih v ekipi.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Družbene povezave & Jeziki:</Body1Strong> Povezave do profesionalnih družbenih profilov 
                      in jezikovne sposobnosti.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Veščine:</Body1Strong> Seznam profesionalnih veščin in področij strokovnega znanja.</li>
                  </ul>
                  Poleg tega profil nudi izvedljive možnosti za povezovanje preko klepeta, e-pošte, video klica ali načrtovanja sestankov, skupaj s 
                  hitrim dostopom do organizacijskega diagrama za širši kontekst ekipe.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Kako lahko posodobim podatke v svojem profilu?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Personalizacija vašega profila je preprosta v TeamConnect. Tukaj je navodilo, kako to storite:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Navigirajte do zavihka <Body1Strong>Zaposleni</Body1Strong> in kliknite na svoj <Body1Strong>Avatar</Body1Strong>, da odprete svoj profil.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        V zgornjem levem kotu boste našli povezavo <Body1Strong>"Uredi svoj profil"</Body1Strong>. 
                        Klik na to bo odprl pogovorno okno za urejanje vašega profila.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Tukaj lahko izboljšate svoj profil z dodajanjem ali posodabljanjem razdelka <Body1Strong>O tebi</Body1Strong>, povezav do socialnih omrežij in telefonske številke. 
                        To olajša vašim sodelavcem vzpostavitev stika ali sledenje.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Ne pozabite pritisniti gumb <Body1Strong>Shrani</Body1Strong>, da uporabite spremembe.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Pomaknite se navzdol v pogovornem oknu, da dodate svoje <Body1Strong>Jezike</Body1Strong> in <Body1Strong>Usposobljenost</Body1Strong>,
                        kot tudi vaše <Body1Strong>Sposobnosti</Body1Strong>. Deljenje teh informacij pomaga vašim sodelavcem razumeti
                        vaša strokovna področja in jezike, ki jih obvladate.
                      </li>
                    </ol>
                  Posodabljanje vašega profila ne samo da ohranja vaše informacije aktualne, ampak tudi spodbuja boljše povezave znotraj vaše organizacije.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Posodobitev vašega delovnega mesta, delovnega prostora in časovnega pasu v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ohranjanje posodobljenih informacij o vašem delovnem mestu, delovnem prostoru in časovnem pasu je ključnega pomena za nemoteno sodelovanje v Microsoft Teams. 
                  Te posodobitve se takoj odražajo v vašem profilu in seznamih TeamConnect, kar omogoča sodelavcem, da hitro razumejo vašo lokacijo dela 
                  in najboljše čase za povezovanje z vami, kar izboljšuje sodelovanje med različnimi regijami in časovnimi pasovi.
                  <p></p>
                  Ste pripravljeni posodobiti svoje podatke? V TeamConnect je to enostavno! Pojdite na 'Nastavitve' v zgornjem meniju in navigirajte do 'Splošne nastavitve'. 
                  Tukaj lahko gladko posodobite svoje delovno mesto in časovni pas ter določite svoj delovni prostor za dan. Ne pozabite shraniti sprememb, 
                  da boste obveščali vse in zagotovili učinkovito sodelovanje, ne glede na vašo lokacijo!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Ste opazili kaj nenavadnega v profilu sodelavca?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Če naletite na kakšne podrobnosti v profilu sodelavca, ki se zdijo nenavadne, je najboljši prvi korak, da jim prijazno sporočite. 
                  Hitri klepet ali e-pošta bi morala biti dovolj! Če iz kakršnega koli razloga informacije niso posodobljene, ne skrbite—samo 
                  pingnite svojo ekipo za podporo HR na <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, in bodo z veseljem 
                  posredovali in uredili stvari.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Razumevanje skupin značk: Vse, Priljubljene, Prijavljeni in Novi zaposleni
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vse:</Body1Strong> Ta privzeta značka prikaže vse zaposlene v vaši organizaciji, 
                      omogoča celovit pregled ekipe.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Priljubljene:</Body1Strong> Kliknite tukaj, da filtrirate vaš pogled na tiste sodelavce, 
                      ki ste jih označili kot 'Priljubljene', kar olajša hitro vzpostavitev stika s ključnimi kontaktnimi osebami.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Prijavljeni:</Body1Strong> Ta značka označuje zaposlene, ki so aktivno na delu, razlikuje 
                      od zgolj spletne prisotnosti v Microsoft Teams. Ko so prijavljeni, zaposleni signalizirajo, da so na delu in delijo svojo trenutno lokacijo dela 
                      tekom dneva. To lahko vključuje različna delovna mesta, kot so Pisarna, Terenska pisarna, Domača pisarna, Oddaljeno delovno mesto, Delovno mesto stranke, 
                      Zasebna pisarna stranke, Javno območje, Potovanje na delo, Poslovno potovanje ali celo Usposabljanje. Zato status 'Prijavljeni' nudi jasen pokazatelj 
                      aktivnega dela, kar kolegom omogoča natančno razumevanje trenutnega delovnega konteksta in razpoložljivosti drug drugega.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Novi zaposleni:</Body1Strong> Odkrijte nove obraze v TeamConnect s to značko, ki 
                      poudarja zaposlene, ki so bili dodani v aplikacijo v zadnjih 30 dneh. Običajno to sovpada z nedavnimi dodatki v vašo organizacijo, kar vam 
                      pomaga pozdraviti nove člane ekipe.
                    </li>
                  </ul>
                  Te skupine značk so zasnovane za poenostavitev navigacije in izboljšanje vaše izkušnje s TeamConnect, zagotavljajoč, da vedno lahko 
                  učinkovito najdete in se povežete s svojimi sodelavci.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Razšifriranje ikon ob iskalnem polju
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Imenik zaposlenih v TeamConnect je zasnovan za vsestranskost in enostavnost, s štirimi ikonami, ki nudijo različne poglede 
                  in hiter dostop do ključnih podrobnosti. Tukaj je, kaj razkriva vsaka ikona:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pogled seznama:</Body1Strong> Vaš osnovni pogled, ki predstavlja vse člane organizacije 
                      na preprostem seznamu.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Majhen kartični pogled:</Body1Strong> Kompaktna alternativa, ta pogled pretvori 
                      seznam v zgoščene kartice zaposlenih.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Velik kartični pogled:</Body1Strong> Izbirajte za več podrobnosti z večjimi karticami, ki vključujejo 
                      dodatne možnosti kontakta, kot so telefonske in mobilne številke za enostavno klicanje.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organigram:</Body1Strong> Vizualizirajte strukturo podjetja z organigramom, 
                      popolno za razumevanje poročevalskih odnosov in navigacijo po organizacijski pokrajini.</li>
                  </ol>
                    Preklapljajte med temi ikonami, da najdete pogled, ki najbolje ustreza vašim trenutnim potrebam, bodisi, da iščete hitre informacije ali globlje vpoglede.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Kje naj hitro iščem sodelavce?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Iščete sodelavca? Samo odpravite se do iskalnega polja TeamConnect, ki se nahaja na zgornji desni strani poleg ikone organigrama. 
                  Začnite tipkati njihovo ime in opazujte, kako se rezultati izpopolnjujejo z vsako črko. Pomnite, za iskanja v TeamConnect preskočite 
                  iskalno vrstico Microsoft Teams na vrhu—naš posebni iskalnik je prava izbira!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Kako deluje abecedno iskanje za iskanje zaposlenih?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Imenik zaposlenih TeamConnect vključuje priročno abecedno iskalno funkcijo, ki vam pomaga hitro najti zaposlene po njihovem imenu. 
                  Preprosto kliknite na črko iz abecede prikazane na vrhu imenika, in TeamConnect bo takoj pokazal vse zaposlene, 
                  katerih imena se začnejo s to črko. Za vrnitev na celotni seznam zaposlenih preprosto odznačite izpostavljeno črko. Ta intuitivna 
                  funkcija omogoča enostavno krmarjenje po obsežnih seznamih zaposlenih, zagotavljajoč, da lahko hitro in učinkovito najdete sodelavce.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Kako lahko prilagodim število zaposlenih prikazanih na stran?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect vam omogoča, da prilagodite število profilov zaposlenih, ki jih želite videti na stran, kar olajša krmarjenje po Imeniku zaposlenih. 
                  Na dnu vsake strani boste našli možnosti paginacije: 10, 50, 100, 500, 1000 in 5000. Privzeta nastavitev prikazuje 10 profilov na stran. 
                  Za ogled več profilov na eni strani preprosto izberite želeno številko iz teh možnosti. Ta funkcija omogoča prožnost pri pregledovanju 
                  imenika in vam omogoča, da si prikaz prilagodite svojim potrebam ali željam.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Kako razvrstim informacije zaposlenih v pogledu seznama?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  V pogledu seznama imenika zaposlenih TeamConnect imate možnost prilagoditi razvrstitev informacij o zaposlenih po svojih potrebah. Stolpci—Ime zaposlenega, 
                  Delovno mesto, Oddelek, Lokacija pisarne in Delovno mesto—so vsi razvrstljivi. Preprosto kliknite na glavo stolpca, po katerem želite razvrstiti. Klik enkrat bo razvrstil 
                  stolpec v naraščajočem vrstnem redu, in klik še enkrat bo razvrstil v padajočem vrstnem redu. Privzeto je imenik razvrščen po imenu zaposlenega v naraščajočem vrstnem redu, 
                  na podlagi imena. Ta funkcija razvrščanja vam pomaga hitro organizirati in pregledati podrobnosti o zaposlenih na način, ki najbolje ustreza vašemu namenu, ne glede na to, ali 
                  iščete določeno osebo ali morate združiti zaposlene po določenem merilu, kot sta oddelek ali lokacija.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>FAQ o imeniku oddelkov</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Uvod v imenik oddelkov:</Body1Strong> Imenik oddelkov je vaša vhodna točka za razumevanje zapletene mreže strukture vaše
              organizacije, ki ponuja jasen pogled na hierarhije oddelkov, vodstveno vodenje in poti za nemoteno komunikacijo znotraj oddelkov.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Kako delujejo značke 'Vsi', 'Priljubljeni' in 'Vaš oddelek' v imeniku oddelkov?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  V imeniku oddelkov TeamConnect značke omogočajo hitro navigacijo in filtriranje informacij o oddelkih. Tukaj je razlaga, kaj posamezna značka predstavlja:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vsi:</Body1Strong> Ta značka prikaže vse oddelke v vaši organizaciji, kar zagotavlja
                      celovit pregled.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Priljubljeni:</Body1Strong> Uporabite to značko za ogled prilagojenega seznama oddelkov, ki ste jih označili
                      kot 'Priljubljene', kar olajša dostop do oddelkov, s katerimi pogosto sodelujete.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vaš oddelek:</Body1Strong> Ta značka filtrira prikaz tako, da prikaže samo vaš oddelek,
                      omogoča hiter dostop do informacij in posodobitev vašega neposrednega oddelka.</li>
                  </ul>
                  Te značke so zasnovane za poenostavitev vaše navigacije znotraj imenika oddelkov, izboljšanje vaše sposobnosti učinkovitega iskanja in vključevanja informacij o oddelkih.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Razumevanje pogledov imenika oddelkov v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigacija po imeniku oddelkov v TeamConnect je optimizirana z več možnostmi pogledov, ki ustrezajo vašim preferencam. Vsak pogled ponuja
                  edinstven način za raziskovanje celovitih informacij o oddelkih, vključno z imenom oddelka, vodjo oddelka, nadrejenim oddelkom, telefonsko številko oddelka,
                  direktno linijo oddelka in e-pošto oddelka. Tukaj je nekaj razpoložljivih pogledov:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pogled s seznamom:</Body1Strong> Privzeta nastavitev, ki pregledno navaja vse oddelke, kar zagotavlja
                      jasen in koncizen pregled.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pogled z velikimi karticami:</Body1Strong> Preklopite na ta pogled, da si ogledate podrobne kartice oddelkov, ki
                      ponujajo razširjen povzetek ključnih informacij vsakega oddelka.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pogled organizacijske sheme:</Body1Strong> Za vizualni prikaz strukture in
                      odnosov med oddelki pogled organizacijske sheme ilustrira, kako se posamezni oddelki vključujejo v širšo organizacijsko hierarhijo.
                    </li>
                  </ul>
                  Za preklapljanje med temi pogledi preprosto uporabite ikone poleg iskalnega polja na vrhu imenika oddelkov. Vsak pogled je zasnovan tako, da vam pomaga
                  dostopati do podrobnosti o oddelkih in jih razumeti z lahkoto, zagotavlja, da najdete informacije, ki jih potrebujete, v formatu, ki vam najbolj ustreza.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Kako uporabljati iskalno polje v imeniku oddelkov
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Brez težav poiščite katerikoli oddelek znotraj TeamConnect z uporabo namenskega iskalnega polja, ki se nahaja tik ob ikonah za prikaz na vrhu 
                  Imenika oddelkov. Preprosto začnite tipkati ime oddelka, ki ga iščete, in opazujte, kako se imenik samodejno zoži, da prikaže samo 
                  ustrezne zadetke. Ta funkcija je posebej zasnovana, da vam pomaga obiti potrebo po ročnem pregledovanju celotnega imenika, saj prinaša 
                  potrebni oddelek neposredno do vaših prstov. Ne pozabite, ta funkcionalnost iskanja je unikatna za Imenik oddelkov TeamConnect, zato 
                  uporabite to iskalno polje namesto glavnega iskanja Microsoft Teams na samem vrhu vmesnika Teams za poizvedbe specifične za oddelke.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Kako deluje abecedni filter v Imeniku oddelkov?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Poenostavite svoje iskanje v Imeniku oddelkov z abecednim filtrom, ki se nahaja na vrhu. Ta funkcija vam omogoča hitro zoženje 
                  oddelkov z izbiro črke. Ko izberete črko, TeamConnect filtrira in prikaže samo tiste oddelke, katerih imena se začnejo 
                  z izbrano črko, kar vam olajša iskanje brez potrebe po drsenju skozi celoten seznam. Za vrnitev na celoten pogled oddelka 
                  preprosto odznačite izpostavljeno črko. Ta intuitivna abecedna navigacija vam zagotavlja, da lahko učinkovito najdete katerikoli oddelek 
                  po začetni črki.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Kako lahko kontaktiram vodjo oddelka?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Kontaktna informacija za vodje oddelkov v TeamConnect je preprosta. Enostavno poiščite zanimiv oddelek in kliknite na ime vodje. To 
                  dejanje bo sprožilo pojavnega okna, ki prikazuje kontaktne možnosti vodje z znanimi ikonami za hiter dostop:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona za klepet:</Body1Strong> Za začetek takojšnjega sporočila preko klepeta Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona za elektronsko pošto:</Body1Strong> Za odprtje novega e-poštnega sporočila v Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona za video klic:</Body1Strong> Za začetek video klica preko Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona za klic:</Body1Strong> Za začetek glasovnega klica z uporabo Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona Več (...):</Body1Strong> Za dodatne možnosti, kot so načrtovanje sestanka ali dodajanje 
                      njihovih kontaktov na vaš seznam Teams.
                    </li>
                  </ul>
                  Te ikone zagotavljajo nemoten način za neposredno komunikacijo z vodji oddelkov znotraj TeamConnect, kar zagotavlja učinkovito komunikacijo znotraj 
                  vaše organizacije.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Kako lahko vidim ali kontaktiram zaposlene v oddelku?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Za raziskovanje, kdo je kdo v oddelku v TeamConnect, poglejte tri pikice zraven imena oddelka. Klik na to možnost 
                  odpre opcije za nadaljnje ukrepanje:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Poglej zaposlene v tem oddelku:</Body1Strong> Izberite to, da prikažete seznam vseh 
                      zaposlenih, povezanih z oddelkom. Število zraven te možnosti kaže, koliko zaposlenih je v oddelku, kar omogoča hitro štetje.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organigram oddelka:</Body1Strong> Če želite razumeti hierarhijo oddelka, 
                      izberite 'organigram oddelka', ki razkrije strukturni razpored, prikazuje odnos med matičnimi oddelki, obravnavanim oddelkom, 
                      in morebitnimi pododdelki. Ta vizualna predstavitev vam pomaga krmariti in razumeti organizacijski okvir, kar olajša identifikacijo in 
                      kontaktiranje določenih članov oddelka.
                    </li>
                    <li><Body1Strong>Oddelek {'>'} Zaposleni:</Body1Strong> Po ogledu zaposlenih znotraj oddelka uporabite to pot navigacije za enostaven povratek 
                      na seznam oddelkov, kar omogoča gladko raziskovanje izkušnje znotraj TeamConnect.
                    </li>
                  </ul>
                  Te funkcije vam omogočajo ne samo videti seznam zaposlenih v določenem oddelku, temveč tudi ponujajo pot za neposredno kontaktiranje preko 
                  komunikacijskih orodij TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Odkrivanje podrobnosti oddelka v globino v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Poglobite se v podrobnosti kateregakoli oddelka z enostavnim klikom na njegovo ime v TeamConnect. Razvije se podroben dialog, ki ponuja bogato 
                  paleto informacij, ki slikovito prikazujejo vlogo in vire oddelka znotraj vaše organizacije. Našli boste vse, od vodstva in finančnih identifikatorjev 
                  do komunikacijskih kanalov in socialnih povezav. Ta funkcija je vaša vhodna točka do:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Srce oddelka – njegov namen, ljudje in kazalniki učinkovitosti.</li>
                    <li className={styles.bulletBottomMargin}>Poti za kontaktiranje – neposredne povezave do ključnih oseb v oddelku in komunikacijske platforme.</li>
                    <li className={styles.bulletBottomMargin}>Organizacijska mreža – razumevanje, kako je oddelek integriran v širšo strukturo podjetja.</li>
                  </ul>
                  Ne glede na to, ali gre za hitro poizvedbo ali poglobljeno raziskavo podatkov oddelka, vam TeamConnect omogoča orodja za povezovanje in razumevanje 
                  edinstvenih ekosistemov znotraj vaše organizacije.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Radovedni, zakaj v TeamConnect ni naslovov oddelkov?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Iskanje lokacije oddelka je včasih lahko kot lov za zakladom brez zemljevida. V TeamConnect običajno ne boste našli določenih naslovov za vsak oddelek. 
                  Tukaj je razlog, zakaj ta kartografska podrobnost pogosto ni navedena:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Več lokacij:</Body1Strong> Mnogi oddelki niso omejeni na en prostor; uspevajo v različnih 
                      pisarnah in geografijah.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Fluidna struktura:</Body1Strong> Organizacijska krajina se nenehno spreminja, oddelki se 
                      razvijajo, združujejo ali celo razpustijo v skladu s strateškimi premiki.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Medoddelčno sodelovanje:</Body1Strong> Sodobni delovni prostori živijo od sodelovalnega 
                      duha, pogosto združujejo talente iz različnih oddelkov znotraj deljenih prostorov.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Delo na daljavo:</Body1Strong> Z fleksibilnostjo dela na daljavo sidra oddelkov niso vedno 
                      spuščena v fizičnih pisarniških prostorih.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pravne nianse:</Body1Strong> Oddelki običajno krožijo okoli pravne strukture podjetja, namesto 
                      da bi imeli svoje samostojne naslove.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Operativna poenostavitev:</Body1Strong> Ohranjanje poudarka na dinamičnem timskem delu, ne 
                      statičnih naslovih, pomaga vzdrževati operativno agilnost.
                    </li>
                  </ul>
                  Gre za sprejemanje brezmejnega pristopa k timskemu delu in učinkovitosti. TeamConnect odraža to filozofijo s poudarkom na povezavah namesto lokacijah.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Ali vašemu oddelku manjkajo informacije ali so zastarele?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Uredniki oddelkov so določeni znotraj vaše organizacije za dodajanje, posodabljanje ali popravljanje specifičnih informacij oddelka v TeamConnect.
                  Če niste prepričani, kdo je urednik vašega oddelka ali če določene informacije zahtevajo posredovanje ekipe za podporo HR, 
                  prosimo kontaktirajte <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> za nasvet.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Pogosta vprašanja o imeniku uradov</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Uvod v imenik uradov:</Body1Strong> Odkrijte hrbtenico fizične prisotnosti vaše organizacije preko imenika uradov, 
                ki zajema bistvene podrobnosti uradov, operativne specifike in celovit pregled oddelkov in osebja, ki opredeljujejo vsako lokacijo.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Navigacija oznak v imeniku uradov
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Z lahkoto se znajdite v imeniku uradov z uporabo našega sistemu oznak, ki je oblikovan za hitri dostop do prave lokacije. Tukaj je, kako vam lahko vsaka oznaka pomaga optimizirati iskanje:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vse:</Body1Strong> Prikazuje celoten seznam uradov, ki zagotavlja pregled nad 
                      lokacijami vaše organizacije.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Priljubljene:</Body1Strong> Pomaga vam osredotočiti se na tiste lokacije uradov, ki jih pogosto obiskujete ali morate 
                      pozorno spremljati, z označevanjem kot 'Priljubljene'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vaš urad:</Body1Strong> Ta osebna oznaka filtrira imenik, da vam pokaže 
                      lokacijo urada, kjer ste nameščeni, tako da je vaša domača baza vedno pri roki.
                    </li>
                  </ul>
                  Ne glede na to, ali rezervirate sejno sobo ali načrtujete obisk, te oznake nudijo personaliziran in poenostavljen način interakcije z geografsko krajino vaše organizacije.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Obvladovanje pogledov imenika pisarn v TeamConnectu
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Potopite se v organizacijsko zasnovo različnih lokacij vašega podjetja s pogledi imenika pisarn v TeamConnectu. Vsak pogled je oblikovan tako, da olajša 
                  edinstven vidik navigacije in razumevanja pisarne:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pogled seznama:</Body1Strong> Privzeta nastavitev, ki vse lokacije pisarn lepo organizira v 
                      lahko berljiv seznam.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pogled velike kartice:</Body1Strong> Pogled velike kartice v imeniku pisarn obogati vaš 
                      pogled z natančno predstavitvijo vsake pisarne. Ko se ukvarjate s kartico pisarne, boste našli bogastvo informacij in možnosti ukrepanja 
                      znotraj menija "Več (...)":
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Pogled oddelkov:</Body1Strong> Neposredno se pomaknite do seznama
                          vseh oddelkov znotraj pisarne.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Pogled zaposlenih:</Body1Strong> Dostop do popolnega seznama zaposlenih, dodeljenih v 
                          pisarno, omogoča neposreden stik ali raziskovanje osebja pisarne.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Organigram poslovne enote:</Body1Strong> Vizualizirajte mesto pisarne znotraj širšega 
                          organizacijskega konteksta za jasno razumevanje hierarhičnih struktur.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Ključni stiki:</Body1Strong> Identificirajte ključne stike v pisarni za 
                          nemoteno komunikacijo.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Državni prazniki pisarne:</Body1Strong> Bodite obveščeni o specifičnih državnih praznikih v pisarni, da lahko ustrezno načrtujete svoje urnike.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Delovni čas pisarne:</Body1Strong> Oglejte si delovni čas pisarne, da svoje obiske ali klice uskladite z njihovim aktivnim časom.
                        </li>
                      </ol>
                      Ta pogled zagotavlja ne samo vizualni in informativni posnetek, temveč tudi hiter dostop do globljih vpogledov in neposrednih ukrepov, povezanih s pisarno, vse lepo zloženo pod možnostjo "Več (...)" za vaše udobje.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pogled organizacijske sheme:</Body1Strong> Vizualizirajte hierarhično strukturo matičnih in podrejenih pisarn
                      za razumevanje medsebojne povezanosti različnih lokacij pisarn znotraj vaše organizacije.
                    </li>
                  </ul>
                  Preklapljajte med temi pogledi z ikonami poleg iskalnega polja, da prilagodite svojo izkušnjo brskanja svojim takojšnjim potrebam.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Hitro iskanje pisarn z iskalno funkcijo TeamConnecta
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Iskanje prave lokacije pisarne znotraj TeamConnecta je olajšano z namensko iskalno funkcijo. Postavljeno vidno poleg ikon za ogled, iskalno polje je vaše orodje za natančno določanje pisarn po imenu. Ko tipkate, imenik natančno prikaže samo pisarne, ki se ujemajo z vašim vnosom, in tako poenostavi vaš iskalni proces. Pomembno je, da to iskalno polje uporabljate znotraj TeamConnecta za najbolj natančne in relevantne rezultate, v nasprotju z splošnim iskanjem v Microsoft Teams. S to ciljno funkcijo iskanja lahko hitro navigirate do katere koli pisarne v mreži vaše organizacije.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Iskanje v imeniku pisarn po abecedi
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Če želite iskati po abecedi, je naš abecedni filter hiter način za iskanje pisarn po imenu. Na vrhu imenika pisarn boste videli črke od A do Ž. Klik na črko filtrira pogled, da prikaže samo tiste pisarne, ki se začnejo s to črko, kar poenostavlja vaš iskalni proces. Za ponastavitev in ponovni prikaz vseh pisarn preprosto odznačite aktivno črko. Ta funkcija je še posebej uporabna, ko poznate ime pisarne, vendar potrebujete hiter način za njeno lociranje znotraj TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Razkrivanje celovitih profilov pisarn v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Podajte se na raziskovalno potovanje skozi jedro fizične prisotnosti vaše organizacije s profili pisarn TeamConnect. 
                  Klik na ime pisarne odpre živahen pogovorni okvir, poln zavihkov z podrobnimi vpogledi v pisarne:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pregled:</Body1Strong> Poglobite se v jedro identitete pisarne z 
                      podrobnimi opisi, od poslovne strukture do delovnega časa in lokalnega časa, ki zajame bistvo okolja pisarne.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nujni kontaktni podatki:</Body1Strong> Dostop do ključnih kontaktnih informacij za 
                      nujne službe in zunanjo podporo, vsak klik stran prek Microsoft Teams Phone System.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontakti v pisarni:</Body1Strong> Enostavno se obrnite na interne podporne službe z 
                      kontaktnimi podatki in možnostmi neposredne komunikacije na dosegu roke.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Prazniki:</Body1Strong> Ostanite na tekočem s pisarniško specifičnimi prazniki, da 
                      bodo vaši načrti usklajeni z lokalnimi opazovanji in delovnimi urniki.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Urnik pisarne:</Body1Strong> Načrtujte svoje interakcije z natančnim poznavanjem,
                      kdaj v pisarni vlada vrvež ali pa se dan zaključuje.
                    </li>
                  </ul>
                  Ne glede na to, ali gre za vrvež Austina ali strateško vozlišče Singapurja, vsak zavihek nudi vrata za razumevanje in povezovanje z raznolikimi 
                  lokacijami, ki sestavljajo globalno prisotnost vašega podjetja.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Poglobljeno raziskovanje dinamike oddelkov v pisarni
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigacija po profesionalni mreži oddelkov pisarne se zlahka odvija v TeamConnect. Evo, kako podrobno raziskati podrobnosti:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Poglej oddelke v tej pisarni:</Body1Strong> Odkrijte vse oddelke, ki tvorijo 
                      tkivo pisarne, tako da izberete to možnost iz menija Več (...).
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organizacijski vpogled:</Body1Strong> Kliknite na katerikoli oddelek, da razkrijete njegov organizacijski diagram za 
                      vizualno predstavitev njegove strukture, z drobtinico, ki vas vodi: Pisarna {'>'} Oddelek {'>'} Diagram.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Poglobljena raziskava oddelka:</Body1Strong> Nadaljujte z raziskovanjem oddelka s klikom na 
                      meni Več (...) poleg imena oddelka. Tu lahko dostopate do seznama vseh zaposlenih znotraj tega oddelka, neposredno v kontekstu pisarne.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Navigacija po drobtinicah:</Body1Strong> Prehajajte med ravnmi brez težav. Od 
                      zaposlenih nazaj do oddelkov ali od organizacijskega diagrama, drobtinica (Pisarna {'>'} Oddelek {'>'} Zaposleni {'>'} Diagram) ohranja vaše 
                      potovanje intuitivno in na pravi poti.
                    </li>
                  </ul>
                  Zapletene poti TeamConnect vas vabijo, da raziskujete, razumete in sodelujete na vsaki ravni oddelkov vaše pisarne, s čimer vas opolnomočijo z 
                  celovitim navigacijskim nadzorom.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Povezovanje s kadri v pisarni v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Radovedni, kdo so obrazi za pisarno? Odgovor je samo klik stran v TeamConnect. Ukvarjajte se z elipsami (tri pike) poleg izbranega imena pisarne, da razkrijete paket interaktivnih možnosti:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Poglej zaposlene v tej pisarni:</Body1Strong> Razkrijte seznam vseh strokovnjakov, 
                      ki ta pisarno imenujejo svoj delovni dom. Prikazana številka nudi hiter izračun zaposlenih, ki vas vabi, da se poglobite v skupnost pisarne.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Možnosti stika:</Body1Strong> Vnos za vsakega zaposlenega je opremljen z ikonami za hitro delovanje, 
                      ki vam omogočajo, da začnete klepete, klice ali celo videokonference neposredno iz TeamConnect, kar spodbuja nemoteno medpisarniško komunikacijo.
                    </li>
                    <li><Body1Strong>Navigacija:</Body1Strong> Če se želite vrniti na širši seznam pisarn z seznama zaposlenih, preprosto kliknite 'Pisarna' v drobtinici 
                      (Pisarna {'>'} Zaposleni) in vas bo vrnilo na pregled pisarne.
                    </li>
                  </ul>
                  S temi funkcijami TeamConnect olajša vizualizacijo porazdelitve delovne sile po pisarnah in enostavno vzpostavljanje stikov,
                  spodbuja povezan delovni prostor ne glede na razdaljo.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Ali so informacije vaše pisarne manjkajoče ali zastarele?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Posodobitve informacij o pisarni v TeamConnect lahko opravijo določeni uredniki pisarne. Če niste prepričani, kdo so uredniki pisarne,
                  ali potrebujete pomoč pri informacijah, ki spadajo v pristojnost kadrovske službe, je ekipa za podporo kadrov pripravljena
                  pomagati na <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Pogosta vprašanja o nastavitvah</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Pomen natančnih splošnih nastavitev v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  V dinamični pokrajini današnjega delovnega okolja je ohranjanje povezave in usklajenosti bolj ključno kot kdaj koli prej. Tukaj igra ključno vlogo posodabljanje vaših
                  Splošnih nastavitev v TeamConnect:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Izbira delovnega mesta:</Body1Strong> Ujemite bistvo vaše delovne nastavitve z posodabljanjem vašega Delovnega mesta, da odraža vašo trenutno
                        situacijo, bodisi prehod iz miru vašega Domačega urada v vrvež glavnega Urada ali celo nastanitev v Javno območje.
                        Ta jasnost v vaši delovni lokaciji nudi kolegom vpogled ne samo v najboljše načine za kontaktiranje vas, ampak tudi v vrsto 
                        pogovorov, ki jih lahko sprožijo z vami, in ščiti zaupnost občutljivih pogovorov, še posebej, ko ste v odprtem 
                        okolju pisarne stranke ali sedite v letališki čakalnici.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Specifikacija delovnega mesta:</Body1Strong> Deljenje vašega delovnega mesta naredi več kot le premošča razdalje v oddaljeni ekipi—prav tako 
                        odpira vrata spontanim strokovnim srečanjem ali priložnostnemu druženju. Naj bo to hitra kava, počasno kosilo ali sodelovalno
                        srečanje, vedeti, da ste v istem mestu, lahko spremeni običajen dan v priložnost za smiselne stike iz oči v oči. To ne
                        samo krepi vezi v ekipi, ampak tudi zagotavlja bogatejše razumevanje lokalnih nians, ki bi lahko vplivale na vaše delo.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Natančnost časovnega pasu:</Body1Strong> Ko ekipe obsegajo celine, je bistveno, da vaš časovni pas odraža
                      vašo trenutno lokacijo za usklajevanje komunikacij. Lokalni čas v vašem profilu TeamConnect je neposredno vplivan s to nastavitvijo,
                      odpravlja kakršno koli ugibanje kolegov, ki poskušajo stopiti v stik z vami.
                    </li>
                  </ul>
                  Podajte se v 'Nastavitve' {'>'} 'Splošne nastavitve' znotraj TeamConnecta, da prilagodite te podrobnosti. Nekaj trenutkov, porabljenih za posodobitev vaših nastavitev, lahko
                  znatno izboljša kohezijo in učinkovitost vaših sodelovalnih prizadevanj na splošno.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Razumevanje izbire delovnega mesta v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Izbor prave nastavitve v meniju Delovno mesto prilagodi TeamConnect, da natančno odraža vaš trenutni status dela. Premaknite miško nad ikono za informacije za podroben opis posamezne možnosti:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Odmor/Konec Dela:</Body1Strong> Pomeni čas odsotnosti z dela, bodisi za odmor ali konec dneva.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pisarna/Teren:</Body1Strong> Označuje prisotnost v delovnem prostoru, ki ga upravlja podjetje.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Domača Pisarna:</Body1Strong> Delo iz posebej določenega domačega delovnega prostora.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Oddaljeno Delovno Mesto/Javni Prostor:</Body1Strong> Delovanje iz okolja zunaj pisarne, kar nakazuje potrebo po fleksibilni komunikaciji.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Strankino Delovno Mesto/Zasebna Pisarna:</Body1Strong> Na lokaciji pri stranki, s pripombo o ravneh zasebnosti za občutljivost pogovorov.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Potovanje/Službena Potovanja/Izobraževanje:</Body1Strong> Na poti ali vključen v specifične delovne aktivnosti zunaj pisarne.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Javni Praznik/Dopust/Različna Odsotnost:</Body1Strong> Uradno prosti zaradi osebnih, zdravstvenih ali prazničnih razlogov.</li>
                  </ul>
                  Izbor ustreznega delovnega mesta zagotavlja, da ima vaša ekipa potreben kontekst za interakcije, s spoštovanjem razpoložljivosti in zasebnosti.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Navigacija statusa 'Prijavljen' v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Oznaka 'Prijavljen' v imeniku zaposlenih je zasnovana tako, da prikaže vse zaposlene, ki so trenutno na delu. Ta vidljivost je določena z izbiro Delovnega mesta, ki ga opravite:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Aktivna Delovna Okolja:</Body1Strong> Izbire Pisarna, Teren, Domača Pisarna, Oddaljeno Delovno Mesto, Javni Prostor, Delovno Mesto Stranke, 
                    Zasebna Pisarna Stranke, Potovanje, Službena Potovanja in Izobraževanje signalizirajo, da ste vključeni v delo, zaradi česar ste 'Prijavljeni' in vidno aktivni kolegom.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ne-Delovne Nastavitve:</Body1Strong> Izbor Odmor, Konec Dela, Javni Praznik, Dopust, Na Odsotnosti, Materinski Dopust ali Bolezenski Dopust 
                      vas odstrani iz seznama 'Prijavljeni', kar postavlja jasna pričakovanja za razpoložljivost in čase odziva.
                    </li>
                  </ul>
                  Ta funkcionalnost pomaga ločiti delovna obdobja od osebnega časa, kar prispeva k učinkovitemu sodelovanju in komunikaciji v ekipi.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Upravljanje podatkov in pogosta vprašanja o zasebnosti</Subtitle2>
          <Body1>
            <p>
              V današnji digitalni dobi je razumevanje, kako so vaši podatki upravljani, ključnega pomena. Podjetje TeamConnect je zavezano k transparentnosti in robustnim praksam zaščite podatkov. 
              Spodaj boste našli informacije o tem, kako obdelujemo, shranjujemo in ščitimo vaše podatke znotraj TeamConnecta, s čimer zagotavljamo, da je vaše digitalno delovno okolje varno in skladno z zahtevami.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Kako TeamConnect ščiti moje osebne podatke?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect je posvečen varovanju vaših osebnih podatkov z celovitim pristopom k varnosti podatkov:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Enotna prijava (SSO):</Body1Strong> Za izboljšanje varnosti in poenostavitev uporabniške izkušnje TeamConnect integrira samodejno 
                      enotno prijavo (SSO) z vašim računom Microsoft 365, kar olajša varno in brezskrbno upravljanje dostopa.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Šifriranje:</Body1Strong> Zagotavljamo, da so vsi podatki znotraj TeamConnect šifrirani tako med prenosom kot v mirovanju, 
                      kar jih dela nedostopne nepooblaščenim stranem.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Minimizacija podatkov:</Body1Strong> Naše zbiranje podatkov je strogo omejeno na tisto, kar je nujno potrebno za delovanje TeamConnecta. 
                      Edine informacije o uporabniku, ki jih shranjujemo, so vaše uporabniško glavno ime (UPN) iz Azure Entra ID, ki je lahko podobno vašemu službenemu e-poštnemu naslovu.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Omejen dostop:</Body1Strong> Dostop do UPN je omejen na administratorja naročnine vaše organizacije v našem portalu za naročnino, 
                      kar zagotavlja, da so občutljive informacije vidne samo pooblaščenim osebam.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Promptna izbrisava podatkov:</Body1Strong> TeamConnect je strukturiran tako, da takoj izbriše shranjeni UPN pod tremi specifičnimi pogoji: 
                      če administrator naročnine prekliče naročnino, če uporabnika odstrani HR znotraj TeamConnecta, ali če naročnina TeamConnect poteče. To zagotavlja, da se vaši osebni podatki ne hranijo dlje od njihove uporabne dobe ali potrebe.
                    </li>                      
                  </ul>
                  <p>S temi ukrepi se TeamConnect zavezuje k vzdrževanju najvišjih standardov zaščite podatkov, s čimer zagotavlja, da so vaši osebni podatki varni in zaupni.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Sprašujete se, kje se nahajajo vaši podatki s TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect presega meje običajnih ponudb SaaS z zagotovilom, da vaši podatki nikoli ne zapustijo vaše organizacije. 
                  Za razliko od običajne prakse shranjevanja podatkov na zunanjih oblakih, TeamConnect premeteno uporablja Microsoft 365 SharePoint Online vaše organizacije 
                  za shranjevanje vseh podatkov aplikacije. Ta inovativen pristop ne le zagovarja suverenost podatkov, ampak tudi krepi varnost, 
                  kar popolnoma sovpada z našo zavezanostjo za zaščito vaše zasebnosti in spoštovanjem strogih standardov skladnosti.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Ali bodo moji podatki deljeni s tretjimi osebami?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ne, podatki niso deljeni s tretjimi osebami! Cubeet je trdno predan zasebnosti in varnosti vaših podatkov. Ne delimo nobenih podatkov TeamConnect s tretjimi osebami brez izrecnega soglasja naših strank. 
                  V našem varnem okolju shranjujemo le bistvene identifikatorje, kot so Uporabniško glavno ime (UPN), ime organizacije, ime najemnika in ID najemnika, znotraj našega portala naročnine. 
                  Te informacije so ključne za zagotavljanje storitev in so zaščitene z našimi strogimi politikami zasebnosti.
                  Bodite prepričani, naše prakse upravljanja podatkov so zasnovane tako, da zagotavljajo zaupnost vaših podatkov, s preglednostjo in nadzorom, ki sta trdno v vaših rokah.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Kako TeamConnect spoštuje predpise o varstvu podatkov, kot je GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect je usklajen z ureditvami o varstvu podatkov, kot so GDPR, HIPAA, CCPA in drugi, z izkoriščanjem lastnih funkcij skladnosti okolja Microsoft 365, 
                  kjer so vsi podatki strank varno shranjeni. Za razliko od tradicionalnih modelov SaaS, TeamConnect ne shranjuje nobenih podatkov strank na svojih strežnikih; namesto tega deluje znotraj 
                  okolja Microsoft 365 vaše organizacije, upoštevajoč njegove robustne standarde varnosti in skladnosti. Ta pristop zagotavlja, da ostaja upravljanje vaših podatkov skladno s 
                  celovitim okvirom skladnosti, ki ga nudi Microsoft 365, vključno z upoštevanjem GDPR, HIPAA za informacije o zdravstvenem varstvu, CCPA za prebivalce Kalifornije, 
                  in drugih globalnih predpisov o varovanju podatkov. Z integracijo, ki se brezhibno zlije z vašo obstoječo postavitvijo Microsoft 365, TeamConnect pooblašča vašo organizacijo, da ohrani 
                  popoln nadzor nad vašimi podatki, zagotavljajoč spoštovanje najstrožjih zakonov in predpisov o varstvu podatkov.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Kako TeamConnect obdeluje podatke iz klepetov, klicev, videoklicev, e-pošte Outlook in klicev telefonskega sistema v Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect izkorišča Microsoft Graph API za omogočanje neposrednih interakcij, kot so klepet, klici, videoklici in e-pošta Outlook znotraj Microsoft Teams,
                  kar zagotavlja neprekinjeno uporabniško izkušnjo. Ključno je, da čeprav TeamConnect sproži te komunikacije, to stori brez poseganja v proces komunikacije
                  ali zmožnosti snemanja ali shranjevanja katerekoli vsebine interakcije. Vsi pogovori, klici in e-pošte potekajo in se upravljajo neposredno znotraj
                  varnih meja Microsoft Teams in Outlook, v strogi skladnosti z robustnimi standardi varnosti in zasebnosti Microsofta 365. To zagotavlja, da TeamConnect izboljša
                  vaše komunikacijske sposobnosti, medtem ko popolnoma ohranja zaupnost in celovitost vaših podatkov.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Za podrobnejši vpogled v naše politike in prakse o podatkih se morda želite posvetovati z {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>Priročnikom za uporabnike TeamConnect</Body1Strong></Link> ali oddelkom za IT vaše organizacije na <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> za podrobnosti, ki se nanašajo na vašo konfiguracijo.
          </p>
        </Body1>        
        <p></p>  
        <Subtitle1 className={styles.fontColor}>Poslušamo: Delite svoje povratne informacije in ideje</Subtitle1>
        <Body1>
          <p>
            Vaši vpogledi poganjajo razvoj TeamConnect. Ne glede na to, ali ste polni briljantnih idej za funkcije ali imate povratne informacije o svoji trenutni izkušnji, smo vse ušesa. Vaš prispevek je neprecenljiv v našem nenehnem prizadevanju, da zagotovimo najboljšo možno storitev.
          </p>
        </Body1>      
        <Subtitle2>Povratne informacije in predlogi za funkcije</Subtitle2>
        <Body1>
          <p>
            Ste pripravljeni deliti? Obiščite našo {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              stran za povratne informacije
            </Link>
            . Ne glede na to, ali je to predlog za novo funkcijo ali povratne informacije o obstoječih funkcionalnostih, preprosto izberite 
            ustrezno možnost iz spustnega menija v obrazcu. Vaši prispevki pomagajo oblikovati prihodnost TeamConnect, 
            zagotavljajo, da izpolnjuje in presega vaše potrebe.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
