import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>Aðstoðarmiðstöð TeamConnect og algengar spurningar</Title3>
        <p><Body1>
          Velkomin(n) í aðstoðarmiðstöð TeamConnect, sem er hönnuð til að styðja við upplifun þína af alhliða fyrirtækjaskráarforritinu okkar. Hér finnur þú úrræði til að hámarka notkun þína á TeamConnect innan Microsoft Teams, sem eykur samskipti þín við netið þitt.
        </Body1></p>
        <Subtitle2>Fljótleg tilvísun leiðarvísir</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Auknar leitargetur: </Body1Strong>
              Finndu fljótt rétta samstarfsmenn, deildir og nauðsynlega tengiliði með öflugum leitartólum okkar.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Sjónræn stigveldiskort: </Body1Strong>
              Farðu um stigveldi skipulagsheildarinnar þinnar og skiljaðu tilkynningarlinur með einföldum stigveldiskortum.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Aðlögunarhæf verkfæri fyrir nútíma þarfir: </Body1Strong>
              Nýttu nútímalega virkni sem eru sniðin að kröfum nútíma vinnustaða.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Optimera upplifun þína</Subtitle2>
        <Body1>
          <p>
            TeamConnect byltingar aðferð þína við samskipti innan skipulagsheildar þinnar, breyta daglegum samskiptum í skipulögð áform.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Fáðu aðstoð og algengar spurningar</Subtitle1>
        <Body1>
          <p>
            Finndu svör við algengum spurningum um eiginleika og stillingar TeamConnect í þessum sérstökum kafla. Hvort sem þú ert að fletta um forritið, aðlaga stillingar, eða leita lausna við áskorunum, er aðstoðarmiðstöð okkar hér til að aðstoða þig á skilvirkan hátt.
          </p>
        </Body1>
        <Body1>
          <p>
            Fyrir ítarlegri stuðning eða sérstaka leiðsögn, vinsamlegast kíktu á <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>Notendaleiðbeiningar TeamConnect</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        
        <Subtitle2>Algengar spurningar um notkun TeamConnect</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Finnst ekki TeamConnect í hliðarstikunni á Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ef TeamConnect er ekki augljóslega sýnilegt eða hefur horfið í hliðarstikunni þinni á Microsoft Teams, getur þú enkfaldlega fest það þar til að auðvelda aðgang. Fylgdu þessum einföldum skrefum:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Opnaðu Microsoft Teams og smelltu á '...' (+ Apps) táknið neðst á hliðarstikunni.</li>
                    <li className={styles.bulletBottomMargin}>Leitaðu að 'TeamConnect' í leitarstikunni efst á sprettiglugganum.</li>
                    <li className={styles.bulletBottomMargin}>Hægri-smelltu á TeamConnect forritið úr leitarniðurstöðunum og veldu 'Pin'.</li>
                  </ol>
                  TeamConnect verður nú fest við hliðarstikuna þína, aðgengilegt með einum smelli!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Tekur á móti tæknivandræðum með TeamConnect? Hér er hvernig þú færð hjálp.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ef þú lendir í tæknivandræðum með TeamConnect innan Microsoft Teams er IT stuðningslið skipulagsheildarinnar þitt til að hjálpa.
                  Hafðu samband við þau á <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, og þau munu aðstoða þig fljótt.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Sérðu ekki starfsmannsupplýsingar þínar?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ef prófíllinn þinn virðist vera frekar tómlegur með einungis nafni þínu og tölvupósti sýnilegum, líklegt er að mannauðsdeildin þín hefur ekki enn bætt við frekari starfsmannsupplýsingum í TeamConnect.
                  Ekki hafa áhyggjur! Fljótur skilaboð til mannauðsstuðningsteam þíns munu setja hlutina af stað. Þeir eru tilbúnir að fylla út prófílinn þinn með öllum mikilvægum upplýsingum.
                  Hafðu samband við <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> og horfðu á prófílinn þinn lifna við!
                </Body1>
              </AccordionPanel>
            </AccordionItem>           
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Hvaða tungumálaúrræði eru í boði í TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect tekur utan um alþjóðlega fjölbreytni Microsoft Teams með því
                  að styðja við öll tungumál þess. Hvort sem þér líkar betur arabíska eða
                  gríska, japanska eða sænska, þá hefur þú heim af möguleikum við
                  fingurgómana, þar á meðal:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arabíska, búlgarska, kínverska (einfölduð og hefðbundin), króatíska,
                      tékkneska, danska, hollenska, enska, eistneska, finnska, franska,
                      þýska, gríska, hebreska, ungverska, indónesíska, ítalska, japanska,
                      kóreska, lettneska, litháíska, norska (Bokmål), pólska, portúgalska
                      (Brasilía og Portúgal), rúmenska, rússneska, serbneska (latneska),
                      slóvakíska, slóvenska, spænska, sænska, taílenska, tyrkneska,
                      úkraínska, víetnamska.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Til að sérsníða TeamConnect-upplifun þína að þínum kjörþörfum,
                  vinsamlegast lagfærðu tungumálastillingarnar beint í Microsoft Teams, þar
                  sem TeamConnect er fullkomlega samþætt með þessum stillingum:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Farðu í stillingar Microsoft Teams með því að smella á þrjá punktana
                      við hliðina á prófílmynd þinni efst, og velja síðan 'Útlit og
                      aðgengi'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Skrunaðu að 'Tungumál' hlutanum og veldu æskilegt tungumál úr
                      fellivalmyndinni.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Staðfestu breytingarnar þínar með því að smella á 'Vista og endurræsa'
                      hnappinn til að beita nýju tungumálastillingunum.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Athugið: </Body1Strong>
                <Body1>
                  Breytingar gerðar á tungumálastillingum þínum í Microsoft Teams munu
                  einnig uppfæra TeamConnect-upplifun þína áreynslulaust. Vertu meðvitaður
                  um að breytingar á tungumálinu í Teams gæti einnig aðlagað tímasetningu
                  þína og dagsetningarform. Um þessar mundir, er TeamConnect að þróast til
                  að styðja við skrift frá hægri til vinstri. Við erum spennt fyrir þessum
                  umbótum og munum halda þér upplýstum þegar þær verða tiltækar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Hvernig get ég breytt þema í TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect samstillir sig við þema valkosti Microsoft Teams, þar á meðal Ljóst, Dökkt og Háskerpu valkosti. Til að sérsníða þemað þitt:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Náðu aðgangi að stillingum Microsoft Teams með því að smella á þrjú punktana við hliðina á prófílmyndinni þinni efst.</li>
                    <li className={styles.bulletBottomMargin}>Veldu 'Útlit og aðgengi' til að skoða þema valkostina.</li>
                    <li className={styles.bulletBottomMargin}>Veldu þemað þitt af valkostunum sem eru í boði til að uppfæra viðmót TeamConnect samstundis.</li>
                  </ol>
                  Þema val þitt í Microsoft Teams verður sjálfkrafa beitt á TeamConnect, sem tryggir samræmda sjónræna upplifun yfir vinnusvæðið þitt.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Kynnum Okkur Aðgerðahnappa Sambönd í TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Um allt TeamConnect, hvar sem þú finnur upplýsingar um tengilið, munuð þið einnig uppgötva þægilega hnappa fyrir fljótlegan aðgang. Þessir handhægu tól 
                  eru hönnuð til að einfalda samskiptin þín innan Microsoft vistkerfisins, tryggja að þú sért alltaf aðeins einn smell frá því að tengjast. 
                  Hér er ítarleg leiðbeining þín um skilvirka notkun þessara hnappa:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Spjallhnappurinn:</Body1Strong> Smelltu til að hefja samstundis Microsoft Teams spjall. Ef þú þarft að fara aftur í TeamConnect eftir að hafa hafið spjall, notaðu einfaldlega aftur örina í skjáborðsappinu, afturhnappinn í fartölvuappinu eða afturhnapp vafrans þíns.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pósthnappurinn:</Body1Strong> Opnar nýjan Outlook tölvupóstdrátt með netfangi viðtakandans tilbúið í "Til:" reitnum. Ef Outlook ræsist ekki, er þess virði að athuga með upplýsingatæknideildinni um einhverjar takmarkanir.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Myndspjallhnappurinn:</Body1Strong> Hefur andlit til andlits myndspjall með Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Símtalshnappurinn:</Body1Strong> Hefur Microsoft Teams raddhringingu, sem gerir fljótlegar umræður einfaldar.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Skipuritshnappurinn:</Body1Strong> Sýnir staðsetningu starfsmannsins í skipuritinu, þar með talinn yfirmaður hans og beinir skýrsluhafa.</li>
                    <li><Body1Strong>Fleiri (...) hnappurinn:</Body1Strong> Birtir frekari valkosti:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Hittast Núna:</Body1Strong> Opnar Microsoft Teams til að hefja strax fund með starfsmanninum.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Nýr Fundur:</Body1Strong> Beinir þér til Microsoft Teams til að áætla nýjan fund.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Bæta við Tengilið:</Body1Strong> Bætir við tengiliðsupplýsingum starfsmannsins úr TeamConnect í nýja fólk appið þitt í Microsoft Teams, auðgandi símaaðgerð þína.</li>
                      </ul>
                    </li>
                  </ul>
                  Þessir innsæisverðu hnappar eru hannaðir til að tryggja að þú getir skilvirkt tengst samstarfsfólki, hvort sem er fyrir fljótlegt spjall, tölvupóst eða til að áætla framtíðarfundar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Hringja í gegnum TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Viljir þú hringja? TeamConnect einfaldar samskipti þín með því að samþætta við Microsoft Teams. 
                  Smelltu einfaldlega á símanúmerið sem þú langar í TeamConnect, og voilà, hringviðmót Microsoft Teams birtist,
                  tilbúið fyrir þig til að hringja út áreynslulaust.
                  <p>
                  <Body1Strong>Athugið: </Body1Strong> 
                  Lendir þú í vandræðum með að framkvæma hringingar? Þetta gæti stafað af IT stefnu fyrirtækis þíns eða notkun á símkerfi sem er ekki Microsoft Teams símkerfi. Tenging við upplýsingatæknideildina þína á <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, gæti varpað ljósi á málið.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Eru einhver kostnaður tengdur því að hringja í gegnum TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect bætir hringjaupplifun þína með því að nota Microsoft Teams. Hér er það sem þú þarft að vita um kostnað tengdan símtölum:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Fyrir símtöl til vinnusíma starfsmanna, deildarsíma eða neyðarlína innan skipulagsheildarinnar þinnar (innri númer), 
                      eru venjulega <Body1Strong>engin gjöld</Body1Strong> þegar notast er við Microsoft Teams Phone System.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Fyrir ytri símtöl, svo sem til farsíma eða útlína, gilda <Body1Strong>staðlaðir gjaldskrár</Body1Strong> símkerfis skipulagsheildarinnar þinnar. 
                      Þessar gjaldskrár geta verið mismunandi eftir því hvaða þjónustupakka þú hefur hjá Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Athugið:</Body1Strong> Ef þú lendir í vandamálum eða hefur spurningar um símgjöld, er best að ráðfæra þig við 
                    upplýsingatæknideildina þína á <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> fyrir nákvæmar upplýsingar 
                    sniðnar að uppsetningu og reglum skipulagsheildarinnar þinnar.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Finnst þér ekki passa saman við stöðu þína 'Úti á skrifstofu' í TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tókst eftir að stöðu þín 'Úti á skrifstofu' frá Microsoft 365 endurspeglast ekki rétt í TeamConnect? 
                  Við höfum þegar innleitt eiginleika til að samstilla 'Úti á skrifstofu' stöðuna í TeamConnect.
                  Við erum nú að bíða eftir útrás þessa eiginleika frá Microsoft. Þegar það gerist, 
                  mun stöðu þín 'Úti á skrifstofu' endurspeglast nákvæmlega í TeamConnect, í samræmi við viðverusjá Microsoft Teams fyrir samfellda upplifun.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>Algengar spurningar um starfsmannaskrá</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Inngangur að starfsmannaskrá:</Body1Strong> Skoðaðu faglegt landslag skipulagsheildar þinnar með starfsmannaskránni, 
                þínu miðstöð fyrir ítarlegar upplýsingar um starfsmenn, vinnuumhverfi og beint tengsl við prófíla og stjórnunarleg tengsl samstarfsmanna þinna.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Þarftu að bæta við, uppfæra eða leiðrétta starfsmannaupplýsingar þínar?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Til að bæta við, uppfæra eða leiðrétta starfsmannaupplýsingar þínar í TeamConnect, vinsamlegast hafðu samband við mannauðsstuðning fyrirtækis þíns. 
                  Þeir eru tilbúnir að aðstoða á <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Hvað gerist þegar ég smell á nafn starfsmanns í skránni?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Smellir þú á nafn starfsmanns í TeamConnect Starfsmannaskrá opnast ítarleg prófílsíða fyrir þann einstakling. Þessi prófíll veitir ítarlegar upplýsingar um starfsmanninn, meðal annars en ekki einskorðað við, starfsheiti, deild, staðsetningu skrifstofu, tengiliðsupplýsingar og fleira. Hann er hannaður til að gefa þér heildaryfirlit yfir starfshlutverk starfsmannsins og hvernig hann fellur inn í skipulagsheildina, sem gerir þér auðveldara að skilja verkaskiptingu þeirra og hvernig þú gætir unnið með þeim.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Hvaða upplýsingar eru innifaldar í prófíl starfsmanns?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hver starfsmannaprófíll í TeamConnect er heildstæð yfirlitsmynd sem býður upp á mikinn fjölda upplýsinga um faglegt líf einstaklingsins. 
                  Hér er það sem þú munt finna:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Grunnupplýsingar:</Body1Strong> Nafn, starfsheiti, deild og staðsetning undirfyrirtækis.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Skipurit:</Body1Strong> Upplýsingar um beinar og starfsmannaskipulagsskyldur skýrslur, 
                      þar á meðal yfirmenn í línu og starfsfuntion.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vinnustaða:</Body1Strong> Núverandi vinnustaða svo sem í leyfi, á leiðinni, 
                      í viðskiptaferð, o.s.frv.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vinnustaður & Staðartími:</Body1Strong> Núverandi vinnustaður starfsmanns og 
                      staðbundin tímasvæði.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Samskiptaupplýsingar:</Body1Strong> Vinnusími, farsími og netfang.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Skrifstofuheimilisfang:</Body1Strong> Fullkomið skrifstofustaðsetning með húsnafni,
                      heimilisfangi og Google korti.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Um:</Body1Strong> Stutt persónuleg kynning eða faglegt æviminning.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Starfsmannatengsl:</Body1Strong> Upplýsingar um skýrslutengsl starfsmanns 
                      og tengsl við teymið.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Félagsleg tengsl & Tungumál:</Body1Strong> Tenglar á fagleg félagsleg prófíl 
                      og tungumálakunnáttu.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Hæfnisvið:</Body1Strong> Listi yfir faglega færni og sérþekkingarsvið.</li>
                  </ul>
                  Auk þess býður prófíllinn upp á virkan valkosti til að tengjast í gegnum spjall, tölvupóst, vídeóhringingu eða skipuleggja fundi, ásamt
                  fljótlegum aðgangi að skipuriti fyrirtækisins fyrir víðara samhengi teymis.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Hvernig get ég uppfært upplýsingar á prófíl mínum?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Það er auðvelt að sérsníða prófílinn þinn í TeamConnect. Hér er hvernig þú getur gert það:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Farðu í <Body1Strong>Starfsmann</Body1Strong> flipann og smelltu á <Body1Strong>Avatar</Body1Strong> þinn til að opna prófílinn þinn.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Í efra vinstri horninu finnur þú <Body1Strong>"Breyta prófílnum þínum"</Body1Strong> hlekkinn. 
                        Smellur á þetta opnar breytingargluggann fyrir prófílinn þinn.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Hér getur þú bætt við prófílnum þínum með því að bæta við eða uppfæra <Body1Strong>Um þig</Body1Strong> hlutanum, félagsmiðlatenglum og símanúmeri. 
                        Þetta gerir það auðveldara fyrir samstarfsfélaga þína að tengjast þér eða fylgja þér.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Ekki gleyma að ýta á <Body1Strong>Vista</Body1Strong> hnappinn til að virkja breytingarnar þínar.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Skrollaðu niður í samtalinu til að bæta við <Body1Strong>Tungumálum</Body1Strong> þínum og <Body1Strong>Hæfni</Body1Strong>, 
                        sem og <Body1Strong>Færni</Body1Strong> þína. Að deila þessum upplýsingum hjálpar samstarfsfólki þínu að skilja 
                        sérsvið þitt og á hvaða tungumálum þú getur tjáð þig.
                      </li>
                    </ol>
                  Það að uppfæra prófílinn þinn heldur ekki aðeins upplýsingum þínum nútímalegum heldur einnig stuðlar að betri tengslum innan skipulagsheildarinnar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Uppfæra vinnustað, vinnusvæði og tímabelti í TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Það er mikilvægt að halda upplýsingum um vinnustað, vinnusvæði og tímabelti uppfærðum til að auðvelda samvinnu í Microsoft Teams. 
                  Þessar uppfærslur endurspeglast samstundis í prófíl þínum og skrám í TeamConnect, sem gerir samstarfsfólki kleift að skilja 
                  vinnustaðinn þinn fljótt og bestu tímana til að tengjast þér, og bætir samvinnu milli mismunandi svæða og tímabelta.
                  <p></p>
                  Tilbúin(n) til að uppfæra upplýsingarnar þínar? Það er auðvelt í TeamConnect! Farðu í 'Stillingar' í efsta valmyndinni og navigeraðu til 'Almennar stillingar'. 
                  Þar getur þú auðveldlega uppfært Vinnustað og Tímabelti þitt, og tilgreint Vinnusvæði dagsins. Mundu að vista breytingarnar til að halda öllum upplýstum og 
                  tryggja árangursríka samvinnu, sama hvar þú ert staddur/stödd!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Sást eitthvað skrítið í prófíl samstarfsfélaga?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ef þú tekur eftir einhverjum upplýsingum í prófíl samstarfsfélaga sem virðast ekki alveg réttar, þá er besta fyrsta skrefið að veita þeim 
                  vingjarnlega ábendingu. Stutt spjall eða tölvupóstur ætti að duga! Ef af einhverjum ástæðum uppfærist upplýsingarnar ekki, ekki hafa áhyggjur—bara 
                  sendu línu til mannauðsstuðningsliðsins þíns á <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, og þau verða ánægð 
                  með að koma og laga málin.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Skilningur á merkjahópum: Allir, Uppáhalds, Innskráðir og Nýir starfsmenn
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Allir:</Body1Strong> Þessi sjálfgefna merki sýnir alla starfsmenn í fyrirtækinu þínu,
                      og gefur heildaryfirsýn yfir teymið.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Uppáhalds:</Body1Strong> Smelltu hér til að sía útsýnið þitt til þeirra samstarfsmanna sem þú hefur 
                      merkt sem 'Uppáhalds,' sem auðveldar þér að tengjast lykilkontaktum hratt.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Innskráðir:</Body1Strong> Þetta merki bendir til starfsmanna sem eru virkir í vinnu, frábrugðið
                      einungis netveru í Microsoft Teams. Þegar innskráðir, gefa starfsmenn til kynna að þeir séu í vinnu og deila núverandi vinnustaðsetningu 
                      yfir daginn. Þetta getur innifalið ýmsa vinnustaði eins og Skrifstofu, Vettvangsskrifstofu, Heimaskrifstofu, Fjarvinnustað, Viðskiptavinarvinnustað, 
                      Einkaskrifstofu viðskiptavinar, Almenna svæðið, Ferðalög, Viðskiptaferðir eða jafnvel Þjálfun. Þar af leiðandi veitir 'Innskráður' staða skýra vísbendingu 
                      um að vera virkur í vinnu, og býður samstarfsmönnum nákvæma skilning á núverandi vinnusamhengi og aðgengi hvers annars.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nýir starfsmenn:</Body1Strong> Greindu ný andlit í TeamConnect með þessu merki, sem hækkar 
                      starfsmenn sem hafa verið bætt við forritið innan síðustu 30 daga. Venjulega samræmist þetta við nýlegar viðbætur við fyrirtækið þitt, hjálpar 
                      þér að taka á móti nýjum teymismeðlimum.
                    </li>
                  </ul>
                  Þessir merkjahópar eru hannaðir til að einfalda leiðsögn og bæta upplifun þína á TeamConnect, og tryggja að þú getir alltaf 
                  fundið og tengst samstarfsmönnum þínum á skilvirkan hátt.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Afkóða táknin við hliðina á leitarkassanum
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Starfsmannaskrá TeamConnect er hönnuð til sveigjanleika og einfaldleika, með fjórum táknum sem bjóða upp á mismunandi útsýni 
                  og fljótlegan aðgang að lykilupplýsingum. Hér er hvað hvert tákn afhjúpar:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Listayfirlit:</Body1Strong> Þitt fyrirvalda útsýni, sýnir alla meðlimi stofnunar 
                      í beinni lista.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lítil kortayfirlit:</Body1Strong> Þjappaður valkostur, þetta útsýni breytir 
                      listanum í bitastærð starfsmannakort.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Stórt kortayfirlit:</Body1Strong> Veldu fyrir meiri smáatriði með stærri kortum, sem fela í sér 
                      viðbótarsamskiptamöguleika eins og síma og farsímanúmer til auðveldrar hringingar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Stofnanakort:</Body1Strong> Sjónræna uppbyggingu fyrirtækisins með Stofnanakortútsýninu, 
                      fullkomið til að skilja tilkynningarsambönd og fletta um skipulagslegt landslag.</li>
                  </ol>
                    Skiptu á milli þessara tákna til að finna útsýnið sem hentar þörfum þínum á þeim tíma, hvort sem þú ert að leita að fljótvirkum upplýsingum eða dýpri innsýn.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Hvar á ég að leita að samstarfsmönnum hratt?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ertu að leita að samstarfsmanni? Farðu bara að leitarkassanum í TeamConnect, sem staðsettur er á hægri hliðinni efst við hliðina á Org kort táknið. 
                  Byrjaðu á að slá inn nafn þeirra, og horfðu á því hvernig niðurstöðurnar þrengjast með hverjum staf. Mundu, fyrir leit í TeamConnect, slepptu leitarstikunni í Microsoft Teams 
                  efst—okkar sérstaki leitarkassi er leiðin að fara!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Hvernig virkar stafrófsleit við að finna starfsmenn?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Starfsmannaskrá TeamConnect inniheldur hentuga stafrófsleitarleið til að hjálpa þér að finna starfsmenn hratt eftir fornafni. 
                  Þú þarft aðeins að smella á bókstaf frá stafrófinu sem birtist efst í skránni, og TeamConnect mun strax sýna þér alla starfsmenn 
                  sem fornöfn byrja á þeim bókstaf. Til að fara aftur í heildarlistann yfir starfsmenn, afvelur bara áherslubókstafinn. Þessi innsæi 
                  eiginleiki gerir það einfalt að fletta í gegnum langa lista yfir starfsmenn, tryggir að þú getir fundið samstarfsfélaga hratt og skilvirklega.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Hvernig get ég breytt fjölda starfsmanna sem birtast á hverri síðu?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect leyfir þér að sérsníða hversu margar starfsmannaprófíl sýna á hverri síðu, gerir það auðveldara að fletta í gegnum Starfsmannaskrá. 
                  Neðst á hverri síðu finnurðu valkosti fyrir blaðsíðutal: 10, 50, 100, 500, 1000 og 5000. Sjálfgefin stilling sýnir 10 prófíl á síðu. 
                  Til að skoða fleiri prófíla á einni síðu, velur þú bara æskilegan fjölda úr þessum kostum. Þessi eiginleiki veitir sveigjanleika í hvernig þú 
                  skoðar skrána, gerir þér kleift að aðlaga birtinguna að þínum sérstökum þörfum eða valmöguleikum.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Hvernig raða ég upplýsingum um starfsmenn í List View?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Í List View starfsmannaskrár TeamConnect hefur þú sveigjanleika til að raða upplýsingum um starfsmenn eftir þörfum þínum. Dálkarnir—Nafn starfsmanns, 
                  Starfsheiti, Deild, Staðsetning skrifstofu og Vinnustaður—eru allir flokkunarhæfir. Smelltu einfaldlega á haus dálksins sem þú vilt raða eftir. Smella einu sinni 
                  raðar dálknum í hækkandi röð, og að smella aftur raðar honum í lækkandi röð. Sjálfgefið er að skráin sé raðað eftir nafni starfsmanns í hækkandi röð, 
                  byggt á fornafni. Þessi flokkunaraðgerð hjálpar þér að skipuleggja og skoða upplýsingar um starfsmenn á þann hátt sem best þjónar tilgangi þínum, hvort sem þú eru að 
                  leita að ákveðinni manneskju eða þarft að flokka starfsmenn eftir tilteknum flokki eins og deild eða staðsetningu.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Algengar spurningar um deildaskrá</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Kynning á deildaskrá:</Body1Strong> Deildaskráin er leið þín til að skilja flókna skipulagsheild þinni, og býður upp á skýra sýn á deildarvaldastig, stjórnunarleiðtogar og leiðir til þess að auðvelda samskipti á milli deilda.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Hvernig virka 'Öll', 'Uppáhalds', og 'Þín deild' merkin í deildaskrá?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Í Deildaskrá TeamConnect, hjálpa merki þér að flýta fyrir leit og síun upplýsinga um deildir. Hér er hvað hvert merki táknar:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Allt:</Body1Strong> Þetta merki sýnir allar deildir innan skipulagsheildarinnar, og veitir 
                      heildstætt yfirlit.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Uppáhalds:</Body1Strong> Notaðu þetta merki til að skoða vönduðan lista yfir deildir sem þú hefur 
                      merkt sem 'Uppáhalds', sem gerir það auðveldara að nálgast deildir sem þú ert oft í samskiptum við.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Þín deild:</Body1Strong> Þetta merki sýnir einungis þína deild, 
                      og auðveldar aðgang að upplýsingum um þína deild og uppfærslum.</li>
                  </ul>
                  Þessi merki eru hönnuð til að einfalda leiðsögn þína innan deildaskrárinnar, og bæta getu þína til að finna og nýta upplýsingar um deildir skilvirkt.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Skilningur á yfirlitum deildaskrár í TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Það er auðvelt að navigera í gegnum deildaskrá í TeamConnect með fjölbreyttum valmöguleikum á yfirlitum sem hentar þínum óskum. Hvert yfirlit býður upp á einstakan hátt til að könnuð ítarlegar upplýsingar um deildir, þ.mt. nafn deildar, deildarstjóra, yfirdeild, símanúmer deildar, neyðarlínu deildar, og netfang deildar. Hér er yfirlit yfir tiltækar yfirlitsgerðir:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Listayfirlit:</Body1Strong> Sjálfgefið uppsetning sem raðar öllum deildum prýðilega, veitir 
                      skýrt og greinargott yfirlit.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Stórt spjaldayfirlit:</Body1Strong> Skiptu yfir í þetta yfirlit til að sjá ítarlegar upplýsingaspjöld um hverja deild, sem bjóða upp á útvíkkaða úttekt á helstu upplýsingum hverrar deildar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Skipuritsyfirlit:</Body1Strong> Fyrir myndræna framsetningu á skipulagsstrúktúr og 
                      tengslum deilda, sýnir skipuritsyfirlitið hvernig hver deild fellur inn í stærri skipulagsheild.
                    </li>
                  </ul>
                  Til að skipta á milli þessara yfirlita, notaðu einfaldlega táknin við hlið leitarreitsins efst í deildaskrá. Hvert yfirlit er hannað til að hjálpa þér að nálgast og skilja upplýsingar um deildir með auðveldi, tryggjandi að þú finnir upplýsingarnar sem þú þarft í sniðinu sem hentar þér best.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Hvernig á að nota leitargluggann í deildaskránni
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Finndu auðveldlega hvaða deild sem er innan TeamConnect með því að nota helgaðan leitarglugga staðsettan rétt við hliðina á skoðunaríkonunum efst á 
                  deildaskránni. Byrjaðu einfaldlega að slá inn nafn deildarinnar sem þú leitar að, og horfðu á þegar skráin þrengir sig til að sýna aðeins 
                  viðeigandi niðurstöður. Þessi eiginleiki er sérstaklega hannaður til að hjálpa þér að sleppa því að þurfa að fara handvirkt í gegnum alla skránna, og færir 
                  þá deild sem þú þarft beint undir fingurgómana á þér. Mundu, þessi leitarvirkni er einstök fyrir deildaskrá TeamConnect, svo vertu viss 
                  um að nota þennan leitarglugga í staðinn fyrir aðal Microsoft Teams leitina efst á Teams viðmótinu fyrir fyrirspurnir tengdar deildum.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Hvernig virkar stafrófsröðunin í deildaskránni?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Fáðu betri yfirsýn yfir leitina þína í deildaskránni með stafrófsröðun sem staðsett er efst. Þessi eiginleiki gerir þér kleift að þrengja niður deildir með því að velja staf. Þegar þú hefur valið staf sér TeamConnect um að sía og aðeins sýna þær deildir sem nöfn byrja á valda stafnum, sem gerir leitina þína auðveldari án þess að þurfa að fletta í gegnum allan listann. Til að snúa aftur til heildarsýnar deilda, afvelurðu einfaldlega hápunktinn staf. Þessi innsæisröðun stafrófa tryggir að þú getir fljótt fundið hvaða deild sem er eftir upphafsstaf hennar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Hvernig get ég haft samband við deildarstjóra?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Það er einfalt að hafa samband við deildarstjóra í TeamConnect. Finndu einfaldlega deildina sem þú hefur áhuga á og smelltu á nafn stjórans. Þessi 
                  aðgerð mun kveikja á sprettiglugga sem sýnir samskiptaval deildarstjórans með kunnuglegum flýtiaðgangstáknum:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Spjalltákn:</Body1Strong> Til að hefja strax skilaboðaskipti í gegnum Microsoft Teams spjall.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pósttákn:</Body1Strong> Til að opna nýjan tölvupóst sem stílaður er á viðkomandi í Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Myndbandssímtalstákn:</Body1Strong> Til að hefja myndbandssímtal í gegnum Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Símtalstákn:</Body1Strong> Til að byrja raddhringingu í gegnum Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Fleira (...) tákn:</Body1Strong> Fyrir auka valkosti eins og að skipuleggja fund eða að bæta við tengiliðaupplýsingum þeirra í Teams listann þinn.
                    </li>
                  </ul>
                  Þessi tákn veita þægilega aðferð til að nálgast deildarstjóra beint í gegnum TeamConnect, tryggjandi skilvirka samskipti innan fyrirtækisins þíns.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Hvernig get ég skoðað eða haft samband við starfsmenn innan deildar?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Til að kanna hver er hver innan deildar í TeamConnect, leitaðu ekki lengra en þrjá punkta við hliðina á nafni deildarinnar. Smelltu á þetta til að 
                  fá upp valkosti fyrir frekari aðgerðir:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Skoða starfsmenn í þessari deild:</Body1Strong> Veldu þetta til að birta lista yfir alla 
                      starfsmenn tengda deildinni. Tölustafurinn við hliðina á þessum valkosti gefur til kynna hversu margir starfsmenn eru í deildinni og veitir fljóta mannahöfðatölu.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Deildar skipurit:</Body1Strong> Ef þú vilt skilja hierarkíu deildarinnar, 
                      veldu 'Deildar skipurit' til að afhjúpa uppbyggingu skipulagsins, sem sýnir tengsl milli yfirdeilda, deildarinnar í spurn og allra undirdeilda. Þessi sjónræna framsetning hjálpar þér að átta þig á og skilja skipulagsrammann og auðveldar þér að bera kennsl á og hafa samband við tiltekna deildarmeðlimi.
                    </li>
                    <li><Body1Strong>Deild {'>'} Starfsmaður:</Body1Strong> Eftir að hafa skoðað starfsmenn innan deildar, notaðu þessa brauðmylsnaleið til að auðvelda 
                      afturför til listans yfir deildir, viðhald góðrar könnunarreynslu innan TeamConnect.
                    </li>
                  </ul>
                  Þessir eiginleikar gera þér kleift að sjá ekki aðeins lista yfir starfsmenn í tiltekinni deild heldur bjóða þér líka leið til að ná beint sambandi við þá í gegnum samskiptatól TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Upplýsingar um deildir ítarlega í TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Könnuðu smáatriði hvaða deildar sem er með einfaldri smell á nafn hennar í TeamConnect. Ítarlegt samtal birtist, sem býður upp á ríka mörgæs af 
                  upplýsingum sem mála heildarmynd af hlutverki og auðlindum deildarinnar innan fyrirtækisins. Þú finnur allt frá stjórnun og fjármálavísum til samskiptaleiða og tengla við samfélagsmiðla. Þessi eiginleiki er þinn innkaupalisti til að:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Hjarta deildarinnar – tilgangur hennar, fólk og frammistöðumælingar.</li>
                    <li className={styles.bulletBottomMargin}>Samskiptaleiðir – beinar línuþræðir til lykilfígúra í deildinni og samskiptapalla.</li>
                    <li className={styles.bulletBottomMargin}>Skipulagsnetið – skilningur á því hvernig deildin samþættist breiðara fyrirtækisbyggingunni.</li>
                  </ul>
                  Hvort sem það er fljótur fyrirspurn eða djúpt kafa í gögn deildarinnar, TeamConnect býður þér upp á tækin til að tengjast og skilja einstök vistkerfi innan fyrirtækisins.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Forvitin um fjarveru deildarheima í TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Að grafa upp staðsetningu deildar getur stundum fundist eins og leit að fjársjóði án korta. Í TeamConnect finnur þú yfirleitt ekki helgaðar heimilisföng fyrir hverja deild. Hér er af hverju þessi landfræðilegu smáatriði eru oft sleppt:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Mörg svæði:</Body1Strong> Margar deildir eru ekki einskorðaðar við eitt rými; þær
                      blómstra á mismunandi skrifstofum og landfræðilegum svæðum.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sveigjanlegt bygging:</Body1Strong> Skipulagslandslagið er alltaf að breytast, með 
                      því að deildir þróast, sameinast eða jafnvel leysast upp í samræmi við stefnumótandi breytingar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Millideildarsamstarf:</Body1Strong> Nútíma vinnurými eru full af samstarfsanda, oftast blandað hæfileikum úr ýmsum deildum í sameiginlegum svæðum.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Fjarskiptavinna menning:</Body1Strong> Með sveigjanleika fjarskiptavinnu eru deildarankrar ekki alltaf sett niður í líkamlegum skrifstofustöðum.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lagaleg blæbrigði:</Body1Strong> Deildir snúast yfirleitt um lagalega uppbyggingu fyrirtækisins frekar en að vera með sín eigin stöðug heimilisföng.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Rekstrarútlínutölur:</Body1Strong> Að halda fókus á fjörugt samstarf, ekki kyrrstæðar heimilisföng, hjálpar við að viðhalda rekstrarhæfni.
                    </li>
                  </ul>
                  Þetta snýst allt um að faðma landamæralausa nálgun til samstarfs og skilvirkni. TeamConnect endurspeglar þessa hugsjón með því að leggja áherslu á tengingar fremur en staðsetningar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Vantar upplýsingar um deildina þína eða eru þær úreltar?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Deildarritstjórar eru tilnefndir innan skipulagsheildarinnar til að bæta við, uppfæra eða leiðrétta sértæk deildarupplýsingar í TeamConnect.
                  Ef þú ert ekki viss um hver deildarritstjóri þinn er eða ef ákveðnar upplýsingar þarfnast íhlutunar teymis stuðnings deildar mannauðs, 
                  vinsamlegast hafðu samband við <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> til leiðsagnar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Algengar spurningar um skrifstofuskrá</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Kynning á skrifstofuskrá:</Body1Strong> Kynntu þér stoðkerfi fyrirtækisins í gegnum skrifstofuskrána, 
                sem inniheldur mikilvægar upplýsingar um skrifstofur, rekstrarupplýsingar og ítarlega yfirsýn yfir deildir og starfsfólk sem skilgreinir hvern stað.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Leiðsagnir um merki í skrifstofuská
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Lærðu að fylgjast með skrifstofuskánni með auðveldum hætti með því að nota kerfið okkar sem er hannað fyrir fljótlegan aðgang að rétta staðsetninguna. Hérna er hvernig hvert merki getur hjálpað til við að einfalda leit þína:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Allt:</Body1Strong> Sýnir heildarlista yfir skrifstofur, veitir yfirsýn yfir 
                      staðsetningar skipulagsheildar þinnar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Uppáhalds:</Body1Strong> Hjálpar þér að einbeita þér að þeim skrifstofustaðsetningum sem þú ferð oft á eða þarft 
                      að halda sérstaklega vel utan um, með því að merkja þær sem 'Uppáhalds'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Þín Skrifstofa:</Body1Strong> Þessi persónulega merki síur skrána til að sýna þér skrifstofustaðsetninguna þar sem þú ert staddur, 
                      halda heimastöðinni þinni við handarbreidd.
                    </li>
                  </ul>
                  Hvort sem þú ert að bóka fundarherbergi eða skipuleggja heimsókn, bjóða þessi merki upp á persónulegan og einfaldaðan hátt til að eiga við landfræðilegt landslag skipulagsheildarinnar þinnar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Námskeið í skjalasafni skrifstofa í TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Kynntu þér skipulag fyrirtækisins á hinum ýmsum staðsetningum með Skjalasafnsýn skrifstofa í TeamConnect. Hver sýn er hönnuð til að auðvelda 
                  sérstaka þætti skrifstofuflakk og skilnings:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Listasýn:</Body1Strong> Sjálfgefið stilling sem skipuleggur allar skrifstofustaðsetningar í 
                      auðlesanlegan lista.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Stór Kortsýn:</Body1Strong> Stóra Kortsýnin í Skjalasafni auðgar sýn þína með ítarlegri 
                      kynningu á hverri skrifstofu. Þegar þú skoðar skrifstofukort, muntu finna fjölda upplýsinga og virkra valkosta 
                      innan "Meira (...)" valmyndar:
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Skoða deildir:</Body1Strong> Beint aðgengi að lista
                          yfir allar deildir innan skrifstofunnar.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Skoða starfsmenn:</Body1Strong> Nálgast fullan lista yfir starfsmenn sem eru 
                          úthlutaðir til skrifstofunnar, sem auðveldar beint samskipti eða könnun á starfsfólki skrifstofunnar.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Myndrit fyrirtækiseininga:</Body1Strong> Visualize staðsetningu skrifstofunnar innan 
                          stærri skipulagslegs samhengis til að skilja hierarchískar byggingar betur.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Nauðsynlegir tengiliðir:</Body1Strong> Greina helstu tengiliði skrifstofunnar fyrir 
                          hnitmiðaða samskipti.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Afmælisdagar skrifstofunnar:</Body1Strong> Vera upplýstur um sérstaka almenna frídaga 
                          skrifstofunnar til að skipuleggja dagatal þitt í samræmi við það.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Opnunartími skrifstofunnar:</Body1Strong> Skoða opnunartíma skrifstofunnar til að samhæfa 
                          heimsóknir þínar eða símtöl við virkan tíma þeirra.
                        </li>
                      </ol>
                      Þessi sýn veitir ekki aðeins sjónrænt og upplýsandi yfirlit heldur einnig fljótan aðgang að ítarlegri innsýn og beinum aðgerðum tengdum skrifstofunni, allt fallegt undir "Meira (...)" valmöguleikum þínum til þæginda.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Myndrit Sýn:</Body1Strong> Sjá mynd af hierarchískri uppbyggingu yfirmanna og undirmannaskrifstofa 
                      til að skilja tengslin milli mismunandi skrifstofustaðsetninga innan fyrirtækisins.
                    </li>
                  </ul>
                  Skiptu á milli þessara sýna með því að nota táknin við hlið leitarkassans til að aðlaga vafrarupplifun þína að þörfum þínum.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Finna skrifstofur hratt með leitaraðgerð TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Það er auðvelt að finna rétta skrifstofustaðsetningu innan TeamConnect með helguðu leitaraðgerðinni. Staðsett áberandi við hlið sjónarmerkjanna, er leitarkassinn þitt verkfæri til að pinpointa skrifstofur eftir nafni. Eftir því sem þú slærð inn, hreinsar skráin sýninguna til að sýna aðeins skrifstofur sem passa við inntakið þitt, einfaldar leitarferlið. Mikilvægt er að nota þennan leitarkassa innan TeamConnect fyrir nákvæmustu og viðeigandi niðurstöður, í staðinn fyrir almenna leit Microsoft Teams. Með þessari miðaða leitaraðgerð getur þú farið fljótt til hvaða skrifstofu sem er í neti fyrirtækisins þíns.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Að fletta skrifstofuskránni stafrofsröð
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ef þú kýst að leita eftir stafrófi, þá er stafrófsfilter okkar fljótleg leið til að finna skrifstofur eftir nafni. Efst í skrifstofuskánni muntu 
                  sjá stafi frá A til Z. Þegar þú smellir á staf, sýnir það aðeins þær skrifstofur sem byrja á þeim staf, sem einfaldar leitina 
                  þína. Til að endurstilla og sjá allar skrifstofur aftur, einfaldlega afveltu virka stafinn. Þessi eiginleiki er sérstaklega gagnlegur þegar þú þekkir 
                  nafn skrifstofunnar en þarft fljótlegan hátt til að staðsetja hana innan TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Kynning á Ítarlegum Skrifstofuprófílum í TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Leggðu af stað í könnunarferð í gegnum hjarta líkamlegrar nærveru skipulagsheildar þinnar með skrifstofuprófílum TeamConnect. 
                  Þegar þú smellir á nafn skrifstofu birtist litríkt prófílgluggi, fullur af flipum sem innihalda ítarlegar upplýsingar um skrifstofuna:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Yfirlit:</Body1Strong> Kafaðu í kjarna skrifstofunnar með ítarlegum 
                      lýsingum, allt frá rekstarbyggingu til opnunartíma og staðartíma, sem endurspeglar kjarnann í skrifstofuumhverfinu.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nauðsynlegir Tengiliðir:</Body1Strong> Aðgengilegir bráðabirgðaþjónustur og 
                      ytri stuðningur, hver aðeins í einum smelli í gegnum Microsoft Teams Phone System.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Skrifstofutengiliðir:</Body1Strong> Náðu tengslum við innri stuðningsþjónustur auðveldlega, með 
                      upplýsingum um tengiliði og beinum samskiptamöguleikum rétt við handarbragðið.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Almennir Frídagar:</Body1Strong> Verðu meðvitaður um skrifstofusértæka frídaga, tryggðu að 
                      áætlanir þínar samræmist staðbundnum athöfnum og vinnuskemum.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Opnunartímar Skrifstofu:</Body1Strong> Skipuleggðu samskipti þín með nákvæmum þekkingu á því hvenær 
                      skrifstofan er iðandi af starfsemi eða fer smám saman að draga úr sér fyrir daginn.
                    </li>
                  </ul>
                  Hvort sem það er iðandi orka Austin eða strategísk miðstöð Singapore, hver flipi býður upp á leið til að skilja og tengjast fjölbreyttum 
                  staðsetningum sem mynda alþjóðlega viðveru fyrirtækisins.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Skoða deildardynamík innan skrifstofu
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Að sjá um faglegt net skrifstofudeilda er auðvelt í TeamConnect. Hér er hvernig þú getur kafað í smáatriðin:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Skoða deildir í þessari skrifstofu:</Body1Strong> Kynntu þér allar deildirnar sem mynda 
                      vef skrifstofunnar með því að velja þennan möguleika úr fleiri (...) valmyndinni.
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Skipulagsleg innsýn:</Body1Strong> Smelltu á hvaða deild sem er til að opna skipuritið hennar fyrir 
                      myndræna framsetningu á byggingu hennar, með leiðsagnarbrauðmylsnum sem leiðir þig: Skrifstofa {'>'} Deild {'>'} Skipurit.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Dýpkun á deild:</Body1Strong> Kannaðu deild nánar með því að smella á 
                      fleiri valmyndina (...) við hliðina á nafni deildarinnar. Hér getur þú nálgast lista yfir alla starfsmenn innan þessarar deildar, rétt í samhengi skrifstofunnar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Brauðmylsnaleiðsögn:</Body1Strong> Ferðast auðveldlega í gegnum stigin. Frá 
                      starfsmönnum aftur til deilda eða frá skipuritinu, brauðmylsnuslóðin (Skrifstofa {'>'} Deild {'>'} Starfsmaður {'>'} Skipurit) heldur ferðalaginu þínu 
                      innsæi og á réttri leið.
                    </li>
                  </ul>
                  Flóknar leiðir TeamConnect bjóða þér að kanna, skilja og taka þátt í hverju lagi deilda skrifstofunnar þinnar, og veita þér heildstæða leiðsagnarvald.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Tengjast starfsfólki skrifstofunnar í TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Forvitinn um andlitin á bak við skrifstofu? Svarið er aðeins einn smell frá í TeamConnect. Takmarkaðu við þrjá punkta (ellipsis) við hliðina á valinni skrifstofu til að afhjúpa valkosti í samskiptum:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Skoða starfsfólk í þessari skrifstofu:</Body1Strong> Uppljóstra lista yfir alla sérfræðinga 
                      sem kalla þessa skrifstofu vinnuheimili sitt. Tölfræðin sýnd er fljótleg samantekt á starfsfólkinu og býður upp á að kanna samfélag skrifstofunnar nánar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Samskiptamöguleikar:</Body1Strong> Hver færsla starfsmanns er búin 
                      fljótvirkum táknum, sem gerir þér kleift að hefja spjall, símtöl eða jafnvel myndfund beint frá TeamConnect, og stuðlar að marklausri samskiptum innan skrifstofunnar.
                    </li>
                    <li><Body1Strong>Leiðsögn:</Body1Strong> Til að fara aftur til yfirlits yfir skrifstofur frá lista yfir starfsmenn, smelltu einfaldlega á 'Skrifstofa' í brauðmylsnuslóðinni 
                      (Skrifstofa {'>'} Starfsmaður), og þú verður leidd/ur aftur yfir á yfirlit skrifstofunnar.
                    </li>
                  </ul>
                  Með þessum eiginleikum gerir TeamConnect það einfalt að sjá fyrir sér dreifingu vinnuafls yfir skrifstofur og að nálgast fólk áreynslulaust, 
                  og stuðlar að tengdum vinnustað óháð fjarlægð.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Vantar eða úreltar upplýsingar um skrifstofuna þína?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Uppfærslur á upplýsingum um skrifstofuna í TeamConnect geta verið meðhöndlaðar af útnefndum ritstjórum skrifstofu. Ef þú ert óviss um hverjir séu ritstjórar skrifstofunnar eða 
                  þarft aðstoð með upplýsingar sem falla undir starfssvið mannauðs, þá er stuðningsteam mannauðs tilbúið að 
                  hjálpa á <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Algengar spurningar um stillingar</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Mikilvægi nákvæmni almennra stillinga í TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Í breytilegu umhverfi nútíma vinnustaða er mikilvægara en nokkru sinni fyrr að vera tengdur og samhæfður. Þar gegnir það lykilhlutverki að uppfæra 
                  Almennar Stillingar þínar í TeamConnect:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Vinnuplássavalið:</Body1Strong> Fangaðu kjarna vinnustaðar þíns með því að uppfæra Vinnuplássið til að endurspegla núverandi 
                        aðstæður þínar, hvort sem er færsla frá friðsæld heimaskrifstofunnar yfir í iði aðal skrifstofunnar, eða jafnvel að hafast við á Almenningssvæði. 
                        Þetta skýrleiki í staðsetningu vinnustaðar þíns veitir samstarfsmönnum innsýn ekki aðeins í bestu leiðirnar til að ná í þig, heldur einnig um 
                        eðli samtala sem þeir geta átt við þig, og tryggir trúnað um viðkvæmar umræður sérstaklega þegar þú ert meðal opinna umhverfis skrifstofu viðskiptavinar 
                        eða situr í flugstöðvarlounge.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Verkstaðaupplýsingar:</Body1Strong> Að deila borginni þar sem þú vinnur gerir meira en að brúa bilið í fjarteymi—það opnar einnig 
                        dyr að skyndilegum faglegum samkomum eða afslöppuðum félagsskap. Hvort sem um ræðir fljótan kaffibolla, rólegan hádegisverð eða samvinnu 
                        fund, að vita að þú sért í sömu borg getur breytt venjulegum degi í tækifæri fyrir merkingarbærar andlits-til-andlits tengingar. Þetta styrkir 
                        ekki aðeins teymisbönd heldur veitir einnig dýpri skilning á staðbundnum áhrifum sem gætu haft áhrif á vinnu þína.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tímabeltisnákvæmni:</Body1Strong> Þar sem teymi ná yfir heimsálfur, er nauðsynlegt að 
                      tímabeltið endurspegli núverandi staðsetningu þína til að samhæfa samskipti. Staðartími prófíls þíns í TeamConnect er beint áhrifavaldur af þessari 
                      stillingu, sem útilokar öll gisk fyrir samstarfsfélaga sem reyna að ná í þig.
                    </li>
                  </ul>
                  Kafaðu í 'Stillingar' {'>'} 'Almennar Stillingar' innan TeamConnect til að fínstilla þessar upplýsingar. Nokkrir stundir notaðar til að uppfæra stillingar þínar 
                  geta marktækt aukið samhæfni og skilvirkni í samvinnu þinni við breiðan hóp.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Að ráða í vinnurými í TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Að velja réttan stað í fellilistanum Vinnurými sérsníður TeamConnect til að endurspegla núverandi vinnustöðu þína nákvæmlega. Farið yfir upplýsingatáknið fyrir ítarlega útskýringu á hverjum valmöguleika:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Fráskráður/Hlé:</Body1Strong> Táknar tíma fjarverandi frá vinnu, hvort sem er í hlé eða í lok dags.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Skrifstofa/Felt-Skrifstofa:</Body1Strong> Gefur til kynna nærveru í vinnurými stýrt af fyrirtækinu.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Heimaskrifstofa:</Body1Strong> Vinnur frá tileinkuðum heimavinnustað.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Fjarrými/Opinn staður:</Body1Strong> Starfar úr umhverfi utan skrifstofu, sem gefur til kynna þörf fyrir sveigjanleg samskipti.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Viðskiptavinur Vinnurými/Einka skrifstofa:</Body1Strong> Á staðnum hjá viðskiptavini, með athugasemd um trúnaðarstig fyrir næmni samtala.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ferðalög/Viðskiptaferðir/Þjálfun:</Body1Strong> Á ferðinni eða í sérstökum vinnutengdum verkefnum utan skrifstofu.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Almenn frí/Dagur frjáls/Fjölbreytt frí:</Body1Strong> Formlega frí af persónulegum, heilsufarslegum eða frídagaástæðum.</li>
                  </ul>
                  Að velja viðeigandi vinnurými tryggir að liðið þitt hafi samhengi sem þarf fyrir samskipti, virðandi aðgengi og friðhelgi.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Að fletta upp 'Innrituð' stöðu í TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  'Innrituð' merkið í Starfsmannaskránni er hannað til að sýna alla starfsmenn sem eru núna við vinnu. Sýnileiki þess er ákvarðaður af vinnurýmivali þínu:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Virkir vinnuumhverfi:</Body1Strong> Valflokkarnir Skrifstofa, Felt-Skrifstofa, Heimaskrifstofa, Fjarrými, Opinn staður, Viðskiptavinur Vinnurými,
                    Einka skrifstofa viðskiptavinar, Ferðalög, Viðskiptaferðir og Þjálfun gefa til kynna að þú sért að vinna, sem gerir þig 'Innrituð' og sýnilega virk fyrir samstarfsfélaga.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Óvirkar stillingar:</Body1Strong> Að velja Fráskráður, Hlé, Almenn frí, Frí, Í Frí, Mæðraorlof, eða Veikindaleyfi
                      fjarlægir þig úr listanum yfir 'Innrituð', og setur skýr væntingar um aðgengi og svartíma.
                  </li>
                  </ul>
                  Þessi aðgerð hjálpar til við að greina á milli vinnutíma og einkatíma, og stuðlar að árangursríkri samvinnu og samskiptum liða.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Gagnastjórnun og persónuvernd Algengar spurningar</Subtitle2>
          <Body1>
            <p>
              Á nútíma stafrænum öld er skilningur á því hvernig gögn þín eru stjórnað mjög mikilvægur. TeamConnect er skuldbundið til gagnsæis og traustar persónuverndarstefnu. 
              Hér að neðan muntu finna upplýsingar um hvernig við meðhöndlum, geymum og verndum gögn þín innan TeamConnect, og tryggjum að stafrænt vinnurými þitt sé öruggt og í samræmi við reglur.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Hvernig ver TeamConnect persónugögn mín?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect er helgað því að vernda persónulegar upplýsingar þínar með heildstæðri nálgun á öryggi gagna:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Ein innskráning (SSO):</Body1Strong> Til að auka öryggi og einfalda notendaupplifun, sameinar TeamConnect sjálfvirkar 
                      ein innskráningar (SSO) með Microsoft 365 reikningnum þínum, sem auðveldar örugga og áreynslulausa aðgangsstjórnun.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Dulkóðun:</Body1Strong> Við tryggjum að öll gögn innan TeamConnect séu dulkóðuð bæði á meðan þau eru send og þegar þau eru geymd, 
                      sem gerir þau óaðgengileg óviðkomandi aðilum.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Gagnaminnkun:</Body1Strong> Safnanir okkar á gögnum eru stranglega takmörkuð við það sem er nauðsynlegt fyrir virkni TeamConnect. 
                      Eina notendaupplýsingin sem við geymum er notandaprinsipnúmerið þitt (UPN) frá Azure Entra ID, sem gæti líkst tölvupósti fyrirtækisins þíns.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Takmörkuður aðgangur:</Body1Strong> Aðgangur að UPN er takmarkaður við áskriftarstjóra fyrirtækis þíns innan áskriftarportals okkar, 
                      og tryggir að viðkvæmar upplýsingar séu aðeins sýnilegar fyrir heimilað starfsfólk.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Fljótleg eyðing gagna:</Body1Strong> TeamConnect er uppbyggt til að strax eyða geymdu UPN undir þremur ákveðnum skilyrðum: 
                      ef áskrift er hætt af hálfu áskriftarstjóra, ef notandi er fjarlægður af HR innan TeamConnect, eða ef áskrift að TeamConnect rennur út. 
                      Þetta tryggir að persónugögn þín séu ekki varðveitt lengur en nauðsynlegt er eða þörf krefur.
                    </li>                      
                  </ul>
                  <p>Með þessum ráðstöfunum skuldbindur TeamConnect sig til að viðhalda hæstu stöðlum gagnaverndar, og tryggir að persónulegar upplýsingar þínar haldist öruggar og trúnaðarmál.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Veltur þú hvar gögnin þín eru geymd með TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect brýtur hefðbundin SaaS framboð með því að tryggja að gögnin þín fari aldrei úr fyrirtækinu þínu.
                  Ólíkt venjulegum aðferðum við geymslu gagna á ytri skýjaþjónum, notar TeamConnect snjalllega SharePoint Online
                  Microsoft 365 innan fyrirtækisins þíns til að hýsa öll umsóknargögn. Þessi nýjungalega nálgun ekki aðeins
                  hvetur til fullveldis gagna heldur einnig styrkir öryggi, samræmist fullkomlega okkar fastmótaðri skuldbindingu
                  við að vernda friðhelgi þína og fylgja ströngum reglum um samræmi.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Verða gögn mín deild með þriðja aðila?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nei, gögn eru ekki deild með þriðja aðilum! Cubeet er staðfastur í skuldbindingu okkar til friðhelgi og öryggis gagna þinna.
                  Við deilum engum gögnum TeamConnect með þriðja aðilum án þess að fá skýrt samþykki frá viðskiptavinum okkar.
                  Í öruggu umhverfi okkar geymum við aðeins nauðsynleg auðkenni, svo sem User Principal Name (UPN), heiti fyrirtækis,
                  heiti leigjanda og auðkenni leigjanda, innan áskriftarvefsvæðis okkar. Þessar upplýsingar eru mikilvægar fyrir
                  þjónustuveitingu og eru varðar undir ströngum friðhelgipólitíkum okkar. Vertu viss um að meðferð gagna okkar er hönnuð
                  til að tryggja að gögn þín verði áfram trúnaðarmál, með gagnsæi og stjórnun fast í höndum þínum.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Hvernig samræmist TeamConnect persónuverndarlögum eins og GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect samræmist persónuverndarreglum á borð við GDPR, HIPAA, CCPA og öðrum með því að nýta
                  aðal samræmisþætti Microsoft 365 umhverfisins, þar sem öll viðskiptavinagögn eru örugglega geymd. Ólíkt
                  hefðbundnum SaaS líkönum, geymir TeamConnect engin viðskiptavinagögn á sínum netþjónum; í staðinn starfar það
                  innan Microsoft 365 umhverfis fyrirtækisins þíns, fylgjandi þeim öflugu öryggis- og samræmisstaðlum.
                  Þessi nálgun tryggir að stjórnun gagna þinna sé í samræmi við viðamikinn samræmisramma sem Microsoft 365 veitir,
                  þ.m.t samræmi við GDPR, HIPAA fyrir heilbrigðisupplýsingar, CCPA fyrir íbúa í Kaliforníu,
                  og aðrar alþjóðlegar persónuverndarreglugerðir. Með því að fallast fullkomlega að núverandi Microsoft 365 uppsetningu þinni,
                  veitir TeamConnect fyrirtækinu þínu vald til að halda fullri stjórn á gögnum þínum, tryggja samræmi við ströngustu
                  lög og reglur um verndun gagna.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Hvernig meðhöndlar TeamConnect gögn frá Microsoft Teams spjallrásum, símtölum, myndbandsköllum, tölvupóstum Outlook og símkerfissímum?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect nýtir Microsoft Graph API til að gera beinar samskiptaleiðir eins og spjall, símtöl, myndbandsköll og tölvupóst Outlook innan Microsoft Teams mögulegar,
                  og bíður upp á saumlausa notendaupplifun. Það er lykilatriði að þrátt fyrir að TeamConnect hefji þessar samskiptaleiðir, þá gerir það það án þess að grípa inn í samskiptaferlið 
                  eða hafa getu til að taka upp eða geyma efni samskiptanna. Allar samræður, símtöl og tölvupóstar eiga sér stað og eru stjórnaðar beint innan 
                  örugga umhverfis Microsoft Teams og Outlook, í strangri samræmi við öflug öryggis- og friðhelgisstaðla Microsoft 365. Þetta tryggir að TeamConnect bæti 
                  samskiptahæfni þína á meðan trúnaður og heilindi gagna þinna eru algerlega varðveitt.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Fyrir ítarlegar upplýsingar um gagnastefnur og -framkvæmdir okkar, gætirðu einnig viljað skoða {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>Notendahandbók TeamConnect</Body1Strong></Link> eða haft samband við upplýsingatæknideild fyrirtækis þíns 
            á <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> fyrir upplýsingar sem tengjast uppsetningu þinni.
          </p>
        </Body1>        
        <p></p>  
      <Subtitle1 className={styles.fontColor}>Við erum að hlusta: Deildu endurgjöf þinni og hugmyndum</Subtitle1>
        <Body1>
          <p>
            Innsýn þín stýrir þróun TeamConnect. Hvort sem þú ert fullur af snjöllum hugmyndum að nýrri eiginleika eða hefur endurgjöf um núverandi reynslu, við viljum heyra frá þér. Þínar ábendingar eru ómetanlegar í stöðugri leit okkar að veita 
            þá bestu mögulegu þjónustu.
          </p>
        </Body1>      
      <Subtitle2>Endurgjöf og Tillögur að Eiginleikum</Subtitle2>
        <Body1>
          <p>
            Tilbúinn til að deila? Farðu yfir á {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Endurgjafarsíðuna okkar
            </Link>
            . Hvort sem það er tillaga að nýjum eiginleika eða endurgjöf um núverandi virkni, velurðu einfaldlega viðeigandi valkost úr fellilistanum í forminu. Þínar framlög aðstoða við að móta framtíð TeamConnect, 
            tryggjandi að það uppfylli og fari fram úr þörfum þínum.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
