import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Body1, Body1Strong, Button } from '@fluentui/react-components';
import { Loading, ErrorException } from '../../components';
import { decodeToken } from '../../utils/common';
import { addResolve, addActivate } from '../../services/loginService';
import { resolveSaasSubscription, activeSaasSubscription } from '../../services/saasService';
import { getCubeetSubscription, resolveCubeetSubscription, subscribedCubeetSubscription } from '../../services/subscriptionService';
import Detail from './detail';
import Footer from '../footer';

const useStyles = makeStyles({
  root: {
  },
  buttonStart: {
    backgroundColor: '#151b2b',
    border: 'none',
    color: 'white',
    padding: '10px 40px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#319bdc',
      color: 'white',
    },
  },
  buttonRegister: {
    backgroundColor: '#2551fe',
    border: 'none',
    color: 'white',
    padding: '10px 40px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#319bdc',
      color: 'white',
    },
  },
});

function Container({
  meProfile,
  purchaseToken,
  accessToken,
}) {
  const [isResolving, setIsResolving] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [isRegistered, setIsRegistered] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const styles = useStyles();

  const startProcess = async () => {
    setIsResolving(true);
    try
    {
      if (!accessToken) {
        throw new Error('System access token is required');
      };

      if (!purchaseToken) {
        throw new Error('Microsoft purchase token is required');
      };

      const { userPrincipalName, id: objectId } = meProfile;
      if (!userPrincipalName || !objectId) {
        throw new Error('User Principal Name or Object ID is required');
      };

      // 4. write log action to db
      await addResolve(meProfile);
      
      // 2. decode purchased token
      const dToken = decodeToken(purchaseToken);

      // 3. resolve saas subscription
      const res_resolve = await resolveSaasSubscription(accessToken, dToken);
      setSubscriptionInfo(res_resolve.data);

      // 4. add cubeet subscription to db
      await resolveCubeetSubscription(res_resolve.data);
      
      setIsResolving(false);
      setErrorMessage('');
    } catch (error) {
      setIsResolving(false);
      setErrorMessage(error.toString());
    };
  };

  const startActivate = async () => {
    setIsActivating(true);
    try 
    {
      if (!accessToken) {
        throw new Error('System access token is required');
      };

      if (!purchaseToken) {
        throw new Error('Microsoft purchase token is required');
      };

      const { userPrincipalName, id: objectId } = meProfile;
      if (!userPrincipalName || !objectId) {
        throw new Error('User Principal Name or Object ID is required');
      };

      const { 
        id: subscriptionId, 
        subscription: { saasSubscriptionStatus: subscriptionStatus }
      } = subscriptionInfo;
      if (!subscriptionId || !subscriptionStatus) {
        throw new Error('Subscription ID or Status is required');
      };

      // 4. write log action to db
      await addActivate(meProfile);
      
      // 2. activate SaaS subscription
      if (subscriptionStatus === 'PendingFulfillmentStart') {
        const res_activate = await activeSaasSubscription(accessToken, subscriptionId);
        if (res_activate && res_activate.status && [200, 201].indexOf(res_activate.status) >= 0) {
          console.log('Subscription is registered successfully');
        } else if (res_activate && res_activate.error) {
          throw new Error(res_activate.error);
        };
      };

      // 3. get SaaS subscription info
      const dToken = decodeToken(purchaseToken);
      const res_resolve = await resolveSaasSubscription(accessToken, dToken);
      setSubscriptionInfo(res_resolve.data);

      // 4. add cubeet subscription to db
      const res_cubeet_sub = await getCubeetSubscription(subscriptionId);
      if (!res_cubeet_sub) {
        await resolveCubeetSubscription(res_resolve.data);
      } else {
        await subscribedCubeetSubscription(subscriptionId);
      };
      
      setIsActivating(false);
      setErrorMessage('');
      setIsRegistered(true);
    } catch (error) {
      setIsActivating(false);
      setErrorMessage(error.toString());
    };
  };

  return (
    <div className={styles.root}>
      <div>
        <Body1Strong>Subscription Registration</Body1Strong>
      <br />
      <div>
        <Body1>
          Thank you for purchasing TeamConnect!
        </Body1>
      </div>
      <br />
        {
          (errorMessage) && 
          <>
            <br />
            <ErrorException text={errorMessage} />
          </>
        }

        {
          (errorMessage && errorMessage.indexOf('NOT_ALLOW_FREE_TRIAL_TWICE_SUBSCRIPTION') >= 0) && 
          <>
            <br />
            <div style={{ marginLeft: '20vw', marginRight: '20vw' }}>
              <p>
                Your domain had registered a 30-days free trial subscription. 
                To continue using TeamConnect, please renew your license in the Microsoft 365 Admin center. 
                If you need any assistance, feel free to reach out to our support team at <a href='mailto:support.teamconnect@cubeet.ai'>support.teamconnect@cubeet.ai</a>.
              </p>
            </div>
          </>
        }

        {
          (errorMessage && errorMessage.indexOf('NOT_ALLOW_MICROSOFT_PERSONAL_ACCOUNT') >= 0) && 
          <>
            <br />
            <div style={{ marginLeft: '20vw', marginRight: '20vw' }}>
              <p>
              Automatic activation is supported for registered business domains only. 
              If you wish to register a sandbox or developer tenant for testing purposes, please contact our support team at <a href='mailto:support.teamconnect@cubeet.ai'>support.teamconnect@cubeet.ai</a>.
              </p>
            </div>
          </>
        }

        <br />
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', columnGap: '2vw' }}>
            {
                (isResolving) 
              ? <Loading text={'Resolving...'} />
              : (!isResolving && !subscriptionInfo) 
              ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Body1 style={{ marginBottom: '10px' }}>
                    Please start your registration by clicking the button below:
                  </Body1>
                  <Button className={styles.buttonStart} onClick={startProcess}>Start registration</Button>
                </div>
              : null
            }

            {
                (isActivating) 
              ? <Loading text={'Registering...'} />
              : (!isActivating && !isResolving && !isRegistered && subscriptionInfo) 
              ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Body1 style={{ marginBottom: '10px' }}>
                    Please complete registering your subscription by clicking the button below:
                  </Body1>
                  <Button className={styles.buttonRegister} onClick={startActivate}>Register your subscription</Button>
                </div>
              : null
            }

            {
              (isRegistered)
              ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Body1 style={{ marginBottom: '10px', color: 'green' }}>
                    Subscription registration was successful.
                  </Body1>
                  <Body1 style={{ marginBottom: '10px', color: 'green' }}>
                    Please manage your subscription and licenses at the <a href='https://admin.microsoft.com/#/homepage'>Microsoft 365 admin center</a>
                  </Body1>
                </div>
              : null
            }
          </div>
        </div>

        <br />
        {
          (subscriptionInfo && subscriptionInfo.id) ? <Detail subscription={subscriptionInfo}/> :null
        }
      </div>
      <Footer />
    </div>
  );
};

Container.propTypes = {
  meProfile: PropTypes.object,
  purchaseToken: PropTypes.string,
  accessToken: PropTypes.string,
};

export default Container;