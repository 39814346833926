import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});
function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>TeamConnect Hjælpecenter & Ofte Stillede Spørgsmål</Title3>
        <p><Body1>
          Velkommen til TeamConnect Hjælpecenteret, designet til at understøtte din oplevelse med vores omfattende virksomhedsfortegnelse-app. Her finder du ressourcer til at optimere din brug af TeamConnect inden for Microsoft Teams, hvilket forstærker kommunikationen over hele dit netværk.
        </Body1></p>
        <Subtitle2>Hurtig Referenceguide</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Forbedrede Søgefunktioner: </Body1Strong>
              Find de rigtige kollegaer, afdelinger og essentielle kontakter hurtigt med vores kraftfulde søgeværktøjer.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Visuelle Org Diagrammer: </Body1Strong>
              Naviger i din organisations hierarki og forstå rapporteringslinjer med lethed gennem intuitive org diagrammer.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Tilpasningsdygtige Værktøjer til Nutidens Behov: </Body1Strong>
              Brug moderne funktionaliteter tilpasset kravene fra nutidens arbejdspladser.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Optimer Din Oplevelse</Subtitle2>
        <Body1>
          <p>
            TeamConnect revolutionerer måden, du interagerer inden for din organisationsstruktur, og omdanner dagligdags kommunikation til strategiske engagementer.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Få Hjælp & Ofte Stillede Spørgsmål</Subtitle1>
        <Body1>
          <p>
            Find svar på almindelige spørgsmål om TeamConnects funktioner og indstillinger i dette dedikerede afsnit. Uanset om du navigerer i appen, tilpasser indstillinger, eller søger løsninger på udfordringer, er vores Hjælpecenter her for at assistere dig effektivt.
          </p>
        </Body1>
        <Body1>
          <p>
            For mere dybdegående støtte eller specifik vejledning, konsulter venligst <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnect Brugervejledning</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        
        <Subtitle2>Brug af TeamConnect Ofte stillede spørgsmål</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Kan ikke finde TeamConnect i Microsoft Teams sidebjælken?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hvis TeamConnect ikke umiddelbart er synlig eller er forsvundet i din Microsoft Teams sidebjælke, kan du nemt fastgøre den for hurtig adgang. Følg blot disse enkle trin:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Åbn Microsoft Teams og klik på '...' (+ Apps) ikonet i bunden af sidebjælken.</li>
                    <li className={styles.bulletBottomMargin}>Søg efter 'TeamConnect' i søgefeltet øverst i pop-up vinduet.</li>
                    <li className={styles.bulletBottomMargin}>Højreklik på TeamConnect app'en fra søgeresultaterne og vælg 'Fastgør'.</li>
                  </ol>
                  TeamConnect vil nu være fastgjort til din sidebjælke, hvilket gør den tilgængelig med bare et klik!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Støder du på tekniske problemer med TeamConnect? Sådan får du hjælp.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hvis du støder på tekniske problemer med TeamConnect inden for Microsoft Teams, står din organisations IT-support team klar til at hjælpe. 
                  Kontakt dem på <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, og de vil hurtigt assistere dig.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Kan ikke finde dine medarbejderoplysninger?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hvis din profil føles en smule tom med kun dit navn og email vist, er det sandsynligt, at dit HR-team endnu ikke har tilføjet resten af dine medarbejderoplysninger til TeamConnect. 
                  Ingen grund til bekymring! En hurtig besked til dit HR-supportteam vil sætte tingene i gang. De står klar til at fylde din profil med alle de vigtige oplysninger. 
                  Kontakt dem på <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> og se din profil blive levende!
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Hvilke sprogmuligheder er tilgængelige i TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect omfavner den globale mangfoldighed af Microsoft Teams ved at understøtte alle dets sprog. 
                  Uanset om du foretrækker arabisk eller græsk, japansk eller svensk, har du en verden af muligheder ved dine fingerspidser, herunder:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arabisk, bulgarsk, kinesisk (forenklet og traditionel), kroatisk, tjekkisk, dansk, hollandsk, engelsk, estisk, finsk, 
                      fransk, tysk, græsk, hebraisk, ungarsk, indonesisk, italiensk, japansk, koreansk, lettisk, litauisk, norsk (Bokmål), 
                      polsk, portugisisk (Brasilien og Portugal), rumænsk, russisk, serbisk (latin), slovakisk, slovensk, spansk, svensk, thai, 
                      tyrkisk, ukrainsk, vietnamesisk.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  For at tilpasse din TeamConnect-oplevelse til dit foretrukne sprog skal du justere sprogindstillingerne direkte i Microsoft Teams, 
                  da TeamConnect er fuldt integreret med disse indstillinger:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Gå til dine Microsoft Teams-indstillinger ved at klikke på de tre prikker ved siden af dit profilbillede øverst, og vælg derefter 'Udseende og tilgængelighed'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Scroll til afsnittet 'Sprog' og vælg dit foretrukne sprog fra rullemenuen.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Bekræft dine ændringer ved at klikke på knappen 'Gem og genstart' for at anvende de nye sprogindstillinger.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Bemærk: </Body1Strong>
                <Body1>
                  Ændringer foretaget i dine sprogindstillinger i Microsoft Teams vil også problemfrit opdatere din TeamConnect-oplevelse. 
                  Vær opmærksom på, at ændring af sproget i Teams også kan justere dit format for tid og dato. For øjeblikket 
                  udvikler TeamConnect sig til at kunne skrive fra højre mod venstre. Vi er begejstrede for disse forbedringer 
                  og vil holde dig informeret, efterhånden som de bliver tilgængelige.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Hvordan ændrer jeg temaet i TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect synkroniserer med dine temaindstillinger i Microsoft Teams, herunder Lyse, Mørke og Høj kontrast valgmuligheder. For at tilpasse dit tema:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Adgang til dine Microsoft Teams-indstillinger ved at klikke på de tre punkter ved siden af dit profilbillede øverst.</li>
                    <li className={styles.bulletBottomMargin}>Vælg 'Udseende og tilgængelighed' for at udforske tema mulighederne.</li>
                    <li className={styles.bulletBottomMargin}>Vælg dit foretrukne tema blandt de tilgængelige muligheder for straks at opdatere dit TeamConnect-interface til at matche.</li>
                  </ol>
                  Dit valg af tema i Microsoft Teams vil automatisk blive anvendt til TeamConnect, hvilket sikrer en ensartet visuel oplevelse på tværs af dit arbejdsområde.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Udforskning af TeamConnect's Kontaktaktionsknapper
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Overalt i TeamConnect, hvor du finder en medarbejders kontaktoplysninger, vil du også opdage vores praktiske hurtigadgangs knapper. Disse nyttige værktøjer er designet til at strømline din kommunikation inden for Microsoft-økosystemet, hvilket sikrer, at du altid er kun et klik væk fra at forbinde. Her er din omfattende guide til effektivt at bruge disse knapper:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chatikon:</Body1Strong> Klik for at starte en øjeblikkelig Microsoft Teams chat. Hvis du skal vende tilbage til TeamConnect efter at have startet en chat, så brug bare tilbage-pilen i Desktop-appen, tilbage-knappen i mobilappen eller din browsers tilbageknap.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Emailikon:</Body1Strong> Åbner et nyt Outlook-e-mailudkast med modtagerens adresse klar i "Til:" feltet. Hvis Outlook ikke starter, er det værd at tjekke med din IT-afdeling for eventuelle restriktioner.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Videoopkaldsikon:</Body1Strong> Starter et ansigt-til-ansigt videoopkald ved brug af Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Opkaldsikon:</Body1Strong> Starter et Microsoft Teams taleopkald, hvilket gør hurtige diskussioner problemfri.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org Chart-ikon:</Body1Strong> Afslører medarbejderens placering i den organisatoriske hierarki, inklusiv deres chef og direkte underordnede.</li>
                    <li><Body1Strong>Mere (...) ikon:</Body1Strong> Afslører yderligere muligheder:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Mød nu:</Body1Strong> Åbner Microsoft Teams for at starte et øjeblikkeligt møde med medarbejderen.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Nyt møde:</Body1Strong> Dirigerer dig til Microsoft Teams for at planlægge et nyt møde.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Tilføj kontakt:</Body1Strong> Tilføjer medarbejderens kontaktoplysninger fra TeamConnect til din nye Microsoft Teams People-app, berigende din opkaldfunktion.</li>
                      </ul>
                    </li>
                  </ul>
                  Disse intuitive knapper er udformet for at sikre, at du effektivt kan forbinde med kolleger, både for en hurtig chat, en e-mail eller for at planlægge fremtidige møder.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Foretage opkald via TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ønsker du at foretage et opkald? TeamConnect strømliner din kommunikation ved at integrere med Microsoft Teams.
                  Klik simpelthen på det ønskede telefonnummer i TeamConnect, og vupti, grænsefladen for Microsoft Teams-opkald dukker op,
                  klar til at du kan ringe ud problemfrit.
                  <p>
                  <Body1Strong>Bemærk: </Body1Strong> 
                  Oplever du problemer med at foretage opkald? Dette kan skyldes din organisations IT-politikker eller 
                  brugen af et andet telefonsystem end Microsoft Teams Phone System. At tjekke ind med din IT-afdeling på <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, kan kaste lys over sagen.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Er der omkostninger forbundet med at foretage opkald via TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect forbedrer din opkaldsoplevelse ved at udnytte Microsoft Teams. Her er hvad du behøver at vide om omkostninger relateret til opkald:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      For opkald til medarbejderes arbejdstelefoner, afdelingstelefoner eller hotlines inden for din organisation (interne numre), 
                      er der typisk <Body1Strong>ingen omkostninger</Body1Strong> når du bruger Microsoft Teams Phone System.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      For eksterne opkald, såsom dem til mobiltelefoner eller eksterne linjer, gælder <Body1Strong>standard takster</Body1Strong> for din organisations telefonsystem. 
                      Disse takster varierer baseret på din specifikke serviceplan med Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Bemærk:</Body1Strong> Hvis du støder på problemer eller har spørgsmål vedrørende opkaldsomkostninger, er det bedst at konsultere din 
                    IT-afdeling på <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> for detaljeret information skræddersyet 
                    til din organisations opsætning og politikker.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Bemærker du en mismatch med din 'Out of Office' tilstedeværelsesstatus i TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Har du lagt mærke til, at din 'Out of Office' tilstedeværelsesstatus fra Microsoft 365 ikke afspejles korrekt i TeamConnect? 
                  Vi har dækket dig ind. Funktionen til at synkronisere 'Out of Office' tilstedeværelsesstatus er allerede implementeret i TeamConnect.
                  Vi venter i øjeblikket på Microsofts udrulning af denne funktion. Når det sker, vil din 'Out of Office' tilstedeværelsesstatus 
                  blive nøjagtigt spejlet i TeamConnect, hvilket er i overensstemmelse med Microsoft Teams' tilstedeværelsesindikatorer for en sømløs oplevelse.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>Ofte Stillede Spørgsmål om Medarbejderfortegnelsen</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >Introduktion til Medarbejderfortegnelsen:</Body1Strong> Naviger i det professionelle landskab i din organisation med Medarbejderfortegnelsen, 
                dit centrale knudepunkt for detaljerede medarbejderindsigter, arbejdsdynamikker og direkte forbindelse til dine kollegers profiler og ledelsesrelationer.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Har du brug for at tilføje, opdatere eller korrigere dine medarbejderdata?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  For at tilføje, opdatere eller korrigere dine medarbejderdata i TeamConnect, kontakt venligst din organisations HR-supportteam. 
                  De er klar til at hjælpe på <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Hvad sker der, når jeg klikker på en medarbejders navn i fortegnelsen?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ved at klikke på en medarbejders navn i TeamConnect-medarbejderfortegnelsen åbner der sig en detaljeret profilside for den pågældende. Denne profil giver omfattende 
                  indsigt om medarbejderen, herunder men ikke begrænset til, deres jobtitel, afdeling, kontorplacering, kontaktoplysninger og mere. Den er designet til at give dig 
                  et fuldt overblik over medarbejderens professionelle rolle og hvordan de passer ind i organisationen, hvilket gør det lettere for dig at forstå deres ansvarsområder og 
                  hvordan du muligvis kan samarbejde med dem.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Hvilke oplysninger indeholder en medarbejders profil?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hver medarbejderprofil i TeamConnect er et omfattende øjebliksbillede, der tilbyder en rigdom af indsigt i individets professionelle liv. 
                  Her er hvad du vil finde:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Grundlæggende detaljer:</Body1Strong> Navn, jobtitel, afdeling og datterselskabs placering.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Rapporteringsstruktur:</Body1Strong> Oplysninger om direkte og funktionelle rapporter, 
                      inklusive linje- og funktionsledere.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Arbejdsstatus:</Body1Strong> Nuværende arbejdsstatus såsom Orlov, Pendling, 
                      Forretningsrejse osv.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Arbejdssted & Lokal Tid:</Body1Strong> Medarbejderens nuværende arbejdsplads og 
                      lokal tidszone.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontaktdetaljer:</Body1Strong> Arbejdstelefon, mobilnummer og e-mailadresse.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontoradresse:</Body1Strong> Komplet kontorplacering inklusive bygningens navn,
                      adresse og Google-kort.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Om:</Body1Strong> En kort personlig introduktion eller professionel biografi.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Medarbejderrelationer:</Body1Strong> Detaljer om medarbejderens rapporterings 
                      struktur og teamrelationer.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sociale links & Sprog:</Body1Strong> Links til professionelle sociale profiler 
                      og sprogfærdigheder.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Færdigheder:</Body1Strong> En liste over professionelle færdigheder og ekspertiseområder.</li>
                  </ul>
                  Derudover tilbyder profilen handlingsmuligheder for at forbinde via chat, e-mail, videoopkald eller planlægning af møder, sammen med 
                  hurtig adgang til organisationens diagram for bredere teamkontekst.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Hvordan opdaterer jeg mine profiloplysninger?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Det er nemt at personliggøre din profil i TeamConnect. Sådan gør du:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Naviger til <Body1Strong>Medarbejder</Body1Strong>-fanen og klik på dit <Body1Strong>Avatar</Body1Strong> for at åbne din profil.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        I øverste venstre hjørne finder du linket <Body1Strong>"Rediger din profil"</Body1Strong>. 
                        Klik på dette vil åbne redigeringsdialogen for din profil.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Her kan du forbedre din profil ved at tilføje eller opdatere din <Body1Strong>Om dig</Body1Strong>-sektion, sociale medier-links og telefonnummer. 
                        Dette gør det lettere for dine kolleger at forbinde eller følge dig.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Glem ikke at trykke på <Body1Strong>Gem</Body1Strong>-knappen for at anvende dine ændringer.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Rul ned i dialogen for at tilføje dine <Body1Strong>Sprog</Body1Strong> og <Body1Strong>Færdigheder</Body1Strong>, 
                        samt dine <Body1Strong>Evner</Body1Strong>. At dele denne information hjælper dine kolleger med at forstå 
                        dine ekspertiseområder og de sprog, du kan kommunikere på.
                      </li>
                    </ol>
                  At opdatere din profil holder ikke kun dine oplysninger aktuelle, men fremmer også bedre forbindelser inden for din organisation.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Opdatere din arbejdsplads, arbejdssted og tidszone i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Det er afgørende at holde dine oplysninger om arbejdsplads, arbejdssted og tidszone opdaterede for ubesværet samarbejde i Microsoft Teams. 
                  Disse opdateringer afspejles øjeblikkeligt i din TeamConnect-profil og opslag, hvilket gør det muligt for kolleger hurtigt at forstå din arbejdsplacering 
                  og de bedste tidspunkter at forbinde med dig, forbedrer teamwork på tværs af forskellige regioner og tidszoner.
                  <p></p>
                  Klar til at opdatere dine detaljer? Det er let i TeamConnect! Gå over til 'Indstillinger' i topmenuen og naviger til 'Generelle indstillinger'. 
                  Her kan du nemt opdatere din Arbejdsplads og Tidszone og specificere dit Arbejdssted for dagen. Husk at gemme dine ændringer for at 
                  holde alle informeret og sikre effektivt samarbejde, uanset din placering!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Har du bemærket noget uventet i en kollegas profil?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hvis du støder på detaljer i en kollegas profil, der ikke ser helt rigtige ud, er det bedste første skridt at give dem en 
                  venlig påmindelse. En hurtig samtale eller e-mail bør gøre tricket! Hvis af en eller anden grund informationen ikke bliver opdateret, så bekymre dig ikke—bare 
                  kontakt dit HR-supportteam på <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, og de vil være glade 
                  for at træde til og ordne tingene.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Forståelse af taggrupper: Alle, Favoritter, Tjekket ind & Nye medarbejdere
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Alle:</Body1Strong> Denne standardtag viser hver medarbejder i din organisation,
                      og giver et omfattende overblik over teamet.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoritter:</Body1Strong> Klik her for at filtrere dit overblik til de kolleger, du har markeret 
                      som 'Favoritter', hvilket gør det lettere hurtigt at kunne kontakte vigtige kontakter.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tjekket ind:</Body1Strong> Denne tag indikerer medarbejdere, der aktivt er på arbejde, i modsætning 
                      til blot online tilstedeværelse på Microsoft Teams. Når tjekket ind, signalerer medarbejderne, at de er på arbejde og deler deres aktuelle arbejdsplacering 
                      i løbet af dagen. Dette kan omfatte en række arbejdspladser såsom Kontor, Felt-kontor, Hjemme-kontor, Fjern-arbejdsplads, Kunde-arbejdsplads, 
                      Kunde-Privat-Kontor, Offentligt område, På vej, Forretningsrejse, eller endda Uddannelse. Derfor giver 'Tjekket ind'-status en klar indikation 
                      af at være aktivt på arbejde, og tilbyder kolleger en præcis forståelse af hinandens nuværende arbejdskontekst og tilgængelighed.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nye medarbejdere:</Body1Strong> Spot nye ansigter i TeamConnect med dette tag, som fremhæver 
                      medarbejdere, der er blevet tilføjet til appen inden for de sidste 30 dage. Typisk stemmer dette overens med seneste tilføjelser til din organisation, hjælper 
                      dig med at byde nye teammedlemmer velkommen.
                    </li>
                  </ul>
                  Disse taggrupper er designet til at strømline navigation og forbedre din TeamConnect-oplevelse, hvilket sikrer, at du altid 
                  kan finde og forbinde dig effektivt med dine kolleger.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Dekodning af ikonerne ved siden af søgeboksen
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect's medarbejderdirectory er designet til alsidighed og brugervenlighed, og indeholder fire ikoner, der tilbyder forskellige visninger 
                  og hurtig adgang til nøgleoplysninger. Her er, hvad hvert ikon afslører:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Listevisning:</Body1Strong> Din standardvisning, der præsenterer alle organisationsmedlemmer 
                      i en enkel liste.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lille kortvisning:</Body1Strong> Et kompakt alternativ, denne visning transformerer listen 
                      til små medarbejderkort.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Stor kortvisning:</Body1Strong> Vælg flere detaljer med større kort, som inkluderer 
                      yderligere kontaktmuligheder som telefon- og mobilnumre for let opkald.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organisationsdiagram:</Body1Strong> Visualiser firmaets struktur med organisationsdiagramvisningen, 
                      perfekt til at forstå rapporteringsrelationer og navigere i organisationslandskabet.</li>
                  </ol>
                    Skift mellem disse ikoner for at finde den visning, der bedst passer til dine behov i øjeblikket, hvad enten du søger hurtige info eller dybere indsigter.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Til hurtige kollegasøgninger, hvor skal jeg kigge?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Leder du efter en kollega? Gå blot til TeamConnect søgeboksen, placeret i øverste højre hjørne ved siden af organisationsdiagramikonen. 
                  Begynd at skrive deres navn, og se hvordan resultaterne forfiner med hvert bogstav. Husk, for TeamConnect-søgninger, spring over Microsoft Teams 
                  søgebaren øverst—vores specielle søgeboks er vejen frem!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Hvordan fungerer den alfabetiske søgning for at finde medarbejdere?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnects medarbejderregister inkluderer en praktisk alfabetisk søgefunktion for hurtigt at finde medarbejdere ved deres fornavn. 
                  Klik blot på et bogstav fra alfabetet vist øverst på registeret, og TeamConnect vil straks vise dig alle medarbejdere, 
                  hvis fornavne begynder med dette bogstav. For at vende tilbage til den fulde liste over medarbejdere skal du blot fravælge det fremhævede bogstav. Denne intuitive 
                  funktion gør det nemt at navigere gennem store medarbejderlister, hvilket sikrer, at du hurtigt og effektivt kan finde kollegaer.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Hvordan kan jeg justere antallet af medarbejdere, der vises pr. side?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect giver dig mulighed for at tilpasse, hvor mange medarbejderprofiler du ser pr. side, hvilket gør det lettere at navigere gennem medarbejderregisteret. 
                  I bunden af hver side finder du indstillinger for sidetal: 10, 50, 100, 500, 1000 og 5000. Standardindstillingen viser 10 profiler pr. side. 
                  For at se flere profiler på en enkelt side skal du blot vælge dit foretrukne nummer fra disse muligheder. Denne funktion giver fleksibilitet i, hvordan du gennemser 
                  registeret, så du kan tilpasse visningen efter dine specifikke behov eller præferencer.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Hvordan sorterer jeg medarbejderinformationer i Listevy?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  I TeamConnects medarbejderregister Listevy har du fleksibiliteten til at sortere medarbejderoplysninger efter dine behov. Kolonnerne—Medarbejdernavn, 
                  Jobtitel, Afdeling, Kontorplacering og Arbejdsplads—er alle sorterbar. Klik blot på kolonneoverskriften, du ønsker at sortere efter. Et klik sorterer 
                  kolonnen i stigende orden, og et klik mere sorterer den i faldende orden. Som standard er registeret sorteret efter Medarbejdernavn i stigende orden, 
                  baseret på fornavnet. Denne sorteringsfunktion hjælper dig hurtigt med at organisere og se medarbejderdetaljer på en måde, der bedst tjener dit formål, 
                  uanset om du leder efter en bestemt person eller har brug for at gruppere medarbejdere efter et bestemt kriterium som afdeling eller placering.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Afdelingsfortegnelse Ofte Stillede Spørgsmål</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Introduktion til afdelingsfortegnelsen:</Body1Strong> Afdelingsfortegnelsen er din indgang til at forstå det indviklede netværk af din 
              organisations struktur, og tilbyder et klart overblik over afdelingshierarkier, ledelsesmæssigt lederskab og muligheder for problemfri afdelingskommunikation.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Hvordan fungerer 'Alle', 'Favoritter' og 'Din Afdeling' tags i afdelingsfortegnelsen?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  I TeamConnects afdelingsfortegnelse hjælper tags dig med hurtigt at navigere og filtrere gennem afdelingsinformation. Her er hvad hver tag repræsenterer:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>All:</Body1Strong> Dette tag viser alle afdelinger inden for din organisation og giver et 
                      omfattende overblik.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoritter:</Body1Strong> Brug dette tag til at se en udvalgt liste af afdelinger, du har markeret 
                      som 'Favoritter', hvilket gør det lettere at få adgang til afdelinger, du ofte interagerer med.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Din Afdeling:</Body1Strong> Dette tag filtrerer visningen til kun at vise din egen afdeling, 
                      og tillader hurtig adgang til din umiddelbare afdelingsinformation og opdateringer.</li>
                  </ul>
                  Disse tags er designet til at strømline din navigation inden for afdelingsfortegnelsen, og forbedrer din evne til effektivt at finde og engagere dig med afdelingsinformation.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Forståelse af visninger i afdelingsfortegnelsen i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigering gennem afdelingsfortegnelsen i TeamConnect er strømlinet med flere visningsmuligheder, der passer til dine præferencer. Hver visning tilbyder en 
                  unik måde at udforske omfattende afdelingsinformation på, inklusive afdelingsnavn, afdelingsleder, overordnet afdeling, afdelingstelefon, 
                  afdelingshotline og afdelingsemail. Her er et overblik over de tilgængelige visninger:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Listevisning:</Body1Strong> Standardopsætning, som pænt lister alle afdelinger, og giver et 
                      klart, koncist overblik.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Stort Kortvisning:</Body1Strong> Skift til denne visning for at se detaljerede afdelingskort, der 
                      tilbyder en udvidet opsummering af hver afdelings nøgleinformation.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org Diagram Visning:</Body1Strong> For en visuel repræsentation af afdelingstrukturen og 
                      relationerne, illustrerer Org Diagram-visningen hvordan hver afdeling passer ind i den større organisationshierarki.
                    </li>
                  </ul>
                  For at skifte mellem disse visninger, brug blot ikonerne ved siden af søgefeltet øverst i afdelingsfortegnelsen. Hver visning er designet til at hjælpe dig 
                  med at tilgå og forstå afdelingsdetaljer med lethed, og sikre at du finder den information, du har brug for i det format, der fungerer bedst for dig.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Sådan bruges søgefeltet i afdelingsfortegnelsen
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Find ubesværet enhver afdeling inden for TeamConnect ved hjælp af det dedikerede søgefelt, der er placeret lige ved siden af visningsikonerne øverst på 
                  Afdelingsfortegnelsen. Begynd simpelthen at taste navnet på den afdeling, du søger, og se, hvordan fortegnelsen tilpasser sig for kun at vise 
                  relevante resultater. Denne funktion er specielt designet til at hjælpe dig med at springe manuel gennemgang af hele fortegnelsen over, hvilket bringer den 
                  afdeling, du har brug for, direkte til dine fingerspidser. Husk, denne søgefunktionalitet er unik for TeamConnects afdelingsfortegnelse, så sørg 
                  for at bruge dette søgefelt i stedet for den primære Microsoft Teams-søgning øverst i Teams-grænsefladen til afdelingsspecifikke forespørgsler.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Hvordan fungerer det alfabetiske filter i afdelingsfortegnelsen?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Streamline din søgning i Afdelingsfortegnelsen med det alfabetiske filter placeret i toppen. Denne funktion giver dig mulighed for hurtigt at indsnævre 
                  afdelinger ved at vælge et bogstav. Når du har valgt et bogstav, filtrerer TeamConnect og viser kun de afdelinger, hvis navne begynder 
                  med dit valgte bogstav, hvilket gør det lettere at finde det, du søger, uden at skulle rulle gennem hele listen. For at vende tilbage til visningen af alle afdelinger, skal du blot fravælge det fremhævede bogstav. Denne intuitive alfabetiske navigation sikrer, at du effektivt kan finde enhver afdeling 
                  efter dens begyndelsesbogstav.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Hvordan kan jeg kontakte afdelingslederen?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Det er enkelt at kontakte en afdelingsleder i TeamConnect. Find blot den pågældende afdeling og klik på lederens navn. Denne 
                  handling vil udløse et popover, der viser lederens kontaktmuligheder med de velkendte hurtigadgangsikoner:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chat-ikon:</Body1Strong> For at starte en øjeblikkelig besked via Microsoft Teams-chat.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>E-mail-ikon:</Body1Strong> For at åbne en ny e-mail adresseret til dem i Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Videoopkalds-ikon:</Body1Strong> For at initiere et videoopkald gennem Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Opkalds-ikon:</Body1Strong> For at begynde en taleopkald ved brug af Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Mere (...) ikon:</Body1Strong> For yderligere muligheder som at planlægge et møde eller tilføje 
                      deres kontaktoplysninger til din Teams-liste.
                    </li>
                  </ul>
                  Disse ikoner tilbyder en problemfri metode til at kontakte afdelingsledere direkte fra TeamConnect og sikrer effektiv kommunikation inden for 
                  din organisation.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Hvordan kan jeg se eller kontakte medarbejdere inden for en afdeling?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hvis du vil udforske hvem der er hvem inden for en afdeling i TeamConnect, skal du ikke se længere end de tre prikker ved siden af afdelingens navn. Ved at klikke på dette vil 
                  du få muligheder for videre handling:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vis Medarbejdere i Denne Afdeling:</Body1Strong> Vælg dette for at vise en liste over alle 
                      medarbejdere knyttet til afdelingen. Tallet ved siden af dette valg angiver, hvor mange medarbejdere der er i afdelingen, hvilket giver et hurtigt mandtal.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Afdelingens Organisationsdiagram:</Body1Strong> Hvis du ønsker at forstå afdelingens hierarki, 
                      vælg 'Afdelingens organisationsdiagram' for at afsløre den strukturelle opstilling, som viser relationen mellem overordnede afdelinger, den pågældende afdeling, 
                      og eventuelle underafdelinger. Denne visuelle fremstilling hjælper dig med at navigere og forstå organisationsrammen, så det bliver lettere at identificere og 
                      kontakte specifikke afdelingsmedlemmer.
                    </li>
                    <li><Body1Strong>Afdeling {'>'} Medarbejder:</Body1Strong> Efter at have set medarbejderne inden for en afdeling, brug dette brødkrummespor til nemt 
                      at navigere tilbage til listen over afdelinger, og oprethold en glat udforskningsoplevelse inden for TeamConnect.
                    </li>
                  </ul>
                  Disse funktioner giver dig mulighed for ikke kun at se listen over medarbejdere i en given afdeling, men også tilbyder en vej til at kontakte dem direkte gennem 
                  TeamConnects kommunikationsværktøjer.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Opdag dybtgående detaljer om afdelingen i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Dyk ned i detaljerne om enhver afdeling med et enkelt klik på dens navn i TeamConnect. En detaljeret dialog udfolder sig og tilbyder en rig mosaik af 
                  information, som giver et fuldt billede af afdelingens rolle og ressourcer inden for din organisation. Du vil finde alt fra ledelse og 
                  finansielle identifikatorer til kommunikationskanaler og sociale links. Denne funktion er din gateway til:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Afdelingens hjerte - dens formål, folk og præstationsmålinger.</li>
                    <li className={styles.bulletBottomMargin}>Kontaktveje - direkte linjer til nøglefigurer i afdelingen og kommunikationsplatforme.</li>
                    <li className={styles.bulletBottomMargin}>Den organisatoriske web – forståelse af hvordan afdelingen integreres med den bredere virksomhedsstruktur.</li>
                  </ul>
                  Uanset om det er en hurtig forespørgsel eller en dybdegående undersøgelse af afdelingsdata, udstyrer TeamConnect dig med værktøjerne til at forbinde med og forstå de unikke 
                  økosystemer inden for din organisation.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Nysgerrig omkring manglen på afdelingsadresser i TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Det kan nogle gange føles som en skattejagt uden kort at finde en afdelings placering. I TeamConnect finder du normalt ikke en dedikeret 
                  adresse for hver afdeling. Her er hvorfor denne kartografiske detalje ofte udelades:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Flere Lokationer:</Body1Strong> Mange afdelinger er ikke begrænset til et enkelt sted; de
                      trives på tværs af forskellige kontorer og geografier.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Fluid Struktur:</Body1Strong> Det organisatoriske landskab er i konstant forandring, med 
                      afdelinger, der udvikler sig, fusionerer eller endda opløses i overensstemmelse med strategiske skift.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Interdepartementalt Samarbejde:</Body1Strong> Moderne arbejdspladser summer af samarbejdsånd, ofte med talenter fra forskellige afdelinger, der blander sig i fælles rum.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Fjernarbejdskultur:</Body1Strong> Med fleksibiliteten ved fjernarbejde er afdelingsanker ikke altid slået ned i fysiske kontorplaceringer.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Juridiske Nuancer:</Body1Strong> Afdelinger kredser typisk omkring virksomhedens juridiske 
                      struktur snarere end at have deres egne selvstændige adresser.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Operativ Forenkling:</Body1Strong> At holde fokus på dynamisk teamwork, ikke statiske 
                      adresser, hjælper med at opretholde operationel smidighed.
                    </li>
                  </ul>
                  Det handler alt sammen om at omfavne en grænseløs tilgang til teamwork og effektivitet. TeamConnect afspejler denne filosofi ved at lægge vægt på forbindelser frem for 
                  placeringer.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Mangler din afdelingsinformation eller er den forældet?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Afdelingsredaktører er udpeget i din organisation til at tilføje, opdatere eller rette specifik afdelingsinformation i TeamConnect.
                  Hvis du er usikker på, hvem din afdelingsredaktør er, eller hvis visse oplysninger kræver intervention fra HR support teamet, 
                  kontakt venligst <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> for vejledning.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Ofte Stillede Spørgsmål om Kontorfortegnelsen</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Introduktion til Kontorfortegnelsen:</Body1Strong> Opdag rygraden i din organisations fysiske tilstedeværelse gennem Kontorfortegnelsen,
                som omfatter essentielle kontordetaljer, operationelle specifikationer og et omfattende overblik over afdelingerne og personalet, der definerer hver placering.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Navigering af mærkater i Kontorfortegnelsen
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Find rundt i Kontorfortegnelsen med lethed ved brug af vores mærkatesystem designet til hurtig adgang til den rigtige placering. Her er hvordan hver mærkat kan hjælpe med at strømline din søgning:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Alle:</Body1Strong> Viser en komplet oversigt over kontorer, hvilket giver et fugleperspektiv af 
                      din organisations placeringer.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoritter:</Body1Strong> Hjælper dig med at fokusere på de kontorplaceringer, du ofte besøger eller har brug for 
                      at holde øje med, ved at markere dem som 'Favoritter'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Dit Kontor:</Body1Strong> Dette personlige mærkat filtrerer fortegnelsen for at vise dig kontorplaceringen, hvor du er stationeret, 
                      holde din hjemmebase lige ved hånden.
                    </li>
                  </ul>
                  Uanset om du booker et mødelokale eller planlægger et besøg, tilbyder disse mærkater en personlig og forenklet måde at interagere med din organisations 
                  geografiske landskab.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
           
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Beherske visninger af kontorfortegnelser i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Dyk ned i den organisatoriske struktur af din virksomheds forskellige lokaliteter med TeamConnects kontorfortegnelsesvisninger. Hver visning er skabt for at fremme 
                  et unikt aspekt af navigering og forståelse i kontoret:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Listevisning:</Body1Strong> Standardindstillingen, der organiserer alle kontorlokationer i 
                      en letlæselig liste.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Stor Kortvisning:</Body1Strong> Stor Kortvisning i kontorfortegnelsen beriger din 
                      visning med en detaljeret præsentation af hvert kontor. Når du interagerer med et kontorkort, finder du en masse information og handlingsmuligheder 
                      inden for "Mere (...)" menuen:
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Se Afdelinger:</Body1Strong> Naviger direkte til en liste
                          over alle afdelinger placeret i kontoret.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Se Medarbejdere:</Body1Strong> Få adgang til en komplet liste over medarbejdere tildelt til 
                          kontoret, som gør det lettere at tage direkte kontakt eller udforske kontorets personale.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Forretningenhedens Orgdiagram:</Body1Strong> Visualiser kontorets placering inden for den større 
                          organisatoriske kontekst for en klar forståelse af hierarkiske strukturer.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Væsentlige Kontakter:</Body1Strong> Identificer nøglekontakter på kontoret for strømlinet 
                          kommunikation.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Kontorets Offentlige Helligdage:</Body1Strong> Hold dig informeret om kontorspecifikke offentlige 
                          helligdage for at planlægge dine tidsplaner i overensstemmelse hermed.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Kontorets Arbejdstider:</Body1Strong> Se kontorets åbningstider for at afstemme dine 
                          besøg eller opkald med deres aktive tider.
                        </li>
                      </ol>
                      Denne visning tilbyder ikke kun et visuelt og informativt øjebliksbillede, men også hurtig adgang til dybere indsigt og direkte handlinger relateret til kontoret, alt sammen pænt arrangeret under "Mere (...)" muligheden for din bekvemmelighed.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Orgdiagram Visning:</Body1Strong> Visualiser den hierarkiske struktur af moderselskabs- og underkontorer 
                      for at forstå sammenhængen mellem forskellige kontorlokationer inden for din organisation.
                    </li>
                  </ul>
                  Skift mellem disse visninger ved hjælp af ikonerne ved siden af søgefeltet for at tilpasse din browsingoplevelse til dine umiddelbare behov.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Hurtig Findning af Kontorer med TeamConnects Søgefunktion
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Det er gjort let at finde det rigtige kontorlokation inden for TeamConnect med den dedikerede søgefunktion. Placeret fremtrædende ved siden af visningsikonerne, er søgefeltet dit værktøj til at pinpointe kontorer efter navn. Når du skriver, raffinerer fortegnelsen visningen for kun at vise kontorer, der matcher 
                  din indtastning, hvilket strømliner din søgeproces. Det er vigtigt at bruge denne søgeboks inden for TeamConnect for at opnå de mest nøjagtige og relevante resultater, 
                  i modsætning til den generelle Microsoft Teams søgning. Med denne målrettede søgefunktionalitet kan du hurtigt navigere til et hvilket som helst kontor i din 
                  organisations netværk.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Navigerer i kontorfortegnelsen alfabetisk
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hvis du foretrækker at søge efter alfabet, er vores alfabetiske filter en hurtig måde at finde kontorer på efter navn. Øverst i kontorfortegnelsen vil du
                  se bogstaverne A til Z. Når du klikker på et bogstav, filtreres visningen, så kun de kontorer, der starter med det pågældende bogstav, vises, hvilket forenkler din 
                  søgeproces. For at nulstille og se alle kontorer igen, skal du blot fravælge det aktive bogstav. Denne funktion er især nyttig, når du kender kontorets 
                  navn, men hurtigt skal finde det i TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Afslører omfattende kontorprofiler i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Begiv dig ud på en opdagelsesrejse gennem hjertet af din organisations fysiske tilstedeværelse med TeamConnects kontorprofiler. 
                  Når du klikker på et kontornavn, åbnes en levende profil-dialog fyldt med faner, der indeholder detaljerede indsigt om kontoret:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Oversigt:</Body1Strong> Dyk ned i kontorets kerneidentitet med detaljerede 
                      beskrivelser, fra virksomhedsstruktur til åbningstider og lokal tid, der fanger essensen af kontormiljøet.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vigtige Kontakter:</Body1Strong> Få adgang til afgørende kontakter til nødtjenester og 
                      ekstern support, hver med et klik gennem Microsoft Teams Phone System.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontorkontakter:</Body1Strong> Nå nemt ud til interne supporttjenester, med 
                      kontaktinformationer og direkte engagement muligheder lige ved hånden.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Offentlige Helligdage:</Body1Strong> Hold dig ajour med kontorspecifikke helligdage, sikre dine 
                      planer stemmer overens med lokale iagttagelser og arbejdsskemaer.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontortider:</Body1Strong> Planlæg dine interaktioner med præcis viden om, hvornår 
                      kontoret summer af aktivitet eller lukker ned for dagen.
                    </li>
                  </ul>
                  Uanset om det er den pulserende energi i Austin eller det strategiske knudepunkt i Singapore, tilbyder hver fane et vindue til at forstå og forbinde med de forskellige 
                  steder, der udgør din virksomheds globale tilstedeværelse.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Dykke ned i afdelingsdynamikker inden for et kontor
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  At navigere gennem det professionelle netværk af et kontors afdelinger udfolder sig nemt i TeamConnect. Her er hvordan man går i dybden med detaljerne:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Se afdelinger i dette kontor:</Body1Strong> Opdag alle de afdelinger, der udgør kontorets 
                      væv ved at vælge denne mulighed fra Flere (...) menuen.
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organisatorisk Indblik:</Body1Strong> Klik på en hvilken som helst afdeling for at afsløre dens organigram for 
                      en visuel repræsentation af dens struktur, med brødsmulestien, der guider dig: Kontor {'>'} Afdeling {'>'} Diagram.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Afdeling Dybdegående:</Body1Strong> Udforsk yderligere en afdeling ved at klikke på dens 
                      Flere menu (...) ved siden af afdelingsnavnet. Her kan du få adgang til en liste over alle medarbejdere inden for den afdeling, lige i kontorkonteksten.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Brødsmulenavigation:</Body1Strong> Gennemgå niveauerne ubesværet. Fra 
                      medarbejdere tilbage til afdelinger eller fra organigrammet, brødsmulestien (Kontor {'>'} Afdeling {'>'} Medarbejder {'>'} Diagram) holder din 
                      rejse intuitiv og på sporet.
                    </li>
                  </ul>
                  TeamConnects indviklede stier inviterer dig til at udforske, forstå og engagere dig med hver lag af dit kontors afdelinger, og giver dig 
                  omfattende navigationskommando.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Forbinde med kontorpersonale i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nysgerrig efter ansigterne bag et kontor? Svaret er bare et klik væk i TeamConnect. Engagere med ellipsen (tre prikker) ved siden af dit valgte 
                  kontornavn for at afsløre en suite af interaktive muligheder:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Se medarbejdere i dette kontor:</Body1Strong> Afdæk en liste over alle de professionelle 
                      der kalder dette kontor deres arbejdshjem. Det viste antal tilbyder en hurtig optælling af medarbejdere, der inviterer dig til at dykke ned i kontorfællesskabet.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontaktmuligheder:</Body1Strong> Hver medarbejderpost er udstyret med hurtig-handlingsikoner, 
                      der giver dig mulighed for at starte chats, opkald eller endda videokonferencer lige fra TeamConnect, og fremmer problemfri intra-kontorkommunikation.
                    </li>
                    <li><Body1Strong>Navigation:</Body1Strong> For at vende tilbage til den bredere kontorliste fra en liste over medarbejdere, skal du blot klikke på 'Kontor' i brødsmulestien 
                      (Kontor {'>'} Medarbejder), og du vil blive taget tilbage til kontoroversigten.
                    </li>
                  </ul>
                  Med disse funktioner gør TeamConnect det simpelt at visualisere arbejdsstyrkedistributionen på tværs af kontorer og at nå ud ubesværet,
                  og fremmer en forbundet arbejdsplads uanset afstanden.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Mangler eller er dine kontoroplysninger forældede?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Opdateringer af kontoroplysninger i TeamConnect kan håndteres af tildelte kontorredaktører. Hvis du er usikker på, hvem kontorredaktørerne er, eller 
                  har brug for hjælp til oplysninger, der hører under HR's område, står HR-supportteamet klar til 
                  at hjælpe på <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Indstillinger - Ofte stillede spørgsmål</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Vigtigheden af nøjagtige generelle indstillinger i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  I dagens dynamiske arbejdsmiljø er det mere afgørende end nogensinde at forblive forbundet og koordineret. Derfor spiller det at opdatere dine 
                  Generelle Indstillinger i TeamConnect en central rolle:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Arbejdspladsvalg:</Body1Strong> Fang essensen af dit arbejdsmiljø ved at opdatere din Arbejdsplads, så den afspejler din nuværende 
                        situation, uanset om det er et skifte fra roen i dit hjemmekontor til travlheden i hovedkontoret, eller endda en indretning i et offentligt område. 
                        Denne klarhed i din arbejdslokation giver kolleger indsigt ikke kun i de bedste måder at kontakte dig på, men også i hvilken type 
                        samtaler de kan indgå med dig, hvilket sikrer fortroligheden af følsomme diskussioner, især når du befinder dig i det åbne 
                        miljø i en klients kontor eller sidder i en lufthavnslounge.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Specifikation af arbejdssted:</Body1Strong> At dele din arbejdsby gør mere end at brobygge i et remote team-setup – det åbner også 
                        døre for spontane professionelle sammenkomster eller uformelt socialt samvær. Uanset om det er en hurtig kaffe, en afslappende frokost eller et samarbejdsmøde, 
                        kan det at vide, at du er i samme by, forvandle en almindelig dag til en mulighed for meningsfulde ansigt-til-ansigt-forbindelser. Dette styrker ikke kun 
                        teambåndene, men giver også en dybere forståelse af de lokale nuancer, som kan påvirke dit arbejde.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Præcision i tidszonen:</Body1Strong> Eftersom teams spænder over kontinenter, er det afgørende, at din tidszone 
                      afspejler dit nuværende opholdssted for at harmonisere kommunikationen. Din TeamConnect-profil's Lokal Tid påvirkes direkte af denne indstilling, 
                      og eliminerer enhver gætteri for kolleger, der forsøger at nå dig.
                    </li>
                  </ul>
                  Dyk ind i 'Indstillinger' {'>'} 'Generelle indstillinger' inden for TeamConnect for at finjustere disse detaljer. Et par øjeblikke brugt på at opdatere dine indstillinger kan 
                  markant forbedre samhørigheden og effektiviteten af dine samarbejdsbestræbelser på tværs af alle områder.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
           
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Afkodning af arbejdspladsvalg i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  At vælge den rigtige indstilling i rullemenuen Arbejdsplads tilpasser TeamConnect til nøjagtigt at afspejle din nuværende arbejdsstatus. Hold musemarkøren over info-ikonet for at få en detaljeret forklaring af hver mulighed:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Break:</Body1Strong> Betyder tid væk fra arbejdet, enten til en pause eller dagens ende.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office/Field-Office:</Body1Strong> Angiver tilstedeværelse i et firmaadministreret arbejdsrum.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> Arbejde fra et dedikeret hjemmekontor.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Public-Area:</Body1Strong> Arbejder fra et ikke-kontormiljø, hvilket antyder et behov for fleksibel kommunikation.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Customer Workplace/Private Office:</Body1Strong> På stedet hos en klient, med en note om privatlivets fred for samtalefølsomhed.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Commute/Business-Travel/Training:</Body1Strong> På farten eller engageret i specifikke arbejdsrelaterede aktiviteter uden for kontoret.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Public-Holiday/Vacation/Various Leaves:</Body1Strong> Officielt fri for personlige, sundhedsmæssige eller feriemæssige årsager.</li>
                  </ul>
                  At vælge en passende arbejdspladsindstilling sikrer, at dit team har den kontekst, der er nødvendig for interaktioner, med respekt for tilgængelighed og privatliv.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Navigering af 'Checked-In' status i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  'Checked-In' mærket i medarbejderregisteret er designet til at vise alle medarbejdere, som aktuelt er på arbejde. Denne synlighed bestemmes af det arbejdspladsvalg du foretager:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Active Work Environments:</Body1Strong> Valg som Office, Field-Office, Home-Office, Remote-Workplace, Public-Area, Customer-Workplace, 
                    Customer Private-Office, Commute, Business-Travel og Training signalerer, at du er engageret i arbejde, hvilket gør dig 'Checked-In' og synligt aktiv overfor kollegaer.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Non-Work Settings:</Body1Strong> At vælge Checked-Out, Break, Public-Holiday, Vacation, On-Leave, Maternity-Leave eller Sick-Leave 
                      fjerner dig fra 'Checked-In' listen, hvilket sætter klare forventninger til tilgængelighed og responstider.
                  </li>
                  </ul>
                  Denne funktionalitet hjælper med at afgrænse arbejdsperioder fra personlig tid, hvilket støtter effektivt samarbejde og kommunikation i teamet.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Datahåndtering og privatlivs-FAQs</Subtitle2>
          <Body1>
            <p>
              I den digitale tidsalder er det afgørende at forstå, hvordan dine data håndteres. TeamConnect er dedikeret til gennemsigtighed og robuste databeskyttelsespraksisser. 
              Nedenfor finder du oplysninger om, hvordan vi håndterer, opbevarer og beskytter dine data inden for TeamConnect, hvilket sikrer, at dit digitale arbejdsområde er sikkert og overholder reglerne.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Hvordan beskytter TeamConnect mine personlige data?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect er dedikeret til at beskytte dine personlige oplysninger med en omfattende tilgang til datasikkerhed:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Single Sign-On (SSO):</Body1Strong> For at styrke sikkerheden og forenkle brugeroplevelsen integrerer TeamConnect automatisk 
                      Single Sign-On (SSO) med din Microsoft 365-konto, hvilket muliggør sikker og ubesværet adgangsstyring.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Kryptering:</Body1Strong> Vi sikrer, at alle data inden for TeamConnect er krypteret, både under overførsel og når de er inaktive, 
                      hvilket gør dem utilgængelige for uautoriserede parter.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Minimalisering af data:</Body1Strong> Vores dataindsamling er strengt begrænset til det, der er væsentligt for TeamConnects funktionalitet. 
                      De eneste brugeroplysninger, vi opbevarer, er dit User Principal Name (UPN) fra Azure Entra ID, som kan ligne din virksomhedsmail.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Begrænset adgang:</Body1Strong> Adgang til UPN er begrænset til abonnementsadministratoren i din organisation inden for vores abonnementsportal, 
                      hvilket sikrer, at følsomme oplysninger kun er synlige for autoriseret personale.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Omgående sletning af data:</Body1Strong> TeamConnect er struktureret til straks at slette det opbevarede UPN under tre specifikke betingelser: 
                      hvis abonnementet annulleres af abonnementsadministratoren, hvis en bruger fjernes af HR inden for TeamConnect, eller hvis TeamConnect 
                      abonnementet udløber. Dette sikrer, at dine personlige data ikke opbevares længere end deres nyttige levetid eller nødvendighed.
                    </li>                      
                  </ul>
                  <p>Med disse foranstaltninger forpligter TeamConnect sig til at opretholde de højeste standarder for databeskyttelse, hvilket sikrer, at dine personlige oplysninger forbliver sikre og fortrolige.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Spekulerer du på, hvor dine data befinder sig med TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect bryder med normen for traditionelle SaaS-tilbud ved at sikre, at dine data aldrig forlader din organisation. 
                  I modsætning til den sædvanlige praksis med at lagre data på eksterne cloudservere, udnytter TeamConnect snedigt din organisations 
                  Microsoft 365 SharePoint Online til at huse alle applikationsdata. Denne innovative tilgang fremmer ikke kun datadominans men styrker også sikkerheden, 
                  perfekt i overensstemmelse med vores strenge tilsagn om at beskytte dit privatliv og overholde strenge overholdelsesstandarder.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Vil mine data blive delt med tredjeparter?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nej, data deles ikke med tredjeparter! Cubeet er standhaftig i vores forpligtelse til dine datas fortrolighed og sikkerhed. Vi deler ikke nogen TeamConnect-data med tredjeparter uden at opnå udtrykkelig samtykke fra vores kunder. 
                  I vores sikre miljø opbevarer vi kun væsentlige identifikatorer, såsom brugerhovednavnet (UPN), organisationsnavnet, lejernavnet, og lejer-ID, inden for vores abonnementsportal. 
                  Disse oplysninger er afgørende for tjenesteydelsen og er beskyttet under vores strenge privatlivspolitik.
                  Vær sikker, vores databehandlingspraksis er designet til at sikre, at dine data forbliver fortrolige, med gennemsigtighed og kontrol fast i dine hænder.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Hvordan overholder TeamConnect databeskyttelsesregulativer som GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect er i overensstemmelse med databeskyttelsesregulativer som GDPR, HIPAA, CCPA og andre ved at udnytte de medfødte compliance-funktioner i Microsoft 365-miljøet, 
                  hvor alle kundedata sikkert opbevares. I modsætning til traditionelle SaaS-modeller opbevarer TeamConnect ikke nogen kundedata på sine egne servere; i stedet opererer det inden for din 
                  organisations Microsoft 365-miljø, under hensyntagen til dets robuste sikkerheds- og compliance-standarder. Denne tilgang sikrer, at dit databehov forbliver konsistent med 
                  det omfattende compliance-rammeværk leveret af Microsoft 365, inklusive overholdelse af GDPR, HIPAA for sundhedsinformation, CCPA for beboere i Californien, 
                  og andre globale databeskyttelsesregulativer. Ved at integrere problemfrit med din eksisterende Microsoft 365-konfiguration, bemyndiger TeamConnect din organisation til at opretholde 
                  fuld kontrol over dine data, og sikre overholdelse af de strengeste databeskyttelseslove og -regulativer.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Hvordan håndterer TeamConnect data fra Microsoft Teams chats, opkald, videoopkald, Outlook e-mails og telefonsystemopkald?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect udnytter Microsoft Graph API til at muliggøre direkte interaktioner såsom chat, opkald, videoopkald og Outlook e-mails inden for Microsoft Teams,
                  hvilket giver en sømløs brugeroplevelse. Afgørende er det, at mens TeamConnect initierer disse kommunikationer, gør det det uden at gribe ind i kommunikationsprocessen
                  eller have evnen til at optage eller gemme noget af interaktionsindholdet. Alle samtaler, opkald og e-mails finder sted og forvaltes direkte inden for de
                  sikre rammer af Microsoft Teams og Outlook, i streng overensstemmelse med Microsofts 365 robuste sikkerheds- og privatlivsstandarder. Dette sikrer, at TeamConnect forbedrer
                  dine kommunikationskapaciteter, samtidig med at det fuldstændig bevare fortroligheden og integriteten af dine data.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            For detaljerede indsigter i vores datapolitikker og praksis, vil du måske også ønske at konsultere {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>TeamConnect Brugervejledning</Body1Strong></Link> eller din organisations IT-
              afdeling på <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> for specifikke oplysninger relateret til din opsætning.
          </p>
        </Body1>        
        <p></p>  
        <Subtitle1 className={styles.fontColor}>Vi Lytter: Del Din Feedback og Ideer</Subtitle1>
        <Body1>
          <p>
            Dine indsigter driver TeamConnects udvikling fremad. Uanset om du bobler over med en brillant funktionside eller har noget
            feedback på din nuværende oplevelse, er vi hele ører. Dine bidrag er uvurderlige i vores fortsatte stræben efter at levere den
            bedst mulige service.
          </p>
        </Body1>      
        <Subtitle2>Feedback og Forslag til Funktioner</Subtitle2>
        <Body1>
          <p>
            Klar til at dele? Gå til vores {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Feedbackside
            </Link>
            . Uanset om det er et forslag til en ny funktion eller feedback på eksisterende funktionaliteter, skal du blot vælge
            den passende mulighed fra dropdown-menuen i formularen. Dine bidrag hjælper med at forme TeamConnects fremtid, 
            sikrer, at det møder og overstiger dine behov.
          </p>
        </Body1>
    </>
  );
};
Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};
export default Container;
