import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});
function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>TeamConnect Yardım Merkezi & SSS</Title3>
        <p><Body1>
          TeamConnect Yardım Merkezi'ne hoş geldiniz, kapsamlı kurumsal rehber uygulamamızla deneyiminizi desteklemek için tasarlandı. Burada, TeamConnect'i Microsoft Teams içinde optimize edilmiş bir şekilde kullanmanız için kaynaklar bulacaksınız, ağınız boyunca iletişimi güçlendirir.
        </Body1></p>
        <Subtitle2>Hızlı Referans Kılavuzu</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Gelişmiş Arama Yetenekleri: </Body1Strong>
              Güçlü arama araçlarımızla doğru meslektaşları, departmanları ve temel kişileri hızla bulun.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Görsel Organizasyon Şemaları: </Body1Strong>
              Organizasyonunuzun hiyerarşisine hakim olun ve raporlama hatlarını kolayca anlayın, sezgisel organizasyon şemaları aracılığıyla.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Bugünün İhtiyaçlarına Uyarlanmış Araçlar: </Body1Strong>
              Çağdaş iş yerlerinin taleplerine uygun olarak özelleştirilmiş modern işlevlerden yararlanın.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Deneyiminizi Optimize Edin</Subtitle2>
        <Body1>
          <p>
            TeamConnect, organizasyonel yapınız içinde etkileşim şeklinizi devrim niteliğinde değiştirir, günlük iletişimleri stratejik etkileşimlere dönüştürür.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Yardım Alın & SSS</Subtitle1>
        <Body1>
          <p>
            Bu özel bölümde TeamConnect'in özellikleri ve ayarları hakkında sıkça sorulan soruların yanıtlarını bulun. Uygulamayı kullanırken, ayarları özelleştirirken veya zorluklara çözüm ararken, Yardım Merkezimiz sizi verimli bir şekilde desteklemek için burada.
          </p>
        </Body1>
        <Body1>
          <p>
            Daha kapsamlı destek ya da özel rehberlik için lütfen <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnect Kullanıcı Rehberi</Body1Strong></Link>ne başvurun.
          </p>
        </Body1>
        <p></p>        
        <Subtitle2>TeamConnect SSS'lerini Kullanma</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Microsoft Teams yan çubuğunda TeamConnect bulamıyor musunuz?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Eğer TeamConnect Microsoft Teams yan çubuğunda kolayca görünmüyor ya da kaybolduysa, hızlı erişim için kolayca sabitleyebilirsiniz. Sadece bu basit adımları takip edin:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Microsoft Teams'i açın ve yan çubuğun alt kısmındaki '...' (+ Uygulamalar) simgesine tıklayın.</li>
                    <li className={styles.bulletBottomMargin}>Açılan pencerenin üst kısmında bulunan arama çubuğuna 'TeamConnect' yazın ve aratın.</li>
                    <li className={styles.bulletBottomMargin}>Arama sonuçlarından TeamConnect uygulamasına sağ tıklayın ve 'Sabitle' seçeneğini seçin.</li>
                  </ol>
                  TeamConnect artık yan çubuğunuza sabitlendi, böylece sadece bir tıklama ile erişilebilir!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. TeamConnect ile teknik sorunlar mı yaşıyorsunuz? İşte yardım almanın yolu.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Microsoft Teams içinde TeamConnect ile herhangi bir teknik sorun yaşarsanız, kuruluşunuzun IT Destek ekibi yardımcı olmak için burada.
                  <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>'da onlara ulaşın, size hemen yardımcı olacaklar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Çalışan detaylarınızı göremiyor musunuz?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Profiliniz sadece adınız ve e-posta adresinizle biraz boş görünüyorsa, muhtemelen İK ekibiniz henüz TeamConnect'e geri kalan çalışan detaylarını eklememiş demektir.
                  Endişelenmeyin! İK destek ekibinize kısa bir mesaj işleri harekete geçirecektir. Profilinizi tüm önemli detaylarla doldurmak için hazır bekliyorlar.
                  <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>'da ulaşın ve profilinizin canlandığını izleyin!
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. TeamConnect'te hangi dil seçenekleri mevcut?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect, Microsoft Teams'in küresel çeşitliliğini destekleyerek tüm
                  dilleri kapsar. Arapça ya da Yunanca, Japonca ya da İsveççe tercih edin,
                  parmaklarınızın ucunda şunlar dahil bir dünya seçeneğiniz vardır:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arapça, Bulgarca, Çince (Basitleştirilmiş ve Geleneksel), Hırvatça,
                      Çekçe, Danca, Flemenkçe, İngilizce, Estonca, Fince, Fransızca,
                      Almanca, Yunanca, İbranice, Macarca, Endonezce, İtalyanca, Japonca,
                      Korece, Letonca, Litvanca, Norveççe (Bokmal), Lehçe, Portekizce
                      (Brezilya ve Portekiz), Romence, Rusça, Sırpça (Latin), Slovakça,
                      Slovence, İspanyolca, İsveççe, Tayca, Türkçe, Ukraynaca, Vietnamca.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  TeamConnect deneyiminizi tercih ettiğiniz dile göre özelleştirmek için,
                  lütfen dil ayarlarınızı direkt olarak Microsoft Teams üzerinden ayarlayın,
                  çünkü TeamConnect bu ayarlarla tam olarak entegredir:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Microsoft Teams ayarlarınıza profil resminizin yanındaki üç noktaya
                      tıklayarak ulaşın ve 'Görünüm ve erişilebilirlik' seçeneğini seçin.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      'Dil' bölümüne gidin ve açılır menüden tercih ettiğiniz dili seçin.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      'Kaydet ve yeniden başlat' düğmesine tıklayarak yeni dil ayarlarını
                      uygulayın.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Not: </Body1Strong>
                <Body1>
                  Microsoft Teams'teki dil ayarlarınızda yapılan değişiklikler, TeamConnect
                  deneyiminizi de sorunsuz bir şekilde güncelleyecektir. Teams'teki dili
                  değiştirmenin zaman ve tarih formatınızı da ayarlayabileceğini unutmayın.
                  Şu anda, TeamConnect sağdan sola yazım için geliştirilmektedir. Bu
                  geliştirmeler konusunda heyecanlıyız ve kullanılabilir hale geldikçe sizi
                  bilgilendireceğiz.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. TeamConnect’te tema nasıl değiştirilir?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect, Microsoft Teams tema tercihlerinizle senkronize olur, bunlar Aydınlık, Karanlık ve Yüksek Kontrast seçeneklerini içerir. Temanızı özelleştirmek için:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Profil resminizin yanındaki üç noktaya tıklayarak Microsoft Teams ayarlarına erişin.</li>
                    <li className={styles.bulletBottomMargin}>'Görünüm ve erişilebilirlik' seçeneğini seçerek tema seçeneklerini keşfedin.</li>
                    <li className={styles.bulletBottomMargin}>Tercih ettiğiniz temayı mevcut seçenekler arasından seçin ve TeamConnect arayüzünüzü anında güncelleyin.</li>
                  </ol>
                  Microsoft Teams'teki tema seçiminiz otomatik olarak TeamConnect'e uygulanır ve çalışma alanınızda tutarlı bir görsel deneyim sağlar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. TeamConnect’in İletişim Eylem Düğmelerini Keşfetme
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect boyunca, bir çalışanın iletişim detaylarını bulduğunuz her yerde, kullanışlı hızlı erişim düğmelerimizi de keşfedeceksiniz. Bu kullanışlı araçlar,
                  Microsoft ekosistemi içinde iletişiminizi hızlandırmak için tasarlanmıştır, böylece bağlantı kurmak için her zaman bir tık uzağınızda olursunuz.
                  Bu düğmeleri etkili bir şekilde kullanmanıza yönelik kapsamlı rehberiniz:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chat İkonu:</Body1Strong> Anında bir Microsoft Teams sohbeti başlatmak için tıklayın. Sohbet başladıktan sonra TeamConnect’e geri dönmek için, Masaüstü Uygulamasında geri oku, Mobil Uygulamada geri düğmesi veya tarayıcınızın geri butonunu kullanın.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>E-posta İkonu:</Body1Strong> Alıcının adresi "Kime:" alanında hazır şekilde yeni bir Outlook e-posta taslağı açar. Outlook başlamazsa, herhangi bir kısıtlama için BT departmanınızla kontrol etmek faydalı olabilir.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Video Arama İkonu:</Body1Strong> Microsoft Teams kullanarak yüz yüze bir video araması başlatır.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Arama İkonu:</Body1Strong> Microsoft Teams üzerinden bir sesli arama başlatarak hızlı tartışmalar yapmanızı sağlar.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org Şeması İkonu:</Body1Strong> Çalışanın organizasyonel hiyerarşideki yerini, yöneticisini ve doğrudan raporlarını gösterir.</li>
                    <li><Body1Strong>Daha Fazla (...) İkonu:</Body1Strong> Daha fazla seçenek gösterir:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Hemen Toplantı:</Body1Strong> Çalışanla hemen bir toplantı başlatmak için Microsoft Teams’i açar.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Yeni Toplantı:</Body1Strong> Yeni bir toplantı planlamak için sizi Microsoft Teams’e yönlendirir.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Kişi Ekle:</Body1Strong> Çalışanın iletişim detaylarını TeamConnect'ten yeni Microsoft Teams Kişiler uygulamanıza ekler, arama işlevinizi zenginleştirir.</li>
                      </ul>
                    </li>
                  </ul>
                  Bu sezgisel düğmeler, hızlı bir sohbet, bir e-posta veya gelecekteki toplantıları planlama gibi ihtiyaçlarınızda meslektaşlarınızla verimli bir şekilde bağlantı kurmanızı sağlar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. TeamConnect Üzerinden Arama Yapma
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Bir arama yapmak mı istiyorsunuz? TeamConnect iletişiminizi kolaylaştırır ve Microsoft Teams ile entegre eder.
                  TeamConnect'te istediğiniz telefon numarasına tıklayın ve işte, Microsoft Teams arama arayüzü açılır,
                  sorunsuz bir şekilde arama yapmanız için hazır hale gelir.
                  <p>
                  <Body1Strong>Not: </Body1Strong>
                  Arama yaparken sorunlarla karşılaşıyorsanız, bu durum kuruluşunuzun BT politikalarından veya Microsoft Teams Telefon Sistemi dışında bir telefon sistemini kullanmanızdan kaynaklanıyor olabilir. <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> adresindeki BT departmanınızla iletişime geçmek, durumu aydınlatmaya yardımcı olabilir.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. TeamConnect aracılığıyla yapılan aramalarla ilgili maliyetler var mı?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect, Microsoft Teams kullanarak arama deneyiminizi artırır. Aramalarla ilgili maliyetler hakkında bilmeniz gerekenler:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Kuruluşunuzda (iç numaralar) çalışanların iş telefonlarına, bölüm telefonlarına veya acil hatlara yapılan aramalar için,
                      Microsoft Teams Telefon Sistemi kullanıldığında genellikle <Body1Strong>hiçbir maliyet yoktur</Body1Strong>.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Mobil telefonlar veya dış hatlar gibi dış aramalar için, kuruluşunuzun telefon sisteminin <Body1Strong>standart tarifeleri</Body1Strong> geçerlidir.
                      Bu tarifeler, Microsoft Teams ile olan özel hizmet planınıza bağlı olarak değişiklik gösterir.
                    </li>
                  </ul>
                  <p><Body1Strong>Not:</Body1Strong> Arama ücretleriyle ilgili herhangi bir sorunla karşılaşırsanız veya sorularınız varsa, kuruluşunuzun yapılandırması ve politikalarına özel 
                    detaylı bilgi için <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> adresindeki BT departmanıyla görüşmeniz en iyisidir.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. TeamConnect'te 'Ofis Dışı' durumunuzla ilgili bir uyumsuzluk mu fark ettiniz?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Microsoft 365'teki 'Ofis Dışı' varlık durumunuzun TeamConnect'te doğru şekilde yansıtılmadığını mı fark ettiniz?
                  Sorununuzu çözmek için gereken özellik TeamConnect'te zaten uygulanmış durumda.
                  Şu anda bu özelliğin Microsoft tarafından dağıtılmasını bekliyoruz. Bu gerçekleştiğinde, 'Ofis Dışı' varlık durumunuz,
                  sorunsuz bir deneyim için Microsoft Teams'in varlık göstergeleriyle uyumlu şekilde TeamConnect'te doğru bir şekilde yansıtılacak.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>Çalışan Rehberi SSS</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Çalışan Rehberi Tanıtımı:</Body1Strong> Kuruluşunuzun profesyonel manzarasını Çalışan Rehberi ile keşfedin,
                meslektaşlarınızın profillerine ve yönetici ilişkilerine doğrudan bağlantılarla dolu, detaylı çalışan bilgileri ve iş yerindeki dinamikler için merkezi bir hub.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Çalışan verilerinizi eklemek, güncellemek veya düzeltmek mi gerekiyor?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect'teki çalışan verilerinizi eklemek, güncellemek veya düzeltmek için lütfen organizasyonunuzun İK destek ekibi ile iletişime geçin.
                  Yardımcı olmaya hazırlar, <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> adresinden ulaşabilirsiniz.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Bir çalışanın adına dizinde tıkladığımda ne olur?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect Çalışan Dizini'nde bir çalışanın adına tıklamak, o kişiye ait detaylı bir profil sayfasını açar. Bu profil, çalışanın iş unvanı, departmanı, ofis 
                  konumu, iletişim bilgileri ve daha fazlası dahil olmak üzere çalışan hakkında kapsamlı bilgiler sunar. Bu profil, çalışanın profesyonel rolünün tam bir 
                  özetini sağlamak ve onun organizasyon içinde nasıl bir yere sahip olduğunu anlamanızı kolaylaştırmak için tasarlanmıştır, böylece sorumluluklarını ve 
                  nasıl işbirliği yapabileceğinizi daha iyi anlayabilirsiniz.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Bir çalışanın profilinde hangi bilgiler yer alır?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect'teki her çalışan profili, kişinin profesyonel yaşamına dair zengin bilgiler sunan kapsamlı bir özet sunar. 
                  İşte bulabileceğiniz bilgiler:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Temel Detaylar:</Body1Strong> İsim, iş unvanı, departman ve bağlı olduğu lokasyon.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Raporlama Yapısı:</Body1Strong> Doğrudan ve fonksiyonel raporlar hakkında bilgi, 
                      dahil olmak üzere çizgi ve fonksiyonel yöneticiler.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>İşyeri Durumu:</Body1Strong> İzinli, İşe Geliş, İş Seyahati gibi güncel çalışma durumları.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Çalışma Yeri & Yerel Saat:</Body1Strong> Çalışanın güncel çalışma yeri ve 
                      yerel saat dilimi.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>İletişim Detayları:</Body1Strong> İş telefonu, cep telefonu numarası ve e-posta adresi.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ofis Adresi:</Body1Strong> Bina adı, adres ve Google harita dahil olmak üzere tam ofis konumu.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Hakkında:</Body1Strong> Kısa kişisel tanıtım veya profesyonel biyografi.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Çalışan İlişkileri:</Body1Strong> Çalışanın raporlama yapısı ve ekip ilişkileri detayları.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sosyal Bağlantılar & Diller:</Body1Strong> Profesyonel sosyal profil bağlantıları 
                      ve dil becerileri.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Beceriler:</Body1Strong> Profesyonel beceriler ve uzmanlık alanları listesi.</li>
                  </ul>
                  Ek olarak, profil, sohbet, e-posta, video arama veya toplantıları planlama yoluyla bağlantı kurma seçenekleri sunar ve geniş ekip bağlamı için 
                  organizasyon şemasına hızlı erişim sağlar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Profil bilgilerimi nasıl güncelleyebilirim?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Profilinizi TeamConnect'te kişiselleştirmek çok kolay. İşte yapmanız gerekenler:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Çalışan</Body1Strong> sekmesine gidin ve profilinizi açmak için <Body1Strong>Avatar</Body1Strong>'ınıza tıklayın.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Sol üst köşede, <Body1Strong>"Profilini düzenle"</Body1Strong> bağlantısını bulacaksınız. 
                        Bunu tıklamak profil düzenleme diyaloğunu açar.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Burada, profilinizi <Body1Strong>Hakkında</Body1Strong> bölümünü ekleyerek veya güncelleyerek, sosyal medya bağlantılarınızı ve telefon numaranızı ekleyerek geliştirebilirsiniz. 
                        Bu, meslektaşlarınızın sizinle iletişime geçmesini veya sizi takip etmesini kolaylaştırır.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Değişikliklerinizi uygulamak için <Body1Strong>Kaydet</Body1Strong> düğmesine basmayı unutmayın.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Diyaloğun altına doğru kaydırarak <Body1Strong>Diller</Body1Strong> ve <Body1Strong>Yetkinlik</Body1Strong> bölümlerini ekleyin,
                        ayrıca <Body1Strong>Beceriler</Body1Strong>inizi de ekleyin. Bu bilgileri paylaşmak, meslektaşlarınızın 
                        uzmanlık alanlarınızı ve hangi dillerde iletişim kurabileceğinizi anlamasına yardımcı olur.
                      </li>
                    </ol>
                  Profilinizi güncellemek, bilgilerinizi güncel tutmanın yanı sıra, organizasyonunuz içinde daha iyi bağlantılar kurmanızı da sağlar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. TeamConnect'te Çalışma Yerinizi, Çalışma Noktanızı ve Saat Diliminizi Güncelleme
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Çalışma yerinizin, çalışma noktanızın ve saat diliminizin bilgilerini güncel tutmak, Microsoft Teams'te kesintisiz işbirliği için hayati önem taşır. 
                  Bu güncellemeler, TeamConnect profilinizde ve listelerinizde anında yansıtılır, meslektaşlarınızın çalışma yerinizi ve sizinle iletişime geçmek 
                  için en uygun zamanları hızla anlamalarını sağlar, farklı bölgelerde ve saat dilimlerinde ekip çalışmasını geliştirir.
                  <p></p>
                  Bilgilerinizi güncellemeye hazır mısınız? TeamConnect'te bu çok kolay! Üst menüdeki 'Ayarlar'a gidin ve 'Genel Ayarlar'a tıklayın. 
                  Buradan Çalışma Yerinizi ve Saat Diliminizi kolayca güncelleyebilir, gün için Çalışma Noktanızı belirtebilirsiniz. Değişikliklerinizi kaydetmeyi unutmayın, 
                  böylece herkes bilgilendirilmiş olur ve yeriniz ne olursa olsun etkili işbirliği sağlanır!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Bir meslektaşınızın profilinde bir yanlışlık mı gördünüz?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Bir meslektaşınızın profilinde doğru olmayan herhangi bir detayla karşılaşırsanız, en iyi ilk adım onlara dostça bir uyarıda bulunmaktır. 
                  Kısa bir sohbet veya e-posta işe yarar! Eğer bir nedenden ötürü bilgi güncellenmezse endişelenmeyin—sadece <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>'ı pingleyin, ve onlar memnuniyetle devreye girip işleri düzeltmek için adım atarlar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Etiket Gruplarını Anlama: Tümü, Favoriler, İşbaşında Olanlar & Yeni Çalışanlar
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tümü:</Body1Strong> Bu varsayılan etiket, organizasyonunuzdaki her çalışanı gösterir,
                      ekibin kapsamlı bir görünümünü sağlar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoriler:</Body1Strong> Görünümünüzü, 'Favoriler' olarak işaretlediğiniz meslektaşlarınıza 
                      filtrelemek için buraya tıklayın, bu da önemli bağlantılarla hızlıca iletişim kurmanızı kolaylaştırır.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>İşbaşında Olanlar:</Body1Strong> Bu etiket, aktif olarak iş başında olan çalışanları 
                      gösterir, yalnızca Microsoft Teams'deki çevrimiçi varlıktan ayrım yapar. İşbaşında olduklarında, çalışanlar işte olduklarını belirtir ve gün 
                      boyunca bulundukları mevcut çalışma konumunu paylaşırlar. Bu, Ofis, Saha-Ofisi, Ev-Ofisi, Uzaktan-İş Yeri, Müşteri-İş Yeri, 
                      Müşteri-Özel-Ofisi, Kamusal-Alan, İşe Gidiş, İş Seyahati veya Eğitim gibi çeşitli iş yerlerini içerebilir. Bu nedenle, 'İşbaşında' durumu, aktif olarak 
                      iş başında olunduğuna dair net bir gösterge sağlar, meslektaşların birbirlerinin mevcut çalışma bağlamını ve erişilebilirliğini net bir şekilde 
                      anlamalarını sağlar.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Yeni Çalışanlar:</Body1Strong> TeamConnect'teki bu etiketle takıma yeni katılan yüzleri 
                      fark edin, son 30 gün içinde uygulamaya eklenmiş çalışanları vurgular. Genellikle, bu sizin organizasyonunuza yakın zamanda katılanları 
                      uyum sağlamalarına yardımcı olarak yeni takım üyelerini karşılamanızı sağlar.
                    </li>
                  </ul>
                  Bu etiket grupları, gezinmeyi kolaylaştırmak ve TeamConnect deneyiminizi geliştirmek için tasarlanmıştır, böylece meslektaşlarınızla her zaman
                  verimli bir şekilde buluşup bağlantı kurabilirsiniz.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Arama Kutusu Yanındaki Simgelerin Çözümlemesi
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect'in Çalışan Rehberi çok yönlülük ve kolaylık için tasarlanmıştır, dört simge sunarak farklı görünümler ve 
                  ana detaylara hızlı erişim sağlar. İşte her simgenin açığa çıkardığı şeyler:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Liste Görünümü:</Body1Strong> Temel görünümünüz, tüm organizasyon üyelerini 
                      basit bir liste halinde sunar.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Küçük Kart Görünümü:</Body1Strong> Kompakt bir alternatif, bu görünüm listeyi 
                      küçük çalışan kartlarına dönüştürür.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Büyük Kart Görünümü:</Body1Strong> Daha fazla detayla büyük kartları tercih edin, bu kartlar
                      telefon ve mobil numaraları içerir, kolay arama yapmanızı sağlar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org Şeması:</Body1Strong> Org Şeması görünümü ile şirketin yapısını görselleştirin,
                      raporlama ilişkilerini anlamak ve organizasyonel manzarayı keşfetmek için mükemmeldir.</li>
                  </ol>
                    Bu simgeler arasında geçiş yaparak, ister hızlı bilgi ister daha derin içgörüler arıyor olun, anlık ihtiyaçlarınıza en uygun görünümü bulun.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Hızlı meslektaş aramaları için nereye bakmalıyım?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Bir meslektaş mı arıyorsunuz? Sadece TeamConnect arama kutusuna gidin, üst sağ tarafta Org şeması simgesinin yanında bulunur. 
                  İsmini yazmaya başlayın ve sonuçların her harfle nasıl rafine olduğunu izleyin. Unutmayın, TeamConnect aramaları için Microsoft Teams 
                  arama çubuğunun üst kısmını atlayın—özel arama kutumuz gidilecek yol!
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Çalışanları bulmak için alfabetik arama nasıl çalışır?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect'in Çalışan Dizini, çalışanları ilk adlarına göre hızlıca bulmanıza yardımcı olacak kullanışlı bir alfabetik arama özelliği içerir.
                  Dizinin üst kısmında görüntülenen alfabedeki bir harfe tıklayın ve TeamConnect, adı o harfle başlayan tüm çalışanları anında gösterecektir. 
                  Tüm çalışanların tam listesine dönmek için, vurgulanan harfi seçiminizden kaldırın. Bu sezgisel özellik, büyük çalışan listeleri arasında 
                  gezinmeyi kolaylaştırarak, meslektaşlarınızı hızlı ve verimli bir şekilde bulmanızı sağlar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Sayfa başına görüntülenen çalışan sayısını nasıl ayarlayabilirim?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect, Çalışan Dizini'nde sayfa başına görüntülediğiniz çalışan profil sayısını özelleştirmenize olanak tanır, bu da dizinden daha kolay 
                  gezinmenizi sağlar. Her sayfanın altında, 10, 50, 100, 500, 1000 ve 5000 olmak üzere sayfalama seçenekleri bulunur. Varsayılan ayar, sayfa başına 
                  10 profil gösterir. Tek bir sayfada daha fazla profil görüntülemek için bu seçeneklerden tercih ettiğiniz numarayı seçin. Bu özellik, dizini 
                  dolaşma şeklinizi belirli ihtiyaçlarınıza veya tercihlerinize uygun olarak özelleştirmenizi sağlar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Liste Görünümünde çalışan bilgilerini nasıl sıralayabilirim?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect'in Çalışan Dizini Liste Görünümünde, çalışan bilgilerini ihtiyaçlarınıza uygun olarak sıralama esnekliğine sahipsiniz. Sütunlar—Çalışan Adı, 
                  İş Unvanı, Departman, Ofis Yeri ve İş Yeri—tümü sıralanabilir. Sıralamak istediğiniz sütun başlığına tıklayın. Bir kez tıklamak, sütunu artan sırada 
                  sıralar, ve tekrar tıklamak azalan sırada sıralar. Varsayılan olarak, dizin, adının ilk adına göre artan sırada Çalışan Adı ile sıralanır. Bu sıralama 
                  özelliği, belirli bir kritere göre, örneğin departman veya yer gibi çalışanları gruplamanız gerekip gerekmediğine bakılmaksızın, ister belirli birini 
                  arıyor olun, isterse hızlı bir şekilde çalışan detaylarını düzenlemenize ve görüntülemenize yardımcı olur.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Bölüm Dizini SSS</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Bölüm Dizini Girişi:</Body1Strong> Bölüm Dizini, kuruluşunuzun karmaşık yapısını anlamanız için bir geçit niteliğindedir ve departman hiyerarşileri, yönetimsel liderlik ve sorunsuz departmanal iletişim yolları hakkında net bir görünüm sunar.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Bölüm Dizini'ndeki 'Tümü', 'Favoriler' ve 'Kendi Bölümünüz' etiketleri nasıl çalışır?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect'in Bölüm Dizini'nde, etiketler departman bilgileri arasında hızlıca gezinmenizi ve filtrelemenizi sağlar. İşte her bir etiketin temsil ettiği şeyler:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tümü:</Body1Strong> Bu etiket, organizasyonunuzdaki tüm bölümleri gösterir ve kapsamlı bir genel bakış sağlar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoriler:</Body1Strong> Bu etiketi, sık sık etkileşimde bulunduğunuz bölümleri işaretlemek ve daha kolay erişim sağlamak için kullanabilirsiniz.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kendi Bölümünüz:</Body1Strong> Bu etiket, sadece kendi bölümünüzü göstermek için görünümü filtreler ve böylece hemen kendi departman bilgilerinize ve güncellemelerine hızlı erişim sağlar.
                    </li>
                  </ul>
                  Bu etiketler, Bölüm Dizini içinde navigasyonunuzu kolaylaştırmak için tasarlanmış olup, departman bilgilerini verimli bir şekilde bulmanıza ve onlarla etkileşimde bulunmanıza olanak tanır.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. TeamConnect'te Bölüm Dizini Görünümlerini Anlama
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect'teki Bölüm Dizini'nde gezinme, tercihlerinize uygun birden fazla görünüm seçeneği ile kolaylaştırılmıştır. Her görünüm, Bölüm Adı, Bölüm Yöneticisi, Ana Bölüm, Bölüm Telefonu, Bölüm Acil Durum Hattı ve Bölüm E-maili dahil olmak üzere kapsamlı departman bilgilerini keşfetme fırsatı sunar. İşte mevcut görünümlerden bir özet:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Liste Görünümü:</Body1Strong> Varsayılan ayar, tüm bölümleri düzenli bir şekilde listeler ve net, öz bir genel bakış sağlar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Geniş Kart Görünümü:</Body1Strong> Detaylı bölüm kartlarını görmek için bu görünüme geçin, her bölümün anahtar bilgilerinin genişletilmiş özetini sunar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org Şema Görünümü:</Body1Strong> Bölüm yapısını ve ilişkilerini görsel olarak temsil etmek için, Org Şema görünümü her bölümün büyük organizasyonel hiyerarşi içinde nasıl yer aldığını gösterir.
                    </li>
                  </ul>
                  Bu görünümler arasında geçiş yapmak için, Bölüm Dizini'nin üst kısmındaki arama kutusu yanındaki simgeleri kullanabilirsiniz. Her bir görünüm, departman detaylarına erişimi ve anlaşılırlığını sağlamak için tasarlanmıştır, böylece ihtiyacınız olan bilgiyi size en uygun formatta bulabilirsiniz.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Bölüm Dizini'nde Arama Kutusunu Nasıl Kullanırız
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect içindeki herhangi bir bölümü, üst kısımdaki görüntüleme ikonlarının yanında bulunan özel arama kutusu kullanılarak rahatça bulun. 
                  Aradığınız bölüm adını yazmaya başlayın ve dizinin sadece ilgili sonuçları gösterecek şekilde kendini nasıl yenilediğini izleyin. Bu özellik, 
                  tüm dizini manuel olarak inceleme ihtiyacını ortadan kaldırmak ve ihtiyacınız olan bölümü doğrudan parmak uçlarınıza getirmek için özel olarak 
                  tasarlanmıştır. Unutmayın, bu arama işlevselliği yalnızca TeamConnect'in Bölüm Dizini'ne özgüdür, bu yüzden bölüme özgü sorgular için Microsoft 
                  Teams'in en üstündeki ana arama yerine bu arama kutusunu kullanmaya özen gösterin.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Bölüm Dizini'nde alfabetik filtre nasıl çalışır?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Bölüm Dizini'nde aramanızı üst kısımda bulunan alfabetik filtre ile hızlandırın. Bu özellik, bir harf seçerek bölümleri hızla daraltmanıza olanak 
                  tanır. Bir harf seçtiğinizde, TeamConnect sadece seçtiğiniz harfle başlayan bölümleri filtreler ve gösterir, bu da aradığınızı tüm listeyi 
                  dolaşmadan bulmanızı kolaylaştırır. Tam bölüm görünümüne geri dönmek için seçili harfi kaldırın. Bu sezgisel alfabetik gezinme, herhangi bir bölümü 
                  baş harfine göre verimli bir şekilde bulmanızı sağlar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Bölüm yöneticisi ile nasıl iletişim kurabilirim?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect'te bir bölüm yöneticisi ile iletişim kurmak doğrudandır. İlgilendiğiniz bölümü bulun ve yöneticinin adına tıklayın. Bu 
                  işlem, yöneticinin iletişim seçeneklerini gösteren bir açılır pencereyi tetikler:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sohbet İkonu:</Body1Strong> Microsoft Teams sohbet üzerinden anında mesaj başlatmak için.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>E-posta İkonu:</Body1Strong> Microsoft Outlook'ta onlara yeni bir e-posta açmak için.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Video Arama İkonu:</Body1Strong> Microsoft Teams üzerinden bir video araması başlatmak için.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Arama İkonu:</Body1Strong> Microsoft Teams kullanarak bir sesli arama başlatmak için.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Daha Fazla (...) İkonu:</Body1Strong> Toplantı planlama veya kişi bilgilerini Teams listesine eklemek gibi ek 
                      seçenekler için.
                    </li>
                  </ul>
                  Bu ikonlar, TeamConnect içinden doğrudan bölüm yöneticilerine ulaşma yolu sağlayarak organizasyonunuz içinde etkin iletişimi garanti eder.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Bir departmandaki çalışanları nasıl görüntüleyebilir veya onlarla iletişime geçebilirim?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect'teki bir departmanda kimlerin olduğunu keşfetmek için departman adının yanındaki üç noktaya daha fazla bakmanıza gerek yok. Buna tıklandığında, daha fazla işlem için seçenekler belirecektir:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Bu Departmandaki Çalışanları Görüntüle:</Body1Strong> Bunu seçerek departmandaki tüm 
                      çalışanların listesini görüntüleyin. Bu seçeneğin yanındaki sayı, departmandaki çalışan sayısını gösterir ve hızlı bir şekilde sayım yapmanızı sağlar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Departman Organizasyon Şeması:</Body1Strong> Eğer departmanın hiyerarşisini anlamak istiyorsanız, 
                      'Departman organizasyon şeması'nı seçin ve yapısal düzeni ortaya çıkartın, ana departmanlar, ilgili departman ve alt departmanlar arasındaki ilişkileri gösterir. Bu görsel temsil, organizasyonel çerçeveyi anlamanıza ve belirli departman üyeleriyle iletişim kurmanızı kolaylaştırır.
                    </li>
                    <li><Body1Strong>Departman {'>'} Çalışan:</Body1Strong> Bir departmandaki çalışanları görüntüledikten sonra, departman listesine geri dönmek için bu ekmek kırıntısı yolunu kullanarak, TeamConnect içinde sorunsuz bir keşif deneyimi sürdürün.
                    </li>
                  </ul>
                  Bu özellikler, belirli bir departmandaki çalışan listesini görmekle kalmayıp aynı zamanda TeamConnect'in iletişim araçları aracılığıyla doğrudan onlara ulaşma yolunu da sunar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. TeamConnect'te Derinlemesine Departman Detaylarını Keşfetme
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect'te herhangi bir departmanın ayrıntılarına basitçe adına tıklayarak ulaşın. Detaylı bir diyalog açılır, departmanın organizasyon içindeki rolünü ve kaynaklarını tam anlamıyla ortaya koyan zengin bilgi dokusunu sunar. Liderlikten mali tanımlayıcılara, iletişim kanallarından sosyal bağlantılara kadar her şeyi bulacaksınız. Bu özellik, organizasyonunuzda benzersiz ekosistemlerle bağlantı kurmanıza ve bunları anlamanıza yardımcı olan araçları sağlar:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Departmanın kalbi – amacı, insanları ve performans metrikleri.</li>
                    <li className={styles.bulletBottomMargin}>İletişim yolları – anahtar departman figürlerine ve iletişim platformlarına doğrudan hatlar.</li>
                    <li className={styles.bulletBottomMargin}>Organizasyonel ağ – departmanın geniş şirket yapısı ile nasıl bütünleştiğini anlama.</li>
                  </ul>
                  İster hızlı bir sorgulama olsun isterse departman verilerine derinlemesine bir dalış, TeamConnect sizleri donanımlarla tanıştırır.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. TeamConnect'te Departman Adreslerinin Olmaması Hakkında Meraklı mısınız?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Bir departmanın yerini keşfetmek bazen haritasız bir hazine avı gibi hissettirebilir. TeamConnect'te, genellikle her departman için ayrılmış bir adres bulamazsınız. İşte bu kartografik detayın genellikle atlanma sebepleri:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Çoklu Konumlar:</Body1Strong> Birçok departman tek bir alana sınırlı değildir; çeşitli ofislerde ve coğrafyalarda faaliyet gösterirler.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Akıcı Yapı:</Body1Strong> Organizasyonel manzara sürekli değişir, departmanlar stratejik değişikliklerle uyum sağlayarak gelişir, birleşir veya hatta dağılır.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Bölümler arası İşbirliği:</Body1Strong> Modern çalışma alanları çeşitli departmanlardan yeteneklerin paylaşıldığı ortak alanlarla işbirliği ruhuyla çınlıyor.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Uzaktan Çalışma Kültürü:</Body1Strong> Uzaktan çalışma esnekliği ile departmanların fiziki ofis yerlerine demir atması her zaman gerekli olmaz.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Hukuki Nüanslar:</Body1Strong> Departmanlar genellikle kendi başlarına ayrı adreslere sahip olmaktansa şirketin hukuki yapısı etrafında şekillenir.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Operasyonel Akıcılık:</Body1Strong> Dinamik takım çalışmasına odaklanmak, statik adreslere değil, operasyonel çevikliği korumaya yardımcı olur.
                    </li>
                  </ul>
                  Tamamıyla sınırsız bir işbirliği ve verimlilik anlayışını benimsemekle ilgili. TeamConnect bu felsefeyi, yerler üzerinden bağlantılar üzerine vurgu yaparak yansıtır.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Bölüm bilgileriniz eksik veya güncel değil mi?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Bölüm editörleri, organizasyonunuzda TeamConnect'te bölümle ilgili bilgileri eklemek, güncellemek veya düzeltmek için belirlenmiştir.
                  Bölüm editörünüzün kim olduğundan emin değilseniz veya belirli bilgilerin İK destek ekibi müdahalesini gerektirip gerektirmediğinden emin değilseniz,
                  rehberlik için lütfen <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> ile iletişime geçin.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Ofis Rehberi SSS</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Ofis Rehberi Giriş:</Body1Strong> Ofis Rehberi aracılığıyla, örgütünüzün fiziksel varlığının temelini keşfedin,
                önemli ofis detaylarını, operasyonel özellikleri ve her konumu tanımlayan bölümleri ve personeli kapsamlı bir şekilde inceleyin.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Ofis Rehberinde Etiketlere Göz Atma
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Etiket sistemimizi kullanarak Ofis Rehberi'nde kolayca yolunuzu bulun. İşte her bir etiketin aramanızı nasıl verimli hale getirebileceği:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Hepsi:</Body1Strong> Ofislerin tam bir listesini gösterir, örgütünüzün konumlarının kuşbakışı bir görünümünü sunar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoriler:</Body1Strong> Sık ziyaret ettiğiniz veya yakın takip etmeniz gereken ofis konumlarını 'Favoriler' olarak işaretleyerek, bu yerlere odaklanmanıza yardımcı olur.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kendi Ofisiniz:</Body1Strong> Bu kişisel etiket, rehberi filtreleyerek sizi istasyonunuza götürür, ana merkezinizi parmaklarınızın ucunda tutar.
                    </li>
                  </ul>
                  Toplantı odası rezerve etmek veya bir ziyaret planlamak olsun, bu etiketler örgütünüzün coğrafi manzarasıyla kişiselleştirilmiş ve basitleştirilmiş bir şekilde etkileşim kurmanızı sağlar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>           
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. TeamConnect'te Ofis Dizin Görünümlerini İyileştirmek
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Şirketinizin çeşitli konumlarının organizasyonel yapıya TeamConnect'in Ofis Dizin görünümleri ile dalın. Her bir görünüm, ofis navigasyonu ve anlamasını kolaylaştırmak için özenle oluşturulmuştur:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Liste Görünümü:</Body1Strong> Tüm ofis konumlarını kolayca okunabilir bir listeye düzenli bir şekilde organize eden varsayılan ayar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Büyük Kart Görünümü:</Body1Strong> Ofis Dizini'ndeki Büyük Kart Görünümü, her ofisi detaylı bir şekilde sunarak görünümünüzü zenginleştirir. Bir ofis kartı ile etkileşime geçtiğinizde "Daha Fazla (...)" menüsü içinde bol miktarda bilgi ve eyleme yönelik seçenekler bulursunuz:
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Departmanları Görüntüle:</Body1Strong> Ofis içinde bulunan tüm departmanların listesine doğrudan geçiş yapın.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Çalışanları Görüntüle:</Body1Strong> Ofise atanmış tüm çalışanların tam listesine erişim sağlayarak ofis personeli hakkında doğrudan iletişime geçme veya keşif yapma imkanı sunar.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Kurumsal Varlık Yapı Şeması:</Body1Strong> Ofisin daha büyük organizasyonel bağlamdaki yerini görselleştirerek hiyerarşik yapıları net bir şekilde anlamanızı sağlar.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Temel Kişiler:</Body1Strong> İletişimi hızlandırmak için önemli ofis kontaklarını belirleyin.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Ofis Resmi Tatilleri:</Body1Strong> Programlarınızı buna göre planlamak için ofise özgü resmi tatiller hakkında bilgi sahibi olun.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Ofis Çalışma Saatleri:</Body1Strong> Ziyaretlerinizi veya aramalarınızı aktif oldukları zamanlara uygun şekilde ayarlamak için ofisin işletme saatlerini görüntüleyin.
                        </li>
                      </ol>
                      Bu görünüm, sadece görsel ve bilgilendirici bir anlık görüntü sağlamakla kalmaz, aynı zamanda ofisle ilgili daha derinlemesine anlayışlar ve doğrudan eylemlere hızlı erişim sunar, hepsi "Daha Fazla (...)" seçeneği altında sizin için kolaylıkla yer alır.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organizasyon Şeması Görünümü:</Body1Strong> Şirketinizdeki farklı ofis konumlarının birbiriyle bağlantılılığını ve ana ve alt ofislerin hiyerarşik yapısını görselleştirin.
                    </li>
                  </ul>
                  İhtiyaçlarınıza uygun olarak gezinme deneyiminizi özelleştirmek için arama kutusunun yanındaki simgeler aracılığıyla bu görünümler arasında geçiş yapın.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. TeamConnect'in Arama Özelliği ile Ofisleri Hızlıca Bulma
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect içinde doğru ofis konumunu bulmayı kolaylaştıran adanmış arama özelliği ile tanışın. Görünüm simgelerinin yanında önemli bir yerde konumlandırılmış arama kutusu, adlarına göre ofisleri hızla belirlemeniz için aracınızdır. Yazdıkça, dizin yalnızca girişinize uyan ofisleri göstererek arama sürecinizi akıcı hale getirir. En doğru ve ilgili sonuçlar için bu arama kutusunu TeamConnect içinde kullanmanız önemlidir, genel Microsoft Teams aramasının aksine. Bu hedeflenmiş arama işlevselliği ile organizasyonunuzun ağındaki herhangi bir ofise hızlıca yönlenebilirsiniz.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Ofis Dizinine Alfabetik Olarak Göz Atmak
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Alfabetik olarak aramayı tercih ediyorsanız, alfabetik filtremiz adlarına göre ofis bulmanın hızlı bir yoludur. Ofis Dizini'nin üst kısmında, A'dan Z'ye kadar harfleri göreceksiniz. Bir harfe tıklamak, görünümü sadece o harfle başlayan ofisleri gösterecek şekilde filtreler ve arama sürecinizi basitleştirir. Tüm ofisleri tekrar görmek için aktif harfi kaldırmanız yeterlidir. Bu özellik, ofis adını bildiğiniz ancak TeamConnect içinde hızlıca bulmanız gerektiğinde özellikle yararlıdır.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. TeamConnect'te Kapsamlı Ofis Profillerini Keşfetme
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Organizasyonunuzun fiziksel varlığının kalbinde bir keşif yolculuğuna çıkın ile TeamConnect'in ofis profilleri. Bir ofis adına tıklamak, detaylı ofis içgörüleri ile dolu sekmeler sunan canlı bir profil diyalog penceresi açar:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Genel Bakış:</Body1Strong> İş yapısından açılış saatlerine ve yerel saate kadar detaylı tanımlayıcılar ile ofisin kimliğinin özüne dalın.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Temel İletişim Bilgileri:</Body1Strong> Acil servisler ve dış destek için hayati öneme sahip kişilere ulaşın, her biri Microsoft Teams Telefon Sistemi aracılığıyla bir tık uzağınızda.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ofis İletişim Bilgileri:</Body1Strong> İç destek hizmetlerine kolayca ulaşın, iletişim detayları ve doğrudan etkileşim seçenekleri parmaklarınızın ucunda.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Resmi Tatiller:</Body1Strong> Ofise özgü tatillerden haberdar olun, planlarınızın yerel gözlemler ve çalışma programları ile uyumlu olduğundan emin olun.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>İş Saatleri:</Body1Strong> Ofisin etkinlikle dolup taştığı veya günün sona erdiği zamanı kesin olarak bilerek etkileşimlerinizi planlayın.
                    </li>
                  </ul>
                  İster Austin'in hareketli enerjisi olsun ister Singapur'un stratejik merkezi, her sekme şirketinizin global varlığını oluşturan çeşitli lokaliteleri anlamak ve onlarla bağlantı kurmak için bir kapı sunar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Ofis İçinde Departman Dinamiklerine Dalış
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Bir ofisin departmanlarının profesyonel ağında gezinmek TeamConnect ile kolaylıkla gerçekleşir. Detaylara nasıl dalınır işte böyle:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Bu Ofisteki Departmanları Görüntüle:</Body1Strong> Daha (...) menüsünden bu seçeneği seçerek 
                      ofisi oluşturan tüm departmanları keşfedin.
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organizasyonel İçgörü:</Body1Strong> Herhangi bir departmana tıklayarak, organizasyonel şemasını 
                      açığa çıkarın, görsel bir yapı temsili ile; kılavuz sizi yönlendirir: Ofis {'>'} Departman {'>'} Şema.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Departmanlara Derin Dalış:</Body1Strong> Departman adının yanındaki Daha (...) menüsüne 
                      tıklayarak bir departmanı daha ayrıntılı keşfedin. Burada, ofis bağlamında o departmandaki tüm çalışanların bir listesine erişebilirsiniz.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ekmek Kırıntısı Navigasyonu:</Body1Strong> Seviyeler arası geçişi sorunsuzca gerçekleştirin. 
                      Çalışanlardan departmanlara veya org şemasına kadar, ekmek kırıntısı izi (Ofis {'>'} Departman {'>'} Çalışan {'>'} Şema) yolculuğunuzu sezgisel ve 
                      doğru tutar.
                    </li>
                  </ul>
                  TeamConnect'in karmaşık yolları, ofisinizin departmanlarının her katmanını keşfetmenizi, anlamanızı ve iletişim kurmanızı sağlayarak, size 
                  kapsamlı bir navigasyon yetkisi verir.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. TeamConnect'te Ofis Personeliyle Bağlantı Kurma
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Bir ofisteki yüzler hakkında meraklı mısınız? Cevap TeamConnect'te bir tıklama uzakta. Seçtiğiniz ofis adının yanındaki üç nokta (ellipsis) simgesine tıklayarak 
                  etkileşimli seçeneklerin bir paketini ortaya çıkarın:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Bu Ofisteki Çalışanları Görüntüle:</Body1Strong> Bu ofisi iş yeri olarak belirten 
                      tüm profesyonellerin bir listesini ortaya çıkarın. Görüntülenen sayı, çalışanların hızlı bir sayımını sunar, ofis topluluğuna dalmanızı 
                      davet eder.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>İletişim Seçenekleri:</Body1Strong> Her çalışan girişi, TeamConnect üzerinden 
                      sohbet başlatmanıza, arama yapmanıza veya hatta video konferanslar düzenlemenize olanak tanıyan hızlı eylem simgeleri ile donatılmıştır, 
                      ofis içi iletişimi sorunsuz hale getirir.
                    </li>
                    <li><Body1Strong>Navigasyon:</Body1Strong> Çalışan listesinden geniş ofis listesine dönmek için, sadece ekmek kırıntısı izinde 'Ofis' 
                      üzerine tıklayın (Ofis {'>'} Çalışan), ve ofis genel görünümüne geri döneceksiniz.
                    </li>
                  </ul>
                  Bu özelliklerle TeamConnect, ofisler arası iş gücü dağılımını görselleştirmeyi ve çaba harcamadan ulaşmayı basit hale getirir, 
                  mesafeye bakılmaksızın bağlantılı bir işyeri oluşturur.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Ofisinizin bilgileri eksik veya güncel değil mi?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect'te ofis bilgileri güncellemeleri, belirlenen ofis editörleri tarafından yönetilebilir. Ofis editörleri konusunda emin değilseniz veya 
                  İK'nın sorumluluk alanına giren bilgilerle ilgili yardıma ihtiyacınız varsa, İK destek ekibi <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> adresinde
                  yardıma hazırdır.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Ayarlar SSS</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. TeamConnect'te Doğru Genel Ayarların Önemi
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Günümüzün dinamik iş ortamında, bağlantılı ve koordineli kalmak her zamankinden daha hayati. İşte TeamConnect'teki Genel Ayarlarınızı güncellemenin
                  kilit rol oynadığı yer burası:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>İş Yeri Seçimi:</Body1Strong> İş yerinizi güncelleyerek çalışma ortamınızın özünü yakalayın, bu ister Ev-Ofis'inizdeki huzurdan 
                        ana Ofisinizin yoğunluğuna geçiş olsun, isterse bir Kamu-Alanına yerleşmek olsun. Çalışma yerinizdeki bu açıklık, meslektaşlarınıza sadece 
                        sizinle nasıl iletişime geçeceklerini değil, aynı zamanda sizinle hangi tür konuşmaları sürdürebileceklerini de sunar, özellikle açık bir 
                        müşteri ofisinde ya da havaalanı salonunda otururken hassas tartışmaların gizliliğini koruyarak.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Çalışma Yeri Belirtimi:</Body1Strong> Çalışma şehrinizi paylaşmak, uzaktan çalışan bir ekip düzeninde mesafeleri aşmanın ötesinde, 
                        spontan profesyonel toplantılara veya gündelik sosyalleşmelere kapılar açar. İster hızlı bir kahve, ister uzun bir öğle yemeği ya da işbirlikçi 
                        bir toplantı olsun, aynı şehirde olduğunuzu bilmek, sıradan bir günü anlamlı yüz yüze bağlantılar için bir fırsata dönüştürebilir. Bu, sadece 
                        takım bağlarını güçlendirmez, aynı zamanda işinizi etkileyebilecek yerel nüansların daha zengin bir anlayışını da sağlar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zaman Dilimi Doğruluğu:</Body1Strong> Ekipler kıtaları kapsadığında, zaman diliminizin mevcut 
                      yerinizi yansıtması iletişimleri uyumlu hale getirmek için şarttır. TeamConnect profilinizin Yerel Zamanı bu ayara doğrudan bağlıdır, 
                      sizinle iletişim kurmaya çalışan meslektaşlar için herhangi bir tahmin işini ortadan kaldırır.
                    </li>
                  </ul>
                  TeamConnect içindeki 'Ayarlar' {'>'} 'Genel Ayarlar'a giderek bu detayları ince ayarlayın. Ayarlarınızı güncellemek için harcanan birkaç dakika, tüm 
                  tahtada işbirliği çabalarınızın uyumu ve verimliliğini önemli ölçüde artırabilir.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. TeamConnect'te İş Yeri Seçimini Çözme
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  İş Yeri açılır menüsünde doğru ayarı seçmek, TeamConnect'in mevcut çalışma durumunuzu doğru bir şekilde yansıtmasını sağlar. Her seçenek için ayrıntılı açıklama almak için bilgi simgesinin üzerine gelin:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Çıkış/Mola:</Body1Strong> Çalışmadan geçici olarak ayrıldığınız zamanları, ister mola ister iş gününün sonu olsun, belirtir.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ofis/Saha-Ofisi:</Body1Strong> Şirket tarafından yönetilen bir çalışma alanında bulunduğunuzu gösterir.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ev-Ofisi:</Body1Strong> Evdeki özel çalışma alanınızda çalışıyorsunuz.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Uzak-İş Yeri/Kamu Alanı:</Body1Strong> Ofis dışı bir ortamda çalışıyor olmanız, esnek iletişim ihtiyacını öne sürer.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Müşteri İş Yeri/Özel Ofis:</Body1Strong> Müşteriyle yerinde bulunuşunuz, konuşma hassasiyeti için mahremiyet seviyelerine dikkat çeker.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Yolculuk/İş Seyahati/Eğitim:</Body1Strong> Yoldayken veya ofis dışında belirli işle ilgili aktivitelerle meşgulken.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Resmi Tatil/Tatil/Çeşitli İzinler:</Body1Strong> Kişisel, sağlık veya tatil sebepleriyle resmi olarak görevden uzakta olduğunuzu belirtir.</li>
                  </ul>
                  Uygun bir iş yeri ayarı seçmek, ekip etkileşimleri için gerekli bağlamı sağlayarak, erişilebilirlik ve mahremiyete saygı gösterilmesini sağlar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. TeamConnect'te 'Giriş Yaptı' Durumunu Yönlendirme
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Çalışan Dizini'nde 'Giriş Yaptı' etiketi, şu andaki İş Yeri seçiminize bağlı olarak işte olan tüm çalışanları göstermek için tasarlanmıştır:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Aktif Çalışma Ortamları:</Body1Strong> Ofis, Sahra-Ofisi, Ev-Ofisi, Uzak-İş Yeri, Kamu Alanı, Müşteri-İş Yeri, Müşteri Özel-Ofisi, Yolculuk, İş Seyahati ve Eğitim seçenekleri sizi işle meşgul olduğunuzu ve meslektaşlarınıza 'Giriş Yaptı' olarak görünür kılınmanızı belirtir.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Çalışmayan Ayarlar:</Body1Strong> Çıkış, Mola, Resmi Tatil, Tatil, İzin, Doğum İzni ve Hastalık İzni gibi durumları seçmek, sizi 'Giriş Yaptı' listesinden çıkarır ve erişilebilirlik ve yanıt süreleri için net beklentiler belirler.
                  </li>
                  </ul>
                  Bu işlevsellik, çalışma dönemlerini kişisel zamanlardan ayırmaya yardımcı olarak etkili takım işbirliği ve iletişimine katkıda bulunur.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Veri Yönetimi ve Gizlilik SSS</Subtitle2>
          <Body1>
            <p>
              Günümüzün dijital çağında, verilerinizin nasıl yönetildiğini anlamak hayati önem taşımaktadır. TeamConnect, şeffaflık ve sağlam veri koruma uygulamalarına bağlıdır.
              Aşağıda, TeamConnect içindeki verilerinizin nasıl ele alındığına, saklandığına ve korunduğuna dair bilgileri bulacaksınız, böylece dijital çalışma alanınızın güvenli ve uyumlu olmasını sağlamaktayız.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. TeamConnect kişisel verilerimi nasıl korur?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect, veri güvenliğine kapsamlı bir yaklaşımla kişisel bilgilerinizi korumaya adanmıştır:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Tek Giriş (SSO):</Body1Strong> Güvenliği artırmak ve kullanıcı deneyimini kolaylaştırmak için TeamConnect, Microsoft 365 hesabınızla otomatik 
                      Tek Giriş (SSO) entegrasyonu sağlar, güvenli ve zahmetsiz erişim yönetimi sağlar.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Şifreleme:</Body1Strong> TeamConnect'in içindeki tüm verilerin iletim esnasında ve beklemedeyken şifrelendiğini garanti ederiz,
                      yetkisiz kişilere erişilemez hale getirir.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Veri Azaltma:</Body1Strong> Veri toplamamız, TeamConnect'in işlevselliği için zorunlu olanlarla sınırlıdır.
                      Sakladığımız tek kullanıcı bilgisi, Azure Entra ID'den aldığımız Kullanıcı Temel Adınız (UPN) olup, bu sizin şirket e-posta adresinize benzeyebilir.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Sınırlı Erişim:</Body1Strong> UPN'ye erişim, yalnızca organizasyonunuzun Abonelik portalındaki Abonelik Yöneticisi tarafından sınırlıdır,
                      hassas bilgilerin sadece yetkili personel tarafından görülebilir olduğunu garanti eder.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Hızlı Veri Silme:</Body1Strong> TeamConnect, saklanan UPN'yi üç özel durumda anında silmek üzere yapılandırılmıştır:
                      abonelik Abonelik Yöneticisi tarafından iptal edildiğinde, bir kullanıcı TeamConnect içinde İK tarafından kaldırıldığında veya TeamConnect
                      aboneliği sona erdiğinde. Bu, kişisel verilerinizin kullanışlı ömrünü veya gerekliliğini aşmadan saklanmadığını garanti eder.
                    </li>                      
                  </ul>
                  <p>Bu önlemlerle TeamConnect, kişisel bilgilerinizin güvenli ve gizli kalmasını sağlayarak veri koruma standartlarının en yüksek düzeyde tutulmasını taahhüt eder.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Verilerinizin TeamConnect ile nerede saklandığını merak mı ediyorsunuz?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect, verilerinizin organizasyonunuzdan ayrılmamasını sağlayarak geleneksel SaaS tekliflerinin kalıplarını kırar. 
                  Verileri dış bulut sunucularında saklama alışkanlığının aksine, TeamConnect uygulama verilerinin tamamını saklamak için organizasyonunuzun 
                  Microsoft 365 SharePoint Online'ını ustaca kullanır. Bu yenilikçi yaklaşım, sadece veri egemenliğini savunmakla kalmaz, aynı zamanda güvenliği güçlendirir,
                  gizliliğinizi koruma ve katı uyum standartlarına bağlı kalma konusundaki sarsılmaz bağlılığımızla mükemmel bir şekilde uyum sağlar.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Verilerim üçüncü taraflarla paylaşılacak mı?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hayır, veriler üçüncü taraflarla paylaşılmaz! Cubeet olarak verilerinizin gizliliği ve güvenliği konusundaki taahhüdümüze kesinlikle sadığız. Müşterilerimizin açık rızası olmadan 
                  TeamConnect verilerini üçüncü taraflarla paylaşmayız. Güvenli ortamımızda, Hizmetimizi sağlamak için kritik önem taşıyan Kullanıcı Temel adı (UPN), 
                  organizasyon adı, kiracı adı ve kiracı kimliği gibi sadece temel tanımlayıcılar saklanır. Bu bilgiler, katı gizlilik politikalarımız altında korunmaktadır.
                  Şüpheniz olmasın, veri yönetimi uygulamalarımız, verilerinizin gizli kalmasını sağlamak, şeffaflık ve kontrolü tamamen sizin elinizde tutmak için tasarlanmıştır.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. TeamConnect, GDPR gibi veri koruma düzenlemelerine nasıl uyum sağlar?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect, GDPR, HIPAA, CCPA ve diğerleri gibi veri koruma düzenlemelerine, tüm müşteri verilerinin güvenli bir şekilde tutulduğu Microsoft 365 ortamının doğal uyumluluk özelliklerinden faydalanarak uyum sağlar. 
                  Geleneksel SaaS modellerinin aksine, TeamConnect herhangi bir müşteri verisini sunucularında saklamaz; bunun yerine, güçlü güvenlik ve uyum standartlarına sahip 
                  organizasyonunuzun Microsoft 365 ortamı içinde faaliyet gösterir. Bu yaklaşım, veri yönetiminizin Microsoft 365 tarafından sağlanan kapsamlı uyum çerçevesi ile tutarlı kalmasını sağlar,
                  GDPR, sağlık bilgileri için HIPAA, California sakinleri için CCPA ve diğer küresel veri koruma düzenlemelerine uyumu içerir. Mevcut Microsoft 365 kurulumunuzla sorunsuz bir şekilde bütünleşerek,
                  TeamConnect, organizasyonunuzun veriler üzerinde tam kontrolü sürdürmesini sağlar, en sıkı veri koruma yasalarına ve düzenlemelerine uyumu garanti eder.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. TeamConnect, Microsoft Teams sohbetleri, aramaları, video aramaları, Outlook e-postaları ve telefon sistem aramalarından gelen verileri nasıl işler?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect, Microsoft Teams içinde sohbet, arama, video arama ve Outlook e-postası gibi doğrudan etkileşimleri mümkün kılan Microsoft Graph API'den yararlanır,
                  sorunsuz bir kullanıcı deneyimi sağlar. Önemli olarak, TeamConnect bu iletişimleri başlatırken, iletişim sürecine müdahale etmeden veya herhangi bir etkileşim içeriğini kaydetme veya saklama yeteneğine sahip olmadan bunu gerçekleştirir. Tüm sohbetler, aramalar ve e-postalar doğrudan Microsoft Teams ve Outlook'un güvenli sınırları içinde, Microsoft'un 365 güvenlik ve gizlilik standartlarına sıkı sıkıya bağlı kalarak gerçekleşir ve yönetilir. Bu, TeamConnect'in iletişim yeteneklerini artırırken verilerinizin gizliliğini ve bütünlüğünü tam olarak koruduğundan emin olur.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Veri politikalarımız ve uygulamalarımız hakkında ayrıntılı bilgi almak için ayrıca {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>TeamConnect Kullanıcı Kılavuzu</Body1Strong></Link>nu veya kurulumunuzla ilgili özel bilgiler için 
            organizasyonunuzun IT departmanı <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> ile görüşmek isteyebilirsiniz.
          </p>
        </Body1>        
        <p></p>  
      <Subtitle1 className={styles.fontColor}>Dinliyoruz: Geri Bildiriminizi ve Fikirlerinizi Paylaşın</Subtitle1>
        <Body1>
          <p>
            Görüşleriniz TeamConnect'in evrimini yönlendirir. Parlak bir özellik fikriyle coşkulu bir şekilde veya mevcut deneyiminizle ilgili bir geri bildirimle ilgili olun, biz her zaman dinlemeye hazırız. Girdileriniz, mümkün olan en iyi hizmeti sağlama sürekli arayışımızda paha biçilmezdir.
          </p>
        </Body1>      
      <Subtitle2>Geri Bildirim ve Özellik Önerileri</Subtitle2>
        <Body1>
          <p>
            Paylaşmaya hazır mısınız? Bizimle {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Geri Bildirim Sayfasına
            </Link>
            gidin. Yeni bir özellik önerisi veya mevcut işlevlerle ilgili bir geri bildirim olsun, formda uygun seçeneği seçin. Katkılarınız, TeamConnect'in ihtiyaçlarınızı karşılamasını ve aşmasını sağlamak için geleceğini şekillendirir.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;