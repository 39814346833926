import PropTypes from "prop-types";
import {
  makeStyles,
  tokens,
  Link,
  Body1,
  Body1Strong,
  Subtitle2,
  Subtitle1,
  Title3,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,
  },
  bulletBottomMargin: {
    marginBottom: "8px",
  },
  scrollToTopButton: {
    position: "fixed",
    right: "5%",
    transform: "translateX(95%)",
    bottom: "8px",
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>
        TeamConnect Yardım Mərkəzi & Tez-tez Verilən Suallar
      </Title3>
      <p>
        <Body1>
          TeamConnect Yardım Mərkəzinə xoş gəldiniz, korporativ kataloq
          tətbiqimizlə təcrübənizi dəstəkləmək üçün nəzərdə tutulmuşdur. Burada,
          TeamConnect-i Microsoft Teams içərisində istifadənizi optimallaşdırmaq
          və şəbəkəniz boyu ünsiyyəti gücləndirmək üçün resurslar tapa
          bilərsiniz.
        </Body1>
      </p>
      <Subtitle2>Tez İstinad Rəhbəri</Subtitle2>
      <ul>
        <Body1>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Gücləndirilmiş Axtarış İmkanları: </Body1Strong>
            Güclü axtarış alətlərimizlə sürətli bir şəkildə doğru həmkarları,
            şöbələri və əsas əlaqələri tapın.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Vişual Orqanizm Şemaları: </Body1Strong>
            Təşkilatınızın iyerarxiyasını naviqasiya edin və intuasif org
            şemaları vasitəsilə hesabat xətlərini asanlıqla başa düşün.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Bu Günün Tələblərinə Uyğun Alətlər: </Body1Strong>
            Müasir iş yerlərinin tələblərinə uyğun düzəldilmiş müasir
            funksionallıqdan istifadə edin.
          </li>
        </Body1>
      </ul>
      <p></p>
      <Subtitle2>Təcrübənizi Optimallaşdırmaq</Subtitle2>
      <Body1>
        <p>
          TeamConnect təşkilati strukturunuzla ünsiyyətinizdə inqilabi
          dəyişikliklər edir, gündəlik əlaqələri strategik qarşılıqlarla
          çevirir.
        </p>
      </Body1>
      <p></p>
      <Subtitle1 className={styles.fontColor}>
        Kömək & Tez-tez Verilən Suallar
      </Subtitle1>
      <Body1>
        <p>
          Bu həsr olunmuş bölmədə TeamConnect'in xüsusiyyətləri və ayarları
          haqqında tez-tez verilən suallara cavabları tapın. Tətbiqi idarə
          etmək, ayarları fərdiləşdirmək və çətinliklərə həll yolları axtarmaq
          istəyirsinizsə, Yardım Mərkəzimiz sizi səmərəli şəkildə dəstəkləmək
          üçün buradadır.
        </p>
      </Body1>
      <Body1>
        <p>
          Daha dərin dəstək və ya xüsusi məsləhət üçün, zəhmət olmasa{" "}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>
              TeamConnect İstifadəçi Rəhbərinə
            </Body1Strong>
          </Link>{" "}
          müraciət edin.
        </p>
      </Body1>
      <p></p>
      <Subtitle2>TeamConnect-dən istifadə haqqında SUALlara CAVAB</Subtitle2>
      <p></p>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="1">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                1. Microsoft Teams sidebarında TeamConnect tapa bilmirsinizmi?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Əgər TeamConnect Microsoft Teams sidebarında aydın görünmürsə və
                ya itmişsə, onu asanlıqla sıxlamaq üçün tez-tez istifadə edilə
                bilər. Sadəcə bu sadə addımları izləyin:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Microsoft Teams’i açın və sidebar'ın altında '...' (+ Apps)
                    simgesini vurun.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Pop-up pəncərənin yuxarısındakı axtarış çubuğunda
                    'TeamConnect' axtarın.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Axtarış nəticələrindən TeamConnect tətbiqini sağ klikləyin
                    və 'Pin' seçin.
                  </li>
                </ol>
                İndi TeamConnect sidebarınıza sıxılmış olacaq və yalnız bir
                kliklə əlçatan olacaq!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="2">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                2. TeamConnect ilə texniki problem yaşayırsınız? Kömək almaq
                üçün belə edin.
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Əgər Microsoft Teams daxilində TeamConnect ilə hər hansı bir
                texniki problem yaşayırsınızsa, təşkilatınızın IT Dəstək
                komandası kömək etməyə hazırdır. Onlara{" "}
                <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>{" "}
                ünvanında müraciət edin və onlar tezliklə sizə kömək edəcəklər.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="3">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                3. İşçi məlumatlarınızı görmürsünüzmü?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Əgər profiliniz adınızla və e-mailinizlə bir az boş görünürsə,
                bu, HR komandanızın hələ TeamConnect-ə digər işçi detallarını
                əlavə etməmiş olmasından kaynaklanır. Narahat olmayın! HR dəstək
                komandanıza qısa bir mesaj hər şeyi hərəkətə keçirəcəkdir.
                Onlar, profilinizi bütün önəmli detallarla doldurmağa
                hazırdırlar.
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>{" "}
                ünvanında müraciət edin və profilinizin canlandığını izləyin!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="4">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                4. TeamConnect-də hansı dil seçimləri mövcuddur?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect, Microsoft Teams-in bütün dillərini dəstəkləyərək
                qlobal müxtəlifliyi qəbul edir. İstər Ərəb, istərsə də Yunan,
                Yapon və ya İsveç dilini üstün tutsanız, barmaqlarınızın ucunda
                dünya miqyasında variantlarınız var, o cümlədən:
              </Body1>
              <ul>
                <li>
                  <Body1>
                    Ərəb, Bolqar, Çin (Sadələşdirilmiş və Ənənəvi), Xorvat, Çex,
                    Danimarka, Holland, İngilis, Eston, Finlandiya, Fransız,
                    Alman, Yunan, İbrani, Macar, İndoneziya, İtalyan, Yapon,
                    Koreya, Latviya, Litva, Norveç (Bokmal), Polşa, Portuqal
                    (Braziliya və Portuqaliya), Rumıniya, Rus, Serb (Latin),
                    Slovakiya, Sloveniya, İspan, İsveç, Tay, Türk, Ukrayna,
                    Vyetnam.
                  </Body1>
                </li>
              </ul>
              <Body1>
                TeamConnect təcrübənizi üstün tutduğunuz dillərə uyğunlaşdırmaq
                üçün, lütfən dil ayarlarını birbaşa Microsoft Teams-də
                tənzimləyin, çünki TeamConnect bu ayarlarla tam inteqrasiya
                olunur:
              </Body1>
              <ol>
                <Body1>
                  <li className={styles.bulletBottomMargin}>
                    Profil şəklinizin yanındakı üç nöqtəyə basaraq Microsoft
                    Teams ayarlarınıza keçin və sonra 'Görünüş və əlçatanlıq'
                    seçin.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    'Dil' bölməsinə keçin və açılan menüdən üstün tutduğunuz
                    dili seçin.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Yeni dil ayarlarını tətbiq etmək üçün 'Yadda saxla və
                    yenidən başlat' düyməsini basaraq dəyişikliklərinizi
                    təsdiqləyin.
                  </li>
                </Body1>
              </ol>
              <Body1Strong>Qeyd: </Body1Strong>
              <Body1>
                Microsoft Teams-də dil ayarlarınızda edilən dəyişikliklər
                həmçinin TeamConnect təcrübənizi problem­siz yeniləyəcək.
                Teams-də dili dəyişmək həmçinin sizin zaman və tarix formatınızı
                tənzimləyə bilər. Hal-hazırda, TeamConnect sağdan-sola yazı üçün
                inkişaf etdirilir. Bu yeniliklər haqqında həyəcanlanırıq və
                mövcud oldular qədər sizi məlumatlandırmağa davam edəcəyik.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="5">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                5. TeamConnect-də mövzunu necə dəyişmək olar?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect, sizin Microsoft Teams mövzu seçimlərinizlə
                sinxronlaşır, buna Yüngül, Qaranlıq və Yüksək Kontrast seçimləri
                daxildir. Mövzunuzu fərdiləşdirmək üçün:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Profil şəklinizin yanındakı üç nöqtəyə klikləyərək Microsoft
                    Teams tənzimləmələrinə daxil olun.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    'Görünüş və əlçatanlıq' seçiminə klikləyərək mövzu
                    seçimlərini araşdırın.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Mövcud seçimlərdən üstünlük verdiyiniz mövzunu seçin ki,
                    dərhal TeamConnect interfeysinizi uyğunlaşdırsın.
                  </li>
                </ol>
                Microsoft Teams-dəki mövzu seçiminiz avtomatik olaraq
                TeamConnect-ə tətbiq ediləcək, iş məkanınızda vizual təcrübənin
                uyğunluğunu təmin edəcək.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="6">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                6. TeamConnect’de Kontakt Əməliyyat Düymələrini Araşdırmaq
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect boyu, bir işçinin kontakt məlumatlarını tapdığınız
                hər yerdə, həmçinin bizim rahat tez-erişim düymələrimizi də kəşf
                edəcəksiniz. Bu faydalı vasitələr sizin Microsoft ekosistemi
                daxilində kommunikasiyanı asanlaşdırmaq üçün nəzərdə
                tutulmuşdur, həmişə bir kliklə əlaqə qurmaq imkanı təmin edir.
                Bu düymələrdən effektiv istifadə etməyə dair ətraflı bələdçiniz:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Chat İkonu:</Body1Strong> Dərhal Microsoft
                    Teams chatinə başlamaq üçün klikləyin. Chatə başladıqdan
                    sonra TeamConnect-ə qayıtmaq üçün masaüstü tətbiqində geri
                    ox, mobil tətbiqdə geri düyməsi və ya brauzerinizin geri
                    düyməsindən istifadə edin.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Email İkonu:</Body1Strong> Alıcının ünvanı
                    "Kimə:" sahəsində hazır olan yeni Outlook e-poçt qaralama
                    pəncərəsini açır. Outlook başlamasa, hər hansı
                    məhdudiyyətlər üçün İT şöbənizlə yoxlamaq faydalı ola bilər.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Video Zəng İkonu:</Body1Strong> Microsoft Teams
                    vasitəsilə üz-üzə video zəng başladır.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Zəng İkonu:</Body1Strong> Microsoft Teams
                    vasitəsilə səsli zəng başlatmaq üçün istifadə olunur, tez
                    müzakirələr üçün asanlaşdırır.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Təşkilat Şeması İkonu:</Body1Strong> İşçinin
                    təşkilati iyerarxiyadakı yerini göstərir, o cümlədən onun
                    meneceri və birbaşa hesabatları.
                  </li>
                  <li>
                    <Body1Strong>Daha Çox (...) İkonu:</Body1Strong> Əlavə
                    seçimləri açır:
                    <ul>
                      <li
                        style={{ marginTop: "8px" }}
                        className={styles.bulletBottomMargin}
                      >
                        <Body1Strong>Dərhal Görüş:</Body1Strong> İşçi ilə dərhal
                        görüş başlatmaq üçün Microsoft Teams-i açır.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Yeni Görüş:</Body1Strong> Yeni görüş
                        planlaşdırmaq üçün sizi Microsoft Teams-ə yönləndirir.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Kontakt Əlavə Et:</Body1Strong> İşçinin
                        kontakt məlumatlarını TeamConnect-dən yeni Microsoft
                        Teams People tətbiqinə əlavə edir, zəng funksiyasını
                        zənginləşdirir.
                      </li>
                    </ul>
                  </li>
                </ul>
                Bu intuitiv düymələr sizin həmkarlarınızla asanlıqla əlaqə
                qurmaq üçün, istər tez bir söhbət, istərsə də bir e-poçt və ya
                gələcək görüşləri planlaşdırmaq üçün hazırlanmışdır.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="7">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                7. TeamConnect vasitəsilə zənglər
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Zəng etmək istəyirsinizmi? TeamConnect, Microsoft Teams ilə
                inteqrasiya olunaraq kommunikasiyanızı asanlaşdırır. Sadəcə
                TeamConnect-də istədiyiniz telefon nömrəsinə klikləyin və ala,
                Microsoft Teams zəng interfeysi açılır, sizin üçün zəng etməyə
                hazır.
                <p>
                  <Body1Strong>Qeyd: </Body1Strong>
                  Zənglərlə bağlı problem yaşayırsınız? Bu, təşkilatınızın İT
                  siyasətləri və ya Microsoft Teams Telefon Sistemi xaricində
                  başqa bir telefon sistemi istifadəsi ilə əlaqədar ola bilər.
                  İT şöbənizlə{" "}
                  <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>
                      {itSupport}
                    </Body1Strong>
                  </Link>{" "}
                  əlaqə saxlamaq məsələyə aydınlıq gətirə bilər.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="8">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                8. TeamConnect vasitəsilə zənglərin edilməsi ilə əlaqədar
                xərclər varmı?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect Microsoft Teams istifadə edərək zənglərinizi daha
                rahat hala gətirir. Zənglərlə bağlı xərclər haqqında biləcəyiniz
                məlumatlar:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    Sizin təşkilatınızın işçilərinin iş telefonlarına,
                    departament telefonlarına, vəya hotlines-lərə (daxili
                    nömrələr) zənglər üçün, Microsoft Teams Telefon Sistemi
                    istifadə olunduğu zaman adətən{" "}
                    <Body1Strong>heç bir xərc</Body1Strong> yoxdur.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Xarici zənglər üçün, məsələn mobil telefonlara və ya xaricə
                    zənglərdə, sizin təşkilatınızın telefon sistemi üzrə{" "}
                    <Body1Strong>standart dərəcələr</Body1Strong> tətbiq olunur.
                    Bu dərəcələr sizin Microsoft Teams ilə xüsusi xidmət
                    planınıza əsasən dəyişir.
                  </li>
                </ul>
                <p>
                  <Body1Strong>Qeyd:</Body1Strong> Əgər hər hansı bir problem
                  yaşayırsınızsa və ya zənglərlə bağlı suallarınız varsa, IT
                  şöbənizlə{" "}
                  <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>
                      {itSupport}
                    </Body1Strong>
                  </Link>{" "}
                  detallı məlumat əldə etmək üçün əlaqə saxlamaq ən yaxşısıdır
                  təşkilatınızın quruluşu və siyasətləri ilə uyğunlaşdırılmış.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="9">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                9. TeamConnect-də 'Ofisdən Kənarda' statusunuzun uyğunsuzluğunu
                müşahidə edirsiniz?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Microsoft 365-dən 'Ofisdən Kənarda' olma statusunuzun
                TeamConnect-də düzgün əks olunmadığını müşahidə etdinizmi? Bu
                problemi həll etmək üçün lazımi tənzimləmələr TeamConnect-də
                artıq həyata keçirilib. Səbirlə Microsoft'un bu xüsusiyyəti
                tətbiq etməsini gözləyirik. Bu baş verdiyi zaman, 'Ofisdən
                Kənarda' statusunuz TeamConnect-də Microsoft Teams'in varlıq
                göstəriciləri ilə uyğunlaşdırılaraq düzgün əks olunacaq.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>İşçi Siyahısı ilə Əlaqədar SSS</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>İşçi Siyahısı Təqdimatı:</Body1Strong> Sizin
          təşkilatınızın peşəkar landşaftını İşçi Siyahısı ilə naviqasiya edin,
          işçilərin detallı məlumatlarını, iş yerindəki dinamikləri, və
          həmkarlarınızın profilləri ilə və idarəetmə əlaqələri ilə birbaşa
          əlaqə saxlamaq üçün mərkəzi bir mənbə.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="10">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                10. İşçi məlumatlarınızı əlavə etmək, yeniləmək, və ya düzəliş
                etmək lazımdırmı?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect-də işçi məlumatlarınızı əlavə etmək, yeniləmək, və
                ya düzəliş etmək üçün təşkilatınızın İK dəstək komandası ilə
                əlaqə saxlayın. Onlar sizə kömək etməyə hazırdırlar{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>
                .
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="11">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                11. İşçilər siyahısında işçinin adına basdıqda nə baş verir?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect İşçilər Siyahısında işçinin adına basmaq həmin
                şəxsin ətraflı profil səhifəsini açır. Bu profil işçi haqqında
                ətraflı məlumatları verir, o cümlədən iş unvanı, departament,
                ofis yeri, əlaqə məlumatları və daha çox şeyi özündə əks
                etdirir. Bu, işçinin peşəkar rolunu və təşkilatdakı yerini tam
                anlamağınıza kömək etmək üçün nəzərdə tutulmuşdur, bununla siz
                onun vəzifələrini və onunla necə əməkdaşlıq edə biləcəyinizi
                anlamağınızı asanlaşdırır.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="12">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                12. İşçinin profilində hansı məlumatlar daxildir?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect-də hər bir işçinin profili peşəkar həyatı haqqında
                geniş məlumatlar təqdim edən ətraflı bir məlumatnamədir. Burada
                nələr tapa bilərsiniz:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Əsas Məlumatlar:</Body1Strong> Ad, iş unvanı,
                    departament və filial yeri.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Raporlaşma Struktur:</Body1Strong> Birbaşa və
                    funksional hesabatlar haqqında məlumat, o cümlədən xətt və
                    funksional menecerlər.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>İş Yeri Status:</Body1Strong> Hazırkı iş
                    statusu kimi Məzuniyyətdə, İşə gediş-gəliş, İşgüzar Səfər və
                    s.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>İş Yeri & Yerli Vaxt:</Body1Strong> İşçinin
                    cari iş yeri və yerli vaxt zona.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Əlaqə Məlumatları:</Body1Strong> İş telefonu,
                    mobil nömrə və elektron poçt ünvanı.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ofis Ünvanı:</Body1Strong> Tam ofis yeri daxil
                    olmaqla bina adı, ünvan və Google xəritə.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Haqqında:</Body1Strong> Qısa şəxsi təqdimat və
                    ya peşəkar bio.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>İşçi Əlaqələri:</Body1Strong> İşçinin hesabat
                    strukturu və komanda əlaqələri haqqında məlumat.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Sosial Linklər & Dillər:</Body1Strong> Peşəkar
                    sosial profillərə linklər və dil biliyi.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Bacarıqlar:</Body1Strong> Peşəkar bacarıqlar və
                    ixtisas sahələri siyahısı.
                  </li>
                </ul>
                Əlavə olaraq, profil, chat, elektron poçt, video zəng və ya
                görüşlər təyin etmək üçün fəaliyyət seçimləri təklif edir,
                həmçinin daha geniş komanda konteksti üçün təşkilatın sxemine
                tez erişim imkanı verir.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="13">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                13. Profil məlumatlarımı necə yeniləyə bilərəm?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Profilinizi TeamConnect-də şəxsi etmək asandır. Bunu necə edə
                bilərsiniz:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Əməkdaş</Body1Strong> bölməsinə keçid edin və
                    profilinizi açmaq üçün <Body1Strong>Avatar</Body1Strong>
                    ınıza tıklayın.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Sol üst küncdə,{" "}
                    <Body1Strong>"Profilinizi redaktə edin"</Body1Strong> keçidi
                    tapa bilərsiniz. Bunu tıkladıqda, profilinizin redaktə
                    dialoqu açılacaq.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Burada, <Body1Strong>Siz haqqında</Body1Strong> bölməsini,
                    sosial media linklərini və telefon nömrənizi əlavə edərək və
                    ya yeniləyərək profilinizi təkmilləşdirə bilərsiniz. Bu,
                    həmkarlarınızın sizinlə əlaqə qurmasını və ya sizə izləməsi
                    üçün daha asan olmasını təmin edir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Dəyişikliklərinizi tətbiq etmək üçün{" "}
                    <Body1Strong>Yadda saxla</Body1Strong> düyməsini basmağı
                    unutmayın.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Dialoqda aşağıya doğru sürüşdürərək{" "}
                    <Body1Strong>Dillər</Body1Strong> və{" "}
                    <Body1Strong>Bacarıq</Body1Strong> əlavə edin, həmçinin{" "}
                    <Body1Strong>Bacarıqlarınız</Body1Strong>. Bu məlumatları
                    paylaşmaq həmkarlarınıza ixtisas sahələrinizi və
                    kommunikasiya qura biləcəyiniz dilləri anlamağa kömək edir.
                  </li>
                </ol>
                Profilinizi yeniləmək yalnız məlumatlarınızı cari saxlamır, həm
                də təşkilatınızdakı əlaqələri gücləndirir.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="14">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                14. TeamConnect-də İş Yeri, İş Məkanı və Vaxt Zonasını Yeniləmə
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                İş yeriniz, iş məkanınız və vaxt zonası məlumatlarınızı cari
                saxlamaq, Microsoft Teams-də səmərəli əməkdaşlıq üçün vacibdir.
                Bu yeniləmələr dərhal TeamConnect profilinizdə və siyahılarda
                əks olunur, həmkarlarınıza iş yerinizi və sizinlə əlaqə saxlamaq
                üçün ən yaxşı vaxtları dərhal anlamağa imkan verir, müxtəlif
                bölgələr və vaxt zonaları arasında komanda işini artırır.
                <p></p>
                Məlumatlarınızı yeniləməyə hazırsınız? TeamConnect-də bu
                asandır! Menyunun üstündəki 'Ayarlar'a keçin və 'Ümumi Ayarlar'a
                daxil olun. Burada İş Yerinizi və Vaxt Zonanızı asanlıqla
                yeniləyə, gün üçün İş Məkanınızı müəyyən edə bilərsiniz.
                Dəyişikliklərinizi saxlamayı unutmayın ki, hər kəs məlumatlı
                olsun və yerinizdən asılı olmayaraq effektiv əməkdaşlıq təmin
                edilsin!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="15">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                15. Həmkarınızın profilində nəsə yanlış görünür?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Həmkarınızın profilində doğru görünməyən hər hansı bir məlumatla
                qarşılaşsanız, ən yaxşı ilk addım onlara dostcasına xəbərdarlıq
                etməkdir. Tez bir söhbət və ya e-poçt işi görəcək! Məlumatlar
                yenilənmirsə narahat olmayın—sadəcə HR dəstək komandanıza{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>{" "}
                ünvanından müraciət edin, və onlar məsələni həll etmək üçün
                sevindirici şəkildə müdaxilə edəcəklər.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="16">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                16. Etiket qruplarını anlama: Hamısı, Sevimlilər, Yoxlanılıb &
                Yeni İşçilər
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Hamısı:</Body1Strong> Bu standart etiket
                    təşkilatınızdakı hər bir işçini göstərir, komandanın tam
                    görünüşünü təmin edir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Sevimlilər:</Body1Strong> Bura basaraq
                    baxışınızı 'Sevimli' kimi işarələdiyiniz iş yoldaşlarınıza
                    filtr edin, əsas təmaslarla tez bir zamanda əlaqə qurmağı
                    asanlaşdırın.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Yoxlanılıb:</Body1Strong> Bu etiket aktiv işdə
                    olan işçiləri göstərir, yalnız Microsoft Teams-də onlayn
                    olmalarından fərqlənir. Yoxlanıldıqda, işçilər işdə
                    olduqlarını və gün ərzində cari iş yerlərini paylaşırlar.
                    Bu, Ofis, Saha-Ofisi, Ev-Ofisi, Uzaq-İş Yeri, Müştəri-İş
                    Yeri, Müştəri-Xüsusi Ofisi, İctimai-Saha, Nəqliyyat, İşgüzar
                    Səyahət və ya Təlim kimi müxtəlif iş yerlərini əhatə edə
                    bilər. Buna görə də, 'Yoxlanılıb' statusu aktiv işdə olmağı
                    aydın şəkildə göstərir, həmkarlar arasında bir-birlərinin
                    cari iş kontekstini və mövcudluğunu dəqiq başa düşməyi təmin
                    edir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Yeni İşçilər:</Body1Strong> TeamConnect-də bu
                    etiket ilə yeni üzləri görün, son 30 gün ərzində tətbiqə
                    əlavə edilmiş işçiləri vurğulayın. Adətən, bu, təşkilatınıza
                    son əlavələrlə uyğunlaşır, yeni komanda üzvlərini
                    qarşılamağa kömək edir.
                  </li>
                </ul>
                Bu etiket qrupları, TeamConnect təcrübənizi təkmilləşdirmək və
                həmişə iş yoldaşlarınızı səmərəli bir şəkildə tapıb əlaqə
                qurmağınızı təmin etmək üçün naviqasiyanı sadələşdirmək üçün
                nəzərdə tutulub.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="17">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                17. Axtarış qutusunun yanındakı ikonaların şifrələnməsi
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect-in İşçi Siyahısı çeviklik və asanlıq üçün nəzərdə
                tutulub, dörd ikona ilə təchiz edilmişdir ki, bu da müxtəlif
                baxışları təqdim edir və əsas təfərrüatlar üçün sürətli giriş
                təmin edir. Hər bir ikona nə açıqlayır:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Siya Listi Baxışı:</Body1Strong> Sizin əsas
                    baxışınız, bütün təşkilat üzvlərini sadə bir siyahıda təqdim
                    edir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Kiçik Kart Baxışı:</Body1Strong> Kompakt
                    alternativ, bu baxış siyahını kiçik işçi kartlarına çevirir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Böyük Kart Baxışı:</Body1Strong> Daha çox
                    məlumatla böyük kartlar seçin, bu kartlar əlavə əlaqə
                    seçimlərini telefon və mobil nömrələri daxil etməklə asan
                    zəng etməyə imkan verir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Təşkilat Şeması:</Body1Strong> Şirkətin
                    strukturunu Təşkilat Şeması baxışı ilə vizuallaşdırın,
                    hesabatlaşma münasibətlərini və təşkilati peyzajı anlamaq və
                    naviqasiya etmək üçün mükəmməldir.
                  </li>
                </ol>
                Bu ikonlar arasında dəyişərək, anın ehtiyacına ən uyğun gələn
                baxışı tapın, istərsiniz sürətli məlumat, istərsiniz daha dərin
                baxış axtarırken.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="18">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                18. İş yoldaşını tez axtarmaq üçün hara baxmalıyam?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                İş yoldaşını axtarırsınız? Sadəcə, TeamConnect axtarış qutusuna
                baş çəkin, bu, üst sağ tərəfdə Org şeması ikonasının yanında
                yerləşir. Onların adını yazmağa başlayın və hər hərf ilə
                nəticələrin necə təmizləndiyinə baxın. Xatırladaq ki,
                TeamConnect axtarışları üçün yuxarıdakı Microsoft Teams axtarış
                panelindən istifadə etməyin—xüsusi axtarış qutumuz gedəcəyiniz
                yoldur!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="19">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                19. Əməkdaşların axtarışında əlifba üzrə axtarış necə işləyir?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect Əməkdaş Qovluğu rahat əlifba axtarış funksiyası ilə
                təchiz olunub ki, bu da sizə əməkdaşları onların adının ilk
                hərfi ilə sürətlə tapmağa kömək edir. Sadəcə qovluğun üst
                hissəsində göstərilən əlifbadan bir hərfi seçin və TeamConnect
                dərhal həmin hərflə başlayan bütün əməkdaşları sizə göstərəcək.
                Bütün əməkdaşların siyahısına qayıtmaq üçün seçilmiş hərfi
                seçimi ləğv edin. Bu intuitiv funksiya geniş əməkdaş siyahıları
                ilə rahat naviqasiya etməyə imkan verir, həmkarlarınızı sürətli
                və effektiv şəkildə tapmağınıza kömək edir.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="20">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                20. Səhifədə göstərilən əməkdaşların sayını necə tənzimləyə
                bilərəm?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect, əməkdaş profillərini səhifədə necə görəcəyinizi
                fərdiləşdirməyə imkan verir, Əməkdaş Qovluğu ilə daha asan
                naviqasiya etməyi təmin edir. Hər səhifənin altında səhifələmə
                seçimləri tapa bilərsiniz: 10, 50, 100, 500, 1000 və 5000. İlkin
                tənzimləmə səhifədə 10 profil göstərir. Tək bir səhifədə daha
                çox profil görmək istədikdə, bu seçimlərdən istədiyiniz rəqəmi
                seçin. Bu funksiya qovluğa baxışınızı xüsusi ehtiyaclarınıza və
                ya üstünlüklərinizə uyğunlaşdırmaqda sizə esneklik təqdim edir.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="21">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                21. List Görünüşündə əməkdaş məlumatlarını necə sıralaya
                bilərəm?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect Əməkdaş Qovluğunun List Görünüşündə, əməkdaş
                məlumatlarını ehtiyaclarınıza uyğun sıralamaq imkanınız var.
                Sütunlar—Əməkdaşın Adı, İş Vəzifəsi, Departament, Ofis Yeri və
                İş Yeri—hamısı sıralanabilir. Sıralamaq istədiyiniz sütun
                başlığına sadəcə klikləyin. Bir dəfə klikləmək sütunu artan
                sırada sıralayacaq, yenidən klikləmək isə azalan sırada
                sıralayacaq. İlkin olaraq, qovluq Əməkdaşın Adı əsasında və adın
                ilk hərfi ilə artan sırada sıralanır. Bu sıralama funksiyası,
                xüsusi birini axtararkən və ya əməkdaşları məsələn departament
                və ya yeri kimi müəyyən bir meyara görə qruplaşdırmaq lazım
                olduğunda, əməkdaş məlumatlarını sürətli şəkildə təşkil etməyə
                və baxmağa kömək edir.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Şöbə Qovluğu TSS</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Şöbə Qovluğuna Giriş:</Body1Strong> Şöbə Qovluğu
          təşkilatınızın mürəkkəb strukturunu anlamaq üçün sizə açılan bir
          qapıdır, şöbələrin iyerarxiyasını, idarəetmə liderliyini və
          şöbələrarası problemlərsiz ünsiyyət üçün yolları aydın şəkildə
          göstərir.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="22">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                22. Şöbə Qovluğundakı 'Hamısı', 'Seçilmişlər' və 'Sizin Şöbəniz'
                etiketləri necə işləyir?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect-in Şöbə Qovluğunda etiketlər şöbələr ilə bağlı
                məlumatları sürətli şəkildə filtrləməyə və naviqasiya etməyə
                kömək edir. Hər bir etiketin təmsil etdiyi məna:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Hamısı:</Body1Strong> Bu etiket
                    təşkilatınızdakı bütün şöbələri göstərir, əhatəli bir baxış
                    təklif edir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Seçilmişlər:</Body1Strong> Bu etiketi, tez-tez
                    əlaqə qurduğunuz şöbələri asanlıqla əldə etmək üçün
                    'Seçilmiş' kimi işarələdiyiniz şöbələrin gözəl listini
                    görüntüləmək üçün istifadə edin.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Sizin Şöbəniz:</Body1Strong> Bu etiket yalnız
                    sizin şöbənizi göstərərək, məlumatlara və yeniliklərə
                    sürətli çıxış imkanı verir.
                  </li>
                </ul>
                Bu etiketlər Şöbə Qovluğu daxilində naviqasiyanızı
                təkmilləşdirmək üçün nəzərdə tutulmuşdur, şöbələrlə bağlı
                məlumatları tapmaq və ilə ünsiyyət qurmaq bacarığınızı artırır.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="23">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                23. TeamConnect-də Şöbə Qovluğu Görünüşlərini Anlamaq
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect-də Şöbə Qovluğu ilə naviqasiya, tərcihlərinizə uyğun
                olan bir neçə görünüş seçimi ilə təkmilləşdirilmişdir. Hər bir
                görünüş, Şöbə Adı, Şöbə Meneceri, Ana Şöbə, Şöbə Telefonu, Şöbə
                Hotline və Şöbə Email daxil olmaqla, şöbələrlə bağlı ətraflı
                məlumatları kəşf etmək üçün unikal bir yol təklif edir. Mövcud
                olan görünüşlərin bir qısa məlumatı:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Siyahı Görünüşü:</Body1Strong> Bütün şöbələri
                    dəqiq və səliqəli bir şəkildə siyahılayan standart
                    quruluşdur.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Böyük Kart Görünüşü:</Body1Strong> Hər bir
                    şöbənin açar məlumatlarını ətraflı göstərən genişləndirilmiş
                    özetləri görmək üçün bu görünüşə keçin.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Org Şeması Görünüşü:</Body1Strong> Şöbənin
                    təşkilati iyerarxiyada necə yer aldığını və əlaqələri vizual
                    şəkildə təqdim edən Org Şeması görünüşü.
                  </li>
                </ul>
                Bu görünüşlər arasında keçid etmək üçün, Şöbə Qovluğunun
                üstündəki axtarış qutusunun yanındakı ikonlardan istifadə edin.
                Hər bir görünüş, şöbə detallarına çıxışı və anlamını
                asanlaşdırır, lazım olan məlumatları sizin üçün ən yaxşı işləyən
                formatda tapmağınıza kömək edir.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="24">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                24. Departament siyahısında Axtarış Qutusundan necə istifadə
                etməli
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect-də Departament Siyahısının ən yuxarısındakı görünüş
                ikonalarının yanında yerləşdirilmiş xüsusi axtarış qutusu
                vasitəsilə asanlıqla hər hansı departamenti tapın. Sadəcə
                axtardığınız departamentin adını yazmağa başlayın və kataloqun
                yalnız əlaqəli uyğunluqları göstərmək üçün necə tənzimləndiyini
                izləyin. Bu xüsusiyyət, bütün kataloqu əl ilə axtarmağa ehtiyacı
                aradan qaldırmaq üçün xüsusi hazırlanmışdır, ehtiyacınız olan
                departamenti birbaşa barmaqlarınızın ucuna gətirir. Xatırlayın,
                bu axtarış funksionallığı yalnız TeamConnect-in Departament
                Kataloqu üçün xüsusi olduğundan, departamentə xüsusi sorğular
                üçün Teams interfeysinin ən yuxarısında yerləşən əsas Microsoft
                Teams axtarış qutusundan istifadə etmək əvəzinə bu axtarış
                qutusundan istifadə etməyinizə əmin olun.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="25">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                25. Departament siyahısında əlifba filtri necə işləyir?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Departament Siyahısında əlifba filtrini ən üstdə yerləşdirərək
                axtarışınızı sadələşdirin. Bu xüsusiyyət, bir hərf seçməklə
                departamentləri sürətlə daraltmağa imkan verir. Bir hərf
                seçdikdən sonra, TeamConnect yalnız adları seçdiyiniz hərflə
                başlayan departamentləri filtrləyərək və nümayiş etdirərək,
                axtardığınızı tapmağı asanlaşdırır, bütün siyahını səhifələməyə
                ehtiyac qalmadan. Tam departament görünüşünə qayıtmaq üçün
                seçilmiş hərfi sadəcə ləğv edin. Bu intuitiv əlifba
                naviqasiyası, hər hansı bir departamenti onun ilkin hərfi ilə
                səmərəli şəkildə tapmağınıza imkan verir.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="26">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                26. Departament rəhbəri ilə necə əlaqə saxlaya bilərəm?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect-də departament rəhbəri ilə əlaqə saxlamaq sadədir.
                Sadəcə maraqlandığınız departamenti tapın və rəhbərin adına
                klikləyin. Bu əməliyyat, rəhbərin əlaqə variantları ilə pop-up
                pəncərənin açılmasını tetikleyir və tanıdıq sürətli giriş
                ikonaları:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Chat İkonu:</Body1Strong> Microsoft Teams
                    çatında dərhal mesaj göndərmək üçün.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>E-poçt İkonu:</Body1Strong> Microsoft
                    Outlook-da onlara yeni bir e-poçt açmaq üçün.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Video Zəng İkonu:</Body1Strong> Microsoft Teams
                    vasitəsilə video zəng başlatmaq üçün.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Zəng İkonu:</Body1Strong> Microsoft Teams
                    istifadə edərək səsli zəng başlatmaq üçün.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Daha çox (...) İkonu:</Body1Strong> Toplantı
                    planlaşdırmaq və ya Teams siyahınıza onların əlaqə
                    məlumatlarını əlavə etmək kimi əlavə variantlar üçün.
                  </li>
                </ul>
                Bu ikonlar, TeamConnect daxilində departament rəhbərlərinə
                birbaşa etibarlı şəkildə ulaşmağa imkan verir və təşkilatınız
                daxilində səmərəli ünsiyyəti təmin edir.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="27">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                27. Bir şöbədə işçiləri necə görə və ya onlarla əlaqə saxlaya
                bilərəm?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect-də bir şöbədə kimlərin olduğunu araşdırmaq üçün,
                şöbə adının yanındakı üç nöqtəyə daha yaxından baxın. Bunu
                vurduqda daha da əməliyyat üçün seçimlər çıxacaq:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Bu Şöbədəki İşçilərə Baxın:</Body1Strong> Bunu
                    seçərək şöbə ilə əlaqəli bütün işçilərin siyahısını
                    göstərin. Bu seçim yanında rəqəm şöbədə neçə işçinin
                    olduğunu göstərir, sürətli bir say sayma təklif edir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Şöbənin Təşkilat Şeması:</Body1Strong> Əgər
                    şöbənin iyerarxiyasını anlamaq istəyirsinizsə, 'Şöbənin
                    təşkilat şeması' seçin ki, struktur tərtibatı açılsın, ana
                    şöbələrin, müzakirə olunan şöbənin və hər hansı altşöbələrin
                    arasındakı əlaqəni nümayiş etdirsin. Bu vizual təqdimat sizə
                    təşkilati çərçivəni başa düşməyə və müəyyən şöbə üzvləri ilə
                    əlaqə qurmağı asanlaşdırmağa kömək edir.
                  </li>
                  <li>
                    <Body1Strong>Şöbə {">"} İşçi:</Body1Strong> Şöbədəki
                    işçilərə baxdıqdan sonra, şöbə siyahısına asanlıqla qayıtmaq
                    üçün bu breadcrumb izini istifadə edin, TeamConnect
                    daxilində səmərəli bir araşdırma təcrübəsini qoruyun.
                  </li>
                </ul>
                Bu xüsusiyyətlər sizi yalnız verilmiş bir şöbədəki işçilərin
                siyahısını görməyə imkan vermir, həm də onlarla birbaşa
                TeamConnect'in kommunikasiya alətləri vasitəsilə əlaqə yaratmaq
                üçün bir yol təklif edir.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="28">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                28. TeamConnect-də Ətraflı Şöbə Məlumatlarını Öyrənmək
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect-də hər hansı bir şöbənin xüsusiyyətlərini onun adına
                sadəcə bir kliklə araşdırın. Ətraflı bir dialoq açılır,
                təşkilatınız daxilində şöbənin rolu və resurslarına dair tam bir
                mənzərəni təsvir edən zəngin məlumat toxuması təqdim edir.
                Rəhbərlikdən maliyyə identifikatorlarına, kommunikasiya
                kanallarına və sosial bağlantılara qədər hər şeyi tapa
                bilərsiniz. Bu xüsusiyyət təşkilatınız daxilindəki unikal
                ekosistemlərlə əlaqə qurmaq və başa düşmək üçün sizə alətləri
                təmin edir:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    Şöbənin ürəyi – onun məqsədi, insanları və performans
                    göstəriciləri.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Əlaqə yolları – əsas şöbə rəsmiləri ilə birbaşa xətlər və
                    kommunikasiya platformaları.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Təşkilati şəbəkə – şöbənin daha geniş şirkət strukturu ilə
                    necə inteqrasiya etdiyini anlamaq.
                  </li>
                </ul>
                İstər sürətli bir sorğu, istərsə də şöbə məlumatlarına dərindən
                nüfuz etmək olsun, TeamConnect sizə bu unikal ekosistemlərlə
                əlaqə qurmaq və başa düşmək üçün vasitələr təmin edir.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="29">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                29. TeamConnect-də Şöbə Ünvanlarının Olmaması Haqqında
                Maraqlanırsınızmı?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Bəzən bir şöbənin yerini tapmaq xəritəsiz bir xəzinə axtarışı
                kimi hiss ola bilər. TeamConnect-də, adətən hər bir şöbə üçün
                ayrılmış bir ünvan tapmazsınız. İşte bu kartografik detayın
                tez-tez nədən daxil edilmədiyi:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Bir neçə Yerləşmə:</Body1Strong> Çox şöbə
                    yalnız bir yerlə məhdudlaşmır; müxtəlif ofislərdə və
                    coğrafiyada inkişaf edirlər.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Sabit Struktur:</Body1Strong> Təşkilati peyzaj
                    daim dəyişir, şöbələr strategiyaya uyğun olaraq inkişaf
                    edir, birləşir və ya hətta ləğv olunur.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Şöbələrarası Əməkdaşlıq:</Body1Strong> Müasir
                    iş yerləri müxtəlif şöbələrin ortaq məkanlarda birləşən
                    istedadları ilə əməkdaşlıq ruhu ilə canlanır.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Uzaqdan İş Mədəniyyəti:</Body1Strong> Uzaqdan
                    iş imkanları ilə, şöbələrin əsasları həmişə fiziki ofis
                    yerlərində qoyulmur.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Hüquqi Detallar:</Body1Strong> Şöbələr tipik
                    olaraq, öz ayrı-ayrı ünvanlarına malik olmaq yerinə,
                    şirkətin hüquqi strukturuna əsaslanır.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Operativ Səmərəliliyi Yüksəltmək:</Body1Strong>{" "}
                    Dinamik komanda işinə diqqəti yönəltmək, sabit ünvanlara
                    deyil, operativ çevikliyi saxlamağa kömək edir.
                  </li>
                </ul>
                Əsas məqsəd iş birliyi və səmərəliliyi təmin etmək üçün
                sərhədsiz yanaşmanı qəbul etməkdir. TeamConnect bu fəlsəfəni
                yerlərə üstünlük verilməsindən daha çox əlaqələrə diqqət çəkərək
                əks etdirir.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="30">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                30. Şöbənizin məlumatları itirilmiş və ya köhnəlmişdir?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Təşkilatınızdakı şöbə redaktorları TeamConnect-də şöbəyə xüsusi
                məlumatları əlavə etmək, yeniləmək və ya düzəltmək üçün təyin
                edilmişdir. Şöbə redaktorunuzun kim olduğundan əmin deyilsinizsə
                və ya müəyyən məlumatların İK dəstək komandasının müdaxiləsini
                tələb edirsə, xahiş edirik{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>
                'nə müraciət edin.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Ofis Kataloqu Sualları</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Ofis kataloqunun təqdimatı:</Body1Strong> Ofis Kataloğu
          vasitəsilə təşkilatınızın fiziki mövcudiyətinin əsasını, əsas ofis
          məlumatlarını, işin əməliyyat təfərrüatlarını və hər yerin xarakterini
          təyin edən şöbələr və personala dair hərtərəfli məlumatı kəşf edin.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="31">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                31. Ofis kataloğunda etiketlərdən istifadə
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Kataloğun etiket sistemi vasitəsilə Ofis Kataloğunda rahatlıqla
                gəzinin, doğru yerə tez çıxış üçün nəzərdə tutulmuşdur. İşte hər
                bir etiketin axtarışınızı necə asanlaşdırdığı:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Hamısı:</Body1Strong> Bütün ofislərin
                    siyahısını göstərir, təşkilatınızın yerləşmələrinə quş
                    baxışı təmin edir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Sevimlilər:</Body1Strong> Tez-tez ziyarət
                    etdiyiniz və ya yaxından nəzarətdə saxlamalı olduğunuz ofis
                    yerlərini 'Sevimlilər' kimi işarələməklə, bu yerlərə diqqəti
                    artırmağa kömək edir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Sizin Ofisiniz:</Body1Strong> Bu şəxsi etiket
                    kataloqu sizin yerləşdiyiniz ofis yerini göstərərək, “ev
                    bazanızı” barmaqlarınızın ucunda saxlayır.
                  </li>
                </ul>
                İster iclas otağı rezerv edin, ister səfər planlaşdırın, bu
                etiketlər təşkilatınızın coğrafi landşaftı ilə qarşılıqlı
                əlaqədə şəxsi və sadələşdirilmiş bir yol təklif edir.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="32">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                32. TeamConnect-də Ofis Siyahılarını Mükəmməl Mənimsəmək
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect-in Ofis Siyahısı baxışları ilə şirkətinizin müxtəlif
                yerlərinin təşkilat quruluşuna dərin nəzər salın. Hər baxış,
                ofis naviqasiyası və başa düşmənin fərqli bir aspektini
                asanlaşdırmaq üçün hazırlanmışdır:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Siyahı Baxışı:</Body1Strong> Bütün ofis
                    yerlərini oxumaq üçün asan olan bir siyahıda sıralayan
                    standart ayar.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Böyük Kart Baxışı:</Body1Strong> Ofis
                    Siyahısındakı Böyük Kart Baxışı hər bir ofisin ətraflı
                    təqdimatını zənginləşdirir. Bir ofis kartı ilə məşğul
                    olarkən, "Daha çox (…)" menüsünün içində çoxlu məlumat və
                    fəaliyyət seçimləri tapacaqsınız:
                    <ol>
                      <li
                        style={{ marginTop: "8px" }}
                        className={styles.bulletBottomMargin}
                      >
                        <Body1Strong>Şöbələrə Baxış:</Body1Strong> Ofisdə
                        yerləşən bütün şöbələrin siyahısına birbaşa keçid.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>İşçilərə Baxış:</Body1Strong> Ofisdə təyin
                        edilmiş işçilərin tam siyahısına daxil olaraq, ofis
                        personalı ilə birbaşa əlaqə və ya araşdırma imkanı təmin
                        edir.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>
                          Ticarət Qurumunun Təşkilat Strukturu:
                        </Body1Strong>{" "}
                        Ofisin daha böyük təşkilati kontekstdə yerini
                        görselleştirərək, iyerarxik strukturları aydın anlamaq
                        üçün.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Əsas Əlaqələr:</Body1Strong> Ünsiyyəti
                        asanlaşdıran əsas ofis əlaqələrini müəyyənləşdirin.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Ofisin Qeyri-İş Günleri:</Body1Strong>{" "}
                        Cədvəllərinizi uyğunlaşdırmaq üçün ofisə xas qeyri-iş
                        günlərindən xəbərdar olun.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Ofisin İş Saatları:</Body1Strong>{" "}
                        Ziyarətlərinizi və ya zənglərinizi onların fəal vaxtları
                        ilə uyğunlaşdırmaq üçün ofisin iş saatlarını görün.
                      </li>
                    </ol>
                    Bu baxış yalnız vizual və məlumatlı anlayışı deyil, həm də
                    ofislə əlaqəli dərindən anlayışları və birbaşa fəaliyyətləri
                    asanlıqla əldə etməyə imkan verir, hamısı sizin rahatlığınız
                    üçün "Daha çox (...)" seçiminin altında səliqəli şəkildə
                    yerləşdirilib.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Təşkilat Şeması Baxışı:</Body1Strong>{" "}
                    Təşkilatınız daxilində müxtəlif ofis yerlərini birləşdirən
                    ana və sub-ofislərin iyerarxik strukturunu görselleştirin.
                  </li>
                </ul>
                Bu baxışları aramaq qutusunun yanındakı ikonlara keçid edərək,
                təcrübənizi cari ehtiyaclarınıza uyğunlaşdırmaq üçün
                fərdiləşdirin.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="33">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                33. TeamConnect-də Tez Ofisləri Tapmaq
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect-də düzgün ofis yerləşdirməsini tapmaq, xüsusi
                axtarış xüsusiyyəti ilə asanlaşdırılmışdır. Axtarış qutusu,
                görüntü ikonlarının yanında yerləşdirilmiş və adına görə
                ofisləri dəqiqləşdirmək üçün sizin vasitənizdir. Yazarkən,
                siyahı yalnız daxil etdiyinizə uyğun gələn ofisləri göstərəcək
                şəkildə tənzimlənir, axtarış prosesinizi sadələşdirir. Ən dəqiq
                və əlaqəli nəticələr üçün TeamConnect daxilində bu axtarış
                qutusundan istifadə etmək mühümdür, ümumi Microsoft Teams
                axtarışına nisbətən. Bu hədəflənmiş axtarış funksionallığı ilə,
                təşkilatınızın şəbəkəsindəki hər hansı bir ofisə sürətlə
                naviqasiya edə bilərsiniz.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="34">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                34. Ofis Qovluğunda Əlifba Sırası ilə Yönəlmək
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Əgər hərflərə görə axtarış etməyi üstün tutursunuzsa, əlifba
                filtrimiz adlara görə ofisləri tapmaq üçün sürətli bir yoldur.
                Ofis Qovluğunun yuxarısında A-dan Z-yə qədər hərfləri
                görəcəksiniz. Bir hərfə klikləmək, yalnız həmin hərflə başlayan
                ofisləri göstərən görünüşü filtrələyir və axtarış prosesinizi
                sadələşdirir. Bütün ofisləri yenidən görmək üçün aktiv hərfi
                ləğv edin. Bu funksiya, ofis adını bildiyiniz halda, onu
                TeamConnect daxilində tez bir şəkildə tapmağa ehtiyacınız
                olduğunda xüsusilə faydalıdır.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="35">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                35. TeamConnect-də Ətraflı Ofis Profillərinin Açılması
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Təşkilatınızın fiziki varlığının qəlbinə keşfi səyahətə başlayın
                TeamConnect-in ofis profilləri ilə. Ofis adına klikləmək parlaq
                profil dialoqunu açır, ətraflı ofis məlumatları ilə dolu
                tablarla:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ümumi Baxış:</Body1Strong> İş strukturu, açılış
                    saatları və yerli vaxt kimi ətraflı təsvirlərlə ofisin
                    kimliyinə dərin bir nəzər salın və ofis mühitinin
                    mahiyyətini tutun.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Əsas Əlaqələr:</Body1Strong> Təcili xidmətlər
                    və xarici dəstək üçün vacib əlaqələrə Microsoft Teams Phone
                    System vasitəsilə bir kliklə çıxış əldə edin.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ofis Əlaqələri:</Body1Strong> Daxili dəstək
                    xidmətlərilə asanlıqla ünsiyyət qurun, əlaqə məlumatları və
                    birbaşa məşğulluq imkanları barmaqlarınızın ucunda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>İctimai Bayramlar:</Body1Strong> Ofisə xas
                    bayramlardan xəbərdar olun, planlarınızı yerli müşahidələr
                    və iş cədvəlləri ilə uyğunlaşdırmağınızı təmin edin.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ofis Saatları:</Body1Strong> Ofisin fəaliyyət
                    göstərdiyi və ya günün sonuna yaxınlaşdığı vaxtları dəqiq
                    biliklə planlaşdırın.
                  </li>
                </ul>
                Austin-in canlı enerjisi və ya Sinqapurun strategik mərkəzi
                olsun, hər bir tab şirkətinizin qlobal varlığını təşkil edən
                müxtəlif yerlərə başa düşmək və əlaqə qurmaq üçün bir qapı
                təklif edir.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          This translation carefully preserves all original coding structure and
          HTML attributes, translating only the text content into Azerbaijani.
          <AccordionItem value="36">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                36. Ofisin Departament Dinamikasının Dərinlərinə Dalmaq
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ofisin departamentlərinin peşəkar şəbəkəsindən asanlıqla keçmək
                TeamConnect-də mümkündür. Detallara necə daxil olmaq olar:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Bu Ofisdəki Departamentlərə Baxın:
                    </Body1Strong>{" "}
                    Daha çox (…) menyusundan bu seçimi seçərək ofisin
                    strukturunu təşkil edən bütün departamentləri kəşf edin.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Təşkilati İçgörü:</Body1Strong> Hər hansı bir
                    departamentə klikləyərək onun təşkilat sxemini və görsel
                    strukturunu açın, sizə yön göstərən breadcrumb yolu: Ofis{" "}
                    {">"} Departament {">"} Sxem.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Departamentə Dərin Dalış:</Body1Strong>{" "}
                    Departament adının yanındakı Daha çox menyusu (…) düyməsini
                    klikləyərək həmin departamentdəki bütün işçilərin siyahısına
                    çıxış əldə edin, doğrudan ofis kontekstində.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Breadcrumb Naviqasiyası:</Body1Strong>{" "}
                    Səviyyələr arasında rahatlıqla hərəkət edin. İşçilərdən
                    departamentlərə və ya təşkilat sxemindən geriyə, breadcrumb
                    yolu (Ofis {">"} Departament {">"} İşçi {">"} Sxem)
                    səyahətinizi intuitiv və izlənən edir.
                  </li>
                </ul>
                TeamConnect-in mürəkkəb yolları hər bir təbəqəni araşdırmağa,
                başa düşməyə və ofisinizin departamentləri ilə məşğul olmağa
                dəvət edir, sizə ətraflı naviqasiya komandası ilə güc verir.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="37">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                37. TeamConnect-də Ofis Əməkdaşları ilə Əlaqə
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Bir ofisin arxasındakı simalar kimlərdir? Cavab TeamConnect-də
                bir klikləməklə mümkündür. Seçdiyiniz ofis adının yanındakı üç
                nöqtəni (ellipsis) klikləyərək interaktiv seçimlər dəstini açın:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Bu Ofisdəki İşçilərə Baxın:</Body1Strong> Bu
                    ofisi iş yeri kimi seçən bütün peşəkarların siyahısını
                    ortaya çıxarın. Göstərilən rəqəm işçilərin sayını tez bir
                    zamanda göstərir, sizə ofis icmasına daxil olmağa dəvət
                    edir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Əlaqə Seçimləri:</Body1Strong> Hər işçi
                    məlumatı, TeamConnect-dəki söhbətləri, zəngləri və ya video
                    konfransları başlatmağa imkan verən sürətli hərəkət ikonları
                    ilə təchiz edilmişdir, ofis içi ünsiyyəti asanlaşdırır.
                  </li>
                  <li>
                    <Body1Strong>Naviqasiya:</Body1Strong> İşçi siyahısından
                    daha geniş ofis siyahısına qayıtmaq üçün, breadcrumb yolu
                    (Ofis {">"} İşçi) 'Ofis'ə klikləyin və sizi ofisə genel
                    baxışa aparacaq.
                  </li>
                </ul>
                Bu xüsusiyyətlərlə, TeamConnect ofislər arasında işçi gücünün
                paylanmasını vizuallaşdırmağı və rahatlaşdırmağı asanlaşdırır,
                məsafədən asılı olmayaraq əlaqəli iş yerini təşviq edir.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="38">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                38 Sizin ofis məlumatlarınız çatışmayır və ya köhnəlmişdir?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect-də ofis məlumatlarının yenilənməsi təyin edilmiş
                ofis redaktorları tərəfindən idarə edilə bilər. Ofis
                redaktorları haqqında əmin deyilsinizsə və ya HR tərəfindən
                idarə olunan məlumatlarla köməkə ehtiyacınız varsa, HR dəstək
                komandası{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>{" "}
                vasitəsilə kömək etməyə hazırdır.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Parametrlər ilə bağlı Tez-tez Soruşulan Suallar</Subtitle2>
      <p></p>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="39">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                39. TeamConnect-də Dəqiq Ümumi Parametrlərin Əhəmiyyəti
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Bu günün dinamik iş mühitində əlaqədə və uyğunlaşmış olmaq heç
                vaxt olmamış qədər vacibdir. TeamConnect-də Ümumi
                Parametrlərinizi yeniləmək mühüm rol oynayır:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>İş Yeri Seçimi:</Body1Strong> Ev-Ofisinizin
                    sakitliyindən əsas Ofisin qalmaqalına keçmək və ya ictimai
                    Bir Yerdə yerləşməklə iş yerinizi cari vəziyyətinizə uyğun
                    olaraq yeniləyin. İş yerinizin bu aydınlığı həmkarlarınıza
                    sizinlə necə əlaqə saxlamağın ən yaxşı yolunu deyil, həm də
                    onlarla apara biləcəkləri söhbətlərin növünü göstərir,
                    xüsusilə açıq bir müştəri ofisində və ya hava limanı
                    salonunda olduğunuz zaman məxfi müzakirələrin məxfiliyini
                    qorumaq.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>İş Nöqtəsinin Spesifikasiyası:</Body1Strong> İş
                    şəhərinizi bölüşmək yalnız uzaq komanda quruluşunda
                    məsafələri qısaltmaq deyil, həm də spontan peşəkar
                    toplantılar və ya gündəlik sosiallaşma üçün imkanlar açır.
                    Bu, sadəcə bir qəhvə, rahat bir nahar və ya birgə toplantı
                    olsun, eyni şəhərdə olduğunuzu bilmək adi bir günü mənalı
                    üzbəüz əlaqələr üçün bir fürsətə çevirə bilər. Bu, yalnız
                    komanda bağlarını gücləndirmir, həm də işinizə təsir edə
                    biləcək yerli nüansları daha yaxşı anlamağa imkan verir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Saat Qərbətə uyğunluğu:</Body1Strong>{" "}
                    Komandalar qitələr arasında genişləndikcə, cari yerinizi əks
                    etdirən zaman bölgənizin dəqiq olması əlaqələrin
                    uyğunlaşmasına zəruri olduğunu qeyd edir. TeamConnect
                    profilinizin Yerli Vaxtı bu parametrdən birbaşa təsirlənir,
                    həmkarlarınıza sizi çatmağa çalışdıqları zaman üçün hər
                    hansı təxmin oyununu aradan qaldırır.
                  </li>
                </ul>
                TeamConnect-də ‘Parametrlər’ {'>'} ‘Ümumi Parametrlər’ bölməsinə
                daxil olaraq bu detalları dəqiq ayarlayın. Parametrlərinizi
                yeniləməyə ayırdığınız bir neçə an, komandanızın ümumi
                uyğunluğunu və səmərəliliyini xeyli artıra bilər.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="40">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                40. İş yerində seçimlərin aydınlaşdırılması TeamConnect-də
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                İş yerləri açılır menyunun düzgün seçilməsi TeamConnect-də cari
                iş vəziyyətinizi dəqiq əks etdirir. Seçimlərin hər birinin
                ətraflı izahını öyrənmək üçün məlumat ikonasının üzərinə gəlin:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Çıxış/Etibar:</Body1Strong> İşdən uzaqlaşma
                    vaxtını, istirahət və ya günün sonunu göstərir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ofis/Saha-Ofisi:</Body1Strong> Şirkət
                    tərəfindən idarə olunan iş məkanında olduğunuzu göstərir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ev-Ofisi:</Body1Strong> Evdəki ayrılmış iş
                    yerindən işləmək.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Uzaq-İş yeri/İctimai Yer:</Body1Strong> Ofis
                    dən kənarda işləməyi, elastik ünsiyyətə olan ehtiyacı
                    göstərir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Müştəri İş yeri/Xüsusi Ofis:</Body1Strong>{" "}
                    Müştəri ilə yerində olmaq, söhbət məxfilik səviyyələri ilə
                    əlaqəli diqqət notu ilə.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Nəqliyyat/İş Səfəri/Təlim:</Body1Strong>{" "}
                    Hərəkətdə və ya xüsusi işlə əlaqəli fəaliyyətlərlə məşğul
                    olmaq.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      İctimai Bayram/Tətil/Müxtəlif Məzuniyyətlər:
                    </Body1Strong>{" "}
                    Şəxsi, sağlamlıq və ya bayram səbəbləri ilə rəsmi işdən
                    kənar.
                  </li>
                </ul>
                Uyğun iş yerləri seçimi komandanızın ünsiyyət üçün lazımi
                konteksti həyata keçirməsinə yardımçı olur, mövcudluğu və
                məxfiliyi qoruyur.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="41">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                41. TeamConnect-də 'Daxil Olundu' Vəziyyətini Naviqasiya
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                İşçilər Siyahısında 'Daxil Olundu' etiketi hazırda işdə olan
                bütün işçiləri göstərilmək üçün nəzərdə tutulmuşdur. Bu görünmə,
                seçdiyiniz İş yeri seçimindən asılıdır:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Aktiv İş Mühitləri:</Body1Strong> Ofis,
                    Saha-Ofisi, Ev-Ofisi, Uzaq-İş yeri, İctimai Yer, Müştəri İş
                    yeri, Müştəri Xüsusi-Ofisi, Nəqliyyat, İş Səfəri və Təlim
                    seçimləri sizin işlə məşğul olduğunuzu bildirir, sizin
                    'Daxil Olundu' və həmkarlarınıza görünən aktiv kimi qeyd
                    edilir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Qeyri-İş Tənzimləmələri:</Body1Strong> Çıxış,
                    Etibar, İctimai Bayram, Tətil, Məzuniyyətdə, Analıq
                    Məzuniyyəti, Xəstəlik Məzuniyyəti vəziyyətləri üçün seçim
                    sizin 'Daxil Olundu' siyahısından çıxarılmanıza yol açır,
                    mövcudluq və cavab vaxtları üçün aydın gözləntilər qoyur.
                  </li>
                </ul>
                Bu funksionallıq iş və şəxsi zamanı ayırmağa kömək edir,
                effektiv komanda əməkdaşlığı və ünsiyyətində yardımçı olur.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>
        Məlumatların İdarə Edilməsi və Məxfilik Tez-tez Soruşulan Suallar
      </Subtitle2>
      <Body1>
        <p>
          Bu günün rəqəmsal dövründə, məlumatlarınızın necə idarə edildiyini
          anlamaq vacibdir. TeamConnect şəffaflığa və möhkəm məlumat mühafizəsi
          praktikalarına öhdədir. Aşağıda, TeamConnect-də məlumatlarınızın necə
          idarə olunduğu, saxlanıldığı və qorunduğu ilə bağlı məlumatları tapa
          bilərsiniz, bu da rəqəmsal iş məkanınızın təhlükəsiz və uyğun olmasını
          təmin edir.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="42">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                42. TeamConnect şəxsi məlumatlarımı necə qoruyur?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <p>
                  TeamConnect şəxsi məlumatlarınızı məlumat təhlükəsizliyinə
                  kompleks yanaşma ilə qorumağa həsr olunmuşdur:
                </p>
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Tək Daxil Olma (SSO):</Body1Strong>{" "}
                    Təhlükəsizliyi gücləndirmək və istifadəçi təcrübəsini
                    sadələşdirmək üçün, TeamConnect Microsoft 365 hesabınızla
                    avtomatlaşdırılmış Tək Daxil Olma (SSO) inteqrasiyasını
                    həyata keçirir, təhlükəsiz və səy göstərmədən idarəetmə
                    imkanı təmin edir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Şifrələmə:</Body1Strong> TeamConnect-də olan
                    bütün məlumatların həm ötürülmə zamanı, həm də istirahət
                    zamanı şifrələndiyini təmin edirik, bunlar qeyri-icazəli
                    şəxslər tərəfindən əlçatan olmayaraq.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Məlumatların Minimumlaşdırılması:</Body1Strong>{" "}
                    Məlumat yığımımız yalnız TeamConnect'in funksionallığı üçün
                    zəruri olanla məhduddur. Bizim saxladığımız yeganə
                    istifadəçi məlumatınız Azure Entra ID-dən sizin İstifadəçi
                    Əsas Adınız (UPN) olur, bu sizin şirkət e-poçt ünvanınıza
                    oxşaya bilər.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Məhdud Giriş:</Body1Strong> UPN-ə giriş yalnız
                    təşkilatınız daxilində Abunəçi Admini tərəfindən bizim Abunə
                    portalımızda məhdudlaşdırılır, həssas məlumatın yalnız icazə
                    verilmiş şəxslər tərəfindən görünməsini təmin edir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Sürətli Məlumatların Silinməsi:</Body1Strong>{" "}
                    TeamConnect saxlanılan UPN-i üç müəyyən şərait altında
                    dərhal silməyə qurulmuşdur: əgər abunə Abunəçi Admini
                    tərəfindən ləğv edilərsə, əgər istifadəçi HR tərəfindən
                    TeamConnect-dən silinərsə, və ya əgər TeamConnect abunəsi
                    bitərsə. Bu, şəxsi məlumatlarınızın faydalı ömründən və ya
                    zərurətindən artıq saxlanmamasını təmin edir.
                  </li>
                </ul>
                <p>
                  Bu tədbirlər vasitəsilə, TeamConnect şəxsi məlumatlarınızın
                  təhlükəsiz və məxfi qalmasını təmin etmək üçün məlumat
                  mühafizəsinin ən yüksək standartlarını qorumağa öhdədir.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="43">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                43. TeamConnect ilə məlumatlarınızın harada olduğunu
                düşünürsünüz?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect, məlumatlarınızın təşkilatınızdan kənara çıxmadığını
                təmin edərək ənənəvi SaaS təkliflərinin qəlibini qırır.
                Məlumatları xarici bulud serverlərində saxlamaq adi təcrübəsinin
                əksinə olaraq, TeamConnect, bütün tətbiq məlumatlarını
                yerləşdirmək üçün təşkilatınızın Microsoft 365 SharePoint
                Online-dan ustalıqla istifadə edir. Bu yenilikçi yanaşma yalnız
                məlumat müstəqilliyini qorumaqla qalmır, həm də təhlükəsizliyi
                gücləndirir, məxfiliyinizi qorumağa və sərt uyğunluq
                standartlarına əməl etməyə dair qəti sədaqətimizlə mükəmməl
                şəkildə uyğunlaşır.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="44">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                44. Məlumatlarım üçüncü tərəflərlə bölüşüləcəkmi?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Xeyr, məlumatlar üçüncü tərəflərlə bölüşülmür! Cubeet,
                məlumatlarınızın məxfiliyi və təhlükəsizliyi ilə əlaqədar
                öhdəliyimizdə sarsılmazdır. Müştərilərimizdən açıq razılıq
                almadan TeamConnect məlumatlarını heç bir üçüncü tərəf ilə
                paylaşmırıq. Abunə portalımızda yalnız vacib identifikatorlar,
                məsələn İstifadəçi Əsas Adı (UPN), təşkilat adı, kirayəçi adı və
                kirayəçi ID-ni saxlayırıq. Bu məlumatlar xidmət göstərilməsi
                üçün vacibdir və sərt məxfilik siyasətlərimiz altında qorunur.
                Məlumatların idarə edilməsi təcrübələrimizin məlumatlarınızın
                məxfi qalmasını təmin etmək üçün dizayn edildiyinə əmin ola
                bilərsiniz, şəffaflıq və nəzarət qətiyyətlə sizin əlinizdə
                qalır.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="45">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                45. TeamConnect məlumat mühafizəsi qanunvericiliyinə, məsələn
                GDPR-ə necə uyğun gəlir?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect, GDPR, HIPAA, CCPA və digər kimi məlumat mühafizəsi
                qanunlarına, Microsoft 365 mühitinin təbii əməl etmə
                xüsusiyyətlərindən istifadə edərək uyğun gəlir, burada bütün
                müştəri məlumatları təhlükəsiz saxlanılır. Ənənəvi SaaS
                modellərinin əksinə olaraq, TeamConnect heç bir müştəri
                məlumatını öz serverlərində saxlamır; əvəzinə, o, təşkilatınızın
                Microsoft 365 mühitində işləyir, onun güclü təhlükəsizlik və
                uyğunluq standartlarına riayət edir. Bu yanaşma məlumat
                idarəçiliyinizin Microsoft 365 tərəfindən təqdim edilən geniş
                uyğunluq çərçivəsi ilə uyğun qalmasını təmin edir, o cümlədən
                GDPR-ə, səhiyyə məlumatları üçün HIPAA-ya, Kaliforniya sakinləri
                üçün CCPA-ya və digər qlobal məlumat mühafizəsi qanunlarına
                riayət etməyi də daxil edir. Mövcud Microsoft 365 quruluşunuzla
                zərifcəsinə inteqrasiya edərək, TeamConnect təşkilatınızın
                məlumatlarınız üzərində tam nəzarət saxlamasına və ən sərt
                məlumat mühafizəsi qanun və qaydalarına uyğunluğu təmin etməyə
                imkan verir.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="46">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                46. KomandaBağlantı Microsoft Teams söhbətləri, zəngləri,
                videozəngləri, Outlook elektron poçtları və telefon sistemləri
                zənglərindən olan məlumatları necə idarə edir?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                KomandaBağlantı, Microsoft Teams daxilində söhbət, zəng,
                videozəng və Outlook elektron poçtu kimi birbaşa qarşılıqlı
                əlaqələri təmin etmək üçün Microsoft Graph API-dən yararlanır,
                istifadəçi təcrübəsini zərfsiz təmin edir. Əhəmiyyətli olaraq,
                KomandaBağlantı bu ünsiyyətləri başladır, lakin ünsiyyət
                prosesinə müdaxilə etmədən və ya qarşılıqlı əlaqə məzmununu
                qeydə almaq və ya saxlamaq imkanına malik olmadan edir. Bütün
                söhbətlər, zənglər və elektron poçtlar birbaşa olaraq Microsoft
                Teams və Outlook'un təhlükəsiz mühitində baş verir və idarə
                olunur, Microsoft'un 365 güclü təhlükəsizlik və məxfilik
                standartlarına sərt uyğunluqda. Bu, KomandaBağlantının sizin
                ünsiyyət qabiliyyətlərinizi artırarkən məlumatlarınızın
                məxfiliyini və bütövlüyünü tam olaraq qoruduğunu təmin edir.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Body1>
        <p>
          Məlumat siyasətlərimiz və təcrübələrimiz haqqında ətraflı məlumatlar
          üçün{" "}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>
              KomandaBağlantı İstifadəçi Bələdçisi
            </Body1Strong>
          </Link>{" "}
          və ya təşkilatınızın İT şöbəsini{" "}
          <Link href={`mailto:${itSupport}`}>
            <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong>
          </Link>{" "}
          ilə məsləhətləşmək istəyə bilərsiniz.
        </p>
      </Body1>
      <p></p>
      <Subtitle1 className={styles.fontColor}>
        Qulaq Asırıq: Rəy və Fikirlərinizi Paylaşın
      </Subtitle1>
      <Body1>
        <p>
          Rəy və təklifləriniz KomandaBağlantı'nın inkişafını idarə edir. İster
          parlaq bir xüsusiyyət fikri ilə dolu olun, ister mövcud təcrübəniz
          haqqında rəy verin, biz bütün fikirləri dinləyirik. Rəyiniz, ən yaxşı
          xidməti təmin etmək məqsədimizdə qiymətli bir fürsətdir.
        </p>
      </Body1>
      <Subtitle2>Rəy və Xüsusiyyət Təklifləri</Subtitle2>
      <Body1>
        <p>
          Paylaşmağa hazırsınız? Bizim{" "}
          <Link
            style={{ fontWeight: 600 }}
            href={feebackLink}
            target='_blank'>
            Rəy Səhifəmizə
          </Link>
          {" "}keçin. Yeni bir xüsusiyyət təklifi etmək və ya mövcud funksionallıqlar
          haqqında rəy vermək, formda uyğun seçimi sadəcə seçin. Sizin
          töhfələriniz KomandaBağlantı'nın gələcəyini formalaşdırmağa kömək
          edir, ehtiyaclarınızı qarşılamağı və aşmağı təmin edir.
        </p>
      </Body1>
    </>
  );
}

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};
export default Container;
