import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>
        Centro de Ajuda e Perguntas Frequentes do TeamConnect
      </Title3>
      <p>
        <Body1>
          Bem-vindo ao Centro de Ajuda do TeamConnect, projetado para apoiar a
          sua experiência com nosso aplicativo abrangente de diretório
          corporativo. Aqui, você encontrará recursos para otimizar o uso do
          TeamConnect dentro do Microsoft Teams, melhorando a comunicação em sua
          rede.
        </Body1>
      </p>
      <Subtitle2>Guia de Referência Rápida</Subtitle2>
      <ul>
        <Body1>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Capacidades de Pesquisa Avançadas: </Body1Strong>
            Encontre os colegas, departamentos e contatos essenciais rapidamente
            com nossas poderosas ferramentas de busca.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Organogramas Visuais: </Body1Strong>
            Navegue pela hierarquia da sua organização e compreenda as linhas de
            reporte com facilidade através de organogramas intuitivos.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>
              Ferramentas Adaptáveis às Necessidades de Hoje:{" "}
            </Body1Strong>
            Utilize funcionalidades modernas adaptadas às demandas dos ambientes
            de trabalho contemporâneos.
          </li>
        </Body1>
      </ul>
      <p></p>
      <Subtitle2>Otimizando Sua Experiência</Subtitle2>
      <Body1>
        <p>
          O TeamConnect revoluciona a forma como você interage dentro da sua
          estrutura organizacional, transformando as comunicações diárias em
          engajamentos estratégicos.
        </p>
      </Body1>
      <p></p>
      <Subtitle1 className={styles.fontColor}>
        Obter Ajuda & Perguntas Frequentes
      </Subtitle1>
      <Body1>
        <p>
          Encontre respostas para perguntas comuns sobre os recursos e
          configurações do TeamConnect nesta seção dedicada. Se você está
          navegando no aplicativo, personalizando configurações ou procurando
          soluções para desafios, nosso Centro de Ajuda está aqui para ajudá-lo
          de maneira eficiente.
        </p>
      </Body1>
      <Body1>
        <p>
          Para suporte mais detalhado ou orientação específica, consulte o{" "}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>
              Guia do Usuário do TeamConnect
            </Body1Strong>
          </Link>
          .
        </p>
      </Body1>
      <p></p>
      <Subtitle2>Usando as Perguntas Frequentes do TeamConnect</Subtitle2>
      <p></p>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="1">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                1. Não consegue encontrar o TeamConnect na barra lateral do
                Microsoft Teams?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Se o TeamConnect não está visível ou desapareceu na sua barra
                lateral do Microsoft Teams, você pode fixá-lo facilmente para
                acesso rápido. Basta seguir estes passos simples:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Abra o Microsoft Teams e clique no ícone '...' (+
                    Aplicativos) na parte inferior da barra lateral.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Procure por 'TeamConnect' na barra de pesquisa no topo da
                    janela pop-up.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Clique com o botão direito no aplicativo TeamConnect dos
                    resultados da pesquisa e selecione 'Fixar'.
                  </li>
                </ol>
                O TeamConnect agora estará fixado na sua barra lateral,
                tornando-o acessível com apenas um clique!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="2">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                2. Encontrando problemas técnicos com o TeamConnect? Veja como
                obter ajuda.
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Se você encontrar qualquer problema técnico com o TeamConnect
                dentro do Microsoft Teams, a equipe de Suporte de TI da sua
                organização está aqui para ajudar. Entre em contato com eles em{" "}
                <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>
                , e eles lhe ajudarão prontamente.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="3">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                3. Não está vendo os detalhes do seu funcionário?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Se o seu perfil parece um pouco vazio apenas com seu nome e
                e-mail visíveis, é provável que sua equipe de RH ainda não tenha
                adicionado o restante dos seus detalhes de funcionário no
                TeamConnect. Não precisa se preocupar! Uma mensagem rápida para
                sua equipe de suporte de RH colocará as coisas em movimento.
                Eles estão de prontidão para preencher seu perfil com todos os
                detalhes importantes. Entre em contato em{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>{" "}
                e veja seu perfil ganhar vida!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="4">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                4. Quais opções de idiomas estão disponíveis no TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                O TeamConnect abraça a diversidade global do Microsoft Teams,
                suportando todos os seus idiomas. Seja preferindo Árabe ou
                Grego, Japonês ou Sueco, você tem um mundo de opções à sua
                disposição, incluindo:
              </Body1>
              <ul>
                <li>
                  <Body1>
                    Árabe, Búlgaro, Chinês (Simplificado e Tradicional), Croata,
                    Tcheco, Dinamarquês, Holandês, Inglês, Estoniano, Finlandês,
                    Francês, Alemão, Grego, Hebraico, Húngaro, Indonésio,
                    Italiano, Japonês, Coreano, Letão, Lituano, Norueguês
                    (Bokmål), Polonês, Português (Brasil e Portugal), Romeno,
                    Russo, Sérvio (Latino), Eslovaco, Esloveno, Espanhol, Sueco,
                    Tailandês, Turco, Ucraniano, Vietnamita.
                  </Body1>
                </li>
              </ul>
              <Body1>
                Para personalizar sua experiência com o TeamConnect para o seu
                idioma preferido, ajuste as configurações de idioma diretamente
                no Microsoft Teams, já que o TeamConnect integra-se totalmente a
                essas configurações:
              </Body1>
              <ol>
                <Body1>
                  <li className={styles.bulletBottomMargin}>
                    Acesse as configurações do seu Microsoft Teams clicando nos
                    três pontos ao lado da sua foto de perfil no topo e, em
                    seguida, selecione 'Aparência e acessibilidade'.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Desça até a seção 'Idioma' e selecione seu idioma preferido
                    no menu suspenso.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Confirme suas alterações clicando no botão 'Salvar e
                    reiniciar' para aplicar as novas configurações de idioma.
                  </li>
                </Body1>
              </ol>
              <Body1Strong>Nota: </Body1Strong>
              <Body1>
                As alterações feitas nas configurações de idioma no Microsoft
                Teams também atualizarão sua experiência com o TeamConnect de
                forma contínua. Esteja ciente de que alterar o idioma no Teams
                pode também ajustar seu formato de data e hora. Atualmente, o
                TeamConnect está evoluindo para suportar a escrita da direita
                para a esquerda. Estamos entusiasmados com esses aprimoramentos
                e manteremos você informado conforme eles estiverem disponíveis.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="5">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                5. Como posso alterar o tema no TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                O TeamConnect sincroniza com suas preferências de tema do
                Microsoft Teams, incluindo as opções Claro, Escuro e Alto
                Contraste. Para personalizar seu tema:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Acesse as configurações do seu Microsoft Teams clicando nos
                    três pontos ao lado da sua foto de perfil no topo.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Selecione 'Aparência e acessibilidade' para explorar as
                    opções de tema.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Escolha o tema de sua preferência dentre as opções
                    disponíveis para atualizar instantaneamente a interface do
                    seu TeamConnect.
                  </li>
                </ol>
                A escolha do tema no Microsoft Teams será aplicada
                automaticamente ao TeamConnect, garantindo uma experiência
                visual consistente em todo o seu espaço de trabalho.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="6">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                6. Explorando os Botões de Ação de Contato do TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Em todo o TeamConnect, onde você encontrar os detalhes de
                contato de um funcionário, também descobrirá nossos botões de
                acesso rápido convenientes. Essas ferramentas úteis são
                projetadas para otimizar sua comunicação dentro do ecossistema
                da Microsoft, garantindo que você esteja sempre a apenas um
                clique de se conectar. Aqui está seu guia completo para utilizar
                esses botões de forma eficaz:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ícone de Chat:</Body1Strong> Clique para
                    iniciar um chat instantâneo do Microsoft Teams. Se precisar
                    voltar ao TeamConnect após iniciar um chat, simplesmente use
                    a seta para trás no Aplicativo de Desktop, o botão voltar no
                    Aplicativo Móvel, ou o botão de voltar do seu navegador.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ícone de Email:</Body1Strong> Abre um novo
                    rascunho de email do Outlook com o endereço do destinatário
                    já no campo "Para:". Caso o Outlook não seja iniciado, vale
                    a pena verificar com seu departamento de TI se há alguma
                    restrição.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ícone de Chamada de Vídeo:</Body1Strong> Inicia
                    uma chamada de vídeo face a face usando o Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ícone de Chamada:</Body1Strong> Inicia uma
                    chamada de voz do Microsoft Teams, facilitando conversas
                    rápidas.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ícone de Organograma:</Body1Strong> Revela o
                    lugar do funcionário na hierarquia organizacional, incluindo
                    seu gerente e subordinados diretos.
                  </li>
                  <li>
                    <Body1Strong>Mais (...) Ícone:</Body1Strong> Revela mais
                    opções:
                    <ul>
                      <li
                        style={{ marginTop: "8px" }}
                        className={styles.bulletBottomMargin}
                      >
                        <Body1Strong>Reunião Agora:</Body1Strong> Abre o
                        Microsoft Teams para iniciar uma reunião imediata com o
                        funcionário.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Nova Reunião:</Body1Strong> Direciona você
                        ao Microsoft Teams para agendar uma nova reunião.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Adicionar Contato:</Body1Strong> Adiciona
                        os detalhes de contato do funcionário do TeamConnect ao
                        seu novo aplicativo Pessoas do Microsoft Teams,
                        enriquecendo sua função de chamada.
                      </li>
                    </ul>
                  </li>
                </ul>
                Esses botões intuitivos são criados para garantir que você possa
                se conectar eficientemente com os colegas, seja para um
                bate-papo rápido, um email ou para agendar reuniões futuras.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="7">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                7. Fazendo chamadas via TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Procurando fazer uma chamada? O TeamConnect otimiza sua
                comunicação integrando-se ao Microsoft Teams. Basta clicar no
                número de telefone desejado no TeamConnect, e voilà, a interface
                de chamadas do Microsoft Teams aparece, pronta para você discar
                de forma transparente.
                <p>
                  <Body1Strong>Nota: </Body1Strong>
                  Encontrando problemas para fazer chamadas? Isso pode ser
                  devido às políticas de TI da sua organização ou ao uso de um
                  sistema telefônico que não seja o Microsoft Teams Phone
                  System. Consultar seu departamento de TI em{" "}
                  <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>
                      {itSupport}
                    </Body1Strong>
                  </Link>
                  , pode esclarecer a questão.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="8">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                8. Existem custos associados a fazer chamadas via TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                O TeamConnect aprimora sua experiência de chamada aproveitando o
                Microsoft Teams. Aqui está o que você precisa saber sobre os
                custos relacionados a chamadas:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    Para chamadas para telefones de trabalho dos funcionários,
                    telefones do departamento ou linhas diretas dentro de sua
                    organização (números internos), geralmente{" "}
                    <Body1Strong>não há custos</Body1Strong> ao usar o Sistema
                    de Telefone do Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Para chamadas externas, como aquelas para telefones móveis
                    ou linhas externas,{" "}
                    <Body1Strong>tarifas padrão</Body1Strong> do sistema de
                    telefonia de sua organização se aplicam. Essas tarifas
                    variam baseadas no seu plano de serviço específico com o
                    Microsoft Teams.
                  </li>
                </ul>
                <p>
                  <Body1Strong>Nota:</Body1Strong> Se você encontrar algum
                  problema ou tiver perguntas sobre as cobranças de chamadas, é
                  melhor consultar o seu departamento de TI em{" "}
                  <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>
                      {itSupport}
                    </Body1Strong>
                  </Link>{" "}
                  para informações detalhadas adaptadas à configuração e
                  políticas de sua organização.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="9">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                9. Notou uma discrepância com o seu status de presença 'Fora do
                Escritório' no TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Percebeu que o seu status de presença 'Fora do Escritório' do
                Microsoft 365 não está refletindo corretamente no TeamConnect?
                Nós te protegemos. A funcionalidade de sincronizar o status de
                presença 'Fora do Escritório' já foi implementada no
                TeamConnect. Estamos atualmente aguardando a implementação dessa
                funcionalidade pela Microsoft. Uma vez que isso aconteça, seu
                status de presença 'Fora do Escritório' será refletido com
                precisão no TeamConnect, alinhando-se com os indicadores de
                presença do Microsoft Teams para uma experiência integrada.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Perguntas Frequentes do Diretório de Funcionários</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Introdução ao Diretório de Funcionários:</Body1Strong>{" "}
          Navegue pela paisagem profissional de sua organização com o Diretório
          de Funcionários, seu hub central para insights detalhados de
          funcionários, dinâmicas de trabalho e conectividade direta aos perfis
          e relações gerenciais de seus colegas.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="10">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                10. Precisa adicionar, atualizar ou corrigir seus dados de
                funcionário?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Para adicionar, atualizar ou corrigir seus dados de funcionário
                no TeamConnect, entre em contato com a equipe de suporte de RH
                de sua organização. Eles estão prontos para ajudar em{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>
                .
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="11">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                11. O que acontece quando eu clico no nome de um funcionário no
                diretório?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Clicar no nome de um funcionário no Diretório de Funcionários do
                TeamConnect abre uma página de perfil detalhada para esse
                indivíduo. Este perfil fornece informações abrangentes sobre o
                funcionário, incluindo, mas não se limitando a, seu cargo,
                departamento, localização do escritório, informações de contato
                e mais. Ele é projetado para fornecer uma visão completa sobre o
                papel profissional do funcionário e como ele se encaixa na
                organização, facilitando para você entender suas
                responsabilidades e como você pode colaborar com ele.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="12">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                12. Que informações estão incluídas no perfil de um funcionário?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Cada perfil de funcionário no TeamConnect é um resumo
                abrangente, oferecendo uma riqueza de informações sobre a vida
                profissional do indivíduo. Aqui está o que você encontrará:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Detalhes Básicos:</Body1Strong> Nome, cargo,
                    departamento e localização da subsidiária.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Estrutura de Reporte:</Body1Strong> Informações
                    sobre relatórios diretos e funcionais, incluindo gerentes de
                    linha e funcionais.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Status no Trabalho:</Body1Strong> Status atual
                    de trabalho como de Licença, em Deslocamento, em Viagem de
                    Negócios, etc.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Local de Trabalho & Hora Local:</Body1Strong>{" "}
                    Localização de trabalho atual do funcionário e fuso horário
                    local.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Detalhes de Contato:</Body1Strong> Telefone do
                    trabalho, número de celular e endereço de email.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Endereço do Escritório:</Body1Strong>{" "}
                    Localização completa do escritório incluindo nome do prédio,
                    endereço e mapa do Google.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Sobre:</Body1Strong> Uma breve introdução
                    pessoal ou biografia profissional.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Relações de Trabalho:</Body1Strong> Detalhes da
                    estrutura de reporte do funcionário e relações com a equipe.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Links Sociais & Idiomas:</Body1Strong> Links
                    para perfis sociais profissionais e proficiências
                    linguísticas.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Habilidades:</Body1Strong> Uma lista de
                    habilidades profissionais e áreas de especialização.
                  </li>
                </ul>
                Além disso, o perfil oferece opções práticas para conectar-se
                via chat, email, chamada de vídeo ou agendar reuniões, junto com
                acesso rápido ao organograma da organização para um contexto
                mais amplo da equipe.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="13">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                13. Como posso atualizar as informações do meu perfil?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Personalizar seu perfil é fácil no TeamConnect. Veja como você
                pode fazer isso:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Navegue até a aba <Body1Strong>Employee</Body1Strong> e
                    clique no seu <Body1Strong>Avatar</Body1Strong> para abrir
                    seu perfil.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    No canto superior esquerdo, você encontrará o link{" "}
                    <Body1Strong>"Editar seu perfil"</Body1Strong>. Clicar neste
                    link abrirá o diálogo de edição para o seu perfil.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Aqui, você pode aprimorar seu perfil adicionando ou
                    atualizando a seção <Body1Strong>About You</Body1Strong>,
                    links de mídias sociais e número de telefone. Isso facilita
                    para que seus colegas se conectem ou sigam você.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Não se esqueça de pressionar o botão{" "}
                    <Body1Strong>Save</Body1Strong> para aplicar suas mudanças.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Desça na caixa de diálogo para adicionar seus{" "}
                    <Body1Strong>Languages</Body1Strong> e{" "}
                    <Body1Strong>Proficiency</Body1Strong>, além de suas{" "}
                    <Body1Strong>Skills</Body1Strong>. Compartilhar essas
                    informações ajuda seus colegas a entender suas áreas de
                    especialização e os idiomas em que você pode se comunicar.
                  </li>
                </ol>
                Atualizar seu perfil não só mantém suas informações atualizadas,
                mas também promove melhores conexões dentro da sua organização.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="14">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                14. Atualizando seu Local de Trabalho, Lugar de Trabalho e Fuso
                Horário no TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Manter as informações do seu local de trabalho, lugar de
                trabalho e fuso horário atualizadas é crucial para uma
                colaboração contínua no Microsoft Teams. Essas atualizações são
                refletidas imediatamente em seu perfil e listagens do
                TeamConnect, permitindo que os colegas entendam rapidamente seu
                local de trabalho e os melhores horários para se conectar com
                você, melhorando o trabalho em equipe entre diferentes regiões e
                fusos horários.
                <p></p>
                Pronto para atualizar seus detalhes? É fácil no TeamConnect!
                Acesse 'Settings' no Menu superior e navegue até 'General
                Settings'. Aqui, você pode atualizar sem problemas seu Local de
                Trabalho e Fuso Horário, e especificar seu Lugar de Trabalho
                para o dia. Lembre-se de salvar suas mudanças para manter todos
                informados e garantir uma colaboração eficaz, não importa sua
                localização!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="15">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                15. Notou algo estranho no perfil de um colega?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Se você encontrar detalhes no perfil de um colega que não
                parecem corretos, o melhor primeiro passo é dar uma dica
                amigável. Uma rápida conversa ou e-mail deve resolver! Se por
                algum motivo a informação não for atualizada, não se preocupe —
                apenas contate sua equipe de suporte de RH em{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>
                , e eles ficarão felizes em entrar e resolver a situação.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="16">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                16. Entendendo os Grupos de Etiquetas: Todos, Favoritos,
                Presentes e Novos Empregados
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Todos:</Body1Strong> Esta etiqueta padrão exibe
                    todos os funcionários da sua organização, proporcionando uma
                    visão abrangente da equipe.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Favoritos:</Body1Strong> Clique aqui para
                    filtrar sua visualização para aqueles colegas que você
                    marcou como 'Favoritos', facilitando a rápida conexão com
                    contatos-chave.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Presentes:</Body1Strong> Esta etiqueta indica
                    os funcionários que estão ativamente no trabalho,
                    distinguindo da mera presença online no Microsoft Teams.
                    Quando registrados como presentes, os funcionários sinalizam
                    que estão no trabalho e compartilham seu local de trabalho
                    atual durante o dia. Isso pode incluir uma variedade de
                    locais de trabalho, como Escritório, Escritório-Campo,
                    Home-Office, Local de Trabalho Remoto, Local de Trabalho do
                    Cliente, Escritório Privado do Cliente, Área Pública,
                    Deslocamento, Viagem de Negócios ou até Treinamento.
                    Portanto, o status 'Presente' fornece uma indicação clara de
                    estar ativamente no trabalho, oferecendo aos colegas uma
                    compreensão precisa do contexto de trabalho atual e da
                    disponibilidade de cada um.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Novos Empregados:</Body1Strong> Identifique
                    novos rostos no TeamConnect com esta etiqueta, destacando
                    funcionários que foram adicionados ao aplicativo nos últimos
                    30 dias. Tipicamente, isso corresponde às adições recentes à
                    sua organização, ajudando a dar boas-vindas aos novos
                    membros da equipe.
                  </li>
                </ul>
                Esses grupos de etiquetas são projetados para simplificar a
                navegação e aprimorar sua experiência com o TeamConnect,
                garantindo que você sempre possa encontrar e se conectar com
                seus colegas de forma eficiente.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="17">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                17. Decodificando os Ícones ao Lado da Caixa de Busca
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                O Diretório de Funcionários do TeamConnect é projetado para
                versatilidade e facilidade, apresentando quatro ícones que
                oferecem diferentes visualizações e acesso rápido aos detalhes
                principais. Aqui está o que cada ícone revela:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Visualização em Lista:</Body1Strong> Sua
                    visualização principal, apresentando todos os membros da
                    organização de forma direta.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Visualização em Cartão Pequeno:</Body1Strong>{" "}
                    Uma alternativa compacta, esta visualização transforma a
                    lista em cartões de funcionários em tamanho reduzido.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Visualização em Cartão Grande:</Body1Strong>{" "}
                    Opte por mais detalhes com cartões maiores, que incluem
                    opções de contato adicionais, como números de telefone e
                    celular para discagem fácil.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Organograma:</Body1Strong> Visualize a
                    estrutura da empresa com a visualização do Organograma,
                    perfeito para entender as relações de reporte e navegar pela
                    paisagem organizacional.
                  </li>
                </ol>
                Alterne entre esses ícones para encontrar a visualização que
                melhor atende às suas necessidades no momento, seja buscando
                informações rápidas ou insights mais profundos.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="18">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                18. Para buscas rápidas de colegas, onde devo olhar?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Procurando um colega? Basta dirigir-se à caixa de busca do
                TeamConnect, localizada no lado superior direito ao lado do
                ícone do Organograma. Comece a digitar o nome deles e observe os
                resultados serem refinados a cada letra. Lembre-se, para buscas
                no TeamConnect, evite a barra de busca do Microsoft Teams no
                topo — nossa caixa de busca especial é o caminho a seguir!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="19">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                19. Como funciona a busca alfabética para encontrar
                funcionários?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                O Diretório de Funcionários do TeamConnect inclui um recurso
                prático de busca alfabética para ajudá-lo a localizar
                funcionários rapidamente pelo primeiro nome. Basta clicar em uma
                letra do alfabeto exibida no topo do diretório, e o TeamConnect
                imediatamente mostrará todos os funcionários cujos primeiros
                nomes começam com essa letra. Para retornar à lista completa de
                funcionários, basta desmarcar a letra destacada. Esta função
                intuitiva facilita a navegação por grandes listas de
                funcionários, garantindo que você possa encontrar colegas de
                forma rápida e eficiente.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="20">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                20. Como posso ajustar o número de funcionários exibidos por
                página?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                O TeamConnect permite personalizar quantos perfis de
                funcionários você visualiza por página, facilitando a navegação
                pelo Diretório de Funcionários. No final de cada página, você
                encontrará opções de paginação: 10, 50, 100, 500, 1000 e 5000. A
                configuração padrão mostra 10 perfis por página. Para ver mais
                perfis em uma única página, basta selecionar o número preferido
                nessas opções. Este recurso oferece flexibilidade na forma como
                você navega pelo diretório, permitindo que você adapte a
                exibição às suas necessidades ou preferências específicas.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="21">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                21. Como eu faço para ordenar as informações dos funcionários na
                Visualização de Lista?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Na Visualização de Lista do Diretório de Funcionários do
                TeamConnect, você tem a flexibilidade de ordenar as informações
                dos funcionários de acordo com suas necessidades. As
                colunas—Nome do Funcionário, Cargo, Departamento, Localização do
                Escritório e Local de Trabalho—são todas ordenáveis. Basta
                clicar no cabeçalho da coluna pela qual deseja ordenar. Clicar
                uma vez organizará a coluna em ordem ascendente, e clicar
                novamente ordenará em ordem descendente. Por padrão, o diretório
                é ordenado por Nome do Funcionário em ordem ascendente, baseado
                no primeiro nome. Este recurso de ordenação ajuda você a
                organizar e visualizar rapidamente os detalhes dos funcionários
                de uma maneira que melhor atenda ao seu propósito, seja
                procurando alguém específico ou precisando agrupar funcionários
                por um critério particular como departamento ou localização.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Perguntas Frequentes do Diretório de Departamentos</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Introdução ao Diretório de Departamentos:</Body1Strong> O
          Diretório de Departamentos é sua porta de entrada para entender a
          complexa rede da estrutura da sua organização, oferecendo uma visão
          clara das hierarquias departamentais, liderança gerencial e vias para
          uma comunicação departamental sem atritos.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="22">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                22. Como funcionam as etiquetas 'Todos', 'Favoritos' e 'Seu
                Departamento' no Diretório de Departamentos?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                No Diretório de Departamentos do TeamConnect, as etiquetas
                ajudam você a navegar e filtrar rapidamente as informações
                departamentais. Aqui está o que cada etiqueta representa:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Todos:</Body1Strong> Esta etiqueta exibe todos
                    os departamentos dentro da sua organização, proporcionando
                    uma visão geral abrangente.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Favoritos:</Body1Strong> Use esta etiqueta para
                    ver uma lista selecionada de departamentos que você marcou
                    como 'Favoritos', facilitando o acesso aos departamentos com
                    os quais você interage frequentemente.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Seu Departamento:</Body1Strong> Esta etiqueta
                    filtra a visualização para mostrar apenas o seu
                    departamento, permitindo rápido acesso às informações e
                    atualizações do seu departamento imediato.
                  </li>
                </ul>
                Essas etiquetas são projetadas para simplificar sua navegação
                dentro do Diretório de Departamentos, melhorando sua capacidade
                de encontrar e interagir com informações departamentais de
                maneira eficiente.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="23">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                23. Compreendendo as Vistas do Diretório de Departamentos no
                TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Navegar pelo Diretório de Departamentos no TeamConnect é
                simplificado com múltiplas opções de visualização para atender
                às suas preferências. Cada visualização oferece uma maneira
                única de explorar informações departamentais abrangentes,
                incluindo Nome do Departamento, Gerente do Departamento,
                Departamento Principal, Telefone do Departamento, Linha Direta
                do Departamento e Email do Departamento. Aqui está um resumo das
                visualizações disponíveis:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Visualização em Lista:</Body1Strong> A
                    configuração padrão que lista todos os departamentos de
                    forma organizada, fornecendo uma visão clara e concisa.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Visualização em Cartão Grande:</Body1Strong>{" "}
                    Mude para esta visualização para ver cartões detalhados dos
                    departamentos, oferecendo um resumo expandido das principais
                    informações de cada departamento.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Visualização em Diagrama Organizacional:
                    </Body1Strong>{" "}
                    Para uma representação visual da estrutura departamental e
                    relações, a visualização em Diagrama Organizacional ilustra
                    como cada departamento se encaixa na hierarquia
                    organizacional maior.
                  </li>
                </ul>
                Para alternar entre essas visualizações, basta usar os ícones ao
                lado da caixa de pesquisa no topo do Diretório de Departamentos.
                Cada visualização é projetada para ajudá-lo a acessar e
                compreender detalhes departamentais com facilidade, garantindo
                que você encontre as informações necessárias no formato que mais
                lhe convier.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="24">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                24. Como Utilizar a Caixa de Pesquisa no Diretório do
                Departamento
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Localize facilmente qualquer departamento dentro do TeamConnect
                usando a caixa de pesquisa dedicada situada logo ao lado dos
                ícones de visualização no topo do Diretório do Departamento.
                Basta começar a digitar o nome do departamento que está
                procurando e observe enquanto o diretório se refina para mostrar
                apenas correspondências relevantes. Esta funcionalidade é
                projetada especificamente para ajudá-lo a evitar a necessidade
                de vasculhar manualmente todo o diretório, trazendo o
                departamento que você precisa diretamente para a ponta dos seus
                dedos. Lembre-se, essa funcionalidade de pesquisa é única do
                Diretório do Departamento do TeamConnect, então certifique-se de
                usar esta caixa de pesquisa em vez da pesquisa principal do
                Microsoft Teams no topo da interface do Teams para consultas
                específicas do departamento.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="25">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                25. Como funciona o filtro alfabético no Diretório do
                Departamento?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Agilize sua pesquisa no Diretório do Departamento com o filtro
                alfabético localizado no topo. Este recurso permite que você
                refine rapidamente os departamentos selecionando uma letra. Uma
                vez que você escolha uma letra, o TeamConnect filtra e exibe
                apenas aqueles departamentos cujos nomes começam com a letra
                selecionada, facilitando a localização do que você está
                procurando sem ter que rolar por toda a lista. Para retornar à
                visualização completa do departamento, basta desmarcar a letra
                destacada. Esta navegação alfabética intuitiva garante que você
                possa localizar eficientemente qualquer departamento pela sua
                letra inicial.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="26">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                26. Como posso entrar em contato com o gerente do departamento?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Entrar em contato com um gerente de departamento no TeamConnect
                é direto. Basta localizar o departamento de interesse e clicar
                no nome do gerente. Esta ação acionará um popover exibindo as
                opções de contato do gerente com os ícones de acesso rápido
                familiares:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ícone de Chat:</Body1Strong> Para iniciar uma
                    mensagem instantânea pelo chat do Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ícone de Email:</Body1Strong> Para abrir um
                    novo e-mail endereçado a eles no Microsoft Outlook.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ícone de Chamada de Vídeo:</Body1Strong> Para
                    iniciar uma chamada de vídeo pelo Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ícone de Chamada:</Body1Strong> Para iniciar
                    uma chamada de voz usando o Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ícone Mais (...):</Body1Strong> Para opções
                    adicionais como agendar uma reunião ou adicionar suas
                    informações de contato à sua lista do Teams.
                  </li>
                </ul>
                Estes ícones fornecem um método fácil para entrar em contato
                diretamente com os gerentes de departamento dentro do
                TeamConnect, garantindo uma comunicação eficiente dentro de sua
                organização.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="27">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                27. Como posso visualizar ou contactar funcionários dentro de um
                departamento?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Para explorar quem é quem dentro de um departamento no
                TeamConnect, não procure além dos três pontos ao lado do nome do
                departamento. Clicar nisso irá trazer opções para mais ações:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Visualizar Funcionários Neste Departamento:
                    </Body1Strong>{" "}
                    Selecione isso para exibir uma lista de todos os
                    funcionários associados ao departamento. O número ao lado
                    desta opção indica quantos funcionários estão no
                    departamento, fornecendo uma contagem rápida.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Organograma do Departamento:</Body1Strong> Se
                    você deseja entender a hierarquia do departamento, escolha
                    'Organograma do departamento' para revelar o layout
                    estrutural, mostrando a relação entre departamentos
                    parentes, o departamento em questão, e quaisquer
                    subdepartamentos. Esta representação visual ajuda a navegar
                    e entender o quadro organizacional, facilitando a
                    identificação e contato de membros específicos do
                    departamento.
                  </li>
                  <li>
                    <Body1Strong>Departamento {">"} Funcionário:</Body1Strong>{" "}
                    Após visualizar os funcionários dentro de um departamento,
                    use este caminho de navegação para facilmente voltar à lista
                    de departamentos, mantendo uma experiência de exploração
                    suave no TeamConnect.
                  </li>
                </ul>
                Estes recursos permitem que você não apenas veja a lista de
                funcionários em um determinado departamento, mas também oferecem
                um caminho para entrar em contato com eles diretamente através
                das ferramentas de comunicação do TeamConnect.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="28">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                28. Descobrindo Detalhes Profundos dos Departamentos no
                TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mergulhe nos detalhes de qualquer departamento com um simples
                clique em seu nome no TeamConnect. Um diálogo detalhado se
                desdobra, oferecendo um rico panorama de informações que pinta
                um quadro completo do papel e dos recursos do departamento
                dentro de sua organização. Você encontrará tudo, desde liderança
                e identificadores financeiros até canais de comunicação e links
                sociais. Este recurso é o seu portal para:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    O coração do departamento – seu propósito, pessoas e
                    métricas de desempenho.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Vias de contato – linhas diretas para figuras-chave do
                    departamento e plataformas de comunicação.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    A teia organizacional – entendendo como o departamento se
                    integra com a estrutura mais ampla da empresa.
                  </li>
                </ul>
                Seja para uma consulta rápida ou um mergulho profundo nos dados
                do departamento, o TeamConnect equipa você com as ferramentas
                para se conectar e compreender os ecossistemas únicos dentro de
                sua organização.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="29">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                29. Curioso Sobre a Ausência de Endereços de Departamentos no
                TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Descobrir a localização de um departamento às vezes pode parecer
                uma caça ao tesouro sem mapa. No TeamConnect, você normalmente
                não encontrará um endereço dedicado para cada departamento. Aqui
                está o porquê deste detalhe cartográfico ser frequentemente
                omitido:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Várias Localizações:</Body1Strong> Muitos
                    departamentos não estão confinados a um único espaço; eles
                    prosperam em vários escritórios e geografias.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Estrutura Fluida:</Body1Strong> A paisagem
                    organizacional está sempre mudando, com departamentos
                    evoluindo, fundindo-se ou até dissolvendo-se em alinhamento
                    com mudanças estratégicas.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Colaboração Interdepartamental:</Body1Strong>{" "}
                    Os espaços de trabalho modernos vibram com espírito
                    colaborativo, frequentemente mesclando talentos de vários
                    departamentos dentro de espaços compartilhados.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Cultura de Trabalho Remoto:</Body1Strong> Com a
                    flexibilidade do trabalho remoto, âncoras departamentais não
                    são sempre lançadas em localizações físicas de escritórios.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Nuances Legais:</Body1Strong> Os departamentos
                    geralmente orbitam em torno da estrutura legal da empresa,
                    em vez de possuírem seus próprios endereços autônomos.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Otimização Operacional:</Body1Strong> Manter o
                    foco no trabalho em equipe dinâmico, não em endereços
                    estáticos, ajuda a manter a agilidade operacional.
                  </li>
                </ul>
                Tudo se trata de adotar uma abordagem sem fronteiras para o
                trabalho em equipe e eficiência. O TeamConnect reflete essa
                filosofia, enfatizando conexões em vez de localizações.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="30">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                30. As informações do seu departamento estão ausentes ou
                desatualizadas?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Editores de departamento são designados dentro da sua
                organização para adicionar, atualizar ou corrigir informações
                específicas do departamento no TeamConnect. Se você não tem
                certeza de quem é o seu editor de departamento ou se certas
                informações requerem a intervenção da equipe de suporte de RH,
                por favor, contate{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>{" "}
                para orientação.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Perguntas Frequentes do Diretório de Escritórios</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Introdução ao Diretório de Escritórios:</Body1Strong>{" "}
          Descubra a espinha dorsal da presença física da sua organização
          através do Diretório de Escritórios, que inclui detalhes essenciais
          dos escritórios, especificidades operacionais e uma visão abrangente
          dos departamentos e pessoal que definem cada local.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="31">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                31. Navegando pelas Tags no Diretório de Escritórios
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Navegue pelo Diretório de Escritórios com facilidade usando
                nosso sistema de tags projetado para acesso rápido ao local
                certo. Aqui está como cada tag pode ajudar a otimizar sua busca:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Todos:</Body1Strong> Apresenta uma listagem
                    completa dos escritórios, oferecendo uma visão panorâmica
                    das localizações da sua organização.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Favoritos:</Body1Strong> Ajuda a se concentrar
                    naqueles escritórios que você visita frequentemente ou
                    precisa monitorar de perto, marcando-os como 'Favoritos'.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Seu Escritório:</Body1Strong> Esta tag pessoal
                    filtra o diretório para mostrar o local do escritório onde
                    você está estacionado, mantendo sua base ao alcance das
                    mãos.
                  </li>
                </ul>
                Se você está reservando uma sala de reuniões ou planejando uma
                visita, essas tags oferecem uma maneira personalizada e
                simplificada de interagir com a paisagem geográfica da sua
                organização.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="32">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                32. Dominando as Visualizações do Diretório de Escritórios no
                TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mergulhe na disposição organizacional das diversas localizações
                da sua empresa com as visualizações do Diretório de Escritórios
                do TeamConnect. Cada visualização é criada para facilitar um
                aspecto único de navegação e entendimento do escritório:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Visualização em Lista:</Body1Strong> O ajuste
                    padrão que organiza todas as localizações de escritórios de
                    modo fácil de ler.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Visualização de Cartão Grande:</Body1Strong> A
                    Visualização de Cartão Grande no Diretório de Escritórios
                    enriquece sua visualização com uma apresentação detalhada de
                    cada escritório. Ao interagir com um cartão de escritório,
                    você encontrará uma riqueza de informações e opções
                    acionáveis dentro do menu "Mais (...)" :
                    <ol>
                      <li
                        style={{ marginTop: "8px" }}
                        className={styles.bulletBottomMargin}
                      >
                        <Body1Strong>Ver Departamentos:</Body1Strong> Navegue
                        diretamente para uma lista de todos os departamentos
                        situados dentro do escritório.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Ver Empregados:</Body1Strong> Acesse a
                        lista completa de funcionários atribuídos ao escritório,
                        facilitando o contato direto ou a exploração do pessoal
                        do escritório.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>
                          Organograma da Entidade Corporativa:
                        </Body1Strong>{" "}
                        Visualize o lugar do escritório dentro do contexto
                        organizacional mais amplo para um entendimento claro das
                        estruturas hierárquicas.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Contatos Essenciais:</Body1Strong>{" "}
                        Identifique contatos chave do escritório para
                        comunicação simplificada.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>
                          Feriados Públicos do Escritório:
                        </Body1Strong>{" "}
                        Mantenha-se informado sobre os feriados públicos
                        específicos do escritório para planejar seus horários de
                        acordo.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>
                          Horário de Funcionamento do Escritório:
                        </Body1Strong>{" "}
                        Veja os horários de funcionamento do escritório para
                        alinhar suas visitas ou chamadas com seus tempos ativos.
                      </li>
                    </ol>
                    Esta visualização fornece não apenas uma fotografia visual e
                    informativa, mas também acesso rápido a insights mais
                    profundos e ações diretas relacionadas ao escritório, tudo
                    organizado de forma conveniente sob a opção "Mais (...)".
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Visualização de Organograma:</Body1Strong>{" "}
                    Visualize a estrutura hierárquica de escritórios principais
                    e subordinados para compreender a interconexão das
                    diferentes localizações de escritórios dentro da sua
                    organização.
                  </li>
                </ul>
                Alterne entre essas visualizações usando os ícones ao lado da
                caixa de pesquisa para personalizar sua experiência de navegação
                de acordo com suas necessidades imediatas.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="33">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                33. Encontrando Escritórios Rapidamente com a Função de Pesquisa
                do TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Localizar rapidamente o escritório certo dentro do TeamConnect é
                facilitado pela função de pesquisa dedicada. Posicionada de
                forma proeminente ao lado dos ícones de visualização, a caixa de
                pesquisa é sua ferramenta para localizar escritórios pelo nome.
                À medida que você digita, o diretório refina a exibição para
                mostrar apenas os escritórios que correspondem à sua entrada,
                otimizando seu processo de pesquisa. É importante usar essa
                caixa de pesquisa dentro do TeamConnect para obter os resultados
                mais precisos e relevantes, ao contrário da pesquisa geral do
                Microsoft Teams. Com essa funcionalidade de pesquisa
                direcionada, você pode navegar rapidamente para qualquer
                escritório na rede da sua organização.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="34">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                34. Navegando pelo Diretório do Escritório Alfabeticamente
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Se você preferir procurar por alfabeto, nosso filtro alfabético
                é uma maneira rápida de encontrar escritórios pelo nome. No topo
                do Diretório do Escritório, você verá as letras de A a Z. Clicar
                em uma letra filtra a visualização para mostrar apenas aqueles
                escritórios que começam com essa letra, simplificando seu
                processo de busca. Para redefinir e ver todos os escritórios
                novamente, simplesmente desmarque a letra ativa. Esta
                funcionalidade é especialmente útil quando você está
                familiarizado com o nome do escritório, mas precisa de uma
                maneira rápida de localizá-lo dentro do TeamConnect.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="35">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                35. Revelando Perfis Completos de Escritórios no TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Embarque em uma jornada exploratória pelo coração da presença
                física de sua organização com os perfis de escritório do
                TeamConnect. Clicar em um nome de escritório traz um diálogo de
                perfil vibrante, repleto de abas com informações detalhadas
                sobre o escritório:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Visão Geral:</Body1Strong> Mergulhe na essência
                    da identidade do escritório com descritores detalhados,
                    desde a estrutura empresarial até os horários de
                    funcionamento e horário local, capturando a essência do
                    ambiente do escritório.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Contatos Essenciais:</Body1Strong> Acesse
                    contatos cruciais para serviços de emergência e suporte
                    externo, cada um a um clique de distância por meio do
                    Sistema de Telefone da Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Contatos do Escritório:</Body1Strong> Entre em
                    contato com os serviços de suporte internos com facilidade,
                    com detalhes de contato e opções de engajamento direto ao
                    seu alcance.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Feriados Públicos:</Body1Strong> Mantenha-se
                    atualizado sobre os feriados específicos do escritório,
                    garantindo que seus planos estejam alinhados com as
                    observâncias locais e os horários de trabalho.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Horário de Funcionamento:</Body1Strong> Planeje
                    suas interações com conhecimento preciso de quando o
                    escritório está movimentado ou fechando para o dia.
                  </li>
                </ul>
                Seja a energia agitada de Austin ou o centro estratégico de
                Cingapura, cada aba oferece um portal para entender e
                conectar-se com os diversos locais que compõem a presença global
                da sua empresa.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="36">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                36. Explorando a Dinâmica dos Departamentos dentro do Escritório
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Navegar pela rede profissional dos departamentos de um
                escritório se desenrola com facilidade no TeamConnect. Veja como
                mergulhar nos detalhes:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Visualizar Departamentos Neste Escritório:
                    </Body1Strong>{" "}
                    Descubra todos os departamentos que formam a estrutura do
                    escritório selecionando esta opção no menu Mais (...).
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Visão Organizacional:</Body1Strong> Clique em
                    qualquer departamento para revelar seu organograma para uma
                    representação visual de sua estrutura, com o caminho de
                    navegação guiando você: Escritório {">"} Departamento {">"}{" "}
                    Gráfico.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Exploração Profunda do Departamento:
                    </Body1Strong>{" "}
                    Explore mais um departamento clicando no menu Mais (...) ao
                    lado do nome do departamento. Aqui, você pode acessar uma
                    lista de todos os funcionários dentro daquele departamento,
                    direto no contexto do escritório.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Navegação por Trilha:</Body1Strong> Percorra os
                    níveis sem problemas. De funcionários de volta aos
                    departamentos ou do organograma, a trilha de navegação
                    (Escritório {">"} Departamento {">"} Funcionário {">"}{" "}
                    Gráfico) mantém sua jornada intuitiva e no caminho certo.
                  </li>
                </ul>
                Os caminhos intricados do TeamConnect convidam você a explorar,
                entender e interagir com cada camada dos departamentos do seu
                escritório, empoderando você com comando de navegação
                abrangente.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="37">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                37. Conectando-se com o Pessoal do Escritório no TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Curioso sobre as pessoas por trás de um escritório? A resposta
                está a apenas um clique no TeamConnect. Engaje com a elipse
                (três pontos) ao lado do seu nome de escritório escolhido para
                revelar um conjunto de opções interativas:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Visualizar Funcionários Neste Escritório:
                    </Body1Strong>{" "}
                    Descubra uma lista de todos os profissionais que chamam este
                    escritório de lar de trabalho. O número exibido oferece um
                    rápido resumo dos funcionários, convidando você a mergulhar
                    na comunidade do escritório.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Opções de Contato:</Body1Strong> Cada entrada
                    de funcionário está equipada com ícones de ação rápida,
                    permitindo que você inicie chats, chamadas ou até
                    videoconferências diretamente do TeamConnect, fomentando uma
                    comunicação intra-oficina sem interrupções.
                  </li>
                  <li>
                    <Body1Strong>Navegação:</Body1Strong> Para retornar à lista
                    mais ampla de escritórios a partir de uma lista de
                    funcionários, simplesmente clique em 'Escritório' na trilha
                    de navegação (Escritório {">"} Funcionário), e você será
                    levado de volta à visão geral do escritório.
                  </li>
                </ul>
                Com essas funcionalidades, o TeamConnect torna simples
                visualizar a distribuição da força de trabalho entre os
                escritórios e alcançar os funcionários sem esforço, promovendo
                um local de trabalho conectado, não importa a distância.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="38">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                38. As informações do seu escritório estão faltando ou
                desatualizadas?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Atualizações das informações do escritório no TeamConnect podem
                ser gerenciadas por editores de escritório designados. Se você
                está incerto sobre quem são os editores de escritório ou precisa
                de assistência com informações que são da alçada do RH, a equipe
                de suporte de RH está pronta para ajudar em{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>
                .
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Perguntas Frequentes sobre Configurações</Subtitle2>
      <p></p>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="39">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                39. A Importância de Configurações Gerais Precisas no
                TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                No cenário dinâmico do ambiente de trabalho atual, estar
                conectado e coordenado é mais crucial do que nunca. É aí que
                atualizar suas Configurações Gerais no TeamConnect desempenha um
                papel fundamental:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Seleção de Local de Trabalho:</Body1Strong>{" "}
                    Capture a essência do seu ambiente de trabalho atualizando
                    seu Local de Trabalho para refletir sua situação atual, seja
                    mudando da tranquilidade do seu Home-Office para a agitação
                    do escritório principal ou até se estabelecendo em uma Área
                    Pública. Essa clareza no seu local de trabalho oferece aos
                    colegas uma visão não apenas das melhores maneiras de entrar
                    em contato com você, mas também da natureza das conversas
                    que eles podem ter com você, protegendo a confidencialidade
                    de discussões sensíveis, especialmente quando você está no
                    ambiente aberto do escritório de um cliente ou sentado em um
                    lounge de aeroporto.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Especificação do Local de Trabalho:
                    </Body1Strong>{" "}
                    Compartilhar a cidade onde você trabalha faz mais do que
                    encurtar distâncias em uma configuração de equipe remota —
                    também abre portas para encontros profissionais espontâneos
                    ou socialização casual. Seja um café rápido, um almoço
                    demorado ou uma reunião colaborativa, saber que você está na
                    mesma cidade pode transformar um dia comum em uma
                    oportunidade para conexões significativas frente a frente.
                    Isso não apenas fortalece os laços da equipe, mas também
                    proporciona um entendimento mais profundo das nuances locais
                    que podem estar influenciando seu trabalho.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Precisão do Fuso Horário:</Body1Strong> À
                    medida que as equipes abrangem continentes, garantir que seu
                    fuso horário reflete seu local atual é essencial para
                    harmonizar as comunicações. O Horário Local do seu perfil no
                    TeamConnect é diretamente influenciado por essa
                    configuração, eliminando qualquer tentativa de adivinhação
                    por parte dos colegas que tentam entrar em contato com você.
                  </li>
                </ul>
                Mergulhe em 'Configurações' {">"} 'Configurações Gerais' dentro
                do TeamConnect para ajustar esses detalhes. Alguns momentos
                gastos atualizando suas configurações podem melhorar
                significativamente a coesão e a eficiência de seus esforços
                colaborativos em todos os aspectos.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="40">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                40. Decifrando a Seleção de Local de Trabalho no TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Escolher o cenário certo no menu suspenso de Local de Trabalho
                adapta o TeamConnect para refletir seu status de trabalho atual
                com precisão. Passe o mouse sobre o ícone de informações para
                uma explicação detalhada de cada opção:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Checked-Out/Break:</Body1Strong> Indica tempo
                    fora do trabalho, seja por uma pausa ou término do dia.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Office/Field-Office:</Body1Strong> Indica
                    presença em um espaço de trabalho gerenciado pela empresa.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Home-Office:</Body1Strong> Trabalhando de um
                    espaço dedicado em casa.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Remote-Workplace/Public-Area:</Body1Strong>{" "}
                    Operando de um ambiente fora do escritório, indicando a
                    necessidade de comunicação flexível.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Customer Workplace/Private Office:
                    </Body1Strong>{" "}
                    No local com um cliente, com uma nota sobre os níveis de
                    privacidade para a sensibilidade da conversa.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Commute/Business-Travel/Training:</Body1Strong>{" "}
                    Em movimento ou envolvido em atividades específicas
                    relacionadas ao trabalho fora do escritório.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Public-Holiday/Vacation/Various Leaves:
                    </Body1Strong>{" "}
                    Oficialmente fora do serviço por motivos pessoais, de saúde
                    ou feriados.
                  </li>
                </ul>
                Selecionar um ambiente de trabalho apropriado garante que sua
                equipe tenha o contexto necessário para interações, respeitando
                disponibilidade e privacidade.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="41">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                41. Navegando pelo Status 'Checked-In' no TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                A tag 'Checked-In' no Diretório de Funcionários é projetada para
                exibir todos os funcionários que estão atualmente no trabalho.
                Essa visibilidade é determinada pela seleção de Local de
                Trabalho que você faz:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Active Work Environments:</Body1Strong> As
                    opções Office, Field-Office, Home-Office, Remote-Workplace,
                    Public-Area, Customer-Workplace, Customer Private-Office,
                    Commute, Business-Travel e Training sinalizam que você está
                    engajado no trabalho, tornando-o 'Checked-In' e visivelmente
                    ativo para os colegas.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Non-Work Settings:</Body1Strong> Optar por
                    Checked-Out, Break, Public-Holiday, Vacation, On-Leave,
                    Maternity-Leave ou Sick-Leave remove você da lista de
                    'Checked-In', estabelecendo expectativas claras para
                    disponibilidade e tempos de resposta.
                  </li>
                </ul>
                Esta funcionalidade ajuda a delinear períodos de trabalho de
                tempo pessoal, auxiliando na colaboração e comunicação eficaz da
                equipe.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>
        Gestão de Dados e Perguntas Frequentes sobre Privacidade
      </Subtitle2>
      <Body1>
        <p>
          Na era digital de hoje, entender como seus dados são gerenciados é
          crucial. O TeamConnect está comprometido com a transparência e
          práticas robustas de proteção de dados. Abaixo, você encontrará
          informações sobre como lidamos, armazenamos e protegemos seus dados
          dentro do TeamConnect, garantindo que seu espaço de trabalho digital
          seja seguro e esteja em conformidade.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="42">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                42. Como o TeamConnect protege meus dados pessoais?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <p>
                  O TeamConnect está dedicado a proteger suas informações
                  pessoais com uma abordagem abrangente para a segurança dos
                  dados:
                </p>
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Single Sign-On (SSO):</Body1Strong> Para
                    aumentar a segurança e otimizar a experiência do usuário, o
                    TeamConnect integra a autenticação automática Single Sign-On
                    (SSO) com sua conta da Microsoft 365, facilitando o
                    gerenciamento de acesso seguro e sem esforços.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Criptografia:</Body1Strong> Garantimos que
                    todos os dados dentro do TeamConnect são criptografados
                    durante a transmissão e quando estão em repouso, tornando-os
                    inacessíveis para partes não autorizadas.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Minimização de Dados:</Body1Strong> Nossa
                    coleta de dados é estritamente limitada ao que é essencial
                    para a funcionalidade do TeamConnect. As únicas informações
                    do usuário que armazenamos são o seu Nome Principal do
                    Usuário (UPN) do Azure Entra ID, que pode se assemelhar ao
                    seu endereço de e-mail da empresa.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Acesso Restrito:</Body1Strong> O acesso ao UPN
                    é restrito ao Administrador da Assinatura da sua organização
                    dentro do nosso portal de Assinaturas, garantindo que as
                    informações sensíveis sejam visíveis apenas para pessoal
                    autorizado.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Exclusão Rápida de Dados:</Body1Strong> O
                    TeamConnect é estruturado para deletar instantaneamente o
                    UPN armazenado sob três condições específicas: se a
                    assinatura for cancelada pelo Administrador da Assinatura,
                    se um usuário for removido pelo RH dentro do TeamConnect, ou
                    se a assinatura do TeamConnect expirar. Isso garante que
                    seus dados pessoais não sejam retidos além da vida útil ou
                    necessidade.
                  </li>
                </ul>
                <p>
                  Por meio dessas medidas, o TeamConnect compromete-se a manter
                  os mais altos padrões de proteção de dados, garantindo que
                  suas informações pessoais permaneçam seguras e confidenciais.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="43">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                43. Está se perguntando onde seus dados residem com o
                TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                O TeamConnect inova em comparação às ofertas tradicionais de
                SaaS ao garantir que seus dados nunca saiam da sua organização.
                Ao contrário da prática usual de armazenar dados em servidores
                de nuvem externos, o TeamConnect usa de maneira engenhosa o
                Microsoft 365 SharePoint Online da sua organização para
                armazenar todos os dados da aplicação. Esta abordagem inovadora
                não apenas defende a soberania dos dados, mas também fortalece a
                segurança, alinhando-se perfeitamente com nosso compromisso
                inabalável de proteger sua privacidade e seguir padrões
                rigorosos de conformidade.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="44">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                44. Meus dados serão compartilhados com terceiros?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Não, os dados não são compartilhados com terceiros! A Cubeet é
                firme em nosso compromisso com a privacidade e segurança dos
                seus dados. Não compartilhamos nenhum dado do TeamConnect com
                terceiros sem obter o consentimento explícito dos nossos
                clientes. Em nosso ambiente seguro, armazenamos apenas
                identificadores essenciais, como o Nome Principal do Usuário
                (UPN), nome da organização, nome do tenant e ID do tenant,
                dentro do nosso portal de assinatura. Essas informações são
                cruciais para a prestação do serviço e são protegidas sob nossas
                políticas rigorosas de privacidade. Fique tranquilo, nossas
                práticas de gestão de dados são projetadas para garantir que
                seus dados permaneçam confidenciais, com transparência e
                controle firmemente em suas mãos.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="45">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                45. Como o TeamConnect está em conformidade com os regulamentos
                de proteção de dados como o GDPR?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                O TeamConnect alinha-se com regulamentos de proteção de dados
                como o GDPR, HIPAA, CCPA e outros, aproveitando os recursos de
                conformidade inerentes ao ambiente do Microsoft 365, onde todos
                os dados do cliente são armazenados de forma segura.
                Diferentemente dos modelos tradicionais de SaaS, o TeamConnect
                não armazena nenhum dado do cliente em seus servidores; em vez
                disso, opera dentro do ambiente Microsoft 365 da sua
                organização, aderindo aos seus rígidos padrões de segurança e
                conformidade. Essa abordagem garante que a governança dos seus
                dados permaneça consistente com o abrangente quadro de
                conformidade fornecido pelo Microsoft 365, incluindo a aderência
                ao GDPR, HIPAA para informações de saúde, CCPA para residentes
                da Califórnia, e outros regulamentos globais de proteção de
                dados. Ao se integrar de maneira contínua com sua configuração
                existente do Microsoft 365, o TeamConnect permite que sua
                organização mantenha controle total sobre seus dados, garantindo
                a conformidade com as leis e regulamentos de proteção de dados
                mais rigorosos.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="46">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                46. Como o TeamConnect lida com dados de chats, chamadas,
                videochamadas do Microsoft Teams, e-mails do Outlook e chamadas
                do sistema telefônico?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                O TeamConnect utiliza a API do Microsoft Graph para habilitar
                interações diretas, como chat, chamadas, videochamadas e e-mail
                do Outlook dentro do Microsoft Teams, proporcionando uma
                experiência de usuário contínua. É crucial que, embora o
                TeamConnect inicie essas comunicações, ele o faça sem intervir
                no processo de comunicação ou ter a capacidade de gravar ou
                armazenar qualquer conteúdo da interação. Todas as conversas,
                chamadas e e-mails ocorrem e são geridos diretamente dentro dos
                limites seguros do Microsoft Teams e do Outlook, em estrita
                aderência aos robustos padrões de segurança e privacidade da
                Microsoft 365. Isso garante que o TeamConnect aprimore suas
                capacidades de comunicação enquanto preserva totalmente a
                confidencialidade e a integridade dos seus dados.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Body1>
        <p>
          Para insights detalhados sobre nossas políticas e práticas de dados,
          você também pode querer consultar o{" "}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>
              Guia do Usuário TeamConnect
            </Body1Strong>
          </Link>{" "}
          ou o departamento de TI da sua organização em{" "}
          <Link href={`mailto:${itSupport}`}>
            <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong>
          </Link>{" "}
          para detalhes relacionados à sua configuração.
        </p>
      </Body1>
      <p></p>
      <Subtitle1 className={styles.fontColor}>
        Estamos Ouvindo: Compartilhe Seu Feedback e Ideias
      </Subtitle1>
      <Body1>
        <p>
          Suas percepções impulsionam a evolução do TeamConnect. Seja
          transbordando com uma ideia brilhante de recurso ou tendo algum
          feedback sobre sua experiência atual, estamos de ouvidos atentos. Sua
          contribuição é inestimável em nossa busca contínua para fornecer o
          melhor serviço possível.
        </p>
      </Body1>
      <Subtitle2>Feedback e Sugestões de Recursos</Subtitle2>
      <Body1>
        <p>
          Pronto para compartilhar? Dirija-se à nossa{" "}
          <Link
            style={{ fontWeight: 600 }}
            href={feebackLink}
            target='_blank'>
            Página de Feedback
          </Link>
          . Seja uma sugestão para um novo recurso ou feedback sobre
          funcionalidades existentes, basta selecionar a opção apropriada no
          dropdown do formulário. Suas contribuições ajudam a moldar o futuro do
          TeamConnect, garantindo que ele atenda e supere suas necessidades.
        </p>
      </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
