import PropTypes from "prop-types";
import {
  makeStyles,
  tokens,
  Link,
  Body1,
  Body1Strong,
  Subtitle2,
  Subtitle1,
  Title3,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,
  },
  bulletBottomMargin: {
    marginBottom: "8px",
  },
  scrollToTopButton: {
    position: "fixed",
    right: "5%",
    transform: "translateX(95%)",
    bottom: "8px",
  },
});
function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>
        Centre d'ajuda i preguntes freqüents de TeamConnect
      </Title3>
      <p>
        <Body1>
          Benvingut al Centre d'Ajuda de TeamConnect, dissenyat per a donar
          suport a la teva experiència amb la nostra aplicació de directori
          corporatiu integral. Aquí, trobaràs recursos per a optimitzar el teu
          ús de TeamConnect dins de Microsoft Teams, millorant la comunicació a
          través de la teva xarxa.
        </Body1>
      </p>
      <Subtitle2>Guia de Referència Ràpida</Subtitle2>
      <ul>
        <Body1>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Capacitats de Recerca Millorades: </Body1Strong>
            Troba els col·legues, departaments i contactes imprescindibles
            ràpidament amb les nostres potents eines de recerca.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Organigrames Visuals: </Body1Strong>
            Navega l'estructura jeràrquica de la teva organització i entén les
            línies de report amb facilitat a través d'organigrames intuïtius.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>
              Eines Adaptatives per a les Necessitats d'Avui:{" "}
            </Body1Strong>
            Utilitza funcionalitats modernes adaptades a les demandes dels llocs
            de treball contemporanis.
          </li>
        </Body1>
      </ul>
      <p></p>
      <Subtitle2>Optimitzant la Teva Experiència</Subtitle2>
      <Body1>
        <p>
          TeamConnect revoluciona la manera com interactues dins de l'estructura
          organitzativa, convertint les comunicacions diàries en compromisos
          estratègics.
        </p>
      </Body1>
      <p></p>
      <Subtitle1 className={styles.fontColor}>
        Obtén Ajuda i Preguntes Freqüents
      </Subtitle1>
      <Body1>
        <p>
          Troba respostes a les preguntes comunes sobre les funcionalitats i
          configuracions de TeamConnect en aquesta secció dedicada. Tant si
          estàs navegant per l'aplicació, personalitzant configuracions, o
          buscant solucions a desafiaments, el nostre Centre d'Ajuda està aquí
          per a assistir-te de manera eficient.
        </p>
      </Body1>
      <Body1>
        <p>
          Per a suport més profund o orientació específica, consulta si us plau
          el{" "}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>
              Guia d'Usuari de TeamConnect
            </Body1Strong>
          </Link>
          .
        </p>
      </Body1>
      <p></p>
      <Subtitle2>
        Utilitzant les preguntes més freqüents de TeamConnect
      </Subtitle2>
      <p></p>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="1">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                1. No trobes TeamConnect a la barra lateral de Microsoft Teams?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Si TeamConnect no és visible fàcilment o ha desaparegut de la
                teva barra lateral de Microsoft Teams, pots fixar-lo fàcilment
                per a un accés ràpid. Només segueix aquests passos simples:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Obre Microsoft Teams i fes clic a la icona '...' (+ Apps) a
                    la part inferior de la barra lateral.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Cerca 'TeamConnect' a la barra de cerca a la part superior
                    de la finestra emergent.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Fes clic amb el botó dret sobre l'aplicació TeamConnect dels
                    resultats de la cerca i selecciona 'Fixa'.
                  </li>
                </ol>
                Ara TeamConnect estarà fixat a la teva barra lateral, fent-lo
                accessible amb només un clic!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="2">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                2. Tens problemes tècnics amb TeamConnect? Aquí tens com obtenir
                ajuda.
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Si experimentes qualsevol problema tècnic amb TeamConnect dins
                de Microsoft Teams, l'equip de suport informàtic de la teva
                organització està aquí per ajudar-te. Contacta'ls a{" "}
                <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>
                , i t'assistiran ràpidament.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="3">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                3. No pots veure els detalls dels teus empleats?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Si el teu perfil sembla una mica buit amb només el teu nom i
                correu electrònic visibles, és probable que el teu equip de RRHH
                encara no hagi afegit la resta dels teus detalls d'empleat a
                TeamConnect. No et preocupis! Un missatge ràpid al teu equip de
                suport de RRHH posarà les coses en marxa. Estan preparats per
                omplir el teu perfil amb tots els detalls importants. Contacta a{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>{" "}
                i mira com el teu perfil pren vida!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="4">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                4. Quines opcions de llengua estan disponibles en TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect abraça la diversitat global de Microsoft Teams
                donant suport a totes les seves llengües. Tant si prefereixes
                l'àrab o el grec, el japonès o el suec, tens un món d'opcions a
                l'abast de la mà, incloent:
              </Body1>
              <ul>
                <li>
                  <Body1>
                    Àrab, Búlgar, Xinès (Simplificat i Tradicional), Croat,
                    Txec, Danès, Neerlandès, Anglès, Estonià, Finès, Francès,
                    Alemany, Grec, Hebreu, Hongarès, Indonesi, Italià, Japonès,
                    Coreà, Letó, Lituà, Noruec (Bokmål), Polonès, Portuguès
                    (Brasil i Portugal), Romanès, Rus, Serbi (Llatí), Eslovac,
                    Eslovè, Espanyol, Suec, Tailandès, Turc, Ucraïnès,
                    Vietnamita.
                  </Body1>
                </li>
              </ul>
              <Body1>
                Per personalitzar la teva experiència a TeamConnect a la teva
                llengua preferida, si us plau, ajusta els ajustos de llengua
                directament a Microsoft Teams, ja que TeamConnect s'integra
                completament amb aquests ajustos:
              </Body1>
              <ol>
                <Body1>
                  <li className={styles.bulletBottomMargin}>
                    Ves als teus ajustos de Microsoft Teams fent clic als tres
                    punts al costat de la teva foto de perfil a la part
                    superior, i selecciona 'Aparença i accessibilitat'.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Desplaça't fins a la secció de 'Llengua' i selecciona la
                    teva llengua preferida del menú desplegable.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Confirma els canvis fent clic al botó 'Guardar i reiniciar'
                    per aplicar els nous ajustos de llengua.
                  </li>
                </Body1>
              </ol>
              <Body1Strong>Nota: </Body1Strong>
              <Body1>
                Els canvis realitzats als teus ajustos de llengua a Microsoft
                Teams actualitzaran també de manera fluida la teva experiència a
                TeamConnect. Té en compte que canviar la llengua a Teams pot
                ajustar també el teu format de data i hora. Actualment,
                TeamConnect està evolucionant per al suport d'escriptura de
                dreta a esquerra. Estem entusiasmats amb aquestes millores i et
                mantindrem informat a mesura que estiguin disponibles.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="5">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                5. Com puc canviar el tema a TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect es sincronitza amb les teves preferències de tema de
                Microsoft Teams, incloent les opcions Clar, Fosc i Alt Contrast.
                Per personalitzar el teu tema:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Accedeix a la configuració de Microsoft Teams fent clic a
                    les tres punts al costat de la teva imatge de perfil a dalt.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Selecciona 'Aparença i accessibilitat' per explorar les
                    opcions de tema.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Tria el teu tema preferit de les opcions disponibles per
                    actualitzar instantàniament la teva interfície de
                    TeamConnect perquè coincideixi.
                  </li>
                </ol>
                La teva elecció de tema a Microsoft Teams serà aplicada
                automàticament a TeamConnect, assegurant una experiència visual
                consistent a través del teu espai de treball.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="6">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                6. Explorant els Botons d'Acció de Contacte de TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                A tot TeamConnect, on trobis detalls de contacte d'un empleat,
                també descobriràs els nostres botons d'accés ràpid pràctics.
                Aquestes eines útils estan dissenyades per agilitzar la teva
                comunicació dins de l'ecosistema de Microsoft, assegurant que
                sempre estàs a només un clic de connectar-te. Aquí tens la teva
                guia completa per utilitzar aquests botons efectivament:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Icona de Xat:</Body1Strong> Fes clic per
                    iniciar un xat instantani de Microsoft Teams. Si necessites
                    tornar a TeamConnect després de començar un xat, simplement
                    utilitza la fletxa enrere a l'App de Escriptori, el botó de
                    tornada a l'App Mòbil, o el botó de tornada del teu
                    navegador.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Icona de Correu Electrònic:</Body1Strong> Obre
                    un nou esborrany de correu electrònic d'Outlook amb l'adreça
                    del destinatari ja en el camp "A:". Si Outlook no s'inicia,
                    val la pena consultar amb el teu departament d'IT per
                    qualsevol restricció.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Icona de Trucada de Vídeo:</Body1Strong> Inicia
                    una trucada de vídeo cara a cara fent servir Microsoft
                    Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Icona de Trucada:</Body1Strong> Inicia una
                    trucada de veu de Microsoft Teams, fent que les discussions
                    ràpides siguin sense fissures.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Icona del Gràfic Orgànic:</Body1Strong> Revela
                    el lloc de l'empleat en la jerarquia organitzativa, incloent
                    el seu gestor i informes directes.
                  </li>
                  <li>
                    <Body1Strong>Més (...) Icona:</Body1Strong> Desvetlla més
                    opcions:
                    <ul>
                      <li
                        style={{ marginTop: "8px" }}
                        className={styles.bulletBottomMargin}
                      >
                        <Body1Strong>Troba't Ara:</Body1Strong> Obre Microsoft
                        Teams per començar una reunió immediata amb l'empleat.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Reunió Nova:</Body1Strong> Et dirigeix a
                        Microsoft Teams per programar una nova reunió.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Afegeix Contacte:</Body1Strong> Afegeix les
                        dades de contacte de l'empleat des de TeamConnect a la
                        nova app People de Microsoft Teams, enriquint la teva
                        funció de trucada.
                      </li>
                    </ul>
                  </li>
                </ul>
                Aquests botons intuïtius estan creats per assegurar que pots
                connectar-te eficientment amb els col·legues, ja sigui per una
                xerrada ràpida, un correu electrònic, o per programar futures
                reunions.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="7">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                7. Fent trucades a través de TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Vols fer una trucada? TeamConnect agilitza la teva comunicació
                integrant-se amb Microsoft Teams. Simplement fes clic al número
                de telèfon desitjat a TeamConnect, i voilà, la interfície de
                trucades de Microsoft Teams apareix, llesta perquè puguis marcar
                de manera fluida.
                <p>
                  <Body1Strong>Nota: </Body1Strong>
                  Tens problemes fent trucades? Això pot ser a causa de les
                  polítiques d'IT de la teva organització o l'ús d'un sistema
                  telefònic diferent del Sistema de Telèfon de Microsoft Teams.
                  Consultar amb el teu departament d'IT a{" "}
                  <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>
                      {itSupport}
                    </Body1Strong>
                  </Link>
                  , pot aclarir el tema.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="8">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                8. Hi ha costos associats per fer trucades a través de
                TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect millora la teva experiència de trucada utilitzant
                Microsoft Teams. Aquí tens el que has de saber sobre els costos
                relacionats amb les trucades:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    Per a trucades a telèfons de treball dels empleats, telèfons
                    del departament o línies directes dins de la teva
                    organització (números interns), generalment{" "}
                    <Body1Strong>no hi ha costos</Body1Strong> quan s'utilitza
                    el Sistema de Telèfons de Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Per a trucades externes, com les fetes a telèfons mòbils o
                    línies externes, s'apliquen les{" "}
                    <Body1Strong>tarifes estàndard</Body1Strong> del sistema
                    telefònic de la teva organització. Aquestes tarifes varien
                    segons el teu pla de serveis específic amb Microsoft Teams.
                  </li>
                </ul>
                <p>
                  <Body1Strong>Nota:</Body1Strong> Si trobes algun problema o
                  tens preguntes sobre els càrrecs de trucades, és millor
                  consultar amb el teu departament de TI a{" "}
                  <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>
                      {itSupport}
                    </Body1Strong>
                  </Link>{" "}
                  per obtenir informació detallada adaptada a la configuració i
                  les polítiques de la teva organització.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="9">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                9. T'has adonat d'una discordància amb el teu estat de presència
                'Fora de l'oficina' a TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                T'has adonat que el teu estat de presència 'Fora de l'oficina'
                de Microsoft 365 no es reflecteix correctament a TeamConnect?
                Ens n'hem ocupat. La funcionalitat per sincronitzar l'estat de
                presència 'Fora de l'oficina' ja està implementada a
                TeamConnect. Actualment estem a l'espera que Microsoft
                implementi aquesta funcionalitat. Un cop això succeeixi, el teu
                estat de presència 'Fora de l'oficina' es reflectirà acuradament
                a TeamConnect, alineant-se amb els indicadors de presència de
                Microsoft Teams per una experiència sense fissures.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Preguntes freqüents del Directori d'Empleats</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Introducció al Directori d'Empleats:</Body1Strong> Navega
          pel paisatge professional de la teva organització amb el Directori
          d'Empleats, el teu centre neuràlgic per a percepcions detallades dels
          empleats, dinàmiques laborals i connectivitat directa als perfils i
          relacions gerencials dels teus col·legues.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="10">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                10. Necessites afegir, actualitzar o corregir les teves dades
                d'empleat?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Per afegir, actualitzar o corregir les teves dades d'empleat a
                TeamConnect, posa't en contacte amb l'equip de suport de RH de
                la teva organització. Estan preparats per ajudar-te a{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>
                .
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="11">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                11. Què passa quan faig clic sobre el nom d'un empleat al
                directori?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Fer clic sobre el nom d'un empleat al Directori d'empleats de
                TeamConnect obre una pàgina de perfil detallada per a aquesta
                persona. Aquest perfil proporciona informació completa sobre
                l'empleat, incloent, però no limitant-se a, el seu títol
                professional, departament, ubicació de l'oficina, informació de
                contacte i més. Està dissenyat per oferir-vos una visió completa
                del rol professional de l'empleat i com s'integra dins de
                l'organització, facilitant-vos entendre les seves
                responsabilitats i com podrieu col·laborar amb ells.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="12">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                12. Quina informació s'inclou en el perfil d'un empleat?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Cada perfil d'empleat a TeamConnect és una instantània completa,
                oferint una riquesa d'informació sobre la vida professional de
                la persona. Aquí trobareu:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Detalls Bàsics:</Body1Strong> Nom, títol
                    professional, departament i ubicació de la subsidiària.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Estructura de Report:</Body1Strong> Informació
                    sobre reports directes i funcionals, inclosos els gestors de
                    línia i funcionals.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Estat del Lloc de Treball:</Body1Strong> Estat
                    actual de treball com ara En Llicència, Commute, Viatge de
                    Negocis, etc.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Lloc de Treball & Hora Local:</Body1Strong> La
                    ubicació actual de treball de l'empleat i la zona horària
                    local.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Detalls de Contacte:</Body1Strong> Telèfon del
                    treball, número de mòbil, i adreça de correu electrònic.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Adreça de l'Oficina:</Body1Strong> Ubicació
                    completa de l'oficina incloent el nom de l'edifici, adreça,
                    i mapa de Google.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Sobre:</Body1Strong> Una breve introducció
                    personal o biografia professional.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Relacions Laborals:</Body1Strong> Detalls de
                    l'estructura de report de l'empleat i relacions en l'equip.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Enllaços Socials & Idiomes:</Body1Strong>{" "}
                    Enllaços a perfils socials professionals i competències
                    lingüístiques.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Habilitats:</Body1Strong> Una llista de
                    habilitats professionals i àrees d'expertesa.
                  </li>
                </ul>
                A més, el perfil ofereix opcions accionables per connectar via
                xat, correu electrònic, trucada de vídeo, o programar reunions,
                juntament amb accés ràpid al gràfic de l'organització per un
                context més ampli de l'equip.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="13">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                13. Com puc actualitzar la informació del meu perfil?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Personalitzar el teu perfil és fàcil a TeamConnect. Així és com
                ho pots fer:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Navega a la pestanya <Body1Strong>Employee</Body1Strong> i
                    fes clic al teu <Body1Strong>Avatar</Body1Strong> per obrir
                    el teu perfil.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    A la part superior esquerra, trobaràs l'enllaç{" "}
                    <Body1Strong>"Edita el teu perfil"</Body1Strong>. Fent clic
                    aquí s'obrirà el diàleg d'edició del teu perfil.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Aquí, pots millorar el teu perfil afegint o actualitzant la
                    secció <Body1Strong>About You</Body1Strong>, enllaços a les
                    xarxes socials, i el número de telèfon. Això facilita que
                    els teus col·legues es connectin o et segueixin.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    No oblidis prémer el botó <Body1Strong>Save</Body1Strong>{" "}
                    per aplicar els teus canvis.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Desplaça't cap avall en el diàleg per afegir les teves{" "}
                    <Body1Strong>Languages</Body1Strong> i{" "}
                    <Body1Strong>Proficiency</Body1Strong>, així com les teves{" "}
                    <Body1Strong>Skills</Body1Strong>. Compartir aquesta
                    informació ajuda als teus col·legues a entendre les teves
                    àrees d'expertesa i els idiomes en els quals pots
                    comunicar-te.
                  </li>
                </ol>
                Actualitzar el teu perfil no només manté la teva informació
                actualitzada sinó que també fomenta millors connexions dins de
                la teva organització.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="14">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                14. Actualitzant el teu lloc de treball, la ubicació de treball
                i la zona horària a TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mantenir actualitzada la informació del teu lloc de treball,
                ubicació de treball i zona horària és crucial per a una
                col·laboració sense interrupcions a Microsoft Teams. Aquestes
                actualitzacions es reflecteixen immediatament en el teu perfil
                de TeamConnect i anuncis, permetent als companys entendre
                ràpidament la teva ubicació de treball i els millors moments per
                connectar amb tu, millorant el treball en equip a través de
                diferents regions i zones horàries.
                <p></p>
                Llest per actualitzar els teus detalls? És fàcil a TeamConnect!
                Vés a 'Settings' al Menú superior i navega a 'General Settings'.
                Aquí, pots actualitzar fàcilment el teu lloc de treball i zona
                horària, i especificar la teva ubicació de treball per al dia.
                Recorda guardar els teus canvis per mantenir a tothom informat i
                assegurar una col·laboració eficaç, independentment de la teva
                ubicació!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="15">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                15. Has detectat alguna cosa incorrecta en el perfil d'un
                col·lega?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Si trobes algun detall al perfil d'un col·lega que no sembla del
                tot correcte, el primer pas millor és donar-los un avís
                amigable. Una xerrada ràpida o un email hauria de ser suficient!
                Si per alguna raó la informació no s'actualitza, no et
                preocupis—només contacta el teu equip de suport de RH a{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>
                , i estaran encantats d'ajudar i resoldre el problema.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="16">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                16. Entendre els grups d'etiquetes: Tots, Preferits, Al Lloc &
                Nous Empleats
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Tots:</Body1Strong> Aquesta etiqueta per
                    defecte mostra tots els empleats de la teva organització,
                    oferint una visió completa de l'equip.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Preferits:</Body1Strong> Feu clic aquí per
                    filtrar la vostra vista als col·legues que heu marcat com a
                    'Preferits', facilitant la connexió ràpida amb els contactes
                    clau.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Al Lloc:</Body1Strong> Aquesta etiqueta indica
                    els empleats que estan activament treballant, diferenciant
                    de la mera presència en línia a Microsoft Teams. Quan estan
                    al lloc, els empleats senyalen que estan treballant i
                    comparteixen la seva ubicació de treball actual durant el
                    dia. Això pot incloure una varietat de llocs de treball com
                    Oficina, Oficina de Camp, Home-Office, Lloc de Treball
                    Remot, Lloc de Treball del Client, Oficina Privada del
                    Client, Àrea Pública, Commutació, Viatge de Negocis, o fins
                    i tot Formació. Per tant, l'estatus 'Al Lloc' proporciona
                    una indicació clara de estar activament al treball, oferint
                    als col·legues una comprensió precisa del context laboral
                    actual i la disponibilitat de cadascun.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Nous Empleats:</Body1Strong> Identifica les
                    cares noves a TeamConnect amb aquesta etiqueta, destacant
                    els empleats que s'han afegit a l'aplicació en els últims 30
                    dies. Normalment, això es correspon amb les noves
                    incorporacions a la vostra organització, ajudant a donar la
                    benvinguda a nous membres de l'equip.
                  </li>
                </ul>
                Aquests grups d'etiquetes estan dissenyats per agilitzar la
                navegació i millorar la vostra experiència amb TeamConnect,
                assegurant que sempre podeu trobar i connectar amb els vostres
                col·legues de manera eficient.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="17">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                17. Descodificant les icones al costat del quadre de cerca
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                El directori d'empleado de TeamConnect està dissenyat per ser
                versàtil i fàcil d'usar, incorporant quatre icones que ofereixen
                diferents vistes i un accés ràpid a detalls clau. Això és el que
                desvetlla cada icona:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Vista de Llista:</Body1Strong> La vostra vista
                    principal, presentant tots els membres de l'organització en
                    una llista senzilla.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Vista de Targetes Petites:</Body1Strong> Una
                    alternativa compacta, aquesta vista transforma la llista en
                    targetes d'empleat de mida reduïda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Vista de Targetes Grans:</Body1Strong> Opteu
                    per més detalls amb targetes més grans, que inclouen opcions
                    de contacte addicionals com números de telèfon i mòbils per
                    a trucades fàcils.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Gràfic Org:</Body1Strong> Visualitzeu
                    l'estructura de l'empresa amb la vista de Gràfic Org,
                    perfecta per entendre les relacions de report i navegar pel
                    paisatge organitzacional.
                  </li>
                </ol>
                Alterneu entre aquestes icones per trobar la vista que millor
                s'adapta a les vostres necessitats en aquell moment, ja sigui
                que busqueu informació ràpida o intuïcions més profundes.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="18">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                18. Per a cerques ràpides de col·legues, on hauria de mirar?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Busqueu un col·lega? Només cal dirigir-se al quadre de cerca de
                TeamConnect, situat a la part superior dreta al costat de la
                icona de Gràfic Org. Comenceu a escriure el seu nom, i veureu
                com els resultats es refinan amb cada lletra. Recordeu, per a
                cerques en TeamConnect, ignoreu la barra de cerca de Microsoft
                Teams a la part superior: el nostre quadre de cerca especial és
                el camí a seguir!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="19">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                19. Com funciona la cerca alfabètica per trobar empleats?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                El Directori d'Empleats de TeamConnect inclou una funció de
                cerca alfabètica pràctica per ajudar-vos a localitzar ràpidament
                els empleats pel seu nom de pila. Simplement feu clic en una
                lletra de l'alfabet mostrada a la part superior del directori, i
                TeamConnect us mostrarà instantàniament tots els empleats els
                noms dels quals comencen amb aquesta lletra. Per tornar a la
                llista completa d'empleats, només cal deseleccionar la lletra
                ressaltada. Aquesta funció intuïtiva fa que navegar per llistes
                grans d'empleats sigui fàcil, assegurant que podeu trobar
                col·legues de manera ràpida i eficaç.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="20">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                20. Com puc ajustar el nombre d’empleats mostrats per pàgina?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect us permet personalitzar quants perfils d'empleat
                veureu per pàgina, facilitant la navegació a través del
                Directori d'Empleats. Al final de cada pàgina, trobareu opcions
                de paginació: 10, 50, 100, 500, 1000 i 5000. La configuració
                predeterminada mostra 10 perfils per pàgina. Per veure més
                perfils en una sola pàgina, simplement seleccioneu el vostre
                nombre preferit d’aquestes opcions. Aquesta funció proporciona
                flexibilitat en com navegueu pel directori, permetent-vos
                adaptar la visualització a les vostres necessitats o
                preferències específiques.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="21">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                21. Com puc ordenar la informació dels empleats en la Vista de
                Llista?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                A la Vista de Llista del Directori d'Empleats de TeamConnect,
                teniu la flexibilitat d'ordenar la informació dels empleats
                segons les vostres necessitats. Les columnes—Nom de l'Empleat,
                Càrrec, Departament, Ubicació de l'Oficina i Lloc de Treball—són
                totes ordenables. Simplement feu clic en la capçalera de la
                columna per la qual desitgeu ordenar. Fer clic una vegada
                ordenarà la columna en ordre ascendent, i fer clic de nou
                l'ordenarà en ordre descendent. Per defecte, el directori està
                ordenat per Nom de l'Empleat en ordre ascendent, basat en el
                primer nom. Aquesta funció d'ordenació ajuda a organitzar
                ràpidament i veure els detalls dels empleats d'una manera que
                millor serveix al vostre propòsit, ja sigui que esteu buscant
                algú en concret o necessiteu agrupar els empleats per un criteri
                particular com el departament o la ubicació.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Preguntes freqüents del Directori de Departaments</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Introducció al Directori de Departaments:</Body1Strong>{" "}
          El Directori de Departaments és la vostra porta d'entrada per entendre
          la complexa xarxa de l'estructura de la vostra organització, oferint
          una visió clara de les jerarquies departamentals, el lideratge
          gerencial i les vies per a una comunicació departamental sense
          entrebancs.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="22">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                22. Com funcionen les etiquetes 'Tot', 'Preferits' i 'El teu
                Departament' en el Directori de Departaments?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                En el Directori de Departaments de TeamConnect, les etiquetes
                t'ajuden a navegar ràpidament i filtrar la informació
                departamental. Aquí teniu el que representa cada etiqueta:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Tot:</Body1Strong> Aquesta etiqueta mostra tots
                    els departaments dins de la vostra organització,
                    proporcionant una visió completa.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Preferits:</Body1Strong> Utilitza aquesta
                    etiqueta per veure una llista seleccionada de departaments
                    que has marcat com a 'Preferits', facilitant l'accés als
                    departaments amb els quals interactues freqüentment.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>El teu Departament:</Body1Strong> Aquesta
                    etiqueta filtra la vista per mostrar només el teu
                    departament, permetent un accés ràpid a la informació i
                    actualitzacions del teu departament immediat.
                  </li>
                </ul>
                Aquestes etiquetes estan dissenyades per agilitar la teva
                navegació dins del Directori de Departaments, millorant la teva
                capacitat de trobar i interactuar amb la informació
                departamental de manera eficient.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="23">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                23. Comprendre les Vistes del Directori de Departaments a
                TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Navegar a través del Directori de Departaments a TeamConnect
                està optimitzat amb múltiples opcions de visualització que
                s'adapten a les teves preferències. Cada vista ofereix una
                manera única d'explorar informació departamental completa,
                incloent el Nom del Departament, el Gerent del Departament, el
                Departament Pare, el Telèfon del Departament, la Línia Directa
                del Departament i el Correu Electrònic del Departament. Aquí
                teniu una instantània de les vistes disponibles:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Vista de Llista:</Body1Strong> La configuració
                    predeterminada que llista ordenadament tots els
                    departaments, proporcionant una visió clara i concisa.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Vista de Fitxes Grans:</Body1Strong> Canvia a
                    aquesta vista per veure fitxes detallades dels departaments,
                    oferint un resum ampliat de la informació clau de cada
                    departament.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Vista de l'Organigrama:</Body1Strong> Per a una
                    representació visual de l'estructura departamental i les
                    relacions, la vista de l'Organigrama il·lustra com cada
                    departament encaixa dins de la jerarquia organitzativa més
                    gran.
                  </li>
                </ul>
                Per canviar entre aquestes vistes, simplement utilitza les
                icones al costat del quadre de cerca a la part superior del
                Directori de Departaments. Cada vista està dissenyada per
                ajudar-te a accedir i entendre els detalls departamentals amb
                facilitat, assegurant que trobis la informació que necessites en
                el format que millor se t'ajusti.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="24">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                24. Com utilitzar el quadre de cerca en el Directori de
                Departaments
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Localitzeu sense problemes qualsevol departament dins de
                TeamConnect utilitzant el quadre de cerca dedicat situat just al
                costat de les icones de visualització a la part superior del
                Directori de Departaments. Simplement comenceu a escriure el nom
                del departament que esteu buscant, i observeu com el directori
                es refinada per mostrar només coincidències rellevants. Aquesta
                funció està específicament dissenyada per ajudar-vos a evitar la
                necessitat de cercar manualment a través de tot el directori,
                portant el departament que necessiteu directament a les vostres
                mans. Recordeu, aquesta funcionalitat de cerca és única per al
                Directori de Departaments de TeamConnect, així que assegureu-vos
                d’utilitzar aquest quadre de cerca en lloc de la cerca principal
                de Microsoft Teams a la part superior de la interfície de Teams
                per a consultes específiques de departament.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="25">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                25. Com funciona el filtre alfabètic en el Directori de
                Departaments?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Agilitzeu la vostra cerca en el Directori de Departaments amb el
                filtre alfabètic situat a la part superior. Aquesta funció us
                permet reduir ràpidament els departaments seleccionant una
                lletra. Un cop escolliu una lletra, TeamConnect filtra i mostra
                només aquells departaments els noms dels quals comencen amb la
                lletra seleccionada, fent que sigui més fàcil trobar el que
                esteu buscant sense necessitat de desplaçar-vos per tota la
                llista. Per tornar a la vista completa del departament,
                simplement deseleccioneu la lletra ressaltada. Aquesta navegació
                alfabètica intuïtiva us assegura que podeu localitzar eficaçment
                qualsevol departament per la seva lletra inicial.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="26">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                26. Com puc contactar amb el gerent del departament?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Contactar amb un gerent de departament a TeamConnect és senzill.
                Simplement localitzeu el departament d'interès i cliqueu en el
                nom del gerent. Aquesta acció desencadenarà un popover que
                mostra les opcions de contacte del gerent amb les icònes d'accés
                ràpid familiars:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Icona de Xat:</Body1Strong> Per iniciar un
                    missatge instantani a través del xat de Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Icona de Correu Electrònic:</Body1Strong> Per
                    obrir un correu electrònic nou adreçat a ells a Microsoft
                    Outlook.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Icona de Trucada de Vídeo:</Body1Strong> Per
                    iniciar una trucada de vídeo a través de Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Icona de Trucada:</Body1Strong> Per començar
                    una trucada de veu utilitzant Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Icona de Més (...):</Body1Strong> Per a opcions
                    addicionals com programar una reunió o afegir la seva
                    informació de contacte a la vostra llista de Teams.
                  </li>
                </ul>
                Aquestes icones proporcionen un mètode senzill per arribar
                directament als gerents de departament des dins de TeamConnect,
                assegurant una comunicació eficaç dins de la vostra
                organització.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="27">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                27. Com puc veure o contactar amb els empleats dins d'un
                departament?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Per explorar qui és qui dins d'un departament a TeamConnect, no
                busquis més enllà dels tres punts al costat del nom del
                departament. Fent clic aquí es desplegaran opcions per a més
                accions:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Veure els empleats en aquest Departament:
                    </Body1Strong>{" "}
                    Selecciona això per mostrar una llista de tots els empleats
                    associats amb el departament. El número al costat d'aquesta
                    opció indica quantitat d'empleats que hi ha al departament,
                    proporcionant un recompte ràpid.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Organigrama del Departament:</Body1Strong> Si
                    desitges entendre la jerarquia del departament, escull
                    'Organigrama del departament' per revelar la disposició
                    estructural, mostrant la relació entre departaments pares,
                    el departament en qüestió, i qualssevol subdepartaments.
                    Aquesta representació visual t'ajuda a navegar i entendre el
                    marc organitzatiu, facilitant la identificació i contacte
                    amb membres específics del departament.
                  </li>
                  <li>
                    <Body1Strong>Departament {">"} Empleat:</Body1Strong>{" "}
                    Després de veure els empleats dins d'un departament,
                    utilitza aquest camí de navegació per tornar fàcilment a la
                    llista de departaments, mantenint una experiència
                    d'exploració fluida dins de TeamConnect.
                  </li>
                </ul>
                Aquestes característiques et permeten no només veure la llista
                d'empleats en un departament donat, sinó també oferir un camí
                per contactar-los directament a través de les eines de
                comunicació de TeamConnect.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="28">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                28. Descobrint els Detalls Profunds dels Departaments en
                TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Endinsa't en les especificitats de qualsevol departament amb un
                simple clic al seu nom a TeamConnect. Es desplega un diàleg
                detallat, oferint un ric tapís d'informació que pinta un quadre
                complet del rol i els recursos del departament dins la teva
                organització. Trobaràs des de lideratge i identificadors
                financers fins a canals de comunicació i enllaços socials.
                Aquesta funció és la teva porta d'entrada a:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    El cor del departament: el seu propòsit, gent, i mètriques
                    de rendiment.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Avingudes de contacte: línies directes a figures clau del
                    departament i plataformes de comunicació.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    La xarxa organitzativa: comprendre com el departament
                    s'integra amb l'estructura més àmplia de l'empresa.
                  </li>
                </ul>
                Sigui una consulta ràpida o una immersió profunda en les dades
                del departament, TeamConnect t'equipa amb les eines per
                connectar amb i comprendre els ecosistemes únics dins la teva
                organització.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="29">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                29. Curiositat sobre l'Absència d'Adreces dels Departaments a
                TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Trobar la ubicació d'un departament de vegades pot semblar una
                recerca del tresor sense mapa. A TeamConnect, no trobaràs
                típicament una adreça dedicada per cada departament. Això és per
                què sovint s'ometen aquests detalls cartogràfics:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Múltiples Ubicacions:</Body1Strong> Molts
                    departaments no estan confinats a un sol espai; prosperen a
                    través de diverses oficines i geografies.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Estructura Fluida:</Body1Strong> El paisatge
                    organitzatiu està sempre canviant, amb departaments que
                    evolucionen, es fusionen o fins i tot es dissolen en
                    alineació amb els canvis estratègics.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Col·laboració Interdepartamental:</Body1Strong>{" "}
                    Els espais de treball moderns bullen amb esperit
                    col·laboratiu, sovint mesclant talents de diversos
                    departaments en espais compartits.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Cultura de Treball Remot:</Body1Strong> Amb la
                    flexibilitat del treball remot, les àncores departamentals
                    no sempre es llencen en ubicacions d'oficines físiques.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Nuances Legals:</Body1Strong> Típicament, els
                    departaments orbiten al voltant de l'estructura legal de
                    l'empresa en lloc de tenir les seves pròpies adreces
                    independents.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Optimització Operacional:</Body1Strong>{" "}
                    Mantenir el focus en el treball en equip dinàmic, no en
                    adreces estàtiques, ajuda a mantenir l'agilitat operativa.
                  </li>
                </ul>
                Tot es basa en adoptar un enfocament sense límits cap al treball
                en equip i l'eficiència. TeamConnect reflecteix aquesta
                filosofia fent èmfasi en les connexions en lloc de les
                ubicacions.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="30">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                30. Falta o està desactualitzada la informació del teu
                departament?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Hi ha editors de departament designats dins de la teva
                organització per afegir, actualitzar o corregir la informació
                específica del departament en TeamConnect. Si no estàs segur de
                qui és el teu editor de departament o si certa informació
                requereix la intervenció de l'equip de suport de RRHH, si us
                plau contacta amb{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>{" "}
                per a orientació.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Preguntes freqüents del Directori d'Oficines</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Introducció al Directori d'Oficines:</Body1Strong>{" "}
          Descobreix l'esquena de la presència física de la teva organització
          mitjançant el Directori d'Oficines, que engloba detalls essencials de
          l'oficina, especificacions operatives i una mirada completa als
          departaments i al personal que defineixen cada ubicació.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="31">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                31. Navegant per Etiquetes en el Directori d'Oficines
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Troba el teu camí pel Directori d'Oficines amb facilitat usant
                el nostre sistema d'etiquetes dissenyat per accedir ràpidament a
                la ubicació correcta. Així és com cada etiqueta pot ajudar a
                agilitzar la teva cerca:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Totes:</Body1Strong> Mostra un llistat complet
                    d'oficines, proporcionant una vista panoràmica de les
                    ubicacions de la teva organització.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Favorits:</Body1Strong> T’ajuda a concentrar-te
                    en aquelles ubicacions d'oficina que freqüentes o necessites
                    mantenir sota vigilància, marcant-les com a 'Favorits'.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>La teva Oficina:</Body1Strong> Aquesta etiqueta
                    personal filtra el directori per mostrar-te la ubicació de
                    l’oficina on estàs estacionat, mantenint la teva base a
                    l'abast.
                  </li>
                </ul>
                Tant si estàs reservant una sala de reunions com si estàs
                planificant una visita, aquestes etiquetes ofereixen una manera
                personalitzada i simplificada d'interactuar amb el paisatge
                geogràfic de la teva organització.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="32">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                32. Dominant les vistes del Directori d'Oficines a TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Submergeix en la disposició organitzativa de les diverses
                ubicacions de la teva companyia amb les vistes del Directori
                d'Oficines de TeamConnect. Cada vista està creada per facilitar
                un aspecte únic de la navegació i comprensió de l'oficina:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Vista de Llista:</Body1Strong> La configuració
                    predeterminada que organitza ordenadament totes les
                    ubicacions de les oficines en una llista fàcil de llegir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Vista de Targeta Gran:</Body1Strong> La Vista
                    de Targeta Gran al Directori d'Oficines enriqueix la teva
                    vista amb una presentació detallada de cada oficina. Quan
                    interactúes amb una targeta d'oficina, trobaràs una riquesa
                    d'informació i opcions accionables dins el menú "Més (...)"
                    :
                    <ol>
                      <li
                        style={{ marginTop: "8px" }}
                        className={styles.bulletBottomMargin}
                      >
                        <Body1Strong>Veure Departaments:</Body1Strong> Navega
                        directament a una llista de tots els departaments
                        situats dins de l'oficina.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Veure Empleats:</Body1Strong> Accedeix a
                        una llista completa dels empleats assignats a l'
                        oficina, facilitant el contacte directe o l'exploració
                        del personal de l'oficina.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>
                          Gràfic Orgànic de l'Entitat Empresarial:
                        </Body1Strong>{" "}
                        Visualitza el lloc de l'oficina dins el context
                        organitzatiu més ampli per un clar entendiment de les
                        estructures jeràrquiques.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Contacts Essentials:</Body1Strong>{" "}
                        Identifica els contactes clau de l'oficina per una
                        comunicació més directa.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Festius Públics de l'Oficina:</Body1Strong>{" "}
                        Mantingues informat sobre els festius específics de
                        l'oficina per planificar els teus horaris en
                        conseqüència.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>
                          Hores de Treball de l'Oficina:
                        </Body1Strong>{" "}
                        Visualitza les hores d'operació de l'oficina per alinear
                        les teves visites o trucades amb els seus temps actius.
                      </li>
                    </ol>
                    Aquesta vista ofereix no només una instantània visual i
                    informativa sinó també un accés ràpid a percepcions més
                    profundes i accions directes relacionades amb l'oficina, tot
                    això ordenadament amagat sota l'opció "Més (...)" per a la
                    teva comoditat.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Vista de Gràfic Orgànic:</Body1Strong>{" "}
                    Visualitza l'estructura jeràrquica dels oficines matriu i
                    subsidiàries per comprendre la interconnectivitat de
                    diferents ubicacions d'oficines dins de la teva
                    organització.
                  </li>
                </ul>
                Alterna a través d'aquestes vistes utilitzant les icones al
                costat de la caixa de cerca per personalitzar la teva
                experiència de navegació segons les teves necessitats
                immediates.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="33">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                33. Trobant Ràpidament Oficines amb la Funció de Cerca de
                TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Centrant-te en la ubicació correcta de l'oficina dins
                TeamConnect es fa fàcil amb la funció de cerca dedicada.
                Posicionada prominentment al costat de les icones de
                visualització, la caixa de cerca és la teva eina per a
                localitzar oficines per nom. Mentre escrius, el directori refina
                la visualització per a mostrar només les oficines que
                coincideixen amb la teva entrada, agilitant el teu procés de
                cerca. És important utilitzar aquesta caixa de cerca dins de
                TeamConnect per als resultats més precisos i rellevants, en
                contraposició a la cerca general de Microsoft Teams. Amb aquesta
                funcionalitat de cerca dirigida, pots navegar ràpidament a
                qualsevol oficina a la xarxa de la teva organització.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="34">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                34. Navegant l'índex alfabètic de l'oficina
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Si prefereixes cercar per l'alfabet, el nostre filtre alfabètic
                és una manera ràpida de trobar oficines per nom. A la part
                superior de l'índex de l'oficina, veuràs les lletres de l'A a la
                Z. En fer clic a una lletra, el filtre mostra només aquelles
                oficines que comencen per aquesta lletra, simplificant el procés
                de cerca. Per a reiniciar i veure totes les oficines de nou,
                només deselecciona la lletra activa. Aquesta funció és
                especialment útil quan estàs familiaritzat amb el nom de
                l'oficina però necessites una manera ràpida de localitzar-la
                dins de TeamConnect.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="35">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                35. Revelant perfils d’oficina complets a TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Embassa't en un viatge exploratori pel cor de la presència
                física de la teva organització amb els perfils d'oficina de
                TeamConnect. Fer clic en un nom d'oficina obre un diàleg de
                perfil vibratiu, ple de pestanyes amb detalls exhaustius sobre
                l'oficina:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Vista general:</Body1Strong> Endinsa't en la
                    identitat essencial de l'oficina amb detalls descriptius,
                    des de l'estructura empresarial fins a les hores d'obertura
                    i l'hora local, capturant l'essència de l'ambient d'oficina.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Contactes essencials:</Body1Strong> Accedeix a
                    contactes crucials per a serveis d'emergència i suport
                    extern, cadascun a un clic de distància a través del Sistema
                    Telefònic de Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Contactes d'oficina:</Body1Strong> Contacta amb
                    serveis de suport interns amb facilitat, amb detalls de
                    contacte i opcions d'interacció directa a l'abast de la mà.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Festius públics:</Body1Strong> Mantingues-te
                    informat sobre festius específics de l'oficina, assegurant
                    que els teus plans s'ajustin a les observàncies locals i als
                    horaris de treball.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Horari d'oficina:</Body1Strong> Planifica les
                    teves interaccions amb un coneixement precís de quan
                    l'oficina està plena d'activitat o es calma per al dia.
                  </li>
                </ul>
                Sigui l'energia trepidant d'Austin o el centre estratègic de
                Singapur, cada pestanya ofereix una porta per entendre i
                connectar amb les diverses localitats que conformen la presència
                global de la teva empresa.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="36">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                36. Endinsar-se en la dinàmica de departaments dins d'una
                oficina
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Navegar per la xarxa professional dels departaments d'una
                oficina es desplega amb facilitat a TeamConnect. Aquí tens com
                endinsar-te en els detalls:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Veure Departaments en Aquesta Oficina:
                    </Body1Strong>{" "}
                    Descobreix tots els departaments que formen el teixit de
                    l'oficina seleccionant aquesta opció des del menú Més (...).
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Visió Organitzativa:</Body1Strong> Clica en
                    qualsevol departament per revelar el seu organigrama per a
                    una representació visual de la seva estructura, amb el camí
                    de navegació guiant-te: Oficina {">"} Departament {">"}{" "}
                    Organigrama.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Immersió Departamental:</Body1Strong> Explora
                    més a fons un departament fent clic en el seu menú Més (...)
                    al costat del nom del departament. Aquí, pots accedir a una
                    llista de tots els empleats dins d'aquest departament, dret
                    en el context de l'oficina.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Navegació per Camí de Navegació:</Body1Strong>{" "}
                    Travessa els nivells sense interrupcions. Des dels empleats
                    tornant als departaments o des de l'organigrama, el camí de
                    navegació (Oficina {">"} Departament {">"} Empleat {">"}{" "}
                    Organigrama) manté el teu viatge intuïtiu i en ruta.
                  </li>
                </ul>
                Els camins intricats de TeamConnect t'inviten a explorar,
                entendre i interactuar amb cada capa dels departaments de la
                teva oficina, potenciant-te amb un comandament de navegació
                complet.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="37">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                37. Connectant amb el personal de l'oficina en TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Curiositat sobre les cares darrere una oficina? La resposta és a
                només un clic en TeamConnect. Interactua amb les suspensius
                (tres punts) al costat del nom de la teva oficina escollida per
                revelar un conjunt d'opcions interactives:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Veure Empleats en Aquesta Oficina:
                    </Body1Strong>{" "}
                    Descobreix una llista de tots els professionals que
                    consideren aquesta oficina com a llar de treball. El nombre
                    mostrat ofereix un recompte ràpid dels empleats,
                    convidant-te a endinsar-te en la comunitat de l'oficina.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Opcions de Contacte:</Body1Strong> Cada entrada
                    d'empleat està equipada amb icones d'acció ràpida,
                    permetent-te iniciar xats, trucades o fins i tot
                    videoconferències directament des de TeamConnect, fomentant
                    la comunicació intra-oficina sense interrupcions.
                  </li>
                  <li>
                    <Body1Strong>Navegació:</Body1Strong> Per tornar a la llista
                    d'oficines més àmplia des de la llista d'empleats,
                    simplement fes clic en 'Oficina' al camí de navegació
                    (Oficina {">"} Empleat) i seràs portat de nou a la visió
                    general de l’oficina.
                  </li>
                </ul>
                Amb aquestes característiques, TeamConnect fa que sigui senzill
                visualitzar la distribució de la força laboral a través de les
                oficines i arribar sense esforç, fomentant un lloc de treball
                connectat malgrat la distància.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="38">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                38. Falta o està desactualitzada la informació de la teva
                oficina?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Les actualitzacions de la informació de l'oficina a TeamConnect
                es poden gestionar pels editors d'oficina assignats. Si no estàs
                segur sobre qui són els editors d'oficina o necessites
                assistència amb informació que depèn d'HR, l'equip de suport de
                HR està preparat per ajudar-te a{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>
                .
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Preguntes freqüents sobre configuració</Subtitle2>
      <p></p>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="39">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                39. La importància d'una configuració general precisa a
                TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                En el dinàmic entorn laboral d'avui dia, estar connectats i
                coordinats és més crucial que mai. Aquí és on actualitzar els
                teus Paràmetres Generals a TeamConnect juga un paper pivotal:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Selecció del lloc de treball:</Body1Strong>{" "}
                    Captura l'essència del teu entorn de treball actualitzant el
                    teu Lloc de Treball per reflectir la teva situació actual,
                    sigui un canvi des de la tranquil·litat del teu Home-Office
                    cap a l'ambient vibrant de l'oficina principal, o fins i tot
                    establir-te en una Àrea Pública. Aquesta clarificació del
                    teu lloc de treball ofereix als col·legues una visió no
                    només de les millors formes de contactar-te, sinó també de
                    la naturalesa de les converses que poden tenir amb tu,
                    protegint la confidencialitat de les discussions sensibles
                    especialment quan estàs en el medi obert d'una oficina de
                    clients o assegut en una sala d'espera d'aeroport.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Especificació del lloc de treball:
                    </Body1Strong>{" "}
                    Compartir la teva ciutat de treball fa més que salvar
                    distàncies en una configuració d'equip remot—també obre
                    portes a trobades professionals espontànies o socialització
                    casual. Sigui un cafè ràpid, un dinar tranquil o una reunió
                    de col·laboració, saber que estàs a la mateixa ciutat pot
                    transformar un dia normal en una oportunitat per a
                    connexions cara a cara significatives. Això no només
                    enforteix els vincles de l'equip, sinó que també proporciona
                    una comprensió més rica de les nuances locals que podrien
                    estar influenciant el teu treball.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Exactitud del fus horari:</Body1Strong> A
                    mesura que els equips cobreixen continents, assegurar que el
                    teu fus horari reflecteix la teva ubicació actual és
                    essencial per harmonitzar les comunicacions. El Temps Local
                    del teu perfil de TeamConnect està directament influenciat
                    per aquesta configuració, eliminant qualsevol endevinalles
                    per als col·legues que intenten contactar-te.
                  </li>
                </ul>
                Fes una immersió en 'Configuració' {">"} 'Configuració General'
                dins de TeamConnect per ajustar aquests detalls. Uns moments
                dedicats a actualitzar les teves configuracions poden millorar
                significativament la cohesió i l'eficàcia dels teus esforços
                col·laboratius a tot arreu.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="40">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                40. Desxifrar la selecció de lloc de treball a TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Escollir l'entorn adequat a la llista desplegable de Lloc de
                Treball ajusta TeamConnect per reflectir el teu estat de treball
                actual amb precisió. Passa el ratolí per sobre de la icona
                d'informació per obtenir una explicació detallada de cada opció:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Sortida/Pausa:</Body1Strong> Indica temps fora
                    del treball, sigui per una pausa o el final del dia.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Oficina/Oficina-Terreny:</Body1Strong> Indica
                    presència en un espai de treball gestionat per l'empresa.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Oficina A Casa:</Body1Strong> Treballant des
                    d'un espai domèstic dedicat.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Lloc de Treball Remot/Zona Pública:
                    </Body1Strong>{" "}
                    Operant des d'un entorn que no és una oficina, suggerint la
                    necessitat de comunicació flexible.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Lloc de Treball del Client/Oficina Privada:
                    </Body1Strong>{" "}
                    In situ amb un client, amb una nota sobre els nivells de
                    privacitat per la sensibilitat de la conversa.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Commutes/Viatges de Negocis/Formació:
                    </Body1Strong>{" "}
                    En moviment o participant en activitats laborals
                    específiques fora de l'oficina.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Festiu Públic/Vacances/Various Permisos:
                    </Body1Strong>{" "}
                    Oficialment fora de servei per motius personals, de salut o
                    festius.
                  </li>
                </ul>
                Seleccionar un entorn de treball apropiat assegura que el teu
                equip té el context necessari per a les interaccions, respectant
                la disponibilitat i la privacitat.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="41">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                41. Navegar per l'estatus 'Checked-In' a TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                L'etiqueta 'Checked-In' al Directori d'Empleats està dissenyada
                per mostrar tots els empleats que actualment estan al treball.
                Aquesta visibilitat es determina per la selecció de Lloc de
                Treball que facis:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Entorns de Treball Actius:</Body1Strong> Les
                    opcions Oficina, Oficina-Terreny, Oficina A Casa, Lloc de
                    Treball Remot, Zona Pública, Lloc de Treball del Client,
                    Oficina Privada del Client, Commutes, Viatges de Negocis, i
                    Formació et senyalen com 'Checked-In' i activament visible
                    pels col·legues.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Configuracions No Laborals:</Body1Strong> Optar
                    per Sortida, Pausa, Festiu Públic, Vacances, Permís,
                    Permissionat per Maternitat, o Baixa per Malaltia et treu de
                    la llista de 'Checked-In', establint expectatives clares per
                    a la disponibilitat i temps de resposta.
                  </li>
                </ul>
                Aquesta funcionalitat ajuda a delimitar els períodes de treball
                del temps personal, ajudant en la col·laboració i comunicació
                efectiva de l'equip.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>
        Gestió de dades i preguntes freqüents sobre privacitat
      </Subtitle2>
      <Body1>
        <p>
          A l'era digital d'avui, entendre com es gestionen les vostres dades és
          crucial. TeamConnect està compromès amb la transparència i les
          pràctiques robustes de protecció de dades. A continuació, trobareu
          informació sobre com tractem, emmagatzemem i protegim les vostres
          dades dins de TeamConnect, assegurant que el vostre espai de treball
          digital sigui segur i conforme.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="42">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                42. Com protegeix TeamConnect les meves dades personals?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <p>
                  TeamConnect està dedicat a protegir la vostra informació
                  personal amb un enfocament complet per a la seguretat de
                  dades:
                </p>
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Autenticació única (SSO):</Body1Strong> Per
                    augmentar la seguretat i millorar l'experiència de l'usuari,
                    TeamConnect integra l'autenticació única automàtica (SSO)
                    amb el vostre compte de Microsoft 365, facilitant una gestió
                    d'accés segura i sense esforç.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Xifratge:</Body1Strong> Ens assegurem que totes
                    les dades dins de TeamConnect estiguin xifrades tant en
                    trànsit com en repòs, fent-les inaccessibles per a parts no
                    autoritzades.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Minimització de Dades:</Body1Strong> La nostra
                    recollida de dades està estrictament limitada al que és
                    essencial per a la funcionalitat de TeamConnect. La única
                    informació de l'usuari que emmagatzemem és el vostre Nom
                    Principal d'Usuari (UPN) des d'Azure Entra ID, que podria
                    semblar-se a la vostra adreça de correu electrònic de
                    l'empresa.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Accés Restringit:</Body1Strong> L'accés al UPN
                    està confinat a l'Administrador de Subscripció de la vostra
                    organització dins del nostre portal de Subscripció,
                    assegurant que la informació sensible sigui visible només
                    per al personal autoritzat.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Esborrament de Dades Prompte:</Body1Strong>{" "}
                    TeamConnect està estructurat per esborrar instantàniament
                    l'UPN emmagatzemat sota tres condicions específiques: si la
                    subscripció és cancel·lada per l'Administrador de
                    Subscripció, si un usuari és eliminat per RRHH dins de
                    TeamConnect, o si la subscripció de TeamConnect expira. Això
                    assegura que les vostres dades personals no se retenguin més
                    enllà de la seva vida útil o necessitat.
                  </li>
                </ul>
                <p>
                  A través d'aquestes mesures, TeamConnect es compromet a
                  mantenir els estàndards més alts de protecció de dades,
                  assegurant que la vostra informació personal romangui segura i
                  confidencial.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="43">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                43. Et preguntes on resideixen les teves dades amb TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect trenca els esquemes dels serveis SaaS tradicionals
                assegurant que les teves dades mai abandonin la teva
                organització. A diferència de la pràctica habitual
                d’emmagatzemar dades en servidors en núvol externs, TeamConnect
                aprofita enginyosament el SharePoint Online de Microsoft 365 de
                la teva organització per allotjar totes les dades de
                l'aplicació. Aquest enfocament innovador no només defensa la
                sobirania de les dades sinó que també enforteix la seguretat,
                alineant-se perfectament amb la nostra dedicació ferma a
                protegir la teva privacitat i a complir amb estàndards de
                conformitat rigorosos.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="44">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                44. Les meves dades seran compartides amb tercers?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                No, les dades no es comparteixen amb tercers! Cubeet té un
                compromís ferm amb la privacitat i la seguretat de les teves
                dades. No compartim cap dada de TeamConnect amb tercers sense
                obtenir un consentiment explícit dels nostres clients. En el
                nostre entorn segur, només emmagatzemem identificadors
                essencials, com ara el Nom Principal de l'Usuari (UPN), el nom
                de l'organització, el nom del llogater i l'ID del llogater, dins
                del nostre portal de subscripció. Aquesta informació és crucial
                per a la prestació del servei i està protegida sota les nostres
                polítiques de privacitat estrictes. Pots estar tranquil, les
                nostres pràctiques de gestió de dades estan dissenyades per
                assegurar que les teves dades romanen confidencials, amb
                transparència i control fermament en les teves mans.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="45">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                45. Com compleix TeamConnect amb les normatives de protecció de
                dades com el GDPR?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect s’alinea amb normatives de protecció de dades com el
                GDPR, HIPAA, CCPA i altres, aprofitant les característiques de
                conformitat inherents de l'entorn de Microsoft 365, on totes les
                dades dels clients s’emmagatzemen de manera segura. A diferència
                dels models SaaS tradicionals, TeamConnect no emmagatzema cap
                dada del client als seus servidors; en canvi, opera dins de
                l'entorn de Microsoft 365 de la teva organització, adherint-se
                als seus estàndards de seguretat i conformitat robustos. Aquest
                enfocament assegura que la governança de les teves dades roman
                consistent amb el marc de conformitat integral proporcionat per
                Microsoft 365, incloent l'adherència al GDPR, HIPAA per a la
                informació de salut, CCPA per a residents de Califòrnia, i
                altres regulacions globals de protecció de dades. Integrant-se
                perfectament amb la teva configuració existent de Microsoft 365,
                TeamConnect habilita la teva organització a mantenir el control
                total sobre les teves dades, assegurant el compliment amb les
                lleis i regulacions de protecció de dades més estrictes.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="46">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                46. Com gestiona TeamConnect les dades dels xats, trucades,
                video trucades, correus d'Outlook i trucades del sistema
                telefònic de Microsoft Teams?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect s'aprofita de l'API de Microsoft Graph per habilitar
                interaccions directes com xat, trucades, video trucades i correu
                d'Outlook dins de Microsoft Teams, proporcionant una experiència
                d'usuari fluida. És crucial, mentre TeamConnect inicia aquestes
                comunicacions, ho fa sense intervenir en el procés de
                comunicació ni té la capacitat de gravar o emmagatzemar cap
                contingut de la interacció. Totes les converses, trucades i
                correus es realitzen i es gestionen directament dins els límits
                segurs de Microsoft Teams i Outlook, en estricte adherència als
                estàndards de seguretat i privacitat robustos de Microsoft 365.
                Això assegura que TeamConnect millora les vostres capacitats de
                comunicació mentre preserva completament la confidencialitat i
                integritat de les vostres dades.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Body1>
        <p>
          Per obtenir informació detallada sobre les nostres polítiques i
          pràctiques de dades, també us podeu voler consultar el{" "}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>
              Guia d'Usuari de TeamConnect
            </Body1Strong>
          </Link>{" "}
          o al departament de TI de la vostra organització a{" "}
          <Link href={`mailto:${itSupport}`}>
            <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong>
          </Link>{" "}
          per detalls relacionats amb la vostra configuració.
        </p>
      </Body1>
      <p></p>
      <Subtitle1 className={styles.fontColor}>
        Estem escoltant: Compartiu el vostre Feedback i Idees
      </Subtitle1>
      <Body1>
        <p>
          Les vostres idees impulsen l'evolució de TeamConnect. Tant si esteu
          desbordats amb una idea brillant per una nova funcionalitat com si
          teniu algun comentari sobre la vostra experiència actual, us escoltem.
          La vostra aportació és invaluable en la nostra recerca contínua per
          proporcionar el millor servei possible.
        </p>
      </Body1>
      <Subtitle2>Comentaris i Suggeriments de Funcionalitats</Subtitle2>
      <Body1>
        <p>
          Preparat per compartir? Vingui a la nostra{" "}
          <Link
            style={{ fontWeight: 600 }}
            href={feebackLink}
            target='_blank'>
            Pàgina de Feedback
          </Link>
          . Tant si és una suggerència per una nova funcionalitat com si és un
          comentari sobre funcionalitats existents, simplement seleccioneu
          l'opció adequada del menú desplegable en el formulari. Les vostres
          aportacions ajuden a moldar el futur de TeamConnect, assegurant-se que
          satisfà i supera les vostres necessitats.
        </p>
      </Body1>
    </>
  );
}

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
