import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();

  return (
    <>     
      <Title3 className={styles.fontColor}>TeamConnect Hilfe-Center & FAQs</Title3>
        <p><Body1>
          Willkommen im TeamConnect Hilfe-Center, das darauf ausgerichtet ist, Ihre Erfahrungen mit unserer umfassenden Unternehmensverzeichnis-App zu unterstützen. 
          Hier finden Sie Ressourcen, um Ihre Nutzung von TeamConnect innerhalb von Microsoft Teams zu optimieren und die Kommunikation in Ihrem Netzwerk zu verbessern.
        </Body1></p>

        <Subtitle2>Schnellreferenzleitfaden</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Erweiterte Suchfunktionen: </Body1Strong>
              Finden Sie schnell und effektiv die richtigen Kollegen, Abteilungen und wichtigen Kontakte mit unseren leistungsstarken Suchwerkzeugen.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Visuelle Organigramme: </Body1Strong>
              Navigieren Sie mühelos durch die Hierarchie Ihrer Organisation und verstehen Sie die Berichtsstrukturen durch intuitive Organigramme.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Anpassbare Werkzeuge für heutige Anforderungen: </Body1Strong>
              Nutzen Sie moderne Funktionalitäten, die auf die Bedürfnisse zeitgenössischer digitaler Arbeitsplätze zugeschnitten sind.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Ihre Erfahrung optimieren</Subtitle2>
        <Body1>
          <p>
            TeamConnect revolutioniert die Art und Weise, wie Sie innerhalb Ihrer Organisationsstruktur interagieren, indem es alltägliche Kommunikation 
            in strategische Engagements verwandelt.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Hilfe & FAQs</Subtitle1>
        <Body1>
          <p>
            Finden Sie Antworten auf häufige Fragen zu den Funktionen und Einstellungen von TeamConnect in diesem speziellen Abschnitt. Ob Sie die App navigieren, 
            Einstellungen anpassen oder Lösungen für Herausforderungen suchen, unser Hilfe-Center steht Ihnen effizient zur Seite.
          </p>
        </Body1>
        <Body1>
          <p>
            Für tiefergehende Unterstützung oder spezifische Anleitungen konsultieren Sie bitte das <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnect Benutzerhandbuch</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        <Subtitle2>TeamConnect FAQs verwenden</Subtitle2>
        <p></p>

        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. TeamConnect ist nicht in der Microsoft Teams Seitenleiste zu finden?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Wenn TeamConnect nicht sofort sichtbar oder in Ihrer Microsoft Teams Seitenleiste verschwunden ist, können Sie es leicht anheften, um schnellen Zugriff zu ermöglichen. Folgen Sie einfach diesen einfachen Schritten:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Öffnen Sie Microsoft Teams und klicken Sie auf das '...' (+ Apps)-Symbol am unteren Rand der Seitenleiste.</li>
                    <li className={styles.bulletBottomMargin}>Suchen Sie 'TeamConnect' in der Suchleiste oben im Pop-up-Fenster.</li>
                    <li className={styles.bulletBottomMargin}>Rechtsklicken Sie auf die TeamConnect-App aus den Suchergebnissen und wählen Sie 'Anheften'.</li>
                  </ol>
                  TeamConnect wird nun an Ihre Seitenleiste angeheftet und ist mit nur einem Klick zugänglich!
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Technische Probleme mit TeamConnect? Hier erfahren Sie, wie Sie Hilfe bekommen.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Wenn Sie technische Probleme mit TeamConnect innerhalb von Microsoft Teams haben, steht Ihnen das IT-Support-Team Ihrer Organisation zur Seite. 
                  Wenden Sie sich an sie unter <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, und sie werden Ihnen umgehend helfen.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Finden Sie Ihre Mitarbeiterdaten nicht?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Wenn Ihr Profil mit nur Ihrem Namen und Ihrer E-Mail leer erscheint, hat Ihr HR-Team wahrscheinlich die restlichen Mitarbeiterdaten in TeamConnect noch nicht hinzugefügt.
                  Keine Sorge! Eine kurze Nachricht an Ihr HR-Support-Team setzt alles in Gang. Sie stehen bereit, um Ihr Profil mit allen wichtigen Informationen zu vervollständigen.
                  Wenden Sie sich unter <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> an sie und sehen Sie, wie Ihr Profil zum Leben erwacht!
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Welche Sprachoptionen gibt es in TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect umfasst die globale Vielfalt von Microsoft Teams, indem es alle dessen Sprachen unterstützt.
                  Ob Sie Arabisch oder Griechisch, Japanisch oder Schwedisch bevorzugen, Sie haben eine Welt voller Möglichkeiten zur Auswahl, einschließlich:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arabisch, Bulgarisch, Chinesisch (vereinfacht und traditionell), Kroatisch, Tschechisch, Dänisch, Niederländisch, Englisch, Estnisch, Finnisch,
                      Französisch, Deutsch, Griechisch, Hebräisch, Ungarisch, Indonesisch, Italienisch, Japanisch, Koreanisch, Lettisch, Litauisch, Norwegisch (Bokmål),
                      Polnisch, Portugiesisch (Brasilien und Portugal), Rumänisch, Russisch, Serbisch (Latein), Slowakisch, Slowenisch, Spanisch, Schwedisch, Thailändisch,
                      Türkisch, Ukrainisch, Vietnamesisch.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Um Ihre TeamConnect-Erfahrung an Ihre bevorzugte Sprache anzupassen, passen Sie bitte die Spracheinstellungen direkt in Microsoft Teams an,
                  da sich TeamConnect vollständig in diese Einstellungen integriert:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Gehen Sie zu Ihren Microsoft Teams-Einstellungen, indem Sie auf die drei Punkte neben Ihrem Profilbild oben klicken und dann 'Erscheinungsbild und Zugänglichkeit' auswählen.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Scrollen Sie zum Abschnitt 'Sprache' und wählen Sie Ihre bevorzugte Sprache aus dem Dropdown-Menü.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Bestätigen Sie Ihre Änderungen, indem Sie auf die Schaltfläche 'Speichern und neu starten' klicken, um die neuen Spracheinstellungen anzuwenden.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Hinweis: </Body1Strong> 
                <Body1>
                  Änderungen an Ihren Spracheinstellungen in Microsoft Teams werden nahtlos auf Ihre TeamConnect-Erfahrung übertragen.
                  Beachten Sie, dass die Änderung der Sprache in Teams auch Ihr Zeit- und Datumsformat anpassen kann. Zurzeit entwickelt sich
                  TeamConnect weiter, um Schreiben von rechts nach links zu unterstützen. Wir freuen uns über diese Verbesserungen
                  und werden Sie auf dem Laufenden halten, sobald sie verfügbar sind.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Wie kann ich das Thema in TeamConnect ändern?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect synchronisiert sich mit Ihren Themeneinstellungen in Microsoft Teams, einschließlich Licht, Dunkel und Hoher Kontrast. Um Ihr Thema anzupassen:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Zugriff auf Ihre Microsoft Teams-Einstellungen, indem Sie auf die drei Punkte neben Ihrem Profilbild oben klicken.</li>
                    <li className={styles.bulletBottomMargin}>Wählen Sie 'Erscheinungsbild und Zugänglichkeit', um die Themenoptionen zu erkunden.</li>
                    <li className={styles.bulletBottomMargin}>Wählen Sie Ihr bevorzugtes Thema aus den verfügbaren Optionen aus, um Ihre TeamConnect-Oberfläche sofort zu aktualisieren.</li>
                  </ol>
                  Ihre Themenauswahl in Microsoft Teams wird automatisch auf TeamConnect angewendet und sorgt für ein konsistentes visuelles Erlebnis in Ihrem Arbeitsbereich.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Erkundung der Kontaktaktionsknöpfe von TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Überall in TeamConnect, wo Sie Kontaktdaten eines Mitarbeiters finden, entdecken Sie auch unsere praktischen Schnellzugriffsknöpfe. Diese nützlichen Werkzeuge 
                  sind darauf ausgelegt, Ihre Kommunikation im Microsoft-Ökosystem zu optimieren, sodass Sie immer nur einen Klick entfernt sind, um Verbindung aufzunehmen. 
                  Hier ist Ihr umfassender Leitfaden zur effektiven Nutzung dieser Knöpfe:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chat-Symbol:</Body1Strong> Klicken, um einen sofortigen Microsoft Teams-Chat zu starten. Wenn Sie nach Beginn eines Chats zu TeamConnect zurückkehren müssen, verwenden Sie einfach den Rückwärtspfeil in der Desktop-App, die Zurück-Taste in der mobilen App oder die Zurück-Taste Ihres Browsers.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>E-Mail-Symbol:</Body1Strong> Öffnet einen neuen Outlook-E-Mail-Entwurf mit der Adresse des Empfängers im Feld "An:". Sollte Outlook nicht starten, lohnt es sich, bei Ihrer IT-Abteilung nachzuforschen, ob Einschränkungen vorliegen.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Videoanruf-Symbol:</Body1Strong> Startet einen persönlichen Videoanruf über Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Anruf-Symbol:</Body1Strong> Startet einen Sprachanruf über Microsoft Teams, um schnelle Gespräche nahtlos zu führen.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organigramm-Symbol:</Body1Strong> Zeigt den Platz des Mitarbeiters in der Organisationshierarchie an, einschließlich seines Managers und direkten Untergebenen.</li>
                    <li><Body1Strong>Mehr (...) Symbol:</Body1Strong> Zeigt weitere Optionen:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Sofortiges Treffen:</Body1Strong> Öffnet Microsoft Teams, um sofort ein Meeting mit dem Mitarbeiter zu starten.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Neues Meeting:</Body1Strong> Leitet Sie zu Microsoft Teams weiter, um ein neues Meeting zu planen.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Kontakt hinzufügen:</Body1Strong> Fügt die Kontaktdaten des Mitarbeiters von TeamConnect zur neuen Microsoft Teams People App hinzu, um Ihre Anruffunktion zu verbessern.</li>
                      </ul>
                    </li>
                  </ul>
                  Diese intuitiven Knöpfe sind darauf ausgelegt, Ihnen eine effiziente Verbindung mit Kollegen zu ermöglichen, sei es für einen schnellen Chat, eine E-Mail oder zur Planung zukünftiger Meetings.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Anrufe über TeamConnect tätigen
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Möchten Sie einen Anruf tätigen? TeamConnect optimiert Ihre Kommunikation, indem es in Microsoft Teams integriert wird. 
                  Klicken Sie einfach auf die gewünschte Telefonnummer in TeamConnect, und voilà, die Anruf-Schnittstelle von Microsoft Teams erscheint, 
                  bereit für Sie, um nahtlos anzurufen.
                  <p>
                  <Body1Strong>Hinweis: </Body1Strong> 
                  Probleme beim Tätigen von Anrufen? Dies könnte auf die IT-Richtlinien Ihrer Organisation oder die 
                  Nutzung eines anderen Telefonssystems als das Microsoft Teams Phone System zurückzuführen sein. Ein Gespräch mit Ihrer IT-Abteilung unter <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, 
                  könnte Aufschluss darüber geben.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Gibt es Kosten für Anrufe über TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect verbessert Ihr Anruferlebnis durch die Nutzung von Microsoft Teams. Hier erfahren Sie, was Sie über die Kosten im Zusammenhang mit Anrufen wissen müssen:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Für Anrufe an die Arbeitstelefone von Mitarbeitern, Abteilungstelefone oder Hotlines innerhalb Ihrer Organisation (interne Nummern), 
                      fallen normalerweise <Body1Strong>keine Kosten</Body1Strong> an, wenn das Microsoft Teams Phone System verwendet wird.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Für externe Anrufe, beispielsweise an Mobiltelefone oder externe Leitungen, gelten <Body1Strong>die Standardtarife</Body1Strong> des Telefonsystems Ihrer Organisation. 
                      Diese Tarife variieren je nach Ihrem spezifischen Serviceplan mit Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Hinweis:</Body1Strong> Wenn Sie auf Probleme stoßen oder Fragen zu den Anrufgebühren haben, ist es am besten, sich mit Ihrer 
                    IT-Abteilung unter <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> für detaillierte Informationen, 
                    die auf die Einrichtung und Richtlinien Ihrer Organisation zugeschnitten sind, zu beraten.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Diskrepanz bei Ihrem 'Abwesenheits'-Presence-Status in TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Haben Sie bemerkt, dass Ihr 'Abwesenheits'-Presence-Status von Microsoft 365 nicht korrekt in TeamConnect reflektiert wird? 
                  Wir haben Sie abgedeckt. Die Funktion zur Synchronisierung des 'Abwesenheits'-Presence-Status wurde bereits in TeamConnect implementiert.
                  Derzeit warten wir auf die Einführung dieser Funktion durch Microsoft. Sobald das geschieht, wird Ihr 'Abwesenheits'-Presence-Status 
                  in TeamConnect genau gespiegelt und stimmt mit den Presence-Indikatoren von Microsoft Teams für ein nahtloses Erlebnis überein.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>

        <Subtitle2>Mitarbeiterverzeichnis FAQ</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >Einführung in das Mitarbeiterverzeichnis:</Body1Strong> Navigieren Sie durch die berufliche Landschaft Ihrer Organisation mit dem Mitarbeiterverzeichnis,
                Ihrem zentralen Knotenpunkt für detaillierte Mitarbeiter-Einblicke, Arbeitsplatzdynamiken und direkte Verbindungen zu den Profilen Ihrer Kollegen und deren Managementbeziehungen.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Müssen Sie Ihre Mitarbeiterdaten hinzufügen, aktualisieren oder korrigieren?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Um Ihre Mitarbeiterdaten in TeamConnect hinzuzufügen, zu aktualisieren oder zu korrigieren, wenden Sie sich bitte an das HR-Supportteam Ihrer Organisation. 
                  Sie sind bereit, Ihnen zu helfen bei <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Was passiert, wenn ich auf einen Mitarbeiter in dem Verzeichnis klicke?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Wenn Sie auf den Namen eines Mitarbeiters im TeamConnect Mitarbeiterverzeichnis klicken, öffnet sich eine detaillierte Profilseite für diese Person. Dieses Profil bietet umfassende
                  Einblicke über den Mitarbeiter, einschließlich, aber nicht begrenzt auf, deren Jobbezeichnung, Abteilung, Bürostandort, Kontaktinformationen und mehr. Es ist darauf ausgelegt,
                  Ihnen einen vollständigen Überblick über die berufliche Rolle des Mitarbeiters und dessen Einbindung in die Organisation zu geben, was es Ihnen erleichtert, deren Verantwortlichkeiten zu verstehen und
                  wie Sie möglicherweise mit ihnen zusammenarbeiten könnten.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Welche Informationen sind in einem Mitarbeiterprofil enthalten?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jedes Mitarbeiterprofil in TeamConnect ist eine umfassende Momentaufnahme, die einen Reichtum an Einblicken in das berufliche Leben des Einzelnen bietet.
                  Hier finden Sie:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Grundangaben:</Body1Strong> Name, Jobtitel, Abteilung und Standort der Niederlassung.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Berichtsstruktur:</Body1Strong> Informationen über direkte und funktionelle Berichte,
                      einschließlich Linien- und Funktionsmanager.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Arbeitsplatzstatus:</Body1Strong> Aktueller Arbeitsstatus wie im Urlaub, Pendeln,
                      Geschäftsreise usw.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Arbeitsplatz & lokale Zeit:</Body1Strong> Der aktuelle Arbeitsort und
                      die lokale Zeitzone des Mitarbeiters.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontaktdetails:</Body1Strong> Arbeits-Telefonnummer, Mobilnummer und E-Mail-Adresse.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Büroadresse:</Body1Strong> Vollständiger Bürostandort einschließlich Gebäudename,
                      Adresse und Google-Karte.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Über:</Body1Strong> Eine kurze persönliche Vorstellung oder berufliche Biographie.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Mitarbeiterbeziehungen:</Body1Strong> Details der Berichtsstruktur und Teambeziehungen des Mitarbeiters.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Soziale Links & Sprachen:</Body1Strong> Links zu beruflichen Sozialprofilen
                      und Sprachkenntnisse.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Fähigkeiten:</Body1Strong> Eine Liste von beruflichen Fähigkeiten und Fachgebieten.</li>
                  </ul>
                  Zusätzlich bietet das Profil handlungsfähige Optionen zur Kontaktaufnahme über Chat, E-Mail, Videoanruf oder zur Terminplanung, zusammen mit
                  schnellem Zugriff auf das Organigramm für einen breiteren Teamkontext.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Wie kann ich meine Profilinformationen aktualisieren?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ihr Profil in TeamConnect zu personalisieren ist einfach. Hier erfahren Sie, wie Sie es tun können:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Navigieren Sie zum <Body1Strong>Mitarbeiter</Body1Strong>-Tab und klicken Sie auf Ihr <Body1Strong>Avatar</Body1Strong>, um Ihr Profil zu öffnen.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        In der oberen linken Ecke finden Sie den Link <Body1Strong>"Ihr Profil bearbeiten"</Body1Strong>. 
                        Wenn Sie darauf klicken, öffnet sich der Dialog zum Bearbeiten Ihres Profils.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Hier können Sie Ihr Profil verbessern, indem Sie Ihren Abschnitt <Body1Strong>Über Sie</Body1Strong>, Links zu sozialen Medien und Ihre Telefonnummer hinzufügen oder aktualisieren.
                        Dies erleichtert es Ihren Kollegen, Verbindung aufzunehmen oder Ihnen zu folgen.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Vergessen Sie nicht, auf den <Body1Strong>Speichern</Body1Strong>-Button zu drücken, um Ihre Änderungen anzuwenden.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Scrollen Sie im Dialog nach unten, um Ihre <Body1Strong>Sprachen</Body1Strong> und <Body1Strong>Kompetenzen</Body1Strong>, 
                        sowie Ihre <Body1Strong>Fähigkeiten</Body1Strong> hinzuzufügen.
                        Das Teilen dieser Informationen hilft Ihren Kollegen, Ihre Fachgebiete und die Sprachen, die Sie sprechen, zu verstehen.
                      </li>
                    </ol>
                  Ihr Profil zu aktualisieren hält nicht nur Ihre Informationen auf dem neuesten Stand, sondern fördert auch bessere Verbindungen innerhalb Ihrer Organisation.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Aktualisieren Ihres Arbeitsplatzes, Arbeitsortes und Ihrer Zeitzone in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Es ist entscheidend, Ihre Informationen zu Arbeitsplatz, Arbeitsort und Zeitzone stets aktuell zu halten, um eine nahtlose Zusammenarbeit in Microsoft Teams zu gewährleisten.
                  Diese Aktualisierungen werden sofort in Ihrem TeamConnect-Profil und den Auflistungen reflektiert, sodass Kollegen schnell Ihren Arbeitsort und die besten Zeiten zur Kontaktaufnahme verstehen können, 
                  was die Teamarbeit über verschiedene Regionen und Zeitzonen hinweg verbessert.
                  <p></p>
                  Bereit, Ihre Daten zu aktualisieren? Es ist einfach in TeamConnect! Gehen Sie zu 'Einstellungen' im oberen Menü und navigieren Sie zu 'Allgemeine Einstellungen'. 
                  Hier können Sie Ihren Arbeitsplatz und Ihre Zeitzone problemlos aktualisieren und Ihren Arbeitsort für den Tag festlegen. Vergessen Sie nicht, Ihre Änderungen zu speichern, 
                  um alle informiert zu halten und eine effektive Zusammenarbeit zu gewährleisten, egal wo Sie sich befinden!
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Etwas Ungewöhnliches im Profil eines Kollegen entdeckt?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Wenn Sie auf Details im Profil eines Kollegen stoßen, die nicht ganz richtig erscheinen, ist der beste erste Schritt, ihnen freundlich Bescheid zu 
                  geben. Ein kurzer Chat oder eine E-Mail sollten ausreichen! Falls aus irgendeinem Grund die Informationen nicht aktualisiert werden, machen Sie sich 
                  keine Sorgen—schreiben Sie einfach Ihrem HR-Support-Team unter <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, 
                  und sie werden gerne eingreifen und die Dinge klären.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Verständnis von Tag-Gruppen: Alle, Favoriten, Eingecheckt & Neue Mitarbeiter
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Alle:</Body1Strong> Dieser Standardtag zeigt jeden Mitarbeiter in Ihrer Organisation an, 
                      und bietet eine umfassende Übersicht über das Team.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoriten:</Body1Strong> Klicken Sie hier, um Ihre Ansicht auf jene Kollegen zu filtern, die Sie als 'Favoriten' markiert haben,
                      was das schnelle Verbinden mit wichtigen Kontakten erleichtert.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Eingecheckt:</Body1Strong> Dieser Tag zeigt an, dass Mitarbeiter aktiv bei der Arbeit sind, im Unterschied zur bloßen Online-Präsenz in Microsoft Teams. Wenn Mitarbeiter eingecheckt sind, signalisieren sie, dass sie bei der Arbeit sind und teilen ihren aktuellen Arbeitsort während des Tages. Dies kann eine Vielzahl von Arbeitsorten umfassen, wie Büro, Außenstelle, Home-Office, Remote-Arbeitsplatz, Kunden-Arbeitsplatz,
                      Kunden-Privatbüro, öffentlicher Bereich, Pendeln, Geschäftsreise oder sogar Weiterbildung. Daher bietet der Status 'Eingecheckt' eine klare Indikation dafür, dass man aktiv bei der Arbeit ist, was Kollegen ein präzises Verständnis vom aktuellen Arbeitskontext und Verfügbarkeit der anderen bietet.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Neue Mitarbeiter:</Body1Strong> Erkennen Sie neue Gesichter in TeamConnect mit diesem Tag, der 
                      Mitarbeiter hervorhebt, die innerhalb der letzten 30 Tage zur App hinzugefügt wurden. In der Regel entspricht dies den jüngsten Zugängen in Ihrer Organisation und hilft Ihnen, neue Teammitglieder willkommen zu heißen.
                    </li>
                  </ul>
                  Diese Tag-Gruppen sind darauf ausgelegt, die Navigation zu vereinfachen und Ihr TeamConnect-Erlebnis zu verbessern, damit Sie immer effizient mit Ihren Kollegen in Verbindung treten können.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Die Bedeutung der Symbole neben dem Suchfeld
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Das Mitarbeiterverzeichnis von TeamConnect ist für Vielseitigkeit und Benutzerfreundlichkeit gestaltet, mit vier Symbolen, die verschiedene Ansichten 
                  bieten und schnellen Zugriff auf wichtige Details ermöglichen. Hier ist, was jedes Symbol offenbart:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Listenansicht:</Body1Strong> Ihre Standardansicht, die alle Mitglieder der Organisation 
                      in einer klaren Liste darstellt.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kleine Kartenansicht:</Body1Strong> Eine kompakte Alternative, diese Ansicht verwandelt die 
                      Liste in kleine Mitarbeiterkarten.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Große Kartenansicht:</Body1Strong> Wählen Sie mehr Details mit größeren Karten, die 
                      zusätzliche Kontaktmöglichkeiten wie Telefon- und Mobilnummern für einfaches Wählen beinhalten.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organigramm:</Body1Strong> Visualisieren Sie die Struktur des Unternehmens mit der Ansicht des Organigramms, 
                      ideal, um Berichtsbeziehungen zu verstehen und sich in der Organisationslandschaft zu orientieren.</li>
                  </ol>
                    Wechseln Sie zwischen diesen Symbolen, um die Ansicht zu finden, die in dem Moment am besten zu Ihren Bedürfnissen passt, egal ob Sie schnell Informationen suchen oder tiefergehende Einblicke wünschen.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Wo sollte ich suchen, um schnell einen Kollegen zu finden?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Auf der Suche nach einem Kollegen? Gehen Sie einfach zur TeamConnect-Suchbox, die sich oben rechts neben dem Symbol des Organigramms befindet. 
                  Beginnen Sie, ihren Namen einzutippen, und beobachten Sie, wie sich die Ergebnisse mit jedem Buchstaben verfeinern. Denken Sie daran, für Suchvorgänge bei TeamConnect die Microsoft Teams Suchleiste oben zu überspringen – unsere spezielle Suchbox ist der richtige Weg!
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Wie funktioniert die alphabetische Suche bei der Suche nach Mitarbeitern?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Das Mitarbeiterverzeichnis von TeamConnect beinhaltet ein praktisches alphabetisches Suchfeature, das Ihnen hilft, Mitarbeiter schnell nach ihrem Vornamen zu lokalisieren. 
                  Klicken Sie einfach auf einen Buchstaben des Alphabets, der oben im Verzeichnis angezeigt wird, und TeamConnect zeigt Ihnen sofort alle Mitarbeiter an, deren Vornamen mit diesem Buchstaben beginnen. Um zur vollständigen Liste der Mitarbeiter zurückzukehren, wählen Sie den hervorgehobenen Buchstaben einfach ab. Dieses intuitive Feature macht das Navigieren durch große Mitarbeiterlisten zum Kinderspiel und stellt sicher, dass Sie Kollegen schnell und effizient finden können.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
                  
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Wie kann ich die Anzahl der pro Seite angezeigten Mitarbeiter anpassen?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect ermöglicht es Ihnen, die Anzahl der pro Seite angezeigten Mitarbeiterprofile anzupassen, was das Durchblättern des Mitarbeiterverzeichnisses erleichtert. 
                  Am unteren Ende jeder Seite finden Sie Paginierungsoptionen: 10, 50, 100, 500, 1000 und 5000. Die Standardeinstellung zeigt 10 Profile pro Seite. 
                  Um mehr Profile auf einer einzigen Seite anzuzeigen, wählen Sie einfach Ihre bevorzugte Anzahl aus diesen Optionen aus. Diese Funktion bietet Flexibilität bei der Ansicht des Verzeichnisses und ermöglicht es Ihnen, die Anzeige nach Ihren spezifischen Bedürfnissen oder Vorlieben anzupassen.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Wie sortiere ich Mitarbeiterinformationen in der Listenansicht?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  In der Mitarbeiterverzeichnis-Listenansicht von TeamConnect haben Sie die Flexibilität, Mitarbeiterinformationen nach Ihren Bedürfnissen zu sortieren. Die Spalten—Mitarbeitername, 
                  Berufsbezeichnung, Abteilung, Bürostandort und Arbeitsplatz—sind alle sortierbar. Klicken Sie einfach auf die Spaltenüberschrift, nach der Sie sortieren möchten. Ein Klick sortiert 
                  die Spalte in aufsteigender Reihenfolge und ein weiterer Klick sortiert sie in absteigender Reihenfolge. Standardmäßig ist das Verzeichnis nach Mitarbeitername in aufsteigender Reihenfolge, 
                  basierend auf dem Vornamen, sortiert. Diese Sortierfunktion hilft Ihnen, Mitarbeiterdetails schnell zu organisieren und anzusehen, ganz gleich, ob Sie eine bestimmte Person suchen oder 
                  Mitarbeiter nach einem bestimmten Kriterium wie Abteilung oder Standort gruppieren müssen.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

          </Accordion>
        </div>
        <p></p>

        <Subtitle2>Häufig gestellte Fragen zum Abteilungsverzeichnis</Subtitle2>
        <Body1>
          <p>
          <Body1Strong>Einführung in das Abteilungsverzeichnis:</Body1Strong> Das Abteilungsverzeichnis ist Ihr Zugang zum Verständnis des komplexen Netzwerkes Ihrer 
            Organisationsstruktur, das eine klare Sicht auf Abteilungshierarchien, Führungsverhalten und Wege für reibungslose Abteilungskommunikation bietet.
          </p>
        </Body1>

        <div>
        <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>

          <AccordionItem value="22">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                22. Wie funktionieren die Tags 'Alle', 'Favoriten' und 'Ihre Abteilung' im Abteilungsverzeichnis?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Im Abteilungsverzeichnis von TeamConnect helfen Tags Ihnen, schnell durch Abteilungsinformationen zu navigieren und sie zu filtern. Hier ist, was jedes Tag darstellt:
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Alle:</Body1Strong> Dieses Tag zeigt alle Abteilungen innerhalb Ihrer Organisation und bietet eine 
                    umfassende Übersicht.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Favoriten:</Body1Strong> Verwenden Sie dieses Tag, um eine kuratierte Liste von Abteilungen anzusehen, die Sie 
                    als 'Favoriten' markiert haben, was den Zugang zu häufig kontaktierten Abteilungen erleichtert.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Ihre Abteilung:</Body1Strong> Dieses Tag filtert die Ansicht, um nur Ihre Abteilung anzuzeigen, 
                    ermöglicht schnellen Zugriff auf unmittelbare Abteilungsinformationen und Aktualisierungen.</li>
                </ul>
                Diese Tags sind dafür konzipiert, Ihre Navigation im Abteilungsverzeichnis zu vereinfachen und Ihre Fähigkeit zu verbessern, effizient mit Abteilungsinformationen umzugehen.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="23">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                23. Verständnis der Ansichten im Abteilungsverzeichnis in TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Das Navigieren im Abteilungsverzeichnis von TeamConnect ist mit mehreren Ansichtsoptionen optimiert, die Ihren Vorlieben entsprechen. Jede Ansicht bietet eine 
                einzigartige Möglichkeit, umfassende Abteilungsinformationen zu erkunden, einschließlich Abteilungsname, Abteilungsleiter, übergeordnete Abteilung, Abteilungstelefon, 
                Abteilungshotline und Abteilungs-E-Mail. Hier ist ein Überblick über die verfügbaren Ansichten:
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Listenansicht:</Body1Strong> Die Standardkonfiguration, die alle Abteilungen ordentlich auflistet, bietet eine 
                    klare, prägnante Übersicht.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Großansicht für Karten:</Body1Strong> Wechseln Sie zu dieser Ansicht, um detaillierte Abteilungskarten zu sehen, die 
                    eine erweiterte Zusammenfassung der wichtigsten Informationen jeder Abteilung bieten.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Org-Chart-Ansicht:</Body1Strong> Für eine visuelle Darstellung der Abteilungsstruktur und 
                    -beziehungen zeigt die Org-Chart-Ansicht, wie jede Abteilung innerhalb der größeren Organisationshierarchie passt.
                  </li>
                </ul>
                Zum Wechseln zwischen diesen Ansichten verwenden Sie einfach die Symbole neben dem Suchfeld oben im Abteilungsverzeichnis. Jede Ansicht ist darauf ausgelegt, Ihnen zu helfen, 
                Abteilungsdetails leicht zugänglich zu machen und zu verstehen, und stellt sicher, dass Sie die Informationen, die Sie benötigen, im für Sie passenden Format finden.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="24">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                24. Wie die Suchleiste im Abteilungsverzeichnis verwenden
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Finden Sie nahtlos jede Abteilung innerhalb von TeamConnect mit der speziell dafür vorgesehenen Suchleiste, die sich direkt neben den Ansichtssymbolen oben im 
                Abteilungsverzeichnis befindet. Beginnen Sie einfach, den Namen der Abteilung einzutippen, nach der Sie suchen, und beobachten Sie, wie das Verzeichnis sich 
                verfeinert und nur relevante Treffer anzeigt. Diese Funktion ist speziell dafür ausgelegt, Ihnen zu helfen, die Notwendigkeit des manuellen Durchsuchens des 
                gesamten Verzeichnisses zu umgehen und bringt die gesuchte Abteilung direkt an Ihre Fingerspitzen. Denken Sie daran, dass diese Suchfunktion einzigartig für das 
                Abteilungsverzeichnis von TeamConnect ist, also nutzen Sie diese Suchleiste anstatt der Haupt-Microsoft Teams Suche ganz oben in der Teams-Oberfläche für 
                abteilungsspezifische Anfragen.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="25">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                25. Wie funktioniert der alphabetische Filter im Abteilungsverzeichnis?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Optimieren Sie Ihre Suche im Abteilungsverzeichnis mit dem alphabetischen Filter, der sich oben befindet. Diese Funktion ermöglicht es Ihnen, schnell Abteilungen 
                zu filtern, indem Sie einen Buchstaben auswählen. Sobald Sie einen Buchstaben ausgewählt haben, filtert TeamConnect und zeigt nur jene Abteilungen an, deren Namen 
                mit Ihrem gewählten Buchstaben beginnen, was Ihnen hilft, schneller zu finden, was Sie suchen, ohne durch die ganze Liste scrollen zu müssen. Um zur vollständigen 
                Ansicht der Abteilungen zurückzukehren, heben Sie einfach die Auswahl des hervorgehobenen Buchstabens auf. Diese intuitive alphabetische Navigation sorgt dafür, 
                dass Sie effizient jede Abteilung anhand ihres Anfangsbuchstabens finden können.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="26">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                26. Wie kann ich den Abteilungsleiter kontaktieren?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Den Abteilungsleiter in TeamConnect zu kontaktieren ist einfach. Lokalisieren Sie die interessierende Abteilung und klicken Sie auf den Namen des Managers. Diese 
                Aktion löst ein Popover aus, das die Kontaktmöglichkeiten des Managers mit den vertrauten Schnellzugriff-Symbolen anzeigt:
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Chat-Symbol:</Body1Strong> Um eine sofortige Nachricht über den Microsoft Teams Chat zu starten.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>E-Mail-Symbol:</Body1Strong> Um eine neue E-Mail in Microsoft Outlook zu öffnen, die an sie/ihn adressiert ist.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Videoanruf-Symbol:</Body1Strong> Um einen Videoanruf über Microsoft Teams zu starten.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Anruf-Symbol:</Body1Strong> Um einen Sprachanruf über Microsoft Teams zu beginnen.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Mehr (...)-Symbol:</Body1Strong> Für zusätzliche Optionen wie das Planen eines Treffens oder das 
                    Hinzufügen ihrer Kontaktdaten zu Ihrer Teams-Liste.
                  </li>
                </ul>
                Diese Symbole bieten eine nahtlose Methode, um direkt aus TeamConnect heraus mit den Abteilungsleitern in Kontakt zu treten und so eine effiziente Kommunikation 
                innerhalb Ihrer Organisation zu gewährleisten.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="27">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                27. Wie kann ich Mitarbeiter innerhalb einer Abteilung einsehen oder kontaktieren?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Um herauszufinden, wer wer innerhalb einer Abteilung in TeamConnect ist, schauen Sie nicht weiter als die drei Punkte neben dem Abteilungsnamen. Ein Klick darauf 
                wird Optionen für weitere Aktionen eröffnen:
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Mitarbeiter in dieser Abteilung ansehen:</Body1Strong> Wählen Sie dies, um eine Liste aller 
                    Mitarbeiter, die der Abteilung zugeordnet sind, anzuzeigen. Die Nummer neben dieser Option zeigt an, wie viele Mitarbeiter sich in der Abteilung befinden und bietet eine schnelle Kopfzahl.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Organigramm der Abteilung:</Body1Strong> Wenn Sie die Hierarchie der Abteilung verstehen möchten, 
                    wählen Sie 'Organigramm der Abteilung', um das strukturelle Layout zu enthüllen, das die Beziehung zwischen übergeordneten Abteilungen, der betroffenen Abteilung, 
                    und eventuellen Unterabteilungen zeigt. Diese visuelle Darstellung hilft Ihnen, das Organisationsgerüst zu navigieren und zu verstehen, sodass es einfacher wird, 
                    bestimmte Abteilungsmitglieder zu identifizieren und zu kontaktieren.
                  </li>
                  <li><Body1Strong>Abteilung {'>'} Mitarbeiter:</Body1Strong> Nachdem Sie die Mitarbeiter innerhalb einer Abteilung eingesehen haben, nutzen Sie diesen Breadcrumb-Pfad, um leicht 
                    zurück zur Liste der Abteilungen zu navigieren und so ein flüssiges Erkundungserlebnis innerhalb von TeamConnect beizubehalten.
                  </li>
                </ul>
                Diese Funktionen erlauben es Ihnen nicht nur, die Liste der Mitarbeiter in einer bestimmten Abteilung zu sehen, sondern bieten auch einen Weg, sie direkt über die Kommunikationswerkzeuge von TeamConnect zu erreichen.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="28">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                28. Entdecken Sie detaillierte Abteilungsinformationen in TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Erforschen Sie die Einzelheiten jeder Abteilung mit einem einfachen Klick auf ihren Namen in TeamConnect. Ein detaillierter Dialog entfaltet sich und bietet ein reichhaltiges Informationsgeflecht,
                das ein vollständiges Bild der Rolle und der Ressourcen der Abteilung in Ihrer Organisation vermittelt. Sie finden alles von Führungskräften und 
                finanziellen Kennzeichnern bis hin zu Kommunikationskanälen und sozialen Verknüpfungen. Diese Funktion ist Ihr Tor zu:
                <ul>
                  <li className={styles.bulletBottomMargin}>Das Herzstück der Abteilung – ihr Zweck, ihre Menschen und Leistungskennzahlen.</li>
                  <li className={styles.bulletBottomMargin}>Kontaktwege – direkte Verbindungen zu wichtigen Abteilungsfiguren und Kommunikationsplattformen.</li>
                  <li className={styles.bulletBottomMargin}>Das Organisationsnetz – Verständnis, wie die Abteilung sich in die breitere Unternehmensstruktur integriert.</li>
                </ul>
                Ob es sich um eine schnelle Anfrage oder eine tiefgehende Untersuchung von Abteilungsdaten handelt, TeamConnect bietet Ihnen die Werkzeuge, um mit den einzigartigen 
                Ökosystemen innerhalb Ihrer Organisation in Verbindung zu treten und diese zu verstehen.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="29">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                29. Neugierig, warum es keine Abteilungsadressen in TeamConnect gibt?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Die Lokalisation einer Abteilung zu ergründen kann manchmal wie eine Schatzsuche ohne Karte wirken. In TeamConnect findest du normalerweise keine spezifische 
                Adresse für jede Abteilung. Hier ist der Grund, warum dieses kartographische Detail oft weggelassen wird:
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Mehrere Standorte:</Body1Strong> Viele Abteilungen sind nicht auf einen einzelnen Raum beschränkt; sie
                    florieren in verschiedenen Büros und Geografien.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Fluide Struktur:</Body1Strong> Die Organisationslandschaft ist ständig im Wandel, wobei
                    Abteilungen sich entwickeln, fusionieren oder sogar im Einklang mit strategischen Veränderungen auflösen.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Abteilungsübergreifende Zusammenarbeit:</Body1Strong> Moderne Arbeitsräume sind erfüllt vom Geist der Zusammenarbeit,
                    oft werden Talente aus verschiedenen Abteilungen in gemeinsamen Räumen zusammengebracht.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Arbeitskultur:</Body1Strong> Mit der Flexibilität der Fernarbeit sind Abteilungsanker nicht immer
                    in physischen Bürostandorten verankert.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Rechtliche Nuancen:</Body1Strong> Abteilungen kreisen typischerweise um die rechtliche Struktur des Unternehmens
                    statt eigene unabhängige Adressen zu haben.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Operationelle Straffung:</Body1Strong> Der Fokus auf dynamischer Teamarbeit, nicht statischen
                    Adressen, hilft dabei, operationelle Agilität zu bewahren.
                  </li>
                </ul>
                Es geht darum, eine grenzenlose Herangehensweise an Teamarbeit und Effizienz zu umarmen. TeamConnect spiegelt diese Philosophie wider, indem es Verbindungen über
                Standorte hinweg betont.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="30">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                30. Fehlen Ihre Abteilungsinformationen oder sind sie veraltet?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Abteilungseditoren sind innerhalb Ihrer Organisation dafür zuständig, abteilungsspezifische Informationen in TeamConnect hinzuzufügen, zu aktualisieren oder zu korrigieren.
                Wenn Sie unsicher sind, wer Ihr Abteilungseditor ist oder ob bestimmte Informationen das Eingreifen des HR-Support-Teams erfordern,
                bitte kontaktieren Sie <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> für weiterführende Beratung.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>

      <Subtitle2>Büroverzeichnis-FAQs</Subtitle2>
        <Body1>
          <p>
            <Body1Strong>Einführung in das Büroverzeichnis:</Body1Strong> Entdecken Sie das Rückgrat der physischen Präsenz Ihrer Organisation durch das Büroverzeichnis,
              welches wesentliche Bürodetails, betriebliche Besonderheiten und einen umfassenden Einblick in die Abteilungen und das Personal umfasst, die jeden Standort definieren.
          </p>
        </Body1>
      <div>
        <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
          <AccordionItem value="31">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                31. Navigation durch Tags im Büroverzeichnis
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Finden Sie sich mühelos im Büroverzeichnis zurecht, indem Sie unser Tag-System nutzen, das für schnellen Zugriff auf den richtigen Standort entwickelt wurde. Hier ist, wie jedes Tag Ihre Suche vereinfachen kann:
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Alle:</Body1Strong> Zeigt eine vollständige Auflistung der Büros, die Ihnen einen Überblick über 
                    die Standorte Ihrer Organisation bietet.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Favoriten:</Body1Strong> Hilft Ihnen, die Bürostandorte zu priorisieren, die Sie häufig besuchen oder besonders im Auge behalten müssen, indem Sie diese als 'Favoriten' markieren.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Ihr Büro:</Body1Strong> Dieses persönliche Tag filtert das Verzeichnis, um Ihnen den Bürostandort anzuzeigen, an dem Sie stationiert sind, und hält Ihre Basis stets griffbereit.
                  </li>
                </ul>
                Ob Sie einen Besprechungsraum buchen oder einen Besuch planen, diese Tags bieten einen personalisierten und vereinfachten Weg, um mit der geografischen Landschaft Ihrer Organisation zu interagieren.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="32">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                32. Beherrschung der Büroverzeichnisansichten in TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Tauchen Sie in die organisatorische Struktur der verschiedenen Standorte Ihres Unternehmens ein mit TeamConnects Büroverzeichnisansichten. Jede Ansicht ist darauf ausgelegt, einen einzigartigen Aspekt der Büronavigation und des Verständnisses zu erleichtern:
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Listenansicht:</Body1Strong> Die Standardeinstellung, die alle Bürostandorte übersichtlich in 
                    einer leicht lesbaren Liste organisiert.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Große Kartenansicht:</Body1Strong> Die Große Kartenansicht im Büroverzeichnis bereichert Ihre 
                    Sicht mit einer detaillierten Darstellung jedes Büros. Wenn Sie mit einer Bürokarte interagieren, finden Sie zahlreiche Informationen und ausführbare Optionen 
                    im "Mehr (...)"-Menü:
                    <ol>
                      <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Abteilungen ansehen:</Body1Strong> Navigieren Sie direkt zu einer Liste
                        aller Abteilungen innerhalb des Büros.
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>Mitarbeiter ansehen:</Body1Strong> Greifen Sie auf eine vollständige Liste der Mitarbeiter zu, die 
                        dem Büro zugeordnet sind, was direkten Kontakt oder die Erkundung des Büropersonals erleichtert.
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>Organigramm der Geschäftseinheit:</Body1Strong> Visualisieren Sie den Platz des Büros innerhalb des größeren
                        organisatorischen Kontexts für ein klares Verständnis der hierarchischen Strukturen.
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>Wichtige Kontakte:</Body1Strong> Identifizieren Sie Schlüsselkontakte im Büro für eine optimierte 
                        Kommunikation.
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>Öffentliche Feiertage des Büros:</Body1Strong> Informieren Sie sich über die bürospezifischen öffentlichen 
                        Feiertage, um Ihre Zeitpläne entsprechend zu planen.
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>Büroarbeitszeiten:</Body1Strong> Sehen Sie sich die Betriebszeiten des Büros an, um Ihre 
                        Besuche oder Anrufe auf deren aktive Zeiten abzustimmen.
                      </li>
                    </ol>
                    Diese Ansicht bietet nicht nur einen visuellen und informativen Schnappschuss, sondern auch schnellen Zugriff auf tiefere Einblicke und direkte Aktionen in Bezug auf das Büro, alles ordentlich unter der Option "Mehr (...)" für Ihre Bequemlichkeit untergebracht.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Organigrammansicht:</Body1Strong> Visualisieren Sie die hierarchische Struktur von übergeordneten und untergeordneten Büros,
                    um die Vernetzung verschiedener Bürostandorte innerhalb Ihrer Organisation zu verstehen.
                  </li>
                </ul>
                Wechseln Sie mithilfe der Symbole neben dem Suchfeld durch diese Ansichten, um Ihre Browsing-Erfahrung Ihren unmittelbaren Bedürfnissen anzupassen.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="33">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                33. Schnelles Finden von Büros mit der Suchfunktion von TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Das Auffinden der richtigen Büro-Location innerhalb von TeamConnect wird durch die spezielle Suchfunktion vereinfacht. Prominent neben den Ansichtssymbolen platziert, ist das Suchfenster Ihr Werkzeug, um Büros nach Namen zu lokalisieren. Während Sie tippen, verfeinert das Verzeichnis die Anzeige, sodass nur Büros angezeigt werden, die Ihrer Eingabe entsprechen, und vereinfacht so Ihren Suchprozess. Es ist wichtig, dieses Suchfeld innerhalb von TeamConnect zu verwenden, um die genauesten und relevantesten Ergebnisse zu erzielen, im Gegensatz zur allgemeinen Suche von Microsoft Teams. Mit dieser gezielten Suchfunktionalität können Sie schnell zu jedem Büro im Netzwerk Ihrer Organisation navigieren.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="34">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                34. Navigieren im Büroverzeichnis nach Alphabet
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Wenn Sie eine Suche nach Alphabet bevorzugen, ist unser alphabetischer Filter eine schnelle Möglichkeit, Büros nach Namen zu finden. Am Anfang des Büroverzeichnisses sehen Sie die Buchstaben A bis Z. Ein Klick auf einen Buchstaben filtert die Ansicht, sodass nur Büros angezeigt werden, die mit diesem Buchstaben beginnen, und vereinfacht so Ihren Suchprozess. Um zurückzusetzen und alle Büros erneut anzuzeigen, deaktivieren Sie einfach den aktiven Buchstaben. Diese Funktion ist besonders nützlich, wenn Sie den Namen des Büros kennen, aber einen schnellen Weg suchen, es innerhalb von TeamConnect zu finden.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="35">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                35. Enthüllung umfassender Büroprofile in TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Beginnen Sie eine Entdeckungsreise durch das Herz der physischen Präsenz Ihrer Organisation mit den Büroprofilen von TeamConnect. Ein Klick auf einen Büronamen öffnet einen lebendigen Profildialog, voll mit Tabs, die detaillierte Einblicke in das Büro bieten:
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Übersicht:</Body1Strong> Tauchen Sie in das Kernstück der Identität des Büros ein mit detaillierten Beschreibungen von der Geschäftsstruktur bis zu den Öffnungszeiten und der lokalen Uhrzeit, die das Wesen des Büroumfelds einfangen.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Wichtige Kontakte:</Body1Strong> Erhalten Sie Zugang zu wichtigen Kontakten für Notdienste und externe Unterstützung, jeweils nur einen Klick entfernt durch das Microsoft Teams Phone System.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Bürokontakte:</Body1Strong> Nehmen Sie mit internen Supportdiensten leicht Kontakt auf, mit Kontaktinformationen und direkten Interaktionsoptionen direkt zur Hand.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Öffentliche Feiertage:</Body1Strong> Bleiben Sie über bürospezifische Feiertage informiert, sodass Ihre Pläne mit den lokalen Gedenktagen und Arbeitszeitplänen übereinstimmen.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Bürozeiten:</Body1Strong> Planen Sie Ihre Interaktionen mit genauer Kenntnis darüber, wann das Büro mit Aktivität brummt oder für den Tag zur Ruhe kommt.
                  </li>
                </ul>
                Ob es sich um die pulsierende Energie von Austin oder das strategische Zentrum von Singapur handelt, jeder Tab bietet ein Tor, um die vielfältigen Standorte zu verstehen und sich mit ihnen zu verbinden, die die globale Präsenz Ihres Unternehmens ausmachen.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="36">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                36. Einblick in die Abteilungsdynamik innerhalb eines Büros
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Das Navigieren im professionellen Netzwerk der Abteilungen eines Büros entfaltet sich mühelos in TeamConnect. Hier erfahren Sie, wie Sie in die Details eintauchen:
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Abteilungen in diesem Büro anzeigen:</Body1Strong> Entdecken Sie alle Abteilungen, die das 
                    Gefüge des Büros bilden, indem Sie diese Option aus dem Mehr (...) Menü wählen.
                  </li>                      
                  <li className={styles.bulletBottomMargin}><Body1Strong>Organisationseinblick:</Body1Strong> Klicken Sie auf eine beliebige Abteilung, um deren Organigramm anzuzeigen, 
                    das eine visuelle Darstellung ihrer Struktur bietet, wobei die Brotkrümelnavigation Sie führt: Büro {'>'} Abteilung {'>'} Diagramm.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Vertiefende Abteilungsuntersuchung:</Body1Strong> Erforschen Sie eine Abteilung weiter, indem Sie auf ihr 
                    Mehr-Menü (...) neben dem Abteilungsnamen klicken. Hier können Sie eine Liste aller Mitarbeiter innerhalb dieser Abteilung, direkt im Kontext des Büros, abrufen.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Brotkrümelnavigation:</Body1Strong> Durchlaufen Sie die Ebenen nahtlos. Von 
                    Mitarbeitern zurück zu Abteilungen oder vom Organigramm, die Brotkrümelnavigation (Büro {'>'} Abteilung {'>'} Mitarbeiter {'>'} Diagramm) hält Ihre 
                    Reise intuitiv und auf Kurs.
                  </li>
                </ul>
                Die komplexen Pfade von TeamConnect laden Sie ein, jede Ebene der Abteilungen Ihres Büros zu erkunden, zu verstehen und sich zu engagieren, und verleihen Ihnen 
                umfassende Navigationsbefehle.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="37">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                37. Vernetzung mit Büropersonal in TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Neugierig auf die Gesichter hinter einem Büro? Die Antwort ist nur einen Klick entfernt in TeamConnect. Engagieren Sie sich mit den Auslassungspunkten (drei Punkte) neben Ihrem gewählten 
                Büronamen, um eine Reihe interaktiver Optionen zu enthüllen:
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Mitarbeiter in diesem Büro anzeigen:</Body1Strong> Entdecken Sie eine Liste aller Fachkräfte, 
                    die dieses Büro als ihre Arbeitsheimat betrachten. Die angezeigte Zahl bietet eine schnelle Zählung der Mitarbeiter und lädt Sie ein, in die Bürogemeinschaft einzutauchen.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Kontaktoptionen:</Body1Strong> Jeder Mitarbeitereintrag ist mit Schnellaktions-Symbolen ausgestattet, 
                    die es Ihnen ermöglichen, Chats, Anrufe oder sogar Videokonferenzen direkt über TeamConnect zu starten und so die Kommunikation innerhalb des Büros nahtlos zu fördern.
                  </li>
                  <li><Body1Strong>Navigation:</Body1Strong> Um von einer Mitarbeiterliste zur breiteren Büroliste zurückzukehren, klicken Sie einfach auf 'Büro' in der Brotkrümelnavigation 
                    (Büro {'>'} Mitarbeiter), und Sie werden zurück zur Büroübersicht gebracht.
                  </li>
                </ul>
                Mit diesen Funktionen macht es TeamConnect einfach, die Verteilung der Belegschaft über die Büros hinweg zu visualisieren und mühelos Kontakt aufzunehmen, 
                was einen vernetzten Arbeitsplatz fördert, unabhängig von der Entfernung.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="38">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                38. Fehlen Ihre Büroinformationen oder sind sie veraltet?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Aktualisierungen der Büroinformationen in TeamConnect können von zugewiesenen Büroeditoren gehandhabt werden. Wenn Sie sich über die Büroeditoren unsicher sind oder 
                Hilfe bei Informationen benötigen, die in den Zuständigkeitsbereich der Personalabteilung fallen, steht das HR-Support-Team bereit, um zu 
                helfen unter <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
              </Body1>
            </AccordionPanel>
          </AccordionItem>            
        </Accordion>
      </div>
      <p></p>

      <Subtitle2>Einstellungen FAQ</Subtitle2>
      <p></p>
      <div>
        <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
          <AccordionItem value="39">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                39. Die Bedeutung genauer allgemeiner Einstellungen in TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                In der dynamischen Landschaft der heutigen Arbeitsumgebung ist Verbundenheit und Koordination wichtiger denn je. Hier spielt die Aktualisierung Ihrer 
                allgemeinen Einstellungen in TeamConnect eine entscheidende Rolle:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Arbeitsplatzwahl:</Body1Strong> Erfassen Sie das Wesen Ihres Arbeitsumfelds, indem Sie Ihren Arbeitsplatz aktualisieren, um Ihre aktuelle 
                      Situation widerzuspiegeln, sei es der Wechsel von der Ruhe Ihres Home-Office zur Hektik des Hauptbüros oder sogar die Eingewöhnung in einen öffentlichen 
                      Bereich. Diese Klarheit über Ihren Arbeitsort bietet Kollegen Einblicke nicht nur in die besten Möglichkeiten, Sie zu erreichen, sondern auch in die Art 
                      der Gespräche, die sie mit Ihnen führen können, und schützt die Vertraulichkeit sensibler Diskussionen, insbesondere wenn Sie sich in der offenen 
                      Umgebung eines Kundenbüros befinden oder in einer Flughafenlounge sitzen.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Arbeitsortangabe:</Body1Strong> Die Angabe Ihrer Arbeitsstadt überbrückt nicht nur Entfernungen in einem Remote-Team-Setup – sie eröffnet auch 
                      Türen für spontane berufliche Zusammenkünfte oder ungezwungenes gesellschaftliches Beisammensein. Ob es ein schneller Kaffee, ein gemütliches Mittagessen 
                      oder ein kollaboratives Treffen ist, die Tatsache, dass Sie sich in derselben Stadt befinden, kann einen gewöhnlichen Tag in eine Gelegenheit für 
                      bedeutungsvolle persönliche Verbindungen verwandeln. Dies stärkt nicht nur die Teambindung, sondern vermittelt auch ein tieferes Verständnis der lokalen 
                      Nuancen, die Ihre Arbeit beeinflussen könnten.
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Zeitzonengenauigkeit:</Body1Strong> Da Teams Kontinente überspannen, ist es wesentlich, dass Ihre 
                    Zeitzone Ihren aktuellen Standort widerspiegelt, um die Kommunikation zu harmonisieren. Die lokale Zeit in Ihrem TeamConnect-Profil wird direkt von dieser 
                    Einstellung beeinflusst und eliminiert jegliches Rätselraten für Kollegen, die versuchen, Sie zu erreichen.
                  </li>
                </ul>
                Tauchen Sie in 'Einstellungen' {'>'} 'Allgemeine Einstellungen' innerhalb von TeamConnect ein, um diese Details zu verfeinern. Ein paar Momente, die Sie damit 
                verbringen, Ihre Einstellungen zu aktualisieren, können die Kohäsion und Effizienz Ihrer gemeinsamen Bemühungen erheblich verbessern.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="40">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                40. Entschlüsselung der Arbeitsplatzwahl in TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Die richtige Einstellung im Arbeitsplatz-Dropdown anzupassen, sorgt dafür, dass TeamConnect Ihren aktuellen Arbeitsstatus genau widerspiegelt. Fahren Sie mit der Maus über das Info-Symbol für eine detaillierte Erklärung jeder Option:
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Pause:</Body1Strong> Kennzeichnet die Zeit abseits der Arbeit, sei es für eine Pause oder das Ende des Tages.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Büro/Field-Office:</Body1Strong> Zeigt die Anwesenheit in einem unternehmensverwalteten Arbeitsbereich an.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> Arbeit aus einem dedizierten häuslichen Arbeitsplatz.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Arbeitsplatz/Öffentlicher Bereich:</Body1Strong> Arbeiten aus einer nicht-bürogebundenen Umgebung, was auf einen Bedarf an flexibler Kommunikation hinweist.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Kundenarbeitsplatz/Privatbüro:</Body1Strong> Vor Ort beim Kunden, mit Hinweis auf Datenschutzniveaus aufgrund der Sensibilität der Gespräche.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Pendeln/Geschäftsreise/Training:</Body1Strong> Unterwegs oder in spezifischen arbeitsbezogenen Aktivitäten außerhalb des Büros engagiert.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Feiertag/Urlaub/Verschiedene Freistellungen:</Body1Strong> Offiziell dienstfrei aus persönlichen, gesundheitlichen oder Urlaubsgründen.</li>
                </ul>
                Eine passende Arbeitsplatzeinstellung zu wählen, gewährleistet, dass Ihr Team den Kontext für Interaktionen hat, unter Beachtung von Verfügbarkeit und Privatsphäre.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="41">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                41. Navigation des 'Eingecheckt' Status in TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Der 'Eingecheckt' Markierung im Mitarbeiterverzeichnis ist darauf ausgelegt, alle Mitarbeiter anzuzeigen, die aktuell bei der Arbeit sind. Diese Sichtbarkeit wird durch die von Ihnen gewählte Arbeitsplatzeinstellung bestimmt:
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Aktive Arbeitsumgebungen:</Body1Strong> Die Auswahlmöglichkeiten Büro, Feldbüro, Home-Office, Remote-Arbeitsplatz, Öffentlicher Bereich, Kundenarbeitsplatz, 
                  Kunden-Privatbüro, Pendeln, Geschäftsreise und Training signalisieren, dass Sie in der Arbeit engagiert sind, was Sie 'Eingecheckt' und sichtbar aktiv für Kollegen macht.
                </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Nicht-Arbeits-Einstellungen:</Body1Strong> Die Wahl von Checked-Out, Pause, Feiertag, Urlaub, Beurlaubung, Mutterschutz oder Krankenstand 
                    entfernt Sie von der 'Eingecheckt' Liste, was klare Erwartungen für Verfügbarkeit und Antwortzeiten setzt.
                </li>
                </ul>
                Diese Funktionalität hilft dabei, Arbeitsperioden von persönlicher Zeit zu unterscheiden, was die effektive Teamzusammenarbeit und Kommunikation unterstützt.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      
      <Subtitle2>Datenverwaltung und Datenschutz – Häufig gestellte Fragen</Subtitle2>
        <Body1>
          <p>
            Im heutigen digitalen Zeitalter ist es entscheidend zu verstehen, wie Ihre Daten verwaltet werden. TeamConnect ist verpflichtet zu Transparenz und robusten Datenschutzpraktiken. 
            Unten finden Sie Informationen darüber, wie wir Ihre Daten in TeamConnect handhaben, speichern und schützen, um sicherzustellen, dass Ihr digitaler Arbeitsbereich sicher und konform ist.
          </p>
        </Body1>
        
      <div>
        <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
          <AccordionItem value="42">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                42. Wie schützt TeamConnect meine persönlichen Daten?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <p>TeamConnect widmet sich dem Schutz Ihrer persönlichen Informationen mit einem umfassenden Ansatz zur Datensicherheit:</p>
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Single Sign-On (SSO):</Body1Strong> Zur Verbesserung der Sicherheit und Vereinfachung der Benutzererfahrung integriert TeamConnect automatisiertes 
                    Single Sign-On (SSO) mit Ihrem Microsoft 365-Konto, was ein sicheres und müheloses Zugangsmanagement ermöglicht.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Verschlüsselung:</Body1Strong> Wir gewährleisten, dass alle Daten innerhalb von TeamConnect sowohl bei der Übertragung als auch im Ruhezustand verschlüsselt sind, 
                    was sie für unbefugte Parteien unzugänglich macht.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Datenminimierung:</Body1Strong> Unsere Datenerfassung beschränkt sich strikt auf das für die Funktionalität von TeamConnect Wesentliche. 
                    Die einzigen Benutzerinformationen, die wir speichern, ist Ihr User Principal Name (UPN) aus Azure Entra ID, der Ihrer Firmen-E-Mail-Adresse ähneln könnte.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Eingeschränkter Zugang:</Body1Strong> Der Zugang zum UPN ist auf den Subscription Admin Ihrer Organisation innerhalb unseres Subscription-Portals beschränkt,
                    was sicherstellt, dass sensible Informationen nur für autorisiertes Personal sichtbar sind.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Schnelle Datenlöschung:</Body1Strong> TeamConnect ist so strukturiert, dass der gespeicherte UPN sofort unter drei spezifischen Bedingungen gelöscht wird: 
                    wenn das Abonnement durch den Subscription Admin gekündigt wird, wenn ein Benutzer von der Personalabteilung innerhalb von TeamConnect entfernt wird, oder wenn das TeamConnect-
                    Abonnement abläuft. Dies gewährleistet, dass Ihre persönlichen Daten nicht über ihre nützliche Lebensdauer oder Notwendigkeit hinaus aufbewahrt werden.
                  </li>                      
                </ul>
                <p>Mit diesen Maßnahmen verpflichtet sich TeamConnect, die höchsten Standards des Datenschutzes aufrechtzuerhalten, um sicherzustellen, dass Ihre persönlichen Informationen sicher und vertraulich bleiben.</p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="43">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                43. Sie fragen sich, wo Ihre Daten bei TeamConnect gespeichert sind?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect bricht mit den Traditionen herkömmlicher SaaS-Angebote, indem sichergestellt wird, dass Ihre Daten nie Ihre Organisation verlassen. 
                Anders als bei der üblichen Praxis, Daten auf externen Cloud-Servern zu speichern, nutzt TeamConnect geschickt Ihr organisationsinternes 
                Microsoft 365 SharePoint Online, um alle Anwendungsdaten zu speichern. Dieser innovative Ansatz verschafft nicht nur Datensouveränität, sondern verstärkt auch die Sicherheit, 
                perfekt in Einklang mit unserem strengen Engagement zum Schutz Ihrer Privatsphäre und der Einhaltung strenger Compliance-Standards.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="44">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                44. Werden meine Daten an Dritte weitergegeben?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Nein, Daten werden nicht an Dritte weitergegeben! Cubeet ist strikt in unserem Engagement für die Privatsphäre und Sicherheit Ihrer Daten. Wir teilen keine TeamConnect Daten mit Dritten ohne eine ausdrückliche Zustimmung unserer Kunden zu erhalten.
                In unserer sicheren Umgebung speichern wir nur wesentliche Kennungen, wie den User Principal Name (UPN), Organisationsname, Mandantenname und Mandanten-ID, innerhalb unseres Abonnementportals.
                Diese Informationen sind entscheidend für die Dienstleistung und werden unter unseren strengen Datenschutzrichtlinien geschützt.
                Seien Sie versichert, dass unsere Datenmanagementpraktiken darauf ausgelegt sind, sicherzustellen, dass Ihre Daten vertraulich bleiben, mit Transparenz und Kontrolle fest in Ihren Händen.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="45">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                45. Wie erfüllt TeamConnect Datenschutzvorschriften wie die DSGVO?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect steht im Einklang mit Datenschutzvorschriften wie der DSGVO, HIPAA, CCPA und anderen, indem es die inhärenten Compliance-Funktionen der Microsoft 365-Umgebung nutzt,
                wo alle Kundendaten sicher gespeichert werden. Im Gegensatz zu traditionellen SaaS-Modellen speichert TeamConnect keine Kundendaten auf seinen eigenen Servern; stattdessen operiert es innerhalb der 
                Microsoft 365-Umgebung Ihrer Organisation und hält sich an deren robuste Sicherheits- und Compliance-Standards. Dieser Ansatz gewährleistet, dass die Datenverwaltung Ihrer Organisation konsistent bleibt mit
                dem umfassenden Compliance-Rahmen, den Microsoft 365 bietet, einschließlich der Einhaltung der DSGVO, HIPAA für Gesundheitsinformationen, CCPA für Einwohner Kaliforniens,
                und andere weltweite Datenschutzvorschriften. Durch die nahtlose Integration in Ihre bestehende Microsoft 365-Einrichtung ermöglicht TeamConnect Ihrer Organisation,
                die vollständige Kontrolle über Ihre Daten zu bewahren und die Einhaltung der strengsten Datenschutzgesetze und -vorschriften zu gewährleisten.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="46">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                46. Wie geht TeamConnect mit Daten aus Microsoft Teams-Chats, Anrufen, Videoanrufen, Outlook-E-Mails und Telefongesprächen um?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect nutzt die Microsoft Graph API, um direkte Interaktionen wie Chats, Anrufe, Videoanrufe und Outlook-E-Mails innerhalb von Microsoft Teams zu ermöglichen, 
                und bietet dabei ein nahtloses Benutzererlebnis. Entscheidend ist, dass TeamConnect diese Kommunikationen initiiert, ohne jedoch in den Kommunikationsprozess einzugreifen 
                oder die Fähigkeit zu haben, Inhalte der Interaktionen aufzuzeichnen oder zu speichern. Alle Gespräche, Anrufe und E-Mails finden direkt innerhalb der 
                sicheren Umgebung von Microsoft Teams und Outlook statt, streng nach den robusten Sicherheits- und Datenschutzstandards von Microsoft 365. Dies stellt sicher, dass TeamConnect 
                Ihre Kommunikationsfähigkeiten verbessert und gleichzeitig die Vertraulichkeit und Integrität Ihrer Daten vollständig wahrt.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Body1>
        <p>
          Für detaillierte Einblicke in unsere Datenschutzrichtlinien und -praktiken möchten Sie vielleicht auch den {' '}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
          <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>TeamConnect Benutzerhandbuch</Body1Strong></Link> konsultieren oder sich an die IT-Abteilung 
          Ihrer Organisation unter <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> wenden, um spezifische Informationen zu Ihrer Konfiguration zu erhalten.
        </p>
      </Body1>

      <p></p>

      <Subtitle1 className={styles.fontColor}>Wir hören zu: Teilen Sie Ihr Feedback und Ihre Ideen</Subtitle1>
      <Body1>
        <p>
          Ihre Erkentnisse treiben die Entwicklung von TeamConnect voran. Ob Sie mit einer brillanten Idee für eine Funktion sprudeln oder Feedback zu Ihrer aktuellen Erfahrung haben, 
          wir sind ganz Ohr. Ihr Input ist unschätzbar auf unserer kontinuierlichen Suche, den bestmöglichen Service zu bieten.
        </p>
      </Body1>

      <Subtitle2>Feedback und Vorschläge für Funktionen</Subtitle2>
        <Body1>
          <p>
            Bereit zu teilen? Besuchen Sie unsere {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Feedbackseite
            </Link>
            . Ob es sich um einen Vorschlag für eine neue Funktion oder Feedback zu bestehenden Funktionalitäten handelt, wählen Sie einfach 
            die entsprechende Option aus dem Dropdown im Formular aus. Ihre Beiträge helfen, die Zukunft von TeamConnect zu gestalten, 
            indem sie sicherstellen, dass es Ihre Bedürfnisse erfüllt und übertrifft.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;