import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>TeamConnect ヘルプセンター & FAQ</Title3>
        <p><Body1>
          TeamConnect ヘルプセンターへようこそ。このヘルプセンターは、包括的な企業ディレクトリアプリの使用をサポートするために設計されており、Microsoft Teams内でのTeamConnectの使用を最適化するためのリソースが見つかります。これにより、ネットワーク全体のコミュニケーションが向上します。
        </Body1></p>
        <Subtitle2>クイックリファレンスガイド</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>強化された検索機能: </Body1Strong>
              強力な検索ツールで、適切な同僚、部門、必要な連絡先を迅速に見つけます。
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>ビジュアル組織図: </Body1Strong>
              直感的な組織図を通じて、組織の階層をナビゲートし、報告ラインを簡単に理解します。
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>現代のニーズに適応するツール: </Body1Strong>
              現代の職場の要求に合わせた最新の機能を活用します。
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>体験を最適化する</Subtitle2>
        <Body1>
          <p>
            TeamConnectは、組織構造内でのやり取りを革新し、日常のコミュニケーションを戦略的なエンゲージメントに変えます。
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>ヘルプ & FAQを入手する</Subtitle1>
        <Body1>
          <p>
            TeamConnectの機能や設定についての一般的な質問に答えるこの専用セクションです。アプリの操作、設定のカスタマイズ、または問題への対策を求めているかどうかにかかわらず、当ヘルプセンターが効率的に支援します。
          </p>
        </Body1>
        <Body1>
          <p>
            より詳細なサポートや具体的なアドバイスが必要な場合は、<Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnectユーザーガイド</Body1Strong></Link>を参照してください。
          </p>
        </Body1>
        <p></p>        
        <Subtitle2>TeamConnect FAQの使用方法</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Microsoft TeamsのサイドバーでTeamConnectが見つからない場合は？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectがMicrosoft Teamsのサイドバーにすぐに見えない場合や消えてしまった場合は、すぐにアクセスできるようにピンで固定することができます。以下の簡単な手順に従ってください：
                  <ol>
                    <li className={styles.bulletBottomMargin}>Microsoft Teamsを開き、サイドバーの下部にある「...」（+ Apps）アイコンをクリックします。</li>
                    <li className={styles.bulletBottomMargin}>ポップアップウィンドウの上部にある検索バーで「TeamConnect」を検索します。</li>
                    <li className={styles.bulletBottomMargin}>検索結果からTeamConnectアプリを右クリックし、「ピン留め」を選択します。</li>
                  </ol>
                  TeamConnectはこれでサイドバーに固定され、クリックするだけでアクセス可能になります！
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. TeamConnectで技術的な問題に遭遇した場合、こちらでサポートを受ける方法。
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Microsoft Teams内のTeamConnectで技術的な問題が発生した場合、組織のITサポートチームがサポートを提供します。
		              <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>まで連絡してください。彼らが迅速に対応します。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. 従業員の詳細が見つからない場合は？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  プロファイルに名前とメールアドレスしか表示されていない場合、HRチームがまだ他の従業員の詳細をTeamConnectに追加していない可能性があります。
		              心配は無用です！HRサポートチームへの迅速なメッセージが物事を進めます。彼らはプロファイルに重要な詳細を記入するために待機しています。
                  <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>まで連絡して、プロファイルを充実させてください。
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. TeamConnectで利用可能な言語オプションは何ですか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectは、Microsoft
                  Teamsのグローバルな多様性を受け入れて、すべての言語をサポートしています。
                  アラビア語でもギリシャ語でも、日本語やスウェーデン語でも、指先には世界中のオプションがあります。以下を含みます：
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      アラビア語、ブルガリア語、中国語（簡体字および繁体字）、クロアチア語、チェコ語、デンマーク語、オランダ語、英語、エストニア語、フィンランド語、
                      フランス語、ドイツ語、ギリシャ語、ヘブライ語、ハンガリー語、インドネシア語、イタリア語、日本語、韓国語、ラトビア語、リトアニア語、ノルウェー語（ブークモール）、
                      ポーランド語、ポルトガル語（ブラジルおよびポルトガル）、ルーマニア語、ロシア語、セルビア語（ラテン）、スロバキア語、スロベニア語、スペイン語、スウェーデン語、タイ語、
                      トルコ語、ウクライナ語、ベトナム語。
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  TeamConnectの体験を好みの言語にカスタマイズするには、Microsoft
                  Teamsで直接言語設定を調整してください。
                  TeamConnectはこれらの設定と完全に統合されています:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      プロファイル画像の上にある三つのドットをクリックしてMicrosoft
                      Teamsの設定を開き、「外観とアクセシビリティ」を選択します。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      「言語」セクションまでスクロールし、ドロップダウンメニューから好みの言語を選択します。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      「保存して再起動」ボタンをクリックして、新しい言語設定を適用します。
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>注意: </Body1Strong>
                <Body1>
                  Microsoft
                  Teamsで言語設定を変更すると、TeamConnectの体験も自動的に更新されます。
                  チームでの言語の変更は時刻や日付の形式が調整される可能性もあります。現時点では、
                  TeamConnectは右から左への書き込みに対応するため進化しています。これらの強化については期待しており、
                  利用可能になり次第お知らせします。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. TeamConnectでテーマを変更する方法は？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectは、Microsoft Teamsのテーマ設定（ライト、ダーク、ハイコントラスト）と同期します。テーマをカスタマイズするには：
                  <ol>
                    <li className={styles.bulletBottomMargin}>プロファイル画像の横にある三つの点をクリックして、あなたのMicrosoft Teamsの設定にアクセスします。</li>
                    <li className={styles.bulletBottomMargin}>「外観とアクセシビリティ」を選択して、テーマオプションを探索します。</li>
                    <li className={styles.bulletBottomMargin}>利用可能なオプションから好みのテーマを選択し、TeamConnectインターフェイスを即座に更新して合わせます。</li>
                  </ol>
                  Microsoft Teamsでのテーマ選択はTeamConnectに自動的に適用され、ワークスペース全体で一貫したビジュアル体験を保証します。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. TeamConnectの連絡先アクションボタンの探索
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect内のどこでも従業員の連絡先の詳細を見つけると、便利なクイックアクセスボタンも発見できます。これらの便利なツールは、
                  Microsoftエコシステム内でのコミュニケーションを合理化するように設計されており、接続まで常にクリックひとつです。
                  これらのボタンを効果的に利用するための包括的なガイドは以下の通りです：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>チャットアイコン：</Body1Strong> Microsoft Teamsで即時チャットを開始するためにクリックします。チャットを始めた後にTeamConnectに戻る場合は、デスクトップアプリの戻る矢印、モバイルアプリの戻るボタン、またはブラウザの戻るボタンを使用してください。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>メールアイコン：</Body1Strong> 新しいOutlookメールドラフトを開き、受信者のアドレスを「To:」欄に自動的に入力します。Outlookが起動しない場合は、制限に関してIT部門に確認する価値があります。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ビデオコールアイコン：</Body1Strong> Microsoft Teamsを使用して対面ビデオ通話を開始します。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>通話アイコン：</Body1Strong> Microsoft Teamsを使って音声通話を開始し、迅速な会話がスムーズに行えます。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>組織図アイコン：</Body1Strong> 従業員の組織内の位置を、その上司と直接報告者を含めて表示します。</li>
                    <li><Body1Strong>その他(...)アイコン：</Body1Strong> さらなるオプションを明らかにします：
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>今すぐ会議：</Body1Strong> Microsoft Teamsを開いて、従業員と即座に会議を始めます。</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>新しい会議：</Body1Strong> 新しい会議をスケジュールするためにMicrosoft Teamsへと誘導します。</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>連絡先を追加：</Body1Strong> TeamConnectから従業員の連絡先詳細を新しいMicrosoft Teams Peopleアプリに追加し、通話機能を豊かにします。</li>
                      </ul>
                    </li>
                  </ul>
                  これらの直感的なボタンは、すばやくチャットをしたり、メールを送ったり、将来の会議をスケジュールするために、効率的に同僚と接続できるように設計されています。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. TeamConnectを通じての通話の実施
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  通話をしたいですか？ TeamConnectはあなたのコミュニケーションを合理化するためにMicrosoft Teamsと連携します。
                  TeamConnectで望ましい電話番号をクリックするだけで、Microsoft Teamsの通話インターフェイスが表示され、シームレスにダイヤルアウトできます。
                  <p>
                  <Body1Strong>注意：</Body1Strong>
                  通話に問題が発生していますか？ これは、組織のITポリシーあるいはMicrosoft Teams Phone System以外の電話システムの使用が原因かもしれません。 <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>でIT部門に問い合わせることで、事情が明らかになるかもしれません。
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. TeamConnect を使って通話する際のコストはありますか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect は Microsoft Teams を活用して通話体験を向上させます。通話関連のコストについて知っておくべきことは次のとおりです：
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      組織内の従業員の業務用電話、部門電話、またはホットライン（内部番号）への通話には、Microsoft Teams Phone System を使用している場合、通常<Body1Strong>費用はかかりません</Body1Strong>。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      携帯電話や外部の電話回線など、外部への通話には<Body1Strong>標準料金</Body1Strong>が適用されます。これらの料金は、Microsoft Teams との具体的なサービスプランに基づいて異なります。
                    </li>
                  </ul>
                  <p><Body1Strong>注記：</Body1Strong> 通話料金に関する問題や質問がある場合は、<Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> で IT 部門に相談することをお勧めします。組織の設定やポリシーに合わせた詳細情報を提供してもらうことができます。</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. TeamConnect で「不在時の状態」と一致しないことに気づきましたか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Microsoft 365 からの「不在時」のプレゼンス状態が TeamConnect で正しく反映されていないことに気づきましたか？安心してください。TeamConnect で「不在時」のプレゼンス状態を同期する機能は既に実装されています。現在、この機能の Microsoft による展開を待っています。それが行われると、「不在時」のプレゼンス状態は TeamConnect 内で正確に反映され、Microsoft Teams のプレゼンス表示とシームレスに合致します。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>従業員ディレクトリ FAQ</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >従業員ディレクトリの紹介：</Body1Strong> 従業員ディレクトリで組織のプロフェッショナルな風景をナビゲートしてください。従業員の詳細な情報、職場のダイナミクス、同僚のプロフィールや管理関係への直接的な接続が中心となります。
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. 従業員のデータを追加、更新、または訂正する必要がありますか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect で従業員データを追加、更新、または訂正する場合は、組織の HR サポートチームに連絡してください。<Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>でサポートが待っています。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. ディレクトリで従業員の名前をクリックすると何が起こりますか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect従業員ディレクトリで従業員の名前をクリックすると、その個人の詳細なプロファイルページが開きます。このプロファイルは、従業員の仕事のタイトル、部門、オフィスの場所、連絡先情報など、限定しませんがそれに関連する包括的な洞察を提供します。これは、従業員の職業的役割と組織内での適合性を完全に理解することを目的としており、彼らの責任範囲を理解し、どのように協力できるかがより簡単になります。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. 従業員のプロファイルにはどのような情報が含まれていますか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectの各従業員プロファイルは、個人の職業生活についての豊富な洞察を提供する包括的なスナップショットです。以下の情報が含まれます：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>基本情報：</Body1Strong> 名前、職種、部門、子会社の所在地。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>報告構造：</Body1Strong> 直属および機能的報告に関する情報、管理ラインと機能マネージャーを含む。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>職場の状況：</Body1Strong> 休職中、通勤中、出張中等の現在の勤務状況。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>勤務地と現地時間：</Body1Strong> 従業員の現在の勤務地と現地の時間帯。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>連絡先情報：</Body1Strong> 勤務先電話番号、携帯番号、及び電子メールアドレス。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>オフィスアドレス：</Body1Strong> ビル名、アドレス、Googleマップを含む完全なオフィス所在地。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>自己紹介：</Body1Strong> 簡潔な個人紹介または職業的なバイオ。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>社内関係：</Body1Strong> 従業員の報告構造とチーム関係の詳細。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ソーシャルリンク＆言語：</Body1Strong> プロフェッショナルソーシャルプロファイルへのリンクと言語習熟度。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>スキル：</Body1Strong> 専門スキルと専門知識エリアのリスト。</li>
                  </ul>
                  さらに、プロファイルはチャット、メール、ビデオ通話、または会議のスケジューリングを含むアクショナブルなオプションの提供、およびより広範なチームのコンテキストのための組織のチャートへのクイックアクセスを提供します。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. プロファイル情報を更新する方法は？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectでプロファイルを個人化するのは簡単です。以下の方法をご覧ください：
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>従業員</Body1Strong> タブに移動し、あなたの <Body1Strong>アバター</Body1Strong> をクリックしてプロファイルを開きます。
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        左上角に <Body1Strong>"プロファイルを編集"</Body1Strong> リンクが見つかります。これをクリックするとプロファイル編集ダイアログが開きます。
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        ここで、<Body1Strong>自己紹介</Body1Strong> セクション、ソーシャルメディアリンク、電話番号を追加または更新してプロファイルを強化できます。
                        これにより、同僚があなたと接続したりフォローしたりしやすくなります。
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        変更を適用するには <Body1Strong>保存</Body1Strong> ボタンを押すのを忘れないでください。
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        ダイアログを下にスクロールして、<Body1Strong>言語</Body1Strong> と <Body1Strong>熟練度</Body1Strong>、
                        さらに <Body1Strong>スキル</Body1Strong> を追加します。この情報を共有することで、同僚はあなたの専門分野や
                        コミュニケーションが可能な言語を理解するのに役立ちます。
                      </li>
                    </ol>
                  プロファイルを更新することで、情報を最新の状態に保つとともに、組織内でのより良いつながりを促進します。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. TeamConnectでの職場、勤務地、タイムゾーンの更新
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Microsoft Teamsでスムーズな協力を実現するためには、職場、勤務地、タイムゾーン情報を最新の状態に保つことが重要です。
                  これらの更新は即座にあなたのTeamConnectプロファイルとリスティングに反映され、同僚があなたの勤務地や連絡を取るのに最適な時間を迅速に把握することができ、
                  異なる地域やタイムゾーンでのチームワークを強化します。
                  <p></p>
                  詳細を更新する準備はできましたか？ TeamConnectでは簡単です！ 上部メニューの '設定' に移動して '一般設定' に進みます。
                  ここで、職場とタイムゾーンを更新し、その日の勤務地を指定することができます。変更を保存して、みんなが情報を共有することで、
                  どこにいても効果的な協力を実現しましょう！
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. 同僚のプロファイルに何かおかしな点を見つけた？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  もし同僚のプロファイルに何か正しくなさそうな詳細を見つけた場合、最初のステップとしては親しみを込めて知らせてあげることです。
                  簡単なチャットやメールで対応できます！ 何らかの理由で情報が更新されない場合も心配無用です。ただ <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> に連絡するだけで、
                  人事サポートチームが喜んで対応し、問題を解決します。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. タググループの理解：すべて、お気に入り、チェックイン、新入社員
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>すべて：</Body1Strong> このデフォルトタグは組織内のすべての従業員を表示し、チームの包括的なビューを提供します。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>お気に入り：</Body1Strong> クリックすると、「お気に入り」としてマークした同僚のみをフィルタリング表示し、主要な連絡先にすばやく接続しやすくします。
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>チェックイン：</Body1Strong> このタグは、現在仕事中の従業員を示し、Microsoft Teamsでのオンラインの存在と区別します。チェックインした従業員は、仕事中であることを示し、その日の現在の作業場所を共有します。これには、オフィス、現地オフィス、自宅オフィス、リモートワークプレイス、顧客ワークプレイス、顧客専用オフィス、公共エリア、通勤、出張、研修など、様々な職場が含まれることがあります。したがって、「チェックイン」のステータスは、積極的に仕事中であることを明確に示し、同僚に互いの現在の作業状況と利用可能性を正確に理解させます。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>新入社員：</Body1Strong> TeamConnect内でこのタグを使用して、アプリに最近30日以内に追加された新人従業員を強調表示します。通常、これは組織に最近追加された人々と一致し、新しいチームメンバーを歓迎するのに役立ちます。
                    </li>
                  </ul>
                  これらのタググループは、ナビゲーションを合理化し、TeamConnectの利用体験を向上させ、いつでも効率的に同僚と接続できるように設計されています。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. 検索ボックス横のアイコンの解読
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectの従業員ディレクトリは、多様性と使いやすさを備えており、異なるビューへのアクセスと重要な詳細への迅速なアクセスを提供する4つのアイコンが特徴です。各アイコンが明らかにする内容は次のとおりです：
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>リストビュー：</Body1Strong> すべての組織メンバーを簡潔なリストとして提示する、行く先のビューです。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>スモールカードビュー：</Body1Strong> コンパク트な代替品であり、このビューはリストを一口サイズの従業員カードに変換します。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ラージカードビュー：</Body1Strong> より多くの詳細を含む大きめのカードを選択し、電話や携帯電話番号などの追加の連絡オプションを含み、簡単にダイヤルできます。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>組織図：</Body1Strong> 組織図ビューで会社の構造を視覚化し、報告関係を理解し、組織風景をナビゲートするのに最適です。</li>
                  </ol>
                    これらのアイコン間を切り替えて、瞬時の情報から深い洞察まで、その時のニーズに最適なビューを見つけてください。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. 同僚をすばやく検索するにはどこを見ればいいですか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  同僚を探していますか？ 上部右側の組織図アイコンの隣にあるTeamConnect検索ボックスに向かってください。 彼らの名前をタイピングし始めると、結果が各文字で絞り込まれるのを見ることができます。 TeamConnectの検索には、上部のMicrosoft Teams検索バーをスキップし、私たちの特別な検索ボックスを使用するのが方法です！
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. 従業員を見つけるためのアルファベット検索はどのように機能しますか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectの従業員ディレクトリには、従業員を名前の頭文字で素早く見つけるための便利なアルファベット検索機能が含まれています。
                  ディレクトリの上部に表示されているアルファベットの中から文字をクリックするだけで、その文字で始まる名前の従業員全員をすぐに表示します。
                  従業員の完全なリストに戻るには、強調表示された文字の選択を解除します。この直感的な機能により、大規模な従業員リストを簡単にナビゲートでき、
                  同僚を迅速かつ効率的に見つけることができます。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. ページごとに表示される従業員数をどのように調整しますか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectでは、ページごとに表示する従業員プロファイルの数をカスタマイズでき、従業員ディレクトリのナビゲーションが容易になります。
                  各ページの下部には、10、50、100、500、1000、そして5000のページネーションオプションがあります。デフォルト設定では、ページごとに10のプロファイルが表示されます。
                  より多くのプロファイルを単一ページで見るには、これらのオプションから希望の数を選択するだけです。この機能により、ディレクトリの表示を特定のニーズや好みに合わせて調整することが可能になります。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. リストビューでの従業員情報の並び替えはどのように行いますか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectの従業員ディレクトリリストビューでは、従業員情報をニーズに合わせて並び替える柔軟性があります。列（従業員名、職名、部署、オフィスの場所、および職場）は、
                  すべて並び替え可能です。並び替えたい列のヘッダーをクリックするだけです。一度クリックすると、列を昇順に並び替え、もう一度クリックすると、降順に並び替えます。
                  デフォルトでは、ディレクトリは名前の昇順で並び替えられています。この並び替え機能により、特定の人を探している場合や、部署や場所など特定の基準で従業員をグループ化する必要がある場合に、
                  従業員の詳細を迅速に整理して表示するのに役立ちます。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>部署ディレクトリFAQ</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>部署ディレクトリの紹介：</Body1Strong> 部署ディレクトリは、部署の階層、管理リーダーシップ、および部署間のスムーズなコミュニケーションのための道筋を明確に示して、組織の複雑な構造を理解するための入口です。
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. 部署ディレクトリの「全部」「お気に入り」「あなたの部署」のタグはどのように機能しますか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectの部署ディレクトリでは、タグを使用して部署情報を迅速にナビゲートしてフィルタリングすることができます。各タグの意味は以下の通りです：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>All:</Body1Strong> このタグは組織内のすべての部署を表示し、包括的な概観を提供します。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favorites:</Body1Strong> このタグを使用して、「お気に入り」としてマークした部署のカスタムリストを表示し、頻繁に連絡を取る部署へのアクセスを容易にします。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Your Department:</Body1Strong> このタグは表示をフィルタリングしてあなたの部署のみを表示し、あなたの直接的な部署情報や更新情報へのアクセスを迅速化します。</li>
                  </ul>
                  これらのタグは、部署ディレクトリ内のナビゲーションを効率化し、部署情報を効率的に見つけて関与する能力を高めるよう設計されています。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. TeamConnectの部署ディレクトリビューの理解
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectの部署ディレクトリをナビゲートすることは、好みに合わせて選択可能な複数の表示オプションで簡素化されています。各ビューは独自の方法で包括的な部署情報を探索する機会を提供し、部署名、部署マネージャー、親部署、部署電話、部署ホットライン、部署Eメールを含みます。利用可能なビューのスナップショットは以下の通りです：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>List View:</Body1Strong> すべての部署をきれいにリストアップするデフォルトの設定で、明確かつ簡潔な概観を提供します。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Large Card View:</Body1Strong> このビューに切り替えると、各部署の重要な情報の拡張された概要を提供する詳細な部署カードを見ることができます。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org Chart View:</Body1Strong> 部署の構造と関係の視覚的表現のために、オルグチャートビューは各部署がより大きな組織の階層内でどのように位置づけられているかを図示します。
                    </li>
                  </ul>
                  これらのビュー間を切り替えるには、部署ディレクトリの上部の検索ボックスの隣にあるアイコンを使用します。各ビューは、必要な情報をあなたに合った形式で見つけて理解するのに役立つように設計されています。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. 部門ディレクトリでの検索ボックスの使用方法
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect内の任意の部門を専用の検索ボックスを使って簡単に見つけ出すことができます。この検索ボックスは、部門ディレクトリの上部の表示アイコンの隣に配置されています。検索したい部門名を入力し始めると、ディレクトリがリファインされ、関連するマッチのみを表示します。この機能は、ディレクトリ全体を手動で調べる必要を回避し、必要な部門を直接手元に届けるように特別に設計されています。この検索機能はTeamConnectの部門ディレクトリに特有のものなので、部門特有の問い合わせにはこの検索ボックスを使用し、Teamsインターフェースの最上部にある主要なMicrosoft Teams検索は使用しないでください。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. 部門ディレクトリにおけるアルファベットフィルターの機能はどのようなものですか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  部門ディレクトリでの検索をアルファベットフィルターを使って効率化します。このフィルターは上部に位置しており、文字を選択することで迅速に部門を絞り込むことができます。選択した文字で始まる部門名のみを表示し、リスト全体をスクロールすることなく目的のものを見つけることが可能です。元の部門ビューに戻るには、ハイライトされた文字の選択を解除します。この直感的なアルファベットナビゲーションにより、任意の部門をその頭文字で効率的に見つけ出すことができます。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. 部門マネージャーにどのように連絡しますか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectで部門マネージャーに連絡するのは簡単です。関心のある部門を見つけてマネージャーの名前をクリックします。このアクションにより、マネージャーの連絡先オプションが表示されるポップオーバーが起動します：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>チャットアイコン：</Body1Strong> Microsoft Teamsチャットで即時メッセージを開始します。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>メールアイコン：</Body1Strong> Microsoft Outlookで新しいメールを彼ら宛に開きます。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ビデオ通話アイコン：</Body1Strong> Microsoft Teamsを通じてビデオ通話を開始します。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>通話アイコン：</Body1Strong> Microsoft Teamsを使用して音声通話を開始します。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>その他 (...) アイコン：</Body1Strong> 会議のスケジュール設定やTeamsリストへの連絡先情報の追加など、追加のオプションがあります。</li>
                  </ul>
                  これらのアイコンは、TeamConnect内から直接部門マネージャーに容易に接触するためのシームレスな方法を提供し、組織内の効果的なコミュニケーションを保証します。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. 部門内の従業員をどのように閲覧または連絡できますか?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect内の部門で誰が誰であるかを探求するには、部門名の横にある三つの点を見てください。これをクリックすると、次のようなさらなるアクションのオプションが表示されます:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>この部門の従業員を表示:</Body1Strong>このオプションを選択して、部門に関連するすべての従業員のリストを表示します。このオプションの横にある数字は部門の従業員数を示しており、すばやく人数を把握できます。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>部門の組織図:</Body1Strong>部門の階層を把握したい場合は、「部門の組織図」を選択して、親部門、該当部門、および下部部門との関係を示す構造レイアウトを表示します。この視覚表現は組織の枠組みをナビゲートし理解するのに役立ち、特定の部門メンバーに連絡するのが容易になります。
                    </li>
                    <li><Body1Strong>部門 {'>'} 従業員:</Body1Strong>部門内の従業員を閲覧した後は、このパンくずリストを使用して部門リストに簡単に戻り、TeamConnect内でのスムーズな探求体験を維持します。
                    </li>
                  </ul>
                  これらの機能により、特定の部門の従業員リストを見るだけでなく、TeamConnectのコミュニケーションツールを通じて直接彼らに連絡する道も提供されます。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. TeamConnectで部門の詳細な情報を探る
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectで部門名をクリックするだけで、その部門の詳細な情報が展開され、組織内での部門の役割やリソースについての完全なイメージが提供されます。リーダーシップや財務識別子、コミュニケーションチャネル、ソーシャルリンクから全てが見つかります。この機能は、組織内のユニークなエコシステムを理解し、接続するためのツールを提供します:
                  <ul>
                    <li className={styles.bulletBottomMargin}>部門の中心 – その目的、人々、およびパフォーマンス指標。</li>
                    <li className={styles.bulletBottomMargin}>連絡方法 – 部門の主要な人物やコミュニケーションプラットフォームへの直通路。</li>
                    <li className={styles.bulletBottomMargin}>組織のウェブ – 部門がどのようにしてより広範な会社の構造と統合しているかを理解する。</li>
                  </ul>
                  これは短い問い合わせから部門データへの深い掘り下げまで、TeamConnectがあなたをサポートします。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. TeamConnectで部門の住所がないことについて気になりますか?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  部門の場所を探すことは、地図なしの宝探しのようなものかもしれません。TeamConnectでは、通常、各部門に専用の住所が見つかりません。ここにその地図的詳細がしばしば省略される理由があります:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>複数の場所:</Body1Strong>多くの部門は単一の場所に限定されず、様々なオフィスや地理的な地域にまたがっています。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>流動的な構造:</Body1Strong>組織の風景は常に変化しており、戦略的なシフトに応じて部門は進化し、合併し、または解散することもあります。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>部門間のコラボレーション:</Body1Strong>現代のワークスペースはコラボレーションの精神で満ちており、しばしば様々な部門の才能が共有スペース内で混在しています。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>リモートワーク文化:</Body1Strong>リモートワークの柔軟性により、部門のアンカーは常に物理的なオフィスロケーションに設置されるわけではありません。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>法的ニュアンス:</Body1Strong>部門は通常、独自のスタンドアロンの住所を持つのではなく、会社の法的構造の周りを回っています。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>運用の効率化:</Body1Strong>静的な住所ではなくダイナミックなチームワークに焦点を当てることで、運用の機敏さを維持するのに役立ちます。
                    </li>
                  </ul>
                  これはチームワークと効率の境界をなくすアプローチを採用することについてです。TeamConnectはこの哲学を反映しており、場所よりも接続を強調しています。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. あなたの部署情報が不足または古いですか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  部署情報を追加、更新、または修正するために、組織内には部署エディタが指定されています。TeamConnectで部署固有の情報を管理します。
                  部署エディタが誰か不明な場合や特定の情報がHRサポートチームの介入を必要とする場合は、
                  <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> までご連絡ください。
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>オフィスディレクトリFAQ</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>オフィスディレクトリの導入：</Body1Strong> オフィスディレクトリを通じて、組織の物理的存在の核心を発見してください。
                ここには、重要なオフィスの詳細、運営の特性、および各ロケーションを定義する部門と人員に関する包括的な情報が含まれています。
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. オフィスディレクトリでのタグのナビゲーション
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  タグシステムを使用して、オフィスディレクトリ内で簡単に移動します。このシステムは、正しいロケーションへの素早いアクセスを設計されています。各タグによって検索を効率化する方法は次のとおりです：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>全部：</Body1Strong> オフィスの完全なリストを表示し、組織のロケーションに関する鳥瞰図を提供します。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>お気に入り：</Body1Strong> 頻繁に訪れるか注目しておくべきオフィスロケーションを、'お気に入り'としてマークすることで焦点を絞ります。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>あなたのオフィス：</Body1Strong> この個人的なタグはディレクトリをフィルタリングして、あなたが配置されているオフィスロケーションを表示し、ホームベースをすぐに確認できるようにします。
                    </li>
                  </ul>
                  会議室を予約する場合でも、訪問を計画する場合でも、これらのタグは組織の地理的な風景との対話を個人化し、簡素化する方法を提供します。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. チームコネクトでのオフィスディレクトリビューのマスター
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  会社のさまざまな場所の組織レイアウトに飛び込んで、TeamConnectのオフィスディレクトリビューを活用してください。各ビューは、オフィスナビゲーションと理解のユニークな側面を容易にするために作成されています:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>リストビュー:</Body1Strong> すべてのオフィスの場所をわかりやすく整理したデフォルトの設定です。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>大きなカードビュー:</Body1Strong> オフィスディレクトリの大きなカードビューは、各オフィスの詳細なプレゼンテーションでビューを豊かにします。オフィスカードを操作するとき、「もっと見る(...)"メニューの中に多くの情報とアクション可能なオプションが見つかります:
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>部門を見る:</Body1Strong> オフィス内の全ての部門のリストに直接移動します。
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>従業員を見る:</Body1Strong> オフィスに割り当てられた従業員の完全な名簿にアクセスし、オフィスの人員の直接的な連絡または探索を容易にします。
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>事業体組織図:</Body1Strong> より大きな組織の文脈でオフィスの位置を視覚化し、階層構造を明確に理解します。
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>重要な連絡先:</Body1Strong> ストリームライン化されたコミュニケーションのためのキーオフィスコンタクトを特定します。
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>オフィスの祝日:</Body1Strong> オフィス固有の公共の休日について情報を得て、スケジュールを適切に計画します。
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>オフィスの営業時間:</Body1Strong> オフィスの営業時間を表示して、訪問や通話を彼らのアクティブな時間と合わせます。
                        </li>
                      </ol>
                      このビューは視覚的かつ情報的なスナップショットを提供するだけでなく、「もっと見る(...)"オプションの下に整然と配されたオフィスに関連するより深い洞察と直接的なアクションへの即時アクセスも提供します。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>組織図ビュー:</Body1Strong> 親オフィスと子オフィスの階層構造を視覚化し、組織内の異なるオフィスの場所の相互接続を理解します。
                    </li>
                  </ul>
                  検索ボックスの隣にあるアイコンを切り替えてこれらのビューを切り替え、直接的なニーズに合わせて閲覧体験をカスタマイズします。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. TeamConnectの検索機能を使った迅速なオフィス検索
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect内で正しいオフィスの場所を簡単に特定することが、専用の検索機能で容易になりました。ビューアイコンの隣に位置する検索ボックスは、名前でオフィスを特定するためのツールです。入力すると、ディレクトリは表示を入力に一致するオフィスのみに絞り込みます。この検索プロセスを効率化します。 TeamConnect内でこの検索ボックスを使用することが最も正確で関連性の高い結果を得るために重要であり、一般的なMicrosoft Teamsの検索とは対照的です。このターゲット指向の検索機能を使用すると、組織ネットワーク内の任意のオフィスに迅速にナビゲートできます。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. オフィスディレクトリをアルファベット順にナビゲート
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  アルファベット順で検索することを好む場合、私たちのアルファベットフィルターは名前でオフィスを見つける迅速な方法です。オフィスディレクトリの上部には、AからZまでの文字が表示されます。文字をクリックすると、その文字で始まるオフィスのみを表示するようにビューがフィルタリングされ、検索プロセスが簡略化されます。すべてのオフィスを再び表示するには、アクティブな文字の選択を解除するだけです。この機能は、オフィスの名前は知っているが、TeamConnect内で迅速に見つける必要がある場合に特に便利です。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. TeamConnectで包括的なオフィスプロファイルを明らかにする
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  組織の物理的存在の中心を探検する旅に出発し、TeamConnect のオフィスプロファイルを通じてください。オフィス名をクリックすると、詳細なオフィス情報が満載のタブが豊富に揃った鮮やかなプロファイルダイアログが表示されます：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>概要:</Body1Strong> ビジネス構造から営業時間、現地時間に至るまで、詳細な記述子でオフィスのアイデンティティの核心に飛び込んでください。そのエッセンスをキャプチャーして、オフィス環境を鮮明にします。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>必須連絡先:</Body1Strong> 緊急サービスおよび外部サポートのための重要な連絡先にアクセスし、それぞれMicrosoft Teams Phone Systemを通じてクリック一つで繋がることができます。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>オフィス連絡先:</Body1Strong> 内部サポートサービスに容易に連絡を取り、連絡先の詳細と直接エンゲージメントオプションがすぐに手元にあります。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>公休日:</Body1Strong> オフィス固有の休日を把握し、地元の観察と労働スケジュールに計画が一致することを確実にします。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>営業時間:</Body1Strong> オフィスが活動に溢れる時間帯や日が暮れる時を正確に把握し、交流を計画します。
                    </li>
                  </ul>
                  オースティンの活気あふれるエネルギーであれ、シンガポールの戦略的なハブであれ、各タブは会社の国際的な存在を形成する様々な場所を理解し、接続するための入口を提供します。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. オフィス内の部門ダイナミクスについて
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  オフィスの部門の職業ネットワークをナビゲートすることは、TeamConnectで容易に展開されます。詳細については、以下の手順で進めます：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>このオフィスの部門を見る：</Body1Strong> もっと読む(...) メニューからこのオプションを選択して、オフィスを形成するすべての部門を発見してください。
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>組織の洞察：</Body1Strong> どの部門にもクリックすると、その組織図が表示され、ビジュアルでその構造が理解できるようになり、パンくずリストが案内します：Office {'>'} Department {'>'} Chart.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>部門の深掘り：</Body1Strong> 部門名の隣にあるもっと読むメニュー(...)をクリックして、その部門に属するすべての従業員のリストをアクセスできます。オフィスのコンテキストで、すぐに。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>パンくずナビゲーション：</Body1Strong> レベルをシームレスに移動します。従業員から部門まで、または組織図から、パンくずリスト (Office {'>'} Department {'>'} Employee {'>'} Chart) があなたの旅を直感的かつ正確に保ちます。
                    </li>
                  </ul>
                  TeamConnectの複雑なパスウェイが、オフィスの各部門の階層を探索して、理解し、関与することを可能にし、包括的なナビゲーション能力を提供します。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. TeamConnectでオフィスの人々とつながる
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  オフィスの背後にいる人々について知りたいですか？答えはTeamConnectでクリックするだけで得られます。選択したオフィス名の横の省略符号（三点リーダ）をクリックして、インタラクティブなオプションのスイートを表示します：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>このオフィスの従業員を見る：</Body1Strong> このオフィスを勤務地としているすべてのプロフェッショナルのリストを公開します。表示された数字が従業員の速算を提供し、オフィスコミュニティに深く潜入することを誘います。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>連絡オプション：</Body1Strong> 各従業員エントリにはクイックアクションアイコンが装備されており、TeamConnectから直接チャット、通話、またはビデオ会議を開始することができ、オフィス内のシームレスなコミュニケーションを促進します。
                    </li>
                    <li><Body1Strong>ナビゲーション：</Body1Strong>従業員リストからより広範なオフィスリストに戻るには、単にパンくずリストの「Office」(Office {'>'} Employee)をクリックすれば、オフィスの概要に戻ります。
                    </li>
                  </ul>
                  これらの機能を使用して、TeamConnectはオフィス横断的に労働力の分布を視覚化し、問題なくアクセスを提供し、どんな距離でもつながる職場環境を育成します。
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. あなたのオフィス情報に不足または古いものはありませんか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectでのオフィス情報の更新は、割り当てられたオフィスエディターが対応可能です。もしオフィスエディターについて不確かであるか、
                  HRの管轄下にある情報で支援が必要な場合、HRサポートチームが <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> で対応準備ができています。
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>設定FAQ</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. TeamConnectでの一般設定の正確性の重要性
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  今日の働き方の動的な環境では、接続され、調整されることがこれまで以上に重要です。それがTeamConnectでの一般設定の更新が重要な役割を果たすことになります：
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>職場の選択：</Body1Strong> ホームオフィスの静けさからメインオフィスの喧騒への変更、あるいは公共エリアへの設置など、現在の状況を反映するように職場を更新してください。
                        この作業場所の明確さは、同僚によるあなたへの連絡方法だけでなく、クライアントのオフィスの開放環境や空港のラウンジにいるときに特に感じる、機密性の高い会話の性質についても洞察を提供します。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>勤務地指定：</Body1Strong> 勤務都市を共有することは、リモートチームの設定の隔たりを埋める以上のことを行い、偶発的なプロの集まりやカジュアルな社交の扉を開きます。同じ都市にいると知っていれば、
                      通常の日が意義のある対面の接続の機会に変わるかもしれません。これはチームの絆を強化するだけでなく、あなたの作業に影響を与えているかもしれない地域のニュアンスをより深く理解するのにも役立ちます。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>タイムゾーンの正確さ：</Body1Strong> チームが大陸をまたにかける場合、現在の場所を反映してタイムゾーンを確保することが、通信の調和に不可欠です。あなたのTeamConnectプロファイルのローカルタイムはこの設定から直接影響を受け、
                      同僚があなたに連絡しようとする際の推測を排除します。
                    </li>
                  </ul>
                  '設定' {'>'} '一般設定' のTeamConnectにアクセスして、これらの詳細を微調整してください。設定を更新するために費やすひとときは、あなたの協力努力の一体感と効率を大幅に向上させることができます。
                </Body1>
              </AccordionPanel>
            </AccordionItem>           
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. TeamConnectで職場の選択を解読する
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  職場のドロップダウンで正しい設定を選択することで、TeamConnectが現在の勤務状態を正確に反映します。各オプションの詳細な説明については、情報アイコンにカーソルを合わせてください：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>チェックアウト／休憩：</Body1Strong> 休憩または業務終了時の離席を意味します。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>オフィス／フィールドオフィス：</Body1Strong> 会社が管理するワークスペースにいることを示します。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ホームオフィス：</Body1Strong> 専用の自宅ワークスペースで働いています。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>リモートワークプレイス／公共エリア：</Body1Strong> オフィス外の環境から操作しており、柔軟なコミュニケーションが求められています。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>顧客の職場／プライベートオフィス：</Body1Strong> 顧客の現場で、会話の機密性に関する注意を払っています。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>通勤／出張／研修：</Body1Strong> 外出中またはオフィス外で特定の業務活動に従事しています。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>公休日／休暇／その他の休暇：</Body1Strong> 個人、健康、または休暇の理由で公式に勤務していません。</li>
                  </ul>
                  適切な職場の設定を選択することで、チームは対話に必要なコンテキストを持ち、可用性とプライバシーを尊重することができます。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. TeamConnectで「チェックイン」ステータスをナビゲートする
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  従業員ディレクトリの「チェックイン」タグは、現在職場にいるすべての従業員を表示するように設計されています。この可視性は、あなたが選択する職場によって決まります：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>アクティブな作業環境：</Body1Strong> オフィス、フィールドオフィス、ホームオフィス、リモートワークプレイス、公共エリア、顧客の職場、
                    顧客の個室、通勤、出張、研修を選択すると、作業に従事しており、「チェックイン」として同僚にアクティブに表示されます。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>非作業設定：</Body1Strong> チェックアウト、休憩、公休日、休暇、休職、産休、病休のステータスを選択すると、「チェックイン」リストから外れ、可用性と応答時間の明確な期待が設定されます。
                    </li>
                  </ul>
                  この機能は、個人の時間と作業期間を区別し、効果的なチームコラボレーションとコミュニケーションに役立ちます。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>データ管理とプライバシーに関するFAQ</Subtitle2>
          <Body1>
            <p>
              今日のデジタル時代において、データの管理方法を理解することが非常に重要です。TeamConnectは透明性と堅牢なデータ保護の実践に尽力しています。
              以下では、TeamConnect内でどのようにデータを取り扱い、保存し、保護するかについての情報を提供しています。これにより、デジタルワークスペースが安全でコンプライアンスが確保されます。
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. TeamConnectは個人データをどのように保護しますか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnectはデータセキュリティに総合的に取り組むことにより、あなたの個人情報を保護することに尽力しています：</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>シングルサインオン（SSO）：</Body1Strong> セキュリティを強化し、ユーザーエクスペリエンスを向上させるため、TeamConnectは自動化されたシングルサインオン (SSO) を
                      Microsoft 365アカウントと統合して、安全かつ効率的なアクセス管理を支援します。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>暗号化：</Body1Strong> TeamConnect内のすべてのデータは、転送時および休止中の両方で暗号化され、不正アクセスから保護されます。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>データの最小化：</Body1Strong> 当社のデータ収集はTeamConnectの機能に不可欠なものに厳しく限定されており、保存しているユーザー情報はAzure Entra IDからのユーザープリンシパル名（UPN）のみで、これは会社のメールアドレスのように見える場合があります。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>アクセス制限：</Body1Strong> UPNへのアクセスは、私たちのサブスクリプションポータル内で組織のサブスクリプション管理者に限定されており、
                      機密情報が許可された人員にのみ表示されるようにしています。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>迅速なデータ削除：</Body1Strong> TeamConnectは、保存されたUPNを直ちに削除する構造になっており、サブスクリプションがサブスクリプション管理者によってキャンセルされた場合、HRによってTeamConnect内でユーザーが削除された場合、またはTeamConnectのサブスクリプションが期限切れになった場合の3つの具体的な条件下でこれを実施します。これにより、個人データがその有用性や必要性を超えて保持されないようにします。
                    </li>                      
                  </ul>
                  <p>これらの措置を通じて、TeamConnectはデータ保護の最高水準を維持し、あなたの個人情報が安全で機密性を保持された状態であることを約束します。</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. TeamConnectを利用するとデータはどこにあるのか疑問に思っていますか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectは、従来のSaaSの枠を超えて、データが組織を離れないように保証します。外部クラウドサーバーにデータを保存する一般的な方法とは異なり、TeamConnectは巧妙に組織のMicrosoft 365 SharePoint Onlineを使用して全てのアプリケーションデータを保管します。この革新的なアプローチはデータの主権を守るだけでなく、セキュリティを強化し、プライバシーの保護と厳格なコンプライアンス基準への遵守という私たちの強い献身に完全に沿っています。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. データは第三者と共有されますか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  いいえ、データは第三者と共有されません！Cubeetはあなたのデータのプライバシーとセキュリティに対する私たちのコミットメントに固執しています。お客様から明確な同意を得ることなく、いかなるTeamConnectデータも第三者と共有することはありません。安全な環境の中で、私たちはサブスクリプションポータル内にUser Principal Name (UPN)、組織名、テナント名、そしてテナントIDのような必要な識別子のみを保存しています。この情報はサービスの提供に不可欠であり、私たちの厳格なプライバシーポリシーの下で守られています。
                  ご安心ください、私たちのデータ管理の実践は、あなたのデータを機密で保持するよう設計されており、透明性とコントロールはしっかりとあなたの手の中にあります。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. TeamConnectはGDPRのようなデータ保護規制にどのように準拠していますか？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectは、すべての顧客データが安全に保存されるMicrosoft 365環境の固有のコンプライアンス機能を活用することで、GDPR、HIPAA、CCPAなどのデータ保護規制に適合します。従来のSaaSモデルとは異なり、TeamConnectは顧客データを自社のサーバーに保存せず、組織のMicrosoft 365環境内で運用し、その堅牢なセキュリティとコンプライアンス基準に従います。このアプローチにより、データガバナンスはMicrosoft 365が提供する包括的なコンプライアンスフレームワーク、GDPR、医療情報のためのHIPAA、カリフォルニア州の住民のためのCCPA、およびその他の世界のデータ保護規制への遵守を含む、一貫して保持されます。既存のMicrosoft 365設定とシームレスに統合することで、TeamConnectは組織がデータを完全に制御し、最も厳格なデータ保護法および規制の遵守を確保することを可能にします。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. TeamConnectはMicrosoft Teamsのチャット、通話、ビデオ通話、Outlookのメール、電話システムの通話データをどのように扱いますか?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectはMicrosoft Graph APIを活用して、Microsoft Teams内で直接チャット、通話、ビデオ通話、Outlookメールを可能にし、
                  シームレスなユーザーエクスペリエンスを提供します。重要なことに、TeamConnectがこれらのコミュニケーションを開始する時、
                  コミュニケーションプロセスに介入したり、インタラクションの内容を記録したり、保存する能力はありません。
                  すべての会話、通話、メールはMicrosoft TeamsとOutlookの安全な範囲内で直接行われ、管理され、Microsoft 365の厳格なセキュリティと
                  プライバシー基準に従います。これにより、TeamConnectはコミュニケーション能力を高めつつ、データの機密性と完整性を完全に保護します。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            当社のデータポリシーと実践についての詳細な洞察については、{' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>TeamConnectユーザーガイド</Body1Strong></Link>または
            <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>でご覧いただくか、
            お客様の組織のIT部門にご相談ください。
          </p>
        </Body1>        
        <p></p>  
        <Subtitle1 className={styles.fontColor}>私たちは耳を傾けています: フィードバックやアイデアを共有してください</Subtitle1>
        <Body1>
          <p>
            お客様の洞察がTeamConnectの進化を推進します。素晴らしい機能のアイデアにあふれている場合でも、現在の経験についてのフィードバックがある場合でも、
            私たちは全ての声に耳を傾けます。お客様の意見は私たちが最高のサービスを提供し続けるために不可欠です。
          </p>
        </Body1>      
        <Subtitle2>フィードバックと機能提案</Subtitle2>
        <Body1>
          <p>
            共有する準備はできましたか？私たちの{' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              フィードバックページ
            </Link>
            へどうぞ。新しい機能の提案か既存の機能に関するフィードバックかを問わず、フォームのドロップダウンから適切なオプションを選択してください。
            お客様からの貢献が、TeamConnectの未来を形作り、お客様のニーズに応えることを確実にします。
          </p>
        </Body1>
    </>
) ;
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
