import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>مرکز کمک و سوالات متداول TeamConnect</Title3>
        <p><Body1>
          به مرکز کمک TeamConnect خوش آمدید، طراحی شده برای پشتیبانی از تجربه شما با برنامه دایرکتوری جامع شرکتی ما. در اینجا، منابعی را برای بهینه‌سازی استفاده شما از TeamConnect درون Microsoft Teams خواهید یافت، که ارتباطات در شبکه شما را تقویت می‌کند.
        </Body1></p>
        <Subtitle2>راهنمای مرجع سریع</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>قابلیت‌های جستجوی پیشرفته: </Body1Strong>
              به سرعت همکاران، بخش‌ها و افراد تماس ضروری مورد نیاز خود را با ابزارهای جستجوی قدرتمند ما پیدا کنید.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>نمودارهای سازمانی بصری: </Body1Strong>
              سلسله مراتب سازمان خود را ناوبری کنید و خطوط گزارش‌دهی را به راحتی از طریق نمودارهای سازمانی شهودی درک کنید.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>ابزارهای سازگار با نیازهای امروز: </Body1Strong>
              از قابلیت‌های مدرن سازگار شده با تقاضاهای محیط‌های کاری معاصر استفاده کنید.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>بهینه‌سازی تجربه شما</Subtitle2>
        <Body1>
          <p>
            TeamConnect نحوه تعامل شما در ساختار سازمانی خود را متحول می‌سازد، و ارتباطات روزمره را به تعاملات استراتژیک تبدیل می‌کند.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>کمک گرفتن و سوالات متداول</Subtitle1>
        <Body1>
          <p>
            پاسخ به سوالات متداول در مورد ویژگی‌ها و تنظیمات TeamConnect را در این بخش اختصاصی بیابید. چه در حال ناوبری در اپلیکیشن، شخصی‌سازی تنظیمات، یا جستجوی راه‌حل‌هایی برای چالش‌ها باشید، مرکز کمک ما در اینجا است تا به شما به طور مؤثر کمک کند.
          </p>
        </Body1>
        <Body1>
          <p>
            برای پشتیبانی عمیق‌تر یا راهنمایی خاص، لطفاً <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>راهنمای کاربر TeamConnect</Body1Strong></Link> را مشاهده کنید.
          </p>
        </Body1>
        <p></p>        
        <Subtitle2>استفاده از سوالات متداول TeamConnect</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. نمی‌توانید TeamConnect را در نوار کناری Microsoft Teams بیابید؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  اگر TeamConnect به راحتی قابل مشاهده نیست یا در نوار کناری Microsoft Teams شما ناپدید شده است، می‌توانید به راحتی آن را برای دسترسی سریع سنجاق کنید. فقط این مراحل ساده را دنبال کنید:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Microsoft Teams را باز کنید و روی نماد '...' (Apps) در پایین نوار کناری کلیک کنید.</li>
                    <li className={styles.bulletBottomMargin}>در نوار جستجو در بالای پنجره بازشو ‘TeamConnect’ را جستجو کنید.</li>
                    <li className={styles.bulletBottomMargin}>روی برنامه TeamConnect از نتایج جستجو کلیک راست کنید و ‘سنجاق کردن’ را انتخاب کنید.</li>
                  </ol>
                  حالا TeamConnect به نوار کناری شما سنجاق شده و تنها با یک کلیک قابل دسترسی است!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. با مشکلات فنی در TeamConnect مواجه هستید؟ در اینجا چگونه کمک بگیرید.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  اگر با هرگونه مشکل فنی در TeamConnect درون Microsoft Teams روبرو شدید، تیم پشتیبانی فناوری اطلاعات سازمان شما برای کمک در اینجا حضور دارد.
                  با آن‌ها تماس بگیرید در <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>، و آن‌ها بلافاصله به شما کمک خواهند کرد.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. جزئیات کارمند خود را نمی‌توانید ببینید؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  اگر پروفایل شما کمی خالی به نظر می‌رسد و تنها نام و ایمیل شما نشان داده می‌شود، احتمالاً تیم منابع انسانی شما هنوز بقیه جزئیات کارمندی شما را به TeamConnect اضافه نکرده است.
                  نگران نباشید! یک پیام سریع به تیم پشتیبانی منابع انسانی کارها را به حرکت درمی‌آورد. آن‌ها آماده‌اند تا پروفایل شما را با تمام جزئیات مهم پر کنند.
                  با آن‌ها تماس بگیرید در <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> و شاهد زنده شدن پروفایل خود باشید!
                </Body1>
              </AccordionPanel>
            </AccordionItem>   
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۴. گزینه‌های زبانی در TeamConnect چه مواردی هستند؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect با پشتیبانی از تمام زبان‌های Microsoft Teams، تنوع جهانی را در
                  آغوش می‌کشد. خواه زبان مورد علاقه‌تان عربی باشد یا یونانی، ژاپنی یا سوئدی،
                  گزینه‌های جهانی در دسترس شما می‌باشند، که شامل:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      عربی، بلغاری، چینی (ساده و سنتی)، کرواتی، چک، دانمارکی، هلندی،
                      انگلیسی، استونیایی، فنلاندی، فرانسوی، آلمانی، یونانی، عبری، مجارستانی،
                      اندونزیایی، ایتالیایی، ژاپنی، کره‌ای، لتونی، لیتوانیایی، نروژی
                      (بوکمال)، لهستانی، پرتغالی (برزیل و پرتغال)، رومانیایی، روسی، صربی
                      (لاتین)، اسلواکی، اسلوونی، اسپانیایی، سوئدی، تایلندی، ترکی، اوکراینی،
                      ویتنامی.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  برای شخصی‌سازی تجربه TeamConnect به زبان مورد نظر خود، لطفاً تنظیمات زبان
                  را مستقیماً در Microsoft Teams تنظیم کنید، زیرا TeamConnect به طور کامل با
                  این تنظیمات یکپارچه است:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      با کلیک کردن روی سه نقطه کنار عکس پروفایل خود در بالا و انتخاب 'نمایش
                      و دسترس‌پذیری'، به تنظیمات Microsoft Teams خود بروید.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      به بخش 'زبان' اسکرول کنید و زبان مورد نظر خود را از منوی کشویی انتخاب
                      کنید.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      با کلیک کردن روی دکمه 'ذخیره و راه‌اندازی مجدد' تغییرات خود را تأیید
                      کنید تا تنظیمات زبان جدید اعمال شوند.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>توجه: </Body1Strong>
                <Body1>
                  تغییرات ایجاد شده در تنظیمات زبان در Microsoft Teams، تجربه TeamConnect
                  شما را نیز به طور خودکار به‌روزرسانی خواهد کرد. توجه داشته باشید که تغییر
                  زبان در Teams ممکن است فرمت زمان و تاریخ شما را نیز تغییر دهد. در حال
                  حاضر، TeamConnect در حال توسعه برای پشتیبانی از نوشتار از راست به چپ است.
                  ما از این پیشرفت‌ها هیجان‌زده هستیم و شما را به محض در دسترس بودن آن‌ها،
                  مطلع خواهیم کرد.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. چگونه می‌توانم تم در TeamConnect را تغییر دهم؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect با تنظیمات تم Microsoft Teams شما همزمان می‌شود، که شامل گزینه‌های روشن، تاریک، و کنتراست بالا می‌باشد. برای شخصی‌سازی تم خود:
                  <ol>
                    <li className={styles.bulletBottomMargin}>با کلیک کردن روی سه نقطه در کنار تصویر پروفایل خود در بالا، به تنظیمات Microsoft Teams خود دسترسی پیدا کنید.</li>
                    <li className={styles.bulletBottomMargin}>گزینه 'ظاهر و دسترس‌پذیری' را انتخاب کنید تا گزینه‌های تم را مشاهده کنید.</li>
                    <li className={styles.bulletBottomMargin}>تم مورد نظر خود را از بین گزینه‌های موجود انتخاب کنید تا فوراً رابط کاربری TeamConnect خود را به روز رسانی کنید.</li>
                  </ol>
                  انتخاب تم شما در Microsoft Teams به طور خودکار در TeamConnect اعمال می‌شود، که تجربه بصری یکپارچه‌ای در فضای کاری شما ایجاد می‌کند.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. کاوش دکمه‌های عملیات تماس در TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  در سراسر TeamConnect، جایی که جزئیات تماس یک کارمند پیدا کنید، دکمه‌های دسترسی سریع قابل کشف ما نیز خواهید دید. این ابزارهای کاربردی 
                  برای ساده‌سازی ارتباطات شما در درون اکوسیستم Microsoft طراحی شده‌اند، تا همیشه تنها با یک کلیک بتوانید ارتباط برقرار کنید.
                  در اینجا راهنمای جامع شما برای استفاده مؤثر از این دکمه‌ها آمده است:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>آیکن چت:</Body1Strong> برای شروع یک چت فوری Microsoft Teams کلیک کنید. اگر بخواهید پس از شروع چت به TeamConnect بازگردید، فقط از فلش برگشت در اپ دسکتاپ، دکمه برگشت در اپ موبایل، یا دکمه برگشت مرورگر خود استفاده کنید.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>آیکن ایمیل:</Body1Strong> پیش‌نویس ایمیل جدید در Outlook با آدرس گیرنده آماده در فیلد "To:" باز می‌شود. اگر Outlook راه‌اندازی نشود، بررسی با بخش IT شرکت‌تان برای هرگونه محدودیت ارزش دارد.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>آیکن تماس ویدئویی:</Body1Strong> تماس رودرروی ویدئویی را با استفاده از Microsoft Teams شروع می‌کند.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>آیکن تماس:</Body1Strong> تماس صوتی Microsoft Teams را شروع می‌کند، و بحث‌های سریع را بی‌درنگ می‌کند.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>آیکن نمودار سازمانی:</Body1Strong> جایگاه کارمند در سلسله مراتب سازمانی را نشان می‌دهد، شامل مدیر و گزارش‌های مستقیم آن‌ها.</li>
                    <li><Body1Strong>بیشتر (...) آیکن:</Body1Strong> گزینه‌های بیشتری را آشکار می‌کند:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>همین حالا ملاقات کنید:</Body1Strong> Microsoft Teams را برای شروع یک جلسه فوری با کارمند باز می‌کند.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>جلسه جدید:</Body1Strong> شما را به Microsoft Teams هدایت می‌کند تا یک جلسه جدید برنامه‌ریزی کنید.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>افزودن مخاطب:</Body1Strong> جزئیات تماس کارمند را از TeamConnect به برنامه جدید Microsoft Teams People اضافه می کند و عملکرد تماس شما را غنی می کند.</li>
                      </ul>
                    </li>
                  </ul>
                    این دکمه‌های بصری برای اطمینان از برقراری ارتباط مؤثر با همکاران، چه برای یک چت سریع، یک ایمیل، یا برنامه‌ریزی جلسات آینده، ساخته شده‌اند.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
              <AccordionItem value="7">
                <AccordionHeader>
                  <Body1Strong className={styles.fontColor}>
                    7. برقراری تماس از طریق TeamConnect
                  </Body1Strong>
                </AccordionHeader>
                <AccordionPanel>
                  <Body1>
                    به دنبال برقراری تماس هستید؟ TeamConnect ارتباطات شما را با ادغام با Microsoft Teams ساده می کند.
                    به سادگی روی شماره تلفن مورد نظر در TeamConnect کلیک کنید و voilà، رابط تماس مایکروسافت تیمز ظاهر می شود.
                    آماده برای شما برای شماره گیری یکپارچه.
                    <p>
                    <Body1Strong>توجه: </Body1Strong>
                    در برقراری تماس با مشکل مواجه می شوید؟ این ممکن است به دلیل سیاست‌های فناوری اطلاعات سازمان شما یا
                    استفاده از سیستم تلفنی غیر از Microsoft Teams Phone System. ورود به بخش فناوری اطلاعات در <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>، می تواند تا حدودی این موضوع را روشن کند.
                    </p>
                  </Body1>
               </AccordionPanel>
             </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۸. آیا هزینه‌هایی برای تماس‌های انجام شده از طریق TeamConnect وجود دارد؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect با استفاده از Microsoft Teams، تجربه‌ی تماس شما را ارتقا می‌دهد. در اینجا مواردی که باید در مورد هزینه‌های مرتبط با تماس بدانید، آورده شده است:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      برای تماس‌ها به تلفن‌های کارکنان، تلفن‌های اداری یا خطوط داغ در سازمان خود (شماره‌های داخلی)،
                      معمولا <Body1Strong>هیچ هزینه‌ای</Body1Strong> وقتی از سیستم تلفن Microsoft Teams استفاده می‌کنید نخواهد بود.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      برای تماس‌های خارجی، مانند تماس‌ها به تلفن‌های همراه یا خطوط بیرونی، <Body1Strong>نرخ‌های استاندارد</Body1Strong> سیستم تلفن سازمان شما اعمال می‌شود.
                      این نرخ‌ها بسته به برنامه سرویس خاص شما با Microsoft Teams متفاوت است.
                    </li>
                  </ul>
                  <p><Body1Strong>توجه:</Body1Strong> اگر با هر مشکلی مواجه شدید یا سوالی در مورد هزینه‌های تماس دارید، بهتر است با دپارتمان IT خود در <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> برای اطلاعات دقیق‌تر و متناسب با تنظیمات و سیاست‌های سازمان خود مشورت کنید.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۹. ناهماهنگی در وضعیت حضور 'بیرون از دفتر' در TeamConnect را مشاهده کردید؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  متوجه شدید که وضعیت حضور 'بیرون از دفتر' شما از Microsoft 365 به طور صحیح در TeamConnect منعکس نمی‌شود؟ ما این ویژگی را پوشش داده‌ایم. ویژگی همسان‌سازی وضعیت حضور 'بیرون از دفتر' در TeamConnect اجرا شده است. ما در انتظار استقرار این ویژگی توسط Microsoft هستیم. پس از آن، وضعیت حضور 'بیرون از دفتر' شما به طور دقیق در TeamConnect منعکس خواهد شد، که با نمایش‌گرهای حضور Microsoft Teams برای یک تجربه بدون وقفه هماهنگ می‌شود.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>پرسش‌های متداول دایرکتوری کارکنان</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>معرفی دایرکتوری کارکنان:</Body1Strong> منظره حرفه‌ای سازمان خود را با دایرکتوری کارکنان کاوش کنید، مرکز مرکزی برای بینش‌های دقیق کارکنان، دینامیک‌های محیط کار، و ارتباط مستقیم با پروفایل‌ها و روابط مدیریتی همکارانتان.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۱۰. نیاز به افزودن، به‌روزرسانی، یا اصلاح داده‌های کارمندی خود دارید؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  برای افزودن، به‌روزرسانی، یا اصلاح داده‌های کارمندی خود در TeamConnect، لطفاً با تیم پشتیبانی HR سازمان خود تماس بگیرید. آن‌ها آماده کمک هستند در <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۱۱. چه اتفاقی می‌افتد وقتی روی نام کارمندی در دایرکتوری کلیک کنم؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  کلیک کردن روی نام کارمند در دایرکتوری کارمندان TeamConnect صفحه‌ی پروفایل تفصیلی آن فرد را باز می‌کند. این پروفایل اطلاعات جامعی 
                  درباره‌ی کارمند ارائه می‌دهد، شامل اما نه محدود به، عنوان شغلی، دپارتمان، مکان دفتر کار، اطلاعات تماس و بیشتر. طراحی شده است تا 
                  شما را با نقش حرفه‌ای کارمند و جایگاه او در سازمان آشنا کند، تا درک بهتری از مسئولیت‌ها و نحوه‌ی همکاری ممکن با آنها داشته باشید.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۱۲. چه اطلاعاتی در پروفایل یک کارمند گنجانده شده است؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  هر پروفایل کارمند در TeamConnect یک نمایه‌ی جامع است، مشتمل بر دیدگاه‌های ارزشمندی از زندگی حرفه‌ای فرد.
                  در اینجا مواردی وجود دارد که خواهید یافت:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>جزئیات پایه:</Body1Strong> نام، عنوان شغلی، دپارتمان و محل شعبه.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ساختار گزارش‌دهی:</Body1Strong> اطلاعات درباره‌ی گزارش‌های مستقیم و عملکردی، 
                      شامل مدیران خطی و عملکردی.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>وضعیت محل کار:</Body1Strong> وضعیت کاری فعلی مانند در مرخصی، در رفت‌وآمد، 
                      سفر کاری و غیره.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>محل کار و زمان محلی:</Body1Strong> مکان کاری کنونی کارمند و 
                      منطقه‌ی زمانی محلی.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>اطلاعات تماس:</Body1Strong> تلفن کاری، شماره موبایل و آدرس ایمیل.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>آدرس دفتر کار:</Body1Strong> مکان دفتر کامل شامل نام ساختمان،
                      آدرس و نقشه گوگل.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>درباره:</Body1Strong> معرفی کوتاه شخصی یا بیوگرافی حرفه‌ای.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>روابط کارمندی:</Body1Strong> جزئیات ساختار گزارش‌دهی و روابط تیمی 
                      کارمند.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>لینک‌های اجتماعی و زبان‌ها:</Body1Strong> لینک‌های به پروفایل‌های اجتماعی حرفه‌ای
                      و تسلط به زبان‌ها.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>مهارت‌ها:</Body1Strong> فهرستی از مهارت‌های حرفه‌ای و حوزه‌های تخصصی.</li>
                  </ul>
                  علاوه بر این، پروفایل گزینه‌های عملیاتی برای ارتباط از طریق چت، ایمیل، تماس تصویری و برنامه‌ریزی جلسات را ارائه می‌دهد، همراه با
                  دسترسی سریع به نمودار سازمانی برای درک بهتر از زمینه‌ی تیم.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. چطور می‌توانم اطلاعات پروفایل خود را به‌روز کنم؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  شخصی‌سازی پروفایل شما در TeamConnect آسان است. اینجا چگونگی انجام آن را بیان می‌کنیم:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        به زبانه <Body1Strong>کارمند</Body1Strong> بروید و بر روی <Body1Strong>آواتار</Body1Strong> خود کلیک کنید تا پروفایل شما باز شود.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        در گوشه بالا سمت چپ، پیوند <Body1Strong>"ویرایش پروفایل شما"</Body1Strong> را خواهید یافت.
                        با کلیک کردن بر این پیوند، پنجره ویرایش پروفایل شما باز می‌شود.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        در اینجا، شما می‌توانید با افزودن یا به‌روز کردن بخش <Body1Strong>درباره شما</Body1Strong>، پیوندهای شبکه‌های اجتماعی، و شماره تلفن، پروفایل خود را بهبود بخشید.
                        این کار، ارتباط یا دنبال کردن همکاران با شما را آسان‌تر می‌کند.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        فراموش نکنید که دکمه <Body1Strong>ذخیره</Body1Strong> را فشار دهید تا تغییرات شما اعمال شود.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        در پنجره به پایین اسکرول کنید و <Body1Strong>زبان‌ها</Body1Strong> و <Body1Strong>مهارت‌ها</Body1Strong> خود را 
                        اضافه کنید، همچنین <Body1Strong>دانش</Body1Strong> خود را 
                        به اشتراک بگذارید. اشتراک‌گذاری این اطلاعات به همکاران شما کمک می‌کند تا درک بهتری از تخصص‌های شما و زبان‌هایی که 
                        می‌توانید در آن‌ها ارتباط برقرار کنید، داشته باشند.
                      </li>
                    </ol>
                  به‌روز رسانی پروفایل شما نه تنها اطلاعات شما را به‌روز نگه می‌دارد، بلکه ارتباطات بهتری را در سازمان شما ایجاد می‌کند.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. به‌روز رسانی محل کار، مکان کار و منطقه زمانی در TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  نگه داشتن اطلاعات محل کار، مکان کار و منطقه زمانی شما به‌روز بسیار ضروری است برای همکاری بدون درز در Microsoft Teams.
                  این به‌روزرسانی‌ها بلافاصله در پروفایل و لیست‌های TeamConnect شما منعکس می‌شوند، که به همکاران اجازه می‌دهد به سرعت مکان کار شما و 
                  بهترین زمان‌ها برای ارتباط با شما را درک کنند، همکاری را در مناطق و مناطق زمانی مختلف افزایش می‌دهد.
                  <p></p>
                  آماده به‌روزرسانی جزئیات خود هستید؟ در TeamConnect آسان است! به 'تنظیمات' در منوی بالا بروید و به 'تنظیمات عمومی' ناوبری کنید.
                  در اینجا، شما می‌توانید به راحتی محل کار و منطقه زمانی خود را به‌روز کنید و مکان کار روزانه خود را مشخص کنید. به یاد داشته باشید که تغییرات خود را ذخیره کنید تا
                  همه را مطلع کرده و همکاری مؤثری داشته باشید، صرف نظر از مکان شما!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. موردی نادرست در پروفایل همکاری خود دیده‌اید؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  اگر جزئیاتی در پروفایل همکاری را مشاهده کردید که کاملاً درست به نظر نمی‌رسد، بهترین گام اولیه این است که به آن‌ها یک هشدار دوستانه بدهید.
                  یک چت سریع یا ایمیل کافی است! اگر به هر دلیلی اطلاعات به‌روز نشود، نگران نباشید—فقط به تیم پشتیبانی HR خود در <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> پیام دهید، و آن‌ها خوشحال 
                  خواهند شد که وارد شوند و موارد را مرتب کنند.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. درک گروه‌های برچسب: همه، مورد علاقه‌ها، حاضرین در محل کار، و کارمندان جدید
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>همه:</Body1Strong> این برچسب پیش‌فرض، هر کارمند در سازمان شما را نشان می‌دهد،
                      و یک دید کلی از تیم را فراهم می‌کند.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>مورد علاقه‌ها:</Body1Strong> اینجا کلیک کنید تا نمای خود را فیلتر کنید تا آن افرادی که شما 
                      به عنوان 'مورد علاقه' علامت‌گذاری کرده‌اید نمایش داده شود، که این کار ارتباط سریع‌تر با افراد کلیدی را آسان‌تر می‌کند.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>حاضرین در محل کار:</Body1Strong> این برچسب کارمندانی را که در حال کار هستند را نشان می‌دهد،
                      و آن‌ها را از حضور آنلاین ساده در مایکروسافت تیمز متمایز می‌کند. با حضور در محل کار، کارمندان نشان می‌دهند که آن‌ها در حال کار هستند و محل کار خود در طول روز را به اشتراک می‌گذارند.
                      این می‌تواند شامل مکان‌های کاری مختلفی مانند دفتر مرکزی، دفتر شعبه، دفتر خانگی، محل کار از راه دور، محل کار مشتری، 
                      دفتر خصوصی مشتری، مناطق عمومی، مسیر مسافرت، سفر کاری، یا حتی آموزش شود. بنابراین، وضعیت 'حاضر در محل کار' نشان‌دهنده فعال بودن در کار است، 
                      و به همکاران درک دقیقی از زمینه کاری فعلی و دسترسی یکدیگر را ارائه می‌دهد.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>کارمندان جدید:</Body1Strong> با این برچسب، چهره‌های جدید در TeamConnect را شناسایی کنید،
                      که کارمندانی را که طی 30 روز گذشته به اپ اضافه شده‌اند، برجسته می‌کند. معمولاً، این با افزوده‌های اخیر به سازمان شما همسو است، که به شما کمک می‌کند تا اعضای جدید تیم را به گرمی استقبال کنید.
                    </li>
                  </ul>
                  این گروه‌های برچسب برای سهولت در ناوبری و بهبود تجربه TeamConnect شما طراحی شده‌اند، تا همیشه بتوانید به طور کارآمد با همکاران خود ارتباط برقرار کنید.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. رمزگشایی آیکون‌های کنار جعبه جستجو
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  دایرکتوری کارمندان TeamConnect برای انعطاف‌پذیری و سهولت طراحی شده است و شامل چهار آیکون است که نماهای مختلفی را ارائه می‌دهند
                  و دسترسی سریع به جزئیات کلیدی را فراهم می‌کنند. در اینجا آنچه هر آیکون آشکار می‌کند بیان شده است:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>نمای لیست:</Body1Strong> نمای اصلی شما، که همه اعضای سازمان را در یک لیست ساده ارائه می‌دهد.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>نمای کارت کوچک:</Body1Strong> یک جایگزین فشرده، این نما لیست را به کارت‌های کوچک کارمندان تبدیل می‌کند.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>نمای کارت بزرگ:</Body1Strong> برای جزئیات بیشتر، با کارت‌های بزرگ‌تری که گزینه‌های تماس اضافی مانند شماره تلفن و شماره موبایل را برای شماره‌گیری آسان‌تر شامل می‌شوند، انتخاب کنید.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>نمودار سازمانی:</Body1Strong> با نمای نمودار سازمانی ساختار شرکت را مجسم کنید، 
                      که برای درک روابط گزارش‌دهی و پیمایش در چشم‌انداز سازمانی ایده‌آل است.</li>
                  </ol>
                    بین این آیکون‌ها جابجا شوید تا نمایی که در لحظه بهترین نیازهای شما را برآورده می‌کند پیدا کنید، خواه به دنبال اطلاعات سریع یا بینش‌های عمیق‌تر باشید.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. برای جستجوی سریع همکاران، کجا باید نگاه کنم؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  به دنبال همکار هستید؟ فقط به جعبه جستجوی TeamConnect بروید، که در سمت راست بالا در کنار آیکون نمودار سازمانی قرار دارد. 
                  شروع به تایپ نام آن‌ها کنید و با هر حرف نتایج را دقیق‌تر ببینید. به خاطر داشته باشید، برای جستجوهای TeamConnect، از نوار جستجوی مایکروسافت تیمز در بالا صرف‌نظر کنید—جعبه جستجوی مخصوص ما راه بهتری است!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. جستجوی الفبایی برای یافتن کارمندان چگونه کار می‌کند؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  دایرکتوری کارمندان TeamConnect شامل ویژگی جستجوی الفبایی کاربردی است تا به شما کمک کند تا به سرعت کارمندان را بر اساس نام اولیه‌شان پیدا کنید.
                  تنها کافی است بر روی حرفی از الفبا که در بالای دایرکتوری نمایش داده شده کلیک کنید، و TeamConnect بلافاصله تمام کارمندانی که نام اولیه‌شان با آن حرف شروع می‌شود را نمایش می‌دهد.
                  برای بازگشت به لیست کامل کارمندان، تنها کافی است حرف برجسته شده را لغو کنید. این ویژگی شهودی، پیمایش در لیست‌های بزرگ کارمندان را آسان می‌کند و اطمینان می‌دهد که شما به سرعت و با کارایی همکاران خود را پیدا کنید.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. چگونه می‌توانم تعداد کارمندان نمایش داده شده در هر صفحه را تنظیم کنم؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect به شما اجازه می‌دهد تا تعداد نمایه‌های کارمندی که در هر صفحه مشاهده می‌کنید را سفارشی کنید، که پیمایش در دایرکتوری کارمندان را آسان‌تر می‌کند.
                  در پایین هر صفحه، گزینه‌های صفحه‌بندی را پیدا خواهید کرد: 10، 50، 100، 500، 1000، و 5000. تنظیم پیش‌فرض 10 نمایه در هر صفحه را نشان می‌دهد.
                  برای مشاهده نمایه‌های بیشتر در یک صفحه، تنها کافی است عدد مورد نظر خود را از این گزینه‌ها انتخاب کنید. این ویژگی انعطاف‌پذیری را در نحوه مرور دایرکتوری فراهم می‌کند، به شما امکان می‌دهد تا نمایش را مطابق با نیازها یا ترجیحات خاص خود تنظیم کنید.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. چگونه می‌توانم اطلاعات کارمندان را در نمای لیست مرتب کنم؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  در نمای لیست دایرکتوری کارمندان TeamConnect، شما انعطاف‌پذیری دارید تا اطلاعات کارمندان را طبق نیاز خود مرتب کنید. ستون‌ها—نام کارمند، عنوان شغلی، دپارتمان، محل دفتر، و محل کار—همگی قابل مرتب‌سازی هستند. تنها کافی است بر روی سربرگ ستونی که می‌خواهید بر اساس آن مرتب‌سازی شود کلیک کنید. کلیک یک‌باره ستون را به ترتیب صعودی مرتب می‌کند، و کلیک دوباره آن را به ترتیب نزولی مرتب می‌کند. به طور پیش‌فرض، دایرکتوری بر اساس نام کارمند به ترتیب صعودی مرتب شده است، بر اساس نام اول. این ویژگی مرتب‌سازی به شما کمک می‌کند که جزئیات کارمندان را به سرعت سازمان‌دهی و مشاهده کنید که به بهترین شکل به هدف شما خدمت می‌کند، چه به دنبال شخص خاصی باشید یا نیاز داشته باشید که کارمندان را بر اساس معیار خاصی مانند دپارتمان یا موقعیت مکانی گروه‌بندی کنید.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>پرسش‌های متداول دایرکتوری بخش‌ها</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>معرفی دایرکتوری بخش‌ها:</Body1Strong> دایرکتوری بخش‌ها نقطه شروع درک ساختار پیچیده سازمان شما است، با ارائه دیدگاهی روشن از سلسله مراتب بخش‌ها، رهبری مدیریتی و راه‌های ارتباطی بدون درز بین بخش‌ها.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۲۲. برچسب‌های 'همه'، 'مورد علاقه‌ها' و 'بخش شما' در دایرکتوری بخش‌ها چگونه کار می‌کنند؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  در دایرکتوری بخش‌های تیم کانکت، برچسب‌ها به شما کمک می‌کنند تا به سرعت اطلاعات بخش‌ها را مرور و فیلتر کنید. در اینجا توضیح داده شده که هر برچسب چه نمایانگری دارد:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>همه:</Body1Strong> این برچسب تمام بخش‌ها در سازمان شما را نمایش می‌دهد و دیدگاه جامعی فراهم می‌کند.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>مورد علاقه‌ها:</Body1Strong> از این برچسب برای مشاهده فهرست انتخابی بخش‌هایی که به عنوان 'مورد علاقه' علامت‌گذاری کرده‌اید استفاده کنید، که دسترسی به بخش‌هایی که به طور مکرر با آنها تعامل دارید را آسان‌تر می‌کند.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>بخش شما:</Body1Strong> این برچسب نمایش را فیلتر می‌کند تا فقط بخش شما را نشان دهد، که دسترسی سریع به اطلاعات و به‌روزرسانی‌های بخش مستقیم شما را ممکن می‌سازد.</li>
                  </ul>
                  این برچسب‌ها برای ساده‌سازی پیمایش شما در دایرکتوری بخش‌ها طراحی شده‌اند تا قدرت شما برای پیدا کردن و تعامل با اطلاعات بخش‌ها را به طور مؤثر افزایش دهند.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۲۳. درک نماهای دایرکتوری بخش‌ها در تیم کانکت
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  پیمایش در دایرکتوری بخش‌ها در تیم کانکت با گزینه‌های چندگانه نمایش برای مطابقت با ترجیحات شما ساده شده است. هر نمایش راهی منحصر به فرد برای کاوش اطلاعات جامع بخشی، از جمله نام بخش، مدیر بخش، بخش والد، شماره تلفن بخش، خط اضطراری بخش و ایمیل بخش را ارائه می‌دهد. در اینجا یک نمای کلی از نماهای در دسترس آورده شده:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>نمای لیست:</Body1Strong> تنظیم پیش‌فرض که تمام بخش‌ها را به طور واضح و مختصر فهرست می‌کند.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>نمای کارت بزرگ:</Body1Strong> به این نما تغییر دهید تا کارت‌های بخش را با جزئیات دیدن کنید، که خلاصه گسترده‌ای از اطلاعات کلیدی هر بخش را ارائه می‌دهد.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>نمای نمودار سازمانی:</Body1Strong> برای نمایش بصری از ساختار بخشی و روابط، نمای نمودار سازمانی نشان می‌دهد که هر بخش چگونه در سلسله مراتب سازمانی بزرگ‌تر جای می‌گیرد.
                    </li>
                  </ul>
                  برای تعویض بین این نماها، کافی است از نمادهای کنار جعبه جستجو در بالای دایرکتوری بخش‌ها استفاده کنید. هر نما برای کمک به شما در دسترسی و درک جزئیات بخشی به راحتی طراحی شده است، تا اطمینان حاصل کنید که اطلاعات مورد نیاز خود را به شکلی که بهترین کارایی را دارد، پیدا کنید.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۲۴. چگونه از کادر جستجو در دایرکتوری بخش‌ها استفاده کنیم؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  به راحتی هر بخشی را در TeamConnect با استفاده از کادر جستجوی اختصاصی که دقیقاً در کنار نمادهای نمایش در بالای دایرکتوری بخش‌ها قرار دارد، پیدا کنید. فقط کافی است تایپ کردن نام بخش مورد نظر خود را شروع کنید و تماشا کنید که دایرکتوری خود را برای نمایش تنها موارد مرتبط تصفیه می‌کند. این ویژگی به طور خاص برای کمک به شما در دور زدن نیاز به جستجوی دستی در کل دایرکتوری طراحی شده است، بنابراین بخش مورد نیاز خود را مستقیماً به نوک انگشتان شما می‌آورد. به خاطر داشته باشید، این عملکرد جستجو منحصر به فرد در دایرکتوری بخش‌های TeamConnect است، پس حتماً از این کادر جستجو به جای جستجوی اصلی Microsoft Teams در بالاترین قسمت رابط Teams برای پرس‌و‌جوهای خاص بخش استفاده کنید.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۲۵. فیلتر الفبایی در دایرکتوری بخش‌ها چگونه کار می‌کند؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  جستجوی خود را در دایرکتوری بخش‌ها با استفاده از فیلتر الفبایی که در بالا قرار دارد، بهینه کنید. این ویژگی به شما امکان می‌دهد به سرعت بخش‌ها را با انتخاب یک حرف محدود کنید. پس از انتخاب یک حرف، TeamConnect فقط آن بخش‌ها را که نام‌های آن‌ها با حرف انتخابی شما شروع می‌شود، فیلتر و نمایش می‌دهد و این کار را برای پیدا کردن آنچه دنبالش هستید بدون نیاز به پیمایش کل لیست، آسان‌تر می‌کند. برای بازگشت به نمای کامل بخش، فقط کافی است حرف برجسته را لغو انتخاب کنید. این ناوبری الفبایی بصری به شما اطمینان می‌دهد که می‌توانید به طور مؤثر هر بخش را با حرف اول آن پیدا کنید.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۲۶. چگونه می‌توانم با مدیر بخش تماس بگیرم؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  تماس با مدیر بخش در TeamConnect سرراست است. فقط کافی است بخش مورد علاقه خود را پیدا کرده و روی نام مدیر کلیک کنید. این عمل یک پاپ‌اور را فعال می‌کند که گزینه‌های تماس مدیر را با نمادهای دسترسی سریع نمایش می‌دهد:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>نماد چت:</Body1Strong> برای شروع یک پیام فوری از طریق چت Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>نماد ایمیل:</Body1Strong> برای باز کردن یک ایمیل جدید خطاب به آن‌ها در Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>نماد تماس ویدیویی:</Body1Strong> برای شروع یک تماس ویدیویی از طریق Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>نماد تماس:</Body1Strong> برای آغاز یک تماس صوتی با استفاده از Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>نماد بیشتر (...) :</Body1Strong> برای گزینه‌های اضافی مانند برنامه‌ریزی جلسه یا اضافه کردن اطلاعات تماس آن‌ها به لیست Teams شما.
                    </li>
                  </ul>
                  این نمادها یک روش بی‌درنگ برای ارتباط مستقیم با مدیران بخش‌ها از درون TeamConnect فراهم می‌آورند و ارتباط مؤثر در سازمان شما را تضمین می‌کنند.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۲۷. چگونه می‌توانم افراد یا کارمندان یک بخش را مشاهده یا با آن‌ها تماس بگیرم؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  برای کشف افراد درون یک بخش در TeamConnect، کافی است به سه نقطه مجاور نام بخش نگاه کنید. کلیک کردن بر روی آن گزینه‌های بیشتری برای عمل آوردن به شما نمایش می‌دهد:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>مشاهده کارمندان در این بخش:</Body1Strong> این گزینه را انتخاب کنید تا فهرستی از تمام 
                      کارمندان مرتبط با بخش نمایش داده شود. عدد کنار این گزینه نشان می‌دهد چند نفر در بخش هستند و سریعاً تعداد آن‌ها را به شما می‌دهد.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>نمودار سازمانی بخش:</Body1Strong> اگر می‌خواهید سلسله مراتب بخش را درک کنید،
                      'نمودار سازمانی بخش' را انتخاب کنید تا چیدمان ساختاری را نمایش دهد، نشان دادن رابطه بین بخش‌های اصلی، بخش مورد نظر، 
                      و زیربخش‌ها. این نمایش تصویری به شما کمک می‌کند تا از چارچوب سازمانی عبور کنید و آن را درک کنید، که این کار را آسان‌تر می‌کند برای شناسایی و 
                      تماس با اعضای خاص بخش.
                    </li>
                    <li><Body1Strong>بخش {'>'} کارمند:</Body1Strong> پس از مشاهده کارمندان درون یک بخش، از این مسیر ناوبری برای برگشت آسان 
                      به فهرست بخش استفاده کنید، تجربه گشت و گذار راحت‌تری را در TeamConnect حفظ کنید.
                    </li>
                  </ul>
                  این ویژگی‌ها به شما اجازه می‌دهند نه تنها فهرست کارمندان در یک بخش مشخص را مشاهده کنید، بلکه یک مسیر برای تماس مستقیم با آن‌ها از طریق ابزارهای ارتباطی TeamConnect فراهم می‌کنید.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۲۸. کشف جزئیات عمقی بخش‌ها در TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  با کلیک ساده بر روی نام هر بخش در TeamConnect، به جزئیاتی از آن بخش فرو بروید. یک گفتگوی تفصیلی آغاز می‌شود که پرده از اطلاعات غنی برمی‌دارد و تصویر کاملی از نقش و منابع بخش در سازمان شما می‌زند. شما هر چیزی را از رهبری گرفته تا شناسه‌های مالی، کانال‌های ارتباطی و لینک‌های اجتماعی پیدا می‌کنید. این ویژگی دروازه‌ای به:
                  <ul>
                    <li className={styles.bulletBottomMargin}>قلب بخش – هدف، افراد و معیارهای عملکرد آن.</li>
                    <li className={styles.bulletBottomMargin}>راه‌های تماس – خطوط مستقیم به اشخاص کلیدی بخش و سکوهای ارتباطی.</li>
                    <li className={styles.bulletBottomMargin}>شبکه سازمانی – درک نحوه تلفیق بخش با ساختار شرکت گسترده‌تر.</li>
                  </ul>
                  چه برای یک پرسش سریع باشد و چه برای کاوش عمیق در داده‌های بخش، TeamConnect با ابزارهایی که به شما مجهز می‌کند، به شما امکان می‌دهد تا با اکوسیستم‌های منحصر به فرد در سازمان خود ارتباط برقرار کرده و آن‌ها را درک کنید.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۲۹. کنجکاو درباره عدم وجود آدرس‌های بخش‌ها در TeamConnect؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  یافتن مکان یک بخش گاهی اوقات مانند گنج‌یابی بدون نقشه به نظر می‌رسد. در TeamConnect، معمولاً آدرس اختصاصی برای هر بخش پیدا نمی‌کنید. دلایل عمومی حذف این جزئیات نقشه‌برداری به شرح زیر است:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>مکان‌های متعدد:</Body1Strong> بسیاری از بخش‌ها به یک فضای واحد محدود نمی‌شوند؛ آن‌ها در دفاتر و جغرافیاهای مختلف رونق می‌گیرند.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ساختار متغیر:</Body1Strong> چشم‌انداز سازمانی همواره در حال تغییر است، با 
                      بخش‌های در حال تکامل، ادغام، یا حتی منحل شدن در هماهنگی با تغییرات استراتژیک.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>همکاری بین بخشی:</Body1Strong> محیط‌های کاری مدرن با روحیه همکاری می‌درخشند، اغلب استعدادها را از بخش‌های مختلف در فضاهای مشترک ترکیب می‌کنند.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>فرهنگ کار از راه دور:</Body1Strong> با انعطاف‌پذیری کار از راه دور، لنگرهای بخشی همیشه در مکان‌های دفتری فیزیکی افکنده نمی‌شوند.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>زیرنویس‌های قانونی:</Body1Strong> بخش‌ها معمولاً به دور ساختار قانونی شرکت می‌چرخند تا داشتن آدرس‌های مستقل خودشان.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>بهینه‌سازی عملیاتی:</Body1Strong> تمرکز بر همکاری پویا، نه آدرس‌های ثابت، به حفظ چابکی عملیاتی کمک می‌کند.
                    </li>
                  </ul>
                  همه چیز درباره پذیرش رویکردی بدون مرز به کار تیمی و کارایی است. TeamConnect با تأکید بر ارتباطات به جای مکان‌ها، این فلسفه را منعکس می‌کند.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. آیا اطلاعات دپارتمان شما مفقود یا قدیمی است؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ویراستاران دپارتمان در سازمان شما مشخص می‌شوند تا اطلاعات خاص دپارتمان را در TeamConnect اضافه، به‌روزرسانی یا اصلاح کنند.
                  اگر مطمئن نیستید که ویراستار دپارتمان شما کیست یا اگر اطلاعات خاصی نیاز به مداخله تیم پشتیبانی HR دارد، 
                  لطفاً برای راهنمایی با <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> تماس بگیرید.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>سوالات متداول دفترچه راهنمای دفاتر</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>معرفی دفترچه راهنمای دفاتر:</Body1Strong> از طریق دفترچه راهنمای دفاتر، مرکز حضور فیزیکی سازمان خود را کشف کنید،
                که شامل جزئیات ضروری دفاتر، جزئیات عملیاتی و نگاه جامع به دپارتمان‌ها و پرسنلی است که هر مکان را تعریف می‌کند.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. راهنمای برچسب‌ها در دفترچه راهنمای دفاتر
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  با استفاده از سیستم برچسب‌زنی ما که برای دسترسی سریع به مکان مناسب طراحی شده است، به راحتی در دفترچه راهنمای دفاتر خود را پیدا کنید. در اینجا نحوه کمک هر برچسب برای ساده‌سازی جستجوی شما آورده شده است:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>همه:</Body1Strong> لیست کاملی از دفاتر را نمایش می‌دهد و نمای کلی از 
                      مکان‌های سازمان شما را فراهم می‌آورد.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>مورد علاقه:</Body1Strong> به شما کمک می‌کند تا روی مکان‌های دفتری که غالباً از آن‌ها بازدید می‌کنید یا نیاز دارید به دقت آن‌ها را زیر نظر داشته باشید، تمرکز کنید، با علامت‌گذاری آن‌ها به عنوان 'مورد علاقه'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>دفتر شما:</Body1Strong> این برچسب شخصی، دفترچه راهنما را فیلتر می‌کند تا مکان دفتری که در آن مستقر هستید را به شما نشان دهد، و پایگاه خانگی شما را در دسترس شما نگه می‌دارد.
                    </li>
                  </ul>
                  چه برای رزرو اتاق جلسه باشید یا برای برنامه‌ریزی بازدید، این برچسب‌ها راهی شخصی و ساده‌شده برای تعامل با چشم‌انداز جغرافیایی سازمان شما ارائه می‌دهند.
                </Body1>
              </AccordionPanel>
            </AccordionItem>           
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. تسلط بر نماهای دفتری در TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  با نماهای دفتری TeamConnect، طراحی شده برای تسهیل جنبه‌های منحصر به فرد مسیریابی و درک اداری، به چیدمان سازمانی مختلف مکان‌های شرکت خود پردازید:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>نمای لیست:</Body1Strong> تنظیم پیش‌فرض که تمامی مکان‌های دفتری را به صورت فهرستی قابل‌خوانده و مرتب سازی می‌کند.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>نمای کارت بزرگ:</Body1Strong> نمای کارت بزرگ در دایرکتوری دفتری با ارائه جزئیات دقیق هر دفتر غنی‌سازی شده است. هنگام تعامل با یک کارت دفتر، اطلاعات فراوان و گزینه‌های عملیاتی را در منوی "بیشتر (...)" خواهید یافت:
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>مشاهده بخش‌ها:</Body1Strong> به طور مستقیم به فهرست تمام بخش‌های موجود در دفتر دسترسی پیدا کنید.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>مشاهده کارمندان:</Body1Strong> به فهرست کامل کارمندان اختصاص داده شده به دفتر دسترسی داشته باشید، تا ارتباط مستقیم یا بررسی پرسنل دفتر را تسهیل کند.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>نمودار سازمانی نهاد کسب و کار:</Body1Strong> جایگاه دفتر را در زمینه سازمانی گسترده‌تر برای درک شفاف ساختارهای سلسله مراتبی تجسم کنید.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>اطلاعات تماس ضروری:</Body1Strong> ارتباطات کلیدی دفتر را برای ارتباطات هموار شناسایی کنید.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>تعطیلات عمومی دفتر:</Body1Strong> از تعطیلات عمومی مخصوص دفتر مطلع شوید تا برنامه‌های خود را متناسب سازید.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>ساعات کاری دفتر:</Body1Strong> ساعات کاری دفتر را مشاهده کنید تا بازدیدها یا تماس‌های خود را با زمان‌های فعال آنها هماهنگ کنید.
                        </li>
                      </ol>
                      این نما نه تنها یک نمای بصری و اطلاعاتی فوری را ارائه می‌دهد، بلکه دسترسی سریع به بینش‌های عمیق‌تر و اقدامات مستقیم مرتبط با دفتر را نیز فراهم می‌کند، که همگی به طور منظم تحت گزینه "بیشتر (...)" برای راحتی شما قرار داده شده‌اند.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>نمای نمودار سازمانی:</Body1Strong> ساختار سلسله مراتبی دفاتر اصلی و فرعی را برای درک ارتباط دفاتر مختلف درون سازمان خود تجسم کنید.
                    </li>
                  </ul>
                  با استفاده از آیکون‌های کنار جعبه جستجو، بین این نماها تغییر دهید تا تجربه مرور خود را متناسب با نیازهای فوری خود سفارشی کنید.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. یافتن سریع دفاتر با قابلیت جستجوی TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  یافتن مکان دفتر مناسب درون TeamConnect با استفاده از ویژگی جستجوی اختصاصی آسان شده است. جعبه جستجو، که به طور برجسته کنار آیکون‌های نما قرار دارد، ابزار شما برای شناسایی دفاتر به نام است. همانطور که تایپ می‌کنید، دایرکتوری نمایش را تصفیه می‌کند تا تنها دفاتری که با ورودی شما مطابقت دارند نشان داده شود، که فرآیند جستجوی شما را بهینه می‌سازد. استفاده از این جعبه جستجو درون TeamConnect برای دستیابی به نتایج دقیق و مرتبط اهمیت دارد، برخلاف جستجوی عمومی Microsoft Teams. با این عملکرد جستجوی هدفمند، می‌توانید به سرعت به هر دفتری در شبکه سازمانی خود دسترسی پیدا کنید.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. پیمایش دفترچه راهنمای دفتر به ترتیب حروف الفبا
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  اگر ترجیح می‌دهید به صورت حروف الفبا جستجو کنید، فیلتر حروف الفبایی ما روشی سریع برای یافتن دفاتر بر اساس نام است. در بالای دفترچه راهنمای دفتر، حروف A تا Z را خواهید دید. کلیک کردن روی یک حرف فیلتر نمایش را تغییر می‌دهد تا فقط دفاتری که با آن حرف شروع می‌شوند نشان داده شوند، که فرآیند جستجوی شما را ساده‌تر می‌کند. برای بازنشانی و دیدن دوباره تمام دفاتر، فقط کافی است حرف فعال را دوباره انتخاب کنید. این ویژگی به‌ویژه زمانی مفید است که با نام دفتر آشنا هستید اما به روشی سریع برای یافتن آن درون TeamConnect نیاز دارید.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. رونمایی از پروفایل‌های جامع دفتر در TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  در مرکز حضور فیزیکی سازمان خود با پروفایل‌های دفتر TeamConnect ماجراجویی کاوشی آغاز کنید. کلیک کردن روی نام دفتر، پنجره‌ای پر از رنگ با پروفایلی جذاب را باز می‌کند که شامل زبانه‌هایی پر از اطلاعات دقیق در مورد دفتر است:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>معرفی:</Body1Strong> با شرح‌های دقیق وارد هویت اصلی دفتر شوید، از ساختار کسب و کار گرفته تا ساعات کار و زمان محلی، که ماهیت محیط دفتر را ضبط می‌کند.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>تماس‌های ضروری:</Body1Strong> به تماس‌های حیاتی برای خدمات اضطراری و پشتیبانی خارجی دسترسی پیدا کنید، هرکدام تنها با یک کلیک از طریق سیستم تلفن Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>تماس‌های دفتر:</Body1Strong> با آسانی با خدمات پشتیبانی داخلی تماس بگیرید، با جزئیات تماس و گزینه‌های مشارکت مستقیم در اختیار شما.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>تعطیلات عمومی:</Body1Strong> از تعطیلات خاص دفتر آگاهی پیدا کنید، تا برنامه‌های شما با روزهای تعطیل محلی و برنامه‌های کاری هم‌راستا شوند.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ساعات کار دفتر:</Body1Strong> با دانستن دقیق اینکه چه زمانی دفتر با فعالیت شلوغ است یا برای روز تعطیل می‌شود، معاملات خود را برنامه‌ریزی کنید.
                    </li>
                  </ul>
                  چه در انرژی پرشور آستین باشید یا در مرکز استراتژیک سنگاپور، هر زبانه دروازه‌ای را برای درک و ارتباط با مکان‌های متنوعی که حضور جهانی شرکت شما را تشکیل می‌دهند، فراهم می‌کند.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۳۶. بررسی دینامیک‌های بخش‌ها در یک دفتر
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ناوبری در شبکه حرفه‌ای بخش‌های یک دفتر با TeamConnect به راحتی انجام می‌شود. در اینجا چگونگی ورود به جزئیات آمده است:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>مشاهده بخش‌ها در این دفتر:</Body1Strong> با انتخاب این گزینه از منوی بیشتر (...)، 
                      تمام بخش‌هایی که قسمتی از دفتر را تشکیل می‌دهند را کشف کنید.
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>بینش سازمانی:</Body1Strong> با کلیک بر روی هر بخش، نمودار سازمانی آن را برای 
                      نشان دادن ساختار بصری آن با استفاده از مسیریابی breadcrumb نشان دهید: دفتر {'>'} بخش {'>'} نمودار.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>عمق بخشی بخش:</Body1Strong> با کلیک بر روی منوی بیشتر (...) در کنار نام بخش، 
                      به طور بیشتری یک بخش را کاوش کنید. در اینجا، می‌توانید لیستی از تمام کارمندان موجود در آن بخش را، در زمینه دفتر، دسترسی پیدا کنید.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>مسیریابی Breadcrumb:</Body1Strong> به راحتی از سطوح عبور کنید. از 
                      کارمندان به بخش‌ها بازگردید یا از نمودار سازمانی، مسیر breadcrumb (دفتر {'>'} بخش {'>'} کارمند {'>'} نمودار) سفر شما را
                      آسان و قابل فهم نگه می‌دارد.
                    </li>
                  </ul>
                  مسیرهای پیچیده TeamConnect شما را به کاوش، درک و مشارکت در هر لایه از بخش‌های دفترتان دعوت می‌کند و به شما قدرت فرمان ناوبری جامع می‌بخشد.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۳۷. ارتباط با پرسنل دفتر در TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  کنجکاو هستید تا چهره‌های پشت یک دفتر را بشناسید؟ پاسخ تنها یک کلیک فاصله دارد در TeamConnect. با فعال‌سازی بر روی نقاطی (سه نقطه) در کنار نام دفتر موردنظر خود، مجموعه‌ای از گزینه‌های تعاملی را آشکار کنید:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>مشاهده کارمندان در این دفتر:</Body1Strong> لیست تمام حرفه‌ای‌هایی را که این دفتر را خانه کاری خود می‌خوانند، کشف کنید. تعداد نمایش داده شده، تعداد کارمندان را به سرعت نشان می‌دهد و شما را به کاوش در جامعه دفتر دعوت می‌کند.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>گزینه‌های تماس:</Body1Strong> هر ورودی کارمند با آیکون‌های عمل سریع مجهز شده است،
                      که به شما امکان می‌دهد چت‌ها، تماس‌ها یا حتی کنفرانس‌های ویدئویی را مستقیماً از طریق TeamConnect آغاز کنید و ارتباطات درون‌دفتری را بدون درز ترویج دهید.
                    </li>
                    <li><Body1Strong>ناوبری:</Body1Strong> برای بازگشت به لیست دفاتر گسترده‌تر از لیست کارمندان، کافی است بر روی 'دفتر' در مسیر breadcrumb 
                      کلیک کنید (دفتر {'>'} کارمند)، و شما به مرور دفتر باز خواهید گشت.
                    </li>
                  </ul>
                  با این ویژگی‌ها، TeamConnect تصویرسازی توزیع نیروی کار در دفاتر و دستیابی به راحتی را ساده می‌کند،
                  که محیط کاری مرتبطی را فراهم می‌آورد، صرف‌نظر از فاصله.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  آیا اطلاعات دفتر شما گم شده یا قدیمی است؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  به‌روزرسانی‌های اطلاعات دفتر می‌تواند توسط ویراستاران مشخصی از دفتر در TeamConnect مدیریت شود. اگر در مورد ویراستاران دفتر مطمئن نیستید یا به کمک در مورد اطلاعاتی که تحت نظارت منابع انسانی است نیاز دارید، تیم پشتیبانی منابع انسانی آماده کمک است در <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>پرسش‌های متداول تنظیمات</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  اهمیت تنظیمات عمومی دقیق در TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  در چشم‌انداز پویای محیط کار امروز، بودن در ارتباط و هماهنگ بودن بیش از پیش حیاتی است. اینجاست که به‌روزرسانی تنظیمات عمومی شما در TeamConnect نقش محوری ایفا می‌کند:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>انتخاب محیط کار:</Body1Strong> جوهره تنظیمات کاری خود را با به‌روزرسانی محل کار خود برای انعکاس دادن وضعیت کنونی‌تان مشخص کنید، چه اینکه از آرامش دفتر خانه به هیاهوی دفتر اصلی منتقل شده‌اید، یا حتی در حال استقرار در یک مکان عمومی هستید. این وضوح در محل کار شما به همکارانتان نه تنها در بهترین روش‌های تماس با شما بلکه در ماهیت گفتگوهایی که می‌توانند با شما داشته باشند، بینش می‌دهد، به ویژه هنگامی که در محیط باز یک دفتر مشتری یا نشسته در سالن فرودگاه هستید، حفاظت از محرمانه بودن بحث‌های حساس.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>مشخصات مکان کار:</Body1Strong> به اشتراک‌گذاری شهر کاری شما فقط فاصله‌ها در یک تیم از راه دور را پل نمی‌زند—بلکه درها را به سوی دیدارهای حرفه‌ای اتفاقی یا معاشرت‌های غیررسمی باز می‌کند. چه یک قهوه سریع، یک ناهار آرام، یا یک جلسه همکاری باشد، دانستن اینکه در همان شهر هستید می‌تواند یک روز عادی را به فرصتی برای ارتباطات معنادار رو در رو تبدیل کند. این نه تنها پیوندهای تیمی را تقویت می‌کند بلکه درک عمیق‌تری از نکات ریز محلی که ممکن است بر کار شما تأثیر بگذارد را فراهم می‌کند.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>دقت منطقه زمانی:</Body1Strong> با تیم‌هایی که قاره‌ها را دربر می‌گیرند، اطمینان حاصل کردن از اینکه منطقه زمانی شما مکان کنونی‌تان را منعکس می‌کند، برای هماهنگی ارتباطات ضروری است. زمان محلی نمایه TeamConnect شما مستقیماً تحت تأثیر این تنظیم قرار می‌گیرد، حدس‌زدن را برای همکارانی که سعی در تماس با شما دارند از بین می‌برد.
                    </li>
                  </ul>
                  به بخش 'تنظیمات' {'>'} 'تنظیمات عمومی' در TeamConnect بروید تا این جزئیات را دقیق‌تر تنظیم کنید. چند لحظه صرف به‌روزرسانی تنظیمات شما می‌تواند هماهنگی و کارایی تلاش‌های مشترک شما را در سراسر جهان به طور قابل توجهی افزایش دهد.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. رمزگشایی انتخاب محل کار در TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  انتخاب تنظیمات صحیح در منوی کشویی محل کار، TeamConnect را برای منعکس کردن دقیق وضعیت کاری فعلی شما سفارشی می‌کند. برای دریافت توضیحات دقیق در مورد هر گزینه، روی آیکون اطلاعات حرکت کنید:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Break:</Body1Strong> نشانگر زمانی دور از کار است، چه برای استراحت یا پایان روز کاری.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office/Field-Office:</Body1Strong> حضور در محیط کاری مدیریت شده توسط شرکت را نشان می‌دهد.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> کار از مکان کاری اختصاصی در خانه.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Public-Area:</Body1Strong> فعالیت از محیط غیر اداری، که نیاز به ارتباط انعطاف‌پذیر را نشان می‌دهد.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Customer Workplace/Private Office:</Body1Strong> در محل کار مشتری، با توجه به سطوح حریم خصوصی برای حساسیت مکالمات.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Commute/Business-Travel/Training:</Body1Strong> در حرکت یا مشغول به فعالیت‌های کاری خاص خارج از دفتر.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Public-Holiday/Vacation/Various Leaves:</Body1Strong> رسماً برای دلایل شخصی، سلامتی یا تعطیلات غیر حاضر.</li>
                  </ul>
                  انتخاب تنظیم محل کار مناسب اطمینان می‌دهد که تیم شما زمینه‌های لازم برای تعاملات را داشته باشد، ضمن احترام به دسترس‌پذیری و حریم شخصی.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. هدایت وضعیت 'Checked-In' در TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  برچسب 'Checked-In' در دایرکتوری کارمندان طراحی شده است تا تمام کارمندانی که در حال حاضر در کار هستند نمایش داده شوند. این قابلیت دید بر اساس انتخاب محل کار شما تعیین می‌شود:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Active Work Environments:</Body1Strong> انتخاب‌های Office, Field-Office, Home-Office, Remote-Workplace, Public-Area, Customer-Workplace, 
                    Customer Private-Office, Commute, Business-Travel, and Training شما را به عنوان فعال در کار نشان می‌دهند، به این ترتیب که شما 'Checked-In' هستید و فعالیت شما برای همکاران قابل مشاهده است.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Non-Work Settings:</Body1Strong> انتخاب‌های Checked-Out, Break, Public-Holiday, Vacation, On-Leave, Maternity-Leave, or Sick-Leave 
                      شما را از لیست 'Checked-In' خارج می‌کند و انتظارات واضحی برای دسترس‌پذیری و زمان پاسخ‌دهی ایجاد می‌کند.
                  </li>
                  </ul>
                  این قابلیت دوره‌های کاری را از زمان شخصی تفکیک می‌کند و به همکاری و ارتباط مؤثر تیمی کمک می‌کند.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>سوالات متداول مدیریت داده‌ها و حریم خصوصی</Subtitle2>
          <Body1>
            <p>
              در عصر دیجیتال امروز، درک نحوه مدیریت داده‌های شما امری حیاتی است. TeamConnect به شفافیت و رویه‌های حفاظت از داده‌های قدرتمند متعهد است.
              در زیر، اطلاعاتی درباره نحوه رسیدگی، ذخیره‌سازی و حفاظت از داده‌های شما در TeamConnect را خواهید یافت، كه اطمینان می‌دهد فضای کاری دیجیتال شما امن و مطابق با مقررات است.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. چگونه TeamConnect از داده‌های شخصی من محافظت می‌کند؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect با رویکرد جامع به امنیت داده‌ها، به حفاظت از اطلاعات شخصی شما اختصاص یافته است:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>ورود به سیستم تک‌نشانی (SSO):</Body1Strong> برای تقویت امنیت و بهبود تجربه کاربری، TeamConnect به صورت خودکار 
                      تک‌نشانی (SSO) را با حساب Microsoft 365 شما ادغام می‌کند و دسترسی امن و بی‌دردسر را تسهیل می‌بخشد.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>رمزنگاری:</Body1Strong> ما اطمینان حاصل می‌کنیم که تمام داده‌ها در TeamConnect هم در هنگام انتقال و هم در حالت بیکار، 
                      رمزگذاری شده و برای اشخاص غیرمجاز قابل دسترسی نیستند.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>کمینه‌سازی داده‌ها:</Body1Strong> جمع‌آوری داده‌های ما به طور سخت به آنچه برای عملکرد TeamConnect ضروری است محدود می‌شود.
                      تنها اطلاعات کاربری که ما ذخیره می‌کنیم نام اصلی کاربر (UPN) از Azure Entra ID است که ممکن است شبیه آدرس ایمیل شرکتی شما باشد.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>دسترسی محدود:</Body1Strong> دسترسی به UPN به مدیر اشتراک سازمان شما در پورتال اشتراک ما محدود شده است، 
                      اطمینان حاصل می‌کند که اطلاعات حساس تنها برای افراد مجاز قابل مشاهده است.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>حذف سریع داده‌ها:</Body1Strong> TeamConnect به گونه‌ای ساخته شده است که UPN ذخیره شده را فوراً در سه شرایط خاص پاک کند:
                      اگر اشتراک توسط مدیر اشتراک لغو شود، اگر کاربر توسط HR در TeamConnect حذف شود، یا اگر اشتراک TeamConnect منقضی شود. این امر اطمینان می‌دهد که داده‌های شخصی شما فراتر از زمان مفید یا ضرورت خود نگهداری نشود.
                    </li>                      
                  </ul>
                  <p>از طریق این اقدامات، TeamConnect به حفظ بالاترین استانداردهای حفاظت از داده‌ها متعهد است، اطمینان حاصل می‌کند که اطلاعات شخصی شما امن و محرمانه باقی بماند.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۴۳. تعجب می‌کنید داده‌های شما کجا در TeamConnect نگهداری می‌شود؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect قالب‌های متداول SaaS را شکسته و با اطمینان از این که داده‌های شما هرگز از سازمان شما خارج نمی‌شود، متمایز می‌شود.
                  بر خلاف روش‌های معمول ذخیره‌سازی داده‌ها در سرورهای ابری خارجی، TeamConnect به طور ماهرانه از Microsoft 365 SharePoint Online سازمان شما 
                  برای میزبانی تمام داده‌های برنامه بهره می‌برد. این رویکرد نوآورانه نه تنها حاکمیت داده‌ها را تقویت می‌کند بلکه امنیت را نیز تقویت می‌کند،
                  در تطبیق کامل با تعهد محکم ما به حفاظت از حریم خصوصی شما و پایبندی به استانداردهای سخت‌گیرانه پایبندی.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۴۴. آیا داده‌های من با هیچ طرف ثالثی به اشتراک گذاشته می‌شود؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  نه، داده‌ها با اشخاص ثالث به اشتراک گذاشته نمی‌شوند! Cubeet در تعهد ما به حریم خصوصی و امنیت داده‌های شما ثابت است. ما هیچ داده‌ای از TeamConnect را بدون کسب رضایت صریح از مشتریانمان با اشخاص ثالث به اشتراک نمی‌گذاریم.
                  در محیط امن ما، تنها شناسه‌های ضروری مانند نام اصلی کاربر (UPN)، نام سازمان، نام مستاجر و شناسه مستاجر در پورتال اشتراک ما ذخیره می‌شود.
                  این اطلاعات برای ارائه خدمات حیاتی است و تحت سیاست‌های حریم خصوصی سخت‌گیرانه ما حفاظت می‌شود.
                  با اطمینان باشید، شیوه‌های مدیریت داده‌های ما طراحی شده‌اند تا اطمینان حاصل کنند که داده‌های شما محرمانه باقی می‌ماند، با شفافیت و کنترلی که به‌طور قاطع در دست شما باقی می‌ماند.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ۴۵. TeamConnect چگونه با مقررات حفاظت از داده‌ها مانند GDPR مطابقت دارد؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect با استفاده از ویژگی‌های پیش‌فرض مطابقت در محیط Microsoft 365، که تمام داده‌های مشتری به صورت امن ذخیره می‌شود، با مقررات حفاظت از داده‌ها مانند GDPR، HIPAA، CCPA و دیگران مطابقت دارد. 
                  بر خلاف مدل‌های SaaS سنتی، TeamConnect هیچ داده‌ای از مشتریان را روی سرورهای خود ذخیره نمی‌کند؛ به جای آن، در محیط Microsoft 365 سازمان شما فعالیت می‌کند، مطابق با استانداردهای امنیتی و پایبندی قوی آن. 
                  این رویکرد اطمینان می‌دهد که حاکمیت داده‌های شما با چارچوب پایبندی جامعی که توسط Microsoft 365 ارائه شده، از جمله پایبندی به GDPR، HIPAA برای اطلاعات بهداشتی، CCPA برای ساکنان کالیفرنیا، 
                  و سایر مقررات حفاظت از داده‌ها در سراسر جهان، مطابقت دارد. با ادغام بی‌درز با تنظیمات موجود Microsoft 365 شما، TeamConnect قدرتمندی سازمان شما را برای حفظ کنترل کامل بر داده‌های خود افزایش می‌دهد،
                  تضمین می‌کند که با سخت‌ترین قوانین و مقررات حفاظت از داده‌ها مطابقت دارد.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. تیم‌کانکت چگونه اطلاعات مربوط به چت‌ها، تماس‌ها، تماس‌های ویدیویی، ایمیل‌های Outlook و تماس‌های تلفنی در مایکروسافت تیمز را مدیریت می‌کند؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  تیم‌کانکت از API مایکروسافت گراف برای فراهم کردن تعامل‌های مستقیم مانند چت، تماس، تماس‌های ویدیویی و ایمیل Outlook در مایکروسافت تیمز استفاده می‌کند،
                  که تجربه‌ی کاربری بدون درزی را ارائه می‌دهد. بسیار مهم است که در حالی که تیم‌کانکت این ارتباطات را آغاز می‌کند، بدون دخالت در فرآیند ارتباطی
                  اقدام می‌کند و توانایی ضبط یا ذخیره‌سازی هیچ یک از محتوای تعاملات را ندارد. تمامی گفتگوها، تماس‌ها و ایمیل‌ها مستقیماً در محیط‌های امن مایکروسافت تیمز و Outlook انجام شده و مدیریت می‌شوند، با رعایت دقیق استانداردهای امنیتی و حفظ حریم خصوصی مایکروسافت 365. این اطمینان حاصل می‌شود که تیم‌کانکت قابلیت‌های ارتباطی شما را افزایش داده در حالی که کاملاً محرمانگی و یکپارچگی داده‌های شما را حفظ می‌کند.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            برای آگاهی بیشتر از سیاست‌ها و رویه‌های ما در زمینه داده‌ها، شما همچنین ممکن است بخواهید {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>راهنمای کاربر تیم‌کانکت</Body1Strong></Link> را مشورت کنید یا با بخش فناوری اطلاعات سازمان خود تماس بگیرید <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> برای جزئیات مرتبط با تنظیمات شما.
          </p>
        </Body1>        
        <p></p>  
        <Subtitle1 className={styles.fontColor}>ما گوش می‌دهیم: نظرات و ایده‌های خود را به اشتراک بگذارید</Subtitle1>
        <Body1>
          <p>
            بینش‌های شما تکامل تیم‌کانکت را راهنمایی می‌کند. خواه با یک ایده ناب برای ویژگی جدید یا با بازخوردی درباره تجربه فعلی خود مملو از حباب هستید، ما همه گوش‌هایمان باز است. ورودی شما در کوشش مداوم ما برای ارائه بهترین خدمات ممکن، بی‌بدیل است.
          </p>
        </Body1>      
        <Subtitle2>بازخوردها و پیشنهادات ویژگی‌</Subtitle2>
        <Body1>
          <p>
            آماده به اشتراک‌گذاری هستید؟ به صفحه‌ی {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              صفحه بازخورد
            </Link>
            ما بروید. خواه پیشنهادی برای ویژگی جدید داشته باشید یا بازخوردی درباره عملکردهای موجود، فقط گزینه مناسب را از منوی کشویی در فرم انتخاب کنید. مشارکت‌های شما به شکل‌گیری آینده تیم‌کانکت کمک می‌کند، تا اطمینان حاصل شود که نیازهای شما را برآورده و فراتر از آن‌ رفع می‌کند.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;