import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>TeamConnect सहायता केंद्र और पूछे जाने वाले प्रश्न</Title3>
        <p><Body1>
          TeamConnect सहायता केंद्र में आपका स्वागत है, जिसे हमारे व्यापक कॉर्पोरेट डायरेक्टरी ऐप के साथ आपके अनुभव का समर्थन करने के लिए डिज़ाइन किया गया है। यहाँ, आपको Microsoft Teams के भीतर TeamConnect का उपयोग अनुकूलित करने के लिए संसाधन मिलेंगे, जिससे आपके नेटवर्क में संचार बढ़ाया जा सके।
        </Body1></p>
        <Subtitle2>त्वरित संदर्भ मार्गदर्शिका</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>विस्तारित खोज क्षमताएं: </Body1Strong>
              हमारे शक्तिशाली खोज उपकरणों के साथ तेजी से सही सहकर्मियों, विभागों और आवश्यक संपर्कों का पता लगाएं।
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>दृश्यात्मक संगठन चार्ट: </Body1Strong>
              सहज ज्ञान युक्त संगठन चार्ट के माध्यम से अपने संगठन की रचना को नेविगेट करें और रिपोर्टिंग लाइनों को समझें।
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>आज की जरूरतों के लिए अनुकूलित उपकरण: </Body1Strong>
              आधुनिक कार्यस्थलों की मांगों के लिए अनुकूलित आधुनिक कार्यक्षमताओं का उपयोग करें।
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>अपने अनुभव को अनुकूलित करें</Subtitle2>
        <Body1>
          <p>
            TeamConnect आपके संगठनात्मक ढांचे के भीतर आपकी बातचीत को क्रांतिकारी बना देता है, हर रोज़ की संचार को रणनीतिक जुड़ाव में बदल देता है।
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>सहायता प्राप्त करें और पूछे जाने वाले प्रश्न</Subtitle1>
        <Body1>
          <p>
            इस समर्पित खंड में TeamConnect की विशेषताओं और सेटिंग्स के बारे में आम प्रश्नों के उत्तर पाएं। चाहे आप ऐप को नेविगेट कर रहे हों, सेटिंग्स को अनुकूलित कर रहे हों, या चुनौतियों के समाधान खोज रहे हों, हमारा सहायता केंद्र आपकी कुशलता से सहायता करने के लिए यहां है।
          </p>
        </Body1>
        <Body1>
          <p>
            अधिक गहराई से सहायता या विशिष्ट मार्गदर्शन के लिए, कृपया <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnect उपयोगकर्ता मार्गदर्शिका</Body1Strong></Link> का परामर्श लें।
          </p>
        </Body1>
        <p></p>        
        <Subtitle2>टीमकनेक्ट FAQs का उपयोग करना</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. माइक्रोसॉफ्ट टीम्स साइडबार में टीमकनेक्ट नहीं मिल रहा?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  यदि टीमकनेक्ट आपके माइक्रोसॉफ्ट टीम्स साइडबार में सहजता से दिखाई नहीं दे रहा है या गायब हो गया है, तो आप इसे आसानी से पिन कर सकते हैं ताकि जल्दी से एक्सेस कर सकें। बस इन सरल चरणों का पालन करें:
                  <ol>
                    <li className={styles.bulletBottomMargin}>माइक्रोसॉफ्ट टीम्स खोलें और साइडबार के नीचे '...' (+ ऐप्स) आइकॉन पर क्लिक करें।</li>
                    <li className={styles.bulletBottomMargin}>पॉप-अप विंडो के ऊपर के सर्च बार में 'टीमकनेक्ट' के लिए खोज करें।</li>
                    <li className={styles.bulletBottomMargin}>सर्च परिणामों से टीमकनेक्ट ऐप पर राइट-क्लिक करें और 'पिन' चुनें।</li>
                  </ol>
                  टीमकनेक्ट अब आपके साइडबार में पिन हो जाएगा, जिससे यह केवल एक क्लिक से सुलभ हो जाएगा!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. टीमकनेक्ट के साथ तकनीकी समस्याएं आ रही हैं? यहाँ मदद कैसे प्राप्त करें।
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  यदि आप माइक्रोसॉफ्ट टीम्स के भीतर टीमकनेक्ट के साथ किसी भी तकनीकी समस्या का सामना कर रहे हैं, तो आपकी संगठन की IT सहायता टीम आपकी मदद के लिए यहाँ है।
                  उन्हें <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> पर संपर्क करें, और वे आपकी शीघ्र मदद करेंगे।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. अपनी कर्मचारी विवरण नहीं देख पा रहे हैं?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  यदि आपकी प्रोफ़ाइल आपका नाम और ईमेल दिखाने के साथ थोड़ी खाली लग रही है, तो संभवतः आपकी HR टीम ने अभी तक टीमकनेक्ट में आपके शेष कर्मचारी विवरण नहीं जोड़े हैं। 
                  चिंता न करें! आपकी HR सहायता टीम को एक त्वरित संदेश सब कुछ शुरू कर देगा। वे आपकी प्रोफ़ाइल में सभी महत्वपूर्ण विवरण भरने के लिए तैयार हैं। 
                  <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> पर संपर्क करें और देखें कि आपकी प्रोফাইল कैसे जीवंत हो उठती है!
                </Body1>
              </AccordionPanel>
            </AccordionItem>  
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. TeamConnect में कौन से भाषा विकल्प उपलब्ध हैं?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect Microsoft Teams की वैश्विक विविधता को स्वीकार करता है और उसकी सभी भाषाओं का समर्थन करता है। 
                  चाहे आप अरबी पसंद करें या ग्रीक, जापानी या स्वीडिश, आपके पास आपकी उंगलियों पर विकल्पों की दुनिया है, जिसमें शामिल हैं:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      अरबी, बल्गेरियाई, चीनी (सरलीकृत और पारंपरिक), क्रोएशियाई, चेक, डैनिश, डच, अंग्रेज़ी, एस्टोनियाई, फिनिश, 
                      फ्रेंच, जर्मन, ग्रीक, हिब्रू, हंगेरियन, इंडोनेशियाई, इतालवी, जापानी, कोरियाई, लात्वीय, लिथुआनियाई, नॉर्वेजियन (बोकमाल), 
                      पोलिश, पुर्तगाली (ब्राज़ील और पुर्तगाल), रोमानियाई, रूसी, सर्बियाई (लैटिन), स्लोवाक, स्लोवेनियाई, स्पैनिश, स्वीडिश, थाई, 
                      तुर्की, यूक्रेनी, वियतनामी।
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  अपने TeamConnect अनुभव को अपनी पसंदीदा भाषा में अनुकूलित करने के लिए, कृपया Microsoft Teams में सीधे भाषा सेटिंग्स को समायोजित करें,
                  क्योंकि TeamConnect इन सेटिंग्स के साथ पूरी तरह से एकीकृत है:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      अपनी प्रोफाइल चित्र के ऊपर तीन बिंदुओं पर क्लिक करके अपने Microsoft Teams सेटिंग्स पर जाएं, और फिर 'Appearance and accessibility' का चयन करें।
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      'Language' अनुभाग पर स्क्रॉल करें और ड्रॉपडाउन मेन्यू से अपनी पसंदीदा भाषा चुनें।
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      'Save and restart' बटन पर क्लिक करके अपने परिवर्तनों की पुष्टि करें और नई भाषा सेटिंग्स लागू करें।
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>नोट: </Body1Strong>
                <Body1>
                  आपकी भाषा सेटिंग्स में Microsoft Teams में किए गए परिवर्तन भी आपके TeamConnect अनुभव को बिना किसी बाधा के अपडेट कर देंगे। 
                  ध्यान दें कि Teams में भाषा बदलने से आपका समय और तारीख प्रारूप भी बदल सकता है। इस समय, 
                  TeamConnect दाएं से बाएं लिखने के लिए विकसित हो रहा है। हम इन सुधारों के बारे में उत्साहित हैं 
                  और जैसे ही वे उपलब्ध होंगे हम आपको सूचित करेंगे। 
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. मैं TeamConnect में थीम कैसे बदल सकता हूँ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect आपकी Microsoft Teams थीम प्राथमिकताओं के साथ सिंक करता है, जिसमें लाइट, डार्क और हाई कॉन्ट्रास्ट विकल्प शामिल हैं। अपनी थीम को कस्टमाइज़ करने के लिए:
                  <ol>
                    <li className={styles.bulletBottomMargin}>अपनी प्रोफ़ाइल तस्वीर के ऊपर दिखाई देने वाले तीन बिंदुओं पर क्लिक करके अपनी Microsoft Teams सेटिंग्स तक पहुँचें।</li>
                    <li className={styles.bulletBottomMargin}>'दिखावट और सुलभता' का चयन करें ताकि थीम विकल्पों का पता लगाया जा सके।</li>
                    <li className={styles.bulletBottomMargin}>उपलब्ध विकल्पों में से अपनी पसंदीदा थीम चुनें ताकि तुरंत आपका TeamConnect इंटरफ़ेस मैच हो जाए।</li>
                  </ol>
                  Microsoft Teams में आपकी थीम पसंद को TeamConnect में स्वचालित रूप से लागू किया जाएगा, जिससे आपके कार्यस्थल में एक सुसंगत दृश्य अनुभव सुनिश्चित होता है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. TeamConnect के कॉन्टैक्ट एक्शन बटन्स की खोज
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect में, जहाँ कहीं भी आप किसी कर्मचारी के संपर्क विवरण पाते हैं, वहाँ आप हमारे सुविधाजनक क्विक-एक्सेस बटन्स की खोज भी करेंगे। ये हैंडी टूल्स
                  आपके संचार को Microsoft ईकोसिस्टम के भीतर सुव्यवस्थित करने के लिए डिज़ाइन किए गए हैं, जिससे आप हमेशा कनेक्ट करने से बस एक क्लिक दूर रहते हैं।
                  यहाँ इन बटन्स का प्रभावी ढंग से उपयोग करने की व्यापक मार्गदर्शिका है:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>चैट आइकन:</Body1Strong> एक त्वरित Microsoft Teams चैट शुरू करने के लिए क्लिक करें। यदि आपको चैट शुरू करने के बाद TeamConnect पर वापस जाना हो, तो बस डेस्कटॉप ऐप में वापस तीर का, मोबाइल ऐप में वापस बटन का या अपने ब्राउज़र के वापस बटन का उपयोग करें।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ईमेल आइकन:</Body1Strong> प्राप्तकर्ता के पते को "To:" फ़ील्ड में तैयार कर के एक नया Outlook ईमेल ड्राफ्ट खोलता है। यदि Outlook लॉन्च नहीं होता, तो यह जांचने के लायक है कि आपके IT विभाग में कोई प्रतिबंध हैं या नहीं।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>वीडियो कॉल आइकन:</Body1Strong> Microsoft Teams का उपयोग करके एक फेस-टू-फेस वीडियो कॉल शुरू करता है।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>कॉल आइकन:</Body1Strong> एक Microsoft Teams वॉयस कॉल शुरू करता है, संक्षिप्त चर्चाओं को सहज बनाता है।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ऑर्ग चार्ट आइकन:</Body1Strong> कर्मचारी की संगठनात्मक संरचना में उनकी जगह प्रकट करता है, जिसमें उनके प्रबंधक और प्रत्यक्ष रिपोर्ट्स शामिल हैं।</li>
                    <li><Body1Strong>अधिक (...) आइकन:</Body1Strong> अधिक विकल्पों का प्रकटन करता है:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>अभी मिलना:</Body1Strong> कर्मचारी के साथ तत्काल बैठक शुरू करने के लिए Microsoft Teams खोलता है।</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>नई मीटिंग:</Body1Strong> नई मीटिंग की योजना बनाने के लिए आपको Microsoft Teams की ओर ले जाता है।</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>संपर्क जोड़ें:</Body1Strong> TeamConnect से कर्मचारी के संपर्क विवरण को आपके नए Microsoft Teams People ऐप में जोड़ता है, आपके कॉल फ़ंक्शन को समृद्ध करता है।</li>
                      </ul>
                    </li>
                  </ul>
                  ये सहज बटन्स इस प्रकार बनाए गए हैं कि आप आसानी से सहकर्मियों के साथ जुड़ सकें, चाहे वह त्वरित चैट के लिए हो, ईमेल के लिए, या भविष्य की बैठकों की योजना के लिए।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. TeamConnect के माध्यम से कॉल करना
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  कॉल करना चाहते हैं? TeamConnect आपके संचार को सुव्यवस्थित करता है जो Microsoft Teams के साथ एकीकृत है।
                  बस TeamConnect में वांछित फ़ोन नंबर पर क्लिक करें, और वोला, Microsoft Teams कॉलिंग इंटरफ़ेस उभरता है,
                  आपको निर्बाध रूप से डायल करने के लिए तैयार।
                  <p>
                  <Body1Strong>नोट: </Body1Strong> 
                  कॉल करने में समस्याएँ आ रही हैं? यह आपके संगठन की IT नीतियों या Microsoft Teams फ़ोन सिस्टम के अलावा किसी अन्य फ़ोन सिस्टम के उपयोग के कारण हो सकता है। इस मामले पर प्रकाश डालने के लिए <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> पर अपने IT विभाग से जांच करने में मदद मिल सकती है।
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. क्या TeamConnect के माध्यम से कॉल करने पर लागतें आती हैं?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect माइक्रोसॉफ्ट टीम्स का उपयोग करके आपके कॉलिंग अनुभव को बढ़ाता है। यहाँ कॉल से संबंधित लागतों के बारे में जानने योग्य बातें हैं:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      आपके संगठन के भीतर कर्मचारी के काम के फोन, विभाग के फोन या हॉटलाइन (आंतरिक नंबर) के लिए कॉल करते समय,
                      माइक्रोसॉफ्ट टीम्स फोन सिस्टम का उपयोग करते समय आमतौर पर <Body1Strong>कोई लागत नहीं</Body1Strong> होती है।
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      बाहरी कॉलों के लिए, जैसे कि मोबाइल फोन या बाहरी लाइनों पर, <Body1Strong>मानक दरें</Body1Strong> लागू होती हैं जो आपके संगठन की फोन प्रणाली की होती हैं। 
                      ये दरें आपके माइक्रोसॉफ्ट टीम्स के साथ विशिष्ट सेवा योजना के आधार पर भिन्न होती हैं।
                    </li>
                  </ul>
                  <p><Body1Strong>ध्यान दें:</Body1Strong> यदि आपको कोई समस्या आती है या कॉल शुल्क के बारे में प्रश्न हैं, तो अपने 
                    IT विभाग से <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> पर संपर्क करना सबसे अच्छा है ताकि आपके संगठन की स्थापना और नीतियों के अनुरूप विस्तृत जानकारी प्राप्त हो सके।</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. क्या आपने TeamConnect में अपनी 'ऑफिस से बाहर' उपस्थिति स्थिति में विसंगति देखी है?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  क्या आपने देखा है कि Microsoft 365 से आपकी 'ऑफिस से बाहर' की उपस्थिति स्थिति TeamConnect में सही ढंग से प्रतिबिंबित नहीं हो रही है?
                  हमने आपके लिए इस सुविधा को अभी TeamConnect में लागू किया है। 
                  हम माइक्रोसॉफ्ट की इस सुविधा के रोलआउट का इंतजार कर रहे हैं। जैसे ही यह होता है, आपकी 'ऑफिस से बाहर' की उपस्थिति स्थिति 
                  TeamConnect में सही ढंग से प्रतिबिंबित होगी, जिससे माइक्रोसॉफ़्ट टीम्स के उपस्थिति संकेतकों के साथ एक सहज अनुभव होगा।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>कर्मचारी निर्देशिका FAQs</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >कर्मचारी निर्देशिका परिचय:</Body1Strong> अपने संगठन के पेशेवर परिदृश्य का पता लगाएं कर्मचारी निर्देशिका के साथ, 
                जो आपके सहकर्मियों की प्रोफाइल और प्रबंधकीय संबंधों से सीधे जोड़ने के लिए आपकी केंद्रीय हब है विस्तृत कर्मचारी अंतर्दृष्टि और कार्यस्थल गतिशीलताओं के लिए।
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. क्या आपको अपने कर्मचारी डेटा में जोड़, अपडेट या सुधार करने की आवश्यकता है?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect में अपने कर्मचारी डेटा को जोड़ने, अपडेट करने या सुधारने के लिए, कृपया अपने संगठन की HR सपोर्ट टीम से संपर्क करें। 
                  वे <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> पर सहायता के लिए तैयार हैं।
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. जब मैं निर्देशिका में किसी कर्मचारी के नाम पर क्लिक करता हूं, तो क्या होता है?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  टीमकनेक्ट कर्मचारी निर्देशिका में किसी कर्मचारी के नाम पर क्लिक करने से उस व्यक्ति के लिए एक विस्तृत प्रोफ़ाइल पेज खुलता है। यह प्रोफ़ाइल कर्मचारी के बारे में व्यापक जानकारी प्रदान करती है, जिसमें उनका कार्य शीर्षक, विभाग, कार्यालय स्थान, संपर्क जानकारी, और अधिक शामिल है। यह आपको कर्मचारी की व्यावसायिक भूमिका और उसके संगठन में फिट होने का पूरा अवलोकन देने के लिए डिज़ाइन की गई है, जिससे आप उनकी जिम्मेदारियों को समझ सकते हैं और यह कैसे तय कर सकते हैं कि आप उनके साथ किस प्रकार सहयोग कर सकते हैं।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. कर्मचारी के प्रोफ़ाइल में कौन सी जानकारी शामिल होती है?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  टीमकनेक्ट में प्रत्येक कर्मचारी की प्रोफ़ाइल एक व्यापक स्नैपशॉट होती है, जो व्यक्ति के व्यावसायिक जीवन के बारे में अमीर अंतर्दृष्टि प्रदान करती है। यहां आप जो पाएंगे:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>मूल विवरण:</Body1Strong> नाम, कार्य शीर्षक, विभाग, और सहायक स्थान।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>रिपोर्टिंग संरचना:</Body1Strong> प्रत्यक्ष और कार्यात्मक रिपोर्टों की जानकारी,
                      सहित लाइन और कार्यात्मक प्रबंधक।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>कार्यस्थल की स्थिति:</Body1Strong> वर्तमान कार्य स्थिति जैसे कि छुट्टी पर, यात्रा पर,
                      व्यापार-यात्रा आदि।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>कार्यस्थल और स्थानीय समय:</Body1Strong> कर्मचारी का वर्तमान कार्य स्थान और 
                      स्थानीय समय क्षेत्र।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>संपर्क विवरण:</Body1Strong> कार्य फोन, मोबाइल नंबर, और ईमेल पता।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>कार्यालय का पता:</Body1Strong> इमारत का नाम, 
                      पता, और Google मानचित्र सहित पूरा कार्यालय स्थान।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>के बारे में:</Body1Strong> एक संक्षिप्त व्यक्तिगत परिचय या व्यावसायिक जीवनी।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>कर्मचारी संबंध:</Body1Strong> कर्मचारी की रिपोर्टिंग 
                      संरचना और टीम संबंधों का विवरण।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>सामाजिक लिंक और भाषाएं:</Body1Strong> पेशेवर सोशल प्रोफाइल्स 
                      और भाषा प्रवीणता के लिंक।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>कौशल:</Body1Strong> पेशेवर कौशल और विशेषज्ञता के क्षेत्रों की सूची।</li>
                  </ul>
                  इसके अतिरिक्त, प्रोफ़ाइल चैट, ईमेल, वीडियो कॉल के माध्यम से जुड़ने के लिए क्रियात्मक विकल्प प्रदान करती है, या बैठकों की योजना बनाने के साथ-साथ 
                  टीम के व्यापक संदर्भ के लिए संगठन के चार्ट तक त्वरित पहुँच प्रदान करती है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. अपनी प्रोफ़ाइल जानकारी को मैं कैसे अपडेट कर सकता हूँ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  टीमकनेक्ट में अपनी प्रोफ़ाइल को व्यक्तिगत बनाना आसान है। यहाँ आप इसे कैसे कर सकते हैं:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>कर्मचारी</Body1Strong> टैब पर जाएँ और अपने <Body1Strong>अवतार</Body1Strong> पर क्लिक करके अपनी प्रोफ़ाइल खोलें।
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        ऊपरी बाएँ कोने में, आप <Body1Strong>"अपनी प्रोफ़ाइल संपादित करें"</Body1Strong> लिंक पाएंगे।
                        इस पर क्लिक करने से आपकी प्रोफ़ाइल के लिए संपादन डायलॉग खुल जाएगा।
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        यहाँ, आप अपनी प्रोफ़ाइल में <Body1Strong>आपके बारे में</Body1Strong> अनुभाग, सोशल मीडिया लिंक, और फ़ोन नंबर जोड़कर या अपडेट कर सकते हैं। 
                        इससे आपके सहकर्मियों के लिए आपसे जुड़ना या आपका अनुसरण करना आसान हो जाता है।
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        अपने परिवर्तन लागू करने के लिए <Body1Strong>सेव</Body1Strong> बटन दबाना न भूलें।
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        डायलॉग में नीचे स्क्रॉल करें और अपनी <Body1Strong>भाषाएँ</Body1Strong> और <Body1Strong>प्रवीणता</Body1Strong>,
                        साथ ही अपने <Body1Strong>कौशल</Body1Strong> जोड़ें। यह जानकारी साझा करने से आपके सहकर्मियों को 
                        आपकी विशेषज्ञता के क्षेत्र और आप जिन भाषाओं में संवाद कर सकते हैं, उसे समझने में मदद मिलती है।
                      </li>
                    </ol>
                  अपनी प्रोफ़ाइल को अपडेट करना न केवल आपकी जानकारी को वर्तमान रखता है, बल्कि आपके संगठन के भीतर बेहतर संबंध बनाने में मदद करता है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. टीमकनेक्ट में आपके कार्यस्थल, कार्यस्थलीय स्थान, और समय क्षेत्र को अपडेट करना
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  माइक्रोसॉफ्ट टीम्स में सहज सहयोग के लिए आपके कार्यस्थल, कार्यस्थलीय स्थान, और समय क्षेत्र की जानकारी को अद्यतन रखना महत्वपूर्ण है। 
                  ये अपडेट आपकी टीमकनेक्ट प्रोफ़ाइल और सूचियों में तुरंत प्रतिबिंबित होते हैं, जिससे सहकर्मियों को आपके कार्य स्थान 
                  और आपसे जुड़ने के लिए सर्वोत्तम समय को समझने में मदद मिलती है, जिससे विभिन्न क्षेत्रों और समय क्षेत्रों में टीमवर्क में वृद्धि होती है।
                  <p></p>
                  अपनी विवरण अपडेट करने के लिए तैयार हैं? टीमकनेक्ट में यह आसान है! ऊपरी मेनू में 'सेटिंग्स' पर जाएं और 'जनरल सेटिंग्स' पर नेविगेट करें। 
                  यहाँ आप आसानी से अपने कार्यस्थल और समय क्षेत्र को अपडेट कर सकते हैं, और अपने कार्यस्थलीय स्थान को दिन के लिए निर्दिष्ट कर सकते हैं। 
                  अपने परिवर्तनों को सहेजना न भूलें ताकि सभी को सूचित रखा जा सके और आपके स्थान के बावजूद प्रभावी सहयोग सुनिश्चित किया जा सके!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. क्या आपने किसी सहकर्मी की प्रोफ़ाइल में कुछ गलत देखा है?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  यदि आप किसी सहकर्मी की प्रोफ़ाइल में कोई विवरण देखते हैं जो बिल्कुल सही नहीं लगता, तो सबसे पहला कदम उन्हें एक अनुकूल संदेश देना है। 
                  एक त्वरित चैट या ईमेल काम करेगा! यदि किसी कारणवश जानकारी अपडेट नहीं होती है, तो चिंता न करें—बस अपनी HR सहायता टीम को 
                  <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> पर पिंग करें, और वे खुशी-खुशी चीजों को सुलझाने में मदद करेंगे।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. समझना टैग समूह: सभी, पसंदीदा, चेक-इन, और नये कर्मचारी
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>सभी:</Body1Strong> यह डिफ़ॉल्ट टैग आपके संगठन के प्रत्येक कर्मचारी को प्रदर्शित करता है,
                      टीम का व्यापक दृश्य प्रदान करता है।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>पसंदीदा:</Body1Strong> यहाँ क्लिक करें ताकि आप उन सहकर्मियों को देख सकें जिन्हें आपने 'पसंदीदा' के रूप में 
                      चिह्नित किया है, जिससे महत्वपूर्ण संपर्कों के साथ जल्दी से जुड़ना आसान हो जाता है। 
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>चेक-इन:</Body1Strong> यह टैग उन कर्मचारियों को दर्शाता है जो सक्रिय रूप से काम पर हैं, 
                      केवल माइक्रोसॉफ्ट टीम्स में ऑनलाइन उपस्थिति से भिन्न होते हैं। जब चेक इन होते हैं, कर्मचारी यह संकेत देते हैं कि वे काम पर हैं और दिन के दौरान अपना वर्तमान कार्य स्थान 
                      साझा करते हैं। इसमें विभिन्न कार्यस्थल जैसे कि कार्यालय, फील्ड-कार्यालय, होम-कार्यालय, दूरस्थ-कार्यस्थल, ग्राहक-कार्यस्थल, 
                      ग्राहक-निजी-कार्यालय, सार्वजनिक-क्षेत्र, यात्रा, व्यावसायिक-यात्रा, या प्रशिक्षण शामिल हो सकते हैं। इसलिए, 'चेक-इन' स्थिति सक्रिय रूप से काम करने का एक स्पष्ट संकेत 
                      प्रदान करती है, सहकर्मियों को एक-दूसरे के वर्तमान कार्य संदर्भ और उपलब्धता की सटीक समझ प्रदान करती है।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>नये कर्मचारी:</Body1Strong> इस टैग के साथ TeamConnect में नए चेहरों को खोजें, जो 
                      हाल ही में ऐप में जोड़े गए कर्मचारियों को उजागर करता है, जो पिछले 30 दिनों के भीतर होता है। आमतौर पर, यह आपकी संगठन में हालिया जोड़ों के साथ मेल खाता है, 
                      आपको नए टीम के सदस्यों का स्वागत करने में मदद करता है।
                    </li>
                  </ul>
                  ये टैग समूह नेविगेशन को सरल बनाने और आपके TeamConnect अनुभव को बढ़ाने के लिए डिजाइन किए गए हैं, सुनिश्चित करते हुए कि आप हमेशा 
                  अपने सहकर्मियों को आसानी से ढूँढ सकें और उनसे कुशलतापूर्वक जुड़ सकें।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. खोज बॉक्स के बगल में आइकनों को समझना
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect की कर्मचारी निर्देशिका विविधता और सुविधा के लिए डिजाइन की गई है, चार आइकन फ़ीचर करती है जो विभिन्न दृश्य प्रदान करते हैं
                  और महत्वपूर्ण विवरणों तक त्वरित पहुँच प्रदान करते हैं। यहाँ प्रत्येक आइकन क्या उजागर करता है:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>सूची दृश्य:</Body1Strong> आपका प्राथमिक दृश्य, सभी संगठन सदस्यों को एक सीधी सूची में प्रस्तुत करता है।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>छोटा कार्ड दृश्य:</Body1Strong> एक संक्षिप्त विकल्प, यह दृश्य सूची को छोटे कर्मचारी कार्डों में बदल देता है।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>बड़ा कार्ड दृश्य:</Body1Strong> अधिक विवरण के साथ बड़े कार्डों के लिए विकल्प चुनें, जिसमें आसान डायलिंग के लिए फोन और मोबाइल नंबर जैसे 
                    अतिरिक्त संपर्क विकल्प शामिल हैं।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>संगठन चार्ट:</Body1Strong> संगठन की संरचना को संगठन चार्ट दृश्य के साथ दृश्यात्मक रूप से समझें,
                      रिपोर्टिंग संबंधों को समझने और संगठनात्मक परिदृश्य को नेविगेट करने के लिए उत्तम।</li>
                  </ol>
                    इन आइकनों के बीच टॉगल करें ताकि वह दृश्य खोज सकें जो उस समय आपकी जरूरतों के अनुसार सबसे अच्छा हो, चाहे आप त्वरित जानकारी चाहते हों या गहरी अंतर्दृष्टि।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. त्वरित सहकर्मी खोज के लिए, मुझे कहाँ देखना चाहिए?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  किसी सहकर्मी की तलाश है? बस TeamConnect खोज बॉक्स की ओर जाएं, जो ऊपरी दाएं कोने में Org chart आइकन के बगल में स्थित है। 
                  उनका नाम टाइप करना शुरू करें, और देखें कैसे परिणाम प्रत्येक अक्षर के साथ शुद्ध होते जाते हैं। याद रखें, TeamConnect खोजों के लिए, माइक्रोसॉफ्ट टीम्स 
                  के ऊपरी खोज बार को छोड़ दें—हमारा विशेष खोज बॉक्स ही जाने का तरीका है!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. कर्मचारियों को ढूंढने के लिए वर्णमाला खोज कैसे काम करती है?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  टीमकनेक्ट की कर्मचारी डायरेक्टरी में एक सुविधाजनक वर्णमाला खोज फीचर शामिल है, जो आपको उनके पहले नाम से कर्मचारियों को त्वरित खोजने में मदद करता है। 
                  बस निर्देशिका के ऊपर दिखाए गए वर्णमाला से एक अक्षर पर क्लिक करें, और टीमकनेक्ट तुरंत आपको वे सभी कर्मचारी दिखाएगा जिनके पहले नाम उस अक्षर से शुरू होते हैं। कर्मचारियों की पूरी सूची में वापस जाने के लिए, बस चयनित अक्षर को अचयनित कर दें। यह सहज फीचर बड़ी कर्मचारी सूचियों के माध्यम से नेविगेट करना आसान बनाता है, सुनिश्चित करता है कि आप जल्दी और कुशलता से सहकर्मियों को ढूंढ सकते हैं।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. मैं प्रति पृष्ठ दिखाए जाने वाले कर्मचारियों की संख्या को कैसे समायोजित कर सकता हूँ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  टीमकनेक्ट आपको प्रति पृष्ठ देखी जाने वाली कर्मचारी प्रोफ़ाइल की संख्या को अनुकूलित करने की अनुमति देता है, जिससे कर्मचारी डायरेक्टरी के माध्यम से नेविगेट करना आसान हो जाता है। 
                  प्रत्येक पृष्ठ के निचले भाग में, आपको पेजिनेशन विकल्प मिलेंगे: 10, 50, 100, 500, 1000, और 5000। डिफ़ॉल्ट सेटिंग प्रति पृष्ठ 10 प्रोफ़ाइल दिखाती है। 
                  एक पृष्ठ पर अधिक प्रोफ़ाइल देखने के लिए, बस इन विकल्पों में से अपनी पसंदीदा संख्या का चयन करें। यह फीचर आपको निर्देशिका को देखने के तरीके में लचीलापन प्रदान करता है, जिससे आप अपनी विशिष्ट जरूरतों या प्राथमिकताओं के अनुसार प्रदर्शन को अनुकूलित कर सकते हैं।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. मैं लिस्ट व्यू में कर्मचारी जानकारी को कैसे सॉर्ट कर सकता हूँ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  टीमकनेक्ट की कर्मचारी डायरेक्टरी लिस्ट व्यू में, आपको कर्मचारी जानकारी को आपकी जरूरतों के अनुसार सॉर्ट करने का लचीलापन मिलता है। कॉलम—कर्मचारी नाम, नौकरी का शीर्षक, विभाग, कार्यालय स्थान, और कार्यस्थल—सभी सॉर्ट किए जा सकते हैं। बस उस कॉलम हैडर पर क्लिक करें जिसे आप सॉर्ट करना चाहते हैं। एक बार क्लिक करने पर कॉलम आरोही क्रम में सॉर्ट हो जाएगा, और फिर से क्लिक करने पर अवरोही क्रम में। डिफ़ॉल्ट रूप से, निर्देशिका पहले नाम के आधार पर आरोही क्रम में कर्मचारी नाम से सॉर्ट की जाती है। यह सॉर्टिंग फीचर आपको किसी विशेष व्यक्ति की तलाश में हों या किसी विशेष मानदंड जैसे विभाग या स्थान से कर्मचारियों को समूहीकृत करने की आवश्यकता हो, कर्मचारी विवरणों को जल्दी से आयोजित करने और देखने में मदद करता है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>विभाग निर्देशिका FAQ</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>विभाग निर्देशिका परिचय:</Body1Strong> विभाग निर्देशिका आपके संगठन की जटिल संरचना को समझने का एक माध्यम है, जो विभागीय पदानुक्रम, प्रबंधकीय नेतृत्व, और विभागीय संचार के लिए सहज मार्ग प्रदान करता है।
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. 'सभी', 'पसंदीदा', और 'आपका विभाग' टैग विभाग निर्देशिका में कैसे काम करते हैं?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect की विभाग निर्देशिका में, टैग आपको विभागीय जानकारी को तेजी से नेविगेट और फिल्टर करने में मदद करते हैं। प्रत्येक टैग का प्रतिनिधित्व यहाँ दिया गया है:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>सभी:</Body1Strong> यह टैग आपके संगठन के सभी विभागों को प्रदर्शित करता है, जिससे आपको एक समग्र अवलोकन प्राप्त होता है।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>पसंदीदा:</Body1Strong> इस टैग का उपयोग करके आप उन विभागों की चयनित सूची देख सकते हैं जिन्हें आपने 'पसंदीदा' के रूप में चिह्नित किया है, जिससे आप उन विभागों तक आसानी से पहुँच सकते हैं जिनसे आप नियमित रूप से संवाद करते हैं।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>आपका विभाग:</Body1Strong> यह टैग दृश्य को फिल्टर करके केवल आपके विभाग को प्रदर्शित करता है, जिससे आप अपने तत्काल विभागीय जानकारी और अपडेट्स तक त्वरित पहुँच प्राप्त कर सकते हैं।</li>
                  </ul>
                  ये टैग विभाग निर्देशिका के भीतर आपके नेविगेशन को सरल बनाने के लिए डिज़ाइन किए गए हैं, जिससे आपकी विभागीय जानकारी को खोजने और संलग्न करने की क्षमता में सुधार होता है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. TeamConnect में विभाग निर्देशिका दृश्यों को समझना
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect के विभाग निर्देशिका में नेविगेट करना आपकी पसंद के अनुसार विभिन्न दृश्य विकल्पों के साथ सरलीकृत है। प्रत्येक दृश्य विभागीय जानकारी को विस्तृत रूप से खोजने का अनूठा तरीका प्रदान करता है, जिसमें विभाग का नाम, विभाग प्रबंधक, मूल विभाग, विभागीय फोन, विभागीय हॉटलाइन, और विभागीय ईमेल शामिल हैं। उपलब्ध दृश्यों की एक झलक यहाँ दी गई है:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>सूची दृश्य:</Body1Strong> डिफ़ॉल्ट सेटअप जो सभी विभागों को साफ-सुथरी सूची में प्रदर्शित करता है, जिससे एक स्पष्ट, संक्षिप्त अवलोकन प्रदान होता है।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>बड़ा कार्ड दृश्य:</Body1Strong> इस दृश्य में स्विच करके आप प्रत्येक विभाग की मुख्य जानकारी का विस्तारित सारांश प्रदान करने वाले विस्तृत विभाग कार्ड्स देख सकते हैं।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>संगठन चार्ट दृश्य:</Body1Strong> विभागीय संरचना और संबंधों का दृश्य प्रतिनिधित्व करने के लिए, संगठन चार्ट दृश्य दिखाता है कि प्रत्येक विभाग बड़े संगठनात्मक पदानुक्रम में किस प्रकार फिट बैठता है।
                    </li>
                  </ul>
                  इन दृश्यों के बीच टॉगल करने के लिए, बस विभाग निर्देशिका के शीर्ष पर सर्च बॉक्स के पास दिए गए आइकनों का उपयोग करें। प्रत्येक दृश्य आपको विभागीय विवरणों तक पहुँचने और उन्हें समझने में मदद करने के लिए डिज़ाइन किया गया है, यह सुनिश्चित करते हुए कि आपको आपके लिए सर्वोत्तम प्रारूप में आवश्यक जानकारी मिले।
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. विभाग निर्देशिका में खोज बॉक्स का उपयोग कैसे करें
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect में किसी भी विभाग को आसानी से खोजने के लिए उसके शीर्ष पर स्थित दर्शन चिह्नों के ठीक बगल में दिए गए समर्पित खोज बॉक्स का प्रयोग करें। बस आप जिस विभाग का नाम खोज रहे हैं उसे टाइप करना शुरू करें, और देखें कैसे निर्देशिका स्वयं को परिष्कृत करती है और केवल प्रासंगिक मिलानों को प्रदर्शित करती है। यह सुविधा विशेष रूप से इसलिए डिजाइन की गई है ताकि आपको पूरी निर्देशिका को मैन्युअली छानने की आवश्यकता से बचाया जा सके, और आपको आवश्यक विभाग सीधे आपकी उंगलियों पर लाया जा सके। याद रखें, यह खोज कार्यक्षमता केवल TeamConnect की विभाग निर्देशिका के लिए अनूठी है, इसलिए विभाग-विशिष्ट प्रश्नों के लिए टीमों के इंटरफेस के शीर्ष पर मुख्य Microsoft Teams खोज का उपयोग करने के बजाय इस खोज बॉक्स का उपयोग करें।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. विभाग निर्देशिका में वर्णमाला फ़िल्टर कैसे काम करता है?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  विभाग निर्देशिका में अपनी खोज को वर्णमाला फ़िल्टर के साथ सुव्यवस्थित करें जो शीर्ष पर स्थित है। यह सुविधा आपको एक अक्षर चुनकर विभागों को जल्दी से संकीर्ण करने की अनुमति देता है। एक बार जब आप एक अक्षर चुन लेते हैं, TeamConnect केवल उन विभागों को छानकर और प्रदर्शित करता है जिनके नाम आपके चयनित अक्षर से शुरू होते हैं, जिससे आपको पूरी सूची के माध्यम से स्क्रॉल किए बिना वह खोजना आसान हो जाता है जो आप देख रहे हैं। पूरे विभाग के दृश्य पर लौटने के लिए बस उजागर अक्षर को अचयनित करें। यह सहज वर्णमाला नेविगेशन आपको किसी भी विभाग को उसके प्रारंभिक अक्षर से कुशलतापूर्वक खोजने में सुनिश्चित करता है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. मैं विभाग प्रबंधक से संपर्क कैसे कर सकता हूँ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect में किसी विभाग प्रबंधक से संपर्क करना सरल है। बस दिलचस्पी वाले विभाग का पता लगाएं और प्रबंधक के नाम पर क्लिक करें। यह क्रिया एक पॉपओवर को ट्रिगर करेगी जिसमें प्रबंधक के संपर्क विकल्प प्रदर्शित होंगे जिसमें परिचित त्वरित-पहुँच चिह्न शामिल हैं:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>चैट चिह्न:</Body1Strong> Microsoft Teams चैट के माध्यم से तत्काल संदेश शुरू करने के लिए।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ईमेल चिह्न:</Body1Strong> उन्हें Microsoft Outlook में एक नया ईमेल पता खोलने के लिए।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>वीडियो कॉल चिह्न:</Body1Strong> Microsoft Teams के माध्यम से एक वीडियो कॉल शुरू करने के लिए।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>कॉल चिह्न:</Body1Strong> Microsoft Teams का उपयोग करते हुए एक आवाज कॉल शुरू करने के लिए।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>अधिक (...) चिह्न:</Body1Strong> एक मीटिंग शेड्यूल करने या अपनी टीमों की सूची में उनकी संपर्क जानकारी जोड़ने जैसे अतिरिक्त विकल्पों के लिए।
                    </li>
                  </ul>
                  ये चिह्न TeamConnect के भीतर से ही विभाग प्रबंधकों से सीधे संपर्क करने का एक सहज तरीका प्रदान करते हैं, जिससे आपके संगठन के भीतर कुशल संचार सुनिश्चित होता है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. मैं विभाग के भीतर कर्मचारियों को कैसे देख सकता हूँ या उनसे संपर्क कर सकता हूँ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  यदि आप TeamConnect में किसी विभाग के भीतर कौन कौन है, यह पता करना चाहते हैं, तो विभाग के नाम के बगल में तीन बिंदुओं पर नजर डालें। इस पर क्लिक करने से आगे की कार्रवाई के विकल्प खुलेंगे:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>इस विभाग में कर्मचारियों को देखें:</Body1Strong> इस विकल्प को चुनें ताकि विभाग से जुड़े सभी कर्मचारियों की सूची प्रदर्शित की जा सके। इस विकल्प के बगल में मौजूद संख्या बताती है कि विभाग में कितने कर्मचारी हैं, जो त्वरित संख्या देती है।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>विभाग संगठनात्मक चार्ट:</Body1Strong> यदि आप विभाग के हायरार्की को समझना चाहते हैं, तो 'विभाग संगठनात्मक चार्ट' चुनें जो संरचनात्मक लेआउट को प्रकट करेगा, जिसमें मूल विभागों, प्रश्�रत विभाग, और किसी भी उपविभागों के बीच का संबंध दर्शाया गया है। यह दृश्य प्रतिनिधित्व आपको नैविगेट करने और संगठनात्मक ढांचे को समझने में मदद करता है, जिससे विशेष विभाग सदस्यों से संपर्क करना आसान हो जाता है।
                    </li>
                    <li><Body1Strong>विभाग {'>'} कर्मचारी:</Body1Strong> एक विभाग के भीतर कर्मचारियों को देखने के बाद, इस ब्रेडक्रंब ट्रेल का उपयोग करके आसानी से विभाग सूची में वापस नेविगेट करें, TeamConnect के भीतर एक सुगम अन्वेषण अनुभव बनाए रखते हुए।
                    </li>
                  </ul>
                  ये विशेषताएं आपको न केवल किसी दिए गए विभाग में कर्मचारियों की सूची देखने की अनुमति देती हैं, बल्कि उनसे सीधे TeamConnect के संचार उपकरणों के माध्यम से संपर्क करने का मार्ग भी प्रदान करती हैं।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. TeamConnect में गहन विभाग विवरणों की खोज
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect में किसी भी विभाग के विशिष्टताओं में गहराई से उतरने के लिए, बस उसके नाम पर एक साधारण क्लिक करें। विस्तृत संवाद खुलता है, जो आपके संगठन के भीतर विभाग की भूमिका और संसाधनों की पूरी तस्वीर प्रदान करता है। आपको नेतृत्व और वित्तीय पहचानकर्ताओं से लेकर संचार चैनलों और सोशल लिंक्स तक सब कुछ मिलेगा। यह सुविधा आपको प्रदान करती है:
                  <ul>
                    <li className={styles.bulletBottomMargin}>विभाग की धड़कन – उसका उद्देश्य, लोग, और प्रदर्शन मेट्रिक्स।</li>
                    <li className={styles.bulletBottomMargin}>संपर्क मार्ग – विभागीय प्रमुख हस्तियों और संचार मंचों के लिए सीधी लाइनें।</li>
                    <li className={styles.bulletBottomMargin}>संगठनात्मक जाल – यह समझना कि विभाग व्यापक कंपनी संरचना के साथ कैसे एकीकृत करता है।</li>
                  </ul>
                  चाहे वह एक त्वरित प्रश्�न हो या विभागीय डेटा में गहराई से उतरना, TeamConnect आपको अपने संगठन के अद्वितीय पारिस्थितिकी तंत्रों के साथ जुड़ने और समझने के लिए उपकरण प्रदान करता है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. TeamConnect में विभाग के पते के अभाव के बारे में जिज्ञासा?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  कभी-कभी एक विभाग का स्थान ढूँढना मानचित्र के बिना एक खजाने की खोज की तरह महसूस हो सकता है। TeamConnect में, आपको आमतौर पर प्रत्येक विभाग के लिए एक समर्पित पता नहीं मिलेगा। यहाँ पर क्यों यह कार्टोग्राफ़िक विवरण अक्सर छोड़ दिया जाता है:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>एकाधिक स्थान:</Body1Strong> कई विभाग एक ही स्थान तक सीमित नहीं होते; वे विभिन्न कार्यालयों और भूगोलों में फलते-फूलते हैं।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>चंचल संरचना:</Body1Strong> संगठनात्मक लैंडस्केप हमेशा बदल रहा है, रणनीतिक बदलावों के साथ विभाग विकसित हो रहे हैं, विलय कर रहे हैं या यहां तक कि भंग भी हो रहे हैं।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>विभागीय सहयोग:</Body1Strong> आधुनिक कार्यस्थल सहयोगी भावना के साथ गुंजायमान होते हैं, अक्सर साझा स्थानों में विभिन्न विभागों की प्रतिभाओं को मिलाते हैं।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>रिमोट कार्य संस्कृति:</Body1Strong> रिमोट काम करने की लचीलापन के साथ, विभागीय लंगर हमेशा भौतिक कार्यालय स्थानों में नहीं डाले जाते हैं।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>कानूनी नुक्ताचीनी:</Body1Strong> विभाग आमतौर पर कंपनी की कानूनी संरचना के चारों ओर घूमते हैं, न कि अपने स्वयं के स्वतंत्र पतों के साथ।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>संचालनात्मक सुगमीकरण:</Body1Strong> गतिशील टीमवर्क पर ध्यान केंद्रित करके, स्थैतिक पतों को नहीं, संचालनात्मक चुस्ती को बनाए रखने में मदद.
                    </li>
                  </ul>
                  यह सब टीम वर्क और दक्षता के लिए सीमा-रहित दृष्टिकोण अपनाने के बारे में है। टीमकनेक्ट स्थानों पर कनेक्शन पर जोर देकर इस दर्शन को दर्शाता है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. क्या आपके विभाग की जानकारी गायब या पुरानी है?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  आपके संगठन में विभाग संपादक नामित किए जाते हैं जो टीमकनेक्ट में विभाग-विशिष्ट जानकारी जोड़ने, अपडेट करने या सही करने का कार्य करते हैं।
                  यदि आपको यकीन नहीं है कि आपके विभाग का संपादक कौन है या कुछ जानकारी के लिए HR सहायता टीम की जरूरत है, 
                  कृपया मार्गदर्शन के लिए <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> से संपर्क करें।
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>कार्यालय निर्देशिका सामान्य प्रश्न</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>कार्यालय निर्देशिका परिचय:</Body1Strong> अपने संगठन की भौतिक उपस्थिति की रीढ़ की हड्डी की खोज करें कार्यालय निर्देशिका के माध्यम से, 
                जो महत्वपूर्ण कार्यालय विवरण, परिचालन विशेषताओं और प्रत्येक स्थान को परिभाषित करने वाले विभागों और कर्मियों की व्यापक झलक प्रदान करता है।
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. कार्यालय निर्देशिका में टैग्स नेविगेट करना
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  हमारी टैग प्रणाली का उपयोग करके कार्यालय निर्देशिका के चारों ओर आसानी से अपना रास्ता खोजें, जो सही स्थान पर त्वरित पहुँच के लिए डिज़ाइन की गई है। यहाँ प्रत्येक टैग कैसे आपकी खोज को सुव्यवस्थित कर सकता है:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>सभी:</Body1Strong> कार्यालयों की पूरी सूची प्रदर्शित करता है, जिससे आपके संगठन के स्थानों का पक्षी दृष्टिकोण प्रदान होता है।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>पसंदीदा:</Body1Strong> उन कार्यालय स्थानों पर ध्यान केंद्रित करने में मदद करता है जिन्हें आप बार-बार जाते हैं या जिन पर आपको नजर रखने की जरूरत है, उन्हें 'पसंदीदा' के रूप में चिह्नित करके।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>आपका कार्यालय:</Body1Strong> यह व्यक्तिगत टैग निर्देशिका को फ़िल्टर करता है ताकि आपको वह कार्यालय स्थान दिखाई दे जहाँ आप स्थित हैं, आपके मुख्य आधार को आपकी उँगलियों पर रखता है।
                    </li>
                  </ul>
                  चाहे आप मीटिंग रूम बुक कर रहे हों या योजना बना रहे हों, ये टैग आपके संगठन की भौगोलिक परिदृश्य के साथ बातचीत करने का एक व्यक्तिगत और सरलीकृत तरीका प्रदान करते हैं।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. टीमकनेक्ट में ऑफिस डायरेक्टरी व्यूज को माहिर करना
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  अपनी कंपनी के विभिन्न स्थानों की संगठनात्मक रचना में गोता लगाएँ जिसे टीमकनेक्ट की ऑफिस डायरेक्टरी व्यूज प्रस्तुत करती हैं। प्रत्येक व्यू को ऑफिस नेविगेशन और समझ के एक अनूठे पहलू को सुविधाजनक बनाने के लिए तैयार किया गया है:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>सूची दृश्य:</Body1Strong> डिफ़ॉल्ट सेटिंग जो सभी ऑफिस स्थानों को एक सुलभ-पठनीय सूची में व्यवस्थित करती है।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>बड़ा कार्ड दृश्य:</Body1Strong> ऑफिस डायरेक्टरी में बड़ा कार्ड दृश्य आपकी दृष्टि को प्रत्येक कार्यालय के विस्तृत प्रस्तुति से समृद्ध करता है। जब आप किसी कार्यालय कार्ड से जुड़ेंगे, तो आपको "अधिक (...)" मेनू के भीतर जानकारी और क्रियाशील विकल्पों का एक खजाना मिलेगा:
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>विभाग देखें:</Body1Strong> सीधे कार्यालय के भीतर स्थित सभी विभागों की सूची पर नेविगेट करें।
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>कर्मचारी देखें:</Body1Strong> कार्यालय में नियुक्त सभी कर्मचारियों की संपूर्ण सूची तक पहुंचें, जिससे कार्यालय के कर्मचारियों से सीधे संपर्क या अन्वेषण सुविधाजनक हो।
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>व्यावसायिक इकाई संगठनात्मक चार्ट:</Body1Strong> व्यापक संगठनात्मक संदर्भ में कार्यालय की स्थिति का चित्रण करें ताकि पदानुक्रमिक संरचनाओं की स्पष्ट समझ बन सके।
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>महत्वपूर्ण संपर्क:</Body1Strong> सुगम संचार के लिए प्रमुख कार्यालय संपर्कों की पहचान करें।
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>कार्यालय सार्वजनिक अवकाश:</Body1Strong> अपनी योजनाएँ उसी के अनुरूप बनाने के लिए कार्यालय-विशिष्ट सार्वजनिक अवकाशों के बारे में जानकार रहें।
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>कार्यालय कार्य समय:</Body1Strong> कार्यालय के संचालन समय को देखें ताकि आप उनके सक्रिय समय के अनुसार अपनी यात्राओं या कॉल्स को संरेखित कर सकें।
                        </li>
                      </ol>
                      यह दृश्य न केवल एक दृश्य और सूचनात्मक स्नैपशॉट प्रदान करता है, बल्कि कार्यालय से संबंधित गहरी अंतर्दृष्टि और प्रत्यक्ष क्रियाओं तक त्वरित पहुंच भी प्रदान करता है, जो सभी आपकी सुविधा के लिए "अधिक (...)" विकल्प के तहत स neatlyयतापूर्वक रखे गए हैं।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>संगठनात्मक चार्ट दृश्य:</Body1Strong> आपके संगठन के भीतर विभिन्न कार्यालय स्थानों की अंतरक्रियाशीलता को समझने के लिए मातृ और उप-कार्यालयों की पदानुक्रमिक संरचना का चित्रण करें।
                    </li>
                  </ul>
                  अपनी तत्काल आवश्यकताओं के अनुरूप अपने ब्राउज़िंग अनुभव को अनुकूलित करने के लिए खोज बॉक्स के बगल में आइकनों के माध्यम से इन व्यूज के बीच टॉगल करें।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. टीमकनेक्ट के खोज फ़ीचर के साथ त्वरित रूप से कार्यालय खोजना
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  टीमकनेक्ट के भीतर सही कार्यालय स्थान को खोजना विशेष खोज फ़ीचर के साथ आसान बनाया गया है। व्यू आइकनों के बगल में प्रमुख रूप से स्थित, खोज बॉक्स नाम से कार्यालयों को चिह्नित करने के लिए आपका उपकरण है। जैसे ही आप टाइप करते हैं, निर्देशिका प्रदर्शन को केवल उन कार्यालयों तक सीमित कर देती है जो आपके इनपुट से मेल खाते हैं, आपकी खोज प्रक्रिया को सुव्यवस्थित करते हैं। सबसे सटीक और प्रासंगिक परिणामों के लिए टीमकनेक्ट के भीतर इस खोज बॉक्स का उपयोग करना महत्वपूर्ण है, सामान्य माइक्रोसॉफ्ट टीम्स खोज के विपरीत। इस लक्षित खोज कार्यक्षमता के साथ, आप अपने संगठन के नेटवर्क में किसी भी कार्यालय में त्वरित रूप से नेविगेट कर सकते हैं।
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. कार्यालय निर्देशिका को वर्णमाला क्रम में नेविगेट करना
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  यदि आप वर्णमाला के अनुसार खोजना पसंद करते हैं, तो हमारी वर्णमाला फिल्टर नाम से कार्यालय खोजने का एक तीव्र तरीका है। कार्यालय निर्देशिका के शीर्ष पर, आपको 
                  अक्षर A से Z तक दिखाई देंगे। किसी अक्षर पर क्लिक करने से दृश्य उन कार्यालयों को दिखाने के लिए फिल्टर हो जाता है जो उस अक्षर से शुरू होते हैं, आपकी खोज 
                  प्रक्रिया को सरल बनाते हैं। सभी कार्यालयों को फिर से देखने के लिए, बस सक्रिय अक्षर को अनचेक करें। यह सुविधा विशेष रूप से उपयोगी है जब आप कार्यालय का नाम 
                  जानते हैं लेकिन इसे TeamConnect में जल्दी से ढूँढने की आवश्यकता होती है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. TeamConnect में व्यापक कार्यालय प्रोफाइल प्रकट करना
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  अपने संगठन की भौतिक उपस्थिति के केंद्र के माध्यम से एक खोजी यात्रा पर निकलें TeamConnect के कार्यालय प्रोफाइलों के साथ। 
                  किसी कार्यालय के नाम पर क्लिक करने से एक जीवंत प्रोफाइल डायलॉग खुलता है, जो विस्तृत कार्यालय अंतर्दृष्टि से भरपूर टैब से सजा होता है:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>अवलोकन:</Body1Strong> व्यापार संरचना से लेकर खुलने के घंटे और स्थानीय समय तक के विस्तृत 
                      विवरणकों के साथ कार्यालय की पहचान के मूल तक गोता लगाएँ, कार्यालय के वातावरण का सार पकड़ते हुए।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>आवश्यक संपर्क:</Body1Strong> आपात सेवाओं और 
                      बाहरी समर्थन के लिए महत्वपूर्ण संपर्कों तक पहुंचें, प्रत्येक Microsoft Teams Phone System के माध्यम से एक क्लिक दूर।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>कार्यालय संपर्क:</Body1Strong> आंतरिक समर्थन सेवाओं से आसानी से संपर्क करें, संपर्क विवरण और 
                      प्रत्यक्ष जुड़ाव विकल्पों के साथ ठीक आपकी उंगलियों पर।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>सार्वजनिक अवकाश:</Body1Strong> कार्यालय-विशिष्ट छुट्टियों के बारे में अवगत रहें, यह सुनिश्चित करते हुए कि 
                      आपकी योजनाएं स्थानीय अवलोकनों और कार्यक्रमों के साथ मेल खाती हैं।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>कार्यालय के घंटे:</Body1Strong> कार्यालय के सक्रिय रहने या दिन के लिए बंद होने के समय के सटीक ज्ञान के साथ अपनी बातचीत की योजना बनाएं।
                    </li>
                  </ul>
                  चाहे वह ऑस्टिन की चहल-पहल हो या सिंगापुर का रणनीतिक केंद्र, प्रत्येक टैब आपकी कंपनी की वैश्विक उपस्थिति को बनाने वाले विविध स्थानों को समझने और उनसे जुड़ने का एक द्वार प्रदान करता है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. कार्यालय के विभागों की गतिशीलता में गहराई से उतरना
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  किसी कार्यालय के विभागों के पेशेवर नेटवर्क को सुगमता से समझना TeamConnect में आसानी से होता है। यहाँ विवरणों में उतरने का तरीका है:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>इस कार्यालय में विभाग देखें:</Body1Strong> इस विकल्प को More (...) मेनू से चुनकर कार्यालय का ताना-बना बनाने वाले सभी विभागों का पता लगाएं।
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>संगठनात्मक अंतर्दृष्टि:</Body1Strong> किसी भी विभाग पर क्लिक करें ताकि उसका आयोजन चार्ट प्रदर्शित हो जो उसके संरचना का दृश्य प्रतिनिधित्व करता है, ब्रेडक्रम्ब ट्रेल के साथ मार्गदर्शन करता है: कार्यालय {'>'} विभाग {'>'} चार्ट।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>विभागीय गहराईयों में उतरना:</Body1Strong> विभाग के नाम के पास More मेनू (...) पर क्लिक करके और उस विभाग के भीतर सभी कर्मचारियों की सूची तक पहुंचें, सही कार्यालय संदर्भ में।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ब्रेडक्रम्ब नेविगेशन:</Body1Strong> सहजता से स्तरों के माध्यम से यात्रा करें। कर्मचारियों से वापिस विभागों तक या आयोजन चार्ट से, ब्रेडक्रम्ब ट्रेल (कार्यालय {'>'} विभाग {'>'} कर्मचारी {'>'} चार्ट) आपकी यात्रा को सहज और सही दिशा में रखता है।
                    </li>
                  </ul>
                  TeamConnect की जटिल पथव्यूह आपको अपने कार्यालय के विभागों के हर परत को खोजने, समझने, और संलग्न करने का निमंत्रण देती है, आपको समग्र नेविगेशनल आदेश प्रदान करती है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. TeamConnect में कार्यालय कर्मियों के साथ जुड़ना
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  किसी कार्यालय के पीछे के चेहरों के बारे में उत्सुक हैं? उत्तर TeamConnect में बस एक क्लिक दूर है। अपने चुने हुए कार्यालय नाम के पास तीन बिंदुओं (थ्री डॉट्स) के साथ सगठन का विकल्प खोलें जिससे इंटरएक्टिव विकल्पों की श्रृंखला प्रकट होती है:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>इस कार्यालय में कर्मचारियों को देखें:</Body1Strong> उन सभी पेशेवरों की सूची उजागर करें जो इस कार्यालय को अपना कामकाजी घर कहते हैं। प्रदर्शित संख्या आपको कार्यालय समुदाय में गहराई से उतरने का निमंत्रण देती है।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>संपर्क विकल्प:</Body1Strong> प्रत्येक कर्मचारी प्रविष्टि को त्वरित क्रिया आइकनों से सुसज्जित किया गया है, जो आपको TeamConnect से चैट, कॉल्स, या यहाँ तक कि वीडियो कॉन्फ्रेंसेस शुरू करने की अनुमति देता है, कार्यालय-अंतर संचार को सहज बनाता है।
                    </li>
                    <li><Body1Strong>नेविगेशन:</Body1Strong> किसी कर्मचारी सूची से व्यापक कार्यालय सूची में वापस जाने के लिए, बस 'कार्यालय' पर क्लिक करें जो ब्रेडक्रम्ब ट्रेल में है (कार्यालय {'>'} कर्मचारी), और आपको कार्यालय अवलोकन में वापिस ले जाया जाएगा।
                    </li>
                  </ul>
                  इन सुविधाओं के साथ, TeamConnect कार्यालयों में कार्यबल वितरण को दृश्यमान बनाने और आसानी से संपर्क करने को सरल बनाता है, किसी भी दूरी के बावजूद एक जुड़े हुए कार्यस्थल को बढ़ावा देता है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. क्या आपकी कार्यालय की जानकारी गायब है या पुरानी है?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect में कार्यालय जानकारी को नियुक्त कार्यालय संपादकों द्वारा संभाला जा सकता है। यदि आप कार्यालय संपादकों के बारे में अनिश्चित हैं या 
                  HR के अधिकार क्षेत्र में आने वाली जानकारी के साथ सहायता की आवश्यकता है, तो HR सहायता टीम आपकी मदद के लिए तैयार है 
                  <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>सेटिंग्स संबंधित प्रश्न</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. TeamConnect में सटीक सामान्य सेटिंग्स का महत्व
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  आज के दिनामिक कार्य परिवेश में जुड़े रहना और समन्वय स्थापित करना पहले से अधिक महत्वपूर्ण है। यहाँ TeamConnect में आपकी सामान्य सेटिंग्स को अपडेट करना एक निर्णायक भूमिका निभाता है:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>कार्यस्थल का चुनाव:</Body1Strong> अपने कार्यस्थल को अपडेट करके अपने वर्तमान परिस्थिति की झलक पकड़ें, चाहे वह आपके होम-ऑफिस की शांति से मुख्य कार्यालय की चहल-पहल में बदलाव हो, या फिर सार्वजनिक क्षेत्र में स्थापित हो। 
                        आपके कार्य स्थल पर यह स्पष्टता सहकर्मियों को न केवल आपसे संपर्क करने के तरीके बताती है, बल्कि संवेदनशील चर्चाओं की गोपनीयता की रक्षा करते हुए उन्हें आपके साथ किस प्रकार के वार्तालाप कर सकते हैं, इसकी जानकारी भी देती है, खासकर जब आप किसी ग्राहक के कार्यालय के खुले वातावरण में हों या किसी हवाई अड्डे के प्रतीक्षालय में बैठे हों।
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>कार्यस्थल विनिर्देशन:</Body1Strong> अपने कार्य शहर को साझा करना केवल एक दूरस्थ टीम सेटअप में दूरियों को पाटने से अधिक है—यह अचानक पेशेवर मिलने-जुलने या सामाजिक समय बिताने के अवसर भी खोलता है। चाहे वह एक त्वरित कॉफी हो, एक शांत दोपहर का भोजन हो, या एक सहयोगी बैठक हो, यह जानना कि आप एक ही शहर में हैं, एक सामान्य दिन को Meaningful चेहरा-ब-चेहरा CONNECTIONS के लिए एक अवसर में बदल सकता है। यह न केवल टीम बंधन को मजबूत करता है बल्कि काम पर प्रभाव डालने वाली स्थानीय विशेषताओं की बेहतर समझ भी प्रदान करता है।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>समय क्षेत्र की सटीकता:</Body1Strong> जैसे-जैसे टीमें महाद्वीपों में फैलती हैं, यह सुनिश्चित करना कि आपका समय क्षेत्र आपके वर्तमान स्थान को दर्शाता है, संचारों के सामंजस्य के लिए अनिवार्य है। आपके TeamConnect प्रोफ़ाइल का स्थानीय समय इस सेटिंग से सीधे प्रभावित होता है, सहकर्मियों के लिए आप तक पहुँचने की कोशिश में किसी भी अनुमान को समाप्त करता है।
                    </li>
                  </ul>
                  इन विवरणों को ठीक करने के लिए TeamConnect के भीतर 'Settings' {'>'} 'General Settings' में गोता लगाएँ। अपनी सेटिंग्स को अपडेट करने में कुछ क्षणों के व्यय से आपकी सहयोगी प्रयासों की संगति और कार्यकुशलता को काफी बढ़ावा मिल सकता है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. टीमकनेक्ट में कार्यस्थल चयन को समझना
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  कार्यस्थल ड्रॉपडाउन में सही सेटिंग चुनना टीमकनेक्ट को आपकी वर्तमान कार्य स्थिति को सही ढंग से प्रतिबिंबित करने के लिए ढालता है। प्रत्येक विकल्प की विस्तृत व्याख्या के लिए जानकारी आइकन पर होवर करें:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>चेक्ड-आउट/ब्रेक:</Body1Strong> काम से समय दूर होना, चाहे वह ब्रेक के लिए हो या दिन के अंत के लिए।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ऑफिस/फील्ड-ऑफिस:</Body1Strong> कंपनी प्रबंधित कार्यस्थल में उपस्थिति दर्शाता है।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>होम-ऑफिस:</Body1Strong> एक समर्पित घरेलू कार्यस्थल से कार्य करना।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>रिमोट-कार्यस्थल/पब्लिक-एरिया:</Body1Strong> किसी गैर-ऑफिस पर्यावरण से संचालन, लचीले संचार की आवश्यकता का सुझाव देता है।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ग्राहक कार्यस्थल/निजी कार्यालय:</Body1Strong> एक ग्राहक के साथ साइट पर, वार्ता की संवेदनशीलता के लिए गोपनीयता स्तरों पर नोट के साथ।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>यात्रा/व्यवसाय यात्रा/प्रशिक्षण:</Body1Strong> चलते-फिरते या कार्यालय के बाहर विशिष्ट कार्य संबंधित गतिविधियों में संलग्न।</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>सार्वजनिक अवकाश/छुट्टी/विविध छुट्टियां:</Body1Strong> व्यक्तिगत, स्वास्थ्य, या छुट्टी कारणों के लिए आधिकारिक रूप से कर्तव्य से मुक्त।</li>
                  </ul>
                  उपयुक्त कार्यस्थल सेटिंग चुनना सुनिश्चित करता है कि आपकी टीम के पास बातचीत के लिए आवश्यक संदर्भ हो, उपलब्धता और गोपनीयता का सम्मान करते हुए।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. टीमकनेक्ट में 'चेक्ड-इन' स्थिति को नेविगेट करना
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  कर्मचारी निर्देशिका में 'चेक्ड-इन' टैग वर्तमान में कार्य पर सभी कर्मचारियों को प्रदर्शित करना डिजाइन किया गया है। यह दृश्यता आपके द्वारा किए गए कार्यस्थल चयन द्वारा निर्धारित होती है:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>सक्रिय कार्य वातावरण:</Body1Strong> ऑफिस, फील्ड-ऑफिस, होम-ऑफिस, रिमोट-कार्यस्थल, पब्लिक-एरिया, ग्राहक-कार्यस्थल,
                    ग्राहक निजी-कार्यालय, यात्रा, व्यवसाय यात्रा, और प्रशिक्षण के विकल्प आपको कार्य में संलग्न करते हैं, आपको 'चेक्ड-इन' और सहकर्मियों के लिए स्पष्ट रूप से सक्रिय बनाते हैं।
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>गैर-कार्य सेटिंग्स:</Body1Strong> चेक्ड-आउट, ब्रेक, सार्वजनिक अवकाश, छुट्टी, अवकाश पर, मातृत्व अवकाश, या सिक-लीव 
                      स्थितियों का चयन करने से आपको 'चेक्ड-इन' सूची से हटा दिया जाता है, उपलब्धता और प्रतिक्रिया समय के लिए स्पष्ट अपेक्षाएं सेट की जाती हैं।
                    </li>
                  </ul>
                  यह कार्यक्षमता कार्यकालों को व्यक्तिगत समय से अलग करने में मदद करती है, प्रEffective team collaboration and communication में सहायता करती है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>डेटा प्रबंधन और गोपनीयता संबंधी सवाल</Subtitle2>
          <Body1>
            <p>
              आज के डिजिटल युग में, यह समझना महत्वपूर्ण है कि आपका डेटा कैसे प्रबंधित किया जाता है। टीमकनेक्ट पारदर्शिता और मजबूत डेटा संरक्षण प्रथाओं के लिए प्रतिबद्ध है। 
              नीचे, आपको टीमकनेक्ट के भीतर यह जानकारी मिलेगी कि हम आपका डेटा कैसे संभालते हैं, स्टोर करते हैं और सुरक्षित रखते हैं, यह सुनिश्चित करते हुए कि आपका डिजिटल कार्यक्षेत्र सुरक्षित और अनुपालन योग्य है।
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. टीमकनेक्ट मेरे व्यक्तिगत डेटा की सुरक्षा कैसे करता है?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>टीमकनेक्ट आपकी व्यक्तिगत जानकारी की सुरक्षा के लिए एक व्यापक दृष्टिकोण के साथ समर्पित है:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>सिंगल साइन-ऑन (SSO):</Body1Strong> सुरक्षा को मजबूत बनाने और उपयोगकर्ता अनुभव को सहज करने के लिए, टीमकनेक्ट आपके माइक्रोसॉफ़्ट 365 खाते के साथ स्वचालित 
                      सिंगल साइन-ऑन (SSO) को एकीकृत करता है, सुरक्षित और सहज पहुँच प्रबंधन को सक्षम करता है।
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>एनक्रिप्शन:</Body1Strong> हम सुनिश्चित करते हैं कि टीमकनेक्ट के भीतर सभी डेटा पारगमन के दौरान और विश्राम की स्थिति में एनक्रिप्ट किये जाते हैं,
                      जिससे वे अनधिकृत पार्टियों के लिए अप्राप्य बन जाते हैं।
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>डेटा न्यूनतमीकरण:</Body1Strong> हमारा डेटा संग्रह केवल वही तक सीमित होता है जो टीमकनेक्ट के कार्यक्षमता के लिए आवश्यक है। 
                      हमारे द्वारा संग्रहित एकमात्र उपयोगकर्ता जानकारी आपका यूज़र प्रिंसिपल नाम (UPN) होता है जो एज़्योर एंट्रा ID से होता है, जो आपके कंपनी ईमेल पते की तरह दिख सकता है।
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>प्रतिबंधित पहुंच:</Body1Strong> UPN तक पहुँच हमारे सब्सक्रिप्शन पोर्टल में आपके संगठन के सब्सक्रिप्शन एडमिन द्वारा सीमित होती है,
                      यह सुनिश्चित करता है कि संवेदनशील जानकारी केवल अधिकृत व्यक्तियों को ही दिखाई देती है।
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>त्वरित डेटा विलोपन:</Body1Strong> टीमकनेक्ट तीन विशेष स्थितियों में संग्रहित UPN को तुरंत मिटाने के लिए बनाया गया है: 
                      यदि सब्सक्रिप्शन द्वारा सब्सक्रिप्शन एडमिन द्वारा रद्द किया जाता है, यदि टीमकनेक्ट के भीतर HR द्वारा एक उपयोगकर्ता को हटा दिया जाता है, या यदि टीमकनेक्ट 
                      का सब्सक्रिप्शन समाप्त हो जाता है। यह आपके व्यक्तिगत डेटा को उसकी उपयोगी जीवन या आवश्यकता से अधिक समय तक बनाए नहीं रखता है।
                    </li>                      
                  </ul>
                  <p>इन उपायों के माध्यम से, टीमकनेक्ट डेटा संरक्षण के उच्चतम मानकों को बनाए रखने के लिए प्रतिबद्ध है, यह सुनिश्चित करते हुए कि आपकी व्यक्तिगत जानकारी सुरक्षित और गोपनीय रहे।</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. आपका डेटा TeamConnect के साथ कहाँ स्थित है, इसके बारे में सोच रहे हैं?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect पारंपरिक SaaS ऑफरिंग्स के सांचे को तोड़ता है जिससे यह सुनिश्चित किया जा सके कि आपका डेटा आपकी संस्था के बाहर नहीं जाता है। 
                  बाहरी क्लाउड सर्वरों पर डेटा संग्रहीत करने की आम प्रथा के विपरीत, TeamConnect चतुराई से आपकी संस्था के Microsoft 365 SharePoint Online का उपयोग करके सभी एप्लिकेशन डेटा को संभालता है। 
                  यह नवीन दृष्टिकोण न केवल डेटा संप्रभुता की वकालत करता है बल्कि सुरक्षा को भी मजबूत बनाता है,
                  हमारी निष्ठा और कड़े अनुपालन मानकों का पालन करने के लिए सही तालमेल बिठाता है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. क्या मेरा डेटा किसी तृतीय पक्ष के साथ साझा किया जाएगा?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  नहीं, डेटा किसी तृतीय पक्ष के साथ साझा नहीं किया जाता है! Cubeet आपके डेटा की गोपनीयता और सुरक्षा के प्रति हमारी प्रतिबद्धता में दृढ़ है। 
                  हम ग्राहकों से स्पष्ट सहमति प्राप्त किए बिना किसी भी TeamConnect डेटा को तृतीय पक्ष के साथ साझा नहीं करते हैं। 
                  हमारे सुरक्षित परिवेश में, हम केवल आवश्यक पहचानकर्ता, जैसे कि यूजर प्रिंसिपल नेम (UPN), संगठन का नाम, टेनेंट का नाम, और टेनेंट आईडी, को अपने सब्सक्रिप्शन पोर्टल के भीतर संग्रहीत करते हैं। 
                  यह जानकारी सेवा प्रदान करने के लिए महत्वपूर्ण है और हमारी कड़ी गोपनीयता नीतियों के अंतर्गत सुरक्षित है।
                  आप आश्वस्त रहें, हमारी डेटा प्रबंधन प्रथाएं डिज़ाइन की गई हैं ताकि आपका डेटा गोपनीय बना रहे, पारदर्शिता और नियंत्रण के साथ आपके हाथों में मजबूती से रहे।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. TeamConnect GDPR जैसे डेटा सुरक्षा विनियमों का पालन कैसे करता है?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect GDPR, HIPAA, CCPA और अन्य जैसे डेटा सुरक्षा विनियमों के साथ संरेखन करता है, जिसमें Microsoft 365 पर्यावरण की अंतर्निहित अनुपालन सुविधाओं का लाभ उठाया जाता है, 
                  जहाँ सभी ग्राहक डेटा सुरक्षित रूप से संग्रहीत किया जाता है। पारंपरिक SaaS मॉडलों के विपरीत, TeamConnect अपने सर्वरों पर किसी भी ग्राहक डेटा को स्टोर नहीं करता है; 
                  इसके बजाय, यह आपके संगठन के Microsoft 365 पर्यावरण के भीतर काम करता है, इसके मजबूत सुरक्षा और अनुपालन मानकों का पालन करता है। 
                  यह दृष्टिकोण यह सुनिश्चित करता है कि आपके डेटा शासन Microsoft 365 द्वारा प्रदान की गई व्यापक अनुपालन ढांचे के साथ निरंतर बना रहे, 
                  जिसमें GDPR, हेल्थकेयर जानकारी के लिए HIPAA, कैलिफ़ोर्निया निवासियों के लिए CCPA,
                  और अन्य वैश्विक डेटा सुरक्षा विनियमों का पालन शामिल है। अपने मौजूदा Microsoft 365 सेटअप के साथ सहजता से एकीकृत करके, TeamConnect आपके संगठन को अपने डेटा पर पूर्ण नियंत्रण बनाए रखने की शक्ति देता है,
                  सबसे कठोर डेटा सुरक्षा कानूनों और नियमों के अनुरूपता को सुनिश्चित करता है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. टीमकनेक्ट Microsoft Teams चैट्स, कॉल्स, वीडियो कॉल्स, Outlook ईमेल, और फोन सिस्टम कॉल्स से डेटा को कैसे संभालता है?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  टीमकनेक्ट Microsoft Graph API का उपयोग करता है ताकि Microsoft Teams के भीतर चैट, कॉल्स, वीडियो कॉल्स, और Outlook ईमेल जैसी प्रत्यक्ष बातचीत को सक्षम बनाया जा सके,
                  जिससे एक सहज उपयोगकर्ता अनुभव प्रदान किया जाता है। महत्वपूर्ण रूप से, जबकि टीमकनेक्ट इन संचारों को शुरू करता है, यह बिना संचार प्रक्रिया में हस्तक्षेप किए
                  या बातचीत की सामग्री को रिकॉर्ड या संग्रहित करने की क्षमता रखे बिना करता है। सभी बातचीत, कॉल्स, और ईमेल्स सीधे Microsoft Teams और Outlook के सुरक्षित दायरे में होती हैं और प्रबंधित की जाती हैं,
                  Microsoft के 365 की सख्त सुरक्षा और गोपनीयता मानकों का पूर्ण पालन करते हुए। यह सुनिश्चित करता है कि टीमकनेक्ट आपकी संचार क्षमताओं को बढ़ाता है
                  जबकि आपके डेटा की गोपनीयता और अखंडता को पूरी तरह से बनाए रखता है।
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            हमारी डेटा नीतियों और प्रथाओं में विस्तृत अंतर्दृष्टि के लिए, आप {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>TeamConnect उपयोगकर्ता गाइड</Body1Strong></Link> या आपके संगठन के IT
            विभाग से <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> पर सलाह लेना पसंद कर सकते हैं 
              जो आपके सेटअप से संबंधित विशिष्टताएं हैं।
          </p>
        </Body1>        
        <p></p>  
        <Subtitle1 className={styles.fontColor}>हम सुन रहे हैं: अपनी प्रतिक्रिया और विचार साझा करें</Subtitle1>
        <Body1>
          <p>
            आपकी दृष्टि टीमकनेक्ट के विकास को चलाती है। चाहे आप किसी शानदार फीचर विचार के साथ उत्साहित हों या अपने वर्तमान अनुभव पर कुछ प्रतिक्रिया देना चाहते हों,
            हम आपकी सुन रहे हैं। आपकी इनपुट हमारे सतत प्रयास में अमूल्य है ताकि हम सर्वोत्तम संभव सेवा प्रदान कर सकें।
          </p>
        </Body1>
        <Subtitle2>प्रतिक्रिया और सुविधा सुझाव</Subtitle2>
        <Body1>
          <p>
            साझा करने के लिए तैयार? हमारे {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              प्रतिक्रिया पृष्ठ
            </Link>
            पर जाएं। चाहे वह एक नई सुविधा के लिए सुझाव हो या मौजूदा कार्यक्षमताओं पर प्रतिक्रिया, बस फ़ॉर्म में उपयुक्त विकल्प का चयन करें। आपके योगदान टीमकनेक्ट के भविष्य को आकार देते हैं,
            सुनिश्चित करते हैं कि यह आपकी आवश्यकताओं को पूरा करता है और उनसे अधिक है।
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
