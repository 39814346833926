import React from 'react';
import { makeStyles, shorthands, Spinner } from '@fluentui/react-components';

const useStyles = makeStyles({
  container: {
    "> div": { ...shorthands.padding("20px") },
  },
});

function Loading ({ text }) {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <Spinner labelPosition="below" label={text ? text : ''} />
    </div>
  );
};

export default Loading;