import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  
  return (
    <>
      <Title3 className={styles.fontColor}>Центр допомоги та ЧаПи TeamConnect</Title3>
        <p><Body1>
          Ласкаво просимо до Центру допомоги TeamConnect, створеного для підтримки вашого досвіду з нашим комплексним корпоративним довідником. Тут ви знайдете ресурси для оптимізації використання TeamConnect у Microsoft Teams, що покращує комунікацію у вашій мережі.
        </Body1></p>
        <Subtitle2>Короткий довідковий посібник</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Покращені можливості пошуку: </Body1Strong>
              Знайти відповідних колег, відділи та необхідні контакти швидко за допомогою наших потужних інструментів пошуку.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Візуальні організаційні діаграми: </Body1Strong>
              Орієнтуйтесь у ієрархії вашої організації та розумійте лінії звітування легко через інтуїтивно зрозумілі org charts.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Адаптивні інструменти для сучасних потреб: </Body1Strong>
              Використовуйте сучасні функціональні можливості, розроблені для вимог сучасних робочих місць.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Оптимізація вашого досвіду</Subtitle2>
        <Body1>
          <p>
            TeamConnect революціонізує те, як ви взаємодієте у вашій організаційній структурі, перетворюючи повсякденне спілкування на стратегічні зобов’язання.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Отримати допомогу та ЧаПи</Subtitle1>
        <Body1>
          <p>
            Знайдіть відповіді на поширені питання про функції та налаштування TeamConnect у цьому спеціальному розділі. Чи навігуєте ви у додатку, коригуєте налаштування чи шукаєте рішення для викликів, наш Центр допомоги готовий вам ефективно допомогти.
          </p>
        </Body1>
        <Body1>
          <p>
            Для більш глибокої підтримки або конкретних рекомендацій, будь ласка, зверніться до <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>Керівництва користувача TeamConnect</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>        
        <Subtitle2>Використання TeamConnect - часті запитання</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Не можете знайти TeamConnect у бічній панелі Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Якщо TeamConnect не відображається або зник у вашій бічній панелі Microsoft Teams, ви легко можете прикріпити його для швидкого доступу. Просто виконайте наступні кроки:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Відкрийте Microsoft Teams і натисніть на іконку '...' (+ Додатки) в нижній частині бічної панелі.</li>
                    <li className={styles.bulletBottomMargin}>Пошукайте 'TeamConnect' у рядку пошуку у верхній частині спливаючого вікна.</li>
                    <li className={styles.bulletBottomMargin}>Клацніть правою кнопкою миші по додатку TeamConnect у результатах пошуку та виберіть 'Прикріпити'.</li>
                  </ol>
                  Тепер TeamConnect буде прикріплено до вашої бічної панелі, що робить його доступним всього в один клік!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Виникають технічні проблеми з TeamConnect? Ось як отримати допомогу.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Якщо ви зіткнулися з будь-якими технічними проблемами з TeamConnect у Microsoft Teams, команда ІТ підтримки вашої організації тут, щоб допомогти.
                  Зверніться до них за адресою <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, і вони нададуть вам допомогу негайно.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Не можете знайти свої дані працівника?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Якщо ваш профіль виглядає трохи порожнім, відображаючи лише ваше ім'я та електронну адресу, ймовірно ваша команда HR ще не додала решту вашої інформації працівника до TeamConnect.
                  Не хвилюйтеся! Швидке повідомлення вашій команді підтримки HR запустить процес. Вони готові оновити ваш профіль усіма важливими деталями.
                  Зверніться за адресою <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> і спостерігайте, як ваш профіль наповнюється життям!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Які мовні варіанти доступні в TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect відображає глобальне розмаїття Microsoft Teams, підтримуючи
                  всі його мови. Чи ви віддаєте перевагу арабській чи грецькій, японській
                  або шведській, у вас є світ варіантів на вибір, включаючи:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Арабська, болгарська, китайська (спрощена та традиційна), хорватська,
                      чеська, данська, нідерландська, англійська, естонська, фінська,
                      французька, німецька, грецька, іврит, угорська, індонезійська,
                      італійська, японська, корейська, латиська, литовська, норвезька
                      (букмол), польська, португальська (Бразилія та Португалія), румунська,
                      російська, сербська (латиниця), словацька, словенська, іспанська,
                      шведська, тайська, турецька, українська, в'єтнамська.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Щоб налаштувати ваш досвід використання TeamConnect на бажану мову, будь
                  ласка, змініть мовні налаштування безпосередньо в Microsoft Teams,
                  оскільки TeamConnect повністю інтегрований з цими налаштуваннями:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Перейдіть до налаштувань Microsoft Teams, натиснувши на три крапки
                      поруч із вашим профільним зображенням угорі, а потім виберіть
                      'Зовнішній вигляд та доступність'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Прокрутіть до розділу 'Мова' і виберіть бажану мову з меню випадаючого
                      списку.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Підтвердіть ваші зміни, натиснувши кнопку 'Зберегти і перезапустити',
                      щоб застосувати нові мовні налаштування.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Примітка: </Body1Strong>
                <Body1>
                  Зміни, зроблені до мовних налаштувань у Microsoft Teams, також
                  безперебійно оновлять ваш досвід використання TeamConnect. Зверніть увагу,
                  що зміна мови в Teams може також змінити формат вашого часу та дати. В
                  даний час TeamConnect розвивається для підтримки письма справа наліво. Ми
                  раді цим поліпшенням і будемо тримати вас в курсі, коли вони стануть
                  доступні.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Як змінити тему в TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect синхронізується з вашими налаштуваннями теми Microsoft Teams, включаючи світлу, темну та висококонтрастну опції. Щоб налаштувати свою тему:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Доступ до налаштувань Microsoft Teams можна здійснити, натиснувши на три крапки поруч з вашим профільним зображенням угорі.</li>
                    <li className={styles.bulletBottomMargin}>Виберіть 'Зовнішній вигляд та доступність', щоб ознайомитися з опціями теми.</li>
                    <li className={styles.bulletBottomMargin}>Виберіть бажану тему з доступних опцій, щоб миттєво оновити інтерфейс TeamConnect відповідно.</li>
                  </ol>
                  Ваш вибір теми в Microsoft Teams буде автоматично застосований до TeamConnect, забезпечуючи узгоджений візуальний досвід по всьому вашому робочому простору.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Вивчення кнопок дії контактів в TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  По всьому TeamConnect, де ви знаходите контактні дані співробітника, ви також знайдете наші зручні кнопки швидкого доступу. Ці корисні інструменти
                  призначені для оптимізації вашого спілкування в екосистемі Microsoft, забезпечуючи, щоб ви завжди були лише на один клік від зв'язку.
                  Ось ваше повне керівництво для ефективного використання цих кнопок:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chat Icon:</Body1Strong> Натисніть, щоб розпочати миттєвий чат в Microsoft Teams. Якщо вам потрібно повернутися до TeamConnect після початку чату, просто скористайтеся стрілкою назад в додатку для настільних комп'ютерів, кнопкою назад у мобільному додатку або кнопкою назад у вашому браузері.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Email Icon:</Body1Strong> Відкриває новий чернетку електронного листа в Outlook з вже готовою адресою отримувача в полі "Кому:".</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Video Call Icon:</Body1Strong> Ініціює відеодзвінок віч-на-віч за допомогою Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Call Icon:</Body1Strong> Здійснює голосовий дзвінок через Microsoft Teams, що робить короткі обговорення плавними.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org Chart Icon:</Body1Strong> Показує місце співробітника у корпоративній ієрархії, включаючи його менеджера та прямих підлеглих.</li>
                    <li><Body1Strong>More (...) Icon:</Body1Strong> Відкриває додаткові опції:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Meet Now:</Body1Strong> Відкриває Microsoft Teams, щоб негайно почати нараду з співробітником.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>New Meeting:</Body1Strong> Направляє вас до Microsoft Teams, щоб запланувати нову зустріч.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Add Contact:</Body1Strong> Додає контактні дані співробітника з TeamConnect до вашого нового додатку Microsoft Teams People, покращуючи вашу функцію дзвінків.</li>
                      </ul>
                    </li>
                  </ul>
                  Ці інтуїтивні кнопки призначені для того, щоб ви могли ефективно зв'язуватися з колегами, чи то для швидкого чату, листа, або для планування майбутніх зустрічей.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Здійснення дзвінків через TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Бажаєте зробити дзвінок? TeamConnect оптимізує ваше спілкування, інтегруючи його з Microsoft Teams. 
                  Просто натисніть на бажаний номер телефону в TeamConnect, і ось, інтерфейс дзвінка Microsoft Teams з'являється,
                  готовий для вас до безперебійного набору.
                  <p>
                  <Body1Strong>Note: </Body1Strong> 
                  Виникають проблеми з дзвінками? Це може бути пов'язано з політикою ІТ вашої організації або використанням телефонної системи, відмінної від Microsoft Teams Phone System. Консультація з вашим ІТ-відділом за <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, може прояснити ситуацію.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Чи є витрати, пов'язані з дзвінками через TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect покращує ваш досвід телефонних дзвінків, використовуючи Microsoft Teams. Ось що вам потрібно знати про витрати на дзвінки:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Для дзвінків на робочі телефони працівників, телефони відділів або гарячі лінії у вашій організації (внутрішні номери),
                      зазвичай <Body1Strong>немає витрат</Body1Strong> при використанні телефонної системи Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Для зовнішніх дзвінків, таких як на мобільні телефони або зовнішні лінії, застосовуються <Body1Strong>стандартні тарифи</Body1Strong> телефонної системи вашої організації.
                      Ці тарифи залежать від вашого конкретного тарифного плану з Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Примітка:</Body1Strong> Якщо у вас виникають проблеми або є питання стосовно оплати дзвінків, найкраще звернутися до вашого 
                    IT відділу за адресою <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> для отримання детальної інформації, 
                    адаптованої до налаштувань та політики вашої організації.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Помічаєте невідповідність з вашим статусом "Поза офісом" у TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Зауважили, що ваш статус "Поза офісом" з Microsoft 365 не відображається правильно в TeamConnect?
                  Ми вже вирішили це питання. Функція синхронізації статусу "Поза офісом" вже впроваджена в TeamConnect.
                  Ми зараз чекаємо на впровадження цієї функції від Microsoft. Як тільки це станеться, ваш статус "Поза офісом"
                  буде точно відображений у TeamConnect, узгоджуючись із показниками присутності Microsoft Teams для безшовного досвіду.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Поширені запитання про довідник співробітників</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >Вступ до довідника співробітників:</Body1Strong> Вивчайте професійний ландшафт вашої організації за допомогою довідника співробітників,
                вашого центрального вузла для детального ознайомлення з інформацією про співробітників, динамікою роботи та прямим зв'язком з профілями колег і відносинами з менеджментом.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Потрібно додати, оновити або виправити дані про співробітника?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Для додавання, оновлення або виправлення даних співробітника в TeamConnect, будь ласка, зверніться до команди підтримки HR вашої організації.
                  Вони готові допомогти на <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Що відбувається, коли я натискаю на ім'я співробітника у довіднику?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Натискання на ім'я співробітника в довіднику співробітників TeamConnect відкриває детальну сторінку профілю цієї особи. Цей профіль надає повне 
                  розуміння про співробітника, включаючи, але не обмежуючись, його посаду, відділ, місце роботи, контактну інформацію та більше. Він призначений для того, 
                  щоб ви мали повне уявлення про професійну роль співробітника та його місце в організації, що спрощує розуміння його обов'язків та 
                  можливості співпраці з ним.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Яка інформація включена в профіль співробітника?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Кожен профіль співробітника в TeamConnect є повним знімком, який пропонує багато інформації про професійне життя особи. 
                  Ось що ви знайдете:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Основні деталі:</Body1Strong> Ім'я, посада, відділ та місце розташування філії.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Структура звітування:</Body1Strong> Інформація про прямі та функціональні звіти, 
                      включаючи лінійних та функціональних керівників.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Статус на робочому місці:</Body1Strong> Поточний статус роботи, такі як Відпустка, Поїздка, 
                      Ділова поїздка тощо.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Робоче місце та місцевий час:</Body1Strong> Поточне місце роботи співробітника та 
                      часовий пояс.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Контактні дані:</Body1Strong> Робочий телефон, мобільний номер і адреса електронної пошти.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Адреса офісу:</Body1Strong> Повна адреса місцезнаходження офісу, включаючи назву будівлі,
                      адресу та Google карту.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Про:</Body1Strong> Коротке особисте представлення або професійне біо.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Відносини з співробітниками:</Body1Strong> Деталі структури звітування та відносин з командою співробітника.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Соціальні посилання та мови:</Body1Strong> Посилання на професійні соціальні профілі 
                      та володіння мовами.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Навички:</Body1Strong> Список професійних навичок та областей експертизи.</li>
                  </ul>
                  Крім того, профіль пропонує функціональні Варіанти для зв'язку через чат, електронну пошту, відеодзвінок або планування зустрічей, а також 
                  швидкий доступ до організаційної схеми для ширшого контексту команди.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Як я можу оновити інформацію у своєму профілі?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Особистізувати ваш профіль легко у TeamConnect. Ось як ви можете це зробити:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Перейдіть на вкладку <Body1Strong>Співробітник</Body1Strong> та натисніть на ваш <Body1Strong>Аватар</Body1Strong>, щоб відкрити ваш профіль.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        У верхньому лівому кутку ви знайдете посилання <Body1Strong>"Редагувати ваш профіль"</Body1Strong>. 
                        Натискання на це посилання відкриє діалог редагування вашого профілю.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Тут ви можете покращити ваш профіль, додавши або оновивши розділ <Body1Strong>Про вас</Body1Strong>, посилання на соціальні мережі та номер телефону. 
                        Це полегшує вашим колегам зв'язок з вами або наслідування за вами.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Не забудьте натиснути кнопку <Body1Strong>Зберегти</Body1Strong>, щоб застосувати ваші зміни.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Прокрутіть діалог вниз, щоб додати ваші <Body1Strong>Мови</Body1Strong> та <Body1Strong>Професійність</Body1Strong>, 
                        а також ваші <Body1Strong>Навички</Body1Strong>. Поділ з цією інформацією допомагає вашим колегам розуміти 
                        ваші області експертизи та мови, якими ви можете спілкуватися.
                      </li>
                    </ol>
                  Оновлення вашого профілю не тільки підтримує вашу інформацію актуальною, але й сприяє кращому зв'язку в межах вашої організації.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Оновлення вашого місця роботи, робочого місця та часового поясу в TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Підтримка актуальності інформації про ваше місце роботи, робоче місце та часовий пояс є важливою для безперебійної співпраці в Microsoft Teams. 
                  Ці оновлення відображаються відразу у вашому профілі TeamConnect та списках, дозволяючи колегам швидко орієнтуватися у вашому місці роботи 
                  і кращих часах для зв'язку з вами, сприяючи роботі в команді в різних регіонах та часових поясах.
                  <p></p>
                  Готові оновити ваші дані? Це легко в TeamConnect! Перейдіть до 'Налаштування' у верхньому меню та перейдіть до 'Загальні налаштування'. 
                  Тут ви можете легко оновити ваше місце роботи та часовий пояс, а також вказати ваше робоче місце на день. Не забудьте зберегти ваші зміни, 
                  щоб тримати всіх в курсі та забезпечувати ефективну співпрацю, незалежно від вашого місцезнаходження!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Виявили щось невірне в профілі колеги?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Якщо ви виявите будь-які деталі в профілі колеги, які здаються неправильними, найкращий перший крок - дати їм дружнє попередження. Швидка бесіда або 
                  електронна пошта повинні вирішити проблему! Якщо з якоїсь причини інформація не оновлюється, не хвилюйтеся - просто відправте повідомлення вашій команді 
                  підтримки HR за посиланням <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, і вони будуть раді 
                  втрутитися і вирішити проблеми.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Розуміння груп тегів: Всі, Улюблені, Присутні на робочому місці & Нові співробітники
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Всі:</Body1Strong> Цей за замовчуванням тег показує кожного співробітника у вашій організації,
                      забезпечуючи всеохоплюючий огляд команди.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Улюблені:</Body1Strong> Клацніть тут, щоб фільтрувати ваш перегляд до тих колег, яких ви помітили
                      як 'Улюблені', полегшуючи швидке зв'язування з ключовими контактами.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Присутні на робочому місці:</Body1Strong> Цей тег вказує на співробітників, які активно працюють, відмінно
                      від простої онлайн-присутності у Microsoft Teams. Коли вони відмічені як присутні, співробітники повідомляють, що вони на роботі та діляться своїм поточним місцем роботи
                      протягом дня. Це може включати різноманітні робочі місця, такі як Офіс, Польовий офіс, Домашній офіс, Віддалене робоче місце, Робоче місце клієнта,
                      Приватний офіс клієнта, Громадська зона, Поїздка, Відрядження чи навіть Навчання. Таким чином, статус 'Присутні на робочому місці' надає ясне розуміння 
                      активної роботи, надаючи колегам точне уявлення про поточний робочий контекст та доступність кожного.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Нові співробітники:</Body1Strong> Виділіть нових осіб у TeamConnect за допомогою цього тега, підкреслюючи 
                      співробітників, які були додані до додатка протягом останніх 30 днів. Зазвичай, це відповідає нещодавнім доповненням до вашої організації, допомагаючи 
                      вам вітати нових членів команди.
                    </li>
                  </ul>
                  Ці групи тегів розроблені для спрощення навігації і підвищення вашого досвіду використання TeamConnect, гарантуючи, що ви завжди зможете
                  знайти та зв'язатися з вашими колегами ефективно.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Розшифровка іконок поруч з полем пошуку
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Employee Directory у TeamConnect розроблений для багатофункціональності та зручності, включаючи чотири іконки, які пропонують різні перегляди
                  та швидкий доступ до ключових деталей. Ось що кожна іконка відкриває:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Перегляд списком:</Body1Strong> Ваш основний режим перегляду, який представляє всіх членів організації
                      у вигляді простого списку.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Перегляд маленькими картками:</Body1Strong> Компактна альтернатива, цей режим перетворює список
                      у невеликі картки співробітників.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Перегляд великими картками:</Body1Strong> Оберіть більш деталізований режим, який включає 
                      додаткові опції контакту, такі як телефон та мобільний номери для зручного набору.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Організаційна діаграма:</Body1Strong> Візуалізуйте структуру компанії за допомогою цієї діаграми, 
                      ідеально підходить для розуміння відносин підзвітності та навігації по організаційному ландшафту.</li>
                  </ol>
                    Перемикайтеся між цими іконками, щоб знайти режим перегляду, який найкраще відповідає вашим потребам у цей момент, чи шукаєте ви швидку інформацію або більш глибокі уявлення.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Де шукати колегу швидко?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Шукаєте колегу? Просто перейдіть до поля пошуку TeamConnect, розташованого у верхній правій частині поруч із значком організаційної діаграми. 
                  Почніть вводити їхнє імя, і спостерігайте, як результати уточнюються з кожною введеною літерою. Пам'ятайте, для пошуку в TeamConnect, оминаєте панель пошуку Microsoft Teams
                  на верхній панелі– наш спеціальний пошуковий простір - це ваш вибір!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Як працює алфавітний пошук для знаходження співробітників?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Довідник співробітників TeamConnect включає зручну алфавітну функцію пошуку, що допомагає вам швидко знаходити співробітників за їхніми іменами. 
                  Просто натисніть на літеру з алфавіту, відображену вгорі довідника, і TeamConnect негайно покаже вам всіх співробітників,
                  чиї імена починаються з цієї літери. Щоб повернутися до повного списку співробітників, просто вимкніть виділену літеру. Ця інтуїтивна
                  функція робить навігацію через великі списки співробітників легкою, забезпечуючи швидкість та ефективність у знаходженні колег.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Як я можу налаштувати кількість співробітників, що відображаються на сторінці?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect дозволяє вам налаштувати, скільки профілів співробітників ви хочете бачити на одній сторінці, що спрощує навігацію по Довіднику співробітників. 
                  Внизу кожної сторінки ви знайдете опції пагінації: 10, 50, 100, 500, 1000, і 5000. За замовчуванням показано 10 профілів на сторінці. 
                  Щоб переглянути більше профілів на одній сторінці, просто виберіть бажану кількість з цих опцій. Ця функція надає вам гнучкість у тому, як ви переглядаєте
                  довідник, дозволяючи адаптувати відображення до ваших конкретних потреб або вподобань.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Як я можу сортувати інформацію про співробітників у перегляді списку?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  У перегляді списку Довідника співробітників TeamConnect у вас є можливість сортувати інформацію про співробітників за вашими потребами. Стовпці—Ім’я співробітника, 
                  Посада, Відділ, Місце в офісі, та Робоче місце—всі вони сортуються. Просто натисніть на заголовок стовпця, за яким ви хочете сортувати. Один клік сортує 
                  стовпець за зростанням, а подвійний клік—за спаданням. За замовчуванням довідник сортується за іменем співробітника в порядку зростання,
                  на основі імені. Ця функція сортування допомагає вам швидко організовувати та переглядати деталі про співробітників так, як це найкраще вам підходить,
                  чи вам потрібно знайти конкретну особу, чи потрібно групувати співробітників за певним критерієм, як-от відділ або місцеположення.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Питання відділу каталогів</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Вступ до каталогу відділів:</Body1Strong> Каталог відділів є вашим входом до розуміння складної структури вашої
              організації, надаючи чітке уявлення про ієрархії відділів, керівництво менеджерів та шляхи для безперебійного спілкування між відділами.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Як працюють теги 'Всі', 'Обрані' та 'Ваш відділ' у Довіднику відділів?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  У Довіднику відділів TeamConnect теги допомагають швидко навігувати та фільтрувати інформацію про відділи. Ось що представляє кожен тег:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Всі:</Body1Strong> Цей тег відображає всі відділи у вашій організації, забезпечуючи 
                      повний огляд.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Обрані:</Body1Strong> Використовуйте цей тег, щоб переглядати кураторський список відділів, які ви відзначили 
                      як 'Обрані', що полегшує доступ до відділів, з якими ви часто взаємодієте.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ваш відділ:</Body1Strong> Цей тег фільтрує відображення, показуючи лише ваш відділ,
                      дозволяючи швидко отримати доступ до інформації та оновлень вашого відділу.</li>
                  </ul>
                  Ці теги призначені для спрощення вашої навігації в каталозі відділів, підвищуючи вашу здатність знаходити та взаємодіяти з інформацією відділів ефективно.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Розуміння переглядів Довідника відділів у TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Навігація в каталозі відділів у TeamConnect оптимізована за допомогою кількох опцій перегляду, які відповідають вашим перевагам. Кожен перегляд пропонує 
                  унікальний спосіб вивчення повної інформації про відділи, включаючи назву відділу, керівника відділу, материнський відділ, телефон відділу, 
                  гарячу лінію відділу та електронну пошту відділу. Ось огляд доступних переглядів:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Перегляд списку:</Body1Strong> Стандартне налаштування, яке акуратно перелічує всі відділи, надає 
                      чіткий, стислий огляд.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Перегляд великої картки:</Body1Strong> Перейдіть до цього перегляду, щоб побачити детальні картки відділів, які пропонують 
                      розширене резюме ключової інформації кожного відділу.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Перегляд організаційної схеми:</Body1Strong> Для візуального представлення структури відділів та 
                      відносин, організаційна схема ілюструє, як кожен відділ вписується у більшу організаційну ієрархію.
                    </li>
                  </ul>
                  Щоб перемикатися між цими переглядами, просто використовуйте значки поруч з полям для пошуку у верхній частині каталогу відділів. Кожен перегляд розроблений для того, щоб ви могли 
                  легко отримувати доступ до деталей відділу та розуміти їх, забезпечуючи знаходження потрібної інформації у форматі, який найкраще вам підходить.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Як користуватися пошуковим полем у довіднику відділів
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Легко знайдіть будь-який відділ у TeamConnect за допомогою спеціального пошукового поля, розташованого поруч із значками перегляду у верхній частині 
                  довідника відділів. Просто почніть вводити назву відділу, який шукаєте, і спостерігайте, як довідник адаптується, відображаючи лише 
                  відповідні результати. Ця функція спеціально розроблена, щоб допомогти вам уникнути необхідності вручну переглядати весь довідник, приносячи 
                  необхідний вам відділ прямо під ваші пальці. Пам'ятайте, ця функціональність пошуку унікальна для довідника відділів TeamConnect, тому обов'язково 
                  користуйтесь цим пошуковим полем, а не основним пошуком Microsoft Teams у самому верху інтерфейсу Teams для запитів, специфічних для відділів.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Як працює алфавітний фільтр у довіднику відділів?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Оптимізуйте свій пошук у довіднику відділів за допомогою алфавітного фільтру, розташованого у верхній частині. Ця функція дозволяє вам швидко 
                  звузити перелік відділів, вибравши літеру. Після вибору літери, TeamConnect фільтрує та відображає лише ті відділи, назви яких починаються 
                  з вибраної вами літери, роблячи пошук більш зручним без необхідності прокручування всього списку. Щоб повернутися до повного перегляду відділів, 
                  просто скасуйте виділення виділеної літери. Ця інтуїтивно зрозуміла навігація за алфавітом забезпечує ефективне виявлення будь-якого відділу 
                  за його першою літерою.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Як я можу зв'язатися з менеджером відділу?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Зв'язатися з менеджером відділу в TeamConnect просто. Просто знайдіть відділ, який вас цікавить, та натисніть на ім'я менеджера. Ця 
                  дія викличе спливаюче вікно, яке відображає опції зв'язку з менеджером за допомогою знайомих іконок швидкого доступу:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Іконка чату:</Body1Strong> Щоб розпочати миттєвий обмін повідомленнями через чат Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Іконка електронної пошти:</Body1Strong> Щоб відкрити новий лист, адресований їм у Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Іконка відеодзвінка:</Body1Strong> Щоб ініціювати відеодзвінок через Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Іконка дзвінка:</Body1Strong> Щоб почати голосовий дзвінок за допомогою Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Іконка більше (...):</Body1Strong> Для додаткових опцій, таких як планування зустрічі або додавання 
                      їхньої контактної інформації до вашого списку Teams.
                    </li>
                  </ul>
                  Ці іконки забезпечують безшовний спосіб звернення до менеджерів відділів безпосередньо з TeamConnect, гарантуючи ефективне спілкування в межах 
                  вашої організації.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Як можу переглянути або зв’язатися з співробітниками у відділі?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Щоб дізнатися, хто є хто в певному відділі в TeamConnect, зверніть увагу на три крапки поруч з назвою відділу. Клікнувши на них, ви побачите 
                  додаткові варіанти дій:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Переглянути співробітників цього відділу:</Body1Strong> Виберіть цей варіант, щоб 
                      показати перелік усіх співробітників, які асоційовані з відділом. Кількість біля цього варіанту вказує на загальну кількість співробітників у 
                      відділі, даючи швидкий облік.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Організаційна структура відділу:</Body1Strong> Якщо ви хочете зрозуміти ієрархію 
                      відділу, виберіть 'Організаційна структура відділу', щоб показати структурну схему, яка демонструє зв’язки між батьківськими відділами, 
                      обраним відділом та будь-якими підвідділами. Це візуальне представлення допоможе вам навігувати та розуміти організаційну структуру, що 
                      полегшує ідентифікацію та звернення до конкретних членів відділу.
                    </li>
                    <li><Body1Strong>Відділ {'>'} Співробітник:</Body1Strong> Після перегляду співробітників відділу, скористайтеся цим шляхом навігації, щоб 
                      легко повернутися до списку відділів, забезпечуючи плавний досвід дослідження в TeamConnect.
                    </li>
                  </ul>
                  Ці можливості дозволяють вам не тільки бачити перелік співробітників в певному відділі, але й надають шлях для прямого зв’язку з ними через 
                  комунікаційні засоби TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Вивчення деталей відділу в TeamConnect більш глибоко
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Заглибтеся в деталі будь-якого відділу одним кліком на його назву в TeamConnect. Розгортається детальний діалог, який пропонує багатий спектр 
                  інформації, що малює повну картину ролі та ресурсів відділу в вашій організації. Ви знайдете все від керівництва і фінансових ідентифікаторів до 
                  каналів комунікації та соціальних зв’язків. Ця можливість є вашим шлюзом до:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Серцебиття відділу – його мета, люди та показники ефективності.</li>
                    <li className={styles.bulletBottomMargin}>Контактні шляхи – прямі лінії до ключових фігур відділу та платформ комунікації.</li>
                    <li className={styles.bulletBottomMargin}>Організаційна мережа – розуміння того, як відділ інтегрується з ширшою структурою компанії.</li>
                  </ul>
                  Чи то швидке запитання, чи глибоке занурення в дані відділу, TeamConnect оснащує вас інструментами для зв’язку та розуміння унікальних 
                  екосистем всередині вашої організації.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Цікаво, чому в TeamConnect немає адрес відділів?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Пошук місцезнаходження відділу іноді може здаватися як полювання за скарбами без карти. У TeamConnect зазвичай ви не знайдете окремої адреси 
                  для кожного відділу. Ось чому ця картографічна деталь часто опускається:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Кілька місць:</Body1Strong> Багато відділів не обмежені одним простором; вони 
                      процвітають у різних офісах та географіях.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Змінна структура:</Body1Strong> Організаційний ландшафт постійно змінюється, 
                      з відділами, що еволюціонують, злиттюєм, або навіть розчиняєм у згоду з стратегічними змінами.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Міжвідділова співпраця:</Body1Strong> Сучасні робочі простори наповнені 
                      співпрацею, часто поєднуючи таланти з різних відділів у спільних просторах.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Культура дистанційної роботи:</Body1Strong> З гнучкістю дистанційної роботи відділи
                      не завжди мають якір у фізичних офісних локаціях.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Юридичні нюанси:</Body1Strong> Відділи зазвичай обертаються навколо юридичної 
                      структури компанії, а не мають власних окремих адрес.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Операційне спрощення:</Body1Strong> Зосередження уваги на динамічній командній 
                      роботі, а не на статичних адресах, допомагає підтримувати оперативну гнучкість.
                    </li>
                  </ul>
                  Все це про прийняття безкордонного підходу до командної роботи та ефективності. TeamConnect відображає цю філософію, акцентуючи зв’язки
                  замість локацій.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Чи відсутня або застаріла інформація у вашому департаменті?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Редактори департаменту призначені у вашій організації, щоб додавати, оновлювати або коригувати специфічну для департаменту інформацію в TeamConnect.
                  Якщо ви не впевнені, хто є редактором вашого департаменту, або якщо певна інформація потребує втручання команди підтримки HR,
                  будь ласка, зверніться за порадою до <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>ЧаПи про каталог офісів</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Вступ до каталогу офісів:</Body1Strong> Відкрийте для себе основу фізичної присутності вашої організації через каталог офісів,
                 який охоплює основні деталі офісу, специфіки операцій, і повний перегляд департаментів та персоналу, котрі визначають кожне місце.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Орієнтування за допомогою міток у каталозі офісів
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  З легкістю орієнтуйтеся у Каталозі офісів за допомогою нашої системи міток, призначеної для швидкого доступу до потрібного місця. Ось як кожна мітка може допомогти поліпшити ваш пошук:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Усі:</Body1Strong> Відображає повний список офісів, надаючи панорамний вигляд 
                      місць розташування вашої організації.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Улюблені:</Body1Strong> Допомагає вам зосередитися на тих офісах, які ви часто відвідуєте або за якими потрібно уважно стежити, 
                      позначивши їх як 'Улюблені'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ваш офіс:</Body1Strong> Ця особиста мітка фільтрує довідник, щоб показати вам місце розташування офісу,
                      де ви перебуваєте, тримаючи вашу домашню базу під рукою.
                    </li>
                  </ul>
                  Чи ви бронюєте конференц-залу, або плануєте візит, ці мітки пропонують персоналізований та спрощений спосіб взаємодії з географічним ландшафтом вашої організації.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Опанування переглядів каталогу офісів у TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Зануртеся в організаційну структуру різних локацій вашої компанії за допомогою переглядів каталогу офісів TeamConnect. Кожен перегляд створений для сприяння
                  унікальному аспекту навігації та розуміння офісу:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Перегляд списком:</Body1Strong> Стандартне налаштування, яке акуратно організовує всі локації офісів у
                      зрозумілий список.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Перегляд великою карткою:</Body1Strong> Перегляд великою карткою у каталозі офісів збагачує ваш
                      перегляд детальною презентацією кожного офісу. Працюючи з карткою офісу, ви знайдете багато інформації та доступні опції
                      у меню "Ще (...)":
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Перегляд відділів:</Body1Strong> Прямий перехід до списку
                          всіх відділів, розташованих у офісі.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Перегляд працівників:</Body1Strong> Доступ до повного списку працівників, призначених для 
                          офісу, що сприяє прямому контактові або дослідженню персоналу офісу.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Організаційна схема бізнесу:</Body1Strong> Візуалізація місця офісу в загальному 
                          організаційному контексті для чіткого розуміння ієрархічних структур.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Основні контакти:</Body1Strong> Ідентифікація ключових контактів офісу для оптимізації 
                          комунікації.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Державні свята офісу:</Body1Strong> Отримання інформації про специфічні для офісу державні 
                          свята, щоб планувати ваш графік відповідно.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Робочі години офісу:</Body1Strong> Перегляд годин роботи офісу, щоб узгодити ваші 
                          візити або дзвінки з їхнім активним часом.
                        </li>
                      </ol>
                      Цей перегляд забезпечує не тільки візуальний та інформаційний моментальний знімок, але й швидкий доступ до глибших відомостей та безпосередніх дій, пов’язаних із офісом, все акуратно розміщене під опцією "Ще (...)" для вашої зручності.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Перегляд організаційної схеми:</Body1Strong> Візуалізація ієрархічної структури головних та 
                      підпорядкованих офісів, щоб зрозуміти взаємозв'язок різних локацій офісу у вашій організації.
                    </li>
                  </ul>
                  Перемикайтеся між цими переглядами, використовуючи значки поруч із полем пошуку, щоб налаштувати ваш досвід перегляду відповідно до ваших поточних потреб.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Швидкий пошук офісів з функцією пошуку TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Зосередження на правильному місцезнаходженні офісу в TeamConnect стає простим завдяки спеціальній функції пошуку. Розташований на видному місці поруч з 
                  значками перегляду, поле пошуку є вашим інструментом для визначення офісів за назвою. Коли ви вводите, каталог уточнює відображення, щоб показувати тільки офіси, які відповідають 
                  вашому запиту, оптимізуючи процес пошуку. Важливо використовувати це поле пошуку в TeamConnect для найточніших та найрелевантніших результатів,
                  на відміну від загального пошуку в Microsoft Teams. З цією цілеспрямованою функціональністю пошуку ви можете швидко перейти до будь-якого офісу в мережі вашої 
                  організації.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Навігація каталогом офісів за алфавітом
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Якщо ви віддаєте перевагу пошуку за алфавітом, наш алфавітний фільтр є швидким способом знайти офіс за назвою. На верхній частині каталогу офісів ви побачите літери від А до Я. Клацання на літеру фільтрує перегляд, щоб показувати лише ті офіси, які починаються з цієї літери, спрощуючи процес пошуку. Щоб скинути і знову побачити всі офіси, просто зніміть вибір активної літери. Ця функція особливо корисна, коли ви знаєте назву офісу, але потрібен швидкий спосіб його знайти у TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Відкриття докладних профілів офісів у TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Вирушайте в дослідницьку подорож у саме серце фізичної присутності вашої організації завдяки профілям офісів TeamConnect. 
                  Клацання на назву офісу відкриває яскравий діалог профілю, переповнений вкладками, повними детальних відомостей про офіс:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Огляд:</Body1Strong> Зануртесь у суть ідентичності офісу з докладними 
                      описами, від структури бізнесу до годин роботи та місцевого часу, вихоплюючи сутність офісного середовища.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Важливі контакти:</Body1Strong> Отримайте доступ до критичних контактів для екстрених служб та 
                      зовнішньої підтримки, кожен клік через систему телефонії Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Контакти офісу:</Body1Strong> Легко звертайтеся до внутрішніх служб підтримки з 
                      контактними даними та можливостями безпосереднього зв'язку прямо на ваших підказках.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Державні свята:</Body1Strong> Стежте за специфічними для офісу святами, переконуючись, що ваші 
                      плани узгоджуються з місцевими спостереженнями та робочими графіками.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Робочі години:</Body1Strong> Плануйте свої взаємодії з точним знанням, коли 
                      офіс кипить активністю або закінчує день.
                    </li>
                  </ul>
                  Чи то метушлива енергія Остіна, чи стратегічний вузол Сінгапуру, кожна вкладка пропонує шлюз для розуміння та зв'язку з різноманітними 
                  місцями, що складають глобальну присутність вашої компанії.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Дослідження динаміки відділів в офісі
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Вивчення професійної мережі відділів офісу відбувається легко в TeamConnect. Ось як поглибитися в деталі:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Переглянути відділи в цьому офісі:</Body1Strong> Відкрийте для себе всі відділи, які 
                      формують структуру офісу, обравши цей варіант із додаткового меню (...).
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Організаційний огляд:</Body1Strong> Клікніть на будь-який відділ, щоб показати його 
                      структурну схему для візуального представлення його структури, з суцільним шляхом, який вас веде: Офіс {'>'} Відділ {'>'} Схема.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Поглиблене дослідження відділу:</Body1Strong> Далі дослідіть відділ, клікнувши на 
                      його додаткове меню (...) поруч з назвою відділу. Тут ви можете переглянути список всіх співробітників цього відділу, прямо в контексті офісу.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Навігація за допомогою суцільного шляху:</Body1Strong> Плавно переходьте рівнями. Від 
                      співробітників назад до відділів або зі структурної схеми. Суцільний шлях (Офіс {'>'} Відділ {'>'} Співробітник {'>'} Схема) тримає ваш 
                      маршрут інтуїтивним та на правильному шляху.
                    </li>
                  </ul>
                  Складні шляхи TeamConnect запрошують вас досліджувати, розуміти та залучатися до кожного шару відділів вашого офісу, надаючи вам 
                  всеосяжне управління навігацією.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Зв’язок з персоналом офісу в TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Цікаво, хто працює в офісі? Відповідь всього лише в один клік у TeamConnect. Взаємодійте з еліпсисом (три крапки) поруч із обраною 
                  назвою офісу, щоб відкрити набір інтерактивних опцій:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Переглянути співробітників у цьому офісі:</Body1Strong> Виявіть список всіх професіоналів,
                      які вважають цей офіс своєю робочою домівкою. Вказане число дозволяє швидко підрахувати співробітників, запрошуючи вас заглибитися у спільноту офісу.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Варіанти зв’язку:</Body1Strong> Окремі записи співробітників оснащені піктограмами швидких дій,
                      які дозволяють ініціювати чати, дзвінки або навіть відеоконференції прямо з TeamConnect, сприяючи безперервному міжофісному спілкуванню.
                    </li>
                    <li><Body1Strong>Навігація:</Body1Strong> Щоб повернутися до ширшого списку офісів зі списку співробітників, просто клікніть 'Офіс' у 
                      суцільному шляху (Офіс {'>'} Співробітник) і ви повернетеся до огляду офісу.
                    </li>
                  </ul>
                  З цими функціями, TeamConnect робить простим візуалізацію розподілу робочої сили між офісами і легко встановлює зв'язок, 
                  підтримуючи зв’язане робоче місце, незалежно від відстані.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Чи відсутня або застаріла інформація про ваш офіс?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Оновлення інформації про офіс у TeamConnect можуть виконувати призначені редактори офісу. Якщо ви не впевнені щодо редакторів офісу або 
                  потребуєте допомоги з інформацією, яка належить до компетенції відділу кадрів, команда підтримки відділу кадрів готова 
                  допомогти за адресою <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Часті запитання щодо налаштувань</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Важливість точних загальних налаштувань у TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  В динамічній робочій атмосфері сьогоднішнього дня підтримувати зв’язок і координацію є важливіше, ніж коли-небудь. Ось чому оновлення вашої 
                  Загальні налаштування в TeamConnect відіграють ключову роль:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Вибір місця роботи:</Body1Strong> Відобразіть особливості вашого робочого середовища, оновивши ваше Місце роботи, щоб воно відповідало вашій 
                        поточній ситуації, чи то перехід із тиші домашнього офісу до гамору основного офісу, або освоєння громадської зони. 
                        Ця ясність у вашому робочому місцезнаходженні дозволяє колегам не тільки знати найкращі способи з вами зв’язатися, але й розуміти, 
                        які розмови вони можуть з вами вести, що захищає конфіденційність особливо чутливих обговорень, особливо коли ви знаходитесь у відкритому 
                        просторі офісу клієнта або сидите в залі аеропорту.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Уточнення робочого місця:</Body1Strong> Поділення вашого міста роботи робить більше, ніж просто звужує відстані в команді, яка працює дистанційно—це також 
                        відкриває можливості для спонтанних професійних зустрічей або неформального спілкування. Чи то швидка кава, спокійний обід, чи спільна 
                        зустріч, знання того, що ви знаходитесь у тому ж місті, може перетворити звичайний день на можливість для значущого особистого контакту. Це не 
                        лише зміцнює командні зв'язки, але і дає глибше розуміння місцевих нюансів, які можуть впливати на вашу роботу.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Точність часового поясу:</Body1Strong> Коли команди охоплюють континенти, забезпечення відображення вашого часового поясу, 
                      який відповідає вашому поточному місцю, є суттєвим для гармонізації спілкування. Місцевий час у вашому профілі TeamConnect безпосередньо залежить від цього налаштування, 
                      усуваючи будь-які здогадки для колег, які намагаються з вами зв’язатися.
                    </li>
                  </ul>
                  Зайдіть у 'Налаштування' {'>'} 'Загальні налаштування' в TeamConnect, щоб уточнити ці деталі. Кілька хвилин, проведених на оновлення ваших установок, можуть 
                  значно підвищити згуртованість і ефективність вашої співпраці на всіх рівнях.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Розшифровка вибору робочого місця в TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Вибір правильного налаштування в розкривному меню «Робоче місце» налаштовує TeamConnect для точного відображення вашого поточного статусу роботи. Наведіть курсор на іконку інформації для отримання детального пояснення кожного варіанта:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Break:</Body1Strong> Вказує на час, коли ви не працюєте, чи це перерва, чи кінець дня.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office/Field-Office:</Body1Strong> Вказує на присутність у приміщенні, яке управляється компанією.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> Робота з домашнього офісу.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Public-Area:</Body1Strong> Робота з неконторського середовища, що вимагає гнучкого зв'язку.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Customer Workplace/Private Office:</Body1Strong> На місці з клієнтом, з примітками про рівні конфіденційності для чутливості розмови.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Commute/Business-Travel/Training:</Body1Strong> У дорозі або залучений у конкретну робочу діяльність поза офісом.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Public-Holiday/Vacation/Various Leaves:</Body1Strong> Офіційно не на службі з особистих, здоров'я або святкових причин.</li>
                  </ul>
                  Вибір відповідного робочого середовища забезпечує, що ваша команда має контекст, необхідний для взаємодії, з повагою до доступності та конфіденційності.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Орієнтація за статусом 'Checked-In' в TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Тег 'Checked-In' в каталозі співробітників призначений для відображення всіх працівників, які зараз на роботі. Ця видимість визначається вибором робочого місця, який ви робите:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Active Work Environments:</Body1Strong> Варіанти Office, Field-Office, Home-Office, Remote-Workplace, Public-Area, Customer-Workplace, 
                    Customer Private-Office, Commute, Business-Travel, та Training свідчать про вашу залученість у роботу, що робить вас 'Checked-In' та видимим для колег.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Non-Work Settings:</Body1Strong> Вибір Checked-Out, Break, Public-Holiday, Vacation, On-Leave, Maternity-Leave, чи Sick-Leave 
                      видаляє вас зі списку 'Checked-In', встановлюючи чіткі очікування щодо доступності і часу відповіді.
                    </li>
                  </ul>
                  Ця функціональність допомагає розмежувати періоди роботи від особистого часу, сприяючи ефективній колаборації та комунікації в команді.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Часті запитання щодо управління даними та конфіденційності</Subtitle2>
          <Body1>
            <p>
              У сучасну цифрову епоху розуміння того, як управляються ваші дані, є життєво важливим. TeamConnect підтримує прозорість і дотримується надійних практик захисту даних. 
              Нижче ви знайдете інформацію про те, як ми обробляємо, зберігаємо і захищаємо ваші дані в TeamConnect, забезпечуючи безпеку та відповідність вашого цифрового робочого простору.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Як TeamConnect захищає мої особисті дані?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect присвячений захисту вашої особистої інформації за допомогою всебічного підходу до безпеки даних:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Однофакторна автентифікація (SSO):</Body1Strong> Для підвищення безпеки і оптимізації користувацького досвіду, TeamConnect інтегрує автоматизовану 
                      однофакторну автентифікацію (SSO) з вашим обліковим записом Microsoft 365, забезпечуючи безпечне і легке управління доступом.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Шифрування:</Body1Strong> Ми забезпечуємо, що всі дані в TeamConnect зашифровані як під час передачі, так і в режимі очікування, 
                      роблячи їх недоступними для несанкціонованих сторін.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Мінімізація даних:</Body1Strong> Наш збір даних строго обмежений тим, що є незамінним для функціональності TeamConnect. 
                      Єдина інформація про користувача, яку ми зберігаємо, - це ваше Унікальне Ім'я Принципала (UPN) з Azure Entra ID, яке може виглядати як ваша корпоративна електронна адреса.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Обмежений доступ:</Body1Strong> Доступ до UPN обмежений Адміністратором Підписки вашої організації в нашому порталі підписок, 
                      забезпечуючи видимість конфіденційної інформації тільки для уповноважених осіб.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Швидке видалення даних:</Body1Strong> TeamConnect структурований так, щоб негайно видалити збережений UPN за трьома конкретними умовами: 
                      якщо підписка скасовується Адміністратором Підписки, якщо користувача видаляє HR в рамках TeamConnect, або якщо підписка TeamConnect закінчується. Це забезпечує, що ваші особисті дані не зберігаються довше, ніж це необхідно.
                    </li>                      
                  </ul>
                  <p>За допомогою цих заходів, TeamConnect прагне підтримувати найвищі стандарти захисту даних, забезпечуючи, щоб ваша особиста інформація залишалася в безпеці та конфіденційно.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Цікаво, де зберігаються ваші дані з TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect виходить за рамки традиційних SaaS пропозицій, забезпечуючи незалишення вашої організацією вашої інформації. 
                  На відміну від звичного зберігання даних на зовнішніх хмарних серверах, TeamConnect кмітливо використовує Microsoft 365 SharePoint Online вашої організації 
                  для зберігання всіх даних додатків. Цей новаторський підхід не тільки підтримує суверенітет даних, але й зміцнює безпеку, 
                  ідеально відповідаючи нашій непохитній відданості захисту вашої конфіденційності та дотриманню жорстких стандартів відповідності.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Чи будуть мої дані передані будь-яким третім сторонам?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ні, дані не передаються третім сторонам! Cubeet неухильно дотримується наших зобов’язань щодо конфіденційності та безпеки ваших даних. Ми не передаємо ніяких даних TeamConnect третім сторонам без отримання явної згоди від наших клієнтів.
                  У нашому безпечному середовищі ми зберігаємо лише основні ідентифікатори, такі як ім'я User Principal (UPN), назва організації, назва орендаря та ідентифікатор орендаря, у нашому порталі підписки.
                  Ця інформація важлива для надання послуг і захищена за нашими жорсткими політиками конфіденційності.
                  Заспокойтеся, наші практики керування даними розроблені так, щоб забезпечити конфіденційність ваших даних, з прозорістю і контролем, що міцно залишаються в вашому распорядженні.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Як TeamConnect відповідає регуляціям захисту даних, таким як GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect відповідає регуляціям захисту даних, таким як GDPR, HIPAA, CCPA та іншим, використовуючи властиві можливості відповідності середовища Microsoft 365, 
                  де всі дані клієнтів безпечно зберігаються. На відміну від традиційних моделей SaaS, TeamConnect не зберігає жодних даних клієнтів на своїх серверах; натомість він працює в середовищі 
                  Microsoft 365 вашої організації, дотримуючись його міцних стандартів безпеки та відповідності. Цей підхід забезпечує, що ваше керування даними залишається узгодженим з 
                  всеосяжним рамковим документом відповідності, який надає Microsoft 365, зокрема дотримання GDPR, HIPAA для медичної інформації, CCPA для мешканців Каліфорнії, 
                  та іншими глобальними регуляціями захисту даних. Інтегруючись безперебійно з вашим існуючим налаштуванням Microsoft 365, TeamConnect надає вашій організації можливість 
                  підтримувати повний контроль над вашими даними, забезпечуючи відповідність найсуворішим законам і регуляціям про захист даних.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Як TeamConnect обробляє дані з чатів, дзвінків, відеодзвінків у Microsoft Teams, електронних листів Outlook та дзвінків телефонної системи?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect використовує Microsoft Graph API для забезпечення прямих взаємодій, таких як чат, дзвінки, відеодзвінки та електронна пошта Outlook у Microsoft Teams, 
                  забезпечуючи безшовний досвід користувача. Важливо, що, починаючи ці спілкування, TeamConnect робить це, не втручаючись в процес комунікації 
                  та не маючи можливості записувати або зберігати будь-який зміст взаємодії. Усі розмови, дзвінки та електронні листи відбуваються і керуються безпосередньо в 
                  безпечних умовах Microsoft Teams та Outlook, у строгій відповідності до надійних стандартів безпеки і конфіденційності Microsoft 365. Це забезпечує, що TeamConnect покращує 
                  ваші комунікаційні можливості, при цьому повністю зберігаючи конфіденційність і цілісність ваших даних.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Для детального ознайомлення з нашими політиками та практиками у сфері даних, ви також можете проконсультуватися з {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>Посібником користувача TeamConnect</Body1Strong></Link> або з IT-відділом вашої організації за адресою <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> для уточнення деталей, що стосуються вашої конфігурації.
          </p>
        </Body1>        
        <p></p>  
      <Subtitle1 className={styles.fontColor}>Ми слухаємо: діліться своїми враженнями та ідеями</Subtitle1>
        <Body1>
          <p>
            Ваші ідеї стимулюють еволюцію TeamConnect. Чи переповнені ви блискучою ідеєю для функції або маєте відгук про поточний досвід, ми завжди вас слухаємо. Ваша думка є незамінною в нашому постійному прагненні надати 
            найкращий можливий сервіс.
          </p>
        </Body1>      
      <Subtitle2>Відгуки та пропозиції функцій</Subtitle2>
        <Body1>
          <p>
            Готові поділитися? Перейдіть на нашу {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              сторінку відгуків
            </Link>
            . Чи це пропозиція нової функції або відгук про існуючі функціональності, просто виберіть 
            відповідний варіант із випадаючого списку у формі. Ваші внески допомагають формувати майбутнє TeamConnect, 
            забезпечуючи відповідність і перевищення ваших потреб.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
