import React, { useState, useEffect } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { PageLayout } from '../../layouts/PageLayout';
import { loginRequest } from '../../config/authConfig';
import { getMeInfo } from '../../services/authService';
import { addLogin } from '../../services/loginService';
import { Loading } from '../../components';
import { Body1, Body1Strong, Subtitle1 } from "@fluentui/react-components";
import { SettingInfo } from '../../components';
import './subscription.css';
import Footer from '../../components/footer';
import Table from './table';

const ListContent = () => {
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(true);
  const [meProfile, setMeProfile] = useState(null);
  const [token, setToken] = useState(null);
  
  const requestProfileData = async (inst, acct) => {
    try 
    {
      setIsLoading(true);

      const resAccess = await inst.acquireTokenSilent({
        ...loginRequest,
        account: acct,
      });

      const { accessToken } = resAccess;
      setToken(accessToken);
      
      const resMe = await (getMeInfo(accessToken));
      setMeProfile(resMe);

      await addLogin(resMe);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    };
  };

  useEffect(() => {
    const runApp = async () => {
      if (instance && Array.isArray(accounts) && accounts.length) {
        const account = accounts[0];
        return requestProfileData(instance, account);
      };
    };
    
    if (instance && accounts) runApp();
  }, [instance, accounts]);

  return (
    <>
      <Subtitle1 className="card-title">Welcome {accounts[0].name}</Subtitle1>
      <br/>
      <p>{meProfile?.userPrincipalName}</p>

      {
        <div style={{ marginTop: '20px', marginBottom: '20px', marginLeft: '20px', marginRight: '20px' }}>
          <SettingInfo token={token} />
        </div>
      }

      {
          (isLoading || !meProfile || !token) 
        ? <Loading text={'Initializing...'} />
        : null
      }

      <br/>
      {
          (!isLoading && meProfile && token) 
        ? <Table token={token} />
        : null
      }
    </>
  );
};

/**
* If a user is authenticated the ListContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
*/
const MainContent = () => {
  return (
    <div className='subscription'>
      <AuthenticatedTemplate>
        <ListContent />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Body1>
          <center>
            Please sign in with your <Body1Strong>Microsoft Admin Account</Body1Strong>.
          </center>
        </Body1>
      </UnauthenticatedTemplate>
      <Footer />
    </div>
  );
};

export default function App() {
  return (
    <PageLayout>
      <center>
        <MainContent />
      </center>
    </PageLayout>
  );
};
