import React from 'react';
import { Body1 } from '@fluentui/react-components';
import { MailRegular } from '@fluentui/react-icons';

const Footer = function() {  
  return (
    <footer style={{ 
      position: 'fixed',
      left: 0,
      bottom: 0, 
      width: '100%',
      backgroundColor: '#151b2b', 
      textAlign: 'left', 
      color: 'white', 
      padding: '10px 20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}>
      <div style={{ alignItems: 'center' }}>
        <a
          className="App-link"
          href="https://cubeet.ai"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'white', textDecoration: 'none', alignItems: 'center' }}
        >
          <Body1>Cubeet Pte. Ltd. © 2024</Body1>
        </a>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <a
          href="mailto:support.teamconnect@cubeet.ai"
          style={{ color: 'white', textDecoration: 'none', alignItems: 'center' }}
        >
          <MailRegular style={{ height:'18px', width:'18px', marginBottom:'2px', marginRight: '6px' }} />
          <Body1 style={{ marginRight: '12px' }}>Support</Body1>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
