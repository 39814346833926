import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();

  return (
    <>
      <Title3 className={styles.fontColor}>TeamConnect hjelpesenter og FAQ</Title3>
        <p><Body1>
          Velkommen til TeamConnect hjelpesenter, designet for å støtte din erfaring med vår omfattende bedriftskatalogapp. Her vil du finne ressurser for å optimalisere din bruk av TeamConnect innen Microsoft Teams, og øke kommunikasjonen på tvers av nettverket ditt.
        </Body1></p>
        <Subtitle2>Hurtigreferanseguiden</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Forbedret søkefunksjonalitet: </Body1Strong>
              Finn de riktige kollegaene, avdelingene og essensielle kontakter raskt med våre kraftige søkeverktøy.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Visuelle organisasjonskart: </Body1Strong>
              Naviger organisasjonens hierarki og forstå rapporteringslinjer enkelt gjennom intuitive organisasjonskart.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Tilpasningsdyktige verktøy for dagens behov: </Body1Strong>
              Bruk moderne funksjoner skreddersydd for kravene til moderne arbeidsplasser.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Optimaliser din erfaring</Subtitle2>
        <Body1>
          <p>
            TeamConnect revolusjonerer hvordan du samhandler innen din organisasjonsstruktur, og gjør hverdagslig kommunikasjon til strategiske engasjementer.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Få hjelp & FAQ</Subtitle1>
        <Body1>
          <p>
            Finn svar på vanlige spørsmål om TeamConnects funksjoner og innstillinger i denne dedikerte seksjonen. Enten du navigerer i appen, tilpasser innstillinger, eller søker løsninger på utfordringer, er vårt hjelpesenter her for å assistere deg effektivt.
          </p>
        </Body1>
        <Body1>
          <p>
            For mer inngående støtte eller spesifikk veiledning, vennligst konsulter <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnect brukerveiledning</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        
        <Subtitle2>Bruker støttespørsmål for TeamConnect</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Finner ikke TeamConnect i sidelinjen til Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hvis TeamConnect ikke er umiddelbart synlig eller har forsvunnet fra sidelinjen i Microsoft Teams, kan du enkelt feste den for rask tilgang. Bare følg disse enkle trinnene:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Åpne Microsoft Teams og klikk på '...' (+ Apps) ikonet nederst på sidelinjen.</li>
                    <li className={styles.bulletBottomMargin}>Søk etter 'TeamConnect' i søkefeltet øverst på oppsprettsvinduet.</li>
                    <li className={styles.bulletBottomMargin}>Høyreklikk på TeamConnect-appen fra søkeresultatene og velg 'Fest'.</li>
                  </ol>
                  TeamConnect vil nå være festet til sidelinjen din, og er tilgjengelig med bare et klikk!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Opplever du tekniske problemer med TeamConnect? Her er hvordan du får hjelp.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hvis du støter på tekniske problemer med TeamConnect innenfor Microsoft Teams, er IT-støtteteamet i din organisasjon her for å hjelpe. 
                  Ta kontakt med dem på <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, og de vil hjelpe deg straks.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Kan du ikke se dine ansattopplysninger?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hvis profilen din føles litt tom med bare navn og e-post vist, er det sannsynlig at HR-teamet ditt ennå ikke har lagt til resten av dine ansattopplysninger i TeamConnect. 
                  Ingen grunn til å frykte! En rask melding til ditt HR-støtteteam vil sette ting i gang. De står klare til å fylle profilen din med alle viktige detaljer. 
                  Ta kontakt på <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> og se profilen din komme til liv!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Hvilke språkalternativer er tilgjengelige i TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect omfavner den globale mangfoldigheten av Microsoft Teams ved å støtte alle dets språk. 
                  Enten du foretrekker arabisk eller gresk, japansk eller svensk, har du en verden av alternativer ved fingertuppene dine, inkludert:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arabisk, bulgarsk, kinesisk (forenklet og tradisjonelt), kroatisk, tsjekkisk, dansk, nederlandsk, engelsk, estisk, finsk, 
                      fransk, tysk, gresk, hebraisk, ungarsk, indonesisk, italiensk, japansk, koreansk, latvisk, litauisk, norsk (bokmål), 
                      polsk, portugisisk (Brasil og Portugal), rumensk, russisk, serbisk (latin), slovakisk, slovensk, spansk, svensk, thai, 
                      tyrkisk, ukrainsk, vietnamesisk.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  For å tilpasse din TeamConnect-opplevelse til ditt foretrukne språk, vennligst juster språkinnstillingene direkte i Microsoft Teams, 
                  ettersom TeamConnect er fullt integrert med disse innstillingene:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Gå til dine Microsoft Teams innstillinger ved å klikke på de tre prikkene ved siden av profilbildet ditt øverst, og deretter velge 'Utseende og tilgjengelighet'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Bla ned til 'Språk'-delen og velg ditt foretrukne språk fra rullegardinmenyen.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Bekreft endringene dine ved å klikke på 'Lagre og start på nytt'-knappen for å anvende de nye språkinnstillingene.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Merk: </Body1Strong>
                <Body1>
                  Endringer gjort i dine språkinnstillinger i Microsoft Teams vil også naadig oppdatere din TeamConnect-opplevelse. 
                  Vær oppmerksom på at endring av språk i Teams også kan justere formatet på din tid og dato. For øyeblikket,
                  TeamConnect er i ferd med å utvikle seg for skriving fra høyre til venstre. Vi er entusiastiske over disse forbedringene 
                  og vil holde deg informert ettersom de blir tilgjengelige.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Hvordan kan jeg endre temaet i TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect synkroniseres med dine tema-preferanser i Microsoft Teams, inkludert Lys, Mørk og Høy Kontrast. For å tilpasse temaet ditt:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Gå til innstillingene dine i Microsoft Teams ved å klikke på de tre prikkene ved siden av profilbildet ditt øverst.</li>
                    <li className={styles.bulletBottomMargin}>Velg 'Utseende og tilgjengelighet' for å utforske tema-alternativene.</li>
                    <li className={styles.bulletBottomMargin}>Velg ditt foretrukne tema fra de tilgjengelige alternativene for å umiddelbart oppdatere TeamConnect-grensesnittet til å matche.</li>
                  </ol>
                  Ditt valg av tema i Microsoft Teams vil automatisk bli anvendt på TeamConnect, noe som sikrer en konsistent visuell opplevelse på tvers av arbeidsområdet ditt.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Utforske TeamConnects kontaktaksjonsknapper
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Overalt i TeamConnect, der du finner en ansatts kontaktdetaljer, vil du også oppdage våre praktiske hurtigtilgangsknapper. Disse nyttige verktøyene 
                  er designet for å effektivisere kommunikasjonen din innenfor Microsoft-økosystemet, og sørger for at du alltid er bare et klikk unna å koble deg til. 
                  Her er din omfattende guide til å bruke disse knappene effektivt:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chat-ikon:</Body1Strong> Klikk for å starte en umiddelbar Microsoft Teams-chat. Hvis du trenger å gå tilbake til TeamConnect etter å ha startet en chat, bruk bare tilbakepilen i skrivebordsappen, tilbakeknappen i mobilappen, eller tilbakeknappen i nettleseren din.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>E-postikon:</Body1Strong> Åpner et nytt Outlook-e-postutkast med mottakerens adresse klar i "Til:"-feltet. Hvis Outlook ikke starter, kan det være lurt å sjekke med IT-avdelingen din for eventuelle restriksjoner.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Videoanrop-ikon:</Body1Strong> Starter et ansikt-til-ansikt videoanrop ved hjelp av Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ring-ikon:</Body1Strong> Starter en Microsoft Teams taleanrop, noe som gjør raske diskusjoner problemfrie.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Orgkart-ikon:</Body1Strong> Avslører den ansattes plass i organisasjonshierarkiet, inkludert deres leder og direkte rapporter.</li>
                    <li><Body1Strong>Mer (...) Ikon:</Body1Strong> Avdekker flere alternativer:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Møt Nå:</Body1Strong> Åpner Microsoft Teams for å starte et umiddelbart møte med den ansatte.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Nytt møte:</Body1Strong> Dirigerer deg til Microsoft Teams for å planlegge et nytt møte.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Legg til kontakt:</Body1Strong> Legger den ansattes kontaktdetaljer fra TeamConnect til din nye Microsoft Teams People-app, berikende din samtalefunksjon.</li>
                      </ul>
                    </li>
                  </ul>
                  Disse intuitive knappene er utformet for å sikre at du kan koble deg til kolleger effektivt, enten for en rask prat, en e-post, eller for å planlegge fremtidige møter.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Å foreta anrop via TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ønsker du å foreta et anrop? TeamConnect effektiviserer kommunikasjonen din ved å integreres med Microsoft Teams. 
                  Klikk bare på ønsket telefonnummer i TeamConnect, og vips, vises grensesnittet for Microsoft Teams-anrop, 
                  klar for at du skal ringe ut sømløst.
                  <p>
                  <Body1Strong>Merk:</Body1Strong> 
                  Opplever du problemer med å foreta anrop? Dette kan skyldes organisasjonens IT-policyer eller bruk av et annet telefonsystem enn Microsoft Teams Phone System. Å sjekke med IT-avdelingen din på <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, kan kaste lys over saken.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Er det kostnader forbundet med å foreta samtaler via TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect forbedrer samtaleopplevelsen din ved å utnytte Microsoft Teams. Her er hva du trenger å vite om kostnader relatert til samtaler:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      For samtaler til ansattes arbeidstelefoner, avdelingstelefoner eller nødlinjer innenfor din organisasjon (interne numre), 
                      er det vanligvis <Body1Strong>ingen kostnader</Body1Strong> når du bruker Microsoft Teams Phone System.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      For eksterne samtaler, for eksempel til mobiltelefoner eller eksterne linjer, gjelder <Body1Strong>standardpriser</Body1Strong> for din organisasjons telefonsystem. 
                      Disse prisene varierer basert på din spesifikke tjenesteplan med Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Merk:</Body1Strong> Hvis du opplever problemer eller har spørsmål om samtalekostnader, er det best å konsultere 
                    din IT-avdeling på <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> for detaljert informasjon tilpasset 
                    din organisasjons oppsett og politikk.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Legger du merke til en uoverensstemmelse med din 'Out of Office' tilstedeværelsesstatus i TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Merket at din 'Out of Office' tilstedeværelsesstatus fra Microsoft 365 ikke gjenspeiles riktig i TeamConnect? 
                  Vi har dekket dette. Funksjonen for å synkronisere 'Out of Office' tilstedeværelsestatus har allerede blitt implementert i TeamConnect.
                  Vi venter for øyeblikket på Microsofts utrulling av denne funksjonen. Når det skjer, vil din 'Out of Office' tilstedeværelsesstatus 
                  bli nøyaktig speilet i TeamConnect, i samsvar med Microsoft Teams' tilstedeværelsesindikatorer for en sømløs opplevelse.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>Ofte stilte spørsmål om ansattkatalogen</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >Introduksjon til ansattkatalogen:</Body1Strong> Naviger i det profesjonelle landskapet i organisasjonen din med ansattkatalogen, 
                ditt sentrale nav for detaljerte innsikter om ansatte, arbeidsdynamikk, og direkte tilkobling til dine kollegers profiler og lederrelasjoner.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Trenger du å legge til, oppdatere, eller rette opp dine ansattopplysninger?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  For å legge til, oppdatere, eller rette opp dine ansattopplysninger i TeamConnect, vennligst ta kontakt med din organisasjons HR-støtteteam. 
                  De er klare til å hjelpe på <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Hva skjer når jeg klikker på en ansatts navn i katalogen?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Når du klikker på en ansatts navn i TeamConnects ansattkatalog, åpnes en detaljert profilside for den enkelte. Denne profilen gir omfattende 
                  innsikt om den ansatte, inkludert men ikke begrenset til, deres stillingstittel, avdeling, kontorlokasjon, kontaktinformasjon og mer. Den er designet for å gi deg 
                  en fullstendig oversikt over den ansattes profesjonelle rolle og hvordan de passer inn i organisasjonen, noe som gjør det enklere for deg å forstå deres ansvar og 
                  hvordan du kan samarbeide med dem.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Hvilken informasjon er inkludert i en ansatts profil?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hver ansattprofil i TeamConnect er et omfattende øyeblikksbilde som tilbyr en mengde innsikt i individets profesjonelle liv. 
                  Her er hva du vil finne:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Grunnleggende detaljer:</Body1Strong> Navn, stillingstittel, avdeling og filiallokasjon.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Rapporteringsstruktur:</Body1Strong> Informasjon om direkte og funksjonelle rapporter, 
                      inkludert linje- og funksjonsledere.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Arbeidsstatus:</Body1Strong> Nåværende arbeidsstatus som På ferie, Pendling, 
                      Forretningsreise, osv.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Arbeidssted & Lokal Tid:</Body1Strong> Den ansattes nåværende arbeidslokasjon og 
                      lokale tidssone.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontaktdetaljer:</Body1Strong> Arbeidstelefon, mobilnummer og e-postadresse.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontoradresse:</Body1Strong> Fullstendig kontorlokasjon inkludert bygningens navn,
                      adresse og Google-kart.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Om:</Body1Strong> En kort personlig introduksjon eller profesjonell biografi.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ansattrelasjoner:</Body1Strong> Detaljer om den ansattes rapporteringsstruktur og teamrelasjoner.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sosiale lenker & Språk:</Body1Strong> Lenker til profesjonelle sosiale profiler 
                      og språkkunnskaper.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ferdigheter:</Body1Strong> En liste over profesjonelle ferdigheter og ekspertiseområder.</li>
                  </ul>
                  I tillegg tilbyr profilen handlingsalternativer for å koble til via chat, e-post, videoanrop, eller planlegge møter, sammen med 
                  rask tilgang til organisasjonens diagram for en bredere teamkontekst.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Hvordan kan jeg oppdatere informasjonen i profilen min?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Det er enkelt å tilpasse profilen din i TeamConnect. Her er hvordan du kan gjøre det:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Naviger til <Body1Strong>Ansatt</Body1Strong>-fanen og klikk på din <Body1Strong>Avatar</Body1Strong> for å åpne profilen din.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        I øvre venstre hjørne vil du finne lenken <Body1Strong>"Rediger profilen din"</Body1Strong>. 
                        Ved å klikke på denne åpnes redigeringsdialogen for profilen din.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Her kan du forbedre profilen din ved å legge til eller oppdatere <Body1Strong>Om Deg</Body1Strong>-seksjonen, sosiale medier linker og telefonnummer. 
                        Dette gjør det lettere for kollegene dine å koble seg til eller følge deg.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Ikke glem å trykke på <Body1Strong>Lagre</Body1Strong>-knappen for å anvende endringene dine.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Rull ned i dialogen for å legge til dine <Body1Strong>Språk</Body1Strong> og <Body1Strong>Ferdigheter</Body1Strong>, 
                        samt din <Body1Strong>Ekspertise</Body1Strong>. Å dele denne informasjonen hjelper kollegene dine å forstå 
                        dine fagområder og språkene du kan kommunisere på.
                      </li>
                    </ol>
                  Å oppdatere profilen din holder ikke bare informasjonen din oppdatert, men fremmer også bedre forbindelser innenfor organisasjonen din.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Oppdater din arbeidsplass, arbeidssted og tidssone i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Det er avgjørende å holde informasjonen om din arbeidsplass, arbeidssted og tidssone oppdatert for sømløs samarbeid i Microsoft Teams. 
                  Disse oppdateringene gjenspeiles umiddelbart i din TeamConnect-profil og -listinger, slik at kolleger raskt kan forstå din arbeidslokasjon 
                  og de beste tidene for å koble seg til deg, noe som forbedrer samarbeid på tvers av ulike regioner og tidssoner.
                  <p></p>
                  Klar for å oppdatere detaljene dine? Det er enkelt i TeamConnect! Gå til 'Innstillinger' i toppmenyen og naviger til 'Generelle innstillinger'. 
                  Her kan du smidig oppdatere din Arbeidsplass og Tidssone, og spesifisere ditt Arbeidssted for dagen. Husk å lagre endringene dine for 
                  å holde alle informert og sikre effektivt samarbeid, uansett hvor du er!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Oppdaget noe galt i en kollegas profil?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hvis du kommer over noen detaljer i en kollegas profil som ikke ser helt riktige ut, er det beste første trinnet å gi dem en 
                  vennlig påminnelse. En rask prat eller e-post burde gjøre susen! Hvis av en eller annen grunn informasjonen ikke blir oppdatert, ikke bekymre deg—bare 
                  ping din HR-støttegruppe på <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, og de vil være glade 
                  for å trå til og ordne opp.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Forståelsesgrupper for tagger: Alle, Favoritter, Innsjekket & Nye Ansatte
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Alle:</Body1Strong> Denne standardtaggen viser hver ansatt i organisasjonen din,
                      ved å gi et omfattende bilde av teamet.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoritter:</Body1Strong> Klikk her for å filtrere din visning til de kollegene du har markert
                      som 'Favoritter', slik at det blir enklere å raskt koble seg til nøkkelkontakter.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Innsjekket:</Body1Strong> Denne taggen indikerer ansatte som aktivt er på jobb, forskjellig 
                      fra bare en online tilstedeværelse i Microsoft Teams. Når innsjekket, signaliserer de ansatte at de er på jobb og deler sin nåværende arbeidsplassering
                      gjennom dagen. Dette kan inkludere en rekke arbeidsplasser som kontor, feltkontor, hjemmekontor, fjernarbeidssted, kundearbeidsplass,
                      kundens private kontor, offentlig område, pendling, jobbreise, eller til og med kurs. Derfor gir innsjekket-status en klar indikasjon
                      på å være aktivt på jobb, og tilbyr kolleger en presis forståelse av hverandres nåværende arbeidskontekst og tilgjengelighet.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nye Ansatte:</Body1Strong> Oppdag nye ansikter i TeamConnect med denne taggen, som fremhever
                      ansatte som har blitt lagt til i appen i løpet av de siste 30 dager. Vanligvis samsvarer dette med nyere tilskudd til organisasjonen din, slik at
                      du kan ønske nye teammedlemmer velkommen.
                    </li>
                  </ul>
                  Disse taggruppene er designet for å forenkle navigasjonen og forbedre din TeamConnect-opplevelse, sørger for at du alltid kan finne og koble deg til dine kolleger effektivt.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Dekoding av ikonene ved siden av søkeboksen
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnects ansattkatalog er designet for allsidighet og brukervennlighet, og har fire ikoner som tilbyr forskjellige visninger
                  og rask tilgang til viktige detaljer. Her er hva hvert ikon avslører:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Listevisning:</Body1Strong> Din vanlige visning, som presenterer alle medlemmer i organisasjonen
                      i en enkel liste.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Liten Kortvisning:</Body1Strong> Et kompakt alternativ, denne visningen omdanner
                      listen til små størrelse-ansattkort.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Stor Kortvisning:</Body1Strong> Velg mer detaljer med større kort, som inkluderer
                      ekstra kontaktalternativer som telefon og mobilnummer for enkel oppringing.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Orgkart:</Body1Strong> Visualiser bedriftens struktur med Orgkart-visningen,
                      perfekt for å forstå rapporteringsforhold og navigere i organisasjonslandskapet.</li>
                  </ol>
                    Veksle mellom disse ikonene for å finne den visningen som best passer dine behov i øyeblikket, enten du søker rask informasjon eller dypere innsikt.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Hvor bør jeg se for raske kollegasøk?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Leter du etter en kollega? Gå bare til TeamConnect-søkeboksen, som er plassert på øvre høyre side ved siden av Org-kart-ikonet.
                  Begynn å skrive inn navnet deres, og se som resultatene blir mer raffinert med hver bokstav. Husk, for TeamConnect-søk, hopp over Microsoft Teams
                  søkefeltet på toppen—vår spesielle søkeboks er veien å gå!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Hvordan fungerer den alfabetiske søkfunksjonen for å finne ansatte?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnects ansattkatalog inkluderer en praktisk alfabetisk søkefunksjon for å hjelpe deg med å raskt finne ansatte ved deres fornavn. 
                  Klikk bare på en bokstav fra alfabetet som vises øverst i katalogen, og TeamConnect vil øyeblikkelig vise deg alle ansatte 
                  hvis fornavn begynner med den bokstaven. For å gå tilbake til den komplette listen over ansatte, er det bare å fjerne markeringen av den fremhevede bokstaven. Denne intuitive 
                  funksjonen gjør det enkelt å navigere gjennom store ansattlister, slik at du kan finne kolleger raskt og effektivt.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Hvordan kan jeg justere antallet ansatte som vises per side?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect lar deg tilpasse hvor mange ansattprofiler du ser per side, noe som gjør det enklere å navigere gjennom ansattkatalogen. 
                  Nederst på hver side finner du pagineringsalternativer: 10, 50, 100, 500, 1000, og 5000. Standardinnstillingen viser 10 profiler per side. 
                  For å se flere profiler på en enkelt side, velg bare ditt foretrukne antall fra disse alternativene. Denne funksjonen gir fleksibilitet i hvordan du blar 
                  gjennom katalogen, slik at du kan tilpasse visningen til dine spesifikke behov eller preferanser.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Hvordan sorterer jeg ansattinformasjon i listevisning?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  I TeamConnects ansattkatalog listevisning, har du fleksibiliteten til å sortere ansattinformasjonen etter dine behov. Kolonnene—Ansattens navn, 
                  Jobbtittel, Avdeling, Kontorsted, og Arbeidsplass—er alle sorteringsbare. Klikk bare på kolonneoverskriften du ønsker å sortere etter. Ett klikk sorterer 
                  kolonnen i stigende rekkefølge, og et nytt klikk sorterer den i synkende rekkefølge. Som standard er katalogen sortert etter Ansattens navn i stigende rekkefølge, 
                  basert på fornavnet. Denne sorteringen hjelper deg raskt med å organisere og se ansattdetaljer på en måte som best tjener ditt formål, enten du leter etter 
                  en spesifikk person eller trenger å gruppere ansatte etter et bestemt kriterium som avdeling eller plassering.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Avdelingskatalog Spørsmål og svar</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Introduksjon til avdelingskatalogen:</Body1Strong> Avdelingskatalogen er din inngangsport til å forstå det komplekse nettverket i din
              organisasjonsstruktur, og tilbyr et klart syn på avdelingenes hierarkier, lederskap i ledelsen, og muligheter for sømløs avdelingskommunikasjon.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Hvordan fungerer merkene 'Alle', 'Favoritter', og 'Din avdeling' i avdelingskatalogen?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  I TeamConnects avdelingskatalog hjelper merker deg å raskt navigere og filtrere gjennom avdelingsinformasjon. Her er hva hvert merke representerer:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Alle:</Body1Strong> Dette merket viser alle avdelinger innen din organisasjon, og gir en
                      omfattende oversikt.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoritter:</Body1Strong> Bruk dette merket for å se en utvalgt liste av avdelinger du har
                      markert som 'Favoritter', hvilket gjør det enklere å få tilgang til avdelinger du ofte samhandler med.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Din avdeling:</Body1Strong> Dette merket filtrerer visningen til å kun vise din avdeling,
                      og tillater rask tilgang til din umiddelbare avdelingsinformasjon og oppdateringer.</li>
                  </ul>
                  Disse merkene er designet for å strømlinjeforme din navigasjon innenfor avdelingskatalogen, og forbedre din evne til effektivt å finne og engasjere deg med avdelingsinformasjon.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Forstå visninger i avdelingskatalogen i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigering gjennom avdelingskatalogen i TeamConnect er strømlinjeformet med flere visningsalternativer som passer dine preferanser. Hver visning gir en 
                  unik måte å utforske omfattende avdelingsinformasjon på, inkludert avdelingens navn, avdelingssjef, overordnet avdeling, telefonnummer til avdelingen, 
                  hotline for avdelingen og e-post for avdelingen. Her er et øyeblikksbilde av de tilgjengelige visningene:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Listevisning:</Body1Strong> Standardoppsettet som pent oppsummerer alle avdelinger, og gir en
                      klar, konsekvent oversikt.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Stor kortvisning:</Body1Strong> Bytt til denne visningen for å se detaljerte avdelingskort, som tilbyr
                      et utvidet sammendrag av hver avdelings nøkkelinformasjon.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org kartvisning:</Body1Strong> For en visuell representasjon av avdelingsstrukturen og 
                      relasjonene, illustrerer org kartvisningen hvordan hver avdeling passer inn i den større organisasjonshierarkiet.
                    </li>
                  </ul>
                  For å veksle mellom disse visningene, bruk ganske enkelt ikonene ved siden av søkeboksen øverst i avdelingskatalogen. Hver visning er designet for å hjelpe deg
                  tilgang og forstå avdelingsdetaljer med letthet, sikrer at du finner informasjonen du trenger i formatet som fungerer best for deg.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Hvordan bruke søkeboksen i avdelingskatalogen
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Finn enkelt enhver avdeling innen TeamConnect ved hjelp av den dedikerte søkeboksen som er plassert rett ved siden av visningsikonene øverst på 
                  Avdelingskatalogen. Begynn rett og slett å skrive inn navnet på avdelingen du leter etter, og se hvordan katalogen raffinerer seg for å vise bare 
                  relevante treff. Denne funksjonen er spesielt designet for å hjelpe deg med å unngå å manuelt måtte søke gjennom hele katalogen, og bringe 
                  avdelingen du trenger direkte til fingertuppene dine. Husk at denne søkefunksjonaliteten er unik for TeamConnects Avdelingskatalog, så sørg 
                  for å bruke denne søkeboksen i stedet for hovedsøket i Microsoft Teams øverst på Teams-grensesnittet for avdelingsspesifikke spørsmål.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Hvordan fungerer det alfabetiske filteret i avdelingskatalogen?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Forenkle søket ditt i avdelingskatalogen med det alfabetiske filteret som befinner seg på toppen. Denne funksjonen lar deg raskt innsnevre 
                  avdelinger ved å velge en bokstav. Når du har valgt en bokstav, filtrerer TeamConnect og viser bare de avdelingene hvis navn begynner 
                  med den valgte bokstaven, noe som gjør det enklere å finne det du leter etter uten å bla gjennom hele listen. For å gå tilbake til full 
                  avdelingsvisning, velg bare den fremhevede bokstaven. Denne intuitive alfabetiske navigasjonen sikrer at du effektivt kan lokalisere enhver 
                  avdeling ved dens startbokstav.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Hvordan kan jeg kontakte avdelingssjefen?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Å kontakte en avdelingssjef i TeamConnect er greit. Finn bare avdelingen av interesse og klikk på sjefens navn. Denne 
                  handlingen vil utløse et sprettoppvindu som viser sjefens kontaktalternativer med de kjente hurtigtilgangsikonene:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chattekstikon:</Body1Strong> For å starte en direkte melding via Microsoft Teams chat.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>E-postikon:</Body1Strong> For å åpne en ny e-post adressert til dem i Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Videoanrop-ikon:</Body1Strong> For å starte et videoanrop gjennom Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Oppringingsikon:</Body1Strong> For å starte en taleanrop ved hjelp av Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Mer (...) -ikon:</Body1Strong> For flere alternativer som å planlegge et møte eller legge til 
                      kontaktopplysningene deres i din Teams-liste.
                    </li>
                  </ul>
                  Disse ikonene gir en sømløs metode for å nå ut til avdelingsledere direkte fra TeamConnect, og sikrer effektiv kommunikasjon innen 
                  organisasjonen din.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Hvordan kan jeg se eller kontakte ansatte innen en avdeling?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  For å utforske hvem som er hvem innen en avdeling i TeamConnect, se ikke lenger enn de tre prikkene ved siden av avdelingsnavnet. Ved å klikke på dette vil 
                  det dukke opp alternativer for videre handling:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vis ansatte i denne avdelingen:</Body1Strong> Velg dette for å vise en liste over alle 
                      ansatte tilknyttet avdelingen. Tallet ved siden av dette alternativet indikerer hvor mange ansatte det er i avdelingen, noe som gir en rask oversikt.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Avdelingsorganisasjonskart:</Body1Strong> Hvis du ønsker å forstå avdelingens hierarki, 
                      velg 'Avdelingsorganisasjonskart' for å avsløre den strukturelle layouten, som viser forholdet mellom overordnede avdelinger, avdelingen i spørsmål, 
                      og eventuelle underavdelinger. Denne visuelle representasjonen hjelper deg med å navigere og forstå organisasjonsrammeverket, noe som gjør det enklere å identifisere og 
                      kontakte bestemte avdelingsmedlemmer.
                    </li>
                    <li><Body1Strong>Avdeling {'>'} Ansatt:</Body1Strong> Etter å ha sett på de ansatte innen en avdeling, bruk denne brødsmulestien til å enkelt 
                      navigere tilbake til avdelingslisten, og oppretthold en jevn utforskningserfaring innen TeamConnect.
                    </li>
                  </ul>
                  Disse funksjonene lar deg ikke bare se listen over ansatte i en gitt avdeling, men også tilby en vei til å nå dem direkte gjennom 
                  TeamConnects kommunikasjonsverktøy.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Oppdage grundige avdelingsdetaljer i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Fordyp deg i detaljene om enhver avdeling med et enkelt klikk på navnet dets i TeamConnect. En detaljert dialog utfolder seg, som tilbyr et rikt bilde av 
                  informasjon som maler et komplett bilde av avdelingens rolle og ressurser innen din organisasjon. Du finner alt fra lederskap og 
                  finansielle identifikatorer til kommunikasjonskanaler og sosiale lenker. Denne funksjonen er din portal til:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Avdelingens hjerteslag – dens formål, mennesker og ytelsesmålinger.</li>
                    <li className={styles.bulletBottomMargin}>Kontaktveier – direkte linjer til nøkkelansatte i avdelingen og kommunikasjonsplattformer.</li>
                    <li className={styles.bulletBottomMargin}>Den organisatoriske webben – forståelse av hvordan avdelingen integreres med den bredere selskapsstrukturen.</li>
                  </ul>
                  Enten det er et raskt spørsmål eller en dypdykking i avdelingsdata, utstyrer TeamConnect deg med verktøyene for å koble til og forstå de unike 
                  miljøene innen din organisasjon.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Nysgjerrig på fraværet av avdelingsadresser i TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Å grave frem en avdelings beliggenhet kan noen ganger føles som en skattejakt uten kart. I TeamConnect vil du vanligvis ikke finne en dedikert 
                  adresse for hver avdeling. Her er hvorfor denne kartografiske detaljen ofte utelates:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Flere lokasjoner:</Body1Strong> Mange avdelinger er ikke begrenset til et enkelt sted; de
                      trives på tvers av forskjellige kontorer og geografier.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Fluid struktur:</Body1Strong> Det organisatoriske landskapet endrer seg stadig, med 
                      avdelinger som utvikler seg, slår seg sammen eller til og med oppløses i samsvar med strategiske skift.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Interdepartementalt samarbeid:</Body1Strong> Moderne arbeidsplasser summer av samarbeidsånd, ofte ved å blande talenter fra forskjellige avdelinger i delte rom.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Fjernarbeidskultur:</Body1Strong> Med fleksibiliteten i fjernarbeid, er ikke avdelingenes ankere alltid nedfelt i fysiske kontorlokaler.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Juridiske nyanser:</Body1Strong> Avdelinger sirkulerer typisk rundt selskapets juridiske 
                      struktur heller enn å ha sine egne frittstående adresser.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Operasjonell effektivisering:</Body1Strong> Å holde fokus på dynamisk teamarbeid, ikke statiske 
                      adresser, bidrar til å opprettholde operasjonell smidighet.
                    </li>
                  </ul>
                  Det handler alt om å omfavne en grenseløs tilnærming til teamarbeid og effektivitet. TeamConnect gjenspeiler denne filosofien ved å legge vekt på forbindelser framfor 
                  lokasjoner.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Mangler det informasjon fra din avdeling, eller er den utdatert?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Avdelingsredaktører er utpekt i din organisasjon for å legge til, oppdatere eller korrigere avdelingsspesifikk informasjon i TeamConnect.
                  Hvis du er usikker på hvem din avdelingsredaktør er, eller hvis en bestemt informasjon krever intervensjon fra HR-støtteteamet,
                  vennligst kontakt <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> for veiledning.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Ofte stilte spørsmål om kontorfortegnelsen</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Introduksjon til kontorfortegnelsen:</Body1Strong> Oppdag ryggraden i organisasjonens fysiske tilstedeværelse gjennom kontorfortegnelsen,
                som omfatter essensielle kontordetaljer, driftsspesifikasjoner og en omfattende oversikt over avdelingene og personellet som definerer hver lokasjon.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Navigering av merkelapper i kontorfortegnelsen
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Finn frem i kontorfortegnelsen med letthet ved hjelp av vårt merkesystem designet for rask tilgang til riktig sted. Slik kan hver merkelapp hjelpe deg med å strømlinjeforme søket ditt:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Alle:</Body1Strong> Viser en komplett oversikt over kontorer, som gir deg et fugleperspektiv over
                      organisasjonens lokasjoner.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoritter:</Body1Strong> Hjelper deg å fokusere på de kontorplassene du ofte besøker eller trenger
                      å holde et øye med, ved å markere dem som 'Favoritter'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ditt Kontor:</Body1Strong> Denne personlige merkelappen filtrerer fortegnelsen for å vise deg 
                      kontorlokasjonen hvor du er stasjonert, og holder din hjemmebase innen rekkevidde.
                    </li>
                  </ul>
                  Enten du booker et møterom eller planlegger et besøk, tilbyr disse merkelappene en personlig og forenklet måte å samhandle med din organisasjons 
                  geografiske landskap.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Å mestre visninger av kontorkataloger i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Dykk ned i den organisatoriske strukturen til bedriftens forskjellige lokasjoner med TeamConnects visninger av kontorkataloger. Hver visning er designet for å 
                  lette en unik aspekt av navigasjon og forståelse av kontorer:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Listevisning:</Body1Strong> Standardinnstillingen som organiserer alle kontorlokasjoner i 
                      en lettleselig liste.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Stor Kortvisning:</Body1Strong> Stor Kortvisning i kontorkatalogen beriker din 
                      visning med en detaljert presentasjon av hvert kontor. Når du engasjerer deg med et kontorkort, vil du finne en mengde informasjon og gjennomførbare alternativer 
                      inne i "Mer (...)"-menyen:
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Vis avdelinger:</Body1Strong> Naviger direkte til en liste
                          over alle avdelinger som er plassert innenfor kontoret.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Vis ansatte:</Body1Strong> Tilgang til en komplett liste over ansatte tilordnet 
                          kontoret, noe som legger til rette for direkte kontakt eller utforskning av kontorpersonalet.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Organisasjonskart for virksomheten:</Body1Strong> Visualiser kontorets plass innenfor den større 
                          organisatoriske konteksten for en klar forståelse av hierarkiske strukturer.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Viktige kontakter:</Body1Strong> Identifiser nøkkelkontakter på kontoret for strømlinjeformet 
                          kommunikasjon.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Offentlige fridager for kontoret:</Body1Strong> Hold deg informert om kontorspesifikke offentlige 
                          fridager for å planlegge timeplanen din tilsvarende.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Arbeidstider for kontoret:</Body1Strong> Vis kontorets driftstimer for å tilpasse dine 
                          besøk eller samtaler med deres aktive tidspunkter.
                        </li>
                      </ol>
                      Denne visningen gir ikke bare et visuelt og informasjonsoversikt, men også rask tilgang til dypere innsikt og direkte handlinger relatert til kontoret, alt pent organisert under "Mer (...)"-alternativet for din bekvemmelighet.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organisasjonskartvisning:</Body1Strong> Visualiser den hierarkiske strukturen av hovedkontor og underkontorer 
                      for å forstå samspillet mellom forskjellige kontorlokasjoner innenfor din organisasjon.
                    </li>
                  </ul>
                  Veksle gjennom disse visningene ved hjelp av ikonene ved siden av søkeboksen for å tilpasse din opplevelsesopplevelse til å passe dine umiddelbare behov.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Raskt finne kontorer med TeamConnects søkefunksjon
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Å finne riktig kontorlokasjon innen TeamConnect er gjort enkelt med den dedikerte søkefunksjonen. Plassert fremtredende ved siden av visningsikonene, er søkeboksen ditt verktøy for å finne kontorer ved navn. Når du skriver, raffinerer katalogen visningen til kun å vise kontorer som samsvarer med ditt innskrevne, og strømlinjeformer søkeprosessen. Det er viktig å bruke denne søkeboksen innen TeamConnect for de mest nøyaktige og relevante resultatene, i motsetning til det generelle Microsoft Teams-søket. Med denne målrettede søkefunksjonaliteten kan du raskt navigere til ethvert kontor i din organisasjonsnettverk.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Navigere i kontoroversikten alfabetisk
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hvis du foretrekker å søke etter alfabetet, er vår alfabetiske filter en rask måte å finne kontorer etter navn på. På toppen av kontoroversikten vil du 
                  se bokstavene A til Å. Ved å klikke på en bokstav filtreres visningen for å kun vise de kontorene som starter med den bokstaven, noe som forenkler 
                  søkeprosessen din. For å tilbakestille og se alle kontorene igjen, bare fjern den aktive bokstaven. Denne funksjonen er spesielt nyttig når du er 
                  kjent med kontornavnet, men trenger en rask måte å finne det i TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Avdekke omfattende kontorprofiler i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Legg ut på en oppdagelsesreise gjennom hjertet av din organisasjons fysiske nærvær med TeamConnects kontorprofiler. 
                  Ved å klikke på et kontornavn åpnes et livlig profilvindu, fylt med faner fulle av detaljerte innsikter om kontoret:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Oversikt:</Body1Strong> Dykk inn i kjernen av kontorets identitet med detaljerte 
                      beskrivelser, fra forretningsstruktur til åpningstider og lokal tid, som fanger essensen av kontormiljøet.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nødvendige kontakter:</Body1Strong> Få tilgang til avgjørende kontakter for nødtjenester og 
                      ekstern støtte, hver kun et klikk unna gjennom Microsoft Teams Phone System.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontorkontakter:</Body1Strong> Ta kontakt med interne støttetjenester med letthet, med 
                      kontaktinformasjon og direkte engasjementsalternativer rett ved fingertuppene dine.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Offentlige helligdager:</Body1Strong> Hold deg oppdatert på kontorspesifikke fridager, og sørg for at 
                      dine planer stemmer overens med lokale høytider og arbeidsskjemaer.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Åpningstider:</Body1Strong> Planlegg dine samhandlinger med presis kunnskap om når 
                      kontoret yrer med aktivitet eller roer seg ned for dagen.
                    </li>
                  </ul>
                  Enten det er den travle energien i Austin eller det strategiske knutepunktet i Singapore, tilbyr hver fane en gateway for å forstå og koble seg til de 
                  forskjellige lokalene som utgjør din bedrifts globale nærvær.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Utforske dynamikken i avdelinger innenfor et kontor
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigering i det profesjonelle nettverket av et kontors avdelinger utfolder seg enkelt i TeamConnect. Her er hvordan du graver dypt inn i detaljene:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Se avdelinger i dette kontoret:</Body1Strong> Oppdag alle avdelingene som utgjør 
                      stoffet i kontoret ved å velge denne muligheten fra Mer (...) menyen.
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organisasjonsinnsikt:</Body1Strong> Klikk på en hvilken som helst avdeling for å avsløre dens org kart for 
                      en visuell fremstilling av dens struktur, med brødsmulestien som veileder deg: Kontor {'>'} Avdeling {'>'} Kart.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Dypdykk i avdelingen:</Body1Strong> Utforsk videre en avdeling ved å klikke på dens 
                      Mer-meny (...) ved siden av avdelingsnavnet. Her kan du få tilgang til en liste over alle ansatte innen den avdelingen, rett i kontorkonteksten.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Brødsmulenavigasjon:</Body1Strong> Kryss gjennom nivåene sømløst. Fra 
                      ansatte tilbake til avdelinger eller fra org kartet, brødsmulestien (Kontor {'>'} Avdeling {'>'} Ansatt {'>'} Kart) holder reisen din intuitiv og på sporet.
                    </li>
                  </ul>
                  TeamConnects intrikate stier inviterer deg til å utforske, forstå og engasjere deg med hvert lag av kontorets avdelinger, og gir deg 
                  omfattende navigasjonskommando.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Koble seg til kontorpersonell i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nysgjerrig på ansiktene bak et kontor? Svaret er bare et klikk unna i TeamConnect. Engasjer deg med ellipsen (tre prikker) ved siden av det valgte 
                  kontornavnet for å avsløre en rekke interaktive alternativer:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Se ansatte i dette kontoret:</Body1Strong> Avdekke en liste over alle de profesjonelle 
                      som kaller dette kontoret sitt arbeidshjem. Antallet som vises tilbyr en rask opptelling av de ansatte, som inviterer deg til å dykke inn i kontorsamfunnet.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontaktalternativer:</Body1Strong> Hver ansattoppføring er utstyrt med hurtighandlingsikoner, 
                      som lar deg starte chatter, samtaler eller til og med videokonferanser direkte fra TeamConnect, og fremmer sømløs kommunikasjon innad i kontoret.
                    </li>
                    <li><Body1Strong>Navigasjon:</Body1Strong> For å gå tilbake til den bredere kontorlisten fra en ansattliste, klikk bare 'Kontor' i brødsmulestien 
                      (Kontor {'>'} Ansatt), og du vil bli tatt tilbake til kontoroversikten.
                    </li>
                  </ul>
                  Med disse funksjonene gjør TeamConnect det enkelt å visualisere arbeidsstyrkefordelingen på tvers av kontorer og å nå ut uten anstrengelse, 
                  og fremmer en tilkoblet arbeidsplass uavhengig av avstand.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Mangler eller er foreldet informasjon om kontoret ditt?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Oppdateringer av kontorinformasjon i TeamConnect kan håndteres av tildelte kontoreditorer. Hvis du er usikker på hvem kontoreditorene er eller 
                  trenger assistanse med informasjon som faller under HRs ansvarsområde, er HR-støtteteamet klart til å 
                  assistere på <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Innstillinger FAQ</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Viktigheten av nøyaktige generelle innstillinger i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  I dagens dynamiske arbeidsmiljø er det viktigere enn noen gang å være tilkoblet og koordinert. Derfor spiller oppdatering av dine 
                  Generelle Innstillinger i TeamConnect en avgjørende rolle:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Valg av arbeidssted:</Body1Strong> Fang essensen av arbeidsmiljøet ditt ved å oppdatere din Arbeidsplass for å gjenspeile din nåværende 
                        situasjon, enten det er et skifte fra roen i ditt Hjemmekontor til summingen i hovedkontoret, eller til og med å slå deg ned i et Offentlig-område. 
                        Denne klarheten i din arbeidslokasjon gir kollegaene innsikt ikke bare i de beste måtene å kontakte deg på, men også i naturen av 
                        samtaler de kan engasjere seg i med deg, noe som beskytter konfidensialiteten til sensitive diskusjoner spesielt når du er midt i det åpne 
                        miljøet til en klients kontor eller sitter i en flyplasslounge.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Spesifikasjon av arbeidssted:</Body1Strong> Å dele din arbeidsby gjør mer enn å tette avstander i et fjernlagoppsett—det åpner også 
                        dører for spontane profesjonelle sammenkomster eller uformelt sosialt samvær. Enten det er en rask kaffe, en avslappende lunsj eller et samarbeidende 
                        møte, kan å vite at du er i samme by forvandle en vanlig dag til en mulighet for meningsfulle ansikt-til-ansikt-forbindelser. Dette ikke 
                        bare styrker teambåndene, men gir også en rikere forståelse av de lokale nyansene som kan påvirke arbeidet ditt.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tidssone nøyaktighet:</Body1Strong> Ettersom team spenner over kontinenter, er det avgjørende at din tidssone 
                      gjenspeiler din nåværende lokasjon for å harmonisere kommunikasjonen. Din lokale tid i TeamConnect-profilen er direkte påvirket av denne innstillingen, 
                      og eliminerer all gjetning for kolleger som prøver å nå deg.
                    </li>
                  </ul>
                  Dykk inn i 'Innstillinger' {'>'} 'Generelle Innstillinger' i TeamConnect for å finjustere disse detaljene. Noen øyeblikk brukt på å oppdatere innstillingene dine kan 
                  betydelig forbedre samhørigheten og effektiviteten av dine samarbeidsbemühninger på tvers av avdelinger.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
           
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Avkode arbeidsplassvalg i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Å velge riktig innstilling i arbeidsplassnedtrekkslisten tilpasser TeamConnect for å nøyaktig gjenspeile din nåværende arbeidsstatus. Hold musepekeren over info-ikonet for en detaljert forklaring av hver mulighet:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Pause:</Body1Strong> Betegner tid borte fra jobb, enten det er for en pause eller dagens slutt.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontor/Feltkontor:</Body1Strong> Angir tilstedeværelse i et bedriftsstyrt arbeidsområde.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Hjemmekontor:</Body1Strong> Jobber fra et dedikert hjemmearbeidsområde.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Fjernarbeidsplass/Offentlig område:</Body1Strong> Opererer fra et ikke-kontormiljø, noe som antyder et behov for fleksibel kommunikasjon.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kundens arbeidsplass/Privat kontor:</Body1Strong> På stedet hos en klient, med en merknad om personvernivåer for samtalefølsomhet.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pendling/Forretningsreise/Opplæring:</Body1Strong> På farten eller engasjert i spesifikke arbeidsrelaterte aktiviteter utenfor kontoret.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Offentlig høytid/Ferie/Ulike fravær:</Body1Strong> Offisielt fri for personlige, helsemessige eller feriemessige grunner.</li>
                  </ul>
                  Å velge en passende arbeidsplassinnstilling sikrer at teamet ditt har konteksten som trengs for interaksjoner, med respekt for tilgjengelighet og personvern.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Navigere 'Checked-In'-statusen i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  'Checked-In'-merket i ansattkatalogen er designet for å vise alle ansatte som for øyeblikket er på jobb. Denne synligheten bestemmes av arbeidsplassvalget du gjør:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Aktive arbeidsmiljøer:</Body1Strong> Valgene Kontor, Feltkontor, Hjemmekontor, Fjernarbeidsplass, Offentlig område, Kundens arbeidsplass, 
                    Kundes privatkontor, Pendling, Forretningsreise og Opplæring signaliserer at du er engasjert i arbeid, og gjør deg 'Checked-In' og synlig aktiv for kollegaer.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikke-arbeidsinnstillinger:</Body1Strong> Å velge Checked-Out, Pause, Offentlig høytid, Ferie, På permisjon, Mammapermisjon, eller Sykefravær 
                      fjerner deg fra 'Checked-In'-listen, og setter klare forventninger for tilgjengelighet og responstider.
                  </li>
                  </ul>
                  Denne funksjonaliteten bidrar til å skille arbeidsperioder fra personlig tid, og hjelper til med effektiv team-samarbeid og kommunikasjon.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Spørsmål og svar om databehandling og personvern</Subtitle2>
          <Body1>
            <p>
              I dagens digitale tidsalder er det avgjørende å forstå hvordan dine data håndteres. TeamConnect er engasjert i å være transparente og har robuste praksiser for databeskyttelse. 
              Nedenfor finner du informasjon om hvordan vi behandler, lagrer og beskytter dine data innenfor TeamConnect, slik at ditt digitale arbeidsområde er sikkert og i samsvar med regelverk.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Hvordan beskytter TeamConnect mine personlige data?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect er dedikert til å beskytte din personlige informasjon med en omfattende tilnærming til datasikkerhet:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Enkel pålogging (SSO):</Body1Strong> For å styrke sikkerheten og streamline brukeropplevelsen, integrerer TeamConnect automatisk 
                      Enkel pålogging (SSO) med din Microsoft 365-konto, noe som legger til rette for sikker og enkel tilgangsstyring.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Kryptering:</Body1Strong> Vi sørger for at alle data innenfor TeamConnect er kryptert både under overføring og når det lagres, 
                      slik at de er utilgjengelige for uautoriserte parter.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Data Minimering:</Body1Strong> Vår datainnsamling er strengt begrenset til det som er essensielt for TeamConnects funksjonalitet.
                      Den eneste brukerinformasjonen vi lagrer er ditt Bruker Hovednavn (UPN) fra Azure Entra ID, som kan ligne din firma e-postadresse.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Begrenset Tilgang:</Body1Strong> Tilgang til UPN er begrenset til abonnementsadministratoren i din organisasjon innenfor vårt abonnementsportal,
                      dette sikrer at sensitiv informasjon kun er synlig for autorisert personell.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Rask Datasletting:</Body1Strong> TeamConnect er strukturert for øyeblikkelig å slette lagret UPN under tre spesifikke forhold: 
                      hvis abonnementet avsluttes av abonnementsadministratoren, hvis en bruker fjernes av HR i TeamConnect, eller hvis TeamConnect-
                      abonnementet utløper. Dette sikrer at dine personlige data ikke beholdes lenger enn deres nyttige levetid eller nødvendighet.
                    </li>                      
                  </ul>
                  <p>Gjennom disse tiltakene forplikter TeamConnect seg til å opprettholde de høyeste standardene for databeskyttelse, og sikrer at din personlige informasjon forblir sikker og konfidensiell.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Lurer du på hvor dataene dine befinner seg med TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect bryter med tradisjonelle SaaS-tilbud ved å sørge for at dataene dine aldri forlater organisasjonen din.
                  I motsetning til vanlig praksis med å lagre data på eksterne skytjenere, benytter TeamConnect genialt din organisasjons
                  Microsoft 365 SharePoint Online for å huse alle applikasjonsdata. Denne nyskapende tilnærmingen fremmer ikke bare datasuverenitet, men styrker også sikkerheten,
                  og er i perfekt overensstemmelse med vår strenge dedikasjon til å beskytte ditt personvern og overholde strenge overholdelsesstandarder.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Vil dataene mine bli delt med noen tredjeparter?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nei, data deles ikke med tredjeparter! Cubeet er standhaft i vår forpliktelse til ditt datas personvern og sikkerhet. Vi deler ingen TeamConnect-data med tredjeparter uten å innhente eksplisitt samtykke fra våre kunder.
                  I vårt sikre miljø lagrer vi bare essensielle identifikatorer, som brukerens hovednavn (UPN), organisasjonsnavn, leietakernavn og leietaker-ID, innenfor vårt abonnementsportal.
                  Denne informasjonen er avgjørende for tjenesteytelsen og er beskyttet under våre strenge personvernpolicyer.
                  Vær trygg, våre praksiser for datavern er designet for å sørge for at dataene dine forblir konfidensielle, med åpenhet og kontroll som fast befinner seg i dine hender.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Hvordan overholder TeamConnect databeskyttelsesreguleringer som GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect er i overensstemmelse med databeskyttelsesreguleringer som GDPR, HIPAA, CCPA og andre ved å utnytte de inneboende samsvarsfunksjonene i Microsoft 365-miljøet,
                  hvor all kundedata er trygt lagret. I motsetning til tradisjonelle SaaS-modeller, lagrer ikke TeamConnect noen kundedata på sine servere; i stedet opererer det innenfor din
                  organisasjons Microsoft 365-miljø, og overholder dets robuste sikkerhet- og samsvarsstandarder. Denne tilnærmingen sikrer at din datastyring forblir konsekvent med
                  det omfattende samsvarsrammeverket som tilbys av Microsoft 365, inkludert overholdelse av GDPR, HIPAA for helseinformasjon, CCPA for innbyggere i California,
                  og andre globale databeskyttelsesreguleringer. Ved å integrere sømløst med din eksisterende Microsoft 365-oppsett, gir TeamConnect organisasjonen din muligheten til å opprettholde
                  full kontroll over dataene dine, og sikrer overholdelse av de strengeste databeskyttelseslover og -regler.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Hvordan håndterer TeamConnect data fra Microsoft Teams chatter, anrop, videosamtaler, Outlook-e-poster og telefonsystemanrop?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect utnytter Microsoft Graph API for å muliggjøre direkte interaksjoner som chat, anrop, videosamtaler og Outlook-e-post inne i Microsoft Teams,
                  og gir en sømløs brukeropplevelse. Vesentlig er det at selv om TeamConnect initierer disse kommunikasjonene, gjør den dette uten å gripe inn i selve kommunikasjonsprosessen
                  eller ha evnen til å registrere eller lagre noe av innholdet i interaksjonen. Alle samtaler, anrop og e-poster foregår og blir håndtert direkte innenfor
                  de sikre grensene til Microsoft Teams og Outlook, i streng overensstemmelse med Microsofts 365 robuste sikkerhets- og personvernstandarder. Dette sikrer at TeamConnect forbedrer
                  dine kommunikasjonsevner samtidig som det fullt ut bevares konfidensialiteten og integriteten til dataene dine.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            For detaljerte innsikter i våre datapolitikker og praksis, kan du også konsultere {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>TeamConnect brukerveiledning</Body1Strong></Link> eller din organisasjons IT
              avdeling på <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> for spesifikasjoner relatert til din konfigurasjon.
          </p>
        </Body1>        
        <p></p>  
        <Subtitle1 className={styles.fontColor}>Vi lytter: Del din tilbakemelding og ideer</Subtitle1>
        <Body1>
          <p>
            Dine innsikter driver TeamConnects evolusjon. Enten du bobler over med en strålende funksjonsidé eller har noe
            tilbakemelding på din nåværende opplevelse, har vi ørene på stilk. Din input er uvurderlig i vår kontinuerlige søken etter å tilby
            den best mulige tjenesten.
          </p>
        </Body1>      
        <Subtitle2>Tilbakemelding og forslag til funksjoner</Subtitle2>
        <Body1>
          <p>
            Klar for å dele? Dra over til vår {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Tilbakemeldingsside
            </Link>
            . Enten det er et forslag til en ny funksjon eller tilbakemelding på eksisterende funksjonaliteter, bare velg
            den passende alternativet fra nedtrekksmenyen i skjemaet. Dine bidrag hjelper til med å forme fremtiden til TeamConnect,
            og sikrer at den oppfyller og overgår dine behov.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
