import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>Ċentru ta' Għajnuna u Mistoqsijiet Frekwenti ta' TeamConnect</Title3>
        <p><Body1>
          Merħba fil-Ċentru ta' Għajnuna ta' TeamConnect, iddisinjat biex jappoġġja l-esperjenza tiegħek bl-app direttorju korporattiv komprensiv tagħna. Hawnhekk, issib riżorsi biex tottimizza l-użu tiegħek ta' TeamConnect fi ħdan il-Microsoft Teams, itejjeb il-komunikazzjoni madwar in-netwerk tiegħek.
        </Body1></p>
        <Subtitle2>Guwida ta' Referenza Mgħaġġla</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Kapaċitajiet ta' Tiftix Imtejba: </Body1Strong>
              Sib il-kollegi, id-dipartimenti, u l-kuntatti essenzjali xierqa malajr b'għodod ta' tiftix qawwija.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Grafika Organiżżattiva Viżwali: </Body1Strong>
              Navika l-ġerarkija tal-organizzazzjoni tiegħek u tifhem il-linji tar-rapport b'mod faċli permezz ta' grafika organiżżattiva intuwittiva.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Għodod Adattivi għall-Bżonnijiet tal-Lum: </Body1Strong>
              Uża funzjonalitajiet moderni ddisinjati skont id-domandi tal-postijiet tax-xogħol kontemporanji.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Ottimizza l-Esperjenza Tiegħek</Subtitle2>
        <Body1>
          <p>
            TeamConnect jirrevoluzzjona kif tinteraġixxi fl-istruttura organizzattiva tiegħek, jikkonverti l-komunikazzjonijiet ta' kuljum f'impenni strateġiċi.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Ikseb Għajnuna & Mistoqsijiet Frekwenti</Subtitle1>
        <Body1>
          <p>
            Sib tweġibiet għal mistoqsijiet komuni dwar il-karatteristiċi u s-settings ta' TeamConnect f'din it-taqsima ddedikata. Kemm jekk qed tinnaviga l-app, tippersonalizza s-settings, jew tfittex soluzzjonijiet għall-isfidi, iċ-Ċentru tal-Għajnuna tagħna hawn biex jassistik b'mod effiċjenti.
          </p>
        </Body1>
        <Body1>
          <p>
            Għal appoġġ aktar fil-fond jew gwida speċifika, jekk jogħġbok ikkonsulta l-<Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>Gwida għall-Utent ta' TeamConnect</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        <Subtitle2>Użu ta' TeamConnect FAQs</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Ma ssibx TeamConnect fil-sidebar tal-Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jekk TeamConnect mhuwiex viżibbli jew sparixxa mis-sidebar tiegħek fil-Microsoft Teams, tista' faċilment tiffissah għall-aċċess rapidu. Segwi dawn il-passi sempliċi:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Iftaħ Microsoft Teams u kklikkja fuq l-ikona '...' (+ Apps) fl-aħħar tas-sidebar.</li>
                    <li className={styles.bulletBottomMargin}>Fittex għal 'TeamConnect' fil-bar tat-tiftix fil-parti ta' fuq tat-tieqa pop-up.</li>
                    <li className={styles.bulletBottomMargin}>Agħfas il-lemin fuq l-app TeamConnect mir-riżultati tat-tiftix u agħżel 'Pin'.</li>
                  </ol>
                  TeamConnect issa se jkun iffissat mas-sidebar tiegħek, li jagħmilha aċċessibbli b'klikk waħda biss!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Tqabbad problemi tekniki ma' TeamConnect? Hawnhekk kif tista' tikseb għajnuna.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jekk tiltaqa' ma' xi problemi tekniki ma' TeamConnect ġewwa Microsoft Teams, it-tim tal-Appoġġ IT tal-organizzazzjoni tiegħek hawn biex jgħinek.
                  Ikkuntattjahom fuq <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, u se jassistuk b'mod pront.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Ma ssejbaħx id-dettalji tal-impjegat tiegħek?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jekk il-profil tiegħek jidher xi ftit vojt biss b'isemek u l-email tiegħek murija, huwa probabbli li t-tim tar-Riżorsi Umani tiegħek għadu ma żiedx il-bqija tad-dettalji tal-impjegat tiegħek għal TeamConnect.
                  M'hemmx għalfejn tinkwieta! Messaġġ malajr lit-tim tal-appoġġ tar-Riżorsi Umani tiegħek ser jibda l-proċess. Huma lesti biex jimlew il-profil tiegħek b'dettalji importanti kollha.
                  Ikkuntattjahom fuq <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> u ara l-profil tiegħek jieħu l-ħajja!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Liema għażliet ta' lingwa huma disponibbli f'TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect jilqa' d-diversità globali ta' Microsoft Teams billi jappoġġja
                  l-lingwi kollha tiegħu. Kemm jekk tippreferi l-Għarbi jew il-Grieg,
                  il-Ġappuniż jew l-Iżvediż, għandek dinja ta' għażliet f'ponta ta' swaba'
                  tiegħek, inklużi:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Għarbi, Bulgaru, Ċiniż (Simplifikat u Tradizzjonali), Kroat, Ċek,
                      Daniż, Olandiż, Ingliż, Estonjan, Finlandiż, Franċiż, Ġermaniż, Grieg,
                      Ebrajk, Ungeriż, Indoneżjan, Taljan, Ġappuniż, Korean, Latvjan,
                      Litwan, Norveġiż (Bokmal), Pollakk, Portugiż (il-Brażil u
                      l-Portugall), Rumen, Russu, Serb (Latin), Slovak, Sloven, Spanjol,
                      Żvediż, Tajlandiż, Tork, Ukren, Vjetnamiż.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Biex tippersonalizza l-esperjenza ta' TeamConnect tiegħek għall-lingwa
                  preferuta tiegħek, jekk jogħġbok aġġusta s-settings tal-lingwa direttament
                  f'Microsoft Teams, hekk kif TeamConnect jintegra kompletament ma' dawn
                  is-settings:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Mur fis-settings ta' Microsoft Teams tiegħek billi tikklikkja fuq
                      it-tliet punti ħdejn l-istampa tal-profil tiegħek fil-quċċata, u
                      mbagħad agħżel 'Appearance and accessibility'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Skrollja lejn is-sezzjoni 'Language' u agħżel il-lingwa preferuta
                      tiegħek mill-menu dropdown.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Ikkonferma l-bidliet tiegħek billi tikklikkja fuq il-buttuna 'Save and
                      restart' biex tapplika s-settings il-ġodda tal-lingwa.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Nota: </Body1Strong>
                <Body1>
                  Bidliet magħmula għas-settings tal-lingwa tiegħek f'Microsoft Teams se
                  jaġġornaw ukoll l-esperjenza tal-TeamConnect tiegħek b'mod bla xkiel. Kun
                  konxju li l-bidla tal-lingwa f'Teams tista' wkoll taġġusta l-format
                  taż-żmien u d-data tiegħek. Bħalissa, TeamConnect qed jevolvi biex isostni
                  kitba minn lemin għal xellug. Qed inħarsu 'l quddiem għal dawn
                  it-titjibiet u se nżommukom infurmati hekk kif isiru disponibbli.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Kif nista' nbiddel it-tema f'TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect jissinkronizza mat-tema ta' preferenzi tiegħek ta' Microsoft Teams, inklużi l-għażliet Light, Dark, u High Contrast. Biex tippersonalizza t-tema tiegħek:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Aċċessa s-settings ta' Microsoft Teams tiegħek billi tikklikkja fuq it-tliet punti ħdejn ir-ritratt tal-profil tiegħek fil-quċċata.</li>
                    <li className={styles.bulletBottomMargin}>Agħżel 'Appearance and accessibility' biex tesplora l-għażliet tat-tema.</li>
                    <li className={styles.bulletBottomMargin}>Agħżel it-tema li tippreferi mill-għażliet disponibbli biex taġġorna mill-ewwel l-interface ta' TeamConnect tiegħek biex taqbel.</li>
                  </ol>
                  L-għażla tat-tema tiegħek f'Microsoft Teams tiġi applikata awtomatikament għal TeamConnect, tiżgura esperjenza viżwali konsistenti madwar l-ispazju tax-xogħol tiegħek.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Esplorazzjoni tal-Buttuni ta' Azzjoni tal-Kuntatt ta' TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Matul TeamConnect, kull fejn issib dettalji ta' kuntatt ta' impjegat, tiskopri wkoll il-buttuni ta' aċċess rapid tagħna. Dawn l-għodod utli 
                  huma ddisinjati biex jissimplifikaw il-komunikazzjoni tiegħek ġewwa l-ekosistema ta’ Microsoft, u jiżguraw li dejjem tkun klikk waħda 'l bogħod milli tikkonnettja. 
                  Hawnhekk għandek gwida komprensiva biex tuża dawn il-buttuni b'mod effettiv:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chat Icon:</Body1Strong> Ikklikkja biex tibda chat istantanju ta' Microsoft Teams. Jekk trid tirritorna għal TeamConnect wara li tibda chat, sempliċiment uża l-vleġġa lura fl-App tad-Desktop, il-buttuna lura fl-App Mobbli, jew il-buttuna lura tal-browser tiegħek.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Email Icon:</Body1Strong> Jiftaħ abbozz ġdid ta' email ta' Outlook bil-indirizz tar-riċevitur diġà fil-qasam "To:" . Jekk Outlook ma jniedix, ikun tajjeb li tivverifika mal-kumpanija tal-IT tiegħek għal xi restrizzjonijiet.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Video Call Icon:</Body1Strong> Tniedi sejħa bil-video wiċċ imb wiċċ permezz ta' Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Call Icon:</Body1Strong> Tibda sejħa bil-vuċi ta' Microsoft Teams, li tagħmel diskussjonijiet malajr mingħajr xkiel.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org Chart Icon:</Body1Strong> Turi l-post tal-impjegat fl-ierarkija organizzattiva, inkluż il-maniġer tiegħu u r-rapporti diretti.</li>
                    <li><Body1Strong>More (...) Icon:</Body1Strong> Tiżvela aktar għażliet:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Meet Now:</Body1Strong> Jiftaħ Microsoft Teams biex tibda laqgħa mill-ewwel mal-impjegat.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>New Meeting:</Body1Strong> Jidderieġik lejn Microsoft Teams biex tiskeda laqgħa ġdida.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Add Contact:</Body1Strong> Iżid id-dettalji ta' kuntatt tal-impjegat minn TeamConnect mal-app ġdida ta' Nies ta' Microsoft Teams, u jtejjeb il-funzjoni tas-sejħa tiegħek.</li>
                      </ul>
                    </li>
                  </ul>
                  Dawn il-buttuni intuwittivi huma maħduma biex jiżguraw li tista' tikkonnettja ma' kollegi b'mod effiċjenti, kemm għal chat malajr, email, jew biex tiskeda laqgħat futuri.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Tagħmel sejħiet permezz ta' TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tixtieq tagħmel sejħa? TeamConnect jissimplifika l-komunikazzjoni tiegħek billi jintegra ma' Microsoft Teams. 
                  Sempliċement ikklikkja fuq in-numru tat-telefon mixtieq f'TeamConnect, u voilà, l-interface tas-sejħa ta' Microsoft Teams jidher,
                  lest biex inti ċċempel b'mod bla xkiel.
                  <p>
                  <Body1Strong>Nota: </Body1Strong> 
                  Jekk tissograw problemi biex tagħmel sejħiet? Dan jista' jkun minħabba l-politiki tal-IT tal-organizzazzjoni tiegħek jew l-użu ta' sistema tat-telefon differenti minn Microsoft Teams Phone System. Li tiċċekkja mal-kumpanija tal-IT tiegħek fuq <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, jista' jagħtik aktar informazzjoni dwar il-kwistjoni.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Hemm spejjeż assoċjati ma' sejħiet permezz ta' TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect itejjeb l-esperjenza tat-telefonati tiegħek billi juża Microsoft Teams. Hawn dak li għandek bżonn tkun taf dwar l-ispejjeż relatati mat-telefonati:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Għal sejħiet lejn telefowns tal-ħidma tal-impjegati, telefowns tad-dipartiment, jew hotlines ġewwa l-organizzazzjoni tiegħek (numri interni), 
                      tipikament <Body1Strong>m'hemmx spejjeż</Body1Strong> meta jintuża s-Sistema tat-Telefon ta' Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Għal sejħiet esterni, bħal dawk lejn mobiles jew linji esterni, japplikaw <Body1Strong>rati standard</Body1Strong> tas-sistema tat-telefon tal-organizzazzjoni tiegħek. 
                      Dawn ir-rati jvarjaw skont il-pjan tas-servizz speċifiku tiegħek ma' Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Nota:</Body1Strong> Jekk tiltaqa’ ma’ xi problemi jew għandek mistoqsijiet dwar ħlasijiet ta' sejħiet, huwa aħjar li tikkonsulta mad-dipartiment tal-IT tiegħek fuq <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> għal informazzjoni dettaljata 
                    mkejla għat-twaqqif u l-politiki tal-organizzazzjoni tiegħek.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Issejbaħ nuqqas ta' konformità mal-istatus 'Out of Office' fil-preżenza tiegħek f’TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Did you notice that your 'Out of Office' presence status from Microsoft 365 isn't being reflected correctly in TeamConnect? 
                  We've got you covered. The feature to synchronize 'Out of Office' presence status has already been implemented in TeamConnect.
                  We're currently waiting for Microsoft's rollout of this feature. Once that happens, your 'Out of Office' presence status 
                  will be accurately mirrored in TeamConnect, aligning with Microsoft Teams' presence indicators for a seamless experience.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>Mistoqsijiet Frekwenti tad-Direttorju tal-Impjegati</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Introduzzjoni tad-Direttorju tal-Impjegati:</Body1Strong> Naviga l-pajsaġġ professjonali tal-organizzazzjoni tiegħek bid-Direttorju tal-Impjegati, 
                l-ispazju ċentrali tiegħek għal għarfien dettaljat fuq l-impjegati, id-dinamika tax-xogħol, u konnettività diretta mal-profili u r-relazzjonijiet managerjali tal-kollegi tiegħek.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Bżonn iżżid, taġġorna, jew tikkoreġi d-data tal-impjegati tiegħek?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Biex iżżid, taġġorna, jew tikkoreġi d-data tal-impjegati tiegħek f'TeamConnect, jekk jogħġbok ikkuntattja t-tim ta' appoġġ tar-riżorsi umani tal-organizzazzjoni tiegħek. 
                  Huma lesti li jgħinuk fuq <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. X'jiġri meta nikklikkja fuq isem tal-impjegat fid-direttorju?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Meta tikklikkja fuq isem impjegat fid-Direttorju tal-Impjegati ta' TeamConnect, tinfetaħ paġna dettaljata tal-profil għal dak l-individwu. Dan il-profil jipprovdi ħarsa komprensiva 
                  dwar l-impjegat, inkluż iżda mhux limitat għat-titlu tax-xogħol, id-dipartiment, il-lokazzjoni tal-uffiċċju, informazzjoni ta' kuntatt, u aktar. Huwa mfassal biex jagħtik 
                  ħarsa sħiħa dwar ir-rwol professjonali tal-impjegat u kif dan jidħol fl-organizzazzjoni, u jagħmlu aktar faċli għalik biex tifhem ir-responsabbiltajiet tiegħu u 
                  kif tista' tikkoopera miegħu.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Liema informazzjoni tinkludi l-profil ta' impjegat?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Kull profil ta' impjegat f'TeamConnect huwa instantanea komprensiva, li toffri għarfien kbir fuq il-ħajja professjonali tal-individwu. 
                  Hawn xi ssib:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Detalji Bażiċi:</Body1Strong> Isem, titlu tax-xogħol, dipartiment, u lokazzjoni tas-sussidjarju.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Struttura tar-Rappurtar:</Body1Strong> Informazzjoni dwar ir-rapporti diretti u funzjonali, 
                      inklużi l-maniġers tal-linja u funzjonali.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Status tal-Post tax-Xogħol:</Body1Strong> Status attwali tax-xogħol bħal Leave, Commute, 
                      Business-Travel, eċċ.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Post tax-Xogħol & Ħin Lokali:</Body1Strong> Il-post attwali tax-xogħol tal-impjegat u 
                      ż-żona tal-ħin lokali.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Detalji ta' Kuntatt:</Body1Strong> Telefon tax-xogħol, numru tal-mowbajl, u indirizz elettroniku.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Indirizz tal-Uffiċċju:</Body1Strong> Post sħiħ tal-uffiċċju inkluż isem tal-bini,
                      l-indirizz, u Google map.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Dwar:</Body1Strong> Introduzzjoni personali qasira jew bijo professjonali.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Relazzjonijiet tal-Impjegati:</Body1Strong> Detalji tal-istruttura tar-rapportar tal-impjegat u r-relazzjonijiet mat-tim.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Links Soċjali & Lingwi:</Body1Strong> Links għall-profili soċjali professjonali 
                      u kompetenzi lingwistiċi.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ħiliet:</Body1Strong> Lista ta' ħiliet professjonali u żoni ta' espertizz.</li>
                  </ul>
                  Barra minn hekk, il-profil joffri għażliet azzjonabbli biex tikkonnettja permezz ta' chat, email, video call, jew tiskeda laqgħat, flimkien ma' 
                  aċċess mgħaġġel għall-grafika tal-organizzazzjoni għal kontest aktar wiesa' tat-tim.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Kif nista' naġġorna l-informazzjoni tal-profil tiegħi?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Tippersonalizza l-profil tiegħek huwa faċli f'TeamConnect. Hawn kif tista' tagħmel dan:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Mur fil-<Body1Strong>Aba tal-Impjegati</Body1Strong> u kklikkja fuq l-<Body1Strong>Avatar</Body1Strong> tiegħek biex tiftaħ il-profil tiegħek.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Fir-rokna ta' fuq tax-xellug, issib il-link <Body1Strong>"Editja l-profil tiegħek"</Body1Strong>. 
                        Ikklikkja dan biex tiftaħ id-djalogu tal-editjar għall-profil tiegħek.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Hawnhekk, tista' ttejjeb il-profil tiegħek billi żżid jew taġġorna s-sezzjoni <Body1Strong>Dwarek</Body1Strong>, links tal-midja soċjali, u numru tat-telefon.
                        Dan jagħmilha aktar faċli għall-kollegi tiegħek biex jikkonnettjaw jew isegwuk.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Tinsiex tagħfas il-buttuna <Body1Strong>Save</Body1Strong> biex tapplika l-bidliet tiegħek.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Skrollja 'l isfel fid-djalogu biex iżżid il-<Body1Strong>Lingwi</Body1Strong> u l-<Body1Strong>Proficiency</Body1Strong>,
                        kif ukoll il-<Body1Strong>Skills</Body1Strong> tiegħek. Li taqsam din l-informazzjoni tgħin lill-kollegi tiegħek jifhmu
                        l-oqsma tal-espertizz tiegħek u l-lingwi li tista' tikkomunika bihom.
                      </li>
                    </ol>
                  L-aġġornament tal-profil tiegħek mhux biss iżomm l-informazzjoni tiegħek kurrenti iżda wkoll jippromwovi konnessjonijiet aħjar fi ħdan l-organizzazzjoni tiegħek.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Taġġorna l-Post tax-Xogħol, Post tax-Xogħol, u l-Ħin f'TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Iż-żamma tal-informazzjoni dwar il-post tax-xogħol, il-post tax-xogħol, u l-ħin kurrent hija kruċjali għall-kollaborazzjoni bla xkiel fil-Microsoft Teams. 
                  Dawn l-aġġornamenti jirriflettu minnufih fil-profil u l-listi TeamConnect tiegħek, li jippermetti lill-kollegi jifhmu malajr il-post tax-xogħol tiegħek 
                  u l-aħjar ħinijiet biex jikkonnettjaw miegħek, li jtejjeb it-timijiet tax-xogħol f'reġjuni u żoni tal-ħin differenti.
                  <p></p>
                  Lest biex taġġorna d-dettalji tiegħek? Huwa faċli f'TeamConnect! Mur fuq 'Settings' fil-Menu ta' fuq u naviga lejn 'General Settings'. 
                  Hawnhekk, tista' faċilment taġġorna l-Post tax-Xogħol u l-Ħin, u tispeċifika l-Post tax-Xogħol tiegħek għall-jum. Ftakar li ssalva l-bidliet tiegħek biex 
                  iżżomm lil kulħadd infurmat u tiżgura kollaborazzjoni effettiva, irrispettivament mill-post tiegħek!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Lesti xi ħaġa barra fil-profil ta' kollega?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jekk tiltaqa' ma' xi dettalji fil-profil ta' kollega li ma jidhrux tajbin, l-ewwel pass aħjar huwa li tagħti indikazzjoni ħbiberija lill-persuna inkwistjoni.
                  Ċajta jew email malajr għandha tagħmel it-trik! Jekk għal xi raġuni l-informazzjoni ma tiġix aġġornata, tinkwetax—sempliċement ibgħat 
                  lill-tim tal-appoġġ HR tiegħek fuq <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, u huma se jkunu kuntenti 
                  li jidħlu u jirranġaw l-affarijiet.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Feħim tal-Gruppi ta' Tags: Kollha, Favoriti, Dawk Illoggjati, u Impjegati Ġodda
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kollha:</Body1Strong> Dan it-tag default juri kull impjegat fl-organizzazzjoni tiegħek, 
                      li jipprovdi veduta komprensiva tat-tim.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoriti:</Body1Strong> Ikklikkja hawn biex tiffiltra l-veduta għal dawk il-kollegi li markajt 
                      bħala 'Favoriti,' jagħmilha aktar faċli biex tikkonnettja malajr ma' kuntatti ewlenin.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Illoggjati:</Body1Strong> Dan it-tag jindika l-impjegati li bħalissa qegħdin jaħdmu, li jagħmilhom 
                      distinti minn dawk li sempliċiment huma online fuq Microsoft Teams. Meta jkunu illoggjati, l-impjegati jsibu sinjal li huma fuq ix-xogħol u jaqsmu l-lokazzjoni 
                      attwali tax-xogħol tagħhom matul il-ġurnata. Dan jista' jinkludi varjetà ta' postijiet tax-xogħol bħal Uffiċċju, Uffiċċju tal-Qasam, Uffiċċju-Dar, Post-Remote, 
                      Post tal-Klijent, Uffiċċju Privat tal-Klijent, Żona Pubblika, Pendolar, Ivvjaġġar tan-Negozju, jew anke Taħriġ. Għalhekk, l-istatus 'Illoggjati' jipprovdi indikazzjoni ċara 
                      li wieħed qiegħed attivament fuq ix-xogħol, joffri lill-kollegi fehim preċiż tal-kuntest u d-disponibbiltà attwali tax-xogħol ta' xulxin.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Impjegati Ġodda:</Body1Strong> Jinduna wiċċijiet ġodda f'TeamConnect b'dan it-tag, 
                      li jenfasizza l-impjegati li ġew miżjuda mal-app fl-aħħar 30 jum. Tipikament, dan jaqbel ma' żidiet riċenti fl-organizzazzjoni tiegħek, 
                      li jgħinek tilqa’ membri ġodda tat-tim.
                    </li>
                  </ul>
                  Dawn il-gruppi ta' tags huma ddisinjati biex jissimplifikaw in-navigazzjoni u jtejbu l-esperjenza tiegħek ma' TeamConnect, biex tiżgura li dejjem 
                  tista' ssib u tikkonnettja mal-kollegi tiegħek b'mod effiċjenti.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Dekowdifikar tal-Ikoni Ħdejn il-Kaxxa tat-Tiftix
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Id-Direttorju tal-Impjegati ta' TeamConnect huwa ddisinjat għal versatilità u faċilità, li jinkludi erba' ikoni li joffru veduti differenti 
                  u aċċess mgħaġġel għad-dettalji ewlenin. Hawn xi jinfetaħ kull ikona:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Veduta tal-Lista:</Body1Strong> Il-veduta tiegħek ta' kuljum, tippreżenta l-membri kollha tal-organizzazzjoni 
                      f'lista sempliċi.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Veduta ta' Karta Żgħira:</Body1Strong> Alternattiva kompatta, din il-veduta tittrasforma l-
                      lista f'karti żgħar tal-impjegati.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Veduta ta' Karta Kbira:</Body1Strong> Agħżel aktar dettall b'karti akbar, li jinkludu 
                      għażliet ta' kuntatt addizzjonali bħal numri tat-telefon u mobbli għal sejħiet faċli.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Karta tal-Organizzazzjoni:</Body1Strong> Viżwalizza l-istruttura tal-kumpanija bil-veduta 
                      tal-Karta tal-Organizzazzjoni, perfetta biex tifhem ir-relazzjonijiet ta' rapportar u tinnaviga l-pajsaġġ organizzattiv.</li>
                  </ol>
                    Biddel bejn dawn l-ikoni biex issib il-veduta li taqbel l-aħjar mal-bżonnijiet tiegħek f'dak il-mument, kemm jekk qed tfittex informazzjoni malajr jew fehim aktar fil-fond.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Għal tiftix malajr ta' kollegi, fejn għandi nħares?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Qed tfittex kollega? Mur sempliċement għall-kaxxa tat-tiftix ta' TeamConnect, li tinsab fuq in-naħa ta' fuq tal-lemin ħdejn l-ikona tal-karta tal-org. 
                  Ibda tikteb l-isem tagħhom, u osserva kif ir-riżultati jirfinaw b'kull ittra. Ftakar, għat-tfittxijiet fuq TeamConnect, evita l-bar tat-tiftix ta' Microsoft Teams 
                  fil-quċċata—il-kaxxa speċjali tat-tiftix tagħna hija t-triq li għandek tmur!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Kif taħdem it-tfittxija alfabetika biex issib impjegati?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Id-Direttorju tal-Impjegati ta' TeamConnect jinkludi karatteristika ta' tfittxija alfabetika konvenjenti biex jgħinek issib malajr impjegati skont l-ewwel isem tagħhom.
                  Sempliċement ikklikkja fuq ittra mill-alfabett murija fil-quċċata tad-direttorju, u TeamConnect minnufih se juri lilek l-impjegati kollha
                  li l-ewwel isimhom jibda b'dik it-tra. Biex tirritorna għal-lista sħiħa tal-impjegati, sempliċement deselezzjona l-ittra enfasizzata. Din il-karatteristika intuwittiva
                  tagħmel in-navigazzjoni permezz ta' listi kbar ta' impjegati faċli, u tiżgura li tista' ssib kollegi malajr u b'mod effiċjenti.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Kif nista' naġġusta l-għadd ta' impjegati murija għal kull paġna?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect jippermettilek li tippersonalizza kemm profili tal-impjegati tara għal kull paġna, biex tagħmelha aktar faċli biex tinnaviga mad-Direttorju tal-Impjegati.
                  Fil-qiegħ ta' kull paġna, issib għażliet ta' paġinazzjoni: 10, 50, 100, 500, 1000, u 5000. L-issettjar default juri 10 profili għal kull paġna.
                  Biex tara aktar profili fuq paġna waħda, sempliċement agħżel in-numru preferut minn dawn l-għażliet. Din il-karatteristika tipprovdi flessibilità kif tibbrawżja
                  d-direttorju, u tħallik tadatta l-wiri għall-bżonnijiet speċifiċi jew preferenzi tiegħek.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Kif nista' norganizza l-informazzjoni tal-impjegati fil-List View?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Fil-List View tad-Direttorju tal-Impjegati ta' TeamConnect, għandek il-flessibilità li torganizza l-informazzjoni tal-impjegati skont il-bżonnijiet tiegħek. Il-kolonni—l-Isem tal-Impjegat,
                  t-Titolu tax-Xogħol, id-Dipartiment, il-Post tax-Xogħol, u l-Post tal-Impjieg—kollha huma organizzabbli. Sempliċement ikklikkja fuq l-intestatura tal-kolonna li trid torganizza. 
                  Tikklikkja darba se torganizza l-kolonna f'ordni ta' tqassim, u tikklikkja mill-ġdid se torganizza f'ordni ta' niżżil. B'default, id-direttorju huwa organizzat skont l-Isem tal-Impjegat f'ordni 
                  ta' tqassim, ibbażat fuq l-ewwel isem. Din il-karatteristika ta' torganizzar tgħinek torganizza u tara d-dettalji tal-impjegati b'mod rapidu b'mod li jaqdi l-aħjar il-għan tiegħek, 
                  sew jekk qiegħed tfittex xi ħadd speċifiku jew teħtieġ li tgruppa l-impjegati skont kriterju partikolari bħal dipartiment jew post.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Mistoqsijiet Komuni tad-Direttorju tad-Dipartimenti</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Introduzzjoni tad-Direttorju tad-Dipartimenti:</Body1Strong> Id-Direttorju tad-Dipartimenti huwa l-bieb tiegħek biex tifhem ix-xibka kumplessa tal-
              struttura tal-organizzazzjoni tiegħek, u joffri veduta ċara tal-ġerarkiji dipartimentali, it-tmexxija maniġerjali, u l-avvenimenti għal komunikazzjoni dipartimentali bla xkiel.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Kif jaħdmu t-tikketti 'Kollha', 'Favoriti', u 'Id-Dipartiment Tiegħek' fid-Direttorju tad-Dipartimenti?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Fil-Direttorju tad-Dipartimenti ta' TeamConnect, it-tikketti jgħinuk tinnaviga u tiffiltra malajr l-informazzjoni dipartimentali. Dan huwa x'jirrappreżenta kull tikketta:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kollha:</Body1Strong> Dan it-tikkett juri l-dipartimenti kollha fl-organizzazzjoni tiegħek, u jipprovdi 
                      ħarsa ġenerali komprensiva.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoriti:</Body1Strong> Uża dan it-tikkett biex tara lista kkurata tad-dipartimenti li kklassifikajt
                      bħala 'Favoriti', u jagħmilha aktar faċli biex taċċessa d-dipartimenti li tinteraġixxi magħhom spiss.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Id-Dipartiment Tiegħek:</Body1Strong> Dan it-tikkett jiffiltra l-wiri biex jurik biss id-dipartiment tiegħek, 
                      u jippermetti aċċess mgħaġġel għall-informazzjoni u l-aġġornamenti immedjati tad-dipartiment tiegħek.</li>
                  </ul>
                  Dawn it-tikketti huma maħsuba biex jissimplifikaw in-navigazzjoni tiegħek fid-Direttorju tad-Dipartimenti, u jtejbu l-ħila tiegħek li ssib u tinteraġixxi mal-informazzjoni 
                  dipartimentali b'mod effiċjenti.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Il-fehim tal-Veduti tad-Direttorju tad-Dipartimenti f'TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  In-navigazzjoni fid-Direttorju tad-Dipartimenti f'TeamConnect hija sempliċifikata b'għażliet ta' veduta multipli biex jaqblu mal-preferenzi tiegħek. Kull veduta toffri mod 
                  uniku kif tesplora l-informazzjoni dipartimentali komprensiva, inkluż l-Isem tad-Dipartiment, il-Maniġer tad-Dipartiment, id-Dipartiment tal-Ġenituri, it-Telefon tad-Dipartiment, 
                  il-Hotline tad-Dipartiment, u l-Email tad-Dipartiment. Hawnhekk hemm ħarsa lejn il-veduti disponibbli:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Veduta tal-Lista:</Body1Strong> This should not be translated.
                      l-issettjar default li ġġib il-lista tad-dipartimenti kollha, tipprovdi ħarsa ċara u konċiża.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Veduta tal-Karta Kbira:</Body1Strong> Aqleb għal din il-veduta biex tara karti dettaljati tad-dipartimenti, li joffru 
                      sommarju espansiv tal-informazzjoni ewlenija ta' kull dipartiment.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Veduta tal-Chart tal-Org:</Body1Strong> Għal rappreżentazzjoni viżwali tal-istruttura dipartimentali u 
                      ir-relazzjonijiet, il-veduta tal-Chart tal-Org turi kif kull dipartiment jidħol fl-ierarkija organizzattiva akbar.
                    </li>
                  </ul>
                  Biex tbiddel bejn dawn il-veduti, sempliċement uża l-ikoni ħdejn il-kaxxa tat-tfittxija fil-quċċata tad-Direttorju tad-Dipartimenti. Kull veduta hija mfassla biex tgħinek 
                  taċċessa u tifhem id-dettalji dipartimentali b'mod faċli, u tiżgura li ssib l-informazzjoni li għandek bżonn fil-format li jaħdem l-aħjar għalik.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Kif tuża l-Kaxxa tat-Tiftix fid-Direttorju tad-Dipartiment
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Sib b'mod lixx kwalunkwe dipartiment fi TeamConnect billi tuża l-kaxxa tat-tiftix iddedikata li tinsab ħdejn l-ikoni tal-wiri fil-quċċata tad-
                  Direttorju tad-Dipartiment. Ibda tittajpja sempliċement l-isem tad-dipartiment li qed tfittex, u osserva kif id-direttorju jraffina lilu nnifsu biex 
                  juri biss logħbiet rilevanti. Din il-karatteristika hija mfassla apposta biex tgħinek tevita l-ħtieġa li tfittex manwalment permezz tad-direttorju 
                  kollu, ġġib id-dipartiment li għandek bżonn direttament għal ponot subgħajk. Ftakar, din il-funzjonalità tat-tiftix hija unika għad-Direttorju tad-Dipartiment 
                  ta' TeamConnect, għalhekk kun żgur li tuża din il-kaxxa tat-tiftix minflok it-tiftix ewlieni ta' Microsoft Teams fil-quċċata tal-interface tat-Teams għal 
                  mistoqsijiet speċifiċi tad-dipartiment.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Kif jaħdem il-filtru alfabettiku fid-Direttorju tad-Dipartiment?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Semplifika t-tfittxija tiegħek fid-Direttorju tad-Dipartiment bil-filtru alfabettiku li jinsab fuq nett. Din il-karatteristika tippermettilek li tgħaddas malajr 
                  id-dipartimenti billi tagħżel ittra. Ladarba tagħżel ittra, TeamConnect jiffiltra u juri biss dawk id-dipartimenti li l-ismijiet tagħhom jibdew 
                  bittra magħżula tiegħek, li jagħmilha aktar faċli biex issib dak li qed tfittex mingħajr ma jkollok tgħaddi mill-lista kollha. Biex tirritorna għal 
                  veduta sħiħa tad-dipartiment, sempliċement deselezzjona l-ittra enfasizzata. Din in-navigazzjoni alfabettika intuwittiva tiżgura li tista' ssib b'mod effiċjenti 
                  kwalunkwe dipartiment permezz tal-ittra inizjali tiegħu.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Kif nista' nikkuntattja lill-maniġer tad-dipartiment?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ikkuntattjar tal-maniġer tad-dipartiment f'TeamConnect huwa sempliċi. Semplicement isib id-dipartiment ta' interess u ikklikkja fuq l-isem tal-maniġer. Dan 
                  l-attiv jattiva popover li juri l-għażliet ta' kuntatt tal-maniġer b'ikoni ta' aċċess mgħaġġel:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chat Icon:</Body1Strong> Biex tibda messaġġ istantanju permezz tal-chat ta' Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Email Icon:</Body1Strong> Biex tiftaħ email ġdida indirizzata lilhom f'Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Video Call Icon:</Body1Strong> Biex tibda sejħa bil-vidjo permezz ta' Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Call Icon:</Body1Strong> Biex tibda sejħa bil-vuċi permezz ta' Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>More (...) Icon:</Body1Strong> Għal għażliet addizzjonali bħal skedar ta' laqgħa jew żieda tal-
                      informazzjoni ta' kuntatt tagħhom mal-lista tat-Teams tiegħek.
                    </li>
                  </ul>
                  Dawn l-ikoni jipprovdu metodu mingħajr xkiel biex tilħaq il-maniġers tad-dipartiment direttament minn ġewwa TeamConnect, u jiżguraw komunikazzjoni effiċjenti 
                  fl-organizzazzjoni tiegħek.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Kif nista' nara jew nikkuntattja impjegati ġewwa dipartiment?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Biex tesplora min hu min ġewwa dipartiment f'TeamConnect, ħares lejn it-tliet dots li jinsabu ħdejn l-isem tad-dipartiment. Billi tikklikkja fuq dan se 
                  tiftaħ għażliet għal aktar azzjoni:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ara l-Impjegati f'Dan id-Dipartiment:</Body1Strong> Agħżel din l-għażla biex turi lista ta' 
                      l-impjegati kollha assoċjati mad-dipartiment. In-numru maġenb din l-għażla jindika kemm hemm impjegati fid-dipartiment, tipprovdi għadd malajr tar-ras.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Grafika tal-Organizzazzjoni tad-Dipartiment:</Body1Strong> Jekk tixtieq tifhem il-ġerarkija tad-dipartiment, 
                      agħżel 'Grafika tal-organizzazzjoni tad-dipartiment' biex turi d-dispożizzjoni strutturali, turi r-relazzjoni bejn id-dipartimenti ewlenin, id-dipartiment inkwistjoni, 
                      u kwalunkwe sottodipartimenti. Din ir-rappreżentazzjoni viżwali tgħinek tinnaviga u tifhem il-qafas organizzattiv, u tagħmilha aktar faċli biex tidentifika u 
                      tikkuntattja membri speċifiċi tad-dipartiment.
                    </li>
                    <li><Body1Strong>Dipartiment {'>'} Impjegat:</Body1Strong> Wara li tkun rajt l-impjegati ġewwa dipartiment, uża din it-traċċa ta' breadcrumbs biex tanaviga lura 
                      għal-lista tad-dipartimenti b'mod faċli, u żżomm esperjenza ta' esplorazzjoni bla xkiel ġewwa TeamConnect.
                    </li>
                  </ul>
                  Dawn il-karatteristiċi jippermettulek mhux biss li tara l-lista ta' impjegati f'dipartiment partikolari iżda wkoll joffru triq biex tilħaqhom direttament permezz tal-għodod 
                  ta' komunikazzjoni ta' TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Skopri Detalji Profondi tad-Dipartiment f'TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Saħħaħ il-komponenti speċifiċi ta' kwalunkwe dipartiment billi sempliċiment tikklikkja fuq l-isem tiegħu f'TeamConnect. Djalogu dettaljat jinfetaħ, joffri tapit rikk ta' 
                  informazzjoni li jpinġi stampa sħiħa tar-rwol u r-riżorsi tad-dipartiment ġewwa l-organizzazzjoni tiegħek. Tista' ssib kollox mis-sejbiet dwar it-tmexxija u identifikaturi finanzjarji 
                  għal kanali ta' komunikazzjoni u links soċjali. Din il-karatteristika hija l-bieb tiegħek għal:
                  <ul>
                       <li className={styles.bulletBottomMargin}>It-taħbit tal-qalb tad-dipartiment – l-iskop, in-nies, u l-metriċi tal-prestazzjoni tiegħu.</li>
                       <li className={styles.bulletBottomMargin}>Movijiet ta' kuntatt – linji diretti lejn figuri dipartimentali ewlenin u pjattaformi ta' komunikazzjoni.</li>
                       <li className={styles.bulletBottomMargin}>Il-web organizzattiv – nifhmu kif id-dipartiment jintegra mal-istruttura usa' tal-kumpanija.</li>
                     </ul>
                  Kemm jekk għandek mistoqsija malajr jew domanda profonda dwar id-data tad-dipartiment, TeamConnect jagħtik l-għodod biex tikkonnektja u tifhem l-ekosistemi uniċi ġewwa l-organizzazzjoni tiegħek.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Kurjuż Dwar in-Nuqqas ta' Indirizzi tad-Dipartiment f'TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Is-sejba tal-post ta' dipartiment tista' ħafna drabi tħossha bħal kaċċa għal teżor mingħajr mappa. F'TeamConnect, normalment ma ssibx indirizz dedikat għal kull dipartiment. Hawn għaliex dan id-dettall kartografiku ħafna drabi jitħalla barra:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Postijiet Multipli:</Body1Strong> Ħafna dipartimenti mhumiex limitati għal spazju wieħed; huma
                      jiffjorixxu f'uffiċini u ġeografiji varji.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Struttura Fluwida:</Body1Strong> Il-pajsaġġ organizzattiv huwa dejjem qed jinbidel, b'dipartimenti
                      jevolvu, jingħaqdu, jew saħansitra jinħallu b'konformità ma' bidliet strateġiċi.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kollaborazzjoni Interdipartimentali:</Body1Strong> Il-postijiet tax-xogħol moderni jżommu spirtu ta' 
                      kollaborazzjoni, spiss jikkombinaw talenti minn dipartimenti varji f'spazji maqsuma.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kultura tax-Xogħol mill-Bogħod:</Body1Strong> Bil-flessibilità tax-xogħol mill-bogħod, l-ankri tad-dipartimenti 
                      mhumiex dejjem miżjuda f'postijiet fiżiċi tal-uffiċini.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nwanċi Legali:</Body1Strong> Id-dipartimenti ġeneralment jorbitaw madwar l-istruttura legali tal-kumpanija
                      aktar milli jkollhom l-indirizzi tagħhom stess.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Streamlining Operattiv:</Body1Strong> Iż-żamma tal-fokus fuq it-timijiet dinamiċi, mhux fuq indirizzi statiċi, 
                      tgħin biex tinżamm l-aġilità operattiva.
                    </li>
                  </ul>
                  Huwa kollu dwar li tadotta approċċ mingħajr fruntieri għat-timijiet u l-effiċjenza. TeamConnect jirrifletti din il-filosofija billi jenfasizza l-konnessjonijiet aktar milli l-postijiet.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Qed jonqslek jew m'għandekx l-informazzjoni tad-dipartiment tiegħek?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Edituri tad-dipartiment huma nominati fl-organizzazzjoni tiegħek biex iżidu, jaġġornaw, jew jikkoreġu informazzjoni speċifika għad-dipartiment f' TeamConnect.
                  Jekk m'intix ċert min hu l-editur tad-dipartiment tiegħek jew jekk ċerta informazzjoni teħtieġ l-intervent tat-tim ta' appoġġ HR,
                  jekk jogħġbok ikkuntattja <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> għal gwida.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Mistoqsijiet Frekwenti tad-Direttorju tal-Uffiċċji</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Introduzzjoni tad-Direttorju tal-Uffiċċji:</Body1Strong> Skopri l-iskeletru tal-preżenza fiżika tal-organizzazzjoni tiegħek permezz tad-Direttorju tal-Uffiċċji,
                li jinkludi dettalji essenzjali tal-uffiċċji, speċifiċitajiet operattivi, u ħarsa komprensiva lejn id-dipartimenti u l-persunal li jiddefinixxu kull post.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Innaviga t-Tikketti fid-Direttorju tal-Uffiċċji
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Sib triqtek madwar id-Direttorju tal-Uffiċċji b'faċilità permezz tas-sistema tag tagħna maħsuba għal aċċess rapidu għall-post it-tajjeb. Hawn kif kull tag jista' jgħin biex tissimplifika t-tfittxija tiegħek:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kollha:</Body1Strong> Juri elenku sħiħ tal-uffiċċji, li tipprovdi perspettiva ġenerali tal-postijiet
                      tal-organizzazzjoni tiegħek.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoriti:</Body1Strong> Jgħinek tiffoka fuq dawk il-postijiet tal-uffiċċji li tattendi spiss jew li għandek bżonn
                      iżżomm taħt għajnejk, billi timmarkahom bħala 'Favoriti'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>L-Uffiċċju Tiegħek:</Body1Strong> Dan it-tag personali jiffiltra d-direttorju biex juri l-lok
                      tal-uffiċċju fejn int stazzjonat, iżomm il-bażi tad-dar tiegħek fil-ponta tas-swaba' tiegħek.
                    </li>
                  </ul>
                  Kemm jekk qed tibbukkja kamra għal-laqgħat jew tippjana żjara, dawn it-tikketti joffru mod personalizzat u semplifikat biex tinteraġixxi mal-pajsaġġ
                  ġeografiku tal-organizzazzjoni tiegħek.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Għoti ta' Kunċett lill-Views tad-Direttorju tal-Uffiċċji f'TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Esplora l-istruttura organizzativa tal-postijiet varji tal-kumpanija tiegħek permezz tal-views tad-Direttorju tal-Uffiċċji ta' TeamConnect. Kull view huwa maħdum biex jiffaċilita 
                  aspett uniku tan-navigazzjoni u l-fehim tal-uffiċċju:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>View tal-Lista:</Body1Strong> L-issettjar default li jorganizza b'mod pulit il-lokazzjonijiet kollha tal-uffiċċji f'lista faċli biex tinqara.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>View tal-Karta Kbira:</Body1Strong> Il-View tal-Karta Kbira fid-Direttorju tal-Uffiċċji jtejjeb il-wiri tiegħek b'preżentazzjoni dettaljata ta' kull uffiċċju. Meta tingaġġa ma' karta tal-uffiċċju, issib tagħrif abbundanti u għażliet ta' azzjoni 
                      fil-menu "More (...)":
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>View tad-Dipartimenti:</Body1Strong> Innaviga direttament lejn lista
                          tal-dipartimenti kollha li jinsabu fl-uffiċċju.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>View tal-Impjegati:</Body1Strong> Aċċessa r-reġistru sħiħ tal-impjegati assenjati għall- 
                          uffiċċju, li jiffaċilita kuntatt dirett jew esplorazzjoni tal-persunal tal-uffiċċju.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Business Entity Org Chart:</Body1Strong> Visualizza l-post tal-uffiċċju fi ħdan il-kuntest organizzattiv akbar għal fehim ċar tal-istrutturi ġerarkiċi.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Kuntatti Essenzjali:</Body1Strong> Identifika l-kuntatti ewlenin tal-uffiċċju għal komunikazzjoni effiċjenti.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Vaganzi Pubbliċi tal-Uffiċċju:</Body1Strong> Kun infurmat dwar il-vaganzi pubbliċi speċifiċi tal-uffiċċju biex tippjana l-iskedi tiegħek b'mod xieraq.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Sigħat tax-Xogħol tal-Uffiċċju:</Body1Strong> Ara s-sigħat operattivi tal-uffiċċju biex taqbel iż-żjarat jew is-sejħiet tiegħek ma' ħinijiet attivi tagħhom.
                        </li>
                      </ol>
                      Dan il-view jipprovdi mhux biss instantanea viżwali u informattiva, iżda wkoll aċċess mgħaġġel għal għarfien aktar profond u azzjonijiet diretti relatati mal-uffiċċju, kollha organizzati b'mod pulit taħt l-għażla "More (...)" għall-konvenjenza tiegħek.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>View tal-Org Chart:</Body1Strong> Visualizza l-istruttura ġerarkika tal-uffiċċji ewlenin u dawk sussidjarji 
                      biex tifhem l-interkonnettività tal-lokazzjonijiet differenti tal-uffiċċji fi ħdan l-organizzazzjoni tiegħek.
                    </li>
                  </ul>
                  Agħżel bejn dawn il-views billi tuża l-ikoni ħdejn il-kaxxa tat-tiftix biex tippersonalizza l-esperjenza tal-browsing tiegħek skont il-ħtiġijiet immedjati tiegħek.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Is-Sib ta' Uffiċċji malajr b'Karatteristika ta' Tiftix ta' TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Is-sejbien tal-lokazzjoni tal-uffiċċju t-tajjeb ġewwa TeamConnect huwa faċilitat bil-karatteristika ddedikata għat-tiftix. Pożizzjonat b'mod prominenti ħdejn l-ikoni tal-view, il-kaxxa tat-tiftix hija l-għodda tiegħek għall-identifikazzjoni ta' uffiċċji bl-isem. Hekk kif tikteb, id-direttorju jirfinar il-wiri biex juri biss l-uffiċċji li jaqblu mal-input tiegħek, semplifikazzjoni tal-proċess tat-tiftix tiegħek. Huwa importanti li tuża din il-kaxxa tat-tiftix ġewwa TeamConnect għal riżultati l-aktar preċiżi u relevanti, minflok it-tiftix ġenerali ta' Microsoft Teams. B'din il-funzjonalità tat-tiftix immirata, tista' timxi malajr lejn kwalunkwe uffiċċju fin-netwerk tal-organizzazzjoni tiegħek.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Navigazzjoni fl-Indiċi tal-Uffiċċji Alfabettikament
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jekk tippreferi tiftix skont l-alfabett, il-filtru alfabettiku tagħna huwa mod mgħaġġel biex issib uffiċċji skont l-isem. Fil-quċċata tal-Indiċi tal-Uffiċċji, tara l-ittri A sa Z. Billi tagħfas fuq ittra, il-fehma tiġi ffiltrata biex turi biss dawk l-uffiċċji li jibdew b'dik l-ittra, semplifikazzjoni tal-proċess tat-tiftix tiegħek. Biex tirristartja u tara l-uffiċċji kollha mill-ġdid, sempliċement iddiżattiva l-ittra attiva. Din il-karatteristika hija partikolarment utli meta tkun taf l-isem tal-uffiċċju imma teħtieġ mod mgħaġġel biex issibu fi ħdan TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Tiżvelar ta' Profili tal-Uffiċċji Komprensivi fi TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ibda vjaġġ esplorattiv lejn il-qalba tal-preżenza fiżika tal-organizzazzjoni tiegħek ma' l-profili tal-uffiċċji ta' TeamConnect.
                  Billi tagħfas fuq isem ta’ uffiċċju tippreżenta dialgu vibranti tal-profil, mimli b'tabs b'dettalji rqiq dwar l-uffiċċju:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ħarsa Ġenerali:</Body1Strong> Skopri l-qalba tal-identità tal-uffiċċju b'deskritturi dettaljati, mill-istruttura tan-negozju sal-ħinijiet tal-ftuħ u l-ħin lokali, li jieħdu l-essenza tal-ambjent tal-uffiċċju.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kuntatti Essenzjali:</Body1Strong> Aċċessa kuntatti kruċjali għas-servizzi ta' emerġenza u appoġġ estern, kull wieħed klikk 'il bogħod permezz tas-Sistema tat-Telefon ta' Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kuntatti tal-Uffiċċju:</Body1Strong> Aqbad kuntatt mas-servizzi ta' appoġġ interni bil-faċilità, b'dettalji ta’ kuntatt u għażliet diretta ta' impenn dritt fuq ponot subgħajk.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vaganzi Pubbliċi:</Body1Strong> Kun infurmat bil-vaganzi speċifiċi tal-uffiċċju, tiżgura li l-pjanijiet tiegħek jikkoinċidu ma' osservanzi lokali u skedi tax-xogħol.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ħinijiet tal-Uffiċċju:</Body1Strong> Ippjana l-interazzjonijiet tiegħek b'għarfien preċiż ta' meta l-uffiċċju jkun mimli b'attività jew jibda jnaqqas għall-jum.
                    </li>
                  </ul>
                  Kemm jekk huwa l-enerġija bużżieqa ta' Austin jew il-hub strateġiku ta' Singapor, kull tab toffri portal biex tifhem u tikkonnettja ma' l-lokalitajiet diversi li jiffurmaw il-preżenza globali tal-kumpanija tiegħek.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Jintlaħaq l-id dinamiċi fid-dipartimenti ta' uffiċċju
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  In-navigazzjoni tal-netwerk professjonali tad-dipartimenti ta' uffiċċju tiżvolġi b'faċilità fil-TeamConnect. Hawnhekk kif tista’ tissieħeb fid-dettalji:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ara d-Dipartimenti f'dan l-Uffiċċju:</Body1Strong> Skopri d-dipartimenti kollha li jagħtu forma 
                      lil dan l-uffiċċju billi tagħżel din l-għażla mill-menus More (...).
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Għarfien Organizzattiv:</Body1Strong> Ikklikkja fuq kwalunkwe dipartiment biex turi l-grafika organizzattiva tiegħu għal 
                      rappreżentazzjoni viżwali tal-istruttura tiegħu, bil-mogħdija tal-breadcrumbs li jmexxik: Uffiċċju {'>'} Dipartiment {'>'} Grafika.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Studio Profond tad-Dipartiment:</Body1Strong> Esplora aktar dipartiment billi tikklikkja fuq il-
                      menu Aktar tiegħu (...) ħdejn l-isem tad-dipartiment. Hawnhekk, tista 'taċċessa lista ta' l-impjegati kollha fi ħdan dak id-dipartiment, eżatt fil-kuntest tal-uffiċċju.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Navigazzjoni bil-Breadcrumbs:</Body1Strong> Aqsam il-livelli bla xkiel. Mill- 
                      impjegati lura għad-dipartimenti jew mill-grafika, il-mogħdija tal-breadcrumbs (Uffiċċju {'>'} Dipartiment {'>'} Impjegat {'>'} Grafika) iżomm il-vjaġġ tiegħek intuwittiv u fuq il-kors.
                    </li>
                  </ul>
                  Il-mogħdijiet kumplessi ta' TeamConnect jistiednuk tesplora, tifhem, u tingħaqad ma' kull saff tad-dipartimenti tal-uffiċċju tiegħek, billi temprek b'kmand navigazzjonali komprensiv.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Konnessjoni mal-Persunal tal-Uffiċċju fil-TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Kurjuż dwar il-wiċċ wara uffiċċju? It-tweġiba hija klikk 'il bogħod fil-TeamConnect. Ingħaqad mal-ellipsis (tliet punti) ħdejn l-isem tal-uffiċċju magħżul tiegħek biex turi suite ta' għażliet interattivi:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ara l-Impjegati f'dan l-Uffiċċju:</Body1Strong> Skopri lista tal-professjonisti kollha 
                      li jqisu dan l-uffiċċju bħala d-dar tax-xogħol tagħhom. In-numru murija joffri għadd malajr tal-impjegati, stedinkollha biex tissieħeb fil-komunità tal-uffiċċju.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Għażliet ta' Kuntatt:</Body1Strong> Kull dħul tal-impjegat huwa mgħammar b'ikoni ta' azzjoni malajr, 
                      li jippermettu li tibda chat, sejħat, jew anke konferenzi bil-vidjo direttament minn TeamConnect, li tippromwovi komunikazzjoni bla xkiel fl-uffiċċju.
                    </li>
                    <li><Body1Strong>Navigazzjoni:</Body1Strong> Biex tirritorna għal-lista wiesgħa tal-uffiċċji minn lista tal-impjegati, sempliċement ikklikkja 'Uffiċċju' fil-mogħdija tal-breadcrumbs 
                      (Uffiċċju {'>'} Impjegat), u tkun meħud lura għal-ħarsa ġenerali tal-uffiċċju.
                    </li>
                  </ul>
                  B'dawn il-karatteristiċi, TeamConnect jagħmilha sempliċi biex tara d-distribuzzjoni tal-forza tax-xogħol madwar l-uffiċċji u li tibgħat messaġġi mingħajr sforz,
                  u tippromwovi post tax-xogħol konness irrispettivament mid-distanza.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Il-informazzjoni tal-uffiċċju tiegħek nieqsa jew antikwata?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Aġġornamenti tal-informazzjoni tal-uffiċċju fil-TeamConnect jistgħu jiġu trattati mill-edituri tal-uffiċċju assenjati. Jekk m'intix ċert dwar l-edituri tal-uffiċċju jew
                  teħtieġ assistenza b'informazzjoni li taqa' taħt il-Kompetenza tal-HR, it-tim ta' appoġġ tal-HR huwa lest li
                  jassisti fuq <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Mistoqsijiet Frekwenti dwar is-Settings</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. L-Importanza ta' Settings Ġenerali Preċiżi fil-TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Fil-pajsaġġ dinamiku tal-ambjent tax-xogħol tal-lum, huwa aktar kruċjali minn qatt qabel li tibqa' konness u kkoordinat. Hawnhekk, taġġorna l-
                  Settings Ġenerali tiegħek fil-TeamConnect jilgħab rwol pivotal:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Għażla tal-Post tax-Xogħol:</Body1Strong> Ikkaptura l-essenza tal-ambjent tax-xogħol tiegħek billi taġġorna l-Post tax-Xogħol tiegħek biex jirrifletti s-sitwazzjoni attwali tiegħek, 
                        sew jekk tqiegħed mill-kwiet tal-Home-Office għall-ħoss tal-Uffiċċju ewlieni, jew anke tistabbilixxi ruħek f'Żona Pubblika.
                        Din iċ-ċarezza fil-lokal tax-xogħol tiegħek toffri lill-kollegi tiegħek għarfien mhux biss fuq l-aħjar modi kif jistgħu jilħquk, iżda wkoll dwar 
                        in-natura ta’ konverżazzjonijiet li jistgħu jidħlu fihom miegħek, billi jżommu kunfidenzjalità ta’ diskussjonijiet sensittivi speċjalment meta tkun 
                        f'ambjent miftuħ tal-uffiċċju tal-klijent jew qed toqgħod f'lounge ta' ajruport.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Speċifikazzjoni tal-Workspot:</Body1Strong> Li taqsam il-belt tax-xogħol tiegħek tagħmel aktar milli tgħaqqad distanzi f'setup ta' tim remot—
                        tiftaħ ukoll bibien għal laqgħat professjonali spontanji jew soċjalizzazzjoni każwali. Kemm jekk huwa kafè malajr, ikla trankwilla, jew laqgħa kollegjali,
                        li tkun taf li int fil-istess belt tista' tibdel jum regolari f'opportunità għal konnessjonijiet wiċċ imb wiċċ sinifikanti. Dan mhux biss isaħħaħ ir-rabtiet 
                        tat-tim iżda jipprovdi wkoll fehim aktar profond tan-nuances lokali li jistgħu jkunu qed jinfluwenzaw ix-xogħol tiegħek.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Preciżjoni taż-Żona Ħinija:</Body1Strong> Peress li t-timijiet jinfirxu fuq kontinenti, li żżomm iż-żona ħinija tiegħek tirrifletti
                      l-lok attwali tiegħek huwa essenzjali biex tarmonizza l-komunikazzjonijiet. Il-Ħin Lokali fil-profil tal-TeamConnect tiegħek huwa direttament influwenzat minn din is-setting,
                      telimina kull ħsieb doppju min-naħa tal-kollegi li jippruvaw jilħquk.
                    </li>
                  </ul>
                  Niddħol fis- 'Settings' {'>'} 'General Settings' fi ħdan il-TeamConnect biex tiffoka iktar fuq dawn id-dettalji. Ftit mumenti mneħħija biex taġġorna s-settings tiegħek jistgħu
                  itejbu bil-kbir il-koħezjoni u l-effiċjenza tas-sforzi kollaborattivi tiegħek fuq diversi livelli.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Ifhim l-Għażla tal-Post tax-Xogħol fi TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Agħżel l-ambjent it-tajjeb mill-dropdown tal-Post tax-Xogħol sabiex tippersonalizza TeamConnect biex jirrifletti b'mod preċiż l-istatus attwali tax-xogħol tiegħek. Żomm il-cursor fuq l-ikona tal-informazzjoni għal spjegazzjoni dettaljata ta' kull għażla:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Break:</Body1Strong> Jindika żmien barra mix-xogħol, kemm jekk tkun waqfa jew tmiem il-ġurnata.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office/Field-Office:</Body1Strong> Juri preżenza f'spazju tax-xogħol immaniġġjat mill-kumpanija.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> Jaħdem minn spazju ddedikat għax-xogħol fid-dar.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Public-Area:</Body1Strong> Jaħdem minn ambjent barra l-uffiċċju, li jissuġġerixxi bżonn ta' komunikazzjoni flessibbli.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Customer Workplace/Private Office:</Body1Strong> Fuq il-post mal-klijent, b'nota dwar il-livelli ta' privatezza għas-sensittività tal-konversazzjoni.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Commute/Business-Travel/Training:</Body1Strong> Qiegħed fuq il-moviment jew impenjat f'attivitajiet relatati max-xogħol barra mill-uffiċċju.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Public-Holiday/Vacation/Various Leaves:</Body1Strong> Uffiċjalment barra mix-xogħol għal raġunijiet personali, ta' saħħa, jew ta' btajjel.</li>
                  </ul>
                  L-għażla ta' post tax-xogħol xieraq tiżgura li t-tim tiegħek ikollu l-kuntest meħtieġ għall-interazzjonijiet, bir-rispett lejn id-disponibbiltà u l-privatezza.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. In-Navigazzjoni tal-Istatus 'Checked-In' fi TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  L-isem 'Checked-In' fid-Direttorju tal-Impjegati huwa mfassal biex juri l-impjegati kollha li bħalissa qegħdin fuq il-post tax-xogħol. Din il-viżibbiltà tiġi determinata mill-għażla tal-Post tax-Xogħol li tagħmel:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ambjenti tax-Xogħol Attivi:</Body1Strong> L-għażliet Office, Field-Office, Home-Office, Remote-Workplace, Public-Area, Customer-Workplace, 
                    Customer Private-Office, Commute, Business-Travel, u Training jsinjalawk  bħala li qiegħed f'xogħol, b'hekk tkun 'Checked-In' u viżibbli għall-kollegi.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Settings Non-Work:</Body1Strong> L-għażla għal Checked-Out, Break, Public-Holiday, Vacation, On-Leave, Maternity-Leave, jew Sick-Leave 
                      tneħħik mil-lista ta' 'Checked-In', tistabbilixxi aspettattivi ċari għad-disponibbiltà u ż-żminijiet ta' rispons.
                  </li>
                  </ul>
                  Din il-funzjonalità tgħin tiddistingwi bejn il-perjodi tax-xogħol u l-ħin personali, tgħin fl-kollaborazzjoni u l-komunikazzjoni effettiva tat-tim.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>FAQs dwar l-Immaniġġjar tad-Data u l-Privatezza</Subtitle2>
          <Body1>
            <p>
              F'din l-epoka diġitali tal-lum, huwa kruċjali li tifhem kif id-dejta tiegħek hija mmaniġġjata. TeamConnect huwa impenjat għat-trasparenza u prattiċi robusti ta' protezzjoni tad-dejta.
              Hawn taħt, issib informazzjoni dwar kif aħna nimmaniġġjaw, naħżnu, u nipproteġu d-dejta tiegħek fi ħdan TeamConnect, biex niżguraw li l-ispazju tax-xogħol diġitali tiegħek huwa sigur u konformi.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Kif TeamConnect jipproteġi d-data personali tiegħi?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect huwa dedikat għall-ħarsien tal-informazzjoni personali tiegħek b'approċċ komprensiv għas-sigurtà tad-dejta:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Single Sign-On (SSO):</Body1Strong> Biex isaħħaħ is-sigurtà u jtejjeb l-esperjenza tal-utent, TeamConnect jintegra awtomatikament 
                      Single Sign-On (SSO) mal-kont Microsoft 365 tiegħek, li jiffaċilita l-immaniġġjar ta' aċċess sigur u mingħajr tbatija.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Kriptaġġ:</Body1Strong> Aħna niżguraw li d-data kollha fi ħdan TeamConnect tkun ikkriptata kemm waqt it-trasferiment kif ukoll waqt li tkun 
                      mistrieħa, tagħmilha inaċċessibbli għal partijiet mhux awtorizzati.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Minimizzazzjoni tad-Data:</Body1Strong> Il-ġbir tad-data tagħna huwa strettament limitat għal dak li hu essenzjali għall-funzjonalità ta' TeamConnect.
                      L-unika informazzjoni tal-utent li naħżnu hija l-Isem Prinċipali tal-Utent (UPN) mill-Azure Entra ID, li jista' jixbah lill-indirizz tal-email tal-kumpanija tiegħek.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Aċċess Ristrett:</Body1Strong> L-aċċess għall-UPN huwa limitat għall-Admin tal-Abbonament tal-organizzazzjoni tiegħek fi ħdan il-portal tal-Abbonament tagħna,
                      billi jiżgura li l-informazzjoni sensittiva tkun viżibbli biss għall-persunal awtorizzat.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Tħassir ta' Data Prompt:</Body1Strong> TeamConnect huwa strutturat biex jħassar immedjatament l-UPN maħżun taħt tliet kondizzjonijiet speċifiċi:
                      jekk l-abbonament jiġi kkanċellat mill-Admin tal-Abbonament, jekk utent jiġi mneħħi mill-HR fi ħdan TeamConnect, jew jekk l-abbonament ta' TeamConnect jiskadi. Dan jiżgura li d-data personali tiegħek ma tibqax miżmuma lil hinn mill-ħajja utli tagħha jew il-bżonn.
                    </li>                      
                  </ul>
                  <p>Permezz ta' dawn il-miżuri, TeamConnect jimpenja ruħu biex iżomm l-ogħla standards ta' protezzjoni tad-dejta, jiżgura li l-informazzjoni personali tiegħek tibqa' sigura u kunfidenzjali.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Tistaqsi fejn jinsabu d-data tiegħek ma' TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect jikser il-mudell tradizzjonali ta' offerti SaaS billi jiżgura li d-data tiegħek qatt ma titlaq mill-organizzazzjoni tiegħek.
                  Minflok il-prattika tas-soltu ta' ħażna ta' data fuq servers tal-cloud esterni, TeamConnect b'mod ingejuż juża l-Microsoft 365 SharePoint Online tal-organizzazzjoni tiegħek 
                  biex iżomm l-istess data tal-applikazzjoni kollha. Dan l-approċċ innovattiv mhux biss isaħħaħ is-sovranità tad-data iżda wkoll is-sigurtà,
                  li jaqbel perfettament mal-impenji sodi tagħna li nipproteġu l-privatezza tiegħek u naderixxu ma' standards ta' konformità stretti.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Id-data tiegħi se tiġi maqsuma ma' partijiet terzi?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Le, l-ebda data ma tiġi maqsuma ma' partijiet terzi! Cubeet huwa determinat fl-impenji tagħna għall-privatezza u s-sigurtà tad-data tiegħek. Aħna ma naqsmux data tal-TeamConnect ma' partijiet terzi mingħajr ma niksbu l-kunsens espliċitu mill-klijenti tagħna. 
                  Fl-ambjent sigur tagħna, aħna nżommu biss identifikaturi essenzjali, bħal Isem Prinċipali tal-Utent (UPN), isem tal-organizzazzjoni, isem tat-tenant, u ID tat-tenant, fl-ambitu tas-sottoskrizzjoni tagħna.
                  Din l-informazzjoni hija kruċjali għall-provvista tas-servizz u hija mħarsa taħt il-politiki stretti tal-privatezza tagħna.
                  Kun żgur, il-prattiki tal-ġestjoni tad-data tagħna huma mfassla biex jiżguraw li d-data tiegħek tibqa' kunfidenzjali, b'trasparenza u kontroll jibqgħu sodament f'idejk.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Kif TeamConnect jikkonforma ma' regolamenti ta' protezzjoni tad-data bħal GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect jikkonforma ma' regolamenti ta' protezzjoni tad-data bħal GDPR, HIPAA, CCPA, u oħrajn billi juża l-karatteristiċi intrinsiċi ta' konformità tal-ambjent Microsoft 365,
                  fejn kull data tal-klijent hija maħżuna b'mod sigur. Differenza mill-mudelli tradizzjonali SaaS, TeamConnect ma jħalliex id-data tal-klijent fuq is-servers tiegħu; minflok, jopera ġewwa l-
                  ambjent Microsoft 365 tal-organizzazzjoni tiegħek, jikkonforma mal-istandards robusti tas-sigurtà u tal-konformità tiegħu. Dan l-approċċ jiżgura li l-governanza tad-data tiegħek tibqa' konsistenti ma'
                  l-qafas ta' konformità kumpless provdut mill-Microsoft 365, inkluż l-aderenza ma' GDPR, HIPAA għall-informazzjoni tas-saħħa, CCPA għar-residenti ta' California,
                  u regolamenti oħra globali ta' protezzjoni tad-data. Billi jintegra bla xkiel mal-konfigurazzjoni eżistenti tal-Microsoft 365 tiegħek, TeamConnect jippermetti lill-organizzazzjoni tiegħek li żżomm
                  kontroll sħiħ fuq id-data tiegħek, tiżgura l-konformità mal-liġijiet u r-regolamenti tal-protezzjoni tad-data l-aktar stretti.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Kif TeamConnect jimmaniġġja d-dejta minn chats, sejħiet, video sejħiet ta' Microsoft Teams, emails ta' Outlook, u sejħiet tas-sistema tat-telefon?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect juża l-Microsoft Graph API biex jippermetti interazzjonijiet diretti bħal chat, sejħiet, video sejħiet, u email Outlook ġewwa Microsoft Teams, 
                  li tipprovdi esperjenza ta' utent bla xkiel. Importanti, waqt li TeamConnect jibda dawn il-komunikazzjonijiet, jagħmel dan mingħajr ma jindaħal fil-proċess ta' 
                  komunikazzjoni jew ikollu l-kapaċità li jirrekordja jew jaħżen xi wħud mill-kontenut ta' l-interazzjoni. Il-konversazzjonijiet, sejħiet, u emails iseħħu u jiġu 
                  ġestiti direttament fl-ambjent sikur ta' Microsoft Teams u Outlook, skond l-istandards stretti ta' sigurtà u privatezza ta' Microsoft 365. Dan jiżgura li TeamConnect 
                  itejjeb il-kapaċitajiet ta' komunikazzjoni tiegħek waqt li jippreserva b'mod sħiħ il-kunfidenzjalità u l-integrità tad-dejta tiegħek.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Għal fehim dettaljat tal-politiki u l-prassi tagħna fuq id-dejta, tista' tixtieq tikkonsulta wkoll il- {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>Gwida għall-Utenti ta' TeamConnect</Body1Strong></Link> jew id-dipartiment tal-IT 
              ta' l-organizzazzjoni tiegħek fuq <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> għal speċifiċi relatati mal-setup tiegħek.
          </p>
        </Body1>        
        <p></p>  
      <Subtitle1 className={styles.fontColor}>Qed Nisimgħu: Aqsam il-Feedback u l-Ideat Tiegħek</Subtitle1>
        <Body1>
          <p>
            L-għarfien tiegħek jimxi l-evoluzzjoni ta' TeamConnect. Kemm jekk int fatt bi idea brillanti ta' karatteristika ġdida jew għandek xi 
            feedback dwar l-esperjenza kurrenti tiegħek, aħna kollha widnejn. Il-kontribut tiegħek huwa inestimabbli fil-missjoni kontinwa tagħna 
            biex nipprovdu l-aħjar servizz possibbli.
          </p>
        </Body1>      
      <Subtitle2>Feedback u Suġġerimenti għal Karatteristiċi</Subtitle2>
        <Body1>
          <p>
            Lest biex taqsam? Mur fuq il-{' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Paġna tal-Feedback
            </Link>
            . Kemm jekk hu suġġeriment għal karatteristika ġdida jew feedback fuq funzjonalitajiet eżistenti, sempliċiment agħżel 
            l-għażla xierqa mid-dropdown fil-formola. Il-kontribuzzjonijiet tiegħek jgħinu biex jiffurmaw il-futur ta' TeamConnect, 
            jiżguraw li jissodisfaw u jaqbżu l-bżonnijiet tiegħek.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
