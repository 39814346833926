import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../config/authConfig';
import Button from 'react-bootstrap/Button';
import { Body1 } from '@fluentui/react-components';

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package 
 */

export const Login = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <Button style={{ backgroundColor: '#151b2b', border: 'none', marginRight: '20px' }} type='button' onClick={handleLogin}>
      <Body1>
        Sign In
      </Body1>
    </Button>
  );
};