import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();

  return (
    <>
      <Title3 className={styles.fontColor}>Centro de ayuda y preguntas frecuentes de TeamConnect</Title3>
        <p><Body1>
          Bienvenido al Centro de ayuda de TeamConnect, diseñado para apoyar tu experiencia con nuestra aplicación integral de directorio corporativo. Aquí encontrarás recursos para optimizar tu uso de TeamConnect dentro de Microsoft Teams, mejorando la comunicación en toda tu red.
        </Body1></p>
        <Subtitle2>Guía de referencia rápida</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Capacidades de búsqueda mejoradas: </Body1Strong>
              Encuentra a los colegas, departamentos y contactos esenciales rápidamente con nuestras potentes herramientas de búsqueda.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Organigramas visuales: </Body1Strong>
              Navega por la jerarquía de tu organización y comprende las líneas de reporte fácilmente mediante organigramas intuitivos.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Herramientas adaptables para las necesidades de hoy: </Body1Strong>
              Utiliza funcionalidades modernas diseñadas para las demandas de los lugares de trabajo contemporáneos.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Optimizando tu experiencia</Subtitle2>
        <Body1>
          <p>
            TeamConnect revoluciona cómo interactúas dentro de tu estructura organizativa, convirtiendo las comunicaciones diarias en compromisos estratégicos.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Obtener ayuda y preguntas frecuentes</Subtitle1>
        <Body1>
          <p>
            Encuentra respuestas a preguntas comunes sobre las características y configuraciones de TeamConnect en esta sección dedicada. Ya sea que estés navegando por la app, personalizando ajustes o buscando soluciones a desafíos, nuestro Centro de ayuda está aquí para asistirte de manera eficiente.
          </p>
        </Body1>
        <Body1>
          <p>
            Para obtener un soporte más profundo o una guía específica, consulta la <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>Guía del usuario de TeamConnect</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        <Subtitle2>Uso de preguntas frecuentes de TeamConnect</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. ¿No encuentra TeamConnect en la barra lateral de Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Si TeamConnect no es visible o desapareció en su barra lateral de Microsoft Teams, puede fijarlo fácilmente para un acceso rápido. Solo siga estos pasos sencillos:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Abra Microsoft Teams y haga clic en el icono de '...' (+ Aplicaciones) en la parte inferior de la barra lateral.</li>
                    <li className={styles.bulletBottomMargin}>Busque 'TeamConnect' en la barra de búsqueda en la parte superior de la ventana emergente.</li>
                    <li className={styles.bulletBottomMargin}>Haga clic derecho en la aplicación TeamConnect de los resultados de búsqueda y seleccione 'Fijar'.</li>
                  </ol>
                  ¡TeamConnect ahora estará fijado en su barra lateral, haciéndolo accesible con solo un clic!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. ¿Problemas técnicos con TeamConnect? Aquí le mostramos cómo obtener ayuda.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Si encuentra algún problema técnico con TeamConnect dentro de Microsoft Teams, el equipo de soporte de TI de su organización está aquí para ayudar.
                  Póngase en contacto con ellos en <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, y le asistirán de manera rápida.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. ¿No ve los detalles de su empleado?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Si su perfil parece un poco vacío con solo su nombre y correo electrónico, es probable que su equipo de RRHH aún no haya agregado el resto de sus detalles de empleado a TeamConnect.
                  ¡No hay motivo de preocupación! Un mensaje rápido a su equipo de soporte de RRHH pondrá las cosas en marcha. Están en espera para completar su perfil con todos los detalles importantes.
                  Póngase en contacto en <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> y vea cómo su perfil cobra vida.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. ¿Qué opciones de idioma están disponibles en TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect abraza la diversidad global de Microsoft Teams al soportar todos sus idiomas. 
                  Ya sea que prefieras el árabe o el griego, el japonés o el sueco, tienes un mundo de opciones a tu alcance, incluyendo:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Árabe, búlgaro, chino (simplificado y tradicional), croata, checo, danés, holandés, inglés, estonio, finlandés, 
                      francés, alemán, griego, hebreo, húngaro, indonesio, italiano, japonés, coreano, letón, lituano, noruego (Bokmål), 
                      polaco, portugués (Brasil y Portugal), rumano, ruso, serbio (latín), eslovaco, esloveno, español, sueco, tailandés, 
                      turco, ucraniano, vietnamita.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Para personalizar tu experiencia en TeamConnect a tu idioma preferido, ajusta los ajustes de idioma directamente en Microsoft Teams, 
                  ya que TeamConnect se integra completamente con estos ajustes:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Ve a tus ajustes de Microsoft Teams haciendo clic en los tres puntos junto a tu foto de perfil en la parte superior, y luego selecciona 'Apariencia y accesibilidad'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Desplázate hasta la sección de 'Idioma' y selecciona tu idioma preferido del menú desplegable.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Confirma tus cambios haciendo clic en el botón 'Guardar y reiniciar' para aplicar los nuevos ajustes de idioma.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Nota: </Body1Strong>
                <Body1>
                  Los cambios realizados en tu configuración de idioma en Microsoft Teams también actualizarán automáticamente tu experiencia en TeamConnect. 
                  Ten en cuenta que al cambiar el idioma en Teams, también puede ajustar tu formato de hora y fecha. En este momento, 
                  TeamConnect está evolucionando para adaptarse a la escritura de derecha a izquierda. Estamos emocionados con estas mejoras 
                  y te mantendremos informado a medida que estén disponibles.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. ¿Cómo puedo cambiar el tema en TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect se sincroniza con tus preferencias de tema de Microsoft Teams, incluyendo las opciones Claro, Oscuro y Alto Contraste. Para personalizar tu tema:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Accede a la configuración de tu Microsoft Teams haciendo clic en los tres puntos junto a tu imagen de perfil en la parte superior.</li>
                    <li className={styles.bulletBottomMargin}>Selecciona 'Apariencia y accesibilidad' para explorar las opciones de tema.</li>
                    <li className={styles.bulletBottomMargin}>Elige tu tema preferido de las opciones disponibles para actualizar al instante tu interfaz de TeamConnect a juego.</li>
                  </ol>
                  Tu elección de tema en Microsoft Teams se aplicará automáticamente a TeamConnect, asegurando una experiencia visual consistente en todo tu espacio de trabajo.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Explorando los botones de acción de contacto de TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A lo largo de TeamConnect, dondequiera que encuentres detalles de contacto de un empleado, también descubrirás nuestros cómodos botones de acceso rápido. Estas herramientas útiles están diseñadas para agilizar tu comunicación dentro del ecosistema de Microsoft, asegurando que siempre estés a solo un clic de conectar.
                  Aquí tienes tu guía completa para utilizar estos botones de manera efectiva:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icono de chat:</Body1Strong> Haz clic para iniciar un chat instantáneo de Microsoft Teams. Si necesitas regresar a TeamConnect después de iniciar un chat, simplemente usa la flecha hacia atrás en la App de Escritorio, el botón atrás en la App Móvil, o el botón de atrás de tu navegador.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icono de correo:</Body1Strong> Abre un nuevo borrador de correo en Outlook con la dirección del destinatario ya en el campo "Para:". Si Outlook no se inicia, vale la pena consultar con tu departamento de TI por cualquier restricción.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icono de llamada de video:</Body1Strong> Inicia una llamada de video cara a cara usando Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icono de llamada:</Body1Strong> Comienza una llamada de voz de Microsoft Teams, facilitando discusiones rápidas.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icono de organigrama:</Body1Strong> Muestra el lugar del empleado en la jerarquía organizacional, incluyendo a su jefe y subordinados directos.</li>
                    <li><Body1Strong>Icono de más (...):</Body1Strong> Revela más opciones:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Reunión ahora:</Body1Strong> Abre Microsoft Teams para comenzar una reunión inmediata con el empleado.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Nueva reunión:</Body1Strong> Te dirige a Microsoft Teams para programar una nueva reunión.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Agregar contacto:</Body1Strong> Añade los detalles de contacto del empleado desde TeamConnect a tu nueva aplicación People de Microsoft Teams, enriqueciendo tu función de llamada.</li>
                      </ul>
                    </li>
                  </ul>
                  Estos botones intuitivos están diseñados para asegurar que puedas conectar de manera eficiente con los colegas, ya sea para una charla rápida, un correo electrónico o para programar futuras reuniones.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Realizando llamadas a través de TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ¿Buscas hacer una llamada? TeamConnect agiliza tu comunicación integrándose con Microsoft Teams. 
                  Simplemente haz clic en el número de teléfono deseado en TeamConnect y voilà, la interfaz de llamada de Microsoft Teams aparece, 
                  lista para que marques sin problemas.
                  <p>
                  <Body1Strong>Nota: </Body1Strong> 
                  ¿Problemas para hacer llamadas? Esto podría deberse a las políticas de TI de tu organización o al 
                  uso de un sistema telefónico distinto al Sistema Telefónico de Microsoft Teams. Consultar con tu departamento de TI en <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, puede arrojar algo de luz sobre el asunto.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. ¿Existen costos asociados con realizar llamadas a través de TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect mejora tu experiencia de llamadas aprovechando Microsoft Teams. Esto es lo que necesitas saber sobre los costos relacionados con las llamadas:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Para llamadas a teléfonos de trabajo de empleados, teléfonos del departamento o líneas directas dentro de tu organización (números internos),
                      típicamente <Body1Strong>no hay costos</Body1Strong> al utilizar el Sistema Telefónico de Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Para llamadas externas, como las realizadas a teléfonos móviles o líneas externas, se aplican las <Body1Strong>tarifas estándar</Body1Strong> del sistema telefónico de tu organización. 
                      Estas tarifas varían según tu plan de servicio específico con Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Nota:</Body1Strong> Si encuentras algún problema o tienes preguntas sobre los cargos de las llamadas, es mejor que consultes con tu 
                    departamento de TI en <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> para obtener información detallada 
                    adaptada a la configuración y políticas de tu organización.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. ¿Notas una discrepancia con tu estado de presencia 'Fuera de la Oficina' en TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ¿Has notado que tu estado de presencia 'Fuera de la Oficina' de Microsoft 365 no se refleja correctamente en TeamConnect? 
                  No te preocupes. La función para sincronizar el estado de presencia 'Fuera de la Oficina' ya ha sido implementada en TeamConnect.
                  Actualmente estamos esperando el despliegue de esta función por parte de Microsoft. Una vez que ocurra, tu estado de presencia 'Fuera de la Oficina' 
                  se reflejará con precisión en TeamConnect, alineándose con los indicadores de presencia de Microsoft Teams para una experiencia integrada.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Preguntas Frecuentes del Directorio de Empleados</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Introducción al Directorio de Empleados:</Body1Strong> Navega el panorama profesional de tu organización con el Directorio de Empleados, 
                tu centro neurálgico para obtener detalles profundos sobre los empleados, dinámicas del lugar de trabajo y conectividad directa con los perfiles y relaciones gerenciales de tus colegas.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. ¿Necesitas agregar, actualizar o corregir tus datos de empleado?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Para agregar, actualizar o corregir tus datos de empleado en TeamConnect, por favor ponte en contacto con el equipo de soporte de RRHH de tu organización. 
                  Están listos para ayudarte en <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. ¿Qué ocurre cuando hago clic en el nombre de un empleado en el directorio?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hacer clic en el nombre de un empleado en el Directorio de Empleados de TeamConnect abre una página de perfil detallado para ese individuo. Este perfil proporciona información integral 
                  sobre el empleado, incluyendo pero no limitado a, su título laboral, departamento, ubicación de oficina, información de contacto, y más. Está diseñado para ofrecerte 
                  una visión completa del rol profesional del empleado y cómo se integra en la organización, facilitando tu entendimiento de sus responsabilidades y 
                  cómo podrías colaborar con ellos.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. ¿Qué información se incluye en el perfil de un empleado?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Cada perfil de empleado en TeamConnect es una instantánea integral, que ofrece una abundancia de perspectivas sobre la vida profesional del individuo. 
                  Aquí está lo que encontrarás:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Detalles Básicos:</Body1Strong> Nombre, título laboral, departamento y ubicación de la filial.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Estructura de Reporte:</Body1Strong> Información sobre informes directos y funcionales, 
                      incluyendo gerentes de línea y funcionales.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Estado en el Trabajo:</Body1Strong> Estado actual de trabajo como En licencia, Traslado, 
                      Viaje de negocios, etc.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Puesto de Trabajo y Hora Local:</Body1Strong> La ubicación actual de trabajo del empleado y 
                      la zona horaria local.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Detalles de Contacto:</Body1Strong> Teléfono del trabajo, número móvil y dirección de correo electrónico.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Dirección de la Oficina:</Body1Strong> Ubicación completa de la oficina incluyendo el nombre del edificio,
                      dirección y mapa de Google.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Acerca de:</Body1Strong> Una breve introducción personal o biografía profesional.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Relaciones Laborales:</Body1Strong> Detalles de la estructura de reporte del empleado y relaciones de equipo.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Enlaces Sociales y Lenguas:</Body1Strong> Enlaces a perfiles sociales profesionales 
                      y competencias lingüísticas.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Habilidades:</Body1Strong> Una lista de habilidades profesionales y áreas de especialización.</li>
                  </ul>
                  Además, el perfil ofrece opciones procesables para conectar a través de chat, correo electrónico, videollamada o programar reuniones, junto con 
                  acceso rápido al organigrama de la organización para un contexto de equipo más amplio.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. ¿Cómo puedo actualizar la información de mi perfil?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Personalizar tu perfil es fácil en TeamConnect. Aquí te mostramos cómo puedes hacerlo:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Navega a la pestaña <Body1Strong>Empleado</Body1Strong> y haz clic en tu <Body1Strong>Avatar</Body1Strong> para abrir tu perfil.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        En la esquina superior izquierda, encontrarás el enlace <Body1Strong>"Editar tu perfil"</Body1Strong>. 
                        Hacer clic aquí abrirá el diálogo de edición para tu perfil.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Aquí, puedes mejorar tu perfil agregando o actualizando tu sección <Body1Strong>Sobre ti</Body1Strong>, enlaces de redes sociales y número de teléfono. 
                        Esto facilita que tus colegas se conecten o te sigan.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        No olvides presionar el botón <Body1Strong>Guardar</Body1Strong> para aplicar tus cambios.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Desplázate hacia abajo en el diálogo para agregar tus <Body1Strong>Idiomas</Body1Strong> y <Body1Strong>Competencia</Body1Strong>, 
                        así como tus <Body1Strong>Habilidades</Body1Strong>. Compartir esta información ayuda a tus colegas a entender 
                        tus áreas de experiencia y los idiomas en los que puedes comunicarte.
                      </li>
                    </ol>
                  Actualizar tu perfil no solo mantiene tu información actualizada, sino que también fomenta mejores conexiones dentro de tu organización.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Actualizando tu lugar de trabajo, puesto de trabajo y zona horaria en TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Mantener actualizada la información de tu lugar de trabajo, puesto de trabajo y zona horaria es crucial para una colaboración sin problemas en Microsoft Teams. 
                  Estas actualizaciones se reflejan instantáneamente en tu perfil y listados de TeamConnect, permitiendo a los colegas comprender rápidamente tu ubicación de trabajo 
                  y los mejores momentos para conectarse contigo, mejorando el trabajo en equipo a través de diferentes regiones y zonas horarias.
                  <p></p>
                  ¿Listo para actualizar tus detalles? ¡Es fácil en TeamConnect! Dirígete a 'Configuración' en el menú superior y navega a 'Configuración General'. 
                  Aquí, puedes actualizar sin problemas tu Lugar de Trabajo y Zona Horaria, y especificar tu Puesto de Trabajo para el día. Recuerda guardar tus cambios para 
                  mantener a todos informados y asegurar una colaboración efectiva, ¡sin importar tu ubicación!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. ¿Has notado algo incorrecto en el perfil de un colega?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Si te encuentras con algún detalle en el perfil de un colega que no parece estar correcto, el primer paso ideal es darle un aviso amistoso. ¡Una rápida charla o un correo electrónico deberían bastar! Si por alguna razón la información no se actualiza, no te preocupes, simplemente contacta a tu equipo de soporte de RRHH en <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, y ellos estarán encantados 
                  de intervenir y resolver las cosas.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Entendiendo los Grupos de Etiquetas: Todos, Favoritos, Registrados y Nuevos Empleados
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Todos:</Body1Strong> Esta etiqueta predeterminada muestra a todos los empleados de su organización,
                      proporcionando una vista completa del equipo.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoritos:</Body1Strong> Haga clic aquí para filtrar su vista a aquellos colegas que ha marcado
                      como 'Favoritos', facilitando la conexión rápida con contactos clave.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Registrados:</Body1Strong> Esta etiqueta indica a los empleados que están activamente en el trabajo, distinguiendo
                      de la simple presencia en línea en Microsoft Teams. Cuando se registran, los empleados señalan que están en el trabajo y comparten su ubicación actual de trabajo
                      durante el día. Esto puede incluir una variedad de lugares de trabajo como la Oficina, Oficina-Campo, Oficina en Casa, Lugar de Trabajo Remoto, Lugar de Trabajo del Cliente,
                      Oficina Privada del Cliente, Área Pública, Viaje al Trabajo, Viaje de Negocios, o incluso Capacitación. Por lo tanto, el estado 'Registrado' proporciona una indicación clara
                      de estar activamente en el trabajo, ofreciendo a los colegas una comprensión precisa del contexto laboral actual y la disponibilidad de cada uno.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nuevos Empleados:</Body1Strong> Identifique las caras nuevas en TeamConnect con esta etiqueta, destacando
                      a los empleados que han sido agregados a la aplicación en los últimos 30 días. Por lo general, esto se alinea con las adiciones recientes a su organización, ayudándole
                      a dar la bienvenida a los nuevos miembros del equipo.
                    </li>
                  </ul>
                  Estos grupos de etiquetas están diseñados para agilizar la navegación y mejorar su experiencia en TeamConnect, asegurando que siempre pueda
                  encontrar y conectar con sus colegas de manera eficiente.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Descifrando los Íconos al Lado del Cuadro de Búsqueda
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  El Directorio de Empleados de TeamConnect está diseñado para ser versátil y fácil de usar, con cuatro íconos que ofrecen diferentes vistas
                  y acceso rápido a detalles clave. Esto es lo que revela cada ícono:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vista de Lista:</Body1Strong> Su vista preferida, presentando a todos los miembros de la organización 
                      en una lista sencilla.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vista de Tarjeta Pequeña:</Body1Strong> Una alternativa compacta, esta vista transforma la 
                      lista en tarjetas de empleado de tamaño reducido.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vista de Tarjeta Grande:</Body1Strong> Opte por más detalle con tarjetas más grandes, que incluyen 
                      opciones de contacto adicionales como números de teléfono y móvil para facilitar la marcación.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organigrama:</Body1Strong> Visualice la estructura de la compañía con la vista del Organigrama, 
                      perfecta para entender las relaciones de reporte y navegar por el paisaje organizacional.</li>
                  </ol>
                    Cambie entre estos íconos para encontrar la vista que mejor se adapte a sus necesidades en el momento, ya sea que esté buscando información rápida o insights más profundos.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. ¿Dónde debo buscar para búsquedas rápidas de colegas?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ¿Busca un colega? Simplemente diríjase al cuadro de búsqueda de TeamConnect, ubicado en la parte superior derecha junto al ícono del organigrama.
                  Comience a escribir su nombre y observe cómo se refinan los resultados con cada letra. Recuerde, para las búsquedas en TeamConnect, omita la barra de búsqueda de Microsoft Teams 
                  en la parte superior, ¡nuestro cuadro de búsqueda especial es el camino a seguir!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. ¿Cómo funciona la búsqueda alfabética para encontrar empleados?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  El Directorio de Empleados de TeamConnect incluye una útil función de búsqueda alfabética para ayudarte a localizar rápidamente a los empleados por su primer nombre.
                  Simplemente haz clic en una letra del alfabeto mostrada en la parte superior del directorio, y TeamConnect te mostrará instantáneamente todos los empleados
                  cuyos nombres comiencen con esa letra. Para volver a la lista completa de empleados, solo deselecciona la letra resaltada. Esta función intuitiva
                  hace que navegar a través de listas grandes de empleados sea fácil, asegurando que puedas encontrar a los colegas de manera rápida y eficiente.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. ¿Cómo puedo ajustar el número de empleados mostrados por página?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect te permite personalizar cuántos perfiles de empleados ves por página, facilitando la navegación a través del Directorio de Empleados.
                  En la parte inferior de cada página, encontrarás opciones de paginación: 10, 50, 100, 500, 1000 y 5000. La configuración predeterminada muestra 10 perfiles por página.
                  Para ver más perfiles en una sola página, simplemente selecciona tu número preferido de estas opciones. Esta característica proporciona flexibilidad en cómo navegas
                  el directorio, permitiéndote adaptar la visualización a tus necesidades o preferencias específicas.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. ¿Cómo ordeno la información de los empleados en la Vista de Lista?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  En la Vista de Lista del Directorio de Empleados de TeamConnect, tienes la flexibilidad de ordenar la información de los empleados según tus necesidades. Las columnas—Nombre del Empleado,
                  Título del Trabajo, Departamento, Ubicación de Oficina y Lugar de Trabajo—son todas ordenables. Simplemente haz clic en el encabezado de la columna por el que deseas ordenar. Hacer clic una vez ordenará
                  la columna en orden ascendente, y hacer clic de nuevo la ordenará en orden descendente. Por defecto, el directorio está ordenado por Nombre del Empleado en orden ascendente,
                  basado en el primer nombre. Esta característica de ordenación te ayuda a organizar y visualizar rápidamente los detalles de los empleados de una manera que mejor sirva a tu propósito, ya sea que estés
                  buscando a alguien en específico o necesites agrupar empleados por un criterio particular como departamento o ubicación.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Preguntas Frecuentes del Directorio de Departamentos</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Introducción al Directorio de Departamentos:</Body1Strong> El Directorio de Departamentos es tu puerta de entrada para entender la compleja red de la 
              estructura de tu organización, ofreciendo una vista clara de las jerarquías departamentales, liderazgo gerencial y vías para una comunicación departamental sin contratiempos.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. ¿Cómo funcionan las etiquetas 'Todos', 'Favoritos' y 'Tu Departamento' en el Directorio de Departamentos?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  En el Directorio de Departamentos de TeamConnect, las etiquetas te ayudan a navegar y filtrar rápidamente la información departamental. Esto es lo que representa cada etiqueta:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>All:</Body1Strong> Esta etiqueta muestra todos los departamentos dentro de tu organización, proporcionando una 
                      visión integral.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favorites:</Body1Strong> Utiliza esta etiqueta para ver una lista curada de los departamentos que has marcado 
                      como 'Favoritos', facilitando el acceso a los departamentos con los que interactúas frecuentemente.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Your Department:</Body1Strong> Esta etiqueta filtra la vista para mostrar solo tu departamento, 
                      permitiendo un acceso rápido a la información y actualizaciones de tu departamento inmediato.</li>
                  </ul>
                  Estas etiquetas están diseñadas para optimizar tu navegación dentro del Directorio de Departamentos, mejorando tu capacidad para encontrar e interactuar con la información 
                  departamental de manera eficiente.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Entendiendo las Vistas del Directorio de Departamentos en TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navegar por el Directorio de Departamentos en TeamConnect es fluido con múltiples opciones de vista para adaptarse a tus preferencias. Cada vista ofrece una 
                  manera única de explorar la información departamental de manera integral, incluyendo el Nombre del Departamento, Gerente del Departamento, Departamento Principal, Teléfono del 
                  Departamento, Línea Directa del Departamento y Email del Departamento. Aquí tienes un resumen de las vistas disponibles:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>List View:</Body1Strong> La configuración predeterminada que lista ordenadamente todos los departamentos, proporcionando un 
                      resumen claro y conciso.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Large Card View:</Body1Strong> Cambia a esta vista para ver tarjetas detalladas de los departamentos, ofreciendo 
                      un resumen ampliado de la información clave de cada departamento.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org Chart View:</Body1Strong> Para una representación visual de la estructura y 
                      relaciones departamentales, la vista de Organigrama ilustra cómo cada departamento se ajusta dentro de la jerarquía organizacional más amplia.
                    </li>
                  </ul>
                  Para alternar entre estas vistas, simplemente usa los íconos al lado del cuadro de búsqueda en la parte superior del Directorio de Departamentos. Cada vista está diseñada para ayudarte 
                  a acceder y entender los detalles departamentales con facilidad, asegurando que encuentres la información que necesitas en el formato que mejor te funcione.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Cómo utilizar el cuadro de búsqueda en el Directorio de Departamentos
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Localice sin problemas cualquier departamento dentro de TeamConnect utilizando el cuadro de búsqueda dedicado situado justo al lado de los iconos de vista en la parte superior del
                  Directorio de Departamentos. Simplemente comience a escribir el nombre del departamento que está buscando, y observe cómo el directorio se refina para mostrar únicamente
                  coincidencias relevantes. Esta característica está específicamente diseñada para ayudarle a evitar la necesidad de buscar manualmente en todo el directorio, llevando el
                  departamento que necesita directamente a sus dedos. Recuerde, esta funcionalidad de búsqueda es única del Directorio de Departamentos de TeamConnect, así que asegúrese
                  de usar este cuadro de búsqueda en lugar de la búsqueda principal de Microsoft Teams en la parte superior de la interfaz de Teams para consultas específicas de departamentos.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. ¿Cómo funciona el filtro alfabético en el Directorio de Departamentos?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Agilice su búsqueda en el Directorio de Departamentos con el filtro alfabético ubicado en la parte superior. Esta característica le permite reducir rápidamente 
                  los departamentos seleccionando una letra. Una vez que elija una letra, TeamConnect filtra y muestra solo aquellos departamentos cuyos nombres comienzan 
                  con la letra seleccionada, facilitando encontrar lo que busca sin necesidad de desplazarse por toda la lista. Para volver a la vista completa del departamento, simplemente deseleccione la letra resaltada. Esta navegación alfabética intuitiva asegura que pueda localizar eficientemente cualquier departamento 
                  por su letra inicial.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. ¿Cómo puedo contactar al gerente del departamento?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Contactar a un gerente de departamento en TeamConnect es sencillo. Simplemente localice el departamento de interés y haga clic en el nombre del gerente. Esta 
                  acción desencadenará un menú emergente mostrando las opciones de contacto del gerente con los conocidos iconos de acceso rápido:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icono de chat:</Body1Strong> Para iniciar un mensaje instantáneo a través del chat de Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icono de correo:</Body1Strong> Para abrir un nuevo correo dirigido a ellos en Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icono de videollamada:</Body1Strong> Para iniciar una llamada de video a través de Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icono de llamada:</Body1Strong> Para comenzar una llamada de voz utilizando Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icono de más (...):</Body1Strong> Para opciones adicionales como programar una reunión o agregar 
                      su información de contacto a su lista de Teams.
                    </li>
                  </ul>
                  Estos iconos ofrecen un método sin interrupciones para comunicarse directamente con los gerentes de departamento desde dentro de TeamConnect, asegurando una comunicación eficiente dentro 
                  de su organización.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. ¿Cómo puedo ver o contactar empleados dentro de un departamento?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Para explorar quién es quién dentro de un departamento en TeamConnect, no busques más allá de los tres puntos junto al nombre del departamento. Hacer clic en esto te
                  mostrará opciones para más acciones:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ver Empleados en Este Departamento:</Body1Strong> Selecciona esta opción para mostrar una lista de todos
                      los empleados asociados con el departamento. El número junto a esta opción indica cuántos empleados hay en el departamento, proporcionando un conteo rápido.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organigrama del Departamento:</Body1Strong> Si deseas comprender la jerarquía del departamento,
                      elige 'Organigrama del departamento' para revelar el diseño estructural, mostrando la relación entre los departamentos principales, el departamento en cuestión,
                      y cualquier subdepartamento. Esta representación visual te ayuda a navegar y entender el marco organizacional, facilitando la identificación y
                      contacto de miembros específicos del departamento.
                    </li>
                    <li><Body1Strong>Departamento {'>'} Empleado:</Body1Strong> Después de ver los empleados dentro de un departamento, utiliza este rastro de migas de pan para navegar fácilmente
                      de regreso a la lista de departamentos, manteniendo una experiencia de exploración fluida dentro de TeamConnect.
                    </li>
                  </ul>
                  Estas características te permiten no solo ver la lista de empleados en un departamento dado, sino también ofrecer una vía para contactarlos directamente a través de las
                  herramientas de comunicación de TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Descubriendo Detalles Profundos de los Departamentos en TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Adéntrate en los detalles específicos de cualquier departamento con un simple clic en su nombre en TeamConnect. Se despliega un diálogo detallado, ofreciendo un rico tapiz de
                  información que pinta un panorama completo del papel y los recursos del departamento dentro de tu organización. Encontrarás todo desde liderazgo y
                  identificadores financieros hasta canales de comunicación y enlaces sociales. Esta característica es tu puerta de entrada a:
                  <ul>
                    <li className={styles.bulletBottomMargin}>El corazón del departamento: su propósito, gente y métricas de rendimiento.</li>
                    <li className={styles.bulletBottomMargin}>Vías de contacto: líneas directas a figuras claves del departamento y plataformas de comunicación.</li>
                    <li className={styles.bulletBottomMargin}>La red organizacional: entendiendo cómo el departamento se integra con la estructura más amplia de la empresa.</li>
                  </ul>
                  Ya sea una consulta rápida o una inmersión profunda en los datos del departamento, TeamConnect te equipa con las herramientas para conectar con y comprender los ecosistemas únicos
                  dentro de tu organización.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. ¿Curioso sobre la Ausencia de Direcciones de Departamentos en TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Descubrir la ubicación de un departamento a veces puede sentirse como una búsqueda del tesoro sin un mapa. En TeamConnect, generalmente no encontrarás una dirección dedicada
                  para cada departamento. He aquí por qué este detalle cartográfico a menudo se omite:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Varias Ubicaciones:</Body1Strong> Muchos departamentos no están confinados a un solo espacio; prosperan
                      en diversas oficinas y geografías.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Estructura Fluida:</Body1Strong> El paisaje organizacional está en constante cambio, con
                      departamentos evolucionando, fusionándose o incluso disolviéndose en alineación con los cambios estratégicos.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Colaboración Interdepartamental:</Body1Strong> Los espacios de trabajo modernos zumban con espíritu colaborativo, a menudo mezclando talentos de varios departamentos dentro de espacios compartidos.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Cultura de Trabajo Remoto:</Body1Strong> Con la flexibilidad del trabajo remoto, los anclajes departamentales no siempre están fijados en ubicaciones de oficinas físicas.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nuancias Legales:</Body1Strong> Los departamentos típicamente orbitan alrededor de la estructura legal de la compañía en lugar de tener sus propias direcciones independientes.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Agilización Operativa:</Body1Strong> Mantener el foco en el trabajo en equipo dinámico, no en direcciones estáticas, ayuda a mantener la agilidad operativa.
                    </li>
                  </ul>
                  Se trata de abrazar un enfoque sin límites para el trabajo en equipo y la eficiencia. TeamConnect refleja esta filosofía al enfatizar las conexiones sobre las ubicaciones.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. ¿Falta o está desactualizada la información de tu departamento?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Los editores de departamento están designados dentro de tu organización para añadir, actualizar o corregir información específica del departamento en TeamConnect.
                  Si no estás seguro de quién es tu editor de departamento o si cierta información requiere la intervención del equipo de soporte de RRHH,
                  por favor contacta a <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> para obtener orientación.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Preguntas frecuentes del Directorio de Oficinas</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Introducción al Directorio de Oficinas:</Body1Strong> Descubre la esencia de la presencia física de tu organización a través del Directorio de Oficinas,
                que abarca detalles esenciales de la oficina, especificaciones operativas y una mirada completa a los departamentos y personal que definen cada ubicación.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Navegando por las Etiquetas en el Directorio de Oficinas
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Encuentra tu camino en el Directorio de Oficinas con facilidad usando nuestro sistema de etiquetas diseñado para un acceso rápido a la ubicación correcta. Aquí está cómo cada etiqueta puede ayudarte a optimizar tu búsqueda:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Todos:</Body1Strong> Muestra un listado completo de las oficinas, proporcionando una visión general
                      de las ubicaciones de tu organización.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoritos:</Body1Strong> Te ayuda a centrarte en aquellas ubicaciones de oficinas que visitas frecuentemente o necesitas 
                      mantener bajo supervisión, marcándolas como 'Favoritos'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tu Oficina:</Body1Strong> Esta etiqueta personal filtra el directorio para mostrarte la ubicación de la oficina 
                      donde estás estacionado, manteniendo tu base a la mano.
                    </li>
                  </ul>
                  Ya sea que estés reservando una sala de reuniones o planeando una visita, estas etiquetas ofrecen una forma personalizada y simplificada de interactuar con el paisaje geográfico de tu organización.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Dominando las Vistas del Directorio de Oficinas en TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Sumérgete en el diseño organizacional de las diferentes ubicaciones de tu empresa con las vistas del Directorio de Oficinas de TeamConnect. Cada vista está diseñada para facilitar un aspecto único de la navegación y comprensión de la oficina:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vista de Lista:</Body1Strong> La configuración predeterminada que organiza ordenadamente todas las ubicaciones de las oficinas en una lista fácil de leer.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vista de Tarjeta Grande:</Body1Strong> La Vista de Tarjeta Grande en el Directorio de Oficinas enriquece tu vista con una presentación detallada de cada oficina. Al interactuar con una tarjeta de oficina, encontrarás una gran cantidad de información y opciones accionables dentro del menú "Más (...)":
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Ver Departamentos:</Body1Strong> Navega directamente a una lista de todos los departamentos situados dentro de la oficina.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Ver Empleados:</Body1Strong> Accede a un listado completo de empleados asignados a la oficina, facilitando el contacto directo o la exploración del personal de la oficina.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Gráfico Organizativo de la Entidad Empresarial:</Body1Strong> Visualiza el lugar de la oficina dentro del contexto organizativo más amplio para una clara comprensión de las estructuras jerárquicas.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Contactos Esenciales:</Body1Strong> Identifica los contactos clave de la oficina para una comunicación eficiente.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Festivos Públicos de la Oficina:</Body1Strong> Mantente informado sobre los festivos públicos específicos de la oficina para planificar tus horarios en consecuencia.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Horario Laboral de la Oficina:</Body1Strong> Consulta las horas de operación de la oficina para alinear tus visitas o llamadas con sus tiempos activos.
                        </li>
                      </ol>
                      Esta vista proporciona no solo una instantánea visual e informativa, sino también un acceso rápido a percepciones más profundas y acciones directas relacionadas con la oficina, todo cuidadosamente agrupado bajo la opción "Más (...)" para tu comodidad.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vista de Gráfico Organizativo:</Body1Strong> Visualiza la estructura jerárquica de oficinas principales y subordinadas para comprender la interconexión de diferentes ubicaciones de oficinas dentro de tu organización.
                    </li>
                  </ul>
                  Cambia entre estas vistas utilizando los iconos junto al cuadro de búsqueda para personalizar tu experiencia de navegación según tus necesidades inmediatas.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Encontrando Oficinas Rápidamente con la Función de Búsqueda de TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Localizar la ubicación de la oficina correcta dentro de TeamConnect se facilita con la función de búsqueda dedicada. Posicionada de manera prominente junto a los iconos de vista, el cuadro de búsqueda es tu herramienta para identificar oficinas por nombre. A medida que escribes, el directorio refina la pantalla para mostrar solo las oficinas que coinciden con tu entrada, agilizando tu proceso de búsqueda. Es importante usar este cuadro de búsqueda dentro de TeamConnect para obtener los resultados más precisos y relevantes, a diferencia de la búsqueda general de Microsoft Teams. Con esta funcionalidad de búsqueda específica, puedes navegar rápidamente a cualquier oficina en la red de tu organización.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Navegación Alfabética por el Directorio de Oficinas
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Si prefieres buscar por el alfabeto, nuestro filtro alfabético es una manera rápida de encontrar oficinas por nombre. En la parte superior del Directorio de Oficinas, verás 
                  las letras de la A a la Z. Haciendo clic en una letra, el filtro ajusta la vista para mostrar solo aquellas oficinas que comienzan con esa letra, simplificando tu proceso 
                  de búsqueda. Para restablecer y ver todas las oficinas de nuevo, simplemente deselecciona la letra activa. Esta función es especialmente útil cuando conoces el nombre de 
                  la oficina pero necesitas una manera rápida de localizarla dentro de TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Revelando Perfiles de Oficina Detallados en TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Embárcate en un viaje exploratorio a través del corazón de la presencia física de tu organización con los perfiles de oficina de TeamConnect. 
                  Haciendo clic en el nombre de una oficina se abre un diálogo de perfil vibrante, repleto de pestañas llenas de detalles exhaustivos sobre la oficina:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Descripción General:</Body1Strong> Sumérgete en la esencia de la identidad de la oficina con
                      descripciones detalladas, desde la estructura empresarial hasta los horarios de apertura y el tiempo local, capturando la esencia del ambiente de la oficina.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Contactos Esenciales:</Body1Strong> Accede a contactos cruciales para servicios de emergencia y 
                      soporte externo, cada uno a un clic de distancia a través del Sistema Telefónico de Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Contactos de la Oficina:</Body1Strong> Contacta a los servicios de soporte internos con facilidad, con 
                      detalles de contacto y opciones de interacción directa a tu alcance.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Festivos Públicos:</Body1Strong> Mantente al tanto de los feriados específicos de la oficina, asegurando que 
                      tus planes se alineen con las observancias locales y los horarios de trabajo.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Horarios de Oficina:</Body1Strong> Planifica tus interacciones con un conocimiento preciso de cuándo 
                      la oficina está llena de actividad o cuando cierra por el día.
                    </li>
                  </ul>
                  Ya sea la energía vibrante de Austin o el centro estratégico de Singapur, cada pestaña ofrece una puerta de entrada para entender y conectar con los diversos 
                  lugares que conforman la presencia global de tu empresa.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Profundizando en la Dinámica de Departamentos dentro de una Oficina
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navegar la red profesional de los departamentos de una oficina se despliega con facilidad en TeamConnect. Aquí te mostramos cómo profundizar en los detalles:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ver Departamentos en Esta Oficina:</Body1Strong> Descubre todos los departamentos que forman el 
                      tejido de la oficina seleccionando esta opción del menú Más (...).
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Perspectiva Organizacional:</Body1Strong> Haz clic en cualquier departamento para revelar su organigrama y 
                      obtener una representación visual de su estructura, con la guía del camino de migas: Oficina {'>'} Departamento {'>'} Organigrama.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Exploración Detallada del Departamento:</Body1Strong> Explora más a fondo un departamento haciendo clic en su 
                      menú Más (...) junto al nombre del departamento. Aquí, puedes acceder a una lista de todos los empleados dentro de ese departamento, justo en el contexto de la oficina.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Navegación por Ruta de Migas:</Body1Strong> Recorre los niveles sin problemas. Desde 
                      empleados de vuelta a departamentos o desde el organigrama, el camino de migas (Oficina {'>'} Departamento {'>'} Empleado {'>'} Organigrama) mantiene tu 
                      viaje intuitivo y en curso.
                    </li>
                  </ul>
                  Los intrincados caminos de TeamConnect te invitan a explorar, entender y comprometerte con cada capa de los departamentos de tu oficina, otorgándote un 
                  dominio completo de la navegación.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Conectándose con el Personal de la Oficina en TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ¿Curioso acerca de las caras detrás de una oficina? La respuesta está a solo un clic de distancia en TeamConnect. Interactúa con los puntos suspensivos (tres puntos) junto al nombre de tu oficina elegida para revelar un conjunto de opciones interactivas:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ver Empleados en Esta Oficina:</Body1Strong> Descubre una lista de todos los profesionales 
                      que consideran a esta oficina su lugar de trabajo. El número mostrado ofrece un recuento rápido de los empleados, invitándote a sumergirte en la comunidad de la oficina.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Opciones de Contacto:</Body1Strong> Cada entrada de empleado está equipada con iconos de acción rápida, 
                      lo que te permite iniciar chats, llamadas o incluso videoconferencias directamente desde TeamConnect, fomentando una comunicación intraoficina sin problemas.
                    </li>
                    <li><Body1Strong>Navegación:</Body1Strong> Para volver a la lista de oficinas más amplia desde una lista de empleados, simplemente haz clic en 'Oficina' en el 
                      camino de migas (Oficina {'>'} Empleado), y serás llevado de vuelta al resumen de la oficina.
                    </li>
                  </ul>
                  Con estas características, TeamConnect hace que sea simple visualizar la distribución de la fuerza laboral a través de las oficinas y comunicarse sin esfuerzos, 
                  fomentando un lugar de trabajo conectado sin importar la distancia.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. ¿Falta información de tu oficina o está desactualizada?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Las actualizaciones de la información de la oficina en TeamConnect pueden ser gestionadas por los editores de oficina asignados. Si tienes dudas sobre los editores de oficina o 
                  necesitas asistencia con información que cae bajo la jurisdicción de RRHH, el equipo de soporte de RRHH está listo para 
                  ayudar en <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Preguntas Frecuentes sobre Configuración</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. La Importancia de una Configuración General Precisa en TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  En el paisaje dinámico del entorno laboral actual, mantenerse conectado y coordinado es más crucial que nunca. Aquí es donde actualizar tus 
                  Configuraciones Generales en TeamConnect juega un papel pivotal:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Selección de Lugar de Trabajo:</Body1Strong> Captura la esencia de tu entorno laboral actualizando tu Lugar de Trabajo para reflejar tu situación 
                        actual, ya sea cambiando desde la tranquilidad de tu Hogar-Oficina al bullicio de la Oficina principal, o incluso adaptándote a un Área Pública. 
                        Esta claridad en tu localización laboral ofrece a los colegas una visión no solo de las mejores maneras de contactarte, sino también sobre la naturaleza de 
                        las conversaciones que pueden tener contigo, protegiendo la confidencialidad de discusiones sensibles especialmente cuando estás en el entorno abierto de 
                        una oficina de cliente o sentado en la sala de un aeropuerto.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Especificación de Puesto de Trabajo:</Body1Strong> Compartir tu ciudad de trabajo hace más que acortar distancias en un equipo remoto—también 
                        abre puertas a reuniones profesionales espontáneas o socializaciones casuales. Ya sea un café rápido, un almuerzo sin prisa o una reunión colaborativa, 
                        saber que estás en la misma ciudad puede transformar un día normal en una oportunidad para conexiones significativas cara a cara. Esto no 
                        solo fortalece los lazos del equipo, sino que también proporciona una comprensión más profunda de los matices locales que pueden estar influyendo en tu trabajo.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Exactitud de Zona Horaria:</Body1Strong> Con equipos que abarcan continentes, asegurar que tu zona horaria refleje 
                      tu localización actual es esencial para armonizar las comunicaciones. La Hora Local en tu perfil de TeamConnect está directamente influenciada por esta configuración, 
                      eliminando cualquier suposición por parte de los colegas que intentan contactarte.
                    </li>
                  </ul>
                  Sumérgete en 'Configuración' {'>'} 'Configuración General' dentro de TeamConnect para ajustar estos detalles. Unos momentos dedicados a actualizar tus configuraciones pueden 
                  mejorar significativamente la cohesión y la eficiencia de tus esfuerzos colaborativos en general.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Descifrando la Selección de Lugar de Trabajo en TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Elegir el ajuste correcto en el menú desplegable de Lugar de Trabajo personaliza TeamConnect para reflejar tu estado actual de trabajo de manera precisa. Pasa el mouse sobre el icono de información para obtener una explicación detallada de cada opción:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Break:</Body1Strong> Indica tiempo fuera del trabajo, ya sea por un descanso o el fin del día.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office/Field-Office:</Body1Strong> Señala presencia en un espacio de trabajo gestionado por la empresa.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> Trabajando desde un espacio dedicado en casa.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Public-Area:</Body1Strong> Operando desde un entorno fuera de la oficina, lo que sugiere una necesidad de comunicación flexible.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Customer Workplace/Private Office:</Body1Strong> En el sitio con un cliente, con una nota sobre los niveles de privacidad para la sensibilidad de la conversación.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Commute/Business-Travel/Training:</Body1Strong> En movimiento o involucrado en actividades específicas relacionadas con el trabajo fuera de la oficina.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Public-Holiday/Vacation/Various Leaves:</Body1Strong> Oficialmente fuera de servicio por motivos personales, de salud o vacaciones.</li>
                  </ul>
                  Seleccionar un ajuste de lugar de trabajo apropiado asegura que tu equipo tenga el contexto necesario para las interacciones, respetando la disponibilidad y privacidad.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Navegando el estado 'Checked-In' en TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  La etiqueta 'Checked-In' en el Directorio de Empleados está diseñada para mostrar a todos los empleados que están actualmente en el trabajo. Esta visibilidad se determina por la selección de Lugar de Trabajo que realices:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ambientes de Trabajo Activos:</Body1Strong> Las opciones de Office, Field-Office, Home-Office, Remote-Workplace, Public-Area, Customer-Workplace, 
                    Customer Private-Office, Commute, Business-Travel y Training te señalan como comprometido en el trabajo, registrándote como 'Checked-In' y visiblemente activo para los colegas.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Configuraciones No Laborales:</Body1Strong> Optar por Checked-Out, Break, Public-Holiday, Vacation, On-Leave, Maternity-Leave o Sick-Leave 
                      te retira de la lista de 'Checked-In', estableciendo expectativas claras para la disponibilidad y tiempos de respuesta.
                  </li>
                  </ul>
                  Esta funcionalidad ayuda a delinear los períodos de trabajo del tiempo personal, ayudando en la colaboración y comunicación efectiva del equipo.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Preguntas frecuentes sobre gestión de datos y privacidad</Subtitle2>
          <Body1>
            <p>
              En la era digital de hoy, entender cómo se gestionan tus datos es crucial. TeamConnect está comprometido con la transparencia y prácticas robustas de protección de datos. 
              A continuación, encontrarás información sobre cómo manejamos, almacenamos y protegemos tus datos dentro de TeamConnect, asegurando que tu espacio de trabajo digital sea seguro y cumpla con las normativas.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. ¿Cómo protege TeamConnect mis datos personales?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect está dedicado a proteger tu información personal con un enfoque integral de seguridad de datos:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Inicio de Sesión Único (SSO):</Body1Strong> Para aumentar la seguridad y optimizar la experiencia del usuario, TeamConnect integra 
                      el Inicio de Sesión Único (SSO) automatizado con tu cuenta de Microsoft 365, facilitando una gestión de acceso segura y sin esfuerzo.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Cifrado:</Body1Strong> Nos aseguramos de que todos los datos dentro de TeamConnect estén cifrados tanto durante su transmisión como cuando están inactivos, 
                      haciéndolos inaccesibles para partes no autorizadas.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Minimización de Datos:</Body1Strong> Nuestra recolección de datos se limita estrictamente a lo esencial para la funcionalidad de TeamConnect. 
                      La única información del usuario que almacenamos es tu Nombre Principal de Usuario (UPN) de Azure Entra ID, que puede parecerse a tu dirección de correo electrónico de la empresa.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Acceso Restringido:</Body1Strong> El acceso al UPN se limita al Administrador de Suscripciones de tu organización dentro de nuestro portal de suscripciones, 
                      asegurando que la información sensible solo sea visible para el personal autorizado.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Eliminación Rápida de Datos:</Body1Strong> TeamConnect está estructurado para eliminar instantáneamente el UPN almacenado bajo tres condiciones específicas: 
                      si la suscripción es cancelada por el Administrador de Suscripciones, si un usuario es eliminado por RRHH dentro de TeamConnect, o si la suscripción de TeamConnect expira. Esto asegura que tus datos personales no se retengan más allá de su vida útil o necesidad.
                    </li>                      
                  </ul>
                  <p>A través de estas medidas, TeamConnect se compromete a mantener los más altos estándares de protección de datos, asegurando que tu información personal permanezca segura y confidencial.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. ¿Te preguntas dónde residen tus datos con TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect rompe el molde de las ofertas tradicionales de SaaS asegurando que tus datos nunca abandonen tu organización. 
                  A diferencia de la práctica habitual de almacenar datos en servidores externos en la nube, TeamConnect utiliza ingeniosamente el 
                  Microsoft 365 SharePoint Online de tu organización para alojar todos los datos de la aplicación. Este enfoque innovador no solo defiende la soberanía de los datos sino que también fortalece la seguridad,
                  alineándose perfectamente con nuestra firme dedicación a proteger tu privacidad y cumplir con estrictos estándares de cumplimiento.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. ¿Se compartirán mis datos con terceros?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ¡No, los datos no se comparten con terceros! Cubeet es firme en nuestro compromiso con la privacidad y seguridad de tus datos. No compartimos ningún dato de TeamConnect con terceros sin obtener el consentimiento explícito de nuestros clientes. 
                  En nuestro entorno seguro, almacenamos solo identificadores esenciales, como el Nombre Principal de Usuario (UPN), nombre de la organización, nombre del arrendatario y ID del arrendatario, dentro de nuestro portal de suscripción. 
                  Esta información es crucial para la prestación de servicios y está protegida bajo nuestras estrictas políticas de privacidad.
                  Ten la seguridad, nuestras prácticas de gestión de datos están diseñadas para asegurar que tus datos permanezcan confidenciales, con transparencia y control firmemente en tus manos.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. ¿Cómo cumple TeamConnect con las regulaciones de protección de datos como el GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect se alinea con regulaciones de protección de datos como el GDPR, HIPAA, CCPA, y otros, aprovechando las características de cumplimiento inherentes del entorno de Microsoft 365, 
                  donde todos los datos de los clientes están almacenados de manera segura. A diferencia de los modelos tradicionales de SaaS, TeamConnect no almacena ningún dato de cliente en sus propios servidores; en cambio, opera dentro del 
                  entorno de Microsoft 365 de tu organización, adheriéndose a sus robustos estándares de seguridad y cumplimiento. Este enfoque garantiza que la gobernanza de tus datos permanezca consistente con 
                  el marco de cumplimiento integral proporcionado por Microsoft 365, incluyendo la adherencia al GDPR, HIPAA para información de salud, CCPA para residentes de California, 
                  y otras regulaciones globales de protección de datos. Al integrarse perfectamente con tu configuración existente de Microsoft 365, TeamConnect empodera a tu organización para mantener 
                  el control total sobre tus datos, asegurando el cumplimiento con las leyes y regulaciones de protección de datos más estrictas.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. ¿Cómo maneja TeamConnect los datos de chats, llamadas, videollamadas de Microsoft Teams, correos de Outlook y llamadas del sistema telefónico?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                <Body1>
                  TeamConnect aprovecha la API de Microsoft Graph para habilitar interacciones directas como chat, llamadas, videollamadas y correos de Outlook dentro de Microsoft Teams, 
                  proporcionando una experiencia de usuario fluida. Es crucial, aunque TeamConnect inicie estas comunicaciones, lo hace sin intervenir en el proceso de comunicación 
                  ni tiene la capacidad de grabar o almacenar contenido de la interacción. Todas las conversaciones, llamadas y correos se realizan y se gestionan directamente dentro de 
                  los confines seguros de Microsoft Teams y Outlook, en estricta adherencia a los robustos estándares de seguridad y privacidad de Microsoft 365. Esto asegura que TeamConnect mejora 
                  tus capacidades de comunicación mientras preserva completamente la confidencialidad e integridad de tus datos.
                </Body1>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Para obtener información detallada sobre nuestras políticas y prácticas de datos, también puedes consultar el {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>Guía del Usuario de TeamConnect</Body1Strong></Link> o al departamento de TI de tu organización en <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> para detalles específicos relacionados con tu configuración.
          </p>
        </Body1>        
        <p></p>  
      <Subtitle1 className={styles.fontColor}>Estamos Escuchando: Comparte tus Comentarios e Ideas</Subtitle1>
        <Body1>
          <p>
            Tus percepciones impulsan la evolución de TeamConnect. Ya sea que estés rebosante de una brillante idea para una función o tengas algún 
            comentario sobre tu experiencia actual, estamos atentos. Tu aporte es invaluable en nuestra continua búsqueda para proporcionar el 
            mejor servicio posible.
          </p>
        </Body1>      
      <Subtitle2>Comentarios y Sugerencias de Características</Subtitle2>
        <Body1>
          <p>
            ¿Listo para compartir? Dirígete a nuestra {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Página de Comentarios
            </Link>
            . Ya sea una sugerencia para una nueva función o comentarios sobre funcionalidades existentes, simplemente selecciona 
            la opción apropiada del menú desplegable en el formulario. Tus contribuciones ayudan a dar forma al futuro de TeamConnect, 
            asegurando que cumpla y supere tus necesidades.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;

