import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import './permission.css';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const ViewSuccess = () => {
  return (
    <>
      <h2>Permission Granted Successfully!</h2>
      <p>
        Thank you for granting the necessary permissions. To continue with setup, please return to the TeamConnect within Microsoft Teams.
      </p>
      <p>
        If you have any questions or need further assistance, feel free to contact us at <a href="mailto:support.teamconnect@cubeet.ai" className="App-link">support.teamconnect@cubeet.ai</a>.
      </p>
    </>
  )
};

const ViewFailed = ({
  error,
  errorDescription,
}) => {
  return (
    <>
      <h2>Permission Granted Failed!</h2>
      <p>
        Unfortunately, when you are granting permissions, an exception is happening as below:
      </p>
      <p>
        Error: {error || ''}
      </p>
      <p>
        Error description: {errorDescription || ''}
      </p>
      <p>
        If you have any questions or need further assistance, feel free to contact us at <a href="mailto:support.teamconnect@cubeet.ai" className="App-link">support.teamconnect@cubeet.ai</a>.
      </p>
    </>
  )
};

function Permission() {
  const query = useQuery();
  const error = query.get('error');
  const errorDescription = query.get('error_description');

  return (
    <div className="App">
      <header className="App-header">
        {
          (error && errorDescription) 
          ? <ViewFailed error={error} errorDescription={errorDescription} />
          : <ViewSuccess />
        }

        <footer>
          <a
            className="App-link"
            href="https://cubeet.ai"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cubeet Pte. Ltd. © 2024
          </a>
        </footer>
      </header>
    </div>
  );
}

export default Permission;