import React, { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../config/authConfig';
import { getMeInfo } from '../../services/authService';
import { addLogin } from '../../services/loginService';
import { Loading, SubscriptionInfo } from '../../components';
import { Body1, Body1Strong, Subtitle1 } from "@fluentui/react-components";
import './home.css';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const Container = () => {
  const query = useQuery();
  const purchaseToken = query.get('token');
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(null);
  const [meProfile, setMeProfile] = useState(null);
  
  const requestProfileData = async (inst, acct) => {
    try 
    {
      setIsLoading(true);

      const resAccess = await inst.acquireTokenSilent({
        ...loginRequest,
        account: acct,
      });
      const { accessToken } = resAccess;
      setAccessToken(accessToken);

      const resMe = await (getMeInfo(accessToken));
      setMeProfile(resMe);

      await addLogin(resMe);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    };
  };

  useEffect(() => {
    const runApp = async () => {
      if (instance && Array.isArray(accounts) && accounts.length) {
        const account = accounts[0];
        return requestProfileData(instance, account);
      };
    };
    
    if (instance && accounts) runApp();
  }, [instance, accounts]);

  return (
    <>
      <Subtitle1 className="card-title">Welcome {accounts[0].name}</Subtitle1>
      <br/>
      <p>{meProfile?.userPrincipalName}</p>
      {
        (isLoading || !meProfile) 
        ? <Loading text={'Initializing...'} />
        : null
      }

      <br/>
      {
          (!isLoading && meProfile && purchaseToken) 
        ? <SubscriptionInfo meProfile={meProfile} purchaseToken={purchaseToken} accessToken={accessToken} />
        : (!isLoading && meProfile && !purchaseToken) 
        ? <div><Body1>Our platform needs your <Body1Strong>Microsoft</Body1Strong> purchase token to register your subscription.</Body1></div>
        : null
      }
    </>
  );
};

export default Container;
