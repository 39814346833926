export const getSettingItem = async (token) => {
  try 
  {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
    };

    if (process.env.NODE_ENV !== 'production') {
      return {
        status: true,
        message: 'this feature is only run in production',
      };
    };

    const url = `/v1/api/setting/item`;
    const res = await (await fetch(url, options)).json();

    return res;
  } catch (error) {
    throw error;
  };
};

export const updateFreeTrial = async (token, value) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
      body: JSON.stringify({
        value: value,
      }),
    };

    if (process.env.NODE_ENV !== 'production') {
      return {
        status: true,
        message: 'this feature is only run in production',
      };
    };

    const url = '/v1/api/setting/freetrial';
    const res = await (await fetch(url, options)).json();
    
    return res;
  } catch (error) {
    throw error;
  };
};

export const updateMsAccount = async (token, value) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
      body: JSON.stringify({
        value: value,
      }),
    };
    
    if (process.env.NODE_ENV !== 'production') {
      return {
        status: true,
        message: 'this feature is only run in production',
      };
    };
    
    const url = '/v1/api/setting/msaccount';
    const res = await (await fetch(url, options)).json();

    return res;
  } catch (error) {
    throw error;
  };
};