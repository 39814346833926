export const getCubeetSubscription = async (subscriptionId) => {
  try 
  {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (process.env.NODE_ENV !== 'production') {
      return {
        status: true,
        message: 'this feature is only run in production',
      };
    };

    const url = `/v1/api/subscription/info?subscriptionId=${subscriptionId}`;
    const res = await (await fetch(url, options)).json();

    return res;
  } catch (error) {
    throw error;
  };
};

export const resolveCubeetSubscription = async (subscriptionInfo) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subscriptionInfo: subscriptionInfo,
      }),
    };

    if (process.env.NODE_ENV !== 'production') {
      return {
        status: true,
        message: 'this feature is only run in production',
      };
    };

    const url = '/v1/api/subscription/resolve';
    const res = await (await fetch(url, options)).json();
    
    return res;
  } catch (error) {
    throw error;
  };
};

export const subscribedCubeetSubscription = async (subscriptionId) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subscriptionInfo: {
          subscriptionId: subscriptionId,
          subscriptionStatus: 'Subscribed',
        },
      }),
    };
    
    if (process.env.NODE_ENV !== 'production') {
      return {
        status: true,
        message: 'this feature is only run in production',
      };
    };
    
    const url = '/v1/api/subscription/subscribed';
    const res = await (await fetch(url, options)).json();

    return res;
  } catch (error) {
    throw error;
  };
};