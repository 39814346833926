import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();

  return (
    <>
      <Title3 className={styles.fontColor}>TeamConnect 帮助中心与常见问题</Title3>
        <p><Body1>
          欢迎来到 TeamConnect 帮助中心，旨在支持您使用我们全面的企业目录应用的体验。在这里，您将找到资源，以优化您在 Microsoft Teams 中使用 TeamConnect 的体验，增强您的网络通信。
        </Body1></p>
        <Subtitle2>快速参考指南</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>增强的搜索功能：</Body1Strong>
              使用我们强大的搜索工具快速找到合适的同事、部门和关键联系人。
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>视觉组织图表：</Body1Strong>
              通过直观的组织图表轻松导航您的组织层次结构并了解汇报线路。
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>适应当今需求的工具：</Body1Strong>
              利用为当代工作场所需求量身定制的现代功能。
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>优化您的体验</Subtitle2>
        <Body1>
          <p>
            TeamConnect 彻底改变了您在组织结构中的互动方式，将日常通信转变为战略性接触。
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>获取帮助和常见问题</Subtitle1>
        <Body1>
          <p>
            在此专 dedicated区中查找有关 TeamConnect 功能和设置的常见问题的答案。无论您是在导航应用程序、自定义设置，还是寻求解决挑战的解决方案，我们的帮助中心都在这里为您提供高效的帮助。
          </p>
        </Body1>
        <Body1>
          <p>
            如需更深入的支持或具体指导，请参阅 <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnect 用户指南</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        <Subtitle2>使用TeamConnect常见问题解答</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. 在Microsoft Teams侧边栏中找不到TeamConnect？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  如果在您的Microsoft Teams侧边栏中看不到TeamConnect或它消失了，您可以轻松地固定它以便快速访问。只需遵循以下简单步骤：
                  <ol>
                    <li className={styles.bulletBottomMargin}>打开Microsoft Teams并点击侧边栏底部的“...”（+应用程序）图标。</li>
                    <li className={styles.bulletBottomMargin}>在弹出窗口顶部的搜索栏中搜索“TeamConnect”。</li>
                    <li className={styles.bulletBottomMargin}>右键点击搜索结果中的TeamConnect应用程序并选择“固定”。</li>
                  </ol>
                  TeamConnect现在将被固定到您的侧边栏，只需单击一下即可访问！
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. 遇到TeamConnect的技术问题？以下是获取帮助的方法。
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  如果您在Microsoft Teams中使用TeamConnect时遇到任何技术问题，您所在组织的IT支持团队在此为您提供帮助。
                  请通过<Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>与他们联系，他们将迅速为您提供帮助。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. 找不到您的员工详细信息？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  如果您的个人资料看起来有些空，只显示您的姓名和电子邮件，很可能是您的HR团队尚未将您的其他员工详细信息添加到TeamConnect中。
                  不用担心！给您的HR支持团队发一条快速消息将会启动事务。他们随时准备完善您的个人资料，填写所有重要详细信息。
                  请通过<Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>联系他们，看着您的个人资料变得生动起来！
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. TeamConnect中有哪些语言选项？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect通过支持Microsoft Teams的所有语言来拥抱全球多样性。
                  无论您偏好阿拉伯语还是希腊语，日语或瑞典语，您都有一个世界的选项在您的指尖，包括：
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      阿拉伯语、保加利亚语、中文（简体和繁体）、克罗地亚语、捷克语、丹麦语、荷兰语、英语、爱沙尼亚语、芬兰语、
                      法语、德语、希腊语、希伯来语、匈牙利语、印度尼西亚语、意大利语、日语、韩语、拉脱维亚语、立陶宛语、挪威语（Bokmal）、
                      波兰语、葡萄牙语（巴西和葡萄牙）、罗马尼亚语、俄语、塞尔维亚语（拉丁）、斯洛伐克语、斯洛文尼亚语、西班牙语、瑞典语、泰语、
                      土耳其语、乌克兰语、越南语。
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  要根据您的偏好自定义TeamConnect体验，请直接在Microsoft Teams中调整语言设置，因为TeamConnect与这些设置完全整合：
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      点击顶部个人资料图片旁边的三个点，然后选择“外观和辅助功能”，进入您的Microsoft Teams设置。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      滚动到“语言”部分并从下拉菜单中选择您偏好的语言。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      点击“保存并重启”按钮以应用新的语言设置，确认您的更改。
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>注意：</Body1Strong>
                <Body1>
                  您在Microsoft Teams中所做的语言设置更改也将无缝更新您的TeamConnect体验。
                  请注意，更改Teams中的语言也可能调整您的时间和日期格式。目前，TeamConnect正在发展以适应从右到左的写作。
                  我们对这些改进感到兴奋，并将在它们可用时通知您。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. 如何在TeamConnect中更改主题？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect会与您的Microsoft Teams主题偏好设置同步，包括浅色、深色和高对比度选项。要自定义您的主题：
                  <ol>
                    <li className={styles.bulletBottomMargin}>通过点击位于顶部的个人资料图片旁的三个点来访问您的Microsoft Teams设置。</li>
                    <li className={styles.bulletBottomMargin}>选择“外观和可访问性”来探索主题选项。</li>
                    <li className={styles.bulletBottomMargin}>从可用选项中选择您偏好的主题，以即时更新您的TeamConnect界面以匹配。</li>
                  </ol>
                  您在Microsoft Teams中的主题选择将自动应用到TeamConnect上，确保您的工作区域有一致的视觉体验。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. 探索TeamConnect的联系人操作按钮
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在TeamConnect中，无论您在何处找到员工的联系方式，您也会发现我们方便的快速访问按钮。这些便捷工具旨在简化您在Microsoft生态系统内的沟通，确保您总是只需点击一下就能进行连接。
                  这里是使用这些按钮的全面指南：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>聊天图标：</Body1Strong> 点击以开始即时Microsoft Teams聊天。如果您需要在开始聊天后返回TeamConnect，只需使用桌面应用中的后退箭头、移动应用中的后退按钮或浏览器的后退按钮。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>邮件图标：</Body1Strong> 打开一个新的Outlook电子邮件草稿，收件人地址已准备好在“To:”字段中。如果Outlook没有启动，检查您的IT部门是否有任何限制是值得的。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>视频通话图标：</Body1Strong> 使用Microsoft Teams启动面对面视频通话。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>通话图标：</Body1Strong> 开始Microsoft Teams语音通话，使快速讨论变得无缝。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>组织结构图标：</Body1Strong> 显示员工在组织层次结构中的位置，包括他们的经理和直接下属。</li>
                    <li><Body1Strong>更多（...）图标：</Body1Strong> 揭示更多选项：
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>立即开会：</Body1Strong> 打开Microsoft Teams立即与员工开始会议。</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>新会议：</Body1Strong> 引导您进入Microsoft Teams安排新会议。</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>添加联系人：</Body1Strong> 将员工的联系信息从TeamConnect添加到您新的Microsoft Teams People app中，丰富您的通话功能。</li>
                      </ul>
                    </li>
                  </ul>
                  这些直观的按钮旨在确保您可以有效地与同事连接，无论是进行快速聊天、发送电子邮件还是安排未来的会议。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. 通过TeamConnect进行通话
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  寻找进行通话的方式？TeamConnect通过与Microsoft Teams集成简化了您的沟通。
                  只需单击TeamConnect中所需的电话号码，voilà，Microsoft Teams的呼叫界面即弹出，
                  为您无缝拨号做好准备。
                  <p>
                  <Body1Strong>注意：</Body1Strong>
                  遇到通话问题？这可能是由于您的组织的IT政策或使用的电话系统不是Microsoft Teams Phone System。在<Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>，联系您的IT部门可能会对此问题有所帮助。
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. 通过TeamConnect打电话有相关费用吗？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect通过利用Microsoft Teams来增强您的通话体验。以下是您需要了解的与电话相关的费用信息：
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      对于呼叫您组织内的员工工作电话、部门电话或热线（内部号码），使用Microsoft Teams Phone System通常<Body1Strong>没有费用</Body1Strong>。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      对于外部电话，例如拨打到移动电话或外线，适用<Body1Strong>标准费率</Body1Strong>，具体费率根据您与Microsoft Teams的具体服务计划而定。
                    </li>
                  </ul>
                  <p><Body1Strong>注意：</Body1Strong>如果您遇到任何问题或有关电话费用的问题，最好咨询您的IT部门，通过<Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>获取针对您组织的设置和政策的详细信息。</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. 发现您在TeamConnect的'外出办公'状态不匹配？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  注意到您从Microsoft 365的'外出办公'状态在TeamConnect中没有正确反映？我们已经解决了这个问题。'外出办公'状态同步功能已在TeamConnect中实现。
                  我们目前正在等待Microsoft推出此功能。一旦实现，您的'外出办公'状态将在TeamConnect中准确反映，与Microsoft Teams的出席指示器无缝对接，提供连贯的体验。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>员工目录常见问题解答</Subtitle2>
        <Body1>
          <p>
            <Body1Strong>员工目录介绍：</Body1Strong>通过员工目录导航您的组织的职业景观，这是您获取详细员工见解、职场动态以及与同事的资料和管理关系直接连接的中央枢纽。
          </p>
        </Body1>
        <div>
        <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
          <AccordionItem value="10">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                10. 需要添加、更新或更正您的员工数据？
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                如需在TeamConnect中添加、更新或更正您的员工数据，请联系您组织的HR支持团队。他们随时准备帮助<Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="11">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                11. 当我点击目录中某个员工的名字时会发生什么？
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                在TeamConnect员工目录中点击一个员工的名字，会打开该员工的详细个人资料页面。此资料页提供关于员工的全面洞察，包括但不限于其职位、部门、办公地点、联系信息等。它旨在为您提供员工的职业角色全景以及他们如何融入组织，使您更容易理解他们的职责以及您可能如何与他们合作。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="12">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                12. 员工资料中包含哪些信息？
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect中的每个员工资料都是一个全面的快照，提供关于个人职业生活的丰富洞察。以下是您将找到的内容：
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>基本信息：</Body1Strong>姓名、职称、部门和子公司位置。</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>报告结构：</Body1Strong>直接和功能报告的信息，包括线性和功能经理。</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>工作状态：</Body1Strong>当前的工作状态，例如休假、通勤、出差等。</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>工作地点和当地时间：</Body1Strong>雇员当前的工作位置和当地时区。</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>联系方式：</Body1Strong>工作电话、手机号码和电子邮件地址。</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>办公地址：</Body1Strong>完整的办公位置，包括建筑名称、地址和Google地图。</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>关于：</Body1Strong>简短的个人介绍或职业简历。</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>员工关系：</Body1Strong>员工的报告结构和团队关系的详细信息。</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>社交链接与语言：</Body1Strong>专业社交资料链接和语言熟练度。</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>技能：</Body1Strong>专业技能和专业领域的列表。</li>
                </ul>
                此外，资料还提供了通过聊天、电子邮件、视频通话或安排会议的可操作选项，以及访问组织图表的快速通道以获取更广泛的团队背景。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="13">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                13. 如何更新我的个人资料信息？
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                在TeamConnect中，个性化您的个人资料很简单。以下是操作步骤：
                  <ol>
                    <li className={styles.bulletBottomMargin}>
                      导航至 <Body1Strong>Employee</Body1Strong> 标签并点击您的 <Body1Strong>Avatar</Body1Strong> 打开您的个人资料。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      在左上角，您会找到 <Body1Strong>"Edit your profile"</Body1Strong> 链接。
                      点击此链接将打开编辑您的个人资料的对话框。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      在这里，您可以通过添加或更新您的 <Body1Strong>About You</Body1Strong> 部分、社交媒体链接和电话号码来增强您的个人资料。
                      这使得同事更容易与您联系或关注您。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      不要忘记按 <Body1Strong>Save</Body1Strong> 按钮以应用您的更改。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      在对话框中向下滚动，添加您的 <Body1Strong>Languages</Body1Strong> 和 <Body1Strong>Proficiency</Body1Strong>，
                      以及您的 <Body1Strong>Skills</Body1Strong>。分享这些信息有助于同事了解您的专业领域和您可以用的语言。
                    </li>
                  </ol>
                更新您的个人资料不仅可以保持您的信息是最新的，还可以在您的组织内促进更好的联系。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="14">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                14. 在TeamConnect中更新您的办公地点、工作地点和时区
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                在Microsoft Teams中保持您的办公地点、工作地点和时区信息更新是至关重要的，便于无缝协作。
                这些更新将立即反映在您的TeamConnect个人资料和列表中，使同事能够迅速了解您的工作地点
                和最佳联系时间，增强跨不同地区和时区的团队合作。
                <p></p>
                准备更新您的详细信息？在TeamConnect中很容易！前往顶部菜单中的'Settings'，导航至'General Settings'。
                在这里，您可以顺利更新您的办公地点和时区，并指定您当天的工作地点。记住保存您的更改，
                以保持每个人都得到通知并确保有效的协作，无论您在哪里！
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="15">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                15. 发现同事的个人资料有错误？
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                如果您发现同事的个人资料某些细节似乎不太正确，最好的第一步是给他们一个友好的提醒。
                一次快速的聊天或电子邮件就可以解决问题！如果由于某种原因信息没有更新，不要担心——只需
                给您的HR支持团队发信至<Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>，他们将很乐意介入并解决问题。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="16">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                16. 理解标签组：全部，收藏，已签到和新员工
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>全部：</Body1Strong>这个默认标签展示了您组织内的每一位员工，
                    提供了团队的全面视图。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>收藏：</Body1Strong>点击这里筛选视图到你标记为“收藏”的同事，
                    使得快速联系关键联系人更加容易。
                  </li>                  
                  <li className={styles.bulletBottomMargin}><Body1Strong>已签到：</Body1Strong>此标签表示正在工作的员工，区别于仅仅在Microsoft Teams在线的状态。
                    签到时，员工表示他们正在工作并在工作日分享他们的当前工作位置。这可能包括各种工作场所，如办公室、分办公室、家办、远程工作地、客户工作地、
                    客户私人办公室、公共区域、通勤、商务旅行甚至培训。因此，“已签到”状态提供了积极工作的明确指示，为同事们提供了对方的当前工作环境和可用性的精确理解。</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>新员工：</Body1Strong>使用此标签在TeamConnect中标出新面孔，
                    高亮显示在过去30天内加入应用程序的员工。通常，这与您组织的最新成员相符，帮助您欢迎新团队成员。
                  </li>
                </ul>
                这些标签组旨在简化导航并增强您的TeamConnect体验，确保您始终可以高效地找到并与同事连接。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="17">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                17. 解码搜索框旁边的图标
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect的员工目录旨在提供多功能性和便利性，设有四个图标提供不同视图和快速访问关键详情。以下是每个图标揭示的功能：
                <ol>
                  <li className={styles.bulletBottomMargin}><Body1Strong>列表视图：</Body1Strong>您的首选视图，直观地展示所有组织成员。</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>小卡片视图：</Body1Strong>一种紧凑的替代方案，此视图将列表转换为小巧的员工卡片。</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>大卡片视图：</Body1Strong>选择更多细节的大卡片，其中包括额外的联系选项如电话和移动号码，便于轻松拨打。</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>组织架构图：</Body1Strong>使用组织架构图视图可视化公司的结构，完美地理解汇报关系和导航组织景观。</li>
                </ol>
                在这些图标之间切换，找到最适合您当前需求的视图，无论您是寻求快速信息还是更深入的见解。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="18">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                18. 快速搜索同事，我应该在哪里查看？
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                寻找一位同事？直接前往位于右上角靠近组织架构图标旁的TeamConnect搜索框。开始输入他们的名字，随着每输入一个字母，结果会逐渐变得精确。记住，对于TeamConnect搜索，跳过顶部的Microsoft Teams搜索栏——我们特殊的搜索框才是正确的选择！
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="19">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                19. 字母顺序搜索如何帮助查找员工？
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect的员工目录包括一个便捷的字母顺序搜索功能，帮助您快速定位员工的名字。只需点击目录顶部显示的字母表中的一个字母，TeamConnect将立即显示所有名字以该字母开头的员工。
                要返回到完整的员工列表，只需取消选择突出显示的字母。这个直观的功能使得浏览大型员工列表变得轻而易举，确保您能快速高效地找到同事。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="20">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                20. 如何调整每页显示的员工数量？
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect允许您自定义每页查看多少员工简介，使浏览员工目录更加容易。在每页的底部，您会发现分页选项：10、50、100、500、1000和5000。默认设置显示每页10个简介。
                要在单页上查看更多简介，只需从这些选项中选择您偏好的数字。这个功能提供了在浏览目录时的灵活性，使您能够根据您的具体需求或偏好调整显示方式。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="21">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                21. 如何在列表视图中排序员工信息？
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                在TeamConnect的员工目录列表视图中，您可以根据需要灵活地对员工信息进行排序。列—员工姓名、职务、部门、办公地点和工作地点—都是可排序的。只需点击您希望排序的列标题。
                点击一次将按升序排列列，再次点击将按降序排列。默认情况下，目录按员工姓名的名字以升序排序。这种排序功能帮助您快速组织并查看员工详细信息，无论您是在寻找特定某人还是需要按某个特定标准如部门或位置对员工进行分组。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>部门目录常见问题解答</Subtitle2>
        <Body1>
          <p>
          <Body1Strong>部门目录介绍：</Body1Strong> 部门目录是您了解组织结构复杂网络的门户，提供部门层级、管理领导和顺畅部门沟通途径的清晰视图。
          </p>
        </Body1>        
      <div>
        <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
          <AccordionItem value="22">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                22. 部门目录中的“全部”、“收藏”和“您的部门”标签是如何工作的？
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                在TeamConnect的部门目录中，标签帮助您快速导航和过滤部门信息。以下是每个标签代表的内容：
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>全部：</Body1Strong> 此标签显示组织内的所有部门，提供全面概览。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>收藏：</Body1Strong> 使用此标签查看您标记为“收藏”的部门的策划列表，便于访问您经常互动的部门。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>您的部门：</Body1Strong> 此标签过滤视图，仅显示您的部门，快速访问您的直接部门信息和更新。</li>
                </ul>
                这些标签旨在简化您在部门目录中的导航，增强您高效查找和参与部门信息的能力。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="23">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                23. 了解TeamConnect中的部门目录视图
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                通过TeamConnect的部门目录导航，提供多种视图选项以适应您的偏好。每种视图都提供了探索全面部门信息的独特方式，包括部门名称、部门经理、上级部门、部门电话、部门热线和部门电子邮件。以下是可用视图的概览：
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>列表视图：</Body1Strong> 默认设置，整齐列出所有部门，提供清晰、简洁的概览。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>大卡片视图：</Body1Strong> 切换到此视图，查看详细的部门卡片，提供每个部门关键信息的扩展摘要。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>组织结构图视图：</Body1Strong> 为了直观表示部门结构和关系，组织结构图视图展示了每个部门如何适应更大的组织层次。
                  </li>
                </ul>
                要在这些视图之间切换，只需使用部门目录顶部搜索框旁边的图标。每种视图都旨在帮助您轻松访问并了解部门细节，确保您以最适合您的方式找到所需信息。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="24">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                24. 如何在部门目录中使用搜索框
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                在TeamConnect的部门目录顶部旁边的专用搜索框中无缝定位任何部门。只需开始键入您正在搜索的部门名称，然后观察目录如何自我细化，仅显示相关匹配项。此功能专门设计用于帮助您绕过手动筛选整个目录的需求，将您需要的部门直接带到您的指尖。请记住，此搜索功能是TeamConnect的部门目录特有的，因此请使用此搜索框而不是Microsoft Teams界面最顶部的主搜索框来进行特定于部门的查询。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="25">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                25. 部门目录中的字母筛选器是如何工作的？
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                使用位于顶部的字母筛选器在部门目录中简化您的搜索。此功能允许您通过选择一个字母来快速缩小部门范围。选择一个字母后，TeamConnect将过滤并仅显示名称以您选中的字母开头的部门，使您在无需浏览整个列表的情况下更容易找到所需内容。要返回到完整的部门视图，只需取消选择突出显示的字母即可。这种直观的字母导航确保您可以根据首字母高效地定位任何部门。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="26">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                26. 我如何联系部门经理？
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                在TeamConnect中联系部门经理非常简单。只需找到感兴趣的部门并点击经理的名字。该操作将触发一个弹出窗口，显示经理的联系方式选项和熟悉的快速访问图标：
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>聊天图标：</Body1Strong> 通过Microsoft Teams聊天启动即时消息。</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>邮件图标：</Body1Strong> 在Microsoft Outlook中打开一个新的邮件，地址写给他们。</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>视频通话图标：</Body1Strong> 通过Microsoft Teams发起视频通话。</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>电话图标：</Body1Strong> 使用Microsoft Teams开始语音通话。</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>更多（...）图标：</Body1Strong> 用于其他选项，如安排会议或将其联系信息添加到您的Teams列表中。
                  </li>
                </ul>
                这些图标提供了一种无缝的方法，可以直接从TeamConnect中联系到部门经理，确保您组织内的高效沟通。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="27">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                27. 如何查看或联系某个部门内的员工？
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                要探索 TeamConnect 中某个部门的人员构成，请查看部门名称旁边的三个点。点击这些点将
                弹出进一步操作选项：
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>查看该部门员工：</Body1Strong> 选择此项以显示该部门所有员工的列表。
                    此选项旁的数字表示该部门的员工数量，提供快速的人数统计。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>部门组织结构图：</Body1Strong> 如果您希望了解部门的层级结构，
                    请选择“部门组织结构图”以揭示结构布局，展示上级部门、所查询的部门以及任何子部门之间的关系。
                    这种视觉表示帮您导航并理解组织框架，使您更容易识别和联系特定部门成员。
                  </li>
                  <li><Body1Strong>部门 {'>'} 员工：</Body1Strong> 查看部门内的员工后，使用这个路径导航回到部门列表，
                    保持在 TeamConnect 中的流畅探索体验。
                  </li>
                </ul>
                这些功能不仅允许您查看某个部门的员工名单，还提供了通过 TeamConnect 的通信工具直接联系他们的途径。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="28">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                28. 在 TeamConnect 中深入了解部门详细信息
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                单击 TeamConnect 中的部门名称，深入了解任何部门的细节。详细对话框展开，提供丰富的信息，
                充分描述了部门在您组织中的角色和资源。您将找到从领导力和财务标识到沟通渠道和社交链接的一切信息。
                这一功能是您的通道，能够：
                <ul>
                  <li className={styles.bulletBottomMargin}>部门的核心—其目的、人员和绩效指标。</li>
                  <li className={styles.bulletBottomMargin}>联系途径—直接联系关键部门人员和沟通平台。</li>
                  <li className={styles.bulletBottomMargin}>组织网络—了解部门如何与更广泛的公司结构集成。</li>
                </ul>
                无论是快速查询还是深入部门数据，TeamConnect 都为您提供了连接和理解组织内独特生态系统的工具。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="29">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                29. 对 TeamConnect 中缺少部门地址感到好奇？
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                寻找部门位置有时可能感觉像是没有地图的寻宝。在 TeamConnect 中，您通常不会找到每个部门的专用地址。以下是经常省略这一地图细节的原因：
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>多地点：</Body1Strong> 许多部门不局限于单一空间；它们跨越不同的办公室和地理位置。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>流动结构：</Body1Strong> 组织景观不断变化，部门随着战略调整而发展、合并或甚至解散。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>部门间协作：</Body1Strong> 现代工作场所充满协作精神，经常将来自不同部门的人才融合在共享空间中。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>远程工作文化：</Body1Strong> 由于远程工作的灵活性，部门锚点并不总是位于物理办公地点。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>法律细节：</Body1Strong> 部门通常围绕公司的法律结构运作，而不是拥有自己的独立地址。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>运营简化：</Body1Strong> 保持对动态团队合作而非静态地址的关注，有助于保持运营的灵活性。
                  </li>
                </ul>
                这一切都是为了拥抱团队合作和效率的无界限方法。TeamConnect 通过强调连接而非位置，体现了这一理念。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="30">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                30. 您的部门信息是否缺失或过时？
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                指定部门编辑负责在TeamConnect中添加、更新或更正部门特定信息。如果您不确定谁是您部门的编辑，或者某些信息需要人力资源支持团队干预，
                请联系<Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>以获取指导。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>办公室目录常见问题解答</Subtitle2>
        <Body1>
          <p>
            <Body1Strong>办公室目录介绍：</Body1Strong>通过办公室目录，了解您的组织的物理存在的支柱，其中包括基本的办公室细节、运营特点，以及定义每个位置的部门和人员的综合视图。
          </p>
        </Body1>
      <div>
        <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
          <AccordionItem value="31">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                31. 在办公室目录中导航标签
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                使用我们为快速访问正确位置而设计的标签系统轻松导航办公室目录。以下是每个标签如何帮助简化您的搜索：
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>所有：</Body1Strong>显示办公室的完整列表，为您提供组织位置的鸟瞰视图。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>收藏夹：</Body1Strong>通过将它们标记为“收藏夹”，帮助您专注于那些您经常访问或需要密切关注的办公室位置。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>您的办公室：</Body1Strong>此个人标签将目录过滤，显示您所在的办公室位置，让您的基地触手可及。
                  </li>
                </ul>
                无论您是预订会议室还是计划访问，这些标签都提供了与您组织的地理布局互动的个性化和简化方式。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="32">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                32. 在TeamConnect中掌握办公室目录视图
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                深入了解公司各地点的组织布局，借助TeamConnect的办公室目录视图。每个视图都是为了促进办公室导航和理解的独特方面而设计：
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>列表视图：</Body1Strong> 默认设置，将所有办公地点整齐地组织在一个易于阅读的列表中。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>大卡片视图：</Body1Strong> 办公室目录中的大卡片视图通过详细的展示每个办公室来丰富您的视图。与办公室卡片交互时，您会在“更多(…)”菜单中找到大量信息和可操作的选项：
                    <ol>
                      <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>查看部门：</Body1Strong> 直接导航到办公室内所有部门的列表。
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>查看员工：</Body1Strong> 访问分配到办公室的员工完整名单，促进直接联系或探索办公室人员。
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>商业实体组织图：</Body1Strong> 在更大的组织背景中可视化办公室的位置，清楚地理解层级结构。
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>重要联系人：</Body1Strong> 确定关键办公室联系人以实现流畅的沟通。
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>办公室公共假期：</Body1Strong> 了解办公室特定的公共假期，以便相应地计划您的日程。
                      </li>
                      <li className={styles.bulletBottomMargin}><Body1Strong>办公室工作时间：</Body1Strong> 查看办公室的营业时间，以便您的访问或电话与他们的活跃时间对齐。
                      </li>
                    </ol>
                    这一视图不仅提供视觉和信息快照，还能快速访问有关办公室的更深入见解和直接操作，所有这些都巧妙地收纳在“更多（...）”选项下，方便您使用。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>组织图视图：</Body1Strong> 可视化父级和子办公室的层级结构，以理解不同办公室位置在您的组织内的相互联系。
                  </li>
                </ul>
                使用搜索框旁边的图标切换这些视图，以自定义您的浏览体验，以满足您的即时需求。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="33">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                33. 使用TeamConnect的搜索功能快速找到办公室
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                在TeamConnect中，借助专用搜索功能轻松定位正确的办公室位置。搜索框位于视图图标旁边显眼位置，是您通过名称精确查找办公室的工具。当您输入时，目录会细化显示仅匹配您输入的办公室，从而简化您的搜索过程。使用TeamConnect中的这个搜索框非常重要，以获得最准确和相关的结果，而不是使用通用的Microsoft Teams搜索。有了这种定向搜索功能，您可以迅速导航到组织网络中的任何办公室。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="34">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                34. 按字母顺序浏览办公室目录
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                如果您倾向于按字母顺序搜索，我们的字母筛选器是快速找到办公室名称的方式。在办公室目录的顶部，您会看到字母A至Z。点击一个字母将筛选视图，仅显示以该字母开头的办公室，简化您的搜索过程。要重置并再次查看所有办公室，只需取消选择活动字母即可。当您熟悉办公室名称但需要快速定位于TeamConnect中时，此功能特别有用。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="35">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                35. 在TeamConnect中展示全面的办公室概况
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                通过TeamConnect的办公室概况，开始一段探索之旅，深入了解您的组织的物理存在心脏。点击一个办公室名称会弹出一个充满活力的概况对话框，其中充满了详细办公室洞察的标签：
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>概览：</Body1Strong> 深入了解办公室的核心身份，从商业结构到开放时间和当地时间，捕捉办公室环境的精髓。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>重要联系人：</Body1Strong> 获取紧急服务和外部支持的关键联系信息，每个联系方式都通过Microsoft Teams电话系统点击即达。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>办公室联系人：</Body1Strong> 轻松联系内部支持服务，联系详情和直接参与选项触手可及。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>公共假期：</Body1Strong> 了解办公室特定的假期，确保您的计划与当地的观察和工作时间表相一致。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>办公时间：</Body1Strong> 通过准确知晓办公室何时繁忙或何时结束一天的活动来计划您的互动。
                  </li>
                </ul>
                无论是奥斯汀的活力还是新加坡的战略中心，每一个标签都提供了了解和连接构成您公司全球存在的不同地方的途径。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="36">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                36. 深入探讨办公室的部门动态
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                在TeamConnect中轻松导航办公室部门的专业网络。以下是深入了解细节的方法：
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>查看此办公室的部门：</Body1Strong>通过从更多（...）菜单中选择此选项，发现组成办公室的所有部门。
                  </li>                      
                  <li className={styles.bulletBottomMargin}><Body1Strong>组织洞察：</Body1Strong>点击任何部门以显示其组织结构图，通过面包屑路径指导您：办公室 {'>'} 部门 {'>'} 图表。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>部门深入研究：</Body1Strong>通过点击部门名称旁边的更多菜单（...）进一步探索一个部门。在这里，您可以访问该部门内所有员工的列表，直接在办公室环境中。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>面包屑导航：</Body1Strong>无缝穿越各级。从员工到部门或从组织图，面包屑路径（办公室 {'>'} 部门 {'>'} 员工 {'>'} 图表）使您的旅程直观且保持正确的轨道。
                  </li>
                </ul>
                TeamConnect的复杂路径邀请您探索、理解并与办公室的每个部门层面进行互动，赋予您全面的导航控制权。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="37">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                37. 在TeamConnect中与办公室人员建立联系
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                对办公室背后的面孔感到好奇吗？在TeamConnect中，答案只需一击即可。在您选择的办公室名称旁边点击省略号（三个点），揭示一系列交互选项：
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>查看此办公室的员工：</Body1Strong>揭示所有将此办公室作为工作场所的专业人士的列表。显示的数字提供了员工的快速统计，邀请您深入了解办公室社区。
                  </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>联系选项：</Body1Strong>每个员工条目都配备了快速操作图标，让您可以从TeamConnect发起聊天、电话或甚至视频会议，促进无缝的办公室内通讯。
                  </li>
                  <li><Body1Strong>导航：</Body1Strong>要从员工列表返回到较广的办公室列表，只需在面包屑路径中点击“办公室”（办公室 {'>'} 员工），您将返回到办公室概览。
                  </li>
                </ul>
                凭借这些功能，TeamConnect使得可视化办公室各地劳动力分布变得简单，并且无论距离如何，都能轻松联系，促进一个联系紧密的工作场所。
              </Body1>
            </AccordionPanel>
          </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. 您的办公室信息是否缺失或过时？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在TeamConnect中，办公室信息的更新可以由指定的办公室编辑处理。如果您不确定办公室编辑者的身份，
                  或者需要在人力资源管理范围内的信息帮助，人力资源支持团队随时准备提供帮助
                  <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>。
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>设置常见问题解答</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. 在TeamConnect中准确设置的重要性
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在当今动态的工作环境中，保持联系和协调比以往任何时候都更为重要。这就是在TeamConnect中更新您的
                  通用设置发挥关键作用的地方：
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>工作场所选择：</Body1Strong>通过更新您的工作场所来捕捉您工作设置的本质，无论是从家庭办公室的宁静转变到主办公室的繁忙，
                        还是安顿在一个公共区域。这种对您工作地点的明确表达，不仅让同事了解与您联系的最佳方式，还让他们了解可以与您进行的对话性质，
                        尤其是当您在客户办公室的开放环境中或坐在机场休息室时，确保敏感讨论的保密性。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>工作地点具体说明：</Body1Strong>共享您的工作城市不仅有助于在远程团队设置中桥接距离——它还为自发的专业聚会或休闲社交打开了大门。
                        无论是快速喝咖啡、悠闲的午餐还是协作会议，知道您在同一城市可以将普通的一天转变为有意义的面对面连接的机会。这不仅加强了团队间的联系，
                        还为可能影响您工作的当地细微差别提供了更深入的理解。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>时区准确性：</Body1Strong>随着团队横跨大陆，确保您的时区反映您当前的地点对于协调通信至关重要。
                      您的TeamConnect个人资料的本地时间直接受到此设置的影响，为试图联系您的同事消除任何猜测。
                    </li>
                  </ul>
                  深入“设置” {'>'} “通用设置” 在TeamConnect中调整这些细节。花几分钟时间更新您的设置可以大大增强您的协作工作的凝聚力和效率。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. 解读 TeamConnect 中的工作场所选择
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在工作场所下拉菜单中选择正确的设置可以确保 TeamConnect 准确反映您当前的工作状态。将鼠标悬停在信息图标上以获取每个选项的详细说明：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>已签出/休息：</Body1Strong> 表示工作时间之外，无论是休息还是一天工作的结束。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>办公室/现场办公室：</Body1Strong> 表示在公司管理的工作空间内。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>家庭办公室：</Body1Strong> 在专门的家庭工作空间工作。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>远程工作场所/公共区域：</Body1Strong> 在非办公室环境中工作，表明需要灵活沟通。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>客户工作场所/私人办公室：</Body1Strong> 在客户现场，注明交谈的隐私级别。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>通勤/商务旅行/培训：</Body1Strong> 在移动中或参与办公室外的特定工作相关活动。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>公共假期/假期/各种假期：</Body1Strong> 官方非工作时间，因私人、健康或假期原因。</li>
                  </ul>
                  选择适当的工作场所设置确保您的团队有进行交互所需的背景，尊重可用性和隐私。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. 在 TeamConnect 中导航“已签到”状态
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  员工目录中的“已签到”标签旨在显示当前在工作的所有员工。这种可见性由您所做的工作场所选择决定：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>活跃工作环境：</Body1Strong> 选择办公室、现场办公室、家庭办公室、远程工作场所、公共区域、客户工作场所、客户私人办公室、通勤、商务旅行和培训显示您正在工作，使您“已签到”并对同事可见。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>非工作设置：</Body1Strong> 选择已签出、休息、公共假期、假期、请假、产假或病假状态会将您从“已签到”列表中移除，为可用性和响应时间设定清晰的期望。
                    </li>
                  </ul>
                  此功能有助于从个人时间中区分工作期间，促进有效的团队合作和沟通。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>数据管理和隐私常见问题解答</Subtitle2>
          <Body1>
            <p>
              在当今的数字时代，了解您的数据如何被管理至关重要。TeamConnect致力于透明度和强大的数据保护实践。
              下面，您将找到有关我们如何处理、存储和保护TeamConnect中的您的数据的信息，确保您的数字工作空间安全且符合合规性。
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. TeamConnect如何保护我的个人数据？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect 致力于通过全面的数据安全方法保护您的个人信息：</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>单点登录（SSO）：</Body1Strong>为了增强安全性和简化用户体验，TeamConnect通过与您的Microsoft 365账户集成自动化单点登录（SSO），
                      实现安全而轻松的访问管理。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>加密：</Body1Strong>我们确保TeamConnect中的所有数据在传输和存储时都进行加密，
                      使其无法被未经授权的方访问。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>数据最小化：</Body1Strong>我们的数据收集严格限于TeamConnect功能所必需的。 
                      我们存储的唯一用户信息是您的Azure Entra ID中的用户主体名称（UPN），这可能类似于您的公司电子邮箱地址。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>限制访问：</Body1Strong>对UPN的访问仅限于您组织内的订阅管理员通过我们的订阅门户进行，
                      确保敏感信息只对授权人员可见。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>及时数据删除：</Body1Strong>TeamConnect被设置为在三种特定条件下立即删除存储的UPN：
                      如果订阅由订阅管理员取消，如果用户被TeamConnect内的人力资源部门移除，或如果TeamConnect
                      订阅到期。这确保您的个人数据不会在其有用生命或必要性之外被保留。
                    </li>                      
                  </ul>
                  <p>通过这些措施，TeamConnect致力于维护最高标准的数据保护，确保您的个人信息安全和保密。</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. 想知道您的数据在TeamConnect中存放在哪里吗？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect打破了传统SaaS服务的模式，确保您的数据不会离开您的组织。
                  与通常将数据存储在外部云服务器上的做法不同，TeamConnect巧妙地利用您的组织的
                  Microsoft 365 SharePoint Online来存放所有应用数据。这种创新的方法不仅维护了数据主权，还增强了安全性，
                  完美地与我们坚定的致力于保护您的隐私和遵守严格的合规标准相一致。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. 我的数据会与任何第三方共享吗？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  不，数据不会与第三方共享！Cubeet坚定地致力于保护您数据的隐私和安全。未经我们客户明确同意，我们不会与任何第三方共享任何TeamConnect数据。
                  在我们的安全环境中，我们仅存储必要的标识符，例如用户主体名称（UPN）、组织名、租户名和租户ID，在我们的订阅门户中。
                  这些信息对于服务提供至关重要，并受到我们严格的隐私政策的保护。
                  您可以放心，我们的数据管理实践旨在确保您的数据保持机密，透明度和控制权始终在您手中。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. TeamConnect是如何符合GDPR等数据保护法规的？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect通过利用Microsoft 365环境的固有合规功能，
                  符合GDPR、HIPAA、CCPA等数据保护法规，其中所有客户数据均安全存储。与传统的SaaS模式不同，
                  TeamConnect不在其服务器上存储任何客户数据；相反，它在您组织的Microsoft 365环境中运作，
                  遵循其健全的安全和合规标准。这种方法确保您的数据治理与Microsoft 365提供的全面合规框架保持一致，
                  包括遵守GDPR、针对医疗信息的HIPAA、针对加州居民的CCPA，以及其他全球数据保护法规。
                  通过与您现有的Microsoft 365设置无缝集成，TeamConnect赋予您的组织全面控制您的数据，
                  确保符合最严格的数据保护法律和法规。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. TeamConnect如何处理来自Microsoft Teams聊天、通话、视频通话、Outlook电子邮件和电话系统通话的数据？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect利用Microsoft Graph API实现直接交互，如聊天、通话、视频通话和Outlook电邮，在Microsoft Teams中提供无缝用户体验。至关重要的是，虽然TeamConnect启动这些通信，但它不干预通信过程，也没有能力记录或存储任何交互内容。所有对话、通话和电子邮件都在Microsoft Teams和Outlook的安全环境中进行和管理，严格遵守Microsoft 365的强大安全和隐私标准。这确保TeamConnect在增强您的通信能力的同时，完全保护了您的数据的机密性和完整性。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            如需详细了解我们的数据政策和实践，您还可以查看{' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>TeamConnect用户指南</Body1Strong></Link>或咨询您的组织IT部门
            <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>，了解与您设置相关的具体信息。
          </p>
        </Body1>        
        <p></p>  
        <Subtitle1 className={styles.fontColor}>我们在倾听：分享您的反馈和想法</Subtitle1>
        <Body1>
          <p>
            您的见解推动TeamConnect的发展。无论您是有一个绝妙的功能想法，还是对当前体验有些反馈，我们都洗耳恭听。您的输入在我们持续提供最佳服务的过程中至关重要。
          </p>
        </Body1>      
        <Subtitle2>反馈和功能建议</Subtitle2>
        <Body1>
          <p>
            准备分享了吗？前往我们的{' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              反馈页面
            </Link>
            。无论是新功能的建议还是对现有功能的反馈，只需从表单中下拉选项中选择适当的选项。您的贡献帮助塑造TeamConnect的未来，确保它满足并超越您的需求。
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;