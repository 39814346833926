import PropTypes from "prop-types";
import {
  makeStyles,
  tokens,
  Link,
  Body1,
  Body1Strong,
  Subtitle2,
  Subtitle1,
  Title3,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,
  },
  bulletBottomMargin: {
    marginBottom: "8px",
  },
  scrollToTopButton: {
    position: "fixed",
    right: "5%",
    transform: "translateX(95%)",
    bottom: "8px",
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>
        Pusat Bantuan & FAQ TeamConnect
      </Title3>
      <p>
        <Body1>
          Selamat datang di Pusat Bantuan TeamConnect, dirancang untuk mendukung
          pengalaman Anda dengan aplikasi direktori perusahaan kami yang
          komprehensif. Di sini, Anda akan menemukan sumber daya untuk
          mengoptimalkan penggunaan TeamConnect dalam Microsoft Teams,
          meningkatkan komunikasi di seluruh jaringan Anda.
        </Body1>
      </p>
      <Subtitle2>Panduan Referensi Cepat</Subtitle2>
      <ul>
        <Body1>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Kemampuan Pencarian yang Ditingkatkan: </Body1Strong>
            Temukan kolega, departemen, dan kontak penting dengan cepat
            menggunakan alat pencarian kami yang kuat.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Diagram Org Visual: </Body1Strong>
            Navigasi hirarki organisasi Anda dan pahami garis pelaporan dengan
            mudah melalui diagram org yang intuitif.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Alat Adaptif untuk Kebutuhan Masa Kini: </Body1Strong>
            Gunakan fungsi modern yang disesuaikan dengan tuntutan tempat kerja
            kontemporer.
          </li>
        </Body1>
      </ul>
      <p></p>
      <Subtitle2>Mengoptimalkan Pengalaman Anda</Subtitle2>
      <Body1>
        <p>
          TeamConnect merevolusi cara Anda berinteraksi dalam struktur
          organisasi Anda, mengubah komunikasi sehari-hari menjadi keterlibatan
          strategis.
        </p>
      </Body1>
      <p></p>
      <Subtitle1 className={styles.fontColor}>Dapatkan Bantuan & FAQ</Subtitle1>
      <Body1>
        <p>
          Temukan jawaban atas pertanyaan umum tentang fitur dan pengaturan
          TeamConnect di bagian khusus ini. Apakah Anda sedang menavigasi
          aplikasi, menyesuaikan pengaturan, atau mencari solusi atas tantangan,
          Pusat Bantuan kami di sini untuk membantu Anda secara efisien.
        </p>
      </Body1>
      <Body1>
        <p>
          Untuk dukungan yang lebih mendalam atau bimbingan spesifik, silakan
          konsultasikan{" "}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>
              Panduan Pengguna TeamConnect
            </Body1Strong>
          </Link>
          .
        </p>
      </Body1>
      <p></p>

      <Subtitle2>Menggunakan Tanya Jawab TeamConnect</Subtitle2>
      <p></p>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="1">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                1. Tidak dapat menemukan TeamConnect di sidebar Microsoft Teams?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Jika TeamConnect tidak terlihat dengan jelas atau menghilang di
                sidebar Microsoft Teams Anda, Anda dapat dengan mudah
                menyematkannya untuk akses cepat. Ikuti langkah-langkah
                sederhana ini:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Buka Microsoft Teams dan klik ikon '...' (+ Aplikasi) di
                    bagian bawah sidebar.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Cari 'TeamConnect' di bilah pencarian di bagian atas jendela
                    pop-up.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Klik kanan pada aplikasi TeamConnect dari hasil pencarian
                    dan pilih 'Pin'.
                  </li>
                </ol>
                TeamConnect akan sekarang disematkan di sidebar Anda,
                menjadikannya dapat diakses hanya dengan satu klik!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="2">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                2. Mengalami masalah teknis dengan TeamConnect? Ini cara
                mendapatkan bantuan.
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Jika Anda mengalami masalah teknis dengan TeamConnect dalam
                Microsoft Teams, tim Dukungan IT organisasi Anda siap membantu.
                Hubungi mereka di{" "}
                <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>
                , dan mereka akan membantu Anda segera.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="3">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                3. Tidak dapat menemukan detail karyawan Anda?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Jika profil Anda terasa agak kosong hanya dengan nama dan email
                Anda, kemungkinan tim HR Anda belum menambahkan detail karyawan
                lainnya ke TeamConnect. Tidak perlu khawatir! Pesan cepat ke tim
                dukungan HR Anda akan memulai prosesnya. Mereka siap untuk
                melengkapi profil Anda dengan semua detail penting. Hubungi di{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>{" "}
                dan saksikan profil Anda menjadi lebih hidup!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="4">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                4. Opsi bahasa apa saja yang tersedia di TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect mendukung keragaman global Microsoft Teams dengan
                menunjang semua bahasa yang tersedia. Apakah Anda memilih Arab
                atau Yunani, Jepang atau Swedia, Anda memiliki dunia pilihan di
                ujung jari Anda, termasuk:
              </Body1>
              <ul>
                <li>
                  <Body1>
                    Arab, Bulgaria, Cina (Sederhana dan Tradisional), Kroasia,
                    Ceko, Denmark, Belanda, Inggris, Estonia, Finlandia,
                    Prancis, Jerman, Yunani, Ibrani, Hungaria, Indonesia,
                    Italia, Jepang, Korea, Latvia, Lithuania, Norwegia (Bokmal),
                    Polandia, Portugis (Brasil dan Portugal), Rumania, Rusia,
                    Serbia (Latin), Slovakia, Slovenia, Spanyol, Swedia,
                    Thailand, Turki, Ukraina, Vietnam.
                  </Body1>
                </li>
              </ul>
              <Body1>
                Untuk menyesuaikan pengalaman TeamConnect Anda sesuai bahasa
                yang diinginkan, silakan atur pengaturan bahasa langsung di
                Microsoft Teams, karena TeamConnect sepenuhnya terintegrasi
                dengan pengaturan ini:
              </Body1>
              <ol>
                <Body1>
                  <li className={styles.bulletBottomMargin}>
                    Pergi ke pengaturan Microsoft Teams Anda dengan mengklik
                    tiga titik di samping gambar profil Anda di atas, lalu pilih
                    'Penampilan dan aksesibilitas'.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Gulir ke bagian 'Bahasa' dan pilih bahasa yang Anda sukai
                    dari menu dropdown.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Konfirmasikan perubahan Anda dengan mengklik tombol 'Simpan
                    dan restart' untuk menerapkan pengaturan bahasa baru.
                  </li>
                </Body1>
              </ol>
              <Body1Strong>Catatan: </Body1Strong>
              <Body1>
                Perubahan yang dilakukan pada pengaturan bahasa di Microsoft
                Teams juga akan secara otomatis memperbarui pengalaman Anda di
                TeamConnect. Perlu diketahui bahwa mengubah bahasa di Teams juga
                dapat menyesuaikan format waktu dan tanggal Anda. Saat ini,
                TeamConnect sedang berkembang untuk dukungan penulisan dari
                kanan ke kiri. Kami sangat antusias tentang peningkatan ini dan
                akan terus memberi tahu Anda seiring ketersediaannya.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          ;
          <AccordionItem value="5">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                5. Bagaimana cara mengubah tema di TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect menyelaraskan dengan preferensi tema Microsoft Teams
                Anda, termasuk opsi Light, Dark, dan High Contrast. Untuk
                mengkustomisasi tema Anda:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Akses pengaturan Microsoft Teams Anda dengan mengklik tiga
                    titik di sebelah foto profil Anda di atas.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Pilih 'Appearance and accessibility' untuk menjelajahi opsi
                    tema.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Pilih tema yang Anda inginkan dari opsi yang tersedia untuk
                    segera memperbarui antarmuka TeamConnect Anda agar sesuai.
                  </li>
                </ol>
                Pilihan tema Anda di Microsoft Teams akan secara otomatis
                diterapkan ke TeamConnect, memastikan pengalaman visual yang
                konsisten di seluruh ruang kerja Anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="6">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                6. Menjelajahi Tombol Aksi Kontak TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Di seluruh TeamConnect, di mana pun Anda menemukan detail kontak
                karyawan, Anda juga akan menemukan tombol akses cepat kami yang
                nyaman. Alat praktis ini dirancang untuk memperlancar komunikasi
                Anda dalam ekosistem Microsoft, memastikan Anda selalu satu klik
                dari terhubung. Berikut panduan lengkap Anda untuk menggunakan
                tombol-tombol ini secara efektif:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Chat Icon:</Body1Strong> Klik untuk memulai
                    chat Microsoft Teams secara instan. Jika Anda perlu kembali
                    ke TeamConnect setelah memulai chat, cukup gunakan panah
                    kembali di Desktop App, tombol kembali di Mobile App, atau
                    tombol kembali browser Anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Email Icon:</Body1Strong> Membuka draf email
                    Outlook baru dengan alamat penerima sudah siap di bidang
                    "To:". Jika Outlook tidak terbuka, ada baiknya memeriksa
                    dengan departemen IT Anda untuk setiap pembatasan.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Video Call Icon:</Body1Strong> Menginisiasi
                    panggilan video tatap muka menggunakan Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Call Icon:</Body1Strong> Memulai panggilan
                    suara Microsoft Teams, membuat diskusi cepat menjadi lancar.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Org Chart Icon:</Body1Strong> Menunjukkan
                    tempat karyawan dalam hierarki organisasi, termasuk manajer
                    mereka dan laporan langsung.
                  </li>
                  <li>
                    <Body1Strong>More (...) Icon:</Body1Strong> Mengungkapkan
                    lebih banyak pilihan:
                    <ul>
                      <li
                        style={{ marginTop: "8px" }}
                        className={styles.bulletBottomMargin}
                      >
                        <Body1Strong>Meet Now:</Body1Strong> Membuka Microsoft
                        Teams untuk memulai pertemuan segera dengan karyawan
                        tersebut.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>New Meeting:</Body1Strong> Mengarahkan Anda
                        ke Microsoft Teams untuk menjadwalkan pertemuan baru.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Add Contact:</Body1Strong> Menambahkan
                        detail kontak karyawan dari TeamConnect ke aplikasi
                        People Microsoft Teams baru Anda, memperkaya fungsi
                        panggilan Anda.
                      </li>
                    </ul>
                  </li>
                </ul>
                Tombol intuitif ini dirancang untuk memastikan Anda dapat
                terhubung dengan efisien dengan rekan kerja, entah itu untuk
                chat cepat, email, atau menjadwalkan pertemuan mendatang.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="7">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                7. Melakukan panggilan melalui TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ingin melakukan panggilan? TeamConnect memperlancar komunikasi
                Anda dengan terintegrasi dengan Microsoft Teams. Cukup klik
                nomor telepon yang diinginkan di TeamConnect, dan voilà,
                antarmuka panggilan Microsoft Teams muncul, siap untuk Anda dial
                keluar dengan lancar.
                <p>
                  <Body1Strong>Catatan: </Body1Strong>
                  Mengalami masalah dengan melakukan panggilan? Ini mungkin
                  disebabkan oleh kebijakan IT organisasi Anda atau penggunaan
                  sistem telepon selain Microsoft Teams Phone System.
                  Berkonsultasi dengan departemen IT Anda di{" "}
                  <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>
                      {itSupport}
                    </Body1Strong>
                  </Link>
                  , dapat memberikan beberapa penjelasan tentang masalah
                  tersebut.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="8">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                8. Apakah ada biaya yang terkait dengan membuat panggilan
                melalui TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect meningkatkan pengalaman panggilan Anda dengan
                memanfaatkan Microsoft Teams. Berikut ini yang perlu Anda
                ketahui tentang biaya terkait panggilan:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    Untuk panggilan ke telepon kerja karyawan, telepon
                    departemen, atau hotline dalam organisasi Anda (nomor
                    internal), biasanya{" "}
                    <Body1Strong>tidak ada biaya</Body1Strong> saat menggunakan
                    Sistem Telepon Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Untuk panggilan eksternal, seperti ke telepon seluler atau
                    garis luar, <Body1Strong>tarif standar</Body1Strong> dari
                    sistem telepon organisasi Anda berlaku. Tarif ini bervariasi
                    berdasarkan rencana layanan spesifik Anda dengan Microsoft
                    Teams.
                  </li>
                </ul>
                <p>
                  <Body1Strong>Catatan:</Body1Strong> Jika Anda menemukan
                  masalah atau memiliki pertanyaan tentang biaya panggilan,
                  lebih baik berkonsultasi dengan departemen TI di{" "}
                  <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>
                      {itSupport}
                    </Body1Strong>
                  </Link>{" "}
                  untuk informasi rinci yang disesuaikan dengan pengaturan dan
                  kebijakan organisasi Anda.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="9">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                9. Menemukan ketidaksesuaian dengan status kehadiran 'Di Luar
                Kantor' Anda di TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Menyadari bahwa status kehadiran 'Di Luar Kantor' Anda dari
                Microsoft 365 tidak mencerminkan dengan benar di TeamConnect?
                Kami sudah menangani hal ini. Fitur untuk menyinkronkan status
                kehadiran 'Di Luar Kantor' telah diimplementasikan di
                TeamConnect. Kami saat ini menunggu Microsoft meluncurkan fitur
                ini. Setelah itu terjadi, status kehadiran 'Di Luar Kantor' Anda
                akan dicerminkan dengan akurat di TeamConnect, sejalan dengan
                indikator kehadiran Microsoft Teams untuk pengalaman yang
                seamless.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>FAQ Direktori Karyawan</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Pengenalan Direktori Karyawan:</Body1Strong> Navigasi
          lanskap profesional organisasi Anda dengan Direktori Karyawan, pusat
          utama untuk wawasan karyawan terperinci, dinamika tempat kerja, dan
          konektivitas langsung ke profil dan hubungan manajerial rekan kerja
          Anda.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="10">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                10. Perlu menambah, memperbarui, atau memperbaiki data karyawan
                Anda?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Untuk menambah, memperbarui, atau memperbaiki data karyawan Anda
                di TeamConnect, silakan hubungi tim dukungan SDM organisasi
                Anda. Mereka siap membantu di{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>
                .
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="11">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                11. Apa yang terjadi ketika saya mengklik nama karyawan di
                direktori?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mengklik nama karyawan di Direktori Karyawan TeamConnect membuka
                halaman profil rinci untuk individu tersebut. Profil ini
                menyediakan wawasan komprehensif tentang karyawan, termasuk
                namun tidak terbatas pada, jabatan kerja mereka, departemen,
                lokasi kantor, informasi kontak, dan lainnya. Ini dirancang
                untuk memberi Anda gambaran lengkap tentang peran profesional
                karyawan tersebut dan bagaimana mereka berfitur dalam
                organisasi, sehingga memudahkan Anda untuk memahami tanggung
                jawab mereka dan bagaimana Anda mungkin berkolaborasi dengan
                mereka.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="12">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                12. Informasi apa saja yang termasuk dalam profil karyawan?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Setiap profil karyawan di TeamConnect adalah snapshot yang
                komprehensif, menawarkan wawasan yang kaya tentang kehidupan
                profesional individu tersebut. Berikut ini yang akan Anda
                temukan:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Detail Dasar:</Body1Strong> Nama, jabatan
                    kerja, departemen, dan lokasi anak perusahaan.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Struktur Pelaporan:</Body1Strong> Informasi
                    tentang laporan langsung dan fungsional, termasuk manajer
                    garis dan fungsional.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Status Tempat Kerja:</Body1Strong> Status kerja
                    saat ini seperti Cuti, Berangkat kerja, Perjalanan Bisnis,
                    dll.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Workspot & Waktu Lokal:</Body1Strong> Lokasi
                    kerja saat ini dan zona waktu lokal.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Detail Kontak:</Body1Strong> Telepon kantor,
                    nomor ponsel, dan alamat email.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Alamat Kantor:</Body1Strong> Lokasi kantor
                    lengkap termasuk nama gedung, alamat, dan Google map.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Tentang:</Body1Strong> Pengenalan pribadi
                    singkat atau bio profesional.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Hubungan Karyawan:</Body1Strong> Detail
                    struktur pelaporan karyawan dan hubungan tim.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Link Sosial & Bahasa:</Body1Strong> Link ke
                    profil sosial profesional dan penguasaan bahasa.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Keahlian:</Body1Strong> Daftar keahlian
                    profesional dan area keahlian.
                  </li>
                </ul>
                Selain itu, profil juga menawarkan opsi yang dapat diambil
                tindakan untuk terhubung melalui chat, email, panggilan video,
                atau jadwal pertemuan, bersama dengan akses cepat ke bagan
                organisasi untuk konteks tim yang lebih luas.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="13">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                13. Bagaimana cara saya memperbarui informasi profil saya?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Memersonalisasi profil Anda mudah di TeamConnect. Berikut cara
                melakukannya:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Navigasikan ke tab <Body1Strong>Employee</Body1Strong> dan
                    klik pada <Body1Strong>Avatar</Body1Strong> Anda untuk
                    membuka profil Anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Di sudut kiri atas, Anda akan menemukan tautan{" "}
                    <Body1Strong>"Edit profil Anda"</Body1Strong>. Mengklik ini
                    akan membuka dialog edit untuk profil Anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Di sini, Anda dapat meningkatkan profil Anda dengan
                    menambahkan atau memperbarui bagian{" "}
                    <Body1Strong>Tentang Anda</Body1Strong>, tautan media
                    sosial, dan nomor telepon. Ini memudahkan rekan kerja Anda
                    untuk terhubung atau mengikuti Anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Jangan lupa untuk menekan tombol{" "}
                    <Body1Strong>Simpan</Body1Strong> untuk menerapkan perubahan
                    Anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Gulir ke bawah di dialog untuk menambahkan{" "}
                    <Body1Strong>Bahasa</Body1Strong> dan{" "}
                    <Body1Strong>Keahlian</Body1Strong> Anda, serta{" "}
                    <Body1Strong>Keterampilan</Body1Strong> Anda. Berbagi
                    informasi ini membantu rekan kerja Anda memahami bidang
                    keahlian Anda dan bahasa yang dapat Anda gunakan dalam
                    komunikasi.
                  </li>
                </ol>
                Memperbarui profil Anda tidak hanya menjaga informasi Anda tetap
                terkini tetapi juga meningkatkan koneksi yang lebih baik dalam
                organisasi Anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="14">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                14. Memperbarui Tempat Kerja, Lokasi Kerja, dan Zona Waktu di
                TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Menjaga informasi tempat kerja, lokasi kerja, dan zona waktu
                Anda tetap terkini sangat penting untuk kolaborasi yang lancar
                di Microsoft Teams. Pembaruan ini langsung terlihat di profil
                dan daftar TeamConnect Anda, memungkinkan rekan kerja dengan
                cepat memahami lokasi kerja Anda dan waktu terbaik untuk
                terhubung dengan Anda, meningkatkan kerja tim lintas berbagai
                region dan zona waktu.
                <p></p>
                Siap memperbarui detail Anda? Ini mudah di TeamConnect! Pergi ke
                'Pengaturan' di Menu atas dan navigasikan ke 'Pengaturan Umum'.
                Di sini, Anda dapat dengan mudah memperbarui Tempat Kerja dan
                Zona Waktu Anda, dan menentukan Lokasi Kerja Anda untuk hari
                itu. Ingat untuk menyimpan perubahan Anda untuk menjaga semua
                orang tetap terinformasi dan memastikan kolaborasi yang efektif,
                tidak peduli lokasi Anda!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="15">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                15. Menemukan sesuatu yang tidak tepat di profil rekan kerja?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Jika Anda menemukan detail apa pun di profil rekan kerja yang
                tampaknya tidak tepat, langkah pertama terbaik adalah memberi
                mereka pemberitahuan ramah. Obrolan singkat atau email harus
                cukup! Jika untuk beberapa alasan informasi tersebut tidak
                diperbarui, jangan khawatir—hanya hubungi tim dukungan HR Anda
                di{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>
                , dan mereka akan dengan senang hati turun tangan dan mengatur
                semuanya.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="16">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                16. Memahami Kelompok Tag: Semua, Favorit, Sudah Check-In, &
                Karyawan Baru
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Semua:</Body1Strong> Tag default ini
                    menampilkan setiap karyawan di organisasi Anda, memberikan
                    gambaran komprehensif tentang tim.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Favorit:</Body1Strong> Klik di sini untuk
                    menyaring tampilan Anda ke rekan kerja yang telah Anda
                    tandai sebagai 'Favorit,' memudahkan untuk segera terhubung
                    dengan kontak kunci.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Sudah Check-In:</Body1Strong> Tag ini
                    menunjukkan karyawan yang sedang aktif bekerja, membedakan
                    dari kehadiran online saja di Microsoft Teams. Ketika sudah
                    check in, karyawan menandakan bahwa mereka sedang bekerja
                    dan membagikan lokasi kerja saat ini sepanjang hari. Ini
                    dapat mencakup berbagai tempat kerja seperti Kantor,
                    Kantor-Lapangan, Kantor-Rumah, Tempat-Kerja-Remote,
                    Tempat-Kerja-Pelanggan, Kantor-Privat-Pelanggan,
                    Area-Publik, Perjalanan, Perjalanan-Bisnis, atau bahkan
                    Pelatihan. Oleh karena itu, status 'Sudah Check-In'
                    memberikan indikasi yang jelas akan keaktifan bekerja,
                    memberikan rekan kerja pemahaman yang tepat tentang konteks
                    kerja dan ketersediaan saat ini masing-masing.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Karyawan Baru:</Body1Strong> Kenali wajah baru
                    di TeamConnect dengan tag ini, menyoroti karyawan yang telah
                    ditambahkan ke aplikasi dalam 30 hari terakhir. Biasanya,
                    ini selaras dengan penambahan baru ke organisasi Anda,
                    membantu Anda menyambut anggota tim baru.
                  </li>
                </ul>
                Kelompok tag ini dirancang untuk menyederhanakan navigasi dan
                meningkatkan pengalaman TeamConnect Anda, memastikan Anda selalu
                dapat menemukan dan terhubung dengan rekan kerja Anda dengan
                efisien.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="17">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                17. Mendekode Ikon di Samping Kotak Pencarian
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Direktori Karyawan TeamConnect dirancang untuk fleksibilitas dan
                kemudahan, menampilkan empat ikon yang menawarkan tampilan yang
                berbeda dan akses cepat ke detail kunci. Inilah yang diungkapkan
                oleh setiap ikon:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Tampilan Daftar:</Body1Strong> Tampilan andalan
                    Anda, mempresentasikan semua anggota organisasi dalam daftar
                    yang sederhana.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Tampilan Kartu Kecil:</Body1Strong> Alternatif
                    kompak, tampilan ini mengubah daftar menjadi kartu karyawan
                    berukuran kecil.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Tampilan Kartu Besar:</Body1Strong> Pilih lebih
                    detail dengan kartu yang lebih besar, yang mencakup opsi
                    kontak tambahan seperti nomor telepon dan seluler untuk
                    mendial dengan mudah.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Bagan Organisasi:</Body1Strong> Visualisasikan
                    struktur perusahaan dengan tampilan Bagan Organisasi,
                    sempurna untuk memahami hubungan pelaporan dan menavigasi
                    lanskap organisasi.
                  </li>
                </ol>
                Alihkan antara ikon-ikon ini untuk menemukan tampilan yang
                paling cocok dengan kebutuhan Anda saat itu, apakah Anda mencari
                informasi cepat atau wawasan yang lebih dalam.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="18">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                18. Untuk pencarian rekan kerja yang cepat, di mana saya harus
                mencari?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mencari rekan kerja? Cukup ke kotak pencarian TeamConnect, yang
                terletak di sisi kanan atas di sebelah ikon Bagan Organisasi.
                Mulai ketikkan nama mereka, dan perhatikan hasilnya menjadi
                lebih spesifik dengan setiap huruf. Ingat, untuk pencarian
                TeamConnect, lewati bilah pencarian Microsoft Teams di atas —
                kotak pencarian khusus kami adalah cara yang tepat!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="19">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                19. Bagaimana cara kerja pencarian alfabetis untuk menemukan
                karyawan?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Direktori Karyawan TeamConnect mencakup fitur pencarian
                alfabetis yang praktis untuk membantu Anda dengan cepat
                menemukan karyawan berdasarkan nama depan mereka. Cukup klik
                pada huruf dari abjad yang ditampilkan di bagian atas direktori,
                dan TeamConnect akan segera menunjukkan semua karyawan yang nama
                depannya dimulai dengan huruf tersebut. Untuk kembali ke daftar
                lengkap karyawan, cukup tidak pilih huruf yang telah disorot.
                Fitur intuitif ini memudahkan navigasi melalui daftar karyawan
                yang besar, memastikan Anda dapat menemukan rekan kerja dengan
                cepat dan efisien.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="20">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                20. Bagaimana saya dapat menyesuaikan jumlah karyawan yang
                ditampilkan per halaman?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect memungkinkan Anda untuk menyesuaikan berapa banyak
                profil karyawan yang Anda lihat per halaman, membuatnya lebih
                mudah untuk menavigasi melalui Direktori Karyawan. Di bagian
                bawah setiap halaman, Anda akan menemukan opsi pagination: 10,
                50, 100, 500, 1000, dan 5000. Pengaturan default menampilkan 10
                profil per halaman. Untuk melihat lebih banyak profil dalam satu
                halaman, cukup pilih jumlah yang Anda inginkan dari opsi ini.
                Fitur ini memberikan fleksibilitas dalam cara Anda menjelajahi
                direktori, memungkinkan Anda menyesuaikan tampilan sesuai dengan
                kebutuhan atau preferensi spesifik Anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="21">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                21. Bagaimana cara saya mengurutkan informasi karyawan dalam
                Tampilan Daftar?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Dalam Tampilan Daftar Direktori Karyawan TeamConnect, Anda
                memiliki fleksibilitas untuk mengurutkan informasi karyawan
                sesuai dengan kebutuhan Anda. Kolom—Nama Karyawan, Jabatan,
                Departemen, Lokasi Kantor, dan Tempat Kerja—semuanya dapat
                diurutkan. Cukup klik pada judul kolom yang ingin Anda urutkan.
                Mengklik sekali akan mengurutkan kolom dalam urutan menaik, dan
                mengklik lagi akan mengurutkan dalam urutan menurun. Secara
                default, direktori diurutkan berdasarkan Nama Karyawan dalam
                urutan menaik, berdasarkan nama depan. Fitur pengurutan ini
                membantu Anda dengan cepat mengorganisir dan melihat detail
                karyawan dengan cara yang paling sesuai dengan tujuan Anda,
                entah Anda sedang mencari seseorang secara spesifik atau perlu
                mengelompokkan karyawan berdasarkan kriteria tertentu seperti
                departemen atau lokasi.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>

      <Subtitle2>Tanya Jawab Direktori Departemen</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Pengenalan Direktori Departemen:</Body1Strong> Direktori
          Departemen adalah pintu gerbang Anda untuk memahami jaringan kompleks
          struktur organisasi Anda, menawarkan pandangan yang jelas tentang
          hierarki departemen, kepemimpinan manajerial, dan jalur komunikasi
          departemen yang lancar.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="22">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                22. Bagaimana tag-tag 'Semua', 'Favorit', dan 'Departemen Anda'
                berfungsi dalam Direktori Departemen?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Dalam Direktori Departemen TeamConnect, tag membantu Anda dengan
                cepat menavigasi dan memfilter informasi departemen. Berikut
                adalah apa yang diwakili oleh masing-masing tag:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Semua:</Body1Strong> Tag ini menampilkan semua
                    departemen dalam organisasi Anda, memberikan gambaran yang
                    komprehensif.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Favorit:</Body1Strong> Gunakan tag ini untuk
                    melihat daftar departemen yang telah Anda tandai sebagai
                    'Favorit', memudahkan akses ke departemen yang sering Anda
                    interaksikan.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Departemen Anda:</Body1Strong> Tag ini
                    menyaring tampilan untuk hanya menunjukkan departemen Anda,
                    memungkinkan akses cepat ke informasi dan pembaruan
                    departemen langsung Anda.
                  </li>
                </ul>
                Tag-tag ini didesain untuk memperlancar navigasi Anda dalam
                Direktori Departemen, meningkatkan kemampuan Anda untuk
                menemukan dan berinteraksi dengan informasi departemen secara
                efisien.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="23">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                23. Memahami Tampilan Direktori Departemen di TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Menavigasi melalui Direktori Departemen di TeamConnect dilakukan
                dengan mulus dengan berbagai opsi tampilan yang sesuai dengan
                preferensi Anda. Setiap tampilan menawarkan cara unik untuk
                menjelajahi informasi departemen yang komprehensif, termasuk
                Nama Departemen, Manajer Departemen, Departemen Induk, Telepon
                Departemen, Hotline Departemen, dan Email Departemen. Berikut
                adalah gambaran tampilan yang tersedia:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Tampilan Daftar:</Body1Strong> Penataan default
                    yang rapi menyajikan semua departemen, memberikan gambaran
                    yang jelas dan ringkas.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Tampilan Kartu Besar:</Body1Strong> Beralih ke
                    tampilan ini untuk melihat kartu-kartu departemen
                    terperinci, menawarkan ringkasan informasi kunci dari setiap
                    departemen.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Tampilan Diagram Organisasi:</Body1Strong>{" "}
                    Untuk representasi visual dari struktur dan hubungan
                    departemen, tampilan Diagram Organisasi mengilustrasikan
                    bagaimana setiap departemen berada dalam hierarki organisasi
                    yang lebih besar.
                  </li>
                </ul>
                Untuk beralih antara tampilan ini, cukup gunakan ikon di samping
                kotak pencarian di bagian atas Direktori Departemen. Setiap
                tampilan dirancang untuk membantu Anda mengakses dan memahami
                detail departemen dengan mudah, memastikan Anda menemukan
                informasi yang Anda butuhkan dalam format yang paling sesuai
                untuk Anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="24">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                24. Cara Menggunakan Kotak Pencarian di Direktori Departemen
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Temukan dengan lancar departemen apa saja dalam TeamConnect
                menggunakan kotak pencarian khusus yang terletak tepat di
                sebelah ikon tampilan di bagian atas Direktori Departemen. Cukup
                mulai mengetik nama departemen yang Anda cari, dan lihat saat
                direktori menyaring diri untuk menampilkan hanya kecocokan yang
                relevan. Fitur ini dirancang khusus untuk membantu Anda melewati
                kebutuhan untuk menyaring secara manual seluruh direktori,
                membawa departemen yang Anda butuhkan langsung ke ujung jari
                Anda. Ingat, fungsi pencarian ini unik untuk Direktori
                Departemen TeamConnect, jadi pastikan untuk menggunakan kotak
                pencarian ini daripada pencarian utama Microsoft Teams di bagian
                atas antarmuka Teams untuk pertanyaan spesifik departemen.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="25">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                25. Bagaimana cara kerja filter alfabetis di Direktori
                Departemen?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Permudah pencarian Anda di Direktori Departemen dengan filter
                alfabetis yang berada di bagian atas. Fitur ini memungkinkan
                Anda untuk cepat menyempitkan departemen dengan memilih huruf.
                Setelah Anda memilih huruf, TeamConnect menyaring dan
                menampilkan hanya departemen-departemen yang namanya dimulai
                dengan huruf yang Anda pilih, sehingga memudahkan Anda untuk
                menemukan apa yang Anda cari tanpa harus menggulir melalui
                seluruh daftar. Untuk kembali ke tampilan departemen penuh,
                cukup batalkan pilihan huruf yang disoroti. Navigasi alfabetis
                intuitif ini memastikan Anda dapat dengan efisien menemukan
                departemen apa saja dengan huruf awalnya.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="26">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                26. Bagaimana cara saya menghubungi manajer departemen?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Menghubungi manajer departemen di TeamConnect adalah hal yang
                mudah. Cukup temukan departemen yang diminati dan klik pada nama
                manajer. Tindakan ini akan memicu munculnya popover yang
                menampilkan opsi kontak manajer dengan ikon akses cepat yang
                familiar:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ikon Obrolan:</Body1Strong> Untuk memulai pesan
                    instan melalui obrolan Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ikon Email:</Body1Strong> Untuk membuka email
                    baru yang ditujukan kepada mereka di Microsoft Outlook.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ikon Panggilan Video:</Body1Strong> Untuk
                    memulai panggilan video melalui Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ikon Panggilan:</Body1Strong> Untuk memulai
                    panggilan suara menggunakan Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ikon Lainnya (...):</Body1Strong> Untuk opsi
                    tambahan seperti menjadwalkan pertemuan atau menambahkan
                    informasi kontak mereka ke daftar Teams Anda.
                  </li>
                </ul>
                Ikon-ikon ini menyediakan metode yang lancar untuk menghubungi
                manajer departemen langsung dari dalam TeamConnect, memastikan
                komunikasi yang efisien dalam organisasi Anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="27">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                27. Bagaimana saya dapat melihat atau menghubungi karyawan di
                dalam sebuah departemen?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Untuk mengetahui siapa saja yang ada di dalam sebuah departemen
                di TeamConnect, carilah tiga titik yang berada di sebelah nama
                departemen. Klik pada ini akan memunculkan opsi untuk tindakan
                lebih lanjut:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Lihat Karyawan Dalam Departemen Ini:
                    </Body1Strong>{" "}
                    Pilih ini untuk menampilkan daftar semua karyawan yang
                    terkait dengan departemen tersebut. Angka di sebelah opsi
                    ini menunjukkan berapa banyak karyawan yang ada di
                    departemen tersebut, memberikan hitungan cepat.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Struktur Organisasi Departemen:</Body1Strong>{" "}
                    Jika Anda ingin memahami hierarki departemen, pilih
                    'struktur organisasi departemen' untuk mengungkapkan tata
                    letak struktural, menunjukkan hubungan antara departemen
                    induk, departemen yang bersangkutan, dan subdepartemen.
                    Representasi visual ini membantu Anda menavigasi dan
                    memahami kerangka organisasi, sehingga memudahkan untuk
                    mengidentifikasi dan menghubungi anggota departemen
                    tertentu.
                  </li>
                  <li>
                    <Body1Strong>Departemen {">"} Karyawan:</Body1Strong>{" "}
                    Setelah melihat karyawan di dalam sebuah departemen, gunakan
                    jejak breadcrumb ini untuk dengan mudah kembali ke daftar
                    departemen, menjaga pengalaman eksplorasi yang lancar di
                    dalam TeamConnect.
                  </li>
                </ul>
                Fitur-fitur ini memungkinkan Anda tidak hanya melihat daftar
                karyawan di departemen tertentu tetapi juga menawarkan jalan
                untuk menghubungi mereka langsung melalui alat komunikasi
                TeamConnect.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="28">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                28. Menemukan Detail Departemen yang Mendalam di TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Selami detail setiap departemen dengan sekali klik pada namanya
                di TeamConnect. Dialog terperinci akan terbuka, menawarkan
                kekayaan informasi yang menggambarkan gambaran penuh peran dan
                sumber daya departemen dalam organisasi Anda. Anda akan
                menemukan segalanya dari kepemimpinan dan pengenal keuangan
                hingga saluran komunikasi dan tautan sosial. Fitur ini merupakan
                gerbang Anda untuk:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    Inti dari departemen – tujuannya, orang-orangnya, dan metrik
                    kinerja.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Avenue kontak – jalur langsung ke tokoh kunci departemen dan
                    platform komunikasi.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Jaringan organisasi – memahami bagaimana departemen
                    terintegrasi dengan struktur perusahaan yang lebih luas.
                  </li>
                </ul>
                Apakah itu pertanyaan cepat atau penyelaman mendalam ke dalam
                data departemen, TeamConnect memperlengkapi Anda dengan alat
                untuk terhubung dengan dan memahami ekosistem unik dalam
                organisasi Anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="29">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                29. Penasaran Tentang Tidak Adanya Alamat Departemen di
                TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Menemukan lokasi sebuah departemen terkadang dapat terasa
                seperti berburu harta karun tanpa peta. Di TeamConnect, Anda
                biasanya tidak akan menemukan alamat khusus untuk setiap
                departemen. Berikut adalah alasan mengapa detail kartografis ini
                sering dihilangkan:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Lokasi Jamak:</Body1Strong> Banyak departemen
                    yang tidak terbatas pada satu tempat; mereka berkembang di
                    berbagai kantor dan geografi.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Struktur yang Berubah-ubah:</Body1Strong>{" "}
                    Lanskap organisasi terus berubah, dengan departemen yang
                    berkembang, bergabung, atau bahkan bubar sejalan dengan
                    pergeseran strategis.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Kolaborasi Antardepartemen:</Body1Strong> Ruang
                    kerja modern berdengung dengan semangat kolaboratif,
                    seringkali menggabungkan bakat dari berbagai departemen
                    dalam ruang bersama.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Budaya Kerja Remote:</Body1Strong> Dengan
                    fleksibilitas kerja remote, jangkar departemen tidak selalu
                    dilemparkan di lokasi kantor fisik.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Nuansa Hukum:</Body1Strong> Departemen biasanya
                    berorbit mengelilingi struktur hukum perusahaan daripada
                    memiliki alamat sendiri.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Penyederhanaan Operasional:</Body1Strong>{" "}
                    Memfokuskan pada kerja tim dinamis, bukan alamat statis,
                    membantu menjaga kelincahan operasional.
                  </li>
                </ul>
                Semuanya tentang menganut pendekatan tanpa batas untuk kerja tim
                dan efisiensi. TeamConnect mencerminkan filosofi ini dengan
                menekankan pada koneksi daripada lokasi.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="30">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                30. Apakah informasi departemen Anda hilang atau usang?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Editor departemen ditunjuk dalam organisasi Anda untuk
                menambahkan, memperbarui, atau membenarkan informasi spesifik
                departemen di TeamConnect. Jika Anda tidak yakin siapa editor
                departemen Anda atau jika informasi tertentu memerlukan
                intervensi tim dukungan HR, silakan hubungi{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>{" "}
                untuk panduan.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Tanya Jawab Direktori Kantor</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Pengenalan Direktori Kantor:</Body1Strong> Temukan tulang
          punggung keberadaan fisik organisasi Anda melalui Direktori Kantor,
          yang mencakup detail kantor penting, spesifikasi operasional, dan
          gambaran menyeluruh tentang departemen dan personel yang
          mendefinisikan setiap lokasi.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="31">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                31. Menavigasi Tag di Direktori Kantor
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Temukan cara berkeliling Direktori Kantor dengan mudah
                menggunakan sistem tag kami yang dirancang untuk akses cepat ke
                lokasi yang tepat. Berikut ini adalah bagaimana setiap tag dapat
                membantu memudahkan pencarian Anda:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong> Semua: </Body1Strong> Menampilkan daftar
                    lengkap kantor, memberikan gambaran luas tentang lokasi
                    organisasi Anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong> Favorit: </Body1Strong> Membantu Anda fokus
                    pada lokasi kantor yang sering Anda kunjungi atau perlu Anda
                    pantau, dengan menandainya sebagai 'Favorit'.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong> Kantor Anda: </Body1Strong> Tag pribadi ini
                    menyaring direktori untuk menunjukkan lokasi kantor tempat
                    Anda berada, menjaga basis rumah Anda dalam jangkauan Anda.
                  </li>
                </ul>
                Apakah Anda sedang memesan ruang pertemuan atau merencanakan
                kunjungan, tag-tag ini menawarkan cara yang dipersonalisasi dan
                disederhanakan untuk berinteraksi dengan lanskap geografis
                organisasi Anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="32">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                32. Menguasai Tampilan Direktori Kantor di TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Selami tata letak organisasi dari berbagai lokasi perusahaan
                Anda dengan tampilan Direktori Kantor TeamConnect. Setiap
                tampilan dirancang untuk memfasilitasi aspek unik dari navigasi
                dan pemahaman kantor:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Tampilan Daftar:</Body1Strong> Pengaturan
                    default yang mengatur semua lokasi kantor menjadi daftar
                    yang mudah dibaca.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Tampilan Kartu Besar:</Body1Strong> Tampilan
                    Kartu Besar dalam Direktori Kantor memperkaya tampilan Anda
                    dengan presentasi terperinci tentang setiap kantor. Saat
                    berinteraksi dengan kartu kantor, Anda akan menemukan banyak
                    informasi dan opsi yang dapat dilakukan dalam menu "Lebih
                    (...)" berikut:
                    <ol>
                      <li
                        style={{ marginTop: "8px" }}
                        className={styles.bulletBottomMargin}
                      >
                        <Body1Strong>Lihat Departemen:</Body1Strong> Navigasi
                        langsung ke daftar semua departemen yang berada dalam
                        kantor tersebut.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Lihat Karyawan:</Body1Strong> Akses daftar
                        lengkap karyawan yang ditugaskan ke kantor, memudahkan
                        kontak langsung atau eksplorasi personel kantor.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>
                          Bagan Organisasi Entitas Bisnis:
                        </Body1Strong>{" "}
                        Visualisasikan posisi kantor dalam konteks organisasi
                        yang lebih besar untuk pemahaman yang jelas tentang
                        struktur hierarki.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Kontak Esensial:</Body1Strong> Identifikasi
                        kontak kunci kantor untuk komunikasi yang efisien.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Hari Libur Umum Kantor:</Body1Strong> Tetap
                        terinformasi tentang hari libur khusus kantor untuk
                        merencanakan jadwal Anda.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Jam Kerja Kantor:</Body1Strong> Lihat jam
                        operasional kantor untuk menyesuaikan kunjungan atau
                        panggilan Anda dengan waktu aktif mereka.
                      </li>
                    </ol>
                    Tampilan ini tidak hanya menyediakan snapshot visual dan
                    informatif, tetapi juga akses cepat ke wawasan lebih dalam
                    dan tindakan langsung terkait kantor, semua tertata rapi di
                    bawah opsi "Lebih (...)" untuk kenyamanan Anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Tampilan Bagan Organisasi:</Body1Strong>{" "}
                    Visualisasikan struktur hierarki dari kantor induk dan anak
                    untuk memahami keterkaitan antar lokasi kantor yang berbeda
                    dalam organisasi Anda.
                  </li>
                </ul>
                Beralih antara tampilan ini menggunakan ikon di samping kotak
                pencarian untuk menyesuaikan pengalaman menjelajah Anda sesuai
                dengan kebutuhan segera Anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="33">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                33. Menemukan Kantor dengan Cepat menggunakan Fitur Pencarian
                TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Menemukan lokasi kantor yang tepat dalam TeamConnect menjadi
                mudah dengan fitur pencarian yang didedikasikan. Terletak
                mencolok di samping ikon tampilan, kotak pencarian adalah alat
                Anda untuk menemukan kantor berdasarkan nama. Saat Anda
                mengetik, direktori memperbaiki tampilan untuk hanya menampilkan
                kantor yang cocok dengan input Anda, memudahkan proses pencarian
                Anda. Penting untuk menggunakan kotak pencarian ini dalam
                TeamConnect untuk hasil yang paling akurat dan relevan, sebagai
                lawan dari pencarian umum Microsoft Teams. Dengan fungsionalitas
                pencarian yang ditargetkan ini, Anda dapat dengan cepat
                menavigasi ke kantor mana pun dalam jaringan organisasi Anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="34">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                34. Menavigasi Direktori Kantor Secara Alfabetis
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Jika Anda lebih suka mencari berdasarkan abjad, filter alfabetis
                kami adalah cara cepat untuk menemukan kantor berdasarkan nama.
                Di bagian atas Direktori Kantor, Anda akan melihat huruf A
                sampai Z. Mengklik huruf akan memfilter tampilan untuk
                menampilkan hanya kantor yang dimulai dengan huruf tersebut,
                memudahkan proses pencarian Anda. Untuk mengatur ulang dan
                melihat semua kantor lagi, cukup batalkan pilihan huruf yang
                aktif. Fitur ini sangat berguna ketika Anda sudah familiar
                dengan nama kantor tetapi membutuhkan cara cepat untuk
                menemukannya dalam TeamConnect.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="35">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                35. Mengungkap Profil Kantor yang Komprehensif di TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mulailah perjalanan eksplorasi melalui inti keberadaan fisik
                organisasi Anda dengan profil kantor di TeamConnect. Mengklik
                nama kantor akan membuka dialog profil yang penuh warna, dengan
                tab-tab yang penuh dengan wawasan kantor terperinci:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ikhtisar:</Body1Strong> Selami inti identitas
                    kantor dengan deskripsi rinci, dari struktur bisnis hingga
                    jam buka dan waktu lokal, menangkap esensi lingkungan
                    kantor.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Kontak Penting:</Body1Strong> Akses kontak
                    penting untuk layanan darurat dan dukungan eksternal, setiap
                    klik away melalui Sistem Telepon Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Kontak Kantor:</Body1Strong> Hubungi layanan
                    dukungan internal dengan mudah, dengan rincian kontak dan
                    opsi keterlibatan langsung di ujung jari Anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Hari Libur:</Body1Strong> Tetap terinformasi
                    tentang hari libur spesifik kantor, memastikan rencana Anda
                    selaras dengan pengamatan lokal dan jadwal kerja.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Jam Kantor:</Body1Strong> Rencanakan interaksi
                    Anda dengan pengetahuan yang tepat tentang kapan kantor
                    ramai dengan aktivitas atau menenangkan diri untuk hari itu.
                  </li>
                </ul>
                Baik itu energi yang ramai dari Austin atau hub strategis
                Singapura, setiap tab menawarkan gerbang untuk memahami dan
                terhubung dengan lokasi beragam yang membentuk kehadiran global
                perusahaan Anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="36">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                36. Menyelami Dinamika Departemen dalam Suatu Kantor
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Menjelajahi jaringan profesional antar departemen di kantor
                menjadi lebih mudah dengan TeamConnect. Berikut cara untuk masuk
                ke detailnya:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Lihat Departemen di Kantor Ini:</Body1Strong>{" "}
                    Temukan semua departemen yang membentuk struktur kantor
                    dengan memilih opsi ini dari menu More (...).
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Wawasan Organisasi:</Body1Strong> Klik pada
                    departemen mana pun untuk menampilkan struktur organnya
                    dalam bentuk visual, dengan panduan breadcrumb trail: Kantor{" "}
                    {">"} Departemen {">"} Diagram.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Penggalian Mendalam Departemen:</Body1Strong>{" "}
                    Jelajahi lebih lanjut sebuah departemen dengan mengklik menu
                    More (...) di samping nama departemen. Di sini, Anda dapat
                    mengakses daftar semua karyawan dalam departemen tersebut,
                    langsung dalam konteks kantor.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Navigasi Breadcrumb:</Body1Strong> Berpindah
                    antar level dengan mulus. Dari karyawan kembali ke
                    departemen atau dari diagram organisasi, breadcrumb trail
                    (Kantor {">"} Departemen {">"} Karyawan {">"} Diagram)
                    menjaga perjalanan Anda tetap intuitif dan terarah.
                  </li>
                </ul>
                Jalur rumit TeamConnect mengajak Anda untuk menjelajahi,
                memahami, dan berinteraksi dengan setiap lapisan departemen di
                kantor Anda, memberdayakan Anda dengan kemampuan navigasi yang
                komprehensif.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="37">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                37. Terhubung dengan Personel Kantor di TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Penasaran dengan wajah-wajah di balik sebuah kantor? Jawabannya
                hanya dengan satu klik di TeamConnect. Berinteraksilah dengan
                ellipsis (tiga titik) di sebelah nama kantor yang dipilih untuk
                mengungkapkan rangkaian opsi interaktif:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Lihat Karyawan di Kantor Ini:</Body1Strong>{" "}
                    Ungkap daftar semua profesional yang menjadikan kantor ini
                    sebagai rumah kerja mereka. Jumlah yang ditampilkan
                    memberikan hitungan cepat dari karyawan, mengajak Anda untuk
                    masuk ke dalam komunitas kantor.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Opsi Kontak:</Body1Strong> Setiap entri
                    karyawan dilengkapi dengan ikon aksi cepat, memungkinkan
                    Anda untuk memulai chat, panggilan, atau bahkan konferensi
                    video langsung dari TeamConnect, mendorong komunikasi
                    intra-kantor yang lancar.
                  </li>
                  <li>
                    <Body1Strong>Navigasi:</Body1Strong> Untuk kembali ke daftar
                    kantor yang lebih luas dari daftar karyawan, cukup klik
                    'Kantor' di breadcrumb trail (Kantor {">"} Karyawan), dan
                    Anda akan dibawa kembali ke gambaran umum kantor.
                  </li>
                </ul>
                Dengan fitur-fitur ini, TeamConnect memudahkan untuk
                memvisualisasikan distribusi tenaga kerja di seluruh kantor dan
                untuk menghubungi dengan mudah, memelihara tempat kerja yang
                terhubung tanpa memandang jarak.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="38">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                38. Apakah informasi kantor Anda hilang atau usang?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Pembaruan informasi kantor di TeamConnect dapat ditangani oleh
                editor kantor yang ditugaskan. Jika Anda tidak yakin tentang
                editor kantor atau memerlukan bantuan dengan informasi yang
                berada di bawah wewenang HR, tim dukungan HR siap untuk membantu
                di{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>
                .
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>FAQ Pengaturan</Subtitle2>
      <p></p>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="39">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                39. Pentingnya Pengaturan Umum yang Akurat di TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Dalam dinamika lingkungan kerja saat ini, menjaga konektivitas
                dan koordinasi sangat penting. Di sinilah pentingnya pembaruan
                Pengaturan Umum Anda di TeamConnect:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Pilihan Tempat Kerja:</Body1Strong> Tangkap
                    esensi dari pengaturan kerja Anda dengan memperbarui Tempat
                    Kerja Anda untuk mencerminkan situasi terkini Anda, baik
                    beralih dari ketenangan Home-Office Anda ke keramaian Kantor
                    Utama, atau bahkan menetap di Area Publik. Keketulenan ini
                    dalam lokasi kerja Anda menawarkan wawasan kepada rekan
                    kerja bukan hanya tentang cara terbaik untuk menghubungi
                    Anda, tetapi juga tentang jenis percakapan yang dapat mereka
                    lakukan dengan Anda, menjaga kerahasiaan pembicaraan
                    sensitif terutama ketika Anda berada di lingkungan terbuka
                    kantor klien atau duduk di lounge bandara.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Spesifikasi Tempat Kerja:</Body1Strong> Berbagi
                    kota kerja Anda lebih dari sekadar menjembatani jarak dalam
                    pengaturan tim jarak jauh—it juga membuka pintu untuk
                    pertemuan profesional spontan atau bersosialisasi secara
                    kasual. Baik itu kopi cepat, makan siang yang santai, atau
                    pertemuan kolaboratif, mengetahui Anda berada di kota yang
                    sama dapat mengubah hari biasa menjadi kesempatan untuk
                    koneksi tatap muka yang berarti. Hal ini tidak hanya
                    memperkuat ikatan tim tetapi juga memberikan pemahaman yang
                    lebih mendalam tentang nuansa lokal yang mungkin memengaruhi
                    pekerjaan Anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Akurasi Zona Waktu:</Body1Strong> Ketika tim
                    mencakup benua, memastikan zona waktu Anda mencerminkan
                    lokasi Anda saat ini adalah penting untuk mengharmoniskan
                    komunikasi. Waktu Lokal di profil TeamConnect Anda langsung
                    dipengaruhi oleh pengaturan ini, mengeliminasi tebakan apa
                    pun bagi rekan kerja yang mencoba menghubungi Anda.
                  </li>
                </ul>
                Masuk ke 'Pengaturan' {">"} 'Pengaturan Umum' di TeamConnect
                untuk menyesuaikan detail ini. Beberapa saat yang dihabiskan
                untuk memperbarui pengaturan Anda dapat sangat meningkatkan
                kohesi dan efisiensi usaha kolaboratif Anda secara keseluruhan.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="40">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                40. Memahami Pilihan Tempat Kerja di TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Memilih pengaturan yang tepat dalam dropdown Tempat Kerja
                menyesuaikan TeamConnect untuk mencerminkan status kerja Anda
                saat ini dengan akurat. Arahkan kursor ke ikon info untuk
                penjelasan rinci mengenai setiap pilihan:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Checked-Out/Break:</Body1Strong> Menandakan
                    waktu jauh dari pekerjaan, baik itu istirahat atau akhir
                    hari.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Office/Field-Office:</Body1Strong> Menunjukkan
                    keberadaan di ruang kerja yang dikelola perusahaan.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Home-Office:</Body1Strong> Bekerja dari ruang
                    kerja rumah yang khusus.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Remote-Workplace/Public-Area:</Body1Strong>{" "}
                    Bekerja dari lingkungan non-kantor, menunjukkan kebutuhan
                    akan komunikasi yang fleksibel.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Customer Workplace/Private Office:
                    </Body1Strong>{" "}
                    Berada di lokasi klien, dengan catatan tentang tingkat
                    privasi untuk sensitivitas percakapan.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Commute/Business-Travel/Training:</Body1Strong>{" "}
                    Dalam perjalanan atau terlibat dalam kegiatan terkait kerja
                    di luar kantor.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Public-Holiday/Vacation/Various Leaves:
                    </Body1Strong>{" "}
                    Resmi tidak bertugas untuk alasan pribadi, kesehatan, atau
                    liburan.
                  </li>
                </ul>
                Memilih pengaturan tempat kerja yang tepat memastikan tim Anda
                memiliki konteks yang dibutuhkan untuk interaksi, menghormati
                ketersediaan dan privasi.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="41">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                41. Menavigasi Status 'Checked-In' di TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Tag 'Checked-In' dalam Direktori Karyawan dirancang untuk
                menampilkan semua karyawan yang saat ini sedang bekerja.
                Visibilitas ini ditentukan oleh pilihan Tempat Kerja yang Anda
                buat:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Active Work Environments:</Body1Strong> Pilihan
                    Office, Field-Office, Home-Office, Remote-Workplace,
                    Public-Area, Customer-Workplace, Customer Private-Office,
                    Commute, Business-Travel, dan Training menandakan Anda
                    sedang terlibat dalam pekerjaan, menjadikan Anda
                    'Checked-In' dan terlihat aktif oleh rekan kerja.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Non-Work Settings:</Body1Strong> Memilih
                    Checked-Out, Break, Public-Holiday, Vacation, On-Leave,
                    Maternity-Leave, atau Sick-Leave mengeluarkan Anda dari
                    daftar 'Checked-In', menetapkan ekspektasi yang jelas untuk
                    ketersediaan dan waktu respons.
                  </li>
                </ul>
                Fungsionalitas ini membantu membedakan periode kerja dari waktu
                pribadi, mendukung kolaborasi dan komunikasi tim yang efektif.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>

      <Subtitle2>
        Pertanyaan yang Sering Diajukan tentang Pengelolaan Data dan Privasi
      </Subtitle2>
      <Body1>
        <p>
          Di era digital saat ini, memahami bagaimana data Anda dikelola sangat
          penting. TeamConnect berkomitmen terhadap transparansi dan praktik
          perlindungan data yang kuat. Di bawah ini, Anda akan menemukan
          informasi tentang bagaimana kami menangani, menyimpan, dan melindungi
          data Anda dalam TeamConnect, memastikan ruang kerja digital Anda aman
          dan sesuai.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="42">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                42. Bagaimana TeamConnect melindungi data pribadi saya?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <p>
                  TeamConnect berdedikasi untuk melindungi informasi pribadi
                  Anda dengan pendekatan komprehensif terhadap keamanan data:
                </p>
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Single Sign-On (SSO):</Body1Strong> Untuk
                    meningkatkan keamanan dan memperbaiki pengalaman pengguna,
                    TeamConnect mengintegrasikan Single Sign-On (SSO) otomatis
                    dengan akun Microsoft 365 Anda, memfasilitasi manajemen
                    akses yang aman dan mudah.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Enkripsi:</Body1Strong> Kami memastikan bahwa
                    semua data dalam TeamConnect dienkripsi baik saat
                    ditransmisikan maupun saat disimpan, membuatnya tidak dapat
                    diakses oleh pihak yang tidak berwenang.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Minimisasi Data:</Body1Strong> Pengumpulan data
                    kami secara ketat terbatas pada apa yang penting untuk
                    fungsionalitas TeamConnect. Satu-satunya informasi pengguna
                    yang kami simpan adalah Nama Principal Pengguna (UPN) Anda
                    dari Azure Entra ID, yang mungkin menyerupai alamat email
                    perusahaan Anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Akses Terbatas:</Body1Strong> Akses ke UPN
                    dibatasi kepada Admin Berlangganan dari organisasi Anda
                    dalam portal Berlangganan kami, memastikan bahwa informasi
                    sensitif hanya terlihat oleh personel yang berwenang.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Penghapusan Data Segera:</Body1Strong>{" "}
                    TeamConnect dirancang untuk segera menghapus UPN yang
                    disimpan di bawah tiga kondisi khusus: jika berlangganan
                    dibatalkan oleh Admin Berlangganan, jika pengguna dihapus
                    oleh HR dalam TeamConnect, atau jika berlangganan
                    TeamConnect kedaluwarsa. Ini memastikan data pribadi Anda
                    tidak disimpan melebihi masa berguna atau kebutuhan.
                  </li>
                </ul>
                <p>
                  Dengan langkah-langkah ini, TeamConnect berkomitmen untuk
                  menjaga standar tertinggi perlindungan data, memastikan bahwa
                  informasi pribadi Anda tetap aman dan rahasia.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="43">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                43. Pernah bertanya-tanya di mana data Anda berada dengan
                TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect mengubah paradigma layanan SaaS tradisional dengan
                memastikan data Anda tidak pernah meninggalkan organisasi Anda.
                Tidak seperti praktik umum penyimpanan data di server cloud
                eksternal, TeamConnect dengan cerdik memanfaatkan SharePoint
                Online Microsoft 365 organisasi Anda untuk menyimpan semua data
                aplikasi. Pendekatan inovatif ini tidak hanya mendukung
                kedaulatan data tetapi juga menguatkan keamanan, sejalan
                sempurna dengan dedikasi kami yang kuat untuk melindungi privasi
                Anda dan mematuhi standar kepatuhan yang ketat.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="44">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                44. Apakah data saya akan dibagikan dengan pihak ketiga?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Tidak, data tidak dibagikan kepada pihak ketiga! Cubeet kukuh
                dalam komitmen kami untuk privasi dan keamanan data Anda. Kami
                tidak membagikan data TeamConnect apa pun dengan pihak ketiga
                tanpa mendapatkan persetujuan eksplisit dari pelanggan kami.
                Dalam lingkungan yang aman kami, kami hanya menyimpan
                pengidentifikasi penting, seperti Nama Utama Pengguna (UPN),
                nama organisasi, nama penyewa, dan ID penyewa, dalam portal
                Berlangganan kami. Informasi ini sangat penting untuk penyediaan
                layanan dan dilindungi di bawah kebijakan privasi ketat kami.
                Tenang saja, praktik pengelolaan data kami dirancang untuk
                memastikan bahwa data Anda tetap rahasia, dengan transparansi
                dan kontrol yang kuat di tangan Anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="45">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                45. Bagaimana TeamConnect mematuhi peraturan perlindungan data
                seperti GDPR?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect selaras dengan peraturan perlindungan data seperti
                GDPR, HIPAA, CCPA, dan lainnya dengan memanfaatkan fitur
                kepatuhan yang inheren dari lingkungan Microsoft 365, di mana
                semua data pelanggan disimpan dengan aman. Tidak seperti model
                SaaS tradisional, TeamConnect tidak menyimpan data pelanggan di
                server kami; sebaliknya, beroperasi dalam lingkungan Microsoft
                365 organisasi Anda, mematuhi standar keamanan dan kepatuhan
                yang ketat. Pendekatan ini memastikan bahwa tata kelola data
                Anda tetap konsisten dengan kerangka kepatuhan menyeluruh yang
                disediakan oleh Microsoft 365, termasuk kepatuhan terhadap GDPR,
                HIPAA untuk informasi kesehatan, CCPA untuk warga California,
                dan peraturan perlindungan data global lainnya. Dengan
                terintegrasi dengan mulus ke dalam pengaturan Microsoft 365 yang
                ada, TeamConnect memberdayakan organisasi Anda untuk
                mempertahankan kontrol penuh atas data Anda, memastikan
                kepatuhan dengan undang-undang dan peraturan perlindungan data
                yang paling ketat.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="46">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                46. Bagaimana TeamConnect mengelola data dari obrolan,
                panggilan, panggilan video Microsoft Teams, email Outlook, dan
                panggilan sistem telepon?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect memanfaatkan Microsoft Graph API untuk memungkinkan
                interaksi langsung seperti obrolan, panggilan, panggilan video,
                dan email Outlook dalam Microsoft Teams, menyediakan pengalaman
                pengguna yang seamless. Yang sangat penting, meskipun
                TeamConnect menginisiasi komunikasi ini, namun hal itu dilakukan
                tanpa intervensi dalam proses komunikasi atau memiliki kemampuan
                untuk merekam atau menyimpan konten interaksi apa pun. Semua
                percakapan, panggilan, dan email berlangsung dan dikelola
                langsung dalam batasan aman Microsoft Teams dan Outlook, dengan
                mematuhi standar keamanan dan privasi Microsoft 365 yang ketat.
                Ini memastikan bahwa TeamConnect meningkatkan kemampuan
                komunikasi Anda sambil sepenuhnya menjaga kerahasiaan dan
                integritas data Anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Body1>
        <p>
          Untuk wawasan mendetail mengenai kebijakan dan praktik data kami, Anda
          mungkin juga ingin berkonsultasi pada{" "}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>
              Panduan Pengguna TeamConnect
            </Body1Strong>
          </Link>{" "}
          atau departemen IT organisasi Anda di{" "}
          <Link href={`mailto:${itSupport}`}>
            <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong>
          </Link>{" "}
          untuk spesifik terkait dengan konfigurasi Anda.
        </p>
      </Body1>
      <p></p>
      <Subtitle1 className={styles.fontColor}>
        Kami Mendengarkan: Bagikan Umpan Balik dan Ide Anda
      </Subtitle1>
      <Body1>
        <p>
          Wawasan Anda mengarahkan evolusi TeamConnect. Entah Anda memiliki ide
          fitur yang brilian atau memiliki beberapa masukan mengenai pengalaman
          Anda saat ini, kami sangat mendengarkan. Masukan Anda sangat berharga
          dalam misi kami untuk terus menyediakan layanan terbaik.
        </p>
      </Body1>
      <Subtitle2>Masukan dan Saran Fitur</Subtitle2>
      <Body1>
        <p>
          Siap untuk berbagi? Kunjungi halaman{" "}
          <Link
            style={{ fontWeight: 600 }}
            href={feebackLink}
            target='_blank'>
            Halaman Umpan Balik
          </Link>
          . Entah itu saran untuk fitur baru atau masukan untuk fungsionalitas
          yang ada, cukup pilih opsi yang sesuai dari dropdown dalam formulir.
          Kontribusi Anda membantu membentuk masa depan TeamConnect, memastikan
          bahwa itu memenuhi dan melampaui kebutuhan Anda.
        </p>
      </Body1>
    </>
  );
}

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
