import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>
        Центар за помош и Често поставувани прашања на TeamConnect
      </Title3>
      <p>
        <Body1>
          Добредојдовте во Центарот за помош на TeamConnect, дизајниран за
          поддршка на вашето искуство со нашата сеопфатна апликација за
          корпоративен именик. Овде ќе најдете ресурси за оптимизација на вашето
          користење на TeamConnect во рамките на Microsoft Teams, зголемувајќи
          ја комуникацијата низ вашата мрежа.
        </Body1>
      </p>
      <Subtitle2>Брз упатник</Subtitle2>
      <ul>
        <Body1>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Подобрени можности за пребарување: </Body1Strong>
            Брзо пронајдете ги вистинските колеги, оддели и суштински контакти
            со нашите моќни алатки за пребарување.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Визуелни организациски шеми: </Body1Strong>
            Навигирајте лесно низ хиерархијата на вашата организација и
            разберете ги линиите на извештајност преку интуитивни организациски
            шеми.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Адаптивни алатки за денешните потреби: </Body1Strong>
            Користете модерни функционалности прилагодени на барањата на
            современите работни места.
          </li>
        </Body1>
      </ul>
      <p></p>
      <Subtitle2>Оптимизирање на вашето искуство</Subtitle2>
      <Body1>
        <p>
          TeamConnect револуционира како што взаемодејствувате во рамките на
          вашата организациска структура, претворајќи ги секојдневните
          комуникации во стратешки ангажмани.
        </p>
      </Body1>
      <p></p>
      <Subtitle1 className={styles.fontColor}>
        Добијте помош и Често поставувани прашања
      </Subtitle1>
      <Body1>
        <p>
          Потражете одговори на често поставувани прашања за карактеристиките и
          подесувањата на TeamConnect во овој посветен дел. Без разлика дали
          навигирате низ апликацијата, прилагодувате подесувања, или барате
          решенија за предизвиците, нашиот Центар за помош е тука да ви помогне
          ефикасно.
        </p>
      </Body1>
      <Body1>
        <p>
          За понатамошна поддршка или специфични упатства, ве молиме
          консултирајте се со{" "}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>
              Упатството за корисници на TeamConnect
            </Body1Strong>
          </Link>
          .
        </p>
      </Body1>
      <p></p>
      <Subtitle2>Користење на ЧПП за TeamConnect</Subtitle2>
      <p></p>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="1">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                1. Не може да го најде TeamConnect во страничната лента на
                Microsoft Teams?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ако TeamConnect не е видлив или исчезнал во вашата странична
                лента на Microsoft Teams, можете лесно да го закачите за брз
                пристап. Само следете ги овие едноставни чекори:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Отворете Microsoft Teams и кликнете на иконата '...' (+
                    Apps) на дното на страничната лента.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Пребарајте за 'TeamConnect' во лентата за пребарување на
                    врвот на појавниот прозорец.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Кликнете десен клик на апликацијата TeamConnect од
                    резултатите од пребарувањето и изберете 'Закачи'.
                  </li>
                </ol>
                Сега TeamConnect ќе биде закачен на вашата странична лента,
                правејќи го достапен со само еден клик!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="2">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                2. Се соочувате со технички проблеми со TeamConnect? Еве како да
                добиете помош.
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ако наидете на некакви технички проблеми со TeamConnect во
                рамките на Microsoft Teams, тимот за ИТ поддршка на вашата
                организација е тука за да помогне. Контактирајте ги на{" "}
                <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>
                , и тие ќе ви помогнат брзо.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="3">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                3. Не може да ги забележите деталите за вашиот вработен?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ако вашиот профил изгледа малку празен со само вашето име и
                е-пошта прикажани, веројатно вашиот тим за човечки ресурси сè
                уште не ги додал останатите детали за вработените во
                TeamConnect. Не мора да се грижите! Брза порака до вашиот тим за
                поддршка на човечки ресурси ќе ги стави работите во движење. Тие
                се подготвени да го пополнат вашиот профил со сите важни детали.
                Контактирајте на{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>{" "}
                и гледајте како вашиот профил оживува!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="4">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                4. Кои јазични опции се достапни во TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect ја прифаќа глобалната разновидност на Microsoft
                Teams со поддршка на сите негови јазици. Било дека преферирате
                арапски или грчки, јапонски или шведски, имате свет на опции на
                дофат, вклучувајќи:
              </Body1>
              <ul>
                <li>
                  <Body1>
                    Арапски, Бугарски, Кинески (поедноставен и традиционален),
                    Хрватски, Чешки, Дански, Холандски, Англиски, Естонски,
                    Фински, Француски, Германски, Грчки, Хебрејски, Унгарски,
                    Индонезиски, Италијански, Јапонски, Корејски, Латвиски,
                    Литвански, Норвешки (Бокмал), Полски, Португалски (Бразил и
                    Португалија), Романски, Руски, Српски (латиница), Словачки,
                    Словенечки, Шпански, Шведски, Тајландски, Турски, Украински,
                    Виетнамски.
                  </Body1>
                </li>
              </ul>
              <Body1>
                За да го прилагодите вашето искуство со TeamConnect кон вашите
                преференции, ве молиме променете ги јазичните поставки директно
                во Microsoft Teams, бидејќи TeamConnect е целосно интегриран со
                овие поставки:
              </Body1>
              <ol>
                <Body1>
                  <li className={styles.bulletBottomMargin}>
                    Одете во вашите поставки на Microsoft Teams со кликање на
                    три точки до вашата слика од профилот на врвот, и потоа
                    изберете 'Изглед и пристапност'.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Скролајте до делот 'Јазик' и изберете го вашиот префериран
                    јазик од паѓачкото мени.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Потврдете ги вашите промени со кликање на копчето 'Зачувај и
                    рестартирај' за да се применат новите јазични поставки.
                  </li>
                </Body1>
              </ol>
              <Body1Strong>Забелешка: </Body1Strong>
              <Body1>
                Промените направени на вашите јазични поставки во Microsoft
                Teams исто така ќе ја ажурираат вашата искуство со TeamConnect.
                Имајте на ум дека промената на јазикот во Teams може исто така
                да го прилагоди форматот на времето и датумот. Во моментов,
                TeamConnect еволуира за поддршка на пишување од десно на лево.
                Ние сме возбудени за овие подобрувања и ќе ве известиме како тие
                стануваат достапни.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="5">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                5. Како можам да ја сменам темата во TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect се синхронизира со вашите преференции за темата на
                Microsoft Teams, вклучувајќи светла, темна и опции за висок
                контраст. За да ја прилагодите вашата тема:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Пристапете до вашите поставки на Microsoft Teams кликнувајќи
                    на трите точки покрај вашата слика на профилот на врвот.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Изберете 'Изглед и пристапност' за да ги истражите опциите
                    за тема.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Изберете ја вашата преферирана тема од достапните опции за
                    да ја ажурирате веднаш средината на вашето TeamConnect.
                  </li>
                </ol>
                Вашиот избор на тема во Microsoft Teams автоматски ќе се примени
                во TeamConnect, обезбедувајќи конзистентно визуелно искуство низ
                вашата работна просторија.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="6">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                6. Истражување на Contact Action Buttons во TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Низ целиот TeamConnect, каде и да најдете детали за контакт на
                вработен, ќе откриете и наши копчиња за брз пристап. Овие
                корисни алатки се дизајнирани да го оптимизираат вашето
                комуницирање во Microsoft екосистемот, осигурувајќи дека секогаш
                сте само на еден клик од поврзување. Еве го вашето опсежно
                упатство за ефективно користење на овие копчиња:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Chat Icon:</Body1Strong> Кликнете за да
                    започнете инстант Microsoft Teams разговор. Ако треба да се
                    вратите во TeamConnect откако сте започнале разговор,
                    едноставно користете го стрелката назад во Desktop App,
                    копчето Back во Mobile App, или копчето back во вашиот
                    прелистувач.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Email Icon:</Body1Strong> Отвара нов недовршен
                    имејл во Outlook со адресата на примачот веќе внесена во
                    полето 'To:'. Ако Outlook не се отвори, проверете со вашето
                    IT одделение за било какви ограничувања.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Video Call Icon:</Body1Strong> Иницира лице во
                    лице видео повик преку Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Call Icon:</Body1Strong> Започнува гласовен
                    повик преку Microsoft Teams, овозможувајќи брзи дискусии без
                    пречки.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Org Chart Icon:</Body1Strong> Покажува место на
                    вработениот во организациската хиерархија, вклучувајќи го
                    неговиот менаџер и директни подредени.
                  </li>
                  <li>
                    <Body1Strong>More (...) Icon:</Body1Strong> Открива
                    дополнителни опции:
                    <ul>
                      <li
                        style={{ marginTop: "8px" }}
                        className={styles.bulletBottomMargin}
                      >
                        <Body1Strong>Meet Now:</Body1Strong> Отвора Microsoft
                        Teams за да започне веднаш состанок со вработениот.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>New Meeting:</Body1Strong> Ве насочува кон
                        Microsoft Teams за да закажете нов состанок.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Add Contact:</Body1Strong> Додава детали за
                        контакт на вработениот од TeamConnect во вашата нова
                        апликација Microsoft Teams People, збогатувајќи ја
                        вашата функција за повик.
                      </li>
                    </ul>
                  </li>
                </ul>
                Овие интуитивни копчиња се изработени за да обезбедат ефикасно
                поврзување со колегите, било тоа за брз разговор, имејл или за
                закажување идни состаноци.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="7">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                7. Правење повици преку TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Сакате да направите повик? TeamConnect го оптимизира вашето
                комуницирање со интеграција со Microsoft Teams. Едноставно
                кликнете на посакуваниот телефонски број во TeamConnect, и
                војла, интерфејсот за повикување на Microsoft Teams се појавува,
                подготвен за да извршите повик без пречки.
                <p>
                  <Body1Strong>Забелешка: </Body1Strong>
                  Се соочувате со проблеми при правење повици? Ова може да биде
                  поради политиките на IT одделот на вашата организација или
                  употребата на телефонски систем различен од Microsoft Teams
                  Phone System. Консултирањето со вашето IT одделение на{" "}
                  <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>
                      {itSupport}
                    </Body1Strong>
                  </Link>
                  , може да ви даде повеќе информации за оваа проблематика.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="8">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                8. Дали има трошоци поврзани со правењето повици преку
                TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect го подобрува вашето искуство со повикување преку
                користење на Microsoft Teams. Еве што треба да знаете за
                трошоците поврзани со повиците:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    За повик до работните телефони на вработените, телефоните во
                    одделите, или хотлајн во вашата организација (внатрешни
                    броеви), обично <Body1Strong>нема трошоци</Body1Strong> кога
                    се користи телефонскиот систем на Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    За надворешни повици, како на пример до мобилни телефони или
                    надворешни линии,{" "}
                    <Body1Strong>стандардните тарифи</Body1Strong> на
                    телефонскиот систем на вашата организација се применуваат.
                    Овие стапки се разликуваат во зависност од вашето конкретно
                    сервисно пакет со Microsoft Teams.
                  </li>
                </ul>
                <p>
                  <Body1Strong>Забелешка:</Body1Strong> Доколку наидете на
                  некакви проблеми или имате прашања во врска со наплатата на
                  повиците, најдобро е да се консултирате со вашата ИТ оддел во{" "}
                  <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>
                      {itSupport}
                    </Body1Strong>
                  </Link>{" "}
                  за детални информации прилагодени на подесувањата и политиките
                  на вашата организација.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="9">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                9. Забележавте несовпаѓање со вашата статусна ознака 'Надвор од
                канцеларија' во TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Забележавте дека вашата ознака за присуство 'Надвор од
                канцеларија' од Microsoft 365 не се одразува правилно во
                TeamConnect? Имаме решение за вас. Функцијата за синхронизација
                на статусот за присуство 'Надвор од канцеларија' е веќе воведена
                во TeamConnect. Во моментов чекаме Microsoft да ја имплементира
                оваа функција. Откако тоа ќе се случи, вашата ознака за
                присуство 'Надвор од канцеларија' ќе биде точно одразена во
                TeamConnect, усогласувајќи се со индикаторите за присуство на
                Microsoft Teams за безпрекорно искуство.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Често поставувани прашања за Именикот на вработени</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Вовед во Именикот на вработени:</Body1Strong> Навигирајте
          низ професионалниот пејзаж на вашата организација со Именикот на
          вработени, вашата централна точка за детални информации за
          вработените, работните динамики, и директна поврзаност со профилите и
          менаџерските врски на вашите колеги.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="10">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                10. Потребно е да додадете, ажурирате или исправите податоци за
                вработените?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                За додавање, ажурирање или исправка на вашите податоци за
                вработените во TeamConnect, ве молиме контактирајте со тимот за
                HR поддршка во вашата организација. Тие се подготвени да ви
                помогнат на{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>
                .
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="11">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                11. Што се случува кога ќе кликнете на името на вработен во
                директориумот?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Кликнувањето на името на вработен во директориумот на вработени
                во TeamConnect отвора детална страница за профил на тој
                индивидуа. Овој профил обезбедува целосни увиди за вработениот,
                вклучувајќи, но не ограничувајќи се на, нивната работна
                позиција, оддел, локација на канцеларија, контакт информации и
                повеќе. Тоа е дизајнирано да ви даде целосен преглед на
                професионалната улога на вработениот и како тие се вклопуваат во
                организацијата, олеснувајќи ви да ги разберете нивните
                одговорности и како би можеле да соработувате со нив.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="12">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                12. Кои информации се вклучени во профилот на вработен?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Секој профил на вработен во TeamConnect е целосен приказ, кој
                нуди богатство на увиди за професионалниот живот на индивидуата.
                Еве што ќе најдете:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Основни детали:</Body1Strong> Име, работна
                    позиција, оддел, и локација на филијала.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Извештајна структура:</Body1Strong> Информации
                    за директни и функционални извештаи, вклучувајќи линиски и
                    функционални менаџери.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Статус на работното место:</Body1Strong>{" "}
                    Сегашен работен статус како на одмор, на пат, на службено
                    патување итн.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Работно место & Локално време:</Body1Strong>{" "}
                    Сегашната работна локација на вработениот и локалната
                    временска зона.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Контакт детали:</Body1Strong> Работен телефон,
                    мобилен број, и е-пошта.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Адреса на канцеларијата:</Body1Strong> Целосна
                    локација на канцеларијата вклучително име на зграда, адреса,
                    и Google мапа.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>За:</Body1Strong> Краток личен вовед или
                    професионална биографија.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Работни односи:</Body1Strong> Детали за
                    извештајната структура и односи во тимот на вработениот.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Социјални врски & Јазици:</Body1Strong> Врски
                    до професионални социјални профили и јазични способности.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Вештини:</Body1Strong> Листа на професионални
                    вештини и области на експертиза.
                  </li>
                </ul>
                Дополнително, профилот нуди опции за дејствување за поврзување
                преку чет, е-пошта, видео повик, или закажување средби, заедно
                со брз пристап до организациската шема за поширок контекст на
                тимот.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="13">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                13. Како можам да ги ажурирам информациите во мојот профил?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Личното прилагодување на вашиот профил е лесно во TeamConnect.
                Еве како можете да го направите тоа:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Одете до табот <Body1Strong>Вработен</Body1Strong> и
                    кликнете на вашата <Body1Strong>Аватар</Body1Strong> за да
                    го отворите вашиот профил.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Во горниот лев агол, ќе најдете врска{" "}
                    <Body1Strong>"Уреди го твојот профил"</Body1Strong>.
                    Кликнување на ова ќе го отвори дијалогот за уредување на
                    вашиот профил.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Тука, можете да го подобрите вашиот профил со додавање или
                    ажурирање на вашата секција{" "}
                    <Body1Strong>За Вас</Body1Strong>, линкови до социјални
                    медиуми и телефонски број. Тоа им олеснува на вашите колеги
                    да се поврзат или следат со вас.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Не заборавите да притиснете на копчето{" "}
                    <Body1Strong>Зачувај</Body1Strong> за да ги примените вашите
                    промени.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Скролирајте надолу во дијалогот за да ги додадете вашите{" "}
                    <Body1Strong>Јазици</Body1Strong> и{" "}
                    <Body1Strong>Вештини</Body1Strong>, како и вашите{" "}
                    <Body1Strong>Способности</Body1Strong>. Споделувањето на
                    овие информации им помага на вашите колеги да разберат во
                    кои области сте стручни и на кои јазици можете да
                    комуницирате.
                  </li>
                </ol>
                Ажурирањето на вашиот профил не само што ги одржува вашите
                информации актуелни, туку исто така овозможува подобра врска во
                рамките на вашата организација.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="14">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                14. Ажурирање на вашето работно место, работна позиција и
                временска зона во TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Одржувањето на информациите за вашето работно место, работна
                позиција и временска зона ажурирани е клучно за непречена
                соработка во Microsoft Teams. Овие ажурирања се одразуваат
                веднаш во вашиот профил и списоци на TeamConnect, овозможувајќи
                колегите брзо да го разберат вашето место на работа и најдобрите
                времиња за поврзување со вас, подобрувајќи тимската работа низ
                различни региони и временски зони.
                <p></p>
                Подготвени сте да ги ажурирате вашите детали? Е лесно во
                TeamConnect! Одете до 'Поставки' во горното мени и навигирајте
                до 'Општи поставки'. Тука, можете лесно да ги ажурирате вашето
                работно место и временска зона и да специфицирате вашата работна
                позиција за денот. Не заборавите да ги запамтите вашите промени
                за да останете информирани и да обезбедите ефикасна соработка,
                без разлика на вашата локација!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="15">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                15. Забележавте нешто необично во профилот на колега?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ако забележите некои детали во профилот на колега кои не
                изгледаат сосема во ред, најдобриот прв чекор е да им дадете
                пријателско предупредување. Брз разговор или е-пошта треба да
                направат работа! Ако по некоја причина информациите не се
                ажурираат, не грижете се—само контактирајте го вашиот тим за
                поддршка на HR на{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>
                , и тие ќе бидат среќни да интервенираат и да ги уредуваат
                работите.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="16">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                16. Разбирање на групи со тагови: Сите, Омилени, Присутни, &
                Нови Вработени
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Сите:</Body1Strong> Овој стандарден таг ги
                    прикажува сите вработени во вашата организација,
                    обезбедувајќи целосен преглед на тимот.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Омилени:</Body1Strong> Кликнете тука за да го
                    филтрирате вашето прикажување до оние колеги што сте ги
                    означиле како 'Омилени', олеснувајќи брза поврзаност со
                    клучни контакти.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Присутни:</Body1Strong> Овој таг укажува на
                    вработените кои активно се на работа, разликувајќи ги од
                    само онлајн присуството во Microsoft Teams. Кога се
                    пријавуваат, вработените покажуваат дека се на работа и ја
                    споделуваат својата тековна работна локација во текот на
                    денот. Ова може да вклучува различни работни места како
                    Канцеларија, Теренска-Канцеларија, Домашна-Канцеларија,
                    Далечинско-Работно Место, Место за Работа кај Клиент,
                    Приватна-Канцеларија кај Клиент, Јавна-Површина, Пат,
                    Службено-Патување, или дури и Обука. Поради тоа, статусот
                    'Присутен' обезбедува јасна индикација за активна работа,
                    овозможувајќи на колегите точно разбирање на тековниот
                    работен контекст и достапност на секој друг.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Нови Вработени:</Body1Strong> Забележете нови
                    лица во TeamConnect со овој таг, истакнувајќи ги вработените
                    кои се додадени во апликацијата во последните 30 дена.
                    Обично, ова е во склад со неодамнешни додатоци кон вашата
                    организација, помагајќи ви да ги пречекате новите членови на
                    тимот.
                  </li>
                </ul>
                Овие групи со тагови се дизајнирани да го оптимизираат
                навигацијата и да го подобрат вашето искуство со TeamConnect,
                осигурувајќи дека секогаш можете лесно да ги најдете и поврзете
                со вашите колеги.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="17">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                17. Дешифрирање на иконите покрај полето за пребарување
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Именикот на вработени во TeamConnect е дизајниран за пристапност
                и леснотија, со четири икони кои нудат различни прикази и брз
                пристап до клучни детали. Еве што открива секоја икона:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Приказ во список:</Body1Strong> Вашиот основен
                    приказ, кој ги претставува сите членови на организацијата во
                    едноставен список.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Мал приказ на картата:</Body1Strong> Компактна
                    алтернатива, овој приказ го трансформира списокот во
                    картички на вработени.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Голем приказ на картата:</Body1Strong> Изберете
                    за повеќе детали со поголеми картички, кои вклучуваат
                    дополнителни опции за контакт како телефонски и мобилни
                    броеви за лесно повикување.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Органограм:</Body1Strong> Визуализирајте ја
                    структурата на компанијата со приказот на органограмот,
                    совршен за разбирање на односите за извештајот и навигација
                    низ организациониот пејзаж.
                  </li>
                </ol>
                Префрлајте се меѓу овие икони за да го најдете приказот кој
                најдобро ви одговара во моментот, без разлика дали барате брзи
                информации или подлабоки увиди.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="18">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                18. За брзо пребарување на колеги, каде треба да погледнам?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ви треба колега? Само одете до полето за пребарување на
                TeamConnect, кое се наоѓа во горниот десен агол до иконата на
                органограмот. Почнете да го внесувате нивното име, и гледајте
                како резултатите се подобруваат со секоја внесена буква.
                Запомнете, за пребарувања на TeamConnect, избегнувајте ја
                лентата за пребарување во Microsoft Teams на врвот - нашето
                посебно поле за пребарување е вистинскиот начин!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="19">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                19. Како функционира алфабетското пребарување за наоѓање
                вработени?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Адресарот за вработени на TeamConnect вклучува корисна функција
                за алфабетско пребарување која ви помага брзо и лесно да ги
                лоцирате вработените по нивното име. Едноставно кликнете на
                буква од алфабетот прикажан на врвот на адресарот, и TeamConnect
                веднаш ќе ви ги покаже сите вработени чиишто имиња започнуваат
                со таа буква. За да се вратите кон целосната листа на вработени,
                само деселектирајте ја истакнатата буква. Оваа интуитивна
                функција овозможува лесно навигација низ големи листи на
                вработени, осигурувајќи дека може брзо и ефикасно да ги најдете
                колегите.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="20">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                20. Како можам да прилагодам бројот на вработени прикажани по
                страница?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect ви овозможува да прилагодите колку профили на
                вработени сакате да видите по страница, што олеснува
                навигацијата низ адресарот на вработени. На дното на секоја
                страница ќе најдете опции за пагинација: 10, 50, 100, 500, 1000
                и 5000. Стандардната поставка прикажува 10 профили по страница.
                За да видите повеќе профили на една страница, едноставно
                изберете го посакуваниот број од овие опции. Оваа функција
                обезбедува флексибилност во начинот на кој прегледувате по
                адресарот, ви овозможувајќи да го прилагодите приказот според
                вашите специфични потреби или преференци.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="21">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                21. Како можам да сортирам информации за вработените во приказот
                на листа?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Во приказот на листата на адресарот на вработени на TeamConnect,
                имате флексибилност да сортирате информации за вработените
                според вашите потреби. Колоните—Име на вработен, Работно место,
                Отдел, Локација на канцеларија и Работно место—се сите
                сортирачки. Едноставно кликнете на заглавието на колоната по
                која сакате да сортирате. Кликањето еднаш ќе ја сортира колоната
                во растечки редослед, а кликањето повторно ќе ја сортира во
                опаѓачки редослед. По стандард, адресарот е сортиран по име на
                вработен во растечки редослед, базирано на првото име. Оваа
                функција за сортирање ви помага брзо да ги организирате и видите
                деталите за вработените на начин што најдобро ги служи вашите
                цели, било дека барате конкретна личност или треба да групирате
                вработени по одреден критериум како отдел или локација.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>
        Често поставувани прашања за директориумот на одделенија
      </Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Вовед во директориумот на одделенија:</Body1Strong>{" "}
          Директориумот на одделенијата е вашиот пристап до разбирање на
          сложената мрежа на вашата организациона структура, нудејќи јасен
          поглед на хиерархиите на одделенијата, менаџерско водство и патишта за
          безпроблемна комуникација помеѓу одделенијата.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="22">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                22. Како функционираат ознаките 'Сите', 'Фаворити' и 'Вашето
                одделение' во директориумот на одделенија?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Во директориумот на одделенија на TeamConnect, ознаките ви
                помагаат брзо да навигирате и филтрирате информации за
                одделението. Еве што претставува секоја ознака:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Сите:</Body1Strong> Оваа ознака прикажува сите
                    одделенија во вашата организација и обезбедува целосен
                    преглед.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Фаворити:</Body1Strong> Користете ја оваа
                    ознака за да видите избрана листа на одделенија кои сте ги
                    означиле како 'Фаворити', олеснувајќи пристап до
                    одделенијата со кои често интерагирате.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Вашето одделение:</Body1Strong> Оваа ознака го
                    филтрира приказот за да прикаже само вашето одделение,
                    овозможувајќи брз пристап до информациите и ажурирањата на
                    вашето одделение.
                  </li>
                </ul>
                Овие ознаки се дизајнирани да ви олеснат навигацијата во
                директориумот на одделенија, зголемувајќи ја вашата способност
                ефикасно да пронајдете и вклучите соодветни информации за
                одделенијата.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="23">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                23. Разбирање на приказите на директориумот на одделенија во
                TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Навигацијата низ директориумот на одделенија во TeamConnect е
                олеснета со повеќе опции за прикажување кои се прилагодени на
                вашите преференции. Секој приказ нуди уникатен начин да изучите
                сеопфатни информации за одделението, вклучувајќи име на
                одделението, менаџер на одделението, надредено одделение,
                телефон на одделението, телефонска линија на одделението и
                електронска пошта на одделението. Еве преглед на достапните
                прикази:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Приказ во список:</Body1Strong> Стандардно
                    подесување кое уредно ги наведува сите одделенија,
                    обезбедувајќи јасен и концизен преглед.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Приказ на големи картички:</Body1Strong>{" "}
                    Префрлете се на овој приказ за да видите детални картички на
                    одделенијата, кои нудат проширен преглед на клучните
                    информации за секое одделение.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Приказ на организациска шема:</Body1Strong> За
                    визуелен приказ на структурата и релациите на одделенијата,
                    приказот на организациската шема илустрира како секое
                    одделение се вклопува во поголемата организациона
                    хиерархија.
                  </li>
                </ul>
                За менување помеѓу овие прикази, едноставно користете ги иконите
                до полето за пребарување на врвот на директориумот на
                одделенија. Секој приказ е дизајниран да ви помогне лесно да
                пристапите и разберете детали за одделението, осигурувајќи дека
                ќе ги најдете информациите што ви се потребни во форматот што
                најдобро ви одговара.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          Translate the following text into mk, ensuring that no part of the
          programming code or code structure is altered:
          <AccordionItem value="24">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                24. Како да ја користите кутијата за пребарување во
                Директориумот на оддели?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Непречено лоцирајте го кој било оддел во TeamConnect користејќи
                го сосема посветениот простор за пребарување сместен веднаш до
                иконите за преглед на врвот на Директориумот на оддели.
                Едноставно почнете да го внесувате името на одделот кое го
                барате, и набљудувајте како директориумот се прилагодува за да
                прикаже само релевантни резултати. Оваа функционалност е
                специјално дизајнирана за да ви помогне да избегнете потребата
                да пребарувате рачно низ целиот директориум, носејќи го
                потребниот оддел директно до вашите прсти. Запомнете, оваа
                функционалност за пребарување е уникатна за Директориумот на
                оддели на TeamConnect, па затоа користете ја оваа кутија за
                пребарување наместо главното пребарување во Microsoft Teams на
                самиот врв на интерфејсот на Teams за прашања специфични за
                оддели.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="25">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                25. Како функционира алфабетскиот филтер во Директориумот на
                оддели?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Олеснете го вашето пребарување во Директориумот на оддели со
                алфабетскиот филтер сместен на врвот. Оваа функционалност ви
                овозможува брзо да ги сузите одделите со избор на буква. Откако
                ќе изберете буква, TeamConnect филтрира и прикажува само тие
                оддели чии имиња започнуваат со вашата избрана буква,
                олеснувајќи ви да најдете она што го барате без да листате низ
                целиот список. За да се вратите на погледот на целиот оддел,
                едноставно деселектирајте ја истакнатата буква. Оваа интуитивна
                алфабетска навигација ви овозможува ефикасно да го лоцирате кој
                било оддел по неговата почетна буква.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="26">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                26. Како можам да контактирам со менаџерот на одделот?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Контактирањето со менаџерот на одделот во TeamConnect е
                едноставно. Едноставно лоцирајте го одделот од интерес и
                кликнете на името на менаџерот. Оваа акција ќе активира popover
                што прикажува опции за контакт со менаџерот со познатите икони
                за брз пристап:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Икона за чет:</Body1Strong> За да започнете
                    инстант порака преку Microsoft Teams chat.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Икона за е-пошта:</Body1Strong> За да отворите
                    нова е-пошта адресирана до него/неа во Microsoft Outlook.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Икона за видео повик:</Body1Strong> За
                    започнување на видео повик преку Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Икона за повик:</Body1Strong> За започнување на
                    гласовен повик користејќи го Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Икона за повеќе (...):</Body1Strong> За
                    дополнителни опции како што се закажување на средба или
                    додавање на нивните контакт информации во вашата листа на
                    Teams.
                  </li>
                </ul>
                Овие икони обезбедуваат непречен метод за директна контакт со
                менаџерите на оддели во рамките на TeamConnect, овозможувајќи
                ефикасн а комуникација во вашата организација.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="27">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                27. Како можам да ги видам или контактирам вработените во оддел?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                За да изведете кој е кој во одделот во TeamConnect, погледнете
                на трите точки покрај името на одделот. Кликнувањето на ова ќе
                прикаже опции за понатамошни активности:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Погледнете вработени во овој оддел:
                    </Body1Strong>{" "}
                    Изберете ја оваа опција за да прикажете список на сите
                    вработени асоцирани со одделот. Бројот покрај оваа опција
                    укажува колку вработени има во одделот, обезбедувајќи брз
                    преглед.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Органска структура на одделот:</Body1Strong>{" "}
                    Ако сакате да ја разберете хиерархијата на одделот, изберете
                    'органска структура на одделот' за да откриете структурален
                    распоред, прикажувајќи ја врската меѓу родителските оддали,
                    одделот за кој станува збор, и сите пододдали. Оваа визуелна
                    репрезентација ви помага да навигирате и разберете
                    организацискиот оквир, олеснувајќи го идентификувањето и
                    контактирањето со специфични членови на одделот.
                  </li>
                  <li>
                    <Body1Strong>Оддел {">"} Вработен:</Body1Strong> По
                    гледањето на вработените во одделот, користете го овој траг
                    за лебови за лесно да се вратите назад до списокот на
                    оддали, одржувајќи гладок искуство за истражување во
                    TeamConnect.
                  </li>
                </ul>
                Овие функции ви овозможуваат не само да го видите списокот на
                вработени во даден оддел, туку исто така нудат патека за
                директно контактирање со нив преку комуникациските алатки на
                TeamConnect.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="28">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                28. Откривање детали за одделот во длабочина во TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Заглабете во спецификите на кој било оддел со едноставен клик на
                неговото име во TeamConnect. Детален дијалог се расклопува,
                нудејќи богат мозаик на информации кои цртаат целосна слика за
                улогата и ресурсите на одделот во вашата организација. Ќе
                најдете сè - од лидерство и финансиски идентификатори до
                комуникациски канали и социјални врски. Оваа функција е вашиот
                портал до:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    Срцето на одделот - неговата цел, луѓето и метриките за
                    перформанси.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Потеги за контакт - директни линии до клучни фигури во
                    одделот и платформи за комуникација.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Организациската мрежа - разбирање како одделот се вклопува
                    со пошироката структура на компанијата.
                  </li>
                </ul>
                Без разлика дали станува збор за брзо прашање или длабоко
                истражување на податоци за одделот, TeamConnect ви нуди алатки
                за да се поврзете со и разберете уникатните екосистеми во вашата
                организација.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="29">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                29. Заинтригирани сте зошто нема адреси за одделите во
                TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Пронаоѓањето локација на оддел понекогаш може да изгледа како
                лов на сокровишта без мапа. Во TeamConnect, обично нема посебна
                адреса наменета за секој оддел. Еве зошто овој картографски
                детал често се изоставува:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Повеќе локации:</Body1Strong> Многу оддали не
                    се ограничени на еден простор; тие процветуваат преку
                    различни канцеларии и географии.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Флуидна структура:</Body1Strong>{" "}
                    Организациската пејзаж е постојано менувачки, со оддали кои
                    се развиваат, спојуваат, или дури и распуштаат во согласност
                    со стратешките смени.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Меѓуодделска соработка:</Body1Strong>{" "}
                    Современите работни простории звучат со соработнички дух,
                    често комбинирајќи таленти од различни оддали во заеднички
                    простори.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Култура на далечинска работа:</Body1Strong> Со
                    флексибилноста на далечинска работа, sidelite на одделот не
                    се секогаш фиксирани во физички канцелариски локации.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Правни нијанси:</Body1Strong> Оддалите обично
                    орбитираат околу правната структура на компанијата наместо
                    да имаат свои сопствени самостојни адреси.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Оперативна ефикасност:</Body1Strong>{" "}
                    Одржувањето на фокусот на динамична тимска работа, а не на
                    статични адреси, помага во одржувањето на оперативната
                    агилност.
                  </li>
                </ul>
                Сè се врти околу прифаќањето на безграничен пристап кон тимска
                работа и ефикасност. TeamConnect го одразува ова филозофија со
                акцент на поврзувања повеќе отколку на локации.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="30">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                30. Дали информациите за вашиот оддел недостасуваат или се
                застарени?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Уредниците на оддели се дизајнирани во вашата организација за
                добавување, ажурирање или корегирање на информации специфични за
                одделот во TeamConnect. Ако не сте сигурни кој е уредникот на
                вашиот оддел или ако одредени информации бараат интервенција од
                тимот за поддршка на HR, ве молиме контактирајте{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>{" "}
                за упатства.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Често поставувани прашања за именикот на канцеларии</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Вовед во именикот на канцеларии:</Body1Strong> Откријте
          ја основата на физичкото присуство на вашата организација преку
          именикот на канцеларии, кој опфаќа суштински детали за канцелариите,
          оперативни спецификации, и целосен поглед на одделите и персоналот кои
          го дефинираат секое место.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="31">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                31. Навигација преку ознаки во именикот на канцеларии
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Најдете го вашиот пат низ именикот на канцеларии со леснотија
                користејќи го нашиот систем на ознаки дизајниран за брз пристап
                до вистинската локација. Еве како секоја ознака може да ви
                помогне да го оптимизирате вашето пребарување:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Сите:</Body1Strong> Прикажува целосен список на
                    канцеларии, обезбедувајќи глобален поглед на локациите на
                    вашата организација.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Омилени:</Body1Strong> Ви помага да се
                    фокусирате на оние локации на канцеларии кои често ги
                    посетувате или треба да ги следите, со означување како
                    'Омилени'.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Вашата канцеларија:</Body1Strong> Оваа лична
                    ознака филтрира именикот за да ви покаже локацијата на
                    канцеларијата каде што сте стационирани, одржувајќи го
                    вашето домашно место веднаш до вас.
                  </li>
                </ul>
                Без разлика дали резервирате сала за состаноци или планирате
                посета, овие ознаки нудат персонализиран и поедноставен начин за
                интеракција со географскиот пејзаж на вашата организација.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="32">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                32. Овладување со прегледите на директориумите во TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Истражете ја организациската структура на различните локации на
                вашата компанија преку прегледите на директориумот на канцеларии
                во TeamConnect. Секој преглед е создаден за да олесни уникатен
                аспект на навигација и разбирање на канцелариите:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Преглед на листа:</Body1Strong> Стандардното
                    подесување кое уредно ги организира сите локации на
                    канцеларии во лесно читлива листа.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Преглед на големи картички:</Body1Strong>{" "}
                    Прегледот на големи картички во директориумот на
                    канцелариите го збогатува вашето гледиште со детален приказ
                    на секоја канцеларија. Кога ќе воспоставите интеракција со
                    картичката на канцеларијата, ќе најдете богатство на
                    информации и опции за акција во менито "Повеќе (...)" :
                    <ol>
                      <li
                        style={{ marginTop: "8px" }}
                        className={styles.bulletBottomMargin}
                      >
                        <Body1Strong>Преглед на оддели:</Body1Strong> Директно
                        навигирајте до листа на сите оддели сместени во
                        канцеларијата.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Преглед на вработени:</Body1Strong>{" "}
                        Пристапете до целосен список на вработени кои се
                        доделени за канцеларијата, олеснувајќи директен контакт
                        или истражување на персоналот на канцеларијата.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>
                          Органограм на деловната единица:
                        </Body1Strong>{" "}
                        Визуализирајте го местото на канцеларијата во рамките на
                        поголемиот организациски контекст за јасно разбирање на
                        хиерархиските структури.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Суштински контакти:</Body1Strong>{" "}
                        Идентификувајте клучни контакти во канцеларијата за
                        стримлинувана комуникација.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>
                          Државни празници на канцеларијата:
                        </Body1Strong>{" "}
                        Останете информирани за специфичните државни празници на
                        канцеларијата за да ги планирате вашите распореди
                        соодветно.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>
                          Работно време на канцеларијата:
                        </Body1Strong>{" "}
                        Прегледајте ги работните часови на канцеларијата за да
                        ги усогласите вашите посети или повици со нивното
                        активно време.
                      </li>
                    </ol>
                    Овој преглед обезбедува не само визуелен и информативен
                    моментален снимок туку и брз пристап до подлабоки сознанија
                    и директни акции поврзани со канцеларијата, сите уредно
                    поставени под опцијата "Повеќе (...)" за ваша удобност.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Преглед на органограм:</Body1Strong>{" "}
                    Визуализирајте ја хиерархиската структура на матичните и
                    под-канцеларии за да ги разберете поврзаностите на
                    различните локации на канцелариите во вашата организација.
                  </li>
                </ul>
                Префрлајте ги овие прегледи користејќи ги иконите покрај
                кутијата за пребарување за да го прилагодите вашето искуство во
                пребарување на вашите непосредни потреби.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="33">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                33. Брзо пронаоѓање на канцеларии со помош на функцијата за
                пребарување во TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Фокусирањето на вистинската локација на канцеларија во рамките
                на TeamConnect е олеснето со посветената функција за
                пребарување. Сместено проминентно покрај иконите за преглед,
                полето за пребарување е вашиот алат за точно утврдување на
                канцелариите по име. Додека пишувате, директориумот го рафинира
                приказот за да покажува само канцеларии кои одговараат на вашите
                информации, оптимизирајќи го вашиот процес на пребарување. Важно
                е да ја користите оваа кутија за пребарување во рамките на
                TeamConnect за најточни и соодветни резултати, за разлика од
                општото пребарување во Microsoft Teams. Со оваа целена
                функционалност за пребарување, може брзо да навигирате до која
                било канцеларија во мрежата на вашата организација.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="34">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                34. Алфабетско навигирање низ директориумот на канцеларии
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ако преферирате пребарување по азбука, нашиот алфабетски филтер
                е брз начин да најдете канцеларии по име. На врвот на
                директориумот на канцеларии, ќе видите букви од A до Z.
                Кликнување на буква го филтрира прикажувањето за да покаже само
                оние канцеларии кои започнуваат со таа буква, олеснувајќи го
                вашиот процес на пребарување. За ресетирање и повторно гледање
                на сите канцеларии, едноставно деселектирајте ја активната
                буква. Оваа функција е особено корисна кога ви е познато името
                на канцеларијата, но ви треба брз начин да ја лоцирате во
                TeamConnect.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="35">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                35. Откривање на опширни профили на канцеларии во TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Започнете истражувачко патување низ срцето на физичкото
                присуство на вашата организација со профилите на канцеларии на
                TeamConnect. Кликањето на име на канцеларија отвора живописен
                дијалог за профил, преполн со јазичиња полни со детални увиди за
                канцелариите:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Преглед:</Body1Strong> Заглебете се во основата
                    на идентитетот на канцеларијата со детални описи, од деловна
                    структура до работно време и локално време, фаќајќи ја
                    суштината на амбиентот на канцеларијата.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Есенцијални контакти:</Body1Strong> Пристапете
                    до клучни контакти за итни услуги и надворешна поддршка,
                    секоја кликна далеку преку Microsoft Teams Phone System.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Контакти во канцеларијата:</Body1Strong>{" "}
                    Контактирајте со леснотија со внатрешни услуги за поддршка,
                    со детали за контакт и опции за директно ангажирање веднаш
                    на дофат.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Државни празници:</Body1Strong> Останете
                    информирани за празници специфични за канцеларијата,
                    осигурувајќи дека вашите планови се усогласуваат со
                    локалните набљудувања и работни распореди.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Работно време:</Body1Strong> Планирајте ги
                    вашите интеракции со прецизно знаење кога канцеларијата е
                    полна со активности или се затвора за денот.
                  </li>
                </ul>
                Без разлика дали е речиси жива енергија од Остин или стратешки
                центар на Сингапур, секое јазиче нуди портал за разбирање и
                поврзување со различните локации што го сочинуваат глобалното
                присуство на вашата компанија.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="36">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                36. Задлабочување во динамиката на одделите во канцеларија
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Навигација низ професионалната мрежа на одделите во канцеларија
                се одвива лесно во TeamConnect. Еве како да се впуштите во
                деталите:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Преглед на одделите во оваа канцеларија:
                    </Body1Strong>{" "}
                    Откријте ги сите одделенија кои ја сочинуваат структурата на
                    канцеларијата избирајќи ја оваа опција од менито More (...).
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Организациски увид:</Body1Strong> Кликнете на
                    кој било оддел за да ја откриете неговата организациска шема
                    за визуелен приказ на неговата структура, со следниве ознаки
                    кои ве водат: Канцеларија {">"} Оддел {">"} Шема.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Задлабочено истражување на одделот:
                    </Body1Strong>{" "}
                    Понатаму истражете оддел кликнувајќи на менито More (...) до
                    името на одделот. Тука, може да пристапите до список на сите
                    вработени во тој оддел, се уште во контекст на
                    канцеларијата.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Навигација преку следни точки:</Body1Strong>{" "}
                    Поминувањето низ нивоата се одвива без пречки. Од
                    вработените назад до одделите или од организациската шема,
                    следната јашка (Канцеларија {">"} Оддел {">"} Вработен {">"}{" "}
                    Шема) го задржува вашето патување интуитивно и на прав пат.
                  </li>
                </ul>
                Комплексните патеки во TeamConnect ве покануваат да истражувате,
                разберете и се ангажирате со секој слој на одделите во вашата
                канцеларија, овозможувајќи ви целосна команда за навигација.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="37">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                37. Комуницирање со персоналот во канцеларијата во TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Заинтересирани за лицата зад канцеларија? Одговорот е само со
                еден клик во TeamConnect. Вклучете се со елипсата (трите точки)
                покрај името на вашата избрана канцеларија за да откриете
                комплет од интерактивни опции:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Преглед на вработените во оваа канцеларија:
                    </Body1Strong>{" "}
                    Откријте список на сите професионалци кои ја нарекуваат оваа
                    канцеларија нивен работен дом. Прикажаниот број нуди брз
                    преглед на вработените, поканувајќи вас да се вклучите во
                    заедницата на канцеларијата.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Опции за контакт:</Body1Strong> Секој запис за
                    вработен е опремен со икони за брза акција, овозможувајќи ви
                    да започнете разговор, повик или дури видео конференција
                    директно од TeamConnect, поддржувајќи беспрекорна
                    комуникација во канцеларијата.
                  </li>
                  <li>
                    <Body1Strong>Навигација:</Body1Strong> За да се вратите на
                    пошироката листа на канцеларии од списокот на вработени,
                    едноставно кликнете на 'Канцеларија' во следната јашка
                    (Канцеларија {">"} Вработен), и ќе бидете вратени назад на
                    прегледот на канцеларијата.
                  </li>
                </ul>
                Со овие функции, TeamConnect го олеснува визуелизирањето на
                распределбата на работната сила низ канцелариите и остварувањето
                на контакт без напор, поддржувајќи поврзано работно место без
                оглед на далечината.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="38">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                38. Дали информациите за вашата канцеларија недостасуваат или се
                застарени?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ажурирањата на информациите за канцеларијата во TeamConnect
                можат да се извршат од страна на доделените уредници на
                канцеларијата. Ако не сте сигурни кои се уредниците на
                канцеларијата или ви треба помош за информациите што се
                однесуваат на HR, тимот за поддршка на HR е подготвен да помогне
                на
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>
                .
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Најчесто поставувани прашања за нагодувањата</Subtitle2>
      <p></p>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="39">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                39. Важноста на точните Општи нагодувања во TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Во динамичната средина на денешната работна околина,
                останувањето поврзано и координирано е посуштествено од било
                кога. Токму тука ажурирањето на вашите Општи нагодувања во
                TeamConnect има клучна улога:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Избор на работно место:</Body1Strong>{" "}
                    Запечатете ја суштината на вашата работна околина со
                    ажурирање на вашето Работно Место да го одразува вашето
                    моментално стање, без разлика дали е премин од мирот на
                    домашната канцеларија кон вревата на главната канцеларија,
                    или дури и сместување во Јавен Простор. Оваа јасност во
                    вашата работна локација им овозможува на колегите увид не
                    само во најдобрите начини за контакт со вас, туку и во
                    природата на разговорите што може да ги водат со вас,
                    штитејќи ја поверливоста на чувствителните разговори особено
                    кога сте во отворената средина на канцеларија на клиент или
                    седите на аеродромска сала.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Спецификација на работното место:</Body1Strong>{" "}
                    Споделувањето на вашиот работен град прави повеќе од
                    премостување на растојания во конфигурација на оддалечен
                    тим—така исто така отвора врати за спонтани професионални
                    собири или случајно дружење. Без разлика дали станува збор
                    за брзо кафе, подолг ручек или колаборативна средба,
                    знаењето дека сте во ист град може да претвори обичен ден во
                    можност за смислени лице в лице поврзувања. Ова не само што
                    ги зајакнува врските во тимот, туку и обезбедува подлабоко
                    разбирање на локалните нијанси што може да влијаат на вашата
                    работа.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Точност на временската зона:</Body1Strong> Како
                    тимовите опфаќаат континенти, осигурувањето дека вашата
                    временска зона го одразува вашето моментално местоположение
                    е суштинско за хармонизирање на комуникациите. Вашето
                    локално време во профилот на TeamConnect директно е под
                    влијание на оваа поставка, елиминирајќи ги било какви
                    претпоставки за колегите кои се обидуваат да ве
                    контактираат.
                  </li>
                </ul>
                Загледајте се во 'Нагодувања' {">"} 'Општи нагодувања' во
                TeamConnect за точно поставување на овие детали. Неколку моменти
                поминати во ажурирање на вашите поставки можат значително да го
                зголемат кохезијата и ефикасноста на вашите колаборативни напори
                насекаде.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="40">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                40. Разјаснување на изборот на работно место во TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Избирањето на соодветни подесувања во паѓачкото мени 'Работно
                Место' го прилагодува TeamConnect за точно да го одрази вашиот
                тековен работен статус. Поминете со глушецот врз иконата за
                информации за детален објаснување на секоја опција:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Одјавен/Пауза:</Body1Strong> Означува време
                    надвор од работа, било тоа за пауза или крај на денот.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Канцеларија/Теренска канцеларија:</Body1Strong>{" "}
                    Укажува на присуство во простор управуван од компанијата.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Домашна канцеларија:</Body1Strong> Работа од
                    посветен домашен работен простор.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Далечинско работно место/Јавен простор:
                    </Body1Strong>{" "}
                    Работа од околина која не е канцеларија, што предложува
                    потреба за флексибилна комуникација.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Работно место кај клиент/Приватна канцеларија:
                    </Body1Strong>{" "}
                    На терен кај клиент, со забелешка за нивоата на приватност
                    за чувствителност на разговорите.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Патување/Службено патување/Обука:</Body1Strong>{" "}
                    Во движење или вклучен во специфични работни активности
                    надвор од канцеларијата.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Државен празник/Одмор/Различни отсуства:
                    </Body1Strong>{" "}
                    Официјално неработен поради лични, здравствени или празнични
                    причини.
                  </li>
                </ul>
                Избирањето соодветно работно место осигурува дека вашиот тим ќе
                има потребен контекст за интеракции, почитувајќи ја достапноста
                и приватноста.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="41">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                41. Управување со статусот 'Пријавен' во TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ознаката 'Пријавен' во Именскиот Директориум на вработени е
                дизајнирана за прикажување на сите вработени кои се моментално
                на работа. Оваа видливост е одредена од изборот на Работно Место
                кој го правите:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Активни работни околини:</Body1Strong> Изборите
                    Канцеларија, Теренска канцеларија, Домашна канцеларија,
                    Далечинско работно место, Јавен простор, Работно место кај
                    клиент, Приватна канцеларија кај клиент, Патување, Службено
                    патување, и Обука ве означуваат како 'Пријавен' и активно
                    видлив за колегите.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Не-работни поставки:</Body1Strong> Избирањето
                    на Одјавен, Пауза, Државен празник, Одмор, На отсуство,
                    Породилно отсуство или Боледување ги отстранува од листата
                    на 'Пријавени', поставувајќи јасни очекувања за достапност и
                    времиња за реакција.
                  </li>
                </ul>
                Оваа функционалност помага во разграничувањето на работните
                периоди од лично време, допринесувајќи кон ефективна
                колаборација и комуникација на тимот.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>
        Најчесто поставувани прашања за управување и приватност на податоци
      </Subtitle2>
      <Body1>
        <p>
          Во денешната дигитална ера, разбирањето како се управува со вашите
          податоци е круцијално. TeamConnect е посветен на транспарентност и
          строги практики за заштита на податоците. Подолу ќе најдете информации
          за тоа како ги обработуваме, чуваме и штитиме вашите податоци во
          TeamConnect, осигурувајќи дека вашата дигитална работна површина е
          сигурна и согласна со прописите.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="42">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                42. Како TeamConnect ги заштитува моите лични податоци?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <p>
                  TeamConnect е посветен на заштита на вашите лични информации
                  со целосен пристап до безбедност на податоците:
                </p>
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Единствена Најава (SSO):</Body1Strong> За
                    засилување на безбедноста и оптимизација на корисничкото
                    искуство, TeamConnect интегрира автоматизирана Единствена
                    Најава (SSO) со вашата сметка на Microsoft 365,
                    овозможувајќи безбедно и лесно управување со пристапот.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Енкрипција:</Body1Strong> Гарантираме дека сите
                    податоци во TeamConnect се шифрирани и за време на пренос и
                    додека се наоѓаат во мирување, правејќи ги непристапни за
                    неовластени страни.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Минимизација на Податоци:</Body1Strong> Нашето
                    собирање на податоци е строго ограничено на она што е
                    суштинско за функционалноста на TeamConnect. Единствените
                    информации за корисник што ги чуваме се вашето User
                    Principal Name (UPN) од Azure Entra ID, кое може да личи на
                    вашата деловна електронска адреса.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ограничен Пристап:</Body1Strong> Пристапот до
                    UPN е ограничен само за Subscription Admin на вашата
                    организација во нашиот портал за претплатници, осигурувајќи
                    дека чувствителните информации се видливи само за
                    овластените лица.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Брзо Бришење на Податоци:</Body1Strong>{" "}
                    TeamConnect е структуриран така што веднаш го брише
                    зачуваниот UPN под три специфични услови: ако претплатата е
                    откажана од Subscription Admin, ако корисник е отстранет од
                    HR во TeamConnect, или ако истече претплатата на
                    TeamConnect. Ова осигурува дека вашите лични податоци не се
                    задржуваат подолго од нивниот корисен живот или потреба.
                  </li>
                </ul>
                <p>
                  Преку овие мерки, TeamConnect се обврзува на одржување на
                  највисоките стандарди за заштита на податоци, осигурувајќи
                  дека вашите лични информации останат безбедни и
                  конфиденцијални.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="43">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                43. Се прашувате каде се наоѓаат вашите податоци со TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect го крши моделот на традиционалните SaaS понуди така
                што осигурува вашите податоци никогаш да не ја напуштат вашата
                организација. За разлика од обичната практика на чување на
                податоците на надворешни облачни сервери, TeamConnect ингениозно
                го искористува вашето Microsoft 365 SharePoint Online на вашата
                организација за да ги смести сите податоци за апликацијата. Овој
                иновативен пристап не само што ја поддржува суверенитетот на
                податоците, туку и го засилува безбедноста, совршено усогласен
                со нашата тврда посветеност кон заштита на вашата приватност и
                почитување на строгите стандарди за усогласеност.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="44">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                44. Дали моите податоци ќе бидат споделени со било кои трети
                страни?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Не, податоците не се споделуваат со трети страни! Cubeet е цврст
                во нашата посветеност кон приватноста и безбедноста на вашите
                податоци. Ние не споделуваме никакви податоци за TeamConnect со
                трети страни без претходно добиена изрична согласност од нашите
                клиенти. Во нашата безбедна средина, чуваме само основни
                идентификатори, како што се Име на главен корисник (UPN), името
                на организацијата, името на закупецот и ID на закупецот, во
                рамките на нашиот портал за претплата. Овие информации се клучни
                за обезбедување на услугата и се заштитени под нашите строги
                политики за приватност. Бидете сигурни дека нашите практики за
                управување со податоци се дизајнирани така што вашите податоци
                остануваат конфиденцијални, со транспарентност и контрола кои
                остануваат цврсто во вашите раце.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="45">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                45. Како TeamConnect се усогласува со регулативите за заштита на
                податоци како што се GDPR?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect е усогласен со регулативите за заштита на податоци
                како што се GDPR, HIPAA, CCPA и други, искористувајќи ги веќе
                вградените функции за усогласеност на Microsoft 365 средината,
                каде сите податоци на клиентите се безбедно чуваат. За разлика
                од традиционалните SaaS модели, TeamConnect не чува никакви
                податоци на клиентите на своите сервери; наместо тоа,
                функционира во рамките на вашата Microsoft 365 средина на вашата
                организација, придржувајќи се кон нејзините строги стандарди за
                безбедност и усогласеност. Овој пристап осигурува дека вашето
                управување со податоци останува усогласено со опширниот рамковен
                документ за усогласеност обезбеден од Microsoft 365, вклучувајќи
                се усогласеност со GDPR, HIPAA за информации за здравствена
                заштита, CCPA за жители на Калифорнија, и други глобални
                регулативи за заштита на податоците. Со интегрирање без проблеми
                со вашата постоечка поставка на Microsoft 365, TeamConnect им
                овозможува на вашата организација да одржува целосна контрола
                врз вашите податоци, осигурувајќи усогласеност со најстрогите
                закони и регулативи за заштита на податоци.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="46">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                46. Како TeamConnect обработува податоци од разговорите,
                повиците, видео повиците, е-маиловите од Outlook и телефонските
                повици во Microsoft Teams?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect користи Microsoft Graph API за да овозможи директни
                интеракции како што се разговори, повици, видео повици и
                е-маилови во Outlook во рамките на Microsoft Teams,
                обезбедувајќи беспрекорно корисничко искуство. Суштински, иако
                TeamConnect иницира овие комуникации, тоа го прави без да се
                меша во процесот на комуникација или да има можност да снима или
                чува содржина од интеракциите. Сите разговори, повици и
                е-маилови се одвиваат и се управуваат директно во безбедните
                рамки на Microsoft Teams и Outlook, во строго придржување кон
                ригорозните стандарди за безбедност и приватност на Microsoft
                365. Ова осигурува дека TeamConnect ги зголемува вашите
                комуникациски можности додека во целост ја зачувува поверливоста
                и интегритетот на вашите податоци.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Body1>
        <p>
          За детални информации околу нашите политики и практики за податоци,
          можеби ќе сакате да се консултирате со{" "}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>
              Раководството за корисници на TeamConnect
            </Body1Strong>
          </Link>{" "}
          или со IT одделот на вашата организација на{" "}
          <Link href={`mailto:${itSupport}`}>
            <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong>
          </Link>{" "}
          за детали поврзани со вашата поставка.
        </p>
      </Body1>
      <p></p>
      <Subtitle1 className={styles.fontColor}>
        Те слушаме: Споделете го вашето мислење и идеи
      </Subtitle1>
      <Body1>
        <p>
          Вашите впечатоци го водат развојот на TeamConnect. Било дека сте полни
          со брилијантна идеја за функција или имате повратни информации за
          вашето моментално искуство, сите сме внимателни. Вашиот придонес е
          непроценлив во нашата непрекината потрага да обезбедиме најдобра можна
          услуга.
        </p>
      </Body1>
      <Subtitle2>Повратни информации и предлози за функции</Subtitle2>
      <Body1>
        <p>
          Подготвени за споделување? Посетете ја нашата{" "}
          <Link
            style={{ fontWeight: 600 }}
            href={feebackLink}
            target='_blank'>
            Страница за повратни информации
          </Link>
          . Било дека е предлог за нова функција или повратна информација за
          постоечки функционалности, едноставно изберете соодветна опција од
          паѓачкото мени во формуларот. Вашите придонеси помагаат во
          обликувањето на иднината на TeamConnect, осигурувајќи дека ги
          исполнува и надминува вашите потреби.
        </p>
      </Body1>
    </>
  );
}

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
