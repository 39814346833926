import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },

});
function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>Centrum Pomocy i FAQ TeamConnect</Title3>
        <p><Body1>
          Witaj w Centrum Pomocy TeamConnect, zaprojektowanym, aby wspierać Twoje doświadczenia z naszą kompleksową aplikacją katalogową dla firm. Tutaj znajdziesz zasoby, które optymalizują korzystanie z TeamConnect w Microsoft Teams, zwiększając komunikację w całej Twojej sieci.
        </Body1></p>
        <Subtitle2>Przewodnik Szybkiego Dostępu</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Ulepszone Możliwości Wyszukiwania: </Body1Strong>
              Znajdź odpowiednich współpracowników, działy i niezbędne kontakty szybko dzięki naszym potężnym narzędziom wyszukiwania.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Wizualne Organigramy: </Body1Strong>
              Nawiguj po hierarchii swojej organizacji i z łatwością rozumiej linie raportowania dzięki intuicyjnym organigramom.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Dostosowane Narzędzia do Dzisiejszych Wymagań: </Body1Strong>
              Wykorzystaj nowoczesne funkcjonalności dostosowane do wymagań współczesnych miejsc pracy.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Optymalizacja Twojego Doświadczenia</Subtitle2>
        <Body1>
          <p>
            TeamConnect rewolucjonizuje sposób, w jaki wchodzisz w interakcje w strukturze swojej organizacji, przekształcając codzienną komunikację w strategiczne zaangażowanie.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Uzyskaj Pomoc i FAQ</Subtitle1>
        <Body1>
          <p>
            Znajdź odpowiedzi na często zadawane pytania dotyczące funkcji i ustawień TeamConnect w tej dedykowanej sekcji. Niezależnie od tego, czy nawigujesz po aplikacji, dostosowujesz ustawienia czy szukasz rozwiązań na problemy, nasze Centrum Pomocy jest tutaj, aby pomóc Ci efektywnie.
          </p>
        </Body1>
        <Body1>
          <p>
            Po bardziej szczegółowe wsparcie lub konkretną poradę proszę skonsultować się z <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>Przewodnikiem Użytkownika TeamConnect</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>        
        <Subtitle2>Korzystanie z FAQ TeamConnect</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Nie możesz znaleźć TeamConnect na pasku bocznym Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jeśli TeamConnect nie jest łatwo widoczny lub zniknął z paska bocznego w Microsoft Teams, możesz łatwo go przypiąć dla szybkiego dostępu. Wystarczy postępować zgodnie z tymi prostymi krokami:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Otwórz Microsoft Teams i kliknij ikonę '...' (+ Aplikacje) na dole paska bocznego.</li>
                    <li className={styles.bulletBottomMargin}>Wyszukaj 'TeamConnect' w pasku wyszukiwania na górze wyskakującego okna.</li>
                    <li className={styles.bulletBottomMargin}>Kliknij prawym przyciskiem myszy na aplikację TeamConnect z wyników wyszukiwania i wybierz 'Przypnij'.</li>
                  </ol>
                  TeamConnect zostanie teraz przypięty do twojego paska bocznego, co umożliwi dostęp do niego jednym kliknięciem!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Napotykasz problemy techniczne z TeamConnect? Oto jak uzyskać pomoc.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jeśli napotkasz jakiekolwiek problemy techniczne z TeamConnect w Microsoft Teams, zespół wsparcia IT twojej organizacji jest tutaj, by pomóc.
                  Skontaktuj się z nimi pod adresem <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, a oni niezwłocznie ci pomogą.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Nie widzisz swoich danych pracowniczych?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jeśli twój profil wydaje się pusty i zawiera tylko twoje imię oraz adres e-mail, prawdopodobnie zespół HR jeszcze nie dodał pozostałych szczegółów twojego zatrudnienia do TeamConnect.
                  Nie ma powodu do niepokoju! Szybka wiadomość do Twojego zespołu wsparcia HR rozpocznie działania. Są oni gotowi, aby uzupełnić twój profil wszystkimi ważnymi szczegółami.
                  Skontaktuj się pod adresem <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> i zobacz, jak twój profil nabiera życia!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Jakie opcje językowe są dostępne w TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect docenia globalne zróżnicowanie Microsoft Teams, wspierając
                  wszystkie jego języki. Czy preferujesz arabski czy grecki, japoński czy
                  szwedzki, masz do dyspozycji świat opcji, w tym:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arabski, bułgarski, chiński (uproszczony i tradycyjny), chorwacki,
                      czeski, duński, holenderski, angielski, estoński, fiński, francuski,
                      niemiecki, grecki, hebrajski, węgierski, indonezyjski, włoski,
                      japoński, koreański, łotewski, litewski, norweski (Bokmal), polski,
                      portugalski (Brazylia i Portugalia), rumuński, rosyjski, serbski
                      (łacina), słowacki, słoweński, hiszpański, szwedzki, tajski, turecki,
                      ukraiński, wietnamski.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Aby dostosować swoje doświadczenie z TeamConnect do preferowanego języka,
                  proszę dostosować ustawienia językowe bezpośrednio w Microsoft Teams,
                  ponieważ TeamConnect w pełni integruje się z tymi ustawieniami:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Przejdź do ustawień Microsoft Teams, klikając na trzy kropki obok
                      swojego zdjęcia profilowego na górze, a następnie wybierz 'Wygląd i
                      dostępność'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Przewiń do sekcji 'Język' i wybierz preferowany język z menu
                      rozwijanego.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Potwierdź swoje zmiany, klikając przycisk 'Zapisz i zrestartuj', aby
                      zastosować nowe ustawienia językowe.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Uwaga: </Body1Strong>
                <Body1>
                  Zmiany dokonane w ustawieniach językowych w Microsoft Teams będą również
                  płynnie aktualizować Twoje doświadczenie z TeamConnect. Należy pamiętać,
                  że zmiana języka w Teams może również dostosować format daty i czasu.
                  Obecnie, TeamConnect ewoluuje, aby obsługiwać pisanie od prawej do lewej.
                  Jesteśmy podekscytowani tymi usprawnieniami i będziemy Was informować,
                  kiedy staną się dostępne.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Jak mogę zmienić motyw w TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect synchronizuje się z Twoimi preferencjami motywu Microsoft Teams, w tym opcjami Jasny, Ciemny i Wysoki kontrast. Aby dostosować swój motyw:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Dostęp do ustawień Microsoft Teams możesz uzyskać, klikając na trzy kropki obok swojego zdjęcia profilowego na górze.</li>
                    <li className={styles.bulletBottomMargin}>Wybierz 'Wygląd i dostępność', aby przeglądać opcje motywów.</li>
                    <li className={styles.bulletBottomMargin}>Wybierz preferowany motyw spośród dostępnych opcji, aby natychmiast zaktualizować interfejs TeamConnect, aby pasował.</li>
                  </ol>
                  Twój wybór motywu w Microsoft Teams zostanie automatycznie zastosowany do TeamConnect, zapewniając spójne doświadczenie wizualne w całej przestrzeni roboczej.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Poznaj przyciski akcji kontaktu w TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  W całym TeamConnect, gdziekolwiek znajdziesz dane kontaktowe pracownika, odkryjesz również nasze wygodne przyciski szybkiego dostępu. Te przydatne narzędzia
                  są zaprojektowane, aby usprawnić komunikację w ekosystemie Microsoft, zapewniając, że zawsze jesteś tylko jednym kliknięciem od nawiązania połączenia.
                  Oto Twój kompleksowy przewodnik po efektywnym korzystaniu z tych przycisków:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona Czatu:</Body1Strong> Kliknij, aby rozpocząć natychmiastową rozmowę na czacie Microsoft Teams. Jeśli potrzebujesz wrócić do TeamConnect po rozpoczęciu czatu, po prostu użyj strzałki wstecz w aplikacji stacjonarnej, przycisku wstecz w aplikacji mobilnej lub przycisku wstecz w przeglądarce.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona Email:</Body1Strong> Otwiera nowy szkic e-maila w Outlook z gotowym adresem odbiorcy w polu "Do:". Jeśli Outlook się nie uruchomi, warto skonsultować się z działem IT w sprawie ewentualnych ograniczeń.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona połączenia wideo:</Body1Strong> Rozpoczyna wideorozmowę twarzą w twarz za pomocą Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona połączenia:</Body1Strong> Rozpoczyna połączenie głosowe za pomocą Microsoft Teams, umożliwiając szybkie dyskusje.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona wykresu organizacyjnego:</Body1Strong> Pokazuje miejsce pracownika w hierarchii organizacyjnej, w tym jego menedżera i bezpośrednich podwładnych.</li>
                    <li><Body1Strong>Ikona Więcej (...):</Body1Strong> Ujawnia dodatkowe opcje:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Spotkanie teraz:</Body1Strong> Otwiera Microsoft Teams, aby natychmiastowo rozpocząć spotkanie z pracownikiem.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Nowe spotkanie:</Body1Strong> Kieruje cię do Microsoft Teams, aby zaplanować nowe spotkanie.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Dodaj kontakt:</Body1Strong> Dodaje dane kontaktowe pracownika z TeamConnect do twojej nowej aplikacji Ludzie Microsoft Teams, wzbogacając funkcję wywołania.</li>
                      </ul>
                    </li>
                  </ul>
                  Te intuicyjne przyciski są opracowane, aby zapewnić efektywne łączenie się z kolegami, czy to na szybki czat, e-mail czy w celu zaplanowania przyszłych spotkań.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Wykonywanie połączeń za pośrednictwem TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Chcesz wykonać połączenie? TeamConnect usprawnia komunikację, integrując się z Microsoft Teams. 
                  Wystarczy, że klikniesz na żądany numer telefonu w TeamConnect, a voilà, interfejs wywołania Microsoft Teams pojawi się,
                  gotowy, abyś mógł bez problemu zadzwonić.
                  <p>
                  <Body1Strong>Uwaga: </Body1Strong> 
                  Napotykasz problemy z wykonywaniem połączeń? Może to być spowodowane politykami IT twojej organizacji lub używaniem systemu telefonicznego innego niż Microsoft Teams Phone System. Skonsultowanie się z działem IT pod <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, może rzucić światło na sprawę.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>           
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Czy są koszty związane z realizacją połączeń przez TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect poprawia jakość twoich połączeń, wykorzystując Microsoft Teams. Oto, co musisz wiedzieć o kosztach związanych z połączeniami:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Dla połączeń do telefonów służbowych pracowników, telefonów działowych lub linii alarmowych w twojej organizacji (numery wewnętrzne),
                      zazwyczaj <Body1Strong>nie ma kosztów</Body1Strong>, gdy używasz systemu telefonicznego Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Dla połączeń zewnętrznych, takich jak na telefony komórkowe czy zewnętrzne linie telefoniczne, obowiązują <Body1Strong>standardowe stawki</Body1Strong> systemu telefonicznego twojej organizacji.
                      Te stawki różnią się w zależności od twojego konkretnego planu usług z Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Uwaga:</Body1Strong> Jeśli napotkasz jakiekolwiek problemy lub masz pytania dotyczące opłat za połączenia, najlepiej jest skonsultować się z 
                    działem IT w <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> w celu uzyskania szczegółowych informacji dostosowanych 
                    do ustawień i polityk twojej organizacji.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Zauważyłeś niezgodność twojego statusu 'Nieobecny' w TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Zauważyłeś, że twój status 'Nieobecny' z Microsoft 365 nie odzwierciedla się poprawnie w TeamConnect? 
                  Mamy to pokryte. Funkcja synchronizacji statusu 'Nieobecny' została już zaimplementowana w TeamConnect.
                  Obecnie czekamy na wdrożenie tej funkcji przez Microsoft. Gdy to nastąpi, twój status 'Nieobecny' będzie dokładnie odzwierciedlony w TeamConnect, 
                  zgodnie z wskaźnikami obecności Microsoft Teams dla płynnego doświadczenia.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>Najczęściej zadawane pytania dotyczące Katalogu Pracowników</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Wprowadzenie do Katalogu Pracowników:</Body1Strong> Nawiguj w zawodowym krajobrazie twojej organizacji dzięki Katalogowi Pracowników,
                twojemu centralnemu punktowi dostępu do szczegółowych informacji o pracownikach, dynamikach miejsca pracy oraz bezpośredniej łączności z profilami i relacjami managerskimi twoich kolegów.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Potrzebujesz dodać, zaktualizować lub poprawić swoje dane pracownicze?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Aby dodać, zaktualizować lub skorygować swoje dane pracownicze w TeamConnect, skontaktuj się z zespołem wsparcia HR twojej organizacji.
                  Są gotowi pomóc pod <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Co się dzieje, gdy kliknę na imię pracownika w katalogu?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Kliknięcie na imię pracownika w Katalogu Pracowników TeamConnect otwiera szczegółową stronę profilową danego pracownika. Profil ten dostarcza kompleksowe 
                  informacje o pracowniku, w tym między innymi nazwisko, stanowisko, dział, lokalizację biura, informacje kontaktowe i więcej. Jest zaprojektowany, aby 
                  zapewnić pełen przegląd roli zawodowej pracownika i sposobu, w jaki wpisuje się on w strukturę organizacji, co ułatwia zrozumienie jego obowiązków oraz 
                  to, jak możesz z nim współpracować.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Jakie informacje zawiera profil pracownika?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Każdy profil pracownika w TeamConnect jest kompleksowym zestawieniem, oferującym bogaty wgląd w jego życie zawodowe. Oto, co znajdziesz:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Szczegóły Podstawowe:</Body1Strong> Imię i nazwisko, stanowisko, dział, lokalizacja filii.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Struktura Raportowania:</Body1Strong> Informacje o bezpośrednich i funkcjonalnych raportach, 
                      w tym kierownicy liniowi i funkcjonalni.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Status w Miejscu Pracy:</Body1Strong> Aktualny status pracy, takie jak Urlop, Dojazdy, 
                      Podróże Służbowe, itp.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Miejsce Pracy & Lokalny Czas:</Body1Strong> Aktualna lokalizacja pracy pracownika i 
                      lokalna strefa czasowa.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Dane Kontaktowe:</Body1Strong> Telefon służbowy, numer komórkowy i adres e-mail.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Adres Biura:</Body1Strong> Kompletna lokalizacja biura, w tym nazwa budynku,
                      adres i mapa Google.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>O Sobie:</Body1Strong> Krótka osobista introdukcja lub profesjonalna biografia.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Relacje Pracownicze:</Body1Strong> Szczegóły struktury raportowania pracownika i relacji w zespole.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Linki Społecznościowe & Języki:</Body1Strong> Linki do profesjonalnych profilów społecznościowych 
                      i znajomość języków.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Umiejętności:</Body1Strong> Lista umiejętności zawodowych i obszarów ekspertyzy.</li>
                  </ul>
                  Ponadto, profil oferuje możliwość nawiązania kontaktu poprzez chat, e-mail, wideorozmowę lub zaplanowanie spotkań, a także szybki dostęp do schematu organizacyjnego, co pozwala lepiej zrozumieć kontekst zespołu.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Jak mogę zaktualizować informacje w moim profilu?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Personalizacja Twojego profilu w TeamConnect jest prosta. Oto jak możesz to zrobić:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Przejdź do zakładki <Body1Strong>Pracownik</Body1Strong> i kliknij na swój <Body1Strong>Avatar</Body1Strong>, aby otworzyć swój profil.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        W lewym górnym rogu znajdziesz link <Body1Strong>"Edytuj swój profil"</Body1Strong>. 
                        Kliknięcie otworzy okno edycji Twojego profilu.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Tutaj możesz ulepszyć swój profil, dodając lub aktualizując sekcję <Body1Strong>O Tobie</Body1Strong>, linki do mediów społecznościowych i numer telefonu. 
                        To ułatwia Twoim kolegom z pracy nawiązanie kontaktu lub obserwowanie Cię.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Nie zapomnij nacisnąć przycisku <Body1Strong>Zapisz</Body1Strong>, aby zastosować zmiany.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Przewiń w dół dialogu, aby dodać swoje <Body1Strong>Języki</Body1Strong> i <Body1Strong>Poziom biegłości</Body1Strong>, 
                        jak również Twoje <Body1Strong>Umiejętności</Body1Strong>. Udostępnianie tych informacji pomaga Twoim kolegom zrozumieć 
                        Twoje obszary kompetencji i języki, w których możesz się porozumiewać.
                      </li>
                    </ol>
                  Aktualizacja Twojego profilu nie tylko utrzymuje Twoje informacje na bieżąco, ale także sprzyja lepszym relacjom w organizacji.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Aktualizacja miejsca pracy, stanowiska i strefy czasowej w TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Zachowanie aktualnych informacji o miejscu pracy, stanowisku i strefie czasowej jest kluczowe dla płynnej współpracy w Microsoft Teams. 
                  Te aktualizacje są odzwierciedlane natychmiast w Twoim profilu i wpisach TeamConnect, co pozwala kolegom szybko zrozumieć Twoje miejsce pracy 
                  i najlepsze momenty na kontakt, poprawiając współpracę przez różne regiony i strefy czasowe.
                  <p></p>
                  Gotowy na aktualizację swoich szczegółów? To proste w TeamConnect! Udaj się do 'Ustawienia' w górnym Menu i przejdź do 'Ustawienia ogólne'. 
                  Tutaj możesz łatwo zaktualizować swoje Miejsce pracy i Strefę czasową oraz określić swoje Stanowisko na dzień. Pamiętaj, aby zapisać swoje zmiany,
                  aby informować wszystkich i zapewnić skuteczną współpracę, niezależnie od Twojej lokalizacji!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Zauważyłeś coś niepokojącego w profilu kolegi?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jeśli natrafisz na jakiekolwiek szczegóły w profilu kolegi, które wydają się nie być w porządku, najlepszym pierwszym krokiem jest dać mu znać w przyjazny sposób. 
                  Szybka rozmowa lub e-mail powinny załatwić sprawę! Jeśli z jakiegoś powodu informacje nie zostaną zaktualizowane, nie martw się—wystarczy, że dasz znać swojemu zespołowi wsparcia HR 
                  na adres <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, a oni z chęcią wkroczą i wszystko uporządkują.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Zrozumienie grup tagów: Wszyscy, Ulubione, Zalogowani & Nowi Pracownicy
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Wszyscy:</Body1Strong> Domyślny tag, który wyświetla wszystkich pracowników w organizacji,
                      zapewniając kompleksowy widok zespołu.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ulubione:</Body1Strong> Kliknij tutaj, aby filtrować widok osób, które oznaczyłeś jako 'Ulubione',
                      co ułatwia szybkie nawiązywanie kontaktu z kluczowymi osobami.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zalogowani:</Body1Strong> Ten tag wskazuje, że pracownicy są aktywnie w pracy, odróżniając
                      ich od samej obecności online w Microsoft Teams. Gdy są zalogowani, pracownicy sygnalizują, że są w pracy i dzielą się swoją aktualną lokalizacją 
                      pracy w ciągu dnia. Może to obejmować różne miejsca pracy, takie jak Biuro, Biuro Polowe, Biuro Domowe, Miejsce Pracy Zdalnej, Miejsce Pracy Klienta, 
                      Prywatne Biuro Klienta, Publiczna Przestrzeń, Dojazd, Podróże Służbowe, a nawet Szkolenia. Dlatego status 'Zalogowani' daje jasny sygnał,
                      że są aktywnie w pracy, oferując kolegom dokładne zrozumienie obecnego kontekstu pracy i dostępności każdego z nich.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nowi Pracownicy:</Body1Strong> Zauważ nowe twarze w TeamConnect dzięki temu tagowi, które podkreśla
                      pracowników, którzy zostali dodani do aplikacji w ciągu ostatnich 30 dni. Zwykle są to niedawne dodatki do twojej organizacji, pomagające
                      przywitać nowych członków zespołu.
                    </li>
                  </ul>
                  Te grupy tagów są zaprojektowane tak, aby usprawnić nawigację i wzbogacić Twoje doświadczenia z TeamConnect, zapewniając zawsze
                  możliwość szybkiego odnalezienia i skontaktowania się z kolegami.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Rozszyfrowanie ikon obok pola wyszukiwania
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Katalog pracowników TeamConnect jest zaprojektowany z myślą o wszechstronności i łatwości użytkowania, zawierając cztery ikony, które oferują różne widoki
                  i szybki dostęp do kluczowych szczegółów. Oto co odsłania każda ikona:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Widok listy:</Body1Strong> Twój główny widok, prezentujący wszystkich członków organizacji
                      w prostej liście.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Widok małych kart:</Body1Strong> Kompaktowa alternatywa, ta opcja przekształca
                      listę w niewielkie karty pracowników.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Widok dużych kart:</Body1Strong> Wybierz więcej szczegółów z większymi kartami, które zawierają
                      dodatkowe opcje kontaktowe, takie jak numery telefonów stacjonarnych i komórkowych, ułatwiające wybieranie numeru.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Schemat organizacyjny:</Body1Strong> Zobacz strukturę firmy w widoku schematu organizacyjnego,
                      idealnym do zrozumienia relacji raportowania i nawigacji po krajobrazie organizacyjnym.</li>
                  </ol>
                    Przełączaj się między tymi ikonami, aby znaleźć widok, który najlepiej odpowiada Twoim aktualnym potrzebom, niezależnie od tego, czy szukasz szybkich informacji, czy głębszych wglądów.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Gdzie szukać kolegi w przypadku pilnych poszukiwań?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Szukasz kolegi? Po prostu udaj się do pola wyszukiwania w TeamConnect, które znajduje się w prawym górnym rogu obok ikony schematu organizacyjnego.
                  Zacznij wpisywać ich imię, a wyniki będą się precyzować z każdą literą. Pamiętaj, aby w poszukiwaniach w TeamConnect pominąć pasek wyszukiwania Microsoft Teams
                  na górze — nasze specjalne pole wyszukiwania to właściwy sposób!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Jak działa alfabetyczne wyszukiwanie przy znajdowaniu pracowników?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Katalog Pracowników TeamConnect zawiera przydatną funkcję alfabetycznego wyszukiwania, która pomaga szybko lokalizować pracowników po imieniu. 
                  Wystarczy kliknąć na literę z alfabetu wyświetloną na górze katalogu, a TeamConnect natychmiast pokaże wszystkich pracowników,
                  których imiona zaczynają się na tę literę. Aby powrócić do pełnej listy pracowników, wystarczy odznaczyć zaznaczoną literę. Ta intuicyjna
                  funkcja ułatwia nawigację przez duże listy pracowników, co pozwala na szybkie i efektywne znajdowanie współpracowników.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Jak mogę dostosować liczbę pracowników wyświetlanych na stronę?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect umożliwia dostosowanie liczby profili pracowników wyświetlanych na stronie, co ułatwia nawigację po Katalogu Pracowników. 
                  Na dole każdej strony znajdziesz opcje paginacji: 10, 50, 100, 500, 1000 i 5000. Domyślnie ustawione jest wyświetlanie 10 profili na stronę. 
                  Aby wyświetlić więcej profili na jednej stronie, wystarczy wybrać preferowaną liczbę z tych opcji. Ta funkcja zapewnia elastyczność w przeglądaniu 
                  katalogu, umożliwiając dostosowanie wyświetlania do konkretnych potrzeb lub preferencji.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Jak mogę sortować informacje o pracownikach w widoku listy?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  W widoku listy Katalogu Pracowników TeamConnect masz możliwość sortowania informacji o pracownikach zgodnie z własnymi potrzebami. Kolumny—Imię i Nazwisko Pracownika, 
                  Stanowisko, Dział, Lokacja Biura i Miejsce Pracy—są sortowalne. Wystarczy kliknąć na nagłówek kolumny, według której chcesz sortować. Jedno kliknięcie posortuje 
                  kolumnę w kolejności rosnącej, a kolejne kliknięcie posortuje ją w kolejności malejącej. Domyślnie katalog jest sortowany według Imienia i Nazwiska Pracownika w 
                  kolejności rosnącej, na podstawie pierwszego imienia. Ta funkcja sortowania pomaga szybko organizować i przeglądać szczegóły dotyczące pracowników w sposób, który 
                  najlepiej służy Twojemu celowi, czy to szukasz kogoś konkretnego, czy musisz pogrupować pracowników według określonego kryterium, takiego jak dział czy lokalizacja.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Często Zadawane Pytania o Katalog Wydziałów</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Wstęp do Katalogu Wydziałów:</Body1Strong> Katalog Wydziałów to Twoja brama do zrozumienia złożonej struktury Twojej 
              organizacji, oferująca jasny widok hierarchii departamentów, przywództwa kierowniczego oraz możliwości łatwej komunikacji międzywydziałowej.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Jak działają tagi 'Wszystkie', 'Ulubione' i 'Twój Wydział' w Katalogu Wydziałów?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  W Katalogu Wydziałów TeamConnect tagi pomagają szybko nawigować i filtrować informacje o wydziałach. Oto co reprezentuje każdy tag:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Wszystkie:</Body1Strong> Ten tag wyświetla wszystkie wydziały w Twojej organizacji, 
                      dostarczając kompleksowy przegląd.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ulubione:</Body1Strong> Użyj tego tagu, aby wyświetlić spersonalizowaną listę wydziałów, 
                      które oznaczyłeś jako 'Ulubione', co ułatwia dostęp do wydziałów, z którymi często współpracujesz.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Twój Wydział:</Body1Strong> Ten tag filtruje widok tak, aby pokazać tylko Twój wydział, 
                      umożliwiając szybki dostęp do bezpośrednich informacji i aktualizacji dotyczących Twojego wydziału.</li>
                  </ul>
                  Te tagi są zaprojektowane, aby usprawnić Twoją nawigację w Katalogu Wydziałów, zwiększając Twoją zdolność do szybkiego znajdowania i angażowania się w 
                  informacje wydziałowe.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Zrozumienie Widoków Katalogu Wydziałów w TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nawigacja po Katalogu Wydziałów w TeamConnect jest usprawniona dzięki wielu opcjom widoków, dostosowanym do Twoich preferencji. Każdy widok oferuje unikalny 
                  sposób na eksplorację kompleksowych informacji o wydziałach, w tym Nazwa Wydziału, Kierownik Wydziału, Wydział Nadrzędny, Telefon Wydziału, Gorąca Linia 
                  Wydziału i Email Wydziału. Oto przegląd dostępnych widoków:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Widok Listy:</Body1Strong> Domyślna konfiguracja, która starannie wyświetla wszystkie wydziały, 
                      zapewniając przejrzysty, zwięzły przegląd.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Widok Dużej Karty:</Body1Strong> Przełącz się na ten widok, aby zobaczyć szczegółowe karty 
                      wydziałów, oferujące rozszerzone podsumowanie kluczowych informacji każdego wydziału.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Widok Organigramu:</Body1Strong> Dla wizualnego przedstawienia struktury i relacji wydziałowych, 
                      widok organigramu ilustruje, jak każdy wydział wpisuje się w szerszą hierarchię organizacyjną.
                    </li>
                  </ul>
                  Aby przełączać się między tymi widokami, wystarczy użyć ikon obok pola wyszukiwania na górze Katalogu Wydziałów. Każdy widok jest zaprojektowany, by pomóc Ci 
                  uzyskać dostęp i zrozumieć szczegóły wydziałowe z łatwością, zapewniając, że znajdziesz potrzebne informacje w formacie, który najlepiej Ci odpowiada.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Jak korzystać z pola wyszukiwania w Katalogu Działów
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Bezproblemowo zlokalizuj dowolny dział w TeamConnect za pomocą dedykowanego pola wyszukiwania umieszczonego tuż obok ikon widoku na górze 
                  Katalogu Działów. Wystarczy, że zaczniesz wpisywać nazwę działu, którego szukasz, i obserwuj, jak katalog dostosowuje się, wyświetlając tylko 
                  odpowiednie dopasowania. Ta funkcja została specjalnie zaprojektowana, aby pomóc Ci ominąć konieczność ręcznego przeszukiwania całego katalogu, 
                  dostarczając potrzebny dział bezpośrednio pod Twoje palce. Pamiętaj, że ta funkcjonalność wyszukiwania jest unikatowa dla Katalogu Działów 
                  TeamConnect, więc upewnij się, że używasz tego pola wyszukiwania zamiast głównego wyszukiwania Microsoft Teams na samej górze interfejsu Teams 
                  dla zapytań specyficznych dla działu.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Jak działa filtr alfabetyczny w Katalogu Działów?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Usprawnij swoje wyszukiwanie w Katalogu Działów za pomocą filtra alfabetycznego znajdującego się na górze. Ta funkcja pozwala szybko zawęzić 
                  działy, wybierając literę. Po wybraniu litery, TeamConnect filtruje i wyświetla tylko te działy, których nazwy zaczynają się na wybraną literę, 
                  co ułatwia znalezienie tego, czego szukasz, bez konieczności przewijania całej listy. Aby powrócić do pełnego widoku działów, wystarczy odznaczyć 
                  wyróżnioną literę. Ta intuicyjna nawigacja alfabetyczna zapewnia efektywne lokalizowanie dowolnego działu po jego początkowej literze.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Jak mogę skontaktować się z kierownikiem działu?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Skontaktowanie się z kierownikiem działu w TeamConnect jest proste. Wystarczy, że znajdziesz dział, który Cię interesuje, i klikniesz na nazwisko 
                  kierownika. Spowoduje to wyświetlenie okienka z opcjami kontaktu kierownika z znanymi ikonami szybkiego dostępu:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikonka Czatu:</Body1Strong> Aby rozpocząć natychmiastową wiadomość przez czat Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikonka Email:</Body1Strong> Aby otworzyć nowy email skierowany do nich w Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikonka Wideorozmowy:</Body1Strong> Aby rozpocząć wideorozmowę przez Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikonka Połączenia:</Body1Strong> Aby rozpocząć rozmowę głosową za pomocą Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikonka Więcej (...):</Body1Strong> Dla dodatkowych opcji jak planowanie spotkań czy dodawanie 
                      ich informacji kontaktowych do Twojej listy Teams.
                    </li>
                  </ul>
                  Te ikony zapewniają płynny sposób na bezpośredni kontakt z kierownikami działów bezpośrednio z poziomu TeamConnect, co zapewnia efektywną komunikację 
                  wewnątrz organizacji.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Jak mogę przeglądać lub kontaktować się z pracownikami w ramach działu?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Aby zbadać, kto jest kim w dziale w TeamConnect, wystarczy spojrzeć na trzy kropki obok nazwy działu. Kliknięcie w nie 
                  otworzy opcje dalszych działań:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Wyświetl Pracowników tego Działu:</Body1Strong> Wybierz tę opcję, aby wyświetlić listę wszystkich 
                      pracowników powiązanych z działem. Liczba obok tej opcji wskazuje, ilu pracowników znajduje się w dziale, dając szybki przegląd stanu osobowego.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organigram Działu:</Body1Strong> Jeśli chcesz zrozumieć hierarchię działu, 
                      wybierz 'organigram działu', aby odkryć strukturę organizacyjną, prezentującą związki między działami nadrzędnymi, omawianym działem, 
                      oraz wszelkimi działami podrzędnymi. Ta wizualna reprezentacja pomoże Ci nawigować i zrozumieć strukturę organizacyjną, ułatwiając identyfikację i 
                      kontaktowanie się z poszczególnymi członkami działu.
                    </li>
                    <li><Body1Strong>Dział {'>'} Pracownik:</Body1Strong> Po wyświetleniu pracowników działu, użyj tej ścieżki nawigacyjnej, aby łatwo 
                      wrócić do listy działów, utrzymując płynne doświadczenie eksploracji w TeamConnect.
                    </li>
                  </ul>
                  Te funkcje pozwalają nie tylko zobaczyć listę pracowników w danym dziale, ale także oferują ścieżkę do bezpośredniego kontaktu z nimi za pomocą narzędzi komunikacyjnych TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Odkrywanie szczegółów działu w TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Zanurz się w szczegóły dowolnego działu, klikając na jego nazwę w TeamConnect. Rozwija się szczegółowy dialog, oferujący bogate spektrum informacji
                  rysujących pełny obraz roli i zasobów działu w Twojej organizacji. Znajdziesz tam wszystko od liderów i identyfikatorów finansowych po kanały komunikacyjne i linki społecznościowe. Ta funkcja jest Twoją bramą do:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Serce działu – jego cel, ludzie i wskaźniki wydajności.</li>
                    <li className={styles.bulletBottomMargin}>Ścieżki kontaktowe – bezpośrednie linie do kluczowych postaci w dziale oraz platformy komunikacyjne.</li>
                    <li className={styles.bulletBottomMargin}>Sieć organizacyjna – zrozumienie, jak dział integruje się z szerszą strukturą firmy.</li>
                  </ul>
                  Niezależnie od tego, czy jest to szybkie zapytanie, czy głębokie zrozumienie danych działu, TeamConnect wyposaża Cię w narzędzia, które umożliwiają kontakt z i zrozumienie unikalnych ekosystemów w Twojej organizacji.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Ciekawostka na temat braku adresów działów w TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Odkrywanie lokalizacji działu czasami może przypominać poszukiwanie skarbu bez mapy. W TeamConnect zwykle nie znajdziesz dedykowanego adresu dla każdego działu. Oto dlaczego ten kartograficzny szczegół często jest pomijany:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Wiele Lokalizacji:</Body1Strong> Wiele działów nie jest ograniczonych do jednego miejsca; prosperują w różnych biurach i geografiach.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Płynna Struktura:</Body1Strong> Krajobraz organizacyjny jest ciągle zmieniający się, z działami, które ewoluują, łączą się lub nawet rozwiązują w zgodzie ze strategicznymi zmianami.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Współpraca Międzydziałowa:</Body1Strong> Nowoczesne przestrzenie pracy brzęczą duchem współpracy, często łącząc talenty z różnych działów w wspólnych przestrzeniach.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kultura Pracy Zdalnej:</Body1Strong> Dzięki elastyczności pracy zdalnej, kotwice działowe nie zawsze są zrzucane w fizycznych lokalizacjach biurowych.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nuansy Prawne:</Body1Strong> Działy zwykle orbitują wokół struktury prawnej firmy, a nie mają własnych niezależnych adresów.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Racjonalizacja Operacyjna:</Body1Strong> Skupienie się na dynamicznej pracy zespołowej, a nie na statycznych adresach, pomaga utrzymać operacyjną zwinność.
                    </li>
                  </ul>
                  Chodzi o przyjęcie podejścia bez granic do pracy zespołowej i efektywności. TeamConnect odzwierciedla tę filozofię, kładąc nacisk na połączenia, a nie lokalizacje.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Czy brakuje informacji o Twoim dziale lub są one nieaktualne?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Edytorzy działów są wyznaczani w Twojej organizacji do dodawania, aktualizowania lub poprawiania specyficznych informacji o działach w TeamConnect.
                  Jeśli nie wiesz, kto jest edytorem Twojego działu, lub jeśli pewne informacje wymagają interwencji zespołu wsparcia HR,
                  proszę skontaktuj się z <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> w celu uzyskania wskazówek.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>FAQ Książki Adresowej Biura</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Wprowadzenie do Książki Adresowej Biura:</Body1Strong> Poznaj trzon fizycznej obecności Twojej organizacji dzięki Książce Adresowej,
                która obejmuje istotne szczegóły dotyczące biur, specyfikę operacyjną oraz kompleksowy przegląd działów i personelu definiujących każdą lokalizację.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Nawigowanie po tagach w Książce Adresowej Biura
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Poruszaj się po Książce Adresowej Biura z łatwością, korzystając z naszego systemu tagów zaprojektowanego do szybkiego dostępu do odpowiedniej lokalizacji. Oto jak każdy tag może ułatwić Twoje poszukiwania:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Wszystkie:</Body1Strong> Wyświetla kompletną listę biur, zapewniając perspektywę ogólną na lokalizacje Twojej organizacji.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ulubione:</Body1Strong> Pomaga skupić się na tych lokalizacjach biurowych, które często odwiedzasz lub musisz śledzić, poprzez oznaczenie ich jako 'Ulubione'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Twoje Biuro:</Body1Strong> Ten osobisty tag filtruje katalog, aby pokazać Ci lokalizację biura, w którym jesteś stacjonarny, trzymając Twoją bazę w zasięgu ręki.
                    </li>
                  </ul>
                  Niezależnie od tego, czy rezerwujesz salę konferencyjną, czy planujesz wizytę, te tagi oferują spersonalizowany i uproszczony sposób interakcji z geograficznym krajobrazem Twojej organizacji.
                </Body1>
              </AccordionPanel>
            </AccordionItem>           
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Opanowanie widoków katalogów biur w TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Zanurz się w organizacyjnym układzie różnych lokalizacji twojej firmy za pomocą widoków Katalogu Biur w TeamConnect. Każdy widok jest zaprojektowany tak, aby ułatwić 
                  unikalny aspekt nawigacji i zrozumienia biura:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Widok Listy:</Body1Strong> Domyślne ustawienie, które uporządkowuje wszystkie lokalizacje biur w 
                      łatwą do przeczytania listę.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Widok Dużej Karty:</Body1Strong> Widok Dużej Karty w Katalogu Biur wzbogaca twoje 
                      spojrzenie o szczegółową prezentację każdego biura. Podczas interakcji z kartą biura znajdziesz bogactwo informacji i opcji możliwych do wykonania 
                      w menu "Więcej (...)":
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Wyświetl Działy:</Body1Strong> Bezpośrednio przejdź do listy
                          wszystkich działów znajdujących się w biurze.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Wyświetl Pracowników:</Body1Strong> Uzyskaj pełną listę pracowników przypisanych do 
                          biura, co ułatwia bezpośredni kontakt lub eksplorację personelu biura.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Org Chart Podmiotu Gospodarczego:</Body1Strong> Zobacz miejsce biura w szerszym 
                          kontekście organizacyjnym dla jasnego zrozumienia struktur hierarchicznych.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Ważne Kontakty:</Body1Strong> Zidentyfikuj kluczowe kontakty biurowe dla usprawnionej 
                          komunikacji.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Święta Publiczne Biura:</Body1Strong> Bądź na bieżąco z świętami publicznymi specyficznymi dla biura, aby odpowiednio planować swoje harmonogramy.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Godziny Pracy Biura:</Body1Strong> Zobacz godziny działania biura, aby dostosować swoje 
                          wizyty lub rozmowy do ich aktywnych czasów.
                        </li>
                      </ol>
                      Ten widok dostarcza nie tylko wizualnego i informacyjnego migawki, ale również szybki dostęp do głębszych wglądów i bezpośrednich działań związanych z biurem, wszystko starannie ukryte pod opcją "Więcej (...)" dla twojej wygody.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Widok Org Chart:</Body1Strong> Zobacz hierarchiczną strukturę biur nadrzędnych i podrzędnych 
                      aby zrozumieć wzajemne powiązania różnych lokalizacji biur w twojej organizacji.
                    </li>
                  </ul>
                  Przełączaj się między tymi widokami za pomocą ikon obok pola wyszukiwania, aby dostosować swoje doświadczenia przeglądania do bieżących potrzeb.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Szybkie znajdowanie biur z funkcją wyszukiwania TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Szybkie odnalezienie odpowiedniej lokalizacji biura w TeamConnect jest możliwe dzięki dedykowanej funkcji wyszukiwania. Umieszczone wyraźnie obok ikon widoku, pole wyszukiwania to twoje narzędzie do lokalizowania biur po nazwie. W miarę wpisywania, katalog dostosowuje wyświetlanie, aby pokazać tylko pasujące biura, co usprawnia proces wyszukiwania. Ważne jest, aby korzystać z tego pola wyszukiwania w TeamConnect dla najdokładniejszych i najbardziej trafnych wyników, w przeciwieństwie do ogólnego wyszukiwania w Microsoft Teams. Dzięki tej ukierunkowanej funkcjonalności wyszukiwania, możesz szybko nawigować do dowolnego biura w sieci twojej organizacji.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Przeglądanie alfabetycznego spisu biur
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jeśli wolisz szukać alfabetycznie, nasz filtr alfabetyczny to szybki sposób, aby znaleźć biura według nazwy. Na górze Katalogu Biur zobaczysz litery od A do Z. Kliknięcie na literę filtruje widok, pokazując tylko te biura, które zaczynają się na tę literę, co upraszcza proces wyszukiwania. Aby zresetować i zobaczyć ponownie wszystkie biura, wystarczy odznaczyć aktywną literę. Ta funkcja jest szczególnie użyteczna, gdy znasz nazwę biura, ale potrzebujesz szybkiego sposobu, aby je zlokalizować w TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Odkrywanie kompleksowych profili biur w TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Rozpocznij podróż eksploracyjną przez serce fizycznej obecności Twojej organizacji z profilami biur w TeamConnect. Kliknięcie na nazwę biura otwiera dialog profilu, pełen zakładek z dokładnymi informacjami o biurze:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Przegląd:</Body1Strong> Zanurz się w rdzeń tożsamości biura z dokładnymi opisami, od struktury biznesowej po godziny otwarcia i lokalny czas, uchwycenie istoty środowiska biurowego.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Podstawowe kontakty:</Body1Strong> Uzyskaj dostęp do kluczowych kontaktów do służb ratunkowych i zewnętrznego wsparcia, każdy dostępny jednym kliknięciem przez system telefonów Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontakty biurowe:</Body1Strong> Łatwo nawiąż kontakt z wewnętrznymi usługami wsparcia, z danymi kontaktowymi i opcjami bezpośredniej interakcji na wyciągnięcie ręki.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Święta publiczne:</Body1Strong> Bądź na bieżąco z lokalnymi świętami biurowymi, zapewniając, że Twoje plany są zgodne z lokalnymi obserwacjami i harmonogramami pracy.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Godziny pracy:</Body1Strong> Planuj swoje interakcje z precyzyjną wiedzą o tym, kiedy biuro tętni życiem lub kiedy kończy dzień.
                    </li>
                  </ul>
                  Niezależnie od tego, czy to energia tętniącego życiem Austin czy strategiczne centrum Singapuru, każda zakładka oferuje bramę do zrozumienia i połączenia się z różnorodnymi lokalizacjami, które tworzą globalną obecność Twojej firmy.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Zagłębianie się w dynamikę działów w biurze
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nawigowanie po sieci zawodowej działów biura odbywa się łatwo w TeamConnect. Oto jak zagłębić się w szczegóły:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Wyświetl Działy w Tym Biurze:</Body1Strong> Odkryj wszystkie działy, które tworzą
                      strukturę biura, wybierając tę opcję z menu Więcej (...).
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Wgląd Organizacyjny:</Body1Strong> Kliknij na dowolny dział, aby ujawnić jego schemat organizacyjny dla
                      wizualnej reprezentacji jego struktury, z ścieżką nawigacyjną prowadzącą cię: Biuro {'>'} Dział {'>'} Schemat.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zagłębianie się w Dział:</Body1Strong> Dokładniej zbadaj dział, klikając na jego
                      menu Więcej (...) obok nazwy działu. Tutaj możesz uzyskać dostęp do listy wszystkich pracowników w tym dziale, w kontekście biura.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nawigacja Breadcrumb:</Body1Strong> Przejedź przez poziomy bezproblemowo. Od
                      pracowników z powrotem do działów lub z diagramu organizacyjnego, ścieżka nawigacyjna (Biuro {'>'} Dział {'>'} Pracownik {'>'} Schemat) utrzymuje twoją
                      podróż intuicyjną i na właściwych torach.
                    </li>
                  </ul>
                  Skomplikowane ścieżki TeamConnect zapraszają cię do eksploracji, zrozumienia i angażowania się na każdej warstwie działów twojego biura, z pełnymi uprawnieniami 
                  do nawigacji.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Łączenie z personelem biurowym w TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ciekawy, kto stoi za biurem? Odpowiedź jest na wyciągnięcie ręki w TeamConnect. Zacznij od ikony trzech kropek obok wybranej nazwy biura, aby ujawnić pakiet interaktywnych opcji:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Wyświetl Pracowników w Tym Biurze:</Body1Strong> Odkryj listę wszystkich profesjonalistów 
                      uznających to biuro za swoje miejsce pracy. Wyświetlona liczba oferuje szybkie podsumowanie pracowników, zapraszając cię do zgłębienia społeczności biurowej.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Opcje Kontaktowe:</Body1Strong> Każda pozycja pracownika jest wyposażona w ikony szybkich działań,
                      pozwalając na rozpoczęcie czatów, rozmów telefonicznych, a nawet wideokonferencji bezpośrednio z TeamConnect, wspierając płynną komunikację wewnątrz biura.
                    </li>
                    <li><Body1Strong>Nawigacja:</Body1Strong> Aby wrócić do szerszej listy biur z listy pracowników, wystarczy kliknąć 'Biuro' w ścieżce nawigacyjnej (Biuro {'>'} Pracownik), 
                      a zostaniesz przeniesiony z powrotem do przeglądu biura.
                    </li>
                  </ul>
                  Dzięki tym funkcjom, TeamConnect ułatwia wizualizację rozkładu siły roboczej w biurach i umożliwia łatwe nawiązywanie kontaktów,
                  wspierając połączone miejsce pracy, bez względu na odległość.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Czy informacje o Twoim biurze są nieaktualne lub brakujące?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Aktualizacja informacji biurowych w TeamConnect może być obsługiwana przez przypisanych edytorów biura. Jeśli nie jesteś pewien co do edytorów biura lub
                  potrzebujesz pomocy w kwestiach należących do działu HR, zespół wsparcia HR jest gotowy do
                  pomocy pod adresem <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>FAQ dotyczące ustawień</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Znaczenie dokładnych Ustawień Ogólnych w TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  W dynamicznym środowisku pracy dzisiejszych czasów, utrzymanie połączenia i koordynacji jest ważniejsze niż kiedykolwiek. Dlatego aktualizacja twoich
                  Ustawień Ogólnych w TeamConnect odgrywa kluczową rolę:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Wybór miejsca pracy:</Body1Strong> Uchwyć istotę swojego miejsca pracy, aktualizując swoje Miejsce Pracy, aby odzwierciedlało twoją
                        obecną sytuację, czy to zmiana z ciszy Home-Office na zgiełk głównego biura, czy osiedlenie się w Publicznej Przestrzeni. 
                        Ta jasność w lokalizacji pracy oferuje kolegom wgląd nie tylko w najlepsze sposoby skontaktowania się z tobą, ale także w rodzaj
                        rozmów, w które mogą się z tobą zaangażować, chroniąc poufność wrażliwych dyskusji, zwłaszcza gdy jesteś w otwartym
                        środowisku biura klienta lub siedzisz w poczekalni na lotnisku.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Specyfikacja miejsca pracy:</Body1Strong> Udostępnienie twojego miasta pracy robi więcej, niż tylko zbliża na odległość w zdalnym zespole
                        —otwiera także drzwi do spontanicznych spotkań zawodowych lub luźnych spotkań towarzyskich. Niezależnie od tego, czy to szybka kawa, 
                        spokojny lunch, czy wspólne spotkanie, wiedza, że ​​znajdujesz się w tym samym mieście, może zmienić zwykły dzień w okazję do znaczących
                        osobistych połączeń. To nie tylko wzmacnia więzi zespołowe, ale także zapewnia głębsze zrozumienie lokalnych niuansów, które mogą wpływać na twoją pracę.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Dokładność strefy czasowej:</Body1Strong> W miarę jak zespoły obejmują kontynenty, zapewnienie, że twoja strefa czasowa odzwierciedla
                      twoją obecną lokalizację jest niezbędne dla harmonizowania komunikacji. Lokalny czas w profilu TeamConnect jest bezpośrednio zależny od tego ustawienia,
                      eliminując wszelkie domysły dla kolegów starających się skontaktować z tobą.
                    </li>
                  </ul>
                  Zanurz się w 'Ustawienia' {'>'} 'Ustawienia ogólne' w TeamConnect, aby dostosować te szczegóły. Kilka chwil spędzonych na aktualizacji twoich ustawień może
                  znacząco zwiększyć spójność i efektywność wspólnych wysiłków na każdym poziomie.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          <AccordionItem value="40">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                40. Rozszyfrowywanie wyboru miejsca pracy w TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Wybór odpowiedniego ustawienia w rozwijanym menu Miejsce pracy dostosowuje TeamConnect do dokładnego odzwierciedlenia Twojego aktualnego statusu pracy. Najedź kursorem na ikonę informacji, aby uzyskać szczegółowe wyjaśnienie każdej opcji:
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Wylogowany/Przerwa:</Body1Strong> Oznacza czas poza pracą, czy to przerwa, czy koniec dnia.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Biuro/Biuro Terenowe:</Body1Strong> Wskazuje obecność w przestrzeni zarządzanej przez firmę.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> Praca z dedykowanego domowego biura.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Zdalne miejsce pracy/Publiczna przestrzeń:</Body1Strong> Praca z miejsca niebędącego biurem, co sugeruje potrzebę elastycznej komunikacji.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Miejsce pracy klienta/Prywatne biuro:</Body1Strong> Praca w miejscu klienta, z uwagą na poziom prywatności rozmów.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Dojazdy/Podróże służbowe/Szkolenia:</Body1Strong> W ruchu lub zaangażowany w określone działania związane z pracą poza biurem.</li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Święto państwowe/Wakacje/Różne urlopy:</Body1Strong> Oficjalnie poza dyżurem z osobistych, zdrowotnych lub świątecznych powodów.</li>
                </ul>
                Wybór odpowiedniego miejsca pracy zapewnia, że Twoi współpracownicy będą mieli kontekst potrzebny do interakcji, szanując dostępność i prywatność.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="41">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                41. Nawigacja po statusie 'Zalogowany' w TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Tag 'Zalogowany' w Katalogu Pracowników jest zaprojektowany, aby wyświetlać wszystkich pracowników, którzy są obecnie w pracy. Ta widoczność jest ustalana przez wybór Miejsca pracy:
                <ul>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Aktywne środowiska pracy:</Body1Strong> Wybory takie jak Biuro, Biuro Terenowe, Home-Office, Miejsce pracy zdalnej, Publiczna przestrzeń, Miejsce pracy klienta, 
                  Prywatne biuro klienta, Dojazdy, Podróże służbowe i Szkolenia sygnalizują zaangażowanie w pracę, co sprawia, że jesteś 'Zalogowany' i widoczny dla współpracowników.
                </li>
                  <li className={styles.bulletBottomMargin}><Body1Strong>Ustawienia niepracujące:</Body1Strong> Wybierając statusy Wylogowany, Przerwa, Święto państwowe, Wakacje, Urlop, Urlop macierzyński lub L4 
                    usuwasz się z listy 'Zalogowanych', ustawiając jasne oczekiwania co do dostępności i czasów odpowiedzi.
                </li>
                </ul>
                Ta funkcjonalność pomaga oddzielić okresy pracy od czasu osobistego, wspomagając efektywną współpracę i komunikację zespołową.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
        <Subtitle2>FAQ dotyczące zarządzania danymi i prywatności</Subtitle2>
          <Body1>
            <p>
              W dzisiejszej cyfrowej erze zrozumienie sposobu zarządzania Twoimi danymi jest kluczowe. TeamConnect jest zaangażowany w przejrzystość i solidne praktyki ochrony danych.
              Poniżej znajdziesz informacje na temat tego, jak obsługujemy, przechowujemy i chronimy Twoje dane w TeamConnect, zapewniając, że Twoja cyfrowa przestrzeń pracy jest bezpieczna i zgodna z przepisami.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Jak TeamConnect chroni moje dane osobiste?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect jest zaangażowany w ochronę Twoich danych osobowych dzięki kompleksowemu podejściu do bezpieczeństwa danych:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Jednolite Logowanie (SSO):</Body1Strong> Aby zwiększyć bezpieczeństwo i usprawnić doświadczenia użytkownika, TeamConnect integruje automatyczne 
                      Jednolite Logowanie (SSO) z Twoim kontem Microsoft 365, ułatwiając bezpieczne i bezproblemowe zarządzanie dostępem.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Szyfrowanie:</Body1Strong> Zapewniamy, że wszystkie dane w TeamConnect są szyfrowane zarówno podczas przesyłania, jak i w stanie spoczynku,
                      czyniąc je niedostępnymi dla nieautoryzowanych stron.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Minimalizacja Danych:</Body1Strong> Nasza zbieranie danych jest ściśle ograniczone do tego, co jest niezbędne dla funkcjonalności TeamConnect.
                      Jedyną informacją o użytkowniku, którą przechowujemy, jest Twoja Nazwa Główna Użytkownika (UPN) z Azure Entra ID, która może przypominać Twój adres email firmowy.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Ograniczony Dostęp:</Body1Strong> Dostęp do UPN jest ograniczony do Administratora Subskrypcji Twojej organizacji w naszym portalu subskrypcji, 
                      zapewniając, że wrażliwe informacje są widoczne tylko dla upoważnionych osób.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Szybkie Usuwanie Danych:</Body1Strong> TeamConnect jest skonstruowany tak, aby natychmiast usuwać przechowywane UPN w trzech konkretnych warunkach:
                      jeśli subskrypcja zostanie anulowana przez Administratora Subskrypcji, jeśli użytkownik zostanie usunięty przez HR w TeamConnect, lub jeśli subskrypcja TeamConnect
                      wygaśnie. To zapewnia, że Twoje dane osobowe nie są przechowywane dłużej niż jest to konieczne lub przydatne.
                    </li>
                  </ul>
                  <p>Dzięki tym środkom, TeamConnect zobowiązuje się do utrzymania najwyższych standardów ochrony danych, zapewniając, że Twoje informacje osobiste pozostają bezpieczne i poufne.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Zastanawiasz się, gdzie znajdują się Twoje dane w TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect łamie schematy tradycyjnych ofert SaaS, zapewniając, że Twoje dane nigdy nie opuszczą Twojej organizacji. 
                  W przeciwieństwie do zwykłej praktyki przechowywania danych na zewnętrznych serwerach w chmurze, TeamConnect pomysłowo wykorzystuje 
                  Microsoft 365 SharePoint Online Twojej organizacji do przechowywania wszystkich danych aplikacji. To innowacyjne podejście nie tylko 
                  wspiera suwerenność danych, ale również wzmacnia bezpieczeństwo, doskonale wpisując się w nasze zdecydowane zaangażowanie w ochronę 
                  Twojej prywatności i przestrzeganie rygorystycznych standardów zgodności.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Czy moje dane będą udostępnione jakimkolwiek stronom trzecim?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nie, dane nie są udostępniane stronom trzecim! Cubeet jest niezachwiany w naszym zobowiązaniu do prywatności i bezpieczeństwa Twoich danych. Nie udostępniamy żadnych danych TeamConnect stronon trzecim bez uzyskania wyraźnej zgody od naszych klientów. 
                  W naszym bezpiecznym środowisku przechowujemy tylko niezbędne identyfikatory, takie jak nazwa głównego użytkownika (UPN), nazwa organizacji, nazwa dzierżawy i identyfikator dzierżawy, w ramach naszego portalu subskrypcji. 
                  Te informacje są kluczowe dla świadczenia usług i są chronione zgodnie z naszymi rygorystycznymi zasadami prywatności.
                  Miej pewność, że nasze praktyki zarządzania danymi są zaprojektowane tak, aby Twoje dane pozostały poufne, z przejrzystością i kontrolą, które pozostają mocno w Twoich rękach.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Jak TeamConnect stosuje się do przepisów o ochronie danych, takich jak RODO?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect jest zgodny z przepisami o ochronie danych, takimi jak RODO, HIPAA, CCPA i inne, wykorzystując wbudowane funkcje zgodności środowiska Microsoft 365, 
                  gdzie wszystkie dane klientów są przechowywane bezpiecznie. W przeciwieństwie do tradycyjnych modeli SaaS, TeamConnect nie przechowuje żadnych danych klientów na swoich serwerach; zamiast tego działa w ramach 
                  środowiska Microsoft 365 Twojej organizacji, przestrzegając jego silnych standardów bezpieczeństwa i zgodności. To podejście zapewnia, że zarządzanie danymi w Twojej organizacji jest zgodne z 
                  kompleksowym ramami zgodności dostarczanymi przez Microsoft 365, w tym przestrzeganie RODO, HIPAA dla informacji o zdrowiu, CCPA dla mieszkańców Kalifornii,
                  oraz innych globalnych przepisów o ochronie danych. Integrując się bezproblemowo z istniejącą konfiguracją Microsoft 365, TeamConnect umożliwia Twojej organizacji utrzymanie 
                  pełnej kontroli nad danymi, zapewniając zgodność z najbardziej rygorystycznymi przepisami i regulacjami o ochronie danych.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Jak TeamConnect obsługuje dane z czatów, rozmów, wideorozmów, e-maili Outlook i połączeń telefonicznych w Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect wykorzystuje Microsoft Graph API, umożliwiając bezpośrednie interakcje takie jak czat, rozmowy, wideorozmowy i e-maile Outlook w ramach Microsoft Teams, 
                  zapewniając płynne doświadczenie użytkownika. Co istotne, chociaż TeamConnect inicjuje te komunikacje, robi to bez ingerowania w proces komunikacji 
                  oraz bez możliwości nagrywania czy przechowywania jakiejkolwiek treści interakcji. Wszystkie konwersacje, rozmowy i e-maile odbywają się i są zarządzane bezpośrednio w 
                  bezpiecznych ramach Microsoft Teams i Outlook, zgodnie z rygorystycznymi standardami bezpieczeństwa i prywatności Microsoft 365. To zapewnia, że TeamConnect zwiększa 
                  twoje możliwości komunikacyjne, jednocześnie w pełni zachowując poufność i integralność twoich danych.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Aby uzyskać szczegółowe informacje na temat naszych zasad i praktyk dotyczących danych, możesz również skonsultować {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>Przewodnik użytkownika TeamConnect</Body1Strong></Link> lub dział IT twojej organizacji pod adresem <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> aby uzyskać informacje specyficzne dla twojej konfiguracji.
          </p>
        </Body1>        
        <p></p>  
        <Subtitle1 className={styles.fontColor}>Słuchamy: Podziel się swoją opinią i pomysłami</Subtitle1>
        <Body1>
          <p>
            Twoje spostrzeżenia napędzają ewolucję TeamConnect. Niezależnie od tego, czy masz genialny pomysł na nową funkcję, czy chcesz podzielić się 
            opinią na temat swoich obecnych doświadczeń, jesteśmy otwarci. Twój wkład jest nieoceniony w naszym ciągłym dążeniu do zapewnienia jak najlepszego serwisu.
          </p>
        </Body1>      
        <Subtitle2>Opinie i propozycje funkcji</Subtitle2>
        <Body1>
          <p>
            Gotowy do podzielenia się? Przejdź do naszej {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Strony z Opiniami
            </Link>
            . Niezależnie od tego, czy to sugestia dotycząca nowej funkcji, czy opinia na temat istniejących funkcjonalności, po prostu wybierz 
            odpowiednią opcję z rozwijanego menu w formularzu. Twoje wnioski pomagają kształtować przyszłość TeamConnect, 
            zapewniając, że spełnia i przekracza twoje oczekiwania.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
