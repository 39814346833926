import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { provideFluentDesignSystem, fluentCard } from '@fluentui/web-components';
import { makeStyles, Body1Strong, Switch } from '@fluentui/react-components';
import { getSettingItem, updateFreeTrial, updateMsAccount } from '../../services/settingService';

provideFluentDesignSystem()
  .register(
    fluentCard()
);

const useStyles = makeStyles({
  root: {
    width: '60vw', 
    marginLeft: '20vw', 
    marginRight: '20vw',
    display: 'flex', 
    flexDirection: 'column',
  },
  rowInfo: {
    display: 'flex', 
    flexDirection: 'row', 
    columnGap: '2vw',
    height: '2vw',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    width: '40%',
    alignItems: 'center',
    justifyContent: 'right',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    alignItems: 'center',
    justifyContent: 'left',
  },
});

function SettingInfo({
  token
}) {
  const styles = useStyles();
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [isMsAccount, setIsMsAccount] = useState(false);

  const onChangeFreeTrial = async () => {
    try {
      await updateFreeTrial(token, !isFreeTrial);
      return requestData(token);
    } catch (err) {
      throw err;
    };
  };

  const onChangeMsAccount = async () => {
    try {
      await updateMsAccount(token, !isMsAccount);
      return requestData(token);
    } catch (err) {
      throw err;
    };
  };

  const requestData = async (t1) => {
    try {
      const resSetting = await getSettingItem(t1);
      if (resSetting && resSetting.data) {
        let { CheckFreeTrial: pTrial, CheckMsAccount: pAcct } = resSetting.data;
        pTrial = (pTrial && parseInt(pTrial) > 0) ? true : false;
        setIsFreeTrial(pTrial);
        pAcct = (pAcct && parseInt(pAcct) > 0) ? true : false;
        setIsMsAccount(pAcct);
      };
    } catch (err) {
      throw err;
    };
  };

  useEffect(() => {
    if (token) requestData(token);
  }, [token]);

  return (
    <div className={styles.root}>
      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>Check Free Trial:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Switch 
            style={{ textAlign: 'left'}}
            checked={isFreeTrial}
            onChange={onChangeFreeTrial}
          />
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong >Check Account:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Switch 
            style={{ textAlign: 'left'}}
            checked={isMsAccount}
            onChange={onChangeMsAccount}
          />
        </div>
      </div>
    </div>
  );
};

SettingInfo.propTypes = {
  token: PropTypes.string,
};

export default SettingInfo;