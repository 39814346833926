const MS_GRAPH_API = 'https://graph.microsoft.com';

const decodeToken = (pToken) => {
  let dToken = pToken;
  do {
    dToken = dToken.replace('%2b', '+');
    dToken = dToken.replace('%2B', '+');
    dToken = dToken.replace('%2f', '/');
    dToken = dToken.replace('%2F', '/');
  } while (
    dToken.indexOf('%2b') >= 0 || dToken.indexOf('%2B') >= 0 ||
    dToken.indexOf('%2f') >= 0 || dToken.indexOf('%2F') >= 0
  );
  return dToken;
};

const LANGUAGES_LOCALIZATION = [
  {
    LOCALE: ['en-us', 'en-US', 'en-au', 'en-AU', 'en-ca', 'en-CA',
      'en-ie', 'en-IE', 'en-in', 'en-IN', 'en-jm', 'en-JM', 'en-nz', 'en-NZ', 'en-ph', 'en-PH',
      'en-tt', 'en-TT', 'en-zw', 'en-ZW', 'en-bz', 'en-BZ',
      'en-my', 'en-MY', 
    ],
    VALUE: 'en-US',
    NAME: 'English (United States)',
  },
  {
    LOCALE: ['en-gb', 'en-GB', 'en-za', 'en-ZA',
      'en-sg', 'en-SG'], 
    VALUE: 'en-GB',
    NAME: 'English (United Kingdom)',
  },  
  {
    LOCALE: ['ar-eg', 'ar-EG', 'ar-jo', 'ar-JO', 'ar-sa', 'ar-SA'], 
    VALUE: 'ar-SA',
    NAME: 'Arabic (Saudi Arabia)',
  },
  {
    LOCALE: ['az-az', 'az-AZ'],
    VALUE: 'az-AZ',
    NAME: 'Azeri (Azerbaijan)',
  },
  {
    LOCALE: ['ca-es', 'ca-ES'],
    VALUE: 'ca-ES',
    NAME: 'Catalan (Spain)',
  },
  {
    LOCALE: ['cy-gb', 'cy-GB'],
    VALUE: 'cy-GB',
    NAME: 'Welsh (United Kingdom)',
  },
  {
    LOCALE: ['da-dk', 'da-DK'],
    VALUE: 'da-DK',
    NAME: 'Danish (Denmark)',
  },
  {
    LOCALE: ['de-de', 'de-DE', 'de-ch', 'de-CH', 'de-at', 'de-AT', 'de-lu', 'de-LU', 'de-li', 'de-LI'],
    VALUE: 'de-DE',
    NAME: 'German (Germany)',
  },
  {
    LOCALE: ['el-gr', 'el-GR'],
    VALUE: 'el-GR',
    NAME: 'Greek (Greece)',
  },
  {
    LOCALE: ['es-AR', 'es-ar', 'es-BO', 'es-bo', 'es-CL', 'es-cl', 
      'es-CR', 'es-cr', 'es-DO', 'es-do', 'es-EC', 'es-ec', 'es-GT', 'es-gt', 
      'es-PA', 'es-pa', 'es-PY', 'es-py', 'es-PE', 'es-pe', 'es-ES', 'es-es', 
      'es-UY', 'es-uy', 'es-VE', 'es-ve', 'eu-ES', 'eu-es', 'gl-es', 'gl-ES', 
    ],
    VALUE: 'es-ES',
    NAME: 'Spanish (Spain)',
  },
  {
    LOCALE: ['es-CO', 'es-co', 'es-SV', 'es-sv', 'es-HN', 'es-hn', 
      'es-MX', 'es-mx', 'es-NI', 'es-ni', 'es-PR', 'es-pr', 'es-US', 'es-us'],
    VALUE: 'es-US',
    NAME: 'Spanish (United States)',
  },
  {
    LOCALE: ['fa-ir', 'fa-IR'],
    VALUE: 'fa-IR',
    NAME: 'Farsi (Iran)',
  },
  {
    LOCALE: ['fi-fi', 'fi-FI'],
    VALUE: 'fi-FI',
    NAME: 'Finnish (Finland)',
  },
  {
    LOCALE: ['fr-BE', 'fr-be', 'fr-CA', 'fr-ca', 'fr-FR', 'fr-fr', 'fr-LU', 'fr-lu', 
      'fr-MC', 'fr-mc', 'fr-CH', 'fr-ch'
    ],
    VALUE: 'fr-FR',
    NAME: 'French (France)',
  },
  {
    LOCALE: ['ga-ie', 'ga-IE'],
    VALUE: 'ga-IE',
    NAME: 'Irish (Ireland)',
  },
  {
    LOCALE: ['he-il', 'he-IL'],
    VALUE: 'he-IL',
    NAME: 'Hebrew (Israel)',
  },
  {
    LOCALE: ['hi-in', 'hi-IN'],
    VALUE: 'hi-IN',
    NAME: 'Hindi (India)',
  },
  {
    LOCALE: ['hr-hr', 'hr-HR'],
    VALUE: 'hr-HR',
    NAME: 'Croatian (Croatia)',
  },
  {
    LOCALE: ['hu-hu', 'hu-HU'],
    VALUE: 'hu-HU',
    NAME: 'Hungarian (Hungary)',
  },
  {
    LOCALE: ['hy-am', 'hy-AM'],
    VALUE: 'hy-AM',
    NAME: 'Armenian (Armenia)',
  },
  {
    LOCALE: ['id-ID', 'id-id'],
    VALUE: 'id-ID',
    NAME: 'Indonesian (Indonesia)',
  },
  {
    LOCALE: ['is-is', 'is-IS'],
    VALUE: 'is-IS',
    NAME: 'Icelandic (Iceland)',
  },
  {
    LOCALE: ['it-IT', 'it-it', 'it-CH', 'it-ch'],
    VALUE: 'it-IT',
    NAME: 'Italian (Italy)',
  },
  {
    LOCALE: ['ja-JP', 'ja-jp'],
    VALUE: 'ja-JP',
    NAME: 'Japanese (Japan)',
  },
  {
    LOCALE: ['ka-GE', 'ka-ge'],
    VALUE: 'ka-GE',
    NAME: 'Georgian (Georgia)',
  },
  {
    LOCALE: ['ko-KR', 'ko-kr'],
    VALUE: 'ko-KR',
    NAME: 'Korean (Korea)',
  },
  {
    LOCALE: ['lt-LT', 'lt-lt'],
    VALUE: 'lt-LT',
    NAME: 'Lithuanian (Lithuania)',
  },
  {
    LOCALE: ['lv-LV', 'lv-lv'],
    VALUE: 'lv-LV',
    NAME: 'Latvian (Latvia)',
  },
  {
    LOCALE: ['mk-MK', 'mk-mk'],
    VALUE: 'mk-MK',
    NAME: 'FYRO Macedonian',
  },
  {
    LOCALE: ['ms-MY', 'ms-my'],
    VALUE: 'ms-MY',
    NAME: 'Malay (Malaysia)',
  },
  {
    LOCALE: ['mt-MT', 'mt-mt'],
    VALUE: 'mt-MT',
    NAME: 'Maltese (Malta)',
  },
  {
    LOCALE: ['nl-NL', 'nl-nl', 'nl-BE', 'nl-be'],
    VALUE: 'nl-NL',
    NAME: 'Dutch (Netherlands)',
  },
  {
    LOCALE: ['no-NO', 'no-no', 'nb-no', 'nb-NO', 'nn-no', 'nn-NO'],
    VALUE: 'no-NO',
    NAME: 'Norwegian (Norway)',
  },
  {
    LOCALE: ['pl-PL', 'pl-pl'],
    VALUE: 'pl-PL',
    NAME: 'Polish (Poland)'
  },
  {
    LOCALE: ['pt-PT', 'pt-pt', 'pt-BR', 'pt-br'],
    VALUE: 'pt-PT',
    NAME: 'Portuguese (Portugal)',
  },
  {
    LOCALE: ['ro-RO', 'ro-ro'],
    VALUE: 'ro-RO',
    NAME: 'Romanian (Romania)',
  },
  {
    LOCALE: ['ru-RU', 'ru-ru'],
    VALUE: 'ru-RU',
    NAME: 'Russian (Russia)',
  },
  {
    LOCALE: ['sk-SK', 'sk-sk'],
    VALUE: 'sk-SK',
    NAME: 'Slovak (Slovakia)',
  },
  {
    LOCALE: ['sl-SI', 'sl-si'],
    VALUE: 'sl-SI',
    NAME: 'Slovenian (Slovenia)',
  },
  {
    LOCALE: ['sq-AL', 'sq-al'],
    VALUE: 'sq-AL',
    NAME: 'Albanian (Albania)',
  },
  {
    LOCALE: ['sr-RS', 'sr-rs'],
    VALUE: 'sr-RS',
    NAME: 'Serbian (Serbia)',
  },
  {
    LOCALE: ['sv-SE', 'sv-se'],
    VALUE: 'sv-SE',
    NAME: 'Swedish (Sweden)',
  },
  {
    LOCALE: ['th-TH', 'th-th'],
    VALUE: 'th-TH',
    NAME: 'Thai (Thailand)',
  },
  {
    LOCALE: ['tr-TR', 'tr-tr'],
    VALUE: 'tr-TR',
    NAME: 'Thai (Thailand)',
  },
  {
    LOCALE: ['uk-UA', 'uk-ua'],
    VALUE: 'uk-UA',
    NAME: 'Turkish (Turkey)',
  },
  {
    LOCALE: ['vi-VN', 'vi-vn'],
    VALUE: 'vi-VN',
    NAME: 'Vietnamese (Viet Nam)',
  },
  {
    LOCALE: ['zh-CN', 'zh-cn', 'zh-SG', 'zh-sg'],
    VALUE: 'zh-CN', // Simplified Chinese
    NAME: 'Chinese (Simplified)',
  },
  {
    LOCALE: ['zh-HK', 'zh-hk', 'zh-TW', 'zh-tw'],
    VALUE: 'zh-TW', // Traditional Chinese
    NAME: 'Chinese (Traditional)',
  },
];

export {
  MS_GRAPH_API,
  LANGUAGES_LOCALIZATION,
  decodeToken,
};