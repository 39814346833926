import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>TeamConnect-ის დახმარების ცენტრი და ხშირი კითხვები</Title3>
        <p><Body1>
          კეთილი მოსვლა TeamConnect-ის დახმარების ცენტრში, რომელიც დაგეხმარებათ მეორედი პირის დირექტორიის აპლიკაციის გამოყენებაში. აქ იპოვნილან რესურსები, რომლებიც ოპტიმიზებენ TeamConnect-ის გამოყენებას Microsoft Teams-ში, გამყარებისთვის ისინი თქვენი ქსელში.
        </Body1></p>
        <Subtitle2>სწრაფი რჩევების გიდი</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>გაუმჯობესებული ძიების უნარები: </Body1Strong>
              მოძებნეთ სწრაფად სწორი თანამშრომლები, დეპარტამენტები და საჭირო კონტაქტები ჩვენი ძლიერი ძიების ხელსაწყოებით.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>ვიზუალური სტრუქტურული დიაგრამები: </Body1Strong>
              შეიგნეთ თქვენი ორგანიზაციის იერარქია და გაიგეთ რაში არის ხაზი, ინტუიტიური სტრუქტურული დიაგრამების მეშვეობით.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>ადაპტაციური ინსტრუმენტები დღევანდელი ნებართვებებისთვის: </Body1Strong>
              გამოიყენეთ დღევანდელი ოფისების მოთხოვნებისადმი ადაპტირებული მოდური ფუნქციონალი.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>თქვენი გამოცდილების ოპტიმიზაცია</Subtitle2>
        <Body1>
          <p>
            TeamConnect რევოლუციურად ცვლის თქვენს მიდგომას ორგანიზაციულ სტრუქტურასთან, რადგან ყოველდღიური კომუნიკაციები ქცევა სტრატეგიულ ენგაჯებებში.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>დამხმარე ცენტრი და ხშირი კითხვები</Subtitle1>
        <Body1>
          <p>
            იპოვეთ პასუხები TeamConnect-ის ფუნქციებზე და კონფიგურაციებზე ხშირად დასმულ კითხვებზე ამ ცეცხლიან ქვეყანაში. თუ გსურთ აპლიკაციის გადარჩევა, პარამეტრების უფროსი შეცვლა ან გადაჭრის პრობლემების ხსნა, ჩვენი დამხმარე ცენტრი აქ გეხმარებათ ეფექტურად.
          </p>
        </Body1>
        <Body1>
          <p>
            უფრო ღრმად დატენვის ან კონკრეტული რჩევებისთვის, გთხოვთ, გამოიყენეთ <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnect-ის მიმოხილვა</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        
        <Subtitle2>TeamConnect-ის ხშირად დასმული კითხვები</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. ვერ ვპოულობ TeamConnect-ს Microsoft Teams-ის ზოლში?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  თუ TeamConnect არ ჩანს ან გაქრა Microsoft Teams-ის ზოლიდან, თქვენ შეგიძლიათ მისი მინიჭება საკუთარ წვდომისთვის. მიჰყევით ეს მარტივი ნაბიჯები:
                  <ol>
                    <li className={styles.bulletBottomMargin}>გახსენით Microsoft Teams და დააჭირეთ '...' (+ Apps) ხატულას ზოლის ქვემოთ.</li>
                    <li className={styles.bulletBottomMargin}>ძიებით 'TeamConnect' საძიებო ზოლში ფანჯრის ზედა მხარეს.</li>
                    <li className={styles.bulletBottomMargin}>მაუსის მარჯვენა დაჭერით აირჩიეთ TeamConnect-ის აპლიკაცია ძიების შედეგებიდან და აირჩიეთ 'Pin'.</li>
                  </ol>
                  TeamConnect ახლა მინიჭებულია თქვენს ზოლს, რათა იყოს მისწვდომით ერთი დაჭერით!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. ტექნიკური პრობლემები TeamConnect-თან? აით, როგორ მიიღოთ დახმარება.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  თუ გაქვთ რაიმე ტექნიკური პრობლემა TeamConnect-თან Microsoft Teams-ში, თქვენი ორგანიზაციის IT დახმარების გუნდი მოგიხსნით პრობლემებს. 
                  მიმართეთ <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> და მათ მალე დაგეხმარებიან.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. ვერ ხედავთ თქვენს თანამშრომელთა დეტალებს?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  თუ თქვენი პროფილი ჩანს ცოტა ცარიელი მხოლოდ თქვენი სახელითა და ელ-ფოსტით, ალბათ, თქვენი HR გუნდი ჯერ არ დაამატებინა თქვენს სხვა თანამშრომლის დეტალებს TeamConnect-ში. 
                  არ გაუჭირდეთ! მარტივი წერილით თქვენი HR მხარდაჭერის გუნდს გაუშვებთ წამოწყებას. მათ მუშაობაში არიან თქვენი პროფილის შევსებაში ყველა მნიშვნელოვანი დეტალით. 
                  მიმართეთ <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> და დაინახეთ, როგორ იცოცხლებს თქვენი პროფილი!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. TeamConnect-ში რომელი ენის ვარიანტებია მისაწვდომი?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ი მხარს უჭერს Microsoft Teams-ის გლობალურ სივრცეში, მხარეს
                  უჭერს ყველა მის ენას. თუ თქვენ ურჩევნიათ არაბული ან ბერძნული, იაპონური ან
                  შვედური, თქვენს თითებზე გყავთ მრავალი ვარიანტი, მათ შორის:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      არაბული, ბულგარული, ჩინური (მარტივისმენლი და ტრადიციული), ხორვატული,
                      ჩეხური, დანიური, ჰოლანდიური, ინგლისური, ესტონური, ფინური, ფრანგული,
                      გერმანული, ბერძნული, ებრაული, უნგრული, ინდონეზიური, იტალიური,
                      იაპონური, კორეული, ლატვიური, ლიტვური, ნორვეგიული (ბოკმალი), პოლონური,
                      პორტუგალიური (ბრაზილია და პორტუგალია), რუმინული, რუსული, სერბული
                      (ლათინური), სლოვაკური, სლოვენიური, ესპანური, შვედური, ტაილანდური,
                      თურქული, უკრაინული, ვიეტნამური.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  TeamConnect-ის გამოცდილების მორგება თქვენს სურვილებებთან, გთხოვთ შეცვალეთ
                  ენის პარამეტრები პირდაპირ Microsoft Teams-ში, როდესაც TeamConnect სრულად
                  ინტეგრირებულია ამ პარამეტრებით:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      წადით Microsoft Teams-ის პარამეტრებში, დააჭირეთ სამ წერტილზე თქვენი
                      პროფილის სურათის გვერდიდან, შემდეგ აირჩიეთ 'გარეგნობა და წვდომა'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      გადადით ენის სექციაზე და დააჭირეთ სურვილიანი ენის არჩევის გამოსაშლელ
                      მენიუში.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      დაადასტურეთ თქვენი ცვლილებები, დააჭირეთ 'შენახვა და გადატვირთვა'
                      ღილაკს, რათა წარმოიშვათ ახალი ენის პარამეტრები.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>შენიშვნა: </Body1Strong>
                <Body1>
                  Microsoft Teams-ში ენის პარამეტრების შეცვლა TeamConnect-ის გამოცდილებას
                  ასევე განაახლებს. გაეცანით, რომ Teams-ის ენის შეცვლა ასევე შეიცვლება
                  თქვენი დროის და თარიღის ფორმატი. ამჟამად, TeamConnect ივიწყებს
                  მარჯვნივ-მარცხნივ წერისთვის. გვიხარიათ ეს განახლებები და გაგაცნობთ, როცა
                  ისინი გახდებიან მისაწვდომი.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. როგორ შევცვალო თემა TeamConnect-ში?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect ისინქრონიზება თქვენი Microsoft Teams თემის პრეფერენციებთან, მათ შორის Light, Dark და High Contrast ოფციები. თემის საკუთარი შეცვლის გზისას:
                  <ol>
                    <li className={styles.bulletBottomMargin}>წვდომა მიიღეთ Microsoft Teams-ის პარამეტრებთან დაჭერით სამ წერტილზე, თქვენი პროფილის სურათთან ზემოთ.</li>
                    <li className={styles.bulletBottomMargin}>შეარჩიეთ 'გარეგნობა და წვდომა' თემის ოფციების გამოკვლევას გამო.</li>
                    <li className={styles.bulletBottomMargin}>აირჩიეთ თქვენი სარჩევი თემა მარაგში არსებული თემებიდან, რათა TeamConnect-ის ინტერფეისი მყისიერად შეიცვალოს.</li>
                  </ol>
                  თქვენი თემის არჩევნა Microsoft Teams-ში ავტომატურად იქნება ჩართული TeamConnect-ში, რაც დაგიფარავთ ვიზუალურ გამოცდილებას თქვენს სამუშაო სივრცეში.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. TeamConnect-ის კონტაქტის მოქმედებითი ღილაკების გამოკვლევა
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ში, სადაცაც იპოვით თანამშრომლის კონტაქტურ დეტალებს, იპოვით ჩვენი გამოადგილებული მალე წვდომის ღილაკებს. ეს ხელსაყრელი ინსტრუმენტები შეიქმნა Microsoft ეკოსისტემაში თქვენი კვლევითი კავშირის გაუმკლავ სათანადოლოდ. აირჩიეთ ეფექტურად ამ ღილაკების გამოყენება:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ჩეთის ხატულა:</Body1Strong> დაკლიკეთ, რათა დაიწყოთ მაშველი Microsoft Teams-ის ჩეთი. თუ გსურთ დაბრუნება TeamConnect-ში ჩეთის დაწყების შემდეგ, გამოიყენეთ ისრა უკან დესკტოპ აპლიკაციაში, უკან ღილაკი მობილურ აპლიკაციაში ან ბრაუზერის უკან ღილაკი.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ელფოსტის ხატულა:</Body1Strong> გახსნის ახალ Outlook-ის ელფოსტის საწყისი მიმღების მისამართით "To:" ველში მზად. თუ Outlook არ გაიხსნა, საჭიროა კითხვა თქვენი IT დეპარტამენტში შეზღუდვებზე.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ვიდეო ზარის ხატულა:</Body1Strong> იწყებს პირისპირ ვიდეო ზარს Microsoft Teams-ით.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ზარის ხატულა:</Body1Strong> იწყებს Microsoft Teams ხმის ზარს, რათა სწრაფი დისკუსიები გამარტივდეს.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ორგანიზაციური ქსელის ხატულა:</Body1Strong> აჩვენებს თანამშრომლის ადგილს ორგანიზაციურ იერარქიაში, მათ შორის მენეჯერისა და პირდაპირი მიმღებთან.</li>
                    <li><Body1Strong>მეტი (...) ხატულა:</Body1Strong> აჩვენებს დამატებით ოფციებს:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>შეხვედრა ახლავე:</Body1Strong> გახსნის Microsoft Teams-ს, რათა დაიწყოთ მყისიერი შეხვედრა თანამშრომლთან.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>ახალი შეხვედრა:</Body1Strong> გადაგიყვანთ Microsoft Teams-ში, რათა დაჯავშნოთ ახალი შეხვედრა.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>დაამატე კონტაქტი:</Body1Strong> ამატებს თანამშრიმელის კანტაქტურ დეტალებს TeamConnect-დან თქვენი ახალ Microsoft Teams People აპლიკაციაში, გამდიდრებს თქვენს ზარის ფუნქციას.</li>
                      </ul>
                    </li>
                  </ul>
                  ეს ინტუიციური ღილაკები დაგეხმარებიან თანამშრომლებთან კავშირის ეფექტურად აღჭურვაში, გაგრძელებით ჩეთით, ელფოსტით ან მომავალი შეხვედრების დაგეგმვაში.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. ზარების გაკეთება TeamConnect-ით
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ეძებთ დარეკვას? TeamConnect აუმჯობესებს თქვენს კომუნიკაციას Microsoft Teams-თან ინტეგრაციის გზით.
                  უბრალოდ დააწკაპუნეთ სასურველ ტელეფონის ნომერზე TeamConnect-ში და მაშ, გამოჩნდება Microsoft Teams დარეკვის ინტერფეისი,
                  მზადაა თქვენთვის შეუფერხებლად აკრიფეთ.
                  <p>
                  <Body1Strong>შენიშვნა: </Body1Strong>
                  ზარების განხორციელებასთან დაკავშირებული პრობლემები გაქვთ? ეს შეიძლება გამოწვეული იყოს თქვენი ორგანიზაციის IT პოლიტიკით ან
                  Microsoft Teams Phone System-ის გარდა სატელეფონო სისტემის გამოყენება. თქვენი IT დეპარტამენტის შემოწმება მისამართზე <a href={`mailto:${itSupport}`}>{itSupport}</a>,
                  შეუძლია ამ საკითხს ნათელი მოჰფინოს.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. შედის თუ რაიმე ხარჯები TeamConnect-ით ზარების გაკეთებაში?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect გაზრდის თქვენს ზარის გამოცდილებას, გამოიყენებს Microsoft Teams-ს. აი, რას უნდა იცოდეთ ზარებთან დაკავშირებულ ხარჯებზე:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      ზარებისთვის თქვენი ორგანიზაციის თანამშრომლების სამუშაო ტელეფონებზე, დეპარტამენტის ტელეფონებზე ან ჰოთლაინებზე (შიდა ნომრები), 
                      Microsoft Teams Phone System-ის გამოყენებით ჩვეულებრივ <Body1Strong>არაარსებობენ ხარჯები</Body1Strong>.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      გარეთა ზარებისთვის, მაგივრად მობილურ ტელეფონებზე ან სხვა ხაზებზე, გამოიყენება <Body1Strong>სტანდარტული ტარიფები</Body1Strong> 
                      თქვენი ორგანიზაციის ტელეფონური სისტემის. ეს ტარიფები ცვლილებადია თქვენი კერძო სერვისის გეგმით Microsoft Teams-თან.
                    </li>
                  </ul>
                  <p><Body1Strong>შენიშვნა:</Body1Strong> თუ გაწერთ პრობლემას ან გაქვთ კითხვები გადასახადის გარეშე, უმჯობესია შენთვის 
                  IT გუნდთან მიმართოთ <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>-ზე დეტალურად 
                  თქვენი ორგანიზაციის წყობისა და პოლიტიკის შესაბამისად.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. განკარგვინია შენი 'გარეთ ოფისიდან' სტატუსი TeamConnect-ში?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  შენიშვნა, რომ შენი 'გარეთ ოფისიდან' გამოჩნდა სტატუსი Microsoft 365-დან TeamConnect-ში არასწორად ისახება?
                  ჩვენ გამოგიყენეთ. 'გარეთ ოფისიდან' სტატუსის სინქრონიზაციის თვისება
                  TeamConnect-ში უკვე შესრულებულია. ჩვენ ახლა Microsoft-ის ამ თვისების გაშვების მოლოდინს ვართ.
                  ეს მოხდება, შენი 'გარეთ ოფისიდან' სტატუსი სწორად ისახება TeamConnect-ში, შეთანხმებულია Microsoft Teams-ის
                  სტატუსის სანიშნეებთან უწყვეტი გამოცდილებისთვის.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>თანამშრომელთა დასახელების კითხვები</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>შესავალი თანამშრომელთა დასახელებლად:</Body1Strong>
              ნავიგაცია თქვენი ორგანიზაციის პროფესიულ ლანდშაფტში თანამშრომლების დირექტორია, თქვენი ცენტრალური 
              ცენტრი თანამშრომლების დეტალური ინფორმაციის მისაღებად, სამუშაო ადგილის დინამიკისა და თქვენი კოლეგების 
              პროფილებთან და მენეჯერულ ურთიერთობებთან პირდაპირი კავშირისთვის.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. გსურთ მოამატოთ, განაახლოთ ან გაასწოროთ თქვენი თანამშრომლების მონაცემები?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  თქვენი თანამშრომლის მონაცემების დამატებისთვის, განაახლებისა თუ სწორებისათ
                  მიმართეთ თქვენი ორგანიზაციის HR მხარდაჭერის გუნდს.
                  მზადაა დაგმხარვალოთ <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. რა ხდება, როდესაც ვაჭერ თანამშრომლის სახელზე დირექტორიაში?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  თანამშრომლის სახელზე დაჭერით TeamConnect თანამშრომთა დირექტორიაში გაიხსნება მათი დეტალური პროფილის გვერდი. ეს პროფილი გაძლევთ მთლიანად შესახებელ ინფორმაციას თანამშრომელზე, როგორც მაგალითად, მათ სამუშაო სათაური, განყოფილება, ოფისის მდებარეობა, კონტაქტის ინფორმაცია და სხვა. პროფილი დაგხმარებთ მიიღოთ მთლიანად აზრი მათზე, როგორი პროფესიული როლი აქვთ და როგორ აირიებენ ორგანიზაციაში, რათა გააადვილოთ მათი ვალდებულების აღქმა და თქვენი თანამშრომლობის საარისებს.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. რომელი ინფორმაცია მოიცავს თანამშრომლის პროფილი?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ის ყოველი თანამშრომლის პროფილი არის საჯარო მონახაზი, რომელიც გაძლევთ ზრდილობაზე ინსაიტებს პიროვნის პროფესიულ ცხოვრებაზე. აი რა იპოვით:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ძირითადი დეტალები:</Body1Strong> სახელი, სამუშაო სათაური, განყოფილება და შემდეო ლოკაცია.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>მოქმედებითი სტრუქტურა:</Body1Strong> ინფორმაცია პირდაპირი და ფუნქციურ რეპორტებზე, 
                      მათ ხაზისა და ფუნქციური მენეჯერები.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>სამუშაო სტატუსი:</Body1Strong> მიმდინარე სამუშაო სტატუსი, როგორიცაა შვებულება, გზაში, 
                      საბიზნესო მოგზაურობა და სხვა.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>სამუშაო ადგილი და ლოკალური დრო:</Body1Strong> თანამშრიმელის მიმდინარე სამუშაო ლოკაცია და 
                      ლოკალური დროის სარჩელი.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>კონტაქტის დეტალები:</Body1Strong> სამუშაო ტელეფონი, მობილური ნომერი და ელ.ფოსტა.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ოფისის მისამართი:</Body1Strong> სრული ოფისის ლოკაცია, შემდეო შენობა,
                      მისამართი და Google რუკა.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>შესახებ:</Body1Strong> მოკლე პირადი აღწერა ან პროფესიული ბიოგრაფია.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>თანამშრომელი ურთიერთობები:</Body1Strong> ინფორმაცია თანამშრომლის რეპორტინგის 
                      სტრუქტურაზე და გუნდის ურთიერთობებზე.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>სოციალური ბმულები და ენები:</Body1Strong> ბმულები პროფესიულ
                      სოციალურ პროფილებისკენ და ენის უნარები.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>უნარები:</Body1Strong> პროფესიული უნარებისა და ექსპერტიზის სია.</li>
                  </ul>
                  ასევე, პროფილი გთავაზობთ აქციური ვარიანტები ჩატით, ელ-ფოსტით, ვიდეო ზარით და შეხვედრების გეგმარებათან ერთად, 
                  სწრაფი წვდომა ორგანიზაციის გრაფიკისკენ უფრო ფართო გუნდის კონტექსტისთვის.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. როგორ შევცვალო ჩემი პროფილის ინფორმაცია?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  პროფილის პერსონალიზაცია TeamConnect-ში მარტივია. აი, როგორ გააკეთეთ:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        გადადით <Body1Strong>თანამშრომლის</Body1Strong> ვარჯიშზე და დააჭირეთ თქვენს <Body1Strong>ავატარს</Body1Strong>, რათა გაიხსნათ თქვენი პროფილი.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        მარჯვენა ზედა კუთხეში იპოვნება <Body1Strong>"პროფილის რედაქტირება"</Body1Strong> ბმული. 
                        მისი დაჭერით გაიხსნება პროფილის რედაქტირების დიალოგი.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        აქ, თქვენ შეგიძლიათ გაზარდოთ თქვენი პროფილი, დაამატოთ ან განაახლეთ <Body1Strong>თქვენი შესახებ</Body1Strong> სექცია, სოციალური ქსელის ბმულები და ტელეფონის ნომერი.
                        ეს გაზრდის თქვენი კოლეგებისთვის თქვენთან კავშირი ან თქვენი გამოყოლის შესაძლებლობას.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        არ დაივიწყეთ <Body1Strong>დამახსოვრების</Body1Strong> ღილაკზე დაჭერა, რათა მოხდეს თქვენი ცვლილებების დამახსოვრება.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        დიალოგში ქვემოთ გადაადით თქვენი <Body1Strong>ენების</Body1Strong> და <Body1Strong>კვალიფიკაციის</Body1Strong> 
                        დამატებას, ასევე თქვენი <Body1Strong>უნარების</Body1Strong> სათქმელში. ეს ინფორმაცია გაზრდის თქვენი კოლეგების შესაძლებლობას გაერკვნენ 
                        თქვენი ექსპერტიზის სფეროში და იმ ენებში, რომელებზეც შეგიძლიათ კომუნიკაცია.
                      </li>
                    </ol>
                  თქვენი პროფილის განახლება არა მარტო უნარჩევს თქვენი ინფორმაციას აღდგენაში, არამედ აგრძელებს უკეთეს კავშირებს თქვენს ორგანიზაციაში.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. თქვენი ოფისის, სამუშაო ადგილისა და დროის ზონის განახლება TeamConnect-ში
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  თქვენი ოფისის, სამუშაო ადგილის და დროის ზონის ინფორმაციის მუდმივი განახლება Microsoft Teams-ში არის ძალზე მნიშვნელოვანი უშუალო კოლაბორაციისთვის. 
                  ეს განახლებები მყისიერად კიანადით თქვენს TeamConnect პროფილში და სიაში, რათა თქვენი თანაშენები სწრაფად გაიგონ თქვენი მუშაობის ლოკაცია 
                  და საუკეთესო დროები თქვენთან კავშირისთვის, ამატებს გუნდურ მუშაობას სხვა რეგიონებსა და დროის ზონებზე.
                  <p></p>
                  მზად ხართ დეტალების განახლებად? TeamConnect-ში ადვილია! ჯერ მიდიხართ 'Settings'-ში MENU-ში და გადადიხართ 'General Settings'-ში. 
                  აქ, თქვენ მშვიდად შეძლებთ განახლოთ თქვენი ოფისი და დროის ზონა, და დააზუსტოთ თქვენი სამუშაო ადგილი დღისათვის. დაახსოვრეთ ცვლილებები, 
                  რათა ყველა იყოს განაცხადი და არანაირი გამორჩეული ადგილი არ გახლდათ მუშაობისთვის!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. ფიქრობთ, რომ რაიმე არასწორადაა კოლეგისა პროფილში?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
              <Body1>
                თუ კოლეგის პროფილში წააწყდებით რაიმე დეტალს, რომელიც მთლად სწორად არ გამოიყურება, საუკეთესო პირველი ნაბიჯი არის მათ მიაწოდოთ
                მეგობრული თავები. სწრაფმა ჩატმა ან ელფოსტამ შედეგი უნდა მოახდინოს! თუ რაიმე მიზეზით ინფორმაცია არ განახლდება, არ ინერვიულოთ - უბრალოდ
                დაუკავშირდით თქვენს HR მხარდაჭერის გუნდს მისამართზე <a href={`mailto:${hrSupport}`}>{hrSupport}</a> და ისინი კმაყოფილი იქნებიან
                რომ შევიდეს და დაალაგოს საქმეები.
              </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. გააზრება ტეგის ჯგუფები: ყველა, ფავორიტები, შემოწმებული და ახალი თანამშრომლები
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ყველა:</Body1Strong> ეს სტანდარტული ტეგი აჩვენებს თქვენს ორგანიზაციაში ყველა თანამშრომელს,
                      გაწევრიანებთ გუნდში მთლიან გამოჩენას.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ფავორიტები:</Body1Strong> დააჭირეთ აქ, რომ გაფილტროთ თქვენი ხედვა იმ კოლეგებზე, რომლებიც
                      მონიშნული გაქვთ 'ფავორიტებად', რათა გაადვილოთ კავშირის ყრა მნიშვნელოვან კონტაქტებთან.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>შემოწმებული:</Body1Strong> ეს ტეგი აჩვენებს, რომ თანამშრომლები არიან აქტიურ მომუშავე რეჟიმში, გასარჩევით
                      მხოლოდ ონლაინ ყოფნისგან Microsoft Teams-ში. როდესაც მოწმდებიან, თანამშრომლები გამოიტანენ, რომ არიან მუშაობაში და გაზიარებენ თავიანთ მუშაობის მდებარეობას
                      დღის განმავლობაში. ამას შორის შეიძლება სხვადასხვა სამუშაო მოქცევები, როგორიცაა ოფისი, ფილიალის ოფისი, სახლის ოფისი, დაშორებული სამუშაო ადგილი, მყიდველის ოფისი,
                      მყიდველის პირადი ოფისი, საჯარო არეალი, გზა, საქმიანი მგზავრობა, ან თუნდაც ტრენინგი. ამრიგად, 'შემოწმებული' სტატუსი აძლევს მკაფიო ნიშნულს
                      მუშაობის აქტიურობის შესახებ, სთავაზობს კოლეგებს ზუსტ გაგებას ერთმანეთის მუშაობის კონტექსტზე და მიმდინარე სიზმრადის შესახებ.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ახალი თანამშრომლები:</Body1Strong> იპოვეთ TeamConnect-ში ახალი პირები ამ ტეგით, რაც ამახილვებს
                      თანამშრომლების, რომლებიც დაემატნენ აპლიკაციაში ბოლო 30 დღეში. ტიპიურად, ეს დადგმულია უახლესთან დამატებებზე თქვენს ორგანიზაციაში, დაგეხმაროთ
                      ახალი გუნდის წევრების მისაღებად.
                    </li>
                  </ul>
                  ეს ტეგის ჯგუფები დიზაინირებულია ნავიგაციის გაუმარტივებლობისთვის და თქვენი TeamConnect გამოცდილების გაძლიერებისთვის, გადარწმუნებთ, რომ ჯერად იპოვნეთ და შეაერთოთ თვენი კოლეგები.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. ნიშნულების გაშიფვრა ძებნის ყუთის გვერდით
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ის თანამშრომლების დირექტორია დიზაინირებულია ვერსატილობითა და მარტივად, შეიცავს ოთხ ნიშნულს, რომლებიც გთავაზობთ ხედვების სხვა
                  ვარიანტებს და გააჩნია წრიული წვდომა მნიშვნელეულ დეტალებთან. აი რა გიშველით თვითეული ნიშნული:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>სიაში ხედვა:</Body1Strong> თქვენი პირველი ხედვა, ჩვენებს ორგანიზაციის ყველა წევრს
                      ერთგულ სიაში.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>პატარა ბარათების დახედვა:</Body1Strong> კომპაქტური ალტერნატივა, ამ ხედვით სია იქცევა
                      თანამშრომლების ბიტის-ზომის ბარათებად.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>დიდი ბარათების ხედვა:</Body1Strong> აირჩიეთ უფრო დეტალური ბარათები, რომლებიც
                      მოიცავს მეტ კონტაქტის ვარიანტებს, როგორიცაა ტელეფონი და მობილური ნომრები მარტივად ზარისთვის.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ორგ სქემა:</Body1Strong> კომპანიის სტრუქტურის ვიზუალიზაცია Org Chart ხედით,
                         იდეალურია ანგარიშგების ურთიერთობების გასაგებად და ორგანიზაციულ ლანდშაფტში ნავიგაციისთვის.</li>
                  </ol>
                    გადართეთ ამ ხატულებს შორის, რათა იპოვოთ ხედი, რომელიც საუკეთესოდ შეესაბამება თქვენს საჭიროებებს ამ მომენტში, მიუხედავად იმისა, ეძებთ სწრაფ ინფორმაციას თუ უფრო ღრმა შეხედულებებს.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. როგორ მუშაობს ანბანური ძიება თანამშრომელთა პოვნისათვის?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ის თანამშრომელთა დირექტორიაში შედის სასწრაფო ანბანური ძიების ფუნქცია, რომელიც დიდი სიჩქარით მიგიყვანთ თანამშრომელთან მათი სახელის პირველ ასოს მიხედვით.
                  უბრალოდ დააჭირეთ ასოს დირექტორიის ზემოთ გამოსაჩენი ანბანზე, და TeamConnect მაშინვე გაჩვენებთ იმ ყველა თანამშრომელს,
                  რომლის სახელებიც ამ ასოთი იწყება. ყველა თანამშრომელთა სრული სიაზე დაბრუნებისათვის, უბრალოდ გააუქმეთ გამორჩეული ასო. ეს ინტუიციური
                  ფუნქცია გახდის ვრცელი თანამშრომლთა სიებით გადახედვის პროცესს მარტივად და მარტივად, ადვილად იპოვოთ კოლეგები და შეაფასოთ ეფექტურად.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. როგორ შევცვალო თანამშრომლთა გვერდზე გამოჩენილი რაოდენობა?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ი გაგიწევთ შეუსწოროთ რამდენი პროფილი დაინახოთ თანამშრომლთა დირექტორიაში ერთ გვერდზე, რათა უფრო მარტივად გადაადექთ მას.
                  თითოეული გვერდის ქვემოთ ნახავთ პანელის ოფციას: 10, 50, 100, 500, 1000, და 5000. ძირითად დაყენება 10 პროფილს აჩვენებს გვერდზე.
                  ერთ გვერდზე მეტი პროფილის ნახვისათვის, უბრალოდ აირჩიეთ თქვენი სასურველი რაოდენობა ამ არჩევნებიდან. ეს ფუნქცია გაძლევთ გამოყენების
                  ლანდშაფტზე მართვის გარანტიებს, რათა დაარიგოთ დირექტორიის ნახვა თქვენი სპეციფიური საჭიროებებისა და სურვილების მიხედვით.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. როგორ გავასორტირო თანამშრომლთა ინფორმაცია List View-ში?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ის თანამშრომელთა დირექტორიაში List View-ზე გაქვთ განაცხადი თანამშრომლთა ინფორმაციის წესიერების დასარგულებლად.
                  სვეტები—თანამშრომლის სახელი, სამუშაო თანამდებობა, დეპარტამენტი, ოფისის მდებარეობა, და სამუშაო ადგილი ყველანი დასორტირებადია.
                  უბრალოდ დააჭირეთ სვეტის სათაურს რომელზეც გსურთ დასორტირება. ერთხელ დაჭერით სვეტი დასორტირდება მაღალიდან დაბალში, და ხელახლა დაჭერით—დაბალიდან მაღალში.
                  საწყისი დაყენებით, დირექტორია სორტირდება თანამშრომლის სახელით მაღალიდან დაბალში, პირველი სახელით. ეს სორტირების ფუნქცია გაგიმარტივებთ
                  თანამშრომლთა დეტალების გადახედვას იმ მიზნით, რომ გარჩილებად დაალაგოთ, შეიძინთ სპეციფიური და გსურთ
                  ამოირჩიოთ ჯგუფები როგორიმე კრიტერიუმის, როგორიცაა დეპარტამენტი ან მდებარეობა.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>დეპარტამენტების დირექტორიის ჰაერის კითხვები</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>დეპარტამენტების დირექტორიის შესავალი:</Body1Strong> დეპარტამენტების დირექტორია თქვენი გზაა თქვენი ორგანიზაციის 
              იერარქიული ქსელის გაგებისკენ, რომელიც გთავაზობთ დეპარტამენტების იერარქიულობების, მენეჯერული ლიდერობის და 
              ურთიერთობის სწორი ხედვის.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. როგორ მუშაობენ 'ყველა', 'ფავორიტები' და 'თქვენი დეპარტამენტის' ტეგები დეპარტამენტების დირექტორიაში?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ის დეპარტამენტების დირექტორიაში, ტეგები დეპარტამენტურ ინფორმაციის სწრაფად ნავიგაციებისა და გაფილტრვის 
                  დამხმარებელია. აი რას ნიშნავს თითოეული ტეგი:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ყველა:</Body1Strong> ეს ტეგი აჩვენებს ყველა დეპარტამენტს 
                      თქვენს ორგანიზაციაში, გთავაზობთ მთლიანი მიმოხილვას.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ფავორიტები:</Body1Strong> გამოიყენეთ ეს ტეგი, რათა იხილოთ თქვენს არჩეულ დეპარტამენტებს 
                      'ფავორიტები' სიაში, რაც ადვილებს იმ დეპარტამენტებთან წვდომას, რომლებთანაც ხშირად გიწევთ საქმე.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>თქვენი დეპარტამენტი:</Body1Strong> ეს ტეგი ფილტრებს ხედვას, რათა 
                      აჩვენოს მხოლოდ თქვენი დეპარტამენტი, გაძლევთ სწრაფ წვდომას თქვენი მაშინდელი დეპარტამენტული 
                      ინფრმაციისა და განახლებებისთვის.
                    </li>
                  </ul>
                  ეს ტეგები დიზაინირებულია თქვენი ნავიგაციის მიმართვაზე დეპარტამენტების დირექტორიაში, რათა გაზრდოთ თქვენი 
                  შესაძლებლობები დეპარტამენტულ ინფორმაციის პოვნაში და მოქმედებაში ეფექტურად.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. TeamConnect-ში დეპარტამენტების დირექტორიის ხედვათა გაგება
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ის დეპარტამენტების დირექტორიის ნავიგაცია და ხედვების გადართვა გაამარტივებს 
                  ხედვების მრავალი ვარიანტით, რათა შეეფერებოდეთ თქვენს სურვილებს. თითოეული ხედვა გთავაზობთ უნიკალურ გზას 
                  დეპარტამენტული ინფორმაციის შესწავლაში, მოიცავს დეპარტამენტის დასახელებას, დეპარტამენტის მენეჯერს, 
                  მშობელ დეპარტამენტს, დეპარტამენტის ტელეფონს, დეპარტამენტის ჰოთლაინს და დეპარტამენტის ელ-ფოსტას. 
                  აი ხედვების არსებული ვარიანტები:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>სიაში ხედვა:</Body1Strong> სტანდარტული 
                      მოწყობილობა, რომელიც სრულყოფილად ასახავს ყველა დეპარტამენტს, გთავაზობთ ნათელ, კონციზურ მიმოხილვას.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>დიდი ბარათის ხედვა:</Body1Strong> გადართეთ ამ ხედვაში, რათა
                      იხილოთ დეტალური ბარათები თითქმის ყველა დეპარტამენტის მთავარ ინფრმაციებზე.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ორგანული დიაგრამის ხედი:</Body1Strong> დეპარტამენტის სტრუქტურისა და ვიზუალური წარმოდგენისთვის
                         ურთიერთობები, Org Chart ხედი ასახავს, თუ როგორ ჯდება თითოეული დეპარტამენტი უფრო დიდ ორგანიზაციულ იერარქიაში.
                       </li>
                  </ul>
                  ამ ხედებს შორის გადასართავად, უბრალოდ გამოიყენეთ ხატები საძიებო ველის გვერდით დეპარტამენტის დირექტორიის ზედა ნაწილში. თითოეული ხედი შექმნილია იმისთვის, რომ დაგეხმაროთ
                  უპრობლემოდ შედით და გაიგეთ დეპარტამენტის დეტალები, რაც უზრუნველყოფს თქვენთვის საჭირო ინფორმაციის პოვნას იმ ფორმატში, რომელიც საუკეთესოდ მუშაობს თქვენთვის.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. როგორ გამოიყენოთ ძიების ველი დეპარტამენტის დირექტორიაში
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  გამოიძიეთ ნათელად ნებისმიერი დეპარტამენტი TeamConnect-ში, დამინდობილი ძიების ველით, რომელიც დირექტორიის სახელს ახლოსაა ნახატების ხედის იკონების გვერდით ზემოთ. 
                  დაიწყეთ ტექსტის ჩაწერა ძიების სრულიად თქმისას, და იხილეთ, როგორ დირექტორია თავად ამჯობინებს იმედს, დააჩვენოს მხოლოდ 
                  შესაბამისი დასკვნები. ეს ფუნქცია მხარს გიჭერთ ხელით დირექტორიაში მთელი საძიებოს გადატანის საქმეში. 
                  საძიებო ფუნქციონალური უნიკალურია TeamConnect-ის დეპარტამენტის დირექტორიისთვის, ამიტომ გამოიყენეთ ეს ძიების ველი 
                  Microsoft Teams-ის ძიების საიტის გამო, საძიებო სპეციფიური შეკითხვებისთვის.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. როგორ მუშაობს ანბანური ფილტრი დეპარტამენტის დირექტორიაში?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  გააუმჯობესე ძიება დეპარტამენტის დირექტორიაში ანბანური ფილტრით, რომელიც ზემოთაა. ეს ფუნქცია გთავაზობთ სწრაფად ხარისხიანი 
                  დეპარტამენტების არჩევანს, ასოს არჩევით. როდესაც აარჩევთ ასოს, TeamConnect ფილტრავს და უჩვენებთ 
                  მხოლოდ იმ დეპარტამენტებს, რომლის სახელებიც იწყება თქვენი შერჩეული ასოთი, რათა უფრო ადვილად 
                  იპოვოთ საჭიროდ. თუ გსურთ დაბრუნება მთლიანი დეპარტამენტის ხედზე, უბრალოდ დაარღვიეთ გამორჩეული ასო. 
                  ეს ინტუიციური ანბანური ნავიგაცია გაძლევთ სწრაფად მიიგოთ ნებისმიერი დეპარტამენტი მისი საწყისი ასოთი.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. როგორ შევძლებ დეპარტამენტის მენეჯერთან კონტაქტირებას?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  დეპარტამენტის მენეჯერთან კონტაქტირება TeamConnect-ში მარტივია. მარტივად მიიგნეთ სასურველი დეპარტამენტი 
                  და დააჭირეთ მენეჯერის სახელს. ეს ქმედება გამოიწვევს popover-ს, რომელიც გამოჩნდება მენეჯერის კონტაქტის 
                  ოპციებით სანაცვლო ოქროს ღილაკებით:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chat Icon:</Body1Strong> ფორმალური შეტყობინების დაწყება Microsoft Teams chat-ით.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Email Icon:</Body1Strong> ახალი წერილის გახსნა Microsoft Outlook-ში.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Video Call Icon:</Body1Strong> ვიდეო ზარის დაწყება Microsoft Teams-ით.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Call Icon:</Body1Strong> ხმოვანი ზარის დაწყება Microsoft Teams-ით.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>More (...) Icon:</Body1Strong> დამატებითი ოპციებისთვის, როგორიცაა 
                      შეხვედრის დაგეგმვა ან კონტაქტის მონაცემთა დამატება თქვენს Teams სიაში.
                    </li>
                  </ul>
                  ეს ღილაკები გაძლევთ გზას, რომ დაუკავშირდეთ დეპარტამენტის მენეჯერთან პირდაპირ TeamConnect-ის გარეშე, იმუშაოთ 
                  ეფექტურად თქვენი ორგანიზატაში.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. როგორ შევძლო ნახო ან დავკავშირდე დეპარტამენტში მუშავდებით თანამშრომლებთან?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  დეპარტამენტში TeamConnect-ში გსურთ გაიგოთ, ვის ადგილი რა არის, არ გადადგათ სამი წერტილის გასაჭირი. დაჭერით ამაზე გამოჩნდება 
                  მეტი ქმედების ვარიანტი:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>მუშავდებით დეპარტამენტში:</Body1Strong> აირჩიეთ ეს ვარიანტი დეპარტამენტთან 
                    მისამაგრებელი ყველა თანამშრომლის სიას ნახავთ. ამ ოპციისთვის მდევნერი ჩივილი აჩვენებს, რამდენი თანამშრომელია 
                    დეპარტამენტში და გაძლევთ მარტივ მთვლელობას.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>დეპარტამენტის სტრუქტურის ე.წ. იერარქია:</Body1Strong> თუ გსურთ გაეცნოთ 
                    დეპარტამენტის იერარქიას, აირჩიეთ 'დეპარტამენტის სტრუქტურის ე.წ. იერარქია', რათა გახსნათ სტრუქტურული 
                    გეგმა, რომელიც აჩვენებს ზედა დეპარტამენტების, ამ დეპარტამენტის და ქვედეპარტამენტების შორის ურთიერთობას.
                    ეს ვიზუალური წარმოსახვა დაგეხმარებათ ნავიგაციაში და გონივრულ ჩარჩოს გაგებაში, რის შემდეგაც
                    გამარტივდებათ კონტაქტირება კონკრეტული დეპარტამენტის წევრებთან.
                    </li>
                    <li><Body1Strong>დეპარტამენტი {'>'} თანამშრომელი:</Body1Strong> დეპარტამენტში თანამშრომლების 
                    ნახვის შემდეგ, გამოიყენეთ ეს breadcrumb ბილიკი დეპარტამენტების სიაში უბრალოდ დასაბრუნებელად, რაც 
                    ამარტივებს გამოკვლევის გამოცდილებას TeamConnect-ში.
                    </li>
                  </ul>
                  ეს ფუნქციები გაძლევთ არა მარტო თანამშრომლების სიას დეპარტამენტისთვის, ასევე გზას გაუხსნით
                  მათთან პირდაპირ საუბრისთვის TeamConnect-ის კომუნიკაციურ ინსტრუმენტებით.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. TeamConnect-ში დეპარტამენტის ღრმა დეტალების აღმოჩენა
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ჩაუღრმავდით ნებისმიერი დეპარტამენტის დეტალებს TeamConnect-ში, მარტივი დაჭერით მისი სახელისთვის. 
                  გაშლილი დიალოგი გთავაზობთ დიდხანს სიმდიდრისების ინფორმაციას, რომელმაც შექმნის მთელ 
                  გამოსახულებას დეპარტამენტის როლზე და რესურსებზე თქვენს ორგანიზაციაში. იქ იპოვით ყველაფერს, 
                  ლიდერობიდან და ფინანსური ბაზირსაკენ კომუნიკაციურ არხებამდე და სოციალურ ბმულამდე. ეს 
                  ფუნქცია არის თქვენი ბილეთი:
                  <ul>
                    <li className={styles.bulletBottomMargin}>დეპარტამენტის ზრდი – მისი მიზანი, ხალხი და შესრულების მეტრიკები.</li>
                    <li className={styles.bulletBottomMargin}>კავშირის გზები – პირდაპირი ხაზები გაიღვიძებენ მნიშვნელი 
                    სადეპარტამენტო ფიგურებთან და კავშირის პლატფორმებთან.</li>
                    <li className={styles.bulletBottomMargin}>ორგანიზაციური ქსელი – აგებს რეალურ აზრს 
                    როგორ დარიგდება დეპარტამენტი დიდი კომპანიის სტრუქტურაში.</li>
                  </ul>
                  თუ სურს მარტივი შეკითხვა ან ღრმა თხრა დეპარტამენტის დატაზე, TeamConnect გთავაზობთ ინსტრუმენტებს ჰაერთან და
                  გაარჩიეთ უნიკალური ეკოსისტემები თქვენს ორგანიზაციაში.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. გაინტერესებთ TeamConnect-ში დეპარტამენტის მისამართების არარსებობა?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  განყოფილების მდებარეობის აღმოჩენა ხანდახან საგანძურზე ნადირობას ჰგავს რუქის გარეშე. TeamConnect-ში, როგორც წესი, ვერ იპოვით გამოყოფილს
                  მისამართი თითოეული განყოფილებისთვის. აი, რატომ არის ხშირად გამოტოვებული ეს კარტოგრაფიული დეტალი:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>მრავალჯერადი მდებარეობა:</Body1Strong> ბევრი დეპარტამენტი არ შემოიფარგლება ერთი სივრცით; მათ
                      აყვავდება სხვადასხვა ოფისებსა და გეოგრაფიებში.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>თხევადი სტრუქტურა:</Body1Strong> ორგანიზაციული ლანდშაფტი მუდმივად იცვლება,
                      დეპარტამენტები ვითარდება, ერწყმის ან იშლება სტრატეგიული ცვლილებების შესაბამისად.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>უწყებათაშორისი თანამშრომლობა:</Body1Strong> თანამედროვე სამუშაო სივრცეები კოლაბორაციით ხმაურიანია
                      სულისკვეთება, რომელიც ხშირად აერთიანებს სხვადასხვა განყოფილების ნიჭს საერთო სივრცეებში.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>დისტანციური მუშაობის კულტურა:</Body1Strong> დისტანციური მუშაობის მოქნილობით, დეპარტამენტი
                      წამყვანები ყოველთვის არ იშლება ოფისის ფიზიკურ ადგილებში.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>სამართლებრივი ნიუანსი:</Body1Strong> დეპარტამენტები, როგორც წესი, ორბიტაზე ტრიალებს კომპანიის იურიდიული გზების გარშემო
                      სტრუქტურა და არა საკუთარი დამოუკიდებელი მისამართები.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ოპერაციული გამარტივება:</Body1Strong> ფოკუსირება დინამიურ გუნდურ მუშაობაზე და არა სტატიკური
                      მიმართავს, ხელს უწყობს ოპერატიული სისწრაფის შენარჩუნებას.
                    </li>
                  </ul>
                  ეს ყველაფერი ეხება გუნდური მუშაობისა და ეფექტურობისადმი საზღვრების გარეშე მიდგომას. TeamConnect ასახავს ამ ფილოსოფიას კავშირებზე ხაზგასმით
                  ლოკაციები.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. გაქვთ თქვენი დეპარტამენტის ინფორმაცია აკლია ან ძველი?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  დეპარტამენტის რედაქტორები დანიშნულია თქვენს ორგანიზაციაში, რათა დაამატონ, განაახლონ ან გაასწორონ დეპარტამენტური სპეციფიური 
                  ინფორმაცია TeamConnect-ში.
                  თუ არ ხართ დარწმუნებული, ვინ არის თქვენი დეპარტამენტის რედაქტორი ან თუ გჭირდებათ რომელიმე ინფორმაციის 
                  შესაძლო მივლინება HR მხარდაჭერის გუნდით, გთხოვთ, დაუკავშირდეთ <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> 
                  გიდისთვის.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>ოფისების დირექტორიის FAQ</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>ოფისების დირექტორიის შესავალი:</Body1Strong> გაიგეთ თქვენი ორგანიზაციის ფიზიკური 
                ყოფნის ძირი ოფისების დირექტორიის საშუალებით, როგორც საჭირო ოფისების დეტალებით, 
                ოპერაციონული სპეციფიკებით და ცალკეული დეპარტამენტებისა და პერსონალის მთლიან 
                ნახეთქებით, რომელიც განზრახ თითოეულ ოფისის.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. თეგებით მართვა ოფისების დირექტორიაში
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  გაერკვიეთ ოფისების დირექტორიაში ჩვენი თეგების სისტემით, რომელიც გამოგიყენებათ 
                  სწრაფ წვდომებისთვის სწორ ლოკაციაზე. აი როგორ შეგიძლიათ ყოველი თეგი გაგიმარტივოთ ძიება:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ყველა:</Body1Strong> აჩვენებს ოფისების სრულ სიას, რათა 
                      გაძლევთ თქვენი ორგანიზაციის ლოკაციების ზოგად ხედვას.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ფავორიტები:</Body1Strong> გეხმარებათ მიაპყროთ ყურადღება 
                      იმ ოფისების ლინკებზე, რომლებსაც ხშირად სტუმრობთ ან მათზე უნდა მიაპყროთ უფრო
                      მეტი ყურადღება, მონიშნავთ როგორც 'ფავორიტები' .
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>თქვენი ოფისი:</Body1Strong>ეს პირადი თეგი ფილტრავს დირექტორიას, 
                      რომ გაჩვენოთ ოფისის ლოკაცია, სადაც თქვენ ემყარებით, შემოქმედებთ თქვენი 
                      ყოფნისას თქვენი სახლი ხელთ იქნება.
                    </li>
                  </ul>
                  თუ დაჯავშნავთ კარგადაკვეთის ოთახს ან გეგმავთ ვიზიტს, ეს თეგები გთავაზობენ 
                  პირადი და გამარტივებული გზა თქვენი ორგანიზაციული გეოგრაფიული 
                  ლანდშაფტზე მოქმედებაში.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. ოფისების დირექტორიის ხედების მართვა TeamConnect-ში
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  დახვეწილიყავით თქვენი კომპანიის სხვადასხვა ლოკაციების ორგანიზაციულ გეგმებში TeamConnect-ის ოფისის დირექტორიის ხედებით. თითოეული ხედი შექმნილია ოფისის ნავიგაციისა და გაგების უნიკალურ ასპექტის გამარტივებლად:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>სიაში ხედი:</Body1Strong> ნაგულისხმევი განაყოფი, რომელიც ყველა ოფისის ლოკაციებს ალაგებს
                      შესაგებლობებრივ სიაში.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>დიდი ბარათის ხედი:</Body1Strong> ოფისის დირექტორიის დიდი ბარათის ხედი გაზრდის თქვენს 
                      ხედვას თითოეულ ოფისის დეტალური წარმოდგენით. ოფისის ბარათის გამოყენებისას, თქვენ იპოვით დიდ რაოდენობას 
                      ინფორმაციითდა მოქმედებითი ოპციებით "მეტი (...)" მენიუში:
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>უყურეთ დეპარტამენტებს:</Body1Strong> პირდაპირ გადადით 
                          ოფისში მდებარე ყველა დეპარტამენტის სიაში.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>უყურეთ თანამშრომლებს:</Body1Strong> წვდომა თანამშრომლების 
                          სრულ როსტერს, რომელთაც ოფისს აკავშირებთ, რათა ოფისის პერსონალთან საუბროთ ან ისინი გაიცნოთ.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>ბიზნეს-ენთითის ორგანიზაციული სქემა:</Body1Strong> იხილეთ ოფისის 
                          ადგილი უფრო დიდ წესრიგში ჰიერარქიული სტრუქტურების გაგებისთვის.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>საჭირო კონტაქტები:</Body1Strong> ისახელეთ მთავარი ოფისის 
                          კონტაქტები კავშირის გლუვადაცემად.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>ოფისის საზოგადო დღეები:</Body1Strong> იყავით ინფორმირებული ოფისისთვის 
                          სპეციფიკური საზოგადო დღეებით, რათა გეგმავდეთ თქვენი გრაფიკები.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>ოფისის სამუშაო საათები:</Body1Strong> იხილეთ ოფისის მუშაობის 
                          საათები, რათა შეესაებით თქვენი ვიზიტები ან ზარები მათი აქტიურ დროებთან.
                        </li>
                      </ol>
                      ეს ხედი მოგცემთ არა მარტო ვიზუალურსა და ინფორმაციულ სნაფშოთს, არამედ სწრაფ წვდომას უფრის შესწავლისა და პირდაპირის მოქმედებისთვის, ყველაფერი კარგად დამოუკიდებლად ათავსებულია "მეტი (...)" პრივილეგიით თქვენი სისარგებლისათვის.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ორგანიზაციული სქემის ხედი:</Body1Strong> იხილეთ ჰიერარქიული სტრუქტურა 
                      ძირითადი და ქვე-ოფისებისთვის, რათა გაიგოთ, როგორ არის სხვადასხვა ოფისები მიბმული თქვენს კომპანიაში.
                    </li>
                  </ul>
                  გადადით ამ ხედებზე გათიშვით ხატულები ძებნის ყუთთან ახლოს, რათა მორგებილი იყოს თქვენი დათვალიერების გამოცდა თქვენი მყისიერებისთვის.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. ოფისების სწრაფი პოვნა TeamConnect-ის ძებნის ფუნქციით
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ში ოფისის სწორი მდებარეობის ნულოვანი შეყვანა გაადვილებულია სპეციალური ძიების ფუნქციით. განლაგებულია თვალსაჩინოდ ხედის გვერდით
                  ხატულები, საძიებო ველი არის თქვენი ინსტრუმენტი ოფისების სახელების დასახელებისთვის. აკრეფისას დირექტორია დახვეწავს ეკრანს მხოლოდ ოფისების შესატყვისად
                  თქვენი შეყვანა, თქვენი ძიების პროცესის გამარტივება. მნიშვნელოვანია ამ საძიებო ველის გამოყენება TeamConnect-ში ყველაზე ზუსტი და შესაბამისი შედეგებისთვის,
                  Microsoft-ის გუნდების ზოგადი ძიებისგან განსხვავებით. ამ მიზნობრივი ძიების ფუნქციით, შეგიძლიათ სწრაფად ნავიგაცია თქვენს ნებისმიერ ოფისში
                  ორგანიზაციის ქსელი.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. ოფისების სიაში ალფაბეტურად ნავიგაცია
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  თუ თქვენ მიიჩნევთ ძებნას ალფაბეტის მიხედვით, ჩვენი ალფაბეტური ფილტრი არის სწრაფი გზა ოფისების პოვნაში სახელით. ოფისების სიაში, თქვენ 
                  იხილავთ ასოებიდან A დან Z-მდე. ასოზე დაკლიკებით ხდება ნახვა მხოლოდ იმ ოფისების, რომლებიც იწყებენ ამ ასოთი, რაც გაამარტივებს თქვენს 
                  ძებნის პროცესს. თავიდან ყველა ოფისის ნახვას მოგიწევთ აქტიური ასოს მოხსნა. ეს ფუნქცია გამოდის რადგანაც ხშირად ვიცით ოფისის 
                  სახელი, მაგრამ სწრაფად მოგიწევთ მისი პოვნა TeamConnect-ში.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. მთლიანობის ოფისების პროფილების ამოხატვა TeamConnect-ში
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  გაემზადეთ კვლევის მოგზაურობაზე თქვენი ორგანიზაციის ფიზიკური გარემოში TeamConnect-ის ოფისების პროფილებით. 
                  ოფისის სახელზე დაჭერით იხილავთ გამტკიცებულ პროფილის დიალოგს, სავსე ჩანართებით, სადაც ვრცელდება დაწერილები ოფისის შესახებ:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>მიმოხილვა:</Body1Strong> მიენდეთ ოფისის იდენტობრივობის ბირთვში 
                      ვრცელი მაღლისხმობით, ბიზნეს სტრუქტურიდან დაწყებამდემ ლოკალური დროით და კარგად თავისუფლების საათებთან მიღწევამდე, აღწერით 
                      ოფისის გარემოს ბირთვს.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>საჭირო კონტაქტები:</Body1Strong> წვდომა სასწრაფო მომსახურებისა და 
                      გარემოსთან დახმარების საჭირო კონტაქტებთან, თითო დაჭერით Microsoft Teams Phone System-ის მეშვეობით.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ოფისის კონტაქტები:</Body1Strong> მიმართეთ შინაგან მომსახურებისთვის 
                      მარტივად, კონტაქტის დეტალებითა და პირდაპირი შეხების ოფციებით, ხელში მიღებულით.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>საჯარო დღესასწაულები:</Body1Strong> იყავით განათლებული ოფისი-სპეციფიურ 
                      დღესასწაულებზე, რათა თქვენი გეგმები დაემთხვიონ ლოკალურ შეხვედრებს და სამსახურის გრაფიკებს.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ოფისის საათები:</Body1Strong> გეგმავეთ თქვენი შეხვედრები 
                      ზუსტი ინფორმაციით, როდისაა ოფისი პირობებში აქტიური ან დღის ბოლოს გაუქმებული.
                    </li>
                  </ul>
                  მეთითან ოსტინის ზეაქტიური ენერგიიდან სინგაპურის სტრატეგიულ ცენტრამდე, თითოეული ჩანართი გთავაზობთ გზას გაეცნოთ და შეუერთდეთ 
                  თქვენი კომპანიის გლობალურ გარემოს სხვაობრივ ლოკაციებს.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. ოფისის დეპარტამენტების შინაგანი დინამიკის კვლევა
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ოფისის დეპარტამენტების პროფესიონალურ ქსელში ორიენტირება TeamConnect-ში მარტივია. აი, როგორ შეუვარდეთ დეტალებში:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>იხილეთ ამ ოფისის დეპარტამენტები:</Body1Strong> აღმოაჩინეთ ყველა დეპარტამენტი, რომელიც აქმს ამ ოფისის 
                      სტრუქტურას, ამირჩიეთ ეს ოფცია More (...) მენიუდან.
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>ორგანიზაციული ხედვა:</Body1Strong> დააჭირეთ რაიმე დეპარტამენტს რათა გახმაურდეს მისი ორგშემდეგი ქსელი, 
                      სადაც ბურდენლევი ტრეკი გაძლევთ მარტივ გზავნილს: ოფისი {'>'} დეპარტამენტი {'>'} ქსელი.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>დეპარტამენტის ღრმად შეყვარება:</Body1Strong> გაიარეთ მეტი გამოკვლევა დეპარტამენტის მიერ, დააჭირეთ, 
                      მის მეტ მენიუზე (...) დეპარტამენტის სახელის გვერდით. აქ, შეძლებთ წვდომას ყველა თანამშრომლის სიაზე ამ დეპარტამენტში, მყოფი ოფისის კონტექსტში.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ბურდენლევი ნავიგაცია:</Body1Strong> გადადით დონეებზე მარტივად. თანამშრომლებიდან დეპარტამენტებისკენ ან ორგშემდეგი ქსელიდან, 
                      ბურდენლევი ტრეკი (ოფისი {'>'} დეპარტამენტი {'>'} თანამშრომელი {'>'} ქსელი) უნდა გაგიზიაროთ თქვენი ინსტინქტური და თავიდან ხედვაზე.
                    </li>
                  </ul>
                  TeamConnect-ის მრავალდონეანი გზები გიწვევთ ყოველ ფენაზე ოფისის დეპარტამენტების დასახელებაში, გასაგებად და გასაერთობად, რაც გენდობთ 
                  მთლიანი ნავიგაციური მართვა.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. კავშირის შექმნა ოფისის პერსონალთან ტიმკონექტში
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  გინდათ გაიგოთ, ვინ არის ოფისის პერსონალის პირები? პასუხი მხოლოდ ერთ დაჭერაშია TeamConnect-ში. დააჭირეთ სამ წერტილისანი სიმბოლოს (წერტილებს) 
                  არჩეული ოფისის სახელთან რათა საკითხავთა სიარული მოგიწიოთ:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>იხილეთ ამ ოფისის თანამშრომლები:</Body1Strong> ამოკითხეთ ყველა პროფესიონალის სია, 
                      ვისაც ეს ოფისი მუშაობის სახლად ა...
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>კონტაქტის ოფციები:</Body1Strong> თითოეული თანამშრომელის ჩანაწერი აღჭურვილია სწრაფ მოქმედების 
                      ხატებით, რაც გაძლევთ შესაძლებლობას ღიად ჩატების, ზარების ან ვიდეო კონფერენციების დაწყებას დაუშვანივით TeamConnect-დან, რაც ქმნის უშუალო
                      შიდა-ოფისის კომუნიკაციას.
                    </li>
                    <li><Body1Strong>ნავიგაცია:</Body1Strong> თანამშრომელთა სიადან უფრო ფართო ოფისის სიაში უკან წასასვლელად, უბრალოდ 
                      დააჭირეთ 'ოფისი' ბურდენლევ ტრეკში (ოფისი {'>'} თანამშრომელი), და თქვენ წაიყვანთ ოფისის მიმოხილვამდე.
                    </li>
                  </ul>
                  ამ ფუნქციებით, TeamConnect გამარტივებს მაშენებლობის განაწილებას ოფისებში მაჩვენებლის და მიწვდომის 
                  შემცირებას, გაყალბებთ მიერთებულ სამსახურს, შორეულად არცთუ მაღლა.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. გაქვთ თქვენი ოფისის ინფორმაცია აკლია ან ძველი?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ოფისის ინფორმაციის განახლება TeamConnect-ში შეიძლება განახლებული ოფისის რედაქტორების მიერ. თუ გაქვს აზრზენი რამე ოფისის რედაქტორებზე, 
                  ან გჭირდებათ დახმარება იმ ინფორმაციით, რომლიც HR-ის კომპეტენციაში შედის, HR-ის მხარდაჭერის გუნდი მზადაა
                  დაგეხმაროთ <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>პარამეტრების ხშირად დასმული კითხვები</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. ზოგადი პარამეტრების ზუსტი დამუშავების მნიშვნელობა TeamConnect-ში
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  დღესდღიური სამუშაო გარემოს დინამიური ლანდშაფტის ფონზე, კავშირში და კოორდინაციაში ყოფნა უფრო მნიშვნელოვანია, 
                  ვიდრე აქამდე. აი, სადაც TeamConnect-ის ზოგადი პარამეტრების განახლება არის ძირითადი:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>სამუშაო გარემოს არჩევა:</Body1Strong> განაახლეთ თქვენი სამუშაო გარემო, რომ ის დაასახელოს თქვენი 
                        მდგრადი
                        სიტუაცია, თქვენი ოფისიდან სიმშვიდის დატოვებით პუბლიკურ ადგილას გადაბირებით. ეს სიზუსტე თქვენი სამუშაო 
                        ლოკაციაში პირებს ინფორმაციას არ მხოლოდ თქვენზე დარეკვის უკეთესი გზის შესახებ, არამედ ეხმარებათ მათ
                        ჩაერთონ...
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>სამუშაო ადგილის მითითება:</Body1Strong> თქვენი მუშაობის ქალაქის გაზიარება არამარტო დაშორებას 
                        ახლებს შორეულ გუნდის შემადგენლობაში, არამედ ხსნის კარს სპონტანურ პროფესიულ შეკრებებზე ან საიდუმლო 
                        სოციალურ გართობებზე...
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>საათზონის სიზუსტე:</Body1Strong> როგორც გუნდები კონტინენტებს გადაჰყავთ, 
                      მნიშვნელოვანია, რომ...
                    </li>
                  </ul>
                  შედით 'პარამეტრები' {'>'} 'ზოგადი პარამეტრები' TeamConnect-ში, რომ შეამჩნიეთ ამ დეტალები. წამებით დაყოვნდეთ განახლებაში...
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. სამსახურში არჩევანის შეფასება TeamConnect-ში
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  სწორი არჩევანი Workplace-ის ჩამონათვალში გაპასუხებს TeamConnect-ის მორგებას თქვენი მიმდინარე სამსახურის სტატუსზე ზუსტად. გადატარეთ ინფორმაციის ხატულა ყველა ოფციის დეტალური ახსნად:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Break:</Body1Strong> ნიშნავს დროის განყოფს სამსახურიდან, შესვენების ან დღის ბოლოს.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office/Field-Office:</Body1Strong> აჩვენებს ყოფნას კომპანიის კავშირთან.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> მუშაობა სპეციალურად მზადებულ სახლის სამსახურში.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Public-Area:</Body1Strong> მუშაობა ოფისგარეშე გარემოში, რაც მიუთითებს გამორთულობის საჭიროებას.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Customer Workplace/Private Office:</Body1Strong> კლიენტთან საიტზე, საუბარის კონფიდენციალურობის შენიშვნით.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Commute/Business-Travel/Training:</Body1Strong> გზაში ან კონკრეტულ სამსახურის საქმიანობებში მერყეობა ოფისგარეშე.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Public-Holiday/Vacation/Various Leaves:</Body1Strong> ოფიციალურად გათიშული პირადი, ჯანმრთელობის ან დღეგრძელი მიზეზებით.</li>
                  </ul>
                  სამსახურის სწორი დაყენების არჩევანი დაგეხმარებათ გუნდთან სწორედ გაგებაში საუბარისას, აღიარებაში და პირადობის დაცვაში.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. 'Checked-In' სტატუსის გარკვევის ნავიგაცია TeamConnect-ში
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  'Checked-In' სტატუსი თანამშრომლების დირექტორიაში ვიზუალურად აჩვენებს ყველა მუშაობაში მყოფ თანამშრომელს. კავშირის რჩევა, რომელიც თქვენ გააკეთებთ:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Active Work Environments:</Body1Strong> არჩევანები Office, Field-Office, Home-Office, Remote-Workplace, Public-Area, Customer-Workplace,
                    Customer Private-Office, Commute, Business-Travel, და Training წარმოადგენს თქვენს სამუშაოში მერყეობას, რაც გახდის თქვენ 'Checked-In' და ხილვადია კოლეგებისთვის.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Non-Work Settings:</Body1Strong> Checked-Out, Break, Public-Holiday, Vacation, On-Leave, Maternity-Leave, ან Sick-Leave
                      სტატუსები არღვევს თქვენ 'Checked-In' სიაში ყოფნას, რაც ამართლებს მზადყოფნის და პასუხის დროის გარკვეული ლაპარაკით.
                  </li>
                  </ul>
                  ეს ფუნქცია ხმაურობს დამუშავების პერიოდებს პირად დროზე, იხმარება ეფექტურ გუნდურ თანამშრომლობაში და კომუნიკაციაში.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>მონაცემთა მართვა და პირადობის დაცვის ხშირად დასმული კითხვები</Subtitle2>
          <Body1>
            <p>
              დღესდღიურ ციფრულ ეპოქაში მნიშვნელოვანია გაიგოთ, როგორ მართება თქვენი მონაცემები. TeamConnect ტარებს საზღვრათა ჩარჩოზე მდგომარეობეს და მყარ მონაცემთა დაცვის პრაქტიკაში. 
              ქვემოთ გახლავთ ინფორმაცია იმაზე, როგორ ვამუშავებთ, ვინახავთ და ვაცავთ თქვენი მონაცემებით TeamConnect-ში, დადებით და დაცვაში.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. როგორ იცავს TeamConnect ჩემს პირად მონაცემებს?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect მზადაა გადაიცვალოს თქვენი პირადი ინფორმაცია რთული მიდგომით მონაცემთა უსაფრთხოებისთვის:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>ერთიანი ავტორიზაცია (SSO):</Body1Strong> დაცვისა და მომხმარებლის გამოცდილების გაუმჯობესებისათვის, TeamConnect 
                      შემოწმების ავტომატურ ერთიან ავტორიზაციას (SSO) თქვენს Microsoft 365 ანგარიშთან, რაც უსაფრთხო და ადვილად 
                      მართვის გამართლებას ადარებს.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>დაშიფვრა:</Body1Strong> ჩვენ ვზღუდავთ, თუ როდის დაიწურება TeamConnect-ში ყველა მონაცემი სანამ იგზავნება და 
                      სანამ ძირს ეკიდება, რაც არ უშვებს უფლებამოსილ პირებს დაშიფრულ მონაცემებში დაშვებას.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>მონაცემთა მინიმიზაცია:</Body1Strong> ჩვენი მონაცემთა შეგროვებას სრულად ზღუდავთ იმას, რაც აუცილებელია 
                      TeamConnect-ის ფუნქციონალური მიზნით. ჩვენ ვინახავთ მხოლოდ თქვენი Azure Entra ID-ს მომხმარებლის 
                      ძირითადი სახელი (UPN), რაც შესაძლოა ჰგავს თქვენს კომპანიის ელფოსტას.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>შეზღუდული წვდომა:</Body1Strong> წვდომა UPN-თან არის შეზღუდული თქვენი კავშირისადმი აბონენტის 
                      ადმინისთვის ჩვენს აბონენტური პორტალში, რაც პირადი მონაცემის საჭიროებას ხელს არ უწყობს
                      მხოლოდ უფლებამოსილ პირთან.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>სწრაფი მონაცემთა წაშლა:</Body1Strong> TeamConnect აწყობს უნაკლო მონაცემთა წაშლის სტრუქტურას UPN-ის მაღლა 
                      სამი კერძო პირობით: თუ აბონენტი გაუქმდება Subscription Admin-ის მიერ, თუ მომხმარებელი ამოიღება HR-ის 
                      მიერ TeamConnect-ში, ან თუ TeamConnect-ის აბონენტი გასდის ვადას. ეს უზღუდავს თქვენი პირადი 
                      მონაცემების განზრახ შენახვას ან საჭიროებაზე მეტს.
                    </li>                      
                  </ul>
                  <p>ამ ზომებით, TeamConnect ადგენს უმაღლესი სტანდარტების დაცვის მუდმივი ადგილის შენახვა, უფროსული და 
                  კონფიდენციური.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>           
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. გსურთ გაიგოთ, სად ინახება თქვენი მონაცემები TeamConnect-ის მიერ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect გამოდის ტრადიციული SaaS შეთავაზებების ფარგლებიდან ისე, რომ თქვენი მონაცემები არ ტოვებენ თქვენს ორგანიზაციას. 
                  უცხო ღრუბლის სერვერებზე მონაცემების შენახვის ჩვევას თავისუფლად, TeamConnect გენიალურად იყენებს თქვენი ორგანიზაციის Microsoft 365 SharePoint Online-ს, 
                  ყველა აპლიკაციის მონაცემების დასახლებლად. ეს დიდებული მიდგომა არა მარტო დამცავია მონაცემთა სიმართლისა, არამედ ამაგრებს უსაფრთხოებასაც, 
                  სრულიად შესაბამისად ჩვენს მტკიცე განსაზღვრელს თქვენი დაცულობისა და მკაცრი დაცვის ნორმების შესაბამისი დაცვისთვის.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. გაიზიარება თუ არა ჩემი მონაცემები სხვა მეორე მხრიდებთან?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  არა, მონაცემები სხვა მეორე მხრიდებთან არ გაიზიარება! Cubeet არის განუდგარი თქვენი მონაცემთა დაცვითა და უსაფრთხოებით. 
                  ჩვენ ვუზიარებთ რაიმე TeamConnect მონაცემს სხვა მხრის სამხედროსთან, გარეშე უარყოფითი თქვენი კლიენტებისგან ნათელი თანხმობის 
                  მიღებისა. ჩვენს უსაფრთხო გარემოში, მხოლოდ საჭირო მონაცემთა აღწერების შენახვა, როგორიცაა User Principal Name (UPN), ორგანიზაციის სახელი, 
                  ტენანტის სახელი და ტენანტის ID, მოხდება ჩვენი გამოწერის პორტალში. ამ ინფორმაციას საჭიროა სერვისის გაწევრიანებასა და 
                  დაცულია ჩვენი მკაცრი კერძოდასავლის პოლიტიკაში.
                  გარიგებაში დარჩით, ჩვენი მონაცემთა მართვის პრაქტიკები დიზაინირებულია ისე, რომ თქვენი მონაცემები დარჩეს კონფიდენციური, 
                  მართვის გამჭირვალობა და კადრები მტკიცედ დარჩენენ თქვენს ხელში.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. როგორ შესაბამისია TeamConnect მონაცემთა დაცვის რეგულაციებთან, როგორიცაა GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect შესაბამისია მონაცემთა დაცვის რეგულაციებთან, როგორიცაა GDPR, HIPAA, CCPA და სხვანი, რათა 
                  მოისპობს Microsoft 365 გარემოს წარმოშობილ დაცვის თვისებებს, 
                  სადაც ყველა კლიენტის მონაცემები დაცულია უსაფრთხებად. ტრადიციული SaaS მოდელების გარდა, TeamConnect არ ინახავს 
                  არცერთ კლიენტის მონაცემს თავისი სერვერებზე; მას მუშაობს თქვენი ორგანიზაციის Microsoft 365 გარემოში, 
                  მის ძლიერ დაცვის და დაცვის ნორმების მიმართულობით. ეს მიდგომა დაადგენს იმას, რომ თქვენი მონაცემთა მართვა დარჩება 
                  მისაღები დაცვის რეგულაციებთან GDPR, HIPAA მკაცრ​ი მონაცემთა დაცვა, CCPA კალიფორნიის მკვიდრებისათვის,
                  და სხვა გლობალური მონაცემთა დაცვის რეგულაციებთან. თქვენი არსებული Microsoft 365 დაყენებით სრულყოფილად 
                  ინტეგრირებამ TeamConnect-ის ხელში გადმოცემული, თქვენი ორგანიზაცია შეძლებს მონაცემთა მთლიანი კონტროლის დაცვას, 
                  მკაცრი მონაცემთა დაცვის კანონებისა და რეგულაციების დაცვით.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. როგორ მართავს TeamConnect Microsoft Teams-ის ჩეთების, ზარების, ვიდეო ზარების, Outlook-ის ელფოსტების და ტელეფონური სისტემის ზარების მონაცემებს?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect იყენებს Microsoft Graph API-ს, რათა გაამართლა Microsoft Teams-ში ჩეთის, ზარების, ვიდეო ზარების და Outlook-ის ელფოსტებისთვის პირდაპირი შემოქმედება,
                  რათა მომხმარებელს უზრუნველყოს უწყვეტი გამოცდილება. კრიტიკულად, TeamConnect იწყებს ამ კომუნიკაციებს, თუმცა ამას აკეთებს ჯერჯერობით კომუნიკაციაში ჩარევის გარეშე
                  და არ ფლობს შესაძლებლობას ამასთანავე ჩაწერას ან შენახვას. ყველა საუბარი, ზარი და ელფოსტა ხდება და იმართება Microsoft Teams-ის და Outlook-ის საიმედო ზოლებში,
                  Microsoft-ის 365 საძლევრიანი უსაფრთხოებისა და კონფიდენციალურობის სტანდარტებზე მკაცრი თანადგომით. ეს განავრცელებს TeamConnect-ის კომუნიკაციურ უნარებს,
                  სრულად იცავს თქვენი მონაცემების კონფიდენციალობისა და სრულობის.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            ჩვენს მონაცემთა პოლიტიკებსა და პრაქტიკებზე დეტალურ ნახევარზე საუბარისას შეიძლება {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>მომხმარებლის რჩევების წიგნის TeamConnect</Body1Strong></Link> ან 
            თქვენი ორგანიზაციის IT დეპარტამენტთან <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> კონკრეტული
            ნაწილების განხილვა.
          </p>
        </Body1>        
        <p></p>  
        <Subtitle1 className={styles.fontColor}>ჩვენ ვუსმენთ: გაუზიარეთ თქვენი შეფასება და იდეები</Subtitle1>
        <Body1>
          <p>
            თქვენი შეფასება ანაწილებს TeamConnect-ის ევოლუციას. თუ თქვენ აიდეათატრიალეთიანის ან გაქვთ რაიმე 
            შეფასება თქვენი მიმდინარე გამოცდილების, ჩვენ ვუსმენთ თქვენს ხმას. თქვენი შეტანება ძვირფასია ჩვენთვის, რათა მივაწვდით ყველაზე საუკეთესო 
            მსახურებას.
          </p>
        </Body1>      
        <Subtitle2>შეფასება და მხარის ნაწილებზე შესარჩევი</Subtitle2>
        <Body1>
          <p>
            მზად ხართ გაუზიარებას? გადადით ჩვენი {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              შეფასების გვერდზე
            </Link>
            . თუ ახალი მხარის ნაწილის შემოტანაა რჩევები ან არსებული ფუნქციების შეფასება, უბრალოდ აირჩიეთ
            სათანადო ოფცია ჩანაწერის ფორმის მენიუდან. თქვენი წვლილი აღწევს TeamConnect-ის მომავალის გამართლებას,
            რათა ის შესრულდება და გაანელებს თქვენი დაცვენილობას.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
