import PropTypes from 'prop-types';
import { provideFluentDesignSystem, fluentCard } from '@fluentui/web-components';
import { makeStyles, Body1, Body1Strong } from '@fluentui/react-components';
import moment from 'moment';

provideFluentDesignSystem()
  .register(
    fluentCard()
);

const useStyles = makeStyles({
  root: {
    width: '60vw', 
    marginLeft: '20vw', 
    marginRight: '20vw',
    marginBottom: '10vh',
    display: 'flex', 
    flexDirection: 'column',
  },
  rowInfo: {
    display: 'flex', 
    flexDirection: 'row', 
    columnGap: '5vw',
    height: '2vw',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    width: '40%',
    alignItems: 'center',
    justifyContent: 'right',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    alignItems: 'center',
    justifyContent: 'left',
  },
});

function SubscriptionDetail({ 
  subscription,
}) {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>Subscription ID:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{subscription?.id}</Body1>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong >Subscription Name:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{subscription?.subscriptionName}</Body1>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>Offer ID:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{subscription?.offerId}</Body1>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>Plan ID:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{subscription?.planId}</Body1>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>Quantity:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{subscription?.quantity}</Body1>
        </div>
      </div>

      <br />
      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1 className={styles.title}></Body1>
        </div>
        <div className={styles.content}>
          <Body1Strong>Subscription Detail</Body1Strong>
        </div>
      </div>
      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>Publisher ID:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{subscription?.subscription?.publisherId}</Body1>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong >SaaS Subscription Status:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{subscription?.subscription?.saasSubscriptionStatus}</Body1>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong >Plan ID:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{subscription?.subscription?.planId}</Body1>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>Term Unit:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{subscription?.subscription?.term?.termUnit}</Body1>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>Auto Renew:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>
            {
              (subscription?.subscription?.autoRenew === true) ? 'True' : 'False'
            }
          </Body1>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>Is Free Trial:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>
            {
              (subscription?.subscription?.isFreeTrial === true) ? 'True' : 'False'
            }
          </Body1>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>Created Date:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{moment(subscription?.subscription?.created).format('YYYY-MM-DD HH:mm:ss')}</Body1>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>Last Modified:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{moment(subscription?.subscription?.lastModified).format('YYYY-MM-DD HH:mm:ss')}</Body1>
        </div>
      </div>
      
      <br />
      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1 className={styles.title}></Body1>
        </div>
        <div className={styles.content}>
          <Body1Strong>Beneficiary</Body1Strong>
        </div>
      </div>
      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>Email ID:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{subscription?.subscription?.beneficiary?.emailId}</Body1>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>Object ID:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{subscription?.subscription?.beneficiary?.objectId}</Body1>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>Tenant ID:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{subscription?.subscription?.beneficiary?.tenantId}</Body1>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>PUID:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{subscription?.subscription?.beneficiary?.puid}</Body1>
        </div>
      </div>

      <br />
      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1 className={styles.title}></Body1>
        </div>
        <div className={styles.content}>
          <Body1Strong>Purchaser</Body1Strong>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>Email ID:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{subscription?.subscription?.purchaser?.emailId}</Body1>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>Object ID:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{subscription?.subscription?.purchaser?.objectId}</Body1>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>Tenant ID:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{subscription?.subscription?.purchaser?.tenantId}</Body1>
        </div>
      </div>

      <div className={styles.rowInfo}>
        <div className={styles.title}>
          <Body1Strong>PUID:</Body1Strong>
        </div>
        <div className={styles.content}>
          <Body1>{subscription?.subscription?.purchaser?.puid}</Body1>
        </div>
      </div>
    </div>
  );
};

SubscriptionDetail.propTypes = {
  subscription: PropTypes.object,
};

export default SubscriptionDetail;