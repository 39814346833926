import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();

  return (
    <>
      <Title3 className={styles.fontColor}>Κέντρο Βοήθειας και Συχνές Ερωτήσεις TeamConnect</Title3>
        <p><Body1>
          Καλώς ήρθατε στο Κέντρο Βοήθειας TeamConnect, σχεδιασμένο για να υποστηρίζει την εμπειρία σας με την εκτεταμένη εφαρμογή καταλόγου της εταιρείας μας. Εδώ, θα βρείτε πόρους για να βελτιστοποιήσετε τη χρήση του TeamConnect εντός των Microsoft Teams, ενισχύοντας την επικοινωνία στο δίκτυό σας.
        </Body1></p>
        <Subtitle2>Οδηγός Αναφοράς</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Βελτιωμένες Δυνατότητες Αναζήτησης: </Body1Strong>
              Βρείτε τους κατάλληλους συναδέλφους, τμήματα και απαραίτητες επαφές γρήγορα με τα ισχυρά μας εργαλεία αναζήτησης.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Οπτικά Οργανογράμματα: </Body1Strong>
              Πλοηγηθείτε στην ιεραρχία της οργάνωσής σας και κατανοήστε τις γραμμές αναφοράς με ευκολία μέσω των διαισθητικών οργανογραμμάτων.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Προσαρμοστικά Εργαλεία για τις Σημερινές Ανάγκες: </Body1Strong>
              Χρησιμοποιήστε σύγχρονες λειτουργίες προσαρμοσμένες στις απαιτήσεις των σύγχρονων χώρων εργασίας.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Βελτιστοποίηση της Εμπειρίας σας</Subtitle2>
        <Body1>
          <p>
            Το TeamConnect επαναστατίζει τον τρόπο που αλληλεπιδράτε μέσα στη δομή του οργανισμού σας, μετατρέποντας τις καθημερινές επικοινωνίες σε στρατηγικές συμμετοχές.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Λάβετε Βοήθεια & Συχνές Ερωτήσεις</Subtitle1>
        <Body1>
          <p>
            Βρείτε απαντήσεις σε συχνές ερωτήσεις για τις δυνατότητες και τις ρυθμίσεις του TeamConnect σε αυτή την αφιερωμένη ενότητα. Είτε πλοηγείστε στην εφαρμογή, προσαρμόζετε ρυθμίσεις, είτε αναζητάτε λύσεις σε προκλήσεις, το Κέντρο Βοήθειας μας είναι εδώ για να σας βοηθήσει αποτελεσματικά.
          </p>
        </Body1>
        <Body1>
          <p>
            Για πιο εις βάθος υποστήριξη ή συγκεκριμένη καθοδήγηση, παρακαλούμε συμβουλευτείτε το <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>Οδηγό Χρήστη του TeamConnect</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        <Subtitle2>Χρήση των FAQ του TeamConnect</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Δεν βρίσκετε το TeamConnect στην πλευρική γραμμή του Microsoft Teams;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Εάν το TeamConnect δεν είναι άμεσα ορατό ή εξαφανίστηκε από την πλευρική σας γραμμή στο Microsoft Teams, μπορείτε εύκολα να το καρφιτσώσετε για γρήγορη πρόσβαση. Ακολουθήστε απλώς αυτά τα απλά βήματα:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Ανοίξτε το Microsoft Teams και κάντε κλικ στο εικονίδιο '...' (+ Apps) στο κάτω μέρος της πλευρικής γραμμής.</li>
                    <li className={styles.bulletBottomMargin}>Αναζητήστε το 'TeamConnect' στην μπάρα αναζήτησης στο επάνω μέρος του αναδυόμενου παραθύρου.</li>
                    <li className={styles.bulletBottomMargin}>Κάντε δεξί κλικ στην εφαρμογή TeamConnect από τα αποτελέσματα αναζήτησης και επιλέξτε 'Καρφίτσωμα'.</li>
                  </ol>
                  Το TeamConnect θα καρφιτσωθεί τώρα στην πλευρική σας γραμμή, κάνοντάς το προσβάσιμο με ένα μόνο κλικ!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Αντιμετωπίζετε τεχνικά προβλήματα με το TeamConnect; Ιδού πώς να λάβετε βοήθεια.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Αν αντιμετωπίσετε οποιαδήποτε τεχνικά προβλήματα με το TeamConnect εντός του Microsoft Teams, η ομάδα υποστήριξης IT του οργανισμού σας είναι εδώ για να βοηθήσει.
                  Επικοινωνήστε μαζί τους στο <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, και θα σας βοηθήσουν άμεσα.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Δεν μπορείτε να δείτε τα στοιχεία του εργαζόμενού σας;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Εάν το προφίλ σας φαίνεται κάπως άδειο με μόνο το όνομα και το email σας να εμφανίζονται, είναι πιθανό ότι η ομάδα HR δεν έχει προσθέσει ακόμη τα υπόλοιπα στοιχεία του εργαζομένου σας στο TeamConnect.
                  Δεν χρειάζεται να ανησυχείτε! Ένα γρήγορο μήνυμα στην ομάδα υποστήριξης HR θα ξεκινήσει τη διαδικασία. Είναι διαθέσιμοι για να συμπληρώσουν το προφίλ σας με όλες τις σημαντικές λεπτομέρειες.
                  Επικοινωνήστε στο <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> και παρακολουθήστε το προφίλ σας να ζωντανεύει!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Ποιες επιλογές γλώσσας είναι διαθέσιμες στο TeamConnect;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Το TeamConnect υιοθετεί την παγκόσμια πολυμορφία των Microsoft Teams
                  υποστηρίζοντας όλες τις γλώσσες τους. Είτε προτιμάτε τα Αραβικά ή τα
                  Ελληνικά, τα Ιαπωνικά ή τα Σουηδικά, έχετε έναν κόσμο επιλογών στα δάχτυλά
                  σας, που περιλαμβάνουν:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Αραβικά, Βουλγαρικά, Κινέζικα (Απλοποιημένα και Παραδοσιακά),
                      Κροατικά, Τσεχικά, Δανέζικα, Ολλανδικά, Αγγλικά, Εσθονικά, Φινλανδικά,
                      Γαλλικά, Γερμανικά, Ελληνικά, Εβραϊκά, Ουγγρικά, Ινδονησιακά, Ιταλικά,
                      Ιαπωνικά, Κορεατικά, Λετονικά, Λιθουανικά, Νορβηγικά (Bokmal),
                      Πολωνικά, Πορτογαλικά (Βραζιλία και Πορτογαλία), Ρουμανικά, Ρωσικά,
                      Σερβικά (Λατινικά), Σλοβακικά, Σλοβενικά, Ισπανικά, Σουηδικά,
                      Ταϊλανδέζικα, Τουρκικά, Ουκρανικά, Βιετναμέζικα.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Για να προσαρμόσετε την εμπειρία σας στο TeamConnect στην προτιμώμενη
                  γλώσσα σας, παρακαλώ ρυθμίστε τις γλωσσικές ρυθμίσεις απευθείας στα
                  Microsoft Teams, καθώς το TeamConnect ενσωματώνεται πλήρως με αυτές τις
                  ρυθμίσεις:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Πηγαίνετε στις ρυθμίσεις των Microsoft Teams κάνοντας κλικ στα τρία
                      σημεία δίπλα στην εικόνα του προφίλ σας στην κορυφή, και στη συνέχεια
                      επιλέξτε 'Εμφάνιση και προσβασιμότητα'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Κυλίστε στην ενότητα 'Γλώσσα' και επιλέξτε την προτιμώμενη γλώσσα σας
                      από το αναπτυσσόμενο μενού.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Επιβεβαιώστε τις αλλαγές σας κάνοντας κλικ στο κουμπί 'Αποθήκευση και
                      επανεκκίνηση' για να εφαρμόσετε τις νέες ρυθμίσεις γλώσσας.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Σημείωση: </Body1Strong>
                <Body1>
                  Οι αλλαγές που έχετε κάνει στις ρυθμίσεις γλώσσας στα Microsoft Teams θα
                  ενημερώσουν απρόσκοπτα την εμπειρία σας στο TeamConnect επίσης. Έχετε
                  υπόψη ότι η αλλαγή της γλώσσας στα Teams μπορεί επίσης να προσαρμόσει τη
                  μορφή της ώρας και της ημερομηνίας σας. Αυτή τη στιγμή, το TeamConnect
                  εξελίσσεται για τη συγγραφή από δεξιά προς τα αριστερά. Είμαστε
                  ενθουσιασμένοι για αυτές τις βελτιώσεις και θα σας κρατήσουμε
                  ενημερωμένους καθώς γίνονται διαθέσιμες.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Πώς μπορώ να αλλάξω το θέμα στο TeamConnect;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Το TeamConnect συγχρονίζεται με τις προτιμήσεις θέματος του Microsoft Teams, περιλαμβάνοντας τις επιλογές Φωτεινό, Σκοτεινό και Υψηλής Αντίθεσης. Για να προσαρμόσετε το θέμα σας:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Πρόσβαση στις ρυθμίσεις του Microsoft Teams κάνοντας κλικ στις τρεις τελείες δίπλα στην εικόνα προφίλ σας στην κορυφή.</li>
                    <li className={styles.bulletBottomMargin}>Επιλέξτε 'Εμφάνιση και προσβασιμότητα' για να εξερευνήσετε τις επιλογές θέματος.</li>
                    <li className={styles.bulletBottomMargin}>Επιλέξτε το προτιμώμενο θέμα από τις διαθέσιμες επιλογές για να ενημερώσετε αμέσως το περιβάλλον του TeamConnect ώστε να ταιριάζει.</li>
                  </ol>
                  Η επιλογή θέματος στο Microsoft Teams θα εφαρμοστεί αυτόματα στο TeamConnect, διασφαλίζοντας μια συνεπή οπτική εμπειρία σε όλο τον χώρο εργασίας σας.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Εξερεύνηση των Κουμπιών Ενέργειας Επαφής του TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Σε όλο το TeamConnect, όπου κι αν βρείτε τα στοιχεία επικοινωνίας ενός υπαλλήλου, θα ανακαλύψετε επίσης τα βολικά κουμπιά γρήγορης πρόσβασης. Αυτά τα εύχρηστα εργαλεία
                  έχουν σχεδιαστεί για να βελτιστοποιούν την επικοινωνία σας μέσα στο οικοσύστημα της Microsoft, διασφαλίζοντας ότι βρίσκεστε πάντα με ένα κλικ μακριά από τη σύνδεση.
                  Ακολουθεί ο περιεκτικός οδηγός σας για την αποτελεσματική χρήση αυτών των κουμπιών:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Εικονίδιο συνομιλίας:</Body1Strong> Κάντε κλικ για να ξεκινήσετε μια άμεση συνομιλία στο Microsoft Teams. Εάν πρέπει να επιστρέψετε στο TeamConnect μετά την έναρξη μιας συνομιλίας, απλώς χρησιμοποιήστε το πίσω βέλος στην εφαρμογή Desktop, το κουμπί πίσω στην εφαρμογή για κινητά ή το κουμπί επιστροφής του προγράμματος περιήγησής σας.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Εικονίδιο email:</Body1Strong> Ανοίγει ένα νέο πρόχειρο email του Outlook με τη διεύθυνση του παραλήπτη έτοιμη στο πεδίο "Προς:". Εάν το Outlook δεν ξεκινήσει, αξίζει να επικοινωνήσετε με το τμήμα IT για τυχόν περιορισμούς.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Εικονίδιο βιντεοκλήσης:</Body1Strong> Εκκινεί μια βιντεοκλήση πρόσωπο με πρόσωπο χρησιμοποιώντας το Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Εικονίδιο κλήσης:</Body1Strong> Ξεκινά μια φωνητική κλήση του Microsoft Teams, κάνοντας τις γρήγορες συζητήσεις απρόσκοπτες.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Εικονίδιο οργανογράμματος:</Body1Strong> Αποκαλύπτει τη θέση του υπαλλήλου στην οργανωτική ιεραρχία, συμπεριλαμβανομένου του διευθυντή του και των άμεσων αναφορών.</li>
                    <li><Body1Strong>Περισσότερα (...) Εικονίδιο:</Body1Strong> Αποκαλύπτει περαιτέρω επιλογές:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Συναντήστε τώρα:</Body1Strong> Ανοίγει το Microsoft Teams για να ξεκινήσει μια άμεση συνάντηση με τον υπάλληλο.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Νέα σύσκεψη:</Body1Strong> Σας κατευθύνει στο Microsoft Teams για να προγραμματίσετε μια νέα συνάντηση.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Προσθήκη επαφής:</Body1Strong> Προσθέτει τα στοιχεία επικοινωνίας του υπαλλήλου από το TeamConnect στη νέα σας εφαρμογή Microsoft Teams People, εμπλουτίζοντας τη λειτουργία κλήσης.</li>
                      </ul>
                    </li>
                  </ul>
                  Αυτά τα διαισθητικά κουμπιά είναι κατασκευασμένα για να διασφαλίζουν ότι μπορείτε να συνδεθείτε αποτελεσματικά με συναδέλφους, είτε για μια γρήγορη συνομιλία, είτε για ένα email, είτε για να προγραμματίσετε μελλοντικές συναντήσεις.
                </Body1>
               </AccordionPanel>
             </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Πραγματοποίηση κλήσεων μέσω TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Θέλετε να πραγματοποιήσετε μια κλήση; Το TeamConnect απλοποιεί την επικοινωνία σας ενσωματώνοντας το Microsoft Teams.
                  Απλώς κάντε κλικ στον επιθυμητό αριθμό τηλεφώνου στο TeamConnect και voilà, εμφανίζεται η διεπαφή κλήσεων του Microsoft Teams,
                  έτοιμο να καλέσετε απρόσκοπτα.
                  <p>
                  <Body1Strong>Σημείωση: </Body1Strong>
                  Αντιμετωπίζετε προβλήματα με την πραγματοποίηση κλήσεων; Αυτό μπορεί να οφείλεται στις πολιτικές πληροφορικής του οργανισμού σας ή στις
                  χρήση τηλεφωνικού συστήματος άλλου από το τηλεφωνικό σύστημα της Microsoft Teams. Έλεγχος με το τμήμα IT στη διεύθυνση <Link href={`mailto:${itSupport}`}>
                  Το <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, μπορεί να ρίξει λίγο φως στο θέμα.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem> 
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Υπάρχουν κόστη συνδεδεμένα με τις κλήσεις μέσω του TeamConnect;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Το TeamConnect βελτιώνει την εμπειρία τηλεφωνικών κλήσεων χρησιμοποιώντας το Microsoft Teams. Ορίστε τι πρέπει να ξέρετε για τα κόστη σχετικά με τις κλήσεις:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Για κλήσεις προς εργασιακά τηλέφωνα υπαλλήλων, τηλέφωνα τμημάτων ή αριθμούς έκτακτης ανάγκης εντός της οργάνωσής σας (εσωτερικοί αριθμοί),
                      συνήθως <Body1Strong>δεν υπάρχουν κόστη</Body1Strong> όταν χρησιμοποιείτε το σύστημα τηλεφώνων Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Για εξωτερικές κλήσεις, όπως προς κινητά τηλέφωνα ή εξωτερικές γραμμές, εφαρμόζονται <Body1Strong>οι συνήθεις τιμές</Body1Strong> του τηλεφωνικού συστήματος της οργάνωσής σας.
                      Αυτές οι τιμές διαφέρουν βάσει του συγκεκριμένου πακέτου υπηρεσιών σας με το Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Σημείωση:</Body1Strong> Αν αντιμετωπίσετε οποιαδήποτε προβλήματα ή έχετε ερωτήσεις για τις χρεώσεις κλήσεων, είναι καλύτερα να συμβουλευτείτε το 
                    IT τμήμα στο <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> για λεπτομερείς πληροφορίες προσαρμοσμένες 
                    στη διάταξη και τις πολιτικές της οργάνωσής σας.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Παρατηρήσατε ασυνέπεια με την κατάσταση παρουσίας 'Εκτός Γραφείου' στο TeamConnect;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Παρατηρήσατε ότι η κατάσταση παρουσίας 'Εκτός Γραφείου' από το Microsoft 365 δεν αντανακλάται σωστά στο TeamConnect; 
                  Έχουμε τη λύση. Η δυνατότητα συγχρονισμού της κατάστασης παρουσίας 'Εκτός Γραφείου' έχει ήδη υλοποιηθεί στο TeamConnect.
                  Περιμένουμε την εφαρμογή αυτής της δυνατότητας από τη Microsoft. Μόλις συμβεί αυτό, η κατάσταση παρουσίας 'Εκτός Γραφείου' 
                  θα αντανακλάται ακριβώς στο TeamConnect, συμφωνούμενη με τους δείκτες παρουσίας του Microsoft Teams για μια ομαλή εμπειρία.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>Συχνές Ερωτήσεις Καταλόγου Υπαλλήλων</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Εισαγωγή Καταλόγου Υπαλλήλων:</Body1Strong> Πλοηγηθείτε στο επαγγελματικό τοπίο της οργάνωσής σας με τον Κατάλογο Υπαλλήλων, 
                το κεντρικό σημείο για λεπτομερείς πληροφορίες υπαλλήλων, δυναμικές των χώρων εργασίας και άμεση συνδεσιμότητα με τα προφίλ και τις διαχειριστικές σχέσεις των συναδέλφων σας.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Χρειάζεστε να προσθέσετε, ενημερώσετε ή διορθώσετε τα στοιχεία του υπαλλήλου σας;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Για να προσθέσετε, να ενημερώσετε, ή να διορθώσετε τα στοιχεία του υπαλλήλου σας στο TeamConnect, παρακαλούμε επικοινωνήστε με την ομάδα υποστήριξης HR της οργάνωσής σας. 
                  Είναι έτοιμοι να σας βοηθήσουν στο <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Τι συμβαίνει όταν κάνω κλικ στο όνομα ενός εργαζομένου στον κατάλογο;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Κάνοντας κλικ στο όνομα ενός εργαζομένου στον κατάλογο εργαζομένων του TeamConnect ανοίγει μια αναλυτική σελίδα προφίλ για το άτομο αυτό. Αυτό το προφίλ παρέχει 
                  ολοκληρωμένες πληροφορίες για τον εργαζόμενο, περιλαμβάνοντας αλλά όχι περιορισμένο σε, την θέση εργασίας, το τμήμα, την τοποθεσία του γραφείου, τις πληροφορίες επικοινωνίας και άλλα. Είναι σχεδιασμένο για να σας δίνει μια πλήρη επισκόπηση του επαγγελματικού ρόλου του εργαζομένου και πως εντάσσεται στον οργανισμό, καθιστώντας ευκολότερη την κατανόηση των ευθυνών του και πως θα μπορούσατε να συνεργαστείτε μαζί του.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Τι πληροφορίες περιλαμβάνονται στο προφίλ ενός εργαζομένου;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Κάθε προφίλ εργαζομένου στο TeamConnect αποτελεί μια ολοκληρωμένη εικόνα, προσφέροντας ένα πλήθος πληροφοριών για την επαγγελματική ζωή του ατόμου.
                  Ιδού τι θα βρείτε:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Βασικές Λεπτομέρειες:</Body1Strong> Όνομα, θέση εργασίας, τμήμα και τοποθεσία θυγατρικής.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Δομή Αναφοράς:</Body1Strong> Πληροφορίες για τους άμεσους και λειτουργικούς αναφερόμενους, 
                      περιλαμβάνοντας τους διοικητικούς και λειτουργικούς διευθυντές.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Κατάσταση Εργασίας:</Body1Strong> Τρέχουσα κατάσταση εργασίας όπως Αδεια, Μετακίνηση, 
                      Επαγγελματικό Ταξίδι κλπ.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Χώρος Εργασίας & Τοπική Ώρα:</Body1Strong> Η τρέχουσα τοποθεσία εργασίας και 
                      η τοπική ζώνη ώρας του εργαζόμενου.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Λεπτομέρειες Επικοινωνίας:</Body1Strong> Τηλέφωνο εργασίας, κινητό τηλέφωνο και διεύθυνση email.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Διεύθυνση Γραφείου:</Body1Strong> Πλήρης τοποθεσία γραφείου 
                      περιλαμβάνοντας το όνομα του κτηρίου, διεύθυνση και Google χάρτη.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Σχετικά με:</Body1Strong> Μια σύντομη προσωπική παρουσίαση ή επαγγελματικό βιογραφικό.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Εργασιακές Σχέσεις:</Body1Strong> Λεπτομέρειες της δομής αναφοράς 
                      του εργαζομένου και των σχέσεων ομάδας.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Κοινωνικοί Σύνδεσμοι & Γλώσσες:</Body1Strong> Σύνδεσμοι προς επαγγελματικά κοινωνικά προφίλ
                      και γλωσσικές καταρτίσεις.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Δεξιότητες:</Body1Strong> Λίστα επαγγελματικών δεξιοτήτων και τομέων ειδίκευσης.</li>
                  </ul>
                  Επιπλέον, το προφίλ προσφέρει ενεργές επιλογές για σύνδεση μέσω chat, email, βίντεο κλήσης ή προγραμματισμού συναντήσεων, μαζί με 
                  γρήγορη πρόσβαση στο διάγραμμα του οργανισμού για ευρύτερο οργανωτικό πλαίσιο.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Πώς μπορώ να ενημερώσω τις πληροφορίες του προφίλ μου;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Η προσωποποίηση του προφίλ σας είναι εύκολη στο TeamConnect. Ιδού πώς μπορείτε να το κάνετε:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Πλοηγηθείτε στην καρτέλα <Body1Strong>Υπάλληλος</Body1Strong> και κάντε κλικ στο <Body1Strong>Άβαταρ</Body1Strong> σας για να ανοίξετε το προφίλ σας.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Στην πάνω αριστερή γωνία, θα βρείτε τον σύνδεσμο <Body1Strong>"Επεξεργαστείτε το προφίλ σας"</Body1Strong>. 
                        Κάνοντας κλικ σε αυτό θα ανοίξει το παράθυρο επεξεργασίας για το προφίλ σας.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Εδώ, μπορείτε να βελτιώσετε το προφίλ σας προσθέτοντας ή ενημερώνοντας το τμήμα <Body1Strong>Σχετικά με Εσάς</Body1Strong>, τους συνδέσμους κοινωνικών δικτύων και τον αριθμό τηλεφώνου σας. 
                        Αυτό διευκολύνει τους συναδέλφους σας να συνδεθούν ή να σας ακολουθήσουν.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Μην ξεχάσετε να πατήσετε το κουμπί <Body1Strong>Αποθήκευση</Body1Strong> για να εφαρμόσετε τις αλλαγές σας.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Κατεβείτε στο διάλογο για να προσθέσετε τις <Body1Strong>Γλώσσες</Body1Strong> και την <Body1Strong>Επάρκεια</Body1Strong> σας, 
                        καθώς και τις <Body1Strong>Δεξιότητες</Body1Strong> σας. Η κοινοποίηση αυτών των πληροφοριών βοηθά τους συναδέλφους σας να κατανοήσουν 
                        τους τομείς ειδίκευσής σας και τις γλώσσες με τις οποίες μπορείτε να επικοινωνήσετε.
                      </li>
                    </ol>
                  Η ενημέρωση του προφίλ σας διατηρεί τις πληροφορίες σας ενημερωμένες και ενισχύει τις συνδέσεις εντός του οργανισμού σας.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Ενημερώσεις Γραφείου, Χώρου Εργασίας και Ζώνης Ώρας στο TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Η διατήρηση των πληροφοριών για τον χώρο εργασίας, το σημείο εργασίας και τη ζώνη ώρας ενημερωμένες είναι κρίσιμη για την ομαλή συνεργασία στο Microsoft Teams.
                  Αυτές οι ενημερώσεις αντανακλώνται άμεσα στο προφίλ και τις καταχωρήσεις σας στο TeamConnect, επιτρέποντας στους συναδέλφους να καταλάβουν γρήγορα τον τόπο εργασίας σας
                  και τις καλύτερες ώρες για να επικοινωνήσετε μαζί σας, ενισχύοντας την ομαδική εργασία σε διάφορες περιοχές και ζώνες ώρας.
                  <p></p>
                  Είστε έτοιμοι για ενημέρωση των στοιχείων σας; Είναι εύκολο στο TeamConnect! Πηγαίνετε στις 'Ρυθμίσεις' στο κύριο Μενού και πλοηγηθείτε στις 'Γενικές Ρυθμίσεις'.
                  Εδώ, μπορείτε να ενημερώσετε ομαλά το Γραφείο και τη Ζώνη Ώρας σας, και να καθορίσετε το Χώρο Εργασίας σας για την ημέρα. Θυμηθείτε να αποθηκεύσετε τις αλλαγές σας για
                  να κρατήσετε όλους ενημερωμένους και να εξασφαλίσετε αποτελεσματική συνεργασία, όπου και αν βρίσκεστε!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Εντοπίσατε κάτι περίεργο στο προφίλ ενός συναδέλφου;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Αν ανακαλύψετε τυχόν λεπτομέρειες στο προφίλ ενός συναδέλφου που δεν φαίνονται σωστές, το καλύτερο πρώτο βήμα είναι να τους δώσετε μια ευγενική προειδοποίηση. Μια γρήγορη συζήτηση ή email θα κάνει τη δουλειά! Αν για κάποιο λόγο οι πληροφορίες δεν ενημερωθούν, μην ανησυχείτε—απλώς ενημερώστε την ομάδα υποστήριξης HR στην <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> και θα χαρούν να μπουν στη διαδικασία και να τακτοποιήσουν τα πράγματα.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Κατανόηση Ομάδων Ετικετών: Όλοι, Αγαπημένοι, Συνδεδεμένοι & Νέοι Υπάλληλοι
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Όλοι:</Body1Strong> Αυτή η προεπιλεγμένη ετικέτα εμφανίζει κάθε υπάλληλο στον οργανισμό σας, 
                      παρέχοντας μια εξαντλητική άποψη της ομάδας.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Αγαπημένοι:</Body1Strong> Κάντε κλικ εδώ για να φιλτράρετε την προβολή σας σε εκείνους τους συναδέλφους που έχετε 
                      επισημάνει ως 'Αγαπημένους', καθιστώντας ευκολότερο τον γρήγορο συνδυασμό με κύριες επαφές.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Συνδεδεμένοι:</Body1Strong> Αυτή η ετικέτα υποδηλώνει υπαλλήλους που εργάζονται ενεργά, διακρίνοντας 
                      από απλή διαδικτυακή παρουσία στο Microsoft Teams. Όταν έχουν συνδεθεί, οι υπάλληλοι δηλώνουν ότι εργάζονται και μοιράζονται την τρέχουσα τοποθεσία εργασίας 
                      τους κατά τη διάρκεια της ημέρας. Αυτό μπορεί να περιλαμβάνει μια ποικιλία χώρων εργασίας όπως Γραφείο, Πεδίο-Γραφείο, Οικία-Γραφείο, Τηλε-Εργασία, 
                      Πελάτης-Χώρος, Πελάτης-Ιδιωτικό-Γραφείο, Δημόσια-Περιοχή, Μετακίνηση, Επαγγελματικό-Ταξίδι ή ακόμα και Κατάρτιση. Ως εκ τούτου, η κατάσταση 'Συνδεδεμένοι' παρέχει μια σαφή ένδειξη 
                      του ότι είναι ενεργά στην εργασία, προσφέροντας στους συναδέλφους μια ακριβή κατανόηση του τρέχοντος εργασιακού πλαισίου και διαθεσιμότητας τους.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Νέοι Υπάλληλοι:</Body1Strong> Εντοπίστε τα νέα πρόσωπα στο TeamConnect με αυτήν την ετικέτα, 
                      επισημαίνοντας υπαλλήλους που έχουν προστεθεί στην εφαρμογή κατά τις τελευταίες 30 ημέρες. Συνήθως, αυτό συμβαδίζει με τις πρόσφατες προσθήκες στον οργανισμό σας, βοηθώντας 
                      σας να καλωσορίσετε νέα μέλη ομάδας.
                    </li>
                  </ul>
                  Οι ομάδες αυτών των ετικετών είναι σχεδιασμένες για να απλοποιήσουν την πλοήγηση και να ενισχύσουν την εμπειρία σας στο TeamConnect, διασφαλίζοντας ότι μπορείτε πάντα 
                  να βρίσκετε και να επικοινωνείτε με τους συναδέλφους σας αποτελεσματικά.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Αποκρυπτογράφηση των Εικονιδίων Δίπλα στο Πλαίσιο Αναζήτησης
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ο Επαγγελματικός Κατάλογος Υπαλλήλων της TeamConnect είναι σχεδιασμένος για ευελιξία και ευκολία, παρουσιάζοντας τέσσερα εικονίδια που προσφέρουν διαφορετικές προβολές 
                  και γρήγορη πρόσβαση σε κύριες λεπτομέρειες. Ιδού τι αποκαλύπτει καθένα από αυτά τα εικονίδια:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Προβολή Λίστας:</Body1Strong> Η προεπιλεγμένη σας προβολή, παρουσιάζει όλα τα μέλη της οργάνωσης 
                      σε μια απλή λίστα.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Προβολή Μικρής Κάρτας:</Body1Strong> Μια συμπαγής εναλλακτική, αυτή η προβολή μετατρέπει τη 
                      λίστα σε μικροσκοπικές κάρτες υπαλλήλων.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Προβολή Μεγάλης Κάρτας:</Body1Strong> Επιλέξτε για περισσότερες λεπτομέρειες με μεγαλύτερες κάρτες, που περιλαμβάνουν 
                      επιπλέον επιλογές επικοινωνίας όπως αριθμοί τηλεφώνου και κινητών για εύκολη κλήση.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Οργανόγραμμα:</Body1Strong> Οπτικοποιήστε τη δομή της εταιρείας με την προβολή του Οργανογράμματος, 
                      ιδανικό για την κατανόηση των σχέσεων αναφοράς και την πλοήγηση στο οργανωτικό τοπίο.</li>
                  </ol>
                    Ρυθμίστε ανάμεσα σε αυτά τα εικονίδια για να βρείτε την προβολή που ταιριάζει καλύτερα στις ανάγκες σας εκείνη τη στιγμή, ανεξάρτητα από το εάν ψάχνετε για γρήγορες πληροφορίες ή βαθύτερες διεισδύσεις.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Για γρήγορες αναζητήσεις συναδέλφων, πού πρέπει να κοιτάξω;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ψάχνετε για έναν συνάδελφο; Απλά κατευθυνθείτε στο πλαίσιο αναζήτησης TeamConnect, το οποίο βρίσκεται στην άνω δεξιά πλευρά δίπλα στο εικονίδιο του Οργανογράμματος. 
                  Αρχίστε να πληκτρολογείτε το όνομά τους, και παρατηρήστε καθώς τα αποτελέσματα διευκρινίζονται με κάθε γράμμα. Θυμηθείτε, για αναζητήσεις στο TeamConnect, αγνοήστε τη μπάρα αναζήτησης του Microsoft Teams 
                  στο επάνω μέρος—το ειδικό μας πλαίσιο αναζήτησης είναι ο καλύτερος τρόπος!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Πώς λειτουργεί η αλφαβητική αναζήτηση για την εύρεση υπαλλήλων;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ο Κατάλογος Υπαλλήλων του TeamConnect περιλαμβάνει μια βολική αλφαβητική δυνατότητα αναζήτησης για να σας βοηθήσει να εντοπίσετε γρήγορα τους υπαλλήλους ανάλογα με το πρώτο τους όνομα. 
                  Απλώς κάντε κλικ σε ένα γράμμα από το αλφάβητο που εμφανίζεται στο επάνω μέρος του καταλόγου, και το TeamConnect θα σας εμφανίσει αμέσως όλους τους υπαλλήλους 
                  των οποίων τα πρώτα ονόματα αρχίζουν με αυτό το γράμμα. Για να επιστρέψετε στην πλήρη λίστα των υπαλλήλων, απλώς απενεργοποιήστε το επιλεγμένο γράμμα. Αυτή η διαισθητική 
                  δυνατότητα καθιστά την πλοήγηση μέσω μεγάλων λιστών υπαλλήλων πολύ εύκολη, εξασφαλίζοντας ότι μπορείτε να βρείτε συναδέλφους γρήγορα και αποτελεσματικά.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Πώς μπορώ να προσαρμόσω τον αριθμό των υπαλλήλων που εμφανίζονται ανά σελίδα;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Το TeamConnect σας επιτρέπει να προσαρμόσετε πόσα προφίλ υπαλλήλων βλέπετε ανά σελίδα, κάνοντας πιο εύκολη την πλοήγηση μέσα από τον Κατάλογο Υπαλλήλων. 
                  Στο κάτω μέρος κάθε σελίδας, θα βρείτε επιλογές σελιδοποίησης: 10, 50, 100, 500, 1000 και 5000. Η προεπιλεγμένη ρύθμιση εμφανίζει 10 προφίλ ανά σελίδα. 
                  Για να δείτε περισσότερα προφίλ σε μία σελίδα, απλώς επιλέξτε τον αριθμό που προτιμάτε από αυτές τις επιλογές. Αυτή η δυνατότητα παρέχει ευελιξία στον τρόπο 
                  που περιηγείστε στον κατάλογο, επιτρέποντάς σας να προσαρμόσετε την εμφάνιση σύμφωνα με τις συγκεκριμένες ανάγκες ή προτιμήσεις σας.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Πώς μπορώ να ταξινομήσω τις πληροφορίες υπαλλήλων στην Προβολή Λίστας;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Στην Προβολή Λίστας του Καταλόγου Υπαλλήλων του TeamConnect, έχετε την ευελιξία να ταξινομήσετε τις πληροφορίες των υπαλλήλων σύμφωνα με τις ανάγκες σας. Οι στήλες—Όνομα Υπαλλήλου, 
                  Τίτλος Εργασίας, Τμήμα, Τοποθεσία Γραφείου και Χώρος Εργασίας—είναι όλες ταξινομήσιμες. Απλά κάντε κλικ στην κεφαλίδα της στήλης που θέλετε να ταξινομήσετε. Ένα κλικ θα ταξινομήσει 
                  τη στήλη σε αύξουσα σειρά, και ένα δεύτερο κλικ θα ταξινομήσει σε φθίνουσα σειρά. Από προεπιλογή, ο κατάλογος ταξινομείται ανά Όνομα Υπαλλήλου σε αύξουσα σειρά, 
                  με βάση το πρώτο όνομα. Αυτή η δυνατότητα ταξινόμησης σας βοηθά να οργανώσετε γρήγορα και να δείτε τις λεπτομέρειες των υπαλλήλων με τον τρόπο που καλύτερα 
                  εξυπηρετεί τον σκοπό σας, είτε αναζητάτε κάποιον συγκεκριμένο είτε χρειάζεστε να ομαδοποιήσετε τους υπαλλήλους ανά ένα συγκεκριμένο κριτήριο όπως το τμήμα ή την τοποθεσία.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Συχνές Ερωτήσεις Καταλόγου Τμημάτων</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Εισαγωγή στον Κατάλογο Τμημάτων:</Body1Strong> Ο Κατάλογος Τμημάτων είναι η πύλη σας για την κατανόηση του πολύπλοκου δικτύου της δομής του
              οργανισμού σας, προσφέροντας μια σαφή άποψη των ιεραρχιών των τμημάτων, της διοικητικής ηγεσίας και των διαδρομών για άνετη επικοινωνία των τμημάτων.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Πώς λειτουργούν οι ετικέτες 'Όλα', 'Αγαπημένα' και 'Το Τμήμα Σας' στον Κατάλογο Τμημάτων;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Στον Κατάλογο Τμημάτων του TeamConnect, οι ετικέτες σας βοηθούν να πλοηγηθείτε και να φιλτράρετε γρήγορα τις πληροφορίες των τμημάτων. Ιδού τι αντιπροσωπεύει κάθε ετικέτα:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Όλα:</Body1Strong> Αυτή η ετικέτα εμφανίζει όλα τα τμήματα εντός του οργανισμού σας, παρέχοντας μια
                      ολοκληρωμένη επισκόπηση.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Αγαπημένα:</Body1Strong> Χρησιμοποιήστε αυτή την ετικέτα για να δείτε μια επιλεγμένη λίστα τμημάτων που έχετε σημειώσει
                      ως 'Αγαπημένα', κάνοντας ευκολότερη την πρόσβαση στα τμήματα με τα οποία αλληλεπιδράτε συχνά.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Το Τμήμα Σας:</Body1Strong> Αυτή η ετικέτα φιλτράρει την προβολή για να εμφανίσει μόνο το δικό σας τμήμα,
                      επιτρέποντας τη γρήγορη πρόσβαση στις πληροφορίες και τις ενημερώσεις του άμεσου τμήματός σας.</li>
                  </ul>
                  Αυτές οι ετικέτες σχεδιάστηκαν για να απλοποιήσουν την πλοήγησή σας εντός του Καταλόγου Τμημάτων, ενισχύοντας την ικανότητά σας να βρίσκετε και να αλληλεπιδράτε με τις πληροφορίες των τμημάτων αποτελεσματικά.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Κατανόηση των Προβολών του Καταλόγου Τμημάτων στο TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Η πλοήγηση μέσω του Καταλόγου Τμημάτων στο TeamConnect είναι απλοποιημένη με πολλαπλές επιλογές προβολής που ταιριάζουν στις προτιμήσεις σας. Κάθε προβολή προσφέρει έναν μοναδικό τρόπο για να εξερευνήσετε ολοκληρωμένες πληροφορίες των τμημάτων, περιλαμβάνοντας το Όνομα του Τμήματος, τον Διευθυντή του Τμήματος, το Γονικό Τμήμα, το Τηλέφωνο του Τμήματος, την Ειδική Γραμμή Βοήθειας του Τμήματος και το Email του Τμήματος. Ιδού μια ανασκόπηση των διαθέσιμων προβολών:
                  <ul>
                     <li className={styles.bulletBottomMargin}><Body1Strong>Προβολή λίστας:</Body1Strong> Η προεπιλεγμένη ρύθμιση που παραθέτει προσεκτικά όλα τα τμήματα, παρέχοντας
                       σαφής, συνοπτική επισκόπηση.
                     </li>
                     <li className={styles.bulletBottomMargin}><Body1Strong>Προβολή μεγάλης κάρτας:</Body1Strong> Μεταβείτε σε αυτήν την προβολή για να δείτε αναλυτικές κάρτες τμήματος που προσφέρει
                       μια εκτεταμένη περίληψη των βασικών πληροφοριών κάθε τμήματος.
                     </li>
                     <li className={styles.bulletBottomMargin}><Body1Strong>Προβολή οργανογράμματος:</Body1Strong> Για οπτική αναπαράσταση της δομής του τμήματος και
                       σχέσεις, η προβολή Οργανογράμματος δείχνει πώς κάθε τμήμα ταιριάζει στην ευρύτερη οργανωτική ιεραρχία.
                     </li>
                   </ul>
                   Για εναλλαγή μεταξύ αυτών των προβολών, απλώς χρησιμοποιήστε τα εικονίδια δίπλα στο πλαίσιο αναζήτησης στο επάνω μέρος του καταλόγου του τμήματος. Κάθε προβολή έχει σχεδιαστεί για να σας βοηθήσει
                   πρόσβαση και κατανόηση των λεπτομερειών του τμήματος με ευκολία, διασφαλίζοντας ότι βρίσκετε τις πληροφορίες που χρειάζεστε στη μορφή που λειτουργεί καλύτερα για εσάς.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Πώς να χρησιμοποιήσετε το πλαίσιο αναζήτησης στον Κατάλογο Τμημάτων
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Εντοπίστε απρόσκοπτα οποιοδήποτε τμήμα εντός του TeamConnect χρησιμοποιώντας το αφιερωμένο πλαίσιο αναζήτησης που βρίσκεται δίπλα στα εικονίδια 
                  προβολής στο επάνω μέρος του Καταλόγου Τμημάτων. Απλά αρχίστε να πληκτρολογείτε το όνομα του τμήματος που ψάχνετε και παρακολουθήστε καθώς ο 
                  κατάλογος διευκρινίζεται για να εμφανίζει μόνο τις σχετικές αντιστοιχίες. Αυτή η δυνατότητα έχει σχεδιαστεί ειδικά για να σας βοηθήσει να 
                  αποφύγετε την ανάγκη να σαρώσετε χειροκίνητα όλο τον κατάλογο, φέρνοντας το τμήμα που χρειάζεστε απευθείας στα δάχτυλά σας. Θυμηθείτε, αυτή 
                  η λειτουργία αναζήτησης είναι μοναδική στον Κατάλογο Τμημάτων του TeamConnect, οπότε σιγουρευτείτε ότι χρησιμοποιείτε αυτό το πλαίσιο αναζήτησης 
                  αντί για την κύρια αναζήτηση του Microsoft Teams στο πολύ επάνω μέρος της διεπαφής των Teams για ειδικές ερωτήσεις για τμήματα.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Πώς λειτουργεί το αλφαβητικό φίλτρο στον Κατάλογο Τμημάτων;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Απλοποιήστε την αναζήτησή σας στον Κατάλογο Τμημάτων με το αλφαβητικό φίλτρο που βρίσκεται στο επάνω μέρος. Αυτή η δυνατότητα σας επιτρέπει να 
                  στενεύσετε γρήγορα τα τμήματα επιλέγοντας ένα γράμμα. Μόλις επιλέξετε ένα γράμμα, το TeamConnect φιλτράρει και εμφανίζει μόνο εκείνα τα τμήματα 
                  των οποίων τα ονόματα αρχίζουν με το επιλεγμένο σας γράμμα, καθιστώντας πιο εύκολη την εύρεση αυτού που ψάχνετε χωρίς να χρειάζεται να 
                  κυλήσετε μέσα από ολόκληρη τη λίστα. Για να επιστρέψετε στην πλήρη προβολή τμήματος, απλώς αποεπιλέξτε το επισημασμένο γράμμα. Αυτή η 
                  διαισθητική αλφαβητική πλοήγηση διασφαλίζει ότι μπορείτε αποτελεσματικά να εντοπίσετε οποιοδήποτε τμήμα από το αρχικό του γράμμα.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Πώς μπορώ να επικοινωνήσω με τον διευθυντή του τμήματος;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Η επικοινωνία με έναν διευθυντή τμήματος στο TeamConnect είναι απλή. Απλώς εντοπίστε το τμήμα που σας ενδιαφέρει και κάντε κλικ στο όνομα του διευθυντή. 
                  Αυτή η ενέργεια θα ενεργοποιήσει ένα popover που εμφανίζει τις επιλογές επικοινωνίας του διευθυντή με τα γνωστά εικονίδια γρήγορης πρόσβασης:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Εικονίδιο συνομιλίας:</Body1Strong> Για να ξεκινήσετε άμεσο μήνυμα μέσω συνομιλίας Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Εικονίδιο email:</Body1Strong> Για να ανοίξετε ένα νέο email προς αυτούς στο Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Εικονίδιο βιντεοκλήσης:</Body1Strong> Για να ξεκινήσετε μια βιντεοκλήση μέσω Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Εικονίδιο κλήσης:</Body1Strong> Για να ξεκινήσετε μια φωνητική κλήση χρησιμοποιώντας τα Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Εικονίδιο Περισσότερα (...):</Body1Strong> Για επιπλέον επιλογές όπως η προγραμματισμένη συνάντηση ή η προσθήκη 
                      των στοιχείων επικοινωνίας τους στη λίστα των Teams σας.
                    </li>
                  </ul>
                  Αυτά τα εικονίδια παρέχουν έναν απρόσκοπτο τρόπο για να επικοινωνήσετε απευθείας με τους διευθυντές τμημάτων από εντός του TeamConnect, εξασφαλίζοντας 
                  αποτελεσματική επικοινωνία εντός του οργανισμού σας.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Πώς μπορώ να δω ή να επικοινωνήσω με τα μέλη ενός τμήματος;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Για να εξερευνήσετε ποιος είναι ποιος εντός ενός τμήματος στο TeamConnect, δείτε τις τρεις τελείες δίπλα στο όνομα του τμήματος. Κάνοντας κλικ επάνω τους θα 
                  εμφανιστούν επιλογές για περαιτέρω δράση:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Προβολή Εργαζομένων σε αυτό το Τμήμα:</Body1Strong> Επιλέξτε αυτό για να εμφανιστεί μια λίστα όλων 
                      των εργαζομένων που συνδέονται με το τμήμα. Ο αριθμός δίπλα σε αυτή την επιλογή δείχνει πόσοι εργαζόμενοι βρίσκονται στο τμήμα, παρέχοντας μια γρήγορη καταμέτρηση.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Οργανόγραμμα Τμήματος:</Body1Strong> Αν θέλετε να κατανοήσετε την ιεραρχία του τμήματος, 
                      επιλέξτε 'Οργανόγραμμα τμήματος' για να αποκαλυφθεί η δομική διάταξη, παρουσιάζοντας τη σχέση μεταξύ των άνω τμημάτων, του εν λόγω τμήματος, 
                      και τυχόν υποτμημάτων. Αυτή η οπτική αναπαράσταση σας βοηθά να πλοηγηθείτε και να κατανοήσετε το οργανωτικό πλαίσιο, καθιστώντας ευκολότερη την ταυτοποίηση και 
                      επικοινωνία με συγκεκριμένα μέλη του τμήματος.
                    </li>
                    <li><Body1Strong>Τμήμα {'>'} Εργαζόμενος:</Body1Strong> Αφού προβάλετε τους εργαζομένους ενός τμήματος, χρησιμοποιήστε αυτή τη διαδρομή για να 
                      πλοηγηθείτε εύκολα πίσω στη λίστα των τμημάτων, διατηρώντας μια ομαλή εμπειρία εξερεύνησης εντός του TeamConnect.
                    </li>
                  </ul>
                  Αυτές οι δυνατότητες σας επιτρέπουν όχι μόνο να δείτε τη λίστα των εργαζομένων σε ένα δεδομένο τμήμα αλλά επίσης προσφέρουν έναν τρόπο για να επικοινωνήσετε απευθείας με αυτούς μέσω των εργαλείων επικοινωνίας του TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Ανακάλυψη Λεπτομερειών Τμήματος σε Βάθος στο TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Εμβαθύνετε στις λεπτομέρειες οποιουδήποτε τμήματος με ένα απλό κλικ στο όνομά του στο TeamConnect. Ένας αναλυτικός διάλογος αναπτύσσεται, προσφέροντας ένα πλούσιο υφάσμα πληροφοριών που σχηματίζει μια πλήρη εικόνα του ρόλου και των πόρων του τμήματος εντός της οργάνωσής σας. Θα βρείτε τα πάντα από την ηγεσία και ταυτότητες οικονομικών δεδομένων μέχρι κανάλια επικοινωνίας και κοινωνικούς συνδέσμους. Αυτή η δυνατότητα είναι η πύλη σας για:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Ο παλμός του τμήματος – ο σκοπός του, οι άνθρωποι και οι μετρικές απόδοσης.</li>
                    <li className={styles.bulletBottomMargin}>Διαύλους επικοινωνίας – απευθείας γραμμές προς κύρια μέλη του τμήματος και πλατφόρμες επικοινωνίας.</li>
                    <li className={styles.bulletBottomMargin}>Το οργανωτικό δίκτυο – κατανόηση του τρόπου ένταξης του τμήματος στην ευρύτερη δομή της εταιρείας.</li>
                  </ul>
                  Είτε πρόκειται για μια γρήγορη ερώτηση είτε για μια βαθιά καταδύση στα δεδομένα του τμήματος, το TeamConnect σας εξοπλίζει με τα εργαλεία για να συνδεθείτε με και να κατανοήσετε τα μοναδικά οικοσυστήματα εντός της οργάνωσής σας.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Απορείτε γιατί δεν υπάρχουν διευθύνσεις τμημάτων στο TeamConnect;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Η αναζήτηση της τοποθεσίας ενός τμήματος μπορεί μερικές φορές να φανεί σαν κυνήγι θησαυρού χωρίς χάρτη. Στο TeamConnect, κανονικά δεν θα βρείτε αφιερωμένη 
                  διεύθυνση για κάθε τμήμα. Ακολουθούν οι λόγοι για τους οποίους αυτή η γεωγραφική λεπτομέρεια συχνά παραλείπεται:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Πολλαπλές Τοποθεσίες:</Body1Strong> Πολλά τμήματα δεν περιορίζονται σε έναν μόνο χώρο· αναπτύσσονται σε διάφορα γραφεία και γεωγραφικές περιοχές.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ρευστή Δομή:</Body1Strong> Το οργανωτικό τοπίο αλλάζει συνεχώς, με τμήματα που εξελίσσονται, συγχωνεύονται ή ακόμη και διαλύονται σύμφωνα με τα στρατηγικά μεταβολές.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Διατμηματική Συνεργασία:</Body1Strong> Οι σύγχρονοι χώροι εργασίας ξεχειλίζουν από συνεργατικό πνεύμα, συχνά συνδυάζοντας ταλέντα από διάφορα τμήματα σε κοινόχρηστους χώρους.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Κουλτούρα Εξ Αποστάσεως Εργασίας:</Body1Strong> Με την ευελιξία της εξ αποστάσεως εργασίας, οι τμηματικές βάσεις δεν αποτίθενται πάντα σε φυσικές τοποθεσίες γραφείων.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Νομικές Λεπτομέρειες:</Body1Strong> Τα τμήματα συνήθως περιστρέφονται γύρω από τη νομική δομή της εταιρείας αντί να έχουν τις δικές τους ανεξάρτητες διευθύνσεις.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Οργανωτική Απλοποίηση:</Body1Strong> Η διατήρηση της εστίασης στη δυναμική ομαδική εργασία, όχι στις στατικές διευθύνσεις, βοηθά στη διατήρηση της λειτουργικής ευελιξίας.
                    </li>
                  </ul>
                  Πρόκειται για την αγκαλιάση μιας προσέγγισης χωρίς όρια στην ομαδική εργασία και την αποδοτικότητα. Το TeamConnect αντικατοπτρίζει αυτή τη φιλοσοφία, τονίζοντας τις συνδέσεις άνω των τοποθεσιών.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Λείπουν ή είναι αναντίστοιχες οι πληροφορίες του τμήματός σας;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Οι επιμελητές των τμημάτων ορίζονται εντός του οργανισμού σας για να προσθέτουν, ενημερώνουν ή διορθώνουν συγκεκριμένες πληροφορίες για τα τμήματα στο TeamConnect.
                  Αν δεν είστε σίγουροι ποιος είναι ο επιμελητής του τμήματός σας ή αν συγκεκριμένες πληροφορίες απαιτούν την παρέμβαση ομάδας υποστήριξης HR, 
                  παρακαλούμε επικοινωνήστε με <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> για καθοδήγηση.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Συχνές Ερωτήσεις Καταλόγου Γραφείων</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Εισαγωγή στον Κατάλογο Γραφείων:</Body1Strong> Ανακαλύψτε την κύρια υποστήριξη της φυσικής παρουσίας του οργανισμού σας μέσω του Καταλόγου Γραφείων, 
                που περιλαμβάνει ουσιαστικές λεπτομέρειες γραφείων, λειτουργικά στοιχεία και μια εις βάθος εξετασή στα τμήματα και το προσωπικό που καθορίζουν κάθε τοποθεσία.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Πλοήγηση Ετικετών στον Κατάλογο Γραφείων
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Βρείτε τον τρόπο σας γύρω από τον Κατάλογο Γραφείων με ευκολία χρησιμοποιώντας το σύστημα ετικετών μας σχεδιασμένο για γρήγορη πρόσβαση στη σωστή τοποθεσία. Να πώς μπορεί να σας βοηθήσει κάθε ετικέτα να βελτιώσετε την αναζήτησή σας:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Όλα:</Body1Strong> Παρουσιάζει μια πλήρη λίστα των γραφείων, προσφέροντας μια επισκόπηση από ψηλά 
                      των τοποθεσιών του οργανισμού σας.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Αγαπημένα:</Body1Strong> Σας βοηθά να επικεντρωθείτε στις τοποθεσίες γραφείων που επισκέπτεστε συχνότερα ή που πρέπει 
                      να παρακολουθείτε στενά, επισημαίνοντάς τα ως 'Αγαπημένα'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Το Γραφείο σας:</Body1Strong> Αυτή η προσωπική ετικέτα φιλτράρει τον κατάλογο για να σας δείξει την 
                      τοποθεσία γραφείου όπου βρίσκεστε, κρατώντας τη βάση σας πάντα εύκολα προσβάσιμη.
                    </li>
                  </ul>
                  Είτε κλείνετε ένα δωμάτιο για συνάντηση είτε σχεδιάζετε μια επίσκεψη, αυτές οι ετικέτες προσφέρουν έναν προσωπικό και απλοποιημένο τρόπο να αλληλεπιδράτε με το γεωγραφικό τοπίο του οργανισμού σας.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Αποκτήστε επιδεξιότητα στις απεικονίσεις του καταλόγου γραφείων στο TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Εξερευνήστε την οργανωτική διάταξη των διάφορων τοποθεσιών της εταιρείας σας με τις απεικονίσεις του γραφείου στο TeamConnect. Κάθε απεικόνιση είναι σχεδιασμένη για να διευκολύνει 
                  ένα μοναδικό στοιχείο πλοήγησης και κατανόησης του γραφείου:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Προβολή Λίστας:</Body1Strong> Η προεπιλεγμένη ρύθμιση που οργανώνει όλες τις τοποθεσίες των γραφείων σε 
                      μια εύκολη στην ανάγνωση λίστα.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Προβολή Μεγάλης Κάρτας:</Body1Strong> Η Προβολή Μεγάλης Κάρτας στον κατάλογο γραφείων εμπλουτίζει την 
                      απεικόνισή σας με μια λεπτομερή παρουσίαση κάθε γραφείου. Όταν αλληλεπιδράτε με μια κάρτα γραφείου, θα βρείτε πληθώρα πληροφοριών και επιλογές δράσης 
                      μέσα στο μενού "Περισσότερα (...)" :
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Προβολή Τμημάτων:</Body1Strong> Κατευθύνεστε απευθείας σε μια λίστα
                          όλων των τμημάτων που βρίσκονται εντός του γραφείου.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Προβολή Υπαλλήλων:</Body1Strong> Πρόσβαση σε πλήρη κατάλογο υπαλλήλων που ανατέθηκαν στο 
                          γραφείο, διευκολύνοντας την άμεση επικοινωνία ή την εξερεύνηση του προσωπικού του γραφείου.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Οργανόγραμμα Επιχειρησιακής Οντότητας:</Body1Strong> Απεικονίστε τη θέση του γραφείου εντός του ευρύτερου 
                          οργανωτικού πλαισίου για κατανόηση των ιεραρχικών δομών.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Βασικές Επαφές:</Body1Strong> Προσδιορίστε κύριες επαφές του γραφείου για αποδοτική 
                          επικοινωνία.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Επίσημες Αργίες του Γραφείου:</Body1Strong> Ενημερωθείτε για τις ειδικές δημόσιες αργίες του γραφείου για να προγραμματίσετε τις ώρες σας ανάλογα.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Ώρες Λειτουργίας του Γραφείου:</Body1Strong> Προβολή των ωρών λειτουργίας του γραφείου για να προσαρμόσετε τις επισκέψεις ή τις κλήσεις σας στις ενεργές ώρες τους.
                        </li>
                      </ol>
                      Αυτή η προβολή παρέχει όχι μόνο μια οπτική και πληροφοριακή στιγμιότυπο, αλλά και γρήγορη πρόσβαση σε βαθύτερες πληροφορίες και άμεσες ενέργειες σχετικά με το γραφείο, όλα καλά τοποθετημένα κάτω από την επιλογή "Περισσότερα (...)" για τη δική σας ευκολία.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Προβολή Οργανογράμματος:</Body1Strong> Απεικονίστε την ιεραρχική δομή των μητρικών και υπο-γραφείων 
                      για να καταλάβετε την αλληλεξάρτηση διαφορετικών τοποθεσιών γραφείων μέσα στην οργάνωσή σας.
                    </li>
                  </ul>
                  Εναλλάξτε μεταξύ αυτών των προβολών χρησιμοποιώντας τα εικονίδια δίπλα στο πλαίσιο αναζήτησης για να προσαρμόσετε την εμπειρία περιήγησής σας στις άμεσες ανάγκες σας.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Εύρεση Γραφείων Γρήγορα με τη Λειτουργία Αναζήτησης του TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Η εστίαση στη σωστή τοποθεσία του γραφείου μέσα στο TeamConnect γίνεται εύκολη με την αφιερωμένη λειτουργία αναζήτησης. Τοποθετημένη προεξέχοντα δίπλα στα εικονίδια 
                  προβολής, το πλαίσιο αναζήτησης είναι το εργαλείο σας για την ακριβή εντοπισμό των γραφείων βάσει του ονόματος. Καθώς πληκτρολογείτε, ο κατάλογος βελτιώνει την 
                  προβολή για να εμφανίζει μόνο τα γραφεία που ταιριάζουν με την εισαγωγή σας, απλοποιώντας τη διαδικασία αναζήτησης. Είναι σημαντικό να χρησιμοποιείτε αυτό το πλαίσιο 
                  αναζήτησης μέσα στο TeamConnect για τα πιο ακριβή και σχετικά αποτελέσματα, σε αντίθεση με τη γενική αναζήτηση του Microsoft Teams. Με αυτή τη στοχευμένη λειτουργικότητα αναζήτησης, 
                  μπορείτε να πλοηγηθείτε γρήγορα σε οποιοδήποτε γραφείο στο δίκτυο της οργάνωσής σας.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Πλοήγηση στον Κατάλογο Γραφείων Αλφαβητικά
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Εάν προτιμάτε την αναζήτηση με βάση το αλφάβητο, το αλφαβητικό φίλτρο μας είναι ένας ταχύς τρόπος για να βρείτε γραφεία βάσει ονόματος. Στο επάνω μέρος του Καταλόγου Γραφείων θα δείτε τα γράμματα Α έως Ζ. Πατώντας σε ένα γράμμα, το προβολή φίλτραρεται ώστε να εμφανίζονται μόνο τα γραφεία που αρχίζουν με αυτό το γράμμα, απλοποιώντας τη διαδικασία αναζήτησης. Για να επανεκκινήσετε και να δείτε ξανά όλα τα γραφεία, απλώς αποεπιλέξτε το ενεργό γράμμα. Αυτή η λειτουργία είναι ιδιαίτερα χρήσιμη όταν είστε εξοικειωμένοι με το όνομα του γραφείου αλλά χρειάζεστε έναν γρήγορο τρόπο να το εντοπίσετε εντός του TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Αποκαλύπτοντας Πλήρη Προφίλ Γραφείων στον TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ξεκινήστε ένα εξερευνητικό ταξίδι στην καρδιά της φυσικής παρουσίας της εταιρείας σας με τα προφίλ γραφείων του TeamConnect. 
                  Πατώντας στο όνομα ενός γραφείου εμφανίζεται ένας πολύχρωμος διάλογος προφίλ, γεμάτος με καρτέλες πλήρων πληροφοριών για το γραφείο:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Επισκόπηση:</Body1Strong> Βυθιστείτε στον πυρήνα της ταυτότητας του γραφείου με λεπτομερείς περιγραφές, από την δομή της επιχείρησης έως τις ώρες λειτουργίας και την τοπική ώρα, αποτυπώνοντας την ουσία του περιβάλλοντος του γραφείου.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Απαραίτητες Επαφές:</Body1Strong> Αποκτήστε πρόσβαση σε κρίσιμες επαφές για υπηρεσίες έκτακτης ανάγκης και εξωτερική υποστήριξη, κάθε μια με ένα κλικ μέσω του συστήματος τηλεφώνου Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Επαφές Γραφείου:</Body1Strong> Έλατε σε επαφή με τις εσωτερικές υπηρεσίες υποστήριξης με ευκολία, με λεπτομέρειες επαφών και επιλογές άμεσης αλληλεπίδρασης διαθέσιμες ακριβώς στα δάχτυλά σας.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Δημόσιες Αργίες:</Body1Strong> Μείνετε ενημερωμένοι για τις αργίες του γραφείου, εξασφαλίζοντας ότι τα σχέδιά σας ταιριάζουν με τις τοπικές τελετές και τα εργασιακά προγράμματα.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ώρες Λειτουργίας:</Body1Strong> Προγραμματίστε τις αλληλεπιδράσεις σας με ακριβή γνώση των ωρών που το γραφείο σφύζει από δραστηριότητα ή κλείνει για την ημέρα.
                    </li>
                  </ul>
                  Είτε πρόκειται για την ακατάληπτη ενέργεια του Austin ή για το στρατηγικό κέντρο της Σιγκαπούρης, κάθε καρτέλα προσφέρει έναν πύλη για να κατανοήσετε και να συνδεθείτε με τις ποικίλες τοποθεσίες που συνθέτουν την παγκόσμια παρουσία της εταιρείας σας.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Εξερεύνηση της Δυναμικής των Τμημάτων σε ένα Γραφείο
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Η πλοήγηση στο επαγγελματικό δίκτυο των τμημάτων ενός γραφείου εξελίσσεται με ευκολία στο TeamConnect. Ιδού πώς να εμβαθύνετε στις λεπτομέρειες:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Προβολή των Τμημάτων σε Αυτό το Γραφείο:</Body1Strong> Ανακαλύψτε όλα τα τμήματα που 
                      αποτελούν τον ιστό του γραφείου επιλέγοντας αυτή την επιλογή από το μενού Περισσότερα (...).
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Οργανωτική Προοπτική:</Body1Strong> Κάντε κλικ σε οποιοδήποτε τμήμα για να αποκαλύψετε το 
                      διάγραμμα του οργανισμού για μια οπτική απεικόνιση της δομής του, με τη διαδρομή ως οδηγό σας: Γραφείο {'>'} Τμήμα {'>'} Διάγραμμα.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Βαθιά Εξερεύνηση των Τμημάτων:</Body1Strong> Εξερευνήστε περαιτέρω ένα τμήμα κάνοντας κλικ στο
                      μενού Περισσότερα (...) δίπλα στο όνομα του τμήματος. Εδώ, μπορείτε να προσπελάσετε μια λίστα όλων των εργαζομένων εντός αυτού του τμήματος, ακριβώς στο πλαίσιο του γραφείου.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Πλοήγηση με Διαδρομή:</Body1Strong> Διανύστε τα επίπεδα χωρίς προβλήματα. Από 
                      τους εργαζόμενους πίσω στα τμήματα ή από το διάγραμμα του οργανισμού, η διαδρομή (Γραφείο {'>'} Τμήμα {'>'} Εργαζόμενος {'>'} Διάγραμμα) διατηρεί 
                      το ταξίδι σας διαισθητικό και στη σωστή κατεύθυνση.
                    </li>
                  </ul>
                  Οι περίπλοκες διαδρομές του TeamConnect σας καλούν να εξερευνήσετε, να κατανοήσετε και να αλληλεπιδράσετε με κάθε στρώση των τμημάτων του 
                  γραφείου σας, ενδυναμώνοντάς σας με πλήρη εντολή πλοήγησης.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Συνδέση με το Προσωπικό του Γραφείου στο TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Περίεργοι για τα πρόσωπα πίσω από ένα γραφείο; Η απάντηση είναι μόνο ένα κλικ μακριά στο TeamConnect. Αλληλεπιδράστε με τις ελλείψεις (τρεις τελείες) 
                  δίπλα από το επιλεγμένο όνομα του γραφείου για να αποκαλύψετε μια σουίτα διαδραστικών επιλογών:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Προβολή των Εργαζομένων σε Αυτό το Γραφείο:</Body1Strong> Ανακαλύψτε μια λίστα όλων των επαγγελματιών
                      που ονομάζουν αυτό το γραφείο την εργασιακή τους κατοικία. Ο αριθμός που εμφανίζεται προσφέρει μια γρήγορη καταμέτρηση των εργαζομένων, καλώντας σας να 
                      εμβαθύνετε στην κοινότητα του γραφείου.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Επιλογές Επικοινωνίας:</Body1Strong> Κάθε καταχώριση εργαζομένου είναι εξοπλισμένη με εικονίδια γρήγορης ενέργειας,
                      επιτρέποντάς σας να ξεκινήσετε συνομιλίες, κλήσεις ή ακόμη και τηλεδιασκέψεις αμέσως από το TeamConnect, ενισχύοντας την άνετη επικοινωνία εντός γραφείου.
                    </li>
                    <li><Body1Strong>Πλοήγηση:</Body1Strong> Για να επιστρέψετε στην ευρύτερη λίστα γραφείων από μια λίστα εργαζομένων, απλά κάντε κλικ στο 'Γραφείο' στη 
                      διαδρομή (Γραφείο {'>'} Εργαζόμενος), και θα μεταφερθείτε πίσω στην επισκόπηση του γραφείου.
                    </li>
                  </ul>
                  Με αυτές τις δυνατότητες, το TeamConnect καθιστά απλή την απεικόνιση της κατανομής του εργατικού δυναμικού στα γραφεία και την εύκολη επικοινωνία, 
                  ενισχύοντας έναν συνδεδεμένο χώρο εργασίας ανεξάρτητα από την απόσταση.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Λείπουν ή είναι παρωχημένες οι πληροφορίες του γραφείου σας;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Οι ενημερώσεις πληροφοριών γραφείου στο TeamConnect μπορούν να διεκπεραιωθούν από τους ορισμένους συντάκτες γραφείου. Εάν δεν είστε σίγουροι για τους συντάκτες γραφείου ή 
                  χρειάζεστε βοήθεια με πληροφορίες που αφορούν τον τομέα των ανθρώπινων πόρων, η ομάδα υποστήριξης των ανθρώπινων πόρων είναι έτοιμη να 
                  βοηθήσει στο <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Συχνές Ερωτήσεις Ρυθμίσεων</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Η Σημασία των Ακριβών Γενικών Ρυθμίσεων στο TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Στο δυναμικό τοπίο του σημερινού εργασιακού περιβάλλοντος, το να παραμένεις συνδεδεμένος και συντονισμένος είναι πιο κρίσιμο από ποτέ. Γι' αυτό η ενημέρωση των 
                  Γενικών Σας Ρυθμίσεων στο TeamConnect διαδραματίζει καίριο ρόλο:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Επιλογή Χώρου Εργασίας:</Body1Strong> Αποτυπώστε την ουσία του εργασιακού σας χώρου ενημερώνοντας τον Χώρο Εργασίας σας ώστε να αντανακλά την τρέχουσα 
                        κατάστασή σας, είτε πρόκειται για μετάβαση από την ηρεμία του Οικιακού σας Γραφείου στη διασκέδαση του κεντρικού Γραφείου, είτε για εγκατάσταση σε ένα Δημόσιο Χώρο. 
                        Αυτή η σαφήνεια σχετικά με τον εργασιακό σας τόπο προσφέρει στους συναδέλφους σας εικόνα όχι μόνο για τους καλύτερους τρόπους επικοινωνίας μαζί σας, αλλά και για τη 
                        φύση των συζητήσεων που μπορούν να έχουν μαζί σας, προστατεύοντας την εμπιστευτικότητα ευαίσθητων συζητήσεων ιδιαίτερα όταν βρίσκεστε στο ανοιχτό 
                        περιβάλλον του γραφείου ενός πελάτη ή κάθεστε σε μια αεροδρομική αίθουσα.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Προσδιορισμός Εργασιακού Σημείου:</Body1Strong> Η κοινοποίηση της εργασιακής σας πόλης κάνει περισσότερα από το να γεφυρώνει αποστάσεις σε ένα απομακρυσμένο 
                        συνεργατικό περιβάλλον—ανοίγει επίσης τις πόρτες σε αυθόρμητες επαγγελματικές συναντήσεις ή περιστασιακά κοινωνικά γεγονότα. Είτε πρόκειται για έναν γρήγορο καφέ, ένα 
                        ανέμελο γεύμα, ή μια συνεργατική συνάντηση, το γεγονός ότι γνωρίζετε πως βρίσκεστε στην ίδια πόλη μπορεί να μετατρέψει μια κανονική μέρα σε μια ευκαιρία για 
                        σημαντικές συναντήσεις πρόσωπο με πρόσωπο. Αυτό όχι μόνο ενισχύει τους δεσμούς της ομάδας αλλά παρέχει επίσης έναν πιο πλούσιο κατανόηση των τοπικών 
                        λεπτομερειών που μπορεί να επηρεάζουν την εργασία σας.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ακρίβεια Χρονικής Ζώνης:</Body1Strong> Καθώς οι ομάδες καλύπτουν ηπείρους, είναι ουσιαστικό να διασφαλίσετε ότι η χρονική σας ζώνη αντανακλά 
                      τον τρέχοντα τόπο σας για τον συντονισμό των επικοινωνιών. Η Τοπική Ώρα του προφίλ σας στο TeamConnect επηρεάζεται άμεσα από αυτή τη ρύθμιση, 
                      εξαλείφοντας κάθε αβεβαιότητα για τους συναδέλφους που προσπαθούν να επικοινωνήσουν μαζί σας.
                    </li>
                  </ul>
                  Εξερευνήστε τις 'Ρυθμίσεις' {'>'} 'Γενικές Ρυθμίσεις' μέσα στο TeamConnect για να προσαρμόσετε λεπτομερώς αυτές τις παραμέτρους. Λίγες στιγμές που θα αφιερώσετε για την ενημέρωση των ρυθμίσεων σας μπορούν 
                  να βελτιώσουν σημαντικά τη συνοχή και την αποτελεσματικότητα των συνεργατικών σας προσπαθειών σε όλο τον κόσμο.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Αποκρυπτογράφηση Επιλογής Χώρου Εργασίας στο TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Η επιλογή του σωστού χώρου εργασίας στο αναδυόμενο μενού Workplace προσαρμόζει το TeamConnect ώστε να αντανακλά ακριβώς την τρέχουσα κατάσταση εργασίας σας. Περάστε τον κέρσορα πάνω από το εικονίδιο πληροφοριών για μια λεπτομερή εξήγηση κάθε επιλογής:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Διάλειμμα:</Body1Strong> Σημαίνει χρόνος μακριά από την εργασία, είτε για διάλειμμα είτε για το τέλος της ημέρας.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Γραφείο/Πεδίο-Γραφείο:</Body1Strong> Δηλώνει παρουσία σε χώρο εργασίας που διαχειρίζεται η εταιρεία.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> Εργασία από αφιερωμένο χώρο εργασίας στο σπίτι.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Δημόσια-Περιοχή:</Body1Strong> Λειτουργία από περιβάλλον εκτός γραφείου, προτείνοντας την ανάγκη για ευέλικτη επικοινωνία.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Χώρος Πελάτη/Ιδιωτικό Γραφείο:</Body1Strong> Παρουσία σε τοποθεσία πελάτη, με σημείωση για τα επίπεδα ιδιωτικότητας για ευαισθησία συζητήσεων.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Μετακίνηση/Επαγγελματικό Ταξίδι/Εκπαίδευση:</Body1Strong> Κινούμενος ή ασχολούμενος με συγκεκριμένες εργασιακές δραστηριότητες έξω από το γραφείο.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Αργία/Διακοπές/Διάφορες Άδειες:</Body1Strong> Επίσημα εκτός υπηρεσίας για προσωπικούς, υγειονομικούς ή εορταστικούς λόγους.</li>
                  </ul>
                  Η επιλογή μιας κατάλληλης ρύθμισης χώρου εργασίας διασφαλίζει ότι η ομάδα σας έχει το απαραίτητο πλαίσιο για αλληλεπιδράσεις, σεβόμενη τη διαθεσιμότητα και την ιδιωτικότητα.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Διαχείριση της Κατάστασης 'Checked-In' στο TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Η ετικέτα 'Checked-In' στον Κατάλογο Υπαλλήλων είναι σχεδιασμένη για να εμφανίζει όλους τους υπαλλήλους που βρίσκονται ενεργά στην εργασία. Η ορατότητα αυτή καθορίζεται από την επιλογή Workplace που κάνετε:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ενεργά Περιβάλλοντα Εργασίας:</Body1Strong> Οι επιλογές Γραφείο, Πεδίο-Γραφείο, Home-Office, Remote-Workplace, Δημόσια-Περιοχή, Χώρος-Πελάτη, 
                    Ιδιωτικό-Γραφείο Πελάτη, Μετακίνηση, Επαγγελματικό Ταξίδι και Εκπαίδευση σηματοδοτούν ότι ασχολείστε με την εργασία, καθιστώντας σας 'Checked-In' και ορατό στους συναδέλφους.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ρυθμίσεις Μη-Εργασίας:</Body1Strong> Το να επιλέγετε καταστάσεις όπως Checked-Out, Διάλειμμα, Δημόσια-Αργία, Διακοπές, Απουσία, Αδεια Μητρότητας ή Αδεια Ασθένειας 
                      αφαιρεί σας από τη λίστα 'Checked-In', ορίζοντας σαφείς προσδοκίες για διαθεσιμότητα και χρόνους απόκρισης.
                    </li>
                  </ul>
                  Αυτή η λειτουργικότητα βοηθά στον διαχωρισμό των περιόδων εργασίας από τον προσωπικό χρόνο, συμβάλλοντας σε αποτελεσματική συνεργασία και επικοινωνία ομάδας.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Διαχείριση Δεδομένων και Συχνές Ερωτήσεις Απορρήτου</Subtitle2>
          <Body1>
            <p>
              Στη σημερινή ψηφιακή εποχή, είναι κρίσιμο να κατανοείτε πώς διαχειρίζονται τα δεδομένα σας. Το TeamConnect δεσμεύεται για διαφάνεια και αυστηρές πρακτικές προστασίας δεδομένων.
              Παρακάτω, θα βρείτε πληροφορίες για το πώς χειριζόμαστε, αποθηκεύουμε και προστατεύουμε τα δεδομένα σας μέσα στο TeamConnect, εξασφαλίζοντας ότι ο ψηφιακός σας χώρος εργασίας είναι ασφαλής και συμμορφωμένος.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Πώς το TeamConnect προστατεύει τα προσωπικά μου δεδομένα;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>Το TeamConnect αφοσιώνεται στην προστασία των προσωπικών σας πληροφοριών με έναν ολοκληρωμένο προσέγγιση στην ασφάλεια δεδομένων:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Ενιαία Σύνδεση (SSO):</Body1Strong> Για την ενίσχυση της ασφάλειας και την απλοποίηση της εμπειρίας χρήστη, το TeamConnect 
                      ενσωματώνει αυτοματοποιημένη Ενιαία Σύνδεση (SSO) με τον λογαριασμό σας στο Microsoft 365, διευκολύνοντας την ασφαλή και άνετη διαχείριση πρόσβασης.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Κρυπτογράφηση:</Body1Strong> Εξασφαλίζουμε ότι όλα τα δεδομένα εντός του TeamConnect είναι κρυπτογραφημένα τόσο κατά τη μεταφορά 
                      όσο και σε κατάσταση ακινησίας, καθιστώντας τα απρόσιτα σε μη εξουσιοδοτημένα μέρη.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Περιορισμός στα Δεδομένα:</Body1Strong> Η συλλογή δεδομένων μας περιορίζεται αυστηρά σε αυτά που είναι ουσιώδη για τη λειτουργία του TeamConnect. 
                      Τα μόνα πληροφορίες χρήστη που αποθηκεύουμε είναι το User Principal Name (UPN) από το Azure Entra ID, το οποίο μπορεί να μοιάζει με την εταιρική σας 
                      διεύθυνση email.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Περιορισμένη Πρόσβαση:</Body1Strong> Η πρόσβαση στο UPN περιορίζεται στον Διαχειριστή Συνδρομής της οργάνωσής σας εντός του πύλης 
                      Συνδρομής μας, εξασφαλίζοντας ότι ευαίσθητες πληροφορίες είναι ορατές μόνο σε εξουσιοδοτημένο προσωπικό.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Άμεση Διαγραφή Δεδομένων:</Body1Strong> Το TeamConnect είναι δομημένο να διαγράφει αμέσως το αποθηκευμένο UPN κάτω από τρεις συγκεκριμένες 
                      συνθήκες: εάν η συνδρομή ακυρωθεί από τον Διαχειριστή Συνδρομής, εάν ένας χρήστης αφαιρεθεί από το HR εντός του TeamConnect, ή αν λήξει η συνδρομή 
                      TeamConnect. Αυτό εξασφαλίζει ότι τα προσωπικά σας δεδομένα δεν διατηρούνται πέρα από το χρήσιμο βίο τους ή την ανάγκη.
                    </li>                      
                  </ul>
                  <p>Μέσω αυτών των μέτρων, το TeamConnect δεσμεύεται στη διατήρηση των υψηλότερων προτύπων προστασίας δεδομένων, εξασφαλίζοντας ότι τα προσωπικά σας πληροφορίες 
                  παραμένουν ασφαλή και εμπιστευτικά.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>           
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Αναρωτιέστε πού βρίσκονται τα δεδομένα σας με το TeamConnect;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Το TeamConnect σπάει τα καθιερωμένα πρότυπα των παραδοσιακών υπηρεσιών SaaS φροντίζοντας ώστε τα δεδομένα σας να μην εγκαταλείπουν ποτέ τον οργανισμό σας.
                  Αντίθετα με τη συνηθισμένη πρακτική αποθήκευσης δεδομένων σε εξωτερικούς cloud servers, το TeamConnect με εξυπνάδα εκμεταλλεύεται το Microsoft 365 SharePoint Online του οργανισμού σας
                  για να φιλοξενεί όλα τα δεδομένα εφαρμογών. Αυτή η καινοτόμα προσέγγιση όχι μόνο προάγει την κυριαρχία των δεδομένων αλλά ενισχύει και την ασφάλεια,
                  ευθυγραμμίζοντας τέλεια με την έντονη αφοσίωσή μας να προστατεύουμε το απόρρητό σας και την τήρηση αυστηρών προτύπων συμμόρφωσης.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Θα μοιραστούν τα δεδομένα μου με τρίτους;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Όχι, τα δεδομένα δεν μοιράζονται με τρίτους! Η Cubeet είναι ακλόνητη στη δέσμευσή μας για την ιδιωτικότητα και την ασφάλεια των δεδομένων σας. Δεν μοιραζόμαστε κανένα δεδομένο του TeamConnect με τρίτους χωρίς να λάβουμε ρητή συγκατάθεση από τους πελάτες μας.
                  Στο ασφαλές περιβάλλον μας, αποθηκεύουμε μόνο απαραίτητους αναγνωριστικούς, όπως το User Principal Name (UPN), το όνομα οργανισμού, το όνομα tenant και το tenant ID, μέσα στο πόρταλ συνδρομών μας.
                  Αυτές οι πληροφορίες είναι κρίσιμες για την παροχή υπηρεσιών και προστατεύονται αυστηρά σύμφωνα με τις πολιτικές μας για την ιδιωτικότητα.
                  Μπορείτε να είστε σίγουροι, οι πρακτικές μας για τη διαχείριση δεδομένων σχεδιάζονται ώστε τα δεδομένα σας να παραμένουν εμπιστευτικά, με διαφάνεια και έλεγχο που παραμένει σταθερά στα χέρια σας.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Πώς συμμορφώνεται το TeamConnect με τους κανονισμούς προστασίας δεδομένων όπως το GDPR;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Το TeamConnect συμμορφώνεται με τους κανονισμούς προστασίας δεδομένων όπως το GDPR, το HIPAA, το CCPA και άλλα, εκμεταλλευόμενο τις εγγενείς δυνατότητες συμμόρφωσης του περιβάλλοντος Microsoft 365,
                  όπου όλα τα δεδομένα πελατών αποθηκεύονται με ασφάλεια. Αντίθετα με τα παραδοσιακά μοντέλα SaaS, το TeamConnect δεν αποθηκεύει κανένα δεδομένο πελάτη στους δικούς του servers· αντ' αυτού, λειτουργεί εντός του
                  περιβάλλοντος Microsoft 365 του οργανισμού σας, τηρώντας τα υψηλά πρότυπα ασφάλειας και συμμόρφωσης του. Αυτή η προσέγγιση εξασφαλίζει ότι η διακυβέρνηση των δεδομένων σας παραμένει συνεπής με
                  το ευρύ πλαίσιο συμμόρφωσης που παρέχεται από το Microsoft 365, συμπεριλαμβανομένης της τήρησης του GDPR, του HIPAA για πληροφορίες υγείας, του CCPA για κατοίκους της Καλιφόρνιας,
                  και άλλων παγκόσμιων κανονισμών προστασίας δεδομένων. Με την άψογη ενσωμάτωση με την υπάρχουσα ρύθμιση Microsoft 365 του οργανισμού σας, το TeamConnect ενδυναμώνει τον οργανισμό σας να διατηρήσει
                  πλήρη έλεγχο των δεδομένων σας, εξασφαλίζοντας τη συμμόρφωση με τους αυστηρότερους νόμους και κανονισμούς προστασίας δεδομένων.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Πώς διαχειρίζεται το TeamConnect τα δεδομένα από συζητήσεις, κλήσεις, βιντεοκλήσεις, emails του Outlook, και κλήσεις από το τηλεφωνικό σύστημα στο Microsoft Teams;
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Το TeamConnect εκμεταλλεύεται το Microsoft Graph API για να ενεργοποιήσει άμεσες αλληλεπιδράσεις όπως συνομιλίες, κλήσεις, βιντεοκλήσεις και emails στο Outlook μέσα από το Microsoft Teams, 
                  παρέχοντας μια απρόσκοπτη εμπειρία χρήστη. Καθοριστικής σημασίας, ενώ το TeamConnect πρωτοβουλεί αυτές τις επικοινωνίες, το κάνει χωρίς να παρεμβαίνει στη διαδικασία επικοινωνίας 
                  ή να έχει τη δυνατότητα να καταγράφει ή να αποθηκεύει οποιοδήποτε περιεχόμενο της αλληλεπίδρασης. Όλες οι συνομιλίες, κλήσεις και emails διεξάγονται και διαχειρίζονται απευθείας μέσα 
                  στα ασφαλή όρια του Microsoft Teams και του Outlook, τηρώντας αυστηρά τα πρότυπα ασφαλείας και ιδιωτικότητας του Microsoft 365. Αυτό εξασφαλίζει ότι το TeamConnect ενισχύει 
                  τις δυνατότητες επικοινωνίας σας διατηρώντας πλήρως την εμπιστευτικότητα και την ακεραιότητα των δεδομένων σας.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Για λεπτομερείς πληροφορίες σχετικά με τις πολιτικές και τις πρακτικές μας για τα δεδομένα, ίσως επίσης θέλετε να ανατρέξετε στο {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>Οδηγός Χρήστη του TeamConnect</Body1Strong></Link> ή απευθυνθείτε στο τμήμα ΤΠ της οργάνωσής σας 
            στη διεύθυνση <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> για συγκεκριμένα στοιχεία που σχετίζονται με τη δική σας ρύθμιση.
          </p>
        </Body1>        
        <p></p>  
      <Subtitle1 className={styles.fontColor}>Σας Ακούμε: Μοιραστείτε τα Σχόλια και τις Ιδέες σας</Subtitle1>
        <Body1>
          <p>
            Οι πληροφορίες σας οδηγούν την εξέλιξη του TeamConnect. Είτε έχετε μια λαμπρή ιδέα για μια νέα δυνατότητα είτε έχετε κάποια σχόλια για την τρέχουσα εμπειρία σας, είμαστε όλα αυτιά. Η συνεισφορά σας 
            είναι πολύτιμη στη συνεχιζόμενη αναζήτησή μας για να προσφέρουμε την καλύτερη δυνατή υπηρεσία.
          </p>
        </Body1>      
      <Subtitle2>Σχόλια και Προτάσεις για Λειτουργίες</Subtitle2>
        <Body1>
          <p>
            Έτοιμοι να μοιραστείτε; Επισκεφτείτε τη {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Σελίδα Σχολίων
            </Link>
            . Είτε πρόκειται για μια πρόταση για μια νέα λειτουργία είτε για σχόλια σχετικά με υπάρχουσες λειτουργικότητες, απλώς επιλέξτε 
            την κατάλληλη επιλογή από το αναπτυσσόμενο μενού στη φόρμα. Οι συνεισφορές σας βοηθούν στο σχηματισμό του μέλλοντος του TeamConnect, 
            διασφαλίζοντας ότι ανταποκρίνεται και ξεπερνά τις ανάγκες σας.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
