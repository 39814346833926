import PropTypes from "prop-types";
import {
  makeStyles,
  tokens,
  Link,
  Body1,
  Body1Strong,
  Subtitle2,
  Subtitle1,
  Title3,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,
  },
  bulletBottomMargin: {
    marginBottom: "8px",
  },
  scrollToTopButton: {
    position: "fixed",
    right: "5%",
    transform: "translateX(95%)",
    bottom: "8px",
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>
        Canolfan Gymorth a Chwestiynau Cyffredin TeamConnect
      </Title3>
      <p>
        <Body1>
          Croeso i Ganolfan Gymorth TeamConnect, sydd wedi'i chynllunio i
          gefnogi eich profiad gyda'n ap cyfeiriadur corfforaethol cynhwysfawr.
          Yma, byddwch yn dod o hyd i adnoddau i optimeiddio eich defnydd o
          TeamConnect o fewn Microsoft Teams, gan wella cyfathrebu ar draws eich
          rhwydwaith.
        </Body1>
      </p>
      <Subtitle2>Canllaw Cyfeirio Cyflym</Subtitle2>
      <ul>
        <Body1>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Galluoedd Chwilio Gwell: </Body1Strong>
            Dod o hyd i'r cydweithwyr, adrannau, a chysylltiadau hanfodol yn
            gyflym gyda'n offer chwilio pwerus.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Siartiau Sefydliadol Gweledol: </Body1Strong>
            Llywio hierarchaeth eich sefydliad a deall llinellau adrodd gyda
            chyfleustra trwy siartiau sefydliadol greddfol.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>
              Offer Addasadwy ar gyfer Anghenion Heddiw:{" "}
            </Body1Strong>
            Defnyddiwch swyddogaethau modern wedi'u teilwra i ofynion
            gweithleoedd cyfoes.
          </li>
        </Body1>
      </ul>
      <p></p>
      <Subtitle2>Optimeiddio Eich Profiad</Subtitle2>
      <Body1>
        <p>
          Mae TeamConnect yn chwyldroi sut rydych chi'n rhyngweithio o fewn eich
          strwythur sefydliadol, gan droi cyfathrebiadau bob dydd yn
          ymgysylltiadau strategol.
        </p>
      </Body1>
      <p></p>
      <Subtitle1 className={styles.fontColor}>
        Cael Cymorth a Chwestiynau Cyffredin
      </Subtitle1>
      <Body1>
        <p>
          Dod o hyd i atebion i gwestiynau cyffredin am nodweddion a gosodiadau
          TeamConnect yn yr adran benodol hon. P'un a ydych chi'n llywio'r ap,
          yn addasu gosodiadau, neu'n chwilio am atebion i heriau, mae ein
          Canolfan Gymorth yma i'ch cynorthwyo'n effeithlon.
        </p>
      </Body1>
      <Body1>
        <p>
          Am gymorth mwy manwl neu arweiniad penodol, ymgynghorwch â'r{" "}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>
              Canllaw Defnyddiwr TeamConnect
            </Body1Strong>
          </Link>
          .
        </p>
      </Body1>
      <p></p>

      <Subtitle2>Defnyddio Cwestiynau Cyffredin TeamConnect</Subtitle2>
      <p></p>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="1">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                1. Methu dod o hyd i TeamConnect ym mar ochr Microsoft Teams?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Os nad yw TeamConnect yn weladwy yn hawdd neu wedi diflannu yn y
                bar ochr Microsoft Teams, gallwch ei pinio'n hawdd ar gyfer
                mynediad cyflym. Dilynwch y camau syml hyn:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Agorwch Microsoft Teams a chliciwch ar yr eicon '...' (+
                    Apps) ar waelod y bar ochr.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Chwiliwch am 'TeamConnect' yn y bar chwilio ar frig y
                    ffenestr naid.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Cliciwch ar y dde ar ap TeamConnect o'r canlyniadau chwilio
                    a dewis 'Pin'.
                  </li>
                </ol>
                Bydd TeamConnect bellach wedi’i pinio i'ch bar ochr, gan ei
                gwneud yn hygyrch gyda dim ond clic!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="2">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                2. Profi problemau technegol gyda TeamConnect? Dyma sut i gael
                help.
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Os ydych yn profi unrhyw broblemau technegol gyda TeamConnect o
                fewn Microsoft Teams, mae tîm Cymorth TG eich sefydliad yma i'ch
                helpu. Cysylltwch â hwy ar{" "}
                <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>
                , a byddant yn eich cynorthwyo'n brydlon.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="3">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                3. Methu gweld manylion eich gweithiwr?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Os yw eich proffil yn teimlo'n eithaf gwag gyda dim ond eich enw
                a chyfeiriad e-bost yn ymddangos, mae'n debyg nad yw eich tîm AD
                eto wedi ychwanegu gweddill eich manylion gweithiwr i
                TeamConnect. Peidiwch â phoeni! Bydd neges gyflym at eich tîm
                cymorth AD yn rhoi pethau ar waith. Maent ar standby i lenwi
                eich proffil gyda'r holl fanylion pwysig. Cysylltwch ar{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>{" "}
                a gwelwch eich proffil yn dod yn fyw!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="4">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                4. Pa opsiynau iaith sydd ar gael yn TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mae TeamConnect yn croesawu amrywiaeth fyd-eang Microsoft Teams
                trwy gefnogi pob un o'i ieithoedd. P'un a ydych chi'n ffafrio
                Arabeg neu Roeg, Japaneaidd neu Swedeg, mae gennych chi fyd o
                opsiynau ar flaenau eich bysedd, gan gynnwys:
              </Body1>
              <ul>
                <li>
                  <Body1>
                    Arabeg, Bwlgareg, Tsieinëeg (Syml a Thraddodiadol), Croateg,
                    Tsieceg, Daneg, Iseldireg, Saesneg, Estonieg, Ffineg,
                    Ffrangeg, Almaeneg, Groeg, Hebraeg, Hwngareg, Indonesieg,
                    Eidaleg, Japaneaidd, Coreeg, Latfieg, Lithwaneg, Norwyeg
                    (Bokmål), Pwyleg, Portiwgaleg (Brasil a Phortiwgal),
                    Rwmaneg, Rwsieg, Serbeg (Lladin), Slofaceg, Slofeneg,
                    Sbaeneg, Swedeg, Thai, Twrceg, Wcreineg, Fietnameg.
                  </Body1>
                </li>
              </ul>
              <Body1>
                I addasu eich profiad TeamConnect i'ch iaith ddewisol, addaswch
                osodiadau iaith yn uniongyrchol yn Microsoft Teams, gan fod
                TeamConnect yn integreiddio'n llawn â'r gosodiadau hyn:
              </Body1>
              <ol>
                <Body1>
                  <li className={styles.bulletBottomMargin}>
                    Ewch i osodiadau eich Microsoft Teams trwy glicio ar y tri
                    dot wrth ymyl eich llun proffil ar y brig, a dewis
                    'Ymddangosiad a hygyrchedd'.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Sgroliwch i'r adran 'Iaith' a dewiswch eich iaith ddewisol
                    o'r ddewislen gollwng.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Cadarnhewch eich newidiadau trwy glicio'r botwm 'Arbed ac
                    ailgychwyn' i gymhwyso'r gosodiadau iaith newydd.
                  </li>
                </Body1>
              </ol>
              <Body1Strong>Nodyn: </Body1Strong>
              <Body1>
                Bydd newidiadau a wneir i'ch gosodiadau iaith yn Microsoft Teams
                yn diweddaru eich profiad TeamConnect yn ddi-dor hefyd. Byddwch
                yn ymwybodol bod newid yr iaith yn Teams hefyd yn gallu addasu
                eich fformat amser a dyddiad. Ar hyn o bryd, mae TeamConnect yn
                esblygu i gyfer ysgrifennu o'r dde i'r chwith. Rydym yn gyffrous
                am y gwelliannau hyn ac fe fyddwn yn eich hysbysu wrth iddynt
                ddod ar gael.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="5">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                5. Sut i newid y thema yn TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mae TeamConnect yn cydamseru â'ch dewisiadau thema Microsoft
                Teams, gan gynnwys Dewisiadau Golau, Tywyll, a Chyferbyniad
                Uchel. I addasu eich thema:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Mynediad i'ch gosodiadau Microsoft Teams trwy glicio ar y
                    tri dot wrth ymyl eich llun proffil ar y brig.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Dewiswch 'Ymddangosiad a hygyrchedd' i archwilio'r opsiynau
                    thema.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Dewiswch eich thema ddewisol o'r opsiynau sydd ar gael i
                    ddiweddaru eich rhyngwyneb TeamConnect ar unwaith i gyfateb.
                  </li>
                </ol>
                Bydd eich dewis thema yn Microsoft Teams yn cael ei gymhwyso'n
                awtomatig i TeamConnect, gan sicrhau profiad gweledol cyson ar
                draws eich gweithle.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="6">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                6. Archwilio Botymau Gweithredu Cyswllt TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ledled TeamConnect, lle bynnag y dewch o hyd i fanylion cyswllt
                gweithiwr, fe welwch hefyd ein botymau mynediad cyflym cyfleus.
                Mae'r offer defnyddiol hyn wedi'u cynllunio i symleiddio eich
                cyfathrebu o fewn ecosystem Microsoft, gan sicrhau eich bod chi
                bob amser dim ond clic i ffwrdd o gysylltu. Dyma eich canllaw
                cynhwysfawr i ddefnyddio'r botymau hyn yn effeithiol:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Eicon Sgwrs:</Body1Strong> Cliciwch i ddechrau
                    sgwrs Microsoft Teams ar unwaith. Os bydd angen i chi
                    ddychwelyd i TeamConnect ar ôl dechrau sgwrs, defnyddiwch y
                    saeth ôl yn yr App Bwrdd Gwaith, y botwm ôl yn yr App
                    Symudol, neu fotwm ôl eich porwr.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Eicon E-bost:</Body1Strong> Yn agor drafft
                    e-bost Outlook newydd gyda chyfeiriad y derbynnydd yn barod
                    yn y maes "To:" Os na fydd Outlook yn lansio, mae'n werth
                    gwirio gyda'ch adran TG am unrhyw gyfyngiadau.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Eicon Galwad Fideo:</Body1Strong> Yn cychwyn
                    galwad fideo wyneb yn wyneb gan ddefnyddio Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Eicon Galwad:</Body1Strong> Yn dechrau galwad
                    llais Microsoft Teams, gan wneud trafodaethau cyflym yn
                    ddi-dor.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Eicon Siart Sefydliadol:</Body1Strong> Yn
                    datgelu lle'r gweithiwr yn hierarchaeth sefydliadol, gan
                    gynnwys eu rheolwr a'u hadroddiadau uniongyrchol.
                  </li>
                  <li>
                    <Body1Strong>Eicon Mwy (...):</Body1Strong> Yn datgelu
                    opsiynau pellach:
                    <ul>
                      <li
                        style={{ marginTop: "8px" }}
                        className={styles.bulletBottomMargin}
                      >
                        <Body1Strong>Cwrdd Nawr:</Body1Strong> Yn agor Microsoft
                        Teams i ddechrau cyfarfod ar unwaith gyda'r gweithiwr.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Cyfarfod Newydd:</Body1Strong> Yn eich
                        cyfeirio chi i Microsoft Teams i drefnu cyfarfod newydd.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Ychwanegu Cyswllt:</Body1Strong> Yn
                        ychwanegu manylion cyswllt y gweithiwr o TeamConnect
                        i'ch ap People Microsoft Teams newydd, gan gyfoethogi
                        eich swyddogaeth alw.
                      </li>
                    </ul>
                  </li>
                </ul>
                Mae'r botymau greddfol hyn wedi'u crefftio i sicrhau y gallwch
                gysylltu'n effeithlon â chydweithwyr, p'un a yw hynny ar gyfer
                sgwrs gyflym, e-bost, neu i drefnu cyfarfodydd yn y dyfodol.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="7">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                7. Gwneud galwadau trwy TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Eisiau gwneud galwad? Mae TeamConnect yn symleiddio eich
                cyfathrebu trwy integreiddio â Microsoft Teams. Cliciwch yn syml
                ar y rhif ffôn dymunol yn TeamConnect, ac voilà, mae'r
                rhyngwyneb galw Microsoft Teams yn ymddangos, yn barod i chi
                ddeialu'n ddi-dor.
                <p>
                  <Body1Strong>Nodyn: </Body1Strong>
                  Os ydych chi'n profi problemau gyda gwneud galwadau? Efallai
                  fod hyn oherwydd polisïau TG eich sefydliad neu'r defnydd o
                  system ffôn gwahanol i System Ffôn Microsoft Teams. Mae gwirio
                  gyda'ch adran TG yn{" "}
                  <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>
                      {itSupport}
                    </Body1Strong>
                  </Link>
                  , yn gallu taflu goleuni ar y mater.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="8">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                8. A oes costau'n gysylltiedig â gwneud galwadau trwy
                TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mae TeamConnect yn gwella eich profiad galw trwy ddefnyddio
                Microsoft Teams. Dyma'r hyn sydd angen i chi ei wybod am gostau
                sy'n gysylltiedig â galwadau:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    Ar gyfer galwadau i ffônau gwaith gweithwyr, ffônau
                    adrannau, neu linellau poeth o fewn eich sefydliad (rhifau
                    mewnol), fel arfer <Body1Strong>nid oes costau</Body1Strong>{" "}
                    wrth ddefnyddio System Ffôn Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Ar gyfer galwadau allanol, fel y rhai i ffonau symudol neu
                    linellau allanol,{" "}
                    <Body1Strong>cyfraddau safonol</Body1Strong> system ffôn
                    eich sefydliad yn berthnasol. Mae'r cyfraddau hyn yn amrywio
                    yn seiliedig ar eich cynllun gwasanaeth penodol gyda
                    Microsoft Teams.
                  </li>
                </ul>
                <p>
                  <Body1Strong>Nodyn:</Body1Strong> Os ydych chi'n dod ar draws
                  unrhyw faterion neu os oes gennych gwestiynau am gostau
                  galwadau, mae'n orau i ymgynghori â'ch adran TG yn{" "}
                  <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>
                      {itSupport}
                    </Body1Strong>
                  </Link>{" "}
                  am wybodaeth fanwl wedi'i teilwra i setup a pholisïau eich
                  sefydliad.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="9">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                9. Yn sylwi ar anghysondeb gyda'ch statws presenoldeb 'Allan o'r
                Swydd' yn TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Sylwi nad yw eich statws presenoldeb 'Allan o'r Swydd' o
                Microsoft 365 yn adlewyrchu'n gywir yn TeamConnect? Mae gennym
                chi wedi'i orchuddio. Mae'r nodwedd i gysoni statws presenoldeb
                'Allan o'r Swydd' eisoes wedi'i gweithredu yn TeamConnect. Rydym
                yn aros ar hyn o bryd am Microsoft i gyflwyno'r nodwedd hon.
                Unwaith y bydd hynny'n digwydd, bydd eich statws presenoldeb
                'Allan o'r Swydd' yn cael ei adlewyrchu'n gywir yn TeamConnect,
                gan gyd-fynd â dangosyddion presenoldeb Microsoft Teams am
                brofiad di-dor.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Cwestiynau Cyffredin Cyfeiriadur y Gweithwyr</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Cyflwyniad i'r Cyfeiriadur Gweithwyr:</Body1Strong>{" "}
          Llywio tirwedd broffesiynol eich sefydliad gyda'r Cyfeiriadur
          Gweithwyr, eich canolfan ganolog ar gyfer mewnwelediadau manwl
          ynghylch gweithwyr, deinamig gweithle, a chysylltedd uniongyrchol â
          phroffiliau a pherthnasoedd rheolaidd eich cydweithwyr.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="10">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                10. Angen ychwanegu, diweddaru, neu gywiro eich data gweithwyr?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                I ychwanegu, diweddaru, neu gywiro eich data gweithwyr yn
                TeamConnect, cysylltwch â thîm cymorth AD eich sefydliad. Maent
                yn barod i helpu yn{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>
                .
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="11">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                11. Beth sy'n digwydd pan fyddaf yn clicio ar enw gweithiwr yn y
                cyfeiriadur?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mae clicio ar enw gweithiwr yn Cyfeiriadur Gweithwyr TeamConnect
                yn agor tudalen broffil fanwl ar gyfer yr unigolyn hwnnw. Mae'r
                proffil hwn yn darparu mewnwelediadau cynhwysfawr am y
                gweithiwr, gan gynnwys ond heb fod yn gyfyngedig i, eu teitl
                swydd, adran, lleoliad swyddfa, gwybodaeth gyswllt, a mwy. Mae
                wedi'i gynllunio i roi trosolwg llawn i chi o rôl broffesiynol y
                gweithiwr a sut maent yn ffitio i mewn i'r sefydliad, gan ei
                gwneud yn haws i chi ddeall eu cyfrifoldebau a sut y gallech chi
                gydweithio â nhw.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="12">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                12. Pa wybodaeth sy'n cael ei chynnwys ym mhroffil gweithiwr?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mae pob proffil gweithiwr yn TeamConnect yn gipolwg cynhwysfawr,
                gan gynnig cyfoeth o fewnwelediadau i fywyd proffesiynol yr
                unigolyn. Dyma beth gewch chi:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Manylion Sylfaenol:</Body1Strong> Enw, teitl
                    swydd, adran, a lleoliad is-gwmni.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Strwythur Adrodd:</Body1Strong> Gwybodaeth am
                    adroddiadau uniongyrchol a swyddogaethol, gan gynnwys
                    rheolwyr llinell a swyddogaethol.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Statws Gweithle:</Body1Strong> Statws gwaith
                    presennol fel Ar- Absenoldeb, Teithio ar Fusnes, ac ati.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Gweithle & Amser Lleol:</Body1Strong> Lleoliad
                    gwaith cyfredol y gweithiwr a parth amser lleol.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Manylion Cyswllt:</Body1Strong> Ffôn gwaith,
                    rhif symudol, a chyfeiriad e-bost.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Cyfeiriad Swyddfa:</Body1Strong> Lleoliad
                    swyddfa llawn gan gynnwys enw'r adeilad, cyfeiriad, a map
                    Google.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ynglŷn:</Body1Strong> Cyflwyniad personol byr
                    neu fywgraffiad proffesiynol.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Cysylltiadau Gweithwyr:</Body1Strong> Manylion
                    strwythur adrodd a chysylltiadau tîm y gweithiwr .
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Cysylltiadau Cymdeithasol & Ieithoedd:
                    </Body1Strong>{" "}
                    Dolenni i broffiliau cymdeithasol proffesiynol a hyfedreddau
                    iaith.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Sgiliau:</Body1Strong> Rhestr o sgiliau
                    proffesiynol a meysydd arbenigedd.
                  </li>
                </ul>
                Yn ogystal, mae'r proffil yn cynnig opsiynau ymarferol i
                gysylltu trwy sgwrs, e-bost, galwad fideo, neu drefnu
                cyfarfodydd, ynghyd â mynediad cyflym i'r siart sefydliadol ar
                gyfer cyd-destun tîm ehangach.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="13">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                13. Sut alla i ddiweddaru gwybodaeth fy mhroffil?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mae personoli eich proffil yn hawdd yn TeamConnect. Dyma sut y
                gallwch ei wneud:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Ewch i'r tab <Body1Strong>Employee</Body1Strong> a chlicio
                    ar eich <Body1Strong>Avatar</Body1Strong> i agor eich
                    proffil.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Yn y gornel chwith uchaf, fe welwch y ddolen{" "}
                    <Body1Strong>"Golygu eich proffil"</Body1Strong>. Bydd
                    clicio ar hyn yn agor y deialog golygu ar gyfer eich
                    proffil.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Yma, gallwch wella eich proffil trwy ychwanegu neu
                    ddiweddaru eich adran <Body1Strong>About You</Body1Strong>,
                    dolenni cyfryngau cymdeithasol, a rhif ffôn. Mae hyn yn ei
                    gwneud hi'n haws i'ch cydweithwyr gysylltu neu ddilyn chi.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Peidiwch ag anghofio pwyswch y botwm{" "}
                    <Body1Strong>Save</Body1Strong> i gymhwyso eich newidiadau.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Sgroliwch i lawr yn y deialog i ychwanegu eich{" "}
                    <Body1Strong>Languages</Body1Strong> a{" "}
                    <Body1Strong>Proficiency</Body1Strong>, yn ogystal â'ch{" "}
                    <Body1Strong>Skills</Body1Strong>. Mae rhannu'r wybodaeth
                    hon yn helpu eich cydweithwyr i ddeall eich meysydd
                    arbenigedd a'r ieithoedd y gallwch gyfathrebu ynddynt.
                  </li>
                </ol>
                Mae diweddaru eich proffil nid yn unig yn cadw eich gwybodaeth
                yn gyfredol ond hefyd yn hybu cysylltiadau gwell o fewn eich
                sefydliad.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="14">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                14. Diweddaru Eich Gweithle, Mannau Gwaith, ac Amser Parth yn
                TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mae cadw gwybodaeth am eich gweithle, mannau gwaith, ac amser
                parth yn gyfredol yn hanfodol ar gyfer cydweithio di-dor yn
                Microsoft Teams. Caiff y diweddariadau hyn eu hadlewyrchu ar
                unwaith yn eich proffil TeamConnect a rhestrau, gan alluogi
                cydweithwyr i ddeall yn gyflym eich lleoliad gwaith a'r
                amseroedd gorau i gysylltu â chi, gan wella cydweithio ar draws
                gwahanol ranbarthau ac amser parthau.
                <p></p>
                Yn barod i ddiweddaru eich manylion? Mae'n hawdd yn TeamConnect!
                Ewch i 'Settings' yn y Dewislen Uchaf ac ewch i 'General
                Settings'. Yma, gallwch ddiweddaru eich Gweithle ac Amser Parth
                yn esmwyth, a nodi eich Mannau Gwaith ar gyfer y dydd. Cofiwch
                gadw eich newidiadau i gadw pawb yn wybodus ac i sicrhau
                cydweithio effeithiol, waeth ble rydych chi!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="15">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                15. Wedi sylwi ar rywbeth anghywir yn mhroffil cydweithiwr?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Os dawch chi ar draws unrhyw fanylion yn mhroffil cydweithiwr
                nad ydynt yn ymddangos yn iawn, y cam cyntaf gorau yw rhoi
                gwybod iddynt yn gyfeillgar. Dylai sgwrs neu e-bost gyflym wneud
                y tro! Os am ryw reswm nad yw'r wybodaeth yn cael ei diweddaru,
                peidiwch â phoeni—dim ond pingio eich tîm cymorth AD yn{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>
                , a byddant yn hapus i gamu i mewn ac yn datrys pethau.
              </Body1>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem value="16">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                16. Deall Grwpiau Tagiau: Pob, Ffefrynnau, Wedi Gwirio i Mewn, a
                Gweithwyr Newydd
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Pob:</Body1Strong> Mae'r tag diofyn yn
                    arddangos pob gweithiwr yn eich sefydliad, gan ddarparu
                    golwg gynhwysfawr ar y tîm.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ffefrynnau:</Body1Strong> Cliciwch yma i hidlo
                    eich golwg i'r cydweithwyr hynny rydych chi wedi'u marcio
                    fel 'Ffefrynnau,' gan ei gwneud hi'n haws cysylltu'n gyflym
                    â chysylltiadau allweddol.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Wedi Gwirio i Mewn:</Body1Strong> Mae'r tag hwn
                    yn dangos gweithwyr sy'n weithgar yn y gwaith, gan
                    wahaniaethu o bresenoldeb ar-lein yn unig yn Microsoft
                    Teams. Pan fyddant wedi gwirio i mewn, mae gweithwyr yn
                    signalu eu bod yn y gwaith ac yn rhannu eu lleoliad gwaith
                    cyfredol yn ystod y dydd. Gall hyn gynnwys amrywiaeth o
                    weithleoedd fel Swyddfa, Swyddfa-Maes, Swyddfa-Gartref,
                    Gweithle-Pellter, Gweithle-Cwsmer, Swyddfa Breifat Cwsmer,
                    Ardal Gyhoeddus, Teithio, Teithio Busnes, neu hyd yn oed
                    Hyfforddiant. Felly, mae'r statws 'Wedi Gwirio i Mewn' yn
                    darparu arwydd clir bod rhywun yn weithgar yn y gwaith, gan
                    gynnig dealltwriaeth fanwl i'r cydweithwyr o gyd-destun
                    gwaith a argaeledd presennol ei gilydd.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Gweithwyr Newydd:</Body1Strong> Nodi wynebau
                    newydd yn TeamConnect gyda'r tag hwn, gan amlygu gweithwyr
                    sydd wedi'u hychwanegu at yr ap o fewn y 30 diwrnod
                    diwethaf. Fel arfer, mae hyn yn cyd-fynd â ychwanegiadau
                    diweddar i'ch sefydliad, gan eich helpu i groesawu aelodau
                    newydd y tîm.
                  </li>
                </ul>
                Mae'r grwpiau tagiau hyn wedi'u cynllunio i symleiddio llywio a
                gwella eich profiad TeamConnect, gan sicrhau y gallwch bob amser
                ddod o hyd i'ch cydweithwyr ac ymgysylltu â nhw'n effeithlon.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="17">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                17. Datgodio'r Eiconau wrth ymyl y Blwch Chwilio
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mae Cyfeiriadur Gweithwyr TeamConnect wedi'i gynllunio ar gyfer
                hyblygrwydd a hwylustod, yn cynnwys pedwar eicon sy'n cynnig
                gwahanol olygfeydd a mynediad cyflym i fanylion allweddol. Dyma
                beth mae pob eicon yn ei ddatgelu:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Golwg Rhestr:</Body1Strong> Eich golwg fynd-i,
                    yn cyflwyno holl aelodau'r sefydliad mewn rhestr syml.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Golwg Cerdyn Bach:</Body1Strong> Dewis
                    cywasgedig, mae'r golwg hon yn trawsnewid y rhestr i gardiau
                    gweithwyr maint brathiad.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Golwg Cerdyn Mawr:</Body1Strong> Dewiswch fwy o
                    fanylion gyda chardiau mwy, sy'n cynnwys opsiynau cysylltu
                    ychwanegol fel rhifau ffôn a symudol ar gyfer galw'n hawdd.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Siart Sefydliad:</Body1Strong> Dychmygwch
                    strwythur y cwmni gyda'r golwg Siart Sefydliad, yn berffaith
                    ar gyfer deall perthynas adrodd a llywio'r dirwedd
                    sefydliadol.
                  </li>
                </ol>
                Dewiswch rhwng yr eiconau hyn i ddod o hyd i'r olygfa sy'n
                gweddu orau i'ch anghenion ar y foment, p'un a ydych chi'n
                chwilio am wybodaeth gyflym neu fewnwelediadau dyfnach.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="18">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                18. Ar gyfer chwiliadau cydweithwyr cyflym, ble ddylwn i edrych?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Yn chwilio am gydweithiwr? Ewch yn syth i flwch chwilio
                TeamConnect, wedi'i leoli ar ochr dde uchaf wrth ymyl eicon y
                siart sefydliad. Dechreuwch deipio eu henw, a gwyliwch wrth i'r
                canlyniadau gael eu puro gyda phob llythyr. Cofiwch, ar gyfer
                chwiliadau TeamConnect, sgipiwch far chwilio Microsoft Teams ar
                y brig—ein blwch chwilio arbennig ni yw'r ffordd i fynd!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="19">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                19. Sut mae'r chwiliad wyddorol yn gweithio i ddod o hyd i
                weithwyr?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mae Cyfeiriadur Gweithwyr TeamConnect yn cynnwys nodwedd chwilio
                wyddorol defnyddiol i'ch helpu i ddod o hyd i weithwyr yn gyflym
                yn ôl eu henw cyntaf. Cliciwch yn syml ar lythyren o'r wyddor a
                arddangosir ar frig y cyfeiriadur, a bydd TeamConnect yn dangos
                ar unwaith yr holl weithwyr y mae eu henwau cyntaf yn dechrau
                gyda'r llythyren honno. I ddychwelyd at y rhestr lawn o
                weithwyr, dim ond dad-ddewis y llythyren amlwg. Mae'r nodwedd
                greddf hon yn ei gwneud hi'n hawdd llywio trwy restrau mawr o
                weithwyr, gan sicrhau y gallwch ddod o hyd i gydweithwyr yn
                gyflym ac yn effeithlon.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="20">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                20. Sut y gallaf addasu nifer y gweithwyr a ddangosir ar bob
                tudalen?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mae TeamConnect yn caniatáu ichi addasu faint o broffiliau
                gweithwyr rydych chi'n eu gweld ar bob tudalen, gan ei gwneud
                hi'n haws i lywio drwy'r Cyfeiriadur Gweithwyr. Ar waelod pob
                tudalen, fe welwch opsiynau tudalennu: 10, 50, 100, 500, 1000, a
                5000. Mae'r gosodiad diofyn yn dangos 10 proffil ar bob tudalen.
                I weld mwy o broffiliau ar un tudalen, dewiswch eich rhif
                dewisol o'r opsiynau hyn. Mae'r nodwedd hon yn darparu
                hyblygrwydd yn y ffordd rydych chi'n pori drwy'r cyfeiriadur,
                gan eich galluogi i addasu'r arddangosfa i fodloni eich
                anghenion neu ddewisiadau penodol.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="21">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                21. Sut ydw i'n trefnu gwybodaeth gweithwyr yn y Gweld Rhestr?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Yn Gweld Rhestr Gyfeiriadur Gweithwyr TeamConnect, mae gennych y
                hyblygrwydd i drefnu gwybodaeth gweithwyr i'ch anghenion. Mae'r
                colofnau—Enw'r Gweithiwr, Teitl y Swydd, Adran, Lleoliad y
                Swyddfa, a'r Gweithle—i gyd yn drefnus. Cliciwch yn syml ar
                benawd y golofn rydych am ei threfnu. Bydd clicio unwaith yn
                trefnu 'r golofn mewn trefn esgynnol, a chlicio eto bydd yn ei
                threfnu mewn trefn ddisgynnol. Yn ddiofyn, mae'r cyfeiriadur
                wedi'i drefnu yn ôl Enw'r Gweithiwr mewn trefn esgynnol, yn
                seiliedig ar yr enw cyntaf. Mae'r nodwedd drefnu hon yn eich
                helpu i drefnu a gweld manylion gweithwyr yn gyflym mewn ffordd
                sy'n gwasanaethu eich pwrpas orau, boed chi'n chwilio am rywun
                penodol neu angen grwpio gweithwyr yn ôl maen prawf penodol fel
                adran neu leoliad.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Cwestiynau Cyffredin Cyfeiriadur yr Adran</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Cyflwyniad Cyfeiriadur yr Adran:</Body1Strong> Mae
          Cyfeiriadur yr Adran yn eich porth i ddeall cymhlethdodau strwythur
          eich sefydliad, gan gynnig golwg glir ar hierarchïau adrannol,
          arweinyddiaeth reolwyr, a llwybrau ar gyfer cyfathrebu adrannol
          di-dor.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="22">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                22. Sut mae'r tagiau 'Pob un', 'Ffefrynnau', a ''Eich Adran’ yn
                gweithio yn Nghyfeiriadur yr Adran?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Yn Cyfeiriadur Adran TeamConnect, mae tagiau'n eich helpu i
                lywio a hidlo drwy wybodaeth adrannol yn gyflym. Dyma beth mae
                pob tag yn ei gynrychioli:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Pob un:</Body1Strong> Mae'r tag hwn yn
                    arddangos pob adran o fewn eich sefydliad, gan ddarparu
                    trosolwg cynhwysfawr.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ffefrynnau:</Body1Strong> Defnyddiwch y tag hwn
                    i weld rhestr dethol o adrannau rydych wedi'u nodi fel
                    'Ffefrynnau', gan ei gwneud yn haws cael mynediad at
                    adrannau rydych yn rhyngweithio â nhw'n aml.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Eich Adran:</Body1Strong> Mae'r tag hwn yn
                    hidlo'r golwg i ddangos dim ond eich adran chi, gan ganiatáu
                    mynediad cyflym at wybodaeth adrannol uniongyrchol a
                    diweddariadau.
                  </li>
                </ul>
                Mae'r tagiau hyn wedi'u cynllunio i symleiddio'ch llywio o fewn
                Cyfeiriadur yr Adran, gan wella eich gallu i ddod o hyd i
                wybodaeth adrannol ac ymgysylltu â hi yn effeithlon.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="23">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                23. Deall Golygfeydd Cyfeiriadur yr Adran yn TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mae llywio drwy Gyfeiriadur yr Adran yn TeamConnect yn cael ei
                symleiddio gyda sawl opsiwn gwylio i siwtio'ch dewisiadau. Mae
                pob golyg yn cynnig ffordd unigryw o archwilio gwybodaeth
                adrannol gynhwysfawr, gan gynnwys Enw'r Adran, Rheolwr yr Adran,
                Adran Rhiant, Ffôn yr Adran, Hotline yr Adran, a Ebost yr Adran.
                Dyma gipolwg ar y golygfeydd sydd ar gael:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Golyg Rhestr:</Body1Strong> Y setup diofyn sy'n
                    rhestru'r holl adrannau'n daclus, gan ddarparu trosolwg clir
                    a chryno.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Golyg Cerdyn Mawr:</Body1Strong> Newid i'r
                    golyg hwn i weld cardiau adran manwl, yn cynnig crynodeb
                    ehangedig o wybodaeth allweddol pob adran.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Golyg Siart Sefydliad:</Body1Strong> Ar gyfer
                    darlunio strwythur a pherthnasoedd adrannol yn weledol,
                    mae'r golyg Siart Sefydliad yn dangos sut mae pob adran yn
                    ffitio o fewn hierarchaeth sefydliadol ehangach.
                  </li>
                </ul>
                I newid rhwng y golygfeydd hyn, defnyddiwch yn syml yr eiconau
                wrth ymyl y blwch chwilio ar frig Cyfeiriadur yr Adran. Mae pob
                golyg wedi'i gynllunio i'ch helpu chi i gael mynediad at
                fanylion adrannol a'u deall yn hawdd, gan sicrhau eich bod yn
                dod o hyd i'r wybodaeth sydd ei hangen arnoch yn y fformat sy'n
                gweithio orau i chi.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="24">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                24. Sut i Ddefnyddio'r Blwch Chwilio yn y Cyfeiriadur Adran
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Canfod unrhyw adran yn TeamConnect yn ddi-dor gan ddefnyddio'r
                blwch chwilio penodedig sydd wedi'i leoli yn union wrth ymyl yr
                eiconau gweld ar frig y Cyfeiriadur Adran. Dechreuwch deipio
                enw'r adran rydych chi'n chwilio amdani, a gwyliwch wrth i'r
                cyfeiriadur ei hun ei fireinio i arddangos dim ond cyfatebion
                perthnasol. Mae'r nodwedd hon wedi'i chynllunio'n benodol i'ch
                helpu i osgoi'r angen i chwilio â llaw drwy'r cyfeiriadur cyfan,
                gan ddod â'r adran sydd ei hangen arnoch yn uniongyrchol at eich
                bysedd. Cofiwch, mae'r swyddogaeth chwilio hon yn unigryw i
                Gyfeiriadur Adran TeamConnect, felly sicrhewch eich bod yn
                defnyddio'r blwch chwilio hwn yn hytrach na'r chwilio prif
                Microsoft Teams ar frig rhyngwyneb y Timau ar gyfer ymholiadau
                sy'n benodol i'r adran.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="25">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                25. Sut mae'r hidlydd wyddorol yn gweithio yn y Cyfeiriadur
                Adran?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Symleiddio eich chwilio yn y Cyfeiriadur Adran gyda'r hidlydd
                wyddorol a leolir ar y brig. Mae'r nodwedd hon yn caniatáu ichi
                gulhau adrannau'n gyflym trwy ddewis llythyren. Unwaith y
                dewiswch lythyren, mae TeamConnect yn hidlo ac yn arddangos dim
                ond yr adrannau hynny y mae eu henwau'n dechrau gyda’ch
                llythyren a ddewiswyd, gan ei gwneud yn haws dod o hyd i'r hyn
                rydych chi'n chwilio amdano heb sgrolio drwy'r rhestr gyfan. I
                ddychwelyd i'r golwg adran lawn, dim ond dad-ddewis y llythyren
                a amlygwyd. Mae'r llywio wyddorol greddfol hwn yn sicrhau y
                gallwch chi leoli unrhyw adran yn effeithlon yn ôl ei llythyren
                gychwynnol.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="26">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                26. Sut alla i gysylltu â rheolwr yr adran?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mae cysylltu â rheolwr adran yn TeamConnect yn syml. Dod o hyd
                i'r adran sydd o ddiddordeb a chlicio ar enw'r rheolwr. Bydd y
                cam hwn yn sbarduno popover yn dangos opsiynau cyswllt y rheolwr
                gyda'r eiconau mynediad cyflym cyfarwydd:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Eicon Sgwrs:</Body1Strong> I ddechrau neges ar
                    unwaith trwy sgwrs Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Eicon E-bost:</Body1Strong> I agor e-bost
                    newydd sy'n cael ei gyfeirio atynt yn Microsoft Outlook.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Eicon Galwad Fideo:</Body1Strong> I ddechrau
                    galwad fideo trwy Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Eicon Galw:</Body1Strong> I ddechrau galwad
                    llais gan ddefnyddio Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Eicon Mwy (...):</Body1Strong> Am opsiynau
                    ychwanegol fel trefnu cyfarfod neu ychwanegu eu gwybodaeth
                    gyswllt i'ch rhestr Timau.
                  </li>
                </ul>
                Mae'r eiconau hyn yn darparu dull di-dor i gysylltu'n
                uniongyrchol â rheolwyr adrannau o fewn TeamConnect, gan sicrhau
                cyfathrebu effeithlon o fewn eich sefydliad.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="27">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                27. Sut allai weld neu gysylltu ag aelodau'r adran?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                I archwilio pwy sydd pwy o fewn adran yn TeamConnect, edrychwch
                dim pellach na'r tair dot wrth ymyl enw'r adran. Clicio ar hyn
                bydd yn dod â dewisiadau ar gyfer camau gweithredu pellach:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Gweld Cyflogeion yn yr Adran Hon:</Body1Strong>{" "}
                    Dewiswch hwn i arddangos rhestr o'r holl aelodau
                    cysylltiedig â'r adran. Mae'r rhif wrth ymyl y dewis hwn yn
                    dangos faint o gyflogeion sydd yn yr adran, gan roi cyfrif
                    pen sydyn.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Siart Trefnu'r Adran:</Body1Strong> Os ydych am
                    ddeall hierarchaeth yr adran, dewis 'siart trefnu adran' i
                    ddatgelu'r cynllun strwythurol, yn arddangos y berthynas
                    rhwng adrannau rhiant, yr adran dan sylw, ac unrhyw
                    is-adrannau. Mae'r cynrychiolaeth weledol hon yn eich helpu
                    i lywio a deall y fframwaith sefydliadol, gan ei gwneud yn
                    haws i adnabod ac cysylltu ag aelodau adran benodol.
                  </li>
                  <li>
                    <Body1Strong>Adran {">"} Cyflogai:</Body1Strong> Ar ôl gweld
                    y cyflogeion o fewn adran, defnyddiwch y llwybr briwsion hwn
                    i lywio'n hawdd yn ôl at restr yr adran, gan gynnal profiad
                    archwilio esmwyth o fewn TeamConnect.
                  </li>
                </ul>
                Mae'r nodweddion hyn yn caniatáu i chi nid yn unig weld rhestr o
                gyflogeion mewn adran benodol ond hefyd yn cynnig llwybr i
                gysylltu â hwy'n uniongyrchol trwy offer cyfathrebu TeamConnect.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="28">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                28. Darganfod Manylion Dwfn Adran yn TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Archwilio manylion penodol unrhyw adran gyda chlic syml ar ei
                henw yn TeamConnect. Mae deialog fanwl yn datblygu, gan gynnig
                tapis cyfoethog o wybodaeth sy'n paentio darlun llawn o rôl ac
                adnoddau'r adran o fewn eich sefydliad. Fe welwch bopeth o
                arweinyddiaeth a nodweddion ariannol i sianeli cyfathrebu a
                dolenni cymdeithasol. Mae'r nodwedd hon yn eich porth i:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    Calon yr adran – ei phwrpas, pobl, a metrigau perfformiad.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Afonau cyswllt – llinellau uniongyrchol at ffigurau
                    allweddol yr adran a llwyfannau cyfathrebu.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Gwe rhwydweithiol sefydliadol – deall sut mae'r adran yn
                    integreiddio gyda strwythur ehangach y cwmni.
                  </li>
                </ul>
                P'un a yw'n ymholiad cyflym neu astudiaeth ddofn o ddata'r
                adran, mae TeamConnect yn eich arfogi â'r offer i gysylltu â a
                deall ecosystemau unigryw o fewn eich sefydliad.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="29">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                29. Chwilfrydig am absenoldeb Cyfeiriadau Adran yn TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Weithiau gall darganfod lleoliad adran deimlo fel helfa drysor
                heb fap. Yn TeamConnect, nid yw'n arferol dod o hyd i gyfeiriad
                penodol ar gyfer pob adran. Dyma pam mae'r manylion cartograffig
                hyn yn aml yn cael eu hepgor:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Lleoliadau Lluosog:</Body1Strong> Mae llawer o
                    adrannau nid yn unig yn ymestyn i un gofod; maent yn ffynnu
                    ar draws swyddfeydd amrywiol a daearyddiaethau.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Strwythur Hylifol:</Body1Strong> Mae'r dirwedd
                    sefydliadol yn newid yn barhaus, gyda adrannau'n esblygu, yn
                    uno, neu hyd yn oed yn diddymu yn unol â newidiadau
                    strategol.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Cydweithio Rhyngadrannol:</Body1Strong> Mae
                    mannau gwaith modern yn swnio gyda'r ysbryd cydweithredol,
                    yn aml yn cyfuno talentau o adrannau amrywiol o fewn gofodau
                    a rennir.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Diwylliant Gwaith o Bell:</Body1Strong> Gyda'r
                    hyblygrwydd o weithio o bell, nid yw angorion adrannol bob
                    amser yn cael eu gollwng mewn lleoliadau swyddfa ffisegol.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Nuansau Cyfreithiol:</Body1Strong> Mae adrannau
                    fel arfer yn cylchdroi o amgylch strwythur cyfreithiol y
                    cwmni yn hytrach na chael eu cyfeiriadau annibynnol eu
                    hunain.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Streamlining Gweithredol:</Body1Strong> Cadw'r
                    ffocws ar waith tîm deinamig, nid cyfeiriadau statig, yn
                    helpu i gynnal hyblygrwydd gweithredol.
                  </li>
                </ul>
                Mae'r cyfan yn ymwneud â chroesawu dull di-ffiniau o gydweithio
                ac effeithlonrwydd. Mae TeamConnect yn adlewyrchu'r athroniaeth
                hon trwy bwysleisio cysylltiadau dros leoliadau.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="30">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                30. A yw gwybodaeth eich adran ar goll neu wedi dyddio?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Penodir golygyddion adrannol yn eich sefydliad i ychwanegu,
                diweddaru neu gywiro gwybodaeth benodol i'r adran yn
                TeamConnect. Os nad ydych chi'n siŵr pwy yw eich golygydd adran
                neu os oes angen ymyrraeth tîm cymorth AD ar wybodaeth benodol,
                cysylltwch â{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>{" "}
                am arweiniad.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Cwestiynau Cyffredin Cyfeiriadur y Swyddfa</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Cyflwyniad i'r Cyfeiriadur Swyddfa:</Body1Strong>{" "}
          Darganfyddwch asgwrn cefn presenoldeb corfforol eich sefydliad trwy'r
          Cyfeiriadur Swyddfa, sy'n cwmpasu manylion hanfodol y swyddfa,
          manylion gweithredol, a golwg gynhwysfawr ar yr adrannau a'r personél
          sy'n diffinio pob lleoliad.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="31">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                31. Llywio Tagiau yn y Cyfeiriadur Swyddfa
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Dewch o hyd i'ch ffordd o amgylch y Cyfeiriadur Swyddfa yn
                rhwydd gan ddefnyddio ein system tagiau a gynlluniwyd ar gyfer
                mynediad cyflym i'r lleoliad cywir. Dyma sut y gall pob tag eich
                helpu i symleiddio'ch chwiliad:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Popeth:</Body1Strong> Yn dangos rhestr gyflawn
                    o swyddfeydd, gan roi golwg aderyn o leoliadau eich
                    sefydliad.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ffefrynnau:</Body1Strong> Yn eich helpu i
                    ganolbwyntio ar y lleoliadau swyddfa hynny rydych chi'n
                    ymweld â nhw'n aml neu mae angen i chi gadw llygad barcud
                    arnynt, trwy eu marcio fel 'Ffefrynnau'.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Eich Swyddfa:</Body1Strong> Mae'r tag personol
                    hwn yn hidlo'r cyfeiriadur i ddangos lleoliad y swyddfa lle
                    rydych chi wedi'ch lleoli, gan gadw eich canolfan gartref
                    wrth eich bysedd.
                  </li>
                </ul>
                P'un a ydych chi'n archebu ystafell gyfarfod neu'n cynllunio
                ymweliad, mae'r tagiau hyn yn cynnig ffordd bersonol a
                symleiddiedig o ryngweithio â thirwedd ddaearyddol eich
                sefydliad.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="32">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                32. Meistroli Golygfeydd Cyfeiriadur Swyddfa yn TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Deifiwch i mewn i gynllun sefydliadol lleoliadau amrywiol eich
                cwmni gyda golygfeydd Cyfeiriadur Swyddfa TeamConnect. Mae pob
                golwg wedi'i chreu i hwyluso agwedd unigryw ar lywio swyddfa a
                dealltwriaeth:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Golwg Rhestr:</Body1Strong> Y gosodiad diofyn
                    sy'n trefnu pob lleoliad swyddfa yn daclus i mewn i restr
                    hawdd ei darllen.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Golwg Cerdyn Mawr:</Body1Strong> Mae'r Golwg
                    Cerdyn Mawr yn y Cyfeiriadur Swyddfa yn cyfoethogi eich
                    golwg gyda chyflwyniad manwl o bob swyddfa. Wrth ymgysylltu
                    â cherdyn swyddfa, fe welwch gyfoeth o wybodaeth a
                    dewisiadau gweithredu o fewn y ddewislen "Mwy (...)" :
                    <ol>
                      <li
                        style={{ marginTop: "8px" }}
                        className={styles.bulletBottomMargin}
                      >
                        <Body1Strong>Gweld Adrannau:</Body1Strong> Ewch yn syth
                        at restr o'r holl adrannau sydd wedi'u lleoli o fewn y
                        swyddfa.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Gweld Gweithwyr:</Body1Strong> Mynediad i
                        restr gyflawn o weithwyr sydd wedi'u neilltuo i'r
                        swyddfa, gan hwyluso cyswllt uniongyrchol neu archwilio
                        personél y swyddfa.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Siart Sefydliad Corff Busnes:</Body1Strong>{" "}
                        Dangoswch leoliad y swyddfa o fewn y cyd-destun
                        sefydliadol ehangach i gael dealltwriaeth glir o
                        strwythurau hierarchaidd.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Cysylltiadau Hanfodol:</Body1Strong>{" "}
                        Adnabod cysylltiadau allweddol y swyddfa ar gyfer
                        cyfathrebu di-dor.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Gwyliau Cyhoeddus y Swyddfa:</Body1Strong>{" "}
                        Cadwch wybodaeth am y gwyliau cyhoeddus penodol i'r
                        swyddfa i gynllunio eich amserlenni yn unol â hynny.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Oriau Gweithio'r Swyddfa:</Body1Strong>{" "}
                        Gweld oriau gweithredu'r swyddfa i gydweddu eich
                        ymweliadau neu alwadau â'u hamseroedd gweithgar.
                      </li>
                    </ol>
                    Mae'r golwg hon yn darparu nid yn unig ciplun gweledol a
                    gwybodaethol ond hefyd mynediad cyflym i fewnwelediadau
                    dyfnach a chamau gweithredu uniongyrchol sy'n gysylltiedig
                    â'r swyddfa, i gyd wedi'u trefnu'n daclus o dan yr opsiwn
                    "Mwy (...)" er eich hwylustod.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Golwg Siart Sefydliad:</Body1Strong> Dangoswch
                    y strwythur hierarchaidd o swyddfeydd rhiant ac
                    is-swyddfeydd i ddeall y cysylltedd rhwng gwahanol leoliadau
                    swyddfa o fewn eich sefydliad.
                  </li>
                </ul>
                Newidiwch drwy'r golygfeydd hyn gan ddefnyddio'r eiconau wrth
                ymyl y blwch chwilio i addasu eich profiad pori i weddu i'ch
                anghenion uniongyrchol.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="33">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                33. Dod o Hyd i Swyddfeydd yn Gyflym gyda Nodwedd Chwilio
                TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mae canolbwyntio ar y lleoliad swyddfa cywir o fewn TeamConnect
                wedi'i hwyluso gyda'r nodwedd chwilio neilltuedig. Wedi'i
                leoli'n amlwg wrth ymyl yr eiconau golygu, mae'r blwch chwilio
                yn eich offeryn i bennu swyddfeydd yn ôl enw. Wrth ichi deipio,
                mae'r cyfeiriadur yn puro'r arddangosfa i ddangos dim ond
                swyddfeydd sy'n cyfateb i'ch mewnbwn, gan symleiddio'ch broses
                chwilio. Mae'n bwysig defnyddio'r blwch chwilio hwn o fewn
                TeamConnect ar gyfer y canlyniadau mwyaf cywir a pherthnasol, yn
                hytrach nag ymchwilio cyffredinol Microsoft Teams. Gyda'r
                swyddogaeth chwilio targed hon, gallwch lywio'n gyflym i unrhyw
                swyddfa yn rhwydwaith eich sefydliad.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="34">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                34. Llywio'r Cyfeiriadur Swyddfa Yn Wyddorol
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Os ydych chi'n well gennych chwilio yn ôl yr wyddor, mae ein
                hidlydd wyddorol yn ffordd gyflym o ddod o hyd i swyddfeydd yn
                ôl enw. Ar ben y Cyfeiriadur Swyddfa, fe welwch y llythrennau A
                trwy Z. Clicio ar lythyren yn hidlo'r olygfa i ddangos dim ond y
                swyddfeydd sy'n dechrau gyda'r llythyren honno, gan
                symleiddio'ch broses chwilio. I ailosod a gweld yr holl
                swyddfeydd eto, dim ond dad-ddewis y llythyren weithredol. Mae'r
                nodwedd hon yn arbennig o ddefnyddiol pan fyddwch chi'n
                gyfarwydd â'r enw swyddfa ond angen ffordd gyflym o'i lleoli o
                fewn TeamConnect.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="35">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                35. Datgelu Proffiliau Swyddfa Cynhwysfawr yn TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Dechreuwch daith archwilio trwy galon presenoldeb corfforol eich
                sefydliad gyda phroffiliau swyddfa TeamConnect. Clicio ar enw
                swyddfa yn cyflwyno deialog broffil llachar, yn llawn o dabiau
                sy'n cynnwys mewnwelediadau manwl am y swyddfa:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Trosolwg:</Body1Strong> Plymiwch i graidd
                    hunaniaeth y swyddfa gyda disgrifiadau manwl, o'r strwythur
                    busnes i oriau agor ac amser lleol, gan ddal hanfod yr
                    amgylchedd swyddfa.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Cysylltiadau Hanfodol:</Body1Strong> Mynediad
                    at gysylltiadau hanfodol ar gyfer gwasanaethau brys a
                    chymorth allanol, pob un yn clic i ffwrdd trwy System Ffôn
                    Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Cysylltiadau'r Swyddfa:</Body1Strong>{" "}
                    Cysylltwch â gwasanaethau cymorth mewnol yn rhwydd, gyda
                    manylion cyswllt a dewisiadau ymgysylltu uniongyrchol wrth
                    eich bysedd.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Gwyliau Cyhoeddus:</Body1Strong> Cadwch lygad
                    ar wyliau penodol i'r swyddfa, gan sicrhau bod eich
                    cynlluniau yn cyd-fynd â dathliadau lleol ac amserlenni
                    gwaith.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Oriau'r Swyddfa:</Body1Strong> Cynlluniwch eich
                    rhyngweithiadau gyda gwybodaeth fanwl o pryd mae'r swyddfa
                    yn brysur gyda gweithgaredd neu'n gostegu ar gyfer y dydd.
                  </li>
                </ul>
                P'un a yw'n egni prysur Austin neu ganolfan strategol Singapore,
                mae pob tab yn cynnig porth i ddeall a chysylltu â'r lleoliadau
                amrywiol sy'n ffurfio presenoldeb byd-eang eich cwmni.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="36">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                36. Archwilio Ddynamigau Adrannau mewn Swyddfa
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Llywio rhwydwaith proffesiynol adrannau swyddfa yn datblygu'n
                hwylus yn TeamConnect. Dyma sut i archwilio'r manylion:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Gweld Adrannau yn y Swyddfa Hon:</Body1Strong>{" "}
                    Darganfyddwch yr holl adrannau sy'n ffurfio ffabrig y
                    swyddfa trwy ddewis yr opsiwn hwn o'r ddewislen Mwy (...).
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Mewnwelediad Sefydliadol:</Body1Strong>{" "}
                    Cliciwch ar unrhyw adran i ddatgelu ei siart trefniadol am
                    gynrychiolaeth weledol o'i strwythur, gyda'r llwybr briwsion
                    yn eich tywys: Swyddfa {">"} Adran {">"} Siart.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Astudio Adran yn Fanwl:</Body1Strong>{" "}
                    Archwiliwch adran ymhellach trwy glicio ar ei ddewislen Mwy
                    (...) wrth ymyl enw'r adran. Yma, gallwch gyrchu rhestr o'r
                    holl weithwyr o fewn yr adran honno, yn uniongyrchol o fewn
                    cyd-destun y swyddfa.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Llwybr Briwsion:</Body1Strong> Symudwch drwy'r
                    lefelau'n ddi-dor. O weithwyr yn ôl i adrannau neu o'r siart
                    trefniadol, mae'r llwybr briwsion (Swyddfa {">"} Adran {">"}{" "}
                    Gweithiwr {">"} Siart) yn cadw eich taith yn reddfol ac ar y
                    trywydd iawn.
                  </li>
                </ul>
                Mae llwybrau cymhleth TeamConnect yn eich gwahodd i archwilio,
                deall, ac ymgysylltu â phob haen o adrannau eich swyddfa, gan
                eich grymuso gyda gorchymyn llywio cynhwysfawr.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="37">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                37. Cysylltu â Phersonél Swyddfa yn TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Chwilfrydig am y wynebau tu ôl i swyddfa? Yr ateb yw un clic i
                ffwrdd yn TeamConnect. Ymgysylltwch â'r hynodion (tri dot) wrth
                ymyl enw'ch swyddfa a ddewiswyd i ddatgelu cyfres o opsiynau
                rhyngweithiol:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Gweld Gweithwyr yn y Swyddfa Hon:</Body1Strong>{" "}
                    Datgelwch restr o'r holl weithwyr proffesiynol sy'n galw'r
                    swyddfa hon yn gartref i'w gwaith. Mae'r nifer a ddangosir
                    yn cynnig cyfrif cyflym o'r gweithwyr, gan eich gwahodd i
                    archwilio cymuned y swyddfa.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Opsiynau Cyswllt:</Body1Strong> Mae pob cofnod
                    gweithiwr yn cael ei gyfarparu gyda eiconau gweithrediad
                    cyflym, gan ganiatáu i chi gychwyn sgwrsio, galwadau, neu
                    hyd yn oed gynadleddau fideo yn uniongyrchol o TeamConnect,
                    gan feithrin cyfathrebu didrafferth o fewn y swyddfa.
                  </li>
                  <li>
                    <Body1Strong>Llywio:</Body1Strong> I ddychwelyd i restr
                    ehangach y swyddfa o restr gweithwyr, cliciwch ar 'Swyddfa'
                    yn y llwybr briwsion (Swyddfa {">"} Gweithiwr), a chewch
                    eich tywys yn ôl i'r trosolwg swyddfa.
                  </li>
                </ul>
                Gyda'r nodweddion hyn, mae TeamConnect yn ei gwneud hi'n syml i
                weld dosbarthiad y gweithlu ar draws swyddfeydd ac i gysylltu'n
                ddi-drafferth, gan feithrin gweithle cysylltiedig waeth beth
                yw'r pellter.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="38">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                38. A yw gwybodaeth eich swyddfa ar goll neu'n hen?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Gellir trin diweddariadau gwybodaeth swyddfa yn TeamConnect gan
                olygyddion swyddfa penodedig. Os ydych chi'n ansicr am yr
                olygyddion swyddfa neu angen cymorth gyda gwybodaeth sy'n dod o
                dan adain AD, mae tîm cymorth AD yn barod i cynorthwyo yn{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>
                .
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Cwestiynau Cyffredin Gosodiadau</Subtitle2>
      <p></p>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="39">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                39. Pwysigrwydd Gosodiadau Cyffredinol Cywir yn TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Yn nhirwedd dynamig amgylchedd gwaith heddiw, mae aros yn
                gysylltiedig ac wedi'i gydgysylltu yn bwysicach nag erioed. Dyna
                lle mae diweddaru eich Gosodiadau Cyffredinol yn TeamConnect yn
                chwarae rôl hanfodol:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Dewis Gweithle:</Body1Strong> Dalwch hanfod
                    eich lleoliad gwaith trwy ddiweddaru eich Gweithle i
                    adlewyrchu eich sefyllfa bresennol, boed yn newid o dawelwch
                    eich Swyddfa-Gartref i brysurdeb y Swyddfa Brif, neu hyd yn
                    oed ymgartrefu mewn Ardaloedd Cyhoeddus. Mae'r eglurder hwn
                    yn eich lleoliad gwaith yn cynnig mewnwelediad i gydweithwyr
                    nid yn unig am y ffyrdd gorau o gysylltu â chi, ond hefyd am
                    natur y sgyrsiau y gallant eu cynnal gyda chi, gan ddiogelu
                    cyfrinachedd trafodaethau sensitif yn enwedig pan fyddwch
                    yng nghanol amgylchedd agored swyddfa cleient neu'n eistedd
                    mewn lolfa maes awyr.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Manyleb Gweithfan:</Body1Strong> Mae rhannu
                    eich dinas waith yn gwneud mwy na phontio pellteroedd mewn
                    trefniant tîm o bell—mae hefyd yn agor drysau i gyfarfodydd
                    proffesiynol ar hap neu gymdeithasu achlysurol. P'un a yw'n
                    goffi cyflym, cinio hamddenol, neu gyfarfod cydweithredol,
                    gall gwybod eich bod yn yr un ddinas drawsnewid diwrnod
                    cyffredin yn gyfle ar gyfer cysylltiadau wyneb yn wyneb
                    ystyrlon. Nid yn unig y mae hyn yn cryfhau cysylltiadau tîm
                    ond mae hefyd yn darparu dealltwriaeth gyfoethocach o'r
                    manylion lleol a allai fod yn dylanwadu ar eich gwaith.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Cywirdeb Parth Amser:</Body1Strong> Wrth i
                    dimau ymestyn ar draws cyfandiroedd, mae sicrhau bod eich
                    parth amser yn adlewyrchu eich lleoliad cyfredol yn hanfodol
                    ar gyfer harmoni cyfathrebu. Mae Amser Lleol eich proffil
                    TeamConnect yn cael ei ddylanwadu'n uniongyrchol gan y
                    gosodiad hwn, gan ddileu unrhyw ddyfalu gan gydweithwyr sy'n
                    ceisio cyrraedd chi.
                  </li>
                </ul>
                Neidiwch i 'Gosodiadau' {">"} 'Gosodiadau Cyffredinol' o fewn
                TeamConnect i ddirwyn manylion hyn i berffeithrwydd. Gall
                ychydig funudau a dreulir yn diweddaru eich gosodiadau wella'n
                sylweddol gydlyniant ac effeithlonrwydd eich hymdrechion
                cydweithredol ar draws y bwrdd.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="40">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                40. Dadansoddi Dewis Gweithle yn TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Dewis yr osodiad cywir yn y ddewislen Gweithle sy'n teilwra
                TeamConnect i adlewyrchu'ch statws gwaith cyfredol yn gywir.
                Rhoi'r llygoden dros yr eicon gwybodaeth am esboniad manwl o bob
                opsiwn:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Wedi Dod Allan/Sêl:</Body1Strong> Yn dynodi
                    amser i ffwrdd o'r gwaith, boed am egwyl neu ddiwedd y dydd.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Swyddfa/Maes-Swyddfa:</Body1Strong> Yn nodi
                    presenoldeb mewn gofod gwaith a reolir gan y cwmni.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Swyddfa Gartref:</Body1Strong> Gweithio o ofod
                    gwaith cartref penodedig.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Gweithle Pell/Gofod Cyhoeddus:</Body1Strong>{" "}
                    Gweithredu o amgylchedd nad yw'n swyddfa, gan awgrymu angen
                    am gyfathrebu hyblyg.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Gweithle Cwsmer/Swyddfa Breifat:</Body1Strong>{" "}
                    Ar safle gyda chleient, gyda nodyn ar lefelau preifatrwydd
                    ar gyfer sensitifrwydd sgwrs.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Teithio Busnes/Hyfforddiant/Teithio:
                    </Body1Strong>{" "}
                    Ar y symud neu'n ymgymryd â gweithgareddau gwaith penodol y
                    tu allan i'r swyddfa.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Gwyliau Cyhoeddus/Gwyliau/Amrywiol Absenoldebau:
                    </Body1Strong>{" "}
                    Oddi ar ddyletswydd yn swyddogol am resymau personol,
                    iechyd, neu wyliau.
                  </li>
                </ul>
                Dewis lleoliad gweithle priodol yn sicrhau bod eich tîm yn cael
                y cyd-destun sydd ei angen ar gyfer rhyngweithio, gan barchu
                argaeledd a phreifatrwydd.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="41">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                41. Llywio'r Statws 'Wedi Cofnodi' yn TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mae'r tag 'Wedi Cofnodi' yn Cyfeiriadur y Gweithwyr wedi'i
                gynllunio i arddangos yr holl weithwyr sydd ar waith ar hyn o
                bryd. Daw'r gwelededd hwn yn sgil y dewis Gweithle a wnewch:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Amgylcheddau Gwaith Gweithgar:</Body1Strong>{" "}
                    Mae'r dewisiadau Swyddfa, Maes-Swyddfa, Swyddfa Gartref,
                    Gweithle Pell, Ardaloedd Cyhoeddus, Gweithle Cwsmer, Swyddfa
                    Breifat Cwsmer, Teithio, Teithio Busnes, a Hyfforddiant yn
                    nodi eich bod yn ymgymryd â gwaith, gan eich gwneud yn 'Wedi
                    Cofnodi' ac yn weledol weithgar i gydweithwyr.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Lleoliadau Heb Fod yn Weithio:</Body1Strong>{" "}
                    Dewis Statwsau Fel Wedi Dod Allan, Egwyl, Gwyliau Cyhoeddus,
                    Gwyliau, Ar Absenoldeb, Absenoldeb Mamolaeth, neu Absenoldeb
                    Salwch yn eich tynnu o'r rhestr 'Wedi Cofnodi', gan osod
                    disgwyliadau clir ar gyfer argaeledd ac amseroedd ymateb.
                  </li>
                </ul>
                Mae'r swyddogaeth hon yn helpu i ddynodi cyfnodau gwaith o amser
                personol, gan gynorthwyo mewn cydweithrediad a chyfathrebu tîm
                effeithiol.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Rheoli Data a Chwestiynau Cyffredin Preifatrwydd</Subtitle2>
      <Body1>
        <p>
          Yn oes ddigidol heddiw, mae deall sut mae eich data'n cael ei reoli yn
          hanfodol. Mae TeamConnect yn ymrwymo i dryloywder ac arferion diogelu
          data cadarn. Isod, fe welwch wybodaeth am sut rydym yn trin, yn
          storio, ac yn diogelu eich data o fewn TeamConnect, gan sicrhau bod
          eich gweithle digidol yn ddiogel ac yn cydymffurfio.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="42">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                42. Sut mae TeamConnect yn diogelu fy data personol?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <p>
                  Mae TeamConnect yn ymroddedig i ddiogelu eich gwybodaeth
                  bersonol gyda dull cynhwysfawr o sicrhau diogelwch data:
                </p>
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Mewngofnodi Unigol (SSO):</Body1Strong> Er mwyn
                    cynyddu diogelwch a symleiddio’r profiad defnyddiwr, mae
                    TeamConnect yn integreiddio Mewngofnodi Unigol (SSO)
                    awtomatig gyda’ch cyfrif Microsoft 365, gan hwyluso
                    rheolaeth mynediad ddiogel ac heb drafferth.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Amgryptio:</Body1Strong> Rydym yn sicrhau bod
                    yr holl ddata o fewn TeamConnect wedi'u hamgryptio yn ystod
                    trosglwyddo ac wrth orffwys, gan eu gwneud yn anhygyrch i
                    bartïon heb awdurdod.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Lleihau Data:</Body1Strong> Mae ein casglu data
                    yn llym gyfyngedig i'r hyn sy'n hanfodol ar gyfer
                    swyddogaethau TeamConnect. Yr unig wybodaeth defnyddiwr
                    rydym yn ei storio yw eich Enw Prif Ddefnyddiwr (UPN) o
                    Azure Entra ID, a allai debygu eich cyfeiriad e-bost cwmni.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Mynediad Cyfyngedig:</Body1Strong> Mae mynediad
                    i'r UPN wedi'i gyfyngu i Weinyddwr Tanysgrifiad eich
                    sefydliad o fewn ein porth Tanysgrifiad, gan sicrhau bod
                    gwybodaeth sensitif yn weladwy i bersonél awdurdodedig yn
                    unig.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Dileu Data Prydlon:</Body1Strong> Mae
                    TeamConnect wedi'i strwythuro i ddileu'r UPN a storir ar
                    unwaith o dan dri amod penodol: os caiff y tanysgrifiad ei
                    ganslo gan Weinyddwr y Tanysgrifiad, os caiff defnyddiwr ei
                    dynnu gan AD o fewn TeamConnect, neu os bydd tanysgrifiad
                    TeamConnect yn dod i ben. Mae hyn yn sicrhau nad yw eich
                    data personol yn cael ei gadw y tu hwnt i'w oes ddefnyddiol
                    neu angenrheidrwydd.
                  </li>
                </ul>
                <p>
                  Trwy'r mesurau hyn, mae TeamConnect yn ymrwymo i gynnal y
                  safonau uchaf o ddiogelu data, gan sicrhau bod eich gwybodaeth
                  bersonol yn parhau i fod yn ddiogel ac yn gyfrinachol.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="43">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                43. Ydych chi'n pendroni ble mae eich data chi yn aros gyda
                TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mae TeamConnect yn torri'r mowld o gynigion SaaS traddodiadol
                drwy sicrhau nad yw eich data byth yn gadael eich sefydliad. Yn
                wahanol i'r arfer cyffredin o storio data ar weinyddion cwmwl
                allanol, mae TeamConnect yn defnyddio'n grefftus SharePoint
                Ar-lein Microsoft 365 eich sefydliad i gartrefu'r holl ddata
                cais. Mae'r dull arloesol hwn nid yn unig yn hyrwyddo
                sofraniaeth data ond hefyd yn cryfhau diogelwch, yn cyd-fynd yn
                berffaith â'n hymroddiad diflino i ddiogelu eich preifatrwydd ac
                i gadw at safonau cydymffurfio llym.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="44">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                44. A fydd fy data i'n cael ei rannu gyda unrhyw drydydd
                partïon?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Nac ydw, data yn cael eu rhannu gyda thrydydd partïon! Mae
                Cubeet yn gadarn yn ein hymrwymiad i breifatrwydd a diogelwch
                eich data. Nid ydym yn rhannu unrhyw ddata TeamConnect gyda
                thrydydd partïon heb gael caniatâd penodol gan ein cwsmeriaid.
                Yn ein hamgylchedd diogel, rydym yn storio dim ond adnabodwyr
                hanfodol, megis Enw Prif Ddefnyddiwr (UPN), enw'r sefydliad,
                enw'r tenant, a ID y tenant, yn ein porth Tanysgrifio. Mae'r
                wybodaeth hon yn hanfodol ar gyfer darparu gwasanaeth ac fe'i
                diogelir o dan ein polisïau preifatrwydd llym. Gallwch fod yn
                sicr, mae ein harferion rheoli data wedi'u dylunio i sicrhau bod
                eich data yn parhau i fod yn gyfrinachol, gyda thryloywder a
                rheolaeth yn aros yn gadarn yn eich dwylo chi.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="45">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                45. Sut mae TeamConnect yn cydymffurfio â rheoliadau diogelu
                data fel GDPR?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mae TeamConnect yn cydymffurfio â rheoliadau diogelu data fel
                GDPR, HIPAA, CCPA, ac eraill drwy fanteisio ar nodweddion
                cydymffurfiaeth cynhenid amgylchedd Microsoft 365, lle caiff yr
                holl ddata cwsmeriaid eu storio'n ddiogel. Yn wahanol i fodelau
                SaaS traddodiadol, nid yw TeamConnect yn storio unrhyw ddata
                cwsmeriaid ar ei weinyddion; yn lle, mae'n gweithredu o fewn
                amgylchedd Microsoft 365 eich sefydliad, gan gadw at ei safonau
                diogelwch a chydymffurfio cadarn. Mae'r dull hwn yn sicrhau bod
                eich llywodraethu data yn parhau i gyd-fynd â'r fframwaith
                cydymffurfio cynhwysfawr a ddarperir gan Microsoft 365, gan
                gynnwys cydymffurfio â GDPR, HIPAA ar gyfer gwybodaeth iechyd,
                CCPA ar gyfer trigolion California, a rheoliadau diogelu data
                byd-eang eraill. Drwy integreiddio'n ddi-dor gyda'ch gosodiad
                Microsoft 365 presennol, mae TeamConnect yn grymuso eich
                sefydliad i gadw rheolaeth llawn dros eich data, gan sicrhau
                cydymffurfiaeth â'r deddfau a'r rheoliadau diogelu data llymaf.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="46">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                46. Sut mae TeamConnect yn trin data o sgwrsiau, galwadau,
                galwadau fideo, e-byst Outlook, a galwadau system ffôn Microsoft
                Teams?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mae TeamConnect yn manteisio ar yr API Graph Microsoft i alluogi
                rhyngweithiadau uniongyrchol fel sgwrsio, galwadau, galwadau
                fideo, ac e-bost Outlook o fewn Microsoft Teams, gan ddarparu
                profiad defnyddiwr di-dor. Yn hanfodol, tra bod TeamConnect yn
                cychwyn y cyfathrebiadau hyn, mae'n gwneud hynny heb ymyrryd yn
                y broses gyfathrebu nac â'r gallu i gofnodi neu storio unrhyw
                gynnwys y rhyngweithio. Mae'r holl sgyrsiau, galwadau, ac e-byst
                yn digwydd ac yn cael eu rheoli'n uniongyrchol o fewn ffiniau
                diogel Microsoft Teams ac Outlook, gan gadw'n dynn at safonau
                diogelwch a phreifatrwydd cadarn Microsoft 365. Mae hyn yn
                sicrhau bod TeamConnect yn gwella eich galluoedd cyfathrebu
                tra'n cadw cyfrinachedd a chyfanrwydd eich data yn llwyr.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Body1>
        <p>
          Am fewnwelediadau manwl i'n polisïau a'n harferion data, efallai yr
          hoffech ymgynghori â{" "}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>
              Canllaw Defnyddiwr TeamConnect
            </Body1Strong>
          </Link>{" "}
          neu adran TG eich sefydliad yn{" "}
          <Link href={`mailto:${itSupport}`}>
            <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong>
          </Link>{" "}
          am fanylion sy'n ymwneud â'ch sefydliad.
        </p>
      </Body1>
      <p></p>
      <Subtitle1 className={styles.fontColor}>
        Rydym yn Gwrando: Rhannwch Eich Adborth a Syniadau
      </Subtitle1>
      <Body1>
        <p>
          Mae eich mewnwelediadau yn gyrru esblygiad TeamConnect. P'un a ydych
          chi'n llawn syniad nodwedd wych neu os oes gennych adborth ar eich
          profiad cyfredol, rydym yn gwrando. Mae eich mewnbwn yn amhrisiadwy yn
          ein hymgyrch barhaus i ddarparu'r gwasanaeth gorau posibl.
        </p>
      </Body1>
      <Subtitle2>Adborth a Syniadau Nodwedd</Subtitle2>
      <Body1>
        <p>
          Yn barod i rannu? Ewch i'n{" "}
          <Link
            style={{ fontWeight: 600 }}
            href={feebackLink}
            target='_blank'>
            Tudalen Adborth
          </Link>
          . P'un a yw'n awgrym ar gyfer nodwedd newydd neu adborth ar
          swyddogaethau presennol, dewiswch yr opsiwn priodol o'r gwymplen yn y
          ffurflen. Mae eich cyfraniadau'n helpu i lunio dyfodol TeamConnect,
          gan sicrhau ei fod yn bodloni ac yn rhagori ar eich anghenion.
        </p>
      </Body1>
    </>
  );
}

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;