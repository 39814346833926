import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();

  return (
    <>
      <Title3 className={styles.fontColor}>TeamConnect Help Center & FAQs</Title3>
        <p><Body1>
          Welcome to the TeamConnect Help Center, designed to support your experience with our comprehensive corporate directory app. Here, you'll find resources to optimize your use of TeamConnect within Microsoft Teams, enhancing communication across your network.
        </Body1></p>

        <Subtitle2>Quick Reference Guide</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Enhanced Search Capabilities: </Body1Strong>
              Find the right colleagues, departments, and essential contacts swiftly with our powerful search tools.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Visual Org Charts: </Body1Strong>
              Navigate your organization's hierarchy and understand reporting lines with ease through intuitive org charts.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Adaptive Tools for Today's Needs: </Body1Strong>
              Utilize modern functionalities tailored to the demands of contemporary workplaces.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Optimizing Your Experience</Subtitle2>
        <Body1>
          <p>
            TeamConnect revolutionizes how you interact within your organizational structure, turning everyday communications into strategic engagements.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Get Help & FAQs</Subtitle1>
        <Body1>
          <p>
            Find answers to common questions about TeamConnect’s features and settings in this dedicated section. Whether you’re navigating the app, customizing settings, or seeking solutions to challenges, our Help Center is here to assist you efficiently.
          </p>
        </Body1>
        <Body1>
          <p>
            For more in-depth support or specific guidance, please consult the <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnect User Guide</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        <Subtitle2>Using TeamConnect FAQs</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Cannot find TeamConnect in the Microsoft Teams sidebar?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  If TeamConnect isn't readily visible or disapeared in your Microsoft Teams sidebar, you can easily pin it for quick access. Just follow these simple steps:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Open Microsoft Teams and click on the '...' (+ Apps) icon at the bottom of the sidebar.</li>
                    <li className={styles.bulletBottomMargin}>Search for 'TeamConnect' in the search bar at the top of the pop-up window.</li>
                    <li className={styles.bulletBottomMargin}>Right-click on the TeamConnect app from the search results and select 'Pin'.</li>
                  </ol>
                  TeamConnect will now be pinned to your sidebar, making it accessible with just a click!
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Encountering technical issues with TeamConnect? Here's how to get help.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  If you run into any technical issues with TeamConnect within Microsoft Teams, your organization's IT Support team is here to help. 
                  Reach out to them at <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, and they'll assist you promptly.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Can't spot your employee details?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  If your profile feels a bit empty with just your name and email showing, it's likely your HR team hasn't yet added the rest of your employee details to TeamConnect. 
                  No need to fret! A quick message to your HR support team will set things in motion. They're on standby to fill in your profile with all the important details. 
                  Reach out at <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> and watch your profile come to life!
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. What language options are available in TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect embraces the global diversity of Microsoft Teams by supporting all its languages. 
                  Whether you prefer Arabic or Greek, Japanese or Swedish, you have a world of options at your fingertips, including:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arabic, Bulgarian, Chinese (Simplified and Traditional), Croatian, Czech, Danish, Dutch, English, Estonian, Finnish, 
                      French, German, Greek, Hebrew, Hungarian, Indonesian, Italian, Japanese, Korean, Latvian, Lithuanian, Norwegian (Bokmal), 
                      Polish, Portuguese (Brazil and Portugal), Romanian, Russian, Serbian (Latin), Slovak, Slovenian, Spanish, Swedish, Thai, 
                      Turkish, Ukrainian, Vietnamese.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  To customize your TeamConnect experience to your preferred language, please adjust the language settings directly in Microsoft Teams, 
                  as TeamConnect fully integrates with these settings:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Go to your Microsoft Teams settings by clicking on the three dots next to your profile picture at the top, and then select 'Appearance and accessibility'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Scroll to the 'Language' section and select your preferred language from the dropdown menu.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Confirm your changes by clicking the 'Save and restart' button to apply the new language settings.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Note: </Body1Strong>
                <Body1>
                  Changes made to your language settings in Microsoft Teams will seamlessly update your TeamConnect experience as well. 
                  Be aware that altering the language in Teams may also adjust your time and date format. At the moment, 
                  TeamConnect is evolving to for right-to-left writing. We're excited about these enhancements 
                  and will keep you informed as they become available.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. How can I change the theme in TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect syncs with your Microsoft Teams theme preferences, including Light, Dark, and High Contrast options. To customize your theme:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Access your Microsoft Teams settings by clicking on the three dots next to your profile picture at the top.</li>
                    <li className={styles.bulletBottomMargin}>Select 'Appearance and accessibility' to explore the theme options.</li>
                    <li className={styles.bulletBottomMargin}>Choose your preferred theme from the available options to instantly update your TeamConnect interface to match.</li>
                  </ol>
                  Your theme choice in Microsoft Teams will be automatically applied to TeamConnect, ensuring a consistent visual experience across your workspace.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Exploring TeamConnect’s Contact Action Buttons
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Throughout TeamConnect, wherever you find an employee's contact details, you'll also discover our convenient quick-access buttons. These handy tools 
                  are designed to streamline your communication within the Microsoft ecosystem, ensuring you're always just a click away from connecting. 
                  Here’s your comprehensive guide to utilizing these buttons effectively:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chat Icon:</Body1Strong> Click to start an instant Microsoft Teams chat. If you need to return to TeamConnect after starting a chat, simply use the back arrow in the Desktop App, the back button in the Mobile App, or your browser’s back button.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Email Icon:</Body1Strong> Opens a new Outlook email draft with the recipient’s address ready in the "To:" field. Should Outlook not launch, it’s worth checking with your IT department for any restrictions.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Video Call Icon:</Body1Strong> Initiates a face-to-face video call using Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Call Icon:</Body1Strong> Starts a Microsoft Teams voice call, making quick discussions seamless.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org Chart Icon:</Body1Strong> Reveals the employee’s place in the organizational hierarchy, including their manager and direct reports.</li>
                    <li><Body1Strong>More (...) Icon:</Body1Strong> Unveils further options:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Meet Now:</Body1Strong> Opens Microsoft Teams to start an immediate meeting with the employee.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>New Meeting:</Body1Strong> Directs you to Microsoft Teams to schedule a new meeting.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Add Contact:</Body1Strong> Adds the employee's contact details from TeamConnect to your new Microsoft Teams People app, enriching your call function.</li>
                      </ul>
                    </li>
                  </ul>
                  These intuitive buttons are crafted to ensure you can efficiently connect with colleagues, whether for a quick chat, an email, or to schedule future meetings.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Making calls via TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Looking to make a call? TeamConnect streamlines your communication by integrating with Microsoft Teams. 
                  Simply click on the desired phone number in TeamConnect, and voilà, the Microsoft Teams calling interface pops up, 
                  ready for you to dial out seamlessly.
                  <p>
                  <Body1Strong>Note: </Body1Strong> 
                  Encountering issues with making calls? This might be due to your organization's IT policies or the 
                  use of a phone system other than Microsoft Teams Phone System. Checking in with your IT department at <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, can shed some light on the matter.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Are there costs associated with making calls via TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect enhances your calling experience by leveraging Microsoft Teams. Here’s what you need to know about call-related costs:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      For calls to employee work phones, department phones, or hotlines within your organization (internal numbers), 
                      there are typically <Body1Strong>no costs</Body1Strong> when using the Microsoft Teams Phone System.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      For external calls, such as those to mobile phones or outside lines, <Body1Strong>standard rates</Body1Strong> of your organization’s phone system apply. 
                      These rates vary based on your specific service plan with Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Note:</Body1Strong> If you encounter any issues or have questions about call charges, it’s best to consult with your 
                    IT department at <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> for detailed information tailored 
                    to your organization’s setup and policies.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Noticing a mismatch with your 'Out of Office' presence status in TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Noticed that your 'Out of Office' presence status from Microsoft 365 isn't reflecting correctly in TeamConnect? 
                  We've got you covered. The feature to synchronize 'Out of Office' presnece status has already been implemented in TeamConnect.
                  We're currently awaiting Microsoft's rollout of this feature. Once that happens, your 'Out of Office' presence status 
                  will be accurately mirrored in TeamConnect, aligning with Microsoft Teams' presence indicators for a seamless experience.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>

        <Subtitle2>Employee Directory FAQs</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >Employee Directory Introduction:</Body1Strong> Navigate the professional landscape of your organization with the Employee Directory, 
                your central hub for detailed employee insights, workplace dynamics, and direct connectivity to your colleagues' profiles and managerial relations.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Need to add, update, or correct your employee data?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  For adding, updating, or correcting your employee data in TeamConnect, please get in touch with your organization's HR support team. 
                  They're ready to help at <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. What happens when I click on an employee's name in the directory?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Clicking on an employee's name in the TeamConnect Employee Directory opens up a detailed profile page for that individual. This profile provides comprehensive 
                  insights about the employee, including but not limited to, their job title, department, office location, contact information, and more. It's designed to give you 
                  a full overview of the employee's professional role and how they fit into the organization, making it easier for you to understand their responsibilities and 
                  how you might collaborate with them.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. What information is included in an employee's profile?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Each employee profile in TeamConnect is a comprehensive snapshot, offering a wealth of insights into the individual's professional life. 
                  Here’s what you’ll find:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Basic Details:</Body1Strong> Name, job title, department, and subsidiary location.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Reporting Structure:</Body1Strong> Information on direct and functional reports, 
                      including line and functional managers.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Workplace Status:</Body1Strong> Current working status such as On-Leave, Commute, 
                      Business-Travel, etc.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Workspot & Local Time:</Body1Strong> The employee's current work location and 
                      local time zone.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Contact Details:</Body1Strong> Work phone, mobile number, and email address.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office Address:</Body1Strong> Complete office location including building name,
                      address, and Google map.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>About:</Body1Strong> A brief personal introduction or professional bio.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Employee Relations:</Body1Strong> Details of the employee's reporting 
                      structure and team relations.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Social Links & Languages:</Body1Strong> Links to professional social profiles 
                      and language proficiencies.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Skills:</Body1Strong> A list of professional skills and expertise areas.</li>
                  </ul>
                  Additionally, the profile offers actionable options to connect via chat, email, video call, or schedule meetings, along with 
                  quick access to the organization's chart for broader team context.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. How can I update my profile information?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Personalizing your profile is easy in TeamConnect. Here’s how you can do it:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Navigate to the <Body1Strong>Employee</Body1Strong> tab and click on your <Body1Strong>Avatar</Body1Strong> to open your profile.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        In the upper left corner, you’ll find the <Body1Strong>"Edit your profile"</Body1Strong> link. 
                        Clicking this will open the edit dialog for your profile.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Here, you can enhance your profile by adding or updating your <Body1Strong>About You</Body1Strong> section, social media links, and phone number. 
                        This makes it easier for your colleagues to connect or follow you.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Don’t forget to press the <Body1Strong>Save</Body1Strong> button to apply your changes.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Scroll down in the dialog to add your <Body1Strong>Languages</Body1Strong> and <Body1Strong>Proficiency</Body1Strong>, 
                        as well as your <Body1Strong>Skills</Body1Strong>. Sharing this information helps your colleagues understand 
                        your areas of expertise and the languages you can communicate in.
                      </li>
                    </ol>
                  Updating your profile not only keeps your information current but also fosters better connections within your organization.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Updating Your Workplace, Workspot, and Timezone in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Keeping your workplace, workspot, and timezone information up-to-date is crucial for seamless collaboration in Microsoft Teams. 
                  These updates are reflected instantly in your TeamConnect profile and listings, enabling colleagues to quickly understand your work location 
                  and the best times to connect with you, enhancing teamwork across different regions and time zones.
                  <p></p>
                  Ready to update your details? It's easy in TeamConnect! Head over to 'Settings' in the top Menu and navigate to 'General Settings'. 
                  Here, you can smoothly update your Workplace and Timezone, and specify your Workspot for the day. Remember to save your changes to 
                  keep everyone informed and ensure effective collaboration, no matter your location!
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Spotted something off in a colleague's profile?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  If you come across any details in a colleague's profile that don't seem quite right, the best first step is to give them a 
                  friendly heads-up. A quick chat or email should do the trick! If for some reason the info doesn't get updated, don't worry—just 
                  ping your HR support team at <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, and they'll be happy 
                  to step in and sort things out.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Understanding Tag Groups: All, Favorites, Checked-In, & New Employees
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>All:</Body1Strong> This default tag displays every employee in your organization, 
                      providing a comprehensive view of the team.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favorites:</Body1Strong> Click here to filter your view to those colleagues you've marked 
                      as 'Favorites,' making it easier to quickly connect with key contacts.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-In:</Body1Strong> This tag indicates employees actively at work, distinguishing 
                      from mere online presence in Microsoft Teams. When checked in, employees signal that they're at work and share their current work location 
                      during the day. This can include a variety of workplaces such as Office, Field-Office, Home-Office, Remote-Workplace, Customer-Workplace, 
                      Customer-Private-Office, Public-Area, Commute, Business-Travel, or even Training. Therefore, the 'Checked-In' status provides a clear indication 
                      of being actively at work, offering colleagues a precise understanding of each other's current work context and availability.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>New Employees:</Body1Strong> Spot fresh faces in TeamConnect with this tag, highlighting 
                      employees who've been added to the app within the last 30 days. Typically, this aligns with recent additions to your organization, helping 
                      you welcome new team members.
                    </li>
                  </ul>
                  These tag groups are designed to streamline navigation and enhance your TeamConnect experience, ensuring you can always 
                  find and connect with your colleagues efficiently.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Decoding the Icons Beside the Search Box
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect's Employee Directory is designed for versatility and ease, featuring four icons that offer different views 
                  and swift access to key details. Here's what each icon unveils:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>List View:</Body1Strong> Your go-to view, presenting all organization members 
                      in a straightforward list.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Small Card View:</Body1Strong> A compact alternative, this view transforms the 
                      list into bite-sized employee cards.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Large Card View:</Body1Strong> Opt for more detail with larger cards, which include 
                      additional contact options like phone and mobile numbers for easy dialing.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org Chart:</Body1Strong> Visualize the company's structure with the Org Chart view, 
                      perfect for understanding reporting relationships and navigating the organizational landscape.</li>
                  </ol>
                    Toggle between these icons to find the view that best suits your needs at the moment, whether you're seeking quick info or deeper insights.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. For quick colleague searches, where should I look?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Looking for a colleague? Just head to the TeamConnect search box, located on the upper right side next to the Org chart icon. 
                  Start typing their name, and watch as the results refine with each letter. Remember, for TeamConnect searches, skip the Microsoft Teams 
                  search bar at the top—our special search box is the way to go!
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. How does the alphabetical search work for finding employees?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect's Employee Directory includes a handy alphabetical search feature to help you swiftly locate employees by their first name. 
                  Simply click on a letter from the alphabet displayed at the top of the directory, and TeamConnect will instantly show you all employees 
                  whose first names begin with that letter. To return to the full list of employees, just deselect the highlighted letter. This intuitive 
                  feature makes navigating through large employee lists a breeze, ensuring you can find colleagues quickly and efficiently.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. How can I adjust the number of employees displayed per page?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect allows you to customize how many employee profiles you view per page, making it easier to navigate through the Employee Directory. 
                  At the bottom of each page, you'll find pagination options: 10, 50, 100, 500, 1000, and 5000. The default setting shows 10 profiles per page. 
                  To view more profiles on a single page, simply select your preferred number from these options. This feature provides flexibility in how you browse 
                  the directory, enabling you to tailor the display to your specific needs or preferences.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. How do I sort employee information in the List View?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  In TeamConnect's Employee Directory List View, you have the flexibility to sort employee information to suit your needs. The columns—Employee Name, 
                  Job Title, Department, Office Location, and Workplace—are all sortable. Simply click on the column header you wish to sort by. Clicking once will sort 
                  the column in ascending order, and clicking again will sort it in descending order. By default, the directory is sorted by Employee Name in ascending order, 
                  based on the first name. This sorting feature helps you quickly organize and view employee details in a way that best serves your purpose, whether you're 
                  looking for someone specific or need to group employees by a particular criterion like department or location.
                </Body1>
              </AccordionPanel>
            </AccordionItem>


          </Accordion>
        </div>
        <p></p>

        <Subtitle2>Department Directory FAQs</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Department Directory Introduction:</Body1Strong> The Department Directory is your gateway to understanding the intricate web of your 
              organization’s structure, offering a clear view of departmental hierarchies, managerial leadership, and avenues for seamless departmental communication.
            </p>
          </Body1>
        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>

            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. How do the 'All', 'Favorites', and 'Your Department' tags work in the Department Directory?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  In TeamConnect's Department Directory, tags help you quickly navigate and filter through departmental information. Here’s what each tag represents:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>All:</Body1Strong> This tag displays all departments within your organization, providing a 
                      comprehensive overview.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favorites:</Body1Strong> Use this tag to view a curated list of departments you've marked 
                      as 'Favorites', making it easier to access departments you interact with frequently.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Your Department:</Body1Strong> This tag filters the view to show only your department, 
                      allowing for quick access to your immediate departmental information and updates.</li>
                  </ul>
                  These tags are designed to streamline your navigation within the Department Directory, enhancing your ability to find and engage with departmental 
                  information efficiently.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Understanding Department Directory Views in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigating through the Department Directory in TeamConnect is streamlined with multiple viewing options to suit your preferences. Each view offers a 
                  unique way to explore comprehensive departmental information, including Department Name, Department Manager, Parent Department, Department Phone, 
                  Department Hotline, and Department Email. Here's a snapshot of the available views:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>List View:</Body1Strong> The default setup that neatly lists all departments, providing a 
                      clear, concise overview.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Large Card View:</Body1Strong> Switch to this view to see detailed department cards, offering 
                      an expanded summary of each department's key information.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org Chart View:</Body1Strong> For a visual representation of the departmental structure and 
                      relationships, the Org Chart view illustrates how each department fits within the larger organizational hierarchy.
                    </li>
                  </ul>
                  To toggle between these views, simply use the icons next to the search box at the top of the Department Directory. Each view is designed to help you 
                  access and understand departmental details with ease, ensuring you find the information you need in the format that works best for you.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. How to Use the Search Box in the Department Directory
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Seamlessly locate any department within TeamConnect using the dedicated search box situated right next to the view icons at the top of the 
                  Department Directory. Simply begin typing the department name you're in search of, and watch as the directory refines itself to display only 
                  relevant matches. This feature is specifically designed to help you bypass the need to manually sift through the entire directory, bringing the 
                  department you need directly to your fingertips. Remember, this search functionality is unique to TeamConnect's Department Directory, so be sure 
                  to use this search box rather than the main Microsoft Teams search at the very top of the Teams interface for department-specific queries.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. How does the alphabetical filter work in the Department Directory?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Streamline your search in the Department Directory with the alphabetical filter located at the top. This feature allows you to quickly narrow 
                  down departments by selecting a letter. Once you choose a letter, TeamConnect filters and displays only those departments whose names begin 
                  with your selected letter, making it easier to find what you're looking for without scrolling through the entire list. To return to the full 
                  department view, simply deselect the highlighted letter. This intuitive alphabetical navigation ensures you can efficiently locate any department 
                  by its initial letter.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. How can I contact the department manager?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Contacting a department manager in TeamConnect is straightforward. Simply locate the department of interest and click on the manager's name. This 
                  action will trigger a popover displaying the manager’s contact options with the familiar quick-access icons:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chat Icon:</Body1Strong> To start an instant message via Microsoft Teams chat.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Email Icon:</Body1Strong> To open a new email addressed to them in Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Video Call Icon:</Body1Strong> To initiate a video call through Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Call Icon:</Body1Strong> To begin a voice call using Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>More (...) Icon:</Body1Strong> For additional options like scheduling a meeting or adding 
                      their contact information to your Teams list.
                    </li>
                  </ul>
                  These icons provide a seamless method to reach out to department managers directly from within TeamConnect, ensuring efficient communication within 
                  your organization.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. How can I view or contact employees within a department?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  To explore who's who within a department in TeamConnect, look no further than the three dots next to the department name. Clicking on this will 
                  bring up options for further action:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>View Employees in This Department:</Body1Strong> Select this to display a list of all 
                      employees associated with the department. The number beside this option indicates how many employees are in the department, providing a quick headcount.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Department Org Chart:</Body1Strong> If you wish to grasp the department's hierarchy, 
                      choose 'Department org chart' to reveal the structural layout, showcasing the relationship between parent departments, the department in question, 
                      and any subdepartments. This visual representation helps you navigate and understand the organizational framework, making it easier to identify and 
                      contact specific department members.
                    </li>
                    <li><Body1Strong>Department {'>'} Employee:</Body1Strong> After viewing the employees within a department, use this breadcrumb trail to easily 
                      navigate back to the department list, maintaining a smooth exploration experience within TeamConnect.
                    </li>
                  </ul>
                  These features allow you to not only see the list of employees in a given department but also offer a pathway to reach out to them directly through 
                  TeamConnect's communication tools.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Discovering In-Depth Department Details in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Delve into the specifics of any department with a simple click on its name in TeamConnect. A detailed dialogue unfolds, offering a rich tapestry of 
                  information that paints a full picture of the department’s role and resources within your organization. You'll find everything from leadership and 
                  financial identifiers to communication channels and social links. This feature is your gateway to:
                  <ul>
                    <li className={styles.bulletBottomMargin}>The heartbeat of the department – its purpose, people, and performance metrics.</li>
                    <li className={styles.bulletBottomMargin}>Contact avenues – direct lines to key departmental figures and communication platforms.</li>
                    <li className={styles.bulletBottomMargin}>The organizational web – understanding how the department integrates with the broader company structure.</li>
                  </ul>
                  Whether it's a quick query or a deep dive into departmental data, TeamConnect equips you with the tools to connect with and comprehend the unique 
                  ecosystems within your organization.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Curious About the Absence of Department Addresses in TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Unearthing a department's location might sometimes feel like a treasure hunt without a map. In TeamConnect, you won't typically find a dedicated 
                  address for each department. Here's why this cartographic detail is often omitted:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Multiple Locations:</Body1Strong> Many departments are not confined to a single space; they
                      thrive across various offices and geographies.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Fluid Structure:</Body1Strong> The organizational landscape is ever-changing, with 
                      departments evolving, merging, or even dissolving in alignment with strategic shifts.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Interdepartmental Collaboration:</Body1Strong> Modern workspaces buzz with collaborative 
                      spirit, often blending talents from various departments within shared spaces.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote Work Culture:</Body1Strong> With the flexibility of remote work, departmental 
                      anchors aren’t always dropped in physical office locations.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Legal Nuances:</Body1Strong> Departments typically orbit around the company's legal 
                      structure rather than having their own standalone addresses.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Operational Streamlining:</Body1Strong> Keeping the focus on dynamic teamwork, not static 
                      addresses, helps maintain operational agility.
                    </li>
                  </ul>
                  It's all about embracing a boundary-less approach to teamwork and efficiency. TeamConnect reflects this philosophy by emphasizing connections over 
                  locations.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Is your department information missing or outdated?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Department editors are designated within your organization to add, update, or correct department-specific information in TeamConnect.
                  If you're unsure who your department editor is or if certain information requires HR support team intervention, 
                  please contact <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> for guidance.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          
          </Accordion>
        </div>
        <p></p>

        <Subtitle2>Office Directory FAQs</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Office Directory Introduction:</Body1Strong> Discover the backbone of your organization’s physical presence through the Office Directory, 
                which encompasses essential office details, operational specifics, and a comprehensive look at the departments and personnel that define each location.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Navigating Tags in the Office Directory
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Find your way around the Office Directory with ease using our tag system designed for quick access to the right location. Here's how each tag can help streamline your search:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>All:</Body1Strong> Displays a complete listing of offices, providing a bird's-eye view of 
                      your organization's locations.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favorites:</Body1Strong> Helps you zero in on those office locations you frequent or need 
                      to keep a close eye on, by marking them as 'Favorites'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Your Office:</Body1Strong> This personal tag filters the directory to show you the office 
                      location where you’re stationed, keeping your home base at your fingertips.
                    </li>
                  </ul>
                  Whether you're booking a meeting room or planning a visit, these tags offer a personalized and simplified way to interact with your organization's 
                  geographical landscape.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Mastering Office Directory Views in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Dive into the organizational layout of your company's various locations with TeamConnect's Office Directory views. Each view is crafted to facilitate 
                  a unique aspect of office navigation and understanding:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>List View:</Body1Strong> The default setting that neatly organizes all office locations into 
                      an easy-to-read list.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Large Card View:</Body1Strong> The Large Card View in the Office Directory enriches your 
                      view with a detailed presentation of each office. When engaging with an office card, you’ll find a wealth of information and actionable options 
                      within the "More (...)" menu:
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>View Departments:</Body1Strong> Directly navigate to a list
                          of all departments situated within the office.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>View Employees:</Body1Strong> Access a complete roster of employees assigned to the 
                          office, facilitating direct contact or exploration of office personnel.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Business Entity Org Chart:</Body1Strong> Visualize the office's place within the larger 
                          organizational context for a clear understanding of hierarchical structures.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Essential Contacts:</Body1Strong> Identify key office contacts for streamlined 
                          communication.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Office Public Holidays:</Body1Strong> Stay informed about the office-specific public 
                          holidays to plan your schedules accordingly.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Office Working Hours:</Body1Strong> View the office’s operating hours to align your 
                          visits or calls with their active times.
                        </li>
                      </ol>
                      This view provides not just a visual and informational snapshot but also quick access to deeper insights and direct actions related to the office, all neatly tucked under the "More (...)" option for your convenience.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org Chart View:</Body1Strong> Visualize the hierarchical structure of parent and sub-offices 
                      to comprehend the interconnectedness of different office locations within your organization.
                    </li>
                  </ul>
                  Toggle through these views using the icons next to the search box to customize your browsing experience to fit your immediate needs.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Finding Offices Quickly with TeamConnect's Search Feature
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Zeroing in on the right office location within TeamConnect is made easy with the dedicated search feature. Positioned prominently next to the view 
                  icons, the search box is your tool for pinpointing offices by name. As you type, the directory refines the display to only show offices matching 
                  your input, streamlining your search process. It's important to use this search box within TeamConnect for the most accurate and relevant results, 
                  as opposed to the general Microsoft Teams search. With this targeted search functionality, you can navigate swiftly to any office in your 
                  organization's network.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Navigating the Office Directory Alphabetically
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  If you prefer to search by alphabet, our alphabetical filter is a swift way to find offices by name. At the top of the Office Directory, you'll 
                  see letters A through Z. Clicking on a letter filters the view to show only those offices starting with that letter, simplifying your search 
                  process. To reset and see all offices again, simply deselect the active letter. This feature is especially useful when you’re familiar with the 
                  office name but need a quick way to locate it within TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Unveiling Comprehensive Office Profiles in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Embark on an exploratory journey through the heart of your organization’s physical presence with TeamConnect's office profiles. 
                  Clicking on an office name brings up a vibrant profile dialog, brimming with tabs full of detailed office insights:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Overview:</Body1Strong> Dive into the core of the office’s identity with detailed 
                      descriptors, from business structure to opening hours and local time, capturing the essence of the office environment.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Essential Contacts:</Body1Strong> Access crucial contacts for emergency services and 
                      external support, each a click away through Microsoft Teams Phone System.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office Contacts:</Body1Strong> Reach out to internal support services with ease, with 
                      contact details and direct engagement options right at your fingertips.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Public Holidays:</Body1Strong> Keep abreast of office-specific holidays, ensuring your 
                      plans align with local observances and work schedules.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office Hours:</Body1Strong> Plan your interactions with precise knowledge of when the 
                      office bustles with activity or winds down for the day.
                    </li>
                  </ul>
                  Whether it's the bustling energy of Austin or the strategic hub of Singapore, each tab offers a gateway to understand and connect with the diverse 
                  locales that make up your company's global presence.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Delving into Department Dynamics within an Office
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigating the professional network of an office’s departments unfolds with ease in TeamConnect. Here’s how to delve into the details:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>View Departments in This Office:</Body1Strong> Discover all the departments that form the 
                      fabric of the office by selecting this option from the More (...) menu.
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organizational Insight:</Body1Strong> Click on any department to reveal its org chart for 
                      a visual representation of its structure, with the breadcrumb trail guiding you: Office {'>'} Department {'>'} Chart.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Departmental Deep Dive:</Body1Strong> Further explore a department by clicking on its 
                      More menu (...) next to the department name. Here, you can access a list of all the employees within that department, right in the office context.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Breadcrumb Navigation:</Body1Strong> Traverse through the levels seamlessly. From 
                      employees back to departments or from the org chart, the breadcrumb trail (Office {'>'} Department {'>'} Employee {'>'} Chart) keeps your 
                      journey intuitive and on track.
                    </li>
                  </ul>
                  TeamConnect's intricate pathways invite you to explore, understand, and engage with every layer of your office’s departments, empowering you with 
                  comprehensive navigational command.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Connecting with Office Personnel in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Curious about the faces behind an office? The answer is just a click away in TeamConnect. Engage with the ellipsis (three dots) next to your chosen 
                  office name to reveal a suite of interactive options:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>View Employees in This Office:</Body1Strong> Uncover a list of all the professionals 
                      calling this office their work home. The number displayed offers a quick tally of the employees, inviting you to delve into the office community.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Contact Options:</Body1Strong> Each employee entry is equipped with quick-action icons, 
                      allowing you to initiate chats, calls, or even video conferences right from TeamConnect, fostering seamless intra-office communication.
                    </li>
                    <li><Body1Strong>Navigation:</Body1Strong> To return to the broader office list from an employee list, simply click 'Office' in the breadcrumb 
                      trail (Office {'>'} Employee), and you’ll be taken back to the office overview.
                    </li>
                  </ul>
                  With these features, TeamConnect makes it simple to visualize the workforce distribution across offices and to reach out effortlessly, 
                  fostering a connected workplace no matter the distance.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Is your office information missing or outdated?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Office information updates in TeamConnect can be handled by assigned office editors. If you're uncertain about the office editors or 
                  need assistance with information that falls under HR's purview, the HR support team is ready to 
                  assist at <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          
          </Accordion>
        </div>
        <p></p>

        <Subtitle2>Settings FAQs</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. The Importance of Accurate General Settings in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  In the dynamic landscape of today's work environment, staying connected and coordinated is more crucial than ever. That's where updating your 
                  General Settings in TeamConnect plays a pivotal role:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Workplace Selection:</Body1Strong> Capture the essence of your work setting by updating your Workplace to mirror your current 
                        situation, be it a shift from the tranquility of your Home-Office to the buzz of the main Office, or even settling into a Public-Area. 
                        This clarity in your work locale offers colleagues insight not just into the best ways to reach out to you, but also into the nature of 
                        conversations they can engage in with you, safeguarding the confidentiality of sensitive discussions especially when you're amidst the open 
                        environment of a client's office or sitting in an airport lounge.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Workspot Specification:</Body1Strong> Sharing your work city does more than bridge distances in a remote team setup—it also 
                        opens doors to spontaneous professional gatherings or casual socializing. Whether it's a quick coffee, a leisurely lunch, or a collaborative 
                        meeting, knowing you're in the same city can transform a regular day into an opportunity for meaningful face-to-face connections. This not 
                        only strengthens team bonds but also provides a richer understanding of the local nuances that might be influencing your work.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Timezone Accuracy:</Body1Strong> As teams span continents, ensuring your timezone reflects 
                      your current locale is essential for harmonizing communications. Your TeamConnect profile's Local Time is directly influenced by this setting, 
                      eliminating any guesswork for colleagues trying to reach you.
                    </li>
                  </ul>
                  Dive into 'Settings' {'>'} 'General Settings' within TeamConnect to fine-tune these details. A few moments spent updating your settings can 
                  significantly enhance the cohesion and efficiency of your collaborative efforts across the board.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Deciphering Workplace Selection in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Choosing the right setting in the Workplace dropdown tailors TeamConnect to reflect your current work status accurately. Hover over the info icon for a detailed explanation of each option:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Break:</Body1Strong> Signifies time away from work, be it for a break or the day’s end.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office/Field-Office:</Body1Strong> Indicates presence in a company-managed workspace.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> Working from a dedicated home workspace.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Public-Area:</Body1Strong> Operating from a non-office environment, suggesting a need for flexible communication.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Customer Workplace/Private Office:</Body1Strong> On-site with a client, with a note on privacy levels for conversation sensitivity.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Commute/Business-Travel/Training:</Body1Strong> On the move or engaged in specific work-related activities outside the office.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Public-Holiday/Vacation/Various Leaves:</Body1Strong> Officially off-duty for personal, health, or holiday reasons.</li>
                  </ul>
                  Selecting an appropriate workplace setting ensures your team has the context needed for interactions, respecting availability and privacy.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Navigating the 'Checked-In' Status in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  The 'Checked-In' tag in the Employee Directory is designed to display all employees who are currently at work. This visibility is determined by the Workplace selection you make:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Active Work Environments:</Body1Strong> The choices Office, Field-Office, Home-Office, Remote-Workplace, Public-Area, Customer-Workplace, 
                    Customer Private-Office, Commute, Business-Travel, and Training signal you're engaged in work, rendering you 'Checked-In' and visibly active to colleagues.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Non-Work Settings:</Body1Strong> Opting for Checked-Out, Break, Public-Holiday, Vacation, On-Leave, Maternity-Leave, or Sick-Leave 
                      statuses removes you from the 'Checked-In' list, setting clear expectations for availability and response times.
                  </li>
                  </ul>
                  This functionality helps delineate working periods from personal time, aiding in effective team collaboration and communication.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>

        <Subtitle2>Data Management and Privacy FAQs</Subtitle2>
          <Body1>
            <p>
              In today's digital age, understanding how your data is managed is crucial. TeamConnect is committed to transparency and robust data protection practices. 
              Below, you'll find information on how we handle, store, and protect your data within TeamConnect, ensuring your digital workspace is secure and compliant.
            </p>
          </Body1>
        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. How does TeamConnect protect my personal data?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect is dedicated to safeguarding your personal information with a comprehensive approach to data security:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Single Sign-On (SSO):</Body1Strong> To bolster security and streamline user experience, TeamConnect integrates automated 
                      Single Sign-On (SSO) with your Microsoft 365 account, facilitating secure and effortless access management.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Encryption:</Body1Strong> We ensure that all data within TeamConnect are encrypted both during transit and while at rest, 
                      making them inaccessible to unauthorized parties.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Data Minimization:</Body1Strong> Our data collection is strictly limited to what is essential for TeamConnect's functionality. 
                      The only user information we store is your User Principal Name (UPN) from Azure Entra ID, which may resemble your company email address.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Restricted Access:</Body1Strong> Access to the UPN is confined to the Subscription Admin of your organization within our Subscription portal, 
                      ensuring that sensitive information is visible only to authorized personnel.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Prompt Data Deletion:</Body1Strong> TeamConnect is structured to instantly delete the stored UPN under three specific conditions: 
                      if the subscription is canceled by the Subscription Admin, if a user is removed by HR within TeamConnect, or if the TeamConnect 
                      subscription expires. This ensures your personal data is not retained beyond its useful life or necessity.
                    </li>                      
                  </ul>
                  <p>Through these measures, TeamConnect commits to maintaining the highest standards of data protection, ensuring that your personal information remains secure and confidential.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Wondering where your data resides with TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect breaks the mold of traditional SaaS offerings by ensuring your data never leaves your organization. 
                  Unlike the usual practice of storing data on external cloud servers, TeamConnect ingeniously leverages your organization's 
                  Microsoft 365 SharePoint Online to house all application data. This innovative approach not only champions data sovereignty but also fortifies security, 
                  perfectly aligning with our staunch dedication to safeguarding your privacy and adhering to stringent compliance standards.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Will my data be shared with any third parties?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  No, data are shared with third parties! Cubeet is steadfast in our commitment to your data's privacy and security. We do not share any TeamConnect data with third parties without obtaining explicit consent from our customers. 
                  In our secure environment, we store only essential identifiers, such as the User Principal Name (UPN), organization name, tenant name, and tenant ID, within our Subscription portal. 
                  This information is crucial for service provision and is safeguarded under our stringent privacy policies.
                  Rest assured, our data management practices are designed to ensure that your data remains confidential, with transparency and control residing firmly in your hands.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. How does TeamConnect comply with data protection regulations like GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect aligns with data protection regulations such as the GDPR, HIPAA, CCPA, and others by leveraging the inherent compliance features of the Microsoft 365 environment, 
                  where all customer data is securely stored. Unlike traditional SaaS models, TeamConnect does not store any customer data on its servers; instead, it operates within your 
                  organization's Microsoft 365 environment, adhering to its robust security and compliance standards. This approach ensures that your data governance remains consistent with 
                  the comprehensive compliance framework provided by Microsoft 365, including adherence to GDPR, HIPAA for healthcare information, CCPA for California residents, 
                  and other global data protection regulations. By integrating seamlessly with your existing Microsoft 365 setup, TeamConnect empowers your organization to maintain 
                  full control over your data, ensuring compliance with the strictest data protection laws and regulations.
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. How does TeamConnect handle data from Microsoft Teams chats, calls, video calls, Outlook emails, and phone system calls?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect leverages the Microsoft Graph API to enable direct interactions such as chat, calls, video calls, and Outlook email within Microsoft Teams, 
                  providing a seamless user experience. Crucially, while TeamConnect initiates these communications, it does so without intervening in the communication process 
                  or having the capability to record or store any of the interaction content. All conversations, calls, and emails take place and are managed directly within the 
                  secure confines of Microsoft Teams and Outlook, in strict adherence to Microsoft's 365 robust security and privacy standards. This ensures that TeamConnect enhances 
                  your communication capabilities while fully preserving the confidentiality and integrity of your data.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>

        <Body1>
          <p>
            For detailed insights into our data policies and practices, you might also want to consult the {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>TeamConnect User Guide</Body1Strong></Link> or your organization's IT 
              department at <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> for specifics related to your setup.
          </p>
        </Body1>        
        <p></p>
  
      <Subtitle1 className={styles.fontColor}>We're Listening: Share Your Feedback and Ideas</Subtitle1>
        <Body1>
          <p>
            Your insights drive TeamConnect's evolution. Whether you're bubbling over with a brilliant feature idea or have some 
            feedback on your current experience, we're all ears. Your input is invaluable in our continuous quest to provide the 
            best possible service.
          </p>
        </Body1>
      
      <Subtitle2>Feedback and Feature Suggestions</Subtitle2>
        <Body1>
          <p>
            Ready to share? Head over to our {' '}
            <Link 
              style={{ fontWeight: 600 }}
              href={feebackLink}
              target='_blank'>Feedback Page
            </Link>
            . Whether it's a suggestion for a new feature or feedback on existing functionalities, simply select 
            the appropriate option from the dropdown in the form. Your contributions help shape the future of TeamConnect, 
            ensuring it meets and exceeds your needs.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;