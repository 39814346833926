import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});
function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>TeamConnect Help Center & FAQs</Title3>
        <p><Body1>
          Välkommen till TeamConnect Help Center, skapat för att stödja din upplevelse i vår omfattande företagskatalog-app. Här hittar du resurser för att optimera din användning av TeamConnect inom Microsoft Teams, vilket förbättrar kommunikationen i ditt nätverk.
        </Body1></p>
        <Subtitle2>Snabbreferensguide</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Förbättrade sökfunktioner: </Body1Strong>
              Hitta rätt kollegor, avdelningar och viktiga kontakter snabbt med våra kraftfulla sökverktyg.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Visuella organisationsdiagram: </Body1Strong>
              Navigera genom din organisations hierarki och förstå rapporteringslinjer enkelt via intuitiva organisationsdiagram.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Anpassningsbara verktyg för dagens behov: </Body1Strong>
              Använd moderna funktioner anpassade för kraven i dagens arbetsplatser.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Optimera din upplevelse</Subtitle2>
        <Body1>
          <p>
            TeamConnect revolutionerar hur du interagerar inom din organisationsstruktur och förvandlar vardagskommunikation till strategiska engagemang.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Få hjälp & vanliga frågor</Subtitle1>
        <Body1>
          <p>
            Hitta svar på vanliga frågor om TeamConnects funktioner och inställningar i detta dedikerade avsnitt. Oavsett om du navigerar i appen, anpassar inställningar eller söker lösningar på utmaningar, är vårt hjälpcenter här för att assistera dig effektivt.
          </p>
        </Body1>
        <Body1>
          <p>
            För mer djupgående stöd eller specifik vägledning, vänligen konsultera <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnect användarguide</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        
        <Subtitle2>Använda TeamConnect - Vanliga frågor</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Hittar inte TeamConnect i sidofältet i Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Om TeamConnect inte är synligt eller har försvunnit i ditt sidofält i Microsoft Teams, kan du enkelt fästa det för snabb åtkomst. Följ dessa enkla steg:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Öppna Microsoft Teams och klicka på '...' (+ appar) ikonen längst ner i sidofältet.</li>
                    <li className={styles.bulletBottomMargin}>Sök efter 'TeamConnect' i sökfältet högst upp i popup-fönstret.</li>
                    <li className={styles.bulletBottomMargin}>Högerklick på TeamConnect-appen från sökresultaten och välj 'Fäst'.</li>
                  </ol>
                  TeamConnect kommer nu att vara fäst i ditt sidofält, vilket gör det tillgängligt med bara ett klick!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Upplever tekniska problem med TeamConnect? Här är hur du får hjälp.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Om du stöter på tekniska problem med TeamConnect inom Microsoft Teams, är din organisations IT-support här för att hjälpa till.
                  Kontakta dem på <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, så kommer de att assistera dig snabbt.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Kan du inte se dina anställduppgifter?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Om din profil känns lite tom med bara ditt namn och e-post som visas, är det troligt att ditt HR-team ännu inte har lagt till resten av dina anställduppgifter i TeamConnect.
                  Ingen anledning att oroa dig! Ett snabbt meddelande till ditt HR-supportteam kommer att sätta saker i rörelse. De är beredda att fylla i din profil med alla viktiga detaljer.
                  Kontakta dem på <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> och se din profil komma till liv!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Vilka språkalternativ finns tillgängliga i TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect omfamnar den globala mångfalden i Microsoft Teams genom att stödja alla dess språk. 
                  Oavsett om du föredrar arabiska eller grekiska, japanska eller svenska, har du en värld av alternativ till ditt förfogande, inklusive:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arabiska, bulgariska, kinesiska (förenklad och traditionell), kroatiska, tjeckiska, danska, nederländska, engelska, estniska, finska, 
                      franska, tyska, grekiska, hebreiska, ungerska, indonesiska, italienska, japanska, koreanska, lettiska, litauiska, norska (bokmål), 
                      polska, portugisiska (Brasilien och Portugal), rumänska, ryska, serbiska (latin), slovakiska, slovenska, spanska, svenska, thailändska, 
                      turkiska, ukrainska, vietnamesiska.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  För att anpassa din TeamConnect-upplevelse till ditt föredragna språk, justera språkinställningarna direkt i Microsoft Teams, 
                  eftersom TeamConnect integrerar helt med dessa inställningar:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Gå till dina inställningar i Microsoft Teams genom att klicka på de tre punkterna bredvid din profilbild högst upp och sedan välja 'Utseende och tillgänglighet'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Skrolla till avsnittet 'Språk' och välj ditt föredragna språk från rullgardinsmenyn.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Bekräfta dina ändringar genom att klicka på knappen 'Spara och starta om' för att tillämpa de nya språkinställningarna.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Notera: </Body1Strong>
                <Body1>
                  Ändringar som gjorts i dina språkinställningar i Microsoft Teams uppdateras även sömlöst i din TeamConnect-upplevelse. 
                  Var medveten om att ändringar av språket i Teams också kan justera ditt tid- och datumformat. För närvarande,
                  utvecklas TeamConnect för att stödja skrift från höger till vänster. Vi är entusiastiska över dessa förbättringar 
                  och kommer att hålla dig informerad när de blir tillgängliga.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Hur kan jag ändra tema i TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect synkroniserar med dina temainställningar i Microsoft Teams, inklusive Ljus, Mörk och Högt kontrast. För att anpassa ditt tema:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Gå till dina Microsoft Teams inställningar genom att klicka på de tre punkterna bredvid din profilbild högst upp.</li>
                    <li className={styles.bulletBottomMargin}>Välj "Utseende och tillgänglighet" för att utforska temalternativen.</li>
                    <li className={styles.bulletBottomMargin}>Välj ditt föredragna tema från de tillgängliga alternativen för att omedelbart uppdatera ditt TeamConnect-gränssnitt för att matcha.</li>
                  </ol>
                  Ditt val av tema i Microsoft Teams kommer automatiskt att tillämpas på TeamConnect, vilket säkerställer en konsekvent visuell upplevelse över din arbetsyta.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Utforska TeamConnects kontaktåtgärdsknappar
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Överallt i TeamConnect, där du hittar en anställds kontaktuppgifter, kommer du också att upptäcka våra praktiska snabbåtkomstknappar. Dessa användbara verktyg 
                  är utformade för att effektivisera din kommunikation inom Microsoft-ekosystemet, så att du alltid är bara ett klick bort från att ansluta. 
                  Här är din omfattande guide till att använda dessa knappar effektivt:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chatikon:</Body1Strong> Klicka för att starta en omedelbar Microsoft Teams-chatt. Om du behöver återgå till TeamConnect efter att ha startat en chatt, använd bara tillbaka-pilen i skrivbordsappen, tillbaka-knappen i mobilappen, eller din webbläsares tillbaka-knapp.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>E-postikon:</Body1Strong> Öppnar ett nytt Outlook-e-postutkast med mottagarens adress redo i "Till:"-fältet. Om Outlook inte startar, är det värt att kolla med din IT-avdelning för eventuella restriktioner.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Videoanropsikon:</Body1Strong> Initierar ett ansikte mot ansikte-videosamtal med hjälp av Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Anropsikon:</Body1Strong> Startar ett Microsoft Teams-röstsamtal och gör snabba diskussioner sömlösa.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organisationsdiagramikon:</Body1Strong> Avslöjar den anställdas plats i organisationshierarkin, inklusive deras chef och direkta rapporter.</li>
                    <li><Body1Strong>Mer (...)ikon:</Body1Strong> Avslöjar ytterligare alternativ:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Träffas nu:</Body1Strong> Öppnar Microsoft Teams för att starta ett omedelbart möte med den anställda.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Nytt möte:</Body1Strong> Dirigerar dig till Microsoft Teams för att schemalägga ett nytt möte.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Lägg till kontakt:</Body1Strong> Lägger till den anställdas kontaktuppgifter från TeamConnect till din nya Microsoft Teams People-app, vilket berikar din samtalsfunktion.</li>
                      </ul>
                    </li>
                  </ul>
                  Dessa intuitiva knappar är utformade för att du effektivt ska kunna ansluta med kollegor, oavsett om det gäller en snabb chatt, ett e-postmeddelande eller för att schemalägga framtida möten.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Att göra samtal via TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Vill du göra ett samtal? TeamConnect effektiviserar din kommunikation genom att integrera med Microsoft Teams. 
                  Klicka helt enkelt på det önskade telefonnumret i TeamConnect, och voilà, Microsoft Teams samtalsgränssnittet dyker upp,
                  redo för dig att ringa sömlöst.
                  <p>
                  <Body1Strong>OBS: </Body1Strong> 
                  Upplever du problem med att göra samtal? Detta kan bero på din organisations IT-policyer eller användningen av ett telefonsystem som inte är Microsoft Teams Phone System. Att kolla med din IT-avdelning på <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, kan ge några svar.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Finns det kostnader förknippade med att göra samtal via TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect förbättrar din samtalsupplevelse genom att utnyttja Microsoft Teams. Här är vad du behöver veta om kostnader relaterade till samtal:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      För samtal till anställdas arbets-telefoner, avdelningstelefoner eller journummer inom din organisation (interna nummer), 
                      finns det vanligtvis <Body1Strong>inga kostnader</Body1Strong> när du använder Microsoft Teams Phone System.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      För externa samtal, som de till mobiltelefoner eller externa linjer, gäller <Body1Strong>standardavgifter</Body1Strong> enligt din organisations telefonsystem. 
                      Dessa avgifter varierar beroende på ditt specifika tjänsteabonnemang med Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Obs:</Body1Strong> Om du stöter på problem eller har frågor om samtalsavgifter är det bäst att konsultera din 
                    IT-avdelning på <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> för detaljerad information anpassad 
                    till din organisations uppställning och policys.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Märker du en orimlighet med din 'Out of Office'-status i TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Har du märkt att din 'Out of Office'-status från Microsoft 365 inte återspeglas korrekt i TeamConnect? 
                  Vi har täckt upp detta. Funktionen för att synkronisera 'Out of Office'-status har redan implementerats i TeamConnect.
                  Vi väntar för närvarande på Microsofts implementering av denna funktion. När det sker kommer din 'Out of Office'-status
                  korrekt återspeglas i TeamConnect, i linje med Microsoft Teams närvaroindikatorer för en sömlös upplevelse.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>Vanliga frågor och svar för anställdaregister</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >Introduktion till anställdaregistret:</Body1Strong> Navigera i den professionella miljön i din organisation med anställdaregistret, 
                din centrala hubb för detaljerade insikter om anställda, arbetsdynamik och direktkoppling till dina kollegors profiler och ledningsrelationer.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Behöver du lägga till, uppdatera eller korrigera dina anställdadata?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  För att lägga till, uppdatera eller korrigera dina anställdadata i TeamConnect, var god kontakta din organisations HR-support. 
                  De är redo att hjälpa till på <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Vad händer när jag klickar på en anställds namn i katalogen?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Att klicka på en anställds namn i TeamConnect Anställdkatalog öppnar upp en detaljerad profilsida för den individen. Denna profil ger omfattande
                  insikter om den anställde, inklusive men inte begränsat till, deras jobbtitel, avdelning, kontorsplats, kontaktinformation och mer. Den är designad för att ge dig
                  en fullständig översikt av den anställdes professionella roll och hur de passar in i organisationen, vilket gör det enklare för dig att förstå deras ansvarsområden och
                  hur du kan samarbeta med dem.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Vilken information ingår i en anställds profil?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Varje anställdprofil i TeamConnect är en omfattande ögonblicksbild som erbjuder en uppsjö av insikter om individens professionella liv.
                  Här är vad du kommer att hitta:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Grundläggande uppgifter:</Body1Strong> Namn, jobbtitel, avdelning och plats för dotterbolag.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Rapportstruktur:</Body1Strong> Information om direkta och funktionella rapporter,
                      inklusive linje- och funktionschefer.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Arbetsplatsstatus:</Body1Strong> Aktuell arbetsstatus som Ledighet, Pendlar, 
                      Affärsresa, etc.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Arbetsplats & Lokal Tid:</Body1Strong> Den anställdes aktuella arbetsplats och 
                      lokala tidszon.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontaktuppgifter:</Body1Strong> Arbets telefon, mobilnummer och e-postadress.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontorsadress:</Body1Strong> Fullständig kontorsplats inklusive byggnadsnamn,
                      adress och Google-karta.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Om:</Body1Strong> En kort personlig introduktion eller professionell biografi.</ li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Anställdrelationer:</Body1Strong> Information om den anställdes rapportstruktur och teamrelationer.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sociala länkar & språk:</Body1Strong> Länkar till professionella sociala profiler
                      och språkkunskaper.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Färdigheter:</Body1Strong> En lista över professionella färdigheter och expertisområden.</li>
                  </ul>
                  Dessutom erbjuder profilen handlingsalternativ för att ansluta via chatt, e-post, videokonferens eller schemalägga möten, tillsammans med
                  snabb åtkomst till organisationens diagram för bredare teamkontext.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Hur kan jag uppdatera min profilinformation?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Det är enkelt att anpassa din profil i TeamConnect. Så här gör du:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Navigera till fliken <Body1Strong>Anställd</Body1Strong> och klicka på din <Body1Strong>Avatar</Body1Strong> för att öppna din profil.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        I det övre vänstra hörnet hittar du länken <Body1Strong>"Redigera din profil"</Body1Strong>. 
                        Klicka på den för att öppna redigeringsdialogen för din profil.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Här kan du förbättra din profil genom att lägga till eller uppdatera din <Body1Strong>Om Dig</Body1Strong>-sektion, sociala medier-länkar och telefonnummer. 
                        Detta underlättar för dina kollegor att ansluta eller följa dig.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Glöm inte att trycka på <Body1Strong>Spara</Body1Strong>-knappen för att tillämpa dina ändringar.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Skrolla ner i dialogen för att lägga till dina <Body1Strong>Språk</Body1Strong> och <Body1Strong>Skicklighetsnivå</Body1Strong>, 
                        samt dina <Body1Strong>Färdigheter</Body1Strong>. Att dela denna information hjälper dina kollegor att förstå 
                        dina expertisområden och de språk du kan kommunicera på.
                      </li>
                    </ol>
                  Att uppdatera din profil håller inte bara din information aktuell utan främjar också bättre anslutningar inom din organisation.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Uppdatera din arbetsplats, arbetsplats och tidszon i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Att hålla din arbetsplats, arbetsställe och tidszonsinformation uppdaterad är avgörande för sömlös samverkan i Microsoft Teams. 
                  Dessa uppdateringar återspeglas omedelbart i din TeamConnect-profil och listor, vilket möjliggör för kollegor att snabbt förstå din arbetsplats 
                  och de bästa tiderna att ansluta med dig, vilket förbättrar teamwork över olika regioner och tidszoner.
                  <p></p>
                  Redo att uppdatera dina uppgifter? Det är enkelt i TeamConnect! Gå till 'Inställningar' i toppmenyn och navigera till 'Allmänna inställningar'. 
                  Här kan du smidigt uppdatera din Arbetsplats och Tidszon, och specificera din Arbetsplats för dagen. Kom ihåg att spara dina ändringar för att 
                  hålla alla informerade och säkerställa effektiv samverkan, oavsett din plats!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Har du upptäckt något fel i en kollegas profil?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Om du stöter på några detaljer i en kollegas profil som inte verkar stämma är det bästa första steget att ge dem en 
                  vänlig påminnelse. Ett snabbt samtal eller e-post bör göra tricket! Om av någon anledning informationen inte blir uppdaterad, oroa dig inte—bara 
                  pinga ditt HR-stödteam på <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, och de kommer gärna 
                  att stiga in och ordna upp saker och ting.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Förståelse av tagggrupper: Alla, Favoriter, Incheckade & Nya Anställda
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Alla:</Body1Strong> Denna standardtagg visar alla anställda i din organisation, 
                      vilket ger en omfattande överblick av teamet.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoriter:</Body1Strong> Klicka här för att filtrera din vy till de kollegor du har markerat 
                      som 'Favoriter', vilket underlättar snabb kontakt med nyckelpersoner.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Incheckade:</Body1Strong> Denna tagg indikerar anställda som aktivt är på arbetet, vilket skiljer 
                      sig från bara att vara online i Microsoft Teams. När incheckade signalerar de anställda att de är på arbetet och delar sin aktuella arbetsplats 
                      under dagen. Detta kan inkludera en mängd olika arbetsplatser såsom kontor, fältkontor, hemmakontor, fjärrarbetsplats, kundens arbetsplats, 
                      kundens privata kontor, offentlig plats, pendling, affärsresa eller t.o.m. utbildning. Därför ger 'Incheckad'-statusen en tydlig indikation 
                      på att vara aktivt på arbetet, vilket ger kollegorna en exakt förståelse av varandras aktuella arbetskontext och tillgänglighet.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nya Anställda:</Body1Strong> Lokalisera nya ansikten i TeamConnect med denna tagg, vilket 
                      framhäver de anställda som har lagts till appen inom de senaste 30 dagarna. Vanligtvis sammanfaller detta med nytillkomna i din organisation, vilket hjälper 
                      dig att välkomna nya teammedlemmar.
                    </li>
                  </ul>
                  Dessa tagggrupper är utformade för att förenkla navigeringen och förbättra din TeamConnect-upplevelse, och säkerställer att du alltid kan 
                  hitta och koppla upp dig med dina kollegor effektivt.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Tolka ikonerna vid sökrutan
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnects medarbetarkatalog är designad för mångsidighet och användarvänlighet och har fyra ikoner som erbjuder olika vyer 
                  och snabb åtkomst till viktiga detaljer. Här är vad varje ikon avslöjar:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Listvy:</Body1Strong> Din standardvy, visar alla medlemmar i organisationen 
                      i en enkel lista.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Litet Kortvy:</Body1Strong> Ett kompakt alternativ, denna vy förvandlar 
                      listan till småbitar av medarbetarkort.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Stor Kortvy:</Body1Strong> Välj mer detaljer med större kort, som inkluderar 
                      ytterligare kontaktalternativ som telefon och mobilnummer för enkel uppringning.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organskartan:</Body1Strong> Visualisera företagets struktur med översikten över 
                      organisationen, perfekt för att förstå rapporteringsrelationer och navigera i organisationslandskapet.</li>
                  </ol>
                    Växla mellan dessa ikoner för att hitta den vy som bäst passar dina behov för stunden, oavsett om du söker snabb info eller djupare insikter.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Var ska jag leta för snabba kollegasökningar?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Letar du efter en kollega? Gå bara till TeamConnects sökruta, som ligger på övre högra sidan bredvid Organskartanikonen. 
                  Börja skriva deras namn, och se hur resultaten förfinas för varje bokstav. Kom ihåg, för TeamConnect-sökningar, hoppa över Microsoft Teams 
                  sökraden högst upp—vår speciella sökruta är vägen att gå!
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Hur fungerar den alfabetiska sökningen för att hitta anställda?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnects anställdkatalog inkluderar en praktisk alfabetisk sökfunktion för att hjälpa dig att snabbt hitta anställda efter deras förnamn. 
                  Klicka bara på en bokstav från alfabetet som visas högst upp på katalogen, och TeamConnect kommer omedelbart att visa dig alla anställda 
                  vars förnamn börjar med den bokstaven. För att återgå till den fullständiga listan över anställda, avmarkera bara den markerade bokstaven. Denna intuitiva 
                  funktion gör det enkelt att navigera genom stora listor av anställda, och säkerställer att du kan hitta kollegor snabbt och effektivt.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Hur kan jag justera antalet anställda som visas per sida?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect låter dig anpassa hur många anställdsprofiler du ser per sida, vilket gör det enklare att navigera genom anställdförteckningen. 
                  Längst ned på varje sida hittar du sidindelningsalternativ: 10, 50, 100, 500, 1000 och 5000. Standardinställningen visar 10 profiler per sida. 
                  För att visa fler profiler på en enda sida, välj helt enkelt ditt önskade nummer från dessa alternativ. Denna funktion ger flexibilitet i hur du bläddrar 
                  i katalogen, vilket möjliggör att du kan anpassa visningen efter dina specifika behov eller preferenser.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Hur sorterar jag anställdinformation i Listvyn?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  I TeamConnects anställdkatalog Listvy har du flexibiliteten att sortera anställdinformation för att passa dina behov. Kolumnerna—Anställd Namn, 
                  Jobbtitel, Avdelning, Kontorsplats, och Arbetsplats—är alla sorterbara. Klicka bara på kolumnrubriken du vill sortera efter. Ett klick sorterar 
                  kolumnen i stigande ordning, och ett ytterligare klick sorterar den i fallande ordning. Som standard är katalogen sorterad efter Anställd Namn i stigande ordning, 
                  baserat på förnamnet. Denna sorteringsfunktion hjälper dig snabbt att organisera och visa anställdinformation på ett sätt som bäst tjänar ditt syfte, oavsett om du letar 
                  efter någon specifik eller behöver gruppera anställda efter ett visst kriterium som avdelning eller plats.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Vanliga frågor om avdelningskatalogen</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Introduktion till avdelningskatalogen:</Body1Strong> Avdelningskatalogen är din portal till att förstå det komplexa nätverket av din 
              organisations struktur, och erbjuder en tydlig översikt av avdelningshierarkier, ledarskap på ledningsnivå och vägar för sömlös avdelningskommunikation.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Hur fungerar taggarna 'Alla', 'Favoriter' och 'Din avdelning' i avdelningskatalogen?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  I TeamConnects avdelningskatalog hjälper taggar dig att snabbt navigera och filtrera genom avdelningsinformation. Här är vad varje tagg representerar:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Alla:</Body1Strong> Denna tagg visar alla avdelningar inom din organisation och ger en 
                      omfattande översikt.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoriter:</Body1Strong> Använd denna tagg för att se en kuraterad lista av avdelningar du har 
                      markerat som 'Favoriter', vilket gör det lättare att komma åt avdelningar du ofta samverkar med.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Din avdelning:</Body1Strong> Denna tagg filtrerar vyn för att endast visa din avdelning, 
                      vilket möjliggör snabb tillgång till din omedelbara avdelningsinformation och uppdateringar.</li>
                  </ul>
                  Dessa taggar är utformade för att effektivisera din navigering inom avdelningskatalogen, vilket förbättrar din förmåga att effektivt hitta och engagera 
                  dig i avdelningsinformation.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Förståelser av vyer i avdelningskatalogen i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigering genom avdelningskatalogen i TeamConnect underlättas med flera visningsalternativ för att passa dina preferenser. Varje vy erbjuder ett 
                  unikt sätt att utforska omfattande avdelningsinformation, inklusive avdelningens namn, avdelningschef, moderavdelning, avdelningstelefon, 
                  avdelningshotline och avdelningse-post. Här är en översikt över tillgängliga vyer:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Listvy:</Body1Strong> Standardinställningen som ordnat listar alla avdelningar, ger en 
                      tydlig, koncis översikt.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Stor Kortvy:</Body1Strong> Byt till denna vy för att se detaljerade kort för varje avdelning, som 
                      erbjuder en utökad sammanfattning av varje avdelnings nyckelinformation.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Orgdiagramvy:</Body1Strong> För en visuell framställning av avdelningsstrukturen och 
                      relationerna, illustrerar Orgdiagramvyen hur varje avdelning passar inom den större organisatoriska hierarkin.
                    </li>
                  </ul>
                  För att växla mellan dessa vyer, använd helt enkelt ikonerna intill sökrutan högst upp i avdelningskatalogen. Varje vy är utformad för att hjälpa dig 
                  att komma åt och förstå avdelningsdetaljer med lätthet, för att säkerställa att du hittar den information du behöver i det format som fungerar bäst för dig.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Hur man använder sökfältet i avdelningskatalogen
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hitta smidigt vilken avdelning som helst inom TeamConnect med hjälp av det dedikerade sökfältet som ligger precis bredvid vyikonerna högst upp i 
                  avdelningskatalogen. Börja bara skriva namnet på avdelningen du söker efter och se hur katalogen finjusteras för att endast visa relevanta 
                  träffar. Den här funktionen är speciellt utformad för att du ska kunna kringgå behovet av att manuellt söka igenom hela katalogen, och istället 
                  direkt ta fram den avdelning du behöver till dina fingertoppar. Kom ihåg, denna sökfunktionalitet är unik för TeamConnects avdelningskatalog, så 
                  se till att använda detta sökfält istället för Microsoft Teams huvudsakliga sökfunktion högst upp i Teams-gränssnittet för avdelningsspecifika 
                  frågor.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Hur fungerar det alfabetiska filtret i avdelningskatalogen?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Effektivisera din sökning i avdelningskatalogen med det alfabetiska filtret som finns högst upp. Den här funktionen gör det möjligt för dig att 
                  snabbt begränsa avdelningar genom att välja en bokstav. När du har valt en bokstav filtrerar och visar TeamConnect endast de avdelningar vars namn 
                  börjar med din valda bokstav, vilket underlättar att hitta det du söker utan att behöva bläddra igenom hela listan. För att återgå till den fullständiga 
                  vy över alla avdelningar, välj helt enkelt bort den markerade bokstaven. Denna intuitiva alfabetiska navigering säkerställer att du effektivt kan 
                  hitta en avdelning genom dess begynnelsebokstav.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Hur kan jag kontakta avdelningens chef?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Att kontakta en avdelningschef i TeamConnect är enkelt. Leta bara upp avdelningen av intresse och klicka på chefens namn. Detta 
                  kommer att utlösa ett popup-fönster som visar chefens kontaktalternativ med de välbekanta snabbåtkomstikonerna:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chat-ikon:</Body1Strong> För att starta ett direktmeddelande via Microsoft Teams chat.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>E-post-ikon:</Body1Strong> För att öppna ett nytt e-mail adresserat till dem i Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Videoanrop-ikon:</Body1Strong> För att initiera ett videoanrop genom Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Samtalsikon:</Body1Strong> För att börja ett röstsamtal med Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Mer (...) ikon:</Body1Strong> För ytterligare alternativ som att schemalägga ett möte eller lägga 
                      till deras kontaktinformation i din Teams-lista.
                    </li>
                  </ul>
                  Dessa ikoner ger en smidig metod för att nå avdelningschefer direkt från TeamConnect, vilket säkerställer effektiv kommunikation inom din organisation.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Hur kan jag se eller kontakta anställda inom en avdelning?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  För att utforska vilka som finns inom en avdelning i TeamConnect, se inte längre än de tre punkterna bredvid avdelningsnamnet. Genom att klicka på detta kommer alternativ för vidare åtgärder visas:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Visa anställda i denna avdelning:</Body1Strong> Välj detta för att visa en lista över alla 
                      anställda som är associerade med avdelningen. Siffran bredvid detta alternativ anger hur många anställda det finns i avdelningen, vilket ger en snabb överblick.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Avdelningens organisationsschema:</Body1Strong> Om du vill förstå avdelningens hierarki, 
                      välj 'Avdelningens organisationsschema' för att avslöja den strukturella layouten, vilket visar relationen mellan moderavdelningar, aktuell avdelning, 
                      och eventuella underavdelningar. Denna visuella representation hjälper dig att navigera och förstå den organisatoriska ramen, vilket gör det enklare att identifiera och 
                      kontakta specifika avdelningsmedlemmar.
                    </li>
                    <li><Body1Strong>Avdelning {'>'} Anställd:</Body1Strong> Efter att ha sett de anställda inom en avdelning, använd denna navigeringsstig för att enkelt 
                      navigera tillbaka till avdelningslistan, vilket bibehåller en smidig utforskningsupplevelse inom TeamConnect.
                    </li>
                  </ul>
                  Dessa funktioner tillåter dig inte bara att se listan över anställda i en given avdelning utan även erbjuder en väg för att direkt kontakta dem genom 
                  TeamConnects kommunikationsverktyg.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Upptäck detaljerade uppgifter om avdelningar i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Fördjupa dig i specifikationerna för valfri avdelning med ett enkelt klick på dess namn i TeamConnect. En detaljerad dialog öppnas och erbjuder en rik väv av 
                  information som ger en fullständig bild av avdelningens roll och resurser inom din organisation. Du hittar allt från ledarskap och 
                  finansiella identifierare till kommunikationskanaler och sociala länkar. Denna funktion är din portal till:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Avdelningens hjärtpuls – dess syfte, människor och prestandamått.</li>
                    <li className={styles.bulletBottomMargin}>Kontaktvägar – direkta linjer till nyckelfigurer i avdelningen och kommunikationsplattformar.</li>
                    <li className={styles.bulletBottomMargin}>Den organisatoriska nätverken – förstå hur avdelningen integreras med den bredare företagsstrukturen.</li>
                  </ul>
                  Oavsett om det är en snabb fråga eller en djupdykning i avdelningsdata, TeamConnect utrustar dig med verktygen för att ansluta till och förstå de unika 
                  ekosystemen inom din organisation.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Nyfiken på frånvaron av avdelningsadresser i TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Att upptäcka en avdelnings plats kan ibland kännas som en skattjakt utan karta. I TeamConnect hittar du vanligtvis inte en specifik adress för varje avdelning. Här är varför denna geografiska detalj ofta utesluts:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Flera platser:</Body1Strong> Många avdelningar är inte begränsade till ett enda utrymme; de
                      frodas över olika kontor och geografier.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Flexibel struktur:</Body1Strong> Den organisatoriska landskapet är ständigt föränderligt, med 
                      avdelningar som utvecklas, sammanslås eller till och med upplöses i linje med strategiska förändringar.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Interdepartemental samverkan:</Body1Strong> Moderna arbetsplatser surrar av samarbetsanda, ofta genom att blanda talanger från olika avdelningar inom delade utrymmen.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Distansarbetskultur:</Body1Strong> Med flexibiliteten i distansarbete är avdelningens 
                      ankarpunkter inte alltid nedsänkta på fysiska kontorsplatser.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Juridiska nyanser:</Body1Strong> Avdelningar kretsar vanligtvis kring företagets juridiska 
                      struktur snarare än att ha sina egna fristående adresser.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Operativ förenkling:</Body1Strong> Att hålla fokus på dynamiskt teamwork istället för statiska 
                      adresser hjälper till att bibehålla operativ smidighet.
                    </li>
                  </ul>
                  Det handlar allt om att omfamna en gränslös strategi till teamwork och effektivitet. TeamConnect speglar denna filosofi genom att betona anslutningar över 
                  platser.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Saknas eller är inaktuella uppgifter om din avdelning?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Avdelningsredigerare är utsedda inom din organisation för att lägga till, uppdatera eller korrigera avdelningsspecifik information i TeamConnect.
                  Om du är osäker på vem din avdelningsredigerare är eller om viss information kräver ingripande av HR-stödteamet, 
                  vänligen kontakta <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> för vägledning.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Vanliga frågor om kontorsförteckningen</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Introduktion till kontorsförteckningen:</Body1Strong> Upptäck ryggraden i din organisations fysiska närvaro genom kontorsförteckningen, 
                som omfattar väsentliga kontorsdetaljer, driftinformation och en omfattande överblick över avdelningarna och personalen som definierar varje plats.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Att navigera etiketter i kontorsförteckningen
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hitta din väg i kontorsförteckningen med lätthet genom att använda vårt taggsystem utformat för snabb tillgång till rätt plats. Här är hur varje tagg kan hjälpa till att effektivisera din sökning:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Alla:</Body1Strong> Visar en komplett lista över kontor, vilket ger en överblick av 
                      din organisations platser.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoriter:</Body1Strong> Hjälper dig att fokusera på de kontorsplatser du ofta besöker eller behöver 
                      ha extra koll på, genom att markera dem som 'Favoriter'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ditt kontor:</Body1Strong> Denna personliga tagg filtrerar direktoriet för att visa dig den kontorsplats 
                      där du är stationerad, vilket håller din hemmabas inom räckhåll.
                    </li>
                  </ul>
                  Oavsett om du bokar ett mötesrum eller planerar ett besök erbjuder dessa taggar ett personligt och förenklat sätt att interagera med din organisations 
                  geografiska landskap.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Behärska visningar av kontorsregister i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Utforska den organisatoriska layouten för ditt företags olika platser med TeamConnects kontorsregister visningar. Varje vy är utformad för att underlätta 
                  en unik aspekt av navigering och förståelse av kontoret:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lista:</Body1Strong> Standardinställningen som organiserar alla kontorsplatser i 
                      en lättläst lista.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Stor Kort Vy:</Body1Strong> Stor Kort Vy i kontorsregistret berikar din 
                      vy med en detaljerad presentation av varje kontor. När du interagerar med ett kontorskort hittar du en mängd information och handlingsalternativ 
                      inom menyn "Mer (...)" :
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Visa avdelningar:</Body1Strong> Navigera direkt till en lista
                          över alla avdelningar som finns inom kontoret.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Visa anställda:</Body1Strong> Få tillgång till en komplett lista över anställda tilldelade till 
                          kontoret, vilket underlättar direktkontakt eller utforskning av kontorspersonal.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Företagsenhets organisationskarta:</Body1Strong> Visualisera kontorets plats inom den större 
                          organisationskontexten för en tydlig förståelse av hierarkiska strukturer.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Viktiga kontakter:</Body1Strong> Identifiera nyckelkontakter på kontoret för effektiv 
                          kommunikation.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Kontors allmänna helgdagar:</Body1Strong> Håll dig informerad om kontorsspecifika allmänna 
                          helgdagar för att planera dina scheman därefter.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Kontorsexpeditionstider:</Body1Strong> Se kontorets öppettider för att anpassa dina 
                          besök eller samtal till deras aktiva tider.
                        </li>
                      </ol>
                      Denna vy ger inte bara en visuell och informativ ögonblicksbild utan också snabb tillgång till djupare insikter och direkta åtgärder relaterade till kontoret, allt snyggt placerat under alternativet "Mer (...)" för din bekvämlighet.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organisationskarta Vy:</Body1Strong> Visualisera den hierarkiska strukturen av moder- och filialkontor 
                      för att förstå sambandet mellan olika kontorsplatser inom din organisation.
                    </li>
                  </ul>
                  Byt mellan dessa vyer med hjälp av ikonerna bredvid sökrutan för att anpassa din sökupplevelse till dina omedelbara behov.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Snabbt hitta kontor med sökfunktionen i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Att snabbt hitta rätt kontorsplats inom TeamConnect görs enkelt med den dedikerade sökfunktionen. Positionerad väl synlig bredvid vyikonerna, är sökrutan ditt 
                  verktyg för att pricka in kontor efter namn. När du skriver raffineras katalogvisningen för att endast visa kontor som matchar din inmatning, vilket 
                  effektiviserar din sökprocess. Det är viktigt att använda denna sökruta inom TeamConnect för de mest exakta och relevanta resultaten, till skillnad från den allmänna 
                  sökningen i Microsoft Teams. Med denna riktade sökfunktionalitet kan du navigera snabbt till vilket kontor som helst inom din organisations nätverk.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Navigera alfabetiskt i kontorsregistret
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Om du föredrar att söka alfabetiskt, är vårt alfabetiska filter ett snabbt sätt att hitta kontor efter namn. Överst i kontorsregistret kommer du
                  att se bokstäverna A till Z. Genom att klicka på en bokstav filtreras vyn för att endast visa de kontor som börjar med den bokstaven, vilket förenklar din
                  sökprocess. För att återställa och se alla kontor igen, avmarkera helt enkelt den aktiva bokstaven. Denna funktion är särskilt användbar när du känner till
                  kontorets namn men behöver ett snabbt sätt att lokalisera det inom TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Avtäckning av omfattande kontorsprofiler i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ge dig ut på en upptäcktsresa genom kärnan i din organisations fysiska närvaro med TeamConnects kontorsprofiler.
                  Genom att klicka på ett kontorsnamn dyker en livlig profileringsdialog upp, fylld med flikar fulla av detaljerade insikter om kontoret:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Översikt:</Body1Strong> Dyk ner i kärnan av kontorets identitet med detaljerade
                      beskrivningar, från affärsstruktur till öppettider och lokal tid, fångar essensen av kontorets miljö.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Viktiga kontakter:</Body1Strong> Få tillgång till avgörande kontakter för nödtjänster och
                      externt stöd, var och en ett klick bort genom Microsoft Teams telefon system.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontorskontakter:</Body1Strong> Kontakta interna supporttjänster med lätthet, med
                      kontaktuppgifter och direkta interaktionsmöjligheter direkt till hands.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Allmänna helgdagar:</Body1Strong> Håll dig uppdaterad om kontorsspecifika helgdagar, säkerställ att
                      dina planer överensstämmer med lokala iakttagelser och arbetsscheman.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontorstider:</Body1Strong> Planera dina interaktioner med exakt kunskap om när
                      kontoret är fullt av aktivitet eller lugnar ner sig för dagen.
                    </li>
                  </ul>
                  Oavsett om det är det pulserande Austin eller det strategiska navet Singapore, erbjuder varje flik en portal för att förstå och ansluta sig till de olika
                  platserna som utgör din företags globala närvaro.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Utforska dynamiken i avdelningarna inom ett kontor
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Att navigera det professionella nätverket av avdelningar på ett kontor utförs med lätthet i TeamConnect. Här är hur du djupdyker i detaljerna:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Visa avdelningar i detta kontor:</Body1Strong> Upptäck alla avdelningar som utgör 
                      kontorets väv genom att välja detta alternativ från menyn Mer (...).
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organisatorisk insikt:</Body1Strong> Klicka på en avdelning för att avslöja dess 
                      organisationsdiagram för en visuell representation av dess struktur, med brödsmulspåret som leder dig: Kontor {'>'} Avdelning {'>'} Diagram.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Djupdykning i avdelningen:</Body1Strong> Utforska ytterligare en avdelning genom att 
                      klicka på dess Mer-menyn (...) bredvid avdelningsnamnet. Här kan du få tillgång till en lista över alla anställda inom den avdelningen, 
                      direkt inom kontorets kontext.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Brödsmulsnavigering:</Body1Strong> Förflytta dig sömlöst genom nivåerna. Från 
                      anställda tillbaka till avdelningar eller från organisationsdiagrammet, brödsmulspåret (Kontor {'>'} Avdelning {'>'} Anställd {'>'} Diagram) 
                      håller din resa intuitiv och på rätt spår.
                    </li>
                  </ul>
                  TeamConnects intrikata vägar inbjuder dig att utforska, förstå och engagera dig i varje skikt av kontorets avdelningar, och ger dig 
                  omfattande navigationsbefogenheter.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Att koppla ihop sig med kontorspersonal i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nyfiken på ansiktena bakom ett kontor? Svaret är bara ett klick bort i TeamConnect. Engagera dig med ellipsen (tre punkter) bredvid det valda 
                  kontorsnamnet för att avslöja en svit av interaktiva alternativ:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Visa anställda i detta kontor:</Body1Strong> Avslöja en lista över alla yrkesverksamma 
                      som kallar detta kontor för deras arbetsplats. Det visade numret erbjuder en snabb uppräkning av anställda, vilket bjuder in dig att djupdyka 
                      in i kontorsgemenskapen.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontaktalternativ:</Body1Strong> Varje anställds post är utrustad med ikoner för 
                      snabbåtgärder, vilket gör det möjligt för dig att initiera chattar, samtal eller till och med video konferenser direkt från TeamConnect, 
                      vilket främjar sömlös intra-kontors kommunikation.
                    </li>
                    <li><Body1Strong>Navigering:</Body1Strong> För att återvända till den bredare kontorslistan från en lista över anställda, klicka helt enkelt 
                      på 'Kontor' i brödsmulspåret (Kontor {'>'} Anställd), så tas du tillbaka till kontorsöversikten.
                    </li>
                  </ul>
                  Med dessa funktioner gör TeamConnect det enkelt att visualisera arbetsstyrkans fördelning över kontor och att nå ut ansträngningslöst, 
                  vilket främjar en sammanlänkad arbetsplats oavsett avstånd.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Saknas eller är informationen om ditt kontor inaktuell?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Uppdateringar av kontorsinformation i TeamConnect kan hanteras av tilldelade kontorsredaktörer. Om du är osäker på vilka kontorsredaktörerna är eller
                  behöver hjälp med information som faller under HR:s ansvarsområde, är HR-supportteamet redo att
                  assistera på <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Inställnings-FAQ</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Betydelsen av korrekta allmänna inställningar i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  I det dynamiska arbetslandskapet som råder idag är det viktigare än någonsin att hålla sig ansluten och samordnad. Det är här uppdatering av dina 
                  allmänna inställningar i TeamConnect spelar en avgörande roll:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Arbetsplatsval:</Body1Strong> Fånga essensen av din arbetsmiljö genom att uppdatera din Arbetsplats så att den speglar din nuvarande 
                        situation, vare sig det är en förändring från lugnet i ditt hemmakontor till sorlet på huvudkontoret eller att bosätta sig i ett offentligt område. 
                        Denna tydlighet i din arbetslokal ger kollegor insikt inte bara i de bästa sätten att nå dig, men också i vilken typ av 
                        samtal de kan engagera sig i med dig, vilket skyddar konfidentialiteten av känsliga diskussioner särskilt när du är mitt i 
                        ett öppet miljö hos en klients kontor eller sitter i en flygplatsterminal.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Specification av arbetsplats:</Body1Strong> Att dela din arbetsstad gör mer än att överbrygga avstånd i en fjärrteaminställning – det öppnar även 
                        dörrar för spontana professionella sammankomster eller informell socialisering. Vare sig det är en snabb kaffe, en långsam lunch eller ett samarbetsmöte, 
                        att veta att du är i samma stad kan förvandla en vanlig dag till en möjlighet för meningsfulla ansikte-mot-ansikte-kontakter. Detta stärker inte bara 
                        teambanden utan ger också en djupare förståelse för de lokala nyanserna som kan påverka ditt arbete.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tidszonsprecision:</Body1Strong> När team sträcker sig över kontinenter, är det viktigt att din tidszon 
                      återspeglar din aktuella plats för att harmonisera kommunikationen. Din TeamConnect-profil's lokala tid påverkas direkt av denna inställning, 
                      vilket eliminerar all gissning för kollegor som försöker nå dig.
                    </li>
                  </ul>
                  Dyk in i 'Inställningar' {'>'} 'Allmänna Inställningar' inom TeamConnect för att finjustera dessa detaljer. Några minuter spenderade på att uppdatera dina inställningar kan 
                  väsentligt öka sammanhållningen och effektiviteten i dina samarbetsinsatser över hela linjen.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Tyda arbetsplatsval i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Att välja rätt inställning i rullgardinsmenyn för arbetsplats anpassar TeamConnect för att exakt återspegla din nuvarande arbetssituation. Håll muspekaren över infoikonen för en detaljerad förklaring av varje alternativ:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Utcheckad/Paus:</Body1Strong> Betyder tid borta från arbetet, antingen för en paus eller dagens slut.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontor/Fältkontor:</Body1Strong> Anger närvaro i en företagsstyrd arbetsyta.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Hemmakontor:</Body1Strong> Arbetar från en dedikerad arbetsyta hemifrån.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Fjärrarbetsplats/Offentligt område:</Body1Strong> Arbetar från en icke-kontorsmiljö, vilket tyder på ett behov av flexibel kommunikation.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kunds arbetsplats/Privatkontor:</Body1Strong> På plats hos en kund, med en anteckning om sekretessnivåer för samtalens känslighet.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pendling/Affärsresa/Utbildning:</Body1Strong> På väg eller engagerad i specifika arbetsrelaterade aktiviteter utanför kontoret.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Allmän helgdag/Semester/Olika ledigheter:</Body1Strong> Officiellt ledig av personliga, hälsomässiga eller helgdagsskäl.</li>
                  </ul>
                  Att välja en lämplig arbetsplatsinställning säkerställer att ditt team har den kontext som behövs för interaktioner, med respekt för tillgänglighet och integritet.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Navigera 'Incheckad'-status i TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Etiketten 'Incheckad' i anställningskatalogen är utformad för att visa alla anställda som för närvarande är på arbete. Denna synlighet bestäms av valet av arbetsplats du gör:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Aktiva arbetsmiljöer:</Body1Strong> Alternativen Kontor, Fältkontor, Hemmakontor, Fjärrarbetsplats, Offentlig plats, Kundens arbetsplats, 
                    Kundens privatkontor, Pendling, Affärsresa och Utbildning signalerar att du är engagerad i arbetet, vilket gör dig 'Incheckad' och synligt aktiv för kollegor.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Inställningar som inte är arbetsplatser:</Body1Strong> Att välja Utcheckad, Paus, Allmän helgdag, Semester, Ledighet, Moderskapsledighet eller Sjukskriven 
                      tar bort dig från 'Incheckad'-listan och skapar klara förväntningar på tillgänglighet och svarstider.
                  </li>
                  </ul>
                  Denna funktionalitet hjälper till att avgränsa arbetsperioder från personlig tid, vilket bidrar till effektivt teamsamarbete och kommunikation.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Datahantering och integritets-FAQ</Subtitle2>
          <Body1>
            <p>
              I dagens digitala ålder är det avgörande att förstå hur dina data hanteras. TeamConnect är engagerade i transparens och robusta dataskyddspraktiker. 
              Nedan hittar du information om hur vi hanterar, lagrar och skyddar dina data inom TeamConnect, vilket säkerställer att din digitala arbetsyta är säker och följer regelverk.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Hur skyddar TeamConnect mina personuppgifter?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect är dedikerade till att skydda din personliga information med ett omfattande tillvägagångssätt för datasäkerhet:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Enkel inloggning (SSO):</Body1Strong> För att öka säkerheten och förenkla användarupplevelsen integrerar TeamConnect automatiserad
                      Enkel inloggning (SSO) med ditt Microsoft 365-konto, vilket möjliggör säker och ansträngningsfri åtkomsthantering.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Kryptering:</Body1Strong> Vi säkerställer att alla data inom TeamConnect är krypterade både under överföring och när de är i vila, 
                      vilket gör dem otillgängliga för obehöriga parter.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Data Minimering:</Body1Strong> Vår datainsamling är strikt begränsad till vad som är nödvändigt för TeamConnects funktionalitet. 
                      Den enda användarinformation vi lagrar är ditt användarhuvudnamn (UPN) från Azure Entra ID, som kan likna din företags e-postadress.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Begränsad Åtkomst:</Body1Strong> Åtkomst till UPN är begränsad till abonnemangsadministratören för din organisation inom vår prenumerationportal, 
                      vilket säkerställer att känslig information endast är synlig för behörig personal.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Omedelbar Data Radering:</Body1Strong> TeamConnect är strukturerat för att omedelbart radera det lagrade UPN under tre specifika förhållanden: 
                      om prenumerationen avslutas av abonnemangsadministratören, om en användare tas bort av HR inom TeamConnect, eller om TeamConnect 
                      prenumerationen löper ut. Detta säkerställer att dina personuppgifter inte behålls längre än dess användbara liv eller nödvändighet.
                    </li>                      
                  </ul>
                  <p>Genom dessa åtgärder förbinder sig TeamConnect att upprätthålla de högsta standarderna för dataskydd, så att dina personuppgifter förblir säkra och konfidentiella.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Undrar du var dina data befinner sig med TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect bryter mallen för traditionella SaaS-erbjudanden genom att säkerställa att dina data aldrig lämnar din organisation. 
                  Till skillnad från den vanliga praxisen att lagra data på externa molnservrar, utnyttjar TeamConnect genialt din organisations 
                  Microsoft 365 SharePoint Online för att hysa alla applikationsdata. Denna innovativa metod främjar inte bara dataskydd utan stärker även säkerheten,
                  i perfekt överensstämmelse med vår stränga hängivenhet till att skydda din integritet och att följa stränga efterlevnadsstandarder.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Kommer mina data att delas med tredje part?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nej, data delas inte med tredje part! Cubeet är orubblig i vårt åtagande att skydda din datas integritet och säkerhet. Vi delar inte några TeamConnect-data med tredje part utan att få uttryckligt samtycke från våra kunder. 
                  I vår säkra miljö lagrar vi endast nödvändiga identifierare, såsom User Principal Name (UPN), organisationsnamn, klientnamn och klient-ID, i vårt prenumerationsportal. 
                  Denna information är avgörande för tjänsteleveransen och skyddas under våra stränga sekretessregler.
                  Var säker, våra datahanteringspraxis är utformade för att säkerställa att dina data förblir konfidentiella, med transparens och kontroll som ligger fast i dina händer.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Hur följer TeamConnect dataskyddsförordningar som GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect överensstämmer med dataskyddsförordningar som GDPR, HIPAA, CCPA och andra genom att utnyttja de medfödda efterlevnadsfunktionerna i Microsoft 365-miljön, 
                  där all kunddata lagras säkert. Till skillnad från traditionella SaaS-modeller lagrar TeamConnect inte några kunddata på sina serverar; istället opererar det inom din 
                  organisations Microsoft 365-miljö, i enlighet med dess robusta säkerhets- och efterlevnadsstandarder. Detta tillvägagångssätt säkerställer att din datagovernans är överensstämmande med 
                  den omfattande efterlevnadsramen som tillhandahålls av Microsoft 365, inklusive överensstämmelse med GDPR, HIPAA för hälsoinformation, CCPA för Kaliforniens invånare, 
                  och andra globala dataskyddsregler. Genom att integrera sömlöst med din befintliga Microsoft 365-uppsättning, ger TeamConnect din organisation möjligheten att behålla 
                  full kontroll över dina data, och säkerställer efterlevnad av de strängaste dataskyddslagarna och -reglerna.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Hur hanterar TeamConnect data från Microsoft Teams chattar, samtal, videosamtal, Outlook e-post och telefonsystem samtal?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect utnyttjar Microsoft Graph API för att möjliggöra direkta interaktioner som chatt, samtal, videosamtal och Outlook e-post inom Microsoft Teams, 
                  vilket ger en sömlös användarupplevelse. Det avgörande är att medan TeamConnect initierar dessa kommunikationer, gör den det utan att ingripa i kommunikationsprocessen 
                  eller ha förmågan att spela in eller lagra något av interaktionsinnehållet. Alla konversationer, samtal och e-postmeddelanden äger rum och hanteras direkt inom de 
                  säkra ramarna för Microsoft Teams och Outlook, i strikt överensstämmelse med Microsofts 365 robusta säkerhets- och integritetsstandarder. Detta säkerställer att TeamConnect förbättrar 
                  dina kommunikationsmöjligheter samtidigt som det fullständigt bevarar konfidentialiteten och integriteten för dina data.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            För detaljerad insikt i våra datapolicys och praxis, kan du också vilja konsultera {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>TeamConnect Användarguide</Body1Strong></Link> eller din organisations IT-avdelning på <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> för information specifikt relaterad till din uppsättning.
          </p>
        </Body1>        
        <p></p>  
        <Subtitle1 className={styles.fontColor}>Vi lyssnar: Dela med dig av din feedback och idéer</Subtitle1>
        <Body1>
          <p>
            Dina insikter driver TeamConnects utveckling. Vare sig du bubblar över av en lysande funktionsidé eller har feedback på din nuvarande upplevelse, vi är alltid lyhörda. Ditt bidrag är ovärderligt i vår kontinuerliga strävan att erbjuda
            den bästa möjliga servicen.
          </p>
        </Body1>      
        <Subtitle2>Feedback och förslag på funktioner</Subtitle2>
        <Body1>
          <p>
            Redo att dela med dig? Bege dig till vår {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Feedbacksida
            </Link>
            . Oavsett om det är ett förslag till en ny funktion eller feedback på befintliga funktionaliteter, välj bara 
            det lämpliga alternativet från rullgardinsmenyn i formuläret. Dina bidrag hjälper till att forma framtiden för TeamConnect, 
            säkerställer att den uppfyller och överträffar dina behov.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;