import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});
function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>TeamConnect-ohjekeskus ja UKK</Title3>
        <p><Body1>
          Tervetuloa TeamConnect-ohjekeskukseen, joka on suunniteltu tukemaan kokemustasi kattavan yrityshakemistosovelluksemme kanssa. Täältä löydät resursseja, jotka auttavat optimoimaan TeamConnectin käyttöä Microsoft Teamsissa sekä parantamaan viestintää verkostossasi.
        </Body1></p>
        <Subtitle2>Pikaopas</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Parannetut hakutoiminnot: </Body1Strong>
              Löydä oikeat työtoverit, osastot ja tärkeät kontaktit nopeasti tehokkailla hakutyökaluillamme.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Graafiset organisaatiokaaviot: </Body1Strong>
              Navigoi organisaatiosi hierarkiassa ja ymmärrä raportointilinjoja helposti intuitiivisten organisaatiokaavioiden avulla.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Sovellukset nykypäivän tarpeisiin: </Body1Strong>
              Käytä moderneja toimintoja, jotka on räätälöity nykyaikaisten työpaikkojen vaatimuksiin.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Tehosta kokemustasi</Subtitle2>
        <Body1>
          <p>
            TeamConnect mullistaa vuorovaikutuksesi organisaatiorakenteessasi, muuttaen jokapäiväiset viestinnät strategisiksi sitoumuksiksi.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Hanki apua ja UKK</Subtitle1>
        <Body1>
          <p>
            Löydä vastaukset yleisiin kysymyksiin TeamConnectin ominaisuuksista ja asetuksista tässä omistautuneessa osiossa. Olitpa sitten navigoimassa sovellusta, mukauttamassa asetuksia tai etsimässä ratkaisuja haasteisiin, ohjekeskuksessamme ollaan valmiina auttamaan sinua tehokkaasti.
          </p>
        </Body1>
        <Body1>
          <p>
            Jos tarvitset syvällisempää tukea tai erityisohjeita, ole hyvä ja neuvottele <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnectin käyttöopas</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        
        <Subtitle2>TeamConnectin käyttö - usein kysyttyjä kysymyksiä</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Eikö TeamConnect löydy Microsoft Teamsin sivupalkista?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jos TeamConnect ei ole helposti nähtävissä tai on kadonnut Microsoft Teamsin sivupalkista, voit helposti kiinnittää sen nopeaa pääsyä varten. Seuraa vain näitä yksinkertaisia vaiheita:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Avaa Microsoft Teams ja napsauta sivupalkin alareunassa olevaa '...' (+ Apps) -kuvaketta.</li>
                    <li className={styles.bulletBottomMargin}>Etsi 'TeamConnect' pop-up-ikkunan yläreunassa olevasta hakupalkista.</li>
                    <li className={styles.bulletBottomMargin}>Napsauta hiiren kakkospainikkeella TeamConnect-sovellusta hakutuloksista ja valitse 'Kiinnitä'.</li>
                  </ol>
                  TeamConnect on nyt kiinnitetty sivupalkkiisi, ja se on käytettävissä vain yhdellä klikkauksella!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Kohtaatko teknisiä ongelmia TeamConnectin kanssa? Näin saat apua.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jos kohtaat teknisiä ongelmia TeamConnectin kanssa Microsoft Teamsissa, organisaatiosi IT-tuki on täällä auttamassa. 
                  Ota yhteyttä heihin osoitteessa <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, niin he auttavat sinua pikaisesti.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Etkö löydä työntekijätietojasi?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jos profiilisi tuntuu hieman tyhjältä näyttäen vain nimesi ja sähköpostiosoitteesi, on todennäköistä, että HR-tiimisi ei ole vielä lisännyt loput työntekijätiedot TeamConnectiin. 
                  Ei hätää! Nopea viesti HR-tukitiimillesi laittaa asiat liikkeelle. He ovat valmiina täyttämään profiilisi kaikilla tärkeillä tiedoilla. 
                  Ota yhteyttä osoitteessa <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> ja katso, kuinka profiilisi herää eloon!
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Mitkä kielivaihtoehdot ovat saatavilla TeamConnectissa?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect tukee Microsoft Teamsin globaalia monimuotoisuutta tukemalla kaikkia sen kieliä.
                  Olipa suosikkisi arabia tai kreikka, japani tai ruotsi, käytettävissäsi on maailmanlaajuinen valikoima kielivaihtoehtoja, joihin sisältyvät:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arabia, bulgaria, kiina (yksinkertaistettu ja perinteinen), kroatia, tšekki, tanska, hollanti, englanti, viro, suomi,
                      ranska, saksa, kreikka, heprea, unkari, indonesia, italia, japani, korea, latvia, liettua, norja (Bokmål),
                      puola, portugali (Brasilia ja Portugali), romania, venäjä, serbia (latina), slovakki, sloveeni, espanja, ruotsi, thai,
                      turkki, ukraina, vietnam.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Mukauttaaksesi TeamConnect-kokemuksesi suosimaksesi kieleksi, säädä kieliasetuksia suoraan Microsoft Teamsissa,
                  sillä TeamConnect integroituu täysin näihin asetuksiin:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Siirry Microsoft Teams -asetuksiisi napsauttamalla kolmea pistettä profiilikuvan vieressä yläreunassa ja valitse sitten 'Ulkonäkö ja saavutettavuus'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Vieritä 'Kieli'-osioon ja valitse haluamasi kieli avattavasta valikosta.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Vahvista muutoksesi napsauttamalla 'Tallenna ja käynnistä uudelleen' -painiketta soveltaaksesi uusia kieliasetuksia.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Huom: </Body1Strong>
                <Body1>
                  Microsoft Teamsissa tekemäsi kieliasetusten muutokset päivittävät saumattomasti myös TeamConnect-kokemuksesi.
                  Huomioi, että Teamsin kielen muuttaminen voi myös muuttaa aika- ja päivämäärämuotoiluja. Tällä hetkellä
                  TeamConnect kehittyy tukemaan oikealta vasemmalle kirjoitusta. Olemme innoissamme näistä parannuksista
                  ja pidämme sinut ajan tasalla niiden saatavuudesta.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Kuinka voin vaihtaa teemaa TeamConnectissa?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect synkronoi teemapreferencesi Microsoft Teamsin kanssa, mukaan lukien Valoisa, Tumma ja Korkea kontrasti -vaihtoehdot. Teeman mukauttamiseen:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Pääse käsiksi Microsoft Teamsin asetuksiin napsauttamalla kolmea pistettä profiilikuvan vieressä yläreunassa.</li>
                    <li className={styles.bulletBottomMargin}>Valitse 'Ulkoasu ja saavutettavuus' tutkiaksesi teemavaihtoehtoja.</li>
                    <li className={styles.bulletBottomMargin}>Valitse haluamasi teema saatavilla olevista vaihtoehdoista, jotta TeamConnect-käyttöliittymäsi päivittyy välittömästi vastaamaan sitä.</li>
                  </ol>
                  Valitsemasi teema Microsoft Teamsissa sovelletaan automaattisesti TeamConnectiin, varmistaen johdonmukaisen visuaalisen kokemuksen työtilassasi.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Tutustuminen TeamConnectin yhteystoimintopainikkeisiin
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ympäri TeamConnectia, missä tahansa löydät työntekijän yhteystiedot, löydät myös kätevät nopean pääsyn painikkeemme. Nämä kätevät työkalut
                  on suunniteltu tehostamaan viestintääsi Microsoftin ekosysteemissä, varmistaen, että olet aina vain yhden klikkauksen päässä yhteydenotosta.
                  Tässä kattava opas näiden painikkeiden tehokkaaseen käyttöön:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Keskustelukuvake:</Body1Strong> Napsauta aloittaaksesi välittömän Microsoft Teams -keskustelun. Jos sinun on palattava TeamConnectiin chatin aloittamisen jälkeen, käytä vain Desktop Appin takaisin-nuolta, Mobile Appin takaisin-painiketta tai selaimen takaisin-painiketta.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sähköpostikuvake:</Body1Strong> Avaa uuden Outlook-sähköpostiluonnoksen vastaanottajan osoitteella valmiina "Vastaanottaja:" kentässä. Jos Outlook ei käynnisty, on hyvä tarkistaa IT-osastolta mahdolliset rajoitukset.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Videopuhelukuvake:</Body1Strong> Aloittaa kasvokkaisen videopuhelun käyttäen Microsoft Teamsia.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Puhelukuvake:</Body1Strong> Aloittaa Microsoft Teams -äänipuhelun, mikä tekee nopeista keskusteluista saumattomia.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Orgaanisena kaavio -kuvake:</Body1Strong> Paljastaa työntekijän paikan organisaatiohierarkiassa, mukaan lukien heidän esimiehensä ja suorat alaiset.</li>
                    <li><Body1Strong>Lisää (...) -kuvake:</Body1Strong> Paljastaa lisävaihtoehtoja:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Tapaa heti:</Body1Strong> Avaa Microsoft Teamsin aloittaakseen välittömästi kokouksen työntekijän kanssa.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Uusi kokous:</Body1Strong> Ohjaa sinut Microsoft Teamsiin aikatauluttaaksesi uuden kokouksen.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Lisää yhteystieto:</Body1Strong> Lisää työntekijän yhteystiedot TeamConnectista uuteen Microsoft Teams People -sovellukseen, rikastaen puhelutoimintoasi.</li>
                      </ul>
                    </li>
                  </ul>
                  Nämä intuitiiviset painikkeet on suunniteltu varmistamaan, että voit tehokkaasti yhteyttä kollegoihin, olipa kyseessä nopea chat, sähköposti tai tulevien kokousten aikatauluttaminen.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Puhelujen tekeminen TeamConnectin kautta
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Haluatko tehdä puhelun? TeamConnect virtaviivaistaa viestintääsi integroitumalla Microsoft Teamsin kanssa.
                  Klikkaa vain haluttua puhelinnumeroa TeamConnectissa, ja kas, Microsoft Teamsin puhelinliittymä ilmestyy,
                  valmiina antamaan sinun soittaa saumattomasti.
                  <p>
                  <Body1Strong>Huomioi: </Body1Strong>
                  Jos kohtaat ongelmia puhelujen tekemisessä, se saattaa johtua organisaatiosi IT-käytännöistä tai
                  muusta puhelinjärjestelmästä kuin Microsoft Teams Phone Systemista. IT-osastosi <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> voi antaa lisätietoa asiasta.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Aiheutuuko TeamConnect-puheluiden soittamisesta kustannuksia?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect parantaa puhelukokemustasi hyödyntämällä Microsoft Teamsia. Tässä sinun tulee tietää puheluihin liittyvistä kustannuksista:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Puheluista työntekijöiden työpuhelimiin, osastoille tai hätänumeroihin organisaatiossasi (sisäiset numerot),
                      ei yleensä ole <Body1Strong>kustannuksia</Body1Strong>, kun käytetään Microsoft Teams -puhelinjärjestelmää.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Ulkoisiin puheluihin, kuten matkapuhelimiin tai ulkopuolisiin linjoihin, sovelletaan <Body1Strong>normaaleja taksoja</Body1Strong> organisaatiosi puhelinjärjestelmässä.
                      Nämä hinnat vaihtelevat Microsoft Teamsin kanssa tekemäsi palvelusopimuksen mukaan.
                    </li>
                  </ul>
                  <p><Body1Strong>Huom:</Body1Strong> Jos kohtaat ongelmia tai sinulla on kysyttävää puhelumaksuista, on parasta ottaa yhteyttä 
                    IT-osastoon osoitteessa <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> täsmällisen tiedon saamiseksi 
                    organisaatiosi asetuksiin ja käytäntöihin liittyen.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Havaitsetko epäjohdonmukaisuuksia 'Out of Office' -tilasi näyttämisessä TeamConnectissa?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Huomasitko, että 'Out of Office' -tilasi Microsoft 365:stä ei heijastu oikein TeamConnectissa? 
                  Olemme huolehtineet asiasta. 'Out of Office' -tilan synkronointiominaisuus on jo toteutettu TeamConnectissa.
                  Odotamme parhaillaan Microsoftin ominaisuuden käyttöönottoa. Kun tämä tapahtuu, 'Out of Office' -tilasi 
                  näkyy oikein TeamConnectissa, ja se on linjassa Microsoft Teamsin läsnäoloindikaattoreiden kanssa saumattoman kokemuksen varmistamiseksi.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>Työntekijöiden hakemiston UKK</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >Työntekijöiden hakemiston esittely:</Body1Strong> Navigoi organisaatiosi ammatillisessa maisemassa Työntekijöiden hakemiston avulla, 
                keskeinen hubi yksityiskohtaisiin työntekijätietoihin, työpaikkadynamiikkaan ja suoraan yhteyteen kollegoidesi profiileihin ja esimiesuhteisiin.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Tarvitsetko lisätä, päivittää tai korjata työntekijätietojasi?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jos sinun täytyy lisätä, päivittää tai korjata työntekijätietojasi TeamConnectissa, ota yhteyttä organisaatiosi HR-tukitiimiin.
                  He ovat valmiita auttamaan osoitteessa <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Mitä tapahtuu, kun klikkaan työntekijän nimeä hakemistossa?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Työntekijän nimeä TeamConnect Työntekijähakemistossa klikkaamalla avautuu yksityiskohtainen profiilisivu kyseisestä henkilöstä. Tämä profiili tarjoaa kattavia
                  tietoja työntekijästä, mukaan lukien mutta ei rajoittuen hänen työnimikkeeseensä, osastoonsa, toimipaikkaansa, yhteystietoihinsa ja muuta. Profiili on suunniteltu antamaan sinulle
                  täydellinen katsaus työntekijän ammatilliseen rooliin ja siihen, miten hän sopii organisaatioon, helpottaen sinua ymmärtämään hänen vastuualueensa ja 
                  miten voisit mahdollisesti tehdä yhteistyötä hänen kanssaan.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Mitä tietoja sisältyy työntekijän profiiliin?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jokainen TeamConnectin työntekijäprofiili on kattava hetkikuva, joka tarjoaa runsaasti tietoa henkilön ammatillisesta elämästä. 
                  Tässä mitä löydät:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Perustiedot:</Body1Strong> Nimi, työnimike, osasto ja tytäryhtiön sijainti.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Raportointirakenne:</Body1Strong> Tiedot suorista ja toiminnallisista raporteista,
                      mukaan lukien linja- ja toiminnalliset esimiehet.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Työpaikan tila:</Body1Strong> Nykyinen työskentelytila, kuten lomalla, työmatkalla, 
                      liikematkalla jne.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Workspot & Paikallinen aika:</Body1Strong> Työntekijän nykyinen työpaikka ja 
                      paikallinen aikavyöhyke.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Yhteystiedot:</Body1Strong> Työpuhelin, matkapuhelinnumero ja sähköpostiosoite.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Toimiston osoite:</Body1Strong> Täydellinen toimipaikan sijainti, mukaan lukien rakennuksen nimi,
                      osoite ja Google-kartta.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tietoja:</Body1Strong> Lyhyt henkilökohtainen esittely tai ammatillinen elämäkerta.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Työntekijäsuhteet:</Body1Strong> Tiedot työntekijän raportointirakenteesta ja tiimisuhteista.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sosiaaliset linkit & Kielet:</Body1Strong> Linkit ammatillisiin sosiaalisen median profiileihin 
                      ja kielitaito.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Taidot:</Body1Strong> Luettelo ammatillisista taidoista ja asiantuntemusalueista.</li>
                  </ul>
                  Lisäksi profiili tarjoaa toiminnallisia vaihtoehtoja yhteydenpitoon chatin, sähköpostin, videopuhelun tai kokousten kautta, sekä 
                  nopean pääsyn organisaation kaavioon laajemman tiimikontekstin saamiseksi.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Kuinka voin päivittää profiilitietojani?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Profiilisi muokkaaminen on helppoa TeamConnectissa. Tässä miten voit tehdä sen:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Siirry <Body1Strong>Työntekijä</Body1Strong>-välilehdelle ja klikkaa <Body1Strong>Avatar</Body1Strong> -kuvakettasi avataksesi profiilisi.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Ylävasemmasta kulmasta löydät linkin <Body1Strong>"Muokkaa profiiliasi"</Body1Strong>. 
                        Klikkaamalla tätä avautuu profiilin muokkausvalintaikkuna.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Tässä voit parantaa profiiliasi lisäämällä tai päivittämällä <Body1Strong>Tietoja sinusta</Body1Strong> -osion, sosiaalisen median linkit ja puhelinnumeron. 
                        Tämä tekee sinut helpommin lähestyttäväksi ja seurattavaksi työkavereillesi.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Älä unohda painaa <Body1Strong>Tallenna</Body1Strong> -painiketta soveltaaksesi muutoksesi.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Vieritä valintaikkunassa alas lisätäksesi <Body1Strong>Kielet</Body1Strong> ja <Body1Strong>Osamisen tason</Body1Strong>, 
                        sekä <Body1Strong>Taidot</Body1Strong>. Tämän tiedon jakaminen auttaa työkavereitasi ymmärtämään 
                        osaamisalueitasi ja kommunikointikielesi.
                      </li>
                    </ol>
                  Profiilisi päivittäminen pitää tietosi ajantasaisena ja edistää parempia yhteyksiä organisaatiossasi.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Työpaikan, työpisteen ja aikavyöhykkeen päivittäminen TeamConnectissa
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Työpaikan, työpisteen ja aikavyöhykkeen tiedon pitäminen ajan tasalla on välttämätöntä saumattomalle yhteistyölle Microsoft Teamsissa. 
                  Nämä päivitykset näkyvät välittömästi TeamConnect-profiilissasi ja listauksissa, mahdollistaen työkavereillesi nopeasti ymmärtää 
                  työpaikkasi sijaintia ja parhaat ajat ottaa sinuun yhteyttä, parantaen tiimityötä eri alueilla ja aikavyöhykkeillä.
                  <p></p>
                  Valmis päivittämään tietosi? Se on helppoa TeamConnectissa! Siirry 'Asetukset'-kohtaan ylävalikossa ja navigoi 'Yleiset Asetukset'-kohtaan. 
                  Täällä voit sujuvasti päivittää Työpaikkasi ja Aikavyöhykkeesi, sekä määrittää Työpisteesi päivälle. Muista tallentaa muutoksesi 
                  pitääksesi kaikki ajan tasalla ja varmistaaksesi tehokkaan yhteistyön, riippumatta sijainnistasi!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Huomasitko jotain epäkohtia työkaverin profiilissa?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jos huomaat jotain epätarkkoja tietoja työkaverin profiilissa, paras ensimmäinen askel on antaa heille ystävällinen muistutus. Nopea keskustelu tai sähköposti riittää! Jos tiedot eivät jostain syystä päivity, älä huoli—ota yhteyttä HR-tukitiimiisi osoitteessa <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, ja he ovat valmiita auttamaan ja järjestelemään asiat kuntoon.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Ymmärrä tageja ryhmittäin: Kaikki, Suosikit, Sisäänkirjautuneet & Uudet Työntekijät
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kaikki:</Body1Strong> Tämä oletustagi näyttää kaikki organisaatiosi työntekijät,
                      tarjoten kattavan näkymän tiimiisi.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Suosikit:</Body1Strong> Klikkaa tästä suodattaaksesi näkymäsi niihin kollegoihin, jotka olet merkinnyt
                      'Suosikeiksi', helpottaen avainkontaktien nopeaa tavoittamista.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sisäänkirjautuneet:</Body1Strong> Tämä tagi osoittaa työssä aktiivisesti olevat työntekijät, eroittaen
                      heidät pelkästä online-läsnäolosta Microsoft Teamsissa. Sisäänkirjautuessaan työntekijät ilmoittavat olevansa töissä ja jakavat nykyisen työpaikkansa
                      päivän aikana. Tämä voi sisältää erilaisia työpaikkoja kuten Toimisto, Kenttätoimisto, Kotitoimisto, Etätyöpiste, Asiakastyöpaikka,
                      Asiakkaan Yksityistoimisto, Julkinen Alue, Matka, Liikematka tai jopa Koulutus. Siksi 'Sisäänkirjautunut' -status tarjoaa selkeän merkin
                      siitä, että ollaan aktiivisesti töissä, tarjoten kollegoille tarkan käsityksen toistensa nykyisestä työympäristöstä ja saatavuudesta.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Uudet Työntekijät:</Body1Strong> Havaitse uudet kasvot TeamConnectissa tämän tagin avulla, korostaen
                      työntekijöitä, jotka on lisätty sovellukseen viimeisten 30 päivän aikana. Tyypillisesti tämä vastaa tuoreita lisäyksiä organisaatioosi, auttaen
                      sinua toivottamaan uudet tiimiläiset tervetulleiksi.
                    </li>
                  </ul>
                  Nämä tagiryhmät on suunniteltu helpottamaan navigointia ja parantamaan TeamConnect-kokemustasi, varmistaen, että voit aina
                  löytää ja ottaa yhteyttä kollegoihisi tehokkaasti.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Hahmottele hakukentän vieressä olevat kuvakkeet
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectin Henkilöstöluettelo on suunniteltu monipuoliseksi ja käyttäjäystävälliseksi, esitellen neljä kuvaketta, jotka tarjoavat erilaisia näkymiä
                  ja nopean pääsyn keskeisiin tietoihin. Tässä mitä kukin kuvake paljastaa:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lista Näkymä:</Body1Strong> Perusnäkymäsi, esittäen kaikki organisaation jäsenet
                      selkeässä listassa.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pieni Korttinäkymä:</Body1Strong> Kompakti vaihtoehto, tämä näkymä muuntaa listan
                      pienikokoisiksi työntekijäkorteiksi.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Suuri Korttinäkymä:</Body1Strong> Valitse yksityiskohtaisempi näkymä suuremmilla korteilla, jotka sisältävät
                      lisää yhteystietoja kuten puhelin- ja matkapuhelinnumeroita helpottamaan soittamista.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organisaatiokaavio:</Body1Strong> Visualisoi yrityksen rakenne Organisaatiokaavio-näkymällä,
                      joka on täydellinen ymmärtämään raportointisuhteita ja navigoimaan organisaation maisemassa.</li>
                  </ol>
                    Vaihda näiden kuvakkeiden välillä löytääksesi näkymän, joka parhaiten sopii tarpeisiisi sillä hetkellä, olitpa sitten etsimässä nopeaa tietoa tai syvempiä oivalluksia.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Mistä etsiä kollegoita nopeasti?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Etsitkö kollegaa? Siirry vain TeamConnectin hakukenttään, joka sijaitsee yläoikealla puolella Organisaatiokaavion kuvakkeen vieressä.
                  Aloita kirjoittamalla heidän nimeään, ja seuraa kuinka tulokset tarkentuvat jokaisella kirjaimella. Muista, TeamConnect-hauissa, ohita Microsoft Teamsin
                  hakupalkki ylhäällä—meidän erikoishakukenttämme on oikea valinta!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Kuinka aakkoshakua käytetään työntekijöiden löytämiseen?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectin työntekijähakemisto sisältää kätevän aakkoshakutoiminnon, joka auttaa sinua löytämään työntekijät nopeasti heidän etunimensä perusteella.
                  Klikkaa vain kirjainta hakemiston yläreunassa näkyvästä aakkosjärjestyksestä, ja TeamConnect näyttää välittömästi kaikki työntekijät,
                  joiden etunimet alkavat kyseisellä kirjaimella. Palataksesi koko työntekijäluetteloon, poista vain korostettu kirjain. Tämä intuitiivinen 
                  toiminto tekee suurten työntekijäluetteloiden selaamisesta helppoa, varmistaen, että löydät kollegat nopeasti ja tehokkaasti.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Kuinka voin säätää näytettävien työntekijöiden määrää sivua kohden?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect mahdollistaa sen, että voit mukauttaa työntekijäprofiilien määrän sivua kohti, mikä helpottaa työntekijähakemiston selaamista. 
                  Kunkin sivun alareunassa löydät sivutusvaihtoehdot: 10, 50, 100, 500, 1000 ja 5000. Oletusasetus näyttää 10 profiilia sivua kohden. 
                  Nähdäksesi enemmän profiileja yhdellä sivulla, valitse vain haluamasi määrä näistä vaihtoehdoista. Tämä toiminto tarjoaa joustavuutta siinä,
                  kuinka selaa hakemistoa, mahdollistaen sen, että voit mukauttaa näkymää omien tarpeidesi tai mieltymystesi mukaan.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Kuinka järjestän työntekijätiedot Listanäkymässä?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectin työntekijähakemiston Listanäkymässä sinulla on joustavuus järjestää työntekijätietoja tarpeidesi mukaan. Sarakkeet—Työntekijän nimi,
                  Työnimike, Osasto, Toimipiste ja Työpaikka—ovat kaikki järjestettävissä. Klikkaa vain haluamaasi sarakeotsikkoa järjestääksesi sen.
                  Klikkaamalla kerran järjestetään sarake nousevaan järjestykseen ja klikkaamalla uudelleen laskevaan järjestykseen. Oletuksena hakemisto on järjestetty 
                  Työntekijän nimen perusteella nousevaan järjestykseen, perustuen etunimeen. Tämä järjestämisominaisuus auttaa sinua nopeasti organisoimaan ja tarkastelemaan 
                  työntekijätietoja parhaalla mahdollisella tavalla, olitpa sitten etsimässä tiettyä henkilöä tai ryhmittelemässä työntekijöitä tietyn kriteerin, kuten osaston 
                  tai sijainnin mukaan.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Osastohakemiston UKK</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Osastohakemiston esittely:</Body1Strong> Osastohakemisto toimii porttinasi ymmärtämään organisaatiosi monimutkaista rakennetta, tarjoten selkeän näkymän osastojen hierarkioista, johtajuudesta ja sujuvan osastoviestinnän mahdollisuuksista.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Miten 'Kaikki', 'Suosikit' ja 'Oma osasto' -tunnisteet toimivat Osastohakemistossa?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnectin osastohakemistossa tunnisteet auttavat sinua nopeasti navigoimaan ja suodattamaan osastotietoja. Tässä mitä kukin tunniste edustaa:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kaikki:</Body1Strong> Tämä tunniste näyttää kaikki organisaatiosi osastot, tarjoten 
                      kattavan yleiskatsauksen.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Suosikit:</Body1Strong> Käytä tätä tunnistetta nähdäksesi kuraatoidun listan osastoista, jotka olet merkinnyt 
                      'Suosikeiksi', helpottaen usein kanssasi vuorovaikutuksessa olevien osastojen pääsyä.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Oma osasto:</Body1Strong> Tämä tunniste suodattaa näkymän näyttämään vain oman osastosi, 
                      mahdollistaen nopean pääsyn välittömiin osastotietoihisi ja päivityksiin.</li>
                  </ul>
                  Nämä tunnisteet on suunniteltu helpottamaan navigointiasi Osastohakemistossa, parantaen kykyäsi löytää ja käsitellä osastotietoja tehokkaasti.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Ymmärrä Osastohakemiston näkymät TeamConnectissa
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Osastohakemiston käyttö TeamConnectissa on sujuvaa useiden katseluvaihtoehtojen avulla, jotka sopivat mieltymyksiisi. Jokainen näkymä tarjoaa ainutlaatuisen tavan tutkia kattavaa osastotietoa, mukaan lukien osaston nimi, osaston johtaja, ylemmän osaston nimi, osaston puhelinnumero, osaston palvelunumero ja osaston sähköposti. Tässä on katsaus saatavilla oleviin näkymiin:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Luettelonäkymä:</Body1Strong> Oletusasetus, joka siististi listaa kaikki osastot, tarjoten
                      selkeän, tiiviin yleiskatsauksen.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Laajennetun korttinäkymä:</Body1Strong> Vaihda tähän näkymään nähdäksesi yksityiskohtaiset osastokortit, jotka tarjoavat
                      laajennetun yhteenvedon kustakin osaston tärkeimmistä tiedoista.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organisaatiokaavionäkymä:</Body1Strong> Visuaalinen esitys osastorakenteesta ja 
                      suhteista, organisaatiokaavionäkymä esittää, kuinka jokainen osasto sijoittuu laajempaan organisaatiohierarkiaan.
                    </li>
                  </ul>
                  Vaihda näiden näkymien välillä käyttämällä kuvakkeita hakulaatikon vieressä Osastohakemiston yläosassa. Jokainen näkymä on suunniteltu auttamaan sinua 
                  pääsemään käsiksi ja ymmärtämään osastotietoja helposti, varmistaen, että löydät tarvitsemasi tiedot sinulle parhaiten sopivassa muodossa.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Kuinka käyttää hakulaatikkoa osastohakemistossa
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Etsi vaivattomasti mitä tahansa osastoa TeamConnectissa omistetun hakulaatikon avulla, joka sijaitsee näkymäkuvakkeiden vieressä yläreunassa 
                  Osastohakemistossa. Aloita vain kirjoittamalla etsimäsi osaston nimi, ja seuraa, kuinka hakemisto tarkentuu näyttämään vain 
                  asiaankuuluvat osumat. Tämä ominaisuus on suunniteltu erityisesti auttamaan sinua välttämään manuaalista läpikäymistä koko hakemistossa, tuoden 
                  tarvitsemasi osaston suoraan sormenpäihisi. Muista, että tämä hakutoiminto on ainutlaatuinen TeamConnectin Osastohakemistolle, joten varmista 
                  että käytät tätä hakulaatikkoa sen sijaan, että käyttäisit pää-Microsoft Teams -hakua, joka on Teams-käyttöliittymän yläreunassa, osastokohtaisiin tiedusteluihin.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Kuinka aakkosellinen suodatin toimii Osastohakemistossa?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Helpota hakua Osastohakemistossa aakkosellisen suotimen avulla, joka sijaitsee yläreunassa. Tämä ominaisuus mahdollistaa nopean osastojen rajaamisen 
                  valitsemalla kirjaimen. Kun olet valinnut kirjaimen, TeamConnect suodattaa ja näyttää vain ne osastot, joiden nimet alkavat 
                  valitsemallasi kirjaimella, helpottaen etsimääsi löytämistä ilman, että sinun tarvitsee selata koko listaa. Palatakseen takaisin koko osastonäkymään, 
                  poista vain korostettu kirjain. Tämä intuitiivinen aakkosnavigointi varmistaa, että voit tehokkaasti paikantaa minkä tahansa osaston 
                  alkukirjaimen perusteella.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Kuinka voin ottaa yhteyttä osaston johtajaan?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Osaston johtajan tavoittaminen TeamConnectissa on suoraviivaista. Etsi vain kiinnostuksesi kohdeosasto ja klikkaa johtajan nimeä. Tämä 
                  toiminto laukaisee ponnahdusikkunan, jossa näkyvät johtajan yhteystiedot tuttujen pikavalintaikoneiden kera:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chat-kuvake:</Body1Strong> Aloittaaksesi heti viestin Microsoft Teams chatin kautta.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sähköpostikuvake:</Body1Strong> Avataksesi uuden sähköpostin heille Microsoft Outlookissa.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Videoyhteyskuvake:</Body1Strong> Aloittaaksesi videopuhelun Microsoft Teamsin kautta.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Puhelukuvake:</Body1Strong> Aloittaaksesi äänipuhelun Microsoft Teamsin kautta.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lisää (...) -kuvake:</Body1Strong> Lisävaihtoehdoille kuten kokouksen aikatauluttamiseen tai 
                      heidän yhteystietojensa lisäämiseen Teams-listallesi.
                    </li>
                  </ul>
                  Nämä kuvakkeet tarjoavat saumattoman tavan ottaa yhteyttä suoraan osaston johtajiin TeamConnectin sisällä, varmistaen tehokkaan viestinnän 
                  organisaatiossasi.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Kuinka voin tarkastella tai ottaa yhteyttä työntekijöihin tietyssä osastossa?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jos haluat tutkia, keitä osastolla on TeamConnectissa, katso osaston nimen vieressä olevia kolmea pistettä. Napsauttamalla tätä saat esille toimintovaihtoehdot:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Näytä työntekijät tässä osastossa:</Body1Strong> Valitse tämä näyttääksesi luettelon kaikista osaston 
                      työntekijöistä. Vaihtoehdon vieressä oleva numero kertoo osaston työntekijöiden määrän, mikä antaa välittömän käsityksen.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Osaston organisaatiokaavio:</Body1Strong> Jos haluat ymmärtää osaston hierarkiaa, 
                      valitse 'Osaston organisaatiokaavio' paljastaaksesi rakenteellisen asettelun, joka näyttää suhteet pääosastojen, kyseessä olevan osaston, 
                      ja mahdollisten alaosastojen välillä. Tämä visuaalinen esitys auttaa sinua navigoimaan ja ymmärtämään organisaatiorakenteen, mikä tekee 
                      tiettyjen osaston jäsenten tunnistamisesta ja yhteydenotosta helpompaa.
                    </li>
                    <li><Body1Strong>Osasto {'>'} Työntekijä:</Body1Strong> Osaston työntekijöiden tarkastelun jälkeen käytä tätä hakupolkua palataksesi helposti 
                      osastoluetteloon, säilyttäen sujuvan tutkimiskokemuksen TeamConnectissa.
                    </li>
                  </ul>
                  Nämä toiminnot mahdollistavat paitsi työntekijäluettelon näkemisen tietyssä osastossa, myös tarjoavat reitin heihin suoraan yhteyden ottamiseksi 
                  TeamConnectin viestintätyökalujen kautta.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Tutustu syvällisesti osaston yksityiskohtiin TeamConnectissa
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Sukella minkä tahansa osaston yksityiskohtiin yksinkertaisesti napsauttamalla sen nimeä TeamConnectissa. Esiin avautuu yksityiskohtainen dialogi, joka tarjoaa 
                  rikkaan kudoksen tietoa, joka maalaa täydellisen kuvan osaston roolista ja resursseista organisaatiossasi. Löydät kaiken johtajuudesta ja taloudellisista 
                  tunnisteista viestintäkanaviin ja sosiaalisiin linkkeihin. Tämä ominaisuus on porttisi:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Osaston sydän – sen tarkoitus, ihmiset ja suoritusmittarit.</li>
                    <li className={styles.bulletBottomMargin}>Yhteydenpitokeinot – suorat linjat keskeisiin osaston henkilöihin ja viestintäalustoihin.</li>
                    <li className={styles.bulletBottomMargin}>Organisaatioverkko – ymmärrys siitä, miten osasto integroituu laajempaan yritysrakenteeseen.</li>
                  </ul>
                  Olipa kyseessä nopea tiedustelu tai syvällinen sukellus osastotietoihin, TeamConnect varustaa sinut työkaluilla, joiden avulla voit olla yhteydessä ja ymmärtää 
                  organisaatiosi ainutlaatuisia ekosysteemejä.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Ihmetteletkö osastojen osoitteiden puuttumista TeamConnectissa?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Osaston sijainnin selvittäminen voi joskus tuntua aarrejahdilta ilman karttaa. TeamConnectissa et tyypillisesti löydä omistettua osoitetta kullekin osastolle. 
                  Tässä syitä, miksi tämä kartografinen yksityiskohta usein jätetään pois:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Monet sijainnit:</Body1Strong> Monet osastot eivät rajoitu yhteen tilaan; ne kukoistavat eri 
                      toimistoissa ja maantieteellisillä alueilla.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Joustava rakenne:</Body1Strong> Organisaatiomaisema on jatkuvasti muuttuva, osastot kehittyvät, 
                      yhdistyvät tai jopa hajoavat strategisten muutosten myötä.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Poikkitieteellinen yhteistyö:</Body1Strong> Modernit työtilat kuhisevat yhteistyöhenkeä, usein 
                      yhdistäen eri osastojen kykyjä jaettuihin tiloihin.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Etätyökulttuuri:</Body1Strong> Etätyön joustavuuden ansiosta osaston ankkurit eivät aina ole 
                      fyysisissä toimistotiloissa.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lainopilliset vivahteet:</Body1Strong> Osastot tyypillisesti kiertävät yrityksen oikeudellisen 
                      rakenteen ympärillä sen sijaan, että niillä olisi omia itsenäisiä osoitteita.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Toiminnan virtaviivaistaminen:</Body1Strong> Dynaamisen tiimityön, ei staattisten osoitteiden, 
                      korostaminen auttaa ylläpitämään toiminnallista ketteryyttä.
                    </li>
                  </ul>
                  Kyse on rajoja rikkovan tiimityön ja tehokkuuden omaksumisesta. TeamConnect heijastaa tätä filosofiaa painottaen yhteyksiä sijaintien sijaan.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Puuttuuko tai onko osastosi tiedot vanhentuneet?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Osaston toimittajat on nimetty organisaatiossasi lisäämään, päivittämään tai korjaamaan osastokohtaisia tietoja TeamConnectissa.
                  Jos et ole varma, kuka osastosi toimittaja on tai jos tietyt tiedot vaativat HR-tukitiimin puuttumista,
                  ole hyvä ja ota yhteyttä <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> saadaksesi ohjeita.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Toimiston hakemiston UKK</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Toimiston hakemiston esittely:</Body1Strong> Tutustu organisaatiosi fyysisen läsnäolon ytimeen Toimiston hakemiston kautta,
                joka kattaa olennaiset toimistotiedot, toimintakohtaiset yksityiskohdat ja kattavan katsauksen jokaisen sijainnin osastoista ja henkilöstöstä.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Tunnisteiden navigointi toimiston hakemistossa
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Löydä tiesi toimiston hakemiston ympäri vaivattomasti käyttämällä tagijärjestelmäämme, joka on suunniteltu nopeaan pääsyyn oikeaan sijaintiin. Näin jokainen tagi voi helpottaa hakuasi:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kaikki:</Body1Strong> Näyttää täydellisen luettelon toimistoista, tarjoten lintuperspektiivin 
                      organisaatiosi sijainteihin.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Suosikit:</Body1Strong> Auttaa keskittymään niihin toimiston sijainteihin, joissa käyt usein tai joita 
                      sinun on seurattava tarkemmin, merkitsemällä ne 'Suosikeiksi'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Oma toimistosi:</Body1Strong> Tämä henkilökohtainen tagi suodattaa hakemiston näyttämään toimiston 
                      sijainnin, jossa olet sijoitettu, pitäen kotipesäsi ulottuvillasi.
                    </li>
                  </ul>
                  Olitpa sitten varaamassa kokoustilaa tai suunnittelemassa vierailua, nämä tarrat tarjoavat henkilökohtaisen ja yksinkertaistetun tavan vuorovaikuttaa organisaatiosi 
                  maantieteellisen maiseman kanssa.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Toimistohaun hallitseminen TeamConnectissa
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Sukella yrityksesi eri sijaintien organisaatiokaavoihin TeamConnectin toimistohakemistonäkymillä. Jokainen näkymä on suunniteltu helpottamaan
                  ainutlaatuista näkökulmaa toimistonavigointiin ja ymmärtämiseen:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Listanäkymä:</Body1Strong> Oletusasetus, joka järjestää kaikki toimistosijainnit
                      helposti luettavaan listaan.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Suurikorttinäkymä:</Body1Strong> Toimistohakemiston Suurikorttinäkymä rikastuttaa
                      näkymääsi yksityiskohtaisella esittelyllä kustakin toimistosta. Toimistokortin käyttötilanteessa löydät paljon tietoa ja toiminnallisia vaihtoehtoja
                      "Lisää (...)" -valikosta:
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Näytä Osastot:</Body1Strong> Siirry suoraan luetteloon
                          kaikista toimistossa sijaitsevista osastoista.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Näytä Työntekijät:</Body1Strong> Pääset käsiksi täydelliseen luetteloon toimistoon
                          määrätyistä työntekijöistä, mikä helpottaa suoraa yhteydenottoa tai toimiston henkilöstön tutkimista.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Yrityksen Organisaatiokaavio:</Body1Strong> Havainnollista toimiston asemaa laajemmassa
                          organisaatioyhteydessä selkeän käsityksen saamiseksi hierarkkisista rakenteista.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Tärkeät Yhteystiedot:</Body1Strong> Tunnista keskeiset toimiston yhteyshenkilöt sujuvaa
                          viestintää varten.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Toimiston Juhlapyhät:</Body1Strong> Pysy ajan tasalla toimistokohtaisista juhlapyhistä
                          aikataulusi suunnittelua varten.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Toimiston Aukioloajat:</Body1Strong> Katso toimiston käyttöajat, jotta voit sovittaa
                          käyntisi tai puhelusi heidän aktiivisiin aikoihinsa.
                        </li>
                      </ol>
                      Tämä näkymä tarjoaa paitsi visuaalisen ja informatiivisen yleiskuvan myös nopean pääsyn syvempiin oivalluksiin ja suoriin toimiin liittyen toimistoon, kaikki kätevästi "Lisää (...)" -vaihtoehdon alla.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organisaatiokaavionäkymä:</Body1Strong> Havainnollista emoyrityksen ja alayksiköiden hierarkkinen rakenne
                      ymmärtääksesi eri toimipisteiden välistä yhteyttä organisaatiossasi.
                    </li>
                  </ul>
                  Vaihda näitä näkymiä käyttämällä hakukentän vieressä olevia kuvakkeita, jotta voit mukauttaa selauskokemustasi vastaamaan välittömiä tarpeitasi.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Toimistojen nopea löytäminen TeamConnectin hakutoiminnolla
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Oikean toimipisteen löytäminen TeamConnectissa on tehty helpoksi omistetulla hakutoiminnolla. Näkyvästi näkymäkuvakkeiden vieressä sijoitettu hakulaatikko on työkalusi
                  toimistojen tarkkaan paikantamiseen nimellä. Kirjoittaessasi hakemisto tarkentaa näyttöä näyttämään vain syötettäsi vastaavat toimistot, mikä tehostaa hakuasi.
                  On tärkeää käyttää tätä hakulaatikkoa TeamConnectissa saadaksesi tarkimmat ja olennaisimmat tulokset, toisin kuin yleisen Microsoft Teamsin haun kanssa. Tämän
                  kohdennetun hakutoiminnon avulla voit navigoida nopeasti mihin tahansa organisaatiosi verkoston toimistoon.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Toimistoluettelon aakkosellinen selaaminen
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Jos haluat hakea aakkosjärjestyksessä, aakkosellinen suodatinkomponenttimme on nopea tapa löytää toimistoja nimen perusteella. Toimistoluettelon yläosassa näet kirjaimet A:sta Z:aan. Kirjaimen klikkaaminen suodattaa näkymän niin, että näet vain kyseisellä kirjaimella alkavat toimistot, mikä yksinkertaistaa hakuprosessiasi. Jos haluat nähdä jälleen kaikki toimistot, poista vain aktiivinen kirjain valinnasta. Tämä ominaisuus on erityisen hyödyllinen, kun tunnet toimiston nimen, mutta tarvitset nopean tavan paikantaa se TeamConnectissa.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Kattavien toimistoprofiilien paljastaminen TeamConnectissa
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Aloita tutkimusmatka organisaatiosi fyysisen läsnäolon ytimeen TeamConnectin toimistoprofiilien kautta.
                  Toimiston nimeä klikkaamalla avautuu eläväinen profiilidialogi, joka on täynnä välilehtiä, joissa on yksityiskohtaisia tietoja toimistosta:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Yleiskatsaus:</Body1Strong> Sukella toimiston identiteetin ytimeen yksityiskohtaisten kuvausten avulla, jotka kattavat kaiken liiketoiminnan rakenteesta aukioloaikoihin ja paikalliseen aikaan, vangiten toimistoympäristön olemuksen.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Välttämättömät yhteystiedot:</Body1Strong> Saavuta tärkeät yhteystiedot hätäpalveluille ja ulkopuoliselle tuelle, jokainen vain klikkauksen päässä Microsoft Teams Phone Systemin kautta.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Toimistoyhteystiedot:</Body1Strong> Ota yhteyttä sisäisiin tukipalveluihin helposti, saatavilla olevat yhteystiedot ja suorat vuorovaikutusvaihtoehdot suoraan ulottuvillasi.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Julkiset lomat:</Body1Strong> Pysy ajan tasalla toimistokohtaisista lomista, varmistaen että suunnitelmasi ovat linjassa paikallisten juhlapäivien ja työaikataulujen kanssa.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Toimiston aukioloajat:</Body1Strong> Suunnittele vuorovaikutuksesi tarkalla tiedolla siitä, milloin toimistossa on vilkasta toimintaa tai milloin päivä rauhoittuu.
                    </li>
                  </ul>
                  Olipa kyseessä Austinin vilkas energia tai Singaporen strateginen keskus, jokainen välilehti tarjoaa portin ymmärtää ja kytkeytyä yhtiösi globaalin läsnäolon eri paikkoihin.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Syventyminen Osastojen Dynamiikkaan Toimistossa
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Toimiston osastojen ammattiverkoston navigointi sujuu vaivattomasti TeamConnectissa. Tässä, miten syventyä yksityiskohtiin:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Näytä Tässä Toimistossa Olevat Osastot:</Body1Strong> Löydä kaikki osastot, jotka muodostavat toimiston 
                      ydinkudoksen valitsemalla tämä vaihtoehto Lisää (...) -valikosta.
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organisaationäkemys:</Body1Strong> Napsauta mitä tahansa osastoa paljastaaksesi sen organisaatiokaavion 
                      visuaalisena esityksenä rakenteesta, johdattavan linkkipolun kanssa: Toimisto {'>'} Osasto {'>'} Kaavio.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Osaston Syväanalyysi:</Body1Strong> Tutki tarkemmin osastoa napsauttamalla sen 
                      Lisää-valikkoa (...) osaston nimen vieressä. Täältä voit päästä käsiksi luetteloon kaikista kyseisen osaston työntekijöistä, suoraan toimistoyhteydessä.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Linkkipolkunavigointi:</Body1Strong> Kulje tasojen läpi saumattomasti. Työntekijöistä takaisin osastoihin tai organisaatiokaaviosta, linkkipolku 
                      (Toimisto {'>'} Osasto {'>'} Työntekijä {'>'} Kaavio) pitää matkasi intuitiivisena ja raiteillaan.
                    </li>
                  </ul>
                  TeamConnectin monimutkaiset polut kutsuvat sinua tutkimaan, ymmärtämään ja osallistumaan jokaiseen toimistosi osastojen kerrokseen, antaen sinulle 
                  kattavan navigointikomennon.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Yhteydenpito Toimiston Henkilöstöön TeamConnectissa
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Utelias toimiston kasvoista? Vastaus on vain klikkauksen päässä TeamConnectissa. Käytä kolmea pistettä valitsemasi toimiston nimen vieressä paljastaaksesi joukon vuorovaikutteisia vaihtoehtoja:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Näytä Tässä Toimistossa Olevat Työntekijät:</Body1Strong> Paljasta kaikkien ammattilaisten luettelo,
                      jotka kutsuvat tätä toimistoa työkodikseen. Näytetty numero tarjoaa nopean yhteenvedon työntekijöistä, kutsuen sinut tutustumaan toimiston yhteisöön.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Yhteydenottovaihtoehdot:</Body1Strong> Jokaisen työntekijätietueen yhteydessä on pikatoimintakuvakkeet, 
                      jotka antavat sinun aloittaa keskustelut, puhelut tai jopa videoneuvottelut suoraan TeamConnectin kautta, edistäen saumatonta sisätoimiston viestintää.
                    </li>
                    <li><Body1Strong>Navigointi:</Body1Strong> Palataksesi laajempaan toimistolistaan työntekijälistasta, napsauta vain 'Toimisto' linkkipolussa 
                      (Toimisto {'>'} Työntekijä), ja sinut viedään takaisin toimiston yleiskatsaukseen.
                    </li>
                  </ul>
                  Näiden ominaisuuksien avulla TeamConnect tekee työvoiman jakautumisen visualisoimisen toimistojen välillä yksinkertaiseksi ja mahdollistaa vaivattoman 
                  lähestymisen, edistäen yhteydessä olevaa työpaikkaa riippumatta etäisyydestä.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Onko toimistotietosi puutteelliset tai vanhentuneet?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Toimistotietojen päivitykset TeamConnectissa voidaan hoitaa määrätyt toimistomuokkaajat. Jos et ole varma toimistomuokkaajista tai
                  tarvitset apua HR:n toimialaan kuuluvissa tiedoissa, HR-tukitiimi on valmiina
                  auttamaan osoitteessa <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Asetusten UKK</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Tarkkojen yleisasetusten merkitys TeamConnectissa
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nykyisen työympäristön dynaamisessa maisemassa yhdistettynä ja koordinoidusti pysyminen on tärkeämpää kuin koskaan. Tässä yleisasetustesi päivittämisellä TeamConnectissa on keskeinen rooli:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Työpaikan valinta:</Body1Strong> Kuvaa työympäristösi päivittämällä Työpaikkasi heijastamaan nykytilannettasi, olipa kyseessä siirtyminen kotitoimiston rauhasta päätoimiston vilinään tai asettuminen Julkiselle alueelle.
                        Tämä selkeys työpaikassasi tarjoaa kollegoillesi käsityksen paitsi parhaista tavoista ottaa sinuun yhteyttä, myös keskusteluiden luonteesta,
                        joita he voivat käydä kanssasi, suojellen arkaluonteisten keskustelujen luottamuksellisuutta erityisesti, kun olet asiakkaan toimistossa tai istut lentokentän aulassa.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Työpaikan määrittely:</Body1Strong> Työkaupunkisi jakaminen luo enemmän kuin vain yhteyksiä etätiimissä—se avaa myös ovet spontaaneille ammattitapaamisille tai rennoille sosiaalisille kohtaamisille.
                        Olipa kyseessä nopea kahvi, rauhallinen lounas tai yhteistyöllinen kokous, tieto siitä, että olet samassa kaupungissa, voi muuttaa tavallisen päivän merkitykselliseksi kasvokkain tapahtuvaksi yhteydeksi.
                        Tämä ei ainoastaan vahvista tiimisiteitä, vaan tarjoaa myös rikkaampaa ymmärrystä paikallisista vivahteista, jotka saattavat vaikuttaa työhösi.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Aikavyöhykkeen tarkkuus:</Body1Strong> Kun tiimit kattavat mantereita, on elintärkeää, että aikavyöhykkeesi heijastaa nykyistä sijaintiasi harmonisoiden kommunikaatiota.
                      TeamConnect-profiilisi Paikallinen Aika määräytyy suoraan tämän asetuksen perusteella, poistaen arvailun, kun kollegat yrittävät tavoittaa sinua.
                    </li>
                  </ul>
                  Sukella 'Asetukset' {'>'} 'Yleisasetukset' TeamConnectissa hienosäätääksesi näitä tietoja. Hetki asetusten päivittämiseen voi merkittävästi parantaa yhteistyöpyrkimystesi yhtenäisyyttä ja tehokkuutta kautta linjan.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Työpaikan valintaa koskevan päätöksen tekeminen TeamConnectissa
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Oikean asetuksen valitseminen Työpaikka-valikosta räätälöi TeamConnectin heijastamaan tarkasti nykyistä työtilaasi. Vie hiiri infokuvakkeen päälle saadaksesi yksityiskohtaisen selityksen kustakin vaihtoehdosta:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Break:</Body1Strong> Merkitsee aikaa pois työstä, oli kyseessä sitten tauko tai työpäivän päättäminen.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office/Field-Office:</Body1Strong> Osoittaa läsnäoloa yrityksen hallinnoimassa työtilassa.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> Työskentely omistetussa kotitoimistossa.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Public-Area:</Body1Strong> Työskentely toimistoympäristön ulkopuolella, vihjaen joustavan viestinnän tarpeeseen.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Customer Workplace/Private Office:</Body1Strong> Asiakkaan luona paikan päällä, huomioon ottaen keskustelujen yksityisyystasot.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Commute/Business-Travel/Training:</Body1Strong> Liikkeellä ollessa tai osallistuessa erityisiin työhön liittyviin aktiviteetteihin toimiston ulkopuolella.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Public-Holiday/Vacation/Various Leaves:</Body1Strong> Virallisesti vapaalla henkilökohtaisista, terveydellisistä tai lomasyistä johtuen.</li>
                  </ul>
                  Sopivan työpaikan asetuksen valinta varmistaa, että tiimilläsi on tarvittava konteksti vuorovaikutuksiin, kunnioittaen saatavuutta ja yksityisyyttä.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. 'Checked-In' -tilan navigointi TeamConnectissa
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  'Checked-In' -merkintä Työntekijähakemistossa on suunniteltu näyttämään kaikki työssä olevat työntekijät. Tämä näkyvyys määräytyy tekemäsi Työpaikan valinnan perusteella:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Active Work Environments:</Body1Strong> Valinnat Office, Field-Office, Home-Office, Remote-Workplace, Public-Area, Customer-Workplace,
                    Customer Private-Office, Commute, Business-Travel ja Training ilmaisevat, että olet työssä kytkeytynyt, tehden sinusta 'Checked-In' ja näkyvän kollegoillesi.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Non-Work Settings:</Body1Strong> Valitsemalla Checked-Out, Break, Public-Holiday, Vacation, On-Leave, Maternity-Leave tai Sick-Leave 
                      -tilat poistat itsesi 'Checked-In' -listalta, asettaen selkeät odotukset saatavuudelle ja vastausajoille.
                    </li>
                  </ul>
                  Tämä toiminnallisuus auttaa erottamaan työajat henkilökohtaisesta ajasta, edistäen tehokasta tiimityöskentelyä ja viestintää.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Tietojenhallinta ja yksityisyys - UKK</Subtitle2>
          <Body1>
            <p>
              Nykyaikaisessa digitaalisessa maailmassa on olennaista ymmärtää, kuinka tietojasi hallinnoidaan. TeamConnect sitoutuu avoimuuteen ja vahvoihin tietosuojakäytäntöihin. 
              Alta löydät tietoa siitä, kuinka käsittelemme, säilytämme ja suojaamme tietojasi TeamConnectissa, varmistaen, että digitaalinen työtilasi on turvallinen ja yhdenmukainen.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Kuinka TeamConnect suojaa henkilötietojani?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect omistautuu henkilötietojesi suojelemiseen kattavalla turvallisuusstrategialla:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Single Sign-On (SSO):</Body1Strong> Turvallisuuden parantamiseksi ja käyttäjäkokemuksen virtaviivaistamiseksi TeamConnect integroi automatisoidun 
                      Single Sign-On (SSO) -järjestelmän Microsoft 365 -tilisi kanssa, helpottaen turvallista ja vaivatonta käyttöoikeuksien hallintaa.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Salaus:</Body1Strong> Varmistamme, että kaikki TeamConnectissa olevat tiedot on salattu sekä siirron aikana että lepotilassa, 
                      tehden niistä saavuttamattomia luvattomille osapuolille.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Tietojen minimointi:</Body1Strong> Tietojen keräämistemme rajoittuu vain välttämättömiin TeamConnectin toiminnallisuutta varten. 
                      Ainoa säilyttämämme käyttäjätieto on käyttäjän pääsähköpostiosoite (UPN) Azure Entra ID:stä, joka saattaa muistuttaa yrityssähköpostiosoitettasi.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Rajoitettu pääsy:</Body1Strong> Pääsy UPN-tietoihin on rajoitettu organisaatiosi tilausylläpidon hallinnoijalle tilausportaalissamme, 
                      varmistaen, että arkaluonteiset tiedot ovat vain valtuutetun henkilöstön nähtävillä.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Prompt Data Deletion:</Body1Strong> TeamConnect on rakennettu välittömästi poistamaan tallennettu UPN kolmessa tietyssä tilanteessa: 
                      jos tilaus peruutetaan tilausylläpidon hallinnoijan toimesta, jos käyttäjä poistetaan TeamConnectin HR:n toimesta, tai jos TeamConnectin 
                      tilaus vanhenee. Tämä varmistaa, että henkilötietojasi ei säilytetä pidempään kuin niiden hyödyllinen elinkaari tai tarve edellyttää.
                    </li>                      
                  </ul>
                  <p>Näiden toimenpiteiden avulla TeamConnect sitoutuu ylläpitämään korkeimpia tietosuojastandardeja, varmistaen, että henkilökohtaiset tietosi pysyvät turvattuina ja luottamuksellisina.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Mietityttääkö, missä tietosi sijaitsevat TeamConnectin kanssa?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect rikkoo perinteisten SaaS-tarjouksien muotin varmistamalla, että tietosi eivät koskaan poistu organisaatiostasi.
                  Toisin kuin tavanomainen käytäntö tallentaa tietoja ulkoisille pilvipalvelimille, TeamConnect käyttää nerokkaasti organisaatiosi
                  Microsoft 365 SharePoint Online -palvelua kaikkien sovellustietojen säilyttämiseen. Tämä innovatiivinen lähestymistapa paitsi edistää tietojen suvereniteettia, myös vahvistaa turvallisuutta,
                  ja se on täysin linjassa sitoutumisemme kanssa suojata yksityisyyttäsi ja noudattaa tiukkoja vaatimustenmukaisuusstandardeja.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Jaetaanko tietoni kolmansien osapuolien kanssa?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ei, tietoja ei jaeta kolmansille osapuolille! Cubeet on vankkumaton sitoutumisessamme tietojesi yksityisyyteen ja turvallisuuteen. Emme jaa mitään TeamConnect-tietoja kolmansille osapuolille ilman asiakkaidemme nimenomaista suostumusta.
                  Turvallisessa ympäristössämme säilytämme vain välttämättömiä tunnisteita, kuten Käyttäjäpääasiallisen Nimen (UPN), organisaation nimen, vuokraajan nimen ja vuokraajan ID:n subscription-portaalissamme.
                  Tämä tieto on olennaista palvelun tarjoamiseksi ja se on suojattu tiukkojen tietosuojakäytäntöjemme mukaisesti.
                  Voit olla varma, että tietojenhallintakäytäntömme on suunniteltu varmistamaan, että tietosi pysyvät luottamuksellisina, ja läpinäkyvyys ja kontrolle ovat tiukasti sinun käsissäsi.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Kuinka TeamConnect noudattaa tietosuojasäännöksiä kuten GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect noudattaa tietosuojasäännöksiä, kuten GDPR, HIPAA, CCPA ja muita, hyödyntämällä Microsoft 365 -ympäristön sisäänrakennettuja vaatimustenmukaisuusominaisuuksia,
                  joissa kaikki asiakastiedot säilytetään turvallisesti. Toisin kuin perinteiset SaaS-mallit, TeamConnect ei säilytä asiakastietoja palvelimillaan; sen sijaan se toimii organisaatiosi
                  Microsoft 365 -ympäristössä noudattaen sen vankkoja turvallisuus- ja vaatimustenmukaisuusstandardeja. Tämä lähestymistapa varmistaa, että tietohallintasi pysyy johdonmukaisena
                  Microsoft 365:n kattavan vaatimustenmukaisuuskehyksen kanssa, mukaan lukien noudattaminen GDPR:ää, HIPAA:ta terveystietojen osalta, CCPA:ta Kalifornian asukkaille,
                  ja muita globaaleja tietosuojasäännöksiä. Integroimalla saumattomasti olemassa olevaan Microsoft 365 -asetukseesi, TeamConnect mahdollistaa organisaatiosi säilyttää
                  täydellisen kontrollin tietojesi yli varmistaen noudattamisen tiukimpien tietosuojalakien ja -säännösten kanssa.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Kuinka TeamConnect käsittelee tietoja Microsoft Teamsin chat-keskusteluista, puheluista, videopuheluista, Outlook-sähköposteista ja puhelinjärjestelmän puheluista?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect hyödyntää Microsoft Graph API:a mahdollistaakseen suorat vuorovaikutukset kuten chatit, puhelut, videopuhelut ja Outlook-sähköpostit Microsoft Teamsissa,
                  tarjoten saumattoman käyttäjäkokemuksen. Tärkeänä seikkana, vaikka TeamConnect käynnistää nämä kommunikaatiot, se tekee sen ilman että sekaantuu viestintäprosessiin
                  tai kykenee tallentamaan tai varastoimaan mitään vuorovaikutuksen sisältöä. Kaikki keskustelut, puhelut ja sähköpostit tapahtuvat ja niitä hallinnoidaan suoraan Microsoft Teamsin ja 
                  Outlookin turvallisissa rajoissa, tiukasti noudattaen Microsoftin 365 vankan turvallisuuden ja yksityisyyden standardeja. Tämä varmistaa, että TeamConnect parantaa
                  viestintäkykyjäsi samalla kun se täysin säilyttää tietojesi luottamuksellisuuden ja eheyden.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Lisätietoja tietokäytännöistämme ja käytännöistämme saat konsultoimalla {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>TeamConnect Käyttäjäopas</Body1Strong></Link> tai organisaatiosi IT-osastoa 
              osoitteessa <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> kyseisen asetukseen liittyvien yksityiskohtien osalta.
          </p>
        </Body1>        
        <p></p>  
      <Subtitle1 className={styles.fontColor}>Kuuntelemme: Jaa Palautteesi ja Ideasi</Subtitle1>
        <Body1>
          <p>
            Näkemyksesi ohjaavat TeamConnectin kehitystä. Oli mielessäsi sitten loistava ominaisuusidea tai palautetta nykyisestä kokemuksestasi, olemme kaikki korvat. Panoksesi on korvaamaton jatkuvassa pyrkimyksessämme tarjota 
            parasta mahdollista palvelua.
          </p>
        </Body1>      
      <Subtitle2>Palaute ja Ominaisuusehdotukset</Subtitle2>
        <Body1>
          <p>
            Valmiina jakamaan? Siirry {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Palautesivulle
            </Link>
            . Olipa kyseessä uuden ominaisuuden ehdotus tai palaute olemassa olevista toiminnoista, valitse vain 
            sopiva vaihtoehto pudotusvalikosta lomakkeessa. Panoksesi auttavat muokkaamaan TeamConnectin tulevaisuutta, 
            varmistamaan, että se täyttää ja ylittää tarpeesi.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
