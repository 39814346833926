import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});
function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>
        Centar za pomoć i Često postavljana pitanja TeamConnecta
      </Title3>
      <p>
        <Body1>
          Dobrodošli u Centar za pomoć TeamConnecta, osmišljen da podrži vaše
          iskustvo sa našom sveobuhvatnom korporativnom aplikacijom za
          direktorijum. Ovde ćete pronaći resurse za optimizaciju vašeg
          korišćenja TeamConnecta unutar Microsoft Teams-a, poboljšavajući
          komunikaciju širom vaše mreže.
        </Body1>
      </p>
      <Subtitle2>Brzi vodič za upotrebu</Subtitle2>
      <ul>
        <Body1>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Poboljšane mogućnosti pretrage: </Body1Strong>
            Brzo pronađite prave kolege, odeljenja i bitne kontakte pomoću naših
            snažnih alata za pretragu.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Vizuelni organizacioni dijagrami: </Body1Strong>
            Navigirajte hijerarhijom vaše organizacije i razumejte linije
            izveštavanja s lakoćom putem intuitivnih organizacionih dijagrama.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Adaptivni alati za današnje potrebe: </Body1Strong>
            Koristite moderne funkcionalnosti prilagođene zahtevima savremenih
            radnih mesta.
          </li>
        </Body1>
      </ul>
      <p></p>
      <Subtitle2>Optimizacija vašeg iskustva</Subtitle2>
      <Body1>
        <p>
          TeamConnect revolucionariše način na koji interagujete unutar vaše
          organizacione strukture, pretvarajući svakodnevnu komunikaciju u
          strateške angažmane.
        </p>
      </Body1>
      <p></p>
      <Subtitle1 className={styles.fontColor}>
        Pomoć i Često postavljana pitanja
      </Subtitle1>
      <Body1>
        <p>
          Pronađite odgovore na česta pitanja o funkcijama i podešavanjima
          TeamConnecta u ovom posvećenom odeljku. Bilo da navigirate kroz
          aplikaciju, prilagođavate podešavanja ili tražite rešenja za izazove,
          naš Centar za pomoć je ovde da vam efikasno pomogne.
        </p>
      </Body1>
      <Body1>
        <p>
          Za dublju podršku ili specifična uputstva, molimo konsultujte{" "}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>
              Vodič za korisnike TeamConnecta
            </Body1Strong>
          </Link>
          .
        </p>
      </Body1>
      <p></p>
      <Subtitle2>Korišćenje TeamConnect FAQ</Subtitle2>
      <p></p>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="1">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                1. Ne možete naći TeamConnect u bočnoj traci Microsoft Teamsa?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ako TeamConnect nije odmah vidljiv ili je nestao iz vaše bočne
                trake Microsoft Teamsa, možete ga lako zakačiti za brzi pristup.
                Samo pratite ove jednostavne korake:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Otvorite Microsoft Teams i kliknite na '...' (+ aplikacije)
                    ikonu na dnu bočne trake.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Pretražite 'TeamConnect' u pretrazi na vrhu pojavnog
                    prozora.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Desnim klikom kliknite na aplikaciju TeamConnect iz
                    rezultata pretrage i izaberite 'Zakači'.
                  </li>
                </ol>
                TeamConnect će sada biti zakačen za vašu bočnu traku, čineći ga
                dostupnim samo jednim klikom!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="2">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                2. Nailazite na tehničke probleme sa TeamConnect? Evo kako da
                dobijete pomoć.
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ako naiđete na bilo koje tehničke probleme sa TeamConnect unutar
                Microsoft Teamsa, tim za IT podršku vaše organizacije je tu da
                vam pomogne. Obratite im se na{" "}
                <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>
                , i oni će vam brzo pomoći.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="3">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                3. Ne možete pronaći podatke o svom zaposlenju?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ako vam profil izgleda pomalo prazno samo sa vašim imenom i
                e-mail adresom, vrlo je moguće da vaš HR tim još nije dodao
                ostale vaše detalje o zaposlenju u TeamConnect. Nema razloga za
                brigu! Kratka poruka vašem HR timu za podršku pokrenuće stvari.
                Oni su spremni da popune vaš profil sa svim važnim detaljima.
                Obratite se na{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>{" "}
                i gledajte kako vaš profil oživljava!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="4">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                4. Koje opcije jezika su dostupne u TeamConnect-u?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect podržava globalnu raznolikost Microsoft Teams-a
                podržavajući sve njegove jezike. Bilo da preferirate arapski ili
                grčki, japanski ili švedski, imate svet opcija na dohvat ruke,
                uključujući:
              </Body1>
              <ul>
                <li>
                  <Body1>
                    Arapski, bugarski, kineski (pojednostavljeni i
                    tradicionalni), hrvatski, češki, danski, holandski,
                    engleski, estonski, finski, francuski, nemački, grčki,
                    hebrejski, mađarski, indonezijski, italijanski, japanski,
                    korejski, letski, litvanski, norveški (Bokmal), poljski,
                    portugalski (Brazil i Portugal), rumunski, ruski, srpski
                    (latinica), slovački, slovenački, španski, švedski,
                    tajlandski, turski, ukrajinski, vijetnamski.
                  </Body1>
                </li>
              </ul>
              <Body1>
                Da prilagodite svoje TeamConnect iskustvo prema željenom jeziku,
                molimo podesite jezička podešavanja direktno u Microsoft Teams.
                TeamConnect je potpuno integrisan s ovim podešavanjima:
              </Body1>
              <ol>
                <Body1>
                  <li className={styles.bulletBottomMargin}>
                    Idite na svoja Microsoft Teams podešavanja klikom na tri
                    tačke pored vaše profilne slike na vrhu, a zatim izaberite
                    'Izgled i pristupačnost'.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Skrolujte do sekcije 'Jezik' i iz padajućeg menija izaberite
                    željeni jezik.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Potvrdite svoje promene klikom na dugme 'Sačuvaj i ponovo
                    pokreni' kako biste primenili nove jezičke podešavanja.
                  </li>
                </Body1>
              </ol>
              <Body1Strong>Napomena: </Body1Strong>
              <Body1>
                Promene koje napravite na jezičkim podešavanjima u Microsoft
                Teams-u će se besprekorno ažurirati i na vašem TeamConnect
                iskustvu. Imajte na umu da promena jezika u Teams-u može takođe
                prilagoditi vaš format vremena i datuma. Trenutno, TeamConnect
                evoluira ka podršci pisanja s desna na levo. Uzbuđeni smo zbog
                ovih poboljšanja i obavestićemo vas kako postanu dostupna.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="5">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                5. Kako mogu promeniti temu u TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect se sinhronizuje sa vašim preferencama teme u
                Microsoft Teams, uključujući Svetlu, Tamnu i opcije Visokog
                kontrasta. Da prilagodite svoju temu:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Pristupite podešavanjima Microsoft Teams-a klikom na tri
                    tačke pored vaše profilne slike na vrhu.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Izaberite 'Izgled i pristupačnost' da istražite opcije tema.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Izaberite željenu temu iz dostupnih opcija da odmah
                    ažurirate svoj TeamConnect interfejs da se podudara.
                  </li>
                </ol>
                Vaš izbor teme u Microsoft Teams-u će automatski biti primenjen
                na TeamConnect, obezbeđujući konzistentno vizualno iskustvo kroz
                vaš radni prostor.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="6">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                6. Istraživanje dugmadi za kontakt akcije u TeamConnect-u
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Gde god u TeamConnect-u pronađete kontakt detalje zaposlenog,
                takođe ćete otkriti naše praktične dugmade za brzi pristup. Ovi
                korisni alati su dizajnirani da olakšaju vašu komunikaciju
                unutar Microsoft ekosistema, osiguravajući da ste uvek samo
                jedan klik daleko od povezivanja. Evo vašeg sveobuhvatnog vodiča
                za efikasno korišćenje ovih dugmadi:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ikonica za četovanje:</Body1Strong> Kliknite da
                    pokrenete instant Microsoft Teams čet. Ako treba da se
                    vratite u TeamConnect nakon početka četa, jednostavno
                    koristite strelicu nazad u Desktop aplikaciji, dugme nazad
                    na mobilnoj aplikaciji ili dugme za povratak vašeg
                    pretraživača.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ikonica za email:</Body1Strong> Otvora novi
                    nacrt mejla u Outlook-u sa spremljenom adresom primaoca u
                    polju "Za:". Ako Outlook ne pokrene, vredi proveriti sa
                    vašim IT odeljenjem za bilo kakva ograničenja.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ikonica za video poziv:</Body1Strong> Pokreće
                    licem u lice video poziv koristeći Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ikonica za poziv:</Body1Strong> Pokreće
                    glasovni poziv u Microsoft Teams-u, čineći brze diskusije
                    glatkim.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Ikonica za organizacioni grafikon:
                    </Body1Strong>{" "}
                    Otkriva mesto zaposlenog unutar hijerarhije organizacije,
                    uključujući njihovog menadžera i direktno izveštavanje.
                  </li>
                  <li>
                    <Body1Strong>Još (...) Ikonica:</Body1Strong> Otkriva dalje
                    opcije:
                    <ul>
                      <li
                        style={{ marginTop: "8px" }}
                        className={styles.bulletBottomMargin}
                      >
                        <Body1Strong>Odmah se sastanite:</Body1Strong> Otvara
                        Microsoft Teams da odmah započnete sastanak sa
                        zaposlenim.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Novi sastanak:</Body1Strong> Usmjerava vas
                        na Microsoft Teams da zakazujete novi sastanak.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Dodaj kontakt:</Body1Strong> Dodaje kontakt
                        detalje zaposlenog iz TeamConnect-a u vašu novu
                        aplikaciju Microsoft Teams People, obogaćujući vašu
                        funkciju poziva.
                      </li>
                    </ul>
                  </li>
                </ul>
                Ovi intuitivni dugmadi su osmišljeni kako bi osigurali da možete
                efikasno da se povežete sa kolegama, bilo da je to za brzi čet,
                email ili da zakazujete buduće sastanke.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="7">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                7. Pozivanje preko TeamConnect-a
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Želite da obavite poziv? TeamConnect olakšava vašu komunikaciju
                integrisanjem sa Microsoft Teams. Jednostavno kliknite na
                željeni telefonski broj u TeamConnect-u, i eto, interfejs za
                pozivanje Microsoft Teams-a se pojavljuje, spreman da
                besprekorno uspostavi poziv.
                <p>
                  <Body1Strong>Napomena: </Body1Strong>
                  Imate problema sa pravljenjem poziva? To može biti zbog IT
                  politika vaše organizacije ili korišćenja telefonskog sistema
                  različitog od Microsoft Teams Phone System. Provera sa vašim
                  IT odeljenjem na{" "}
                  <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>
                      {itSupport}
                    </Body1Strong>
                  </Link>
                  , može rasvetliti stvar.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="8">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                8. Da li postoje troškovi povezani sa pozivanjem preko
                TeamConnect-a?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect poboljšava vaše iskustvo pozivanja korišćenjem
                Microsoft Teams-a. Evo šta treba da znate o troškovima vezanim
                za pozive:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    Za pozive na radne telefone zaposlenih, telefone odeljenja
                    ili hitne linije unutar vaše organizacije (unutrašnji
                    brojevi), obično <Body1Strong>nema troškova</Body1Strong>{" "}
                    kada se koristi Microsoft Teams Phone System.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Za spoljne pozive, kao što su oni na mobilne telefone ili
                    spoljne linije, primenjuju se{" "}
                    <Body1Strong>standardne tarife</Body1Strong> telefonskog
                    sistema vaše organizacije. Ove tarife variraju u zavisnosti
                    od vašeg specifičnog servisnog plana sa Microsoft Teams-om.
                  </li>
                </ul>
                <p>
                  <Body1Strong>Napomena:</Body1Strong> Ako naiđete na bilo kakve
                  probleme ili imate pitanja o troškovima poziva, najbolje je da
                  se konsultujete sa vašim IT odeljenjem na{" "}
                  <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>
                      {itSupport}
                    </Body1Strong>
                  </Link>{" "}
                  za detaljne informacije prilagođene setupu i politikama vaše
                  organizacije.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="9">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                9. Primetili ste neslaganje sa vašim statusom 'Van kancelarije'
                u TeamConnect-u?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Primetili ste da vaš status prisutnosti 'Van kancelarije' iz
                Microsoft 365 nije pravilno prikazan u TeamConnect-u? Mi smo se
                pobrinuli za to. Funkcija za sinhronizaciju statusa prisutnosti
                'Van kancelarije' je već implementirana u TeamConnect-u.
                Trenutno čekamo na Microsoft da uvede ovu funkciju. Kada se to
                desi, vaš status prisutnosti 'Van kancelarije' će tačno biti
                odražen u TeamConnect-u, usklađen sa indikatorima prisutnosti
                Microsoft Teams-a za besprekoran doživljaj.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Često postavljana pitanja o imeniku zaposlenih</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Uvod u imenik zaposlenih:</Body1Strong> Navigirajte
          profesionalnim pejzažem vaše organizacije sa imenikom zaposlenih,
          vašim centralnim čvorom za detaljan uvid u zaposlene, dinamiku na
          radnom mestu i direktan kontakt sa profilima i menadžerskim odnosima
          vaših kolega.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="10">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                10. Potrebno je dodati, ažurirati ili ispraviti vaše podatke
                zaposlenog?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Za dodavanje, ažuriranje ili ispravku vaših podataka zaposlenog
                u TeamConnect-u, molimo vas da se obratite timu za podršku
                ljudskih resursa vaše organizacije. Oni su spremni da pomognu na{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>
                .
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="11">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                11. Šta se dešava kada kliknem na ime zaposlenog u imeniku?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Klik na ime zaposlenog u imeniku TeamConnect otvara detaljnu
                profilnu stranicu te osobe. Ovaj profil pruža sveobuhvatne uvide
                o zaposlenom, uključujući, ali ne ograničavajući se na, njihovu
                radnu poziciju, odeljenje, lokaciju kancelarije, kontakt
                informacije i više. Dizajniran je da vam pruži potpuni pregled
                profesionalne uloge zaposlenog i kako se uklapaju u
                organizaciju, čineći lakšim za vas da razumete njihove
                odgovornosti i kako biste mogli sarađivati s njima.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="12">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                12. Koje informacije su uključene u profil zaposlenog?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Svaki profil zaposlenog u TeamConnect je sveobuhvatan pregled,
                koji nudi bogatstvo uvida u profesionalni život pojedinca. Evo
                šta ćete pronaći:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Osnovni detalji:</Body1Strong> Ime, radna
                    pozicija, odeljenje i lokacija podružnice.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Struktura izvještavanja:</Body1Strong>{" "}
                    Informacije o direktnim i funkcionalnim izvještajima,
                    uključujući linijalne i funkcionalne menadžere.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Status na radnom mjestu:</Body1Strong> Trenutni
                    radni status kao što su Na-odsustvu, Putovanje,
                    Poslovno-putovanje itd.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Radno mesto & Lokalno vreme:</Body1Strong>{" "}
                    Trenutna radna lokacija i lokalna vremenska zona zaposlenog.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Contact Details:</Body1Strong> Poslovni
                    telefon, mobilni broj i e-mail adresa.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Adresa kancelarije:</Body1Strong> Kompletna
                    lokacija kancelarije uključujući naziv zgrade, adresu i
                    Google mapu.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>O zaposlenom:</Body1Strong> Kratki lični uvod
                    ili profesionalna biografija.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Odnosi unutar kompanije:</Body1Strong> Detalji
                    o izveštajnoj strukturi i odnosima unutar tima.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Socijalne veze & Jezici:</Body1Strong> Linkovi
                    ka profesionalnim socijalnim profilima i jezičke
                    sposobnosti.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Veštine:</Body1Strong> Lista profesionalnih
                    veština i oblasti stručnosti.
                  </li>
                </ul>
                Dodatno, profil nudi opcije za povezivanje putem chata, e-maila,
                video poziva ili zakazivanje sastanaka, zajedno s brzim
                pristupom organizacionom dijagramu za širi kontekst tima.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="13">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                13. Kako mogu ažurirati informacije na mom profilu?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Personalizacija vašeg profila je jednostavna u TeamConnect-u.
                Evo kako to možete uraditi:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Navigirajte do kartice <Body1Strong>Zaposleni</Body1Strong>{" "}
                    i kliknite na svoj <Body1Strong>Avatar</Body1Strong> da
                    otvorite svoj profil.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    U gornjem levom uglu naći ćete link{" "}
                    <Body1Strong>"Edituj svoj profil"</Body1Strong>. Klikom na
                    to otvoriće se dijalog za uređivanje vašeg profila.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Ovde možete poboljšati svoj profil dodavanjem ili
                    ažuriranjem sekcije <Body1Strong>O Vama</Body1Strong>,
                    linkova društvenih mreža i telefonskog broja. To olakšava
                    vašim kolegama da se povežu sa vama ili vas prate.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Ne zaboravite pritisnuti dugme{" "}
                    <Body1Strong>Sačuvaj</Body1Strong> kako biste primenili
                    svoje promene.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Skrolujte nadole u dijalogu da dodate svoje{" "}
                    <Body1Strong>Jezike</Body1Strong> i{" "}
                    <Body1Strong>Proficijenciju</Body1Strong>, kao i vaše{" "}
                    <Body1Strong>Veštine</Body1Strong>. Deljenje ove informacije
                    pomaže vašim kolegama da razumeju vaše oblasti ekspertize i
                    jezike na kojima možete komunicirati.
                  </li>
                </ol>
                Ažuriranjem vašeg profila ne samo da držite svoje informacije
                ažurnim, već i podstičete bolje konekcije unutar vaše
                organizacije.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="14">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                14. Ažuriranje radnog mesta, radne lokacije i vremenske zone u
                TeamConnect-u
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ažuriranje informacija o radnom mestu, radnoj lokaciji i
                vremenskoj zoni je ključno za nesmetanu saradnju u Microsoft
                Teams-u. Ova ažuriranja su odmah vidljiva na vašem TeamConnect
                profilu i listinzima, omogućavajući kolegama da brzo razumeju
                vašu radnu lokaciju i najbolje vreme za kontakt sa vama, što
                poboljšava timski rad u različitim regionima i vremenskim
                zonama.
                <p></p>
                Spremni ste za ažuriranje svojih detalja? To je lako u
                TeamConnect-u! Idite na 'Postavke' u gornjem meniju i
                navigirajte do 'Opšte postavke'. Ovde možete lako ažurirati
                svoje Radno mesto i Vremensku zonu, i odrediti svoju Radnu
                lokaciju za dan. Ne zaboravite sačuvati vaše promene kako bi svi
                bili informisani i kako bi saradnja bila efikasna bez obzira na
                vašu lokaciju!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="15">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                15. Primetili ste nešto neobično u profilu kolege?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ako naiđete na bilo kakve detalje u profilu kolege koji se čine
                neobičnim, najbolji prvi korak je da im ljubazno skrenete
                pažnju. Brz razgovor ili email bi trebalo da reše problem! Ako
                iz nekog razloga informacije ne budu ažurirane, ne brinite—samo
                kontakrirajte vaš HR podršku na{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>
                , i oni će rado intervenisati i rešiti stvar.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="16">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                16. Razumevanje grupa oznaka: Sve, Omiljeni, Prisutni & Novi
                zaposleni
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Sve:</Body1Strong> Ova podrazumevana oznaka
                    prikazuje sve zaposlene u vašoj organizaciji, nudeći
                    sveobuhvatan pregled tima.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Omiljeni:</Body1Strong> Kliknite ovde da
                    filtrirate prikaz na kolege koje ste označili kao
                    'Omiljene', što olakšava brze kontakte sa ključnim osobama.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Prisutni:</Body1Strong> Ova oznaka pokazuje
                    zaposlene koji su aktivno na poslu, razlikujući ih od samo
                    online prisustva u Microsoft Teams. Kada se prijave,
                    zaposleni signaliziraju da su na poslu i dele svoju trenutnu
                    lokaciju rada tokom dana. Ovo može uključivati različite
                    radne prostore kao što su Kancelarija, Terenska kancelarija,
                    Dom-kancelarija, Udaljeni radni prostor, Kancelarija kod
                    klijenta, Privatna kancelarija kod klijenta, Javna površina,
                    Putovanje, Službeno putovanje ili čak Obuka. Stoga, status
                    'Prisutni' pruža jasan pokazatelj aktivnog rada, nudeći
                    kolegama precizno razumevanje trenutnog radnog konteksta i
                    dostupnosti svakog pojedinca.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Novi zaposleni:</Body1Strong> Prepoznajte nova
                    lica u TeamConnect sa ovom oznakom, ističući zaposlene koji
                    su dodati u aplikaciju u poslednjih 30 dana. Ovo obično
                    odgovara nedavnim pridruživanjima vašoj organizaciji,
                    pomažući vam da dočekate nove članove tima.
                  </li>
                </ul>
                Ove grupe oznaka su dizajnirane da olakšaju navigaciju i
                poboljšaju vaše iskustvo u TeamConnect, omogućavajući vam da
                uvek brzo pronađete i povežete se sa kolegama.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="17">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                17. Dekodiranje ikona pored polja za pretragu
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Direktorijum zaposlenih TeamConnect je dizajniran za svestranost
                i jednostavnost, sa četiri ikone koje nude različite prikaze i
                brz pristup ključnim detaljima. Evo šta svaka ikona otkriva:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Prikaz liste:</Body1Strong> Vaš osnovni prikaz,
                    koji predstavlja sve članove organizacije na jednostavan
                    način.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Mali prikaz kartice:</Body1Strong> Kompaktna
                    alternativa, ovaj prikaz transformiše listu u male kartice
                    zaposlenih.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Veliki prikaz kartice:</Body1Strong> Odaberite
                    detaljniji prikaz s većim karticama, koje uključuju dodatne
                    opcije kontaktiranja poput telefonskih i mobilnih brojeva za
                    lako biranje.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Organizacioni dijagram:</Body1Strong>{" "}
                    Vizualizujte strukturu kompanije sa prikazom organizacionog
                    dijagrama, idealno za razumevanje izveštajnih odnosa i
                    navigaciju kroz organizacioni pejzaž.
                  </li>
                </ol>
                Prebacujte se između ovih ikona kako biste pronašli prikaz koji
                najbolje odgovara vašim trenutnim potrebama, bilo da tražите
                brze informacije ili dublje uvide.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="18">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                18. Gde treba da tražim za brzo pretraživanje kolega?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Tražite kolegu? Samo se uputite do polja za pretragu
                TeamConnect, koje se nalazi u gornjem desnom uglu pored ikone
                organizacionog dijagrama. Počnite da kucate njihovo ime i
                posmatrajte kako se rezultati preciziraju sa svakim slovom.
                Zapamtite, za pretrage u TeamConnect, preskočite traku za
                pretragu Microsoft Teams na vrhu – naš posebni okvir za pretragu
                je pravi način!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="19">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                19. Како функционише алфабетска претрага за проналажење
                запослених?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Директоријум запослених TeamConnect укључује корисну алфабетску
                функцију претраге која вам помаже да брзо лоцирате запослене по
                њиховом имену. Само кликните на слово из алфабета приказаног на
                врху директоријума, и TeamConnect ће одмах приказати све
                запослене чија имена почињу с тим словом. Да бисте се вратили на
                потпуну листу запослених, само деселектујте истакнуто слово. Ова
                интуитивна функција олакшава навигацију кроз велике листе
                запослених, осигуравајући да брзо и ефикасно пронађете колеге.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="20">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                20. Како могу прилагодити број запослених приказаних по
                страници?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect вам омогућава да прилагодите колико профила
                запослених видите по страници, чинећи лакшим кретање кроз
                Директоријум запослених. На дну сваке странице налазе се опције
                за пагинацију: 10, 50, 100, 500, 1000 и 5000. Подразумевана
                поставка показује 10 профила по страници. Да бисте видели више
                профила на једној страници, само изаберите жељени број из ових
                опција. Ова функција пружа флексибилност у начину на који
                прегледате директоријум, омогућавајући вам да прилагодите приказ
                вашим специфичним потребама или преференцама.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="21">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                21. Како могу сортирати информације о запосленима у приказу
                листе?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                У Приказу листе Директоријума запослених TeamConnect, имате
                могућност да сортирате информације о запосленима према вашим
                потребама. Колоне—Име запосленог, Назив радног места, Одељење,
                Локација канцеларије и Радно место—су све сортирајуће. Само
                кликните на заглавље колоне по којој желите да сортирате. Један
                клик ће сортирати колону у растућем редоследу, а поновни клик ће
                је сортирати у опадајућем редоследу. Подразумевано, директоријум
                је сортиран по Име запосленог у растућем редоследу, на основу
                првог имена. Ова функција сортирања вам помаже да брзо
                организујете и видите детаље о запосленима на начин који најбоље
                служи вашој сврси, било да тражите одређену особу или требате да
                групишете запослене по одређеном критеријуму као што су одељење
                или локација.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Честа питања у вези са директоријумом одељења</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Увод у директоријум одељења:</Body1Strong> Директоријум
          одељења је ваша капија за разумевање сложене мреже структуре ваше
          организације, која нуди јасан приказ хијерархија унутар одељења,
          руководство и могућности за беспрекидну комуникацију између одељења.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="22">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                22. Како функционишу ознаке 'Сви', 'Фаворити' и 'Ваше одељење' у
                директоријуму одељења?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                У Директоријуму одељења TeamConnect, ознаке вам помажу да брзо
                навигирате и филтрирате информације о одељењима. Ево шта свака
                ознака представља:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Сви:</Body1Strong> Ова ознака приказује сва
                    одељења у вашој организацији, пружајући свеобухватан
                    преглед.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Фаворити:</Body1Strong> Користите ову ознаку да
                    видите одабрану листу одељења која сте означили као
                    'Фаворите', олакшавајући приступ одељењима са којима често
                    интерагујете.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ваше одељење:</Body1Strong> Ова ознака филтрира
                    приказ тако да приказује само ваше одељење, омогућавајући
                    брз приступ информацијама и ажурирањима вашег одељења.
                  </li>
                </ul>
                Ове ознаке су дизајниране да унапреде вашу навигацију унутар
                директоријума одељења, побољшавајући вашу способност да ефикасно
                пронађете и ангажујете се са информацијама о одељењима.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="23">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                23. Разумевање приказа директоријума одељења у TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Навигација кроз Директоријум одељења у TeamConnect је олакшана
                са више опција приказа које одговарају вашим преференцијама.
                Свака опција нуди јединствен начин за истраживање опсежних
                информација о одељењима, укључујући име одељења, менаџера
                одељења, надређено одељење, телефон одељења, хитну линију
                одељења и е-пошту одељења. Ево прегледа доступних приказа:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Приказ листе:</Body1Strong> Подразумевана
                    подешавања која уредно наводе сва одељења, пружајући јасан,
                    сажет преглед.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Приказ великих картица:</Body1Strong> Пребаците
                    се на овај приказ да видите детаљне картице одељења, које
                    нуде проширени преглед кључних информација о сваком одељењу.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Приказ организационе шеме:</Body1Strong> За
                    визуелни приказ структуре и односа унутар одељења, приказ
                    организационе шеме илустратира како се свако одељење уклапа
                    у ширу хијерархију организације.
                  </li>
                </ul>
                Да бисте променили ове приказе, једноставно користите иконе
                поред поља за претрагу на врху директоријума одељења. Сваки
                приказ је дизајниран да вам помогне да приступите и разумете
                детаље о одељењима с једноставношћу, осигуравајући да пронађете
                информације које вам требају у формату који вам најбоље
                одговара.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="24">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                24. Kako koristiti pretraživač u Direktorijumu odeljenja
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Jednostavno locirajte bilo koje odeljenje unutar TeamConnect-a
                koristeći namenski pretraživač koji se nalazi odmah pored ikona
                za prikaz na vrhu Direktorijuma odeljenja. Samo počnite da
                kucate ime odeljenja koje tražite, i posmatrajte kako se
                direktorijum precizira prikazujući samo relevantne poklapanja.
                Ova funkcija je posebno dizajnirana da vam pomogne da izbegnete
                potrebu za ručnim pretraživanjem celog direktorijuma, dovodeći
                potrebno odeljenje direktno do vaših prstiju. Zapamtite, ova
                funkcionalnost pretrage je jedinstvena za Direktorijum odeljenja
                TeamConnect-a, tako da obavezno koristite ovaj pretraživač
                umesto glavnog pretraživača Microsoft Teams na samom vrhu
                interfejsa Teams-a za upite specifične za odeljenje.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="25">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                25. Kako funkcioniše abecedni filter u Direktorijumu odeljenja?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ubrzajte svoju pretragu u Direktorijumu odeljenja pomoću
                abecednog filtera koji se nalazi na vrhu. Ova funkcija vam
                omogućava da brzo suzite odeljenja tako što ćete odabrati slovo.
                Kada izaberete slovo, TeamConnect filtrira i prikazuje samo ona
                odeljenja čija imena počinju sa izabranim slovom, čineći
                pronalaženje onoga što tražite jednostavnijim bez potrebe za
                pregledanjem cele liste. Da biste se vratili na puni prikaz
                odeljenja, jednostavno poništite izbor istaknutog slova. Ova
                intuitivna abecedna navigacija osigurava da efikasno locirate
                bilo koje odeljenje po njegovom početnom slovu.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="26">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                26. Kako mogu kontaktirati menadžera odeljenja?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Kontaktiranje menadžera odeljenja u TeamConnect-u je
                jednostavno. Jednostavno locirajte odeljenje koje vas zanima i
                kliknite na ime menadžera. Ova akcija će aktivirati iskačući
                prozor koji prikazuje opcije kontaktiranja menadžera sa poznatim
                ikonama za brzi pristup:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Chat Ikona:</Body1Strong> Za započinjanje
                    trenutne poruke putem Microsoft Teams chata.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Email Ikona:</Body1Strong> Za otvaranje nove
                    e-pošte adresirane njima u Microsoft Outlook-u.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Video poziv Ikona:</Body1Strong> Za pokretanje
                    video poziva putem Microsoft Teams-a.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Poziv Ikona:</Body1Strong> Za započinjanje
                    glasovnog poziva koristeći Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Više (...) Ikona:</Body1Strong> Za dodatne
                    opcije kao što su zakazivanje sastanka ili dodavanje
                    njihovih kontakt informacija na vašu Teams listu.
                  </li>
                </ul>
                Ove ikone pružaju besprekoran način za direktno kontaktiranje
                menadžera odeljenja unutar TeamConnect-a, osiguravajući efikasnu
                komunikaciju unutar vaše organizacije.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="27">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                27. Kako mogu pregledati ili kontaktirati zaposlene unutar
                odjela?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Da biste istražili ko je ko unutar odjela u TeamConnect,
                potražite tri tačke pored naziva odjela. Klikom na njih pojaviće
                se opcije za dalje akcije:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Pregledajte zaposlene u ovom odjelu:
                    </Body1Strong>{" "}
                    Izaberite ovo da prikažete listu svih zaposlenih povezanih
                    sa odjelom. Broj pored ove opcije pokazuje koliko zaposlenih
                    ima u odjelu, pružajući brzi pregled.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Organizacijska šema odjela:</Body1Strong> Ako
                    želite razumeti hijerarhiju odjela, odaberite
                    'organizacijska šema odjela' kako biste otkrili strukturalni
                    raspored, pokazujući odnos između matičnih odjela, odjela o
                    kojem je reč, i bilo kojih pododjela. Ovaj vizualni prikaz
                    pomaže vam da se snalazite i razumete organizacioni okvir,
                    olakšavajući identifikaciju i kontaktiranje određenih
                    članova odjela.
                  </li>
                  <li>
                    <Body1Strong>Odjel {">"} Zaposleni:</Body1Strong> Nakon
                    pregleda zaposlenih unutar odjela, koristite ovu putanju za
                    lako vraćanje na listu odjela, održavajući gladak doživljaj
                    istraživanja unutar TeamConnect.
                  </li>
                </ul>
                Ove funkcije vam omogućavaju da ne samo vidite listu zaposlenih
                u datom odjelu već i pružaju put do direktnog kontaktiranja
                preko komunikacijskih alata TeamConnect-a.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="28">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                28. Otkrivanje detaljnih informacija odjela u TeamConnect-u
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Zaronite u detalje bilo kojeg odjela sa jednostavnim klikom na
                njegovo ime u TeamConnect-u. Detaljan dijalog se otvara, nudeći
                bogat mozaik informacija koje oslikavaju potpunu sliku o ulozi i
                resursima odjela unutar vaše organizacije. Naći ćete sve, od
                liderstva i finansijskih identifikatora do komunikacijskih
                kanala i društvenih veza. Ova funkcija je vaša kapija do:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    Srca odjela – njegova svrha, ljudi i metrički pokazatelji
                    učinka.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Komunikacijski putevi – direktni kontakti ključnih figura
                    odjela i komunikacijske platforme.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Organizaciona mreža – razumevanje kako se odjel integriše u
                    širu strukturu kompanije.
                  </li>
                </ul>
                Bilo da je u pitanju brzi upit ili temeljito proučavanje
                podataka o odjelu, TeamConnect vas oprema alatima za povezivanje
                i razumevanje jedinstvenih ekosistema unutar vaše organizacije.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="29">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                29. Zainteresovani za odsustvo adresa odjela u TeamConnect-u?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Otkrivanje lokacije odjela ponekad može izgledati kao potraga za
                blagom bez mape. U TeamConnect-u obično nećete pronaći posvećene
                adrese za svaki odjel. Evo zašto se ova kartografska detalja
                često izostavljaju:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Višestruke lokacije:</Body1Strong> Mnogi odjeli
                    nisu ograničeni samo na jedan prostor; oni prosperiraju u
                    različitim kancelarijama i geografijama.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Fluidna struktura:</Body1Strong> Organizacioni
                    pejzaž je u stalnoj promeni, sa odjelima koji evoluiraju,
                    spajaju se ili čak raspuštaju u skladu sa strateškim
                    promenama.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Međuodjeljenska saradnja:</Body1Strong>{" "}
                    Savremeni radni prostori bruje od saradničkog duha, često
                    mešajući talente iz različitih odjela unutar zajedničkih
                    prostora.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Radna kultura na daljinu:</Body1Strong> Sa
                    fleksibilnošću rada na daljinu, sidra odjeljenja često nisu
                    postavljena u fizičkim kancelarijskim prostorima.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Pravne nijanse:</Body1Strong> Odjeli obično
                    orbitiraju oko pravne strukture kompanije umesto da imaju
                    sopstvene samostalne adrese.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Operativno pojednostavljivanje:</Body1Strong>{" "}
                    Održavanje fokusa na dinamičnom timskom radu, a ne na
                    statičnim adresama, pomaže u očuvanju operativne agilnosti.
                  </li>
                </ul>
                Sve se svodi na usvajanje pristupa bez granica prema timskom
                radu i efikasnosti. TeamConnect odražava ovu filozofiju
                naglašavajući veze umesto lokacija.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="30">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                30. Da li nedostaju ili su zastarele informacije o vašem
                odeljenju?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Urednici odeljenja se određuju unutar vaše organizacije da
                dodaju, ažuriraju ili isprave informacije specifične za
                odeljenje u TeamConnect. Ako niste sigurni ko je vaš urednik
                odeljenja ili ako određene informacije zahtevaju intervenciju
                tima za podršku ljudskim resursima, molimo kontaktirajte{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>{" "}
                za uputstva.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Često postavljana pitanja o Imeniku kancelarija</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Uvod u Imenik kancelarija:</Body1Strong> Otkrijte kičmu
          fizičkog prisustva vaše organizacije kroz Imenik kancelarija, koji
          obuhvata osnovne detalje o kancelariji, operativne detalje, i
          sveobuhvatan pregled odeljenja i osoblja koje definiše svaku lokaciju.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="31">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                31. Navigacija oznakama u Imeniku kancelarija
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Lako pronađite put kroz Imenik kancelarija koristeći naš sistem
                oznaka dizajniran za brzi pristup pravoj lokaciji. Evo kako
                svaka oznaka može pomoći da olakšate svoju pretragu:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Sve:</Body1Strong> Prikazuje kompletnu listu
                    kancelarija, pružajući pregled iz ptičije perspektive
                    lokacija vaše organizacije.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Favoriti:</Body1Strong> Pomaže vam da se
                    fokusirate na one lokacije kancelarija koje često posećujete
                    ili na koje morate obratiti posebnu pažnju, označavajući ih
                    kao 'Favoriti'.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Vaša kancelarija:</Body1Strong> Ova lična
                    oznaka filtrira direktorijum tako da vam prikaže lokaciju
                    kancelarije gde ste stacionirani, čuvajući vašu bazu u
                    dometu vaših prstiju.
                  </li>
                </ul>
                Bilo da rezervišete salu za sastanke ili planirate posetu, ove
                oznake nude personalizovan i pojednostavljen način interakcije s
                geografskim pejzažom vaše organizacije.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="32">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                32. Usavršavanje pregleda direktorijuma kancelarija u
                TeamConnect-u
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Zaronite u organizacioni raspored raznih lokacija vaše kompanije
                pomoću pregleda direktorijuma kancelarija TeamConnect-a. Svaki
                pregled je kreiran da olakša jedinstven aspekt navigacije i
                razumevanja kancelarija:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Pregled Liste:</Body1Strong> Podrazumevana
                    podešavanja koja uredno organizuju sve lokacije kancelarija
                    u lako čitljivu listu.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Pregled Velike Kartice:</Body1Strong> Pregled
                    Velike Kartice u direktorijumu kancelarija obogaćuje vaš
                    pogled detaljnim prikazom svake kancelarije. Kada koristite
                    karticu kancelarije, naći ćete obilje informacija i opcija
                    za akciju unutar menija "Više (...)" :
                    <ol>
                      <li
                        style={{ marginTop: "8px" }}
                        className={styles.bulletBottomMargin}
                      >
                        <Body1Strong>Pogledajte Odeljenja:</Body1Strong>{" "}
                        Direktno pređite na listu svih odeljenja lociranih
                        unutar kancelarije.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Pogledajte Zaposlene:</Body1Strong>{" "}
                        Pristupite kompletnom spisku zaposlenih dodeljenih
                        kancelariji, olakšavajući direktan kontakt ili
                        istraživanje osoblja kancelarije.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>
                          Organizaciona šema poslovnog entiteta:
                        </Body1Strong>{" "}
                        Vizualizujte mesto kancelarije unutar šireg
                        organizacionog konteksta za jasno razumevanje
                        hijerarhijskih struktura.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Bitni Kontakti:</Body1Strong> Identifikujte
                        ključne kontakte kancelarije za olakšanu komunikaciju.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Javni Praznici Kancelarije:</Body1Strong>{" "}
                        Budite informisani o specifičnim javnim praznicima
                        kancelarije kako biste mogli adekvatno planirati vaše
                        rasporede.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Radno Vreme Kancelarije:</Body1Strong>{" "}
                        Pogledajte radno vreme kancelarije kako biste uskladili
                        vaše posete ili pozive sa njihovim aktivnim vremenima.
                      </li>
                    </ol>
                    Ovaj pregled pruža ne samo vizuelni i informativni trenutak,
                    već i brz pristup dubljim uvidima i direktnim akcijama
                    vezanim za kancelariju, sve uredno sakriveno pod opcijom
                    "Više (...)" za vašu udobnost.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Pregled Organizacione Šeme:</Body1Strong>{" "}
                    Vizualizujte hijerarhijsku strukturu matičnih i podređenih
                    kancelarija kako biste razumeli međusobnu povezanost
                    različitih lokacija kancelarija u vašoj organizaciji.
                  </li>
                </ul>
                Prebacujte se između ovih pregleda koristeći ikone pored polja
                za pretragu kako biste prilagodili svoje iskustvo pregledanja
                vašim neposrednim potrebama.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="33">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                33. Brzo pronalaženje kancelarija uz funkciju pretrage
                TeamConnect-a
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Fokusiranje na pravu lokaciju kancelarije unutar TeamConnect-a
                olakšano je zahvaljujući posvećenoj funkciji pretrage.
                Pozicionirano istaknuto pored ikona za pregled, polje za
                pretragu je vaš alat za precizno lociranje kancelarija po imenu.
                Dok kucate, direktorijum precizira prikaz tako da se prikazuju
                samo kancelarije koje se poklapaju sa vašim unosom,
                pojednostavljujući vaš proces pretrage. Važno je koristiti ovo
                polje za pretragu unutar TeamConnect-a za najtačnije i
                najrelevantnije rezultate, za razliku od opšte pretrage u
                Microsoft Teams-u. Sa ovom ciljanom funkcionalnošću pretrage,
                možete brzo navigirati do bilo koje kancelarije u mreži vaše
                organizacije.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="34">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                34. Азбучно прегледање именика канцеларија
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Уколико предност дате азбучном претраживању, наш азбучни филтер
                је брз начин да пронађете канцеларије по имену. На врху Именика
                канцеларија видећете слова од A до Z. Кликом на слово филтрирате
                приказ тако да се приказују само оне канцеларије које почињу тим
                словом, што олакшава ваш процес претраге. Да бисте поново видели
                све канцеларије, једноставно деселектујте активно слово. Ова
                функција је посебно корисна када сте упознати са именом
                канцеларије али вам је потребан брз начин да је лоцирате унутар
                TeamConnect.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="35">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                35. Откривање свеобухватних профила канцеларија у TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Започните истраживачко путовање кроз срж физичког присуства ваше
                организације уз профиле канцеларија у TeamConnect. Кликом на име
                канцеларије отвара се живописни дијалог профила, препун картица
                са детаљним увидима о канцеларији:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Преглед:</Body1Strong> Зароните у језгро
                    идентитета канцеларије са детаљним описима, од пословне
                    структуре до радног времена и локалног времена, сагледајући
                    суштину радног окружења.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Основни контакти:</Body1Strong> Приступите
                    кључним контактима за хитне услуге и спољну подршку, свака
                    доступна једним кликом преко Microsoft Teams Phone System.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Контакти канцеларије:</Body1Strong> Лако се
                    обратите интерним службама подршке, са детаљима контакта и
                    опцијама за директну интеракцију на дохват руке.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Државни празници:</Body1Strong> Останите
                    упознати са празницима специфичним за канцеларију,
                    обезбеђујући да ваши планови буду усклађени са локалним
                    обележавањима и радним распоредом.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Радно време:</Body1Strong> Планирајте своје
                    интеракције са прецизним знањем када канцеларија ври од
                    активности или се спрема за крај дана.
                  </li>
                </ul>
                Било да је то живахна енергија Остина или стратешки центар
                Сингапура, свака картица нуди пролаз за разумевање и повезивање
                са разноврсним локалитетима који чине глобално присуство ваше
                компаније.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="36">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                36. Istraživanje dinamike odeljenja unutar kancelarije
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Navigacija kroz profesionalnu mrežu odeljenja kancelarije odvija
                se sa lakoćom u TeamConnect. Evo kako da istražite detalje:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Pregled odeljenja u ovoj kancelariji:
                    </Body1Strong>{" "}
                    Otkrijte sva odeljenja koja čine okosnicu kancelarije
                    biranjem ove opcije iz menija Više (...).
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Organizacioni uvid:</Body1Strong> Kliknite na
                    bilo koje odeljenje da otkrijete njegovu organizacionu šemu
                    za vizuelni prikaz njegove strukture, uz pomoć putokaza:
                    Kancelarija {">"} Odeljenje {">"} Šema.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Dubinska analiza odeljenja:</Body1Strong>{" "}
                    Dodatno istražite odeljenje klikom na njegov meni Više (...)
                    pored naziva odeljenja. Ovde možete pristupiti listi svih
                    zaposlenih unutar tog odeljenja, direktno u kontekstu
                    kancelarije.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Navigacija putem putokaza:</Body1Strong>{" "}
                    Pređite kroz nivoe bez problema. Od zaposlenih nazad do
                    odeljenja ili od organizacione šeme, putokaz (Kancelarija{" "}
                    {">"} Odeljenje {">"} Zaposleni {">"} Šema) drži vaše
                    putovanje intuitivnim i na pravom putu.
                  </li>
                </ul>
                Složene staze TeamConnect-a pozivaju vas da istražite, razumete
                i angažujete se na svakom nivou odeljenja vaše kancelarije,
                osnažujući vas sa sveobuhvatnom komandom za navigaciju.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="37">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                37. Povezivanje sa osobljem kancelarije u TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Zainteresovani ste za lica iza kancelarije? Odgovor je na samo
                jedan klik u TeamConnect. Angažujte se sa tri tačke pored
                izabranog naziva kancelarije da otkrijete niz interaktivnih
                opcija:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Pregled zaposlenih u ovoj kancelariji:
                    </Body1Strong>{" "}
                    Otkrijte listu svih profesionalaca koji nazivaju ovu
                    kancelariju svojim radnim mestom. Prikazani broj nudi brzi
                    pregled zaposlenih, pozivajući vas da se upustite u
                    zajednicu kancelarije.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Opcije za kontakt:</Body1Strong> Svaki unos
                    zaposlenog je opremljen sa ikonicama za brzu akciju,
                    omogućavajući vam da započnete ćaskanja, pozive ili čak
                    video konferencije direktno iz TeamConnect-a, podstičući
                    besprekornu komunikaciju unutar kancelarije.
                  </li>
                  <li>
                    <Body1Strong>Navigacija:</Body1Strong> Da se vratite na širi
                    spisak kancelarija sa liste zaposlenih, jednostavno kliknite
                    'Kancelarija' u putokazu (Kancelarija {">"} Zaposleni), i
                    bićete vraćeni na pregled kancelarije.
                  </li>
                </ul>
                Sa ovim funkcijama, TeamConnect olakšava vizualizaciju raspodele
                radne snage po kancelarijama i omogućava vam da lako stupite u
                kontakt, podstičući povezano radno mesto bez obzira na
                udaljenost.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="38">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                38. Da li nedostaju ili su zastareli podaci o vašoj kancelariji?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ažuriranje informacija o kancelariji u TeamConnect-u može
                obavljati osoblje zaduženo za to. Ako niste sigurni ko su
                urednici kancelarije ili vam je potrebna pomoć u vezi sa
                informacijama koje spadaju u domen ljudskih resursa, tim za
                podršku ljudskim resursima je spreman da pomogne na{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>
                .
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Često postavljena pitanja o podešavanjima</Subtitle2>
      <p></p>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="39">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                39. Važnost tačnih opštih podešavanja u TeamConnect-u
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                U dinamičnom okruženju današnjeg radnog prostora, ostanak
                povezan i koordiniran je važniji nego ikad. Evo gde ažuriranje
                vaših Opštih podešavanja u TeamConnect-u igra ključnu ulogu:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Izbor radnog mesta:</Body1Strong> Osvježite
                    suštinu vašeg radnog okruženja ažuriranjem vašeg Mesta rada
                    kako bi odražavalo vašu trenutnu situaciju, bilo da je to
                    promjena iz mira vašeg kućnog ureda u živost glavnog ureda,
                    ili čak selidba u Javni prostor. Ova jasnoća u vašem radnom
                    lokalitetu pruža kolegama uvid ne samo u najbolje načine za
                    kontaktiranje s vama, već i u vrstu razgovora koje mogu
                    voditi s vama, štiteći poverljivost osetljivih rasprava
                    posebno kada ste u otvorenom okruženju kancelarije klijenta
                    ili sedite u sali aerodromskog salona.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Specifikacija radnog mesta:</Body1Strong>{" "}
                    Deljenje vašeg radnog grada čini više od povezivanja na
                    daljinu u timskom okruženju—također otvara vrata spontanim
                    profesionalnim okupljanjima ili neformalnom druženju. Bilo
                    da je reč o brzoj kafi, opuštenom ručku, ili saradničkom
                    sastanku, znanje da ste u istom gradu može pretvoriti običan
                    dan u priliku za značajne susrete licem u lice. To ne samo
                    da jača veze u timu, već pruža i bogatije razumevanje
                    lokalnih nijansi koje mogu uticati na vaš rad.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Tačnost vremenske zone:</Body1Strong> Dok
                    timovi obuhvataju kontinente, važno je da vaša vremenska
                    zona odražava vašu trenutnu lokaciju za usklađivanje
                    komunikacija. Lokalno vreme vašeg profila na TeamConnect
                    direktno je uticano ovom postavkom, eliminisajući bilo kakve
                    nagađanja za kolege koje pokušavaju da vas kontaktiraju.
                  </li>
                </ul>
                Zaronite u 'Settings' {">"} 'General Settings' unutar
                TeamConnect-a da finiširate ove detalje. Nekoliko trenutaka
                provedenih na ažuriranju vaših podešavanja može značajno
                unaprediti koheziju i efikasnost vaših zajedničkih napora na
                svim nivoima.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="40">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                40. Разрешавање избора радног места у TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Избором правог подешавања у падајућем менију радног места
                прилагођава се TeamConnect да тачно одражава ваш тренутни радни
                статус. Пређите показивачем преко иконе информација за детаљно
                објашњење сваке опције:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Checked-Out/Break:</Body1Strong> Означава време
                    одсуства са рада, било да је у питању пауза или крај дана.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Office/Field-Office:</Body1Strong> Означава
                    присуство у просторијама које управља компанија.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Home-Office:</Body1Strong> Рад из посвећеног
                    домаћег радног простора.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Remote-Workplace/Public-Area:</Body1Strong> Рад
                    из окружења које није канцеларија, што наговештава потребу
                    за флексибилном комуникацијом.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Customer Workplace/Private Office:
                    </Body1Strong>{" "}
                    На терену код клијента, са напоменом о нивоу приватности за
                    осетљивост разговора.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Commute/Business-Travel/Training:</Body1Strong>{" "}
                    У покрету или ангажован на специфичним радним активностима
                    ван канцеларије.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Public-Holiday/Vacation/Various Leaves:
                    </Body1Strong>{" "}
                    Званично слободни из личних, здравствених или празничних
                    разлога.
                  </li>
                </ul>
                Избором одговарајућег подешавања радног места осигуравате да ваш
                тим има контекст потребан за интеракције, уз поштовање
                доступности и приватности.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="41">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                41. Управљање статусом 'Checked-In' у TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ознака 'Checked-In' у „Пословном именику“ дизајнирана је да
                прикаже све запослене који су тренутно на послу. Ова видљивост
                се одређује избором радног места који направите:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Active Work Environments:</Body1Strong> Избори
                    као што су Office, Field-Office, Home-Office,
                    Remote-Workplace, Public-Area, Customer-Workplace, Customer
                    Private-Office, Commute, Business-Travel и Training
                    означавају да сте ангажовани на послу, што вас чини
                    'Checked-In' и видљиво активним колегама.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Non-Work Settings:</Body1Strong> Опције као што
                    су Checked-Out, Break, Public-Holiday, Vacation, On-Leave,
                    Maternity-Leave или Sick-Leave уклањају вас са листе
                    'Checked-In', постављајући јасна очекивања за доступност и
                    времена одговора.
                  </li>
                </ul>
                Ова функционалност помаже у разграничењу радног времена од
                личног времена, доприносећи ефикасној тимској сарадњи и
                комуникацији.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Управљање подацима и најчешћа питања о приватности</Subtitle2>
      <Body1>
        <p>
          У данашњем дигиталном добу разумевање начина на који се управља вашим
          подацима је круцијално. TeamConnect је посвећен транспарентности и
          строгим праксама заштите података. Испод ћете пронаћи информације о
          томе како обрађујемо, чувамо и заштитимо ваше податке у оквиру
          TeamConnect-а, осигуравајући да је ваш дигитални радни простор сигуран
          и у складу са прописима.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="42">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                42. Како TeamConnect штити моје личне податке?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <p>
                  TeamConnect је посвећен заштити Ваше личне информације
                  свеобухватним приступом сигурности података:
                </p>
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Једноструко пријављивање (SSO):</Body1Strong>{" "}
                    Да би појачао сигурност и унапредио корисничко искуство,
                    TeamConnect интегрише аутоматизовано Једноструко
                    пријављивање (SSO) са вашим налогом на Microsoft 365,
                    омогућавајући безбедно и једноставно управљање приступом.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Шифровање:</Body1Strong> Ми обезбеђујемо да су
                    сви подаци у оквиру TeamConnect-а шифровани како током
                    преноса, тако и док су у мировању, чинећи их недоступним
                    неовлашћеним странама.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Минимизација података:</Body1Strong> Наше
                    прикупљање података стриктно је ограничено на оно што је
                    неопходно за функционалност TeamConnect-а. Једине
                    информације о кориснику које чувамо су ваше Корисничко Име
                    Главног Принципала (UPN) из Azure Entra ID, које може бити
                    слично вашој фирминој имејл адреси.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ограничен приступ:</Body1Strong> Приступ UPN-у
                    је ограничен на Администратора Претплате ваше организације у
                    нашем порталу за претплату, осигуравајући да осетљиве
                    информације буду видљиве само овлашћеним особама.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Брзо брисање података:</Body1Strong>{" "}
                    TeamConnect је структуриран тако да одмах брише сачувани UPN
                    под три специфична услова: ако претплата буде отказана од
                    стране Администратора Претплате, ако корисника уклони људски
                    ресурси унутар TeamConnect-а, или ако претплата на
                    TeamConnect истекне. То осигурава да се ваш лични подаци не
                    чувају дуже од њиховог корисног века или потребе.
                  </li>
                </ul>
                <p>
                  Кроз ове мере, TeamConnect се обавезује на одржавање највиших
                  стандарда заштите података, осигуравајући да Ваше личне
                  информације остану сигурне и поверљиве.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="43">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                43. Zabrinuti ste gde se nalaze vaši podaci sa TeamConnect-om?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect prekida tradicionalni obrazac SaaS ponuda
                osiguravajući da vaši podaci nikada ne napuštaju vašu
                organizaciju. Za razliku od uobičajene prakse skladištenja
                podataka na spoljnim oblak serverima, TeamConnect ingeniozno
                koristi Microsoft 365 SharePoint Online vaše organizacije za
                smeštaj svih aplikacijskih podataka. Ovaj inovativni pristup ne
                samo da promoviše suverenitet podataka već i jača sigurnost,
                savršeno se usklađujući sa našom snažnom posvećenošću zaštiti
                vaše privatnosti i poštovanju strogi standardi usklađenosti.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="44">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                44. Da li će moji podaci biti deljeni sa trećim stranama?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ne, podaci se ne dele sa trećim stranama! Cubeet je
                nepokolebljiv u našoj posvećenosti privatnosti i sigurnosti
                vaših podataka. Ne delimo nikakve podatke TeamConnect sa trećim
                stranama bez dobijanja izričitog pristanka od naših klijenata. U
                našem sigurnom okruženju, čuvamo samo osnovne identifikatore,
                kao što su Korisničko Ime Principala (UPN), ime organizacije,
                ime tenanta i ID tenanta, unutar našeg Portala pretplate. Ove
                informacije su ključne za pružanje usluga i zaštićene su našim
                strogo politikama privatnosti. Budite sigurni, naše prakse
                upravljanja podacima su dizajnirane da osiguraju da vaši podaci
                ostanu poverljivi, sa transparentnošću i kontrolom koja čvrsto
                ostaje u vašim rukama.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="45">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                45. Kako TeamConnect postupa u skladu sa propisima o zaštiti
                podataka poput GDPR-a?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect usklađuje se sa propisima o zaštiti podataka poput
                GDPR-a, HIPAA, CCPA i drugih korišćenjem urođenih funkcija
                usklađenosti okruženja Microsoft 365, gde su svi podaci
                klijenata sigurno skladišteni. Za razliku od tradicionalnih SaaS
                modela, TeamConnect ne skladišti nikakve podatke klijenata na
                svojim serverima; umesto toga, funkcioniše unutar Microsoft 365
                okruženja vaše organizacije, pridržavajući se njegovih robustnih
                standarda sigurnosti i usklađenosti. Ovaj pristup osigurava da
                vaše upravljanje podacima ostane usklađeno sa sveobuhvatnim
                okvirom usklađenosti koji pruža Microsoft 365, uključujući
                pridržavanje GDPR-a, HIPAA za informacije o zdravstvenoj
                zaštiti, CCPA za stanovnike Kalifornije, i drugih globalnih
                propisa o zaštiti podataka. Integracijom sa vašim postojećim
                Microsoft 365 postavkama, TeamConnect osnažuje vašu organizaciju
                da održava punu kontrolu nad vašim podacima, osiguravajući
                usklađenost sa najstrožijim zakonima i propisima o zaštiti
                podataka.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="46">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                46. Kako TeamConnect upravlja podacima iz četova, poziva, video
                poziva, Outlook emailova i poziva telefonskog sistema Microsoft
                Teams?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect koristi Microsoft Graph API kako bi omogućio
                direktnu interakciju kao što su čet, pozivi, video pozivi i
                Outlook email unutar Microsoft Teamsa, pružajući besprekorno
                korisničko iskustvo. Ključno je da, iako TeamConnect pokreće ove
                komunikacije, to čini bez mešanja u proces komunikacije ili
                sposobnosti snimanja ili čuvanja bilo kog sadržaja interakcije.
                Svi razgovori, pozivi i emailovi odvijaju se i upravljaju
                direktno unutar sigurnih granica Microsoft Teamsa i Outlooka, u
                strogoj usklađenosti sa robustnim standardima sigurnosti i
                privatnosti Microsofta 365. Ovo osigurava da TeamConnect
                poboljšava vaše komunikacione sposobnosti, istovremeno potpuno
                čuvajući poverljivost i integritet vaših podataka.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Body1>
        <p>
          Za detaljne uvide u naše politike i prakse podataka, možete se takođe
          posavetovati sa{" "}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>
              Vodičem za korisnike TeamConnect
            </Body1Strong>
          </Link>{" "}
          ili IT odeljenjem vaše organizacije na{" "}
          <Link href={`mailto:${itSupport}`}>
            <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong>
          </Link>{" "}
          za detalje povezane sa vašim sistemom.
        </p>
      </Body1>
      <p></p>
      <Subtitle1 className={styles.fontColor}>
        Slušamo vas: Podelite vaše povratne informacije i ideje
      </Subtitle1>
      <Body1>
        <p>
          Vaši uvidi pokreću evoluciju TeamConnecta. Bilo da imate izvanrednu
          ideju za novu funkciju ili imate povratne informacije o vašem
          trenutnom iskustvu, otvoreni smo za slušanje. Vaši doprinosi su
          neprocenjivi u našem neprekidnom traganju da pružimo najbolju moguću
          uslugu.
        </p>
      </Body1>
      <Subtitle2>Povratne informacije i predlozi za funkcije</Subtitle2>
      <Body1>
        <p>
          Spremni za deljenje? Posetite našu{" "}
          <Link
            style={{ fontWeight: 600 }}
            href={feebackLink}
            target='_blank'>
            Stranicu za povratne informacije
          </Link>
          . Bilo da je to predlog za novu funkciju ili povratna informacija o
          postojećim funkcionalnostima, jednostavno odaberite odgovarajuću
          opciju iz padajućeg menija u formularu. Vaši doprinosi pomažu u
          oblikovanju budućnosti TeamConnecta, osiguravajući da zadovolji i
          prevaziđe vaše potrebe.
        </p>
      </Body1>
    </>
  );
}

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
