import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>TeamConnect 헬프 센터 및 자주 묻는 질문(FAQs)</Title3>
        <p><Body1>
          TeamConnect 헬프 센터에 오신 것을 환영합니다. 이곳은 우리의 종합 기업 디렉터리 앱과의 경험을 지원하도록 설계되었습니다. 여기에서 TeamConnect를 Microsoft Teams 내에서 최적화하여 네트워크 전반에 걸쳐 의사 소통을 증진할 자료를 찾을 수 있습니다.
        </Body1></p>
        <Subtitle2>빠른 참조 가이드</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>강화된 검색 기능: </Body1Strong>
              강력한 검색 도구로 적합한 동료, 부서 및 필수 연락처를 신속하게 찾습니다.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>시각적 조직도: </Body1Strong>
              직관적인 조직도를 통해 조직의 계층 구조를 쉽게 파악하고 보고 관계를 이해합니다.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>오늘날의 필요에 맞는 적응형 도구: </Body1Strong>
              현대 직장의 요구에 맞춘 현대적 기능을 활용하세요.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>경험 최적화</Subtitle2>
        <Body1>
          <p>
            TeamConnect는 조직 구조 내에서의 상호 작용 방식을 혁신적으로 바꿔, 일상적인 의사 소통을 전략적인 참여로 전환합니다.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>도움말 및 자주 묻는 질문(FAQs) 받기</Subtitle1>
        <Body1>
          <p>
            TeamConnect의 기능 및 설정에 관한 일반적인 질문에 대한 답변을 이 전용 섹션에서 찾아보세요. 앱을 탐색하거나 설정을 사용자 정의하거나 문제에 대한 해결책을 찾고 있든, 저희 헬프 센터가 효과적으로 도와드릴 준비가 되어 있습니다.
          </p>
        </Body1>
        <Body1>
          <p>
            보다 심층적인 지원이나 구체적인 안내가 필요한 경우, 여기를 통해 상담해주세요 <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnect 사용자 가이드</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>        
        <Subtitle2>TeamConnect FAQs 사용하기</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Microsoft Teams 사이드바에서 TeamConnect를 찾을 수 없나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  만약 TeamConnect가 Microsoft Teams 사이드바에서 쉽게 보이지 않거나 사라진 경우, 빠르게 접근할 수 있도록 핀 설정할 수 있습니다. 다음의 간단한 단계를 따라 해 보세요:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Microsoft Teams를 열고 사이드바 하단의 '...' (+ Apps) 아이콘을 클릭하세요.</li>
                    <li className={styles.bulletBottomMargin}>팝업 창 상단의 검색창에서 'TeamConnect'를 검색하세요.</li>
                    <li className={styles.bulletBottomMargin}>검색 결과에서 TeamConnect 앱을 마우스 오른쪽 버튼으로 클릭하고 '핀 설정'을 선택하세요.</li>
                  </ol>
                  이제 TeamConnect가 사이드바에 핀 설정되어 클릭 한 번으로 접근할 수 있습니다!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. TeamConnect와 관련된 기술적 문제를 겪고 계신가요? 도움을 받는 방법입니다.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Microsoft Teams 내에서 TeamConnect를 사용하다 기술적 문제가 발생한 경우, 귀하의 조직 IT 지원 팀이 도움을 줄 준비가 되어 있습니다.
                  <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>으로 연락하시면 신속하게 도움을 받을 수 있습니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. 직원 세부 정보를 찾을 수 없나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  프로필에 이름과 이메일만 표시되어 있는 것 같고, HR 팀이 아직 TeamConnect에 나머지 직원 세부 정보를 추가하지 않은 것 같다면 걱정할 필요 없습니다! HR 지원 팀에게 간단한 메시지를 보내면 모든 중요한 세부 정보로 프로필을 완성해 줄 것입니다.
                  <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>으로 연락하시면 프로필이 생동감 있게 완성됩니다!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. TeamConnect에서 사용 가능한 언어 옵션은 무엇인가요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect는 Microsoft Teams의 전 세계적 다양성을 수용하여 모든 언어를
                  지원합니다. 아랍어나 그리스어, 일본어 혹은 스웨덴어를 선호하든, 여러분의
                  손끝에서 선택할 수 있는 세계의 옵션이 있습니다. 예를 들어:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      아랍어, 불가리아어, 중국어(간체 및 번체), 크로아티아어, 체코어,
                      덴마크어, 네덜란드어, 영어, 에스토니아어, 핀란드어, 프랑스어, 독일어,
                      그리스어, 히브리어, 헝가리어, 인도네시아어, 이탈리아어, 일본어,
                      한국어, 라트비아어, 리투아니아어, 노르웨이어(북말), 폴란드어,
                      포르투갈어(브라질 및 포르투갈), 루마니아어, 러시아어,
                      세르비아어(라틴), 슬로바키아어, 슬로베니아어, 스페인어, 스웨덴어,
                      타이어, 터키어, 우크라이나어, 베트남어.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  선호하는 언어로 TeamConnect 경험을 맞춤 설정하려면, Microsoft Teams에서
                  직접 언어 설정을 조정하세요. TeamConnect는 이러한 설정과 완벽히
                  통합됩니다:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      프로필 사진 옆의 세 개의 점을 클릭한 다음 '외관 및 접근성'을 선택하여
                      Microsoft Teams 설정으로 이동하세요.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      '언어' 섹션으로 스크롤하여 드롭다운 메뉴에서 선호하는 언어를
                      선택하세요.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      '저장 및 재시작' 버튼을 클릭하여 새 언어 설정을 적용하면 변경 사항을
                      확인하세요.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>참고: </Body1Strong>
                <Body1>
                  Microsoft Teams에서 언어 설정을 변경하면 TeamConnect 경험도 자연스럽게
                  업데이트됩니다. Teams에서 언어를 변경하면 시간 및 날짜 형식도 조정될 수
                  있음을 유의하세요. 현재 TeamConnect는 오른쪽에서 왼쪽으로 쓰기를 위해
                  발전하고 있습니다. 이러한 개선 사항에 대해 기대하고 있으며, 사용
                  가능해지면 여러분에게 알려드리겠습니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. TeamConnect에서 테마를 어떻게 변경하나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect는 귀하의 Microsoft Teams 테마 선호도와 동기화되며, 라이트, 다크 및 고대비 옵션을 포함합니다. 테마를 사용자 정의하려면:
                  <ol>
                    <li className={styles.bulletBottomMargin}>프로필 사진 상단 옆에 있는 세 개의 점을 클릭하여 Microsoft Teams 설정에 접근합니다.</li>
                    <li className={styles.bulletBottomMargin}>'외형 및 접근성'을 선택하여 테마 옵션을 탐색합니다.</li>
                    <li className={styles.bulletBottomMargin}>사용 가능한 옵션에서 선호하는 테마를 선택하여 즉시 TeamConnect 인터페이스를 업데이트하여 일치시킵니다.</li>
                  </ol>
                  Microsoft Teams의 테마 선택은 TeamConnect에 자동으로 적용되어 작업 공간 전반에 걸쳐 일관된 시각적 경험을 보장합니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. TeamConnect의 연락처 동작 버튼 탐색
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect 전역에서 직원의 연락처 정보를 찾을 때마다 편리한 빠른 접근 버튼도 발견할 수 있습니다. 이러한 유용한 도구는
                  Microsoft 생태계 내에서의 의사소통을 간소화하도록 설계되었으며, 연결이 항상 클릭 거리에 있도록 보장합니다.
                  이 버튼들을 효과적으로 활용하기 위한 종합 가이드는 다음과 같습니다:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>채팅 아이콘:</Body1Strong> Microsoft Teams에서 즉시 채팅을 시작하려면 클릭합니다. 채팅을 시작한 후 TeamConnect로 돌아가려면 데스크탑 앱의 뒤로 가기 화살표, 모바일 앱의 뒤로 가기 버튼 또는 브라우저의 뒤로 가기 버튼을 사용하십시오.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>이메일 아이콘:</Body1Strong> 수신자 주소가 "받는사람:" 필드에 입력된 새 Outlook 이메일 초안을 엽니다. Outlook이 실행되지 않으면 IT 부서에 제한 사항이 있는지 확인해 볼 가치가 있습니다.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>비디오 통화 아이콘:</Body1Strong> Microsoft Teams를 사용하여 얼굴을 맞대고 비디오 통화를 시작합니다.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>전화 아이콘:</Body1Strong> Microsoft Teams 음성 통화를 시작하여 빠른 논의를 원활하게 합니다.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>조직도 아이콘:</Body1Strong> 직원의 조직 내 위치를 보여주며, 그들의 관리자와 직접 보고자를 포함합니다.</li>
                    <li><Body1Strong>더 보기 (...) 아이콘:</Body1Strong> 추가 옵션을 보여줍니다:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>지금 만나기:</Body1Strong> Microsoft Teams를 열어 직원과 즉시 회의를 시작합니다.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>새 회의:</Body1Strong> 새 회의를 예약하기 위해 Microsoft Teams로 안내합니다.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>연락처 추가:</Body1Strong> 직원의 연락처 정보를 TeamConnect에서 새 Microsoft Teams People 앱으로 추가하여 통화 기능을 풍부하게 합니다.</li>
                      </ul>
                    </li>
                  </ul>
                  이 직관적 버튼들은 빠른 채팅, 이메일 보내기 또는 미래 회의를 예약할 때 동료들과 효율적으로 연결할 수 있도록 제작되었습니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. TeamConnect를 통해 전화 걸기
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  전화를 걸고 싶으신가요? TeamConnect는 Microsoft Teams와 통합되어 의사소통을 간소화합니다.
                  TeamConnect에서 원하는 전화번호를 클릭하기만 하면, Microsoft Teams 통화 인터페이스가 나타나며,
                  원활하게 바로 걸 수 있습니다.
                  <p>
                  <Body1Strong>주의: </Body1Strong> 
                  전화를 걸 때 문제가 발생하나요? 이는 귀하의 조직의 IT 정책 또는 Microsoft Teams Phone System 이외의 전화 시스템 사용 때문일 수 있습니다. <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>에서 IT 부서와 확인하면 문제에 대해 설명해 줄 수 있습니다.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. TeamConnect을 통한 전화 발신에 비용이 드나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect는 Microsoft Teams를 활용하여 귀하의 통화 경험을 향상시킵니다. 통화 관련 비용에 대해 알아야 할 사항은 다음과 같습니다:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      조직 내의 직원 업무 전화, 부서 전화, 또는 핫라인(내부 번호)으로의 전화의 경우, Microsoft Teams Phone System을 사용할 때 일반적으로 <Body1Strong>비용이 없습니다</Body1Strong>.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      휴대전화나 외부 회선과 같은 외부 전화의 경우, 귀하의 조직의 전화 시스템에 적용되는 <Body1Strong>표준 요금</Body1Strong>이 적용됩니다.
                      이 요금은 Microsoft Teams와의 특정 서비스 계획에 따라 다릅니다.
                    </li>
                  </ul>
                  <p><Body1Strong>참고:</Body1Strong> 통화 요금에 대해 문제가 발생하거나 질문이 있는 경우, 조직의 설정 및 정책에 맞춘 자세한 정보를 위해 <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>에서 IT 부서에 문의하는 것이 가장 좋습니다.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. TeamConnect에서 '부재중' 상태가 올바르게 반영되지 않는 것을 발견하셨나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Microsoft 365에서의 '부재중' 상태가 TeamConnect에서 정확하게 반영되지 않는 것을 발견하셨나요? 이 기능은 이미 TeamConnect에서 구현되었습니다.
                  현재 Microsoft의 이 기능이 배포될 때를 기다리고 있습니다. 이 기능이 배포되면, 귀하의 ‘부재중’ 상태는 TeamConnect에서 정확하게 반영되어 Microsoft Teams의
                  상태 지표와 일치하는 매끄러운 경험을 제공하게 됩니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>직원 목록 자주 묻는 질문</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >직원 목록 소개:</Body1Strong> 조직의 전문적인 환경을 탐색하고 직원 목록을 통해 조직 내 동료들의 프로필과 관리 관계에 대한 자세한 정보 및 직접 연결을 제공합니다.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. 귀하의 직원 데이터를 추가, 업데이트, 또는 수정하고 싶으신가요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect에서 귀하의 직원 데이터를 추가, 업데이트, 또는 수정하고자 할 경우, 조직의 HR 지원 팀에 연락하시기 바랍니다. 
                  그들은 <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>에서 도움을 준비하고 있습니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. 디렉토리에서 직원 이름을 클릭하면 어떤 일이 발생하나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect 직원 디렉토리에서 직원의 이름을 클릭하면 해당 개인의 상세 프로필 페이지가 열립니다. 이 프로필은 직책, 부서, 사무실 위치, 연락처 정보 등을 포함하여 직원에 대한 종합적인 정보를 제공합니다. 이는 직원의 전문적 역할과 조직 내에서의 위치를 전체적으로 파악할 수 있게 해 주어, 그들의 책임을 이해하고 그들과 협력할 수 있는 방법을 더 쉽게 알 수 있게 합니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. 직원 프로필에는 어떤 정보가 포함되어 있나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect의 각 직원 프로필은 개인의 전문적인 삶에 대한 풍부한 정보를 제공하는 종합적인 스냅샷입니다. 다음을 찾을 수 있습니다:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>기본 정보:</Body1Strong> 이름, 직책, 부서, 그리고 자회사 위치.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>보고 구조:</Body1Strong> 직접적 보고와 기능적 보고에 대한 정보 포함, 라인 및 기능 관리자.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>근무 상태:</Body1Strong> 휴가 중, 출퇴근 중, 출장 중 등의 현재 근무 상태.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>근무지 및 현지 시간:</Body1Strong> 직원의 현재 근무 위치 및 현지 시간대.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>연락처 정보:</Body1Strong> 직장 전화번호, 휴대전화 번호, 이메일 주소.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>사무실 주소:</Body1Strong> 건물 이름, 주소 및 Google 지도를 포함한 사무실 위치.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>소개:</Body1Strong> 간단한 개인 소개 또는 전문적인 바이오.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>직원 관계:</Body1Strong> 직원의 보고 구조 및 팀 관계에 관한 정보.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>소셜 링크 및 언어:</Body1Strong> 전문 소셜 프로필 링크 및 언어 숙련도.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>기술:</Body1Strong> 전문 기술 및 전문 분야 목록.
                    </li>
                  </ul>
                  또한, 채팅, 이메일, 화상 통화, 또는 회의 일정을 잡을 수 있는 실행 가능 옵션을 제공하며, 조직 차트에 빠르게 접근하여 보다 넓은 팀 맥락을 제공합니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. 내 프로필 정보를 어떻게 업데이트하나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect에서 프로필을 개인화하는 것은 쉽습니다. 아래 방법을 따라 해보세요:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>직원</Body1Strong> 탭으로 이동하여 <Body1Strong>아바타</Body1Strong>를 클릭하고 프로필을 엽니다.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        왼쪽 상단에 <Body1Strong>"프로필 편집"</Body1Strong> 링크를 찾을 수 있습니다. 
                        이를 클릭하면 프로필 편집 대화 상자가 열립니다.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        여기에서 <Body1Strong>About You</Body1Strong> 섹션, 소셜 미디어 링크 및 전화번호를 추가하거나 업데이트하여 프로필을 더욱 향상시킬 수 있습니다. 
                        이는 동료들이 귀하와 연결하거나 팔로우할 수 있게 합니다.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        변경 사항을 적용하려면 <Body1Strong>저장</Body1Strong> 버튼을 누르는 것을 잊지 마세요.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        대화 상자에서 아래로 스크롤하여 <Body1Strong>언어</Body1Strong>와 <Body1Strong>숙련도</Body1Strong>, 
                        그리고 <Body1Strong>기술</Body1Strong>을 추가할 수 있습니다. 이 정보를 공유하면 동료들이 귀하의 전문 분야와 소통할 수 있는 언어를 이해하는 데 도움이 됩니다.
                      </li>
                    </ol>
                  프로필을 업데이트하는 것은 귀하의 정보를 최신 상태로 유지할 뿐만 아니라 조직 내에서 더 나은 연결을 촉진합니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. TeamConnect에서 직장, 작업 장소 및 시간대 업데이트하기
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  직장, 작업 장소 및 시간대 정보를 최신 상태로 유지하는 것은 Microsoft Teams에서 원활한 협업을 위해 중요합니다. 
                  이 업데이트는 귀하의 TeamConnect 프로필과 목록에 즉시 반영되어 동료들이 귀하의 작업 위치와 연결하기에 가장 좋은 시간을 빠르게 이해할 수 있도록 하며, 
                  다양한 지역 및 시간대에서의 팀워크를 향상시킵니다.
                  <p></p>
                  세부 정보를 업데이트할 준비가 되셨나요? TeamConnect에서는 쉽습니다! 상단 메뉴에서 '설정'으로 이동하여 '일반 설정'으로 이동합니다.
                  여기에서 직장 및 시간대를 원활하게 업데이트하고, 하루의 작업 장소를 지정할 수 있습니다. 변경 사항을 저장하여 모든 사람이 정보를 알 수 있도록 하고, 
                  위치에 상관없이 효과적인 협업을 보장하세요!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. 동료의 프로필에서 이상한 점을 발견했나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  동료의 프로필에서 정확하지 않아 보이는 세부 사항을 발견하면 가장 좋은 첫 단계는 그들에게 친근하게 알려주는 것입니다. 
                  간단한 채팅이나 이메일이면 충분합니다! 정보가 업데이트되지 않는다면 걱정하지 마세요—<Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>에서 HR 지원 팀에 연락하면, 
                  그들이 문제를 해결할 수 있도록 도와드릴 준비가 되어 있습니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. 태그 그룹 이해하기: 전체, 즐겨찾기, 체크인 및 신입직원
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>전체:</Body1Strong> 이 기본 태그는 조직의 모든 직원을 표시하여 팀의 전체적인 
                      모습을 제공합니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>즐겨찾기:</Body1Strong> 여기를 클릭하여 '즐겨찾기'로 표시한 동료만 보이도록 필터링하여 
                      중요한 연락처와 빠르게 연결할 수 있습니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>체크인:</Body1Strong> 이 태그는 Microsoft Teams에서의 온라인 상태가 아닌 실제 
                      근무 중인 직원을 나타냅니다. 체크인된 직원은 근무 중임을 표시하고 하루 동안의 현재 근무 위치를 공유합니다. 이는 사무실, 현장 사무실, 재택 근무, 
                      원격 근무, 고객 근무지, 고객 전용 사무실, 공공 장소, 출퇴근 중, 출장 또는 교육 등 다양한 근무 장소를 포함할 수 있습니다. 따라서 '체크인' 상태는 
                      직원들이 활발하게 근무 중임을 명확히 나타내어 동료들이 각자의 현재 근무 맥락과 이용 가능성을 정확하게 이해할 수 있도록 해줍니다.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>신입직원:</Body1Strong> TeamConnect에서 이 태그를 사용하여 30일 이내에 앱에 추가된 
                      신입 직원을 알아볼 수 있으며, 일반적으로 조직의 최근 추가된 팀 멤버들과 환영을 돕습니다.
                    </li>
                  </ul>
                  이러한 태그 그룹은 탐색을 간소화하고 TeamConnect 경험을 향상시켜 언제나 효율적으로 동료를 찾고 연결할 수 있도록 설계되었습니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. 검색 상자 옆의 아이콘 해석하기
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect의 직원 디렉토리는 다용도로 설계되었으며, 다양한 뷰를 제공하고 핵심 세부 정보에 빠르게 접근할 수 있도록 네 가지 아이콘을 특징으로 합니다. 각 아이콘은 다음과 같은 것을 보여줍니다:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>리스트 뷰:</Body1Strong> 모든 조직 구성원을 간단한 목록으로 표시하는 기본 뷰입니다.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>소형 카드 뷰:</Body1Strong> 목록을 소형 직원 카드로 변환하는 컴팩트한 대안입니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>대형 카드 뷰:</Body1Strong> 더 상세한 정보를 포함하는 큰 카드 뷰를 선택하세요. 이는 전화 및 휴대폰 번호 등 추가적인 연락 옵션을 포함하여 쉽게 다이얼 할 수 있습니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>조직도:</Body1Strong> 조직의 구조를 시각화하여 보고 관계를 이해하고 조직 풍경을 탐색하는 데 이상적인 조직도 뷰입니다.</li>
                  </ol>
                  이 아이콘 사이를 전환하여 현재 필요에 가장 적합한 뷰를 찾으세요, 간략한 정보나 깊은 통찰력을 구하든 간에.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. 동료를 빠르게 검색하려면 어디를 봐야 하나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  동료를 찾고 계신가요? 오른쪽 상단에 있는 조직도 아이콘 옆 TeamConnect 검색 상자로 이동하세요. 그들의 이름을 입력하기 시작하면 글자를 입력할 때마다 결과가 점점 더 정제됩니다. TeamConnect 검색을 할 때는 위쪽에 있는 Microsoft Teams 검색 바는 사용하지 말고 특별한 검색 상자를 사용하는 것이 좋습니다!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. 직원을 찾기 위한 알파벳 검색은 어떻게 작동하나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect의 직원 명단에는 이름의 첫 글자를 사용하여 신속하게 직원을 찾을 수 있는 편리한 알파벳 검색 기능이 포함되어 있습니다.
                  디렉토리 상단에 표시된 알파벳 중 하나를 클릭하기만 하면 TeamConnect는 해당 글자로 시작하는 모든 직원을 즉시 보여줍니다. 
                  직원 전체 목록으로 돌아가려면 강조 표시된 글자의 선택을 해제하기만 하면 됩니다. 이 직관적인 기능은 대규모 직원 목록을 쉽게 탐색할 수 있게 하여, 
                  동료를 신속하고 효율적으로 찾을 수 있게 도와줍니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. 페이지당 표시되는 직원 수를 어떻게 조정할 수 있나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect는 페이지당 볼 수 있는 직원 프로필 수를 사용자가 설정할 수 있도록 해, 직원 명단을 쉽게 탐색할 수 있게 합니다.
                  각 페이지의 하단에는 페이지 수 선택 옵션이 있습니다: 10, 50, 100, 500, 1000 및 5000. 기본 설정은 페이지당 10개의 프로필을 보여줍니다.
                  더 많은 프로필을 한 페이지에서 보려면 이 옵션들 중에서 원하는 숫자를 선택하기만 하면 됩니다. 이 기능은 디렉토리를 검색하는 방법에 유연성을 제공하여, 
                  특정 요구나 선호도에 맞게 디스플레이를 맞춤 설정할 수 있게 합니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. 목록 보기에서 직원 정보를 어떻게 정렬할 수 있나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect의 직원 명단 목록 보기에서는 직원 정보를 필요에 맞게 정렬할 수 있는 유연성을 제공합니다. 열—직원 이름, 직책, 부서, 사무실 위치 및 직장—은 모두 정렬이 가능합니다.
                  정렬하려는 열의 헤더를 클릭하기만 하면 됩니다. 한 번 클릭하면 오름차순으로 정렬되고, 다시 클릭하면 내림차순으로 정렬됩니다. 기본적으로, 
                  디렉토리는 이름의 첫 글자를 기준으로 오름차순으로 정렬됩니다. 이 정렬 기능은 특정 인물을 찾거나 부서나 위치와 같은 특정 기준에 따라 
                  직원들을 그룹화할 필요가 있을 때, 직원 세부 정보를 빠르게 조직하고 볼 수 있도록 도와줍니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>부서 목록 FAQ</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>부서 목록 소개:</Body1Strong> 부서 목록은 조직의 복잡한 구조를 이해하는 데 있어 관문 역할을 하며, 부서 계층, 관리 리더십 및 부서 간 원활한 의사소통을 위한 경로를 명확하게 보여줍니다.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. '전체', '즐겨찾기', '당신의 부서' 태그는 부서 목록에서 어떻게 작동하나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect의 부서 목록에서 태그는 부서 정보를 빠르게 탐색하고 필터링하는 데 도움을 줍니다. 각 태그가 나타내는 것은 다음과 같습니다:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>전체:</Body1Strong> 이 태그는 조직 내 모든 부서를 표시하여 종합적인 개요를 제공합니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>즐겨찾기:</Body1Strong> ‘즐겨찾기’로 표시한 부서들의 선별된 목록을 볼 수 있게 해주어, 자주 상호작용하는 부서에 쉽게 접근할 수 있게 합니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>당신의 부서:</Body1Strong> 이 태그는 바로 당신의 부서만을 보여주는 뷰로 필터링하여, 근접한 부서 정보와 업데이트에 빠르게 접근할 수 있게 해줍니다.</li>
                  </ul>
                  이러한 태그들은 부서 목록 내 탐색을 간소화 설계되어 부서 정보를 효과적으로 찾고 활용할 수 있게 합니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. TeamConnect에서 부서 목록 뷰 이해하기
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect의 부서 목록을 통한 탐색은 선호도에 따라 맞춤 설정이 가능한 다양한 뷰 옵션으로 간소화됩니다. 각 뷰는 부서 이름, 부서 관리자, 상위 부서, 부서 연락처, 부서 핫라인, 부서 이메일을 포함한 종합적인 부서 정보를 탐색할 수 있는 독특한 방법을 제공합니다. 사용 가능한 뷰는 다음과 같습니다:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>리스트 뷰:</Body1Strong> 모든 부서를 깔끔하게 나열하는 기본 설정으로, 명확하고 간결한 개요를 제공합니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>큰 카드 뷰:</Body1Strong> 각 부서의 주요 정보를 확장된 요약으로 보여주는 상세한 부서 카드를 확인할 수 있는 뷰로 전환합니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>조직도 뷰:</Body1Strong> 부서 구조와 관계의 시각적 표현을 위해, 조직도 뷰는 조직 내에서 각 부서가 어떻게 맞춰져 있는지를 보여줍니다.
                    </li>
                  </ul>
                  이러한 뷰 사이를 전환하려면 부서 목록 상단의 검색 상자 옆에 있는 아이콘을 사용하면 됩니다. 각 뷰는 여러분이 필요한 정보를 원하는 형식으로 쉽게 접근하고 이해할 수 있도록 설계되었습니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>           
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. 부서 디렉토리에서 검색 상자 사용 방법
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect 내의 어떤 부서라도 쉽게 찾을 수 있도록 디렉토리 상단의 보기 아이콘 바로 옆에 위치한 전용 검색 상자를 사용하세요. 검색하고자 하는 부서 이름을 입력하기 시작하면, 디렉토리가 자동으로 세분화되어 관련 부서만을 표시합니다. 이 기능은 전체 디렉토리를 수동으로 검색할 필요 없이 필요한 부서를 손쉽게 찾을 수 있도록 특별히 설계되었습니다. 이 검색 기능은 TeamConnect의 부서 디렉토리에만 특화되어 있으므로, 부서별 검색을 위해 Teams 인터페이스 최상단의 주 Microsoft Teams 검색 상자 대신 이 검색 상자를 사용하세요.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. 부서 디렉토리에서 알파벳 필터는 어떻게 작동하나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  부서 디렉토리에서 상단에 위치한 알파벳 필터를 사용하여 검색을 간소화하세요. 이 기능은 알파벳 중 하나를 선택함으로써 부서를 빠르게 좁혀나갈 수 있게 해줍니다. 글자를 선택하면, TeamConnect는 해당 글자로 시작하는 부서만을 필터링하여 표시합니다. 전체 부서 목록을 다시 보려면 강조 표시된 글자 선택을 해제하면 됩니다. 이 직관적인 알파벳 탐색은 초기 글자별로 어떤 부서든 효율적으로 찾을 수 있도록 보장합니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. 부서 관리자에게 어떻게 연락할 수 있나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect에서 부서 관리자에게 연락하는 것은 간단합니다. 관심 있는 부서를 찾아 관리자의 이름을 클릭하세요. 이 작업은 관리자의 연락처 옵션을 표시하는 팝오버를 활성화합니다. 다음과 같은 친숙한 빠른 접근 아이콘을 제공합니다:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>채팅 아이콘:</Body1Strong> Microsoft Teams 채팅을 통해 즉시 메시지를 시작합니다.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>이메일 아이콘:</Body1Strong> Microsoft Outlook에서 새 이메일을 그들에게 보내기 위해 엽니다.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>비디오 통화 아이콘:</Body1Strong> Microsoft Teams를 통해 비디오 통화를 시작합니다.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>전화 아이콘:</Body1Strong> Microsoft Teams를 사용해 음성 통화를 시작합니다.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>더 보기 (...) 아이콘:</Body1Strong> 회의 예약 또는 연락처 정보를 Teams 목록에 추가하는 등 추가 옵션을 위해 사용합니다.
                    </li>
                  </ul>
                  이러한 아이콘은 TeamConnect 내부에서 부서 관리자에게 직접 연락할 수 있는 원활한 방법을 제공하여 조직 내 효율적인 의사소통을 보장합니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. 부서 내의 직원을 어떻게 보거나 연락할 수 있나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect 내에서 부서별 인물을 알아보고 싶다면, 부서 이름 옆에 있는 세 점을 더 이상 찾지 마세요. 이를 클릭하면 다음과 같은 추가 조치 옵션이 표시됩니다:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>이 부서의 직원 보기:</Body1Strong> 이 옵션을 선택하면 해당 부서와 관련된 모든 직원의 목록이 표시됩니다. 이 옵션 옆의 숫자는 부서 내 직원 수를 나타내어 빠른 인원 수를 제공합니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>부서 조직도:</Body1Strong> 부서의 계층 구조를 파악하고 싶다면 '부서 조직도'를 선택하여 구조적 레이아웃을 공개하십시오. 이는 상위 부서, 해당 부서 및 하위 부서 간의 관계를 보여 주어 조직 구조를 탐색하고 이해하는 데 도움이 되며, 특정 부서 구성원을 식별하고 연락하는 데 도움이 됩니다.
                    </li>
                    <li><Body1Strong>부서 {'>'} 직원:</Body1Strong> 부서 내 직원들을 확인한 후, 이 빵 부스러기 경로를 사용하여 부서 목록으로 쉽게 돌아갈 수 있어 TeamConnect 내에서 원활한 탐색 경험을 유지할 수 있습니다.
                    </li>
                  </ul>
                  이 기능을 통해 주어진 부서의 직원 목록을 볼 수 있을 뿐만 아니라 TeamConnect의 커뮤니케이션 도구를 통해 직접 연락할 수 있는 경로도 제공됩니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. TeamConnect에서 부서의 자세한 정보 발견하기
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect에서 각 부서의 이름을 클릭하기만 하면, 부서의 역할과 조직 내 자원에 대한 풍부한 정보가 담긴 자세한 대화상자가 펼쳐집니다. 리더십과 재정 식별자에서부터 커뮤니케이션 채널 및 소셜 링크에 이르기까지 모든 것을 찾을 수 있습니다. 이 기능은 다음에 대한 게이트웨이입니다:
                  <ul>
                    <li className={styles.bulletBottomMargin}>부서의 심장 – 그 목적, 사람들, 그리고 성과 메트릭스.</li>
                    <li className={styles.bulletBottomMargin}>연락처 경로 – 주요 부서 인사 및 커뮤니케이션 플랫폼에 대한 직접 연결.</li>
                    <li className={styles.bulletBottomMargin}>조직의 웹 – 부서가 보다 넓은 회사 구조와 어떻게 통합되는지 이해.</li>
                  </ul>
                  간단한 문의 또는 부서 데이터에 대한 심층적인 분석이든, TeamConnect는 조직 내 독특한 생태계와 연결하고 이해할 수 있는 도구를 제공합니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. TeamConnect에서 부서 주소가 없는 이유에 대해 궁금하신가요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  부서의 위치를 찾는 것이 때때로 지도 없는 보물 찾기처럼 느껴질 수 있습니다. TeamConnect에서는 일반적으로 각 부서에 대한 전용 주소를 찾을 수 없습니다. 지리적 세부 사항이 종종 생략되는 이유는 다음과 같습니다:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>다중 위치:</Body1Strong> 많은 부서는 단일 공간에 국한되지 않고 다양한 사무실과 지리적 위치에 걸쳐 번성합니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>유동적 구조:</Body1Strong> 조직 경관은 전략적 변화에 따라 부서가 진화하고 통합되거나 해체되면서 끊임없이 변화합니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>부서 간 협업:</Body1Strong> 현대의 작업 공간은 협업의 정신으로 가득 차 있으며, 종종 공유 공간에서 다양한 부서의 재능을 혼합합니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>원격 근무 문화:</Body1Strong> 원격 근무의 유연성으로 인해 부서의 앵커가 항상 물리적 사무실 위치에 놓이지는 않습니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>법적 뉘앙스:</Body1Strong> 부서는 일반적으로 회사의 법적 구조를 중심으로 운영되며 자체적인 독립 주소를 갖지 않습니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>운영의 간소화:</Body1Strong> 동적인 팀워크에 초점을 맞추고 정적인 주소에 매이지 않음으로써 운영의 민첩성을 유지하는 데 도움이 됩니다.
                    </li>
                  </ul>
                  협업과 효율성에 대한 경계 없는 접근 방식을 수용하는 것입니다. TeamConnect는 위치보다는 연결을 강조함으로써 이 철학을 반영합니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. 당신의 부서 정보가 누락되었거나 오래된 것입니까?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  부서 편집자는 귀하의 조직 내에서 지정되어 TeamConnect에서 부서별 정보를 추가, 업데이트 또는 수정하도록 합니다.
                  귀하의 부서 편집자가 누구인지 확실하지 않거나 특정 정보가 인사 지원 팀의 개입이 필요한 경우,
                  <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>에게 안내를 요청하세요.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>사무실 디렉터리 자주 묻는 질문들</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>사무실 디렉터리 소개:</Body1Strong> 사무실 디렉터리를 통해 귀하의 조직의 물리적 존재의 핵심을 확인하세요.
              이는 필수 사무실 내역, 운영 세부 사항, 그리고 각 위치를 정의하는 부서와 인물에 대한 종합적인 정보를 포함합니다.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. 사무실 디렉터리에서 태그 찾아내기
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  사무실 디렉터리에서 우리의 태그 시스템을 사용하여 원하는 위치에 빠르게 접근할 수 있습니다. 각 태그가 검색을 간소화하는 방법은 다음과 같습니다:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>전체:</Body1Strong> 사무실의 전체 목록을 표시하여 조직의 위치에 대한 조감도를 제공합니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>즐겨찾기:</Body1Strong> 자주 가거나 주의 깊게 관찰해야 할 사무실 위치를 '즐겨찾기'로 표시함으로써 해당 위치에 초점을 맞출 수 있게 도와줍니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>귀하의 사무실:</Body1Strong> 이 개인 태그는 디렉터리를 필터링하여 귀하가 배치된 사무실 위치를 보여주며, 귀하의 홈 베이스를 손안에 둡니다.
                    </li>
                  </ul>
                  회의실을 예약하거나 방문 계획을 세울 때, 이러한 태그들은 귀하의 조직의 지리적 풍경과 상호 작용하는 개인화되고 간소화된 방법을 제공합니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. TeamConnect에서 사무실 디렉토리 보기 마스터하기
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect의 사무실 디렉토리 뷰를 통해 회사의 다양한 위치의 조직적 레이아웃에 대해 탐구하십시오. 각 뷰는 사무실 탐색 및 이해의 독특한
                  측면을 용이하게 하도록 제작되었습니다:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>목록 보기:</Body1Strong> 모든 사무실 위치를 깔끔하게 정리하여 쉽게 읽을 수 있는 기본 설정입니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>큰 카드 보기:</Body1Strong> 사무실 디렉토리의 큰 카드 보기는 각 사무실의 자세한
                      프리젠테이션을 통해 시야를 풍부하게 합니다. 사무실 카드와 상호 작용할 때 "더 보기 (...)" 메뉴 내에서 많은 정보 및 실행 가능한 선택권을 확인할 수 있습니다:
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>부서 보기:</Body1Strong> 사무실 내에 위치한 모든 부서의 목록으로 직접
                          이동합니다.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>직원 보기:</Body1Strong> 사무실에 배정된 직원들의 전체 명단에 액세스하여 사무실 인력을 직접 연락하거나
                          탐색합니다.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>기업 조직도 차트:</Body1Strong> 조직 내에서 사무실의 위치를 시각화하여 계층 구조를
                          명확하게 이해합니다.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>핵심 연락처:</Body1Strong> 원활한 소통을 위해 주요 사무실 연락처를 식별합니다.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>사무실 공휴일:</Body1Strong> 사무실별 공휴일에 대해 정보를 얻어 일정을
                          계획할 수 있습니다.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>사무실 운영 시간:</Body1Strong> 사무실의 운영 시간을 확인하여 방문 또는 통화를
                          그들의 활동 시간에 맞춥니다.
                        </li>
                      </ol>
                      이 뷰는 시각적 및 정보적 스냅샷뿐만 아니라 사무실과 관련된 더 깊은 통찰과 직접적인 행동에 대한 빠른 접근을 제공하며, 모두 "더 보기 (...)" 옵션 아래에서 깔끔하게 정리됩니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>조직도 보기:</Body1Strong> 조직 내 다른 사무실 위치의 상호 연결성을 이해하기 위해
                      모(母) 사무실과 자(子) 사무실의 계층 구조를 시각화합니다.
                    </li>
                  </ul>
                  검색 상자 옆에 있는 아이콘을 토글하여 이러한 뷰를 변경하고, 즉시 필요한 것에 맞게 탐색 경험을 사용자 지정하십시오.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. TeamConnect의 검색 기능을 이용하여 빠르게 사무실 찾기
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect 내에서 적합한 사무실 위치를 신속하게 찾는 것은 전용 검색 기능으로 쉽게 가능합니다. 뷰 아이콘 옆에 눈에 띄게 배치된 검색 상자는 이름으로 사무실을
                  정확하게 찾는 도구입니다. 입력하면서 디렉토리는 입력한 내용과 일치하는 사무실만 표시하여 검색 과정을 간소화합니다. Microsoft
                  Teams의 일반 검색이 아닌 TeamConnect 내의 이 검색 상자를 사용하는 것이 가장 정확하고 관련성 높은 결과를 얻는 데 중요합니다. 이
                  특정 검색 기능을 사용하면 조직 네트워크의 어느 사무실로든 신속하게 이동할 수 있습니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. 사무실 디렉토리를 알파벳 순으로 탐색
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  알파벳 순으로 검색을 선호한다면, 알파벳 필터를 사용하는 것이 사무실 이름을 찾는 빠른 방법입니다. 사무실 디렉토리 상단에는 A부터 Z까지의 문자가 표시됩니다. 문자를 클릭하면 해당 문자로 시작하는 사무실만 표시되어 검색 과정이 간소화됩니다. 모든 사무실을 다시 보려면 활성 문자의 선택을 취소하면 됩니다. 이 기능은 사무실 이름을 알고 있지만 TeamConnect 내에서 빠르게 찾아야 할 때 특히 유용합니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. TeamConnect에서 종합적인 사무실 프로필 공개
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  조직의 물리적 존재의 심장부를 탐험하는 여정에 착수하세요. TeamConnect의 사무실 프로필을 통해 활기찬 프로필 대화 상자가 열리며, 상세한 사무실 정보가 가득한 탭이 표시됩니다:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>개요:</Body1Strong> 사업 구조부터 운영 시간 및 현지 시간에 이르기까지, 사무실 환경의 본질을 포착하는 상세한 설명으로 사무실의 정체성을 파고들어 보세요.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>필수 연락처:</Body1Strong> 응급 서비스 및 외부 지원을 위한 중요 연락처에 접근하세요, 각 연락처는 Microsoft Teams 전화 시스템을 통해 클릭 한 번으로 이용할 수 있습니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>사무실 연락처:</Body1Strong> 내부 지원 서비스에 쉽게 연락할 수 있도록, 연락처 상세 정보 및 직접 참여 옵션이 바로 사용자의 손안에 있습니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>공휴일:</Body1Strong> 사무실별 휴일을 인지하여 계획이 현지 관례 및 작업 일정과 일치하도록 하세요.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>운영시간:</Body1Strong> 사무실이 활동적이거나 하루를 마감하는 시간을 정확히 알고 상호작용을 계획하세요.
                    </li>
                  </ul>
                  바쁜 오스틴의 에너지부터 싱가포르의 전략적 허브에 이르기까지, 각 탭은 회사의 글로벌 존재를 이루는 다양한 지역과 연결하고 이해하는 관문을 제공합니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. 사무실 내 부서 역학 탐구
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  사무실의 부서들의 전문적 네트워크를 탐색하는 것은 TeamConnect에서 쉽게 펼쳐집니다. 세부사항을 탐구하는 방법은 다음과 같습니다:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>이 사무실의 부서 보기:</Body1Strong> 더 보기 (...) 메뉴에서 이 옵션을 선택하여 
                      사무실을 구성하는 모든 부서를 확인하세요.
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>조직 인사이트:</Body1Strong> 어느 부서를 클릭하면 그 부서의 조직도가 나타나며, 
                      빵 부스러기 경로가 안내해줍니다: 사무실 {'>'} 부서 {'>'} 차트.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>부서 심층 분석:</Body1Strong> 부서 이름 옆의 더보기 메뉴 (...)를 클릭하여 해당 부서를 
                      더 자세히 탐색하세요. 여기에서 사무실 컨텍스트 내 해당 부서의 모든 직원 목록에 접근할 수 있습니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>빵 부스러기 네비게이션:</Body1Strong> 원활하게 수준을 넘나드세요. 직원에서 부서로 또는 
                      조직도에서, 빵 부스러기 경로(사무실 {'>'} 부서 {'>'} 직원 {'>'} 차트)가 직관적이고 정확한 여정을 유지합니다.
                    </li>
                  </ul>
                  TeamConnect의 복잡한 경로를 통해 사무실의 각 부서 레이어를 탐구하고 이해하며 참여함으로써, 포괄적인 탐색 컨트롤을 갖출 수 있습니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. TeamConnect에서 사무실 인사들과 연결하기
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  사무실 뒤의 인물이 궁금하신가요? TeamConnect에서는 단 한 번의 클릭으로 해답을 얻을 수 있습니다. 선택한 사무실 이름 옆의 줄임표(세 개의 점)를 눌러 다양한 대화형 옵션을 공개하세요:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>이 사무실의 직원 보기:</Body1Strong> 이 사무실을 업무 공간으로 삼는 모든 전문가들의 목록을 공개하세요. 
                      표시된 숫자는 직원들의 빠른 개수를 제공하며, 사무실 커뮤니티를 더 깊이 파고들도록 초대합니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>연락처 옵션:</Body1Strong> 각 직원 항목에는 빠른 작업 아이콘이 장착되어 있어,
                      TeamConnect에서 채팅, 전화 또는 비디오 회의를 시작할 수 있게 해주어, 사무실 간 원활한 커뮤니케이션을 촉진합니다.
                    </li>
                    <li><Body1Strong>내비게이션:</Body1Strong> 직원 목록에서 더 넓은 사무실 목록으로 돌아가려면, 빵 부스러기 경로에서 '사무실'을 클릭하십시오 (사무실 {'>'} 직원), 
                      그러면 사무실 개요로 돌아갑니다.
                    </li>
                  </ul>
                  이러한 기능을 통해 TeamConnect는 사무실 간 인력 분포를 시각화하고 수월하게 연락을 취할 수 있게 해주어, 거리에 상관없이 연결된 직장을 조성합니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. 귀하의 사무실 정보가 없거나 오래되었습니까?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect에서 사무실 정보 업데이트는 지정된 사무실 편집자가 처리할 수 있습니다. 사무실 편집자가 누구인지 확실하지 않거나 인사 관리 범위에 속하는 정보에 대한 도움이 필요한 경우, 
                  HR 지원팀이 <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>을 통해 도움을 준비하고 있습니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>설정 자주 묻는 질문</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. TeamConnect에서 일반 설정의 정확성이 중요한 이유
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  오늘날의 업무 환경에서 변화하는 풍경에서 연결되고 조정되는 것이 그 어느 때보다 중요합니다. TeamConnect의 일반 설정을 업데이트하는 것이 중요한 역할을 합니다:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>직장 선택:</Body1Strong> 홈-오피스의 고요함에서 본사의 분주함으로, 또는 공공장소에 자리잡는 등, 현재 상황을 반영하도록 직장을 업데이트하여 근무 장소의 
                        본질을 포착하세요. 근무 장소의 명확성은 동료들에게 당신에게 연락하는 최상의 방법뿐만 아니라 고객의 사무실이나 공항 라운지와 같이 개방된 환경에서 민감한 대화의 
                        기밀을 유지할 수 있는 대화의 성격을 이해하는 데 도움을 줍니다.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>작업 장소 상세 정보:</Body1Strong> 근무 도시를 공유하는 것은 원격 팀 설정에서 거리를 연결하는 것 이상의 효과가 있으며, 
                        때때로는 전문적인 모임이나 캐주얼한 사교 활동의 문을 엽니다. 커피 한 잔, 여유로운 점심, 또는 협력적인 회의 등, 같은 도시에 있다는 것을 알면 평범한 하루를 
                        의미 있는 대면 연결의 기회로 변환할 수 있습니다. 이것은 팀 유대를 강화할 뿐만 아니라 작업에 영향을 미칠 수 있는 지역적 뉘앙스에 대한 더 풍부한 이해를 
                        제공합니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>시간대 정확성:</Body1Strong> 대륙을 아우르는 팀들에서는 현재 위치를 반영하는 시간대를 확보하는 것이 커뮤니케이션을 
                      조화롭게 하는 데 필수적입니다. TeamConnect 프로필의 현지 시간은 이 설정에 직접적으로 영향을 받아, 동료가 당신에게 연락을 시도할 때 추측할 필요가 없습니다.
                    </li>
                  </ul>
                  TeamConnect 내 '설정' {'>'} '일반 설정'으로 들어가 이러한 세부 사항을 미세 조정하세요. 설정을 업데이트하는 몇 분 동안은 전반적으로 협업 노력의 일체감과 효율성을 
                  크게 향상시킬 수 있습니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. TeamConnect에서 직장 선택 해독
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  직장 드롭다운에서 올바른 설정을 선택하면 TeamConnect가 현재의 근무 상태를 정확히 반영하도록 조정됩니다. 각 옵션에 대한 자세한 설명은 정보 아이콘에 마우스를 올려 놓으세요:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>퇴근/휴식:</Body1Strong> 업무에서 시간을 내는 것을 나타내며 쉬는 시간이나 하루의 끝을 의미합니다.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>사무실/현장 사무실:</Body1Strong> 회사가 관리하는 작업 공간에 있는 것을 나타냅니다.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>재택 사무실:</Body1Strong> 전용 가정 작업 공간에서 일합니다.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>원격 작업장/공공 장소:</Body1Strong> 사무실이 아닌 환경에서 작동하며, 유연한 커뮤니케이션이 필요함을 제안합니다.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>고객 직장/개인 사무실:</Body1Strong> 고객과 현장에서 일하며 대화의 민감성에 대한 프라이버시 수준에 대해 언급합니다.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>통근/출장/교육:</Body1Strong> 이동 중이거나 사무실 외부에서 특정 업무 관련 활동에 참여하고 있습니다.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>공휴일/휴가/각종 휴가:</Body1Strong> 개인적, 건강적, 또는 휴일 이유로 공식적으로 근무에서 제외됩니다.</li>
                  </ul>
                  적절한 직장 설정을 선택하면 팀이 상호 작용에 필요한 맥락을 이해하고, 이용 가능성과 사생활을 존중할 수 있습니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. TeamConnect에서 '체크인' 상태 탐색
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  직원 디렉토리의 '체크인' 태그는 현재 업무 중인 모든 직원을 표시하도록 설계되었습니다. 이 가시성은 선택한 직장 선택에 의해 결정됩니다:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>활동 중인 업무 환경:</Body1Strong> 사무실, 현장 사무실, 재택 사무실, 원격 작업장, 공공 장소, 고객 직장, 
                    고객 사설 사무실, 통근, 출장, 교육과 같은 선택은 업무에 참여하고 있다는 것을 나타내며, 동료들에게 '체크인' 상태로 활동적으로 보이게 합니다.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>비근무 설정:</Body1Strong> 퇴근, 휴식, 공휴일, 휴가, 휴직, 출산 휴가, 병가 
                      상태를 선택하면 '체크인' 목록에서 제외되며, 이용 가능성과 응답 시간에 대한 명확한 기대를 설정합니다.
                    </li>
                  </ul>
                  이 기능은 업무 시간과 개인 시간을 구분하는 데 도움이 되어 팀의 효과적인 협력과 소통을 돕습니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>데이터 관리 및 개인 정보 보호 FAQ</Subtitle2>
          <Body1>
            <p>
              오늘날 디지털 시대에 데이터 관리 방법을 이해하는 것이 매우 중요합니다. TeamConnect는 투명성과 강력한 데이터 보호 관행에 전념하고 있습니다.
              아래에서는 TeamConnect 내부에서 데이터를 어떻게 처리하고 저장하며 보호하는지에 대한 정보를 확인할 수 있으므로 디지털 작업 공간이 안전하고 준수하도록 보장합니다.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. TeamConnect는 내 개인 데이터를 어떻게 보호하나요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect는 데이터 보안에 대한 종합적인 접근 방식으로 귀하의 개인 정보를 보호하는 데 전념하고 있습니다:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>단일 로그인(SSO):</Body1Strong> 보안을 강화하고 사용자 경험을 향상시키기 위해, TeamConnect는 자동화된 
                      단일 로그인(SSO)을 귀하의 Microsoft 365 계정과 통합하여 안전하고 수월한 접근 관리를 촉진합니다.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>암호화:</Body1Strong> TeamConnect 내 모든 데이터는 전송 중과 저장 중 모두 암호화되어 
                      무단자에게 접근할 수 없도록 보호됩니다.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>데이터 최소화:</Body1Strong> 우리의 데이터 수집은 TeamConnect의 기능에 필수적인 것으로 엄격히 제한됩니다.
                      우리가 저장하는 사용자 정보는 귀하의 Azure Entra ID에서 비롯된 User Principal Name (UPN)뿐이며, 이는 귀하의 회사 이메일 주소와 유사할 수 있습니다.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>제한된 접근:</Body1Strong> UPN에 대한 접근은 구독 포털 내 귀하의 조직의 구독 관리자에게 제한되어 
                      민감한 정보가 승인된 인원에게만 보이도록 합니다.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>신속한 데이터 삭제:</Body1Strong> TeamConnect는 세 가지 특정 조건하에서 저장된 UPN을 즉시 삭제하도록 구성되어 있습니다:
                      구독 관리자에 의해 구독이 취소되거나, TeamConnect 내 HR에 의해 사용자가 제거되거나, TeamConnect 
                      구독이 만료되는 경우입니다. 이는 귀하의 개인 데이터가 유용한 수명이나 필요성을 넘어 보관되지 않도록 보장합니다.
                    </li>                      
                  </ul>
                  <p>이러한 조치를 통해 TeamConnect는 데이터 보호의 최고 기준을 유지하며 귀하의 개인 정보가 안전하고 기밀로 유지되도록 보장합니다.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. TeamConnect를 사용하면 데이터가 어디에 저장되는지 궁금하신가요?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect는 전통적인 SaaS 제공 방식을 탈피하여 귀하의 데이터가 귀하의 조직 밖으로 나가지 않도록 보장합니다.
                  외부 클라우드 서버에 데이터를 저장하는 일반적인 방식과 달리, TeamConnect는 기발하게도 귀하의 조직의
                  Microsoft 365 SharePoint Online을 사용하여 모든 애플리케이션 데이터를 저장합니다. 이러한 혁신적인 접근 방식은 데이터 주권을 지키는 것뿐만 아니라 보안을 강화하며,
                  귀하의 프라이버시를 보호하고 엄격한 준수 기준을 준수하는 데 대한 우리의 강력한 헌신과 완벽하게 일치합니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. 내 데이터가 제3자와 공유됩니까?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  아니요, 데이터가 제3자와 공유되지 않습니다! Cubeet은 데이터의 프라이버시와 보안에 대한 우리의 약속에 확고합니다. 고객으로부터 명시적인 동의를 얻지 않고는 어떠한 TeamConnect 데이터도 제3자와 공유하지 않습니다.
                  우리의 안전한 환경에서 User Principal Name (UPN), 조직 이름, 테넌트 이름, 테넌트 ID와 같은 기본적인 식별자만을
                  구독 포털 내에서 저장합니다. 이 정보는 서비스 제공에 중요하며 우리의 엄격한 프라이버시 정책 하에 보호됩니다.
                  안심하십시오, 우리의 데이터 관리 관행은 귀하의 데이터가 기밀로 유지되도록 설계되었으며, 투명성과 제어는 귀하의 손에 확고히 남아 있습니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. TeamConnect는 GDPR 같은 데이터 보호 규정을 어떻게 준수합니까?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect는 Microsoft 365 환경의 내장된 준수 기능을 활용하여 GDPR, HIPAA, CCPA 등과 같은 데이터 보호 규정을 준수합니다.
                  전통적인 SaaS 모델과는 달리, TeamConnect는 고객 데이터를 자체 서버에 저장하지 않고, 귀하의 조직의 Microsoft 365 환경 안에서 운영되며, 견고한 보안 및 준수 기준을 준수합니다.
                  이 접근 방식은 귀하의 데이터 거버넌스가 Microsoft 365가 제공하는 포괄적인 준수 프레임워크와 일관되게 유지되도록 보장하며,
                  GDPR, 건강 정보에 대한 HIPAA, 캘리포니아 주민을 위한 CCPA 및 기타 전 세계 데이터 보호 규정을 준수합니다. 기존의 Microsoft 365 설정과 원활히 통합함으로써 TeamConnect는 귀하의 조직이
                  가장 엄격한 데이터 보호 법률 및 규정을 준수하면서 귀하의 데이터에 대한 전체 통제권을 유지할 수 있도록 지원합니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. TeamConnect는 Microsoft Teams 채팅, 통화, 화상 통화, Outlook 이메일 및 전화 시스템 통화의 데이터를 어떻게 처리합니까?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect는 Microsoft Graph API를 활용하여 Microsoft Teams 내에서 채팅, 통화, 화상 통화 및 Outlook 이메일과 같은 직접적인 상호작용을 가능하게 하여,
                  원활한 사용자 경험을 제공합니다. 중요한 점은, TeamConnect가 이러한 커뮤니케이션을 초기화하지만, 커뮤니케이션 과정에 개입하거나 상호 작용 내용을 녹음하거나 저장할 수 있는
                  기능이 없다는 것입니다. 모든 대화, 통화 및 이메일은 Microsoft Teams 및 Outlook의 안전한 범위 내에서 직접 이루어지며, Microsoft 365의 강력한 보안 및 개인정보 보호 표준을
                  엄격히 준수합니다. 이는 TeamConnect가 통신 능력을 향상시키면서도 데이터의 기밀성과 무결성을 완벽하게 보존함을 보장합니다.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            데이터 정책 및 관행에 대한 자세한 통찰을 얻고 싶다면 {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>TeamConnect 사용자 가이드</Body1Strong></Link> 또는 
              귀하의 조직 IT 부서의 <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>에 문의하여
              귀하의 설정과 관련된 구체적인 사항을 상의하십시오.
          </p>
        </Body1>        
        <p></p>  
        <Subtitle1 className={styles.fontColor}>우리는 듣고 있습니다: 여러분의 피드백과 아이디어를 공유하세요</Subtitle1>
        <Body1>
          <p>
            여러분의 통찰력이 TeamConnect의 발전을 이끕니다. 훌륭한 기능 아이디어로 가득 차 있든 현재 경험에 대한 피드백이 있든, 
            우리는 항상 귀를 기울입니다. 여러분의 의견은 우리가 최상의 서비스를 제공할 수 있도록 하는 지속적인 노력에서 매우 소중합니다.
          </p>
        </Body1>      
        <Subtitle2>피드백 및 기능 제안</Subtitle2>
        <Body1>
          <p>
            공유할 준비가 되셨나요? 우리의 {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              피드백 페이지
            </Link>
            로 이동하십시오. 새로운 기능에 대한 제안이든 기존 기능에 대한 피드백이든, 양식에서 적절한 옵션을 선택하기만 하면 됩니다.
            여러분의 기여는 TeamConnect의 미래를 형성하는 데 도움을 주며, 여러분의 요구를 충족시키고 뛰어넘도록 보장합니다.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
