import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();

  return (
    <>
      <Title3 className={styles.fontColor}>Centre d'aide et FAQ de TeamConnect</Title3>
        <p><Body1>
          Bienvenue au Centre d'aide de TeamConnect, conçu pour soutenir votre expérience avec notre application complète d'annuaire d'entreprise. Ici, vous trouverez des ressources pour optimiser votre utilisation de TeamConnect au sein de Microsoft Teams, améliorant la communication à travers votre réseau.
        </Body1></p>

        <Subtitle2>Guide de référence rapide</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Capacités de recherche améliorées : </Body1Strong>
              Trouvez rapidement les bons collègues, départements et contacts essentiels avec nos puissants outils de recherche.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Organigrammes visuels : </Body1Strong>
              Naviguez dans la hiérarchie de votre organisation et comprenez les lignes de reporting en toute simplicité grâce à des organigrammes intuitifs.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Outils adaptatifs pour les besoins actuels : </Body1Strong>
              Utilisez des fonctionnalités modernes adaptées aux exigences des lieux de travail contemporains.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Optimiser votre expérience</Subtitle2>
        <Body1>
          <p>
            TeamConnect révolutionne la manière dont vous interagissez au sein de votre structure organisationnelle, transformant les communications quotidiennes en engagements stratégiques.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Obtenir de l'aide & FAQ</Subtitle1>
        <Body1>
          <p>
            Trouvez des réponses aux questions courantes sur les fonctionnalités et les paramètres de TeamConnect dans cette section dédiée. Que vous naviguiez dans l'application, personnalisiez les paramètres ou recherchiez des solutions à des défis, notre Centre d'aide est là pour vous assister efficacement.
          </p>
        </Body1>
        <Body1>
          <p>
            Pour un soutien plus approfondi ou des conseils spécifiques, veuillez consulter le <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>Guide de l'utilisateur de TeamConnect</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        <Subtitle2>Utilisation des FAQ de TeamConnect</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Impossible de trouver TeamConnect dans la barre latérale de Microsoft Teams ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Si TeamConnect n'est pas visible ou a disparu de votre barre latérale Microsoft Teams, vous pouvez facilement l'épingler pour un accès rapide. Suivez simplement ces étapes simples :
                  <ol>
                    <li className={styles.bulletBottomMargin}>Ouvrez Microsoft Teams et cliquez sur l'icône '...' (+ Applications) en bas de la barre latérale.</li>
                    <li className={styles.bulletBottomMargin}>Recherchez 'TeamConnect' dans la barre de recherche en haut de la fenêtre contextuelle.</li>
                    <li className={styles.bulletBottomMargin}>Cliquez avec le bouton droit sur l'application TeamConnect dans les résultats de recherche et sélectionnez 'Épingler'.</li>
                  </ol>
                  TeamConnect sera maintenant épinglé à votre barre latérale, le rendant accessible en un clic !
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Vous rencontrez des problèmes techniques avec TeamConnect ? Voici comment obtenir de l'aide.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Si vous rencontrez des problèmes techniques avec TeamConnect dans Microsoft Teams, l'équipe de support informatique de votre organisation est là pour vous aider. 
                  Contactez-les à <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, et ils vous assisteront rapidement.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Vous ne trouvez pas vos détails d'employé ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Si votre profil semble un peu vide avec seulement votre nom et votre adresse email affichés, il est probable que votre équipe RH n'ait pas encore ajouté le reste de vos détails d'employé à TeamConnect. 
                  Pas de souci ! Un simple message à votre équipe de support RH mettra les choses en mouvement. Ils sont prêts à compléter votre profil avec tous les détails importants. 
                  Contactez-les à <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> et regardez votre profil prendre vie !
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Quelles options linguistiques sont disponibles dans TeamConnect ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect embrasse la diversité mondiale de Microsoft Teams en supportant toutes ses langues.
                  Que vous préfériez l'arabe ou le grec, le japonais ou le suédois, vous avez un monde d'options à portée de main, incluant :
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arabe, bulgare, chinois (simplifié et traditionnel), croate, tchèque, danois, néerlandais, anglais, estonien, finnois,
                      français, allemand, grec, hébreu, hongrois, indonésien, italien, japonais, coréen, letton, lituanien, norvégien (Bokmål),
                      polonais, portugais (Brésil et Portugal), roumain, russe, serbe (latin), slovaque, slovène, espagnol, suédois, thaï,
                      turc, ukrainien, vietnamien.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Pour personnaliser votre expérience TeamConnect à votre langue préférée, veuillez ajuster les paramètres de langue directement dans Microsoft Teams,
                  car TeamConnect s'intègre pleinement à ces paramètres :
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Accédez à vos paramètres Microsoft Teams en cliquant sur les trois points à côté de votre photo de profil en haut, puis sélectionnez 'Apparence et accessibilité'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Défilez jusqu'à la section 'Langue' et sélectionnez votre langue préférée dans le menu déroulant.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Confirmez vos modifications en cliquant sur le bouton 'Enregistrer et redémarrer' pour appliquer les nouveaux paramètres de langue.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Note : </Body1Strong>
                <Body1>
                  Les modifications apportées à vos paramètres de langue dans Microsoft Teams mettront également à jour votre expérience TeamConnect de manière transparente.
                  Soyez conscient que modifier la langue dans Teams peut également ajuster votre format de date et d'heure. Actuellement,
                  TeamConnect évolue pour l'écriture de droite à gauche. Nous sommes enthousiastes à propos de ces améliorations
                  et vous tiendrons informés dès qu'elles seront disponibles.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Comment puis-je changer le thème dans TeamConnect ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect se synchronise avec vos préférences de thème Microsoft Teams, y compris les options Lumière, Sombre, et Haut Contraste. Pour personnaliser votre thème :
                  <ol>
                    <li className={styles.bulletBottomMargin}>Accédez à vos paramètres Microsoft Teams en cliquant sur les trois points à côté de votre image de profil en haut.</li>
                    <li className={styles.bulletBottomMargin}>Sélectionnez 'Apparence et accessibilité' pour explorer les options de thème.</li>
                    <li className={styles.bulletBottomMargin}>Choisissez votre thème préféré parmi les options disponibles pour mettre à jour instantanément votre interface TeamConnect pour correspondre.</li>
                  </ol>
                  Votre choix de thème dans Microsoft Teams sera automatiquement appliqué à TeamConnect, assurant une expérience visuelle cohérente à travers votre espace de travail.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Explorer les boutons d'action de contact de TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Partout dans TeamConnect, où que vous trouviez les coordonnées d'un employé, vous découvrirez également nos boutons d'accès rapide pratiques. Ces outils pratiques 
                  sont conçus pour rationaliser votre communication au sein de l'écosystème Microsoft, vous assurant que vous n'êtes jamais qu'à un clic de la connexion.
                  Voici votre guide complet pour utiliser efficacement ces boutons :
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icone de discussion :</Body1Strong> Cliquez pour démarrer une discussion instantanée Microsoft Teams. Si vous devez revenir à TeamConnect après avoir commencé une discussion, utilisez simplement la flèche arrière dans l'application de bureau, le bouton arrière dans l'application mobile, ou le bouton arrière de votre navigateur.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icone d'email :</Body1Strong> Ouvre un nouveau brouillon d'email Outlook avec l'adresse du destinataire prête dans le champ "À :". Si Outlook ne se lance pas, il vaut la peine de vérifier auprès de votre département informatique pour toute restriction.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icone d'appel vidéo :</Body1Strong>Initie un appel vidéo en face à face en utilisant Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icone d'appel :</Body1Strong> Démarre un appel vocal Microsoft Teams, rendant les discussions rapides sans couture.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icone de l'organigramme :</Body1Strong> Révèle la place de l'employé dans la hiérarchie organisationnelle, incluant leur manager et les rapports directs.</li>
                    <li><Body1Strong>Icone Plus (...) :</Body1Strong> Dévoile d'autres options :
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Rencontrer maintenant :</Body1Strong> Ouvre Microsoft Teams pour démarrer une réunion immédiate avec l'employé.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Nouvelle réunion :</Body1Strong> Vous dirige vers Microsoft Teams pour planifier une nouvelle réunion.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Ajouter un contact :</Body1Strong>Ajoute les coordonnées de l'employé de TeamConnect à votre nouvelle application Personnes Microsoft Teams, enrichissant votre fonction d'appel.</li>
                      </ul>
                    </li>
                  </ul>
                  Ces boutons intuitifs sont conçus pour vous assurer de pouvoir connecter efficacement avec vos collègues, que ce soit pour une discussion rapide, un email, ou pour planifier des réunions futures.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Passer des appels via TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Vous cherchez à passer un appel ? TeamConnect simplifie votre communication en s'intégrant à Microsoft Teams. 
                  Cliquez simplement sur le numéro de téléphone souhaité dans TeamConnect, et voilà, l'interface d'appel de Microsoft Teams apparaît, 
                  prête pour vous de composer de manière transparente.
                  <p>
                  <Body1Strong>Note : </Body1Strong> 
                  Vous rencontrez des problèmes pour faire des appels ? Cela pourrait être dû aux politiques informatiques de votre organisation ou à l'utilisation 
                  d'un système téléphonique autre que le système téléphonique Microsoft Teams. Prendre contact avec votre département informatique à <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, peut éclaircir la situation.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Y a-t-il des coûts associés aux appels via TeamConnect ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect améliore votre expérience d'appel en tirant parti de Microsoft Teams. Voici ce que vous devez savoir sur les coûts liés aux appels :
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Pour les appels vers les téléphones de travail des employés, les téléphones des départements ou les hotlines au sein de votre organisation (numéros internes), 
                      il n'y a généralement <Body1Strong>aucun coût</Body1Strong> lors de l'utilisation du système téléphonique de Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Pour les appels externes, tels que ceux vers des téléphones mobiles ou des lignes externes, les <Body1Strong>tarifs standards</Body1Strong> du système téléphonique de votre organisation s'appliquent. 
                      Ces tarifs varient selon votre plan de service spécifique avec Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Note :</Body1Strong> Si vous rencontrez des problèmes ou avez des questions concernant les frais d'appel, il est préférable de consulter votre 
                    département informatique à <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> pour des informations détaillées 
                    adaptées à la configuration et aux politiques de votre organisation.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Vous constatez un décalage avec votre statut de présence 'Absent du bureau' dans TeamConnect ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Vous avez remarqué que votre statut de présence 'Absent du bureau' de Microsoft 365 n'est pas correctement reflété dans TeamConnect ?
                  Nous avons ce qu'il vous faut. La fonction de synchronisation du statut de présence 'Absent du bureau' a déjà été mise en œuvre dans TeamConnect.
                  Nous attendons actuellement le déploiement de cette fonctionnalité par Microsoft. Une fois cela fait, votre statut de présence 'Absent du bureau'
                  sera précisément reflété dans TeamConnect, s'alignant avec les indicateurs de présence de Microsoft Teams pour une expérience transparente.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>FAQs sur l'annuaire des employés</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Introduction à l'annuaire des employés :</Body1Strong> Naviguez dans le paysage professionnel de votre organisation avec l'annuaire des employés, 
                votre centre névralgique pour des informations détaillées sur les employés, les dynamiques de travail et la connectivité directe aux profils de vos collègues et aux relations managériales.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Besoin d'ajouter, mettre à jour ou corriger vos données d'employé ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Pour ajouter, mettre à jour ou corriger vos données d'employé dans TeamConnect, veuillez contacter l'équipe de support RH de votre organisation. 
                  Ils sont prêts à aider à <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Que se passe-t-il lorsque je clique sur le nom d'un employé dans l'annuaire ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Cliquer sur le nom d'un employé dans l'annuaire des employés de TeamConnect ouvre une page de profil détaillée pour cette personne. Ce profil fournit des informations complètes sur l'employé, incluant mais non limité à, leur intitulé de poste, département, lieu de travail, informations de contact, et plus encore. Il est conçu pour vous donner un aperçu complet du rôle professionnel de l'employé et de sa place au sein de l'organisation, facilitant ainsi votre compréhension de ses responsabilités et comment vous pourriez collaborer avec lui.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Quelles informations sont incluses dans le profil d'un employé ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Chaque profil d'employé dans TeamConnect est un aperçu complet, offrant une richesse d'informations sur la vie professionnelle de l'individu. 
                  Voici ce que vous trouverez :
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Détails de base :</Body1Strong> Nom, intitulé de poste, département et lieu de la filiale.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Structure hiérarchique :</Body1Strong> Informations sur les rapports directs et fonctionnels, 
                      y compris les managers de ligne et fonctionnels.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Statut professionnel :</Body1Strong> Statut de travail actuel tel que En congé, Déplacement, 
                      Voyage d'affaires, etc.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lieu de travail & Heure locale :</Body1Strong> Lieu de travail actuel de l'employé et 
                      fuseau horaire local.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Détails de contact :</Body1Strong> Téléphone professionnel, numéro de portable et adresse email.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Adresse du bureau :</Body1Strong> Lieu complet du bureau incluant le nom du bâtiment,
                      adresse, et carte Google.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>À propos :</Body1Strong> Une brève introduction personnelle ou biographie professionnelle.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Relations de travail :</Body1Strong> Détails de la structure hiérarchique de l'employé et des relations au sein de l'équipe.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Liens sociaux & Langues :</Body1Strong> Liens vers les profils sociaux professionnels
                      et compétences linguistiques.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Compétences :</Body1Strong> Liste des compétences professionnelles et domaines d'expertise.</li>
                  </ul>
                  De plus, le profil offre des options interactives pour se connecter via chat, email, appel vidéo, ou planifier des réunions, ainsi que 
                  un accès rapide au schéma organisationnel pour un contexte d'équipe plus large.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Comment puis-je mettre à jour les informations de mon profil ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Personnaliser votre profil est facile dans TeamConnect. Voici comment vous pouvez le faire :
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Naviguez vers l’onglet <Body1Strong>Employé</Body1Strong> et cliquez sur votre <Body1Strong>Avatar</Body1Strong> pour ouvrir votre profil.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Dans le coin supérieur gauche, vous trouverez le lien <Body1Strong>"Modifier votre profil"</Body1Strong>. 
                        Cliquer dessus ouvrira la boîte de dialogue de modification pour votre profil.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Ici, vous pouvez améliorer votre profil en ajoutant ou en mettant à jour votre section <Body1Strong>À propos de vous</Body1Strong>, les liens vers les réseaux sociaux, et votre numéro de téléphone. 
                        Cela facilite la connexion ou le suivi par vos collègues.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        N’oubliez pas de presser le bouton <Body1Strong>Enregistrer</Body1Strong> pour appliquer vos changements.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Descendez dans la dialogue pour ajouter vos <Body1Strong>Langues</Body1Strong> et <Body1Strong>Compétences</Body1Strong>, 
                        ainsi que vos <Body1Strong>Compétences</Body1Strong>. Partager ces informations aide vos collègues à comprendre 
                        vos domaines d'expertise et les langues dans lesquelles vous pouvez communiquer.
                      </li>
                    </ol>
                  Mettre à jour votre profil permet non seulement de garder vos informations à jour, mais aussi de favoriser de meilleures connexions au sein de votre organisation.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Mise à jour de votre lieu de travail, de votre poste et de votre fuseau horaire dans TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Garder à jour les informations de votre lieu de travail, de votre poste et de votre fuseau horaire est crucial pour une collaboration sans faille dans Microsoft Teams.
                  Ces mises à jour sont instantanément reflétées dans votre profil et vos annonces TeamConnect, permettant à vos collègues de rapidement comprendre votre lieu de travail
                  et les meilleurs moments pour se connecter avec vous, améliorant ainsi le travail d'équipe à travers différentes régions et fuseaux horaires.
                  <p></p>
                  Prêt à mettre à jour vos détails ? C'est facile dans TeamConnect! Rendez-vous dans 'Paramètres' dans le menu principal et naviguez jusqu'à 'Paramètres généraux'. 
                  Ici, vous pouvez facilement mettre à jour votre lieu de travail et votre fuseau horaire, et spécifier votre poste pour la journée. N'oubliez pas de sauvegarder vos changements pour 
                  tenir tout le monde informé et assurer une collaboration efficace, peu importe votre emplacement !
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Vous avez remarqué quelque chose d'anormal dans le profil d'un collègue ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Si vous remarquez des détails dans le profil d'un collègue qui ne semblent pas tout à fait corrects, la meilleure première étape est de lui donner 
                  un coup de pouce amical. Un petit chat ou un email devrait faire l'affaire ! Si pour une raison quelconque les informations ne sont pas mises à jour, ne vous inquiétez pas—juste 
                  contactez votre équipe de support RH à <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, et ils seront heureux 
                  d'intervenir et de régler les choses.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Comprendre les groupes de tags : Tous, Favoris, Présents, & Nouveaux Employés
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tous :</Body1Strong> Ce tag par défaut affiche chaque employé de votre organisation, 
                      offrant une vue complète de l'équipe.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoris :</Body1Strong> Cliquez ici pour filtrer votre vue sur les collègues que vous avez 
                      marqués comme 'Favoris', facilitant ainsi la connexion rapide avec les contacts clés.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Présents :</Body1Strong> Ce tag indique les employés activement au travail, les distinguant 
                      de la simple présence en ligne sur Microsoft Teams. Lorsqu'ils sont présents, les employés signalent qu'ils sont au travail et partagent leur emplacement de travail actuel 
                      durant la journée. Cela peut inclure une variété de lieux de travail tels que Bureau, Bureau sur le terrain, Bureau à domicile, Lieu de travail à distance, Lieu de travail chez le client, 
                      Bureau privé du client, Zone publique, Déplacement, Voyage d'affaires, ou même Formation. Ainsi, le statut 'Présent' fournit une indication claire 
                      de l'activité au travail, offrant aux collègues une compréhension précise du contexte de travail actuel et de la disponibilité de chacun.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nouveaux Employés :</Body1Strong> Repérez les nouveaux visages dans TeamConnect avec ce tag, mettant en évidence 
                      les employés qui ont été ajoutés à l'application au cours des 30 derniers jours. Typiquement, cela correspond aux ajouts récents à votre organisation, aidant 
                      ainsi à accueillir les nouveaux membres de l'équipe.
                    </li>
                  </ul>
                  Ces groupes de tags sont conçus pour simplifier la navigation et améliorer votre expérience TeamConnect, vous assurant de toujours 
                  trouver et de vous connecter efficacement avec vos collègues.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Décoder les icônes à côté de la boîte de recherche
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  L'annuaire des employés de TeamConnect est conçu pour être polyvalent et facile à utiliser, comportant quatre icônes qui offrent différentes vues 
                  et un accès rapide aux détails clés. Voici ce que chaque icône révèle :
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vue Liste :</Body1Strong> Votre vue de prédilection, présentant tous les membres de l'organisation 
                      dans une liste simple.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vue Petite Carte :</Body1Strong> Une alternative compacte, cette vue transforme la 
                      liste en cartes d'employés de petite taille.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vue Grande Carte :</Body1Strong> Optez pour plus de détails avec des cartes plus grandes, qui incluent 
                      des options de contact supplémentaires comme les numéros de téléphone et mobile pour une numérotation facile.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organigramme :</Body1Strong> Visualisez la structure de l'entreprise avec la vue Organigramme, 
                      parfaite pour comprendre les relations hiérarchiques et naviguer dans le paysage organisationnel.</li>
                  </ol>
                    Basculez entre ces icônes pour trouver la vue qui convient le mieux à vos besoins à ce moment, que vous recherchiez des informations rapides ou des insights plus approfondis.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Pour des recherches rapides de collègues, où devrais-je regarder ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Vous cherchez un collègue ? Dirigez-vous simplement vers la boîte de recherche TeamConnect, située en haut à droite à côté de l'icône de l'organigramme. 
                  Commencez à taper leur nom, et observez les résultats se raffiner à chaque lettre. Souvenez-vous, pour les recherches dans TeamConnect, ignorez la barre de recherche 
                  de Microsoft Teams en haut—notre boîte de recherche spéciale est la voie à suivre !
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Comment fonctionne la recherche alphabétique pour trouver des employés ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  L'annuaire des employés de TeamConnect inclut une fonctionnalité de recherche alphabétique pratique pour vous aider à localiser rapidement les employés par leur prénom. 
                  Cliquez simplement sur une lettre de l'alphabet affichée en haut de l'annuaire, et TeamConnect vous montrera instantanément tous les employés 
                  dont le prénom commence par cette lettre. Pour revenir à la liste complète des employés, il suffit de désélectionner la lettre mise en surbrillance. Cette fonction intuitive 
                  rend la navigation à travers de grandes listes d'employés facile, vous assurant de trouver des collègues rapidement et efficacement.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Comment puis-je ajuster le nombre d'employés affichés par page ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect vous permet de personnaliser le nombre de profils d'employés que vous visualisez par page, ce qui facilite la navigation dans l'annuaire des employés. 
                  Au bas de chaque page, vous trouverez des options de pagination : 10, 50, 100, 500, 1000, et 5000. Le réglage par défaut affiche 10 profils par page. 
                  Pour voir plus de profils sur une seule page, sélectionnez simplement votre nombre préféré parmi ces options. Cette fonctionnalité offre une flexibilité dans la façon dont vous parcourez 
                  l'annuaire, vous permettant d'adapter l'affichage à vos besoins ou préférences spécifiques.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Comment puis-je trier les informations des employés dans l'Affichage Liste ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Dans l'Affichage Liste de l'annuaire des employés de TeamConnect, vous avez la flexibilité de trier les informations des employés selon vos besoins. Les colonnes—Nom de l'employé, 
                  Titre du poste, Département, Lieu de bureau, et Lieu de travail—sont toutes triables. Cliquez simplement sur l'en-tête de la colonne par laquelle vous souhaitez trier. Un clic triera 
                  la colonne dans l'ordre croissant, et un second clic la triera dans l'ordre décroissant. Par défaut, l'annuaire est trié par Nom de l'employé dans l'ordre croissant, 
                  basé sur le prénom. Cette fonctionnalité de tri vous aide à organiser rapidement et à visualiser les détails des employés de manière qui répond le mieux à vos objectifs, que vous 
                  recherchiez une personne spécifique ou que vous ayez besoin de regrouper les employés par un critère particulier comme le département ou le lieu.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>FAQs du répertoire des départements</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Introduction au répertoire des départements :</Body1Strong> Le répertoire des départements est votre porte d'entrée pour comprendre la structure complexe de votre 
              organisation, offrant une vue claire des hiérarchies départementales, du leadership managérial et des voies pour une communication départementale sans heurts.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>

            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Comment fonctionnent les étiquettes 'Tous', 'Favoris' et 'Votre département' dans le répertoire des départements ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Dans le répertoire des départements de TeamConnect, les étiquettes vous aident à naviguer rapidement et à filtrer les informations départementales. Voici ce que chaque étiquette représente :
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tous :</Body1Strong> Cette étiquette affiche tous les départements au sein de votre organisation, fournissant un
                      aperçu complet.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoris :</Body1Strong> Utilisez cette étiquette pour voir une liste organisée des départements que vous avez marqués
                      comme 'Favoris', facilitant l'accès aux départements avec lesquels vous interagissez fréquemment.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Votre département :</Body1Strong> Cette étiquette filtre l'affichage pour montrer uniquement votre département,
                      permettant un accès rapide à vos informations départementales immédiates et mises à jour.</li>
                  </ul>
                  Ces étiquettes sont conçues pour rationaliser votre navigation dans le répertoire des départements, améliorant votre capacité à trouver et à interagir avec les informations 
                  départementales de manière efficace.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Comprendre les vues du répertoire des départements dans TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Naviguer dans le répertoire des départements dans TeamConnect est simplifié avec plusieurs options d'affichage pour répondre à vos préférences. Chaque vue offre un moyen unique d'explorer les informations départementales complètes, incluant le nom du département, le gestionnaire du département, le département parent, le téléphone du département, la ligne directe du département et l'email du département. Voici un aperçu des vues disponibles :
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vue Liste :</Body1Strong> La configuration par défaut qui liste soigneusement tous les départements, fournissant un
                      aperçu clair et concis.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vue Grande Carte :</Body1Strong> Passez à cette vue pour voir des cartes détaillées des départements, offrant
                      un résumé élargi des informations clés de chaque département.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vue Organigramme :</Body1Strong> Pour une représentation visuelle de la structure et des relations départementales,
                      la vue Organigramme illustre comment chaque département s'intègre dans la hiérarchie organisationnelle plus large.
                    </li>
                  </ul>
                  Pour basculer entre ces vues, utilisez simplement les icônes à côté de la boîte de recherche en haut du répertoire des départements. Chaque vue est conçue pour vous aider à accéder et comprendre les détails départementaux avec facilité, vous assurant de trouver les informations dont vous avez besoin dans le format le plus adapté pour vous.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Comment utiliser la zone de recherche dans l'annuaire du département
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Localisez sans effort n’importe quel département au sein de TeamConnect en utilisant la zone de recherche dédiée située juste à côté des icônes de vue en haut de l'annuaire du département. Commencez simplement à taper le nom du département que vous recherchez, et observez alors que l'annuaire se précise pour n'afficher que les correspondances pertinentes. Cette fonctionnalité est spécialement conçue pour vous aider à éviter de devoir fouiller manuellement dans tout l'annuaire, amenant ainsi le département dont vous avez besoin directement à portée de main. Rappelez-vous, cette fonctionnalité de recherche est unique à l'annuaire du département de TeamConnect, alors assurez-vous d'utiliser cette zone de recherche plutôt que la recherche principale de Microsoft Teams située tout en haut de l'interface des Teams pour des requêtes spécifiques au département.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Comment fonctionne le filtre alphabétique dans l'annuaire du département ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Simplifiez votre recherche dans l'annuaire du département avec le filtre alphabétique situé en haut. Cette fonctionnalité vous permet de réduire rapidement les départements en sélectionnant une lettre. Une fois que vous choisissez une lettre, TeamConnect filtre et affiche uniquement les départements dont les noms commencent par la lettre sélectionnée, facilitant la recherche de ce que vous cherchez sans avoir à défiler à travers toute la liste. Pour revenir à la vue complète du département, désélectionnez simplement la lettre en surbrillance. Cette navigation alphabétique intuitive vous assure de pouvoir localiser efficacement n’importe quel département par sa lettre initiale.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Comment puis-je contacter le responsable du département ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Contacter un responsable de département dans TeamConnect est simple. Localisez simplement le département qui vous intéresse et cliquez sur le nom du responsable. Cette action déclenchera un popover affichant les options de contact du responsable avec les icônes d'accès rapide familières :
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icône de discussion :</Body1Strong> Pour démarrer un message instantané via le chat de Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icône d'email :</Body1Strong> Pour ouvrir un nouvel email qui leur est adressé dans Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icône d'appel vidéo :</Body1Strong> Pour initier un appel vidéo via Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icône d'appel :</Body1Strong> Pour commencer un appel vocal en utilisant Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icône Plus (...) :</Body1Strong> Pour des options supplémentaires comme planifier une réunion ou ajouter leurs informations de contact à votre liste Teams.
                    </li>
                  </ul>
                  Ces icônes fournissent une méthode transparente pour contacter directement les responsables de département depuis TeamConnect, garantissant une communication efficace au sein de votre organisation.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Comment puis-je voir ou contacter les employés au sein d'un département ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Pour explorer qui est qui au sein d'un département dans TeamConnect, il suffit de cliquer sur les trois points à côté du nom du département. Cliquer dessus 
                  affichera des options pour d'autres actions :
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Voir les employés dans ce département :</Body1Strong> Sélectionnez cette option pour afficher une liste de tous 
                      les employés associés au département. Le nombre à côté de cette option indique combien d'employés se trouvent dans le département, fournissant un décompte rapide.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organigramme du département :</Body1Strong> Si vous souhaitez comprendre la hiérarchie du département, 
                      choisissez 'Organigramme du département' pour révéler la disposition structurelle, montrant la relation entre les départements parents, le département concerné, 
                      et tous sous-départements. Cette représentation visuelle vous aide à naviguer et à comprendre le cadre organisationnel, facilitant l'identification et 
                      le contact des membres spécifiques du département.
                    </li>
                    <li><Body1Strong>Département {'>'} Employé :</Body1Strong> Après avoir consulté les employés au sein d'un département, utilisez ce fil d'Ariane pour naviguer facilement 
                      retour à la liste des départements, maintenant une expérience d'exploration fluide au sein de TeamConnect.
                    </li>
                  </ul>
                  Ces fonctionnalités vous permettent non seulement de voir la liste des employés d'un département donné mais aussi offrent un chemin pour les contacter directement à travers 
                  les outils de communication de TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Découvrir les détails approfondis des départements dans TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Plongez dans les spécificités de n'importe quel département avec un simple clic sur son nom dans TeamConnect. Un dialogue détaillé se déroule, offrant une riche tapisserie d'informations 
                  qui peint une image complète du rôle et des ressources du département au sein de votre organisation. Vous trouverez tout, des chefs de file et des identifiants financiers jusqu'aux canaux de 
                  communication et liens sociaux. Cette fonctionnalité est votre porte d'accès à :
                  <ul>
                    <li className={styles.bulletBottomMargin}>Le cœur du département – son but, ses gens, et ses indicateurs de performance.</li>
                    <li className={styles.bulletBottomMargin}>Les voies de contact – lignes directes vers les figures clés du département et plateformes de communication.</li>
                    <li className={styles.bulletBottomMargin}>La toile organisationnelle – comprendre comment le département s'intègre à la structure de l'entreprise plus large.</li>
                  </ul>
                  Que ce soit pour une question rapide ou une immersion profonde dans les données départementales, TeamConnect vous équipe avec les outils pour vous connecter et comprendre les écosystèmes uniques 
                  au sein de votre organisation.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Curieux de l'absence d'adresses des départements dans TeamConnect ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Trouver la localisation d'un département peut parfois sembler une chasse au trésor sans carte. Dans TeamConnect, vous ne trouverez généralement pas d'adresse dédiée pour chaque département. Voici pourquoi ce détail cartographique est souvent omis :
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Plusieurs localisations :</Body1Strong> Beaucoup de départements ne sont pas confinés à un seul espace ; ils 
                      prospèrent à travers divers bureaux et géographies.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Structure fluide :</Body1Strong> Le paysage organisationnel change constamment, avec 
                      des départements qui évoluent, fusionnent ou même se dissolvent en alignement avec les changements stratégiques.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Collaboration interdépartementale :</Body1Strong> Les espaces de travail modernes bourdonnent d'esprit collaboratif, souvent en mélangeant les talents de divers départements au sein d'espaces partagés.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Culture de travail à distance :</Body1Strong> Avec la flexibilité du travail à distance, les ancrages départementaux ne sont pas toujours déposés dans des emplacements de bureaux physiques.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nuances légales :</Body1Strong> Les départements orbitent généralement autour de la structure légale de l'entreprise plutôt que d'avoir leurs propres adresses autonomes.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Rationalisation opérationnelle :</Body1Strong> Garder l'accent sur le travail d'équipe dynamique, et non sur des adresses statiques, aide à maintenir l'agilité opérationnelle.
                    </li>
                  </ul>
                  Il s'agit d'embrasser une approche sans frontières en matière de travail d'équipe et d'efficacité. TeamConnect reflète cette philosophie en mettant l'accent sur les connexions plutôt que sur les lieux.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Les informations de votre département sont-elles manquantes ou obsolètes ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Des éditeurs de département sont désignés au sein de votre organisation pour ajouter, mettre à jour ou corriger les informations spécifiques au département dans TeamConnect.
                  Si vous n'êtes pas sûr de qui est votre éditeur de département ou si certaines informations nécessitent l'intervention de l'équipe de soutien RH,
                  veuillez contacter <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> pour obtenir des conseils.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>FAQs du répertoire des bureaux</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Introduction au répertoire des bureaux :</Body1Strong> Découvrez la colonne vertébrale de la présence physique de votre organisation à travers le répertoire des bureaux, 
                qui englobe les détails essentiels des bureaux, les spécificités opérationnelles, et un aperçu complet des départements et du personnel qui définissent chaque emplacement.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Naviguer par étiquettes dans le répertoire des bureaux
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Trouvez votre chemin dans le répertoire des bureaux en toute facilité en utilisant notre système d'étiquettes conçu pour un accès rapide au bon endroit. Voici comment chaque étiquette peut faciliter votre recherche :
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tous :</Body1Strong> Affiche une liste complète des bureaux, offrant une vue d'ensemble 
                      des emplacements de votre organisation.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favoris :</Body1Strong> Vous aide à vous concentrer sur les emplacements de bureaux que vous fréquentez ou que vous devez 
                      surveiller de près, en les marquant comme 'Favoris'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Votre bureau :</Body1Strong> Cette étiquette personnelle filtre le répertoire pour vous montrer le 
                      lieu de bureau où vous êtes stationné, gardant votre base à portée de main.
                    </li>
                  </ul>
                  Que vous réserviez une salle de réunion ou planifiez une visite, ces étiquettes offrent une manière personnalisée et simplifiée d'interagir avec le paysage géographique de votre organisation.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Maîtriser les Vues de l'Annuaire des Bureaux dans TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Plongez dans la disposition organisationnelle des différents sites de votre entreprise avec les vues de l'Annuaire des Bureaux de TeamConnect. Chaque vue est conçue pour faciliter un aspect unique de la navigation et de la compréhension des bureaux :
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vue en Liste :</Body1Strong> Le réglage par défaut qui organise soigneusement tous les emplacements des bureaux dans une liste facile à lire.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vue Grande Carte :</Body1Strong> La Vue Grande Carte dans l'Annuaire des Bureaux enrichit votre vision avec une présentation détaillée de chaque bureau. Lors de l'interaction avec une carte de bureau, vous trouverez de nombreuses informations et options d'action dans le menu "Plus (...)":
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Voir les Départements :</Body1Strong> Accédez directement à une liste de tous les départements situés au sein du bureau.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Voir les Employés :</Body1Strong> Accédez à une liste complète des employés affectés au bureau, facilitant le contact direct ou l'exploration du personnel du bureau.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Organigramme de l'Entité Commerciale :</Body1Strong> Visualisez la place du bureau au sein du contexte organisationnel plus large pour une compréhension claire des structures hiérarchiques.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Contacts Essentiels :</Body1Strong> Identifiez les contacts clés du bureau pour une communication simplifiée.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Congés Publics du Bureau :</Body1Strong> Restez informé des jours fériés spécifiques au bureau pour planifier vos horaires en conséquence.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Horaires de Travail du Bureau :</Body1Strong> Consultez les heures d'ouverture du bureau pour coordonner vos visites ou appels pendant leurs heures d'activité.
                        </li>
                      </ol>
                      Cette vue fournit non seulement un instantané visuel et informatif mais aussi un accès rapide à des insights plus profonds et des actions directes liées au bureau, le tout soigneusement regroupé sous l'option "Plus (...)" pour votre commodité.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vue Organigramme :</Body1Strong> Visualisez la structure hiérarchique des bureaux parents et sous-bureaux pour comprendre l'interconnexion des différents emplacements de bureaux au sein de votre organisation.
                    </li>
                  </ul>
                  Basculer entre ces vues en utilisant les icônes à côté de la boîte de recherche pour personnaliser votre expérience de navigation selon vos besoins immédiats.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Trouver Rapidement des Bureaux avec la Fonction de Recherche de TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Cibler le bon emplacement de bureau au sein de TeamConnect est facilité par la fonction de recherche dédiée. Positionnée de manière proéminente à côté des icônes de vue, la boîte de recherche est votre outil pour localiser les bureaux par nom. Au fur et à mesure que vous tapez, l'annuaire affiche seulement les bureaux correspondant à votre saisie, optimisant votre processus de recherche. Il est important d'utiliser cette boîte de recherche dans TeamConnect pour obtenir les résultats les plus précis et pertinents, par opposition à la recherche générale dans Microsoft Teams. Avec cette fonctionnalité de recherche ciblée, vous pouvez naviguer rapidement vers n'importe quel bureau dans le réseau de votre organisation.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Naviguer dans le répertoire de bureau par ordre alphabétique
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Si vous préférez rechercher par ordre alphabétique, notre filtre alphabétique est un moyen rapide de trouver des bureaux par nom. En haut du répertoire de bureau, vous verrez 
                  les lettres de A à Z. Cliquer sur une lettre filtre la vue pour afficher uniquement les bureaux commençant par cette lettre, simplifiant votre processus de recherche. Pour réinitialiser et voir tous les bureaux à nouveau, il suffit de désélectionner la lettre active. Cette fonctionnalité est particulièrement utile lorsque vous connaissez le nom du bureau mais avez besoin d'un moyen rapide de le localiser dans TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Révélation des profils de bureau complets dans TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Lancez-vous dans un voyage exploratoire au cœur de la présence physique de votre organisation avec les profils de bureau de TeamConnect. 
                  Cliquer sur un nom de bureau ouvre une boîte de dialogue de profil vibrante, débordante d'onglets remplis d'aperçus détaillés du bureau :
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vue d'ensemble :</Body1Strong> Plongez dans le cœur de l'identité du bureau avec des 
                      descripteurs détaillés, allant de la structure commerciale aux heures d'ouverture et à l'heure locale, capturant l'essence de l'environnement de bureau.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Contacts essentiels :</Body1Strong> Accédez aux contacts cruciaux pour les services d'urgence et 
                      le soutien externe, chacun à un clic via le système téléphonique de Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Contacts du bureau :</Body1Strong> Contactez les services de soutien internes facilement, avec 
                      les coordonnées et les options d'engagement direct à portée de main.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Jours fériés :</Body1Strong> Restez informé des jours fériés spécifiques au bureau, assurant que 
                      vos plans s'alignent avec les observances locales et les horaires de travail.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Heures de bureau :</Body1Strong> Planifiez vos interactions avec une connaissance précise de quand 
                      le bureau est animé d'activité ou se calme pour la journée.
                    </li>
                  </ul>
                  Que ce soit l'énergie bouillonnante d'Austin ou le hub stratégique de Singapour, chaque onglet offre une passerelle pour comprendre et se connecter avec les divers 
                  lieux qui composent la présence mondiale de votre entreprise.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Exploration des dynamiques de département dans un bureau
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Naviguer dans le réseau professionnel des départements d'un bureau se déroule avec aisance dans TeamConnect. Voici comment explorer les détails :
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Voir les départements dans ce bureau :</Body1Strong> Découvrez tous les départements qui 
                      constituent le tissu du bureau en sélectionnant cette option dans le menu Plus (...).
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Connaissance organisationnelle :</Body1Strong> Cliquez sur un département pour révéler 
                      son organigramme pour une représentation visuelle de sa structure, avec le fil d'Ariane vous guidant : Bureau {'>'} Département {'>'} Organigramme.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Approfondissement départemental :</Body1Strong> Explorez davantage un département en 
                      cliquant sur son menu Plus (...) à côté du nom du département. Ici, vous pouvez accéder à une liste de tous les employés au sein de ce département, 
                      directement dans le contexte du bureau.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Navigation par fil d'Ariane :</Body1Strong> Traversez les niveaux de manière transparente. 
                      Des employés aux départements ou à partir de l'organigramme, le fil d'Ariane (Bureau {'>'} Département {'>'} Employé {'>'} Organigramme) 
                      maintient votre parcours intuitif et sur la bonne voie.
                    </li>
                  </ul>
                  Les parcours complexes de TeamConnect vous invitent à explorer, comprendre et interagir avec chaque couche des départements de votre bureau, vous donnant
                  un pouvoir de navigation complet.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Se connecter avec le personnel du bureau dans TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Curieux de connaître les visages derrière un bureau ? La réponse est juste à un clic dans TeamConnect. Engagez-vous avec l'ellipse (trois points) à côté du nom 
                  de votre bureau choisi pour révéler une suite d'options interactives :
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Voir les employés dans ce bureau :</Body1Strong> Découvrez une liste de tous les professionnels
                      qui considèrent ce bureau comme leur lieu de travail. Le nombre affiché offre un décompte rapide des employés, vous invitant à plonger dans la 
                      communauté du bureau.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Options de contact :</Body1Strong> Chaque entrée d'employé est équipée d'icônes d'action rapide,
                      vous permettant d'initier des discussions, des appels ou même des vidéoconférences directement depuis TeamConnect, favorisant une communication 
                      intra-bureau sans faille.
                    </li>
                    <li><Body1Strong>Navigation :</Body1Strong> Pour revenir à la liste des bureaux plus large à partir d'une liste d'employés, cliquez simplement sur 
                      'Bureau' dans le fil d'Ariane (Bureau {'>'} Employé), et vous serez ramené à l'aperçu du bureau.
                    </li>
                  </ul>
                  Avec ces fonctionnalités, TeamConnect rend simple la visualisation de la répartition de la main-d'œuvre à travers les bureaux et permet de contacter 
                  sans effort, favorisant un lieu de travail connecté, peu importe la distance.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Vos informations de bureau sont-elles manquantes ou obsolètes ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Les mises à jour des informations de bureau dans TeamConnect peuvent être gérées par les éditeurs de bureau assignés. Si vous avez des doutes sur les éditeurs de bureau ou 
                  si vous avez besoin d'aide concernant des informations relevant de la compétence des RH, l'équipe de support RH est prête à 
                  assister à <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>

        <Subtitle2>FAQs sur les paramètres</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. L'importance de la précision des paramètres généraux dans TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Dans le paysage dynamique de l'environnement de travail d'aujourd'hui, rester connecté et coordonné est plus crucial que jamais. C'est là que la mise à jour de vos 
                  Paramètres Généraux dans TeamConnect joue un rôle essentiel :
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Sélection du lieu de travail :</Body1Strong> Capturez l'essence de votre cadre de travail en mettant à jour votre Lieu de travail pour qu'il reflète votre situation 
                        actuelle, que ce soit un passage de la tranquillité de votre bureau à domicile à l'agitation du bureau principal, ou même vous installer dans un espace public. 
                        Cette clarté sur votre lieu de travail offre aux collègues une insight non seulement sur les meilleures façons de vous contacter, mais aussi sur la nature des 
                        conversations qu'ils peuvent avoir avec vous, protégeant la confidentialité de discussions sensibles surtout lorsque vous êtes dans l'environnement ouvert 
                        d'un bureau client ou assis dans un salon d'aéroport.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Spécification du poste de travail :</Body1Strong> Partager votre ville de travail fait plus que combler les distances dans une configuration d'équipe à distance—cela ouvre également 
                        les portes à des rassemblements professionnels spontanés ou à des interactions sociales décontractées. Que ce soit pour un café rapide, un déjeuner tranquille, ou une réunion de collaboration, 
                        savoir que vous êtes dans la même ville peut transformer une journée ordinaire en une occasion de connections significatives en face à face. Cela non 
                        seulement renforce les liens d'équipe mais offre également une compréhension plus profonde des nuances locales qui pourraient influencer votre travail.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Précision du fuseau horaire :</Body1Strong> Alors que les équipes couvrent les continents, il est essentiel que votre fuseau horaire reflète 
                      votre localisation actuelle pour harmoniser les communications. L'heure locale de votre profil TeamConnect est directement influencée par ce paramètre, 
                      éliminant toute supposition pour les collègues essayant de vous contacter.
                    </li>
                  </ul>
                  Plongez dans 'Paramètres' {'>'} 'Paramètres Généraux' dans TeamConnect pour affiner ces détails. Quelques moments passés à mettre à jour vos paramètres peuvent 
                  considérablement améliorer la cohésion et l'efficacité de vos efforts collaboratifs à tous les niveaux.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Décryptage de la sélection de lieu de travail dans TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Choisir le bon réglage dans le menu déroulant de Workplace ajuste TeamConnect pour refléter précisément votre statut de travail actuel. Survolez l'icône d'information pour une explication détaillée de chaque option :
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Pause :</Body1Strong> Indique une absence du travail, que ce soit pour une pause ou la fin de la journée.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Bureau/Bureau sur le terrain :</Body1Strong> Signale une présence dans un espace de travail géré par l'entreprise.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Bureau à domicile :</Body1Strong> Travail à partir d'un espace de travail à domicile dédié.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lieu de travail distant/Lieu public :</Body1Strong> Travail à partir d'un environnement hors bureau, suggérant un besoin de communication flexible.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lieu de travail du client/Bureau privé :</Body1Strong> Sur site avec un client, avec une note sur les niveaux de confidentialité pour la sensibilité des conversations.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Commute/Voyage d'affaires/Formation :</Body1Strong> En déplacement ou engagé dans des activités de travail spécifiques hors du bureau.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Jour férié/Vacances/Divers congés :</Body1Strong> Officiellement hors service pour des raisons personnelles, de santé ou de vacances.</li>
                  </ul>
                  Sélectionner un cadre de lieu de travail approprié assure que votre équipe a le contexte nécessaire pour les interactions, en respectant la disponibilité et la confidentialité.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Navigation du statut 'Checked-In' dans TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  L'étiquette 'Checked-In' dans l'annuaire des employés est conçue pour afficher tous les employés actuellement au travail. Cette visibilité est déterminée par la sélection de lieu de travail que vous effectuez :
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Environnements de travail actifs :</Body1Strong> Les choix Bureau, Bureau sur le terrain, Bureau à domicile, Lieu de travail distant, Zone publique, Lieu de travail client,
                    Bureau privé client, Commute, Voyage d'affaires et Formation indiquent que vous êtes engagé dans le travail, vous marquant 'Checked-In' et activement visible pour les collègues.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Paramètres hors travail :</Body1Strong> Opter pour Checked-Out, Pause, Jour férié, Vacances, En congé, Congé de maternité, ou Congé maladie
                      vous retire de la liste des 'Checked-In', établissant des attentes claires pour la disponibilité et les temps de réponse.
                  </li>
                  </ul>
                  Cette fonctionnalité aide à délimiter les périodes de travail du temps personnel, facilitant la collaboration et la communication efficaces au sein de l'équipe.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Gestion des données et FAQ sur la confidentialité</Subtitle2>
          <Body1>
            <p>
              À l'ère numérique actuelle, comprendre comment vos données sont gérées est crucial. TeamConnect s'engage à être transparent et à appliquer des pratiques robustes de protection des données. 
              Ci-dessous, vous trouverez des informations sur la manière dont nous traitons, stockons et protégeons vos données au sein de TeamConnect, garantissant que votre espace de travail numérique est sécurisé et conforme.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Comment TeamConnect protège-t-il mes données personnelles ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect se consacre à la protection de vos informations personnelles avec une approche complète de la sécurité des données :</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Single Sign-On (SSO) :</Body1Strong> Pour renforcer la sécurité et simplifier l'expérience utilisateur, TeamConnect intègre 
                      un Single Sign-On (SSO) automatisé avec votre compte Microsoft 365, facilitant une gestion des accès sécurisée et sans effort.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Cryptage :</Body1Strong> Nous garantissons que toutes les données au sein de TeamConnect sont chiffrées, aussi bien lors du transfert qu'au repos, 
                      les rendant inaccessibles aux parties non autorisées.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Minimisation des données :</Body1Strong> Notre collecte de données est strictement limitée à ce qui est essentiel pour le fonctionnement de TeamConnect. 
                      Les seules informations utilisateur que nous stockons sont votre Nom Principal d'Utilisateur (UPN) provenant d'Azure Entra ID, qui peut ressembler à votre adresse e-mail professionnelle.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Accès restreint :</Body1Strong> L'accès à l'UPN est confiné à l'Admin Abonnement de votre organisation dans notre portail d'abonnement, 
                      garantissant que les informations sensibles ne sont visibles que par le personnel autorisé.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Suppression rapide des données :</Body1Strong> TeamConnect est structuré pour supprimer instantanément l'UPN stocké sous trois conditions spécifiques : 
                      si l'abonnement est annulé par l'Admin Abonnement, si un utilisateur est retiré par les RH au sein de TeamConnect, ou si 
                      l'abonnement à TeamConnect expire. Cela garantit que vos données personnelles ne sont pas conservées au-delà de leur durée de vie utile ou de la nécessité.
                    </li>                      
                  </ul>
                  <p>Grâce à ces mesures, TeamConnect s'engage à maintenir les normes les plus élevées de protection des données, garantissant que vos informations personnelles restent sécurisées et confidentielles.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Vous vous demandez où résident vos données avec TeamConnect ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect rompt avec le modèle traditionnel des offres SaaS en garantissant que vos données ne quittent jamais votre organisation. 
                  Contrairement à la pratique habituelle de stocker des données sur des serveurs cloud externes, TeamConnect utilise ingénieusement le SharePoint Online de Microsoft 365 de votre organisation pour héberger toutes les données de l'application. Cette approche innovante non seulement prône la souveraineté des données mais renforce également la sécurité, 
                  en parfaite adéquation avec notre engagement ferme à protéger votre vie privée et à respecter des normes de conformité strictes.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Mes données seront-elles partagées avec des tiers ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Non, les données ne sont partagées avec aucun tiers ! Cubeet est résolu dans notre engagement à préserver la confidentialité et la sécurité de vos données. Nous ne partageons aucune donnée de TeamConnect avec des tiers sans obtenir un consentement explicite de nos clients.
                  Dans notre environnement sécurisé, nous stockons uniquement les identifiants essentiels, tels que le nom d'utilisateur principal (UPN), le nom de l'organisation, le nom du locataire et l'ID du locataire, au sein de notre portail d'abonnement. 
                  Ces informations sont cruciales pour la fourniture de services et sont protégées sous nos politiques de confidentialité strictes.
                  Soyez assuré, nos pratiques de gestion des données sont conçues pour garantir que vos données restent confidentielles, avec transparence et contrôle fermement entre vos mains.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Comment TeamConnect se conforme-t-il aux réglementations sur la protection des données telles que le RGPD ?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect s'aligne sur les réglementations de protection des données telles que le RGPD, HIPAA, CCPA et d'autres en exploitant les fonctionnalités de conformité inhérentes de l'environnement Microsoft 365, 
                  où toutes les données des clients sont stockées en toute sécurité. Contrairement aux modèles SaaS traditionnels, TeamConnect ne stocke aucune donnée client sur ses serveurs ; au lieu de cela, il fonctionne au sein de l'environnement Microsoft 365 de votre 
                  organisation, adhérant à ses normes robustes de sécurité et de conformité. Cette approche garantit que la gouvernance de vos données reste cohérente avec 
                  le cadre de conformité complet fourni par Microsoft 365, y compris l'adhésion au RGPD, HIPAA pour les informations de santé, CCPA pour les résidents de Californie, 
                  et d'autres réglementations mondiales sur la protection des données. En s'intégrant de manière transparente à votre configuration Microsoft 365 existante, TeamConnect donne à votre organisation 
                  le plein contrôle sur vos données, assurant la conformité avec les lois et réglementations de protection des données les plus strictes.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Comment TeamConnect gère-t-il les données issues des chats, appels, appels vidéo, emails Outlook et appels téléphoniques du système de Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                <Body1>
                  TeamConnect utilise l'API Microsoft Graph pour permettre des interactions directes telles que le chat, les appels, les appels vidéo et les emails Outlook au sein de Microsoft Teams, 
                  offrant une expérience utilisateur transparente. Il est crucial, tandis que TeamConnect initie ces communications, de le faire sans intervenir dans le processus de communication 
                  ni avoir la capacité d'enregistrer ou de stocker aucun contenu de l'interaction. Toutes les conversations, appels et emails se déroulent et sont gérés directement au sein des 
                  environnements sécurisés de Microsoft Teams et Outlook, en stricte conformité avec les normes rigoureuses de sécurité et de confidentialité de Microsoft 365. Ceci garantit que TeamConnect améliore 
                  vos capacités de communication tout en préservant entièrement la confidentialité et l'intégrité de vos données.
                </Body1>

                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Pour des informations détaillées sur nos politiques et pratiques de données, vous pourriez également consulter le {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>Guide de l'utilisateur de TeamConnect</Body1Strong></Link> ou le département informatique de votre organisation à <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> pour des détails liés à votre configuration.
          </p>
        </Body1>        
        <p></p>  
      <Subtitle1 className={styles.fontColor}>Nous écoutons : Partagez Vos Retours et Idées</Subtitle1>
        <Body1>
          <p>
            Vos insights entraînent l'évolution de TeamConnect. Que vous débordiez d'une idée brillante pour une nouvelle fonctionnalité ou que vous ayez des retours sur votre expérience actuelle, nous sommes tout ouïe. Votre participation est inestimable dans notre quête continue d'offrir le meilleur service possible.
          </p>
        </Body1>      
      <Subtitle2>Retours et Suggestions de Fonctionnalités</Subtitle2>
        <Body1>
          <p>
            Prêt à partager ? Rendez-vous sur notre {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Page de Retours
            </Link>
            . Que ce soit une suggestion pour une nouvelle fonctionnalité ou des retours sur les fonctionnalités existantes, sélectionnez simplement 
            l'option appropriée dans le menu déroulant du formulaire. Vos contributions contribuent à façonner l'avenir de TeamConnect, 
            en s'assurant qu'il répond et dépasse vos besoins.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;

