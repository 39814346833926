import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();

  return (
    <>
      <Title3 className={styles.fontColor}>Centro Assistenza e FAQ di TeamConnect</Title3>
        <p><Body1>
          Benvenuto nel Centro Assistenza di TeamConnect, progettato per supportare la tua esperienza con la nostra completa app di directory aziendale. Qui troverai risorse per ottimizzare l'uso di TeamConnect all'interno di Microsoft Teams, migliorando la comunicazione all'interno della tua rete.
        </Body1></p>
        <Subtitle2>Guida di riferimento rapido</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Capacità di ricerca avanzata: </Body1Strong>
              Trova rapidamente i colleghi giusti, i dipartimenti e i contatti essenziali grazie ai nostri potenti strumenti di ricerca.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Organigrammi visivi: </Body1Strong>
              Naviga la gerarchia della tua organizzazione e comprendi le linee di report con facilità attraverso organigrammi intuitivi.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Strumenti adattivi per le esigenze odierne: </Body1Strong>
              Utilizza funzionalità moderne adattate alle richieste dei luoghi di lavoro contemporanei.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Ottimizza la Tua Esperienza</Subtitle2>
        <Body1>
          <p>
            TeamConnect rivoluziona il modo in cui interagisci all'interno della tua struttura organizzativa, trasformando le comunicazioni quotidiane in impegni strategici.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Ottieni Aiuto & FAQ</Subtitle1>
        <Body1>
          <p>
            Trova risposte alle domande comuni sulle funzionalità e le impostazioni di TeamConnect in questa sezione dedicata. Che tu stia navigando nell'app, personalizzando le impostazioni o cercando soluzioni a sfide, il nostro Centro Assistenza è qui per assisterti efficacemente.
          </p>
        </Body1>
        <Body1>
          <p>
            Per un supporto più approfondito o una guida specifica, consulta la <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>Guida Utente di TeamConnect</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        <Subtitle2>Utilizzo delle FAQ di TeamConnect</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Non trovi TeamConnect nella barra laterale di Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Se TeamConnect non è visibile o è scomparso nella tua barra laterale di Microsoft Teams, puoi facilmente fissarlo per un accesso rapido. Basta seguire questi semplici passi:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Apri Microsoft Teams e fai clic sull'icona '...' (+ App) nella parte inferiore della barra laterale.</li>
                    <li className={styles.bulletBottomMargin}>Cerca 'TeamConnect' nella barra di ricerca nella parte superiore della finestra popup.</li>
                    <li className={styles.bulletBottomMargin}>Fai clic destro sull'app TeamConnect dai risultati della ricerca e seleziona 'Fissa'.</li>
                  </ol>
                  TeamConnect sarà ora fissato alla tua barra laterale, rendendolo accessibile con un solo clic!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Problemi tecnici con TeamConnect? Ecco come ottenere aiuto.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Se incontri problemi tecnici con TeamConnect all'interno di Microsoft Teams, il team di assistenza IT della tua organizzazione è qui per aiutarti. 
                  Contattali a <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, e ti assisteranno prontamente.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Non riesci a trovare i dettagli del tuo impiegato?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Se il tuo profilo sembra un po' vuoto con solo il tuo nome e email visibili, è probabile che il tuo team HR non abbia ancora aggiunto il resto dei tuoi dettagli di impiegato a TeamConnect. 
                  Non ti preoccupare! Un rapido messaggio al tuo team di supporto HR metterà le cose in moto. Sono pronti a completare il tuo profilo con tutti i dettagli importanti. 
                  Contattali a <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> e vedrai il tuo profilo prendere vita!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Quali opzioni linguistiche sono disponibili in TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect abbraccia la diversità globale di Microsoft Teams supportando tutte le sue lingue. 
                  Che tu preferisca l'arabo o il greco, il giapponese o lo svedese, hai un mondo di opzioni a portata di mano, che includono:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arabo, Bulgaro, Cinese (Semplificato e Tradizionale), Croato, Ceco, Danese, Olandese, Inglese, Estone, Finlandese, 
                      Francese, Tedesco, Greco, Ebraico, Ungherese, Indonesiano, Italiano, Giapponese, Coreano, Lettone, Lituano, Norvegese (Bokmal), 
                      Polacco, Portoghese (Brasile e Portogallo), Rumeno, Russo, Serbo (Latino), Slovacco, Sloveno, Spagnolo, Svedese, Tailandese, 
                      Turco, Ucraino, Vietnamita.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Per personalizzare la tua esperienza con TeamConnect nella tua lingua preferita, per favore regola le impostazioni della lingua direttamente in Microsoft Teams, 
                  poiché TeamConnect si integra completamente con queste impostazioni:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Vai alle impostazioni di Microsoft Teams cliccando sui tre puntini accanto alla tua immagine del profilo in alto, e poi seleziona 'Aspetto e accessibilità'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Scorri fino alla sezione 'Lingua' e seleziona la tua lingua preferita dal menu a tendina.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Conferma le tue modifiche cliccando sul bottone 'Salva e riavvia' per applicare le nuove impostazioni della lingua.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Nota: </Body1Strong>
                <Body1>
                  Le modifiche apportate alle tue impostazioni linguistiche in Microsoft Teams aggiorneranno anche la tua esperienza con TeamConnect in modo fluido. 
                  Sii consapevole che cambiare la lingua in Teams può anche modificare il formato di data e ora. Al momento, 
                  TeamConnect si sta evolvendo per supportare la scrittura da destra a sinistra. Siamo entusiasti di questi miglioramenti 
                  e ti terremo informato non appena saranno disponibili.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Come posso cambiare il tema in TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect si sincronizza con le tue preferenze di tema di Microsoft Teams, incluse le opzioni Chiaro, Scuro e Alto Contrasto. Per personalizzare il tuo tema:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Accedi alle impostazioni di Microsoft Teams cliccando sui tre puntini accanto all'immagine del tuo profilo in alto.</li>
                    <li className={styles.bulletBottomMargin}>Seleziona 'Aspetto e accessibilità' per esplorare le opzioni di tema.</li>
                    <li className={styles.bulletBottomMargin}>Scegli il tuo tema preferito dalle opzioni disponibili per aggiornare immediatamente l'interfaccia di TeamConnect in modo che corrisponda.</li>
                  </ol>
                  La tua scelta di tema in Microsoft Teams verrà applicata automaticamente a TeamConnect, garantendo un'esperienza visiva coerente in tutto il tuo spazio di lavoro.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Esplorando i pulsanti di azione dei contatti di TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  In tutto TeamConnect, dove trovi i dettagli di contatto di un dipendente, scoprirai anche i nostri comodi pulsanti di accesso rapido. Questi strumenti utili 
                  sono progettati per semplificare la tua comunicazione all'interno dell'ecosistema Microsoft, garantendo che tu sia sempre a un clic di distanza dal connetterti.
                  Ecco la tua guida completa all'utilizzo efficace di questi pulsanti:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icona Chat:</Body1Strong> Clicca per avviare una chat istantanea su Microsoft Teams. Se devi tornare a TeamConnect dopo aver iniziato una chat, semplicemente utilizza la freccia indietro nell'App Desktop, il pulsante indietro nell'App Mobile o il pulsante indietro del tuo browser.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icona Email:</Body1Strong> Apre una nuova bozza di email su Outlook con l'indirizzo del destinatario pronto nel campo "A:". Se Outlook non si avvia, è consigliabile controllare con il tuo reparto IT per eventuali restrizioni.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icona Videochiamata:</Body1Strong> Avvia una videochiamata faccia a faccia tramite Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icona Chiamata:</Body1Strong> Avvia una chiamata vocale tramite Microsoft Teams, rendendo le discussioni rapide senza interruzioni.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icona Organigramma:</Body1Strong> Rivela il posto del dipendente nella gerarchia organizzativa, inclusi il suo manager e i diretti subordinati.</li>
                    <li><Body1Strong>Icona Altro (...):</Body1Strong> Svela ulteriori opzioni:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Incontro Immediato:</Body1Strong> Apre Microsoft Teams per iniziare subito un incontro con il dipendente.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Nuovo Incontro:</Body1Strong> Ti indirizza a Microsoft Teams per programmare un nuovo incontro.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Aggiungi Contatto:</Body1Strong> Aggiunge i dettagli del contatto del dipendente da TeamConnect alla tua nuova app People di Microsoft Teams, arricchendo la tua funzione di chiamata.</li>
                      </ul>
                    </li>
                  </ul>
                  Questi pulsanti intuitivi sono progettati per garantire che tu possa connetterti efficacemente con i colleghi, sia per una chat veloce, un'email o per pianificare incontri futuri.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Effettuare chiamate tramite TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Vuoi fare una chiamata? TeamConnect semplifica la tua comunicazione integrandosi con Microsoft Teams. 
                  Basta fare clic sul numero di telefono desiderato in TeamConnect e voilà, l'interfaccia di chiamata di Microsoft Teams appare,
                  pronta per permetterti di chiamare senza interruzioni.
                  <p>
                  <Body1Strong>Note: </Body1Strong> 
                  Problemi nell'effettuare chiamate? Potrebbe essere dovuto alle politiche IT della tua organizzazione o all'uso di un sistema telefonico diverso dal Microsoft Teams Phone System. Verificare con il tuo reparto IT a <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, può fare chiarezza sulla questione.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem> <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Ci sono costi associati alle chiamate effettuate tramite TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect migliora la tua esperienza di chiamata sfruttando Microsoft Teams. Ecco cosa devi sapere sui costi relativi alle chiamate:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Per le chiamate ai telefoni di lavoro dei dipendenti, ai telefoni del dipartimento o alle linee di emergenza all'interno della tua organizzazione (numeri interni), 
                      generalmente <Body1Strong>non ci sono costi</Body1Strong> quando si utilizza il sistema telefonico di Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Per le chiamate esterne, come quelle ai telefoni cellulari o alle linee esterne, si applicano le <Body1Strong>tariffe standard</Body1Strong> del sistema telefonico della tua organizzazione. 
                      Queste tariffe variano in base al tuo particolare piano di servizio con Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Nota:</Body1Strong> Se incontri problemi o hai domande sui costi delle chiamate, è meglio consultarsi con il 
                    dipartimento IT presso <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> per informazioni dettagliate 
                    personalizzate secondo la configurazione e le politiche della tua organizzazione.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Hai notato una discrepanza con il tuo stato di presenza 'Fuori ufficio' in TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hai notato che il tuo stato di presenza 'Fuori ufficio' da Microsoft 365 non si riflette correttamente in TeamConnect? 
                  Abbiamo una soluzione per te. La funzionalità per sincronizzare lo stato di presenza 'Fuori ufficio' è già stata implementata in TeamConnect.
                  Stiamo aspettando il lancio di questa funzionalità da parte di Microsoft. Una volta che ciò avverrà, il tuo stato di presenza 'Fuori ufficio' 
                  verrà riflettuto accuratamente in TeamConnect, allineandosi con gli indicatori di presenza di Microsoft Teams per un'esperienza senza interruzioni.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>Domande Frequenti sulla Rubrica dei Dipendenti</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >Introduzione alla Rubrica dei Dipendenti:</Body1Strong> Naviga il panorama professionale della tua organizzazione con la Rubrica dei Dipendenti, 
                il tuo hub centrale per approfondimenti dettagliati sui dipendenti, le dinamiche sul posto di lavoro, e la connettività diretta ai profili e alle relazioni manageriali dei tuoi colleghi.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Hai bisogno di aggiungere, aggiornare o correggere i tuoi dati di dipendente?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Per aggiungere, aggiornare o correggere i tuoi dati di dipendente in TeamConnect, ti preghiamo di contattare il team di supporto HR della tua organizzazione. 
                  Sono pronti ad aiutarti presso <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem><AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Cosa succede quando clicco sul nome di un dipendente nella directory?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Cliccando sul nome di un dipendente nella Directory dei Dipendenti di TeamConnect si apre una pagina del profilo dettagliata per quella persona. Questo profilo fornisce informazioni complete
                  sul dipendente, che includono, ma non si limitano a, il loro titolo di lavoro, dipartimento, ubicazione dell'ufficio, informazioni di contatto, e altro. È progettato per darti
                  una panoramica completa del ruolo professionale del dipendente e di come si inserisce nell'organizzazione, facilitando la comprensione delle loro responsabilità e
                  di come potresti collaborare con loro.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Quali informazioni sono incluse nel profilo di un dipendente?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ogni profilo di dipendente in TeamConnect è uno spaccato completo, che offre una ricchezza di informazioni sulla vita professionale dell'individuo.
                  Ecco cosa troverai:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Dettagli Base:</Body1Strong> Nome, titolo di lavoro, dipartimento e ubicazione della filiale.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Struttura di Reporting:</Body1Strong> Informazioni sui rapporti diretti e funzionali, 
                      includendo manager di linea e funzionali.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Stato nel Posto di Lavoro:</Body1Strong> Stato attuale di lavoro come In-Ferie, In-Trasferta, 
                      in Viaggio d'Affari, ecc.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Postazione di Lavoro & Ora Locale:</Body1Strong> La postazione di lavoro attuale del dipendente e 
                      il fuso orario locale.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Dettagli del Contatto:</Body1Strong> Telefono del lavoro, numero di cellulare e indirizzo email.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Indirizzo dell'Ufficio:</Body1Strong> Posizione completa dell'ufficio inclusi nome dell'edificio,
                      indirizzo e mappa di Google.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Riguardo:</Body1Strong> Una breve introduzione personale o bio professionale.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Relazioni di Lavoro:</Body1Strong> Dettagli della struttura di reporting del dipendente e delle relazioni di squadra.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Link Sociali & Lingue:</Body1Strong> Link ai profili social professionali 
                      e competenze linguistiche.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Competenze:</Body1Strong> Un elenco di competenze professionali e aree di competenza.</li>
                  </ul>
                  Inoltre, il profilo offre opzioni concrete per connettersi tramite chat, email, videochiamata o pianificare riunioni, insieme a
                  un rapido accesso al grafico dell'organizzazione per un contesto di team più ampio.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Come posso aggiornare le informazioni del mio profilo?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Personalizzare il tuo profilo è facile in TeamConnect. Ecco come puoi farlo:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Naviga nella scheda <Body1Strong>Employee</Body1Strong> e clicca sul tuo <Body1Strong>Avatar</Body1Strong> per aprire il tuo profilo.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Nell'angolo superiore sinistro troverai il link <Body1Strong>"Modifica il tuo profilo"</Body1Strong>. 
                        Cliccando verrà aperta la finestra di modifica del tuo profilo.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Qui, puoi migliorare il tuo profilo aggiungendo o aggiornando la sezione <Body1Strong>Informazioni su di te</Body1Strong>, i link dei social media e il numero di telefono. 
                        Questo rende più facile per i tuoi colleghi connettersi o seguirti.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Non dimenticare di premere il pulsante <Body1Strong>Save</Body1Strong> per applicare le modifiche.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Scorri verso il basso nella finestra di dialogo per aggiungere le tue <Body1Strong>Lingue</Body1Strong> e <Body1Strong>Competenze</Body1Strong>, 
                        così come le tue <Body1Strong>Abilità</Body1Strong>. Condividere queste informazioni aiuta i tuoi colleghi a comprendere 
                        le tue aree di competenza e le lingue che parli.
                      </li>
                    </ol>
                  Aggiornare il tuo profilo non solo mantiene le tue informazioni aggiornate, ma favorisce anche migliori connessioni all'interno della tua organizzazione.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Aggiornare il tuo luogo di lavoro, posto di lavoro e fuso orario in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Mantenere aggiornate le informazioni sul tuo luogo di lavoro, posto di lavoro e fuso orario è cruciale per una collaborazione senza interruzioni in Microsoft Teams. 
                  Questi aggiornamenti si riflettono immediatamente nel tuo profilo e nelle inserzioni di TeamConnect, permettendo ai colleghi di comprendere rapidamente la tua ubicazione lavorativa 
                  e i momenti migliori per connettersi con te, potenziando la collaborazione tra diverse regioni e fusi orari.
                  <p></p>
                  Pronto a aggiornare i tuoi dettagli? È facile in TeamConnect! Vai a 'Impostazioni' nel menu in alto e naviga in 'Impostazioni Generali'. 
                  Qui, puoi facilmente aggiornare il tuo luogo di lavoro e fuso orario, e specificare il tuo posto di lavoro per il giorno. Ricorda di salvare le modifiche per 
                  mantenere tutti informati e garantire una collaborazione efficace, indipendentemente dalla tua posizione!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Hai notato qualcosa di strano nel profilo di un collega?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Se noti dei dettagli nel profilo di un collega che non sembrano esatti, il primo passo migliore è informarlo cordialmente. Una breve chat o una email dovrebbero bastare! Se per qualche motivo le informazioni non vengono aggiornate, non ti preoccupare—basta che contatti il tuo team di supporto HR a <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, e loro saranno felici 
                  di intervenire e risolvere la situazione.
                </Body1>
              </AccordionPanel>
            </AccordionItem> <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Comprendere i Gruppi di Tag: Tutti, Preferiti, Presenti e Nuovi Impiegati
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tutti:</Body1Strong> Questo tag predefinito mostra tutti gli impiegati della tua organizzazione,
                      fornendo una visione completa del team.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Preferiti:</Body1Strong> Clicca qui per filtrare la tua vista su quei colleghi che hai marcato
                      come 'Preferiti', facilmente per connettersi rapidamente con contatti chiave.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Presenti:</Body1Strong> Questo tag indica gli impiegati attivamente al lavoro, distinguendo 
                      dalla semplice presenza online su Microsoft Teams. Quando sono presenti, gli impiegati segnalano di essere al lavoro e condividono la loro posizione lavorativa 
                      attuale durante la giornata. Questo può includere una varietà di luoghi di lavoro come Ufficio, Ufficio sul Campo, Ufficio di Casa, Luogo di Lavoro Remoto,
                       Luogo di Lavoro del Cliente, Ufficio Privato del Cliente, Area Pubblica, Commute, Viaggio d'Affari o anche Formazione. Pertanto, lo stato 'Presente' fornisce una chiara indicazione 
                      di essere attivamente al lavoro, offrendo ai colleghi una comprensione precisa del contesto lavorativo e della disponibilità corrente di ciascuno.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nuovi Impiegati:</Body1Strong> Identifica i nuovi volti in TeamConnect con questo tag, evidenziando 
                      gli impiegati che sono stati aggiunti all'app negli ultimi 30 giorni. Tipicamente, questo si allinea con le recenti aggiunte alla tua organizzazione, aiutandoti 
                      a dare il benvenuto ai nuovi membri del team.
                    </li>
                  </ul>
                  Questi gruppi di tag sono progettati per semplificare la navigazione e migliorare la tua esperienza con TeamConnect, assicurandoti di poter sempre 
                  trovare e connetterti con i tuoi colleghi in modo efficiente.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Decifrare le Icone Accanto alla Casella di Ricerca
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  La Directory dei Dipendenti di TeamConnect è progettata per versatilità e facilità d'uso, presentando quattro icone che offrono diverse visualizzazioni
                  e accesso rapido ai dettagli chiave. Ecco cosa rivela ogni icona:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vista Elenco:</Body1Strong> La tua vista predefinita, presenta tutti i membri dell'organizzazione
                      in un elenco semplice.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vista Cartolina Piccola:</Body1Strong> Un'alternativa compatta, questa vista trasforma l'elenco
                      in cartoline dei dipendenti di piccole dimensioni.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vista Cartolina Grande:</Body1Strong> Opta per più dettagli con cartoline più grandi, che includono
                      opzioni di contatto aggiuntive come numeri di telefono e cellulari per facilitare la composizione rapida.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org Chart:</Body1Strong> Visualizza la struttura aziendale con la vista Org Chart, 
                      perfetta per comprendere le relazioni di reportistica e navigare nel panorama organizzativo.</li>
                  </ol>
                    Alterna tra queste icone per trovare la vista che meglio si adatta alle tue esigenze del momento, sia che tu stia cercando informazioni rapide o intuizioni più profonde.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Dove dovrei cercare per una ricerca rapida dei colleghi?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Stai cercando un collega? Basta recarsi alla casella di ricerca di TeamConnect, situata nella parte superiore destra accanto all'icona del Org chart. 
                  Inizia a digitare il loro nome e osserva come i risultati si raffinano con ogni lettera. Ricorda, per le ricerche su TeamConnect, evita la barra di ricerca di Microsoft Teams 
                  nella parte superiore: la nostra casella di ricerca speciale è la via da seguire!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Come funziona la ricerca alfabetica per trovare gli impiegati?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  La Directory degli Impiegati di TeamConnect include una pratica funzione di ricerca alfabetica per aiutarti a localizzare rapidamente gli impiegati per nome di battesimo.
                  Clicca semplicemente su una lettera dell'alfabeto mostrata in cima alla directory, e TeamConnect ti mostrerà immediatamente tutti gli impiegati
                  i cui nomi di battesimo iniziano con quella lettera. Per tornare all'elenco completo degli impiegati, deseleziona semplicemente la lettera evidenziata. Questa funzione intuitiva
                  rende la navigazione tra lunghi elenchi di impiegati semplice e veloce, assicurando che tu possa trovare i colleghi rapidamente ed efficientemente.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Come posso regolare il numero di impiegati visualizzati per pagina?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect ti permette di personalizzare quanti profili di impiegati visualizzare per pagina, rendendo più facile navigare attraverso la Directory degli Impiegati.
                  Alla fine di ogni pagina, troverai opzioni di paginazione: 10, 50, 100, 500, 1000, e 5000. L'impostazione predefinita mostra 10 profili per pagina.
                  Per visualizzare più profili in una singola pagina, seleziona semplicemente il tuo numero preferito tra queste opzioni. Questa funzione offre flessibilità nel modo in cui
                  navighi nella directory, permettendoti di adattare la visualizzazione alle tue esigenze o preferenze specifiche.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Come posso ordinare le informazioni degli impiegati nella Vista Lista?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nella Vista Lista della Directory degli Impiegati di TeamConnect, hai la flessibilità di ordinare le informazioni degli impiegati secondo le tue necessità. Le colonne—Nome dell'Impiegato,
                  Titolo di Lavoro, Dipartimento, Località dell'Ufficio, e Luogo di Lavoro—sono tutte ordinabili. Clicca semplicemente sull'intestazione della colonna che desideri ordinare. Un clic
                  ordinerà la colonna in ordine ascendente, e un clic successivo la ordinerà in ordine discendente. Per impostazione predefinita, la directory è ordinata per Nome dell'Impiegato in ordine ascendente,
                  basato sul nome di battesimo. Questa funzione di ordinamento ti aiuta a organizzare rapidamente e visualizzare i dettagli degli impiegati in modo che serva al meglio il tuo scopo, che tu stia
                  cercando una persona specifica o necessiti di raggruppare gli impiegati per un criterio particolare come il dipartimento o la località.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Domande frequenti sulla Rubrica dei Dipartimenti</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Introduzione alla Rubrica dei Dipartimenti:</Body1Strong> La Rubrica dei Dipartimenti è il tuo accesso per comprendere la complessa rete della struttura 
              della tua organizzazione, offrendo una visione chiara delle gerarchie dipartimentali, della leadership manageriale e delle vie per una comunicazione dipartimentale fluida.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Come funzionano i tag 'Tutti', 'Preferiti' e 'Il Tuo Dipartimento' nella Rubrica dei Dipartimenti?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nella Rubrica dei Dipartimenti di TeamConnect, i tag ti aiutano a navigare velocemente e a filtrare le informazioni dipartimentali. Ecco cosa rappresenta ciascun tag:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tutti:</Body1Strong> Questo tag mostra tutti i dipartimenti della tua organizzazione, fornendo una 
                      panoramica completa.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Preferiti:</Body1Strong> Usa questo tag per visualizzare un elenco curato dei dipartimenti che hai 
                      segnato come 'Preferiti', rendendo più facile l'accesso ai dipartimenti con cui interagisci frequentemente.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Il Tuo Dipartimento:</Body1Strong> Questo tag filtra la visualizzazione per mostrare solo il tuo dipartimento, 
                      permettendo un accesso rapido alle informazioni e agli aggiornamenti del tuo dipartimento immediato.</li>
                  </ul>
                  Questi tag sono progettati per semplificare la tua navigazione all'interno della Rubrica dei Dipartimenti, migliorando la tua capacità di trovare e interagire con le informazioni 
                  dipartimentali in modo efficiente.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Comprendere le Visualizzazioni della Rubrica dei Dipartimenti in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigare nella Rubrica dei Dipartimenti in TeamConnect è semplificato con molteplici opzioni di visualizzazione adatte alle tue preferenze. Ogni vista offre un 
                  modo unico di esplorare informazioni dipartimentali complete, includendo Nome del Dipartimento, Manager del Dipartimento, Dipartimento Principale, Telefono del 
                  Dipartimento, Hotline del Dipartimento e Email del Dipartimento. Ecco una panoramica delle viste disponibili:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vista Elenco:</Body1Strong> La configurazione predefinita che elenca ordinatamente tutti i dipartimenti, fornendo una 
                      panoramica chiara e concisa.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vista Carta Grande:</Body1Strong> Passa a questa vista per vedere le schede dettagliate dei dipartimenti, offrendo 
                      un riassunto esteso delle informazioni chiave di ciascun dipartimento.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vista Organigramma:</Body1Strong> Per una rappresentazione visiva della struttura e delle relazioni 
                      dipartimentali, la vista Organigramma illustra come ogni dipartimento si inserisce all'interno della gerarchia organizzativa più ampia.
                    </li>
                  </ul>
                  Per alternare tra queste viste, usa semplicemente le icone accanto alla casella di ricerca in cima alla Rubrica dei Dipartimenti. Ogni vista è progettata per aiutarti 
                  a accedere e comprendere i dettagli dipartimentali con facilità, assicurando che tu trovi le informazioni di cui hai bisogno nel formato più adatto a te.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Come utilizzare la casella di ricerca nell'Elenco dei Dipartimenti
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Trova senza sforzo qualsiasi dipartimento all'interno di TeamConnect utilizzando la casella di ricerca dedicata situata proprio accanto alle icone di visualizzazione in alto nell'Elenco dei Dipartimenti. Basta iniziare a digitare il nome del dipartimento che stai cercando e osserva mentre l'elenco si perfeziona per mostrare solo le corrispondenze pertinenti. Questa funzionalità è specificamente progettata per aiutarti a evitare la necessità di cercare manualmente in tutto l'elenco, portando il dipartimento di cui hai bisogno direttamente alle tue dita. Ricorda, questa funzionalità di ricerca è unica per l'Elenco dei Dipartimenti di TeamConnect, quindi assicurati di utilizzare questa casella di ricerca piuttosto che la ricerca principale di Microsoft Teams in cima all'interfaccia di Teams per query specifiche del dipartimento.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Come funziona il filtro alfabetico nell'Elenco dei Dipartimenti?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Semplifica la tua ricerca nell'Elenco dei Dipartimenti con il filtro alfabetico situato in alto. Questa funzionalità ti permette di restringere rapidamente i dipartimenti selezionando una lettera. Una volta scelta una lettera, TeamConnect filtra e mostra solo quei dipartimenti i cui nomi iniziano con la lettera selezionata, rendendo più facile trovare ciò che stai cercando senza dover scorre l'intera lista. Per tornare alla visualizzazione completa dei dipartimenti, deseleziona semplicemente la lettera evidenziata. Questa navigazione alfabetica intuitiva ti assicura di poter localizzare efficientemente qualsiasi dipartimento dalla sua lettera iniziale.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Come posso contattare il responsabile del dipartimento?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Contattare un responsabile di dipartimento in TeamConnect è semplice. Localizza semplicemente il dipartimento di interesse e clicca sul nome del manager. Questa azione attiverà un popover che mostra le opzioni di contatto del manager con le familiari icone di accesso rapido:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icona Chat:</Body1Strong> Per iniziare un messaggio istantaneo tramite la chat di Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icona Email:</Body1Strong> Per aprire una nuova email indirizzata a loro in Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icona Videochiamata:</Body1Strong> Per avviare una videochiamata tramite Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icona Chiamata:</Body1Strong> Per iniziare una chiamata vocale utilizzando Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Icona Altro (...):</Body1Strong> Per opzioni aggiuntive come programmare una riunione o aggiungere le loro informazioni di contatto alla tua lista di Teams.
                    </li>
                  </ul>
                  Queste icone forniscono un metodo semplice per raggiungere direttamente i responsabili dei dipartimenti all'interno di TeamConnect, garantendo una comunicazione efficiente all'interno della tua organizzazione.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Come posso visualizzare o contattare i dipendenti all'interno di un dipartimento?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Per esplorare chi è chi all'interno di un dipartimento in TeamConnect, non guardare oltre i tre punti accanto al nome del dipartimento. Cliccando su questo si apriranno opzioni per ulteriori azioni:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Visualizza Dipendenti in Questo Dipartimento:</Body1Strong> Seleziona questa opzione per visualizzare un elenco di tutti i dipendenti associati al dipartimento. Il numero accanto a questa opzione indica quanti dipendenti ci sono nel dipartimento, fornendo un rapido conteggio.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organigramma del Dipartimento:</Body1Strong> Se desideri comprendere la gerarchia del dipartimento, scegli 'Organigramma del dipartimento' per rivelare il layout strutturale, mostrando la relazione tra i dipartimenti genitori, il dipartimento in questione e eventuali sottodipartimenti. Questa rappresentazione visiva ti aiuta a navigare e comprendere il quadro organizzativo, rendendo più facile identificare e contattare specifici membri del dipartimento.
                    </li>
                    <li><Body1Strong>Dipartimento {'>'} Dipendente:</Body1Strong> Dopo aver visualizzato i dipendenti all'interno di un dipartimento, usa questo percorso di navigazione per tornare facilmente all'elenco dei dipartimenti, mantenendo un'esperienza di esplorazione fluida all'interno di TeamConnect.
                    </li>
                  </ul>
                  Queste funzionalità ti permettono non solo di vedere l'elenco dei dipendenti in un determinato dipartimento, ma offrono anche un modo per contattarli direttamente attraverso gli strumenti di comunicazione di TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Scoprire Dettagli Approfonditi sui Dipartimenti in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Immergiti nei dettagli di qualsiasi dipartimento con un semplice clic sul suo nome in TeamConnect. Si dispiegherà un dialogo dettagliato, che offre un ricco tessuto di informazioni che delineano completamente il ruolo e le risorse del dipartimento all'interno della tua organizzazione. Troverai tutto, dalla leadership e dagli identificativi finanziari ai canali di comunicazione e ai collegamenti social. Questa funzionalità è la tua porta d'accesso a:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Il cuore del dipartimento - il suo scopo, persone e metriche di performance.</li>
                    <li className={styles.bulletBottomMargin}>Vie di contatto - linee dirette ai principali figure del dipartimento e piattaforme di comunicazione.</li>
                    <li className={styles.bulletBottomMargin}>La rete organizzativa - comprendere come il dipartimento si integra con la struttura aziendale più ampia.</li>
                  </ul>
                  Che si tratti di una semplice domanda o di un'analisi approfondita dei dati del dipartimento, TeamConnect ti fornisce gli strumenti per connetterti e comprendere gli ecosistemi unici all'interno della tua organizzazione.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Curioso di sapere perché mancano gli indirizzi dei dipartimenti in TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Scoprire la posizione di un dipartimento può a volte sembrare una caccia al tesoro senza mappa. In TeamConnect, generalmente non troverai un indirizzo dedicato per ogni dipartimento. Ecco perché questo dettaglio cartografico è spesso omesso:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Varie Località:</Body1Strong> Molti dipartimenti non sono confinati a un singolo spazio; prosperano in vari uffici e geografie.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Struttura Fluida:</Body1Strong> Il paesaggio organizzativo è in continuo cambiamento, con dipartimenti che si evolvono, si fondono o si dissolvono in allineamento con i cambiamenti strategici.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Collaborazione Interdipartimentale:</Body1Strong> Le moderne aree di lavoro sono piene di spirito collaborativo, spesso mescolando talenti di vari dipartimenti in spazi condivisi.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lavoro Remoto:</Body1Strong> Con la flessibilità del lavoro remoto, gli ancoraggi dei dipartimenti non sono sempre fissati in sedi fisiche.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sfumature Legali:</Body1Strong> I dipartimenti orbitano tipicamente attorno alla struttura legale della società piuttosto che avere propri indirizzi autonomi.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Semplificazione Operativa:</Body1Strong> Mantenere la concentrazione sulla collaborazione dinamica, non su indirizzi statici, aiuta a mantenere l'agilità operativa.
                    </li>
                  </ul>
                  Si tratta di abbracciare un approccio senza confini al lavoro di squadra e all'efficienza. TeamConnect riflette questa filosofia enfatizzando le connessioni piuttosto che le località.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Le informazioni del tuo dipartimento sono mancanti o obsolete?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Gli editori dei dipartimenti sono designati all'interno della tua organizzazione per aggiungere, aggiornare o correggere le informazioni specifiche del dipartimento in TeamConnect.
                  Se non sai chi è l'editore del tuo dipartimento o se alcune informazioni richiedono l'intervento del team di supporto HR, 
                  si prega di contattare <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> per una guida.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>FAQs dell'Elenco Uffici</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Introduzione all'Elenco Uffici:</Body1Strong> Scopri l'essenza della presenza fisica della tua organizzazione tramite l'Elenco Uffici, 
                che comprende dettagli essenziali degli uffici, specifiche operative e una panoramica completa dei dipartimenti e del personale che definiscono ogni sede.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Navigare tra i Tag nell'Elenco Uffici
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Naviga con facilità nell'Elenco Uffici utilizzando il nostro sistema di tag progettato per un accesso rapido alla giusta sede. Ecco come ogni tag può aiutarti a snellire la tua ricerca:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tutti:</Body1Strong> Mostra un elenco completo degli uffici, fornendo una visione a volo d'uccello delle 
                      sedi della tua organizzazione.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Preferiti:</Body1Strong> Ti aiuta a concentrarti su quelle sedi ufficio che frequenti o su cui devi 
                      tenere d'occhio, marcandole come 'Preferiti'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Il Tuo Ufficio:</Body1Strong> Questo tag personale filtra l'elenco per mostrarti la sede 
                      dell'ufficio dove sei posizionato, mantenendo la tua base principale a portata di mano.
                    </li>
                  </ul>
                  Che tu stia prenotando una sala riunioni o pianificando una visita, questi tag offrono un modo personalizzato e semplificato per interagire con il paesaggio geografico 
                  della tua organizzazione.
                </Body1>
              </AccordionPanel>
            </AccordionItem><AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Mastery della Directory degli Uffici in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Esplora la struttura organizzativa delle varie sedi della tua azienda con le viste della Directory degli Uffici di TeamConnect. Ogni vista è creata per facilitare 
                  un aspetto unico della navigazione e della comprensione degli uffici:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vista Elenco:</Body1Strong> La configurazione predefinita che organizza ordinatamente tutte le sedi degli uffici in 
                      un elenco facile da leggere.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vista Carta Grande:</Body1Strong> La Vista Carta Grande nella Directory degli Uffici arricchisce la tua 
                      visione con una presentazione dettagliata di ogni ufficio. Interagendo con una carta dell'ufficio, troverai una serie di informazioni e opzioni eseguibili 
                      all'interno del menu "Altro (...)":
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Visualizza Dipartimenti:</Body1Strong> Naviga direttamente a un elenco
                          di tutti i dipartimenti situati all'interno dell'ufficio.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Visualizza Impiegati:</Body1Strong> Accedi a un elenco completo di impiegati assegnati all' 
                          ufficio, facilitando il contatto diretto o l'esplorazione del personale dell'ufficio.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Organigramma dell'Entità Aziendale:</Body1Strong> Visualizza il posto dell'ufficio all'interno del contesto 
                          organizzativo più ampio per una chiara comprensione delle strutture gerarchiche.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Contatti Essenziali:</Body1Strong> Identifica i contatti chiave dell'ufficio per una 
                          comunicazione semplificata.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Festività Pubbliche dell'Ufficio:</Body1Strong> Mantieniti informato sulle festività pubbliche 
                          specifiche dell'ufficio per pianificare i tuoi programmi di conseguenza.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Orari di Lavoro dell'Ufficio:</Body1Strong> Visualizza gli orari di funzionamento dell'ufficio per allineare le tue 
                          visite o chiamate ai loro orari attivi.
                        </li>
                      </ol>
                      Questa vista offre non solo uno scorcio visivo e informativo, ma anche un accesso rapido a ulteriori approfondimenti e azioni dirette relative all'ufficio, tutto ordinatamente raccolto sotto l'opzione "Altro (...)" per la tua comodità.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vista Organigramma:</Body1Strong> Visualizza la struttura gerarchica degli uffici principali e secondari 
                      per comprendere l'interconnessione delle diverse sedi degli uffici all'interno della tua organizzazione.
                    </li>
                  </ul>
                  Alterna tra queste viste utilizzando le icone accanto alla casella di ricerca per personalizzare la tua esperienza di navigazione in base alle tue esigenze immediate.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Trova Uffici Rapidamente con la Funzione di Ricerca di TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Individuare la giusta sede dell'ufficio all'interno di TeamConnect è reso semplice dalla funzione di ricerca dedicata. Posizionata in modo prominente accanto alle icone di visualizzazione, la casella di ricerca è il tuo strumento per individuare gli uffici per nome. Mentre digiti, la directory affina la visualizzazione per mostrare solo gli uffici che corrispondono al tuo input, semplificando il processo di ricerca. È importante utilizzare questa casella di ricerca all'interno di TeamConnect per ottenere risultati più accurati e pertinenti, a differenza della ricerca generale di Microsoft Teams. Con questa funzionalità di ricerca mirata, puoi navigare rapidamente verso qualsiasi ufficio nella rete della tua organizzazione.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Navigare nell'Elenco Uffici in Ordine Alfabetico
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Se preferisci cercare per alfabeto, il nostro filtro alfabetico è un modo rapido per trovare gli uffici per nome. In cima all'Elenco Uffici, vedrai 
                  le lettere dalla A alla Z. Cliccando su una lettera, la visualizzazione sarà filtrata per mostrare solo quegli uffici che iniziano con quella lettera, 
                  semplificando il tuo processo di ricerca. Per resettare e vedere nuovamente tutti gli uffici, deseleziona semplicemente la lettera attiva. Questa 
                  funzionalità è particolarmente utile quando conosci il nome dell'ufficio ma hai bisogno di un modo rapido per localizzarlo all'interno di TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Rivelazione dei Profili Ufficio Completi in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Imbarcati in un viaggio esplorativo nel cuore della presenza fisica della tua organizzazione con i profili ufficio di TeamConnect. 
                  Cliccando sul nome di un ufficio, si apre una vivace finestra di dialogo del profilo, ricca di schede piene di approfondimenti dettagliati sugli uffici:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Panoramica:</Body1Strong> Immergiti nel nucleo dell'identità dell'ufficio con dettagliati 
                      descrittori, dalla struttura aziendale agli orari di apertura e all'orario locale, catturando l'essenza dell'ambiente ufficio.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Contatti Essenziali:</Body1Strong> Accedi ai contatti cruciali per i servizi di emergenza e 
                      supporto esterno, ognuno a un clic di distanza tramite il sistema telefonico di Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Contatti Ufficio:</Body1Strong> Contatta con facilità i servizi di supporto interni, con 
                      dettagli dei contatti e opzioni di interazione diretta a portata di mano.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Festività Pubbliche:</Body1Strong> Mantieniti informato sulle festività specifiche dell'ufficio,
                      assicurando che i tuoi piani siano in linea con le osservazioni locali e gli orari di lavoro.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Orari di Ufficio:</Body1Strong> Pianifica le tue interazioni con la precisa conoscenza di 
                      quando l'ufficio è pieno di attività o si calma per la giornata.
                    </li>
                  </ul>
                  Che si tratti dell'energia vivace di Austin o del centro strategico di Singapore, ogni scheda offre un passaggio per comprendere e connettersi con i 
                  diversi luoghi che compongono la presenza globale della tua azienda.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Approfondimento delle dinamiche di reparto all'interno di un ufficio
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigare la rete professionale dei dipartimenti di un ufficio si svolge con facilità in TeamConnect. Ecco come approfondire i dettagli:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Visualizza i reparti in questo ufficio:</Body1Strong> Scopri tutti i reparti che formano il
                      tessuto dell'ufficio selezionando questa opzione dal menu Ulteriori (...) .
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Intuizioni organizzative:</Body1Strong> Clicca su un qualsiasi dipartimento per rivelarne l'organigramma per
                      una rappresentazione visiva della sua struttura, con il percorso breadcrumb che ti guida: Ufficio {'>'} Dipartimento {'>'} Organigramma.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Approfondimento sui reparti:</Body1Strong> Esplora ulteriormente un reparto cliccando sul suo
                      menu Ulteriori (...) accanto al nome del dipartimento. Qui, puoi accedere a un elenco di tutti i dipendenti all'interno di quel reparto, direttamente nel contesto dell'ufficio.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Navigazione Breadcrumb:</Body1Strong> Attraversa i livelli senza interruzioni. Dagli 
                      dipendenti ai reparti o dall'organigramma, il percorso breadcrumb (Ufficio {'>'} Dipartimento {'>'} Dipendente {'>'} Organigramma) mantiene il tuo 
                      viaggio intuitivo e sulla giusta via.
                    </li>
                  </ul>
                  I percorsi intricati di TeamConnect ti invitano a esplorare, comprendere e interagire con ogni strato dei dipartimenti del tuo ufficio, potenziandoti con 
                  un comando di navigazione completo.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Connettersi con il personale dell'ufficio in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Curioso di conoscere i volti dietro un ufficio? La risposta è a portata di clic in TeamConnect. Interagisci con i puntini di sospensione (tre punti) accanto al nome del tuo ufficio scelto per rivelare una suite di opzioni interattive:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Visualizza i dipendenti in questo ufficio:</Body1Strong> Scopri un elenco di tutti i professionisti
                      che chiamano questo ufficio la loro casa lavorativa. Il numero visualizzato offre un rapido conteggio dei dipendenti, invitandoti a esplorare la comunità dell'ufficio.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Opzioni di contatto:</Body1Strong> Ogni voce del dipendente è equipaggiata con icone di azione rapida,
                      permettendoti di iniziare chat, chiamate o anche videoconferenze direttamente da TeamConnect, favorendo una comunicazione intra-ufficio senza soluzione di continuità.
                    </li>
                    <li><Body1Strong>Navigazione:</Body1Strong> Per tornare all'elenco degli uffici più ampio da un elenco di dipendenti, basta cliccare su 'Ufficio' nel percorso 
                      breadcrumb (Ufficio {'>'} Dipendente), e sarai riportato alla panoramica dell'ufficio.
                    </li>
                  </ul>
                  Con queste caratteristiche, TeamConnect rende semplice visualizzare la distribuzione della forza lavoro attraverso gli uffici e raggiungere senza sforzo, 
                  promuovendo un luogo di lavoro connesso, a prescindere dalla distanza.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Manca o è obsoleta l'informazione del tuo ufficio?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Gli aggiornamenti delle informazioni dell'ufficio in TeamConnect possono essere gestiti dai redattori di ufficio assegnati. Se sei incerto su chi siano i redattori dell'ufficio o 
                  hai bisogno di assistenza per informazioni che rientrano nella competenza delle Risorse Umane, il team di supporto HR è pronto a 
                  assistere a <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>FAQ impostazioni</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. L'importanza di Impostazioni Generali precise in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nel dinamico contesto lavorativo di oggi, rimanere connessi e coordinati è più essenziale che mai. Ecco dove l'aggiornamento delle tue 
                  Impostazioni Generali in TeamConnect gioca un ruolo fondamentale:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Selezione del Luogo di Lavoro:</Body1Strong> Cattura l'essenza del tuo ambiente lavorativo aggiornando il tuo Luogo di Lavoro per riflettere la tua situazione 
                        attuale, sia che si tratti di un passaggio dalla tranquillità del tuo Home-Office al brusio dell'Ufficio principale, o persino di sistemarti in un'Area Pubblica. 
                        Questa chiarezza nel tuo locale lavorativo offre ai colleghi intuizioni non solo sui migliori modi per contattarti, ma anche sulla natura delle 
                        conversazioni che possono intrattenere con te, salvaguardando la riservatezza delle discussioni sensibili soprattutto quando sei nel mezzo 
                        dell'ambiente aperto di un ufficio clienti o seduto in una sala d'attesa aeroportuale.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Specificazione del Luogo di Lavoro:</Body1Strong> Condividere la tua città lavorativa fa più che colmare le distanze in un setup di team remoto: apre anche 
                        le porte a riunioni professionali spontanee o socializzazioni casuali. Che si tratti di un caffè veloce, un pranzo tranquillo o un incontro di lavoro, 
                        sapere che sei nella stessa città può trasformare una giornata normale in un'opportunità per connessioni significative faccia a faccia. Questo non 
                        solo rafforza i legami di squadra ma offre anche una comprensione più profonda delle sfumature locali che potrebbero influenzare il tuo lavoro.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Precisione del Fuso Orario:</Body1Strong> Mentre i team si estendono su continenti, assicurare che il tuo fuso orario rifletta 
                      la tua località attuale è essenziale per armonizzare le comunicazioni. Il Tempo Locale nel tuo profilo di TeamConnect è direttamente influenzato da questa impostazione, 
                      eliminando ogni incertezza per i colleghi che cercano di raggiungerti.
                    </li>
                  </ul>
                  Immergiti in 'Impostazioni' {'>'} 'Impostazioni Generali' all'interno di TeamConnect per perfezionare questi dettagli. Spendere qualche momento per aggiornare le tue impostazioni può 
                  migliorare significativamente la coesione e l'efficienza dei tuoi sforzi collaborativi su tutto il fronte.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Decifrare la Selezione del Luogo di Lavoro in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Scegliere l'impostazione giusta nel menu a tendina Luogo di Lavoro adatta TeamConnect per riflettere accuratamente il tuo stato lavorativo attuale. Passa il mouse sull'icona delle informazioni per una spiegazione dettagliata di ogni opzione:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Break:</Body1Strong> Indica un periodo di assenza dal lavoro, sia che si tratti di una pausa o della fine della giornata.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office/Field-Office:</Body1Strong> Indica la presenza in uno spazio di lavoro gestito dall'azienda.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> Lavorare da uno spazio dedicato in casa.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Public-Area:</Body1Strong> Lavorare da un ambiente non ufficio, suggerendo la necessità di comunicazione flessibile.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Customer Workplace/Private Office:</Body1Strong> Sul posto con un cliente, con una nota sui livelli di privacy per la sensibilità delle conversazioni.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Commute/Business-Travel/Training:</Body1Strong> In movimento o impegnato in attività lavorative specifiche fuori dall'ufficio.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Public-Holiday/Vacation/Various Leaves:</Body1Strong> Ufficialmente non in servizio per motivi personali, di salute o festivi.</li>
                  </ul>
                  Selezionare un'impostazione di luogo di lavoro appropriata assicura che il tuo team abbia il contesto necessario per le interazioni, rispettando disponibilità e privacy.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Navigare lo Stato 'Checked-In' in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Il tag 'Checked-In' nella Directory dei Dipendenti è progettato per mostrare tutti i dipendenti che sono attualmente al lavoro. Questa visibilità viene determinata dalla selezione del Luogo di Lavoro che effettui:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ambienti di Lavoro Attivi:</Body1Strong> Le scelte Ufficio, Field-Office, Home-Office, Remote-Workplace, Public-Area, Customer-Workplace,
                    Ufficio Privato del Cliente, Commute, Business-Travel e Training indicano che sei impegnato nel lavoro, rendendoti 'Checked-In' e visibilmente attivo ai colleghi.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Impostazioni Non-Lavorative:</Body1Strong> Optare per Checked-Out, Break, Public-Holiday, Vacation, On-Leave, Maternity-Leave o Sick-Leave
                      ti rimuove dalla lista dei 'Checked-In', stabilendo aspettative chiare per disponibilità e tempi di risposta.
                  </li>
                  </ul>
                  Questa funzionalità aiuta a delineare i periodi di lavoro dal tempo personale, contribuendo a una collaborazione e comunicazione efficace del team.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Gestione dei dati e FAQ sulla privacy</Subtitle2>
          <Body1>
            <p>
              Nell'era digitale odierna, comprendere come vengono gestiti i tuoi dati è fondamentale. TeamConnect è impegnata nella trasparenza e nella adozione di pratiche robuste di protezione dei dati. 
              Qui sotto, troverai informazioni su come gestiamo, conserviamo e proteggiamo i tuoi dati all'interno di TeamConnect, assicurando che il tuo spazio di lavoro digitale sia sicuro e conforme.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Come protegge TeamConnect i miei dati personali?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect è dedicata alla salvaguardia delle tue informazioni personali attraverso un approccio globale alla sicurezza dei dati:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Autenticazione Unica (SSO):</Body1Strong> Per rafforzare la sicurezza e semplificare l'esperienza utente, TeamConnect integra l'autenticazione automatica 
                      Single Sign-On (SSO) con il tuo account Microsoft 365, facilitando una gestione dell'accesso sicura e senza sforzo.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Crittografia:</Body1Strong> Ci assicuriamo che tutti i dati all'interno di TeamConnect siano crittografati sia durante la trasmissione che quando sono inattivi, 
                      rendendoli inaccessibili a parti non autorizzate.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Minimizzazione dei Dati:</Body1Strong> La nostra raccolta di dati è strettamente limitata a ciò che è essenziale per la funzionalità di TeamConnect. 
                      Le uniche informazioni utente che conserviamo sono il tuo User Principal Name (UPN) da Azure Entra ID, che può assomigliare al tuo indirizzo email aziendale.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Accesso limitato:</Body1Strong> L'accesso all'UPN è limitato all'amministratore dell'abbonamento della tua organizzazione all'interno del nostro portale di abbonamento, 
                      garantendo che le informazioni sensibili siano visibili solo al personale autorizzato.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Eliminazione immediata dei dati:</Body1Strong> TeamConnect è strutturata per eliminare immediatamente l'UPN memorizzato in tre condizioni specifiche: 
                      se l'abbonamento viene cancellato dall'amministratore dell'abbonamento, se un utente viene rimosso dalla HR all'interno di TeamConnect, o se l'abbonamento a TeamConnect 
                      scade. Ciò garantisce che i tuoi dati personali non vengano conservati oltre la loro vita utile o necessità.
                    </li>                      
                  </ul>
                  <p>Attraverso queste misure, TeamConnect si impegna a mantenere gli standard più elevati di protezione dei dati, assicurando che le tue informazioni personali rimangano sicure e riservate.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Si chiede dove risiedono i suoi dati con TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect rompe gli schemi delle offerte tradizionali SaaS assicurando che i suoi dati non lascino mai la sua organizzazione. 
                  A differenza della prassi comune di immagazzinare i dati su server cloud esterni, TeamConnect sfrutta in modo ingegnoso lo SharePoint Online di Microsoft 365 della sua organizzazione 
                  per ospitare tutti i dati dell'applicazione. Questo approccio innovativo non solo promuove la sovranità dei dati ma rinforza anche la sicurezza, 
                  allineandosi perfettamente con la nostra ferma dedizione alla salvaguardia della sua privacy e al rispetto degli standard di conformità rigorosi.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. I miei dati verranno condivisi con terze parti?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  No, i dati non sono condivisi con terze parti! Cubeet è fermo nel nostro impegno per la privacy e la sicurezza dei suoi dati. Non condividiamo nessun dato di TeamConnect con terze parti senza ottenere il consenso esplicito dai nostri clienti. 
                  Nel nostro ambiente sicuro, conserviamo solo identificativi essenziali, come il Nome Principale dell'Utente (UPN), il nome dell'organizzazione, il nome del tenant e l'ID del tenant, all'interno del nostro portale di sottoscrizione. 
                  Questa informazione è fondamentale per la fornitura del servizio ed è protetta dalle nostre rigide politiche sulla privacy.
                  State tranquilli, le nostre pratiche di gestione dei dati sono progettate per assicurare che i suoi dati rimangano confidenziali, con trasparenza e controllo saldamente nelle sue mani.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Come si conforma TeamConnect alle normative sulla protezione dei dati come il GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect si allinea alle regolamenti sulla protezione dei dati come il GDPR, HIPAA, CCPA e altri sfruttando le caratteristiche di conformità intrinseche dell'ambiente Microsoft 365, 
                  dove tutti i dati dei clienti sono conservati in sicurezza. A differenza dei modelli tradizionali SaaS, TeamConnect non immagazzina nessun dato dei clienti sui propri server; invece, opera all'interno dell'ambiente Microsoft 365 della sua 
                  organizzazione, aderendo ai suoi solidi standard di sicurezza e conformità. Questo approccio garantisce che la governance dei suoi dati rimanga coerente con 
                  il quadro di conformità comprensivo fornito da Microsoft 365, inclusa l'aderenza a GDPR, HIPAA per le informazioni sanitarie, CCPA per i residenti in California, 
                  e altre normative globali sulla protezione dei dati. Integrando in modo fluido con la sua configurazione Microsoft 365 esistente, TeamConnect potenzia la sua organizzazione a mantenere 
                  il pieno controllo sui suoi dati, garantendo la conformità con le leggi e le regolamentazioni sulla protezione dei dati più rigorose.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Come gestisce TeamConnect i dati provenienti dalle chat, dalle chiamate, dalle videochiamate di Microsoft Teams, dalle email di Outlook e dalle chiamate del sistema telefonico?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect sfrutta l'API Microsoft Graph per abilitare interazioni dirette come chat, chiamate, videochiamate e email Outlook all'interno di Microsoft Teams, 
                  fornendo un'esperienza utente fluida. È fondamentale, mentre TeamConnect avvia queste comunicazioni, lo fa senza intervenire nel processo di comunicazione 
                  o avere la capacità di registrare o memorizzare qualsiasi contenuto dell'interazione. Tutte le conversazioni, chiamate ed email si svolgono e sono gestite direttamente all'interno 
                  dei confini sicuri di Microsoft Teams e Outlook, nel rispetto rigoroso degli standard di sicurezza e privacy di Microsoft 365. Questo garantisce che TeamConnect migliora 
                  le tue capacità di comunicazione preservando completamente la riservatezza e l'integrità dei tuoi dati.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Per approfondimenti dettagliati sulle nostre politiche e pratiche relative ai dati, potresti anche voler consultare il {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>Manuale dell'utente di TeamConnect</Body1Strong></Link> o il dipartimento IT della tua organizzazione 
            presso <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> per dettagli specifici relativi alla tua configurazione.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Ascoltiamo: Condividi i Tuoi Feedback e Idee</Subtitle1>
        <Body1>
          <p>
            I tuoi spunti guidano l'evoluzione di TeamConnect. Che tu sia pieno di un'idea brillante per una nuova funzionalità o abbia un feedback sulla tua esperienza attuale, siamo tutto orecchi. 
            Il tuo contributo è prezioso nella nostra continua ricerca per offrire il miglior servizio possibile.
          </p>
        </Body1>
        <Subtitle2>Feedback e Suggerimenti per le Funzionalità</Subtitle2>
        <Body1>
          <p>
            Pronto a condividere? Dirigiti alla nostra {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Pagina dei Feedback
            </Link>
            . Che si tratti di una proposta per una nuova funzionalità o di un feedback sulle funzionalità esistenti, a semplicemente selezionare 
            l'opzione appropriata dal menu a tendina nel modulo. I tuoi contributi aiutano a plasmare il futuro di TeamConnect,
            garantendo che soddisfi e superi le tue esigenze.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;