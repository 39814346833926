import React from 'react';
import ReactDOM from 'react-dom/client';
import Routes from './routes';
import reportWebVitals from './reportWebVitals';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './config/authConfig';

// Bootstrap components
import 'bootstrap/dist/css/bootstrap.min.css';

const msalInstance = new PublicClientApplication(msalConfig);

/**
 * Recommend: wrapping most or all of your components in the MsalProvider component. 
 * It's best to render the MsalProvider as close to the root as possible.
 */

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <FluentProvider theme={teamsLightTheme}>
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Routes />
      </MsalProvider>
    </React.StrictMode>
  </FluentProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
