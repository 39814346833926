import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>
        Pusat Bantuan & FAQ TeamConnect
      </Title3>
      <p>
        <Body1>
          Selamat datang di Pusat Bantuan TeamConnect, yang dirancang untuk
          mendukung pengalaman anda dengan aplikasi direktori korporat kami yang
          menyeluruh. Di sini, anda akan menemukan sumber daya untuk
          mengoptimalkan penggunaan TeamConnect dalam Microsoft Teams,
          meningkatkan komunikasi di jaringan anda.
        </Body1>
      </p>
      <Subtitle2>Panduan Referensi Cepat</Subtitle2>
      <ul>
        <Body1>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Kemampuan Pencarian yang Lebih Baik: </Body1Strong>
            Temukan rekan kerja, departemen, dan kontak penting dengan cepat
            menggunakan alat pencari kami yang canggih.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Grafik Org Visual: </Body1Strong>
            Navigasi hierarki organisasi anda dan memahami laporan garis dengan
            mudah melalui grafik org yang intuitif.
          </li>
          <li className={styles.bulletBottomMargin}>
            <Body1Strong>Alat Adaptif untuk Kebutuhan Sekarang: </Body1Strong>
            Gunakan fungsionalitas modern yang disesuaikan dengan tuntutan
            tempat kerja kontemporer.
          </li>
        </Body1>
      </ul>
      <p></p>
      <Subtitle2>Mengoptimalkan Pengalaman Anda</Subtitle2>
      <Body1>
        <p>
          TeamConnect merevolusi cara anda berinteraksi dalam struktur
          organisasi anda, mengubah komunikasi sehari-hari menjadi keterlibatan
          strategis.
        </p>
      </Body1>
      <p></p>
      <Subtitle1 className={styles.fontColor}>Dapatkan Bantuan & FAQ</Subtitle1>
      <Body1>
        <p>
          Temukan jawaban untuk pertanyaan umum tentang fitur dan pengaturan
          TeamConnect di bagian khusus ini. Baik anda sedang menavigasi
          aplikasi, menyesuaikan pengaturan, atau mencari solusi untuk
          tantangan, Pusat Bantuan kami siap membantu anda dengan efisien.
        </p>
      </Body1>
      <Body1>
        <p>
          Untuk dukungan yang lebih mendalam atau bimbingan spesifik, silakan
          konsultasikan{" "}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>
              Panduan Pengguna TeamConnect
            </Body1Strong>
          </Link>
          .
        </p>
      </Body1>
      <p></p>
      <Subtitle2>Menggunakan TeamConnect FAQs</Subtitle2>
      <p></p>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="1">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                1. Tidak dapat mencari TeamConnect di sidebar Microsoft Teams?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Jika TeamConnect tidak kelihatan dengan nyata atau hilang di
                sidebar Microsoft Teams anda, anda boleh mudah memasangkannya
                untuk capaian pantas. Ikuti langkah mudah ini:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Buka Microsoft Teams dan klik pada ikon '...' (+ Apps) di
                    bahagian bawah sidebar.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Cari 'TeamConnect' di bar cari di bahagian atas tetingkap
                    pop-up.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Klik kanan pada aplikasi TeamConnect dari hasil carian dan
                    pilih 'Pin'.
                  </li>
                </ol>
                TeamConnect kini akan dipasang pada sidebar anda, menjadikannya
                mudah diakses dengan hanya satu klik!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="2">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                2. Menghadapi masalah teknikal dengan TeamConnect? Berikut cara
                mendapatkan bantuan.
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Jika anda menghadapi sebarang masalah teknikal dengan
                TeamConnect dalam Microsoft Teams, pasukan Sokongan IT
                organisasi anda di sini untuk membantu. Hubungi mereka di{" "}
                <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>
                , dan mereka akan membantu anda dengan segera.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="3">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                3. Tidak dapat melihat butiran pekerja anda?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Jika profil anda terasa sedikit kosong dengan hanya menunjukkan
                nama dan e-mel anda, kemungkinan pasukan HR anda belum menambah
                butiran pekerja lain ke dalam TeamConnect. Tidak perlu risau!
                Satu mesej cepat kepada pasukan sokongan HR anda akan memulakan
                segalanya. Mereka bersedia untuk melengkapkan profil anda dengan
                semua butiran penting. Hubungi di{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>{" "}
                dan saksikan profil anda menjadi lebih hidup!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="4">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                4. Apakah pilihan bahasa yang tersedia dalam TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect menerima kepelbagaian global Microsoft Teams dengan menyokong semua bahasanya.
                Sama ada anda lebih suka bahasa Arab atau Yunani, Jepun atau Sweden, anda mempunyai dunia pilihan di hujung jari anda, termasuk:
              </Body1>
              <ul>
                <li>
                  <Body1>
                    Arab, Bulgaria, Cina (Ringkas dan Tradisional), Croatia, Czech, Denmark, Belanda, Inggeris, Estonia, Finland,
                    Perancis, Jerman, Yunani, Ibrani, Hungary, Indonesia, Itali, Jepun, Korea, Latvia, Lithuania, Norway (Bokmal),
                    Poland, Portugis (Brazil dan Portugal), Romania, Rusia, Serbia (Latin), Slovak, Slovenia, Sepanyol, Sweden, Thai,
                    Turki, Ukraine, Vietnam.
                  </Body1>
                </li>
              </ul>
              <Body1>
                Untuk menyesuaikan pengalaman TeamConnect anda kepada bahasa pilihan anda, sila laraskan tetapan bahasa secara terus dalam Microsoft Teams,
                kerana TeamConnect menyepadukan sepenuhnya dengan tetapan ini:
              </Body1>
              <ol>
                <Body1>
                  <li className={styles.bulletBottomMargin}>
                    Pergi ke tetapan Microsoft Teams anda dengan mengklik pada tiga titik di sebelah gambar profil anda di bahagian atas, dan kemudian pilih 'Penampilan dan kebolehaksesan'.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Tatal ke bahagian 'Bahasa' dan pilih bahasa pilihan anda daripada menu lungsur turun.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Sahkan perubahan anda dengan mengklik butang 'Simpan dan mulakan semula' untuk menggunakan tetapan bahasa baharu.
                  </li>
                </Body1>
              </ol>
              <Body1Strong>Nota: </Body1Strong>
              <Body1>
                Perubahan yang dibuat pada tetapan bahasa anda dalam Microsoft Teams akan mengemas kini pengalaman TeamConnect anda dengan lancar juga.
                Harap maklum bahawa mengubah bahasa dalam Pasukan juga boleh melaraskan format masa dan tarikh anda. Pada masa ini,
                TeamConnect sedang berkembang untuk memasukkan tetapan serantau dan sokongan untuk penulisan kanan ke kiri. Kami teruja dengan peningkatan ini
                dan akan memaklumkan anda apabila ia tersedia.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="5">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                5. Bagaimana caranya mengubah tema di TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect mengikuti preferensi tema Microsoft Teams anda,
                termasuk pilihan Light, Dark, dan High Contrast. Untuk
                menyesuaikan tema anda:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Akses pengaturan Microsoft Teams anda dengan mengklik tiga
                    titik di samping gambar profil anda di atas.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Pilih 'Appearance and accessibility' untuk menjelajahi
                    pilihan tema.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Pilih tema yang anda inginkan dari pilihan yang tersedia
                    untuk segera memperbarui antarmuka TeamConnect anda agar
                    sesuai.
                  </li>
                </ol>
                Pilihan tema anda di Microsoft Teams akan secara otomatis
                diterapkan ke TeamConnect, memastikan pengalaman visual yang
                konsisten di seluruh ruang kerja anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="6">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                6. Menjelajahi Tombol Aksi Kontak TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Di seluruh TeamConnect, di mana pun Anda menemukan detail kontak
                karyawan, Anda juga akan menemukan tombol akses cepat kami yang
                nyaman. Alat praktis ini dirancang untuk mempermudah komunikasi
                Anda dalam ekosistem Microsoft, memastikan Anda hanya selangkah
                lagi untuk terhubung. Berikut panduan lengkap Anda untuk
                menggunakan tombol-tombol ini secara efektif:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Chat Icon:</Body1Strong> Klik untuk memulai
                    obrolan Microsoft Teams secara instan. Jika Anda perlu
                    kembali ke TeamConnect setelah memulai obrolan, cukup
                    gunakan panah kembali di Desktop App, tombol kembali di
                    Mobile App, atau tombol kembali browser Anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Email Icon:</Body1Strong> Membuka draf email
                    Outlook baru dengan alamat penerima siap di kolom "To:".
                    Jika Outlook tidak terbuka, ada baiknya memeriksa dengan
                    departemen TI Anda untuk setiap pembatasan.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Video Call Icon:</Body1Strong> Memulai
                    panggilan video tatap muka menggunakan Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Call Icon:</Body1Strong> Memulai panggilan
                    suara Microsoft Teams, membuat diskusi cepat menjadi lancar.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Org Chart Icon:</Body1Strong> Menunjukkan
                    posisi karyawan dalam hierarki organisasi, termasuk manajer
                    dan laporan langsung mereka.
                  </li>
                  <li>
                    <Body1Strong>More (...) Icon:</Body1Strong> Memperlihatkan
                    lebih banyak pilihan:
                    <ul>
                      <li
                        style={{ marginTop: "8px" }}
                        className={styles.bulletBottomMargin}
                      >
                        <Body1Strong>Meet Now:</Body1Strong> Membuka Microsoft
                        Teams untuk memulai pertemuan langsung dengan karyawan
                        tersebut.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>New Meeting:</Body1Strong> Mengarahkan Anda
                        ke Microsoft Teams untuk menjadwalkan pertemuan baru.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Add Contact:</Body1Strong> Menambahkan
                        detail kontak karyawan dari TeamConnect ke aplikasi
                        Orang baru Microsoft Teams Anda, memperkaya fungsi
                        panggilan Anda.
                      </li>
                    </ul>
                  </li>
                </ul>
                Tombol intuitif ini dibuat untuk memastikan Anda dapat terhubung
                dengan efisien dengan rekan kerja, baik untuk obrolan singkat,
                email, atau menjadwalkan pertemuan mendatang.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="7">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                7. Melakukan panggilan melalui TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ingin melakukan panggilan? TeamConnect mempermudah komunikasi
                Anda dengan terintegrasi dengan Microsoft Teams. Cukup klik pada
                nomor telepon yang diinginkan di TeamConnect, dan voilà,
                antarmuka panggilan Microsoft Teams muncul, siap untuk Anda
                tekan dengan lancar.
                <p>
                  <Body1Strong>Note: </Body1Strong>
                  Mengalami masalah dengan melakukan panggilan? Ini mungkin
                  karena kebijakan TI organisasi Anda atau penggunaan sistem
                  telepon selain Microsoft Teams Phone System. Melakukan
                  pemeriksaan dengan departemen TI Anda di{" "}
                  <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>
                      {itSupport}
                    </Body1Strong>
                  </Link>
                  , dapat memberikan beberapa pencerahan tentang masalah
                  tersebut.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="8">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                8. Adakah kos yang berkaitan dengan membuat panggilan melalui
                TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect meningkatkan pengalaman panggilan anda dengan
                memanfaatkan Microsoft Teams. Berikut adalah apa yang anda perlu
                tahu tentang kos yang berkaitan dengan panggilan:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    Untuk panggilan ke telefon kerja pekerja, telefon jabatan,
                    atau talian kecemasan dalam organisasi anda (nombor
                    dalaman), biasanya <Body1Strong>tiada kos</Body1Strong>{" "}
                    apabila menggunakan Sistem Telefon Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Untuk panggilan luaran, seperti ke telefon bimbit atau
                    talian luar, <Body1Strong>kadar standard</Body1Strong>{" "}
                    sistem telefon organisasi anda berlaku. Kadar ini berbeza
                    berdasarkan pelan perkhidmatan khusus anda dengan Microsoft
                    Teams.
                  </li>
                </ul>
                <p>
                  <Body1Strong>Nota:</Body1Strong> Jika anda menghadapi sebarang
                  masalah atau mempunyai pertanyaan mengenai caj panggilan,
                  adalah lebih baik untuk berunding dengan Jabatan IT anda di{" "}
                  <Link href={`mailto:${itSupport}`}>
                    <Body1Strong className={styles.fontColor}>
                      {itSupport}
                    </Body1Strong>
                  </Link>{" "}
                  untuk mendapatkan maklumat yang disesuaikan dengan penubuhan
                  dan dasar organisasi anda.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="9">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                9. Mengesan ketidaksesuaian dengan status kehadiran 'Out of
                Office' anda dalam TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Menyedari bahawa status kehadiran 'Out of Office' anda dari
                Microsoft 365 tidak mencerminkan dengan betul dalam TeamConnect?
                Kami telah menangani perkara ini. Ciri untuk menyelaraskan
                status kehadiran 'Out of Office' telah dilaksanakan dalam
                TeamConnect. Kami sedang menunggu pelaksanaan ciri ini oleh
                Microsoft. Setelah itu berlaku, status kehadiran 'Out of Office'
                anda akan dicerminkan dengan tepat dalam TeamConnect, sejajar
                dengan penunjuk kehadiran Microsoft Teams untuk pengalaman yang
                lancar.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Soalan Lazim Direktori Pekerja</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Pengenalan Direktori Pekerja:</Body1Strong> Terokai
          landskap profesional organisasi anda dengan Direktori Pekerja, pusat
          utama anda untuk wawasan pekerja yang terperinci, dinamika tempat
          kerja, dan sambungan langsung ke profil rakan sekerja anda dan
          hubungan pengurusan.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="10">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                10. Perlu menambah, mengemas kini, atau membetulkan data pekerja
                anda?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Untuk menambah, mengemas kini, atau membetulkan data pekerja
                anda dalam TeamConnect, sila hubungi pasukan sokongan HR
                organisasi anda. Mereka bersedia untuk membantu di{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>
                .
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="11">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                11. Apa yang berlaku apabila saya klik pada nama pekerja dalam
                direktori?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mengklik nama pekerja dalam Direktori Pekerja TeamConnect
                membuka halaman profil terperinci untuk individu tersebut.
                Profil ini menyediakan pandangan menyeluruh mengenai pekerja,
                termasuk tetapi tidak terhad kepada, jawatan mereka, jabatan,
                lokasi pejabat, maklumat perhubungan, dan lain-lain. Ia direka
                untuk memberi anda gambaran penuh mengenai peranan profesional
                pekerja tersebut dan bagaimana mereka menyatu dalam organisasi,
                memudahkan anda memahami tanggungjawab mereka dan bagaimana anda
                mungkin berkolaborasi dengan mereka.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="12">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                12. Apakah maklumat yang termasuk dalam profil pekerja?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Setiap profil pekerja dalam TeamConnect adalah gambaran
                menyeluruh, menawarkan wawasan yang kaya mengenai kehidupan
                profesional individu. Inilah yang anda akan temui:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Butiran Asas:</Body1Strong> Nama, jawatan
                    kerja, jabatan, dan lokasi anak syarikat.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Struktur Pelaporan:</Body1Strong> Maklumat
                    mengenai laporan langsung dan fungsional, termasuk pengurus
                    baris dan pengurus fungsional.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Status Tempat Kerja:</Body1Strong> Status kerja
                    semasa seperti Cuti, Berulang-alik, Perjalanan Perniagaan,
                    dan lain-lain.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Lokasi Kerja & Masa Tempatan:</Body1Strong>{" "}
                    Lokasi kerja semasa pekerja dan zon waktu tempatan.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Maklumat Perhubungan:</Body1Strong> Telefon
                    kerja, nombor mudah alih, dan alamat e-mel.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Alamat Pejabat:</Body1Strong> Lokasi pejabat
                    lengkap termasuk nama bangunan, alamat, dan Google map.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Mengenai:</Body1Strong> Pengenalan peribadi
                    atau bio profesional ringkas.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Hubungan Pekerja:</Body1Strong> Butiran
                    struktur laporan pekerja dan hubungan pasukan.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Pautan Sosial & Bahasa:</Body1Strong> Pautan ke
                    profil sosial profesional dan kefasihan bahasa.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Kemahiran:</Body1Strong> Senarai kemahiran
                    profesional dan bidang kepakaran.
                  </li>
                </ul>
                Tambahan pula, profil tersebut menawarkan pilihan tindakan untuk
                berhubung melalui sembang, e-mel, panggilan video, atau
                menjadualkan mesyuarat, bersama dengan akses pantas ke carta
                organisasi untuk konteks pasukan yang lebih luas.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="13">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                13. Bagaimana saya boleh mengemaskini maklumat profil saya?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Memperibadikan profil anda adalah mudah dalam TeamConnect.
                Berikut adalah caranya:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    Navigasi ke tab <Body1Strong>Pekerja</Body1Strong> dan klik
                    pada <Body1Strong>Avatar</Body1Strong> anda untuk membuka
                    profil anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Di sudut kiri atas, anda akan menemui pautan{" "}
                    <Body1Strong>"Edit profil anda"</Body1Strong>. Mengklik ini
                    akan membuka dialog suntingan untuk profil anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Di sini, anda boleh memperkayakan profil anda dengan
                    menambah atau mengemaskini bahagian{" "}
                    <Body1Strong>Mengenai Anda</Body1Strong>, pautan media
                    sosial, dan nombor telefon. Ini memudahkan rakan sekerja
                    anda untuk berhubung atau mengikuti anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Jangan lupa untuk menekan butang{" "}
                    <Body1Strong>Simpan</Body1Strong> untuk memohon perubahan
                    anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Tatal ke bawah dalam dialog untuk menambah{" "}
                    <Body1Strong>Bahasa</Body1Strong> dan{" "}
                    <Body1Strong>Kemahiran</Body1Strong> anda, serta{" "}
                    <Body1Strong>Kemahiran</Body1Strong> anda. Berkongsi
                    maklumat ini membantu rakan sekerja anda memahami bidang
                    kepakaran anda dan bahasa yang anda boleh berkomunikasi.
                  </li>
                </ol>
                Mengemaskini profil anda bukan sahaja mengemas kini maklumat
                anda tetapi juga membina sambungan yang lebih baik dalam
                organisasi anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="14">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                14. Mengemaskini Tempat Kerja Anda, Lokasi Kerja, dan Zon Waktu
                dalam TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mengemaskini maklumat tempat kerja, lokasi kerja, dan zon waktu
                anda adalah penting untuk kerjasama yang lancar dalam Microsoft
                Teams. Kemas kini ini akan dipantulkan dengan segera dalam
                profil dan senarai TeamConnect anda, membolehkan rakan sekerja
                memahami dengan cepat lokasi kerja anda dan waktu terbaik untuk
                berhubung dengan anda, meningkatkan kerjasama seberang kawasan
                dan zon waktu yang berbeza.
                <p></p>
                Bersedia untuk mengemaskini butiran anda? Ia mudah dalam
                TeamConnect! Pergi ke 'Settings' di Menu atas dan navigasi ke
                'General Settings'. Di sini, anda boleh dengan lancar
                mengemaskini Tempat Kerja dan Zon Waktu anda, dan menentukan
                Lokasi Kerja anda untuk hari itu. Ingat untuk menyimpan
                perubahan anda untuk memastikan semua orang termaklum dan
                memastikan kerjasama yang efektif, tidak kira lokasi anda!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="15">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                15. Menemui sesuatu yang tidak kena pada profil rakan sekerja?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Jika anda menemui sebarang maklumat dalam profil rakan sekerja
                yang tidak tampak tepat, langkah pertama terbaik adalah
                memberikan mereka pemberitahuan mesra. Sembang ringkas atau
                email patut cukup! Jika atas sebab tertentu maklumat tersebut
                tidak dikemaskini, jangan risau—hanya hubungi pasukan sokongan
                HR anda di{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {hrSupport}
                  </Body1Strong>
                </Link>
                , dan mereka akan gembira untuk membantu dan menyelesaikan
                masalah tersebut.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="16">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                16. Memahami Kumpulan Tag: Semua, Kegemaran, Berkelayakan, &
                Pekerja Baru
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Semua:</Body1Strong> Tag lalai ini memaparkan
                    setiap pekerja dalam organisasi anda, menyediakan pandangan
                    menyeluruh tentang pasukan.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Kegemaran:</Body1Strong> Klik di sini untuk
                    menapis pandangan anda kepada rakan sekerja yang telah anda
                    tandai sebagai 'Kegemaran,' memudahkan anda untuk berhubung
                    dengan cepat dengan kenalan utama.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Berkelayakan:</Body1Strong> Tag ini menunjukkan
                    pekerja yang secara aktif bekerja, membezakan dari kehadiran
                    dalam talian di Microsoft Teams sahaja. Ketika berkelayakan,
                    pekerja memberi isyarat bahawa mereka berada di tempat kerja
                    dan berkongsi lokasi kerja mereka semasa hari. Ini boleh
                    termasuk pelbagai tempat kerja seperti Pejabat, Pejabat
                    Lapangan, Pejabat Rumah, Tempat Kerja Jauh, Tempat Kerja
                    Pelanggan, Pejabat Peribadi Pelanggan, Kawasan Awam,
                    Perjalanan, Perjalanan Perniagaan, atau Latihan. Oleh itu,
                    status 'Berkelayakan' memberitahu kehadiran aktif di tempat
                    kerja, memberi rakan sekerja pemahaman yang jelas mengenai
                    konteks kerja semasa dan kebolehcapaian setiap orang.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Pekerja Baru:</Body1Strong> Kenal pasti wajah
                    baru dalam TeamConnect dengan tag ini, menonjolkan pekerja
                    yang telah ditambahkan ke dalam aplikasi dalam masa 30 hari
                    yang lalu. Umumnya, ini sejajar dengan penambahan terkini
                    kepada organisasi anda, membantu anda menyambut ahli pasukan
                    yang baru.
                  </li>
                </ul>
                Kumpulan tag ini direka untuk memudahkan navigasi dan
                meningkatkan pengalaman TeamConnect anda, memastikan anda
                sentiasa dapat mencari dan berhubung dengan rakan sekerja dengan
                cekap.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="17">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                17. Mendekodkan Ikon di Samping Kotak Pencarian
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Direktori Pekerja TeamConnect direka untuk kepelbagaian dan
                kemudahan, menampilkan empat ikon yang menawarkan pandangan yang
                berbeza dan akses pantas ke maklumat kunci. Berikut adalah apa
                yang setiap ikon dedahkan:
                <ol>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Pandangan Senarai:</Body1Strong> Pandangan
                    utama anda, mempersembahkan semua ahli organisasi dalam
                    senarai yang teratur.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Pandangan Kad Kecil:</Body1Strong> Alternatif
                    yang ringkas, pandangan ini mengubah senarai menjadi kad
                    pekerja bersaiz kecil.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Pandangan Kad Besar:</Body1Strong> Pilih lebih
                    banyak butiran dengan kad yang lebih besar, yang termasuk
                    pilihan hubungan tambahan seperti nombor telefon dan mudah
                    alih untuk panggilan mudah.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Carta Organisasi:</Body1Strong> Visualisasikan
                    struktur syarikat dengan pandangan Carta Organisasi,
                    sempurna untuk memahami hubungan pelaporan dan menavigasi
                    landskap organisasi.
                  </li>
                </ol>
                Togol di antara ikon-ikon ini untuk mencari pandangan yang
                paling sesuai dengan keperluan anda pada saat itu, sama ada anda
                mencari maklumat cepat atau wawasan yang lebih mendalam.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="18">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                18. Untuk pencarian rakan sekerja dengan cepat, di mana saya
                harus mencari?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mencari rakan sekerja? Hanya pergi ke kotak pencarian
                TeamConnect, yang terletak di sudut kanan atas bersebelahan
                dengan ikon Carta Organisasi. Mulakan menaip nama mereka, dan
                lihat hasilnya semakin tepat dengan setiap huruf. Ingat, untuk
                pencarian TeamConnect, abaikan bar pencarian Microsoft Teams di
                atas—kotak pencarian khas kami adalah cara yang tepat!
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="19">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                19. Bagaimana kaedah pencarian abjad berfungsi untuk mencari
                pekerja?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Direktori Pekerja TeamConnect termasuk ciri pencarian abjad yang
                berguna untuk membantu anda dengan cepat mencari pekerja
                mengikut nama pertama mereka. Cukup klik pada huruf dari abjad
                yang dipaparkan di bahagian atas direktori, dan TeamConnect akan
                segera menunjukkan semua pekerja yang nama pertama mereka
                bermula dengan huruf tersebut. Untuk kembali ke senarai lengkap
                pekerja, hanya nyahpilih huruf yang disorot. Ciri intuitif ini
                memudahkan navigasi melalui senarai pekerja yang besar,
                memastikan anda dapat mencari rakan sekerja dengan cepat dan
                cekap.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="20">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                20. Bagaimana saya boleh laras bilangan pekerja yang dipaparkan
                per halaman?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect membolehkan anda menyesuaikan berapa banyak profil
                pekerja yang anda lihat per halaman, menjadikannya lebih mudah
                untuk menavigasi melalui Direktori Pekerja. Di bahagian bawah
                setiap halaman, anda akan menemui pilihan penomboran halaman:
                10, 50, 100, 500, 1000, dan 5000. Tetapan lalai menunjukkan 10
                profil per halaman. Untuk melihat lebih banyak profil pada satu
                halaman, pilih nombor kegemaran anda dari pilihan-pilihan ini.
                Ciri ini menyediakan fleksibiliti dalam cara anda melayari
                direktori, membolehkan anda menyesuaikan paparan mengikut
                keperluan atau kegemaran anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="21">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                21. Bagaimana saya mengatur maklumat pekerja dalam Paparan
                Senarai?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Dalam Paparan Senarai Direktori Pekerja TeamConnect, anda
                mempunyai fleksibiliti untuk mengatur maklumat pekerja mengikut
                keperluan anda. Lajur—Nama Pekerja, Jawatan, Jabatan, Lokasi
                Pejabat, dan Tempat Kerja—semua boleh diatur. Cukup klik pada
                tajuk lajur yang ingin anda atur. Klik sekali akan mengatur
                lajur dalam urutan menaik, dan klik lagi akan mengatur dalam
                urutan menurun. Secara lalai, direktori disusun menurut Nama
                Pekerja dalam urutan menaik, berdasarkan nama pertama. Ciri
                pengaturan ini membantu anda dengan cepat mengatur dan melihat
                butiran pekerja dengan cara yang paling sesuai untuk tujuan
                anda, sama ada anda mencari seseorang secara khusus atau perlu
                mengelompokkan pekerja mengikut kriteria tertentu seperti
                jabatan atau lokasi.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Soalan Lazim Direktori Jabatan</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Pengenalan Direktori Jabatan:</Body1Strong> Direktori
          Jabatan adalah pintu gerbang anda untuk memahami jalinan struktur
          organisasi anda, menawarkan pandangan yang jelas tentang hierarki
          jabatan, kepimpinan pengurusan, dan saluran komunikasi jabatan yang
          lancar.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="22">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                22. Bagaimanakah tag 'Semua', 'Kegemaran', dan 'Jabatan Anda'
                berfungsi dalam Direktori Jabatan?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Dalam Direktori Jabatan TeamConnect, tag membantu anda untuk
                menavigasi dan menapis maklumat jabatan dengan cepat. Berikut
                adalah apa yang setiap tag wakili:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Semua:</Body1Strong> Tag ini memaparkan semua
                    jabatan dalam organisasi anda, menyediakan tinjauan yang
                    komprehensif.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Kegemaran:</Body1Strong> Gunakan tag ini untuk
                    melihat senarai terpilih jabatan yang anda tanda sebagai
                    'Kegemaran', memudahkan akses kepada jabatan yang anda
                    interaksi dengan kerap.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Jabatan Anda:</Body1Strong> Tag ini menapis
                    paparan untuk menunjukkan hanya jabatan anda, membolehkan
                    akses cepat kepada maklumat dan kemas kini jabatan anda.
                  </li>
                </ul>
                Tag-tag ini direka untuk mempermudah navigasi anda dalam
                Direktori Jabatan, meningkatkan keupayaan anda untuk mencari dan
                berinteraksi dengan maklumat jabatan dengan cekap.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="23">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                23. Memahami Paparan Direktori Jabatan dalam TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Menavigasi melalui Direktori Jabatan dalam TeamConnect
                dipermudah dengan pelbagai pilihan paparan yang sesuai dengan
                preferensi anda. Setiap paparan menawarkan cara yang unik untuk
                meneroka maklumat jabatan yang komprehensif, termasuk Nama
                Jabatan, Pengurus Jabatan, Jabatan Induk, Telefon Jabatan,
                Hotline Jabatan, dan Emel Jabatan. Berikut adalah gambaran
                paparan yang tersedia:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Paparan Senarai:</Body1Strong> Penyusunan lalai
                    yang mengatur semua jabatan secara rapi, memberikan gambaran
                    yang jelas dan ringkas.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Paparan Kad Besar:</Body1Strong> Beralih ke
                    paparan ini untuk melihat kad jabatan terperinci, menawarkan
                    ringkasan lanjut informasi utama setiap jabatan.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Paparan Carta Organisasi:</Body1Strong> Untuk
                    representasi visual struktur jabatan dan hubungan, paparan
                    Carta Organisasi menggambarkan bagaimana setiap jabatan
                    sesuai dalam hierarki organisasi yang lebih besar.
                  </li>
                </ul>
                Untuk beralih antara paparan ini, cukup gunakan ikon-ikon
                bersebelahan kotak carian di atas Direktori Jabatan. Setiap
                paparan direka untuk membantu anda mengakses dan memahami
                maklumat jabatan dengan mudah, memastikan anda mendapatkan
                maklumat yang anda perlukan dalam format yang sesuai dengan
                anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="24">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                24. Bagaimana Menggunakan Kotak Pencarian dalam Direktori
                Jabatan
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Temukan secara lancar mana-mana jabatan dalam TeamConnect
                menggunakan kotak pencarian yang khusus terletak tepat di
                sebelah ikon tampilan di atas Direktori Jabatan. Hanya mula
                menaip nama jabatan yang anda cari, dan saksikan bagaimana
                direktori itu menyaring sendiri untuk menampilkan hanya padanan
                yang relevan. Ciri ini direka khusus untuk membantu anda
                mengelak daripada perlu menyaring secara manual melalui
                keseluruhan direktori, membawa jabatan yang anda perlukan terus
                ke hujung jari anda. Ingat, fungsi pencarian ini unik untuk
                Direktori Jabatan TeamConnect, jadi pastikan menggunakan kotak
                pencarian ini daripada pencarian utama Microsoft Teams yang
                terletak di sangat atas antara muka Teams untuk pertanyaan yang
                spesifik kepada jabatan.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="25">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                25. Bagaimanakah penapis abjad berfungsi dalam Direktori
                Jabatan?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mempercepatkan pencarian anda dalam Direktori Jabatan dengan
                penapis abjad yang terletak di atas. Ciri ini membolehkan anda
                untuk dengan cepat menyaring jabatan dengan memilih sebuah
                huruf. Setelah anda memilih sebuah huruf, TeamConnect menyaring
                dan menampilkan hanya jabatan-jabatan yang namanya bermula
                dengan huruf yang anda pilih, menjadikannya lebih mudah untuk
                mencari apa yang anda perlukan tanpa perlu menggulir melalui
                keseluruhan senarai. Untuk kembali ke paparan jabatan penuh,
                hanya nyahpilih huruf yang disorot. Navigasi abjad intuitif ini
                memastikan anda boleh mencari mana-mana jabatan dengan efisien
                melalui huruf awalnya.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="26">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                26. Bagaimana saya boleh menghubungi pengurus jabatan?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Menghubungi pengurus jabatan dalam TeamConnect adalah mudah.
                Cuma cari jabatan yang berminat dan klik pada nama pengurus.
                Tindakan ini akan memicu sebuah tetingkap pop timbul yang
                menunjukkan pilihan hubungan pengurus dengan ikon akses cepat:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ikon Sembang:</Body1Strong> Untuk memulakan
                    mesej segera melalui sembang Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ikon Emel:</Body1Strong> Untuk membuka emel
                    baru yang dialamatkan kepada mereka dalam Microsoft Outlook.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ikon Panggilan Video:</Body1Strong> Untuk
                    memulakan panggilan video melalui Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ikon Panggilan:</Body1Strong> Untuk memulakan
                    panggilan suara menggunakan Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ikon Lagi (...):</Body1Strong> Untuk pilihan
                    tambahan seperti menjadualkan mesyuarat atau menambahkan
                    maklumat hubungan mereka ke senarai Teams anda.
                  </li>
                </ul>
                Ikon-ikon ini menyediakan kaedah yang lancar untuk menghubungi
                pengurus jabatan secara langsung dari dalam TeamConnect,
                memastikan komunikasi yang efisien dalam organisasi anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="27">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                27. Bagaimana saya boleh melihat atau menghubungi pekerja dalam
                satu jabatan?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Untuk meneroka siapa di dalam satu jabatan di TeamConnect, lihat
                sahaja tiga titik di sebelah nama jabatan. Mengkliknya akan
                membuka pilihan untuk tindakan lanjut:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Lihat Pekerja dalam Jabatan Ini:</Body1Strong>{" "}
                    Pilih ini untuk memaparkan senarai semua pekerja yang
                    berkaitan dengan jabatan tersebut. Nombor di sebelah pilihan
                    ini menunjukkan berapa ramai pekerja dalam jabatan itu,
                    menyediakan kiraan kasar dengan cepat.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Carta Organisasi Jabatan:</Body1Strong> Jika
                    anda ingin memahami hierarki jabatan, pilih 'Carta
                    organisasi jabatan' untuk mendedahkan susun atur struktur,
                    memaparkan hubungan antara jabatan induk, jabatan yang
                    dipertanyakan, dan sebarang subjabatan. Representasi visual
                    ini membantu anda menavigasi dan memahami kerangka
                    organisasi, memudahkan identifikasi dan menghubungi ahli
                    jabatan tertentu.
                  </li>
                  <li>
                    <Body1Strong>Jabatan {">"} Pekerja:</Body1Strong> Selepas
                    melihat pekerja di dalam jabatan, gunakan jalan breadcrumb
                    ini untuk mudah menavigasi kembali ke senarai jabatan,
                    memelihara pengalaman penjelajahan yang lancar dalam
                    TeamConnect.
                  </li>
                </ul>
                Ciri-ciri ini membolehkan anda bukan sahaja melihat senarai
                pekerja dalam jabatan tertentu tetapi juga menawarkan jalan
                untuk menghubungi mereka secara langsung melalui alat komunikasi
                TeamConnect.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="28">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                28. Menemui Perincian Jabatan Secara Mendalam dalam TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Gali perincian tentang mana-mana jabatan dengan klik yang mudah
                pada namanya dalam TeamConnect. Dialog terperinci terbuka,
                menawarkan pemandangan informasi yang kaya yang menggambarkan
                gambaran menyeluruh tentang peranan dan sumber jabatan dalam
                organisasi anda. Anda akan menemui segalanya dari kepemimpinan
                dan pengecam kewangan hingga saluran komunikasi dan pautan
                sosial. Ciri ini adalah pintu gerbang anda ke:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    Nadi jabatan – tujuannya, orang-orangnya, dan metrik
                    prestasi.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Saluran hubungan – talian langsung kepada tokoh-tokoh
                    penting jabatan dan platform komunikasi.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    Jaringan organisasi – memahami bagaimana jabatan
                    mengintegrasi dengan struktur syarikat yang lebih luas.
                  </li>
                </ul>
                Sama ada itu pertanyaan cepat atau penyelaman mendalam ke dalam
                data jabatan, TeamConnect melengkapkan anda dengan alat untuk
                berhubung dan memahami ekosistem unik dalam organisasi anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="29">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                29. Ingin Tahu Mengapa Tidak Ada Alamat Jabatan dalam
                TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mengenal pasti lokasi jabatan kadangkala terasa seperti mencari
                harta tanpa peta. Dalam TeamConnect, anda biasanya tidak akan
                menemui alamat khusus untuk setiap jabatan. Inilah mengapa
                detail kartografi ini seringkali dihilangkan:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Lokasi Berbilang:</Body1Strong> Banyak jabatan
                    tidak terhad kepada satu ruang; mereka berkembang di
                    pelbagai pejabat dan geografi.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Struktur yang Fleksibel:</Body1Strong> Landskap
                    organisasi sentiasa berubah, dengan jabatan berkembang,
                    bergabung, atau bahkan bubar selari dengan pergeseran
                    strategik.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Kolaborasi Antara Jabatan:</Body1Strong> Ruang
                    kerja moden berdenyut dengan semangat kolaboratif, sering
                    menggabungkan bakat dari pelbagai jabatan dalam ruang
                    bersama.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Budaya Kerja Jauh:</Body1Strong> Dengan
                    fleksibilitas kerja jauh, jangkar jabatan tidak selalu
                    diletakkan di lokasi pejabat fizikal.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Nuansa Undang-undang:</Body1Strong> Jabatan
                    biasanya mengorbit sekitar struktur undang-undang syarikat
                    daripada memiliki alamat sendirian.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Streamlining Operasional:</Body1Strong>{" "}
                    Memfokuskan pada kerjasama dinamik, bukan alamat statik,
                    membantu menjaga ketangkasan operasional.
                  </li>
                </ul>
                Semuanya adalah tentang memeluk pendekatan tanpa batasan untuk
                kerjasama dan kecekapan. TeamConnect mencerminkan filosofi ini
                dengan menekankan hubungan daripada lokasi.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="30">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                30. Apakah maklumat jabatan anda tiada atau lapuk?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Pengedit jabatan ditetapkan dalam organisasi anda untuk
                menambah, mengemaskini, atau membetulkan maklumat tertentu
                jabatan dalam TeamConnect. Jika anda tidak pasti siapa pengedit
                jabatan anda atau jika maklumat tertentu memerlukan campur
                tangan pasukan sokongan HR, sila hubungi{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>{" "}
                untuk panduan.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>FAQ Direktori Pejabat</Subtitle2>
      <Body1>
        <p>
          <Body1Strong>Pengenalan Direktori Pejabat:</Body1Strong> Temui tulang
          belakang kehadiran fizikal organisasi anda melalui Direktori Pejabat,
          yang merangkumi butiran penting pejabat, spesifikasi operasi, dan
          pandangan menyeluruh terhadap jabatan dan personel yang mendefinisikan
          setiap lokasi.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="31">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                31. Mengemudi Teg dalam Direktori Pejabat
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Cari jalan anda dalam Direktori Pejabat dengan mudah menggunakan
                sistem teg kami yang direka untuk akses cepat ke lokasi yang
                tepat. Inilah cara setiap teg dapat membantu mempercepat
                pencarian anda:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Semua:</Body1Strong> Memaparkan senarai lengkap
                    pejabat, menyediakan pandangan helikopter lokasi organisasi
                    anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Kegemaran:</Body1Strong> Membantu anda
                    memfokuskan pada lokasi pejabat yang anda kerap kunjungi
                    atau perlu pantau, dengan menandanya sebagai 'Kegemaran'.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Pejabat Anda:</Body1Strong> Teg peribadi ini
                    menapis direktori untuk menunjukkan lokasi pejabat di mana
                    anda bertugas, menjaga pangkalan rumah anda di hujung jari
                    anda.
                  </li>
                </ul>
                Sama ada anda menempah bilik mesyuarat atau merancang lawatan,
                teg-teg ini menawarkan cara yang dipersonalisasi dan
                dipermudahkan untuk berinteraksi dengan landskap geografi
                organisasi anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="32">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                32. Menguasai Paparan Direktori Pejabat dalam TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Jelajahi tata letak organisasi pelbagai lokasi syarikat anda
                dengan paparan Direktori Pejabat TeamConnect. Setiap paparan
                direka untuk memudahkan aspek unik navigasi pejabat dan
                pemahaman:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Paparan Senarai:</Body1Strong> Tetapan lalai
                    yang mengatur semua lokasi pejabat menjadi senarai yang
                    mudah dibaca.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Paparan Kad Besar:</Body1Strong> Paparan Kad
                    Besar dalam Direktori Pejabat memperkaya pandangan anda
                    dengan penyajian terperinci setiap pejabat. Ketika
                    berinteraksi dengan kad pejabat, anda akan menemui banyak
                    maklumat dan pilihan tindakan dalam menu "Lebih (...)" yang
                    tertera:
                    <ol>
                      <li
                        style={{ marginTop: "8px" }}
                        className={styles.bulletBottomMargin}
                      >
                        <Body1Strong>Lihat Jabatan:</Body1Strong> Navigasi
                        langsung ke senarai semua jabatan yang terletak dalam
                        pejabat.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Lihat Kakitangan:</Body1Strong> Akses
                        senarai lengkap kakitangan yang ditugaskan ke pejabat,
                        memudahkan kontak langsung atau eksplorasi personel
                        pejabat.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Carta Org Entiti Perniagaan:</Body1Strong>{" "}
                        Visualisasikan kedudukan pejabat dalam konteks
                        organisasi yang lebih besar untuk pemahaman yang jelas
                        tentang struktur hierarki.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Kontak Penting:</Body1Strong> Kenal pasti
                        kontak penting pejabat untuk komunikasi yang lebih
                        lancar.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Cuti Umum Pejabat:</Body1Strong> Dapatkan
                        maklumat tentang cuti umum khusus pejabat untuk
                        merancang jadual anda dengan sewajarnya.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        <Body1Strong>Waktu Bekerja Pejabat:</Body1Strong> Lihat
                        waktu operasi pejabat untuk menyelaraskan kunjungan atau
                        panggilan anda dengan waktu aktif mereka.
                      </li>
                    </ol>
                    Paparan ini tidak hanya menyediakan gambaran visual dan
                    informasi tetapi juga akses cepat ke wawasan yang lebih
                    mendalam dan tindakan langsung yang berkaitan dengan
                    pejabat, semuanya tersusun rapi dalam pilihan "Lebih (...)"
                    untuk keselesaan anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Paparan Carta Org:</Body1Strong> Visualisasikan
                    struktur hierarki pejabat induk dan sub-pejabat untuk
                    memahami keterhubungan antara lokasi pejabat yang berbeza
                    dalam organisasi anda.
                  </li>
                </ul>
                Togglekan melalui ikon-ikon ini di sebelah kotak carian untuk
                menyesuaikan pengalaman melayari anda mengikut keperluan anda
                segera.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="33">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                33. Mencari Pejabat dengan Cepat menggunakan Ciri Pencarian
                TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Memfokuskan pada lokasi pejabat yang betul dalam TeamConnect
                dipermudah dengan ciri pencarian khusus. Diposisikan dengan
                menonjol di sebelah ikon paparan, kotak pencarian adalah alat
                anda untuk menentukan pejabat berdasarkan nama. Ketika anda
                menaip, direktori memperhalusi paparan untuk hanya menunjukkan
                pejabat yang sepadan dengan input anda, mempercepat proses
                pencarian anda. Penting untuk menggunakan kotak pencarian ini
                dalam TeamConnect untuk hasil yang paling akurat dan relevan,
                berbanding dengan pencarian umum Microsoft Teams. Dengan
                kefungsian pencarian yang ditargetkan ini, anda dapat dengan
                cepat menavigasi ke mana-mana pejabat dalam rangkaian organisasi
                anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="34">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                34. Meneroka Direktori Pejabat Mengikut Abjad
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Jika anda lebih suka mencari mengikut abjad, penapis abjad kami
                adalah cara cepat untuk menemukan pejabat mengikut nama. Di
                bahagian atas Direktori Pejabat, anda akan melihat huruf A
                hingga Z. Mengklik pada suatu huruf akan menapis paparan untuk
                menunjukkan hanya pejabat yang bermula dengan huruf tersebut,
                memudahkan proses pencarian anda. Untuk menetapkan semula dan
                melihat semua pejabat lagi, hanya nyahpilih huruf yang aktif.
                Ciri ini sangat berguna apabila anda sudah mengenal nama pejabat
                tetapi perlu cara cepat untuk menemukannya dalam TeamConnect.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="35">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                35. Mendedahkan Profil Pejabat Menyeluruh dalam TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Memulakan perjalanan eksplorasi melalui inti kehadiran fizikal
                organisasi anda dengan profil pejabat TeamConnect. Mengklik nama
                pejabat akan membuka dialog profil yang cerah, penuh dengan tab
                yang mengandungi wawasan pejabat terperinci:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ikhtisar:</Body1Strong> Menyelam ke dalam teras
                    identiti pejabat dengan penerangan terperinci, dari struktur
                    perniagaan hingga jam buka dan waktu setempat, merangkumi
                    esensi daripada persekitaran pejabat.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Kontak Penting:</Body1Strong> Mendapatkan akses
                    kepada kontak penting untuk perkhidmatan kecemasan dan
                    sokongan luar, setiap satu klik melalui Sistem Telefon
                    Microsoft Teams.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Kontak Pejabat:</Body1Strong> Menghubungi
                    perkhidmatan sokongan dalaman dengan mudah, dengan butiran
                    kontak dan pilihan libatan langsung di hujung jari anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Cuti Umum:</Body1Strong> Kekal terkini dengan
                    cuti khusus pejabat, memastikan rancangan anda sejajar
                    dengan pemerhatian tempatan dan jadual kerja.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Waktu Pejabat:</Body1Strong> Merancang
                    interaksi anda dengan pengetahuan tepat tentang kapan
                    pejabat sibuk dengan aktiviti atau berakhir untuk hari itu.
                  </li>
                </ul>
                Sama ada tenaga yang sibuk di Austin atau hab strategik di
                Singapura, setiap tab menawarkan pintu gerbang untuk memahami
                dan berhubung dengan pelbagai tempat yang membentuk kehadiran
                global syarikat anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="36">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                36. Menekuni Dinamika Jabatan dalam Pejabat
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Mengembara dalam rangkaian profesional jabatan sebuah pejabat
                berlangsung dengan mudah dalam TeamConnect. Berikut adalah cara
                untuk menelusuri perincian:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Lihat Jabatan di Pejabat Ini:</Body1Strong>{" "}
                    Temui semua jabatan yang membentuk struktur pejabat dengan
                    memilih pilihan ini dari menu Lebih (...).
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Wawasan Organisasi:</Body1Strong> Klik pada
                    mana-mana jabatan untuk memperlihatkan carta organisasinya
                    bagi representasi visual strukturnya, dengan jejak roti yang
                    memandu anda: Pejabat {">"} Jabatan {">"} Carta.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Penyelaman Mendalam Jabatan:</Body1Strong>{" "}
                    Terokai lebih lanjut sebuah jabatan dengan mengklik pada
                    menu Lebihnya (...) di sebelah nama jabatan. Di sini, anda
                    boleh mengakses senarai semua pekerja dalam jabatan
                    tersebut, langsung dalam konteks pejabat.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Navigasi Jejak Roti:</Body1Strong> Berpindah
                    melalui tahap dengan lancar. Dari pekerja kembali ke jabatan
                    atau dari carta organisasi, jejak roti (Pejabat {">"}{" "}
                    Jabatan {">"} Pekerja {">"} Carta) menjaga perjalanan anda
                    intuitif dan di landasan yang betul.
                  </li>
                </ul>
                Laluan rumit TeamConnect mempelawa anda untuk meneroka,
                memahami, dan melibatkan setiap lapisan jabatan pejabat anda,
                memperkasakan anda dengan kuasa navigasi yang komprehensif.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="37">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                37. Berhubung dengan Kakitangan Pejabat dalam TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Ingin tahu tentang wajah-wajah di sebalik sebuah pejabat?
                Jawapannya hanya satu klik saja dalam TeamConnect. Engage dengan
                tanda elipsis (tiga titik) di sebelah nama pejabat pilihan anda
                untuk mendedahkan rangkaian pilihan interaktif:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Lihat Pekerja di Pejabat Ini:</Body1Strong>{" "}
                    Dedahkan senarai semua profesional yang menjadikan pejabat
                    ini rumah kerja mereka. Nombor yang dipaparkan menawarkan
                    kiraan cepat pekerja, mempelawa anda untuk menyelami
                    komuniti pejabat.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Pilihan Hubungan:</Body1Strong> Setiap entri
                    pekerja dilengkapi dengan ikon tindakan cepat, membolehkan
                    anda untuk memulakan sembang, panggilan, atau bahkan video
                    konferensi langsung dari TeamConnect, memupuk komunikasi
                    dalaman pejabat yang lancar.
                  </li>
                  <li>
                    <Body1Strong>Navigasi:</Body1Strong> Untuk kembali ke
                    senarai pejabat yang lebih luas dari senarai pekerja, hanya
                    klik 'Pejabat' dalam jejak roti (Pejabat {">"} Pekerja), dan
                    anda akan dibawa kembali ke gambaran keseluruhan pejabat.
                  </li>
                </ul>
                Dengan ciri-ciri ini, TeamConnect memudahkan visualisasi
                pengedaran tenaga kerja merentasi pejabat dan untuk menghubungi
                dengan usaha yang mudah, memupuk tempat kerja yang terhubung
                tanpa mengira jarak.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="38">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                38. Adakah maklumat pejabat anda hilang atau lapuk?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Kemas kini maklumat pejabat di TeamConnect boleh diuruskan oleh
                penyunting pejabat yang ditugaskan. Jika anda tidak pasti siapa
                penyunting pejabat atau memerlukan bantuan mengenai maklumat
                yang berada di bawah bidang kuasa HR, pasukan sokongan HR sedia
                membantu di{" "}
                <Link href={`mailto:${hrSupport}`}>
                  <Body1Strong className={styles.fontColor}>
                    {itSupport}
                  </Body1Strong>
                </Link>
                .
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Soalan Lazim Tetapan</Subtitle2>
      <p></p>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="39">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                39. Kepentingan Tetapan Umum yang Tepat dalam TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Dalam landskap kerja yang dinamik masa kini, kekal terhubung dan
                terkoordinasi adalah lebih penting dari sebelumnya. Inilah
                kepentingan mengemas kini Tetapan Umum anda dalam TeamConnect:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Pemilihan Tempat Kerja:</Body1Strong> Menangkap
                    esensi setting kerja anda dengan mengemas kini Tempat Kerja
                    anda untuk mencerminkan situasi semasa anda, sama ada
                    berpindah dari ketenangan Home-Office ke kehingaran Pejabat
                    Utama, atau bahkan menetap di Kawasan Awam. Keklaran lokasi
                    kerja anda memberi wawasan kepada rakan sekerja bukan hanya
                    tentang cara terbaik untuk menghubungi anda, tetapi juga
                    tentang sifat perbualan yang mereka dapat libatkan dengan
                    anda, melindungi kerahsiaan perbincangan sensitif
                    terutamanya ketika anda berada di persekitaran terbuka
                    pejabat pelanggan atau duduk di ruang menunggu lapangan
                    terbang.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Spesifikasi Tempat Kerja:</Body1Strong>{" "}
                    Berkongsi bandar kerja anda lebih daripada menjembatani
                    jarak dalam susunan pasukan jauh—ia juga membuka pintu ke
                    pertemuan profesional spontan atau bersosial secara santai.
                    Sama ada itu kopi cepat, makan tengah hari yang santai, atau
                    pertemuan kolaboratif, mengetahui anda berada di bandar yang
                    sama dapat mengubah hari biasa menjadi peluang untuk
                    sambungan muka ke muka yang bermakna. Ini bukan hanya
                    mengukuhkan ikatan pasukan tetapi juga memberikan pemahaman
                    yang lebih mendalam tentang nuansa tempatan yang mungkin
                    mempengaruhi kerja anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Ketepatan Zon Waktu:</Body1Strong> Sebagai
                    pasukan merangkumi benua, memastikan zon waktu anda
                    mencerminkan lokasi semasa anda adalah penting untuk
                    mengharmonikan komunikasi. Waktu Tempatan dalam profil
                    TeamConnect anda secara langsung dipengaruhi oleh tetapan
                    ini, menghilangkan sebarang tekaan untuk rakan sekerja yang
                    cuba menghubungi anda.
                  </li>
                </ul>
                Terokai 'Tetapan' {">"} 'Tetapan Umum' dalam TeamConnect untuk
                menala perincian ini. Sedikit masa yang dihabiskan untuk
                mengemas kini tetapan anda boleh meningkatkan kekompakan dan
                kecekapan usaha kolaboratif anda secara keseluruhan.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="40">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                40. Menyahut Pemilihan Tempat Kerja dalam TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Memilih tetapan yang tepat dalam dropdown Tempat Kerja
                menyesuaikan TeamConnect untuk mencerminkan status kerja anda
                dengan tepat. Arahkan kursor ke ikon maklumat untuk penjelasan
                terperinci mengenai setiap pilihan:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Checked-Out/Break:</Body1Strong> Menandakan
                    masa jauh dari kerja, sama ada untuk rehat atau penghujung
                    hari.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Office/Field-Office:</Body1Strong> Menunjukkan
                    kehadiran di ruang kerja yang diuruskan syarikat.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Home-Office:</Body1Strong> Bekerja dari ruang
                    kerja rumah yang khusus.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Remote-Workplace/Public-Area:</Body1Strong>{" "}
                    Beroperasi dari persekitaran bukan pejabat, menunjukkan
                    keperluan untuk komunikasi fleksibel.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Customer Workplace/Private Office:
                    </Body1Strong>{" "}
                    Berada di tempat dengan pelanggan, dengan catatan mengenai
                    tahap privasi untuk sensitiviti perbualan.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Commute/Business-Travel/Training:</Body1Strong>{" "}
                    Bergerak atau terlibat dalam aktiviti kerja tertentu di luar
                    pejabat.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>
                      Public-Holiday/Vacation/Various Leaves:
                    </Body1Strong>{" "}
                    Resmi tidak bertugas untuk sebab peribadi, kesihatan, atau
                    cuti umum.
                  </li>
                </ul>
                Memilih tetapan tempat kerja yang sesuai memastikan pasukan anda
                mempunyai konteks yang diperlukan untuk interaksi, menghormati
                ketersediaan dan privasi.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="41">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                41. Mengemudi Status 'Checked-In' dalam TeamConnect
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Tanda 'Checked-In' dalam Direktori Pekerja direka untuk
                memaparkan semua pekerja yang sedang berada di tempat kerja.
                Visibiliti ini ditentukan oleh pilihan Tempat Kerja yang anda
                buat:
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Persekitaran Kerja Aktif:</Body1Strong> Pilihan
                    Pejabat, Field-Office, Home-Office, Remote-Workplace,
                    Public-Area, Customer-Workplace, Customer Private-Office,
                    Commute, Business-Travel, dan Training menandakan anda
                    terlibat dalam kerja, menjadikan anda 'Checked-In' dan
                    kelihatan aktif kepada rakan sekerja.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Penetapan Bukan Kerja:</Body1Strong> Memilih
                    Checked-Out, Break, Public-Holiday, Vacation, On-Leave,
                    Maternity-Leave, atau Sick-Leave mengeluarkan anda dari
                    senarai 'Checked-In', menetapkan jangkaan yang jelas untuk
                    ketersediaan dan masa respons.
                  </li>
                </ul>
                Fungsi ini membantu membezakan tempoh kerja dari masa peribadi,
                membantu dalam kolaborasi dan komunikasi pasukan yang efektif.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Subtitle2>Pengurusan Data dan Soalan Lazim Privasi</Subtitle2>
      <Body1>
        <p>
          Pada era digital hari ini, memahami bagaimana data anda diuruskan
          adalah sangat penting. TeamConnect komited kepada ketelusan dan amalan
          perlindungan data yang kukuh. Di bawah, anda akan menemui maklumat
          mengenai bagaimana kami mengendalikan, menyimpan, dan melindungi data
          anda dalam TeamConnect, memastikan ruang kerja digital anda selamat
          dan mematuhi peraturan.
        </p>
      </Body1>
      <div>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="42">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                42. Bagaimana TeamConnect melindungi data peribadi saya?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                <p>
                  TeamConnect komited untuk melindungi maklumat peribadi anda
                  dengan pendekatan menyeluruh terhadap keselamatan data:
                </p>
                <ul>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Log Masuk Sekali (SSO):</Body1Strong> Untuk
                    meningkatkan keselamatan dan pengalaman pengguna,
                    TeamConnect mengintegrasikan Log Masuk Sekali (SSO)
                    automatik dengan akaun Microsoft 365 anda, memudahkan
                    pengurusan akses yang selamat.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Penyulitan:</Body1Strong> Kami memastikan
                    bahawa semua data dalam TeamConnect disulitkan semasa dalam
                    transit dan ketika tidak aktif, menjadikannya tidak dapat
                    diakses oleh pihak yang tidak berkebenaran.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Pengurangan Data:</Body1Strong> Pengumpulan
                    data kami terhad sekadar apa yang penting untuk fungsi
                    TeamConnect. Maklumat pengguna yang kami simpan hanya Nama
                    Utama Pengguna (UPN) dari Azure Entra ID, yang mungkin
                    menyerupai alamat emel syarikat anda.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Akses Terhad:</Body1Strong> Akses kepada UPN
                    dibatasi kepada Pentadbir Langganan organisasi anda dalam
                    portal Langganan kami, memastikan maklumat sensitif hanya
                    dapat dilihat oleh kakitangan yang berkebenaran.
                  </li>
                  <li className={styles.bulletBottomMargin}>
                    <Body1Strong>Penghapusan Data Segera:</Body1Strong>{" "}
                    TeamConnect direka untuk menghapuskan UPN yang disimpan
                    dengan serta-merta di bawah tiga syarat tertentu: jika
                    langganan dibatalkan oleh Pentadbir Langganan, jika seorang
                    pengguna dikeluarkan oleh HR dalam TeamConnect, atau jika
                    langganan TeamConnect tamat. Ini memastikan data peribadi
                    anda tidak disimpan melebihi hayat berguna atau
                    keperluannya.
                  </li>
                </ul>
                <p>
                  Melalui langkah-langkah ini, TeamConnect komited untuk
                  memelihara piawaian perlindungan data yang tertinggi,
                  memastikan maklumat peribadi anda selamat dan terpelihara.
                </p>
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="43">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                43. Anda bertanya-tanya di mana data anda berada dengan
                TeamConnect?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect memecahkan kebiasaan penawaran SaaS tradisional
                dengan memastikan data anda tidak pernah meninggalkan organisasi
                anda. Tidak seperti praktik biasa menyimpan data pada server
                awan luar, TeamConnect dengan cerdik menggunakan Microsoft 365
                SharePoint Online organisasi anda untuk menempatkan semua data
                aplikasi. Pendekatan inovatif ini tidak hanya memperjuangkan
                kedaulatan data tetapi juga memperkuat keamanan, selaras
                sempurna dengan dedikasi teguh kami untuk melindungi privasi
                anda dan mematuhi standar kepatuhan yang ketat.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="44">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                44. Apakah data saya akan dibagi dengan pihak ketiga?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                Tidak, data tidak dibagi dengan pihak ketiga! Cubeet gigih dalam
                komitmen kami terhadap privasi dan keamanan data anda. Kami
                tidak membagikan data TeamConnect apa pun dengan pihak ketiga
                tanpa mendapatkan persetujuan eksplisit dari pelanggan kami. Di
                lingkungan yang aman kami, kami hanya menyimpan pengenal
                penting, seperti Nama Utama Pengguna (UPN), nama organisasi,
                nama penyewa, dan ID penyewa, dalam portal Berlangganan kami.
                Informasi ini penting untuk penyediaan layanan dan dilindungi di
                bawah kebijakan privasi kami yang ketat. Yakinlah, praktik
                pengelolaan data kami dirancang untuk memastikan bahwa data anda
                tetap rahasia, dengan transparansi dan kontrol yang kokoh di
                tangan anda.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="45">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                45. Bagaimana TeamConnect mematuhi peraturan perlindungan data
                seperti GDPR?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect selaras dengan peraturan perlindungan data seperti
                GDPR, HIPAA, CCPA, dan lainnya dengan memanfaatkan fitur
                kepatuhan yang melekat pada lingkungan Microsoft 365, di mana
                semua data pelanggan disimpan secara aman. Tidak seperti model
                SaaS tradisional, TeamConnect tidak menyimpan data pelanggan apa
                pun di servernya; sebaliknya, ia beroperasi dalam lingkungan
                Microsoft 365 organisasi anda, mematuhi standar keamanan dan
                kepatuhan yang kuat. Pendekatan ini memastikan bahwa tata kelola
                data anda tetap konsisten dengan kerangka kepatuhan komprehensif
                yang disediakan oleh Microsoft 365, termasuk kepatuhan terhadap
                GDPR, HIPAA untuk informasi kesehatan, CCPA untuk penduduk
                California, dan peraturan perlindungan data global lainnya.
                Dengan terintegrasi dengan mulus dengan setup Microsoft 365 anda
                yang ada, TeamConnect memberdayakan organisasi anda untuk
                mempertahankan kontrol penuh atas data anda, memastikan
                kepatuhan dengan undang-undang dan peraturan perlindungan data
                yang paling ketat.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value="46">
            <AccordionHeader>
              <Body1Strong className={styles.fontColor}>
                46. Bagaimanakah TeamConnect mengendalikan data dari Microsoft
                Teams chats, calls, video calls, e-mel Outlook, dan panggilan
                sistem telefon?
              </Body1Strong>
            </AccordionHeader>
            <AccordionPanel>
              <Body1>
                TeamConnect memanfaatkan Microsoft Graph API untuk membolehkan
                interaksi langsung seperti chat, panggilan, video call, dan
                e-mel Outlook dalam Microsoft Teams, menyediakan pengalaman
                pengguna yang lancar. Secara kritikal, walaupun TeamConnect
                memulakan komunikasi ini, ia melakukannya tanpa campur tangan
                dalam proses komunikasi atau memiliki keupayaan untuk merakam
                atau menyimpan sebarang kandungan interaksi. Semua perbualan,
                panggilan, dan e-mel berlangsung dan diuruskan langsung di dalam
                batasan selamat Microsoft Teams dan Outlook, mematuhi secara
                ketat standard keselamatan dan privasi Microsoft 365 yang
                robust. Ini memastikan bahawa TeamConnect meningkatkan keupayaan
                komunikasi anda sambil mengekalkan kerahsiaan dan integriti data
                anda sepenuhnya.
              </Body1>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <p></p>
      <Body1>
        <p>
          Untuk wawasan terperinci mengenai polisi dan praktik data kami, anda
          juga mungkin ingin merujuk{" "}
          <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>
              Panduan Pengguna TeamConnect
            </Body1Strong>
          </Link>{" "}
          atau jabatan IT organisasi anda di{" "}
          <Link href={`mailto:${itSupport}`}>
            <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong>
          </Link>{" "}
          untuk perincian yang berkaitan dengan persediaan anda.
        </p>
      </Body1>
      <p></p>
      <Subtitle1 className={styles.fontColor}>
        Kami Mendengar: Kongsi Maklum Balas dan Idea Anda
      </Subtitle1>
      <Body1>
        <p>
          Pandangan anda mendorong evolusi TeamConnect. Sama ada anda penuh
          dengan idea ciri hebat atau memiliki beberapa maklum balas mengenai
          pengalaman semasa anda, kami semua telinga. Sumbangan anda sangat
          berharga dalam usaha berterusan kami untuk menyediakan perkhidmatan
          yang terbaik.
        </p>
      </Body1>
      <Subtitle2>Maklum Balas dan Cadangan Ciri</Subtitle2>
      <Body1>
        <p>
          Bersedia untuk berkongsi? Pergi ke halaman{" "}
          <Link
            style={{ fontWeight: 600 }}
            href={feebackLink}
            target='_blank'>
            Halaman Maklum Balas
          </Link>
          . Sama ada itu adalah cadangan untuk ciri baru atau maklum balas
          mengenai fungsi yang sedia ada, hanya pilih pilihan yang sesuai dari
          dropdown dalam borang. Sumbangan anda membantu membentuk masa depan
          TeamConnect, memastikan ia memenuhi dan melebihi keperluan anda.
        </p>
      </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
