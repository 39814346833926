import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});
function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>مركز مساعدة TeamConnect والأسئلة الشائعة</Title3>
        <p><Body1>
          مرحبًا بكم في مركز مساعدة TeamConnect، الذي تم تصميمه لدعم تجربتكم مع تطبيق الدليل الشامل للشركات لدينا. هنا ستجدون موارد لتحسين استخدامكم لـ TeamConnect ضمن Microsoft Teams، معززًا الاتصال عبر شبكتكم.
        </Body1></p>
        <Subtitle2>دليل المرجع السريع</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>قدرات بحث محسنة:</Body1Strong>
              اعثر على الزملاء المناسبين والأقسام وجهات الاتصال الأساسية بسرعة باستخدام أدوات البحث القوية لدينا.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>مخططات تنظيمية بصرية:</Body1Strong>
              استكشف هيكلية المنظمة الخاصة بك وافهم خطوط التقارير بسهولة من خلال مخططات تنظيمية بديهية.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>أدوات متطورة لاحتياجات اليوم:</Body1Strong>
              استفد من الوظائف الحديثة التي تم تصميمها لمتطلبات أماكن العمل المعاصرة.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>تحسين تجربتك</Subtitle2>
        <Body1>
          <p>
            يُحدث TeamConnect ثورة في كيفية تفاعلك داخل هيكلك التنظيمي، ويحول التواصل اليومي إلى تفاعلات استراتيجية.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>احصل على المساعدة والأسئلة الشائعة</Subtitle1>
        <Body1>
          <p>
            اعثر على إجابات للأسئلة المتكررة حول مميزات وإعدادات TeamConnect في هذا القسم المخصص. سواء كنت تتنقل في التطبيق، أو تخصص الإعدادات، أو تبحث عن حلول للتحديات، فإن مركز المساعدة هنا لمساعدتك بكفاءة.
          </p>
        </Body1>
        <Body1>
          <p>
            للحصول على دعم أكثر تعمقاً أو توجيه خاص، يرجى استشارة <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>دليل المستخدم TeamConnect</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        
        <Subtitle2>استخدام الأسئلة الشائعة حول TeamConnect</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. لا يمكن العثور على TeamConnect في الشريط الجانبي لمايكروسوفت تيمز؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  إذا لم يظهر TeamConnect بشكل واضح أو اختفى في الشريط الجانبي لمايكروسوفت تيمز، يمكنك تثبيته بسهولة للوصول السريع. فقط اتبع هذه الخطوات البسيطة:
                  <ol>
                    <li className={styles.bulletBottomMargin}>افتح مايكروسوفت تيمز وانقر على أيقونة '...' (+ التطبيقات) في أسفل الشريط الجانبي.</li>
                    <li className={styles.bulletBottomMargin}>ابحث عن 'TeamConnect' في شريط البحث في أعلى النافذة المنبثقة.</li>
                    <li className={styles.bulletBottomMargin}>انقر بزر الماوس الأيمن على تطبيق TeamConnect من نتائج البحث واختر 'تثبيت'.</li>
                  </ol>
                  سيتم الآن تثبيت TeamConnect في الشريط الجانبي، مما يجعله قابلاً للوصول بنقرة واحدة فقط!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. تواجه مشاكل تقنية مع TeamConnect؟ إليك كيفية الحصول على المساعدة.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  إذا واجهت أي مشاكل تقنية مع TeamConnect داخل مايكروسوفت تيمز، فإن فريق دعم تكنولوجيا المعلومات في منظمتك هنا للمساعدة.
                  تواصل معهم على <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>، وسوف يساعدونك على الفور.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. لا تستطيع رؤية تفاصيل موظفك؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  إذا بدا ملفك الشخصي فارغًا نوعا ما بمجرد إظهار اسمك وبريدك الإلكتروني، فمن المحتمل أن فريق الموارد البشرية لم يضف بعد بقية تفاصيل موظفك إلى TeamConnect.
                  لا داعي للقلق! رسالة سريعة إلى فريق دعم الموارد البشرية ستحرك الأمور. هم في انتظار لملء ملفك التعريفي بجميع التفاصيل المهمة.
                  تواصل على <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> وشاهد كيف يتحول ملفك الشخصي إلى الحياة!
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. ما هي خيارات اللغة المتوفرة في TeamConnect؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  يدعم TeamConnect تنوع اللغات العالمي الموجود في Microsoft Teams من خلال دعم جميع لغاته. 
                  سواء كنت تفضل العربية أو اليونانية، اليابانية أو السويدية، لديك عالم من الخيارات بين يديك، بما في ذلك:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      العربية، البلغارية، الصينية (المبسطة والتقليدية)، الكرواتية، التشيكية، الدانماركية، الهولندية، الإنجليزية، الإستونية، الفنلندية، 
                      الفرنسية، الألمانية، اليونانية، العبرية، المجرية، الإندونيسية، الإيطالية، اليابانية، الكورية، اللاتفية، الليتوانية، النرويجية (بوكمال)، 
                      البولندية، البرتغالية (البرازيل والبرتغال)، الرومانية، الروسية، الصربية (اللاتينية)، السلوفاكية، السلوفينية، الإسبانية، السويدية، التايلندية،
                      التركية، الأوكرانية، الفيتنامية.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  لتخصيص تجربة TeamConnect الخاصة بك إلى لغتك المفضلة، يرجى ضبط إعدادات اللغة مباشرة في Microsoft Teams، 
                  حيث يتكامل TeamConnect تمامًا مع هذه الإعدادات:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      اذهب إلى إعدادات Microsoft Teams الخاصة بك بالنقر على النقاط الثلاث الموجودة بجانب صورة ملفك الشخصي في الأعلى، ومن ثم اختر 'المظهر وقابلية الوصول'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      انتقل إلى قسم "اللغة" واختر لغتك المفضلة من قائمة الخيارات المنسدلة.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      قم بتأكيد التغييرات بالنقر على زر "حفظ وإعادة التشغيل" لتطبيق إعدادات اللغة الجديدة.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>ملاحظة: </Body1Strong>
                <Body1>
                  التغييرات التي تجريها على إعدادات اللغة في Microsoft Teams ستحدث أيضا تحديث سلس لتجربة TeamConnect الخاصة بك. 
                  تجدر الإشارة إلى أن تغيير اللغة في Teams قد يؤدي كذلك إلى تعديل تنسيق الوقت والتاريخ. في الوقت الحالي، 
                  يتطور TeamConnect لدعم الكتابة من اليمين إلى اليسار. نحن متحمسون لهذه التحسينات 
                  وسنوافيكم بالمستجدات حال توافرها.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. كيف يمكنني تغيير الثيم في TeamConnect؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  يتزامن TeamConnect مع تفضيلات ثيم Microsoft Teams الخاصة بك، بما في ذلك الخيارات الفاتحة والداكنة وعالية التباين. لتخصيص الثيم الخاص بك:
                  <ol>
                    <li className={styles.bulletBottomMargin}>استخدم إعدادات Microsoft Teams الخاصة بك بالنقر على الثلاث نقاط بجانب صورة ملفك الشخصي في الأعلى.</li>
                    <li className={styles.bulletBottomMargin}>اختر 'المظهر وسهولة الوصول' لاستكشاف خيارات الثيم.</li>
                    <li className={styles.bulletBottomMargin}>اختر الثيم المفضل لديك من الخيارات المتاحة لتحديث واجهة TeamConnect الخاصة بك على الفور لتتناسب مع ذلك.</li>
                  </ol>
                  سيتم تطبيق اختيار الثيم الخاص بك في Microsoft Teams تلقائيًا على TeamConnect، لضمان تجربة بصرية متسقة عبر مساحة عملك.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. استكشاف أزرار فعل الاتصال في TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  في جميع أنحاء TeamConnect، حيثما تجد تفاصيل الاتصال الخاصة بالموظف، ستكتشف أيضًا أزرار الوصول السريع الخاصة بنا. هذه الأدوات المفيدة
                  مصممة لتسهيل التواصل ضمن نظام Microsoft البيئي، مما يضمن أنك دائمًا على بُعد نقرة واحدة من الاتصال.
                  إليك دليلك الشامل لاستخدام هذه الأزرار بفعالية:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>أيقونة الدردشة:</Body1Strong> انقر لبدء دردشة فورية في Microsoft Teams. إذا كنت بحاجة للعودة إلى TeamConnect بعد بدء الدردشة، استخدم فقط السهم الخلفي في التطبيق المكتبي، أو الزر الخلفي في التطبيق المحمول، أو زر الرجوع في متصفحك.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>أيقونة البريد الإلكتروني:</Body1Strong> يفتح مسودة بريد إلكتروني جديدة في Outlook مع عنوان المستلم جاهزًا في خانة "إلى:".</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>أيقونة مكالمة فيديو:</Body1Strong> تبدأ مكالمة فيديو وجهًا لوجه باستخدام Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>أيقونة الاتصال:</Body1Strong> تبدأ مكالمة صوتية باستخدام Microsoft Teams، مما يجعل المناقشات السريعة سلسة.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>أيقونة الرسم التنظيمي:</Body1Strong> تكشف عن مكانة الموظف في التسلسل الهرمي التنظيمي، بما في ذلك مديره والتقارير المباشرة له.</li>
                    <li><Body1Strong>أيقونة المزيد (...):</Body1Strong> تكشف عن خيارات أخرى:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>اجتمع الآن:</Body1Strong> يفتح Microsoft Teams لبدء اجتماع فوري مع الموظف.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>اجتماع جديد:</Body1Strong> يوجهك إلى Microsoft Teams لجدولة اجتماع جديد.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>إضافة جهة اتصال:</Body1Strong> يضيف تفاصيل الاتصال الخاصة بالموظف من TeamConnect إلى تطبيق الأشخاص Microsoft Teams الجديد، مما يثري وظيفة الاتصال لديك.</li>
                      </ul>
                    </li>
                  </ul>
                  هذه الأزرار البديهية صممت لضمان أنك يمكنك الاتصال بكفاءة مع الزملاء، سواء لدردشة سريعة، أو إرسال بريد إلكتروني، أو لجدولة اجتماعات مستقبلية.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. إجراء المكالمات عبر TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  ترغب في إجراء مكالمة؟ TeamConnect يسهل التواصل الخاص بك من خلال التكامل مع Microsoft Teams.
                  فقط انقر على رقم الهاتف المطلوب في TeamConnect، وها هي واجهة الاتصال Microsoft Teams تظهر،
                  جاهزة لكي تقوم بالاتصال بسلاسة.
                  <p>
                  <Body1Strong>ملاحظة:</Body1Strong>
                  تواجه مشاكل في إجراء المكالمات؟ قد يكون ذلك بسبب سياسات تقنية المعلومات في مؤسستك أو
                  استخدام نظام هاتف غير نظام هاتف Microsoft Teams. التحقق من قسم تقنية المعلومات في  <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>، يمكن أن يوضح الأمر.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. هل هناك تكاليف مرتبطة بإجراء المكالمات عبر TeamConnect؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect يعزز تجربة الاتصال لديك من خلال استخدام Microsoft Teams. إليك ما تحتاج إلى معرفته عن تكاليف المكالمات:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      بالنسبة للمكالمات إلى هواتف العمل للموظفين، أو هواتف الإدارات، أو الخطوط الساخنة ضمن المنظمة (الأرقام الداخلية)،
                      عادة <Body1Strong>لا توجد تكاليف</Body1Strong> عند استخدام نظام هاتف Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      بالنسبة للمكالمات الخارجية، مثل تلك إلى الهواتف المحمولة أو الخطوط الخارجية، <Body1Strong>التعريفات القياسية</Body1Strong> لنظام هاتف منظمتك تنطبق.
                      هذه التعريفات تختلف بناءً على خطة الخدمة الخاصة بك مع Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>ملاحظة:</Body1Strong> إذا واجهت أي مشاكل أو كان لديك أسئلة حول تكاليف المكالمات، من الأفضل استشارة 
                    قسم تقنية المعلومات لديك على <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> للحصول على معلومات مفصلة 
                    تناسب إعداد وسياسات منظمتك.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. هل تلاحظ عدم تطابق مع حالة تواجدك 'خارج المكتب' في TeamConnect؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  لاحظت أن حالة تواجدك 'خارج المكتب' من Microsoft 365 لا تعكس بشكل صحيح في TeamConnect؟
                  لدينا الحل. تم بالفعل تنفيذ ميزة مزامنة حالة تواجد 'خارج المكتب' في TeamConnect.
                  نحن حالياً في انتظار طرح مايكروسوفت لهذه الميزة. عند حدوث ذلك، ستعكس حالة تواجدك 'خارج المكتب'
                  بدقة في TeamConnect، متماشياً مع مؤشرات حضور Microsoft Teams لتجربة سلسة.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>أسئلة متكررة حول دليل الموظفين</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >مقدمة دليل الموظفين:</Body1Strong> تنقل في المشهد المهني لمنظمتك مع دليل الموظفين، 
                مركزك المركزي للحصول على رؤى مفصلة للموظفين، ديناميكيات العمل، والتواصل المباشر مع ملفات تعريف زملائك وعلاقاتهم الإدارية.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. هل تحتاج إلى إضافة، تحديث، أو تصحيح بيانات الموظف الخاصة بك؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  لإضافة، تحديث، أو تصحيح بيانات الموظف الخاصة بك في TeamConnect، يرجى التواصل مع فريق دعم الموارد البشرية لمنظمتك.
                  هم مستعدون للمساعدة على <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. ماذا يحدث عند النقر على اسم موظف في الدليل؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  النقر على اسم موظف في دليل الموظفين TeamConnect يفتح صفحة ملف شخصي مفصلة لهذا الفرد. يوفر هذا الملف معلومات شاملة
                  عن الموظف، تشمل على سبيل المثال لا الحصر، الوظيفة، القسم، موقع المكتب، معلومات الاتصال، والمزيد. تم تصميمه ليقدم لك 
                  نظرة كاملة على الدور المهني للموظف وكيفية اندماجه في المنظمة، مما يسهل عليك فهم مسؤولياتهم 
                  وكيف يمكنك التعاون معهم.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. ما المعلومات المتضمنة في ملف الموظف الشخصي؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  كل ملف شخصي للموظف في TeamConnect هو لمحة شاملة، يقدم معلومات قيمة عن الحياة المهنية للفرد.
                  إليك ما ستجده:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>التفاصيل الأساسية:</Body1Strong> الاسم، الوظيفة، القسم، وموقع الشركة التابع لها.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>هيكل التقارير:</Body1Strong> معلومات عن التقارير المباشرة والوظيفية،
                      بما في ذلك المديرين الخطيين والوظيفيين.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>حالة العمل:</Body1Strong> الحالة الحالية مثل في إجازة، في الطريق،
                      في سفر عمل، إلخ.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>مكان العمل والتوقيت المحلي:</Body1Strong> موقع عمل الموظف الحالي و
                      المنطقة الزمنية المحلية.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>تفاصيل الاتصال:</Body1Strong> رقم الهاتف في العمل، رقم الهاتف المحمول، وعنوان البريد الإلكتروني.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>عنوان المكتب:</Body1Strong> موقع المكتب الكامل بما في ذلك اسم المبنى،
                      العنوان، وخريطة Google.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>حول:</Body1Strong> مقدمة شخصية قصيرة أو سيرة ذاتية مهنية.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>علاقات الموظف:</Body1Strong> تفاصيل عن هيكل التقارير وعلاقات الفريق للموظف.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>الروابط الاجتماعية واللغات:</Body1Strong> روابط للملفات الشخصية الاجتماعية المهنية
                      وكفاءات اللغة.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>المهارات:</Body1Strong> قائمة المهارات المهنية ومجالات الخبرة.</li>
                  </ul>
                  بالإضافة إلى ذلك، يقدم الملف الشخصي خيارات يمكن تنفيذها للاتصال عبر الدردشة، البريد الإلكتروني، مكالمة فيديو، أو جدولة الاجتماعات، إلى جانب 
                  الوصول السريع إلى مخطط المنظمة لفهم أوسع لسياق الفريق.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. كيف يمكنني تحديث معلومات ملفي الشخصي؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  تخصيص ملفك الشخصي سهل في TeamConnect. إليك كيفية القيام بذلك:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        انتقل إلى علامة تبويب <Body1Strong>الموظف</Body1Strong> وانقر على <Body1Strong>الأفاتار</Body1Strong> الخاص بك لفتح ملفك الشخصي.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        في الزاوية العلوية اليسرى، ستجد رابط <Body1Strong>"تحرير ملفك الشخصي"</Body1Strong>.
                        النقر على هذا سيفتح مربع الحوار لتحرير ملفك الشخصي.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        هنا، يمكنك تعزيز ملفك الشخصي عن طريق إضافة أو تحديث قسم <Body1Strong>حولك</Body1Strong>، وروابط وسائل التواصل الاجتماعي، ورقم الهاتف.
                        هذا يجعل من السهل على زملائك الاتصال بك أو متابعتك.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        لا تنسى الضغط على زر <Body1Strong>حفظ</Body1Strong> لتطبيق التغييرات.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        انتقل للأسفل في مربع الحوار لإضافة <Body1Strong>اللغات</Body1Strong> و <Body1Strong>الكفاءة</Body1Strong>،
                        بالإضافة إلى <Body1Strong>المهارات</Body1Strong>. مشاركة هذه المعلومات تساعد زملائك على فهم
                        مجالات خبرتك واللغات التي يمكنك التواصل بها.
                      </li>
                    </ol>
                  تحديث ملفك الشخصي لا يحافظ فقط على معلوماتك حديثة ولكن يعزز أيضاً الروابط داخل مؤسستك.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. تحديث مكان عملك، موقع عملك، والمنطقة الزمنية في TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  الحفاظ على معلومات مكان عملك، موقع عملك، والمنطقة الزمنية حديثة أمر حيوي للتعاون السلس في Microsoft Teams.
                  هذه التحديثات تنعكس فورًا في ملفك الشخصي في TeamConnect والقوائم، مما يمكن الزملاء من فهم مكان عملك بسرعة
                  وأفضل الأوقات للتواصل معك، مما يعزز التعاون الجماعي عبر المناطق والمناطق الزمنية المختلفة.
                  <p></p>
                  جاهز لتحديث تفاصيلك؟ الأمر سهل في TeamConnect! انتقل إلى 'الإعدادات' في القائمة العلوية وانتقل إلى 'الإعدادات العامة'.
                  هنا، يمكنك بسهولة تحديث مكان عملك والمنطقة الزمنية، وتحديد موقع عملك لليوم. تذكر أن تحفظ التغييرات لكي
                  تبقي الجميع مطلعين ولضمان التعاون الفعّال، بغض النظر عن موقعك!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. لاحظت شيئًا خاطئًا في ملف تعريف زميل؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  إذا صادفت أي تفاصيل في ملف تعريف زميل لا تبدو صحيحة، فإن الخطوة الأولى الأفضل هي إعطائهم تنبيهًا وديًا.
                  يجب أن يفي الدردشة السريعة أو البريد الإلكتروني بالغرض! إذا لم يتم تحديث المعلومات لسبب ما، لا تقلق—فقط
                  أرسل إشارة إلى فريق دعم الموارد البشرية لديك على <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>، وسيكونون سعداء
                  بالتدخل وترتيب الأمور.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. فهم مجموعات العلامات: الكل، المفضلة، المسجلون، والموظفون الجدد
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>الكل:</Body1Strong> هذه العلامة الافتراضية تعرض كل موظف في منظمتك، 
                      مما يوفر نظرة شاملة على الفريق.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>المفضلة:</Body1Strong> انقر هنا لتصفية عرضك لتلك الزملاء الذين علمتهم 
                      كـ'مفضلة'، مما يسهل الاتصال السريع بالأشخاص المهمين.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>المسجلون:</Body1Strong> هذه العلامة تشير إلى الموظفين النشطين في العمل، ما يميزهم 
                      عن مجرد وجودهم على الإنترنت في Microsoft Teams. عند التسجيل، يُظهر الموظفون أنهم في العمل ويشاركون موقع عملهم الحالي 
                      خلال اليوم. يمكن أن يشمل ذلك مجموعة متنوعة من أماكن العمل مثل المكتب، مكتب الميدان، مكتب المنزل، مكان العمل البعيد، مكان عمل العميل، 
                      مكتب العميل الخاص، المنطقة العامة، الانتقال، السفر التجاري، أو حتى التدريب. لذلك، يوفر وضع 'المسجلون' مؤشرًا واضحًا 
                      للعمل النشط، مما يقدم للزملاء فهمًا دقيقًا لسياق العمل الحالي والتوفر لكل منهم.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>الموظفون الجدد:</Body1Strong> اكتشف الوجوه الجديدة في TeamConnect مع هذه العلامة، التي تبرز
                      الموظفين الذين أُضيفوا إلى التطبيق خلال آخر 30 يومًا. عادة، يتماشى هذا مع الإضافات الأخيرة لمنظمتك، مما يساعد
                      على الترحيب بأعضاء الفريق الجدد.
                    </li>
                  </ul>
                  تم تصميم مجموعات العلامات هذه لتسهيل التنقل وتعزيز تجربتك في TeamConnect، مما يضمن لك القدرة دائمًا على
                  العثور على زملائك والتواصل معهم بكفاءة.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. فك تشفير الأيقونات بجانب صندوق البحث
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  دليل الموظفين في TeamConnect مصمم ليكون متعدد الاستخدامات وسهل الاستخدام، ويضم أربعة أيقونات تقدم عروضًا مختلفة
                  ووصول سريع إلى التفاصيل الرئيسية. إليك ما تكشفه كل أيقونة:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>عرض القائمة:</Body1Strong> عرضك الافتراضي، يقدم جميع أعضاء المنظمة
                      في قائمة مباشرة.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>عرض البطاقة الصغيرة:</Body1Strong> بديل مضغوط، يحول هذا العرض القائمة إلى بطاقات موظف صغيرة.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>عرض البطاقة الكبيرة:</Body1Strong> اختر التفاصيل الأكبر مع البطاقات الأكبر، التي تشمل 
                      خيارات اتصال إضافية مثل أرقام الهاتف والجوال للاتصال السهل.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>الرسم البياني التنظيمي:</Body1Strong> تصور هيكل الشركة مع عرض الرسم البياني التنظيمي، 
                      مثالي لفهم علاقات التقارير والتنقل في المشهد التنظيمي.</li>
                  </ol>
                    تبديل بين هذه الأيقونات للعثور على العرض الذي يناسب احتياجاتك في الوقت الحالي، سواء كنت تبحث عن معلومات سريعة أو رؤى أعمق.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. كيف يمكنني البحث عن زميل بسرعة؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  تبحث عن زميل؟ توجه فقط إلى صندوق البحث TeamConnect، الموجود في الجانب العلوي الأيمن بجانب أيقونة الرسم البياني التنظيمي. 
                  ابدأ بكتابة اسمهم، وشاهد كيف تتحسن النتائج مع كل حرف. تذكر، للبحث في TeamConnect، تجاوز شريط بحث Microsoft Teams 
                  في الأعلى—صندوق البحث الخاص بنا هو الطريقة المثلى للذهاب!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. كيف يعمل البحث الأبجدي للعثور على الموظفين؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  دليل الموظفين في TeamConnect يتضمن ميزة البحث الأبجدي المفيدة لمساعدتك في تحديد موقع الموظفين بسرعة حسب الاسم الأول. 
                  فقط انقر على حرف من الأبجدية المعروضة في أعلى الدليل، وسيعرض لك TeamConnect على الفور جميع الموظفين
                  الذين تبدأ أسماؤهم الأولى بذلك الحرف. للعودة إلى القائمة الكاملة للموظفين، فقط قم بإلغاء تحديد الحرف المميز. هذه الميزة الحدسية
                  تجعل التنقل خلال قوائم الموظفين الكبيرة سهلاً، مما يضمن أنك تستطيع العثور على زملاء العمل بسرعة وكفاءة.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. كيف يمكنني ضبط عدد الموظفين المعروضين في كل صفحة؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  يتيح لك TeamConnect تخصيص عدد ملفات تعريف الموظفين التي تشاهدها في كل صفحة، مما يجعل من السهل التنقل خلال دليل الموظفين.
                  في أسفل كل صفحة، ستجد خيارات الترقيم: 10، 50، 100، 500، 1000، و 5000. الإعداد الافتراضي يعرض 10 ملفات تعريف لكل صفحة.
                  لمشاهدة المزيد من الملفات الشخصية على صفحة واحدة، فقط حدد رقمك المفضل من هذه الخيارات. توفر هذه الميزة مرونة في الطريقة التي تتصفح
                  بها الدليل، مما يمكنك من تخصيص العرض لتلائم احتياجاتك أو تفضيلاتك الخاصة.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. كيف أرتب معلومات الموظفين في عرض القائمة؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  في عرض قائمة دليل الموظفين في TeamConnect، لديك المرونة لفرز معلومات الموظفين بما يتناسب مع احتياجاتك. الأعمدة—اسم الموظف،
                  المسمى الوظيفي، القسم، موقع المكتب، ومكان العمل—جميعها قابلة للفرز. فقط انقر على رأس العمود الذي ترغب في فرزه. النقر مرة واحدة سيفرز
                  العمود تصاعديًا، والنقر مرة أخرى سيقوم بالفرز بالترتيب التنازلي. بشكل افتراضي، يتم فرز الدليل حسب اسم الموظف تصاعديًا،
                  وفقًا للاسم الأول. تساعد ميزة الفرز هذه في تنظيم وعرض تفاصيل الموظفين بسرعة بطريقة تخدم غرضك بشكل أفضل، سواء كنت تبحث
                  عن شخص محدد أو تحتاج إلى تجميع الموظفين حسب معيار معين مثل القسم أو الموقع.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>الأسئلة الشائعة حول دليل الأقسام</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>مقدمة دليل الأقسام:</Body1Strong> دليل الأقسام هو بوابتك لفهم الشبكة المعقدة لهيكلية
              منظمتك، حيث يوفر رؤية واضحة للتسلسلات الهرمية الإدارية، والقيادة الإدارية، والسبل للتواصل الإداري السلس.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. كيف تعمل العلامات 'الكل'، 'المفضلة'، و'قسمك' في دليل الأقسام؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  في دليل الأقسام TeamConnect، تساعدك العلامات على التنقل السريع وتصفية المعلومات الإدارية. إليك ما تمثله كل علامة:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>الكل:</Body1Strong> هذه العلامة تعرض جميع الأقسام داخل منظمتك، موفرةً
                      نظرة شاملة.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>المفضلة:</Body1Strong> استخدم هذه العلامة لعرض قائمة مختارة من الأقسام التي
                      قمت بتمييزها كـ 'المفضلة'، مما يسهل الوصول إلى الأقسام التي تتفاعل معها بشكل متكرر.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>قسمك:</Body1Strong> تسمح لك هذه العلامة بتصفية العرض لإظهار قسمك فقط،
                      مما يتيح الوصول السريع إلى معلومات وتحديثات قسمك المباشرة.</li>
                  </ul>
                  تم تصميم هذه العلامات لتسهيل التنقل داخل دليل الأقسام، معززةً قدرتك على العثور على المعلومات الإدارية والتفاعل معها بكفاءة.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. فهم عرض دليل الأقسام في TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  التنقل عبر دليل الأقسام في TeamConnect يتم بسلاسة مع خيارات عرض متعددة لتناسب تفضيلاتك. كل عرض يقدم طريقة فريدة لاستكشاف المعلومات الإدارية 
                  الشاملة، بما في ذلك اسم القسم، مدير القسم، القسم الأم، هاتف القسم، خط ساخن للقسم، وبريد القسم الإلكتروني. إليك لمحة عن العروض المتاحة:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>عرض القائمة:</Body1Strong> الإعداد الافتراضي الذي يسرد جميع الأقسام بشكل
                      منظم وواضح.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>عرض البطاقة الكبيرة:</Body1Strong> انتقل إلى هذا العرض لمشاهدة بطاقات الأقسام
                      التفصيلية، والتي تقدم ملخصًا موسعًا للمعلومات الرئيسية لكل قسم.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>عرض مخطط المنظمة:</Body1Strong> لتمثيل بصري لهيكل الأقسام والعلاقات، يوضح
                      عرض مخطط المنظمة كيف يتناسب كل قسم ضمن التسلسل الهرمي للمنظمة الأكبر.
                    </li>
                  </ul>
                  للتبديل بين هذه العروض، استخدم الأيقونات بجوار مربع البحث في أعلى دليل الأقسام. كل عرض مصمم لمساعدتك على الوصول إلى التفاصيل الإدارية
                  وفهمها بسهولة، مما يضمن أن تجد المعلومات التي تحتاجها على النحو الذي يناسبك.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. كيفية استخدام صندوق البحث في دليل الأقسام
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  يمكنك تحديد موقع أي قسم داخل TeamConnect بسلاسة باستخدام صندوق البحث المخصص الموجود بجانب أيقونات العرض في أعلى دليل الأقسام. فقط ابدأ بكتابة اسم القسم 
                  الذي تبحث عنه، وشاهد كيف ينقح الدليل نفسه ليعرض فقط المطابقات ذات الصلة. تم تصميم هذه الميزة خصيصًا لمساعدتك على تجنب الحاجة إلى الفرز اليدوي لكامل الدليل، 
                  مما يجلب القسم الذي تحتاجه مباشرة إلى أطراف أصابعك. تذكر، هذه الوظيفة البحث خاصة بدليل أقسام TeamConnect، لذا تأكد من استخدام هذا الصندوق بدلاً من البحث الرئيسي 
                  في Microsoft Teams الموجود في أعلى واجهة Teams للاستفسارات الخاصة بالأقسام.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. كيف يعمل فلتر الأبجدية في دليل الأقسام؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  قم بتسهيل بحثك في دليل الأقسام باستخدام فلتر الأبجدية الموجود في الأعلى. تسمح لك هذه الميزة بتضييق نطاق الأقسام بسرعة عن طريق اختيار حرف. بمجرد اختيارك لحرف، 
                  يقوم TeamConnect بتصفية وعرض الأقسام التي تبدأ أسماؤها بهذا الحرف المختار، مما يسهل عليك العثور على ما تبحث عنه دون الحاجة للتمرير خلال القائمة بأكملها. 
                  للعودة إلى عرض القسم الكامل، ببساطة قم بإلغاء تحديد الحرف المميز. تضمن هذه الملاحة الأبجدية البديهية أن تتمكن من تحديد موقع أي قسم حسب الحرف الأول بفعالية.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. كيف يمكنني الاتصال بمدير القسم؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  التواصل مع مدير قسم في TeamConnect سهل ومباشر. قم ببساطة بتحديد موقع قسم الاهتمام وانقر على اسم المدير. سيؤدي هذا الإجراء إلى تشغيل مربع منبثق يعرض 
                  خيارات الاتصال بالمدير مع أيقونات الوصول السريع المألوفة:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>أيقونة الدردشة:</Body1Strong> لبدء رسالة فورية عبر دردشة Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>أيقونة البريد الإلكتروني:</Body1Strong> لفتح بريد إلكتروني جديد موجه إليهم في Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>أيقونة مكالمة فيديو:</Body1Strong> لبدء مكالمة فيديو من خلال Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>أيقونة المكالمة:</Body1Strong> لبدء مكالمة صوتية باستخدام Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>أيقونة المزيد (...):</Body1Strong> لخيارات إضافية مثل جدولة اجتماع أو إضافة معلومات الاتصال الخاصة بهم 
                      إلى قائمة Teams الخاصة بك.
                    </li>
                  </ul>
                  توفر هذه الأيقونات طريقة سلسة للوصول إلى مديري الأقسام مباشرة من داخل TeamConnect، مما يضمن التواصل الفعال داخل مؤسستك.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. كيف يمكنني مشاهدة أو التواصل مع الموظفين ضمن قسم معين؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  لاستكشاف من هم الموظفون ضمن قسم معين في TeamConnect، انظر إلى النقاط الثلاث بجانب اسم القسم. النقر عليها سيظهر خيارات لاتخاذ مزيد من الإجراءات:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>عرض الموظفين في هذا القسم:</Body1Strong> اختر هذا الخيار لعرض قائمة جميع الموظفين المرتبطين بالقسم. يشير الرقم بجانب هذا الخيار إلى عدد الموظفين في القسم، مما يوفر عدداً سريعاً.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>هيكل تنظيمي للقسم:</Body1Strong> إذا كنت ترغب في فهم تسلسل الهيكلية داخل القسم، اختر "هيكل تنظيمي للقسم" لكشف الترتيب الهيكلي، معرضاً علاقات الأقسام الأم بالقسم المعني وأي أقسام فرعية. هذه التمثيل البصري يساعد في تسهيل فهم وتصفح الإطار التنظيمي، مما يجعل من السهل التعرف على والتواصل مع أعضاء قسم معين.
                    </li>
                    <li><Body1Strong>القسم {'>'} الموظف:</Body1Strong> بعد مشاهدة الموظفين ضمن قسم معين، استخدم هذا المسار للتنقل بسهولة مرة أخرى إلى قائمة الأقسام، مما يحافظ على تجربة استكشاف سلسة داخل TeamConnect.
                    </li>
                  </ul>
                  تتيح لك هذه الميزات ليس فقط مشاهدة قائمة الموظفين في قسم معين ولكن أيضًا توفر طريقة للوصول إليهم مباشرة من خلال أدوات الاتصال في TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. اكتشاف تفاصيل الأقسام بعمق في TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  اغمر في تفاصيل أي قسم بنقرة بسيطة على اسمه في TeamConnect. يتكشف حوار مفصل، يقدم شرحًا غنيًا بالمعلومات الذي يصور دور القسم الكامل وموارده ضمن منظمتك. ستجد كل شيء من القيادة والمعرفات المالية إلى قنوات الاتصال والروابط الاجتماعية. هذه الميزة هي بوابتك إلى:
                  <ul>
                    <li className={styles.bulletBottomMargin}>نبض القسم – الغرض منه، الأشخاص، ومقاييس الأداء.</li>
                    <li className={styles.bulletBottomMargin}>طرق الاتصال – خطوط مباشرة لشخصيات رئيسية في القسم ومنصات التواصل.</li>
                    <li className={styles.bulletBottomMargin}>الشبكة التنظيمية – فهم كيفية تكامل القسم مع البنية الأوسع للشركة.</li>
                  </ul>
                  سواء كان استفسار سريع أو تحليل عميق لبيانات القسم، يزودك TeamConnect بالأدوات اللازمة للتواصل مع وفهم النظم البيئية الفريدة ضمن منظمتك.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. هل تساءلت عن سبب عدم ظهور عناوين الأقسام في TeamConnect؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  قد يبدو البحث عن موقع قسم ما أحيانًا مثل البحث عن الكنز بدون خريطة. في TeamConnect، نادرًا ما تجد عنوانًا مخصصًا لكل قسم. إليك الأسباب التي قد تُحذف فيها هذه التفاصيل الجغرافية:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>مواقع متعددة:</Body1Strong> العديد من الأقسام لا تقتصر على مساحة واحدة؛ تزدهر في مكاتب وجغرافيا متنوعة.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>هيكل مرن:</Body1Strong> المشهد التنظيمي في تغيير مستمر، حيث تتطور الأقسام وتندمج أو حتى تتحلل بما يتماشى مع التحولات الاستراتيجية.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>التعاون بين الأقسام:</Body1Strong> تتميز أماكن العمل الحديثة بروح التعاون، وغالبًا ما تمزج المواهب من أقسام مختلفة في مساحات مشتركة.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ثقافة العمل عن بعد:</Body1Strong> مع مرونة العمل عن بعد، لا توضع دائمًا المراسي الإدارية في مواقع المكاتب الفعلية.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>الفروقات القانونية:</Body1Strong> تدور الأقسام عادة حول بنية الشركة القانونية بدلاً من امتلاك عناوين خاصة بها.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>تبسيط العمليات:</Body1Strong> التركيز على التعاون الديناميكي وليس على العناون الثابتة، يساعد في الحفاظ على رشاقة التشغيل.
                    </li>
                  </ul>
                  يتعلق الأمر بتبني نهج لا حدود له للعمل الجماعي والكفاءة. TeamConnect يعكس هذه الفلسفة من خلال التركيز على الاتصالات بدلاً من المواقع.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. هل معلومات قسمك مفقودة أو قديمة؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  يُعيّن محررو الأقسام داخل منظمتك لإضافة أو تحديث أو تصحيح المعلومات الخاصة بالقسم في TeamConnect.
                  إذا لم تكن متأكدًا من مَن هو محرر قسمك أو إذا كانت معلومات معينة تتطلب تدخل فريق دعم الموارد البشرية،
                  يُرجى التواصل مع <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> للحصول على التوجيه.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>أسئلة شائعة عن دليل المكاتب</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>مقدمة دليل المكاتب:</Body1Strong> اكتشف العمود الفقري للوجود الفعلي لمنظمتك من خلال دليل المكاتب،
              الذي يشمل تفاصيل المكاتب الأساسية والخصائص التشغيلية ونظرة شاملة على الأقسام والأفراد الذين يحددون كل موقع.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. التنقل بين العلامات في دليل المكاتب
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  اكتشف طريقك حول دليل المكاتب بسهولة باستخدام نظام العلامات المصمم للوصول السريع إلى الموقع الصحيح. إليك كيف يمكن لكل علامة أن تساعد في تسهيل بحثك:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>الكل:</Body1Strong> يعرض قائمة كاملة بالمكاتب، موفرًا نظرة شمولية على
                      مواقع منظمتك.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>المفضلة:</Body1Strong> يساعدك على التركيز على تلك المواقع المكتبية التي تزورها بشكل متكرر أو تحتاج
                      لمتابعتها عن كثب، من خلال وضع علامة 'المفضلة' عليها.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>مكتبك:</Body1Strong> تُصفي هذه العلامة الشخصية الدليل لتظهر لك
                      موقع المكتب الذي تتمركز فيه، مما يبقي قاعدتك الرئيسية تحت أطراف أصابعك.
                    </li>
                  </ul>
                  سواء كنت تحجز غرفة اجتماعات أو تخطط لزيارة، تقدم هذه العلامات طريقة شخصية ومبسطة للتفاعل مع المشهد الجغرافي لمنظمتك.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. إتقان عرض دليل المكتب في TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  اغمر نفسك في التخطيط التنظيمي لمختلف مواقع شركتك مع عروض دليل المكتب في TeamConnect. كل عرض مصمم لتسهيل جانب فريد من الملاحة داخل المكتب وفهمه:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>عرض القائمة:</Body1Strong> الإعداد الافتراضي الذي ينظم جميع مواقع المكاتب بقائمة سهلة القراءة.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>عرض البطاقة الكبيرة:</Body1Strong> يثري عرض البطاقة الكبيرة في دليل المكتب عرضك بتقديم تفصيلي لكل مكتب. عند التفاعل مع بطاقة المكتب، ستجد ثروة من المعلومات والخيارات القابلة للتنفيذ ضمن قائمة "المزيد (...)" :
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>عرض الأقسام:</Body1Strong> انتقل مباشرة إلى قائمة بجميع الأقسام الموجودة داخل المكتب.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>عرض الموظفين:</Body1Strong> احصل على قائمة كاملة بالموظفين المعينين في المكتب، مما يسهل التواصل المباشر أو استكشاف الكادر.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>مخطط تنظيمي للكيان التجاري:</Body1Strong> تخيل مكان المكتب ضمن السياق التنظيمي الأوسع لفهم واضح للهياكل الهرمية.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>جهات الاتصال الأساسية:</Body1Strong> حدد جهات الاتصال الرئيسية في المكتب للتواصل المبسط.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>العطلات الرسمية للمكتب:</Body1Strong> كن على دراية بالعطل الرسمية الخاصة بالمكتب لتخطيط جداولك وفقاً لذلك.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>ساعات عمل المكتب:</Body1Strong> اطلع على ساعات تشغيل المكتب لتنسيق زياراتك أو مكالماتك مع أوقات نشاطهم.
                        </li>
                      </ol>
                      يوفر هذا العرض ليس فقط لقطة بصرية ومعلوماتية، ولكن أيضاً إمكانية الوصول السريع إلى الرؤى العميقة والإجراءات المباشرة المتعلقة بالمكتب، كلها مرتبة بعناية تحت خيار "المزيد (...)" لراحتك.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>عرض المخطط التنظيمي:</Body1Strong> تخيل الهيكل الهرمي للمواقع الرئيسية والفرعية لفهم الترابط بين مختلف مواقع المكاتب داخل مؤسستك.
                    </li>
                  </ul>
                  تبديل بين هذه العروض باستخدام الأيقونات بجانب صندوق البحث لتخصيص تجربة التصفح الخاصة بك لتناسب احتياجاتك الفورية.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. العثور على المكاتب بسرعة باستخدام ميزة البحث في TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  التركيز على موقع المكتب الصحيح داخل TeamConnect أصبح سهلاً باستخدام ميزة البحث المخصصة. موضوع بشكل بارز بجانب أيقونات العرض، صندوق البحث هو أداتك لتحديد المكاتب حسب الاسم. عندما تكتب، يعمل الدليل على تحسين العرض ليظهر فقط المكاتب التي تطابق إدخالك، مما يبسط عملية البحث. من المهم استخدام هذا الصندوق داخل TeamConnect للحصول على النتائج الأكثر دقة وصلة، بعكس البحث العام في Microsoft Teams. مع هذه الوظيفة البحثية المستهدفة، يمكنك التنقل بسرعة إلى أي مكتب في شبكة مؤسستك.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ٣٤. التنقل في دليل المكتب أبجديًا
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  إذا كنت تفضل البحث أبجديًا، فإن فلترنا الأبجدي هو طريقة سريعة للعثور على المكاتب حسب الاسم. في أعلى دليل المكتب، سترى الحروف من أ إلى ي. النقر على حرف يعمل على 
                  تصفية العرض ليظهر فقط تلك المكاتب التي تبدأ بهذا الحرف، مما يبسط عملية البحث. لإعادة الضبط ورؤية جميع المكاتب مجددًا، فقط قم بإلغاء تحديد الحرف النشط. هذه الخاصية مفيدة 
                  بشكل خاص عندما تكون متعرفًا على اسم المكتب لكنك تحتاج إلى طريقة سريعة لتحديد موقعه داخل TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  ٣٥. كشف ملامح المكتب الشاملة في TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  انطلق في رحلة استكشافية عبر قلب الوجود الفعلي لمنظمتك مع ملفات المكتب في TeamConnect. 
                  النقر على اسم مكتب يظهر حوارًا نابضًا بالحياة مليء بالعلامات التي تحتوي على تفاصيل مفصلة عن المكتب:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>نظرة عامة:</Body1Strong> اغمر نفسك في جوهر هوية المكتب مع مواصفات مفصلة، من تركيبة الأعمال إلى ساعات العمل 
                      والوقت المحلي، ملتقطًا جوهر بيئة المكتب.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>جهات الاتصال الأساسية:</Body1Strong> يمكنك الوصول إلى جهات الاتصال الحاسمة لخدمات الطوارئ و
                      الدعم الخارجي، كل منها على بعد نقرة واحدة من خلال نظام الهاتف Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>جهات اتصال المكتب:</Body1Strong> تواصل بسهولة مع خدمات الدعم الداخلي، مع تفاصيل جهات الاتصال وخيارات 
                      التفاعل المباشرة بمتناول يدك.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>العطلات الرسمية:</Body1Strong> كن على اطلاع بالعطلات المحددة للمكتب، مما يضمن توافق خططك مع 
                      المراعاة المحلية وجداول العمل.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>ساعات العمل:</Body1Strong> خطط لتعاملاتك مع معرفة دقيقة بوقت نشاط المكتب أو هدوئه خلال اليوم.
                    </li>
                  </ul>
                  سواء كانت طاقة آوستن المزدهرة أو المركز الاستراتيجي في سنغافورة، تقدم كل علامة تبويب بوابة لفهم والتواصل مع المواقع المتنوعة التي تشكل الوجود العالمي لشركتك.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. الغوص في ديناميكيات الأقسام داخل المكتب
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  التنقل في الشبكة المهنية لأقسام المكتب يتكشف بسهولة في TeamConnect. إليك كيفية الغوص في التفاصيل:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>عرض الأقسام في هذا المكتب:</Body1Strong> اكتشف جميع الأقسام التي تشكل 
                      نسيج المكتب باختيار هذا الخيار من قائمة المزيد (...).
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>الرؤية التنظيمية:</Body1Strong> انقر على أي قسم لكشف الرسم التنظيمي له 
                      لتمثيل مرئي لهيكله، مع مسار التنقل الذي يرشدك: المكتب {'>'} القسم {'>'} الرسم البياني.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>الغوص العميق في القسم:</Body1Strong> استكشف قسمًا أكثر بالنقر على 
                      قائمة المزيد (...) المجاورة لاسم القسم. هنا، يمكنك الوصول إلى قائمة بجميع الموظفين داخل ذلك القسم، مباشرةً في سياق المكتب.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>التنقل التسلسلي:</Body1Strong> انتقل عبر المستويات بسلاسة. من 
                      الموظفين إلى الأقسام أو من الرسم التنظيمي، يحافظ مسار التنقل (المكتب {'>'} القسم {'>'} الموظف {'>'} الرسم البياني) على 
                      رحلتك بديهية وعلى المسار الصحيح.
                    </li>
                  </ul>
                  مسارات TeamConnect المعقدة تدعوك لاستكشاف وفهم والتفاعل مع كل طبقة من أقسام مكتبك، مما يمنحك سيطرة تنقلية شاملة.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. الاتصال بالعاملين في المكتب في TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  فضولي حول الوجوه وراء مكتب؟ الجواب على بعد نقرة واحدة في TeamConnect. تفاعل مع النقاط الثلاث (الحذف) الموجودة بجانب اسم المكتب المختار لكشف مجموعة من الخيارات التفاعلية:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>عرض الموظفين في هذا المكتب:</Body1Strong> كشف قائمة بجميع المهنيين
                      الذين يعتبرون هذا المكتب بيتهم العملي. الرقم المعروض يقدم عدًا سريعًا للموظفين، داعيًا إياك للغوص في مجتمع المكتب.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>خيارات الاتصال:</Body1Strong> كل إدخال موظف مجهز بأيقونات للعمل السريع،
                      مما يسمح لك ببدء المحادثات، المكالمات، أو حتى مؤتمرات الفيديو مباشرةً من TeamConnect، مما يعزز التواصل داخل المكتب بسلاسة.
                    </li>
                    <li><Body1Strong>التNavigation:</Body1Strong> للعودة إلى قائمة المكاتب الأوسع من قائمة الموظفين، فقط انقر على 'المكتب' في مسار التنقل
                      (المكتب {'>'} الموظف)، وستعود إلى لمحة عامة عن المكتب.
                    </li>
                  </ul>
                  مع هذه الميزات، يجعل TeamConnect من السهل تصور توزيع القوى العاملة عبر المكاتب والتواصل بسهولة,
                  مما يعزز مكان عمل متصل بغض النظر عن المسافة.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. هل معلومات مكتبك مفقودة أو قديمة؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  يمكن التعامل مع تحديثات معلومات المكتب في TeamConnect من خلال محرري المكاتب المعينين. إذا كنت غير متأكد بشأن محرري المكتب أو
                  تحتاج إلى مساعدة بشأن المعلومات التي تقع تحت إشراف الموارد البشرية، فإن فريق دعم الموارد البشرية جاهز ل
                  المساعدة على <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>أسئلة مكررة حول الإعدادات</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. أهمية الإعدادات العامة الدقيقة في TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  في بيئة العمل المتغيرة اليوم، أصبح البقاء على اتصال وتنسيق أمرًا أكثر أهمية من أي وقت مضى. هنا تلعب تحديث إعداداتك العامة في TeamConnect دورًا حاسمًا:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>اختيار مكان العمل:</Body1Strong> اعكس جوهر بيئة عملك من خلال تحديث مكان عملك ليعكس وضعك الحالي، سواء كان الانتقال من هدوء مكتبك 
                      المنزلي إلى صخب المكتب الرئيسي، أو حتى الاستقرار في منطقة عامة. توفر هذه الوضوح في مكان عملك رؤية لزملائك ليس فقط عن أفضل الطرق للتواصل معك، ولكن أيضًا 
                      عن طبيعة الحوارات التي يمكن أن يشاركوا فيها معك، مما يحافظ على سرية المناقشات الحساسة خاصة عندما تكون في بيئة مفتوحة لمكتب عميل أو جالسًا في صالة مطار.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>تحديد موقع العمل:</Body1Strong> مشاركة مدينة عملك لا تقطع المسافات في فريق العمل عن بُعد فحسب، بل تفتح أبواب للتجمعات المهنية العفوية أو التواصل الاجتماعي 
                      الغير رسمي. سواء كان ذلك فنجان قهوة سريع، غداء ممتع، أو اجتماع تعاوني، يمكن أن يحول معرفة أنك في نفس المدينة يومًا عاديًا إلى فرصة للتواصل وجهًا لوجه 
                      ذو مغزى. هذا لا يقوي فقط روابط الفريق ولكنه يوفر أيضًا فهمًا أعمق للفروقات الدقيقة المحلية التي قد تؤثر على عملك.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>دقة التوقيت الزمني:</Body1Strong> بما أن الفرق تمتد عبر القارات، فإن التأكد من أن منطقتك الزمنية تعكس 
                      موقعك الحالي هو أمر ضروري لتنسيق التواصل. يتأثر الوقت المحلي في ملفك الشخصي في TeamConnect مباشرةً بهذا الإعداد،
                      مما يلغي أي تخمين لزملائك الذين يحاولون الوصول إليك.
                    </li>
                  </ul>
                  اغمر نفسك في 'الإعدادات' {'>'} 'الإعدادات العامة' ضمن TeamConnect لضبط هذه التفاصيل. بضع لحظات تقضيها في تحديث إعداداتك يمكن أن تعزز بشكل كبير التماسك وكفاءة جهودك 
                  التعاونية عبر اللوحة.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. تحليل اختيار مكان العمل في TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  اختيار الإعداد الصحيح في قائمة مكان العمل يعكس بدقة وضع عملك الحالي. مرر فوق أيقونة المعلومات للحصول على شرح مفصل لكل خيار:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Checked-Out/Break:</Body1Strong> يدل على وقت بعيد عن العمل، سواء كان استراحة أو نهاية اليوم.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Office/Field-Office:</Body1Strong> يشير إلى الحضور في مكان عمل تديره الشركة.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-Office:</Body1Strong> العمل من مكان عمل منزلي مخصص.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote-Workplace/Public-Area:</Body1Strong> العمل من بيئة غير مكتبية، مما يدل على الحاجة للتواصل المرن.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Customer Workplace/Private Office:</Body1Strong> في موقع مع عميل، مع ملاحظة حول مستويات الخصوصية لحساسية المحادثة.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Commute/Business-Travel/Training:</Body1Strong> في الطريق أو مشارك في أنشطة عمل محددة خارج المكتب.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Public-Holiday/Vacation/Various Leaves:</Body1Strong> خارج الخدمة رسمياً لأسباب شخصية أو صحية أو عطلات.</li>
                  </ul>
                  اختيار إعداد مكان العمل المناسب يضمن أن يكون فريقك على دراية بالسياق اللازم للتفاعلات، مع احترام التوافر والخصوصية.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. التنقل في حالة 'Checked-In' في TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  تم تصميم علامة 'Checked-In' في دليل الموظفين لعرض جميع الموظفين الذين هم حاليًا في العمل. يتم تحديد هذه الرؤية بواسطة اختيارك لمكان العمل:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Active Work Environments:</Body1Strong> الخيارات Office, Field-Office, Home-Office, Remote-Workplace, Public-Area, Customer-Workplace, 
                    Customer Private-Office, Commute, Business-Travel, و Training تشير إلى أنك مشغول في العمل، مما يجعلك 'Checked-In' ونشطًا بشكل واضح لزملائك.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Non-Work Settings:</Body1Strong> اختيار Checked-Out, Break, Public-Holiday, Vacation, On-Leave, Maternity-Leave, أو Sick-Leave 
                      يزيلك من قائمة 'Checked-In'، مما يوضح التوقعات للتوافر وأوقات الاستجابة.
                  </li>
                  </ul>
                  تساعد هذه الوظيفة في تمييز فترات العمل عن الوقت الشخصي، مما يساعد في التعاون والتواصل الفعّال بين الفريق.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>الأسئلة المتكررة حول إدارة البيانات والخصوصية</Subtitle2>
          <Body1>
            <p>
              في عصر الرقمنة الحالي، من الضروري فهم كيفية إدارة بياناتك. تلتزم TeamConnect بالشفافية وممارسات حماية البيانات القوية.
              فيما يلي، ستجد معلومات حول كيفية التعامل مع بياناتك وتخزينها وحمايتها داخل TeamConnect، لضمان أمان مساحة عملك الرقمية ومطابقتها للوائح.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. كيف تحمي TeamConnect بياناتي الشخصية؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>تلتزم TeamConnect بحماية معلوماتك الشخصية من خلال نهج شامل لأمن البيانات:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>تسجيل دخول موحد (SSO):</Body1Strong> لزيادة الأمان وتبسيط تجربة المستخدم، تدمج TeamConnect تسجيل دخول موحد تلقائي 
                      مع حسابك في Microsoft 365، مما يسهل إدارة الوصول الآمنة والسلسة.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>التشفير:</Body1Strong> نضمن تشفير جميع البيانات داخل TeamConnect أثناء النقل وأثناء التخزين، 
                      مما يجعلها غير قابلة للوصول من قبل الأطراف غير المصرح بها.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>تقليل البيانات:</Body1Strong> تقتصر جمع البيانات لدينا على ما هو ضروري فقط لوظائف TeamConnect. 
                      المعلومات الوحيدة التي نخزنها هي اسم المستخدم الرئيسي (UPN) من Azure Entra ID، والذي قد يشبه عنوان بريدك الإلكتروني للشركة.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>الوصول المقيد:</Body1Strong> يقتصر الوصول إلى UPN على مشرف الاشتراك في تنظيمك ضمن بوابة الاشتراك لدينا، 
                      مما يضمن أن المعلومات الحساسة تظهر للأفراد المصرح لهم فقط.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>حذف البيانات الفوري:</Body1Strong> تم تنظيم TeamConnect لحذف UPN المخزن فورًا تحت ثلاث ظروف محددة: 
                      إذا تم إلغاء الاشتراك بواسطة مشرف الاشتراك، أو إذا تمت إزالة مستخدم بواسطة قسم الموارد البشرية داخل TeamConnect، أو إذا انتهت صلاحية اشتراك 
                      TeamConnect. يضمن ذلك عدم الاحتفاظ ببياناتك الشخصية بعد انتهاء فائدتها أو ضرورتها.
                    </li>                      
                  </ul>
                  <p>من خلال هذه الإجراءات، تلتزم TeamConnect بالحفاظ على أعلى معايير حماية البيانات، مما يضمن بقاء معلوماتك الشخصية آمنة وسرية.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. تتساءل أين توجد بياناتك مع TeamConnect؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  يكسر TeamConnect قالب العروض التقليدية للبرمجيات كخدمة (SaaS) من خلال ضمان عدم مغادرة بياناتك لمنظمتك.
                  على عكس الممارسة المعتادة بتخزين البيانات على خوادم سحابية خارجية، يستخدم TeamConnect ببراعة Microsoft 365 SharePoint Online الخاص بمنظمتك
                  لاستضافة جميع بيانات التطبيق. لا تقتصر هذه الطريقة الابتكارية على تعزيز سيادة البيانات فحسب، بل تقوي الأمان أيضًا،
                  متماشية تمامًا مع تفانينا الشديد في حماية خصوصيتك والالتزام بمعايير الامتثال الصارمة.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. هل سيتم مشاركة بياناتي مع أطراف ثالثة؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  لا، لا يتم مشاركة بيانات مع الأطراف الثالثة! تلتزم Cubeet بشدة بخصوصية وأمان بياناتك. لا نشارك أي بيانات TeamConnect مع أطراف ثالثة دون الحصول على موافقة صريحة من عملائنا.
                  في بيئتنا الآمنة، نخزن فقط المعرفات الأساسية، مثل اسم المستخدم الرئيسي (UPN)، اسم المنظمة، اسم المستأجر، ومعرف المستأجر، ضمن بوابة الاشتراك لدينا.
                  هذه المعلومات ضرورية لتقديم الخدمة ومحمية من خلال سياسات الخصوصية الصارمة لدينا.
                  كن مطمئنًا، فممارسات إدارة بياناتنا مصممة لضمان بقاء بياناتك سرية، مع الشفافية والسيطرة في يديك بثبات.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. كيف يتوافق TeamConnect مع لوائح حماية البيانات مثل الـ GDPR؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  يتوافق TeamConnect مع أنظمة حماية البيانات مثل الـ GDPR، HIPAA، CCPA، وغيرها من خلال استغلال الميزات التلقائية للامتثال في بيئة Microsoft 365،
                  حيث يتم تخزين جميع بيانات العملاء بأمان. على عكس نماذج SaaS التقليدية، لا يخزن TeamConnect أي بيانات عملاء على خوادمه؛ بدلاً من ذلك، يعمل ضمن بيئة 
                  Microsoft 365 الخاصة بمنظمتك، ملتزمًا بمعايير الأمان والامتثال الصارمة. يضمن هذا النهج أن حوكمة بياناتك تظل متسقة مع 
                  الإطار الشامل للامتثال الذي توفره Microsoft 365، بما في ذلك الالتزام بـ GDPR، HIPAA لمعلومات الرعاية الصحية، CCPA لسكان كاليفورنيا،
                  وغيرها من أنظمة حماية البيانات العالمية. من خلال الاندماج السلس مع إعداد Microsoft 365 الحالي لديك، يمكن لـ TeamConnect تمكين منظمتك من 
                  الحفاظ على السيطرة الكاملة على بياناتك، مما يضمن الامتثال لأشد قوانين وأنظمة حماية البيانات صرامة.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. كيف يتعامل TeamConnect مع بيانات الدردشات والمكالمات ومكالمات الفيديو في Microsoft Teams ورسائل Outlook ومكالمات نظام الهاتف؟
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  يستفيد TeamConnect من واجهة برمجة تطبيقات Microsoft Graph لتمكين التفاعلات المباشرة مثل الدردشة والمكالمات ومكالمات الفيديو والبريد الإلكتروني Outlook داخل Microsoft Teams،
                  مما يوفر تجربة مستخدم سلسة. الأهم من ذلك، بينما يبدأ TeamConnect هذه الاتصالات، يقوم بذلك دون التدخل في عملية الاتصال
                  أو امتلاك القدرة على تسجيل أو تخزين أي من محتوى التفاعل. تجري جميع المحادثات والمكالمات والرسائل البريدية مباشرة ويتم إدارتها داخل
                  حدود Microsoft Teams وOutlook الآمنة، مع الالتزام الصارم بمعايير الأمان والخصوصية القوية لـ Microsoft 365. هذا يضمن أن TeamConnect يعزز
                  قدرات التواصل الخاصة بك مع المحافظة التامة على سرية وسلامة بياناتك.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            لمزيد من التفاصيل حول سياساتنا وممارساتنا البيانية، قد ترغب أيضاً في استشارة {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>دليل المستخدم TeamConnect</Body1Strong></Link> أو قسم تقنية المعلومات في 
             منظمتك على <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> لمعرفة التفاصيل المتعلقة بإعدادك.
          </p>
        </Body1>        
        <p></p>  
      <Subtitle1 className={styles.fontColor}>نحن نستمع: شارك بآرائك وأفكارك</Subtitle1>
        <Body1>
          <p>
            آرائك تدفع تطور TeamConnect. سواء كنت تمتلك فكرة مميزة لميزة جديدة أو لديك بعض الملاحظات على تجربتك الحالية، نحن نصغي جيداً. مساهماتك لا تقدر بثمن في سعينا المستمر لتقديم
            أفضل خدمة ممكنة.
          </p>
        </Body1>      
      <Subtitle2>الملاحظات واقتراحات الميزات</Subtitle2>
        <Body1>
          <p>
            جاهز للمشاركة؟ انتقل إلى صفحتنا {' '}
            <Link 
              style={{ fontWeight: 600 }}
              href={feebackLink}
              target='_blank'>
              صفحة الفيدباك
            </Link>
            . سواء كان ذلك اقتراحًا لميزة جديدة أو تعليقًا على الوظائف الحالية، ببساطة اختر
            الخيار المناسب من القائمة المنسدلة في النموذج. مساهماتك تساعد على تشكيل مستقبل TeamConnect،
            مما يضمن أنه يفي بتوقعاتك ويتجاوزها.
          </p>
        </Body1>
    </>
  );
};
Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};
export default Container;