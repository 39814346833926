import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Help from './pages/help';
import Permission from './pages/permission';
import Subscription from './pages/subscription';

const RouteConfig = function() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={ <Home /> } />
        <Route exact path='/landing' element={ <Home /> } />
        
        <Route exact path='/help' element={ <Help /> } />
        <Route exact path='/welcome' element={ <Permission /> } />
        <Route exact path='/permission' element={ <Permission /> } />
        <Route exact path='/subscription' element={ <Subscription /> } />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteConfig;