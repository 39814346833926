export const addLogin = async (userInfo) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userInfo: userInfo,
        action: 'LOGIN'
      }),
    };

    if (process.env.NODE_ENV !== 'production') {
      return {
        status: true,
        message: 'this feature is only run in production',
      };
    };

    const url = '/v1/api/login';
    const res = await (await fetch(url, options)).json();
    
    return res;
  } catch (error) {
    throw error;
  };
};

export const addResolve = async (userInfo) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userInfo: userInfo,
        action: 'RESOLVE'
      }),
    };

    if (process.env.NODE_ENV !== 'production') {
      return {
        status: true,
        message: 'this feature is only run in production',
      };
    };

    const url = '/v1/api/login';
    const res = await (await fetch(url, options)).json();
    
    return res;
  } catch (error) {
    throw error;
  };
};

export const addActivate = async (userInfo) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userInfo: userInfo,
        action: 'ACTIVATE'
      }),
    };

    if (process.env.NODE_ENV !== 'production') {
      return {
        status: true,
        message: 'this feature is only run in production',
      };
    };

    const url = '/v1/api/login';
    const res = await (await fetch(url, options)).json();
    
    return res;
  } catch (error) {
    throw error;
  };
};