import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});
function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>Centrum pomoci a časté otázky TeamConnect</Title3>
        <p><Body1>
          Vitajte v centrálnom pomoci TeamConnect, ktoré je navrhnuté tak, aby podporovalo vaše skúsenosti s našou komplexnou korporačnou adresárovou aplikáciou. Tu nájdete prostriedky na optimalizáciu vášho využitia TeamConnectu v rámci Microsoft Teams, čím zvýšite komunikáciu v rámci vašej siete.
        </Body1></p>
        <Subtitle2>Rýchly referenčný sprievodca</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Zlepšené vyhľadávacie schopnosti: </Body1Strong>
              Nájdite správnych kolegov, oddelenia a nevyhnutné kontakty rýchlo s našimi silnými vyhľadávacími nástrojmi.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Vizuálne organizačné schémy: </Body1Strong>
              Orientujte sa v hierarchii vašej organizácie a pochopite jasné reportovacie linky s ľahkosťou prostredníctvom intuitívnych organizačných schém.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Prispôsobivé nástroje pre dnešné potreby: </Body1Strong>
              Využite moderné funkcionality prispôsobené požiadavkám súčasných pracovísk.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Optimalizácia vášho zážitku</Subtitle2>
        <Body1>
          <p>
            TeamConnect revolucionalizuje spôsob, akým komunikujete v rámci vašej organizačnej štruktúry, premení každodennú komunikáciu na strategické angažovanosti.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Získajte pomoc & časté otázky</Subtitle1>
        <Body1>
          <p>
            Nájdite odpovede na bežné otázky o funkcionalitách a nastaveniach TeamConnect v tejto venovanej sekcii. Či už idete preskúmavať aplikáciu, prispôsobovať nastavenia, alebo hľadáte riešenia na výzvy, naše Centrum pomoci je tu, aby vám pomohlo efektívne.
          </p>
        </Body1>
        <Body1>
          <p>
            Pre hlbšiu podporu alebo špecifické usmernenie, prosím konzultujte <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>Používateľskú príručku TeamConnect</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>        
        <Subtitle2>Používanie FAQ k TeamConnect</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Nemôžete nájsť TeamConnect v bočnom paneli Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ak TeamConnect nie je na prvý pohľad viditeľný alebo zmizol vo vašom bočnom paneli Microsoft Teams, môžete si ho ľahko pripnúť pre rýchly prístup. Stačí sledovať tieto jednoduché kroky:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Otvorte Microsoft Teams a kliknite na ikonu '...' (+ Apps) na spodku bočného panelu.</li>
                    <li className={styles.bulletBottomMargin}>Vyhľadajte 'TeamConnect' v hľadacom poli na vrchu vyskakovacieho okna.</li>
                    <li className={styles.bulletBottomMargin}>Kliknite pravým tlačidlom myši na aplikáciu TeamConnect z výsledkov vyhľadávania a vyberte 'Pripnúť'.</li>
                  </ol>
                  TeamConnect bude teraz pripnutý k vášmu bočnému panelu, čo zaručí prístup jednoduchým kliknutím!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Narazili ste na technické problémy s TeamConnect? Tu je návod, ako získať pomoc.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ak narazíte na akékoľvek technické problémy s TeamConnect v rámci Microsoft Teams, tím IT podpory vašej organizácie je tu, aby vám pomohol.
                  Obráťte sa na nich na adrese <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, a oni vám okamžite pomôžu.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Nevidíte svoje údaje zamestnanca?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ak sa váš profil zdá byť trochu prázdny, sú tam len vaše meno a e-mail, je pravdepodobné, že váš tím HR ešte nepridal zvyšok vašich údajov zamestnanca do TeamConnect.
                  Netreba sa báť! Rýchla správa vášmu týmu HR podpory veci pohne dopredu. Sú pripravení doplniť váš profil o všetky dôležité detaily.
                  Obráťte sa na <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> a sledujte, ako váš profil ožije!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Aké možnosti jazykov sú dostupné v TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect oslavuje globálnu rozmanitosť Microsoft Teams podporou
                  všetkých jeho jazykov. Či už uprednostňujete arabčinu alebo gréčtinu,
                  japončinu alebo švédčinu, máte na dosah ruky svet možností, vrátane:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arabčina, bulharčina, čínština (zjednodušená a tradičná), chorvátčina,
                      čeština, dánčina, holandčina, angličtina, estónčina, finčina,
                      francúzština, nemčina, gréčtina, hebrejčina, maďarčina, indonézština,
                      taliančina, japončina, kórejčina, lotyština, litovčina, nórčina
                      (Bokmal), poľština, portugalčina (Brazília a Portugalsko), rumunčina,
                      ruština, srbochorvátčina (latinka), slovenčina, slovinčina,
                      španielčina, švédčina, thajčina, turečtina, ukrajinčina, vietnamčina.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Pre prispôsobenie vášho zážitku v TeamConnect vo vašom obľúbenom jazyku,
                  prosím, upravte jazykové nastavenia priamo v Microsoft Teams, pretože
                  TeamConnect je s týmito nastaveniami plne integrovaný:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Prejdite na nastavenia Microsoft Teams kliknutím na tri bodky vedľa
                      vášho profilového obrázka v hornej časti a potom vyberte "Vzhľad a
                      prístupnosť".
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Prejdite do sekcie "Jazyk" a z rozbaľovacieho menu vyberte váš
                      preferovaný jazyk.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Potvrďte zmeny kliknutím na tlačidlo "Uložiť a reštartovať" na
                      použitie nových jazykových nastavení.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Poznámka: </Body1Strong>
                <Body1>
                  Zmeny v jazykových nastaveniach v Microsoft Teams sa bezproblémovo
                  prejavia aj vo vašom zážitku s TeamConnect. Uvedomte si, že zmena jazyka v
                  Teams môže tiež upraviť formát vášho času a dátumu. V súčasnosti sa
                  TeamConnect vyvíja na podporu písania zprava doľava. Tešíme sa na tieto
                  vylepšenia a budeme vás informovať, keď budú dostupné.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Ako môžem zmeniť tému v TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect sa synchronizuje s vašimi preferenciami tém v Microsoft Teams, vrátane možností Svetlý, Tmavý a Vysoký kontrast. Na prispôsobenie témy:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Prístup k nastaveniam Microsoft Teams kliknutím na tri bodky vedľa vašej profilovej fotky v hornej časti.</li>
                    <li className={styles.bulletBottomMargin}>Vyberte 'Vzhľad a prístupnosť', aby ste preskúmali možnosti tém.</li>
                    <li className={styles.bulletBottomMargin}>Vyberte svoju preferovanú tému z dostupných možností a okamžite aktualizujte rozhranie TeamConnect, aby zodpovedalo vašej voľbe.</li>
                  </ol>
                  Váš výber témy v Microsoft Teams bude automaticky aplikovaný na TeamConnect, čím sa zabezpečí konzistentný vizuálny zážitok v pracovnom priestore.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Skúmanie akčných tlačidiel kontaktov v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  V aplikácii TeamConnect, kdekoľvek nájdete kontaktné údaje zamestnanca, nájdete tiež naše pohodlné tlačidlá rýchleho prístupu. Tieto praktické nástroje
                  sú navrhnuté na zefektívnenie komunikácie v rámci ekosystému Microsoft, zabezpečujúc, že ste vždy len na kliknutie od spojenia.
                  Tu je váš podrobný sprievodca efektívnym využívaním týchto tlačidiel:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona chatu:</Body1Strong> Kliknite na začatie okamžitého chatu v Microsoft Teams. Ak sa potrebujete vrátiť do TeamConnect po začatí chatu, jednoducho použite šípku späť v Desktop App, tlačidlo späť v Mobile App alebo tlačidlo späť vášho prehliadača.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona emailu:</Body1Strong> Otvára nový koncept emailu v Outlooku s už vyplnenou adresou príjemcu v poli "Komu:".</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona videohovoru:</Body1Strong> Spúšťa videohovor tvárou v tvár prostredníctvom Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona volania:</Body1Strong> Začína hlasový hovor prostredníctvom Microsoft Teams, uľahčujúc rýchle diskusie.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona organizačného diagramu:</Body1Strong> Ukazuje miesto zamestnanca vo firemnej hierarchii vrátane jeho manažéra a priamych podriadených.</li>
                    <li><Body1Strong>Ikona viac (...):</Body1Strong> Odhaľuje ďalšie možnosti:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Stretnúť sa teraz:</Body1Strong> Otvára Microsoft Teams, aby okamžite začalo stretnutie so zamestnancom.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Nové stretnutie:</Body1Strong> Smeruje vás do Microsoft Teams na naplánovanie nového stretnutia.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Pridať kontakt:</Body1Strong> Pridáva kontaktné údaje zamestnanca z TeamConnect do novej aplikácie Microsoft Teams People, obohacujúc tak vašu funkciu volania.</li>
                      </ul>
                    </li>
                  </ul>
                  Tieto intuitívne tlačidlá sú navrhnuté tak, aby ste mohli efektívne komunikovať so spolupracovníkmi, či už pre rýchly chat, email, alebo naplánovanie budúcich stretnutí.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Volania prostredníctvom TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Chcete uskutočniť volanie? TeamConnect optimalizuje vašu komunikáciu integrovaním s Microsoft Teams.
                  Jednoducho kliknite na požadované telefónne číslo v TeamConnect a et voilà, rozhranie volania Microsoft Teams sa objaví,
                  pripravené na to, aby ste mohli bezproblémovo volať.
                  <p>
                  <Body1Strong>Poznámka: </Body1Strong>
                  Máte problémy s uskutočňovaním volaní? To môže byť spôsobené politikou IT vašej organizácie alebo používaním telefónneho systému iného ako Microsoft Teams Phone System. Konzultácia s vaším IT oddelením na <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, môže na veci vrhnúť viac svetla.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Sú spojené nejaké náklady s uskutočňovaním hovorov cez TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect zlepšuje váš zážitok z volania využitím Microsoft Teams. Tu je to, čo potrebujete vedieť o nákladoch súvisiacich s hovormi:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Pre hovory na pracovné telefóny zamestnancov, telefóny oddelení alebo linky pomoci vo vašej organizácii (vnútorné čísla),
                      pri použití telefónneho systému Microsoft Teams sú <Body1Strong>zvyčajne bezplatné</Body1Strong>.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Pre externé hovory, napríklad na mobilné telefóny alebo vonkajšie linky, sa aplikujú <Body1Strong>štandardné sadzby</Body1Strong> telefónneho systému vašej organizácie.
                      Tieto sadzby sa líšia podľa vášho špecifického servisného plánu s Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Poznámka:</Body1Strong> Ak narazíte na akékoľvek problémy alebo máte otázky týkajúce sa poplatkov za hovory, je najlepšie sa poradiť s
                    IT oddelením na <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> pre detailné informácie prispôsobené 
                    nastaveniu a politikám vašej organizácie.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Zaznamenávate nesúlad vo vašom stave 'Mimo kancelárie' v TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Zaznamenali ste, že váš stav prítomnosti 'Mimo kancelárie' z Microsoft 365 nie je správne zobrazený v TeamConnect? 
                  Máme pre vás riešenie. Funkcia synchronizácie stavu prítomnosti 'Mimo kancelárie' už bola v TeamConnect implementovaná.
                  Momentálne čakáme na uvedenie tejto funkcie spoločnosťou Microsoft. Keď sa to stane, váš stav prítomnosti 'Mimo kancelárie'
                  bude presne odzrkadlený v TeamConnect, čím sa zarovná so stavovými indikátormi prítomnosti spoločnosti Microsoft Teams pre bezproblémový zážitok.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>FAQ k adresáru zamestnancov</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >Úvod do adresára zamestnancov:</Body1Strong> Preskúmajte profesijnú krajinu vašej organizácie s adresárom zamestnancov,
                vašim centrálne koncentrovaným zdrojom pre podrobné informácie o zamestnancoch, dynamike pracoviska a priamej konektivite s profilmi a manažérskymi vzťahmi vašich kolegov.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Potrebujete pridať, aktualizovať alebo opraviť vaše údaje zamestnanca?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Pre pridanie, aktualizáciu alebo opravu vašich údajov zamestnanca v TeamConnect sa, prosím, obráťte na tím podpory HR vašej organizácie.
                  Sú pripravení vám pomôcť na <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Čo sa stane, keď kliknem na meno zamestnanca v adresári?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Kliknutie na meno zamestnanca v adresári zamestnancov TeamConnect otvorí podrobnú profilovú stránku tohto jedinca. Tento profil poskytuje komplexné 
                  prehľady o zamestnancovi, vrátane, ale nie limitované na, pracovný titul, oddelenie, umiestnenie kancelárie, kontaktné informácie a ďalšie. Je navrhnutý tak, aby vám 
                  poskytol úplný prehľad o profesionálnej úlohe zamestnanca a ako sa zapája do organizácie, čo vám uľahčí pochopiť ich zodpovednosti a 
                  ako by ste mohli spolupracovať.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Aké informácie sú zahrnuté v profile zamestnanca?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Každý profil zamestnanca v TeamConnect je komplexným prehľadom, ktorý ponúka bohaté podrobnosti o profesionálnom živote jednotlivca. 
                  Tu je to, čo nájdete:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Základné detaily:</Body1Strong> Meno, pracovný titul, oddelenie a miesto pobočky.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Štruktúra reportovania:</Body1Strong> Informácie o priamych a funkčných reportoch, 
                      vrátane priamych manažérov a funkčných manažérov.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Stav v pracovisku:</Body1Strong> Súčasný pracovný stav, ako je Napríklad na dovolenke, Cesta za prácou, 
                      Služobná cesta, a pod.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pracovisko & Lokálny čas:</Body1Strong> Aktuálne pracovisko zamestnanca a 
                      miestne časové pásmo.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontaktné údaje:</Body1Strong> Pracovný telefón, mobilné číslo a emailová adresa.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Adresa kancelárie:</Body1Strong> Kompletná adresa kancelárie vrátane názvu budovy,
                      adresy a Google mapy.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>O mne:</Body1Strong> Krátke osobné predstavenie alebo profesionálny životopis.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vzťahy so zamestnancami:</Body1Strong> Detaily o reportovacej štruktúre a vzťahoch v tíme zamestnanca.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sociálne siete & Jazyky:</Body1Strong> Linky na profesionálne sociálne profily 
                      a ovládanie jazykov.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Schopnosti:</Body1Strong> Zoznam profesionálnych schopností a odborných oblastí.</li>
                  </ul>
                  Navyše, profil ponúka akčné možnosti pre pripojenie cez chat, email, videokonferenciu, alebo naplánovanie stretnutí, spolu s 
                  rýchlým prístupom k organizačnému diagramu pre širší kontext tímu.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Ako si môžem aktualizovať informácie v profile?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Personalizácia vášho profilu je jednoduchá v TeamConnect. Tu je návod ako na to:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Prejdite na záložku <Body1Strong>Zamestnanec</Body1Strong> a kliknite na vášho <Body1Strong>Avatara</Body1Strong> na otvorenie vášho profilu.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        V ľavom hornom rohu nájdete odkaz <Body1Strong>"Upraviť váš profil"</Body1Strong>. 
                        Kliknutím sa otvorí dialóg pre úpravu vášho profilu.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Tu môžete zlepšiť váš profil pridaním alebo aktualizáciou sekcie <Body1Strong>O vás</Body1Strong>, odkazmi na sociálne siete a telefónnym číslom. 
                        To uľahčí vašim kolegom spojenie sa s vami alebo sledovanie.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Nezabudnite stlačiť tlačidlo <Body1Strong>Uložiť</Body1Strong> na použitie vašich zmien.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Posuňte sa nadol v dialógu, aby ste pridali vaše <Body1Strong>Jazyky</Body1Strong> a <Body1Strong>Profesijnú úroveň</Body1Strong>, 
                        ako aj vaše <Body1Strong>Zručnosti</Body1Strong>. Zdieľanie týchto informácií pomáha vašim kolegom pochopiť 
                        vaše oblasti odbornosti a jazyky, v ktorých sa viete dorozumieť.
                      </li>
                    </ol>
                  Aktualizácia vášho profilu nielenže udrží vaše informácie aktuálne, ale tiež podporuje lepšie spojenie vo vašej organizácii.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Aktualizácia vášho pracoviska, pracovného miesta a časového pásma v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Udržiavanie aktualizovaných informácií o vášom pracovisku, pracovnom mieste a časovom pásme je kľúčové pre plynulú spoluprácu v Microsoft Teams. 
                  Tieto aktualizácie sa okamžite prejavia vo vašom profile a zoznamoch v TeamConnect, čím umožňujú kolegom rýchlo pochopiť vaše pracovisko 
                  a najlepšie časy na spoluprácu s vami, čo zlepšuje tímovú prácu naprieč rôznymi regiónmi a časovými pásmami.
                  <p></p>
                  Ste pripravení aktualizovať svoje údaje? Je to jednoduché v TeamConnect! Prejdite do 'Nastavenia' v hornom menu a navigujte do 'Všeobecné nastavenia'. 
                  Tu môžete plynule aktualizovať svoje Pracovisko a Časové pásmo, a špecifikovať svoje Pracovné miesto na deň. Nezabudnite uložiť svoje zmeny, 
                  aby ste udržali každého informovaného a zabezpečili efektívnu spoluprácu bez ohľadu na vašu polohu!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Zaznamenali ste niečo nesprávne v profile kolegu?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ak narazíte na akékoľvek detaily v profile kolegu, ktoré nevyzerajú úplne správne, najlepším prvým krokom je dať im priateľské upozornenie. Rýchly rozhovor alebo e-mail by mali stačiť! Ak z nejakého dôvodu informácie nebudú aktualizované, nebojte sa – stačí kontaktovať váš tím pre podporu ľudských zdrojov na <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, a oni budú radi k dispozícii na vyriešenie situácie.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Požiadavky na skupiny tagov: Všetci, Obľúbení, Prihlásení & Noví zamestnanci
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Všetci:</Body1Strong> Tento prednastavený tag zobrazuje každého zamestnanca vo vašej organizácii, 
                      poskytujúc komplexný pohľad na tím.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Obľúbení:</Body1Strong> Kliknite tu, aby ste filtrovali zobrazenie na tých kolegov, ktorých ste označili 
                      ako 'Obľúbení', čo uľahčuje rýchle spojenie s kľúčovými kontaktmi.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Prihlásení:</Body1Strong> Tento tag označuje zamestnancov, ktorí sú aktívne v práci, a odlišuje ich 
                      od prostého online stavu v Microsoft Teams. Keď sú prihlásení, zamestnanci signalizujú, že sú v práci a počas dňa zdieľajú svoju aktuálnu pracovnú polohu. 
                      Toto môže zahŕňať rôzne pracoviská ako Kancelária, Terénna kancelária, Domáca kancelária, Vzdialené pracovisko, Zákaznícke pracovisko, 
                      Súkromná kancelária zákazníka, Verejná oblasť, Cesta, Služobná cesta alebo Dokonca Školenie. Tým pádom status 'Prihlásený' poskytuje jasnú indikáciu 
                      aktívneho práce a kolegom presné chápanie súčasného pracovného kontextu a dostupnosti každého.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Noví zamestnanci:</Body1Strong> V aplikácii TeamConnect si všimnite nové tváre s týmto tagom, ktorý 
                      zdôrazňuje zamestnancov, ktorí boli pridaní do aplikácie v posledných 30 dňoch. Zvyčajne to zodpovedá nedávnym prírastkom vo vašej organizácii, čo vám pomáha 
                      privítať nových členov tímu.
                    </li>
                  </ul>
                  Tieto skupiny tagov sú navrhnuté tak, aby zjednodušili navigáciu a zlepšili vašu skúsenosť s TeamConnect, zaručujúc, že vždy môžete 
                  efektívne nájsť a spojiť sa so svojimi kolegami.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Dekódujte ikony vedľa vyhľadávacieho poľa
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Adresár zamestnancov TeamConnect je navrhnutý pre všestrannosť a jednoduchosť, a obsahuje štyri ikony, ktoré ponúkajú rôzne zobrazenia
                  a rýchly prístup k kľúčovým detailom. Tu je to, čo každá ikona odhaľuje:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zobrazenie zoznamu:</Body1Strong> Vaše základné zobrazenie, ktoré predstavuje všetkých členov organizácie
                      v priamom zozname.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zobrazenie malej karty:</Body1Strong> Kompaktná alternatíva, toto zobrazenie transformuje
                      zoznam na malé karty zamestnancov.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zobrazenie veľkej karty:</Body1Strong> Pre viac detailov si vyberte väčšie karty, ktoré zahŕňajú
                      ďalšie možnosti kontaktu ako telefón a mobilné čísla pre ľahké volanie.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organizačný diagram:</Body1Strong> Vizuálny prehľad štruktúry spoločnosti s organizačným diagramom, 
                      ideálny na pochopenie vzťahov pri riadení a navigáciu po organizačnej krajine.</li>
                  </ol>
                    Prepínaním medzi týmito ikonami nájdite zobrazenie, ktoré najlepšie vyhovuje vašim potrebám v danom momente, či už hľadáte rýchle informácie alebo hlbšie prehľady.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Kde by som mal hľadať pri rýchlom vyhľadávaní kolegov?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hľadáte kolegu? Stačí ísť na vyhľadávacie pole TeamConnect, umiestnené v pravom hornom rohu vedľa ikony organizačného diagramu. 
                  Začnite písať ich meno a pozorujte, ako sa výsledky zosilňujú s každým písmenom. Nezabudnite, pre vyhľadávania v TeamConnect, obíďte vyhľadávací panel 
                  Microsoft Teams na vrchu - naša špeciálna vyhľadávacia skrinka je správna cesta!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Ako funguje abecedné vyhľadávanie pri hľadaní zamestnancov?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Adresár zamestnancov TeamConnect obsahuje praktickú abecednú vyhľadávaciu funkciu, ktorá vám pomôže rýchlo nájsť zamestnancov podľa ich mena. 
                  Stačí kliknúť na písmeno z abecedy zobrazené na vrchu adresára a TeamConnect okamžite zobrazí všetkých zamestnancov, 
                  ktorých mená začínajú týmto písmenom. Pre návrat k celkovému zoznamu zamestnancov stačí zrušiť výber zvýrazneného písmena. Táto intuitívna 
                  funkcia uľahčuje navigáciu v rozsiahlych zoznamoch zamestnancov, čo zabezpečuje, že môžete kolegov nájsť rýchlo a efektívne.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Ako môžem nastaviť počet zamestnancov zobrazených na stránke?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect vám umožňuje prispôsobiť, koľko profilov zamestnancov si prezeráte na stránke, čo uľahčuje navigáciu v adresári zamestnancov. 
                  Na spodnej časti každej stránky nájdete možnosti stránkovania: 10, 50, 100, 500, 1000 a 5000. Predvolené nastavenie zobrazuje 10 profilov na stránke. 
                  Pre zobrazenie viacerých profilov na jednej stránke jednoducho vyberte požadované číslo z týchto možností. Táto funkcia poskytuje flexibilitu pri 
                  prehliadaní adresára, čo vám umožňuje prispôsobiť zobrazenie vašim konkrétnym potrebám alebo preferenciám.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Ako môžem zoradiť informácie o zamestnancoch v zobrazení zoznamu?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  V zobrazení zoznamu adresára zamestnancov TeamConnect máte možnosť zoraďovať informácie o zamestnancoch podľa vašich potrieb. Stĺpce—Meno zamestnanca, 
                  Pozícia, Oddelenie, Miesto práce a Pracovisko—sú všetky zoraditeľné. Stačí kliknúť na hlavičku stĺpca, podľa ktorého chcete zoradiť. Kliknutím raz zoradíte 
                  stĺpec vzostupne a opätovným kliknutím zoradíte zostupne. Predvolene je adresár zoradený podľa mena zamestnanca vzostupne, 
                  na základe mena. Táto funkcia zoraďovania vám pomáha rýchlo organizovať a prezerať podrobnosti o zamestnancoch spôsobom, ktorý najlepšie slúži vášmu účelu, 
                  či už hľadáte konkrétnu osobu alebo potrebujete zoskupiť zamestnancov podľa určitého kritéria, ako je oddelenie alebo miesto.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Často kladené otázky adresára oddelení</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Úvod do adresára oddelení:</Body1Strong> Adresár oddelení je vašou bránou k pochopeniu zložité siete štruktúry vašej organizácie, ktorá poskytuje jasný pohľad na hierarchiu oddelení, vedúce manažérske postavenia a možnosti pre plynulú komunikáciu medzi oddeleniami.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Ako fungujú značky 'Všetky', 'Obľúbené' a 'Vaše oddelenie' v adresári oddelení?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  V adresári oddelení TeamConnect vám značky pomáhajú rýchlo navigovať a filtrovať informácie o oddeleniach. Tu je význam jednotlivých značiek:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Všetky:</Body1Strong> Táto značka zobrazuje všetky oddelenia vo vašej organizácii a poskytuje 
                      komplexný prehľad.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Obľúbené:</Body1Strong> Použite túto značku na zobrazenie kurátovaného zoznamu oddelení, ktoré ste označili 
                      ako 'Obľúbené', čím uľahčujete prístup k oddeleniam, s ktorými často komunikujete.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vaše oddelenie:</Body1Strong> Táto značka filtruje zobrazenie tak, aby ukazovalo len vaše oddelenie, 
                      čo umožňuje rýchly prístup k aktuálnym informáciám a aktualizáciám vášho oddelenia.</li>
                  </ul>
                  Tieto značky sú navrhnuté tak, aby zjednodušili vašu navigáciu v adresári oddelení, čím zvyšujú vašu schopnosť efektívne nájsť a zapojiť sa do informácií o oddeleniach.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Porozumenie zobrazeniam adresára oddelení v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigácia v adresári oddelení v TeamConnect je zjednodušená prostredníctvom viacerých možností zobrazenia, ktoré zodpovedajú vašim preferenciám. Každé zobrazenie ponúka 
                  unikátny spôsob, ako preskúmať komplexné informácie o oddeleniach, vrátane názvu oddelenia, manažéra oddelenia, nadriadeného oddelenia, telefónu oddelenia, 
                  hotline oddelenia a e-mailu oddelenia. Tu je prehľad dostupných zobrazení:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zobrazenie zoznamu:</Body1Strong> Predvolené nastavenie, ktoré prehľadne zobrazuje všetky oddelenia, poskytujúca 
                      jasný a stručný prehľad.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zobrazenie veľkých kariet:</Body1Strong> Prepnite na toto zobrazenie, aby ste videli detailné karty oddelení, ktoré 
                      ponúkajú rozšírený súhrn kľúčových informácií o každom oddelení.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zobrazenie organizačného diagramu:</Body1Strong> Pre vizuálne znázornenie štruktúry oddelení a 
                      vzťahov, zobrazenie organizačného diagramu ilustruje, ako sa každé oddelenie hodí do širšej organizačnej hierarchie.
                    </li>
                  </ul>
                  Na prepnutie medzi týmito zobrazeniami jednoducho použite ikony vedľa vyhľadávacieho poľa na vrchu adresára oddelení. Každé zobrazenie je navrhnuté tak, aby vám pomohlo 
                  pristupovať k informáciám o oddeleniach a ľahko ich pochopiť, zabezpečujúc, že nájdete informácie, ktoré potrebujete vo formáte, ktorý vám najviac vyhovuje.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Ako používať vyhľadávací box v adresári oddelení
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ľahko lokalizujte akékoľvek oddelenie v TeamConnect pomocou špeciálneho vyhľadávacieho boxu umiestneného priamo vedľa ikon zobrazenia v hornej časti 
                  adresára oddelení. Jednoducho začnite písať názov oddelenia, ktoré hľadáte, a sledujte, ako sa adresár upravuje, aby zobrazil iba relevantné zhody. Táto 
                  funkcia je špeciálne navrhnutá tak, aby ste nemuseli manuálne prechádzať celý adresár, čím privedie potrebné oddelenie priamo k vašim prstom. Pamätajte, 
                  že táto vyhľadávacia funkcia je unikátna pre adresár oddelení TeamConnect, takže používajte tento vyhľadávací box namiesto hlavného vyhľadávania Microsoft 
                  Teams na vrchu rozhrania Teams pre špecifické dotazy na oddelenia.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Ako funguje abecedný filter v adresári oddelení?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Zjednodušte svoje vyhľadávanie v adresári oddelení pomocou abecedného filtra umiestneného hore. Táto funkcia vám umožňuje rýchlo zúžiť oddelenia výberom 
                  písmena. Po výbere písmena TeamConnect filtruje a zobrazuje iba tie oddelenia, ktorých názvy začínajú vybraným písmenom, čím uľahčuje nájdenie toho, čo hľadáte 
                  bez nutnosti prechádzať celý zoznam. Aby ste sa vrátili k úplnému zobrazeniu oddelení, jednoducho zrušte výber zvýrazneného písmena. Táto intuitívna abecedná navigácia 
                  zabezpečuje, že môžete efektívne lokalizovať akékoľvek oddelenie podľa jeho počiatočného písmena.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Ako môžem kontaktovať manažéra oddelenia?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Kontaktovanie manažéra oddelenia v TeamConnect je priamočiare. Jednoducho lokalizujte zaujímavé oddelenie a kliknite na meno manažéra. Táto akcia spustí 
                  vyskakovacie okno zobrazujúce kontaktne možnosti manažéra s rýchlo prístupnými ikonami:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona chatu:</Body1Strong> Na začatie okamžitej správy prostredníctvom chatu Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona e-mailu:</Body1Strong> Na otvorenie nového e-mailu adresovaného im v Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona videhovoru:</Body1Strong> Na iniciovanie videhovoru prostredníctvom Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona hovoru:</Body1Strong> Na začatie hlasového hovoru pomocou Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ikona viac (...):</Body1Strong> Pre ďalšie možnosti ako naplánovanie stretnutia alebo pridanie 
                      ich kontaktných informácií do vášho zoznamu Teams.
                    </li>
                  </ul>
                  Tieto ikony poskytujú jednoduchú metódu ako sa priamo v TeamConnect obrátiť na manažérov oddelení, čím zabezpečujú efektívnu komunikáciu vo vašej organizácii.
                </Body1>
              </AccordionPanel>
            </AccordionItem>              
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Ako môžem zobraziť alebo kontaktovať zamestnancov v rámci oddelenia?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ak chcete preskúmať, kto je kto v oddelení v TeamConnect, pozrite sa na tri bodky vedľa názvu oddelenia. Kliknutím na ne sa
                  zobrazia možnosti pre ďalšie kroky:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zobraziť zamestnancov v tomto oddelení:</Body1Strong> Vyberte túto možnosť na zobrazenie zoznamu všetkých
                      zamestnancov asociovaných s oddelením. Číslo pri tejto možnosti ukazuje, koľko zamestnancov je v oddelení, čo poskytuje rýchly prehľad.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organizačná schéma oddelenia:</Body1Strong> Ak si prajete pochopiť hierarchiu oddelenia,
                      zvoľte 'Organizačná schéma oddelenia' na odhalenie štruktúrneho rozloženia, ktoré ukazuje vzťahy medzi nadradenými oddeleniami, daným oddelením,
                      a akýmikoľvek pododdeleniami. Táto vizuálna reprezentácia vám pomôže navigovať a pochopiť organizačný rámec, čo uľahčuje identifikáciu a
                      kontaktovanie konkrétnych členov oddelenia.
                    </li>
                    <li><Body1Strong>Oddelenie {'>'} Zamestnanec:</Body1Strong> Po zobrazení zamestnancov v oddelení použite túto navigačnú cestu na jednoduchý návrat na zoznam oddelení, čím udržiavate plynulý priebeh skúmania v TeamConnect.
                    </li>
                  </ul>
                  Tieto funkcie vám umožnia nielen vidieť zoznam zamestnancov v danom oddelení, ale tiež poskytujú cestu na priamy kontakt s nimi prostredníctvom komunikačných nástrojov TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Objavovanie podrobností o oddeleniach v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ponorte sa do detailov akéhokoľvek oddelenia jednoduchým kliknutím na jeho názov v TeamConnect. Rozvinie sa detailný dialóg, ktorý ponúkne bohaté informácie,
                  ktoré vám poskytnú úplný obraz o úlohe a zdrojoch oddelenia vo vašej organizácii. Nájdete tu všetko od vedenia a finančných identifikátorov po komunikačné kanály a sociálne linky. Táto funkcia je vašou bránou k:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Srdcu oddelenia – jeho účelu, ľuďoch a ukazovateľoch výkonnosti.</li>
                    <li className={styles.bulletBottomMargin}>Kontaktným cestám – priame linky na kľúčové postavy oddelenia a komunikačné platformy.</li>
                    <li className={styles.bulletBottomMargin}>Organizačnej sieti – pochopenie, ako sa oddelenie integruje do širšej štruktúry spoločnosti.</li>
                  </ul>
                  Či už ide o rýchlu otázku alebo hlboké ponorenie do údajov oddelenia, TeamConnect vás vybaví nástrojmi na spojenie s a pochopenie jedinečných ekosystémov vo vašej organizácii.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Zvedaví, prečo v TeamConnect chýbajú adresy oddelení?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Objavovanie polohy oddelenia môže niekedy pripomínať hľadanie pokladu bez mapy. V TeamConnect obvykle nenájdete vyhradené adresy pre každé oddelenie. Tu je dôvod, prečo táto kartografická detail často chýba:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Viacero lokalít:</Body1Strong> Mnohé oddelenia nie sú obmedzené na jeden priestor; prosperujú v rôznych kanceláriách a geografických oblastiach.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Fluidná štruktúra:</Body1Strong> Organizačná krajina sa neustále mení, oddelenia sa vyvíjajú, spájajú alebo dokonca rozpúšťajú v súlade so strategickými zmenami.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Medziinstitucionálna spolupráca:</Body1Strong> Moderné pracoviská sú plné spolupráce, často spájajú talenty z rôznych oddelení v zdieľaných priestoroch.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong> Kultúra práce z domu:</Body1Strong> S flexibilitou práce z domu nie sú kotvy oddelení vždy zahodené v fyzických kancelárskych priestoroch.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Právne nuansy:</Body1Strong> Oddelenia sa zvyčajne točia okolo právnej štruktúry spoločnosti namiesto toho, aby mali vlastné samostatné adresy.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zjednodušenie operácií:</Body1Strong> Sústredenie sa na dynamickú tímovú prácu namiesto statických adries udržiava prevádzkovú agilitu.
                    </li>
                  </ul>
                  Ide o prijatie bezhraničného prístupu k tímovej práci a efektivite. TeamConnect odráža túto filozofiu tým, že kládže dôraz na spojenia namiesto lokalít.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Chýbajú alebo sú zastaralé informácie o vašom oddelení?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Redaktori oddelení sú v rámci vašej organizácie určení na pridávanie, aktualizáciu alebo opravu špecifických informácií pre oddelenia v TeamConnect.
                  Ak si nie ste istí, kto je redaktorom vášho oddelenia, alebo ak určité informácie vyžadujú zásah podporného tímu HR,
                  prosím kontaktujte <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> pre usmernenie.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Často kladené otázky adresára kancelárií</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Úvod do adresára kancelárií:</Body1Strong> Objavte oporu fyzickej prítomnosti vašej organizácie pomocou adresára kancelárií,
                ktorý zahŕňa základné údaje o kanceláriách, operačné špecifiká a komplexný pohľad na oddelenia a personál, ktorý definuje každé miesto.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Orientácia v značkách v adresári kancelárií
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Pohybujte sa v adresári kancelárií pohodlne pomocou nášho systému značiek určeného pre rýchly prístup na správne miesto. Tu je, ako vám každá značka môže pomôcť zefektívniť vaše vyhľadávanie:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Všetko:</Body1Strong> Zobrazí kompletný zoznam kancelárií a poskytne vám prehľad o 
                      miestach vašej organizácie.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Obľúbené:</Body1Strong> Pomôže vám ľahko nájsť tie kancelárske miesta, ktoré často navštevujete alebo na ktoré potrebujete dohliadať, označené ako 'Obľúbené'.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vaša kancelária:</Body1Strong> Táto osobná značka filtrová adresár tak, aby vám ukázala kancelárske miesto, kde ste umiestnení, udržuje vašu domovskú základňu na dosah ruky.
                    </li>
                  </ul>
                  Či už rezervujete zasadačku alebo plánujete návštevu, tieto značky ponúkajú personalizovaný a zjednodušený spôsob, ako pristupovať k geografickému prostrediu vašej organizácie.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Ovládanie zobrazení kancelárskych adresárov v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ponorte sa do organizačného rozloženia rôznych lokalít vašej spoločnosti pomocou zobrazení kancelárskych adresárov v TeamConnect. Každé zobrazenie je navrhnuté tak, aby uľahčilo 
                  jedinečný aspekt navigácie a porozumenia kancelárii:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zobrazenie zoznamu:</Body1Strong> Predvolené nastavenie, ktoré prehľadne organizuje všetky kancelárske lokality do 
                      ľahko čitateľného zoznamu.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zobrazenie veľkej karty:</Body1Strong> Zobrazenie veľkej karty v kancelárskom adresári obohacuje vaše 
                      zobrazenie o detailnú prezentáciu každej kancelárie. Pri interakcii s kartou kancelárie nájdete bohaté informácie a možnosti konania 
                      v menu "Viac (...)" :
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Zobraziť oddelenia:</Body1Strong> Priamo navigujte na zoznam
                          všetkých oddelení v kancelárii.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Zobraziť zamestnancov:</Body1Strong> Získajte prístup k úplnému zoznamu zamestnancov priradených k 
                          kancelárii, čo uľahčuje priamy kontakt alebo prieskum personálu kancelárie.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Organizačný diagram právnickej osoby:</Body1Strong> Vizualizujte miesto kancelárie v rámci väčšieho 
                          organizačného kontextu pre jasné pochopenie hierarchických štruktúr.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Dôležité kontakty:</Body1Strong> Identifikujte kľúčové kontakty kancelárie pre zjednodušenie 
                          komunikácie.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Štátne sviatky kancelárie:</Body1Strong> Buďte informovaní o špecifických štátnych sviatkoch kancelárie, 
                          aby ste mohli plánovať svoje rozvrhy podľa nich.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Pracovná doba kancelárie:</Body1Strong> Zobraziť prevádzkové hodiny kancelárie, aby ste mohli prispôsobiť svoje 
                          návštevy alebo hovory ich aktívnym časom.
                        </li>
                      </ol>
                      Toto zobrazenie poskytuje nielen vizuálny a informačný momentkový snímok, ale aj rýchly prístup k hlbším pohľadom a priamym akciám súvisiacim s kanceláriou, všetko pekne zorganizované pod možnosťou "Viac (...)" pre vaše pohodlie.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zobrazenie organizačného diagramu:</Body1Strong> Vizualizujte hierarchickú štruktúru materských a vedľajších kancelárií 
                      pre pochopenie prepojenia rôznych kancelárskych lokalít vo vašej organizácii.
                    </li>
                  </ul>
                  Prepínanie medzi týmito zobrazeniami pomocou ikon vedľa vyhľadávacieho poľa umožňuje prispôsobiť vaše skúsenosti s prehliadaním aktuálnym potrebám.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Rýchle hľadanie kancelárií s vyhľadávacou funkciou TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Vyhľadanie správnej kancelárskej lokality v TeamConnect je jednoduché vďaka vyhradenej vyhľadávacej funkcii. Umiestnené výrazne vedľa ikon zobrazenia, vyhľadávacie pole je vaším nástrojom na presné určenie kancelárií podľa názvu. Keď píšete, adresár upresňuje zobrazenie, aby zobrazil iba kancelárie zodpovedajúce vašemu zadaniu, čím zjednodušuje váš vyhľadávací proces. Je dôležité používať toto vyhľadávacie pole v rámci TeamConnect pre najpresnejšie a najrelevantnejšie výsledky, na rozdiel od všeobecného vyhľadávania v Microsoft Teams. S touto cielenou vyhľadávacou funkcionalitou môžete rýchlo navigovať do ľubovoľnej kancelárie v sieti vašej organizácie.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Abecedné prehľadávanie adresára kancelárií
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ak uprednostňujete vyhľadávanie podľa abecedy, náš abecedný filter je rýchly spôsob, ako nájsť kancelárie podľa názvu. Na vrchu adresára kancelárií uvidíte
                  písmená od A po Z. Kliknutím na písmeno sa zobrazenie filtrova, čím sa ukážu iba tie kancelárie, ktoré začínajú na toto písmeno, čo zjednodušuje váš 
                  vyhľadávací proces. Pre zobrazenie všetkých kancelárií znovu jednoducho odznačte aktívne písmeno. Táto funkcia je obzvlášť užitočná, keď poznáte názov 
                  kancelárie, ale potrebujete rýchly spôsob, ako ju lokalizovať v rámci TeamConnect.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Poodhalenie komplexných profilov kancelárií v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Vydajte sa na prieskumnú cestu srdcom fyzickej prítomnosti vašej organizácie s profilmi kancelárií v TeamConnect. Kliknutím na názov kancelárie sa 
                  zobrazí živý dialógový profil, preplnený kartami plnými detailných informácií o kancelárii:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Prehľad:</Body1Strong> Ponorte sa do jadra totožnosti kancelárie s detailnými 
                      popismi, od obchodnej štruktúry po otváracie hodiny a miestny čas, zachytávajúc podstatu prostredia kancelárie.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Dôležité kontakty:</Body1Strong> Získajte prístup k zásadným kontaktom pre núdzové služby a 
                      externú podporu, každý na dosah kliknutia prostredníctvom systému telefónov Microsoft Teams.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontakty kancelárie:</Body1Strong> Jednoducho sa spojte so službami vnútornej podpory s 
                      kontaktnými údajmi a možnosťami priamej interakcie priamo na dosah ruky.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Štátne sviatky:</Body1Strong> Buďte informovaní o špecifických sviatkoch kancelárie, uistite sa, že vaše 
                      plány sú v súlade s miestnymi zvyklosťami a pracovnými rozvrhmi.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Otváracie hodiny:</Body1Strong> Plánujte svoje interakcie s presným vedomím, kedy 
                      kancelária ožije aktivitou alebo končí deň.
                    </li>
                  </ul>
                  Či už ide o živé prostredie Austinu alebo strategické centrum Singapuru, každá karta ponúka bránu k porozumeniu a spojeniu s rozmanitými 
                  miestami, ktoré tvoria globálnu prítomnosť vašej spoločnosti.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Prieskum dynamiky oddelení v kancelárii
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigácia v profesionálnej sieti oddelení kancelárie sa odvíja s ľahkosťou v TeamConnect. Tu je návod, ako preniknúť do detailov:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zobraziť oddelenia v tejto kancelárii:</Body1Strong> Objavte všetky oddelenia, ktoré tvoria 
                      základ kancelárie vybraním tejto možnosti z menu Viac (...).
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organizačný prehľad:</Body1Strong> Kliknutím na ktorékoľvek oddelenie zobrazíte jeho organizačný diagram pre 
                      vizuálnu reprezentáciu jeho štruktúry, pričom vás navigačná cesta vedie: Kancelária {'>'} Oddelenie {'>'} Schéma.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Hlboký prieskum oddelenia:</Body1Strong> Ďalej preskúmajte oddelenie kliknutím na 
                      jeho menu Viac (...) vedľa názvu oddelenia. Tu môžete získať prístup k zoznamu všetkých zamestnancov v danom oddelení, priamo v kontexte kancelárie.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Navigácia navigačnou cestou:</Body1Strong> Prechádzajte úrovňami plynule. Od 
                      zamestnancov späť k oddeleniam alebo z organizačného diagramu, navigačná cesta (Kancelária {'>'} Oddelenie {'>'} Zamestnanec {'>'} Schéma) udržiava váš 
                      cestovný zážitok intuitívny a na správnej ceste.
                    </li>
                  </ul>
                  Zložité cesty TeamConnect vás pozývajú preskúmať, pochopiť a zapojiť sa do každej vrstvy oddelení vašej kancelárie, čím vám poskytujú 
                  komplexné navigačné schopnosti.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Spojenie s personálom kancelárie v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Zvedaví na tváre za kanceláriou? Odpoveď je len kliknutie ďaleko v TeamConnect. Zapojte sa do trojbodového menu vedľa vybraného 
                  názvu kancelárie, aby ste odhalili sadu interaktívnych možností:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zobraziť zamestnancov v tejto kancelárii:</Body1Strong> Odhaľte zoznam všetkých profesionálov
                      volajúcich túto kanceláriu svojim pracoviskom. Zobrazené číslo ponúka rýchly sčítanie zamestnancov, vyzývajúc vás k ponoření sa do komunity kancelárie.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Možnosti kontaktu:</Body1Strong> Každý záznam zamestnanca je vybavený ikonami pre rýchle akcie, 
                      umožňujúce iniciovať chaty, hovory alebo dokonca videohovory priamo z TeamConnect, čo podporuje plynulú komunikáciu v rámci kancelárie.
                    </li>
                    <li><Body1Strong>Navigácia:</Body1Strong> Ak sa chcete vrátiť k širšiemu zoznamu kancelárií zo zoznamu zamestnancov, jednoducho kliknite na 'Kancelária' v navigačnej 
                      ceste (Kancelária {'>'} Zamestnanec) a budete presmerovaní späť na prehľad kancelárie.
                    </li>
                  </ul>
                  S týmito funkciami TeamConnect uľahčuje vizualizáciu distribúcie pracovnej sily naprieč kanceláriami a umožňuje ľahké oslovenie,
                  podporuje prepojené pracovisko bez ohľadu na vzdialenosť.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Chýbajú alebo sú zastaralé informácie o vašej kancelárii?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Aktualizácie informácií o kancelárii v TeamConnect môžu spravovať pridelení redaktori kancelárie. Ak si nie ste istí ohľadom redaktorov kancelárie alebo
                  potrebujete pomoc s informáciami, ktoré spadajú do pôsobnosti HR, tím podpory HR je pripravený
                  pomôcť na <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Nastavenia Často kladené otázky</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Dôležitosť presných všeobecných nastavení v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  V dynamickom prostredí dnešného pracovného prostredia je viac než kedykoľvek predtým dôležité zostať spojený a koordinovaný. Tu hrá kľúčovú úlohu aktualizácia
                  Vašich všeobecných nastavení v TeamConnect:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Výber pracoviska:</Body1Strong> Zachyťte podstatu vášho pracovného prostredia aktualizáciou vášho pracoviska, aby odrážalo vašu súčasnú 
                        situáciu, či už je to zmena z pokoja vašej home-office na ruch hlavnej kancelárie, alebo usadnutie sa na verejnej ploche. Táto jasnosť o vašom pracovnom mieste 
                        poskytuje kolegom nahliadnutie nielen do najlepších spôsobov, ako sa vám ozvať, ale aj do povahy 
                        konverzácií, ktoré s vami môžu viesť, čím sa chráni dôvernosť citlivých diskusií, najmä keď ste uprostred 
                        otvoreného prostredia kancelárie klienta alebo sedíte v hale letiska.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Špecifikácia pracoviska:</Body1Strong> Zdieľanie vášho pracovného mesta robí viac než len preklenúť vzdialenosti v nastavení vzdialeného tímu—tiež 
                        otvára dvere k spontánnym profesionálnym stretnutiam alebo neformálnemu spoločenskému stýkaniu. Či už ide o rýchlu kávu, pokojný obed, alebo kolaboratívne 
                        stretnutie, vedomosť, že ste v rovnakom meste, môže premeniť bežný deň na príležitosť pre zmysluplné stretnutia tvárou v tvár. To nielen posilňuje väzby v tíme, ale tiež 
                        poskytuje hlbšie porozumenie miestnym nuanciám, ktoré môžu ovplyvňovať vašu prácu.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Presnosť časového pásma:</Body1Strong> Keďže tímy siahajú cez kontinenty, zabezpečenie, aby vaše časové pásmo 
                      odrážalo vašu aktuálnu lokalitu, je nevyhnutné pre harmonizáciu komunikácie. Miestny čas vo vašom profile TeamConnect je priamo ovplyvnený týmto nastavením, 
                      eliminuje akékoľvek hádanie pre kolegov, ktorí sa snažia spojiť s vami.
                    </li>
                  </ul>
                  Ponorte sa do 'Nastavenia' {'>'} 'Všeobecné nastavenia' v TeamConnect, aby ste doladili tieto detaily. Zopár chvíľ strávených aktualizáciou vašich nastavení 
                  môže výrazne zvýšiť súdržnosť a efektívnosť vašej spolupráce naprieč celým tímom.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Rozlúštenie výberu pracoviska v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Výber správneho nastavenia v rozbaľovacom menu Pracovisko prispôsobí TeamConnect tak, aby presne odrážal váš aktuálny pracovný stav. Najazdite na ikonu informácií pre podrobné vysvetlenie každej možnosti:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Odhlásený/Prestávka:</Body1Strong> Označuje čas strávený mimo práce, či už ide o prestávku alebo koniec dňa.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kancelária/Pobočka:</Body1Strong> Udáva prítomnosť v pracovnom priestore spravovanom spoločnosťou.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Domáca kancelária:</Body1Strong> Pracuje sa z vyhradeného domáceho pracoviska.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vzdialené pracovisko/Verejný priestor:</Body1Strong> Práca z prostredia mimo kancelárie, čo naznačuje potrebu flexibilnej komunikácie.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zákaznícke pracovisko/Súkromná kancelária:</Body1Strong> Na mieste u klienta s poznámkou o úrovniach súkromia pre citlivosť rozhovorov.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Doprava/Služobná cesta/Školenie:</Body1Strong> Pohyb alebo zapojenie do konkrétnych pracovných aktivít mimo kancelárie.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Verejný sviatok/Dovolenka/Rôzne voľná:</Body1Strong> Oficiálne mimo služby z osobných, zdravotných alebo sviatočných dôvodov.</li>
                  </ul>
                  Výber vhodného nastavenia pracoviska zabezpečuje, že váš tím bude mať kontext potrebný pre interakcie, dodržiavajúc dostupnosť a súkromie.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Navigácia stavu "Prihlásený" v TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Značka "Prihlásený" v adresári zamestnancov je navrhnutá tak, aby zobrazovala všetkých zamestnancov, ktorí sú momentálne v práci. Táto viditeľnosť je určená výberom Pracoviska, ktorý urobíte:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Aktívne pracovné prostredia:</Body1Strong> Možnosti Kancelária, Pobočka, Domáca kancelária, Vzdialené pracovisko, Verejný priestor, Zákaznícke pracovisko,
                    Súkromná kancelária zákazníka, Doprava, Služobná cesta a Školenie signalizujú, že ste zapojení do práce, čo vás označuje ako "Prihláseného" a viditeľne aktívneho pre kolegov.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nastavenia mimo práce:</Body1Strong> Výber možností Odhlásený, Prestávka, Verejný sviatok, Dovolenka, Na voľne, Materská dovolenka alebo Nemocenská dovolenka 
                      odstráni vaše meno zo zoznamu "Prihlásených", čím jasne definuje očakávania týkajúce sa dostupnosti a časov na odpovede.
                  </li>
                  </ul>
                  Táto funkčnosť pomáha rozlišovať pracovné obdobia od osobného času, čím prispieva k efektívnej spolupráci a komunikácii v tíme.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Správa údajov a často kladené otázky o ochrane súkromia</Subtitle2>
          <Body1>
            <p>
              V dnešnej digitálnej dobe je pochopenie správy vašich údajov kľúčové. TeamConnect sa zaväzuje k transparentnosti a k robustným praktikám ochrany údajov. 
              Nižšie nájdete informácie o tom, ako spravujeme, ukladáme a chránime vaše údaje v rámci TeamConnect, čím zabezpečujeme, že váš digitálny pracovný priestor je bezpečný a v súlade s predpismi.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Ako TeamConnect chráni moje osobné údaje?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect sa zameriava na ochranu vašich osobných informácií pomocou komplexného prístupu k bezpečnosti údajov:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Single Sign-On (SSO):</Body1Strong> Aby posilnil bezpečnosť a zjednodušil používateľský zážitok, TeamConnect integruje automatizované 
                      Single Sign-On (SSO) s vaším účtom Microsoft 365, čo umožňuje bezpečné a pohodlné spravovanie prístupu.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Šifrovanie:</Body1Strong> Zabezpečujeme, že všetky údaje v rámci TeamConnect sú šifrované počas prenosu aj v pokoji, 
                      čím sú nepriístupné neoprávneným stranám.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Minimalizácia údajov:</Body1Strong> Naša zbierka údajov je striktne obmedzená len na to, čo je nevyhnutné pre funkčnosť TeamConnect. 
                      Jediné informácie o používateľovi, ktoré ukladáme, sú vaše používateľské meno (UPN) z Azure Entra ID, ktoré môže pripomínať vašu firemnú e-mailovú adresu.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Obmedzený prístup:</Body1Strong> Prístup k UPN je obmedzený na správcu predplatného vašej organizácie v našom portáli predplatného, 
                      čím sa zabezpečuje, že citlivé informácie sú viditeľné len pre autorizovaný personál.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Rýchle vymazanie údajov:</Body1Strong> TeamConnect je štruktúrovaný tak, aby okamžite zmazal uložené UPN za troch konkrétnych podmienok: 
                      ak správca predplatného zruší predplatné, ak HR v rámci TeamConnect odstráni používateľa, alebo ak predplatné TeamConnect vyprší. Toto zabezpečuje, že vaše osobné údaje nie sú uchovávané dlhšie, než je ich užitočná životnosť alebo nevyhnutnosť.
                    </li>                      
                  </ul>
                  <p>Pomocou týchto opatrení sa TeamConnect zaväzuje k udržiavaniu najvyšších štandardov ochrany údajov, čím zabezpečuje, že vaše osobné informácie zostanú bezpečné a dôverné.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Zaujímate sa, kde sú uložené vaše údaje s TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect prelomí štandardy tradičných SaaS ponúk zabezpečením, že vaše údaje nikdy neopustia vašu organizáciu. 
                  Na rozdiel od bežnej praxe ukladania údajov na externé cloudové servery, TeamConnect dômyselne využíva vášho 
                  organizácie Microsoft 365 SharePoint Online na uloženie všetkých aplikačných údajov. Tento inovatívny prístup nielen že podporuje suverenitu údajov, ale aj zvyšuje bezpečnosť,
                  a dokonale súladí s našim neochvejným záväzkom chrániť vaše súkromie a dodržiavať prísne normy súladu.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Budú moje údaje zdieľané s akýmikoľvek tretími stranami?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nie, údaje nie sú zdieľané s tretími stranami! Cubeet je neochvejný vo svojom záväzku chrániť súkromie a bezpečnosť vašich údajov. Bez výslovného súhlasu našich zákazníkov nijaké údaje TeamConnect s tretími stranami nezdieľame.
                  V našom bezpečnom prostredí ukladáme iba nevyhnutné identifikátory, ako sú User Principal Name (UPN), názov organizácie, názov tenant a ID tenant, v rámci nášho portálu Subscription.
                  Tieto informácie sú kľúčové pre poskytovanie služieb a sú chránené našimi prísnymi pravidlami ochrany osobných údajov.
                  Buďte si istí, že naše postupy správy údajov sú navrhnuté tak, aby zaručili dôvernosť vašich údajov, pričom transparentnosť a kontrola sú pevne vo vašich rukách.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Ako TeamConnect spĺňa predpisy na ochranu údajov ako GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect je v súlade s predpismi ochrany údajov ako GDPR, HIPAA, CCPA a ďalšími prostredníctvom využívania vrodených funkcií súladu prostredia Microsoft 365,
                  kde sú všetky údaje zákazníkov bezpečne uložené. Na rozdiel od tradičných modelov SaaS, TeamConnect neukladá žiadne údaje zákazníkov na svoje servery; namiesto toho funguje
                  v prostredí Microsoft 365 vašej organizácie, dodržiavajúc jeho robustné bezpečnostné a súladové štandardy. Tento prístup zabezpečuje, že riadenie vašich údajov zostáva v súlade s 
                  komplexným rámcom súladu, ktorý poskytuje Microsoft 365, vrátane dodržiavania GDPR, HIPAA pre zdravotnícke informácie, CCPA pre obyvateľov Kalifornie,
                  a ďalších globálnych predpisov na ochranu údajov. Integráciou do vášho existujúceho nastavenia Microsoft 365, TeamConnect umožňuje vašej organizácii udržať 
                  plnú kontrolu nad vašimi údajmi a zabezpečuje súlad s najprísnejšími zákonmi a predpismi na ochranu údajov.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Ako zvláda TeamConnect údaje z chatov, hovorov, videohovorov, e-mailov z Outlooku a hovorov z telefónneho systému v Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect využíva Microsoft Graph API na umožnenie priamych interakcií, ako sú chaty, hovory, videohovory a e-maily v Outlooku v rámci Microsoft Teams,
                  čím poskytuje plynulý zážitok používateľa. Hlavné je, že aj keď TeamConnect iniciovať tieto komunikácie, robí tak bez zasahovania do procesu komunikácie
                  alebo bez schopnosti zaznamenávať alebo ukladať obsah interakcií. Všetky konverzácie, hovory a e-maily prebiehajú a sú riadené priamo v rámci
                  bezpečného prostredia Microsoft Teams a Outlooku, s prísnym dodržiavaním robustných bezpečnostných a súkromných štandardov Microsoftu 365. To zabezpečuje, že TeamConnect zvyšuje
                  vaše komunikačné schopnosti, pričom plne zachováva dôvernosť a integritu vašich údajov.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Pre podrobné informácie o našich zásadách a praktikách súvisiacich s údajmi sa môžete tiež poradiť s {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>Príručkou používateľa TeamConnect</Body1Strong></Link> alebo s IT oddelením vašej organizácie na <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> pre špecifiká súvisiace s vaším nastavením.
          </p>
        </Body1>        
        <p></p>  
        <Subtitle1 className={styles.fontColor}>Počúvame vás: Zdieľajte svoje návrhy a nápady</Subtitle1>
        <Body1>
          <p>
            Vaše podnety poháňajú vývoj TeamConnect. Či už máte skvelý nápad na novú funkciu alebo máte spätnú väzbu na vašu súčasné skúsenosti, my počúvame na všetko. Váš príspevok je neoceniteľný v našom neustálom snažení ponúknuť vám 
            najlepšiu možnú službu.
          </p>
        </Body1>      
        <Subtitle2>Spätná väzba a návrhy na funkcie</Subtitle2>
        <Body1>
          <p>
            Pripravení zdieľať? Prejdite na našu {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              stránku so spätnou väzbou
            </Link>
            . Či už ide o návrh na novú funkciu alebo spätnú väzbu na existujúce funkcionality, jednoducho vyberte 
            príslušnú možnosť z rozbaľovacieho zoznamu vo formulári. Vaše príspevky pomáhajú formovať budúcnosť TeamConnect, 
            zaistí, že bude spĺňať a prekračovať vaše potreby.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
