import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>TeamConnect palīdzības un BUJ centrs</Title3>
        <p><Body1>
          Laipni lūdzam TeamConnect Palīdzības centrā, kas izveidots, lai atbalstītu jūsu pieredzi ar mūsu visaptverošo korporatīvo direktoriju lietotni. Šeit jūs atradīsiet resursus, lai optimizētu TeamConnect izmantošanu Microsoft Teams, uzlabojot komunikāciju savā tīklā.
        </Body1></p>
        <Subtitle2>Ātrās atsauces rokasgrāmata</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Uzlabotas meklēšanas iespējas: </Body1Strong>
              Atrast īstos kolēģus, nodaļas un būtiskus kontaktus ātri, izmantojot mūsu spēcīgos meklēšanas rīkus.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Vizuālie organizācijas diagrammas: </Body1Strong>
              Lai viegli izprastu hierarhiju un atskaites līnijas jūsu organizācijā, izmantojiet intuitīvas organizācijas diagrammas.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Pielāgoti rīki šodienas vajadzībām: </Body1Strong>
              Izmantojiet mūsdienīgas funkcionalitātes, kas pielāgotas mūsdienu darba vietu prasībām.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Optimizējot savu pieredzi</Subtitle2>
        <Body1>
          <p>
            TeamConnect revolucionē, kā jūs mijiedarbojaties savā organizācijas struktūrā, pārvēršot ikdienas komunikācijas stratēģiskās mijiedarbībās.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Saņemt palīdzību un BUJ</Subtitle1>
        <Body1>
          <p>
            Atrodiet atbildes uz bieži uzdotajiem jautājumiem par TeamConnect funkcijām un iestatījumiem šajā veltītajā sadaļā. Vai nu pārlūkojat lietotni, pielāgojat iestatījumus vai meklējat risinājumus problēmām, mūsu Palīdzības centrs ir šeit, lai jūs efektīvi atbalstītu.
          </p>
        </Body1>
        <Body1>
          <p>
            Plašākam atbalstam vai konkrētai vadībai lūdzu konsultējieties ar <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnect Lietotāja rokasgrāmatu</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>        
        <Subtitle2>Izmantojot TeamConnect BUJ</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Nevar atrast TeamConnect Microsoft Teams sānjoslā?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ja TeamConnect nav acīmredzami redzams vai ir pazudis jūsu Microsoft Teams sānjoslā, to var viegli piespraust ātrai piekļuvei. Vienkārši izpildiet šos soļus:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Atveriet Microsoft Teams un noklikšķiniet uz '...' (+ Apps) ikonas sānjoslas apakšā.</li>
                    <li className={styles.bulletBottomMargin}>Meklējiet 'TeamConnect' meklēšanas joslā augšpusē uznirstošajā logā.</li>
                    <li className={styles.bulletBottomMargin}>Ar peles labo pogu noklikšķiniet uz TeamConnect lietotnes meklēšanas rezultātos un izvēlieties 'Piespraust'.</li>
                  </ol>
                  Tagad TeamConnect būs piesprausts jūsu sānjoslā, padarot to pieejamu tikai ar vienu klikšķi!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Sastopaties ar tehniskām problēmām, izmantojot TeamConnect? Lūk, kā iegūt palīdzību.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ja sastopaties ar jebkādām tehniskām problēmām, izmantojot TeamConnect Microsoft Teams, jūsu organizācijas IT atbalsta komanda ir šeit, lai palīdzētu. 
                  Sazinieties ar viņiem, izmantojot <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, un viņi jums palīdzēs nekavējoties.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Nevarat atrast savus darbinieka datus?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ja jūsu profilā redzami tikai jūsu vārds un e-pasts, visticamāk, jūsu personāla nodaļa vēl nav pievienojusi pārējos jūsu darbinieka datus TeamConnect. 
                  Nav jāuztraucas! Īsa ziņa jūsu personāla atbalsta komandai liks lietām virzīties uz priekšu. Viņi ir gatavi papildināt jūsu profilu ar visiem svarīgajiem datiem. 
                  Sazinieties, izmantojot <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, un vērojiet, kā jūsu profils iegūst dzīvību!
                </Body1>
              </AccordionPanel>
            </AccordionItem>           
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Kādas valodas iespējas ir pieejamas TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect atzīst Microsoft Teams globālo daudzveidību, atbalstot visas
                  tās valodas. Vai jūs dodat priekšroku arābu vai grieķu, japāņu vai zviedru
                  valodai - jums ir pieejamas daudzas iespējas, tostarp:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      arābu, bulgāru, ķīniešu (vienkāršotā un tradicionālā), horvātu, čehu,
                      dāņu, holandiešu, angļu, igauņu, somu, franču, vācu, grieķu, ivritu,
                      ungāru, indonēziešu, itāļu, japāņu, korejiešu, latviešu, lietuviešu,
                      norvēģu (bokmål), poļu, portugāļu (Brazīlija un Portugāle), rumāņu,
                      krievu, serbu (latīņu), slovāku, slovēņu, spāņu, zviedru, taju, turku,
                      ukraiņu, vjetnamiešu.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Lai pielāgotu savu TeamConnect pieredzi vēlamajai valodai, lūdzu,
                  pielāgojiet valodas iestatījumus tieši Microsoft Teams, jo TeamConnect
                  pilnībā integrējas ar šiem iestatījumiem:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Dodieties uz saviem Microsoft Teams iestatījumiem, noklikšķinot uz
                      trīs punktiem blakus savam profila attēlam augšdaļā, un pēc tam
                      izvēlieties 'Izskats un pieejamība'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Ritiniet līdz sadaļai 'Valoda' un izvēlieties vēlamo valodu no
                      nolaižamā izvēlnes.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Apstipriniet savas izmaiņas, noklikšķinot uz pogas 'Saglabāt un
                      restartēt', lai piemērotu jaunos valodas iestatījumus.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Piezīme: </Body1Strong>
                <Body1>
                  Izmaiņas, ko veicat savos valodas iestatījumos Microsoft Teams, tiks
                  netraucēti atjauninātas arī jūsu TeamConnect pieredzē. Ņemiet vērā, ka
                  valodas maiņa Teams var arī mainīt jūsu laika un datuma formātu. Pašlaik
                  TeamConnect attīstās, lai atbalstītu rakstīšanu no labās uz kreiso. Mēs
                  esam priecīgi par šiem uzlabojumiem un turpināsim jūs informēt, kā tie
                  kļūst pieejami.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Kā es varu mainīt dizainu TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect sinhronizē jūsu Microsoft Teams dizaina izvēles, ieskaitot gaišo, tumšo un augstas kontrasta opcijas. Lai pielāgotu savu dizainu:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Pieejiet jūsu Microsoft Teams iestatījumiem, noklikšķinot uz trīs punktiem blakus jūsu profila attēlam augšā.</li>
                    <li className={styles.bulletBottomMargin}>Izvēlieties 'Izskats un pieejamība', lai pārlūkotu dizaina opcijas.</li>
                    <li className={styles.bulletBottomMargin}>Izvēlieties vēlamo dizainu no pieejamajām opcijām, lai nekavējoties atjauninātu jūsu TeamConnect interfeisu, lai tas atbilstu.</li>
                  </ol>
                  Jūsu izvēlētais dizains Microsoft Teams tiks automātiski piemērots TeamConnect, nodrošinot vizuāli konsekventu pieredzi visā jūsu darbvietā.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Iepazīstiet TeamConnect kontaktpogas
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Visa TeamConnect, kur vien atradīsit darbinieka kontaktinformāciju, jūs arī atradīsiet mūsu ērtās ātrās piekļuves pogas. Šie noderīgie rīki 
                  ir izstrādāti, lai vienkāršotu jūsu saziņu Microsoft ekosistēmā, nodrošinot, ka jūs vienmēr esat tikai vienu klikšķi no savienojuma. 
                  Šeit ir jūsu visaptverošs rokasgrāmata šo pogu efektīvai izmantošanai:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chat Icon:</Body1Strong> Noklikšķiniet, lai sāktu nekavējoties Microsoft Teams tērzēšanu. Ja jums ir nepieciešams atgriezties TeamConnect pēc tērzēšanas sākšanas, vienkārši izmantojiet atpakaļ bultiņu darbvirsmas lietotnē, atpakaļ pogu mobilajā lietotnē vai pārlūka atpakaļ pogu.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Email Icon:</Body1Strong> Atver jaunu Outlook e-pasta melnrakstu ar saņēmēja adresi gatavu "Kam:" laukā. Ja Outlook nedarbojas, ir vērts pārbaudīt ar jūsu IT nodaļu par jebkādiem ierobežojumiem.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Video Call Icon:</Body1Strong> Uzsāk sejas pret seju video zvanu, izmantojot Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Call Icon:</Body1Strong> Sāk Microsoft Teams balss zvanu, padarot ātrās diskusijas nepārtrauktas.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org Chart Icon:</Body1Strong> Atklāj darbinieka vietu organizācijas hierarhijā, ieskaitot viņa vadītāju un tiešos pakļautības.</li>
                    <li><Body1Strong>More (...) Icon:</Body1Strong> Atklāj papildu opcijas:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Meet Now:</Body1Strong> Atver Microsoft Teams, lai nekavējoties sāktu sanāksmi ar darbinieku.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>New Meeting:</Body1Strong> Novirza jūs uz Microsoft Teams, lai ieplānotu jaunu sanāksmi.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Add Contact:</Body1Strong> Pievieno darbinieka kontaktinformāciju no TeamConnect uz jūsu jauno Microsoft Teams People lietotni, bagātinot jūsu zvana funkciju.</li>
                      </ul>
                    </li>
                  </ul>
                  Šīs intuitīvās pogas ir radītas, lai nodrošinātu, ka jūs varat efektīvi sazināties ar kolēģiem, vai tā būtu ātra tērzēšana, e-pasts vai sanāksmju ieplānošana nākotnē.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Zvanīšana caur TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Vēlaties veikt zvanu? TeamConnect vienkāršo jūsu saziņu, integrējoties ar Microsoft Teams. 
                  Vienkārši noklikšķiniet uz vēlamā tālruņa numura TeamConnect, un lūk, Microsoft Teams zvana interfeiss parādās, 
                  gatavs jums tūlītēji veikt zvanu bez problēmām.
                  <p>
                  <Body1Strong>Piezīme: </Body1Strong> 
                  Sastopoties ar problēmām, veicot zvanus? Tas var būt saistīts ar jūsu organizācijas IT politiku vai 
                  citu tālruņu sistēmu izmantošanu nevis Microsoft Teams Phone System. Saziņa ar jūsu IT nodaļu vietnē <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, var sniegt vairāk informācijas par situāciju.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Vai saistībā ar zvaniem, izmantojot TeamConnect, ir kādas izmaksas?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect uzlabo jūsu zvanīšanas pieredzi, izmantojot Microsoft Teams. Lūk, ko jums vajadzētu zināt par zvanu saistītajām izmaksām:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Zvaniem uz darbinieku darba tālruņiem, nodaļu tālruņiem vai uzņēmuma iekšējiem numuriem (iekšējie numuri), izmantojot Microsoft Teams Phone System, 
                      parasti <Body1Strong>nav izmaksu</Body1Strong>.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Eksternāliem zvaniem, piemēram, uz mobilo tālruņiem vai ārējām līnijām, piemēro <Body1Strong>standarta tarifus</Body1Strong> jūsu organizācijas tālruņu sistēmā.
                      Šie tarifi var atšķirties atkarībā no jūsu konkrētā abonementa plāna ar Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Piezīme:</Body1Strong> Ja jums rodas problēmas vai jums ir jautājumi par zvanu izmaksām, labāk ir konsultēties ar 
                    IT nodaļu, izmantojot <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> lai iegūtu informāciju, kas pielāgota
                    jūsu organizācijas sistēmai un politikām.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Vai pamanījāt neatbilstību jūsu "Prombūtnes" statusā TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Vai esat pamanījis, ka jūsu "Prombūtnes" statuss no Microsoft 365 netiek pareizi atspoguļots TeamConnect? 
                  Mēs esam to jau iestrādājuši. Jau ir implementēta "Prombūtnes" statusa sinhronizācijas funkcija TeamConnect.
                  Pašlaik gaidām, kad Microsoft izlaidīs šo funkciju. Tā notiekot, jūsu "Prombūtnes" statuss 
                  tiks precīzi atspoguļots TeamConnect, saskanot ar Microsoft Teams klātbūtnes indikatoriem, nodrošinot nevainojamu pieredzi.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>FAQ par Darbinieku direktoriju</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Iepazīšanās ar Darbinieku direktoriju:</Body1Strong> Iepazīstieties ar jūsu organizācijas profesionālo vidi, izmantojot Darbinieku direktoriju, 
                kas ir jūsu centrālais resurss detalizētai darbinieku informācijai, darba dinamikai, un tiešam savienojumam ar jūsu kolēģu profilu un vadības attiecībām.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Nepieciešams pievienot, atjaunināt vai labot jūsu darbinieka datus?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Lai pievienotu, atjauninātu vai labotu jūsu darbinieka datus TeamConnect, lūdzu sazinieties ar jūsu organizācijas personāla atbalsta komandu. 
                  Viņi ir gatavi palīdzēt, izmantojot <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Kas notiek, kad noklikšķināt uz darbinieka vārda direktorijā?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Noklikšķinot uz darbinieka vārda TeamConnect darbinieku direktorijā, tiek atvērta detalizēta profila lapa par šo personu. Šis profils sniedz plašu
                  ieskatu par darbinieku, ieskaitot, bet neaprobežojoties ar viņa amata nosaukumu, departamentu, biroja atrašanās vietu, kontaktinformāciju un vēl vairāk. Tas ir izstrādāts, 
                  lai jums sniegtu pilnu ieskatu par darbinieka profesionālo lomu un viņa iekļaušanos organizācijā, padarot vieglāk saprast viņa pienākumus un 
                  kā jūs varētu sadarboties ar viņu.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Kas ir ietverts darbinieka profilā?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Katrs darbinieka profils TeamConnect ir izsmeļošs momentuzņēmums, kas piedāvā bagātīgu ieskatu indivīda profesionālajā dzīvē. 
                  Lūk, ko jūs tur atradīsiet:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pamata detaļas:</Body1Strong> Vārds, amata nosaukums, departaments un filiāles atrašanās vieta.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ziņošanas struktūra:</Body1Strong> Informācija par tiešiem un funkcionālajiem ziņojumiem, 
                      ieskaitot līnijas un funkciju vadītājus.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Darba statuss:</Body1Strong> Pašreizējais darba statuss, piemēram, Atvaļinājumā, Ceļā, 
                      Komandējumā uc.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Darba vieta un vietējais laiks:</Body1Strong> Darbinieka pašreizējā darba vieta un 
                      vietējā laika zona.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kontaktinformācija:</Body1Strong> Darba tālrunis, mobilais tālrunis un e-pasta adrese.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Biroja adrese:</Body1Strong> Pilna biroja atrašanās vieta, ieskaitot ēkas nosaukumu,
                      adresi un Google karti.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Par:</Body1Strong> Īss personīgais ievads vai profesionālais apraksts.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Darbinieku saistības:</Body1Strong> Informācija par darbinieka ziņošanas 
                      struktūru un komandas attiecībām.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sociālie saites un valodas:</Body1Strong> Saites uz profesionālajiem sociālajiem profilēm 
                      un valodu pratējiem.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Prasmes:</Body1Strong> Profesionālo prasmju un ekspertīzes jomu saraksts.</li>
                  </ul>
                  Turklāt profils piedāvā rīcībā izmantojamas iespējas sazināties caur tērzēšanu, e-pastu, videozvanu vai sapulču plānošanu, kā arī 
                  ātru piekļuvi organizācijas diagrammai plašākam komandas kontekstam.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Kā es varu atjaunināt savas profila informāciju?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Profila personalizēšana TeamConnect ir vienkārša. Lūk, kā to varat izdarīt:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Dodieties uz <Body1Strong>Darbinieks</Body1Strong> cilni un klikšķiniet uz sava <Body1Strong>Avatāra</Body1Strong> 
                        lai atvērtu savu profilu.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Augšējā kreisajā stūrī atradīsiet saiti <Body1Strong>"Rediģēt savu profilu"</Body1Strong>. 
                        Klikšķinot šeit, tiks atvērts rediģēšanas dialogs jūsu profilam.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Šeit varat uzlabot savu profilu, pievienojot vai atjauninot savu <Body1Strong>Par Jums</Body1Strong> sadaļu, 
                        sociālo tīklu saites un tālruņa numuru. Tas atvieglos jūsu kolēģiem ar jums sazināties vai sekot jums.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Neaizmirstiet nospiediet <Body1Strong>Saglabāt</Body1Strong> pogu, lai piemērotu jūsu izmaiņas.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Ritiniet dialogā uz leju, lai pievienotu jūsu <Body1Strong>Valodas</Body1Strong> un <Body1Strong>Prasmes</Body1Strong>, 
                        kā arī <Body1Strong>Prasmes</Body1Strong>. Šīs informācijas dalīšana palīdz kolēģiem saprast 
                        jūsu specializācijas jomas un valodas, kādās jūs varat sazināties.
                      </li>
                    </ol>
                  Profila atjaunošana ne tikai uztur jūsu informāciju aktuālu, bet arī veicina labāku savstarpējo saikni jūsu organizācijā.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Atjaunojot jūsu darba vietu, darba vietu un laika joslu TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Savas darbavieta, darba vietas un laika joslas informācijas aktualizēšana ir būtiska nevainojamai sadarbībai Microsoft Teams. 
                  Šie atjauninājumi nekavējoties tiek atspoguļoti jūsu TeamConnect profilā un sarakstos, ļaujot kolēģiem ātri saprast jūsu 
                  darba vietu un labākos laikus saziņai ar jums, uzlabojot sadarbību starp dažādiem reģioniem un laika zonām.
                  <p></p>
                  Gatavs atjaunināt savu informāciju? Tas ir vienkārši TeamConnect! Dodieties uz 'Iestatījumi' augšējā izvēlnē un 
                  navigācijā uz 'Vispārīgie iestatījumi'. Šeit jūs varat vienkārši atjaunot savu Darbavietu un Laika joslu, 
                  un norādīt savu Darba vietu dienai. Neaizmirstiet saglabāt savas izmaiņas, lai visi būtu informēti un nodrošinātu efektīvu 
                  sadarbību neatkarīgi no jūsu atrašanās vietas!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Vai pamanījāt kaut ko nepareizu kolēģa profilā?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ja pamanāt kādu informāciju kolēģa profilā, kas šķiet nepareiza, labākais pirmais solis ir viņam draudzīgi 
                  paziņot. Ātra tērzēšana vai e-pasts darīs triku! Ja kādu iemeslu dēļ informācija netiek atjaunota, neuztraucieties—tikai 
                  sūtiet ziņu jūsu personāla atbalsta komandai <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, un viņi būs priecīgi 
                  iegriesties un izlabot lietas.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Iepazīšanās ar birkas grupām: Visi, Favorīti, Iereģistrētie un Jauni darbinieki
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Visi:</Body1Strong> Šī noklusējuma birka attēlo katru darbinieku jūsu organizācijā,
                      nodrošinot visaptverošu komandas skatu.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favorīti:</Body1Strong> Klikšķiniet šeit, lai filtrētu skatu uz tiem kolēģiem, kurus esat atzīmējuši
                      kā 'Favorītus', padarot ātrāk savienojamu ar galvenajiem kontaktiem.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Iereģistrētie:</Body1Strong> Šī birka norāda darbiniekus, kas ir pašlaik darbā, atšķirībā
                      no vienkāršas tiešsaistes klātbūtnes Microsoft Teams. Reģistrējoties, darbinieki signalizē, ka viņi ir darbā un dienas laikā dalās ar savu pašreizējo
                      darba vietu. Tas var iekļaut dažādas darba vietas, piemēram, Birojs, Lauka birojs, Mājas birojs, Attālināta darba vieta, Klienta darba vieta,
                      Klienta privātais birojs, Publiska zona, Ceļojums, Darījumu ceļojums vai pat Apmācība. Tādējādi 'Iereģistrētie' statuss sniedz skaidru norādi
                      par aktīvu darbību darbā, piedāvājot kolēģiem precīzu izpratni par katras citas pašreizējo darba kontekstu un pieejamību.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Jauni darbinieki:</Body1Strong> Ievērojiet jaunas sejas TeamConnect ar šo birku, kas izceļ
                      darbiniekus, kuri ir pievienoti lietotnei pēdējo 30 dienu laikā. Parasti tas saskan ar neseniem pievienojumiem jūsu organizācijai, palīdzot
                      sveikt jaunos komandas biedrus.
                    </li>
                  </ul>
                  Šīs birku grupas ir izstrādātas, lai vienkāršotu navigāciju un uzlabotu jūsu TeamConnect pieredzi, nodrošinot, ka vienmēr varat
                    atrast un efektīvi savienoties ar kolēģiem.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Kā atšifrēt ikonas blakus meklēšanas logam
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect Darbinieku direktorija ir paredzēta universālai un vienkāršai lietošanai, tajā tiek piedāvātas četras ikonas, kas piedāvā dažādus skatus
                  un ātru piekļuvi galvenajai informācijai. Lūk, ko katrs piktogramma atklāj:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Saraksta skats:</Body1Strong> Jūsu iecienītākais skats, kurā visi organizācijas dalībnieki tiek
                      prezentēti vienkāršā sarakstā.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Mazās kartītes skats:</Body1Strong> Kompakts variants, šis skats pārveido
                      sarakstu mazizmēra darbinieku kartītēs.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lielās kartītes skats:</Body1Strong> Izvēlēties detalizētāku informāciju ar lielākām kartītēm, kas ietver
                      papildu saziņas opcijas, piemēram, tālruņa un mobilā telefona numurus vieglai zvanīšanai.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organizācijas shēma:</Body1Strong> Vizualizējiet uzņēmuma struktūru ar Organizācijas shēmas skatu,
                      kas ir ideāli piemērots, lai saprastu ziņošanas attiecības un pārlūkotu organizācijas ainavu.</li>
                  </ol>
                    Pārslēdzieties starp šīm ikonām, lai atrastu skatu, kas vislabāk atbilst jūsu vajadzībām šajā brīdī, vai nu meklējot ātru informāciju vai dziļākas izpratnes.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Kur ātri meklēt kolēģi?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Meklē kolēģi? Dodieties uz TeamConnect meklēšanas lodziņu, kas atrodas augšējā labajā pusē blakus Organizācijas shēmas ikonai.
                  Sāciet ievadīt viņa vārdu, un vērojiet, kā rezultāti tiek precizēti ar katru ierakstīto burtu. Atceries, meklējot TeamConnect, izlaidiet Microsoft Teams 
                  meklēšanas joslu augšpusē — mūsu īpašais meklēšanas logs ir īstā izvēle!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Kā alfabētiskā meklēšana darbojas, lai atrastu darbiniekus?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect's Employee Directory ietver ērtu alfabētisko meklēšanas funkciju, lai palīdzētu jums ātri atrast darbiniekus pēc viņu vārda. 
                  Vienkārši noklikšķiniet uz burtu alfabētā, kas atrodas direktorija augšdaļā, un TeamConnect nekavējoties parādīs visus darbiniekus, 
                  kuru vārdi sākas ar šo burtu. Lai atgrieztos pie pilnīga darbinieku saraksta, vienkārši noņemiet izcelto burtu. Šī intuitīvā 
                  funkcija padara lielu darbinieku sarakstu pārlūkošanu vieglu, nodrošinot, ka jūs varat ātri un efektīvi atrast kolēģus.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Kā es varu pielāgot attēloto darbinieku skaitu lapā?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect ļauj jums pielāgot, cik darbinieku profilus jūs redzat lapā, padarot vieglāku navigāciju caur Darbinieku direktoriju. 
                  Katras lapas apakšā jūs atradīsiet lapas numerācijas opcijas: 10, 50, 100, 500, 1000 un 5000. Noklusējuma iestatījums parāda 10 profilus lapā. 
                  Lai redzētu vairāk profilu vienā lapā, vienkārši izvēlieties vēlamo skaitu no šīm opcijām. Šī funkcija nodrošina elastību, kā jūs pārlūkojat 
                  direktoriju, ļaujot jums pielāgot displeju atbilstoši jūsu konkrētajām vajadzībām vai preferences.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Kā es varu kārtot darbinieku informāciju Saraksta Skatā?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect's Darbinieku Direktorija Saraksta Skatā jums ir elastība kārtot darbinieku informāciju atbilstoši jūsu vajadzībām. Kolonnas—Darbinieka Vārds, 
                  Amats, Departaments, Biroja Atrašanās vieta un Darbavieta—ir visi kārtojami. Vienkārši noklikšķiniet uz kolonnas galvenes, pēc kuras vēlaties kārtot. Klikšķināšana 
                  vienreiz kārtos kolonnu augošā secībā, un klikšķināšana vēlreiz – dilstošā secībā. Pēc noklusējuma direktorija tiek kārtota pēc Darbinieka Vārda augošā secībā, 
                  balstoties uz vārdu. Šī kārtošanas funkcija palīdz jums ātri organizēt un apskatīt darbinieku detaļas veidā, kas vislabāk kalpo jūsu mērķim, vai jūs meklējat 
                  konkrētu personu vai vēlaties grupēt darbiniekus pēc konkrēta kritērija, piemēram, departamenta vai atrašanās vietas.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Nodaļu direktorija biežāk uzdotie jautājumi</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Nodaļu direktorijas ievads:</Body1Strong> Nodaļu direktorija ir jūsu ceļvedis organizācijas struktūras sarežģītajos labirintos, nodrošinot skaidru 
              ieskatu nodaļu hierarhijās, vadības līderībā un iespējām nevainojamai nodaļu komunikācijai.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Kā darbojas birku 'Visas', 'Izlase' un 'Jūsu nodaļa' izmantošana Nodaļu direktorijā?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  In TeamConnect's Nodaļu direktorijā, birkas palīdz ātri navigēt un filtrēt caur nodaļas informāciju. Lūk, ko katrs no šiem birku veidiem pārstāv:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Visas:</Body1Strong> Šī birka rāda visas organizācijas nodaļas, sniedzot izsmeļošu 
                      pārskatu.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Izlase:</Body1Strong> Izmantojiet šo birku, lai skatītu speciāli atlasīto nodaļu sarakstu, kuru esat 
                      atzīmējuši kā 'Izlase', atvieglojot biežāk apmeklēto nodaļu piekļuvi.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Jūsu nodaļa:</Body1Strong> Šī birka filtrē skatu, lai rādītu tikai jūsu nodaļu, 
                      ļaujot ātri piekļūt tiešai nodaļas informācijai un atjauninājumiem.</li>
                  </ul>
                  Šīs birkas ir izstrādātas, lai padarītu jūsu navigāciju Nodaļu direktorijā vienkāršāku, uzlabojot jūsu spēju efektīvi atrast un iesaistīt ar 
                  nodaļas informāciju.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Izpratne par Nodaļu direktorijas skatījumiem TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigējot caur Nodaļu direktoriju TeamConnect, ir pieejamas dažādas skatīšanās iespējas, kas atbilst jūsu preferences. Katrs skats piedāvā 
                  unikālu veidu, kā pētīt izsmeļošu nodaļu informāciju, tostarp Nodaļas nosaukumu, Nodaļas vadītāju, Galveno nodaļu, Nodaļas tālruni, 
                  Nodaļas karsto līniju un Nodaļas e-pastu. Lūk, pieejamo skatu apskats:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Skatu Saraksts:</Body1Strong> Noklusējuma iestatījums, kas kārtīgi uzskaita visas nodaļas, nodrošinot 
                      skaidru, saistošu ieskatu.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lielo Karšu Skats:</Body1Strong> Pārslēdzieties uz šo skatu, lai redzētu detalizētas nodaļu kartes, piedāvājot 
                      paplašinātu kopsavilkumu par katras nodaļas galveno informāciju.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organizācijas diagrammas skats:</Body1Strong> Lai vizuāli attēlotu nodaļu struktūru un 
                      sakarus, Organizācijas diagrammas skats parāda, kā katra nodaļa iederas plašākajā organizācijas hierarhijā.
                    </li>
                  </ul>
                  Lai pārslēgtos starp šiem skatiem, vienkārši izmantojiet ikonas blakus meklēšanas lodziņam Nodaļu direktorijas augšdaļā. Katrs skats ir izveidots, 
                  lai jums palīdzētu piekļūt un saprast nodaļas detaļas viegli, nodrošinot, ka jūs atradīsit nepieciešamo informāciju jums vispiemērotākajā formātā.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Kā lietot meklēšanas lodziņu Nodaļu direktorijā
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Viegli atrast jebkuru nodaļu TeamConnect, izmantojot īpaši izveidotu meklēšanas lodziņu, kas atrodas tieši blakus skatīšanas ikonām direktorija augšdaļā. 
                  Vienkārši sāciet rakstīt meklējamās nodaļas nosaukumu, un vērojiet, kā direktorijs tiek precizēts, lai parādītu tikai atbilstošus rezultātus. Šī funkcija 
                  ir īpaši izstrādāta, lai jums nebūtu manuāli jāmeklē visā direktorijā, ļaujot vajadzīgo nodaļu ātri atrast un parādīt tieši jūsu pirkstu galiem. Atcerieties, 
                  ka šī meklēšanas funkcionalitāte ir unikāla TeamConnect Nodaļu direktorijai, tāpēc izmantojiet šo meklēšanas lodziņu, nevis galveno Microsoft Teams meklēšanu 
                  pašā Teams interfeisa augšpusē, ja nepieciešams veikt nodaļas specifiskus vaicājumus.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Kā darbojas alfabētiskais filtrs Nodaļu direktorijā?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Efektīvi vienkāršojiet meklēšanu Nodaļu direktorijā, izmantojot alfabētisko filtru, kas atrodas augšpusē. Šī funkcija ļauj ātri sašaurināt nodaļas, 
                  atlasot burtu. Izvēloties burtu, TeamConnect filtrē un parāda tikai tās nodaļas, kuru nosaukumi sākas ar jūsu izvēlēto burtu, padarot meklēšanu 
                  vienkāršāku bez nepieciešamības pārlūkot visu sarakstu. Lai atgrieztos pie pilnas nodaļas skata, vienkārši noņemiet izcelto burtu. Šī intuitīvā alfabētiskā 
                  navigācija nodrošina, ka jūs varat efektīvi atrast jebkuru nodaļu pēc tās sākumburta.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Kā es varu sazināties ar nodaļas vadītāju?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Sazināties ar nodaļas vadītāju TeamConnect ir vienkārši. Vienkārši atrodiet interesējošo nodaļu un noklikšķiniet uz vadītāja vārda. Šī darbība izsauks 
                  uznirstošo logu, kurā tiks parādītas vadītāja saziņas iespējas ar pazīstamajām ātrās piekļuves ikonām:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tērzēšanas ikona:</Body1Strong> Lai uzsāktu tūlītēju ziņojumu caur Microsoft Teams tērzēšanu.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>E-pasta ikona:</Body1Strong> Lai atvērtu jaunu e-pastu, kas adresēts viņiem Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Video zvana ikona:</Body1Strong> Lai uzsāktu video zvanu caur Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Zvana ikona:</Body1Strong> Lai uzsāktu balss zvanu, izmantojot Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vairāk (...) ikona:</Body1Strong> Papildu opcijām, piemēram, tikšanās plānošanai vai 
                      to kontaktinformācijas pievienošanai jūsu Teams sarakstam.
                    </li>
                  </ul>
                  Šīs ikonas nodrošina vienkāršu metodi, kā tieši no TeamConnect sazināties ar nodaļas vadītājiem, nodrošinot efektīvu komunikāciju jūsu organizācijā.
                </Body1>
              </AccordionPanel>
            </AccordionItem>           
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Kā es varu apskatīt vai sazināties ar darbiniekiem konkrētā nodaļā?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Lai pētītu, kas ir kas konkrētā nodaļā TeamConnect, meklējiet tālāk par trim punktiem blakus nodaļas nosaukumam. Uzklikšķinot uz tā, 
                  tiks parādītas iespējas turpmākai rīcībai:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Apskatīt darbiniekus šajā nodaļā:</Body1Strong> Izvēlieties šo, lai parādītu visu 
                      ar nodaļu saistīto darbinieku sarakstu. Skaits blakus šai opcijai norāda, cik darbinieki atrodas nodaļā, nodrošinot ātru dalībnieku skaitu.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nodaļas organizatoriskā shēma:</Body1Strong> Ja vēlaties saprast nodaļas hierarhiju, 
                      izvēlieties 'Nodaļas organizatoriskā shēma', lai atklātu strukturālo izkārtojumu, demonstrējot attiecības starp mātesnodaļām, attiecīgo nodaļu 
                      un jebkādām apakšnodaļām. Šī vizuālā attēlojums palīdz jums orientēties un saprast organizatorisko struktūru, padarot vieglāku konkrētu 
                      nodaļas locekļu identificēšanu un sazināšanos.
                    </li>
                    <li><Body1Strong>Nodaļa {'>'} Darbinieks:</Body1Strong> Pēc darbinieku apskatīšanas nodaļā, izmantojiet šo navigācijas ceļu, lai viegli 
                      atgrieztos pie nodaļu saraksta, uzturot vienmērīgu pētījuma pieredzi TeamConnect ietvaros.
                    </li>
                  </ul>
                  Šīs funkcijas ļauj jums ne tikai apskatīt darbinieku sarakstu konkrētā nodaļā, bet arī piedāvā ceļu, kā tieši sazināties ar viņiem, izmantojot 
                  TeamConnect komunikācijas rīkus.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Iedziļināšanās nodaļas detaļās TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ienirstiet jebkuras nodaļas detaļās ar vienu klikšķi uz tās nosaukuma TeamConnect. Atvērsies detalizēts dialogs, piedāvājot bagātīgu informācijas 
                  klāstu, kas izklāsta pilnu nodaļas lomu un resursus jūsu organizācijā. Jūs atradīsiet visu, sākot no vadības un finanšu identifikatoriem līdz 
                  komunikācijas kanāliem un sociālajām saitēm. Šī funkcija ir jūsu ceļvedis uz:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Nodaļas sirdspukstu – tās mērķi, cilvēkiem un veiktspējas rādītājiem.</li>
                    <li className={styles.bulletBottomMargin}>Saziņas ceļiem – tiešām līnijām uz galvenajām nodaļas personām un komunikācijas platformām.</li>
                    <li className={styles.bulletBottomMargin}>Organizatoriskais tīkls – sapratne, kā nodaļa iekļaujas plašākā uzņēmuma struktūrā.</li>
                  </ul>
                  Vai tas ir ātrs jautājums vai dziļš iedziļināšanās nodaļas datos, TeamConnect jums piedāvā rīkus saziņai un unikālo ekosistēmu izpratnes gūšanai jūsu organizācijā.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Vai jūs interesē nodaļas adreses trūkums TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nodaļas atrašanās vietas meklēšana reizēm var šķist kā dārgumu medības bez kartes. TeamConnect parasti neatradīsiet atvēļotu adresi katrai nodaļai. Lūk, kāpēc šis kartogrāfiskais detaļa bieži tiek atstāta ārā:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Daudzvietīgas atrašanās vietas:</Body1Strong> Daudzas nodaļas nav ierobežotas vienā vietā; tās 
                      plaukst dažādos birojos un ģeogrāfijās.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Plūstoša struktūra:</Body1Strong> Organizatoriskā ainava pastāvīgi mainās, ar 
                      nodaļām, kas attīstās, apvienojas vai pat izšķīst, saskaņojot ar stratēģiskām izmaiņām.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Starpposma sadarbība:</Body1Strong> Mūsdienu darbvietās valda sadarbības gars, bieži apvienojot 
                      dažādu nodaļu talantus kopīgās telpās.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Attālinātā darba kultūra:</Body1Strong> Ar attālinātā darba elastību nodaļu pamatnes nav vienmēr 
                      novietotas fiziskās biroju vietās.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Tiesiskās nianses:</Body1Strong> Nodaļas parasti orbitē ap uzņēmuma tiesisko struktūru, nevis 
                      ir ar savām neatkarīgajām adresēm.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Operacionālā vienkāršošana:</Body1Strong> Uzstādot fokusu uz dinamisku komandu darbu, nevis 
                      statiskām adresēm, tiek saglabāta operacionālā veiklība.
                    </li>
                  </ul>
                  Viss ir par robežu neklātienē uzņēmējdarbību un efektivitāti. TeamConnect atspoguļo šo filozofiju, uzsverot saiknes nevis vietas.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Vai jūsu departamenta informācija ir trūkstoša vai novecojusi?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Departamentu redaktori ir noteikti jūsu organizācijā, lai pievienotu, atjauninātu vai labotu departamenta specifisko informāciju TeamConnect.
                  Ja neesat pārliecināts, kas ir jūsu departamenta redaktors vai ja konkrēta informācija prasa personāla atbalsta komandas iejaukšanos,
                  lūdzu, sazinieties ar <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> pēc vadlīnijām.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Biežāk uzdotie jautājumi par biroju direktoriju</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Ievads biroju direktorijā:</Body1Strong> Iepazīstiet sava organizācijas fiziskās klātbūtnes pamatu ar biroju direktoriju,
                kas ietver būtiskas biroja detaļas, operacionālos nianses un plašu apskatu par departamentiem un personālu, kas raksturo katru atrašanās vietu.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Oriģinālo atzīmju pārlūkošana biroju direktorijā
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Atrodiet vienkāršā veidā savu ceļu apkārt biroju direktorijā, izmantojot mūsu atzīmju sistēmu, kas paredzēta ātrai piekļuvei pareizajai vietai. Lūk, kā katras atzīmes var palīdzēt vienkāršot jūsu meklēšanu:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Visi:</Body1Strong> Parāda pilnu biroju sarakstu, sniedzot skatupunktu uz
                      jūsu organizācijas atrašanās vietām.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favorīti:</Body1Strong> Palīdz jums koncentrēties uz tiem biroju atrašanās vietām, kurus bieži apmeklējat vai nepieciešams 
                      vērot, atzīmējot tās kā "Favorītus".
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Jūsu birojs:</Body1Strong> Šī personīgā atzīme filtrē direktoriju, lai jums parādītu 
                      biroju atrašanās vietu, kur jūs esat novietots, saglabājot jūsu bāzi jūsu pirkstu galiem.
                    </li>
                  </ul>
                  Neatkarīgi no tā, vai jūs rezervējat sapulces telpu vai plānojat apmeklējumu, šīs atzīmes piedāvā personalizētu un vienkāršotu veidu, kā sadarboties ar jūsu organizācijas 
                  ģeogrāfisko ainavu.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Biroju kataloga skatījumu apguve TeamConnect vidē
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Iedziļinieties savas uzņēmuma dažādo atrašanās vietu organizatoriskajā izkārtojumā ar TeamConnect biroju kataloga skatījumiem. Katrs skatījums ir veidots, lai veicinātu
                  unikālu biroja apmeklējuma un izpratnes aspektu:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Saraksta Skats:</Body1Strong> Noklusējuma iestatījums, kas ērti sakārto visas biroju atrašanās vietas
                      viegli lasāmā sarakstā.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lielā Kartiņa Skats:</Body1Strong> Lielā Kartiņa Skats biroja katalogā bagātina jūsu
                      skatījumu ar detalizētu katra biroja prezentāciju. Sazinoties ar biroja kartiņu, jūs atradīsiet daudz svarīgas informācijas un darbības iespējas
                      izvēlnē "Vairāk (...)":
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Skatīt Nodaļas:</Body1Strong> Tieši pāriet uz sarakstu
                          ar visām nodaļām, kas atrodas birojā.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Skatīt Darbiniekus:</Body1Strong> Iegūt pieeju pilnam darbinieku sarakstam, kas piešķirti 
                          birojam, veicinot tiešu kontaktu vai biroja personāla izpēti.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Uzņēmuma Organizācijas Shēma:</Body1Strong> Vizualizēt biroja vietu lielākā 
                          organizatoriskajā kontekstā, lai skaidri saprastu hierarhiskās struktūras.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Svarīgi Kontaktpersonas:</Body1Strong> Identificēt galvenās biroja kontaktpersonas ātrai 
                          komunikācijai.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Biroja Oficiālās Brīvdienas:</Body1Strong> Uzzināt par biroja specifiskajām 
                          oficiālajām brīvdienām, lai plānotu savu grafiku atbilstoši.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Biroja Darba Laiks:</Body1Strong> Skatīt biroja darbības stundas, lai saskaņotu jūsu
                          apmeklējumus vai zvanus ar tos aktīvajiem laikiem.
                        </li>
                      </ol>
                      Šis skatījums nodrošina ne tikai vizuālu un informācijas momentuzņēmumu, bet arī ātru pieeju dziļākai izpratnei un tiešām darbībām, kas saistītas ar biroju, visi kārtīgi sakārtoti "Vairāk (...)" izvēlnē jūsu ērtībai.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organizācijas Shēmas Skats:</Body1Strong> Vizualizēt virsbūves un apakšbūves biroju hierarhisko struktūru,
                      lai saprastu dažādo biroju savstarpējo saistību jūsu organizācijā.
                    </li>
                  </ul>
                  Pārslēdzieties starp šiem skatījumiem, izmantojot ikonas blakus meklēšanas lodziņam, lai pielāgotu sava pārlūkošanas pieredzi jūsu tūlītējām vajadzībām.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Biroju ātrā atrašana, izmantojot TeamConnect meklēšanas funkciju
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Pareizās biroju atrašanās vietas ātra noteikšana TeamConnect ir vienkāršota ar veltītu meklēšanas funkciju. Prominenti novietota blakus skatījumu ikonām, meklēšanas lodziņš ir jūsu rīks biroju precīzai lokalizēšanai pēc nosaukuma. Rakstot, katalogs precizē displeju, lai parādītu tikai jūsu ievades atbilstošos birojus, efektivizējot jūsu meklēšanas procesu. Ir svarīgi izmantot šo meklēšanas lodziņu TeamConnect ietvaros, lai iegūtu visprecīzākos un atbilstošākos rezultātus, nevis izmantojot vispārīgo Microsoft Teams meklēšanu. Ar šo mērķtiecīgo meklēšanas funkcionalitāti jūs varat ātri pārvietoties uz jebkuru biroju jūsu organizācijas tīklā.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Alfabētiskā biroju direktorija pārlūkošana
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ja dodat priekšroku meklēšanai pēc alfabēta, mūsu alfabētiskais filtrs ir ātrs veids, kā atrast birojus pēc nosaukuma. Biroju direktorijas augšdaļā
                  redzēsiet burtus no A līdz Z. Uzklikšķinot uz burta, skats tiek filtrēts, lai rādītu tikai tos birojus, kuru nosaukums sākas ar šo burtu, vienkāršojot 
                  jūsu meklēšanas procesu. Lai atiestatītu un atkal redzētu visus birojus, vienkārši noņemiet aktīvo burtu. Šī funkcija ir īpaši noderīga, ja jūs 
                  zināt biroja nosaukumu, bet nepieciešams ātri to atrast TeamConnect ietvaros.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Izvērstu biroju profilu atklāšana TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Dodies izpētes ceļojumā cauri jūsu organizācijas fiziskās klātbūtnes centrām ar TeamConnect biroju profiliem. 
                  Uzklikšķinot uz biroja nosaukuma, parādās krāsaina profila dialoglodziņš, kas pilns ar cilnēm, kurās atrodama detalizēta informācija par biroju:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pārskats:</Body1Strong> Iedziļinieties biroja identitātes kodolā ar detalizētiem 
                      aprakstiem, sākot no uzņēmuma struktūras līdz darba laikiem un vietējam laikam, atspoguļojot biroja vides būtību.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Svarīgi kontakti:</Body1Strong> Piekļūstiet būtiskiem kontaktiem avārijas pakalpojumiem un 
                      ārējai atbalstam, katrs pieejams ar vienu klikšķi, izmantojot Microsoft Teams Phone System.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Biroja kontakti:</Body1Strong> Viegli sazinieties ar iekšējiem atbalsta dienestiem, 
                      ar kontaktinformāciju un tiešās iesaistes iespējām tieši jums pa rokai.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Valsts svētki:</Body1Strong> Esiet informēti par biroja specifiskajiem brīvdieniem, nodrošinot, 
                      ka jūsu plāni saskan ar vietējām atzīmēm un darba grafikiem.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Darba laiki:</Body1Strong> Plānojiet savas mijiedarbības ar precīzu zināšanu par laiku, 
                      kad birojs ir pilns ar darbību vai mierīgi noslēdz dienu.
                    </li>
                  </ul>
                  Vai tas ir Austinas rosīgais enerģijas straume vai stratēģiskais Singapūras mezgls, katrs cilnes piedāvā vārtus, lai saprastu un sazinātos ar dažādām 
                  vietām, kas veido jūsu uzņēmuma globālo klātbūtni.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Iedziļinoties biroja nodaļu dinamikā
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Profesionālā tīkla izpēte biroja nodaļās norisinās vienkārši, izmantojot TeamConnect. Lūk, kā iedziļināties sīkāk:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Apskatīt nodaļas šajā birojā:</Body1Strong> Atklājiet visas nodaļas, kuras veido biroja 
                      struktūru, izvēloties šo opciju no izvēlnes Vairāk (...).
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organizācijas izziņa:</Body1Strong> Noklikšķiniet uz jebkuras nodaļas, lai atklātu tās organizācijas shēmu  
                      vizuālai struktūras reprezentācijai, kur norādītājs ceļš jums palīdzēs: Birojs {'>'} Nodaļa {'>'} Shēma.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nodaļas detalizēta izpēte:</Body1Strong> Vēl vairāk izpētiet nodaļu, noklikšķinot uz tās 
                      izvēlnes Vairāk (...) blakus nodaļas nosaukumam. Šeit varat piekļūt visu šīs nodaļas darbinieku sarakstam tieši biroja kontekstā.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Maizes drupatu navigācija:</Body1Strong> Pārvietojieties cauri līmeņiem bez piepūles. No 
                      darbiniekiem atpakaļ uz nodaļām vai no organizācijas shēmas, maizes drupatu ceļš (Birojs {'>'} Nodaļa {'>'} Darbinieks {'>'} Shēma) saglabā jūsu 
                      ceļojumu intuitīvu un uz pareizā kursa.
                    </li>
                  </ul>
                  TeamConnect sarežģītie maršruti aicina jūs izpētīt, saprast un iesaistīties katrā jūsu biroja nodaļas slānī, piešķirot jums izsmeļošu navigācijas 
                  kontrolēšanu.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Saziņa ar biroja personālu TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Interesē, kas ir biroja cilvēki? Atbilde ir tikai viena klikšķa attālumā TeamConnect. Iesaistieties, noklikšķinot uz trīs punktiņiem blakus jūsu izvēlētajam 
                  biroja nosaukumam, lai atklātu interaktīvu opciju klāstu:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Apskatīt darbiniekus šajā birojā:</Body1Strong> Atklājiet visus profesionāļus,
                      kuri sauc šo biroju par savu darba vietu. Parādītais skaitlis ātri sniedz darbinieku skaitu, mudinot jūs iesaistīties biroja kopienā.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Saziņas iespējas:</Body1Strong> Katrs darbinieka ieraksts ir aprīkots ar ātras darbības ikonām,
                      ļaujot jums uzsākt tērzēšanu, zvanus vai pat video konferences tieši no TeamConnect, veicinot nepārtrauktu iekšējo biroja komunikāciju.
                    </li>
                    <li><Body1Strong>Navigācija:</Body1Strong> Lai atgrieztos pie plašākā biroju saraksta no darbinieku saraksta, vienkārši noklikšķiniet uz 'Birojs' 
                      maizes drupatu ceļā (Birojs {'>'} Darbinieks), un jūs tiksiet atgriezts pie biroja pārskata.
                    </li>
                  </ul>
                  Ar šīm funkcijām TeamConnect padara darbinieku atrašanu pāri birojiem vienkāršu, un ļauj viegli sazināties, 
                  veicinot savienotu darbavietu neatkarīgi no attāluma.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Vai jūsu biroja informācija ir trūkstoša vai novecojusi?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Biroja informācijas atjauninājumus TeamConnect var veikt piešķirtie biroja redaktori. Ja neesat pārliecināts par biroja redaktoriem vai 
                  nepieciešama palīdzība saistībā ar informāciju, kas atrodas Personāla vadības kompetencē, Personāla atbalsta komanda ir gatava 
                  palīdzēt <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Iestatījumu BUJ</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Precīzu Vispārējo iestatījumu nozīme TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Mūsdienu dinamiskajā darba vidē savstarpēja saziņa un koordinācija ir svarīgāka nekā jebkad agrāk. Lūk, kur aktualizācija 
                  jūsu Vispārējos Iestatījumos TeamConnect ieņem būtisku lomu:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Darba vietas izvēle:</Body1Strong> Atspoguļojiet sava darba vides būtību, atjauninot jūsu Darba vietu, lai tas atbilstu jūsu 
                        pašreizējai situācijai, vai tā būtu pāreja no mājas biroja mierīguma uz galvenā biroja burzmas, vai iekārtošanās Publiskā Zonā. 
                        Šī skaidrība par darba vietu sniedz kolēģiem ieskatu ne tikai par labākajiem veidiem, kā jums piezvanīt, bet arī par sarunu raksturu, 
                        kuras varat iesaistīties, aizsargājot konfidenciāla satura apspriešanu īpaši, kad atrodaties atklātā klienta biroja vidē vai sēžat lidostas zālē.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Darba vietas specifikācija:</Body1Strong> Jūsu darba pilsētas dalīšana dara vairāk nekā tikai pārvar attālumus attālinātā komandas 
                        iestatījumā—tā arī veicina spontānus profesionāļus tikšanās vai neformālas socializēšanās iespējas. Vai tas būtu ātrs kafijas pauze, izklaidējoša pusdienas 
                        laiks vai sadarbības sanāksme, zināšana, ka atrodaties tajā pašā pilsētā, var pārvērst parastu dienu iespējā veidot nozīmīgas aci pret aci 
                        saiknes. Tas ne tikai stiprina komandas saiknes, bet arī sniedz dziļāku izpratni par vietējiem nišām, kas var ietekmēt jūsu darbu.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Laika joslas precizitāte:</Body1Strong> Kā komandas pārklājas kontinentos, svarīgi ir nodrošināt, ka 
                      jūsu laika josla atspoguļo jūsu pašreizējo atrašanās vietu, lai sinhronizētu saziņu. Jūsu TeamConnect profila Vietējais Laiks tieši atkarīgs no šī 
                      iestatījuma, novēršot visas minēšanas nepieciešamību kolēģiem, kuri mēģina jums sasniegt.
                    </li>
                  </ul>
                  Ielūkojieties 'Iestatījumi' {'>'} 'Vispārējie Iestatījumi' TeamConnect, lai precizētu šīs detaļas. Dažas brīži, kas pavadīti atjauninot jūsu iestatījumus, var 
                  būtiski uzlabot jūsu sadarbības pūles efektivitāti un vienotību visā.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. Darba vides izvēles atšifrēšana TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Pareizas iestatījuma izvēle darba vides nolaižamajā sarakstā pielāgo TeamConnect, lai precīzi atspoguļotu jūsu pašreizējo darba statusu. Virziet kursoru virs informācijas ikonas, lai iegūtu detalizētu skaidrojumu par katru opciju:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Izrakstīts/Pārtraukums:</Body1Strong> Norāda laiku prom no darba, vai nu pārtraukuma laikā, vai dienas beigās.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Birojs/Lauka birojs:</Body1Strong> Norāda klātbūtni uzņēmuma pārvaldītā darbavietā.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Mājas birojs:</Body1Strong> Strādā no īpaši iekārtota mājas darbavieta.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Attālinātā darbavieta/Sabiedriskā vieta:</Body1Strong> Darbojas ārpus biroja vides, kas liecina par nepieciešamību pēc elastīgas komunikācijas.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Klienta darbavieta/Privāts birojs:</Body1Strong> Uz vietas pie klienta, ar piezīmi par privātuma līmeņiem sarunu sensitivitātei.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Pārvietošanās/Darījumu ceļojums/Apmācība:</Body1Strong> Ceļā vai iesaistīts noteiktās darba saistītās aktivitātēs ārpus biroja.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sabiedriskā brīvdiena/Atvaļinājums/Dažādi atvaļinājumi:</Body1Strong> Oficiāli brīvā laikā personisku, veselības vai svētku iemeslu dēļ.</li>
                  </ul>
                  Atbilstošas darbavietas iestatīšana nodrošina, ka jūsu komanda ir informēta par nepieciešamo kontekstu mijiedarbībām, respektējot pieejamību un privātumu.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Navigācija caur 'Reģistrēts' statusu TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  'Reģistrēts' birkas darbinieku direktorijā ir paredzēts, lai parādītu visus darbā esošos darbiniekus. Šī redzamība ir atkarīga no jūsu veiktās darbavietas izvēles:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Aktīvās darba vides:</Body1Strong> Izvēles Birojs, Lauka birojs, Mājas birojs, Attālinātā darbavieta, Sabiedriskā vieta, Klienta darbavieta, 
                    Klienta privātais birojs, Pārvietošanās, Darījumu ceļojums un Apmācība signalizē, ka esat iesaistīts darbā, padarot jūs ‘Reģistrētu’ un redzami aktīvu kolēģiem.
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Bezdarbības iestatījumi:</Body1Strong> Izvēloties Izrakstīts, Pārtraukums, Sabiedriskā brīvdiena, Atvaļinājums, Prombūtnē, Vecāku atvaļinājums vai Slimības atvaļinājums,
                      statusi noņem jūs no ‘Reģistrēto’ saraksta, nosakot skaidras gaidas par pieejamību un atbildes laikiem.
                  </li>
                  </ul>
                  Šī funkcionalitāte palīdz atšķirt darba periodus no personiskā laika, veicinot efektīvu komandas sadarbību un komunikāciju.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Datu pārvaldības un privātuma BUJ</Subtitle2>
          <Body1>
            <p>
              Mūsdienās digitālajā laikmetā ir būtiski saprast, kā tiek pārvaldīti jūsu dati. TeamConnect ir apņēmies nodrošināt pārredzamību un stingras datu aizsardzības prakses. 
              Zemāk jūs atradīsiet informāciju par to, kā mēs apstrādājam, glabājam un aizsargājam jūsu datus TeamConnect ietvaros, nodrošinot jūsu digitālās darbvietas drošību un atbilstību.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Kā TeamConnect aizsargā manus personīgos datus?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect ir apņēmies aizsargāt jūsu personisko informāciju ar visaptverošu pieeju datu drošībai:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Vienreizēja pierakstīšanās (SSO):</Body1Strong> Lai uzlabotu drošību un vienkāršotu lietotāja pieredzi, TeamConnect integrē automatizētu 
                      Vienreizējas pierakstīšanās (SSO) ar jūsu Microsoft 365 kontu, nodrošinot drošu un bezpiepūles piekļuves pārvaldību.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Šifrēšana:</Body1Strong> Mēs nodrošinām, ka visi dati TeamConnect ietvaros tiek šifrēti gan pārraidē, gan atpūtai, 
                      padarot tos nepieejamus neautorizētām personām.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Datu minimizēšana:</Body1Strong> Mūsu datu vākšana ir stingri ierobežota līdz tam, kas ir būtiski TeamConnect funkcionalitātei. 
                      Vienīgā lietotāja informācija, ko mēs glabājam, ir jūsu User Principal Name (UPN) no Azure Entra ID, kas var atgādināt jūsu uzņēmuma e-pasta adresi.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Ierobežota piekļuve:</Body1Strong> Piekļuve UPN ir ierobežota līdz jūsu organizācijas abonementa administratoram mūsu abonementa portālā, 
                      nodrošinot, ka sensitīvā informācija ir redzama tikai autorizētām personām.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Ātra datu dzēšana:</Body1Strong> TeamConnect ir strukturēts tā, lai nekavējoties dzēstu glabāto UPN trīs konkrētos apstākļos: 
                      ja abonements tiek atcelts abonementa administratora, ja lietotājs tiek izņemts no HR TeamConnect ietvaros, vai ja TeamConnect 
                      abonements izbeidzas. Tas nodrošina, ka jūsu personīgie dati netiek saglabāti ilgāk nekā nepieciešams vai noderīgs.
                    </li>                      
                  </ul>
                  <p>Izmantojot šos pasākumus, TeamConnect apņemas uzturēt augstākos datu aizsardzības standartus, nodrošinot, ka jūsu personiskā informācija paliek droša un konfidenciāla.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Vai jūs jautājat, kur atrodas jūsu dati, izmantojot TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect pārkāpj tradicionālo SaaS piedāvājumu robežas, nodrošinot, ka jūsu dati nekad nepārsniedz jūsu organizācijas robežas. 
                  Atšķirībā no parastās prakses glabāt datus ārējos mākoņdatu serveros, TeamConnect izdomīgi izmanto jūsu organizācijas 
                  Microsoft 365 SharePoint Online, lai turētu visus lietojumprogrammas datus. Šī inovatīvā pieeja ne tikai veicina datu suverenitāti, bet arī pastiprina drošību, 
                  perfekti saskanot ar mūsu nopietno apņemšanos aizsargāt jūsu privātumu un ievērot stingrus atbilstības standartus.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Vai mani dati tiks dalīti ar kādām trešajām pusēm?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nē, dati netiek dalīti ar trešajām pusēm! Cubeet ir nenogurstoši apņēmies aizsargāt jūsu datu privātumu un drošību. Mēs neizpaužam nevienu TeamConnect datu trešajām pusēm bez skaidras klientu piekrišanas. 
                  Savā drošajā vidē mēs glabājam tikai būtiskus identifikatorus, piemēram, Lietotāja Galvenais vārds (UPN), organizācijas nosaukums, īpašnieka nosaukums un īpašnieka ID, mūsu abonementu portālā. 
                  Šī informācija ir svarīga pakalpojumu nodrošināšanai un tiek aizsargāta ar mūsu stingrām privātuma politikām.
                  Esiet droši, mūsu datu pārvaldības prakses ir izstrādātas, lai nodrošinātu, ka jūsu dati paliek konfidenciāli, ar pārredzamību un kontroli stingri jūsu rokās.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Kā TeamConnect ievēro datu aizsardzības noteikumus, piemēram, GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect ir saskaņots ar datu aizsardzības noteikumiem, piemēram, GDPR, HIPAA, CCPA un citiem, izmantojot Microsoft 365 vides iedzimtās atbilstības funkcijas, 
                  kur tiek droši glabāti visi klientu dati. Atšķirībā no tradicionāliem SaaS modeļiem, TeamConnect neuzglabā nekādus klientu datus saviem serveros; tā vietā tas darbojas jūsu 
                  organizācijas Microsoft 365 vidē, ievērojot tās robustos drošības un atbilstības standartus. Šī pieeja nodrošina, ka jūsu datu pārvaldība ir saskaņota ar 
                  Microsoft 365 nodrošināto plašo atbilstības ietvaru, ievērojot GDPR, HIPAA veselības aprūpes informācijai, CCPA Kalifornijas iedzīvotājiem, 
                  un citus globālos datu aizsardzības noteikumus. Integrējoties bez problēmām ar jūsu esošo Microsoft 365 iestatījumu, TeamConnect ļauj jūsu organizācijai uzturēt 
                  pilnu kontroli pār jūsu datiem, nodrošinot atbilstību stingrākajiem datu aizsardzības likumiem un noteikumiem.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Kā TeamConnect apstrādā datus no Microsoft Teams čatiem, zvaniem, videozvaniem, Outlook e-pastiem un tālruņa sistēmas zvaniem?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect izmanto Microsoft Graph API, lai nodrošinātu tiešas mijiedarbības, piemēram, čatu, zvanus, videozvanus un Outlook e-pastu Microsoft Teams ietvaros, 
                  nodrošinot vienmērīgu lietotāja pieredzi. Būtiski, ka, lai arī TeamConnect inicē šīs saziņas, tas notiek bez iejaukšanās saziņas procesā 
                  vai spējas ierakstīt vai saglabāt kādu no mijiedarbības saturiem. Visas sarunas, zvani un e-pasti notiek un tiek pārvaldīti tieši 
                  drošajā Microsoft Teams un Outlook vidē, stingri ievērojot Microsoft 365 drošības un privātuma standartus. Tas nodrošina, ka TeamConnect uzlabo 
                  jūsu saziņas iespējas, pilnībā saglabājot jūsu datu konfidencialitāti un integritāti.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Lai iegūtu detalizētu informāciju par mūsu datu politiku un praksi, jūs varētu arī konsultēties ar {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>TeamConnect Lietotāja Rokasgrāmatu</Body1Strong></Link> vai savas organizācijas IT 
              nodaļu adresei <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> lai uzzinātu detalizētus datus, kas saistīti ar jūsu iestatījumiem.
          </p>
        </Body1>        
        <p></p>  
        <Subtitle1 className={styles.fontColor}>Mēs Klausa: Dalieties Savā Atsauksmē un Idejās</Subtitle1>
        <Body1>
          <p>
            Jūsu ieskati virza TeamConnect attīstību. Vai jums ir kāda lieliska ideja par funkciju vai atsauksme par pašreizējo pieredzi, mēs esam visām ausīm. Jūsu ieguldījums ir nenovērtējams mūsu pastāvīgajā centienā sniegt 
            pēc iespējas labāku servisu.
          </p>
        </Body1>      
        <Subtitle2>Atsauksmes un Ieteikumi Funkcijām</Subtitle2>
        <Body1>
          <p>
            Gatavs dalīties? Dodieties uz mūsu {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Atsauksmju lapu
            </Link>
            . Neatkarīgi no tā, vai tā ir ieteikums jaunai funkcijai vai atsauksme par esošajām funkcionalitātēm, vienkārši izvēlieties 
            piemēroto opciju no nolaižamā izvēlnes veidlapā. Jūsu ieguldījumi palīdz veidot TeamConnect nākotni, 
            nodrošinot, ka tas atbilst un pārsniedz jūsu vajadzības.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
