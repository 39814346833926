import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>TeamConnect Օգնության Կենտրոնը & ՀՏՀ</Title3>
        <p><Body1>
          Բարի գալուստ TeamConnect Օգնության Կենտրոն, որը նախատեսված է Ձեր փորձը աջակցել մեր համալրագույն ընկերական լուրջացանկի ծրագրով։ Այստեղ դուք կգտնեք ռեսուրսներ TeamConnect-ի օգտագործման օպտիմալացման համար Microsoft Teams-ում, որոնք կբարելավեն կապը Ձեր ցանցում։
        </Body1></p>
        <Subtitle2>Համեմատական Թեստերի Ցուցակ</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Բարձրակարգ որոնման հնարքները՝ </Body1Strong>
              Գտեք ճիշտ համակիրներին, բաժիններն ու կարևոր կոնտակտներն արագորեն մեր հզոր որոնման գործիքներով։
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Տեսական կառույցները՝ </Body1Strong>
              Հետևեք Ձեր կազմաձևակառուցման հիերարխիային և հստակ հասկացեք զեկուցման գծերը հեշտ տեսական գրաֆիկների միջոցով։
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Ադապտիվ գործիքները այսօրվա համար՝ </Body1Strong>
              Օգտագործեք առավելությունները, որոնք հարմարված են ռազմագծարար աշխատավայրերին։
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Ձեր փորձը օպտիմալացնելը</Subtitle2>
        <Body1>
          <p>
            TeamConnect թելադրում է, թե ինչպես եք փոխազդում Ձեր կազմակերպական կառուցման ներսում, դարձնելով ամենօրյա կափումը ստրատեգիական ուժիտ զբաղվածքներ։
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Օգնություն & ՀՏՀ</Subtitle1>
        <Body1>
          <p>
            Գտեք պատասխաններ հաճախակի հարցերին TeamConnect-ի առանձնահատկությունների և պարամետրերի մասին այս նվիրված բաժնում։ Եթե դուք ցանկանում եք զբաղվել ծրագրի ուղենիշը, կարգավորել պարամետրերը կամ գտնել լուծումներ հրահանգներին, մեր Օգնության Կենտրոնը այստեղ է, որ արագ օգնի Ձեզ։
          </p>
        </Body1>
        <Body1>
          <p>
            Ավելի խորը աջակցության կամ հատուկ ուղենիշի հարցումների դեպքում՝ դիմեք <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnect Օգտագործողի Ձեռնարկինը</Body1Strong></Link>։
          </p>
        </Body1>
        <p></p>        
        <Subtitle2>Օգտագործելու TeamConnect ՀՏՀ</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Չե՞ք գտնում TeamConnect-ը Microsoft Teams-ի կողմնակալում
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Եթե TeamConnect-ը համալիրորեն չի երեւում կամ անհետացել է Microsoft Teams-ի կողմնակալում, կարող եք հեշտությամբ կպչել այն՝ արագ մուտքի համար։ Հետեւեք այս պարզ քայլերին՝
                  <ol>
                    <li className={styles.bulletBottomMargin}>Բացեք Microsoft Teams-ը և սեղմեք '...' (+ Apps) պատկերակը կողմնակալի ստորին մասում։</li>
                    <li className={styles.bulletBottomMargin}>Փնտրեք 'TeamConnect'-ը բացվող պատուհանի վերևի փնտրտուքարարակազմում։</li>
                    <li className={styles.bulletBottomMargin}>Կտակ արեք TeamConnect ծրարին փնտրտուքարտույցից և ընտրեք 'Pin'։</li>
                  </ol>
                  TeamConnect-ը հիմա կկպչված լինի ձեր կողմնակալում՝ դարձյալ հասանելի միայն մեկ սեղմումով.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Տեխնիկական խնդիրները TeamConnect-ում։ Ահա ինչպես ստանալ աջակցություն
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Եթե հանդիպեք TeamConnect-ում Microsoft Teams-ի մեջ որեւէ տեխնիկական խնդիրների, ձեր կազմակերպության IT աջակցությունի թիմը այստեղ է ձեզ օգնելու. 
                  Կապվեք հետնեք <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, և նրանք անհոգ օգնելու են ձեզ՝ առաջարկելով պատվերաժամ միջոցառում.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Չե՞ք տեսնում ձեր աշխատակցի՝ մանրամասները
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Եթե ձեր պրոֆիլը մի քիչ դատարկ է մնում՝ ցույց տալով միայն ձեր անունը և էլ-փոստը, ապա գուցե ձեր HR թիմը դեռեւս չի ավելցրել ձեր աշխատակիցի մնացած մանրամասները TeamConnect-ում.
                  Մի՛ փորձեք! Պատասխանատու HR թիմին արագ ուղարկեք նամակ, և նրանք պատրաստ են լիազորված լրացնել ձեր պրոֆիլը բոլոր կարեւոր մանրամասներով.
                  Կապվեք՝ <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, և դիտեք՝ ձեր պրոֆիլը կկրկնակի ապրի.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Ի՞նչ լեզվային ընտրանքներ կան TeamConnect-ում
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect ընդունում է Microsoft Teams-ի գլոբալ բազմազան էժանագիրը՝
                  աջակցելով այն բոլոր լեզուները: Եթե ​​դուք նախընտելանում եք արաբերեն կամ
                  հունարեն, դապոներեն կամ շվեդերեն, ձեր մոտ բազում ընտրանքներ կան՝ ներառյալ՝
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Արաբերեն, բուլղարացի, չինարեն (պարզացված և ավանդական), հորվաթերեն,
                      չեխերեն, դանիերեն, հոլանդերեն, անգլերեն, եստոներեն, ֆիններեն,
                      ֆրանսերեն, գերմաներեն, հունարեն, եբրայերեն, հունգարերեն, ինդոնեզերեն,
                      իտալերեն, դապոներեն, կորեերեն, լատվիերեն, լիտվերեն, նորվեգերեն
                      (Բոկմալ), լեհերեն, պորտուգալերեն (Բրազիլիա և Պորտուգալիա), ռումիներեն,
                      ռուսերեն, սերբերեն (լատինական), սլովակերեն, սլովեներեն, իսպաներեն,
                      շվեդերեն, թայերեն, թուրքերեն, ուկրաիներեն, վիետնամերեն
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Ձեր TeamConnect փորձը ձեր նախընտրած լեզվով ավելին մատչելի դարձնելու համար՝
                  խնդրում ենք տեղափոխել լեզվի պարամետրերը անգամ Microsoft Teams-ում, քանի որ
                  TeamConnect-ը լրիվ ինտեգրված է այս պարամետրերի հետ՝
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Անցեք Microsoft Teams-ի ձեր պարամետրերի մուտքի էջը՝ կտտացնելով երեք
                      կետիկով տեղադրված ձեր պրոֆիլանկարից աջ կողմ, և ապա ընտրեք 'Թեմայի և
                      հասանելիության դիտում'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Գնացեք 'Լեզու' բաժնին և ընտրեք ձեր նախընտրած լեզվը ներքեւի ցուցակից.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Հաստատեք ձեր փոփոխությունները՝ կտտացնելով 'Պահպանել և վերսկսել'
                      կոճակին՝ նոր լեզվային պարամետրերը կիրառելու համար.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Նշում՝ </Body1Strong>
                <Body1>
                  Microsoft Teams-ում կատարած լեզվային պարամետրերի փոփոխությունները
                  նախորդում են նույնպես թարմացնել ձեր TeamConnect փորձը։ Դիմեք ուշ, որ լեզվի
                  փոփոխումը Teams-ում նաև կհարթակ ձեր ամսաթվի և ժամի ֆորմատին. Հիմա,
                  TeamConnect-ը զարգանում է գրառման աջից ձախ. Մենք հիացած ենք այս
                  գերազանցմանը դիմակայելով և տեղեկացնելու ենք ձեզ, երբ այն դառնա մատչելի.
                </Body1>
              </AccordionPanel>
            </AccordionItem>;
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Ինչպես կարող եմ փոխել թեման TeamConnect-ում
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ը սինքրոնացված է ձեր Microsoft Teams թեմայի նախընտրանքների հետ, ներառյալ «Լուսավոր», «Խավարտական» և «Հակակոնտրաստ» ընտրանքները. Թեմանում անհատականացման համար՝
                  <ol>
                    <li className={styles.bulletBottomMargin}>Դիմեք Microsoft Teams-ի պարամետրերին՝ սեղմելով վերևին ձեր պրոֆիլի նկարի կողքին գտնվող երեք կետերը.</li>
                    <li className={styles.bulletBottomMargin}>Ընտրեք «Տեսք և հասանելիություն»՝ թեմայի ընտրանքները քննարկելու համար.</li>
                    <li className={styles.bulletBottomMargin}>Ընտրեք ձեր նախընտրած թեման՝ ամենահասանելի ընտրանքներից մեկը՝ TeamConnect ինտերֆեյսը այնպես հենց թարմացնելու համար.</li>
                  </ol>
                  Ձեր ընտրած թեման Microsoft Teams-ում ավտոմատապես կկիրառվի TeamConnect-ում, ապահովելով բավարար վիզուալ փորձ ձեր աշխատատեղում.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Դիտորդում՝ TeamConnect-ի կապահովման ակտիվ կոճակները
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ում՝ որտեղից որ գտնեք աշխատակիցների կապահովման մանրամասները, նաև կգտնեք մեր կարևոր արագ մուտքային կոճակները. Այս հարմար գործիքները
                  ձևավորված են կափսելու ձեր կապը Microsoft էկոսիստեմի հետ՝ ապահովելով՝ միշտ մի շարժ կտակերվեք կապնվելու։ Համառոտ ուղղորդական՝ այս կոճակներից օգտվելու՝
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Քամերային կոճակը՝</Body1Strong> Սեղմեք՝ միակողմանի Microsoft Teams չատը սկսելու համար. Եթե պետք է վերադառնաք TeamConnect-՝ հենց սկիզբ հանեք առջևում գտնվող ձախ սլաքը, նախկին լուսամուտը կամ ձեր վեբ դիտարկչի back կոճակը.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Email Icon:</Body1Strong> Բացում է նոր Outlook էլ. Փոստի նամակի նախօրեին՝ «Երաշխիք» դաշտում ստացողի հասցեն պատրաստով։ Եթե Outlook-ը չպետք է, ի՞նչ արդյունք ունեք լինի, որպես տեսլականություններով.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Video Call Icon:</Body1Strong> Գնալ դեմին Microsoft Teams-ում միջոցով.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Call Icon:</Body1Strong> Գործել Microsoft Teams-ի ձայնաշարժին՝ արագ քննարկալու հարցակաները.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Org Chart Icon:</Body1Strong> Ցուցադրում է աշխատակցի տեղը կազմակերպչական հիերարխիայում ՝ ներառյալ նրա մենեջերին.</li>
                    <li><Body1Strong>Ավելին (...) Icon:</Body1Strong> Աշխատահարթակների ավելին՝
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Meet Now:</Body1Strong> Բացում է Microsoft Teams-ը՝ անմիջապես հանդիպում սկսելու համար.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>New Meeting:</Body1Strong> Վերադարձնել և Microsoft Teams-ում նոր հանդիպում որոշակի.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Add Contact:</Body1Strong> Քաշել աշխատակցի կոնտակտները TeamConnect-ից Microsoft Teams մարդկանց հրապարակին֝ ձեր կոչերի ֆունկցիան հարստացնելու համա.։</li>
                      </ul>
                    </li>
                  </ul>
                  Այս շրջանցիկ կոճակները ձևավորված են՝ ապահովելով՝ դուք կարող եք հարստորեն կապվել ձեր մետաղանյութերի հետ՝ խորհրդավոր զրույցի, էլ. փոստի և ապագա հանդիպումները պլանավորելու դեպքում.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. TeamConnect-ի միջոցով զանգեր կատարելը
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Զանգե՞լ եք ուզում. TeamConnect-ը հեշտացնում է ձեր հաղորդակցությունը՝ ինտեգրվելով Microsoft Teams-ին.
                  Պարզապես սեղմեք ցանկալի հեռախոսահամարի վրա TeamConnect-ում և բացվում է Microsoft Teams զանգերի միջերեսը,
                  պատրաստ է ձեզ անխափան հավաքելու համար.
                  <p>
                  <Body1Strong>Նշում. </Body1Strong>
                  Զանգեր կատարելու հետ կապված խնդիրների հանդիպե՞լ եք: Դա կարող է պայմանավորված լինել ձեր կազմակերպության ՏՏ քաղաքականության կամ
                  Microsoft Teams Phone System-ից տարբեր հեռախոսային համակարգի օգտագործում. Գրանցվելով ձեր ՏՏ բաժին՝ <a href={`mailto:${itSupport}`}>{itSupport}</a> հասցեով,
                  կարող է որոշակի լույս սփռել խնդրի վրա.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Կան՞ ծախսեր, կապված TeamConnect-ով զանգերի կատարման հետ
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ը բարելավում է ձեր զանգերի փորձը՝ օգտվելով Microsoft Teams-ից։ Ահա ինչ պետք է հասկանալ զանգերի վարձույթների մասին.
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Երբ զանգում եք աշխատակցի աշխատային հեռախոսներին, բաժնեմաստերի հեռախոսներին կամ հեռաձայնականներին ձեր կազմակերպության մեջ (ներքին համարներ), 
                      Microsoft Teams Phone System-ը օգտագործելիս <Body1Strong>արժեքներ չկան</Body1Strong>։
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Արտաքին զանգերի դեպքում՝ մոբիլ հեռախոսներին կամ արտաքին գծերին, <Body1Strong>ստանդարտ արժեքներ</Body1Strong> են կիրառվում՝ համաձայն ձեր կազմակերպության հեռախոսային համակարգին։
                      Այս արժեքները տարբերվում են ըստ Microsoft Teams-ի հետ ձեր հատուկ ծառայության պլանի։
                    </li>
                  </ul>
                  <p><Body1Strong>Նշում:</Body1Strong> Եթե հանդիպեք խնդիրների կամ ունեք հարցեր զանգերի վարձույթի մասին, ամենալավն է կապվել ձեր 
                    IT բաժնի հետ <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>՝ մանրամասն տեղեկության առումով,
                    ըստ ձեր կազմակերպության կազմափոխության և քաղաքակրթության։</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Միայնակ փոխանորդային 'Հեռանկարարատեսությունը' TeamConnect-ում
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Հանդիպե՞լ եք, որ ձեր 'Հեռանկարատեսության' բնութագիրը Microsoft 365-ից չի համապատկերվում TeamConnect-ում.
                  Մի անհոգնեք։ 'Հեռանկարային' բնութագրերի համաժամացման հատկությունը արդեն կատարված է TeamConnect-ում.
                  Մենք հիմա սպասում ենք Microsoft-ի այս ֆունկցիայի տրոհումին։ Հենց սա կատարվի, ձեր 'Հեռանկարային' բնութագիրը 
                  ճշգրիտ կհամապատկերվի TeamConnect-ում, հարմարավետ կհամախառնվի Microsoft Teams-ի բնութագրերի հետ.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>Աշխատակիցների Ուղեցույցի FAQ</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >Աշխատակիցների Ուղեցույցի Ներածություն:</Body1Strong> Թեքում եք ձեր կազմակերպության մասնագետների արշավանքը՝ հենվելով Աշխատակիցների Ուղեցույցին,
                ձեր կենտրոնական հաբը մանրամասնի տեսարաներում, աշխատացառներում և սահմանափակ կապերում՝ ձեր հարակիցների հետ։
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Գրանցելու, թարմացնելու կամ ուղղելու է ձեր աշխատակցի տվյալները:
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Եթե ցանկանում եք գրանցել, թարմացնել կամ ուղղել ձեր աշխատակցի տվյալները TeamConnect-ում, դիմեք ձեր կազմակերպության HR աջակցման թիմին։ 
                  Այնպես կսպասեք ձեռքբերելուն <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>           
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Ի՞նչ է կատարվում, երբ սեղմում եմ աշխատակցի անունը տեղեկատուից:
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Աշխատակցի անունը սեղմելը TeamConnect Աշխատակցի Տեղեկատուում բացում է աշխատակցի մանրամասն էջը։ Այս պրոֆիլն առաջարկում է լիարժեք տեսություններ աշխատակցի մասին՝ նրա աշխատանքի պաշտոնը, մասնաճյուղը, գրասենյակի դիրքը, կապի տվյալները եւ այլն։ Այն ձեռնարկված է ձեզ առաջարկելու լիարժեք տեսություն աշխատակցի վարժանի դերի մասին և թե ինչպես է նա միացած կազմակերպությանը, բարելավելու ձեր հասկանալուն իրենց պատասխանատություններից և ինչպես կարող եք հետադարձ գործել նրանց հետ:
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Ի՞նչ տվյալներ են ներառված աշխատակցիի պրոֆիլում։
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Աշխատակցի պրոֆիլն ունի լիարժեք պատկեր, որը առաջարկում է կազմավորած տեսություններ աշխատակցիի մասին։
                  Ահա ինչը դուք կգտնեք՝
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Հիմնական մանրամասներ:</Body1Strong> Անուն,աշխատանքի պաշտոն, մասնաճյուղ եւ բաժինը:
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Հաշվեկշռման կարգը:</Body1Strong> Տեղեկատում ընթացիքը ուղղակի եւ գործնական զեկուցումների մասին,
                      ներառյալ գիծ և գործնական մանագերեր։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Աշխատատեղի վիճակ:</Body1Strong> Ընթացիկ աշխատավիճակ, իրավիճակները ինչպես հանգստի մեջ, ճամփորդել, 
                      Բիզնես ճամփորդական և այլն։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Աշխատատեղից և տեղական ժամանակ:</Body1Strong> Այժմյան աշխատատեղը  և տեղական ժամային գոտի:
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Կապի տվյալներ:</Body1Strong> Աշխատատեղի հեռախոս, բջջային հեռախոսի համար եւ էլփոստի հասցե:
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Գրասենյակի հասցե:</Body1Strong> Գրասենյակի տեղականը՝ շենքի անունը, 
                      հասցեն և Google քարտեզ:
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Մասին:</Body1Strong> Կարճ անձնական ներածություն կամ մասնագիտական շարադրանք:
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Աշխատակցի կապեր:</Body1Strong> Ընկալաձեւ խել աշխատակցի հաշվեկշռվող կառուցվածքը եւ թիմային կապերը:
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Սոցիալական կապեր և լեզուներ:</Body1Strong> Հղումներ՝ մասնագիտական սոցիալական պրոֆիլերին և լեզվաբառի հարթակներին:
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Հմտություններ:</Body1Strong> Մասնագիտական հմտությունների ցուցակ և մասնագիտական գոտերի։
                    </li>
                  </ul>
                  Բունկրտորումը նաև առաջարկում է արձակելի կապերի տարբերակներ չաթի, էլփոստի, տեսազանգի կամ հանդիպումների գրանցման համար, այնուհանդերձ տրամադրելով որակությանը թիմի լայնականությունը համաձայն օրգանիզացիականի գրաֆիկին։
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. ինչպես կարող եմ թարմացնել իմ պրոֆիլի տվյալները:
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ինքնատիպ ձևավորելը հեշտ է TeamConnect-ում: Հաստատում է, 
                  թե ինչպես եք դուք անելու:
                  <ol>
                    <li className={styles.bulletBottomMargin}>
                      Գնալով <Body1Strong>Աշխատակիցներ</Body1Strong> ներդիր և սեղմելով 
                      <Body1Strong>Avatar</Body1Strong> ձերը՝ բացել է ձեր պրոֆիլը:
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Վերեւի ձախ անկյանում՝ գտնելով <Body1Strong>"Խմբագրիր նախընտրածդ"</Body1Strong> հղումը:
                      Սեղմելով այստեղ, կբացվի խմբագրումի պատուհանը ձեր պրոֆիլի համար:
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Այստեղ դուք կարող եք ավելացնել կամ թարմացնել ձեր <Body1Strong>Մասին ձեզ</Body1Strong> բաժինը, 
                      սոցիալական հղումները և հեռախոսահամարը: Սա կհեշտացնի ձեր աշխատակցիկներին կապնվել 
                      կամ հետևել ձեզ:
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Մի մոռացեք սեղմել <Body1Strong>Պահպանել</Body1Strong> կոճակը՝ կիրառելու ձեր փոփոխությունները:
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Գլուխը կարելի է գլորել պնդման դեպքում՝ ավելցնելու 
                      <Body1Strong>Լեզուներ</Body1Strong> և <Body1Strong>Մասնագիտություն</Body1Strong>, 
                      ինչպես նաև ձեր <Body1Strong>Հմտությունները</Body1Strong>: Այստեղից բաժանելը համայնքին 
                      կտանի հայտնիություն ձեր մասնագիտական հարթակի և կեստեսաքարտուղի մասին:
                    </li>
                  </ol>
                  Ձեր պրոֆիլը թարմացնելը միայն չի պահպանում ձեր տվյալները թարմ, 
                  այլ նաև որոգում է կապերը ձեր կազմության ներսում:
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Թարմացնելով ձեր աշխատավայրը, աշխատավայրը և ժամային գոտին TeamConnect-ում
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ձեր աշխատավայրի, աշխատավայրի և ժամային գոտու մասին տեղեկատվությունը արդիական պահելը շատ կարևոր է Microsoft Teams-ում անխափան համագործակցության համար.
                  Այս թարմացումներն անմիջապես արտացոլվում են ձեր TeamConnect պրոֆիլում և ցուցակներում՝ թույլ տալով գործընկերներին արագ հասկանալ ձեր աշխատանքի վայրը
                  և ձեզ հետ կապվելու լավագույն ժամանակները՝ ուժեղացնելով թիմային աշխատանքը տարբեր տարածաշրջաններում և ժամային գոտիներում.
                  <p></p>
                  Պատրա՞ստ եք թարմացնել ձեր տվյալները? TeamConnect-ում հեշտ է. Գնացեք «Կարգավորումներ» վերևի ընտրացանկում և անցեք «Ընդհանուր կարգավորումներ».
                  Այստեղ դուք կարող եք սահուն թարմացնել ձեր աշխատավայրը և ժամային գոտին և նշել ձեր աշխատանքային տարածքը օրվա համար. Հիշեք, որ պահեք ձեր փոփոխությունները
                  բոլորին տեղեկացված պահեք և ապահովեք արդյունավետ համագործակցություն՝ անկախ ձեր գտնվելու վայրից!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Ինչ-որ բան նկատե՞լ եք գործընկերոջ պրոֆիլում?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Եթե գործընկերոջ պրոֆիլում հանդիպեք որևէ մանրամասների, որոնք այնքան էլ ճիշտ չեն թվում, լավագույն առաջին քայլը նրան տալն է.
                  ընկերական գլխավերեւում. Արագ զրույցը կամ էլփոստը պետք է հաջողության հասնեն: Եթե ինչ-ինչ պատճառներով տեղեկատվությունը չի թարմացվում, մի անհանգստացեք, պարզապես
                  զանգահարեք ձեր HR աջակցման թիմին <a href={`mailto:${hrSupport}`}>{hrSupport}</a> հասցեով, և նրանք ուրախ կլինեն
                  մտնել և կարգավորել իրերը.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Բառերի խումբերի ընկալումը՝ Բոլորը, Ընտրվածները, Ստուգվածները և Նոր աշխատողները
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Բոլորը՝</Body1Strong> Այս լռելյայն նշանը ցուցադրում է Ձեր կազմում ներառված ամեն մի աշխատողին՝
                      ամբողջական տեսքը տալով թիմին։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ընտրվածները՝</Body1Strong> Սեղմեք այստեղ՝ Ձեր դիտարկումը ֆիլտրելու համար այն համատեղերին, որոնք Ձեր կողմից
                      նշված են 'Ընտրվածներով', հեշտացնելով արագ կապը հիմնական կոնտակտների հետ։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ստուգվածները՝</Body1Strong> Այս նշանը ցուցադրում է աշխատանքային ժամանակ ակտիվ աշխատողներին, տարբերելով 
                      միայն Microsoft Teams-ի օնլայն ընթացակարգում լինելը: Ստուգվելիս աշխատողները բուժում են, որ աշխատանքին ենք եւ կիսում 
                      են իրոք աշխատավայրը՝ ժամանակավորումը: Այստեղ կարող է ներառվել Office, Field-Office, Home-Office, Remote-Workplace, Customer-Workplace,
                      Customer-Private-Office, Public-Area, Commute, Business-Travel կամ ավելին քննարկվելը: Ստուգվածը բացատրում է աշխատանքային կյանքում  
                      ակտիվ լինելը եւ ապահովում է աշխատակիցներին հստակ էական իրարումները։ </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Նոր աշխատողները՝</Body1Strong> Հայտնի նոր աստղերը TeamConnect-ում այս նշանինը՝
                      օգտագործվում են հատուկ, որով ապագանում են հավելվում ծրագրին վերջին 30 օրերին: Հաստատ, այս տարբերելը համազգեստումը 
                      նոր աշխատակիցներին:
                    </li>
                  </ul>
                  Այս բառերի խմբերը ցույց են տրամադրված նավարկման հեռանկարները եւ ավելի հզորությանը՝ TeamConnect փորձը, հարմարեցնելով  
                  միշտ՝ գտնելու եւ կապված լինելու հեշտերը՝ Ձեր աշխատակիցների:
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Ինքնությունների բառերի փաթեթը որոնման տուփի կողքին 
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect's Employee Directory-ը նախատեսված է բազմակողմանիության և դյուրինության համար, որն ունի չորս պատկերակ, որոնք առաջարկում են տարբեր տեսակետներ:
                  և առանցքային մանրամասների արագ մուտք: Ահա թե ինչ է բացում յուրաքանչյուր պատկերակ.
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ցանկի տեսք.</Body1Strong> Ձեր այցելուների դիտումը, որը ներկայացնում է կազմակերպության բոլոր անդամներին
                      պարզ ցուցակում:</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Փոքր քարտի տեսք.</Body1Strong> Կոմպակտ այլընտրանք, այս տեսքը փոխակերպում է
                      ցուցակագրել աշխատակիցների չափի քարտերի մեջ:
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Խոշոր քարտի տեսք.</Body1Strong> Ընտրեք ավելի մանրամասն ավելի մեծ քարտեր, որոնք ներառում են
                      Կոնտակտային հավելյալ ընտրանքներ, ինչպիսիք են հեռախոսի և բջջային համարները՝ հեշտ հավաքելու համար:
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Կազմերի գծապատկեր.</Body1Strong> Պատկերացրեք ընկերության կառուցվածքը Կազմակերպչական աղյուսակի տեսքով,
                      կատարյալ է հաշվետվության փոխհարաբերությունները հասկանալու և կազմակերպչական լանդշաֆտում կողմնորոշվելու համար:</li>
                  </ol>
                    Անցեք այս պատկերակների միջև՝ գտնելու այն տեսակետը, որը լավագույնս համապատասխանում է ձեր կարիքներին այս պահին, անկախ նրանից՝ դուք փնտրում եք արագ տեղեկատվություն կամ ավելի խորը պատկերացումներ:
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Գործընկերների արագ որոնումների համար որտե՞ղ պետք է նայեմ:
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Փնտրու՞մ եք գործընկերոջ: Պարզապես այցելեք TeamConnect որոնման դաշտը, որը գտնվում է վերին աջ կողմում, Օրգ աղյուսակի պատկերակի կողքին:
                  Սկսեք մուտքագրել նրանց անունը և հետևեք, թե ինչպես են արդյունքները ճշգրտվում յուրաքանչյուր տառով: Հիշեք, որ TeamConnect որոնումների համար բաց թողեք Microsoft-ի թիմերը
                  Որոնման սանդղակը վերևում. մեր հատուկ որոնման տուփը ճանապարհն է:
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Ինչպես է աշխատում այբբենական որոնումը աշխատակիցների գտնելու համար:
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ի Աշխատակցի Ուղեցույցը ներառում է հարմար այբբենական որոնման հատկանիշ՝ աշխատակցիների արագ գտնելու օգնությամբ ըստ նրանց անվանի։ 
                  Պարզապես սեղմեք տեսարանում շարվածի գագաթնական այբուբենիվ՝ և TeamConnect ականջակալ կպատկերի այդ ամենաաշխատակիցներին, 
                  որոնց անվաներն այս տառով են սկսվում։ Աշխատակցների լիստը տեսնելու համար, պարզապես չընտրեք այլոց գլուխգիրը։ Այս հարմար 
                  հատկանիշը հեշտացնում է մեծ ցուցակներով նավիգացիային անցնելը՝ ապահովելով աշխատակիցների արագ և արդար գտելու հնարավորություն։
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Ինչպես կարող եմ տեղավորել էջում դրսևորվող աշխատակցների թիվը։
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ը թույլ է տալիս ձեզ ընտրելու համար աշխատակցի պրոֆիլները՝ մեկ էջում։ Էջի հատակին դուք կգտնեք համարը՝ 10, 50, 100, 500, 1000 և 5000։ Գործարկման մնացորդտեքստը 
                  ցուցադրում է 10 պրոֆիլ։ Մեկ էջում ավելի շատ պրոֆիլներ տեսնելու համար՝ պարզապես ընտրեք Ձեր նախընտրած թիվը այդ հատկանիշներից մեկից։ Այս հատկանիշն 
                  ապահովում է հարմարությունը ցուցադրումտեքստին՝ Ձեր պահանջների կամ նախընտրություններին հա्रմատեղելու համար։
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Ինչպես եմ կարող տեղադրել աշխատակցի տվյալները դիտարկղում։
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ի Աշխատակցի Ուղեցույցի Դիտարկման Դիտումում, Ձեր համար կա համար տեղադրելու աշխատակցի տվյալները։ Տոպրակները՝ Աշխատակցի Անվանում, Աշխատակազմ, 
                  Բաժանում, Գրաֆիկային Վայր և Աշխատավայր, ամբողջը տեղադրելի։ պարզապես սեղմեք տեղեկության վերևակայում տոպրակ հանդուգամր կողմով։ 
                  Մեկ սեղմումով՝ տոպրակը կսերտապարտաշրջ հաճախորդիկակալակաբույստե աճ կարգին։ Եվ երկրորդ սեղմումով՝ նույնը կսերտապարտաշրջ շրջհակուղային կարգին։ 
                  Իրարանցույցը լռությամբ տեղադրված է Աշխատակցի Անվամբ աճ կարգով։ Այս տեղադրման կառավարման հատկանիշը օգնում է արագ կազմակերպել աշխատակցներին։
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Բաժանմունքային Ուղեցույցի ՀՏՀ</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Բաժանմունքային Ուղեցույցի Ներածություն:</Body1Strong> Բաժանմունքային Ուղեցույցը ձեր դարպասն է՝ հասկանալու ձեր 
              կազմակերպության բարդ կառույցը, ապահովելով բաց տեսլական՝ բաժանմունքային հարարատերականների, ղեկավարության և բաժանմունքային 
              հարթ կապի առումով։
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Ինչպես են աշխատում 'Բոլորը', 'Ընտրվածները' և 'Ձեր Բաժանմունքը' պիտակները Բաժանմունքային Ուղեցույցում։
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ի Բաժանմունքային Ուղեցույցում պիտակները օգնում են արագ ուղղորդել և զտել բաժանմունքային տվյալները: Ահա ինչպես է 
                  յուրաքանչյուր պիտակը ներկայացված։
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Բոլորը:</Body1Strong> Այս պիտակը ցուցադրում է ձեր կազմակերպության բոլոր բաժանմունքները,
                      ապահովելով լիահատակ համառություն։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ընտրվածները:</Body1Strong> Այս պիտակով դուք նայում եք ձեր որպես 'Ընտրված',
                      ընտրածող բաժանմունքների ցուցակ, ապահովելով հեշտ մուտք ձեր հաճախակի խոսելու բաժանմունքներին։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ձեր Բաժանմունքը:</Body1Strong> Այս պիտակը ֆիլտրում է դիտումը՝ ցուցադրելու
                      միայն ձեր բաժանմունքը, ապահովելով արագ մուտք ձեր անմիջական բաժանմունքի տվյալների և թարմացումներին։</li>
                  </ul>
                  Այս պիտակները նախագծված են պարզեցնել ձեր նավիգացումը Բաժանմունքային Ուղեցույցում, ավելացնելով ձեր կարողությունը 
                  գտնել և շփվել բաժանմունքային Տվյալներով արդյունավետ։
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Իմանալու Բաժանմունքային Ուղեցույցի Դիտումները TeamConnect-ում
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ում Բաժանմունքային Ուղեցույցում նավիգացումը հեշտացված է շարժման օպցիաներով, որոնք պատրաստված են հարմարվել ձեր 
                  նախընտրություններին։ Ասումները միաքանչյուր միայնակ դիտումը կերպարում է լայնատեսակ բաժանմունքային Տվյալները, ներառյալ 
                  Բաժանմունքի Անունը, Բաժանմունքային ղեկավարը, Հայրենիք Բաժանմունքը, Բաժանմունքային հեռախոսը, Բաժանմունքային Արտակարգ 
                  գիծը, և Բաժանմունքային Էլ.փոստը։ Ահա առկա դիտորոշվումները։
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ընդգծային Դիտում:</Body1Strong> Նախնական սահմանումը կատարված է պարզորոշելու
                      բոլոր բաժանմունքները՝ տրամադրելով հստակեղծիտ ամփոփում։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Մեծ Կարտային Դիտում:</Body1Strong> Փոխանցեք այս դիտումը, որպեսզի ցուցադրեք 
                      մանրամասն բաժանմունքային քարտերը, ապահովելով՝ մեծացրած ամփոփում յունք բաժանմոքիքիք բաժանմունտիիի կարեքությծուըթը
                      ըկատակվածիքվումնք։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Կազմերի գծապատկերի դիտում.</Body1Strong> Գերատեսչական կառուցվածքի և տեսողական ներկայացման համար
                        հարաբերությունների, Org Chart տեսակետը ցույց է տալիս, թե ինչպես է յուրաքանչյուր բաժին տեղավորվում ավելի մեծ կազմակերպչական հիերարխիայում:
                    </li>
                  </ul>
                  Այս դիտումների միջև փոխարկվելու համար պարզապես օգտագործեք ստորաբաժանման գրացուցակի վերևում գտնվող որոնման դաշտի կողքին գտնվող պատկերակները: Յուրաքանչյուր դիտում նախատեսված է ձեզ օգնելու համար
                  հեշտությամբ մուտք գործեք և հասկանաք գերատեսչական մանրամասները, ապահովելով, որ դուք կգտնեք ձեզ անհրաժեշտ տեղեկատվությունը այն ձևաչափով, որն առավել հարմար է ձեզ համար:
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. ինչպես օգտագործել Բաժինների Դասակարգիչի որոնման դաշտը
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Գումարած երթուղարկեք կամ բաժին մեջ TeamConnect կիրառեք նվիրված որոնման դաշտը, որը գտնվում է հաջորդ տեսակետի նշաններին վերևում՝ 
                  Բաժինների Դասակարգիչի վրա: Սկսեք գրանցել բաժինի անունը, որին եք փնտրում, և դիտեք, ինչպես դասակարգիչը նվազեցնում է իրեն, 
                  ցուցադրելով միայն հարակից համապարզությունները: Այս հատկությունը մշակված է մասնավորապես ձեզ օգնելու համար՝ հաղթելով ամբողջ 
                  դասակարգիչը առանց ձեռքով հանելու պահանջը, տալով ձեզ պետքական բաժինը պարզապես ձեր հաստատուններին: Հիշեք, այս որոնման 
                  լուծումը բացարձակապես է TeamConnect-ի Բաժինների դասակարգչին, թերևս գործածեք այս որոնման դաշտը Microsoft Teams-ի՝ 
                  վերևում հիմնական որոնումների համար, ավելին՝ բաժինային հարցերի համար:
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Ինչպես է աշխատում այբբենական ֆիլտրը Բաժինների Դասակարգչի մեջ:
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Թեթևացրեք ձեր որոնումը Բաժինների Դասակարգիչում՝ այբբենական ֆիլտրի միջոցով: Այս հատկությունը Ձեզ տալիս է պարզել 
                  բաժիններին ակնթարթով` ընտրելով մի տառ: Երբ ընտրեք մի տառը, TeamConnect-ը ֆիլտրում է և ցուցադրում միայն այն 
                  բաժինները, որոնց անունները սկսվում են` ընտրված տառով, առանց ամբողջ ցուցակը անցնելու: Եթե ​​ուզում եք 
                  դեպի ամբողջ բաժինների տեսակետ վերադառնալ, պարզապես ապավելեք ընտրված տառը: Այս ինստինկտիվ այբբենական անցուռուստացումը 
                  ապահովում է, որ դուք կարող եք արագ համակչել կամ բաժին ըստ իր առաջիա տառերի:
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Ինչպես կարող եմ կապնվել բաժինի մենեջերի հետ:
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Կապը բաժնի մենեջերի հետ TeamConnect-ում ընդունակ է: Ուղղակի գտեք հետաքրքիր բաժինը և սեղմեք մենեջերի 
                  անվան վրա։ Այս գործընթացը ցուցաբերում է պոպավերը բացվում մենեջերի կապի ընտրանքներով և ծանոթ արագ-մուտքի 
                  նշաններով՝
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chat Icon:</Body1Strong> Սկսել Microsoft Teams-ում ականջակց չաթին։</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Email Icon:</Body1Strong> Բացել նոր էլեկտրոնային փոստ՝ 
                    հասցեագրված դրանց Microsoft Outlook-ում։</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Video Call Icon:</Body1Strong> Կատարել տեսազանգ Microsoft Teams-ում։</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Call Icon:</Body1Strong> Սկսել ձայնային զանգը Microsoft Teams-ի միջոցով։</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>More (...) Icon:</Body1Strong> Լրացուցիչ ընտրանքների համար, 
                      ինչպես հանդիպում կազմելը կամ նրանց կոնտակտի տվյալները ավելացնելը ձեր Teams ցանկում։
                    </li>
                  </ul>
                  Այս Նշանները բացահայտական եղանակ են հաղորդակցվելու բաժնեմենջերների հետ առաջինների հետ՝ TeamConnect-ում, 
                  ապահովելով հարմարավետ խոսակցություն ձեր կազմակերպագրության հետ։
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Ինչպես կարող եմ տեսնել կամ կապնվել բաժնում աշխատողների հետ:
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Հետեւեք՝ ովքեր են բաժնում TeamConnect-ում, դիտեք բաժնի անվան կողքին եղած երեք կետերը։ Այստեղ սեղմելով՝ կառուցվվեն ընդգրկություն։
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Դիտեք Այս Բաժնում Աշխատողներին:</Body1Strong> Ընտրեք այս ընտրանքը՝ բաժնում կապված բոլոր 
                      աշխատողների ցուցակը ցույց տալու՝ բաժնի այս ընտրանքի կողքին նշված թիվը ցույց է տալիս, թե բաժնում որքան աշխատող կա։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Բաժնի Օրգանական Գրաֆիկ:</Body1Strong> Եթե ցանկանաք հասկանալ բաժնի հիերարխիան, 
                      ընտրեք 'Բաժնի օրգանական գրաֆիկ'՝ բաժնի կառուցվածքը բացահայտելու, որոնք ցույց տալիս են ծնող բաժնեմասերի,
                      տվյալ բաժնի և ենթաբաժների միջև հանգույցները։ Այս նկարագրական ներքաշվելու միջոցը օգնում է հասկանալ ու կապնվել բաժնի
                      հատուկ անձինքների հետ։
                    </li>
                    <li><Body1Strong>Բաժին {'>'} Աշխատող:</Body1Strong> Բաժնում աշխատողներին դիտելուց հետո, օգտագործեք այս նավարկման ղեկը՝
                      հեշտությամբ հետ գնալու բաժնեմասերի ցուցակը, ապահովելով TeamConnect-ում հանգիստ հետաքրքիր փորձ։
                    </li>
                  </ul>
                  Այս հատկությունները Ձեզ կտրամադրեն ոչ միայն տեսնել տվյալ բաժնում աշխատողների ցուցակը, այլ նաև պատմականը պետք է
                  ապահովի ուղղի դեպի նրանց:
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. TeamConnect-ում բաժնեմասերի խորը մանրամասները հետազոտելու գործիք
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Հուսալով բացեք յուրաքանչյուր բաժինի մանրամասները՝ սեղմելով այդ բաժնի անվան վրա TeamConnect-ում։ Փոխհատուցող զրույցը բացվում է՝
                  հարուստ ներկածության փաթեթով, որը թեկուզ մեկ բաժինի դերն ու ռեսուրսները մեծարում է Ձեր կազմում։ Դուք գտնելու եք ամեն 
                  բան՝ ղեկավարությունից և ֆինանսական նշաններից մինչեւ կապի եղանակները և սոցիալական հղումները։ Այս հատկությունը Ձեր պատուհանն է՝
                  <ul>
                    <li className={styles.bulletBottomMargin}>Բաժինի սիրտը – իր նպատակը՝ անձիքը և գործնականության մեթոդները։</li>
                    <li className={styles.bulletBottomMargin}>Կապի արանքներ – ուղղակի գծեր բաժնի կարեվոր անձանց և կապի հարթակներին։</li>
                    <li className={styles.bulletBottomMargin}>Կազմակերպության ցանցը – հասկանալ՝ ինչպես է բաժինը ինտեգրում ավելի լայն ընկերության կառույցում։</li>
                  </ul>
                  Անկախ Ձեզ պետք է արագ հարցում կամ խորը հետազոտություն՝ TeamConnect-ը ունի գործիքները՝ կապվելու հետ և հասկանալու՝
                  տարբեր եկոսիստեմների հետ՝ Ձեր կազմում։
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Հարցեր՝ բաժնեմասերի հասցեների բացակայության մասին TeamConnect-ում։
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Գտնելուն բաժինի վայրը ենթադրում է հաճախ՝ գանձափնյա որոնում առանց քարտեզի։ TeamConnect-ում բաժնի հասցեները չեն նշված։ Ահա՛ ինչու՝
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Բազմաթիվ վայրեր.</Body1Strong> Շատ բաժիններ սահմանափակված չեն մեկ տարածությամբ; նրանք
                      բարգավաճել տարբեր գրասենյակներում և աշխարհագրություններում:
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Հեղուկի կառուցվածքը.</Body1Strong> Կազմակերպչական լանդշաֆտը մշտապես փոփոխվում է.
                      ստորաբաժանումները զարգանում են, միաձուլվում կամ նույնիսկ լուծարվում ռազմավարական տեղաշարժերի հետ համահունչ:
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Միջգերատեսչական համագործակցություն.</Body1Strong> Ժամանակակից աշխատանքային տարածքները աշխույժ են համագործակցում
                      ոգին, հաճախ համատեղելով տարբեր բաժինների տաղանդները ընդհանուր տարածքներում:
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Հեռակա աշխատանքի մշակույթ.</Body1Strong> Հեռավար աշխատանքի ճկունությամբ՝ գերատեսչական
                      խարիսխները միշտ չէ, որ գցվում են ֆիզիկական գրասենյակային վայրերում:
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Իրավական նրբերանգներ.</Body1Strong> Բաժանմունքները սովորաբար պտտվում են ընկերության իրավական դաշտի շուրջ
                      կառուցվածքը, այլ ոչ թե սեփական առանձին հասցեներ ունենալը:
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Օպերատիվ պարզեցում.</Body1Strong> Կենտրոնանալով դինամիկ թիմային աշխատանքի վրա, ոչ թե ստատիկ
                      հասցեները, օգնում է պահպանել գործառնական շարժունությունը:
                    </li>
                  </ul>
                  Ամեն ինչ թիմային աշխատանքի և արդյունավետության նկատմամբ սահմաններից զերծ մոտեցում ընդունելու մասին է: TeamConnect-ն արտացոլում է այս փիլիսոփայությունը՝ ընդգծելով կապերը
                  վայրերը.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Ձեր բաժնի տվյալները բացակայում են կամ հնացած են:
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ձեր կազմաձևակերպության մեջ բաժնեմասերի խմբագրողները նշանակված են՝ հավելելու, թարմացնելու կամ շտկելու բաժնեմասական տվյալներ TeamConnect-ում։
                  Եթե դուք չգիտեք, թե ով է ձեր բաժնի խմբագրողը, կամ եթե որոշ տվյալների կարիք ունեն ՀՌ աջակցության թիմի միջամտությանը, 
                  խնդրում եմ կապվել <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> հետ հուշումների համար։
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Օֆիսի տեղեկացության ՀՏՀ</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Օֆիսի տեղեկացույցի ներածություն՝</Body1Strong> Կատարեք ձեր կազմաձևակերպանական մարմնի ֆիզիկական հաստատունելու ավազահարթունը 
                Օֆիսի տեղեկացույցում՝ ներառյալ է կարևոր ստացվածքները, գործողությունները և ամբողջական տեսությունը բաժիններից և անձնակազմին՝ յուրաքանչյուր դիրքում։
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Թեգերով անցում Օֆիսի տեղեկացույցում
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Հեշտությամբ համալրեք Օֆիսի տեղեկացույցում ձեր ճանապարհը՝ մեր թեգերի համակարգով՝ արագ մուտքի համար ճիշտ տեղում։ Ահա թե ինչպես կարող է յուրաքանչյուր թեգը հեշտացնել ձեր որոնումը:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Բոլորը:</Body1Strong> Ցույց է տալիս բոլոր գրասենյակների ամբողջ ցանկը, ապահովելով ձեր 
                      կազմաձևակերպանական դիրքերի թռիչքի բացառիկ համառություն։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ընտրանի:</Body1Strong> Ձեզ հասցեագրում է այն գրասենյակների դիրքերը, որոնք հաճախում եք կամ 
                      ամենաշատ հասունացում եք հասցեագրելով `Ընտրանի' որպես։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ձեր Գրասենյակը:</Body1Strong> Այս անհատականացված թեգը ֆիլտրում է տեղեկացույցը՝ ցույց տալով 
                      ձեր հիմնական բազային գրասենյակը՝ պահելով ձեր հիմնաբնական հաստատունը ձեր մատակարար։
                    </li>
                  </ul>
                  Հետախորընկալություն միջավայրի հետ կապվելիս կամ այցը կազմակերպելիս, այս թեգերը առաջարկում են անհատականացված և պարզապես ինտերակցիոն ձև՝ 
                  համակարգելով և նվազեցնելով ձեր արգանական շրջանի փորձը։
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Տեղեկատուն բյուրոյի դիրքը պարզելու TeamConnect-ի մասին
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Խորամանկ մտնեք ձեր կազմակերպության տարբեր տեղանքների կազմակերպական տեսքերին` TeamConnect-ի տեղեկատու բյուրոների կողմից։ Ամեն տեսքը մշակված է հեշտացնելու 
                  գրասենյակների հավաքածու ու ընկալումը մի եզակի կողմից.
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ցուցակային տեսք:</Body1Strong> Կանխարգելված տեղադրումը, որը պատկառորեն կազմակերպում է ամենագրասենյակների տեղանքները` 
                      հեշտ էլ կարդացելի ցուցակում:
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Մեծ քարտ տեսքը:</Body1Strong> Մեծ քարտ տեսքը տեղեկատու բյուրոն հարստացնում է ձեր տեսակետը `յուրաքանչյուր կարմիրի մանրամասն պատկերմամբ։ Կարմիրի քարտով հանդիպելիս, դուք կգտնեք շատ տվյալներ ու ակտիվ արտառոցներ՝
                      "Ավելին (...)" ընտրացանկի մեջ`
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Դիտեք բաժինները:</Body1Strong> Անցեք բոլոր գրասենյակում գտնվող բաժինների ցուցակին։
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Դիտեք աշխատակիցներին:</Body1Strong> Մուտքագրեք գրասենյակում նշանակված աշխատողների համախմբմամբ, աջակցելով 
                          անմիջակայուն կապ կամ գրասենյակի անձնակազմի ծանոթացման::
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Կոմպանիայիր կազմակերպական շարանային գրաֆիկը:</Body1Strong> Տեսարանական տեսահորիզոնում հասկացեք գրասենյակի տեղը ընդլայն կազմակերպության 
                          հիերարխիական կառուցվածքում:
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Հիմնական կապեր:</Body1Strong> Որոշեք գրասենյակի հիմնական կապերը՝ հեշտացնելու 
                          հարաբերակապը։
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Օֆիսի հանդես փառատոները:</Body1Strong> Թարմացվեք օֆիսի հատուկ փառատոների մասին` եզակի ճապակասելու ձեր 
                          գործիքները պլանավորելու համար։
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Օֆիսի աշխատաժամերը:</Body1Strong> Տեսեք օֆիսի աշխատանքի ժամերը, համակերպելու ձեր 
                          այցերը կամ զանգերը նրանց ակտիվ ժամերի հետ։
                        </li>
                      </ol>
                      Այս տեսքը հատուկված է ոչ միայն տեսահորիզոնական և տվյալների կլորացմանը, այլեւ արագ մուտքի համար `ավելին (...)" ընտրացանկից կատարված դրսևորումներին
                      համար։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Շարանային գրաֆիկի տեսք:</Body1Strong> Դիտեք հիերարխիական կառուցվածքը բուհերի ու ենթաբուհերի` ընկալելու ձեր 
                      կազմակերպության տարբեր գրասենյակների միջև կապը։
                    </li>
                  </ul>
                  Ցանկացած տեսքեր անցնելով, օգտագործեք պատկերների կողքին գտվող պատկերները `ձեր զննարկման փորձը կարելի է հարմարեցնել ձեր պատահական պահանջներին։
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Գտեք գրասենյակները արագ TeamConnect-ի որոնման ֆունկցիայի միջոցով
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect-ում գրասենյակի ճիշտ գտնվելու վայրի զրոյացումը հեշտացվում է հատուկ որոնման գործառույթի շնորհիվ: Գտնվում է տեսարանի կողքին
                  սրբապատկերներ, որոնման դաշտը ձեր գործիքն է գրասենյակների անուններով մատնանշելու համար: Մուտքագրելիս գրացուցակը ճշգրտում է էկրանը՝ ցույց տալով միայն համապատասխան գրասենյակները
                  ձեր ներդրումը, պարզեցնելով ձեր որոնման գործընթացը: Կարևոր է օգտագործել այս որոնման դաշտը TeamConnect-ում առավել ճշգրիտ և համապատասխան արդյունքների համար,
                  ի տարբերություն ընդհանուր Microsoft Teams որոնման: Այս նպատակային որոնման գործառույթով դուք կարող եք արագ նավարկել ձեր ցանկացած գրասենյակ
                  կազմակերպության ցանցը։
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Մոտենալ Գրադարանի Այբուբենիորեն
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Եթե ​​նախընտրում եք որոնել ըստ այբուբենի, մեր այբուբենական ֆիլտրը արագ միջոց է գրասենյակները հայտնաբերելու անունով։ Գրադարանի վերելակում, դուք կտեսնեք 
                  տառեր A-ից Z-ը։ Հպելով տառի վրա ֆիլտրը կփոխի տեսարանը՝ ցույց տալով միայն այն գրասենյակները, որոնց անվանումը այդ տառով է սկսվում, պարզեցնելով 
                  ձեր որոնման ընթացքը։ Հենցստեղծ բոլոր գրասենյակներին վերատեսականալու համար պարզապես ապատիժեք ակտիվ տառը։ Այս հատկանիշը շատ օգտակար է, երբ 
                  ծանոթ եք գրասենյակի անվանմանը, բայց պետք է արագ որոնեք այն TeamConnect-ում։
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Բացահայտելով Համալիր Գրասենյակի Պրոֆիլները TeamConnect-ում
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Սկսեք զբաղվածառայություն ձեր կազմախմբի ֆիզիկական ընկուզեղածների սրտում՝ TeamConnect-ի գրասենյակների պրոֆիլներով։
                  Հպելով գրասենյակի անվան վրա՝ բացվում է գույներով փայլուն պրոֆիլի դայալոգ, որտեղ տաբերի բազում են լցված լողացող գրասենյակների մանրամասներով՝
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Համառություն:</Body1Strong> Մտցվեք գրասենյակի իսկույնության հունը՝ մանրամասն 
                      նկարագրությամբ՝ իրավաբանության կառուցվածքից մինչև բացման ժամերն ու տեղական ժամարժեք, բռնելով գրասենյակի շրջապատքը։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Կարևոր Կոնտակտներ:</Body1Strong> Մուտք առնեք համատետերաց և արտաքին աջակցության 
                      կարևոր կոնտակտներին՝ մի հպումով Microsoft Teams Phone System-ում։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Գրասեյակի Կոնտակտներ:</Body1Strong> Եզակի համագործարկման սերվիսներին կից՝ 
                      կոնտակտային տվյալներով և առաջարկման հանուն։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Հանգիստի Օրեր:</Body1Strong> Խորհուրդալով գրադարանական հանգիստի օրերի 
                      տեղեկատվության մասին, մտածելով ձեր պլանները տեղական հուշականների և գործունեության գրաֆիկների համաձայն։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Գրասենյակի Ժամեր:</Body1Strong> Պլանավորեք ձեր արձակուրդը՝ իմանալով, թե երբ է 
                      գրասենյակը երաշտով բռնկվում կամ օրը ավարտվում։
                    </li>
                  </ul>
                  Նախագահենք Austin-ի առաջարկային էներգիային կամ Singapore-ի համախմբի ստրատեգիկ կենտրոնը, յուրաքանչյուր տաբ հատիկ է` տալով 
                  միջոցեր ընկալելու և կապվելու ձեր ընկերության չափազանց գեօգրաֆիան։
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  Հատուկ խորհդանշմամբով ծառայակազմերի ֆունկցիաները գրասենյակում
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Միասին գտնվող ծառայության բաժինների ընթացիքները TeamConnect-ում պարզ եւ հեշտ է. Ահա ինչպես խորըացուցիչ բուժեք դետալները՝
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Դիտեք այս գրասենյակում ծառայության բաժինները՝</Body1Strong> Անկախ բաժնեմասները, 
                      որոնք կազմում են գրասենյակի բաժինը՝ ընտրելով այս տարբերակը Ավելին (...) մենյուից։
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Օրգանիզացվող Դիտում՝</Body1Strong> Սեղմեք յուրաքանչյուր բաժնի վրա՝ հաստատելու 
                      դրա կազմաձևերի տեսչական ներկայացումը՝ հետևող բալուրի ճանապարհով՝ Գրասենյակ {'>'} Բաժին {'>'} Դիագրամ։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Բաժնի խորըացում՝</Body1Strong> Խորը դիտարկեք մի բաժին, սեղմելով Ավելին մենյուն (...)
                      բաժնի անվան կողքին։ Այստեղ, դուք կարող եք հասնել բաժնի այդ գրասենյակում աշխատող բոլոր աշխատակիցների ցանկին։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Նշանակալիցանելի նավիգացիա՝</Body1Strong> Հեռավորել տարբեր դասակարգերով։ Աշխատակցներից 
                      վերադառնալու դեպքում՝ բաժնեմասից կամ կազմաձևերից՝ հետևող բալուրի ճանապարհը (Գրասենյակ {'>'} Բաժին {'>'} Աշխատակից {'>'} Դիագրամ) կպահպանի 
                      ձեր ճանապարհը բնազդաբար եւ վստահելիորեն։
                    </li>
                  </ul>
                  TeamConnect-ի բարդ ուղիներն առաջարկում են ծառայակազմերի յուրական շերտերը հասկանալու, ընկալելու և զբաղվելու։ Գործառնական 
                  հրահանգումը ձեզ կամուրջ է տալիս։
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  TeamConnect-ում գրասենյակի աշխատակցներին հետ կապը ստեղծելը
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Հպարտանալու մեծաքանակ դեմքերին գրասենյակում: TeamConnect-ում պատասխանը միայն մի սեղմումով է: Շնաղարկեք եռակետի կոճակը 
                  ձեր ընտրած գրասենյակի անունից պաշտոնաբառին՝ ցույցելու ինտերակտիվ տարբերակների բազմություն:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Դիտելով Այս Գրասենյակում աշխատող մարդկանց:</Body1Strong> Չտալ այն բոլոր 
                      մասնագետներին, որոնք գնում են գրասենյակին իրենց գործառնական տուն։ Համարը հայտնված է, ցուցադրում է 
                      աշխատակցների արագ քանակը՝ հրավիրելով գրասենյակի համայնքին քաղում լուծածել:
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Կապի Ընտրանում:</Body1Strong> Սեղմվող առաջացած օգտագործվողներից զոված 
                      պարունակում է chats, calls կամ video conferences արագ-արագելականներով, չորրորդանսիկ և 
                      հեռահար շփումները TeamConnect-ում:
                    </li>
                    <li><Body1Strong>Նավիգացիա:</Body1Strong> Աշխատակցի ցանկից գրասենյակի առավել ցանկին վերադառնալուն: Սեղմելով 'գրասենյակ' 
                      բալուրի ճանապարհով (Գրասենյակ {'>'} Աշխատակից)։ Ձեզ կքաշի հետ գրասենյակի ակնարկին։
                    </li>
                  </ul>
                  Այստեղից TeamConnect-ը պարզ է նկարագրում գրասենյակների տարբերակիտները և հեշտությամբ կապել գրասենյակները, 
                  շփումն հասնում է առանց առնվազն մեկ հայրեն".
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38։ Ձեր գրասենյակի տվյալները բացակայում են կամ հինգարկում պահված են:
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Գրասենյակի տվյալները TeamConnect-ում կարող են թարմացվել նշված գրասենյակների խմբագրողների կողմից։ Եթե դուք չեք համոզված գրասենյակների խմբագրողների մասին կամ կարիք ունեք HR-ի տակ 
                  դասվող տվյալներով օգնությանը, HR աջակցման թիմը պատրաստ է օգնելու <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>։
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>կարգավորման հՏՀ</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39։ TeamConnect-ի ճշգրիտ Ընդհանուր Կարգավորման Առավելությունը
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Աշխատանքային միջավայրի այսօրվա դինամիկ կառուցվածքում՝ կապված մնալը և համակարգված աշխատանքը ավելի կարևոր են քան երբևէ։ Դրա մասնակիցները TeamConnect-ի Ընդհանուր Կարգավորման
                  թարմացման օրենքն են:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Աշխատավայրի Ընտրություններ:</Body1Strong> Ձեր գործառույթի էությունը որքան որ ճշգրիտ ցույց ենք տալիս, դուք նույնքան եք հեշտությամբ արտացոլում
                      ձեր գործառույթը։ Եթե դուք կողքից փոխեք Ձեր Աշխատավայրը՝ հույսի բացվածքից Կանչին կամ հանրային վայրերի։ Ձեր գործառույթը կարող է ուղեկցել ձեզ այդ տեղերում։
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Աշխատավայրի Մանրակարգեր:</Body1Strong> Ձեր աշխատաքաղաքը Համատեղելուց գալիս այնություն ունի որ վերաբացի շարժվող մենամարտերը կամ պարզապես սոցիալապես։ Եթե  
                      լինեք նույն քաղաքում, ապա այն կարող է փոխել ձեր օրվա ստանդարտերը։ Կապի համար չպետք է դիտում կլինի ձեր ժամանակի վայրը։
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ժամային գոտի Ճշգրտություն:</Body1Strong> Կապված գործողություններով, ճշգրիտ ժամանակի դասումը պետք է։ Ձեր TeamConnect պրոֆիլի Լոկալ Ժամը այս 
                      կարգավորմամբ է։
                    </li>
                  </ul>
                  Դուք կարող եք 'Կարգավորմանում'{'>'} 'Ընդհանուր Կարգավորում' TeamConnect-ում խմբագրեք այս տվյալները։ Այս տվյալների ուղղումն ազդակ։
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40։ Թիմայնակապ ընտրված աշխատանքային տեղը հասկանալը
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ընտրելով ճիշտ տեղը Աշխատանքային դաշտից ընտրացանկում ձեր TeamConnect-ը կիսում է ձեր ընթացիկ աշխատանքային վիճակը։ Տեղադրեք թռիչք տեղեկանիշի վրա յուրաքանչյուր տարբերակի մանրամասն բացատրության համար:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ստուգված դուրս/Լուսաբաց:</Body1Strong> Նշանակում է աշխատանքից հեռացումը, լուսաբաց կամ օրվա ավարտը։</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Գրասենյակ/Դաշտային գրասենյակ:</Body1Strong> Նշանակում օպերատորի առկա լինելը ընկերության հասարակական տարածքում։</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Տուն-Գրասենյակ:</Body1Strong> Աշխատում է նվիրված տնային գործառնական տարածքից։</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Հեռավոր Աշխատանքային Տեղ/Հանրային Տարածք:</Body1Strong> Օպերացիաներ անվճար գրասենյակային միջավայրից, պարզելով հարցաքննարկական կարիքը։</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Հաճախորդի Աշխատանքային Տեղ/Անձնական Գրասենյակ:</Body1Strong> Հաճախորդի կողմից տեղումումը՝ նշելով զրույցների կապահովության մակարդակները։</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ճամփորդություն/Բիզնես-Ճամփորդություն/Թրեյնինգ:</Body1Strong> Գործադիր շարժվելու կամ գրասենյակից դուրս հատուկ աշխատանքային գործառնականների զբաղվելու ժամանակ։</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Հանրային Առաջնորդ տոն/Տարեդարձ Ֆիքսելա/Հատուկ Բաց հետաձգումներ:</Body1Strong> Պաշտոնապես մինչև հատուկ ձևավորված է։</li>
                  </ul>
                  Տեղը ճիշտ ընտրելը ապահովում է, որ ձեր թիմը կողմնակալված լինի հարաբերություններում՝ հարգելով մասնակցականության և առանձնապես՝ անվանականությանը։
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41։ 'Ստուգված' կարգավիճակի ուղեկցությունը TeamConnect-ում
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  'Ստուգված' պիտակը Աշխատողների Դաստարանում նախադաշնակ է դրամանցի աշխատանքի վիճակին։ Աշխատանքի տեղ ընտրություն, որ ձեր տեսանելի կլինելը կախված է:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ակտիվ Աշխատային Նախադաշնակներ:</Body1Strong> Ընտրությունները Գրասենյակ, Դաշտային Գրասենյակ, Տուն-Գրասենյակ, Հեռավոր Աշխատանքային Տեղ, Հանրային Տարածք, Հաճախորդի Գրասենյակ, 
                    Հաճախորդի Անձնական Գրասենյակ, Ճամփորդություն, Բիզնես-Ճամփորդություն, և Թրեյնինգ նշանակում են, որ դուք հաշվիչական եք, իսկ դուք 'Ստուգված' և տեսանելի եք համալրողներին։
                  </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ոչ Աշխատանքային Տարածքներ:</Body1Strong> Օպտիմալականսները Ստուգված, Լուսաբաց, Հանրային Առաջացուցական, 
                    Վայրկյանորոշիչ, Համեղ, Մատյու-Հանգստանք, կամ Սպասարկում-Հեռացում կարգավիճակները հեռացնում են ձեզ 'Ստուգված' ցուցակից, նշանակելով դատարկ սպասումը և առաջարկի ժամանակները։
                  </li>
                </ul>
                Այս ֆունկցիոնալությունը օգնում է առանձնացնել աշխատանքային ժամանակաշրջանները անձնական ժամանակից՝ օգնելով արդյունավետ թիմային համագործակցությանը և հաղորդակցությանը:
                  </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Տվյալների ղեկավարում և գաղտնիության ՀՏՀ</Subtitle2>
        <Body1>
          <p>
            Այսօրվա թվային դարում կարևոր է հասկանալ, թե ինչպես են վարվում ձեր տվյալները։ TeamConnect-ը հավաստած է բացառիկ թափանցիկությանը և հզոր տվյալների պաշտպանման պրակտիկաներին։ 
            Ստորև կգտնեք տեղեկատվություն մասին, թե ինչպես ենք կառավարում, պահում և պաշտպանում ձեր տվյալները TeamConnect-ում։ Ձեր թվային աշխատամասը ապահով և համաձայնապատշաճ է։
          </p>
        </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Ինչպես է TeamConnect պաշտպանում իմ անձնական տվյալները:
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect-ը մոտեցում է անձնական տվյալների պաշտպանմանը տեղավորումամբ՝ ամբողջականորեն թափանցիկ:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Մեկ անվանումնական մուտք (SSO):</Body1Strong> Որպեսզի պաշտպանել անվտանգությունը և պարզապես օգտագործողի փորձը, TeamConnect-ը ինտեգրում է ավտոմատացված 
                      Մեկ անվանումնական մուտք (SSO) ձեր Microsoft 365 հաշվի հետ, համարժեք և հեշտ մուտքի կառավարումը:
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Կոդավորում:</Body1Strong> Մենք համապատասխանատված ենք, որ TeamConnect-ի բոլոր տվյալները փոխադրման և վառելափոխված պահեստո֯ւմ դեպքերում 
                      բացառիկորեն կոդավորված են, դարձնելով անհասանելի որևէ անլիցենզիատ անձանց։
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Տվյալների մինիմալում:</Body1Strong> Մեր տվյալների հավաքը խիստ սահմանված է այնպիսին, որ պետք է TeamConnect-ի ֆունկցիոնալությանը։ 
                      Ակտուալ ակնհայտ օգտագործողի տվյալները։
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Սահմանափակված մուտք:</Body1Strong> Մուտքը UPN-ին սահմանված է ձեր կազմակերպության Subscription Admin-ի մեր Subscription portal-ում, 
                      ապահովում է, որ շահագործական տվյալները երբեք չեն։
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Պատրաստ Տվյալների Հեռացում:</Body1Strong> TeamConnect-ը կազմավորված է հեռացնելու պահած UPN-ը երեք հատուկ պայմաններում՝ 
                      եթե բաժանոկոտումը համավարակման մեջ է մեր Subscription Admin-ի կողմից, եթե HR-ը հանում է օգտագործողին TeamConnect-ի մեջ, կամ եթե TeamConnect-ի 
                      բաժանոկոտումը պայմանական է՝ այսպիսով ապահովում է, որ ձեր անձնական տվյալները մնան։
                    </li>                      
                  </ul>
                  <p>Այս միջոցներով, TeamConnect-ը համաձայնված է տվյալների պաշտպանման ամենաբարձր կարգերի համար, ապահովելով, որ ձեր անձնական տվյալները մնան անվտանգ և գաղտնի։</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Պատրաստվածում եք գտնել, որտեղ է ձեր տվյալները TeamConnect-ում:
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect խախտում է ավանդական SaaS առաջարկների ձևանմուշը՝ ապահովելով, որ ձեր տվյալները երբեք չհասնեն ձեր կազմակերպությանը: Հակառակ հասարակ պրակտիկայի՝ տվյալներ պահելու արտաքին ամպային սերվերներում՝ TeamConnect ինտելեկտուալ կերպով օգտագործում է ձեր կազմակերպության Microsoft 365 SharePoint Online-ը՝ բոլոր հարթակի տվյալները պահելու համար: Այս նորարար մոտեցումը 
                  ոչ միայն աջակցում է տվյալների ազատությանը, այլև ամրապնդում է անվտանգությանը՝ 
                  ողջունականորեն համաչափվելով մեր անդուլաբար նվիրումը ձեր գաղտնիքի ապահովմանը և պահպանելով խիստ համաձայնման ստանդարտները:
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Ի՞նչ եք ասում, կիսվե՞ն իմ տվյալները որևէ երրորդ կողմին:
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ո՛չ, տվյալները կիսված են երրորդ կողմին: Cubeet-ը հաստատուն է մեր նվիրումներին ձեր տվյալների գաղտնիքին և անվտանգությանը։ Մենք չենք կիսում TeamConnect-ի որևէ տվյալ երրորդ կողմերի հետ՝
                  առանց ձեր հաճախորդներից բացառիկ համաձայնությանը չստանալով: Մեր անվտանգ միջավայրում, մենք պահում ենք միայն հիմնական ինդենտիֆիկատորներ, ինչպիսիք են User Principal Name (UPN), կազմակերպության անունը, տենանտի անունը 
                  և տենանտի ID, մեր Subscription պորտալում։ Այս տվյալները կարևոր են ծառայությունը տարածելու համար և ապահովված են մեր խիստ գաղտնիքի պայմանականներով։
                  Համոզված եղեք՝ մեր տվյալների ղեկավարման պրակտիկաները նախանշված են ձեր տվյալների գաղտնիությանը պահպանելու, միշտ պահպանելով այդպիսի թափանցիկության և կառավարման չափանիշեր:
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Ի՞նչպես է TeamConnect համալրվում տվյալների պաշտպանման կանոնարկմաններին, ինչպես է GDPR:
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect համալրվում է տվյալների պաշտպանման կանոնարկմաններին, ինչպիսիք են GDPR-ը, HIPAA-ն, CCPA-ն և այլները՝ 
                  օգտվելով Microsoft 365 միջավայրի ինքնատիպ հարմարանքի հատկություններից՝ որտեղ բոլոր հաճախորդների տվյալները պահված են անվտանգ։ Այս մոտեցումը ապահովում է, որ ձեր տվյալների ղեկավարությունը 
                  մնայի Microsoft 365-ի ամբողջական հարմարացման շրջանակի ներսում՝ հետևելով GDPR-ի, HIPAA-ի հետ դեղահատույցի ինֆորմացիայի, CCPA-ի հետ Քալիֆորնիայան 
                  բնակչների և այլ գլոբալ տվյալների պաշտպանման կանոնարկմաններին։ TeamConnect-ի անխափան ինտեգրացիան ձեր արդենական Microsoft 365 կարգավիճակին է՝ 
                  իրավունք տալով ձեր կազմակերպությանը սպառնալքին մնալ ձեր տվյալների վրա՝ պաշտպանելով ամենախիստ տվյալների ապահովման օրենքների և կանոնարկմանների:
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Inch vor baze e TeamConnect khandznum Microsoft Teams-i chatneri, zangler, videozangner, Outlook-e mailleri ev heraxosahamarneric andamneric?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect arajin usanoxneri mijazgayin bazman hamar ogtagorcel e Microsoft Graph API-n, vochr tarragrman chat, zang, videozang ev Outlook maileri Microsoft Teams-i mej, 
                  ogtakarar vochmi ardev drutyan pahpanman. Vchrhashel, erb TeamConnect sksel e ayn hayteri, an hartarum e ayn anjatman dproci mej chi pordzum, chi hartarum ev chi tagher 
                  vaxtnel bolor mijakayqneri vstahutyan. Bolor khandznverner, zangler ev mailler lsum en ev partadir aveliakhpiakan Microsoft Teams-i ev Outlook-i mej, Microsoft-365-i 
                  amboxj tsarhmanev arzhanman hogovneri pahapanum. Ayselov, TeamConnect pahpanelis dzer hartakts arajinsekian karevorutyunner, toxelov bolor hnaravorutyunnerov pahpanvum 
                  e dzer tvyalnere.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Vor hartsankal dzez bolor tvyalneri masin, patasxanneri ev tsarhanakneri hamar, dzez vstah klini hamadzaynen {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>TeamConnect-ի Oghjatsman Uzhandes</Body1Strong></Link> kam dzer kensayakan IT 
              departamenti het <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> pataskhananerov, vorpeszi masnaktsel dzer kargavorumy.
          </p>
        </Body1>        
        <p></p>  
      <Subtitle1 className={styles.fontColor}>Mer kerpov: Pacel dzer hartsazruycev ev gortser</Subtitle1>
        <Body1>
          <p>
            Dzer hartsannerum karevorutyun chunen TeamConnect-i paymannery. Khashvi tarekan gortsadramasnakan hartser ev patasxanner masin, mer ojakhn kgam katarants. Dzer 
            hartsannerum arzheke amena lav ardyunaktzin.
          </p>
        </Body1>      
      <Subtitle2>Hartsazruyc ev Gortsadramasnakan Usanoxutyunner</Subtitle2>
        <Body1>
          <p>
            Kpats ekrordakits? Azat tveq mer {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Hartsazruycneri ej
            </Link>
            . Hnchuma ardyoq nor gortsadramasnakan nshanakutyun kam patasxi masin steghtsamane matutsvox droshum. Dzez hartserum nkatel en TeamConnect-i tanjvogh paymannerj, 
            voronum arden nuynink tvyal pahpanneri.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;