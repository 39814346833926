import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>TeamConnect Súgóközpont és GYIK</Title3>
        <p><Body1>
          Üdvözöljük a TeamConnect Súgóközpontban, amelyet úgy terveztünk, hogy támogassa az Ön élményét cégünk átfogó vállalati címtár alkalmazásával. Itt olyan erőforrásokat talál, amelyek optimalizálják a TeamConnect használatát a Microsoft Teamsen belül, fokozva a kommunikációt hálózatán belül.
        </Body1></p>
        <Subtitle2>Gyors Referencia Útmutató</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Megnövelt Keresési Képességek: </Body1Strong>
              Gyorsan találja meg a megfelelő kollégákat, osztályokat és lényeges kapcsolatokat erőteljes keresőeszközeinkkel.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Vizuális Orgádiagramok: </Body1Strong>
              Könnyedén navigáljon szervezete hierarchiájában és értse meg a jelentési vonalakat intuitív orgádiagramok segítségével.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Adaptív Eszközök a Mai Igényekhez: </Body1Strong>
              Használjon modern funkciókat, amelyek az aktuális munkahelyi követelményekre szabottak.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Élményének Optimalizálása</Subtitle2>
        <Body1>
          <p>
            A TeamConnect forradalmasítja, ahogy kapcsolatba lép szervezeti struktúrájával, stratégiai együttműködéssé alakítva a mindennapi kommunikációt.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Segítségnyújtás és GYIK</Subtitle1>
        <Body1>
          <p>
            Találjon választ a TeamConnect jellemzőire és beállításaira vonatkozó gyakori kérdésekre ebben a dedikált szekcióban. Legyen szó az alkalmazás használatáról, beállítások testreszabásáról vagy kihívásokra való megoldások kereséséről, Súgóközpontunk itt áll az Ön szolgálatában, hogy hatékonyan segítsen.
          </p>
        </Body1>
        <Body1>
          <p>
            Mélyrehatóbb támogatásért vagy specifikus útmutatásért kérjük, tekintse meg a <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnect Felhasználói Útmutatót</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        
        <Subtitle2>A TeamConnect GYIK használata</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. Nem találja a TeamConnectet a Microsoft Teams oldalsávjában?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ha a TeamConnect nem látható vagy eltűnt a Microsoft Teams oldalsávjában, könnyen kitűzheti a gyors hozzáférés érdekében. Csak kövesse ezeket az egyszerű lépéseket:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Nyissa meg a Microsoft Teams alkalmazást és kattintson a '...' (+ Alkalmazások) ikonra az oldalsáv alján.</li>
                    <li className={styles.bulletBottomMargin}>Keressen a 'TeamConnect' kifejezésre a felugró ablak tetején lévő keresősávban.</li>
                    <li className={styles.bulletBottomMargin}>Kattintson jobb egérgombbal a keresési eredmények között szereplő TeamConnect alkalmazásra és válassza a 'Kitűz' opciót.</li>
                  </ol>
                  A TeamConnect most már kitűzve lesz az oldalsávra, így csak egy kattintással elérhető!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Technikai problémák merülnek fel a TeamConnecttel kapcsolatban? Íme, hogyan kérhet segítséget.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ha technikai problémába ütközik a TeamConnect használata során a Microsoft Teamsen belül, a szervezetének IT támogató csapata készen áll a segítségre.
                  Vegye fel velük a kapcsolatot itt: <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, és ők azonnal segítenek Önnek.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Nem találja a munkavállalói adatait?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ha úgy érzi, hogy a profilja üresnek tűnik, csupán a nevével és email címével, valószínűleg az Ön HR csapata még nem adta hozzá a többi munkavállalói adatot a TeamConnecthez.
                  Ne aggódjon! Egy gyors üzenet az Ön HR támogató csapatának elindítja a folyamatot. Készen állnak arra, hogy profilját minden fontos adattal kiegészítsék.
                  Vegye fel velük a kapcsolatot itt: <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, és nézze, ahogy profilja életre kel!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Milyen nyelvi lehetőségek érhetők el a TeamConnectben?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A TeamConnect támogatja a Microsoft Teams globális sokszínűségét, mivel
                  minden nyelvet támogat. Akár arabul vagy görögül, japánul vagy svédül
                  szeretnél, rengeteg lehetőség közül választhatsz, köztük:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arab, bolgár, kínai (egyszerűsített és hagyományos), horvát, cseh,
                      dán, holland, angol, észt, finn, francia, német, görög, héber, magyar,
                      indonéz, olasz, japán, koreai, lett, litván, norvég (Bokmal), lengyel,
                      portugál (Brazília és Portugália), román, orosz, szerb (latin),
                      szlovák, szlovén, spanyol, svéd, thai, török, ukrán, vietnámi.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Annak érdekében, hogy a TeamConnect élményét a preferált nyelvedre
                  szabhasd, kérlek állítsd be a nyelvi beállításokat közvetlenül a Microsoft
                  Teamsben, mivel a TeamConnect teljes mértékben integrálódik ezekhez a
                  beállításokhoz:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Menj a Microsoft Teams beállításaihoz, kattintva a profil képed
                      melletti három pontra, aztán válaszd az 'Megjelenés és
                      hozzáférhetőség' opciót.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Görgess le a 'Nyelv' szekcióhoz és válaszd ki a preferált nyelvet a
                      legördülő menüből.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Erősítsd meg a változtatásokat a 'Mentés és újraindítás' gombra
                      kattintva, hogy alkalmazd az új nyelvi beállításokat.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Megjegyzés: </Body1Strong>
                <Body1>
                  A Microsoft Teams nyelvi beállításainak módosítása zökkenőmentesen
                  frissíti a TeamConnect élményedet is. Legyél tisztában azzal, hogy a Teams
                  nyelvének megváltoztatása szintén módosíthatja az idő és dátum formátumot.
                  Jelenleg a TeamConnect fejlődik a jobbról balra írás támogatásához.
                  Izgatottan várjuk ezeket a fejlesztéseket és folyamatosan tájékoztatunk,
                  ahogy elérhetővé válnak.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Hogyan változtathatom meg a témát a TeamConnectben?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A TeamConnect szinkronizálja a Microsoft Teams témabeállításait, beleértve a Világos, Sötét és Nagy kontraszt opciókat. A téma testreszabásához:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Férj hozzá a Microsoft Teams beállításaihoz, kattintson a profil képed melletti három pontra a tetején.</li>
                    <li className={styles.bulletBottomMargin}>Válaszd a 'Megjelenés és hozzáférhetőség' lehetőséget a téma opciók felfedezéséhez.</li>
                    <li className={styles.bulletBottomMargin}>Válaszd ki a preferált témát a rendelkezésre álló opciók közül, hogy azonnal frissítsd a TeamConnect felületedet, hogy megfeleljen.</li>
                  </ol>
                  A Microsoft Teamsben kiválasztott témád automatikusan alkalmazásra kerül a TeamConnectben, biztosítva egy konzisztens vizuális élményt az egész munkaterületeden.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. A TeamConnect Kapcsolat Akció Gombjainak Felfedezése
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A TeamConnecten belül, ahol csak alkalmazottak elérhetőségi adatait találod, felfedezheted kényelmes gyorselérési gombjainkat is. Ezek a hasznos eszközök 
                  arra vannak tervezve, hogy egyszerűsítsék a kommunikációt a Microsoft ökoszisztémán belül, biztosítva, hogy mindig csak egy kattintásnyira legyél a kapcsolódástól.
                  Íme a teljes útmutató ezeknek a gomboknak az hatékony használatához:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chat Ikon:</Body1Strong> Kattints a Microsoft Teams azonnali csevegés indításához. Ha vissza kell térned a TeamConnectbe a csevegés elindítása után, egyszerűen használd a vissza nyilat az asztali alkalmazásban, a vissza gombot a mobilalkalmazásban, vagy a böngésződ vissza gombját.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Email Ikon:</Body1Strong> Megnyit egy új Outlook e-mail vázlatot a címzett címével készen a "To:" mezőben. Ha az Outlook nem indul el, érdemes lehet az IT részleggel ellenőrizni a korlátozásokat.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Videohívás Ikon:</Body1Strong> Videóhívást indít a Microsoft Teams használatával.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Hívás Ikon:</Body1Strong> Egy Microsoft Teams hanghívást indít, megkönnyítve a gyors megbeszéléseket.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Szervezeti Diagram Ikon:</Body1Strong> Megmutatja az alkalmazott helyét a szervezeti hierarchiában, beleértve a menedzserét és közvetlen jelentéseit.</li>
                    <li><Body1Strong>További (...) Ikon:</Body1Strong> További lehetőségeket tár fel:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Azonnal Találkozó:</Body1Strong> Megnyitja a Microsoft Teamset, hogy azonnali találkozót kezdjen az alkalmazottal.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Új Találkozó:</Body1Strong> A Microsoft Teamsre irányít, hogy új találkozót ütemezzen.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Kapcsolat Hozzáadása:</Body1Strong> Hozzáadja az alkalmazott kapcsolati adatait a TeamConnectből az új Microsoft Teams Emberek alkalmazásodhoz, gazdagítva a hívás funkciódat.</li>
                      </ul>
                    </li>
                  </ul>
                  Ezek az intuitív gombok úgy vannak megtervezve, hogy hatékonyan tudj kapcsolatba lépni kollégáiddal, akár egy gyors csevegéshez, egy e-mailhez, vagy a jövőbeli találkozók ütemezéséhez.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Hívások indítása a TeamConnecten keresztül
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Hívást szeretnél indítani? A TeamConnect egyszerűsíti a kommunikációt a Microsoft Teams integrációjával.
                  Egyszerűen kattints a kívánt telefonszámra a TeamConnecten, és íme, a Microsoft Teams hívófelülete felugrik,
                  kész arra, hogy zökkenőmentesen tárcsázz.
                  <p>
                  <Body1Strong>Megjegyzés: </Body1Strong> 
                  Problémába ütközöl a hívások indításával? Ez a szervezeted IT irányelveinek vagy egy másik, mint a Microsoft Teams Phone System telefonrendszere használatának következménye lehet. Az IT részlegnél történő érdeklődés a <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> segíthet a helyzet tisztázásában.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Vannak költségek a TeamConnecten keresztüli hívások kezdeményezésével kapcsolatban?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A TeamConnect javítja a hívásokkal kapcsolatos élményt azáltal, hogy kihasználja a Microsoft Teams-et. Íme, amit tudnia kell a hívásokkal kapcsolatos költségekről:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Az alkalmazottak munkatelefonjaira, osztálytelefonokra vagy belső segélyvonalakra történő hívások esetén 
                      (belső számok) általában <Body1Strong>nincsenek költségek</Body1Strong> a Microsoft Teams Telefon Rendszer használatakor.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Külső hívások esetén, mint például mobiltelefonokra vagy külső vonalakra, a szervezet telefonrendszerének <Body1Strong>szabvány díjai</Body1Strong> érvényesülnek.
                      Ezek az árak változhatnak attól függően, hogy milyen szolgáltatási csomagot választ a Microsoft Teams-szel.
                    </li>
                  </ul>
                  <p><Body1Strong>Megjegyzés:</Body1Strong> Ha problémákat észlel vagy kérdései vannak a hívási díjakról, a legjobb, ha az
                    IT-osztállyal konzultál <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> részletes információkért, 
                    amelyek az Ön szervezetének beállításaira és szabályzataira szabottak.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Észrevettél egy eltérést a 'Távolléti' állapotodban a TeamConnectben?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Észrevetted, hogy a Microsoft 365 'Távolléti' jelenlét állapota nem tükröződik megfelelően a TeamConnectben?
                  Gondoskodtunk róla. A 'Távolléti' jelenlét állapotának szinkronizálására szolgáló funkció már bevezetésre került a TeamConnectben.
                  Jelenleg a Microsoft ezen funkció kiadására várunk. Amint ez megtörténik, a 'Távolléti' jelenléti állapotod 
                  pontosan megjelenik a TeamConnectben, összhangban a Microsoft Teams jelenlétindikátorokkal a zökkenőmentes élmény érdekében.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Gyakran Ismételt Kérdések az Alkalmazotti Könyvtárral Kapcsolatban</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Alkalmazotti Könyvtár Bemutatása:</Body1Strong> Manőverezzen szervezete szakmai táján az Alkalmazotti Könyvtárral,
                központi központja a részletes alkalmazotti betekintéseknek, munkahelyi dinamikáknak, és közvetlen kapcsolattartásnak kollégái profiljaival és menedzseri kapcsolataival.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Szükséges hozzáadni, frissíteni vagy korrigálni az alkalmazotti adataidat?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Az alkalmazotti adatok hozzáadásához, frissítéséhez vagy korrekciójához a TeamConnectben, kérjük, vegye fel a kapcsolatot szervezete HR támogató csapatával.
                  Készen állnak a segítségre <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Mi történik, amikor rákattintok egy alkalmazott nevére a címtárban?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Egy alkalmazott nevére kattintva a TeamConnect Alkalmazotti Címtárban megnyílik az adott személy részletes profiloldala. Ez a profil átfogó betekintést nyújt az alkalmazottal kapcsolatban, beleértve, de nem kizárólagosan, az állás megnevezését, a részleget, az irodai helyszínt, a kapcsolattartási információkat és egyebeket. Az alkalmazott szakmai szerepének és szervezeten belüli helyének teljes áttekintését nyújtja, megkönnyítve számodra megérteni az alkalmazott felelősségeit és azt, hogy hogyan működhetnétek együtt.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Milyen információk szerepelnek egy alkalmazott profiljában?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Minden alkalmazotti profil a TeamConnectben egy átfogó pillanatkép, amely gazdag betekintést nyújt az egyén szakmai életébe. 
                  Íme, amit megtalálhatsz:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Alapadatok:</Body1Strong> Név, állás megnevezése, részleg és leányvállalat helyszíne.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Jelentési szerkezet:</Body1Strong> Információk a közvetlen és funkcionális jelentésekről, 
                      beleértve a vonal- és funkcionális vezetőket.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Munkahelyi Státusz:</Body1Strong> Jelenlegi munkahelyi státusz, mint Szabadságon, Ingázik, 
                      Üzleti utazás, stb.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Munkahely & Helyi Idő:</Body1Strong> Az alkalmazott jelenlegi munkahelye és 
                      helyi időzónája.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kapcsolattartási adatok:</Body1Strong> Munkahelyi telefon, mobil szám és email cím.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Irodai cím:</Body1Strong> Teljes irodai helyszín, beleértve az épület nevét,
                      címet és Google térképet.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Rólam:</Body1Strong> Rövid személyes bemutatkozás vagy szakmai önéletrajz.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Alkalmazotti kapcsolatok:</Body1Strong> Információk az alkalmazott jelentési 
                      szerkezetéről és csapaton belüli kapcsolatairól.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Szociális hivatkozások & Nyelvek:</Body1Strong> Hivatkozások a szakmai közösségi profilokra 
                      és nyelvtudás.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Készségek:</Body1Strong> A szakmai készségek és szakterületek listája.</li>
                  </ul>
                  Ráadásul a profil gyakorlati lehetőségeket kínál a kapcsolatfelvételre csevegés, email, videóhívás vagy értekezletek tervezése révén, valamint 
                  gyors hozzáférést nyújt a szervezet diagramjához a szélesebb csapat kontextusához.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Hogyan frissíthetem a profilinformációimat?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A profilod személyre szabása egyszerű a TeamConnectben. Íme, hogyan teheted meg:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Navigálj az <Body1Strong>Alkalmazott</Body1Strong> fülre és kattints a <Body1Strong>Avatarodra</Body1Strong> a profilod megnyitásához.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        A bal felső sarokban megtalálod a <Body1Strong>"Profilod szerkesztése"</Body1Strong> linket.
                        A kattintás megnyitja a profil szerkesztő párbeszédpanelt.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Itt tovább finomíthatod a profilodat azzal, hogy hozzáadsz vagy frissítesz információkat a <Body1Strong>Rólad szóló</Body1Strong> szekcióban, a közösségi média linkeket és a telefonszámot.
                        Ez megkönnyíti a kollégáid számára, hogy kapcsolatba lépjenek veled vagy követni tudjanak.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Ne felejtsd el megnyomni a <Body1Strong>Mentés</Body1Strong> gombot a változtatások alkalmazásához.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Görgess le a párbeszédpanelen, hogy hozzáadd a <Body1Strong>Nyelveket</Body1Strong> és a <Body1Strong>Tudásszintet</Body1Strong>,
                        valamint a <Body1Strong>Készségeket</Body1Strong>. Ennek megosztása segíti a kollégáidat abban, hogy megértsék
                        milyen szakterületeken vagy jártas és milyen nyelveken tudsz kommunikálni.
                      </li>
                    </ol>
                  A profilod frissítése nemcsak az információidat tartja naprakészen, hanem elősegíti a jobb kapcsolatok kialakítását szervezeteden belül.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Munkahelyed, munkaállomásod és időzónád frissítése a TeamConnectben
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Alapvető fontosságú, hogy naprakész információkkal rendelkezz a munkahelyedről, munkaállomásodról és időzónádról a zökkenőmentes együttműködés érdekében a Microsoft Teamsben.
                  Ezeket a frissítéseket azonnal tükrözi a TeamConnect profilodban és listázásaidban, lehetővé téve a kollégáid számára, hogy gyorsan megértsék a munkavégzés helyét
                  és a kapcsolatfelvételhez legjobb időpontokat, javítva ezzel a különböző régiók és időzónák közötti együttműködést.
                  <p></p>
                  Kész vagy a részletek frissítésére? Egyszerű a TeamConnectben! Menj a 'Beállítások' menüpontra a felső menüben és navigálj a 'Általános beállítások' menüpontra.
                  Itt zökkenőmentesen frissítheted a Munkahelyedet és Időzónádat, és megadhatod a napi Munkaállomásodat. Ne felejtsd el menteni a változtásokat,
                  hogy mindenki tájékozott legyen és hatékony legyen az együttműködés, függetlenül a helyzetedtől!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Valami furcsát vettél észre egy kolléga profiljában?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ha olyan részleteket találsz egy kolléga profiljában, amelyek nem tűnnek helyesnek, a legjobb első lépés egy barátságos figyelmeztetés.
                  Egy gyors csevegés vagy email megteszi a trükköt! Ha valamilyen oknál fogva az információ nem frissül, ne aggódj—csak 
                  jelezd az HR támogató csapatodnak a <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, és ők szívesen
                  beavatkoznak és rendbe teszik a dolgokat.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Címkecsoportok megértése: Összes, Kedvencek, Bejelentkezett és Új alkalmazottak
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Összes:</Body1Strong> Ez az alapértelmezett címke megjeleníti az összes alkalmazottat a szervezetben,
                      így átfogó képet nyújt a csapatról.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kedvencek:</Body1Strong> Kattints ide a nézet szűréséhez azon kollégákra, akiket 'Kedvencek'ként jelöltél,
                      így könnyebbé téve a kulcsfontosságú kapcsolatok gyors elérését.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Bejelentkezett:</Body1Strong> Ez a címke a munkában aktívan részt vevő alkalmazottakat jelzi,
                      megkülönböztetve őket a pusztán online jelenléttől a Microsoft Teams-en. Bejelentkezéskor az alkalmazottak jelezik, hogy munkában vannak, és megosztják aktuális munkahelyüket 
                      a nap folyamán. Ez magában foglalhat különböző munkahelyeket, mint az Iroda, Terepi Iroda, Home-Office, Távmunkahely, Ügyfél Munkahely,
                      Ügyfél Privát Iroda, Nyilvános Hely, Ingázás, Üzleti Utazás vagy akár Képzés. Így a 'Bejelentkezett' státusz egyértelműen jelzi,
                      hogy aktívan dolgoznak, lehetővé téve a kollégák számára, hogy pontosan értsék egymás aktuális munkakörülményeit és elérhetőségét.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Új alkalmazottak:</Body1Strong> Fedezd fel az új arcokat a TeamConnectben ezzel a címkével, amely kiemeli
                      azokat az alkalmazottakat, akik az utóbbi 30 napban lettek hozzáadva az alkalmazáshoz. Ez általában összhangban van a szervezet legutóbbi bővítésével, segítve
                      az új csapattagok köszöntését.
                    </li>
                  </ul>
                  Ezek a címkecsoportok arra lettek tervezve, hogy egyszerűsítsék a navigációt és javítsák a TeamConnect élményt, biztosítva, hogy mindig 
                  hatékonyan tudj kapcsolódni a kollégáidhoz.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. A keresőmező melletti ikonok dekódolása
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A TeamConnect alkalmazottjainak címtára sokoldalúságra és könnyű használatra lett tervezve, négy ikont tartalmaz, amelyek különböző nézeteket kínálnak
                  és gyors hozzáférést nyújtanak a kulcsfontosságú részletekhez. Íme, mit tár fel mindegyik ikon:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lista Nézet:</Body1Strong> Az alapértelmezett nézeted, amely minden szervezeti tagot 
                      egyenes listában mutat be.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kis Kártya Nézet:</Body1Strong> Egy kompakt alternatíva, ez a nézet a listát kisebb méretű alkalmazotti kártyákra alakítja át.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nagy Kártya Nézet:</Body1Strong> Válassz több részletet tartalmazó nagyobb kártyákat, amelyek 
                      további kapcsolatfelvételi lehetőségeket is tartalmaznak, mint például telefon- és mobiltelefonszámokat a könnyű tárcsázáshoz.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Szervezeti Diagram:</Body1Strong> Ábrázold a cég szerkezetét a Szervezeti Diagram nézettel,
                      amely tökéletes az jelentési kapcsolatok megértéséhez és a szervezeti tájban való tájékozódáshoz.</li>
                  </ol>
                    Váltogass ezek között az ikonok között, hogy megtaláld az aktuális igényeidnek leginkább megfelelő nézetet, legyen szó gyors információkeresésről vagy mélyebb betekintésről.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Hol kereshetek gyorsan kollégát?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Kollégát keresel? Csak menj a TeamConnect keresőmezőjéhez, amely a jobb felső sarokban található a Szervezeti diagram ikon mellett.
                  Kezdd el gépelni a nevüket, és figyeld, ahogy az eredmények finomodnak minden betűvel. Ne feledd, a TeamConnect kereséseknél hagyd ki a Microsoft Teams 
                  keresősávját a tetején – a mi különleges keresőmezőnk a megfelelő választás!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Hogyan működik az ABC szerinti keresés az alkalmazottak megtalálásához?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A TeamConnect Alkalmazotti Adattárában található hasznos ABC szerinti keresési funkció segít gyorsan megtalálni az alkalmazottakat keresztnév alapján.
                  Egyszerűen kattintson az adattár tetején megjelenített ábécé egyik betűjére, és a TeamConnect azonnal megmutatja azokat az alkalmazottakat,
                  akiknek a keresztneve ezzel a betűvel kezdődik. A teljes alkalmazottak listájához való visszatéréshez csak szüntesse meg a kiemelt betű kijelölését. Ez az intuitív
                  funkció lehetővé teszi a nagy alkalmazotti listák könnyű áttekintését, biztosítva, hogy gyorsan és hatékonyan találja meg kollégáit.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Hogyan állíthatom be az oldalanként megjelenített alkalmazottak számát?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A TeamConnect lehetővé teszi, hogy testre szabhassa, hány alkalmazotti profil jelenjen meg oldalanként, megkönnyítve az Alkalmazotti Adattárban való navigálást.
                  Az egyes oldalak alján található oldalszámozási opciók: 10, 50, 100, 500, 1000 és 5000. Az alapértelmezett beállítás 10 profil megjelenítését teszi lehetővé oldalanként.
                  Több profil megtekintéséhez egyetlen oldalon egyszerűen válassza ki a kívánt számot ezek közül az opciók közül. Ez a funkció rugalmasságot biztosít az adattár böngészésében,
                  lehetővé téve, hogy a kijelzőt a saját igényeihez vagy preferenciáihoz igazítsa.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Hogyan rendezhetem az alkalmazottak adatait a Lista Nézetben?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A TeamConnect Alkalmazotti Adattár Lista Nézetében rugalmasan rendezheti az alkalmazottak adatait az Ön igényei szerint. Az oszlopok—Alkalmazott neve,
                  Munkakör, Osztály, Irodai helyszín és Munkahely—mind rendezhetőek. Egyszerűen kattintson arra az oszlopfejlécre, amely szerint rendezni kíván. Egyszeri kattintással
                  az oszlop növekvő sorrendbe rendeződik, és ismételt kattintással csökkenő sorrendbe. Alapértelmezés szerint az adattár az Alkalmazott neve szerint van rendezve
                  növekvő sorrendben, a keresztnév alapján. Ez a rendezési funkció segít gyorsan szervezni és megtekinteni az alkalmazotti adatokat oly módon, amely a legjobban megfelel
                  a céljainak, legyen szó konkrét személy kereséséről vagy alkalmazottak bizonyos kritérium szerinti csoportosításáról, mint az osztály vagy helyszín.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Osztályok Könyvtárának GYIK-je</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Osztályok Könyvtárának Bemutatása:</Body1Strong> Az Osztályok Könyvtára az Ön kapuja szervezete bonyolult szerkezetének megértéséhez, tiszta képet nyújtva az osztályok hierarchiájáról, a vezetői vezetésről és a zökkenőmentes osztályközi kommunikáció útjairól.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Hogyan működnek az 'Összes', 'Kedvencek' és 'Az Ön Osztálya' címkék az Osztályok Könyvtárában?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A TeamConnect Osztályok Könyvtárában a címkék segítenek gyorsan navigálni és szűrni az osztályinformációk között. Íme, mit jelképez egyes címkék:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Összes:</Body1Strong> Ez a címke az összes osztályt megjeleníti szervezetén belül, biztosítva egy átfogó áttekintést.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kedvencek:</Body1Strong> Ezzel a címkével megtekintheti azokat az osztályokat, amelyeket 'Kedvencek'-ként jelölt meg, megkönnyítve a gyakran interakcióba lépő osztályok elérését.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Az Ön Osztálya:</Body1Strong> Ez a címke szűri a nézetet, hogy csak az Ön osztályát mutassa, lehetővé téve az azonnali hozzáférést a közvetlen osztályinformációkhoz és frissítésekhez.</li>
                  </ul>
                  Ezek a címkék arra lettek tervezve, hogy egyszerűsítsék a navigációt az Osztályok Könyvtárában, fokozva képességét az osztályinformációk hatékony felkutatására és kezelésére.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. Az Osztályok Könyvtárának megértése a TeamConnectben
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Az Osztályok Könyvtárában való navigáció a TeamConnectben egyszerűsítve van több nézeti lehetőséggel, amelyek illeszkednek az Ön preferenciáihoz. Minden nézet egyedi módon kínál betekintést az átfogó osztályinformációkba, beleértve az Osztály Neve, Osztályvezető, Szülő Osztály, Osztály Telefonja, Osztály Segélyhívója és Osztály E-mail címe. Íme egy pillanatkép az elérhető nézetekről:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lista Nézet:</Body1Strong> Az alapértelmezett beállítás, ami rendezetten listázza az összes osztályt, biztosítva egy tiszta, tömör áttekintést.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nagy Kártya Nézet:</Body1Strong> Váltson erre a nézetre a részletes osztálykártyák megtekintéséhez, amelyek bővebb összefoglalást nyújtanak minden osztály kulcsinformációiról.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Szervezeti Diagram Nézet:</Body1Strong> A szervezeti struktúra és kapcsolatok vizuális ábrázolásához, a Szervezeti Diagram nézet bemutatja, hogy az egyes osztályok hogyan illeszkednek a szélesebb szervezeti hierarchiába.
                    </li>
                  </ul>
                  A nézetek közötti váltáshoz egyszerűen használja a keresősáv melletti ikonokat az Osztályok Könyvtárának tetején. Minden nézet úgy van kialakítva, hogy segítse Önt az osztályok részleteinek könnyű hozzáférésében és megértésében, biztosítva, hogy a szükséges információkat a legmegfelelőbb formátumban találja meg.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Hogyan használjuk a keresőmezőt az Osztálykönyvtárban
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Zökkenőmentesen találja meg az adott osztályt a TeamConnectben az erre kijelölt keresőmezővel, amely közvetlenül a megtekintés ikonok mellett található az Osztálykönyvtár tetején. Egyszerűen kezdje el begépelni a keresett osztály nevét, és figyelje, ahogy a könyvtár magától finomítja a kijelzést, hogy csak a releváns találatokat mutassa. Ez a funkció kifejezetten azért lett kialakítva, hogy megkerülje a szükséget az egész könyvtár kézi átnézésére, közvetlenül az ujjai hegyére hozva az ön által szükséges osztályt. Ne feledje, ez a keresési funkcionalitás egyedül a TeamConnect Osztálykönyvtárában található, tehát használja ezt a keresőmezőt a Microsoft Teams legfelső részén található fő keresés helyett az osztályszepecifikus keresésekhez.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Hogyan működik az ábécé-szűrő az Osztálykönyvtárban?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Egyszerűsítse a keresést az Osztálykönyvtárban az ábécé-szűrővel, amely a tetején található. Ez a funkció lehetővé teszi, hogy gyorsan leszűkítse az osztályokat azáltal, hogy kiválaszt egy betűt. Amint kiválasztott egy betűt, a TeamConnect szűri és csak azokat az osztályokat jeleníti meg, amelyek neve az ön által választott betűvel kezdődik, megkönnyítve így a keresést anélkül, hogy végig kellene görgetnie az egész listán. A teljes osztálynézet visszaállításához egyszerűen szüntesse meg a kiemelt betű kijelölését. Ez az intuitív ábécés navigáció biztosítja, hogy hatékonyan megtalálhassa az adott osztályt kezdőbetűje alapján.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Hogyan léphetek kapcsolatba az osztályvezetővel?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Az osztályvezetővel való kapcsolatfelvétel a TeamConnectben egyértelmű. Egyszerűen találja meg az érdekelt osztályt és kattintson az osztályvezető nevére. Ez az akció előidézi egy felugró ablak megjelenését, amely megmutatja az osztályvezető elérhetőségi opcióit az ismert gyors hozzáférésű ikonokkal:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Cset ikon:</Body1Strong> Azonnali üzenet indítása a Microsoft Teams cseten keresztül.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Email ikon:</Body1Strong> Új e-mail megnyitása, címezve nekik a Microsoft Outlookban.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Videohívás ikon:</Body1Strong> Videóhívás kezdeményezése a Microsoft Teamsen keresztül.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Hívás ikon:</Body1Strong> Hanghívás kezdése a Microsoft Teams használatával.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Több (...) ikon:</Body1Strong> További opciókhoz, mint például értekezlet ütemezése vagy kapcsolat információik hozzáadása a Teams listájához.</li>
                  </ul>
                  Ezek az ikonok egy zökkenőmentes módszert biztosítanak az osztályvezetőkkel való közvetlen kapcsolattartásra a TeamConnecten belül, biztosítva a hatékony kommunikációt szervezetén belül.
                </Body1>
              </AccordionPanel>
            </AccordionItem>            
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Hogyan tekinthetem meg vagy léphetek kapcsolatba a részlegetekben dolgozó alkalmazottakkal?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A TeamConnect részlegében dolgozó személyek megismeréséhez csak keresd meg a három pontot a részleg neve mellett. Ezen kattintva további műveletek jelennek meg:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>A részlegben dolgozó alkalmazottak megtekintése:</Body1Strong> Válaszd ezt az opciót az adott részleg összes munkatársának megjelenítéséhez. A lehetőség mellett szereplő szám jelzi, hány munkatárs tartozik a részleghez, így gyors áttekintést nyújtva.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Részleg Szervezeti Ábrája:</Body1Strong> Ha meg akarod érteni a részleg hierarchiáját, válaszd a 'Részleg szervezeti ábrája' opciót a strukturális felépítés megjelenítéséhez, ami bemutatja a szülő részlegek, az adott részleg és az alrészlegek közötti kapcsolatokat. Ez a vizuális ábrázolás segít navigálni és megérteni a szervezeti keretrendszert, megkönnyítve az egyes részlegtagok azonosítását és elérését.
                    </li>
                    <li><Body1Strong>Részleg {'>'} Alkalmazott:</Body1Strong> Miután megtekintetted a részlegen belüli alkalmazottakat, használd ezt a morzsalék-ösvényt a részleglista könnyű visszanavigálásához, fenntartva a TeamConnecten belüli zökkenőmentes felfedezési élményt.
                    </li>
                  </ul>
                  Ezek a funkciók lehetővé teszik, hogy nemcsak megtekinthesd a részlegen belüli alkalmazottak listáját, de közvetlen kapcsolatfelvételi lehetőséget is kínáljanak a TeamConnect kommunikációs eszközein keresztül.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. A részleg részletes adatainak felfedezése a TeamConnectben
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Merülj el bármely részleg részleteiben egy egyszerű kattintással a nevére a TeamConnectben. Egy részletes párbeszéd bontakozik ki, gazdag információs kínálattal, ami teljes képet fest a részleg szerepéről és erőforrásairól szervezetedben. Mindent megtalálsz a vezetőségtől és pénzügyi azonosítóktól kezdve a kommunikációs csatornákig és a közösségi linkekig. Ez a funkció a kapud nyitja meg:
                  <ul>
                    <li className={styles.bulletBottomMargin}>A részleg szíve – célja, emberei és teljesítménymutatói.</li>
                    <li className={styles.bulletBottomMargin}>Kapcsolatfelvételi utak – közvetlen vonalak a kulcsfontosságú részlegbeli személyekhez és kommunikációs platformok.</li>
                    <li className={styles.bulletBottomMargin}>A szervezeti háló – megértés, hogyan illeszkedik a részleg a szélesebb cégstruktúrába.</li>
                  </ul>
                  Legyen szó gyors érdeklődésről vagy mélyreható részlegadatok elemzéséről, a TeamConnect felvértez téged az eszközökkel, hogy kapcsolatba léphess és megérthesd szervezeted egyedi ökoszisztémáit.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Kíváncsi vagy, miért nincsenek címek a részlegeknél a TeamConnectben?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Egy részleg helyszínének felfedezése néha olyan, mint egy térkép nélküli kincskeresés. A TeamConnectben általában nem találsz külön címet minden egyes részleghez. Íme, miért hagyják ki gyakran ezt a térképi részletet:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Több Helyszín:</Body1Strong> Sok részleg nem korlátozódik egyetlen térre; különböző irodákban és földrajzi helyeken virágoznak.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Folyékony Szerkezet:</Body1Strong> A szervezeti táj folyamatosan változik, a részlegek fejlődnek, egyesülnek vagy akár megszűnnek a stratégiai változásokkal összhangban.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Osztályok közötti Együttműködés:</Body1Strong> A modern munkahelyek az együttműködési szellemmel pezsegnek, gyakran ötvözve különböző részlegek tehetségeit megosztott terekben.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Távmunka Kultúrája:</Body1Strong> A távmunka rugalmasságával a részlegek nem mindig rendelkeznek fizikai irodahelyekkel.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Jogi Árnyalatok:</Body1Strong> A részlegek általában a cég jogi szerkezetének körül forognak, nem rendelkeznek saját önálló címekkel.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Működési Egyszerűsítés:</Body1Strong> A dinamikus csapatteljesítményre, nem statikus címekre összpontosítva segít fenntartani az üzemeltetési mozgékonyságot.
                    </li>
                  </ul>
                  Minden a határok nélküli csapatmunka és hatékonyság elfogadásáról szól. A TeamConnect ezt a filozófiát tükrözi, hangsúlyozva a kapcsolatokat a helyszínek felett.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Hiányzik vagy elavult az osztály információja?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Az osztály szerkesztők az Ön szervezetében belül vannak kijelölve, hogy hozzáadják, frissítsék vagy javítsák az osztályspecifikus információkat a TeamConnectben.
                  Ha nem biztos abban, hogy ki az osztály szerkesztője, vagy ha bizonyos információk HR támogatói beavatkozást igényelnek,
                  kérjük, lépjen kapcsolatba a <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> részére útmutatásért.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Irodai Könyvtár GYIK</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Irodai Könyvtár Bevezetés:</Body1Strong> Fedezze fel szervezetének fizikai jelenlétének gerincét az Irodai Könyvtáron keresztül,
                ami magában foglalja az alapvető irodai részleteket, működési sajátosságokat, valamint az egyes helyszíneket meghatározó osztályokat és személyzetet.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Címkék navigálása az Irodai Könyvtárban
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Könnyedén találja meg az Irodai Könyvtárban való eligazodás módját a címkék használatával, amelyek gyors hozzáférést biztosítanak a megfelelő helyekhez. Íme, hogyan segíthet minden egyes címke az ön keresésének egyszerűsítésében:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Minden:</Body1Strong> Teljes irodák listáját jeleníti meg, megadva egy madártávlati 
                      képet a szervezet helyszíneiről.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kedvencek:</Body1Strong> Segít összpontosítani azokra az irodai helyszínekre, amelyeket gyakran látogat, 
                      vagy szorosan figyelemmel kell kísérnie, a 'Kedvencek' megjelölésével.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Az ön irodája:</Body1Strong> Ez a személyes címke szűri a könyvtárat annak érdekében, hogy megmutassa az irodai 
                      helyszínt, ahol állomásozik, így az otthoni bázisa mindig kéznél van.
                    </li>
                  </ul>
                  Akár egy tárgyalót foglal, vagy csak egy látogatást tervez, ezek a címkék személyre szabott és egyszerűsített módot kínálnak a szervezet
                  földrajzi tájképével való interakcióra.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Irodák kezelése a Névtári Nézetekben a TeamConnect alkalmazásban
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Mélyedjen el az Ön vállalatának különböző helyszíneinek szervezeti felépítésében a TeamConnect Irodai Névtár nézeteivel. Minden nézet úgy van kialakítva, hogy 
                  segítse az irodai tájékozódást és az értelmezést:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lista Nézet:</Body1Strong> Az alapértelmezett beállítás, amely rendezetten rendezi az összes 
                      irodát egy könnyen olvasható listába.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nagy Kártya Nézet:</Body1Strong> Az Irodai Névtár Nagy Kártya Nézetében egy részletes bemutatóval 
                      gazdagíthatja a nézetét az egyes irodákról. Amikor egy irodakártyával foglalkozik, a "Több (...)" menüben gazdag információkat és cselekvési lehetőségeket 
                      talál:
                      <ol>
                        <li style={{ marginTop: '8px' }} className={styles.bulletBottomMargin}><Body1Strong>Osztályok Megtekintése:</Body1Strong> Közvetlenül navigáljon az 
                          iroda összes osztályának listájára.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Munkatársak Megtekintése:</Body1Strong> Férjen hozzá az irodához rendelt összes munkatárs 
                          teljes névsorához, lehetővé téve a közvetlen kapcsolatot vagy az iroda személyzetének felfedezését.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Vállalati Egység Org Chart:</Body1Strong> Ábrázolja az iroda helyét a nagyobb 
                          szervezeti kontextusban, azért, hogy egyértelműen értse a hierarchikus szerkezeteket.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Fontos Kapcsolattartók:</Body1Strong> Azonosítsa a kulcsfontosságú irodai kapcsolattartókat a 
                          kommunikáció egyszerűsítése érdekében.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Iroda Ünnepnapok:</Body1Strong> Tájékozódjon az iroda-specifikus munkaszüneti napokról, hogy 
                          ennek megfelelően tervezhesse a programját.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Irodai Munkaidő:</Body1Strong> Tekintse meg az iroda működési óráit, hogy látogatásait vagy 
                          hívásait azok aktív idejére időzítse.
                        </li>
                      </ol>
                      Ez a nézet nemcsak vizuális és információs pillanatképet nyújt, hanem gyors hozzáférést is biztosít az irodával kapcsolatos mélyebb betekintésekhez és 
                      közvetlen cselekvésekhez, mind egyértelműen elrendezve a "Több (...)" lehetőség alatt az Ön kényelme érdekében.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Szervezeti Diagram Nézet:</Body1Strong> Ábrázolja a szülői és alárendelt irodák hierarchikus 
                      szerkezetét, hogy megértse a különböző irodahelyszínek összefüggéseit a szervezeten belül.
                    </li>
                  </ul>
                  Ezeket a nézeteket az ikonok segítségével válthatja át a keresőmező mellett, hogy testre szabja böngészési élményét az aktuális igényeihez.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Irodák gyors megtalálása a TeamConnect Keresési Funkciójával
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A TeamConnectben az irodahelyek gyors megtalálása egyszerű a dedikált keresési funkcióval. A nézet ikonok mellett kiemelten elhelyezett keresőmező a 
                  segítségére lesz az irodák név szerinti azonosításában. Ahogy gépel, a névtár az Ön bevitelének megfelelően finomítja a megjelenítést, így egyszerűsítve a 
                  keresési folyamatot. Fontos, hogy ezt a keresőmezőt használja a TeamConnectben a legpontosabb és legrelevánsabb eredmények érdekében, a Microsoft Teams 
                  általános keresőjével szemben. Ezzel a célzott keresési funkcionalitással gyorsan elnavigálhat bármelyik irodához a szervezete hálózatán belül.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Irodaadatbázis betűrendes navigálása
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Ha előnyben részesíted az abc szerinti keresést, az ABC-szűrőnk gyors módszert nyújt az irodák név szerinti megtalálására. Az Irodaadatbázis tetején
                  látni fogod az A-tól Z-ig terjedő betűket. Egy betűre kattintva a nézet csak az adott kezdőbetűvel rendelkező irodákat mutatja, egyszerűsítve ezzel a
                  keresési folyamatot. Az összes iroda újbóli megjelenítéséhez egyszerűen töröld a kiválasztott betűt. Ez a funkció különösen hasznos, amikor ismered az
                  iroda nevét, de gyorsan szeretnéd azt megtalálni a TeamConnect-en belül.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Átfogó irodai profilok bemutatása a TeamConnect-ben
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Indulj felfedezőútra szervezeted fizikai jelenlétének szívébe a TeamConnect irodai profiljaival.
                  Egy iroda nevére kattintva egy élénk profil párbeszédablak jelenik meg, amely részletes irodaelemzéseket kínáló lapokkal teli:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Áttekintés:</Body1Strong> Merülj el az iroda azonosságának magjában részletes
                      leírókkal, az üzleti szerkezettől kezdve a nyitvatartási időkön és helyi időn át, amelyek megfogják az iroda környezetének lényegét.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Alapvető kapcsolatok:</Body1Strong> Férj hozzá a sürgősségi szolgálatokhoz és
                      külső támogatáshoz elengedhetetlen kapcsolatokhoz, mindegyik csak egy kattintásra van a Microsoft Teams Phone Systemen keresztül.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Irodai kapcsolatok:</Body1Strong> Lépj kapcsolatba a belső támogató szolgáltatásokkal könnyedén, a
                      kapcsolattartási adatokkal és közvetlen elérhetőségi lehetőségekkel közvetlenül az ujjaid hegyén.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Munkaszüneti napok:</Body1Strong> Tartsd szem előtt az irodaspecifikus ünnepeket, biztosítva, hogy
                      terveid összhangban legyenek a helyi megemlékezésekkel és munkarendekkel.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Munkaidő:</Body1Strong> Tervezd meg interakcióidat pontos tudással arról, mikor nyüzsg az
                      iroda tevékenységgel vagy épp leáll a nap végére.
                    </li>
                  </ul>
                  Legyen szó Austin pezsgő energiájáról vagy Szingapúr stratégiai központjáról, minden lap kaput nyújt a cég globális jelenlétének különféle
                  helyszíneinek megértéséhez és azokkal való kapcsolatfelvételhez.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. Elmélyült betekintés az irodákon belüli osztályok dinamikájába
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Az irodai osztályok szakmai hálózatának navigálása könnyen kivitelezhető a TeamConnect segítségével. Íme, hogyan lehet részletesen megismerni:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Oszdályok megtekintése ebben az irodában:</Body1Strong> Fedezze fel az összes olyan osztályt, amely az iroda szövetét alkotja, válassza ki ezt az opciót a Több (...) menüből.
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Szervezeti betekintés:</Body1Strong> Kattintson bármelyik osztályra az org ábra megjelenítéséhez, amely vizuálisan ábrázolja a szerkezetét, az útvonalmutató segítségével: Iroda {'>'} Osztály {'>'} Ábra.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Osztályozó részletes vizsgálata:</Body1Strong> További részletek felfedezése egy osztályról a neve mellett lévő Több menü (...) kattintásával. Itt hozzáférhet az adott osztály minden munkatársának listájához, közvetlenül az irodai kontextusban.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Morzsamenü-navigáció:</Body1Strong> Zökkenőmentesen haladjon a szintek között. Az alkalmazottaktól vissza az osztályokhoz vagy az org ábráról, a morzsamenü (Iroda {'>'} Osztály {'>'} Alkalmazott {'>'} Ábra) intuitív és nyomon követhető utazást biztosít.
                    </li>
                  </ul>
                  A TeamConnect összetett útvonalai arra invitálnak, hogy felfedezze, megértse és kapcsolódjon az iroda minden osztályának minden rétegéhez, így átfogó navigációs parancsnokságot biztosítva Önnek.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Kapcsolatfelvétel az irodai személyzettel a TeamConnectben
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Kíváncsi az iroda mögötti arcokra? A válasz csak egy kattintásnyira van a TeamConnectben. Foglalkozzon az ellipszissel (három pont) a választott irodanév mellett, hogy felfedjen egy interaktív opciók sorát:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Dolgozók megtekintése ebben az irodában:</Body1Strong> Fedezze fel az összes szakembert, aki ezt az irodát nevezi munkahelyének. A megjelenített szám gyors összesítést nyújt a dolgozókról, meghívva Önt az irodai közösség megismerésére.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kapcsolatfelvételi lehetőségek:</Body1Strong> Minden alkalmazotti bejegyzés gyorsműveleti ikonokkal van felszerelve,
                      amely lehetővé teszi a csevegések, hívások vagy akár videókonferenciák kezdeményezését közvetlenül a TeamConnecten keresztül, elősegítve a zökkenőmentes irodai kommunikációt.
                    </li>
                    <li><Body1Strong>Navigáció:</Body1Strong> Az alkalmazottak listájáról vissza az szélesebb irodai listára való visszatéréshez egyszerűen kattintson az 'Iroda' szövegre a morzsaútvonalon (Iroda {'>'} Alkalmazott), és visszakerül az iroda áttekintéséhez.
                    </li>
                  </ul>
                  Ezekkel a funkciókkal a TeamConnect egyszerűvé teszi az irodák közötti munkaerő-eloszlás vizualizálását és a problémamentes kapcsolatfelvételt, elősegítve a távolság ellenére is összekapcsolt munkahely kialakítását.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Hiányzik vagy elavultak az irodai információk?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Az irodai információk frissítését a TeamConnectben kijelölt iroda szerkesztők kezelik. Ha bizonytalan vagy az iroda szerkesztőivel kapcsolatban,
                  vagy segítségre van szükséged az HR hatáskörébe tartozó információkkal kapcsolatban, az HR támogató csapat készen áll a segítségre ezen a linken: 
                  <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Beállítások GYIK</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. A TeamConnect általános beállításainak pontosságának jelentősége
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A mai munkakörnyezet dinamikus tájának megfelelően a kapcsolattartás és az összehangolódás fontosabb, mint valaha. Ezért kiemelt szerepet kapnak az
                  általános beállítások frissítései a TeamConnectben:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Munkahely kiválasztása:</Body1Strong> Frissítsd a Munkahelyedet, hogy tükrözze jelenlegi helyzetedet, legyen az a Home-Office nyugalma
                        és a főiroda nyüzsgése közötti váltás, vagy egy nyilvános helyre való letelepedés. Ezzel a munkahelyed világossá tétele lehetővé teszi a kollégáid 
                        számára, hogy ne csak a legjobb módon léphessenek kapcsolatba veled, hanem bepillantást nyerjenek abba is, milyen jellegű beszélgetéseket folytathatnak 
                        veled, különösen fontos ez az érzékeny megbeszélések bizalmas kezelése szempontjából, amikor ügyfél irodájában vagy repülőtéri váróban tartózkodsz.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Munkahely specifikáció:</Body1Strong> A munkavárosod megosztása több, mint távolságok áthidalása egy távmunka beállításban—új lehetőségeket
                        is nyit a spontán szakmai találkozókra vagy a szabadidős társasági eseményekre. Akár egy gyors kávé, egy laza ebéd, vagy egy közös munkamegbeszélés,
                        ha tudják, hogy ugyanabban a városban vagy, akkor egy szokásos nap átalakulhat jelentős személyes kapcsolattartás lehetőségévé. Ez nem csak a csapatszellem 
                        erősítésében játszik szerepet, hanem mélyebb megértést is nyújt a helyi sajátosságokról, amelyek érinthetik a munkádat.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Időzóna pontosság:</Body1Strong> A kontinenseken átívelő csapatok esetében alapvető a kommunikáció
                      összehangolása érdekében, hogy az időzónád tükrözze a jelenlegi helyzetedet. A TeamConnect profilod helyi idője közvetlenül ezen beállítástól függ,
                      megszüntetve azokat a találgatásokat, amelyekkel a kollégáidnak szembe kellene nézniük, amikor megpróbálnak elérni téged.
                    </li>
                  </ul>
                  Merülj el a 'Beállítások' {'>'} 'Általános beállítások' menüpontban a TeamConnectben, hogy finomhangolhasd ezeket a részleteket. Néhány percnyi beállításfrissítés
                  jelentősen növelheti együttműködési erőfeszítéseid koherenciáját és hatékonyságát minden téren.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. A munkahelyi beállítások megfejtése a TeamConnectben
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A megfelelő beállítás kiválasztása a Munkahely legördülő menüben pontosan tükrözi a jelenlegi munkahelyzetedet a TeamConnectben. Vigyél az információ ikon fölé az egyes opciók részletes magyarázatához:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kijelentkezve/Szünet:</Body1Strong> Jelzi a munkahelytől való távollétet, legyen szó szünetről vagy a nap végéről.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Iroda/Terepi iroda:</Body1Strong> Jelzi a jelenlétet egy vállalat által kezelt munkaterületen.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Home-office:</Body1Strong> Otthoni, dedikált munkaterületről való munkavégzés.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Távmunkahely/Nyilvános terület:</Body1Strong> Irodán kívüli környezetből történő működés, ami rugalmas kommunikációt sugall.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ügyfél munkahelye/Magán iroda:</Body1Strong> Ügyfélnél történő helyszíni munka, megjegyzéssel a beszélgetések adatvédelmi szintjéről.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Utazás/Üzleti út/Képzés:</Body1Strong> Mozgásban vagy kifejezetten munkával kapcsolatos tevékenységekben való részvétel az irodán kívül.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Állami ünnep/Szabadság/Különböző szabadságok:</Body1Strong> Hivatalosan munkától távol személyes, egészségügyi vagy ünnepi okokból.</li>
                  </ul>
                  A megfelelő munkahelyi beállítás kiválasztása biztosítja, hogy a csapatod megfelelő kontextusban tudjon interakcióba lépni, tiszteletben tartva a rendelkezésre állásodat és a magánéletedet.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. Az 'Aktív' állapot navigálása a TeamConnectben
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Az 'Aktív' címke az Alkalmazotti Könyvtárban úgy van kialakítva, hogy megmutassa az összes jelenleg munkában lévő alkalmazottat. Ez a láthatóság a választott Munkahely beállításodtól függ:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Aktív munka-környezetek:</Body1Strong> Az Iroda, Terepi iroda, Home-office, Távmunkahely, Nyilvános terület, Ügyfél munkahelye, 
                    Ügyfél magán-irodája, Utazás, Üzleti út és Képzés választások jelzik, hogy munkában vagy, így 'Aktív' állapotba kerülsz és láthatóan aktív vagy a kollégáid számára.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nem munka beállítások:</Body1Strong> A Kijelentkezett, Szünet, Állami ünnep, Szabadság, Távollét, GYES, vagy Betegszabadság 
                      státuszok kiválasztása kivon téged az 'Aktív' listából, világos várakozásokat szabva a rendelkezésre állásodra és a válaszadási időkre.
                    </li>
                  </ul>
                  Ez a funkcionalitás segít elkülöníteni a munkaidőt a személyes időtől, elősegítve az effektív csapatmunkát és kommunikációt.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Adatkezelési és adatvédelmi GYIK</Subtitle2>
          <Body1>
            <p>
              A mai digitális korban elengedhetetlen az adatkezelés megértése. A TeamConnect elkötelezett az átláthatóság és a robustus adatvédelmi gyakorlatok mellett. 
              Alább információkat talál arról, hogyan kezeljük, tároljuk és védjük az Ön adatait a TeamConnecten belül, biztosítva, hogy digitális munkaterülete biztonságos és megfelelő legyen.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Hogyan véd meg a TeamConnect a személyes adataimat?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>A TeamConnect elkötelezett személyes adatai védelme mellett egy átfogó megközelítéssel az adatbiztonság terén:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Egyszerű bejelentkezés (SSO):</Body1Strong> A biztonság erősítése és a felhasználói élmény egyszerűsítése érdekében a TeamConnect automatizált 
                      Egyszerű bejelentkezést (SSO) integrál az Ön Microsoft 365 fiókjával, biztosítva ezzel a zökkenőmentes és biztonságos hozzáféréskezelést.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Titkosítás:</Body1Strong> Biztosítjuk, hogy a TeamConnecten belüli összes adat titkosítva legyen mind az adatátvitel, mind a pihenő állapot során, 
                      megakadályozva a jogosulatlan felek hozzáférését.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Adatminimalizálás:</Body1Strong> Adatgyűjtésünk szigorúan korlátozódik arra, ami elengedhetetlen a TeamConnect funkcióinak működéséhez. 
                      Az egyetlen felhasználói információ, amelyet tárolunk, az Ön Felhasználói Főnév (UPN) az Azure Entra ID-ből, ami hasonlíthat a cég e-mail címére.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Korlátozott hozzáférés:</Body1Strong> A UPN-hoz való hozzáférés korlátozódik a szervezetén belüli előfizetési adminisztrátorra az előfizetési portálunkon, 
                      biztosítva, hogy az érzékeny információ csak a jogosult személyzet számára legyen látható.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Gyors adattörlés:</Body1Strong> A TeamConnect úgy van strukturálva, hogy azonnal törölje a tárolt UPN-t három konkrét feltétel mellett: 
                      ha az előfizetést törli az előfizetési adminisztrátor, ha egy felhasználót eltávolít az HR a TeamConnectben, vagy ha a TeamConnect 
                      előfizetés lejár. Ez biztosítja, hogy a személyes adatait ne őrizzük meg tovább azok hasznos élettartamán vagy szükségességén túl.
                    </li>                      
                  </ul>
                  <p>Ezekkel az intézkedésekkel a TeamConnect elkötelezi magát az adatvédelmi normák legmagasabb szinten való fenntartása mellett, biztosítva, hogy személyes adatai biztonságosak és bizalmasak maradjanak.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Kíváncsi, hogy az adatai hol találhatóak a TeamConnect használata során?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A TeamConnect megszakítja a hagyományos SaaS kínálatok mintáját azzal, hogy biztosítja, az Ön adatai sosem hagyják el a szervezetét.
                  A szokásos gyakorlattal ellentétben, amely az adatokat külső felhőszervereken tárolja, a TeamConnect zseniálisan használja ki szervezete
                  Microsoft 365 SharePoint Online-ját, hogy otthont adjon minden alkalmazásadatnak. Ez az újító megközelítés nem csak az adatszuverenitást emeli ki, hanem megerősíti a biztonságot is,
                  tökéletesen összhangban van az elkötelezettségünkkel az Ön adatvédelmének és szigorú megfelelőségi standardjaink betartásának védelmében.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Az adataimat megosztják harmadik felekkel?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nem, az adatokat nem osztjuk meg harmadik felekkel! A Cubeet elkötelezett adatvédelmi és biztonsági kötelezettségünkben. Nem osztunk meg semmilyen TeamConnect adatot harmadik felekkel
                  anélkül, hogy kifejezett beleegyezést kapnánk ügyfeleinktől.
                  Biztonságos környezetünkben csak alapvető azonosítókat tárolunk, mint például a User Principal Name (UPN), a szervezet neve, a bérlő neve, és a bérlő azonosítója, amelyek előfizetési portálunkon belül helyezkednek el.
                  Ezek az információk alapvetőek a szolgáltatás nyújtásához, és szigorú adatvédelmi irányelveink védelme alatt állnak.
                  Biztos lehet benne, hogy adatkezelési gyakorlataink úgy vannak kialakítva, hogy az Ön adatai bizalmasak maradjanak, az átláthatóság és az irányítás pedig szilárdan az Ön kezében maradjon.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Hogyan felel meg a TeamConnect az adatvédelmi rendeleteknek, mint az GDPR?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A TeamConnect összhangban van az olyan adatvédelmi rendeletekkel, mint az GDPR, HIPAA, CCPA, és mások, a Microsoft 365 környezetének meglévő megfelelőségi funkcióinak felhasználásával,
                  ahol minden ügyfél adata biztonságosan tárolódik. A hagyományos SaaS modellekkel ellentétben a TeamConnect nem tárol semmilyen ügyféladatot a saját szerverein; ehelyett az Ön szervezetének
                  Microsoft 365 környezetében működik, betartva annak erős biztonsági és megfelelőségi előírásait. Ez a megközelítés biztosítja, hogy az adatgazdálkodás összhangban maradjon a
                  Microsoft 365 által biztosított átfogó megfelelőségi kerettel, beleértve az GDPR, HIPAA az egészségügyi információkra, CCPA Kalifornia lakosaira,
                  és más globális adatvédelmi rendeletek betartását. Azzal, hogy zökkenőmentesen integrálódik a meglévő Microsoft 365 beállításával, a TeamConnect lehetővé teszi szervezete számára,
                  hogy teljes mértékben ellenőrzése alatt tartsa az adatokat, biztosítva a legszigorúbb adatvédelmi törvények és rendeletek betartását.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Hogyan kezeli a TeamConnect a Microsoft Teams csevegésekből, hívásokból, videóhívásokból, Outlook email-ekből és telefonos hívásokból származó adatokat?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  A TeamConnect a Microsoft Graph API-t használja az olyan közvetlen interakciók lehetővé tételére, mint a csevegés, hívások, videóhívások és Outlook e-mail Microsoft Teamson belül, 
                  biztosítva egy zökkenőmentes felhasználói élményt. Fontos, hogy bár a TeamConnect kezdeményezi ezeket a kommunikációkat, ezt úgy teszi, hogy nem avatkozik be a kommunikációs folyamatba 
                  és nincs képessége rögzíteni vagy tárolni az interakció tartalmát. Minden beszélgetés, hívás és email közvetlenül a Microsoft Teams és Outlook biztonságos környezetében zajlik, 
                  szigorúan betartva a Microsoft 365 erős biztonsági és adatvédelmi szabványait. Ez biztosítja, hogy a TeamConnect növeli 
                  kommunikációs képességeidet, miközben teljes mértékben megőrzi az adataid bizalmas voltát és integritását.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Adataink kezelési szabályzatairól és gyakorlatairól részletes betekintést nyerhetsz, ha felkeresed a {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>TeamConnect Felhasználói Útmutatót</Body1Strong></Link> vagy szervezeted IT 
              osztályát itt: <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> a te beállításaidhoz kapcsolódó specifikációkért.
          </p>
        </Body1>        
        <p></p>  
      <Subtitle1 className={styles.fontColor}>Hallgatunk Rád: Oszd Meg Véleményedet és Ötleteidet</Subtitle1>
        <Body1>
          <p>
            A Te betekintésed hajtja a TeamConnect fejlődését. Akár egy zseniális funkció ötletével teli vagy, vagy van visszajelzésed a jelenlegi tapasztalataidról, szívesen halljuk. A te hozzájárulásod nélkülözhetetlen a 
            folyamatos törekvésünkben, hogy a lehető legjobb szolgáltatást nyújtsuk.
          </p>
        </Body1>      
      <Subtitle2>Visszajelzések és Funkciójavaslatok</Subtitle2>
        <Body1>
          <p>
            Kész vagy megosztani? Látogass el a {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Visszajelzési Oldalra
            </Link>
            . Legyen szó új funkció javaslatáról vagy a meglévő funkciók visszajelzéséről, egyszerűen válaszd ki 
            az űrlapon a megfelelő opciót. A te hozzájárulásod formálja a TeamConnect jövőjét, 
            biztosítva, hogy eleget tegyen és túlszárnyalja az igényeidet.
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;