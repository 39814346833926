import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});
function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();
  return (
    <>
      <Title3 className={styles.fontColor}>TeamConnect Helpcentrum & Veelgestelde Vragen</Title3>
        <p><Body1>
          Welkom bij het TeamConnect Helpcentrum, ontworpen om uw ervaring te ondersteunen met onze uitgebreide bedrijvengids app. Hier vindt u middelen om uw gebruik van TeamConnect binnen Microsoft Teams te optimaliseren, wat de communicatie binnen uw netwerk bevordert.
        </Body1></p>
        <Subtitle2>Snelstartgids</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>Verbeterde Zoekmogelijkheden: </Body1Strong>
              Vind snel de juiste collega's, afdelingen en essentiële contacten met onze krachtige zoektools.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Visuele Organisatieschema's: </Body1Strong>
              Navigeer eenvoudig door de hiërarchie van uw organisatie en begrijp de rapportagelijnen met behulp van intuïtieve organisatieschema's.
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>Aanpasbare Tools voor Hedendaagse Behoeften: </Body1Strong>
              Gebruik moderne functionaliteiten die zijn aangepast aan de eisen van hedendaagse werkplekken.
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>Optimaliseer Uw Ervaring</Subtitle2>
        <Body1>
          <p>
            TeamConnect revolutioneert de manier waarop u interacteert binnen uw organisatiestructuur, door alledaagse communicatie te veranderen in strategische betrokkenheid.
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>Krijg Hulp & Veelgestelde Vragen</Subtitle1>
        <Body1>
          <p>
            Vind antwoorden op veelgestelde vragen over de functies en instellingen van TeamConnect in deze speciale sectie. Of u nu de app navigeert, instellingen aanpast, of oplossingen zoekt voor uitdagingen, ons Helpcentrum is hier om u efficiënt te helpen.
          </p>
        </Body1>
        <Body1>
          <p>
            Voor meer diepgaande ondersteuning of specifieke begeleiding, raadpleeg de <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnect Gebruikershandleiding</Body1Strong></Link>.
          </p>
        </Body1>
        <p></p>
        
        <Subtitle2>Gebruik van TeamConnect FAQs</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. TeamConnect niet vinden in de zijbalk van Microsoft Teams?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Als TeamConnect niet direct zichtbaar is of verdwenen is in uw zijbalk van Microsoft Teams, kunt u het gemakkelijk vastzetten voor snelle toegang. Volg gewoon deze eenvoudige stappen:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Open Microsoft Teams en klik op het '...' (+ Apps) pictogram onderaan de zijbalk.</li>
                    <li className={styles.bulletBottomMargin}>Zoek naar 'TeamConnect' in de zoekbalk bovenaan het pop-upvenster.</li>
                    <li className={styles.bulletBottomMargin}>Klik met de rechtermuisknop op de TeamConnect-app uit de zoekresultaten en selecteer 'Vastzetten'.</li>
                  </ol>
                  TeamConnect zal nu vastgezet worden aan uw zijbalk, waardoor het toegankelijk is met slechts één klik!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. Technische problemen met TeamConnect? Hier is hoe u hulp kunt krijgen.
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Als u technische problemen ondervindt met TeamConnect binnen Microsoft Teams, staat het IT-ondersteuningsteam van uw organisatie klaar om te helpen. 
                  Neem contact met hen op via <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, en zij zullen u prompt assisteren.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. Uw werknemersgegevens niet kunnen vinden?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Als uw profiel een beetje leeg aanvoelt met alleen uw naam en e-mail zichtbaar, is het waarschijnlijk dat uw HR-team de rest van uw werknemersgegevens nog niet aan TeamConnect heeft toegevoegd. 
                  Geen zorgen! Een snel berichtje naar uw HR-ondersteuningsteam zet de zaken in beweging. Ze staan klaar om uw profiel aan te vullen met alle belangrijke details. 
                  Neem contact op via <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> en zie hoe uw profiel tot leven komt!
                </Body1>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. Welke taalopties zijn beschikbaar in TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect omarmt de wereldwijde diversiteit van Microsoft Teams door ondersteuning te bieden voor al zijn talen. 
                  Of je nu de voorkeur geeft aan Arabisch of Grieks, Japans of Zweeds, je hebt een wereld aan opties binnen handbereik, inclusief:
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      Arabisch, Bulgaars, Chinees (Vereenvoudigd en Traditioneel), Kroatisch, Tsjechisch, Deens, Nederlands, Engels, Ests, Fins, 
                      Frans, Duits, Grieks, Hebreeuws, Hongaars, Indonesisch, Italiaans, Japans, Koreaans, Lets, Litouws, Noors (Bokmål), 
                      Pools, Portugees (Brazilië en Portugal), Roemeens, Russisch, Servisch (Latijn), Slowaaks, Sloveens, Spaans, Zweeds, Thai, 
                      Turks, Oekraïens, Vietnamees.
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  Om je TeamConnect ervaring aan te passen aan je voorkeurstaal, stel de taalinstellingen direct in Microsoft Teams bij,
                  aangezien TeamConnect volledig integreert met deze instellingen:
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      Ga naar je Microsoft Teams instellingen door op de drie puntjes naast je profielfoto bovenaan te klikken, en selecteer vervolgens 'Verschijning en toegankelijkheid'.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Scroll naar het gedeelte 'Taal' en selecteer je voorkeurstaal uit het dropdownmenu.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Bevestig je wijzigingen door op de knop 'Opslaan en herstarten' te klikken om de nieuwe taalinstellingen toe te passen.
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>Opmerking: </Body1Strong>
                <Body1>
                  Wijzigingen aan je taalinstellingen in Microsoft Teams zullen ook naadloos je TeamConnect ervaring updaten.
                  Wees ervan bewust dat het aanpassen van de taal in Teams ook je tijd- en datumnotatie kan wijzigen. Op dit moment,
                  evolueert TeamConnect naar ondersteuning voor schrijven van rechts naar links. We zijn enthousiast over deze verbeteringen
                  en zullen je op de hoogte houden naarmate ze beschikbaar komen.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. Hoe kan ik het thema in TeamConnect wijzigen?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect synchroniseert met uw Microsoft Teams themavoorkeuren, inclusief Licht, Donker en Hoog Contrast opties. Om uw thema aan te passen:
                  <ol>
                    <li className={styles.bulletBottomMargin}>Ga naar uw Microsoft Teams instellingen door op de drie puntjes naast uw profielfoto bovenaan te klikken.</li>
                    <li className={styles.bulletBottomMargin}>Selecteer 'Uiterlijk en toegankelijkheid' om de themaopties te verkennen.</li>
                    <li className={styles.bulletBottomMargin}>Kies uw voorkeursthema uit de beschikbare opties om direct uw TeamConnect interface bij te werken zodat deze overeenkomt.</li>
                  </ol>
                  Uw themakeuze in Microsoft Teams wordt automatisch toegepast op TeamConnect, wat zorgt voor een consistente visuele ervaring in uw werkruimte.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. Verkennen van de Contactactieknoppen in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Overal in TeamConnect waar u de contactgegevens van een werknemer vindt, ontdekt u ook onze handige sneltoegangsknoppen. Deze handige tools 
                  zijn ontworpen om uw communicatie binnen het Microsoft-ecosysteem te stroomlijnen, zodat u altijd slechts een klik verwijderd bent van verbinding. 
                  Hier is uw uitgebreide gids voor het effectief gebruiken van deze knoppen:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chatpictogram:</Body1Strong> Klik om een directe Microsoft Teams-chat te starten. Als u na het starten van een chat wilt terugkeren naar TeamConnect, gebruik dan eenvoudigweg de terugpijl in de Desktop App, de terugknop in de mobiele app of de terugknop van uw browser.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>E-mailpictogram:</Body1Strong> Opent een nieuw Outlook-e-mailconcept met het adres van de ontvanger klaar in het veld "Aan:". Mocht Outlook niet starten, dan is het raadzaam om dit na te gaan bij uw IT-afdeling voor eventuele beperkingen.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Video-oproepicoon:</Body1Strong> Start een videogesprek via Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Belicoon:</Body1Strong> Start een spraakoproep via Microsoft Teams, wat snelle besprekingen vergemakkelijkt.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organigramicoon:</Body1Strong> Toont de plek van de werknemer in de organisatiehiërarchie, inclusief hun manager en rechtstreekse rapporten.</li>
                    <li><Body1Strong>Meer (...) Pictogram:</Body1Strong> Onthult verdere opties:
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>Nu vergaderen:</Body1Strong> Opent Microsoft Teams om direct een vergadering met de werknemer te starten.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Nieuwe vergadering:</Body1Strong> Leidt u naar Microsoft Teams om een nieuwe vergadering te plannen.</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Contact toevoegen:</Body1Strong> Voegt de contactgegevens van de werknemer vanuit TeamConnect toe aan uw nieuwe Microsoft Teams People-app, waardoor uw bel-functie verrijkt wordt.</li>
                      </ul>
                    </li>
                  </ul>
                  Deze intuïtieve knoppen zijn zo ontworpen dat u efficiënt kunt verbinden met collega's, of het nu voor een snel gesprek, een e-mail of om toekomstige vergaderingen in te plannen.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. Bellen via TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Wilt u een telefoongesprek voeren? TeamConnect stroomlijnt uw communicatie door integratie met Microsoft Teams. 
                  Klik gewoon op het gewenste telefoonnummer in TeamConnect, en voilà, de belinterface van Microsoft Teams verschijnt, 
                  klaar om moeiteloos uit te bellen.
                  <p>
                  <Body1Strong>Opmerking: </Body1Strong> 
                  Problemen met het maken van oproepen? Dit kan te wijten zijn aan het IT-beleid van uw organisatie of het 
                  gebruik van een ander telefoonsysteem dan het Microsoft Teams Phone System. Het raadplegen van uw IT-afdeling op <Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>, kan opheldering bieden.
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. Zijn er kosten verbonden aan het maken van oproepen via TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect verbetert uw belervaring door gebruik te maken van Microsoft Teams. Hier is wat u moet weten over de kosten gerelateerd aan oproepen:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      Voor oproepen naar werktelefoons van medewerkers, afdelingstelefoons of hotlines binnen uw organisatie (interne nummers),
                      zijn er doorgaans <Body1Strong>geen kosten</Body1Strong> bij gebruik van het Microsoft Teams Phone System.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      Voor externe oproepen, zoals naar mobiele telefoons of buitenlijnen, gelden de <Body1Strong>standaardtarieven</Body1Strong> van het telefoonsysteem van uw organisatie.
                      Deze tarieven variëren op basis van uw specifieke serviceplan bij Microsoft Teams.
                    </li>
                  </ul>
                  <p><Body1Strong>Opmerking:</Body1Strong> Als u problemen ondervindt of vragen heeft over de oproepkosten, is het raadzaam om te overleggen met uw 
                    IT-afdeling op <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> voor gedetailleerde informatie 
                    die is afgestemd op de opzet en het beleid van uw organisatie.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. Merkt u een mismatch op met uw 'Out of Office'-aanwezigheidsstatus in TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Merkt u dat uw 'Out of Office'-aanwezigheidsstatus van Microsoft 365 niet correct wordt weergegeven in TeamConnect?
                  Wij hebben dit voor u geregeld. De functie om de 'Out of Office'-aanwezigheidsstatus te synchroniseren is al geïmplementeerd in TeamConnect.
                  We wachten momenteel op de uitrol van deze functie door Microsoft. Zodra dit gebeurt, zal uw 'Out of Office'-aanwezigheidsstatus
                  correct worden gespiegeld in TeamConnect, in lijn met de aanwezigheidsindicatoren van Microsoft Teams voor een naadloze ervaring.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>Vaak gestelde vragen over de medewerkersgids</Subtitle2>
          <Body1>
            <p>
              <Body1Strong >Introductie medewerkersgids:</Body1Strong> Navigeer door het professionele landschap van uw organisatie met de medewerkersgids,
                uw centrale hub voor gedetailleerde inzichten over medewerkers, werkplekdynamiek en directe connectiviteit met de profielen en managementrelaties van uw collega's.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. Moet u uw werknemersgegevens toevoegen, bijwerken of corrigeren?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Neem voor het toevoegen, bijwerken of corrigeren van uw werknemersgegevens in TeamConnect contact op met het HR-ondersteuningsteam van uw organisatie.
                  Ze staan klaar om te helpen op <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. Wat gebeurt er als ik op de naam van een werknemer in de directory klik?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Door te klikken op de naam van een werknemer in de TeamConnect Werknemersgids wordt een gedetailleerde profielpagina voor die persoon geopend. Dit profiel biedt uitgebreide 
                  inzichten over de werknemer, inclusief maar niet beperkt tot, hun functietitel, afdeling, kantoorlocatie, contactinformatie en meer. Het is ontworpen om je 
                  een volledig overzicht te geven van de professionele rol van de werknemer en hoe zij passen binnen de organisatie, waardoor het gemakkelijker voor je wordt om hun verantwoordelijkheden te begrijpen en 
                  hoe je mogelijk met hen zou kunnen samenwerken.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. Welke informatie is opgenomen in het profiel van een werknemer?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Elk werknemersprofiel in TeamConnect is een uitgebreide momentopname, die een schat aan inzichten biedt in het professionele leven van de individu. 
                  Hier is wat je zult vinden:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Basisgegevens:</Body1Strong> Naam, functietitel, afdeling en locatie van dochteronderneming.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Rapportagestructuur:</Body1Strong> Informatie over directe en functionele rapporten, 
                      inclusief lijn- en functionele managers.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Arbeidsstatus:</Body1Strong> Huidige werkstatus zoals Met verlof, Onderweg, 
                      Zakelijk-reizen, enz.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Werkplek & Lokale Tijd:</Body1Strong> De huidige werklocatie van de werknemer en 
                      lokale tijdzone.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Contactgegevens:</Body1Strong> Werktelefoon, mobiel nummer en e-mailadres.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kantooradres:</Body1Strong> Volledige kantoorlocatie inclusief gebouwnaam,
                      adres en Google map.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Over:</Body1Strong> Een korte persoonlijke introductie of professionele bio.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Werknemersrelaties:</Body1Strong> Details van de rapportagestructuur van de werknemer en teamrelaties.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Sociale Links & Talen:</Body1Strong> Links naar professionele sociale profielen 
                      en taalvaardigheden.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vaardigheden:</Body1Strong> Een lijst van professionele vaardigheden en expertisegebieden.</li>
                  </ul>
                  Daarnaast biedt het profiel actiegerichte opties om te verbinden via chat, e-mail, videogesprek, of vergaderingen plannen, samen met 
                  snelle toegang tot de organisatiekaart voor een bredere teamcontext.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. Hoe kan ik mijn profielinformatie bijwerken?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Je profiel personaliseren is eenvoudig in TeamConnect. Hier is hoe je dat kunt doen:
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        Navigeer naar het tabblad <Body1Strong>Medewerker</Body1Strong> en klik op je <Body1Strong>Avatar</Body1Strong> om je profiel te openen.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        In de linkerbovenhoek vind je de link <Body1Strong>"Bewerk je profiel"</Body1Strong>. 
                        Hierop klikken opent de bewerkingsdialoog voor je profiel.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Hier kun je je profiel verbeteren door je <Body1Strong>Over Jou</Body1Strong> sectie, sociale media links en telefoonnummer toe te voegen of bij te werken. 
                        Dit maakt het makkelijker voor je collega's om met je te verbinden of je te volgen.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Vergeet niet om op de <Body1Strong>Opslaan</Body1Strong> knop te drukken om je wijzigingen toe te passen.
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        Scroll naar beneden in de dialoog om je <Body1Strong>Talen</Body1Strong> en <Body1Strong>Vaardigheid</Body1Strong>, 
                        evenals je <Body1Strong>Vaardigheden</Body1Strong> toe te voegen. Deze informatie delen helpt je collega's om 
                        jouw expertisegebieden en de talen waarmee je kunt communiceren te begrijpen.
                      </li>
                    </ol>
                  Je profiel bijwerken houdt niet alleen je informatie actueel, maar bevordert ook betere connecties binnen je organisatie.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. Je werkplek, werkpleklocatie en tijdzone bijwerken in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Je informatie over werkplek, werkpleklocatie en tijdzone actueel houden is cruciaal voor naadloze samenwerking in Microsoft Teams. 
                  Deze updates worden direct weergegeven in je TeamConnect-profiel en lijsten, waardoor collega's snel je werklocatie kunnen begrijpen 
                  en de beste tijden om met jou te verbinden, wat de samenwerking over verschillende regio’s en tijdzones verbetert.
                  <p></p>
                  Klaar om je details bij te werken? Het is eenvoudig in TeamConnect! Ga naar ‘Instellingen’ in het bovenste menu en navigeer naar ‘Algemene Instellingen’. 
                  Hier kun je soepel je werkplek en tijdzone bijwerken, en je werkpleklocatie voor de dag specificeren. Vergeet niet je wijzigingen op te slaan om 
                  iedereen geïnformeerd te houden en effectieve samenwerking te garanderen, ongeacht je locatie!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. Iets mis opgemerkt in het profiel van een collega?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Als je gegevens in het profiel van een collega tegenkomt die niet helemaal kloppen, is de beste eerste stap om hen vriendelijk een seintje te geven. Een snel gesprek of e-mail zou de truc moeten doen! Als om een of andere reden de info niet wordt bijgewerkt, maak je geen zorgen—stuur gewoon een bericht naar je HR-ondersteuningsteam op <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>, en zij zullen blij zijn om in te grijpen en de zaken recht te zetten.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. Inzicht in taggroepen: Alles, Favorieten, Ingelogd & Nieuwe Medewerkers
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Alles:</Body1Strong> Deze standaardtag toont elke medewerker in uw organisatie, 
                      en biedt een volledig overzicht van het team.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favorieten:</Body1Strong> Klik hier om je weergave te filteren naar de collega's die je hebt gemarkeerd 
                      als 'Favorieten', waardoor het eenvoudiger wordt om snel in contact te komen met belangrijke contacten.
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>Ingelogd:</Body1Strong> Deze tag geeft aan dat medewerkers actief aan het werk zijn, onderscheidend 
                      van alleen online aanwezig zijn in Microsoft Teams. Wanneer ingelogd, geven medewerkers aan dat ze aan het werk zijn en delen ze hun huidige werklocatie 
                      gedurende de dag. Dit kan variëren van werkplekken zoals Kantoor, Veldkantoor, Thuiswerkplek, Remote-Werkplek, Klant-Werkplek,
                      Klant-Privékantoor, Openbaar Gebied, Onderweg, Zakenreis of zelfs Training. Daarom biedt de status 'Ingelogd' een duidelijke indicatie 
                      van actief aan het werk zijn, waardoor collega's een precies begrip krijgen van elkaars huidige werkomgeving en beschikbaarheid.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nieuwe Medewerkers:</Body1Strong> Ontdek nieuwe gezichten in TeamConnect met deze tag, die 
                      medewerkers benadrukt die de afgelopen 30 dagen aan de app zijn toegevoegd. Dit komt meestal overeen met recente toevoegingen aan uw organisatie, wat helpt 
                      bij het verwelkomen van nieuwe teamleden.
                    </li>
                  </ul>
                  Deze taggroepen zijn ontworpen om navigatie te stroomlijnen en uw TeamConnect-ervaring te verbeteren, zodat u altijd efficiënt 
                  uw collega's kunt vinden en verbinden.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. Het decoderen van de iconen naast het zoekvak
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect's werknemersgids is ontworpen voor veelzijdigheid en gebruiksgemak, met vier iconen die verschillende weergaven bieden 
                  en snelle toegang tot belangrijke details. Hier is wat elk icoon onthult:
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lijstweergave:</Body1Strong> Je standaardweergave, die alle organisatieleden 
                      op een duidelijke lijst presenteert.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kleine Kaartweergave:</Body1Strong> Een compact alternatief, deze weergave transformeert de 
                      lijst naar hapklare medewerkerskaarten.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Grote Kaartweergave:</Body1Strong> Kies voor meer details met grotere kaarten, die 
                      extra contactopties zoals telefoon- en mobiele nummers voor gemakkelijk bellen bevatten.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organigram:</Body1Strong> Visualiseer de structuur van het bedrijf met het organigram, 
                      perfect voor het begrijpen van rapportagerelaties en het navigeren door het organisatielandschap.</li>
                  </ol>
                    Wissel tussen deze iconen om de weergave te vinden die op dat moment het beste aansluit bij je behoeften, of je nu op zoek bent naar snelle info of diepere inzichten.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. Waar moet ik kijken voor snelle zoekopdrachten naar collega's?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Op zoek naar een collega? Ga gewoon naar het zoekvak van TeamConnect, gelegen aan de rechterbovenkant naast het icoon van het organigram. 
                  Begin met het typen van hun naam en kijk hoe de resultaten verfijnen met elke letter. Onthoud, voor zoekopdrachten in TeamConnect, sla de zoekbalk van Microsoft Teams 
                  bovenaan over—onze speciale zoekvak is de manier om te gaan!
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. Hoe werkt de alfabetische zoekopdracht voor het vinden van werknemers?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  De Werknemersgids van TeamConnect omvat een handige alfabetische zoekfunctie om je snel werknemers te helpen lokaliseren op basis van hun voornaam. 
                  Klik simpelweg op een letter uit het alfabet dat bovenaan de gids wordt weergegeven, en TeamConnect zal onmiddellijk alle werknemers tonen
                  wiens voornamen beginnen met die letter. Om terug te keren naar de volledige lijst van werknemers, deselecteer gewoon de gemarkeerde letter. Deze intuïtieve 
                  functie maakt navigatie door grote werknemerslijsten een fluitje van een cent, zodat je collega's snel en efficiënt kunt vinden.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. Hoe kan ik het aantal werknemers per pagina aanpassen?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect staat je toe om aan te passen hoeveel werknemersprofielen je per pagina bekijkt, waardoor het gemakkelijker wordt om door de Werknemersgids te navigeren. 
                  Aan de onderkant van elke pagina vind je paginatie-opties: 10, 50, 100, 500, 1000, en 5000. De standaardinstelling toont 10 profielen per pagina. 
                  Om meer profielen op één pagina te bekijken, selecteer gewoon je voorkeursaantal uit deze opties. Deze functie biedt flexibiliteit in hoe je 
                  de gids doorbladert, waardoor je de weergave kunt aanpassen aan je specifieke behoeften of voorkeuren.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. Hoe sorteer ik werknemersinformatie in de Lijstweergave?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  In de Lijstweergave van de Werknemersgids van TeamConnect, heb je de flexibiliteit om werknemersinformatie te sorteren naar je behoeften. De kolommen—Naam van de Werknemer, 
                  Functietitel, Afdeling, Kantoorlocatie, en Werkplek—zijn allemaal sorteertaar. Klik simpelweg op de kolomkop waarop je wilt sorteren. Eén keer klikken zal de kolom sorteren in oplopende volgorde, 
                  en nogmaals klikken zal het sorteren in aflopende volgorde. Standaard is de gids gesorteerd op Naam van de Werknemer in oplopende volgorde, 
                  gebaseerd op de voornaam. Deze sorteeroptie helpt je snel werknemersgegevens te organiseren en te bekijken op een manier die het beste bij je doel past, of je nu 
                  iemand specifiek zoekt of werknemers wilt groeperen op een bepaald criterium zoals afdeling of locatie.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Veelgestelde vragen over de afdelingsgids</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>Inleiding tot de afdelingsgids:</Body1Strong> De afdelingsgids is jouw poort naar het begrijpen van het ingewikkelde netwerk van de structuur van je 
              organisatie, die een duidelijk beeld biedt van de hiërarchieën binnen afdelingen, leiderschap van managers en wegen voor naadloze communicatie binnen afdelingen.
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. Hoe werken de labels 'Alles', 'Favorieten' en 'Jouw Afdeling' in de afdelingsgids?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  In de afdelingsgids van TeamConnect, helpen labels je snel te navigeren en te filteren door afdelingsinformatie. Dit vertegenwoordigt elk label:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Alles:</Body1Strong> Dit label toont alle afdelingen binnen je organisatie en biedt een 
                      volledig overzicht.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favorieten:</Body1Strong> Gebruik dit label om een samengestelde lijst van afdelingen die je hebt 
                      gemarkeerd als 'Favorieten' te bekijken, waardoor het gemakkelijker wordt om toegang te krijgen tot afdelingen waarmee je vaak interactie hebt.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Jouw Afdeling:</Body1Strong> Dit label filtert de weergave om alleen jouw afdeling te tonen, 
                      waardoor je snel toegang hebt tot directe afdelingsinformatie en updates.</li>
                  </ul>
                  Deze labels zijn ontworpen om je navigatie binnen de afdelingsgids te stroomlijnen, waardoor je vermogen om efficiënt afdelingsinformatie te vinden en ermee om te gaan wordt verbeterd.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. De weergaven van de afdelingsgids in TeamConnect begrijpen
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Navigeren door de afdelingsgids in TeamConnect is gestroomlijnd met meerdere weergaveopties die aan je voorkeuren voldoen. Elke weergave biedt een 
                  unieke manier om uitgebreide afdelingsinformatie te verkennen, inclusief Afdelingsnaam, Afdelingsmanager, Bovenliggende Afdeling, Telefoonnummer van Afdeling, 
                  Hotline van Afdeling, en E-mailadres van Afdeling. Hier is een overzicht van de beschikbare weergaven:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lijstweergave:</Body1Strong> De standaardinstelling die netjes alle afdelingen opsomt, en een 
                      duidelijk, beknopt overzicht biedt.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Grote Kaartweergave:</Body1Strong> Schakel over naar deze weergave om gedetailleerde kaarten van afdelingen te zien, die een uitgebreide samenvatting bieden van de belangrijkste informatie van elke afdeling.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organogramweergave:</Body1Strong> Voor een visuele voorstelling van de afdelingsstructuur en 
                      relaties, illustreert de organogramweergave hoe elke afdeling binnen de grotere organisatorische hiërarchie past.
                    </li>
                  </ul>
                  Om tussen deze weergaven te schakelen, gebruik je simpelweg de iconen naast het zoekvak bovenaan de afdelingsgids. Elke weergave is ontworpen om je te helpen 
                  gemakkelijk toegang te krijgen tot en inzicht te krijgen in afdelingsdetails, zodat je de informatie die je nodig hebt in het formaat dat het beste bij je past, kunt vinden.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. Hoe gebruik je het zoekvak in de afdelingsgids
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Zoek naadloos naar elke afdeling binnen TeamConnect met behulp van het speciale zoekvak dat zich direct naast de weergave-iconen bovenaan de 
                  Afdelingsgids bevindt. Begin simpelweg met het typen van de naam van de afdeling waar je naar zoekt, en zie hoe de gids zichzelf verfijnt om alleen 
                  relevante overeenkomsten weer te geven. Deze functie is speciaal ontworpen om te voorkomen dat je handmatig door de hele gids moet zoeken, en brengt de 
                  afdeling die je nodig hebt direct binnen handbereik. Onthoud dat deze zoekfunctionaliteit uniek is voor de Afdelingsgids van TeamConnect, dus zorg ervoor 
                  dat je dit zoekvak gebruikt in plaats van de hoofdzoekfunctie van Microsoft Teams bovenaan de Teams-interface voor afdelingsspecifieke vragen.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. Hoe werkt het alfabetische filter in de Afdelingsgids?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Stroomlijn je zoekopdracht in de Afdelingsgids met het alfabetische filter bovenaan. Deze functie stelt je in staat om snel afdelingen te verkleinen door 
                  een letter te selecteren. Zodra je een letter kiest, filtert TeamConnect en toont alleen die afdelingen waarvan de namen beginnen met de door jou gekozen 
                  letter, waardoor het gemakkelijker wordt om te vinden wat je zoekt zonder door de hele lijst te hoeven scrollen. Om terug te keren naar de volledige 
                  weergave van de afdeling, deselecteer je gewoon de gemarkeerde letter. Deze intuïtieve alfabetische navigatie zorgt ervoor dat je efficiënt elke afdeling 
                  kunt lokaliseren op basis van zijn beginletter.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. Hoe kan ik contact opnemen met de afdelingsmanager?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Contact opnemen met een afdelingsmanager in TeamConnect is eenvoudig. Zoek simpelweg de afdeling van interesse en klik op de naam van de manager. Deze 
                  actie zal een pop-over activeren die de contactopties van de manager weergeeft met de bekende sneltoegangsiconen:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Chatpictogram:</Body1Strong> Om een direct bericht te starten via Microsoft Teams chat.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Emailpictogram:</Body1Strong> Om een nieuwe e-mail te openen, geadresseerd aan hen in Microsoft Outlook.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Videobel-pictogram:</Body1Strong> Om een videogesprek te starten via Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Bel-pictogram:</Body1Strong> Om een spraakoproep te beginnen met Microsoft Teams.</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Meer (...)-pictogram:</Body1Strong> Voor aanvullende opties zoals het plannen van een vergadering of het toevoegen van 
                      hun contactinformatie aan je Teams lijst.
                    </li>
                  </ul>
                  Deze iconen bieden een naadloze methode om rechtstreeks vanuit TeamConnect contact op te nemen met afdelingsmanagers, wat zorgt voor effectieve communicatie binnen 
                  je organisatie.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. Hoe kan ik medewerkers binnen een afdeling bekijken of contacteren?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Om te ontdekken wie wie is binnen een afdeling in TeamConnect, kijk niet verder dan de drie puntjes naast de afdelingsnaam. Hierop klikken zal 
                  opties voor verdere acties tonen:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Medewerkers in deze afdeling bekijken:</Body1Strong> Selecteer deze optie om een lijst van alle 
                      medewerkers verbonden aan de afdeling weer te geven. Het nummer naast deze optie geeft aan hoeveel medewerkers er in de afdeling zijn, wat een snelle personeelstelling biedt.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organogram van de afdeling:</Body1Strong> Als je de hiërarchie van de afdeling wilt begrijpen, 
                      kies dan 'Organogram van de afdeling' om de structurele lay-out te tonen, die de relatie tussen moederafdelingen, de betreffende afdeling, 
                      en eventuele subafdelingen weergeeft. Deze visuele voorstelling helpt je om de organisatorische structuur te navigeren en te begrijpen, waardoor het makkelijker wordt om specifieke leden van de afdeling 
                      te identificeren en te contacteren.
                    </li>
                    <li><Body1Strong>Afdeling {'>'} Medewerker:</Body1Strong> Na het bekijken van de medewerkers binnen een afdeling, gebruik dit kruimelpad om gemakkelijk 
                      terug te navigeren naar de lijst met afdelingen, wat zorgt voor een soepele verkenning binnen TeamConnect.
                    </li>
                  </ul>
                  Deze functies stellen je niet alleen in staat om de lijst van werknemers in een bepaalde afdeling te zien, maar bieden ook een manier om direct via de communicatietools van TeamConnect contact met hen op te nemen.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. Diepgaande details van afdelingen ontdekken in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Duik in de details van elke afdeling met een simpele klik op de naam ervan in TeamConnect. Er ontvouwt zich een gedetailleerde dialoog, die een rijk palet van 
                  informatie biedt dat een compleet beeld schetst van de rol en middelen van de afdeling binnen je organisatie. Je vindt er alles van leiderschap en 
                  financiële identificatoren tot communicatiekanalen en sociale links. Deze functie is jouw poort naar:
                  <ul>
                    <li className={styles.bulletBottomMargin}>Het hart van de afdeling – haar doel, mensen en prestatie-indicatoren.</li>
                    <li className={styles.bulletBottomMargin}>Contactmogelijkheden – directe lijnen naar belangrijke afdelingsfiguren en communicatieplatformen.</li>
                    <li className={styles.bulletBottomMargin}>Het organisatorisch web – begrijpen hoe de afdeling integreert met de bredere bedrijfsstructuur.</li>
                  </ul>
                  Of het nu gaat om een snelle vraag of een diepgaand onderzoek naar afdelingsdata, TeamConnect voorziet je van de middelen om verbinding te maken met en de unieke ecosystemen binnen je organisatie te begrijpen.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. Benieuwd waarom er geen afdelingsadressen in TeamConnect staan?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Het achterhalen van een afdelingslocatie kan soms aanvoelen als een schattenjacht zonder kaart. In TeamConnect vind je doorgaans geen specifiek 
                  adres voor elke afdeling. Hier is waarom deze cartografische detail vaak wordt weggelaten:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Meerdere Locaties:</Body1Strong> Veel afdelingen zijn niet beperkt tot één ruimte; ze
                      gedijen in verschillende kantoren en geografieën.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Vloeiende Structuur:</Body1Strong> Het organisatorische landschap is altijd in beweging, met 
                      afdelingen die evolueren, samensmelten, of zelfs ontbinden in lijn met strategische verschuivingen.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Interdepartementale Samenwerking:</Body1Strong> Moderne werkplekken bruisen van de samenwerkingsgeest, vaak met talenten uit verschillende afdelingen binnen gedeelde ruimtes.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Remote Werkcultuur:</Body1Strong> Met de flexibiliteit van thuiswerk zijn afdelingsankers niet altijd neergelegd op fysieke kantoorlocaties.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Juridische Nuances:</Body1Strong> Afdelingen draaien doorgaans rond de juridische 
                      structuur van het bedrijf in plaats van dat ze hun eigen zelfstandige adressen hebben.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Operationele Stroomlijning:</Body1Strong> De focus op dynamisch teamwork, niet statische 
                      adressen, helpt operationele behendigheid te behouden.
                    </li>
                  </ul>
                  Het gaat allemaal om het omarmen van een grenzeloze benadering van teamwork en efficiëntie. TeamConnect weerspiegelt deze filosofie door de nadruk te leggen op verbindingen in plaats van locaties.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. Ontbreekt er informatie van uw afdeling of is deze verouderd?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Afdelingsredacteuren zijn binnen uw organisatie aangewezen om afdelingsspecifieke informatie in TeamConnect toe te voegen, bij te werken of te corrigeren.
                  Als u niet zeker weet wie uw afdelingsredacteur is of als bepaalde informatie tussenkomst van het HR-ondersteuningsteam vereist,
                  neem dan contact op met <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> voor begeleiding.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>FAQ's kantoorgids</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>Inleiding kantoorgids:</Body1Strong> Ontdek de kern van de fysieke aanwezigheid van uw organisatie door de kantoorgids,
                die essentiële kantoorgegevens, operationele details en een uitgebreid overzicht van de afdelingen en het personeel dat elke locatie definieert, omvat.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. Tags navigeren in de kantoorgids
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Vind gemakkelijk uw weg in de kantoorgids met ons tagsysteem dat ontworpen is voor snelle toegang tot de juiste locatie. Hier is hoe elke tag uw zoekopdracht kan stroomlijnen:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Alles:</Body1Strong> Toont een volledige lijst van kantoren, waardoor u een vogelvluchtperspectief krijgt van 
                      de locaties van uw organisatie.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Favorieten:</Body1Strong> Helpt u zich te concentreren op die kantoorlocaties die u vaak bezoekt of waarop u 
                      nauwlettend moet letten, door ze als 'Favorieten' te markeren.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Uw kantoor:</Body1Strong> Dit persoonlijke label filtert de gids om u de kantoorlocatie te tonen waar u 
                      gestationeerd bent, en houdt uw thuisbasis binnen handbereik.
                    </li>
                  </ul>
                  Of u nu een vergaderruimte boekt of een bezoek plant, deze tags bieden een gepersonaliseerde en vereenvoudigde manier om te interageren met het geografische landschap van uw organisatie.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. Het beheersen van kantoorlocatieoverzichten in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Duik in de organisatorische indeling van de verschillende locaties van je bedrijf met de Office Directory weergaven van TeamConnect. Elke weergave is ontworpen om 
                  een uniek aspect van kantoornavigatie en begrip te bevorderen:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Lijstweergave:</Body1Strong> De standaardinstelling die alle kantoorlocaties netjes organiseert in 
                      een makkelijk te lezen lijst.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Grote Kaartweergave:</Body1Strong> De Grote Kaartweergave in de Office Directory verrijkt je 
                      zicht met een gedetailleerde presentatie van elk kantoor. Wanneer je met een kantoor kaart omgaat, vind je veel informatie en uitvoerbare opties 
                      binnen het menu "Meer (...)":
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>Weergave afdelingen:</Body1Strong> Navigeer direct naar een lijst
                          van alle afdelingen binnen het kantoor.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Weergave werknemers:</Body1Strong> Toegang tot een volledige lijst van werknemers toegewezen aan het 
                          kantoor, wat direct contact of verkenning van kantoorpersoneel vergemakkelijkt.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Organisatieschema bedrijfsentiteit:</Body1Strong> Visualiseer de plaats van het kantoor binnen de grotere 
                          organisatorische context voor een duidelijk begrip van hiërarchische structuren.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Essentiële contacten:</Body1Strong> Identificeer belangrijke kantoorcontacten voor gestroomlijnde 
                          communicatie.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Vakantiedagen kantoor:</Body1Strong> Blijf op de hoogte van de specifieke feestdagen van het kantoor om je schema's dienovereenkomstig te plannen.
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>Werkuren kantoor:</Body1Strong> Bekijk de openingstijden van het kantoor om je bezoeken of oproepen af te stemmen op hun actieve tijden.
                        </li>
                      </ol>
                      Deze weergave biedt niet alleen een visuele en informatieve momentopname maar ook snelle toegang tot diepere inzichten en directe acties gerelateerd aan het kantoor, allemaal netjes onder de optie "Meer (...)" voor jouw gemak.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organisatieschema Weergave:</Body1Strong> Visualiseer de hiërarchische structuur van moeder- en subkantoren 
                      om de onderlinge verbondenheid van verschillende kantoorlocaties binnen je organisatie te begrijpen.
                    </li>
                  </ul>
                  Wissel tussen deze weergaven met de iconen naast het zoekvak om je browse-ervaring aan te passen aan je directe behoeften.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. Snel kantoren vinden met de zoekfunctie van TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Het vinden van de juiste kantoorlocatie binnen TeamConnect wordt vergemakkelijkt met de speciale zoekfunctie. Prominent naast de weergave-icoontjes gepositioneerd, is het zoekvak jouw hulpmiddel om kantoren op naam te pinpointen. Terwijl je typt, verfijnt de directory de weergave om alleen kantoren te tonen die overeenkomen met jouw invoer, waardoor je zoekproces wordt gestroomlijnd. Het is belangrijk om dit zoekvak binnen TeamConnect te gebruiken voor de meest nauwkeurige en relevante resultaten, in tegenstelling tot de algemene zoekfunctie van Microsoft Teams. Met deze gerichte zoekfunctionaliteit kun je snel navigeren naar elk kantoor in het netwerk van je organisatie.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. Alfabetisch navigeren door de kantoorgids
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Als u de voorkeur geeft aan zoeken op alfabet, is onze alfabetische filter een snelle manier om kantoren op naam te vinden. Bovenaan de kantoorgids ziet u de letters A tot Z. Als u op een letter klikt, wordt de weergave gefilterd om alleen die kantoren te tonen die met die letter beginnen, wat uw zoekproces vereenvoudigt. Om opnieuw te resetten en alle kantoren weer te zien, deselecteert u gewoon de actieve letter. Deze functie is vooral nuttig als u bekend bent met de naam van het kantoor, maar snel de locatie binnen TeamConnect moet vinden.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. Uitgebreide kantoorprofielen onthullen in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Begin aan een verkennende reis door het hart van de fysieke aanwezigheid van uw organisatie met de kantoorprofielen van TeamConnect. 
                  Door op een kantoornaam te klikken, wordt een levendig profieldialoogvenster geopend, boordevol tabbladen met gedetailleerde inzichten in het kantoor:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Overzicht:</Body1Strong> Duik in de kern van de identiteit van het kantoor met gedetailleerde 
                      beschrijvingen, van bedrijfsstructuur tot openingstijden en lokale tijd, en vang de essentie van de kantooromgeving.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Essentiële contacten:</Body1Strong> Toegang tot cruciale contacten voor nooddiensten en 
                      externe ondersteuning, elk een klik verwijderd via het Microsoft Teams Phone System.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kantoorcontacten:</Body1Strong> Neem gemakkelijk contact op met interne ondersteuningsdiensten, met 
                      contactgegevens en directe betrokkenheidsopties binnen handbereik.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Openbare feestdagen:</Body1Strong> Blijf op de hoogte van kantoorspecifieke feestdagen, zorg dat uw 
                      plannen overeenkomen met lokale waarnemingen en werkschema's.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Kantooruren:</Body1Strong> Plan uw interacties met precieze kennis van wanneer het 
                      kantoor bruist van activiteit of afbouwt voor de dag.
                    </li>
                  </ul>
                  Of het nu de bruisende energie van Austin is of het strategische knooppunt van Singapore, elk tabblad biedt een toegangspoort om te begrijpen en verbinding te maken met de diverse locaties die de mondiale aanwezigheid van uw bedrijf vormen.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. De Dynamiek van Afdelingen Binnen Een Kantoor Onderzoeken
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Het navigeren door het professionele netwerk van de afdelingen van een kantoor verloopt moeiteloos in TeamConnect. Hier is hoe je in de details kunt duiken:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Bekijk Afdelingen in Dit Kantoor:</Body1Strong> Ontdek alle afdelingen die de basis 
                      vormen van het kantoor door deze optie te selecteren uit het Meer (...) menu.
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>Organisatorisch Inzicht:</Body1Strong> Klik op een afdeling om zijn organogram te onthullen voor 
                      een visuele weergave van zijn structuur, met het breadcrumb spoor dat je begeleidt: Kantoor {'>'} Afdeling {'>'} Schema.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Diepgaand Onderzoek van de Afdeling:</Body1Strong> Verken verder een afdeling door te klikken op het 
                      Meer menu (...) naast de naam van de afdeling. Hier kun je een lijst van alle medewerkers binnen die afdeling verkrijgen, rechtstreeks in de context van het kantoor.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Breadcrumb Navigatie:</Body1Strong> Navigeer naadloos door de niveaus. Van 
                      medewerkers terug naar afdelingen of vanuit het organogram, het breadcrumb spoor (Kantoor {'>'} Afdeling {'>'} Medewerker {'>'} Schema) houdt je 
                      reis intuïtief en op koers.
                    </li>
                  </ul>
                  De complexe paden van TeamConnect nodigen je uit om te verkennen, te begrijpen en te betrekken bij elk niveau van de afdelingen van je kantoor, waarbij je wordt uitgerust met 
                  een volledige navigatiebevoegdheid.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. Verbinding Maken met Kantoorpersoneel in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Benieuwd naar de gezichten achter een kantoor? Het antwoord is slechts één klik verwijderd in TeamConnect. Ga om met de ellips (drie puntjes) naast je gekozen 
                  kantoornaam om een reeks interactieve opties te onthullen:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Bekijk Medewerkers in Dit Kantoor:</Body1Strong> Ontdek een lijst van alle professionals 
                      die dit kantoor hun werk thuis noemen. Het getoonde aantal biedt een snelle telling van de medewerkers, waarbij je wordt uitgenodigd om in te duiken in de kantoorgemeenschap.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Contactmogelijkheden:</Body1Strong> Elk medewerkersinvoer is uitgerust met snelle-actie iconen, 
                      waardoor je chats, oproepen of zelfs videoconferenties direct vanuit TeamConnect kunt starten, waardoor naadloze intra-kantoor communicatie bevorderd wordt.
                    </li>
                    <li><Body1Strong>Navigatie:</Body1Strong> Om terug te keren naar de bredere kantoorlijst vanuit een medewerkerslijst, klik je eenvoudig op 'Kantoor' in de breadcrumb 
                      trail (Kantoor {'>'} Medewerker), en je wordt teruggebracht naar het overzicht van het kantoor.
                    </li>
                  </ul>
                  Met deze functies maakt TeamConnect het eenvoudig om de distributie van medewerkers over kantoren te visualiseren en moeiteloos contact te leggen, 
                  waardoor een verbonden werkplek wordt bevorderd, ongeacht de afstand.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. Ontbreekt er informatie over uw kantoor of is deze verouderd?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Updates van kantoorinformatie in TeamConnect kunnen worden behandeld door toegewezen kantoorredacteuren. Als u onzeker bent over de kantoorredacteuren of
                  hulp nodig heeft met informatie die onder de bevoegdheid van HR valt, staat het HR-ondersteuningsteam klaar om
                  te helpen op <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>.
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>Instellings-FAQ's</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. Het belang van nauwkeurige algemene instellingen in TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  In het dynamische landschap van de huidige werkomgeving is verbonden en gecoördineerd blijven belangrijker dan ooit. Daarom speelt het bijwerken van uw 
                  algemene instellingen in TeamConnect een cruciale rol:
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Selectie van de werkplaats:</Body1Strong> Leg de essentie van uw werkomgeving vast door uw werkplaats bij te werken om uw huidige 
                        situatie weer te geven, of u nu overstapt van de rust van uw thuiskantoor naar de drukte van het hoofdkantoor, of zelfs settelt in een openbare ruimte. 
                        Deze duidelijkheid over uw werklocatie biedt collega's inzicht niet alleen in de beste manieren om contact met u op te nemen, maar ook in de aard van 
                        gesprekken die ze met u kunnen voeren, waarbij de vertrouwelijkheid van gevoelige discussies wordt gewaarborgd, vooral wanneer u zich in de open 
                        omgeving van een kantoor van een klant bevindt of in een luchthavenlounge zit.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Specificatie van de werkplek:</Body1Strong> Het delen van uw werkstad doet meer dan afstanden overbruggen in een op afstand werkend team - het opent ook 
                        de deuren naar spontane professionele bijeenkomsten of informeel socialiseren. Of het nu gaat om een snelle koffie, een lange lunch of een collaboratieve 
                        bijeenkomst, weten dat u in dezelfde stad bent, kan van een gewone dag een kans maken voor betekenisvolle face-to-face verbindingen. Dit versterkt niet 
                        alleen de teambanden, maar biedt ook een rijker begrip van de lokale nuances die uw werk kunnen beïnvloeden.
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>Nauwkeurigheid van tijdzone:</Body1Strong> Aangezien teams continenten overspannen, is het essentieel dat uw tijdzone 
                      uw huidige locatie weerspiegelt voor het harmoniseren van communicatie. De lokale tijd op uw TeamConnect-profiel wordt rechtstreeks beïnvloed door deze instelling, 
                      waardoor elke giswerk voor collega's die proberen contact met u op te nemen, wordt geëlimineerd.
                    </li>
                  </ul>
                  Duik in 'Instellingen' {'>'} 'Algemene instellingen' binnen TeamConnect om deze details te verfijnen. Een paar momenten besteed aan het bijwerken van uw instellingen kunnen 
                  de samenhang en efficiëntie van uw samenwerkingsinspanningen aanzienlijk verbeteren.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          
        <AccordionItem value="40">
          <AccordionHeader>
            <Body1Strong className={styles.fontColor}>
              40. Werkplekselectie ontcijferen in TeamConnect
            </Body1Strong>
          </AccordionHeader>
          <AccordionPanel>
            <Body1>
              Het kiezen van de juiste instelling in de dropdown Werkplek stemt TeamConnect af om uw huidige werkstatus nauwkeurig weer te geven. Beweeg over het info-icoon voor een gedetailleerde uitleg van elke optie:
              <ul>
                <li className={styles.bulletBottomMargin}><Body1Strong>Afgemeld/Pauze:</Body1Strong> Geeft tijd weg van het werk aan, of het nu om een pauze of het einde van de dag gaat.</li>
                <li className={styles.bulletBottomMargin}><Body1Strong>Kantoor/Veldkantoor:</Body1Strong> Geeft aanwezigheid in een door het bedrijf beheerde werkruimte aan.</li>
                <li className={styles.bulletBottomMargin}><Body1Strong>Thuis-Kantoor:</Body1Strong> Werken vanuit een speciaal ingerichte thuiswerkplek.</li>
                <li className={styles.bulletBottomMargin}><Body1Strong>Afgelegen Werkplek/Openbare Ruimte:</Body1Strong> Werken vanuit een niet-kantooromgeving, wat wijst op een behoefte aan flexibele communicatie.</li>
                <li className={styles.bulletBottomMargin}><Body1Strong>Klant Werkplek/Privékantoor:</Body1Strong> Ter plaatse bij een klant, met een notitie over privacy niveaus voor gesprek gevoeligheid.</li>
                <li className={styles.bulletBottomMargin}><Body1Strong>Onderweg/Zakelijke Reis/Training:</Body1Strong> Onderweg of bezig met specifieke werkgerelateerde activiteiten buiten het kantoor.</li>
                <li className={styles.bulletBottomMargin}><Body1Strong>Openbare Vakantie/Vakantie/Diverse Verloven:</Body1Strong> Officieel vrij voor persoonlijke, gezondheids- of vakantieredenen.</li>
              </ul>
              Het selecteren van een geschikte werkplekinstelling zorgt ervoor dat uw team de context heeft die nodig is voor interacties, met respect voor beschikbaarheid en privacy.
            </Body1>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem value="41">
          <AccordionHeader>
            <Body1Strong className={styles.fontColor}>
              41. Navigeren in de 'Ingecheckt'-status in TeamConnect
            </Body1Strong>
          </AccordionHeader>
          <AccordionPanel>
            <Body1>
              Het label 'Ingecheckt' in de Medewerkersgids is ontworpen om alle medewerkers te tonen die momenteel aan het werk zijn. Deze zichtbaarheid is bepaald door de werkplekselectie die u maakt:
              <ul>
                <li className={styles.bulletBottomMargin}><Body1Strong>Actieve Werkomgevingen:</Body1Strong> De keuzes Kantoor, Veldkantoor, Thuis-Kantoor, Afgelegen Werkplek, Openbare Ruimte, Klant Werkplek, Klant Privékantoor, Onderweg, Zakelijke Reis, en Training geven aan dat u bezig bent met werk, waardoor u 'Ingecheckt' en zichtbaar actief voor collega's bent.
                </li>
                <li className={styles.bulletBottomMargin}><Body1Strong>Instellingen Niet-Werk:</Body1Strong> Opties zoals Afgemeld, Pauze, Openbare Vakantie, Vakantie, Verlof, Moederschapsverlof, of Ziekteverlof 
                  verwijderen u van de 'Ingecheckt' lijst, en stellen duidelijke verwachtingen voor beschikbaarheid en reactietijden.
                </li>
              </ul>
              Deze functionaliteit helpt werkperioden te onderscheiden van persoonlijke tijd, wat bijdraagt aan effectieve team samenwerking en communicatie.
            </Body1>
          </AccordionPanel>
        </AccordionItem>
        </Accordion>
        </div>
        <p></p>
        <Subtitle2>Gegevensbeheer en privacy FAQ's</Subtitle2>
          <Body1>
            <p>
              In het digitale tijdperk van vandaag is het cruciaal om te begrijpen hoe uw gegevens worden beheerd. TeamConnect zet zich in voor transparantie en robuuste gegevensbeschermingspraktijken.
              Hieronder vindt u informatie over hoe wij uw gegevens binnen TeamConnect behandelen, opslaan en beschermen, om te zorgen dat uw digitale werkruimte veilig en conform is.
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. Hoe beschermt TeamConnect mijn persoonlijke gegevens?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect zet zich in om uw persoonlijke informatie te beschermen met een uitgebreide benadering van gegevensbeveiliging:</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Single Sign-On (SSO):</Body1Strong> Om beveiliging te versterken en de gebruikerservaring te vereenvoudigen, integreert TeamConnect automatisch 
                      Single Sign-On (SSO) met uw Microsoft 365-account, wat zorgt voor veilig en moeiteloos toegangsbeheer.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Versleuteling:</Body1Strong> We zorgen ervoor dat alle gegevens binnen TeamConnect versleuteld zijn, zowel tijdens het transport als wanneer ze in rust zijn,
                      waardoor ze niet toegankelijk zijn voor onbevoegde partijen.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Data Minimalisatie:</Body1Strong> Onze gegevensverzameling is strikt beperkt tot wat essentieel is voor de functionaliteit van TeamConnect. 
                      De enige gebruikersinformatie die we opslaan is uw Gebruikers Hoofdnaam (UPN) van Azure Entra ID, dat kan lijken op uw bedrijfsemailadres.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Beperkte Toegang:</Body1Strong> Toegang tot de UPN is beperkt tot de Abonnement Beheerder van uw organisatie binnen ons abonnementsportal,
                      zorgend dat gevoelige informatie alleen zichtbaar is voor geautoriseerd personeel.
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>Prompte Gegevensverwijdering:</Body1Strong> TeamConnect is zo gestructureerd dat de opgeslagen UPN onmiddellijk wordt verwijderd onder drie specifieke voorwaarden:
                      als het abonnement wordt geannuleerd door de Abonnement Beheerder, als een gebruiker wordt verwijderd door HR binnen TeamConnect, of als het TeamConnect 
                      abonnement verloopt. Dit zorgt ervoor dat uw persoonlijke gegevens niet langer worden bewaard dan nodig of nuttig is.
                    </li>                      
                  </ul>
                  <p>Met deze maatregelen zet TeamConnect zich in om de hoogste normen van gegevensbescherming te handhaven, zodat uw persoonlijke informatie veilig en vertrouwelijk blijft.</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. Benieuwd waar uw gegevens zich bevinden met TeamConnect?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect doorbreekt het traditionele SaaS-aanbod door te zorgen dat uw gegevens uw organisatie niet verlaten.
                  In tegenstelling tot de gebruikelijke praktijk van het opslaan van gegevens op externe cloud servers, benut TeamConnect vindingrijk de 
                  Microsoft 365 SharePoint Online van uw organisatie om alle applicatiegegevens te huisvesten. Deze innovatieve aanpak bevordert niet alleen de soevereiniteit van gegevens maar versterkt ook de beveiliging,
                  perfect in lijn met onze vaste toewijding aan het beschermen van uw privacy en het naleven van strenge nalevingsnormen.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. Zullen mijn gegevens met derden worden gedeeld?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  Nee, gegevens worden met derden gedeeld! Cubeet is standvastig in ons streven naar privacy en veiligheid van uw gegevens. Wij delen geen TeamConnect-gegevens met derden zonder expliciete toestemming van onze klanten.
                  In onze beveiligde omgeving slaan we alleen essentiële identificatoren op, zoals de User Principal Name (UPN), organisatienaam, tenantnaam en tenant-ID, binnen ons abonnementsportaal.
                  Deze informatie is cruciaal voor de dienstverlening en wordt beschermd onder onze strikte privacybeleid.
                  Wees gerust, onze gegevensbeheerpraktijken zijn ontworpen om ervoor te zorgen dat uw gegevens vertrouwelijk blijven, met transparantie en controle stevig in uw handen.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. Hoe voldoet TeamConnect aan gegevensbeschermingsregelgeving zoals de AVG?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect stemt overeen met gegevensbeschermingsregelgeving zoals de AVG, HIPAA, CCPA en andere door de inherente compliance-functies van de Microsoft 365-omgeving te benutten,
                  waar alle klantgegevens veilig worden opgeslagen. In tegenstelling tot traditionele SaaS-modellen slaat TeamConnect geen klantgegevens op zijn servers op; in plaats daarvan functioneert het binnen uw
                  Microsoft 365-omgeving van uw organisatie, in overeenstemming met de robuuste beveiligings- en nalevingsnormen daarvan. Deze aanpak zorgt ervoor dat uw gegevensbeheer consistent blijft met
                  het uitgebreide nalevingskader dat Microsoft 365 biedt, inclusief naleving van AVG, HIPAA voor gezondheidsinformatie, CCPA voor inwoners van Californië,
                  en andere wereldwijde gegevensbeschermingsregelgevingen. Door naadloos te integreren met uw bestaande Microsoft 365-setup, stelt TeamConnect uw organisatie in staat
                  volledige controle over uw gegevens te behouden, waarbij wordt voldaan aan de strengste gegevensbeschermingswetten en -regelgevingen.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. Hoe gaat TeamConnect om met gegevens uit Microsoft Teams chats, gesprekken, videogesprekken, Outlook-e-mails en telefoonsysteemgesprekken?
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect benut de Microsoft Graph API om directe interacties zoals chat, gesprekken, videogesprekken en Outlook-e-mail binnen Microsoft Teams mogelijk te maken,
                  wat zorgt voor een naadloze gebruikerservaring. Belangrijk is dat hoewel TeamConnect deze communicaties initieert, dit gebeurt zonder tussenkomst in het communicatieproces
                  of de mogelijkheid om enige inhoud van de interactie op te nemen of te bewaren. Alle gesprekken, oproepen en e-mails vinden plaats en worden rechtstreeks beheerd binnen de
                  veilige grenzen van Microsoft Teams en Outlook, in strikte naleving van Microsoft's 365 robuuste beveiligings- en privacyrichtlijnen. Dit zorgt ervoor dat TeamConnect 
                  uw communicatiemogelijkheden verbetert terwijl volledig de vertrouwelijkheid en integriteit van uw gegevens behouden blijft.
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            Voor gedetailleerde inzichten in ons databeleid en praktijken, wilt u misschien ook de {' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>TeamConnect Gebruikershandleiding</Body1Strong></Link> of de IT-afdeling van uw organisatie op <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> raadplegen voor specifieke informatie gerelateerd aan uw configuratie.
          </p>
        </Body1>        
        <p></p>  
        <Subtitle1 className={styles.fontColor}>We Luisteren: Deel Uw Feedback en Ideeën</Subtitle1>
        <Body1>
          <p>
            Uw inzichten sturen de evolutie van TeamConnect. Of u nu barst van een briljant functie-idee of wat feedback hebt op uw huidige ervaring, we zijn één en al oor. Uw input is onschatbaar in onze continue zoektocht om de best mogelijke service te bieden.
          </p>
        </Body1>      
        <Subtitle2>Feedback en Feature Suggesties</Subtitle2>
        <Body1>
          <p>
            Klaar om te delen? Ga naar onze {' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              Feedbackpagina
            </Link>
            . Of het nu een suggestie voor een nieuwe functie is of feedback op bestaande functionaliteiten, selecteer gewoon 
            de juiste optie uit de dropdown in het formulier. Uw bijdragen helpen de toekomst van TeamConnect vorm te geven, 
            zodat het voldoet aan en overtreft uw behoeften.
          </p>
          </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};
export default Container;
