import PropTypes from 'prop-types';
import {
  makeStyles, tokens,
  Link, Body1, Body1Strong, Subtitle2, Subtitle1, Title3,
  Accordion, AccordionHeader, AccordionItem, AccordionPanel,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  fontColor: {
    color: tokens.colorBrandForeground1,  
  },
  bulletBottomMargin: {
    marginBottom: '8px',
  },
  scrollToTopButton: {
    position: 'fixed',
    right: '5%',
    transform: 'translateX(95%)',
    bottom: '8px',
  },
});

function Container({
  itSupport,
  hrSupport,
  feebackLink,
  userGuideLink,
  openItems,
  handleToggle,
}) {
  const styles = useStyles();

  return (
    <>
      <Title3 className={styles.fontColor}>TeamConnect帮助中心和常见问题解答</Title3>
        <p><Body1>
          欢迎访问TeamConnect帮助中心，旨在支持您使用我们全面的企业目录应用。在这里，您将找到优化在Microsoft Teams中使用TeamConnect的资源，增强您的网络通信。
        </Body1></p>
        <Subtitle2>快速参考指南</Subtitle2>
        <ul>
          <Body1>
            <li className={styles.bulletBottomMargin}><Body1Strong>增强搜索功能：</Body1Strong>
              通过我们强大的搜索工具迅速找到合适的同事、部门和重要联系人。
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>视觉组织图表：</Body1Strong>
              通过直观的组织图表轻松导航您的组织层级并理解汇报线路。
            </li>
            <li className={styles.bulletBottomMargin}><Body1Strong>适应当今需求的工具：</Body1Strong>
              利用现代功能，满足当代工作场所的需求。
            </li>
          </Body1>
        </ul>
        <p></p>
        <Subtitle2>优化您的体验</Subtitle2>
        <Body1>
          <p>
            TeamConnect彻底改变了您在组织结构内的互动方式，将日常通信转变为战略性接触。
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>获取帮助和常见问题解答</Subtitle1>
        <Body1>
          <p>
            在这个专用部分找到关于TeamConnect的功能和设置的常见问题答案。无论您是在导航应用程序，自定义设置，还是寻求解决挑战的解决方案，我们的帮助中心都在这里为您提供高效的帮助。
          </p>
        </Body1>
        <Body1>
          <p>
            如需更深入的支持或具体指导，请咨询<Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong className={styles.fontColor}>TeamConnect用户指南</Body1Strong></Link>。
          </p>
        </Body1>
        <p></p>
        <Subtitle2>使用 TeamConnect 常见问题解答</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="1">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  1. 在 Microsoft Teams 侧边栏中找不到 TeamConnect？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  如果在您的 Microsoft Teams 侧边栏中看不到 TeamConnect 或者它已消失，您可以轻松地将其固定，以便快速访问。只需遵循以下简单步骤：
                  <ol>
                    <li className={styles.bulletBottomMargin}>打开 Microsoft Teams 并单击侧边栏底部的 “...”（+ 应用程序）图标。</li>
                    <li className={styles.bulletBottomMargin}>在弹出窗口顶部的搜索栏中搜索 “TeamConnect”。</li>
                    <li className={styles.bulletBottomMargin}>右键点击搜索结果中的 TeamConnect 应用程序并选择 “固定”。</li>
                  </ol>
                  TeamConnect 现在将固定在您的侧边栏上，只需单击即可访问！
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="2">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  2. 遇到 TeamConnect 的技术问题？以下是获得帮助的方法。
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  如果您在 Microsoft Teams 中使用 TeamConnect 遇到任何技术问题，您的组织的 IT 支持团队在此提供帮助。
                  通过 <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> 与他们联系，他们将迅速为您提供帮助。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="3">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  3. 看不到您的员工详细信息？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  如果您的个人资料看起来有些空荡，只显示您的姓名和电子邮件，这可能是因为您的 HR 团队尚未将其他员工详细信息添加到 TeamConnect 中。
                  不用担心！向您的 HR 支持团队发送快速消息将启动事情。他们随时准备用所有重要的细节填写您的个人资料。
                  通过 <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link> 联系他们，观看您的个人资料焕发生机！
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="4">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  4. TeamConnect支持哪些语言选项？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect支持Microsoft Teams的全球多样性，支援所有其语言。
                  无论您喜欢阿拉伯语还是希腊语，日语或瑞典语，您都可以在指尖上选择一个世界，包括：
                </Body1>
                <ul>
                  <li>
                    <Body1>
                      阿拉伯语、保加利亚语、中文（简体和繁体）、克罗地亚语、捷克语、丹麦语、荷兰语、英语、爱沙尼亚语、芬兰语、
                      法语、德语、希腊语、希伯来语、匈牙利语、印度尼西亚语、意大利语、日语、韩语、拉脱维亚语、立陶宛语、挪威语（Bokmal）、
                      波兰语、葡萄牙语（巴西和葡萄牙）、罗马尼亚语、俄语、塞尔维亚语（拉丁语）、斯洛伐克语、斯洛文尼亚语、西班牙语、瑞典语、泰语、
                      土耳其语、乌克兰语、越南语。
                    </Body1>
                  </li>
                </ul>
                <Body1>
                  要根据您偏好的语言自定义您的TeamConnect体验，请直接在Microsoft
                  Teams中调整语言设置，因为TeamConnect完全集成了这些设置：
                </Body1>
                <ol>
                  <Body1>
                    <li className={styles.bulletBottomMargin}>
                      通过点击位于顶部的个人资料图片旁的三个点并选择“外观和辅助功能”进入您的Microsoft
                      Teams设置。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      滚动到“语言”部分并从下拉菜单中选择您偏好的语言。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      点击“保存并重启”按钮以应用新的语言设置，确认您的更改。
                    </li>
                  </Body1>
                </ol>
                <Body1Strong>注意： </Body1Strong>
                <Body1>
                  在Microsoft
                  Teams中对您的语言设置所做的更改也将无缝更新您的TeamConnect体验。
                  请注意，更改Teams中的语言也可能调整您的时间和日期格式。目前，
                  TeamConnect正在进化以支持从右到左的书写。我们对这些增强功能感到兴奋
                  并将随时通知您它们何时可用。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="5">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  5. 如何在TeamConnect中更改主题？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect会与您的Microsoft Teams主题偏好同步，包括浅色、深色和高对比度选项。自定义您的主题：
                  <ol>
                    <li className={styles.bulletBottomMargin}>通过点击顶部个人资料图片旁的三个点来访问您的Microsoft Teams设置。</li>
                    <li className={styles.bulletBottomMargin}>选择“外观和可访问性”来探索主题选项。</li>
                    <li className={styles.bulletBottomMargin}>从可用选项中选择您喜欢的主题，即可立即更新您的TeamConnect界面以匹配。</li>
                  </ol>
                  您在Microsoft Teams中的主题选择将自动应用到TeamConnect，确保在您的工作空间中提供一致的视觉体验。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="6">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  6. 探索TeamConnect的联系人操作按钮
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在TeamConnect中，每当您找到员工的联系详情，您也会发现我们便捷的快速访问按钮。这些方便的工具旨在简化您在Microsoft生态系统内的沟通，确保您总是只需点击一下就能连接。
                  这里是有效利用这些按钮的全面指南：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>聊天图标：</Body1Strong> 点击开始Microsoft Teams即时聊天。如果您需要在开始聊天后返回TeamConnect，只需使用桌面应用中的后退箭头、移动应用中的后退按钮或您浏览器的后退按钮。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>电子邮件图标：</Body1Strong> 打开一个新的Outlook电子邮件草稿，收件人地址已准备好在“To:”字段中。如果Outlook没有启动，值得检查您的IT部门是否有任何限制。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>视频通话图标：</Body1Strong> 使用Microsoft Teams发起面对面视频通话。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>通话图标：</Body1Strong> 开始Microsoft Teams语音通话，使快速讨论变得无缝。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>组织图标：</Body1Strong> 显示员工在组织层级中的位置，包括他们的经理和直接下属。</li>
                    <li><Body1Strong>更多（...）图标：</Body1Strong> 揭示更多选项：
                      <ul>
                        <li style={{marginTop: '8px'}} className={styles.bulletBottomMargin}><Body1Strong>立即会议：</Body1Strong> 打开Microsoft Teams，与员工开始即时会议。</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>新会议：</Body1Strong> 引导您前往Microsoft Teams安排新会议。</li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>添加联系人：</Body1Strong> 将员工的联系详情从TeamConnect添加到您的新Microsoft Teams People应用中，增强您的通话功能。</li>
                      </ul>
                    </li>
                  </ul>
                  这些直观的按钮旨在确保您可以有效地与同事联系，无论是进行快速聊天、发送电子邮件还是安排未来的会议。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="7">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  7. 通过TeamConnect拨打电话
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  想要拨打电话吗？TeamConnect通过与Microsoft Teams集成，简化了您的通信。
                  只需在TeamConnect中点击所需的电话号码，Microsoft Teams的通话界面就会弹出，让您无缝拨打电话。
                  <p>
                  <Body1Strong>注意：</Body1Strong>
                  遇到拨打电话的问题？这可能是由于您组织的IT政策或使用的电话系统不是Microsoft Teams Phone System。联系您的IT部门<Link href={`mailto:${itSupport}`}>
                  <Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>，可以了解情况。
                  </p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="8">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  8. 使用TeamConnect拨打电话有相关成本吗？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect通过利用Microsoft Teams增强了您的通话体验。以下是您需要了解的与通话相关的成本信息：
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      对于拨打员工工作电话、部门电话或组织内部的热线（内部号码），
                      使用Microsoft Teams Phone System通常<Body1Strong>没有成本</Body1Strong>。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      对于拨打外部电话，例如移动电话或外线，适用<Body1Strong>标准费率</Body1Strong>，这些费率根据您在Microsoft Teams的具体服务计划而有所不同。
                    </li>
                  </ul>
                  <p><Body1Strong>注意：</Body1Strong> 如果您遇到任何问题或有关通话费用的问题，最好咨询您的
                    IT部门，在<Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> 这里可以得到针对您组织的设置和政策的详细信息。</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="9">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  9. 在TeamConnect中，您的“离开办公”状态显示不匹配？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  注意到您在Microsoft 365中的“离开办公”状态在TeamConnect中没有正确反映？我们已经为您解决了这个问题。
                  同步“离开办公”状态的功能已在TeamConnect中实现。我们当前正在等待Microsoft推出此功能。一旦实现，您的“离开办公”状态将在TeamConnect中准确反映，与Microsoft Teams的在位指示符无缝对接，实现流畅体验。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>        
        <Subtitle2>员工目录常见问题解答</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>员工目录简介：</Body1Strong> 探索您的组织中的专业风貌，员工目录是您获取详细员工见解、职场动态以及与同事的档案和管理关系直接连接的中心。
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="10">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  10. 需要添加、更新或更正您的员工数据吗？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  如需在TeamConnect中添加、更新或更正您的员工数据，请联系您组织的人力资源支持团队。
                  他们随时愿意帮助您，在<Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="11">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  11. 当我点击目录中某个员工的名字时会发生什么？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在TeamConnect员工目录中点击一个员工的名字会打开该个人的详细资料页面。这个资料提供了关于员工的全面洞察，包括但不限于他们的职位名称、部门、办公地点、联系信息等。它旨在为您提供员工的职业角色和他们在组织中的定位的全面概述，帮助您了解他们的职责以及您可能与他们合作的方式。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="12">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  12. 员工资料中包含哪些信息？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect中的每个员工资料都是一个全面的快照，提供了对个人职业生活的深入洞察。以下是您将找到的内容：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>基本详情：</Body1Strong> 姓名、职位名称、部门及子公司位置。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>汇报结构：</Body1Strong> 直接和职能汇报的信息，包括直线和职能经理。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>工作状态：</Body1Strong> 当前工作状态，如休假、通勤、出差等。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>工作地点和当地时间：</Body1Strong> 员工当前的工作地点和当地时区。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>联系详情：</Body1Strong> 工作电话、移动电话和电子邮件地址。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>办公地址：</Body1Strong> 完整的办公位置，包括建筑名称、地址和谷歌地图。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>关于：</Body1Strong> 简短的个人介绍或职业简历。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>员工关系：</Body1Strong> 员工汇报结构和团队关系的详细内容。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>社交链接和语言：</Body1Strong> 专业社交档案链接和语言熟练度。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>技能：</Body1Strong> 专业技能和专长领域的列表。</li>
                  </ul>
                  此外，资料还提供了通过聊天、电子邮件、视频通话或安排会议连接的可行选项，以及快速访问组织图表以获得更广泛团队环境的功能。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="13">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  13. 如何更新我的个人资料信息？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在TeamConnect中个性化您的个人资料很容易。以下是操作方法：
                    <ol>
                      <li className={styles.bulletBottomMargin}>
                        导航至<Body1Strong>员工</Body1Strong>标签，然后点击您的<Body1Strong>头像</Body1Strong>打开您的个人资料。
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        在左上角，您会找到<Body1Strong>"编辑您的个人资料"</Body1Strong>链接。
                        点击它将打开编辑对话框。
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        在这里，您可以通过添加或更新您的<Body1Strong>关于您</Body1Strong>部分、社交媒体链接和电话号码来增强您的个人资料。
                        这让您的同事更容易联系或关注您。
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        不要忘记按<Body1Strong>保存</Body1Strong>按钮来应用您的更改。
                      </li>
                      <li className={styles.bulletBottomMargin}>
                        在对话框中向下滚动，以添加您的<Body1Strong>语言</Body1Strong>和<Body1Strong>熟练度</Body1Strong>，
                        以及您的<Body1Strong>技能</Body1Strong>。分享这些信息有助于您的同事了解您的专业领域以及您能用的语言。
                      </li>
                    </ol>
                  更新您的个人资料不仅能保持您的信息是最新的，还有助于在您的组织内建立更好的联系。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="14">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  14. 更新您的工作地点、工作站和时区在TeamConnect
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在Microsoft Teams中保持您的工作地点、工作站和时区信息是至关重要的，以实现无缝协作。
                  这些更新会立即反映在您的TeamConnect资料和列表中，使同事们能迅速理解您的工作地点
                  和最佳联络时间，加强不同地区和时区间的团队合作。
                  <p></p>
                  准备更新您的详细信息了吗？在TeamConnect中很简单！前往顶部菜单中的'设置'，导航到'常规设置'。
                  在这里，您可以顺畅地更新您的工作地点和时区，并指定您当天的工作站。记得保存您的更改，
                  以保持每个人的信息更新并确保无论您在何地都能有效协作！
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="15">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  15. 发现同事个人资料中的错误了吗？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  如果您发现同事的个人资料中有些信息看起来不太对劲，最好的第一步是给他们一个友好的提示。
                  一次快速的聊天或电子邮件应该可以解决问题！如果由于某种原因信息未能更新，别担心——只需
                  在<Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{hrSupport}</Body1Strong></Link>，联系您的HR支持团队，
                  他们将乐于介入并解决问题。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="16">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  16. 理解标签组：全部，收藏，已签到和新员工
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>全部：</Body1Strong> 这个默认标签展示了你组织中的每一个员工，
                      提供了一个团队的全面视图。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>收藏：</Body1Strong> 点击这里过滤你视图到那些你标记为'收藏'的同事，
                      使得与关键联系人快速连接更加容易。
                    </li>                  
                    <li className={styles.bulletBottomMargin}><Body1Strong>已签到：</Body1Strong> 此标签指示正在工作的员工，与仅仅在线于
                      Microsoft Teams的在线存在相区分。签到时，员工表示他们在工作，并在日间分享他们当前的工作位置。这可以包括多种工作地点，如办公室、现场办公、家庭办公、远程工作地点、客户工作地点、客户私人办公室、公共区域、通勤、商务旅行或甚至培训。因此，'已签到'状态提供了一个明确指示，表明正在积极工作，为同事提供了对方当前工作环境和可用性的准确理解。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>新员工：</Body1Strong> 使用此标签在TeamConnect中发现新面孔，突出显示
                      在过去30天内被添加到应用程序中的员工。通常，这与你组织的最新添加相符，帮助你欢迎新团队成员。
                    </li>
                  </ul>
                  这些标签组旨在简化导航并增强你的TeamConnect体验，确保你总是能够高效地找到并与同事连接。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="17">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  17. 解码搜索框旁的图标
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect的员工目录为多功能和易用而设计，提供四个图标，它们提供不同视图和快速访问关键细节。以下是每个图标揭示的内容：
                  <ol>
                    <li className={styles.bulletBottomMargin}><Body1Strong>列表视图：</Body1Strong> 你的首选视图，以简单的列表呈现所有组织成员。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>小卡片视图：</Body1Strong> 一个紧凑的选择，这个视图将列表转换成小块员工卡片。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>大卡片视图：</Body1Strong> 选择更多细节的大卡片，其中包括额外的联系选项，如电话和移动电话号码，便于拨打。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>组织结构图：</Body1Strong> 用组织结构图视图可视化公司结构，完美地理解汇报关系和浏览组织景观。</li>
                  </ol>
                  在这些图标之间切换，找到最适合你当前需要的视图，无论你是在寻找快速信息还是更深入的洞察。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="18">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  18. 快速查找同事，我应该在哪里看？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  寻找同事？只需前往位于上方右侧靠近组织结构图图标的TeamConnect搜索框。开始输入他们的名字，看着结果随着每个字母的输入而精细化。记住，对于TeamConnect搜索，跳过顶部的Microsoft Teams搜索栏——我们特殊的搜索框是去处！
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="19">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  19. 字母搜索如何用于查找员工？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect的员工目录包括一个方便的字母搜索功能，帮助您快速按名字查找员工。
                  只需点击目录顶部显示的字母表中的一个字母，TeamConnect将立即显示所有名字以该字母开头的员工。
                  要返回到全部员工列表，只需取消选择突出显示的字母。这个直观的功能使得浏览大型员工列表变得轻而易举，
                  确保您可以快速有效地找到同事。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="20">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  20. 如何调整每页显示的员工数量？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect允许您自定义每页查看的员工资料数量，使得浏览员工目录更加容易。
                  在每页的底部，您会找到分页选项：10、50、100、500、1000 和 5000。默认设置显示每页 10 个资料。
                  要在一个页面上查看更多资料，只需从这些选项中选择您偏好的数字。此功能提供了浏览目录的灵活性，
                  使您能够根据具体需求或偏好定制显示方式。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="21">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  21. 我如何在列表视图中对员工信息进行排序？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在TeamConnect的员工目录列表视图中，您可以根据需要灵活地对员工信息进行排序。
                  列—员工姓名、职位、部门、办公地点和工作场所—都是可排序的。只需点击您希望按照排序的列标题。
                  点击一次将按升序对列进行排序，再次点击将按降序排序。默认情况下，目录按照名字的升序排列员工姓名。
                  这种排序功能帮助您快速组织和查看员工详情，无论您是在寻找特定某人还是需要按部门或地点等特定标准对员工进行分组。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>部门目录常见问题解答</Subtitle2>
          <Body1>
            <p>
            <Body1Strong>部门目录介绍：</Body1Strong>部门目录是您了解组织结构复杂网络的入口，提供部门层级、管理领导和顺畅部门沟通途径的清晰视图。
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="22">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  22. '所有'、'收藏'和'您的部门'标签在部门目录中是如何工作的？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在TeamConnect的部门目录中，标签帮助您快速导航和过滤部门信息。下面是每个标签的含义：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>所有：</Body1Strong>此标签显示组织内的所有部门，提供全面的概览。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>收藏：</Body1Strong>使用此标签查看您标记为'收藏'的部门列表，便于您频繁互动的部门的访问。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>您的部门：</Body1Strong>此标签过滤显示仅为您的部门，允许快速访问您的直接部门信息和更新。</li>
                  </ul>
                  这些标签旨在简化您在部门目录内的导航，增强您高效找到并参与部门信息的能力。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="23">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  23. 了解TeamConnect中的部门目录视图
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在TeamConnect的部门目录中，通过多种视图选项来简化导航，以适应您的偏好。每种视图都提供了一种独特的方式来探索全面的部门信息，包括部门名称、部门经理、上级部门、部门电话、部门热线和部门电子邮件。以下是可用视图的快照：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>列表视图：</Body1Strong>默认设置，整齐地列出所有部门，提供清晰、简洁的概览。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>大卡片视图：</Body1Strong>切换到此视图可以看到详细的部门卡片，提供每个部门关键信息的扩展概要。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>组织结构图视图：</Body1Strong>为部门结构和关系提供视觉表示，组织结构图视图展示每个部门如何在更大的组织层次中定位。
                    </li>
                  </ul>
                  要在这些视图之间切换，只需使用部门目录顶部搜索框旁边的图标。每种视图都旨在帮助您轻松访问和理解部门细节，确保您以最适合您的格式找到所需信息。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="24">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  24. 如何使用部门目录中的搜索框
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在TeamConnect的部门目录顶部，使用专用的搜索框可以无缝定位任何部门，该搜索框位于视图图标旁边。只需开始输入您要查找的部门名称，就会看到目录自身正在细化，仅显示相关匹配项。此功能专为帮助您绕过手动筛选整个目录的需要而设计，可直接将所需部门带到您的指尖。请记住，这种搜索功能是TeamConnect的部门目录特有的，因此请使用此搜索框而不是Teams界面最顶部的主Microsoft Teams搜索来进行部门特定的查询。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="25">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  25. 字母过滤器在部门目录中是如何工作的？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在部门目录顶部利用字母过滤器来简化您的搜索。此功能允许您通过选择一个字母来快速缩小部门范围。一旦你选择了一个字母，TeamConnect会过滤并仅显示那些名称以您所选字母开头的部门，这使得找到所需内容变得更加容易，无需浏览整个列表。要返回到完整的部门视图，只需取消选择突出显示的字母即可。这种直观的字母导航确保您可以通过其首字母有效地定位任何部门。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="26">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  26. 我如何联系部门经理？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在TeamConnect中联系部门经理很简单。只需找到感兴趣的部门并点击经理的名字。此操作将触发一个弹出窗口，显示经理的联系方式选项和熟悉的快速访问图标：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>聊天图标：</Body1Strong> 通过Microsoft Teams聊天启动即时消息。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>电子邮件图标：</Body1Strong> 在Microsoft Outlook中打开一个新邮件，地址发送给他们。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>视频通话图标：</Body1Strong> 通过Microsoft Teams启动视频通话。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>通话图标：</Body1Strong> 使用Microsoft Teams开始语音通话。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>更多 (...) 图标：</Body1Strong> 用于其他选项，如安排会议或将他们的联系信息添加到您的Teams列表中。
                    </li>
                  </ul>
                  这些图标提供了一种无缝的方法，直接在TeamConnect内部与部门经理联系，确保组织内的有效沟通。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="27">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  27. 如何查看或联系某个部门内的员工？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  要在TeamConnect中探查某个部门内的员工名单，请点击部门名称旁边的三个点。点击后将出现进一步操作的选项：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>查看该部门的员工：</Body1Strong>选择此项以显示与该部门相关的所有员工的列表。此选项旁边的数字显示部门内的员工数目，快速提供人数统计。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>部门组织图：</Body1Strong>如果你想了解部门的层级结构，选择“部门组织图”以揭示结构布局，展示父部门、所查询的部门以及任何子部门之间的关系。这种视觉呈现帮助你导航并理解组织框架，便于识别并联系特定部门成员。
                    </li>
                    <li><Body1Strong>部门 {'>'} 员工：</Body1Strong>查看部门内的员工后，利用这个面包屑路径轻松回到部门列表，保持在TeamConnect内的流畅探索体验。
                    </li>
                  </ul>
                  这些功能不仅让你可以看到某个部门的员工名单，还提供了通过TeamConnect的沟通工具直接联系他们的途径。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="28">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  28. 在TeamConnect中发现部门的深入详情
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  只需在TeamConnect中点击部门名称，即可深入了解任何部门的具体情况。一个详细的对话框将展开，提供丰富的信息，完整呈现部门在您组织中的角色和资源。你将发现从领导层和财务标识到沟通渠道和社交链接的所有信息。这一功能是你的入口，掌握：
                  <ul>
                    <li className={styles.bulletBottomMargin}>部门的核心—其目的、人员和绩效指标。</li>
                    <li className={styles.bulletBottomMargin}>联系途径—与主要部门人物和沟通平台的直接线路。</li>
                    <li className={styles.bulletBottomMargin}>组织网络—理解部门如何与更广泛的公司结构整合。</li>
                  </ul>
                  无论是快速查询还是深入了解部门数据，TeamConnect都为你提供了连接和理解组织内独特生态系统的工具。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="29">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  29. 对TeamConnect中缺少部门地址感到好奇？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  寻找部门地点有时可能像没有地图的寻宝。在TeamConnect中，你通常不会找到每个部门的专用地址。以下是这项地图详细信息通常被省略的原因：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>多地点：</Body1Strong>许多部门不限于单一空间；它们在不同办公室和地理位置蓬勃发展。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>流动结构：</Body1Strong>组织景观在不断变化，部门随着战略变化而发展、合并甚至解散。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>跨部门协作：</Body1Strong>现代工作空间充满了合作精神，经常将不同部门的人才混合在共享空间中。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>远程工作文化：</Body1Strong>随着远程工作的灵活性，部门锚点并不总是设在实体办公地点。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>法律细节：</Body1Strong>部门通常围绕公司的法律结构运作，而不是拥有自己的独立地址。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>运营简化：</Body1Strong>保持对动态团队合作的关注，而不是静态地址，有助于保持运营灵活性。
                    </li>
                  </ul>
                  这一切都是为了拥抱一个无界限的团队合作和效率方法。TeamConnect通过强调联系而非地点，体现了这一理念。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="30">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  30. 您的部门信息是否缺失或已过时？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在您的组织内指定部门编辑以在TeamConnect中添加、更新或更正部门特定信息。
                  如果您不确定谁是您部门的编辑或某些信息是否需要人力资源支持团队的干预， 
                  请联系 <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> 获取指导。
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>办公室目录常见问题解答</Subtitle2>
          <Body1>
            <p>
              <Body1Strong>办公室目录介绍：</Body1Strong> 通过办公室目录了解您组织的物理存在的核心，该目录包括基本的办公室细节、运营特点以及定义每个位置的部门和人员的全面概览。
            </p>
          </Body1>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="31">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  31. 导航办公室目录中的标签
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  使用我们为快速访问正确位置而设计的标签系统轻松找到办公室目录中的路径。以下是每个标签如何帮助简化您的搜索：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>全部：</Body1Strong> 显示办公室的完整列表，提供您组织位置的鸟瞰图。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>收藏：</Body1Strong> 通过将它们标记为“收藏”，帮助您专注于您经常访问或需要密切关注的办公室位置。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>您的办公室：</Body1Strong> 这个个人标签过滤目录，向您显示您所在的办公位置，保持您的基地在您的指尖。
                    </li>
                  </ul>
                  无论您是预订会议室还是计划访问，这些标签都为您与组织的地理景观互动提供了个性化和简化的方式。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="32">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  32. 在TeamConnect中掌握办公室目录视图
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  深入了解您公司各地点的组织布局，通过TeamConnect的办公室目录视图。每个视图都是为了促进对办公室导航和理解的独特方面而设计的:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>列表视图:</Body1Strong> 默认设置，将所有办公地点整齐地组织成易于阅读的列表。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>大卡片视图:</Body1Strong> 办公室目录中的大卡片视图通过每个办公室的详细展示丰富您的视图。与办公室卡片交互时，您会在“更多(…)”菜单中找到大量信息和可操作选项:
                      <ol>
                        <li style={{ marginTop:'8px' }}className={styles.bulletBottomMargin}><Body1Strong>查看部门:</Body1Strong> 直接导航至办公室内所有部门的列表。
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>查看员工:</Body1Strong> 访问分配给办公室的员工完整名册，便于直接联系或探索办公室人员。
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>业务实体组织图:</Body1Strong> 将办公室在更大组织环境中的位置可视化，清楚地了解层级结构。
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>重要联系人:</Body1Strong> 识别关键办公室联系人，以简化沟通。
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>办公室公共假期:</Body1Strong> 了解办公室特定的公共假期，以相应地计划您的日程。
                        </li>
                        <li className={styles.bulletBottomMargin}><Body1Strong>办公时间:</Body1Strong> 查看办公室的营业时间，以便您的访问或通话符合其活动时间。
                        </li>
                      </ol>
                      这种视图不仅提供视觉和信息快照，还可以快速访问与办公室相关的更深入见解和直接操作，所有这些都整齐地放在“更多(…)”选项下，方便您使用。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>组织图视图:</Body1Strong> 可视化父级和子办公室的层级结构，以理解您的组织中不同办公地点之间的相互联系。
                    </li>
                  </ul>
                  使用搜索框旁边的图标切换这些视图，以自定义您的浏览体验，以适应您的即时需求。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="33">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  33. 使用TeamConnect的搜索功能快速找到办公室
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在TeamConnect中，通过专用的搜索功能轻松定位正确的办公室位置。搜索框位于视图图标旁边的显眼位置，是您通过名称查找办公室的工具。当您输入时，目录会细化显示，仅显示与您输入匹配的办公室，简化您的搜索过程。在TeamConnect中使用这个搜索框非常重要，以获得最准确和相关的结果，而不是使用通用的Microsoft Teams搜索。有了这种针对性的搜索功能，您可以迅速导航至组织网络中的任何一个办公室。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="34">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  34. 按字母顺序浏览办公室目录
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  如果您喜欢按字母顺序搜索，我们的字母筛选器是快速找到办公室名称的方法。在办公室目录的顶部，您会看到从A到Z的字母。点击一个字母会筛选视图，仅显示以该字母开头的办公室，简化您的搜索过程。要重置并再次查看所有办公室，只需取消选择活动字母。当您熟悉办公室名称但需要快速找到它在TeamConnect中的位置时，此功能特别有用。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="35">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  35. TeamConnect中全面展示办公室简介
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  开始通过TeamConnect的办公室简介，探索您组织的物理存在的核心。点击办公室名称会弹出一个充满活力的简介对话框，其中充满了提供详细办公室洞察的标签:
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>概览：</Body1Strong>通过详细描述，从业务结构到营业时间和当地时间，深入了解办公室的身份特征。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>必要联系人：</Body1Strong>访问紧急服务和外部支持的重要联系信息，每一个信息都通过Microsoft Teams Phone System点击即可到达。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>办公室联系人：</Body1Strong>轻松联系内部支持服务，联系详细信息和直接互动选项触手可及。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>公共假期：</Body1Strong>了解办公室特定的假日，确保您的计划与当地的观察和工作日程一致。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>办公时间：</Body1Strong>精准规划您的互动，确知办公室何时活动繁忙或何时为一天结束而放慢脚步。
                    </li>
                  </ul>
                  无论是奥斯汀的繁忙能量还是新加坡的战略中心，每个标签都提供了一个门户，以理解和连接构成贵公司全球存在感的多样地点。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="36">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  36. 探索办公室内部门的动态
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在TeamConnect中轻松导航办公室部门的专业网络。以下是深入了解详情的方法：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>查看本办公室的部门：</Body1Strong>通过从更多(...)菜单中选择此选项，发现构成办公室结构的所有部门。
                    </li>                      
                    <li className={styles.bulletBottomMargin}><Body1Strong>组织洞察：</Body1Strong>点击任何一个部门显示其组织结构图，以获取其结构的视觉表现，面包屑路径指引您：办公室 {'>'} 部门 {'>'} 图表。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>部门深入研究：</Body1Strong>通过点击部门名称旁边的更多菜单(...)进一步探索一个部门。这里，您可以在办公室环境中访问该部门所有员工的列表。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>面包屑导航：</Body1Strong>平滑地遍历各级。从员工返回到部门或从组织结构图，面包屑路径（办公室 {'>'} 部门 {'>'} 员工 {'>'} 图表）使您的旅程直观并保持在正确的轨道上。
                    </li>
                  </ul>
                  TeamConnect的错综复杂的路径邀请您探索、理解并参与您办公室的每一层部门，赋予您全面的导航控制权。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="37">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  37. 在TeamConnect中与办公室人员连接
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  对办公室背后的面孔感到好奇？答案在TeamConnect中只需一键即可获得。在您选择的办公室名称旁边点击省略号（三个点）将显示一系列互动选项：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>查看本办公室的员工：</Body1Strong>揭示所有称此办公室为工作家园的专业人士的列表。显示的数字提供了员工的快速统计，邀请您深入了解办公室社区。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>联系选项：</Body1Strong>每个员工条目都配备了快速操作图标，允许您从TeamConnect中发起聊天、电话或甚至视频会议，促进无缝的办公室内通信。
                    </li>
                    <li><Body1Strong>导航：</Body1Strong>要从员工列表返回到更广泛的办公室列表，只需在面包屑路径中点击“办公室”（办公室 {'>'} 员工），您将被带回到办公室概览。
                    </li>
                  </ul>
                  凭借这些功能，TeamConnect使得可视化跨办公室的员工分布变得简单，并轻松联系，促进无论距离多远都能保持工作场所的联系。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="38">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  38. 您的办公室信息是否遗失或过时？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect中的办公室信息更新可以由指定的办公室编辑处理。如果您不确定谁是办公室编辑或
                  需要与HR相关的信息帮助，HR支持团队随时
                  准备在 <Link href={`mailto:${hrSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link> 协助您。
                </Body1>
              </AccordionPanel>
            </AccordionItem>          
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>设置常见问题</Subtitle2>
        <p></p>
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="39">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  39. 在TeamConnect中确保一般设置的准确性的重要性
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在当今工作环境的动态格局中，保持互联和协调比以往任何时候都更为重要。这就是在TeamConnect中更新您的
                  一般设置起到关键作用的地方：
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>工作地点选择：</Body1Strong>通过更新您的工作地点来捕捉您工作环境的本质，无论是从家庭办公室的宁静到主办公室的繁忙，还是安顿在公共区域。
                        您的工作地点的明确性不仅为同事提供了联络您的最佳方式，还涉及他们可以与您进行的对话性质，尤其是当您在客户办公室的开放环境中或坐在机场休息室时，保护敏感讨论的保密性。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>工作地点明确：</Body1Strong>分享您的工作城市不仅能缩短远程团队设置中的距离——它还打开了临时专业集会或随意社交的大门。无论是快速咖啡、悠闲午餐还是合作会议，知道您在同一城市可以将普通一天转变为面对面连接的有意义机会。这不仅加强了团队的联系，还为您可能影响工作的当地细微差别提供了更深入的理解。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>时区准确性：</Body1Strong>随着团队横跨大陆，确保您的时区反映您当前的所在地对于协调沟通至关重要。您的TeamConnect个人资料的本地时间直接受此设置影响，
                      消除了同事试图联系您时的任何猜测。
                    </li>
                  </ul>
                  跳转到TeamConnect的'设置' {'>'} '一般设置'，细调这些详情。花几分钟时间更新您的设置可以
                  显著增强您整个团队的合作默契和效率。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="40">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  40. 解析 TeamConnect 中的工作场所选择
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  在工作场所下拉菜单中选择正确的设置，可以使 TeamConnect 准确反映您当前的工作状态。将鼠标悬停在信息图标上，以获取每个选项的详细说明：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>已签出/休息：</Body1Strong> 表示工作时间之外，无论是休息还是一天的结束。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>办公室/现场办公室：</Body1Strong> 表示处于公司管理的工作空间内。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>家庭办公室：</Body1Strong> 在专用的家庭工作空间工作。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>远程工作场所/公共区域：</Body1Strong> 在非办公环境中运作，表明需要灵活的沟通。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>客户工作场所/私人办公室：</Body1Strong> 在客户现场，注意对话隐私级别。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>通勤/商务旅行/培训：</Body1Strong> 在移动中或参与办公室外的特定工作相关活动。</li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>公共假期/假期/各种休假：</Body1Strong> 因个人、健康或假日原因正式不上班。</li>
                  </ul>
                  选择适当的工作场所设置可以确保您的团队了解交互所需的上下文，尊重可用性和隐私。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="41">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  41. 在 TeamConnect 中导航“已签到”状态
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  员工目录中的“已签到”标签旨在显示当前在工作的所有员工。这种可见性由您所做的工作场所选择决定：
                  <ul>
                    <li className={styles.bulletBottomMargin}><Body1Strong>活跃工作环境：</Body1Strong> 选择办公室、现场办公室、家庭办公室、远程工作场所、公共区域、客户工作场所、
                    客户私人办公室、通勤、商务旅行和培训等，表明您正在工作，使您“已签到”并对同事可见。
                    </li>
                    <li className={styles.bulletBottomMargin}><Body1Strong>非工作设置：</Body1Strong> 选择已签出、休息、公共假期、假期、请假、产假或病假
                      状态让您从“已签到”列表中移除，为可用性和响应时间设定清晰的期望。
                    </li>
                  </ul>
                  这个功能有助于区分工作时间和个人时间，促进有效的团队合作和沟通。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Subtitle2>数据管理和隐私常见问题解答</Subtitle2>
          <Body1>
            <p>
              在当今数字化时代，理解您的数据是如何管理的至关重要。TeamConnect 致力于透明度和强大的数据保护实践。
              下方，您将找到有关我们如何在 TeamConnect 内处理、存储和保护您的数据的信息，确保您的数字工作空间是安全且符合法规的。
            </p>
          </Body1>        
        <div>
          <Accordion openItems={openItems} onToggle={handleToggle} multiple collapsible>
            <AccordionItem value="42">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  42. TeamConnect 如何保护我的个人数据？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  <p>TeamConnect 致力于通过全面的数据安全方法来保护您的个人信息：</p>
                  <ul>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>单点登录 (SSO)：</Body1Strong> 为了加强安全性和简化用户体验，TeamConnect 集成了与您的 Microsoft 365 账户的自动化
                      单点登录 (SSO)，便于安全且轻松的访问管理。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>加密：</Body1Strong> 我们确保 TeamConnect 中的所有数据在传输和静止时都进行加密，
                      使它们无法被未经授权的方访问。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>数据最小化：</Body1Strong> 我们的数据收集严格限于 TeamConnect 功能所必需的。
                      我们存储的唯一用户信息是您在 Azure Entra ID 中的用户主体名称 (UPN)，可能类似于您的公司电子邮件地址。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>限制访问：</Body1Strong> 访问 UPN 仅限于您组织中的订阅管理员在我们的订阅门户中，
                      确保敏感信息仅对授权人员可见。
                    </li>
                    <li className={styles.bulletBottomMargin}>
                      <Body1Strong>及时数据删除：</Body1Strong> TeamConnect 设计为在三种特定条件下立即删除存储的 UPN:
                      如果订阅由订阅管理员取消，如果用户被 TeamConnect 中的 HR 删除，或者如果 TeamConnect
                      订阅过期。这确保您的个人数据在其有用寿命或必要性之后不被保留。
                    </li>                      
                  </ul>
                  <p>通过这些措施，TeamConnect 承诺维护最高的数据保护标准，确保您的个人信息保持安全和保密。</p>
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="43">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  43. 想知道您的数据存放在 TeamConnect 的哪里吗？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect 打破了传统 SaaS 产品的模式，确保您的数据永不离开您的组织。
                  与通常将数据存储在外部云服务器的做法不同，TeamConnect 巧妙地利用您的组织的
                  Microsoft 365 SharePoint Online 来存放所有应用数据。这种创新方法不仅维护了数据主权，还加强了安全性，
                  完美地与我们坚定的保护您的隐私和遵守严格的合规标准的承诺相一致。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="44">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  44. 我的数据会与任何第三方共享吗？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  不，数据不会与第三方共享！Cubeet 坚定承诺保护您的数据隐私和安全。我们不会在未获得客户明确同意的情况下,
                  与任何第三方共享 TeamConnect 数据。
                  在我们安全的环境中，我们仅存储必要的标识符，例如用户主体名称（UPN）、组织名称、租户名称和租户 ID，
                  这些都存放在我们的订阅门户中。这些信息对于服务提供至关重要，并且在我们严格的隐私政策下得到保护。
                  请放心，我们的数据管理实践旨在确保您的数据保持机密，同时在您手中保持透明度和控制权。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="45">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  45. TeamConnect 是如何遵守 GDPR 等数据保护法规的？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect 依靠 Microsoft 365 环境的固有合规性功能，遵守 GDPR、HIPAA、CCPA 等数据保护法规，
                  在该环境中安全存储所有客户数据。与传统的 SaaS 模型不同，TeamConnect 不在其服务器上存储任何客户数据；
                  相反，它在您的组织的 Microsoft 365 环境中运行，遵循其稳固的安全性和合规标准。这种方法确保您的数据治理与 
                  Microsoft 365 提供的全面合规框架保持一致，包括遵守 GDPR、针对医疗信息的 HIPAA、针对加利福尼亚居民的 CCPA，
                  以及其他全球数据保护法规。通过与您现有的 Microsoft 365 设置无缝集成，TeamConnect 使您的组织能够完全控制您的数据，
                  确保遵守最严格的数据保护法律和法规。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem value="46">
              <AccordionHeader>
                <Body1Strong className={styles.fontColor}>
                  46. TeamConnect如何处理来自Microsoft Teams聊天、通话、视频通话、Outlook电子邮件和电话系统通话的数据？
                </Body1Strong>
              </AccordionHeader>
              <AccordionPanel>
                <Body1>
                  TeamConnect利用Microsoft Graph API来启用诸如聊天、通话、视频通话和Outlook电子邮件的直接交互方式，在Microsoft Teams中提供无缝的用户体验。关键的是，虽然TeamConnect启动这些通讯，但它不介入通讯过程，也没有能力记录或存储任何交互内容。所有对话、通话和电子邮件都是在Microsoft Teams和Outlook的安全环境中直接进行和管理的，严格遵守Microsoft 365的强大安全和隐私标准。这确保了TeamConnect在增强您的通信能力的同时，完全保护了您的数据的保密性和完整性。
                </Body1>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <p></p>
        <Body1>
          <p>
            欲了解有关我们数据政策和实践的详细信息，您也可以查询{' '}
            <Link href={userGuideLink} target="_blank" rel="noreferrer">
            <Body1Strong style={{ color: tokens.colorBrandForeground1 }}>TeamConnect用户指南</Body1Strong></Link>或您的组织的IT部门
            <Link href={`mailto:${itSupport}`}><Body1Strong className={styles.fontColor}>{itSupport}</Body1Strong></Link>以获得与您的设置相关的具体信息。
          </p>
        </Body1>
        <p></p>
        <Subtitle1 className={styles.fontColor}>我们在倾听：分享您的反馈和想法</Subtitle1>
        <Body1>
          <p>
            您的见解推动TeamConnect的发展。无论您是有一个绝妙的功能想法，还是对当前体验有一些反馈，我们都洗耳恭听。您的输入对我们不断提供最佳服务的追求至关重要。
          </p>
        </Body1>
        <Subtitle2>反馈和功能建议</Subtitle2>
        <Body1>
          <p>
            准备分享？请前往我们的{' '}
            <Link 
              style={{ fontWeight: 600 }} 
              href={feebackLink}
              target='_blank'>
              反馈页面
            </Link>
            。无论是针对新功能的建议还是对现有功能的反馈，只需从表单中选择适当的选项。您的贡献有助于塑造TeamConnect的未来，确保它满足并超越您的需求。
          </p>
        </Body1>
    </>
  );
};

Container.propTypes = {
  itSupport: PropTypes.string,
  hrSupport: PropTypes.string,
  feebackLink: PropTypes.string,
  userGuideLink: PropTypes.string,
  openItems: PropTypes.array,
  handleToggle: PropTypes.func,
};

export default Container;
